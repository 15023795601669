import { memo, useCallback } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Transforms } from 'slate';
import { ReactEditor, RenderElementProps, useReadOnly, useSelected, useSlate } from 'slate-react';

import { Checkbox } from 'lib/checkbox';

const Wrapper = styled('div')<{ $showHighlight: boolean }>`
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 6px;
  background: ${({ $showHighlight, theme }) =>
    $showHighlight && theme.palette.dina.blackHoverOverlay};
  & + & {
    margin-top: 0;
  }
`;

const CheckboxWrapper = styled('span')`
  cursor: pointer;
  margin: 0 4px;
  align-self: flex-start;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Label = styled('span')<{ $checked: boolean }>`
  flex: 1;
  font-size: 16px;
  ${({ $checked }) => css`
    opacity: ${$checked ? 0.666 : 1};
    text-decoration: ${!$checked ? 'none' : 'line-through'};
  `}

  &:focus {
    outline: none;
  }
`;

function CheckList({ attributes, children, element }: Readonly<RenderElementProps>) {
  const editor = useSlate();
  const readOnly = useReadOnly();
  const selected = useSelected();

  const showHighlight = !readOnly && selected;

  const { checked = false } = element?.data ?? {};

  const onCheckedChange = useCallback(() => {
    const path = ReactEditor.findPath(editor, element);
    Transforms.setNodes(editor, { data: { checked: !checked } }, { at: path });
  }, [element, checked]);

  return (
    <Wrapper $showHighlight={showHighlight} {...attributes}>
      <CheckboxWrapper contentEditable={false}>
        <Checkbox checked={checked} onCheckedChange={onCheckedChange} />
      </CheckboxWrapper>
      <Label contentEditable={!readOnly} $checked={checked} suppressContentEditableWarning>
        {children}
      </Label>
    </Wrapper>
  );
}

export default memo(CheckList);
