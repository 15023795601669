/* eslint-disable react/no-array-index-key */
import React, { memo, useState, useCallback, useMemo } from 'react';
import { useSlate, useReadOnly } from 'slate-react';
import PropTypes from 'prop-types';
import fieldEnums from 'utils/constants/fieldEnums';
import AutoComplete from 'components/autoCompleteBase';
import useGetFieldsForBlock from 'hooks/useGetFieldsForBlock';
import { elementTypes } from 'components/editor/constants/types';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import { useEditorContext } from 'components/editor/EditorContext';
import { ReactComponent as FeedSourceIcon } from 'assets/icons/systemicons/FeedSource.svg';
import updateBlock from 'components/editor/utils/updateBlock';
import Chip from 'components/chip';
import useChangeCollapse from 'components/editor/hooks/useChangeCollapse';
import stringifyList from 'components/editor/components/tags/utils/stringifyList';
import Box from '../box';
import DragAndDrop from '../dragAndDrop';
import { ContentWrapper, SourceIcon, ChipListWrapper, ListItem } from './styled';

const AutoCompleteBase = memo(AutoComplete);

const Source = ({ attributes, children, element, direction }) => {
  const [getFieldsForBlock] = useGetFieldsForBlock();
  const { data } = element;
  const editor = useSlate();
  const field = getFieldsForBlock(fieldEnums.CUSTOM_SOURCES, { options: [] });
  const options = field?.options || [];
  const readOnly = useReadOnly();
  const { update } = useEditorContext();
  const { content = [], collapsed = false } = data;

  const [onChangeCollapse] = useChangeCollapse(element);

  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const updateSource = useCallback(
    (updatedData) => {
      updateBlock(editor, element, updatedData, update, false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element, update],
  );

  const handleChange = useCallback(
    (event, newSource) => {
      event.preventDefault();
      if (newSource && newSource !== null) {
        const isIncluded = content.find((opt) => opt.value === newSource.value);

        if (!isIncluded) {
          const updatedData = {
            ...data,
            content: [...data.content, { id: newSource.id, value: newSource.value }],
          };
          updateSource(updatedData);
        }
        setInputValue('');
        setValue(null);
      }
    },
    [content, data, updateSource],
  );

  const removeSource = useCallback(
    (index) => {
      const updatedData = {
        ...data,
        content: content.filter((_, pos) => pos !== index),
      };
      updateSource(updatedData);
    },
    [content, data, updateSource],
  );

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    getClearProps,
    groupedOptions,
    getPopupIndicatorProps,
    popupOpen,
  } = useAutocomplete({
    id: 'source-box',
    options,
    autoHighlight: true,
    getOptionLabel: (option) => option.value,
    value,
    onChange: handleChange,
    inputValue,
    onInputChange: (_, newValue) => setInputValue(newValue),
  });

  const sourceView = useMemo(
    () => {
      const collapsedContent = stringifyList(content);
      return (
        <Box
          iconComponent={<SourceIcon className="skipOverride" />}
          title="Source"
          readOnly={readOnly}
          hideEllipsisButton
          collapsed={collapsed}
          collapsedContent={collapsedContent}
          updateCollapsed={onChangeCollapse}
        >
          <ContentWrapper>
            <AutoCompleteBase
              content={content}
              readOnly={readOnly}
              value={inputValue}
              placeholder="Start typing to find Source"
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              getListboxProps={getListboxProps}
              getOptionProps={getOptionProps}
              getClearProps={getClearProps}
              groupedOptions={groupedOptions}
              getPopupIndicatorProps={getPopupIndicatorProps}
              popupOpen={popupOpen}
              direction={direction}
            />
            <ChipListWrapper>
              {content.map((option, idx) => (
                <ListItem key={`${option.id}-${idx}`}>
                  <Chip
                    label={option.value}
                    width="100%"
                    height={40}
                    onDelete={() => removeSource(idx)}
                    CustomAvatar={<FeedSourceIcon />}
                  />
                </ListItem>
              ))}
            </ChipListWrapper>
          </ContentWrapper>
        </Box>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [content, inputValue, popupOpen, readOnly, removeSource],
  );

  return (
    <div {...attributes}>
      <DragAndDrop element={element}>
        {children}
        {sourceView}
      </DragAndDrop>
    </div>
  );
};

Source.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

Source.defaultProps = {
  attributes: {},
  children: null,
  element: {
    children: [],
    data: { content: [] },
    type: elementTypes.SOURCE,
  },
};

export default memo(Source);
