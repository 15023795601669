import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Checkbox } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { StyledTextField, StyledCheckbox } from 'components/metadataEditor/fields/text/styled';
import Tooltip from 'components/tooltip';
import FieldWrapper from './FieldWrapper';
import { Remove, OptionWrapper, StyledDragHandle } from '../styled';

function FieldOptions({
  fieldOptions,
  updateOption,
  removeOption,
  updateFieldOptions,
  setHasDuplicateOption,
  field,
  setSelectedField,
}) {
  const [optionsWithError, setOptionsWithError] = useState([]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    const oldIndex = fieldOptions.findIndex((item) => item.id === active.id);
    const newIndex = fieldOptions.findIndex((item) => item.id === over.id);

    const updatedOrder = arrayMove(fieldOptions, oldIndex, newIndex);

    const updatedField = {
      ...field,
      options: updatedOrder,
    };

    // Update the field options and the selected field with the updated values
    updateFieldOptions(updatedField);
    setSelectedField(updatedField);
  };

  const handleOptionChange = (ev, option) => {
    const { name, value, checked } = ev.target;

    // Determine the updated value based on the name (either 'value' or 'checkbox')
    let updatedValue;
    if (name === 'value') {
      updatedValue = `${option?.value?.startsWith('inactive#') ? 'inactive#' : 'active#'}${value}`;
    } else if (name === 'checkbox') {
      updatedValue = checked
        ? `inactive#${getValueWithoutPrefix(option.value)}`
        : `active#${getValueWithoutPrefix(option.value)}`;
    }

    // Update the option value with the updated value
    updateOption({ value: updatedValue, prop: 'value', option });
  };

  const isTextFieldDisabled = (fieldOptionId) =>
    fieldOptionId === 'closed' || fieldOptionId === 'created';

  const getValueWithoutPrefix = (value) => {
    if (value && value.startsWith('active#')) {
      return value.substring('active#'.length);
    } else if (value && value.startsWith('inactive#')) {
      return value.substring('inactive#'.length);
    }
    return value;
  };

  // Check for duplicate values and update the optionsWithError state
  useEffect(() => {
    const fSet = new Set();
    const optionsWithError = [];

    fieldOptions.forEach((field) => {
      const valueWithoutPrefix = getValueWithoutPrefix(field.value);
      if (!fSet.has(valueWithoutPrefix)) {
        fSet.add(valueWithoutPrefix);
      } else {
        optionsWithError.push(field.id);
      }
    });

    setOptionsWithError(optionsWithError);
  }, [fieldOptions]);

  // Update the hasDuplicateOption state only when optionsWithError changes
  useEffect(() => {
    setHasDuplicateOption(optionsWithError.length > 0);
  }, [optionsWithError, setHasDuplicateOption]);

  return (
    <div style={{ padding: '0 0 18px 18px' }}>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={fieldOptions} strategy={verticalListSortingStrategy}>
          {fieldOptions.map((option) =>
            field.id === 'DEFAULT#status' ? (
              <FieldWrapper key={option?.id} field={option}>
                <OptionWrapper>
                  <StyledTextField
                    variant='filled'
                    value={option?.title}
                    onChange={(ev) =>
                      updateOption({ value: ev.target.value, prop: 'title', option })
                    }
                  />
                  <Tooltip
                    title={
                      isTextFieldDisabled(option?.id)
                        ? 'This value is immutable and cannot be changed'
                        : ''
                    }
                  >
                    <StyledTextField
                      name='value'
                      variant='filled'
                      value={getValueWithoutPrefix(option?.value)}
                      disabled={isTextFieldDisabled(option?.id)}
                      onChange={(ev) => handleOptionChange(ev, option)}
                      $hasError={optionsWithError.includes(option?.id)}
                    />
                  </Tooltip>
                  <Tooltip title={'Mark task as completed when this status is set'}>
                    <StyledCheckbox
                      name='checkbox'
                      checked={!option?.value?.startsWith('active#')}
                      onChange={(ev) => handleOptionChange(ev, option)}
                      disabled={isTextFieldDisabled(option?.id)}
                      color='primary'
                    />
                  </Tooltip>
                  {!isTextFieldDisabled(option?.id) && (
                    <Remove className='remove' onClick={() => removeOption(option)} />
                  )}
                  {optionsWithError.includes(option?.id) && (
                    <FormControl error>
                      <FormHelperText id={option?.id}>Duplicate Value</FormHelperText>
                    </FormControl>
                  )}
                </OptionWrapper>
              </FieldWrapper>
            ) : (
              <FieldWrapper key={option?.id} field={option}>
                <OptionWrapper>
                  <StyledTextField
                    variant='filled'
                    value={option?.title}
                    onChange={(ev) =>
                      updateOption({ value: ev.target.value, prop: 'title', option })
                    }
                  />
                  <StyledTextField
                    name='value'
                    variant='filled'
                    value={option?.value}
                    onChange={(ev) =>
                      updateOption({ value: ev.target.value, prop: 'value', option })
                    }
                    $hasError={optionsWithError.includes(option?.id)}
                  />
                  <Remove className='remove' onClick={() => removeOption(option)} />
                  {optionsWithError.includes(option?.id) && (
                    <FormControl error>
                      <FormHelperText id={option?.id}>Duplicate Value</FormHelperText>
                    </FormControl>
                  )}
                </OptionWrapper>
              </FieldWrapper>
            ),
          )}
        </SortableContext>
      </DndContext>
    </div>
  );
}

FieldOptions.propTypes = {
  fieldOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      icon: PropTypes.string,
      __typename: PropTypes.string,
    }),
  ).isRequired,
  updateOption: PropTypes.func.isRequired,
  removeOption: PropTypes.func.isRequired,
  updateFieldOptions: PropTypes.func.isRequired,
  setHasDuplicateOption: PropTypes.func.isRequired,
  field: PropTypes.shape({
    defaultValue: PropTypes.string.isRequired,
    function: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        icon: PropTypes.string,
        __typename: PropTypes.string,
      }),
    ).isRequired,
    type: PropTypes.string.isRequired,
    __typename: PropTypes.string.isRequired,
  }).isRequired,
  setSelectedField: PropTypes.func.isRequired,
};

FieldOptions.defaultProps = {
  fieldOptions: [],
  updateOption: () => {},
  removeOption: () => {},
  updateFieldOptions: () => {},
  setHasDuplicateOption: () => {},
  field: {
    defaultValue: '',
    function: () => {},
    id: '',
    label: '',
    name: '',
    options: [],
    type: '',
    __typename: '',
  },
  setSelectedField: () => {},
};

export default FieldOptions;
