/* eslint-disable import/no-extraneous-dependencies */
import pipe from 'lodash/fp/pipe';

/**
 * Converts hh:mm:ss formatted time string to seconds
 *
 * @param {String} time String to be converted
 * @returns {Number} Converted seconds
 */

const getSeconds = (time) => {
  if (!time) return 0;

  const getSign = pipe((string) => string.replaceAll(':', ''), Number, Math.sign);

  const timeArray = time.split(':').reverse();
  let summedSeconds = 0;
  for (let i = 0; i < timeArray.length; i++) {
    if (i === 0) summedSeconds += Number(timeArray[i]);
    if (i === 1) summedSeconds += Number(timeArray[i]) * 60;
    if (i === 2) summedSeconds += Number(timeArray[i]) * 60 * 60;
  }

  return getSign(time) * Number(summedSeconds);
};

export default getSeconds;
