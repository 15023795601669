import React, { memo } from 'react';
import PropTypes from 'prop-types';
import InputField from 'components/inputField';

const Input = memo(InputField);

const EncoderName = ({ readOnly, title, direction }) => (
  <Input
    description="Encoder name"
    disableLabel
    value={title}
    usage="editor"
    disabled={readOnly}
    placeholder="Encoder name"
    direction={direction}
  />
);

EncoderName.propTypes = {
  /** state of the title */
  title: PropTypes.string,
  /** boolean to disable input */
  readOnly: PropTypes.bool,
};

EncoderName.defaultProps = {
  title: '',
  readOnly: false,
};

export default memo(EncoderName);
