import { KanbanBoardStateType } from 'types';

const kanbanDefaultState: KanbanBoardStateType = {
  id: 'undefined',
  name: 'Undefined',
  description: 'Undefined',
  backgroundColor: 'kanbanArchived',
  icon: 'todo',
};

export default kanbanDefaultState;
