import { ApolloCache } from '@apollo/client';

import { MemberType } from 'types/graphqlTypes';

import {
  FetchBySpaceInput,
  FetchForms,
  ORDER_FORM_QUERY,
  ORDER_FORM_SPACE_QUERY,
} from './useGetOrderForms';

const removeFromSpaceCache = (
  client: ApolloCache<unknown>,
  spaceId: string,
  orderFormRefId: string,
) => {
  const cachedOrderForms = client.readQuery<FetchBySpaceInput>({
    query: ORDER_FORM_SPACE_QUERY,
    variables: {
      input: { mId: spaceId, mType: 'order_form' },
    },
  });
  if (cachedOrderForms?.getMembers) {
    const updated = [...cachedOrderForms.getMembers.filter((f) => f.mRefId !== orderFormRefId)];
    client.writeQuery({
      query: ORDER_FORM_SPACE_QUERY,
      variables: {
        input: { mId: spaceId, mType: 'order_form' },
      },
      data: {
        getMembers: updated,
      },
    });
  }
};

const removeFromOrderMenuCache = (client: ApolloCache<unknown>, orderFormRefId: string) => {
  const cachedOrderForms = client.readQuery<FetchForms>({
    query: ORDER_FORM_QUERY,
    variables: {
      input: { mType: 'order_form' },
    },
  });
  if (cachedOrderForms?.getMembersOftype) {
    const updated = [
      ...cachedOrderForms.getMembersOftype.filter((f) => f.mRefId !== orderFormRefId),
    ];
    client.writeQuery({
      query: ORDER_FORM_QUERY,
      variables: {
        input: { mType: 'order_form' },
      },
      data: {
        getMembersOftype: updated,
      },
    });
  }
};

const writeToOrderMenuCache = (client: ApolloCache<unknown>, newOrderForm: MemberType) => {
  const cachedOrderForms = client.readQuery<FetchForms>({
    query: ORDER_FORM_QUERY,
    variables: {
      input: { mType: 'order_form' },
    },
  });

  const existingIndex =
    cachedOrderForms?.getMembersOftype.findIndex((f) => f.mRefId === newOrderForm.mRefId) ?? -1;
  const copy = [...(cachedOrderForms?.getMembersOftype ?? [])];
  let updated: MemberType[] = [];
  if (existingIndex >= 0) {
    copy.splice(existingIndex, 1, newOrderForm);
    updated = [...copy];
  } else {
    updated = [newOrderForm, ...copy];
  }

  client.writeQuery({
    query: ORDER_FORM_QUERY,
    variables: {
      input: { mType: 'order_form' },
    },
    data: {
      getMembersOftype: updated,
    },
  });
};

const writeToSpaceOrderFormCache = (client: ApolloCache<unknown>, newOrderForm: MemberType) => {
  const cachedOrderForms = client.readQuery<FetchBySpaceInput>({
    query: ORDER_FORM_SPACE_QUERY,
    variables: {
      input: {
        mId: newOrderForm.mId,
        mType: 'order_form',
      },
    },
  });

  const existingIndex =
    cachedOrderForms?.getMembers.findIndex((f) => f.mRefId === newOrderForm.mRefId) ?? -1;
  const copy = [...(cachedOrderForms?.getMembers ?? [])];
  let updated: MemberType[] = [];
  if (existingIndex >= 0) {
    copy.splice(existingIndex, 1, newOrderForm);
    updated = [...copy];
  } else {
    updated = [newOrderForm, ...copy];
  }

  client.writeQuery({
    query: ORDER_FORM_SPACE_QUERY,
    variables: {
      input: {
        mId: newOrderForm.mId,
        mType: 'order_form',
      },
    },
    data: {
      getMembers: updated,
    },
  });
};

export const removeFromOrderFormCache = (
  client: ApolloCache<unknown>,
  orderFormRefId: string,
  spaceId?: string,
) => {
  if (spaceId) removeFromSpaceCache(client, spaceId, orderFormRefId);
  removeFromOrderMenuCache(client, orderFormRefId);
};

export const writeOrderFormToCache = (client: ApolloCache<unknown>, newOrderForm: MemberType) => {
  writeToOrderMenuCache(client, newOrderForm);
  writeToSpaceOrderFormCache(client, newOrderForm);
};
