import { memo, useCallback } from 'react';
import { useSlate } from 'slate-react';

import { markTypes } from 'components/editor/constants';
import { isMarkActive, toggleMark } from 'components/editor/utils';

import ButtonBase from '../buttonBase';

import iconComponents from './constants/iconComponents';

interface MarkButtonProps {
  type: string;
}

function MarkButton({ type = markTypes.BOLD }: Readonly<MarkButtonProps>) {
  const editor = useSlate();
  const isActive = isMarkActive(editor, type);

  const onMouseDown = useCallback(
    (event: React.MouseEvent<SVGSVGElement>) => {
      event.preventDefault();
      toggleMark(editor, type, isActive);
    },
    [editor, isActive, type],
  );

  const IconComponent = iconComponents[type];

  return (
    <ButtonBase
      type={type}
      isActive={isActive}
      IconComponent={IconComponent}
      onMouseDown={onMouseDown}
    />
  );
}

export default memo(MarkButton);
