/* eslint-disable sort-imports */
import { useEffect } from 'react';

import { DialogDataModel, useDataModel } from '../atoms';

interface Props {
  children: React.ReactNode;
  initialState?: DialogDataModel;
}

/**
 * This component  wraps the dialog and sets the initial state.
 * Using the internal state is optional.
 * */
export const StateWrapper = ({ children, initialState }: Readonly<Props>) => {
  const [, setDataModel] = useDataModel();

  useEffect(() => {
    if (initialState) setDataModel(initialState);
  }, [JSON.stringify(initialState)]);

  return <>{children}</>;
};
