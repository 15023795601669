import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import useCheckUserRight from 'hooks/useCheckUserRight';
import { useEditFormDialog } from 'store';
import { useReadOnly, useSelected, useSlateStatic, ReactEditor } from 'slate-react';
import { ReactComponent as Settings } from 'assets/icons/systemicons/settings_on.svg';
import useMouseClickEvents from 'hooks/useMouseClickEvents';
import { elementTypes } from 'components/editor/constants/types';

import {
  ArrowIconWrapper,
  ArrowDown,
  ArrowUp,
  BoxWrapper,
  BoxIconWrapper,
  ContentWrapper,
  Menu,
  MainContentWrapper,
  EllipsisWrapper,
  TitleWrapper,
  Title,
  Subtitle,
  SubSubTitle,
  CollapsedContent,
  TopRightContentWrapper,
  TopRightContent,
} from './styled';

const commonMenuItems = [
  {
    title: 'Configure metadata',
    action: 'configure',
    icon: <Settings />,
  },
];

export const availableBlocks = [
  elementTypes.DESCRIPTION,
  elementTypes.QUOTE_BOX,
  elementTypes.VIDEO,
  elementTypes.AUDIO,
  elementTypes.ABSTRACT,
  elementTypes.IMAGE,
  elementTypes.PHOTO_GALLERY,
  elementTypes.URL,
];

const Box = ({
  children,
  height,
  title,
  subtitle,
  subsubTitle,
  type,
  boxType,
  platformId,
  menuItems,
  onMenuSelect,
  hideEllipsisButton,
  element,
  collapsed,
  updateCollapsed,
  collapsedContent,
  iconComponent,
  boxTopRightContent,
  allowInternalDragDrop,
  direction,
  showArrowIcon,
}) => {
  const [, setEditForm] = useEditFormDialog();
  const isReadOnly = useReadOnly();
  const isSelected = useSelected(element);
  const [checkUserRight] = useCheckUserRight();
  const canSeeSettings = checkUserRight('feature', 'settings');
  const editor = useSlateStatic();
  const showHighlight = !isReadOnly && isSelected;
  const includeAdminConfigureOption = canSeeSettings && availableBlocks.includes(boxType);
  const mItems = [...menuItems, ...(includeAdminConfigureOption ? commonMenuItems : [])];
  const showEllipsis = includeAdminConfigureOption || !hideEllipsisButton;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const forceEditorFocus = useCallback(() => ReactEditor.focus(editor), []);

  const doSelect = useCallback(
    (val) => {
      if (val.action === 'configure') {
        if (!platformId || !boxType) {
          // eslint-disable-next-line no-console
          console.warn(
            `Missing parameters to edit form, platformId: ${platformId}, boxType: ${boxType}`,
          );
        } else {
          setEditForm({
            open: true,
            formRefId: `block#${platformId}#${boxType}`,
          });
        }
      } else {
        onMenuSelect(val);
      }
    },
    [onMenuSelect, setEditForm, platformId, boxType],
  );

  const onDragStart = useCallback((e) => {
    if (!allowInternalDragDrop) {
      e.preventDefault();
      e.stopPropagation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [handleClick, handleDoubleClick] = useMouseClickEvents(forceEditorFocus, updateCollapsed);

  const Arrow = useMemo(() => (collapsed ? ArrowDown : ArrowUp), [collapsed]);

  return (
    <BoxWrapper readOnly={isReadOnly}>
      <ContentWrapper
        showHighlight={showHighlight}
        contentEditable={false}
        collapsed={collapsed}
        height={height}
      >
        <BoxIconWrapper
          type={type}
          role="presentation"
          onDoubleClick={showArrowIcon ? handleDoubleClick : null}
          onClick={handleClick}
        >
          {iconComponent}
          {showArrowIcon && (
            <ArrowIconWrapper type={type}>
              <Arrow type={type} onClick={handleDoubleClick} className="skipOverride" />
            </ArrowIconWrapper>
          )}
        </BoxIconWrapper>
        <MainContentWrapper>
          <EllipsisWrapper collapsed={collapsed} subtitle={subtitle}>
            <TitleWrapper>
              <Title>
                {title} {subsubTitle && <SubSubTitle>({subsubTitle})</SubSubTitle>}
              </Title>
              {subtitle && !collapsed && <Subtitle>{subtitle}</Subtitle>}
            </TitleWrapper>
            <TopRightContentWrapper collapsed={collapsed}>
              {boxTopRightContent && <TopRightContent>{boxTopRightContent}</TopRightContent>}
              {showEllipsis && <Menu collapsed={collapsed} items={mItems} onSelect={doSelect} />}
            </TopRightContentWrapper>
          </EllipsisWrapper>
          {collapsed ? (
            <CollapsedContent collapsed={collapsedContent}>
              {collapsedContent || 'No content...'}
            </CollapsedContent>
          ) : (
            <div draggable onDragStart={onDragStart} dir={direction}>
              {children}
            </div>
          )}
        </MainContentWrapper>
      </ContentWrapper>
    </BoxWrapper>
  );
};

Box.propTypes = {
  type: PropTypes.oneOf(['default', 'media', 'error', 'iscoverphoto', 'feed']),
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      action: PropTypes.string,
      icon: PropTypes.element,
    }),
  ),
  onMenuSelect: PropTypes.func,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
  hideEllipsisButton: PropTypes.bool,
  collapsed: PropTypes.bool,
  updateCollapsed: PropTypes.func,
  collapsedContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  direction: PropTypes.oneOf(['auto', 'ltr', 'rtl']),
  showArrowIcon: PropTypes.bool,
  subtitle: PropTypes.string,
  subsubTitle: PropTypes.string,
  boxType: PropTypes.string,
  platformId: PropTypes.string,
  boxTopRightContent: PropTypes.string,
  allowInternalDragDrop: PropTypes.bool,
};

Box.defaultProps = {
  type: 'default',
  menuItems: [],
  onMenuSelect: () => {},
  height: null,
  title: '',
  hideEllipsisButton: false,
  collapsed: false,
  updateCollapsed: () => {},
  collapsedContent: '',
  direction: 'auto',
  showArrowIcon: true,
  allowInternalDragDrop: false,
  subtitle: '',
  boxType: '',
  subsubTitle: '',
  boxTopRightContent: '',
  platformId: '',
};

export default memo(Box);
