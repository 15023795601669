import { useMemo } from 'react';

import useGetFieldsForBlock from 'hooks/useGetFieldsForBlock';
// eslint-disable-next-line sort-imports
import { MetadataFormFieldType, MMetaDataField } from 'types/graphqlTypes';

const getMetadata = (metadata: MMetaDataField[]) => (field: MetadataFormFieldType) =>
  metadata?.find((meta) => meta.key === field.id) || {
    key: field.id,
    value: field.value,
  };

const usePublishMetadata = (mMetaData: MMetaDataField[]) => {
  const [getFieldsForBlock] = useGetFieldsForBlock();

  const getPublishMetadata = getMetadata(mMetaData);

  const forcePublishField = getFieldsForBlock('force-publish', {
    id: 'force-publish',
    value: 'false',
    name: 'Force Publish',
    type: 'boolean',
  });

  const silentUpdateField = getFieldsForBlock('update', {
    id: 'silent-update',
    value: 'false',
    name: 'Silent Update',
    type: 'boolean',
  });

  return useMemo(
    () => [getPublishMetadata(forcePublishField), getPublishMetadata(silentUpdateField)],
    [forcePublishField, getPublishMetadata, silentUpdateField],
  );
};

export default usePublishMetadata;
