import { useCallback, useEffect, useMemo, useState } from 'react';

import { ReactComponent as ArrowDown } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_down.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_right.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/systemicons/close_small.svg';
import { ReactComponent as Add } from 'assets/icons/systemicons/plus_small.svg';
import { ReactComponent as PlusSmallCircle } from 'assets/icons/systemicons/plus_ultraSmall_circle.svg';
import { ReactComponent as SortDown } from 'assets/icons/systemicons/sort_down.svg';
import { Button, IconButton } from 'components/buttons';
import Dialog from 'components/dialogs/DialogBuilder';

import {
  ButtonContainer,
  Container,
  InputField,
  NodeBody,
  StyledTreeNode,
  StyledTreeView,
  TreeViewWrapper,
} from '../styled';
import {
  addNode,
  createTree,
  removeNode,
  renameNode,
  revertTree,
  sortTree,
  toggleNodeCollapse,
  // eslint-disable-next-line sort-imports
  TreeNode as TreeNodeProps,
} from '../utils';

const NESTING_LIMIT = 10;

interface Props {
  open: boolean;
  setOpen: (val: boolean) => void;
  treeAlternatives: string[][];
  updateTreeAlternatives: (treeAlts: string[][]) => void;
}

interface TreeAndNodeProps {
  node: TreeNodeProps;
  path: string[];
  handleAddNode: (path: string[]) => void;
  handleRemoveNode: (path: string[]) => void;
  handleRenameNode: (path: string[], newName: string) => void;
  handleCollapse: (path: string[]) => void;
}

function TreeNode({
  node,
  path,
  handleAddNode,
  handleRemoveNode,
  handleRenameNode,
  handleCollapse,
}: Readonly<TreeAndNodeProps>) {
  const [localValue, setLocalValue] = useState(node.value);
  const [localError, setLocalError] = useState<string>('');

  const memoChilds = useMemo(() => node.children, [node.children]);

  const handleValueChange = (value: string) => {
    setLocalValue(value);
    if (value === '') {
      setLocalError("Value can't be empty");
    } else if (value.includes('▸')) {
      setLocalError("'▸' is reserved. Can't be used as value");
    } else {
      setLocalError('');
    }
  };

  const saveNode = () => {
    if (localError?.length) {
      handleValueChange(node.value);
    } else {
      handleRenameNode(path, localValue);
    }
  };

  return (
    <StyledTreeNode>
      <NodeBody>
        {memoChilds.length > 0 && (
          <IconButton
            height={35}
            width={24}
            usage="text"
            onClick={() => handleCollapse(path)}
            tabIndex={-1}
          >
            {node.isCollapsed ? <ArrowRight /> : <ArrowDown />}
          </IconButton>
        )}
        <InputField
          value={localValue}
          variant="filled"
          placeholder="Enter choice name..."
          onChange={(e) => handleValueChange(e.target.value)}
          error={!!localError.length}
          helperText={localError?.length ? localError : undefined}
          fullWidth
          inputProps={{
            onBlur: (e) => e.relatedTarget?.ariaLabel !== path.toString() && saveNode(),
            onKeyDown: (e) => {
              if (e.key === 'Enter') {
                if (e.shiftKey) {
                  handleAddNode(path);
                }
                saveNode();
                e.currentTarget.blur();
              }
            },
          }}
        />
        <IconButton
          title={
            path.length < NESTING_LIMIT ? 'Add nested value' : 'Reached maximum nesting limit of 10'
          }
          ariaLabel={path.toString()}
          height={35}
          width={24}
          usage="text"
          onClick={() => {
            handleAddNode(path);
            saveNode();
          }}
          disabled={path.length >= NESTING_LIMIT || !!localError?.length}
          tabIndex={-1}
        >
          <PlusSmallCircle />
        </IconButton>
        <IconButton
          title="Remove value"
          height={35}
          width={24}
          iconSize={12}
          usage="text"
          onClick={() => handleRemoveNode(path)}
          tabIndex={-1}
        >
          <DeleteIcon />
        </IconButton>
      </NodeBody>
      {!node.isCollapsed &&
        memoChilds.map((child) => (
          <StyledTreeView key={path.join('_') + '_' + child.value}>
            <TreeNode
              node={child}
              path={[...path, child.value]}
              handleAddNode={handleAddNode}
              handleRemoveNode={handleRemoveNode}
              handleRenameNode={handleRenameNode}
              handleCollapse={handleCollapse}
            />
          </StyledTreeView>
        ))}
    </StyledTreeNode>
  );
}

function EditTreeChoiceDialog({
  open,
  setOpen,
  treeAlternatives,
  updateTreeAlternatives,
}: Readonly<Props>) {
  const [tree, setTree] = useState<TreeNodeProps[]>(createTree(treeAlternatives));

  useEffect(() => {
    setTree(createTree(treeAlternatives));
  }, [treeAlternatives]);

  const onClose = useCallback(() => {
    updateTreeAlternatives(treeAlternatives);
    setOpen(false);
  }, [updateTreeAlternatives, treeAlternatives]);

  const onDone = useCallback(() => {
    const arr = revertTree(tree);
    updateTreeAlternatives(arr);
    setOpen(false);
  }, [updateTreeAlternatives, tree]);

  const handleSortTree = useCallback(() => {
    const updatedTree = sortTree(tree);
    setTree(updatedTree);
  }, [tree]);

  const handleAddNode = useCallback(
    (path: string[]) => {
      const updatedTree = addNode(tree, path);
      setTree(updatedTree);
    },
    [tree],
  );

  const handleRemoveNode = useCallback(
    (path: string[]) => {
      const updatedTree = removeNode(tree, path);
      setTree(updatedTree);
    },
    [tree],
  );

  const handleRenameNode = useCallback(
    (path: string[], newName: string) => {
      const updatedTree = renameNode(tree, path, newName);
      setTree(updatedTree);
    },
    [tree],
  );

  const handleCollapse = useCallback(
    (path: string[]) => {
      const updatedTree = toggleNodeCollapse(tree, path);
      setTree(updatedTree);
    },
    [tree],
  );

  return (
    <Dialog open={open} onClose={onClose} style={{ minWidth: '600px', maxWidth: '800px' }}>
      <Dialog.Header>Configure Tree Choice</Dialog.Header>
      <Dialog.Body>
        <Container>
          <ButtonContainer>
            <Button
              width={90}
              height={32}
              variant="outlined"
              usage="outlined"
              onClick={() => handleAddNode([])}
              title="Add root choice"
            >
              <Add className="skipOverride" />
              Add
            </Button>
            <Button
              width={90}
              height={32}
              variant="outlined"
              usage="outlined"
              title="Sort tree"
              onClick={handleSortTree}
            >
              <SortDown className="skipOverride" />
              Sort
            </Button>
          </ButtonContainer>
          <TreeViewWrapper>
            {tree.map((child) => (
              <StyledTreeView key={child.value}>
                <TreeNode
                  node={child}
                  path={[child.value]}
                  handleAddNode={handleAddNode}
                  handleRemoveNode={handleRemoveNode}
                  handleRenameNode={handleRenameNode}
                  handleCollapse={handleCollapse}
                />
              </StyledTreeView>
            ))}
          </TreeViewWrapper>
        </Container>
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton />
        <Dialog.ConfirmButton label="Confirm" onClick={onDone} />
      </Dialog.Footer>
    </Dialog>
  );
}

export default EditTreeChoiceDialog;
