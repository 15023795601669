import gql from 'graphql-tag';

export default gql`
  query GetConversationsOfUser($input: ConversationsOfUserInput) {
    getConversationsOfUser(input: $input) {
      convoType
      mAssignedMembers {
        mId
        mType
      }
      mCreatedAt
      mCreatedById
      mId
      mRefId
      mTitle
      mType
      mUpdatedAt
      mUpdatedById
      convoLeaveOption
    }
  }
`;
