import styled from '@emotion/styled';

import { ReactComponent as Padlock } from 'assets/icons/systemicons/padlock_on.svg';
import Dialog from 'components/dialogs/DialogBuilder';
import Infobar from 'components/infobar';
import Text from 'components/text';
import { HStack, VStack } from 'layouts/box/Box';
import { MemberType, MemberTypeEnum } from 'types/graphqlTypes';
import { getPlatformIcon } from 'utils/instance/platform';

import { SelectedIndicator } from './styled';

const StyledLockedIcon = styled(Padlock)`
  height: 16px;
  width: 16px;
  justify-self: flex-end;
  path {
    fill: ${({ theme }) => theme.palette.dina.statusWarning};
  }
`;

const StyledHStack = styled(HStack)`
  background-color: ${({ theme }) => theme.palette.dina.blackHoverOverlay};
`;

const StyledList = styled('ul')`
  margin: 0px;
  width: 100%;
  padding: 12px;
  padding-inline-start: 30px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const StyledListItem = styled('li')`
  ::marker {
    color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  }
`;

interface ListItemProps {
  item: MemberType;
}

function SimpleListItem({ item }: Readonly<ListItemProps>) {
  const platform = item?.mProperties?.platform as string;
  const platformKind = item.mProperties?.platformKind as string;
  const PlatformIcon = getPlatformIcon(platform, platformKind);

  return (
    <StyledListItem>
      <StyledHStack
        gap="4px"
        justifyContent="flex-start"
        padding="4px 8px"
        borderRadius="4px"
        width="100%"
      >
        {item.mType === MemberTypeEnum.Instance && <PlatformIcon />}
        <Text variant="listItemLabel" color="highEmphasis" truncate>
          {item.mTitle}
        </Text>
        {item.locked && <StyledLockedIcon className="skipOverride" />}
      </StyledHStack>
    </StyledListItem>
  );
}

interface ReviewFailedItemsProps {
  failedItemProps: MemberType[];
}
export function ReviewFailedItems({ failedItemProps }: Readonly<ReviewFailedItemsProps>) {
  return (
    <>
      <Dialog.Header>Move Failed</Dialog.Header>
      <Dialog.Body>
        <VStack gap="8px">
          <Infobar color="highEmphasis" background="errorBackground" border="statusError">
            Failed to move following items:
          </Infobar>
          <StyledList>
            {failedItemProps.map((item) => item && <SimpleListItem key={item.mId} item={item} />)}
          </StyledList>
        </VStack>
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton autoFocus label="Close" />
      </Dialog.Footer>
    </>
  );
}

interface IndicatorProps {
  totalCount: number;
  selectionCount: number;
}
export function Indicator({ totalCount, selectionCount }: Readonly<IndicatorProps>) {
  return (
    <SelectedIndicator width="100%" height="28px">
      <Text variant="caption" color="mediumEmphasis">
        {`${selectionCount} of 
                ${totalCount} selected`}
      </Text>
    </SelectedIndicator>
  );
}
