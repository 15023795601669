import { useCallback } from 'react';
import insertParagraph from 'components/editor/components/paragraph/utils/insertParagraph';
import { Transforms } from 'slate';
import { useSlate, ReactEditor } from 'slate-react';

import getDropPath from './getDropPath';

const { findPath, focus } = ReactEditor;
const { select, removeNodes, insertNodes } = Transforms;

const useDragEnd = () => {
  const editor = useSlate();

  const onDragEnd = useCallback(
    (source, destination) => {
      const sourcePath = findPath(editor, source);
      const destinationPath = findPath(editor, destination);

      if (Array.isArray(destinationPath)) {
        const dropPath = getDropPath(sourcePath, destinationPath);
        if (dropPath.length === 0 || JSON.stringify(sourcePath) === JSON.stringify(dropPath))
          return;

        removeNodes(editor, { at: sourcePath });

        if (dropPath[0] === editor.children.length) {
          insertParagraph(editor, { at: dropPath, mode: 'highest' });
        }

        insertNodes(editor, source, { at: dropPath });
        select(editor, dropPath);
        focus(editor);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return [onDragEnd];
};

export default useDragEnd;
