import PropTypes from 'prop-types';
import Scrollbar from 'components/scrollbar/scrollbar';
import { RootWrapper } from './menu-styled';
import Groups from './groups';
import Users from './users';

const Menu = (props) => {
  const { groupPolicies } = props;

  return (
    <RootWrapper>
      <Scrollbar>
        <Groups groupPolicies={groupPolicies} />
        <Users />
      </Scrollbar>
    </RootWrapper>
  );
};

Menu.propTypes = {
  groupPolicies: PropTypes.array,
};

Menu.defaultProps = {
  groupPolicies: [],
};

export default Menu;
