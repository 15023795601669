const inReviewState = '03-in-review';
const isApproved = (mState) => mState === '04-ready';

const restrictedPlatformProps = (platformName) => {
  const mProperties = {
    __typename: 'PlatformType',
    provider: {
      message: `Publishing to ${platformName} is restricted, needs approval.`,
    },
  };
  return mProperties;
};

export { isApproved, inReviewState, restrictedPlatformProps };
