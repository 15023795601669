import { useEffect, useRef, useState } from 'react';

interface RectResult {
  bottom: number;
  height: number;
  left: number;
  right: number;
  top: number;
  width: number;
}

const useRect = <T extends HTMLElement>() => {
  const ref = useRef<T | null>(null);
  const [rect, setRect] = useState<RectResult | null>();

  const set = () => setRect(ref?.current ? ref.current.getBoundingClientRect() : null);

  const useEffectInEvent = (event: 'resize' | 'scroll', useCapture: boolean) => {
    useEffect(() => {
      set();
      window.addEventListener(event, set, useCapture);
      return () => window.removeEventListener(event, set, useCapture);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  };

  useEffectInEvent('resize', false);

  return [rect, ref] as const;
};

export default useRect;
