/* eslint-disable import/prefer-default-export */
import { atom, useAtom } from 'jotai';

export type UploadingFiles = {
  mId?: string;
  mRefId?: string;
  mediaType?: string;
  fileName?: string;
  thumbBlob?: string;
  fileSelected?: boolean;
  storyId?: string;
};

/** Uploading files array, temp storage of file under upload so we can show a spinner */
const uploadingFilesAtom = atom<UploadingFiles[]>([]);

const uploadingItemsAtom = atom(
  (get) => get(uploadingFilesAtom),
  (get, set, { storyId, mediaType, fileName, mRefId, ...rest }: UploadingFiles) => {
    const uploadingArray = get(uploadingFilesAtom);

    const filtered = uploadingArray.filter((i) => i.fileName !== fileName);
    if (filtered.length === uploadingArray.length)
      filtered.push({ storyId, mediaType, fileName, mRefId, ...rest });

    set(uploadingFilesAtom, filtered);
  },
);

export const useUploadingFiles = () => useAtom(uploadingItemsAtom);
