export enum WIDGETS {
  TASKS = 'Tasks',
  FEED = 'Feed',
  ASSETS = 'Assets',
  MIMIR = 'Mimir',
  SEARCH = 'Search',
  IFRAME = 'Web URL',
  KANBAN = 'Kanban',
  STORYGRID = 'Grid deck',
  SEARCH_PLUGIN = 'Search plugin',
  NOTE = 'Note',
  TABLE = 'Data table',
}
