import { memo, useCallback } from 'react';

import { getDefaultLayoutSettings } from 'components/editMdfDialog/utils';
import { useEditFieldValueDialog } from 'features/mdf/mdf-utils';

import { CellProps } from '../fields';

import { Link } from './LinkDefault';

function LinkCell({
  fieldModel,
  fieldSettings,
  mdf,
  setValue,
  value,
  disableEdit,
}: Readonly<CellProps>) {
  const [, showEditFieldDialog] = useEditFieldValueDialog();
  const { hint } = fieldSettings ?? getDefaultLayoutSettings(fieldModel);
  const showFieldEditor = useCallback(() => {
    if (!disableEdit) {
      showEditFieldDialog({
        startValue: value,
        fieldId: fieldModel.fieldId,
        headerText: `Edit ${fieldSettings?.label ?? fieldModel.fieldId}`,
        viewType: 'default',
        mdf,
        onConfirm: (v) => setValue(v),
      });
    }
  }, [showEditFieldDialog, disableEdit, mdf, fieldModel, fieldSettings, value]);

  return <Link url={value as string} hint={hint} onEditClick={showFieldEditor} />;
}

export default memo(LinkCell);
