import styled from '@emotion/styled/macro';

import { Button } from '@material-ui/core';

export const ButtonWrapper = styled('div')`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  padding-left: 6px;
  border-bottom: 0.5px solid ${({ theme }) => theme.palette.dina.buttonBorderOutlined};
`;

export const QuickSelectButton = styled(Button)`
  ${({ theme }) => theme.typography.dina.button}
  width: 95px;
  border-radius: 4px;
  height: 32px;
  text-transform: none;
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  background-color: ${({ theme }) => theme.palette.dina.buttonOutlined};
  border: 0.5px solid ${({ theme }) => theme.palette.dina.buttonBorderOutlined};
  margin: 0 2px;
  :focus {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.palette.dina.onFocus};
  }

  :hover {
    background-color: ${({ theme }) => theme.palette.dina.buttonBackgroundOutlinedOnHover};
  }
`;
