import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const StyledRegularContent = styled('div')`
  ${({ theme }) => css`
    ${theme.typography.dina.caption};
  `}
  margin: 4px 8px 8px 8px;
  line-height: 15px;
  overflow-wrap: break-word;
`;

export const HighlightedText = styled('span')`
  ${({ theme }) => css`
    ${theme.typography.dina.captionMedium}
    color: ${theme.palette.dina.highEmphasis}
  `};
  margin-right: 4px;
`;
