import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const RootWrapper = styled('div')`
  width: 100%;
  flex-shrink: 0;
  user-select: none;
  overflow-y: auto;
`;

export const DetailsHeader = styled('div')`
  ${({ theme }) => css`
    ${theme.typography.dina.h6};
    border-bottom: 1px solid ${theme.palette.dina.dividerLight};
  `};
  background-color: ${({ theme }) => theme.palette.dina.headerFooterOverlay};
  padding: 8px;
`;

export const LoadingSpinner = styled('img')`
  height: 36px;
  width: 36px;
`;

export const ChoiceGroup = styled('li')`
  list-style: none;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

export const ChoiceGroupHeader = styled('div')`
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.palette.dina.headerFooterOverlay};
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

export const ChoiceList = styled('ul')`
  margin: 0;
  padding: 0 0 8px 0;
`;

export const SubChoiceList = styled('ul')`
  margin: 0;
  padding: 0;
`;

export const ChoiceItem = styled('li')`
  list-style: none;
  padding: 0 16px;
  line-height: 32px;
`;

export const SubChoiceItem = styled('li')`
  list-style: none;
  padding: 0 16px 0 26px;
  line-height: 32px;
`;
