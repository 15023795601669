import { distanceInWordsStrict } from 'date-fns';

const DistanceInWords = oldDate => {
  const timeStr = distanceInWordsStrict(oldDate, new Date());

  if (timeStr.includes('second')) return 'Just now';

  return timeStr;
};

export default DistanceInWords;
