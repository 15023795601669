import React from 'react';
import { Typography } from '@material-ui/core';
import ribbon from 'assets/icons/systemicons/ribbon.svg';
import ThumbnailComponent from 'components/thumbnail';
import { ReactComponent as GraphicsIcon } from 'assets/icons/systemicons/rundown/secondary_graphic.svg';
import { ReactComponent as MosIcon } from 'assets/icons/systemicons/rundown/mos.svg';
import useStyles from './thumbnail-styles';

const Thumbnail = (props) => {
  const { image, variant, isNew, duration, onPlay } = props;
  const classes = useStyles({ image, variant });

  const middleIconSelector = (type) => {
    switch (type) {
      case 'video':
        return (
          <ThumbnailComponent
            duration={duration}
            usage="scratchPad"
            width={132}
            height={64}
            type="video"
            videoThumbnailImage={image}
            onPlay={onPlay}
            hideCloseButton
          />
        );
      case 'mos':
        return image ? (
          <ThumbnailComponent
            usage="scratchPad"
            width={132}
            height={64}
            type="mos"
            src={image}
            hideCloseButton
          />
        ) : (
          <MosIcon className={classes.graphics} />
        );
      default:
        return <GraphicsIcon className={classes.graphics} />;
    }
  };

  return (
    <div className={classes.root}>
      {middleIconSelector(variant)}
      {isNew && (
        <div>
          <img src={ribbon} alt="New" className={classes.newRibbon} />
          <Typography classes={{ root: classes.newText }}>NEW</Typography>
        </div>
      )}
    </div>
  );
};

export default Thumbnail;
