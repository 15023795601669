import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    '& path': {
      fill: theme.palette.dina.iconSelectedWhite,
      'fill-opacity': 0.54,
    },
    '&:hover': {
      '& path': {
        fillOpacity: 1,
      },
    },
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  itemTitle: {
    ...theme.typography.dina.overline,
    padding: '10px 16px',
  },
  fontSizeContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 10px 10px 12px',
    marginBottom: '4px',
    borderBottom: `1px solid ${theme.palette.dina.dividerLight}`,
  },
  radioContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 18px 6px',
  },
  radioButton: {
    display: 'flex',
    alignItems: 'center',
    margin: '6px 0px',
    cursor: 'pointer',
  },
  radioText: {
    ...theme.typography.dina.listItemLabel,
    margin: '0px 10px',
  },
  ellipsisButton: {
    background: 'transparent',
    '& path': {
      fill: theme.palette.dina.whiteHighEmphasis,
      fillOpacity: 0.54,
    },
    '&:hover': {
      background: 'transparent',
      '& > :first-child': {
        '& > :first-child': {
          '& path': {
            fill: theme.palette.dina.whiteHighEmphasis,
            fillOpacity: 1,
          },
        },
      },
    },
  },
  switchText: {
    ...theme.typography.dina.captionRegular,
  },
  betaText: {
    ...theme.typography.dina.listItemLabel,
    color: theme.palette.dina.statusWarning,
  },
}));

export default useStyles;
