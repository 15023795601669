/* eslint-disable max-len */
import { elementTypes } from 'components/editor/constants';
import { ReactComponent as VideoIcon } from 'assets/icons/systemicons/editor/video_small.svg';
import { ReactComponent as PhotoIcon } from 'assets/icons/systemicons/editor/photo_small.svg';
import { ReactComponent as GifIcon } from 'assets/icons/systemicons/editor/gif_small.svg';

const { GIF, IMAGE, VIDEO } = elementTypes;

const iconComponents = {
  [GIF]: GifIcon,
  [IMAGE]: PhotoIcon,
  [VIDEO]: VideoIcon,
};

export default iconComponents;
