import { ReactComponent as Error } from 'assets/icons/systemicons/error_alt_small_selected_on.svg';
import { ReactComponent as Info } from 'assets/icons/systemicons/info_alt_small_selected_on.svg';
import { ReactComponent as Success } from 'assets/icons/systemicons/ready_small_selected_off.svg';

import useToast from './useToast';

import {
  IconWrapper,
  StyledClose,
  StyledContent,
  StyledDescription,
  StyledProvider,
  StyledTitle,
  StyledToastRoot,
  StyledViewPort,
} from './styled';

export const ToastTypeIcons: Record<
  'info' | 'error' | 'success',
  React.FC<React.SVGProps<SVGSVGElement>>
> = {
  info: Info,
  error: Error,
  success: Success,
};

export function ToastComponent() {
  const { toasts } = useToast();
  return (
    <StyledProvider swipeDirection="right">
      {toasts.map((t) => {
        const Icon = ToastTypeIcons[t.type];
        return (
          <StyledToastRoot key={t.id} onOpenChange={t.setOpen}>
            <IconWrapper>
              <Icon className="skipOverride" />
            </IconWrapper>
            <StyledContent>
              <StyledTitle>{t.title}</StyledTitle>
              {t.description && <StyledDescription>{t.description}</StyledDescription>}
            </StyledContent>
            <StyledClose>Dismiss</StyledClose>
          </StyledToastRoot>
        );
      })}
      <StyledViewPort />
    </StyledProvider>
  );
}
