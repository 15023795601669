import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Icon from 'components/icon/icon-view';
import Tooltip from 'components/tooltip';
import ICONS from 'assets/icons/icons';
import useStyles from './ellipsisButton-styles';

const EllipsisButton = ({ onClick, hidden, padding, IconComponent, title, ...rest }) => {
  const classes = useStyles({ padding });

  return (
    <Tooltip title={title || "More options"}>
      <IconButton
        style={{ display: hidden ? 'none' : '' }}
        classes={{ root: classes.buttonRoot }}
        {...{ onClick }}
        {...rest}
      >
        {IconComponent || <Icon icon={ICONS.MORE_VERTICAL} height={16} width={4} />}
      </IconButton>
    </Tooltip>
  );
};

EllipsisButton.propTypes = {
  /** Callback to be invoked on vertical ellipsis menu button click */
  onClick: PropTypes.func,
  /** optional padding prop to ellipsis button */
  padding: PropTypes.string,
  /** hide the ellipsis button */
  hidden: PropTypes.bool,
  IconComponent: PropTypes.node,
  title: PropTypes.string,
};

EllipsisButton.defaultProps = {
  onClick: () => {},
  padding: '12px',
  hidden: false,
  IconComponent: null,
};

export default EllipsisButton;
