import { EditorValue } from 'types';
import useLogger from 'utils/useLogger';

const parseContent = (
  logger: ReturnType<typeof useLogger>,
  mContent?: string | null,
): {
  parsedContent?: EditorValue;
  parsedError?: string;
} => {
  try {
    return {
      parsedContent: JSON.parse(mContent ?? '{}') as EditorValue,
    };
  } catch (error) {
    logger.error(error as Error);
    return {
      parsedError: 'Invalid message content',
    };
  }
};

export default parseContent;
