import { gql } from 'graphql-tag';

export const GET_SPACE_CONTACTS = gql`
  query GetSpaceContacts($input: GetMemberInput) {
    getMembers(input: $input) {
      mId
      mRefId
      mType
      mAvatarUrl
      mTitle
    }
  }
`;

export const CREATE_SPACE_CONTACT = gql`
  mutation CreateSpaceContact($input: CreateMemberInput) {
    createMember(input: $input) {
      mId
      mRefId
      mType
    }
  }
`;

export const CREATE_ORDER_FORM = gql`
  mutation CreateSpaceForm($input: UpdateFormInput) {
    updateForm(input: $input) {
      mTitle
      mId
      mRefId
      mRefTitle
      mType
      mProperties {
        __typename
        ... on MetadataFormType {
          types
          fields {
            id
            name
            type
            label
            function
            defaultValue
            options {
              id
              title
              value
              icon
            }
          }
        }
      }
    }
  }
`;

export const GET_ORDER_FORMS = gql`
  query GetOrderForms($input: GetMemberInput) {
    getMembers(input: $input) {
      mTitle
      mId
      mRefId
      mRefTitle
      mType
      mProperties {
        __typename
        ... on MetadataFormType {
          types
          fields {
            id
            name
            type
            label
            description
            defaultValue
            options {
              id
              title
              value
              icon
            }
          }
        }
      }
    }
  }
`;

export const DELETE_MEMBER = gql`
  mutation DeleteMember($input: DeleteSingleMemberInput) {
    deleteSingleMember(input: $input) {
      mId
      mRefId
    }
  }
`;

const commonViewReturnFields = `
  mId
  mRefId
  mTitle
  mType
  mState
  mCreatedAt
  mOrder
  mProperties {
    __typename
  }
  metadata
  mUpdatedAt
  mMetaData {
    key
    value
  }
  configs {
    key
    value
  }
  permissions {
    read {
      name
      groups
      __typename
    }
    write {
      name
      groups
      __typename
    }
    __typename
  }
  __typename
`;

export const GET_VIEWS = gql`
  query GetSpaceViews($input: GetMemberInput) {
    getMembers(input: $input) {
      ${commonViewReturnFields}
    }
  }
`;

export const VIEW_SUBSCRIPTION = gql`
  subscription notifyMemberUpdate($mIdSubscribed: String!) {
    notifyMemberUpdateSubscription(mIdSubscribed: $mIdSubscribed) {
      ${commonViewReturnFields}
      crudAction
      locked
      mContentKey
      mUpdatedAt
      mUpdatedById
    }
  }
`;

export const CREATE_VIEW = gql`
  mutation CreateView($input: CreateMemberInput) {
    createMember(input: $input) {
      ${commonViewReturnFields}
    }
  }
`;
