/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { Transforms } from 'slate';
import { useSlate, ReactEditor } from 'slate-react';
import findKey from 'lodash/findKey';
import useGetAutomationTemplates from 'hooks/useGetAutomationTemplates';
import DropZone from 'components/editor/components/dropZone';
import getTypeValues from 'components/editor/utils/getTypeValues';
import getDefaultTemplate from 'components/editor/utils/getDefaultTemplate';
import { elementTypes } from 'components/editor/constants/types';

const { secondaryTypes } = elementTypes;
const secondaryTypeValues = getTypeValues(secondaryTypes);

const SecondaryDropZone = ({ children, element }) => {
  const editor = useSlate();

  const { templates } = useGetAutomationTemplates();

  const onDrop = useCallback(
    ({ type }) => {
      const { variant: templateVariant, name, description } = getDefaultTemplate(type, templates);
      const { data } = element;

      const updatedData = {
        ...data,
        templateVariant,
        name,
        description,
        templateType: findKey(secondaryTypes, (value) => value === type),
      };

      const path = ReactEditor.findPath(editor, element);

      Transforms.setNodes(editor, { type, data: updatedData }, { at: path });
      ReactEditor.focus(editor);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  return (
    <DropZone accept={secondaryTypeValues} {...{ onDrop, element }}>
      {children}
    </DropZone>
  );
};

SecondaryDropZone.propTypes = {
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

SecondaryDropZone.defaultProps = {
  children: null,
  element: {
    children: [],
  },
};

export default memo(SecondaryDropZone);
