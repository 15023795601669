import { useRoutes } from 'react-router-dom';

import useToast from 'components/toast/useToast';
import { useAuthContext } from 'contexts/AuthContext';
import {
  AuthStates,
  getAuthState,
  openFederatedSignIn,
  setAuthState,
  UseAuthAudit,
  UseHostedUI,
} from 'features/auth';
import AuthAudit from 'screens/authAudit';
import InitialLoadingScreen from 'screens/loading';
import Login from 'screens/login';

import { protectedRoutes } from './protected';

/**
 * Returns true if the windows href indicates that it is redirected from HostedUI
 * Using presence of code search params to detect this.
 */
const isRedirectedFromHostedUI = () => {
  const url = new URL(window.location.href);
  const code = url.searchParams.get('code');
  return !!code;
};

const invokeSignIn = (authState: string | null) => {
  if (!authState || authState === AuthStates.NOT_VERIFIED) return true;
  return authState === AuthStates.VERIFIED ? false : !isRedirectedFromHostedUI();
};

function AppRoutes() {
  const auth = useAuthContext();
  const routes = auth.user ? protectedRoutes : [];
  const element = useRoutes(routes);
  const { errorToast } = useToast();

  if (UseAuthAudit) return <AuthAudit />;

  if (auth.user) return <>{element}</>;

  if (UseHostedUI) {
    const authState = getAuthState();
    if (!auth.verified && invokeSignIn(authState)) {
      setAuthState(AuthStates.VERIFYING);
      openFederatedSignIn(auth).catch(errorToast);
      return <div />;
    }
    if (authState === AuthStates.VERIFYING) {
      return <InitialLoadingScreen />;
    }
    return <div />;
  }

  if (auth.loading) return <InitialLoadingScreen />;

  return <Login />;
}

export default AppRoutes;
