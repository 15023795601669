import { useSelectedGroupCategoryAtom } from 'screens/main/components/header/navbar/settings/atoms';
import GROUP_SETTING_TYPES from 'screens/main/components/header/navbar/settings/utils/groupSettingTypes';
import { GroupPropType } from 'types/groupPermissions/permissions';

import Users from './users/Users';
import Group from './group';

import RootWrapper from './styled';

const Details = (props: GroupPropType) => {
  const { groupPolicies, instanceTypes, kanbanStates } = props;
  const [category] = useSelectedGroupCategoryAtom();
  const getComponent = () => {
    switch (category) {
      case GROUP_SETTING_TYPES.GROUP:
        return (
          <Group
            groupPolicies={groupPolicies}
            instanceTypes={instanceTypes}
            kanbanStates={kanbanStates}
          />
        );
      case GROUP_SETTING_TYPES.USERS:
        return <Users groupPolicies={groupPolicies} />;
      default:
        return <div />;
    }
  };

  return <RootWrapper>{getComponent()}</RootWrapper>;
};

export default Details;
