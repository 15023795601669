// Creates default view in defaultViews in mPropeties
const createDefaultView = (mTitle) => ({
  defaultView: `${mTitle}-instance-metadata-1`,
  type: `${mTitle}-instance-metadata`,
  variant: 'default',
  __typename: 'MetadataFormDefaultViewType',
});

// Creates view in views in mPropeties
const createView = (views, mTitle, instanceViewId) => {
  const instanceMetadataView = views.find((view) => view.id === 'instance-metadata-1');
  const instanceMetadataViewColumns = instanceMetadataView?.columndefs?.[0]?.columns;
  return {
    columndefs: [
      {
        columns: [...instanceMetadataViewColumns],
        variant: 'default',
        __typename: 'MetadataFormColumnDefType',
      },
    ],
    id: instanceViewId,
    name: `${mTitle}-instance-metadata`,
    type: `${mTitle}-instance-metadata`,
    __typename: 'MetadataFormViewType',
  };
};

const isViewExistsForInstanceType = (instanceViewId, changedInstanceFieldsRows, views) => {
  const changedFieldsInInstanceType = changedInstanceFieldsRows.find(
    (changedInstanceFieldsRow) => changedInstanceFieldsRow.viewId === instanceViewId,
  );
  const viewExits = views.find((view) => view.id === instanceViewId);
  // If there are changes in instance type and view does not exits for that instance type
  if (!viewExits && changedFieldsInInstanceType) return false;
  return true;
};

const addViewsInForm = (changedInstanceFieldsRows, form, instanceTypes) => {
  const { views, defaultViews } = form?.mProperties;
  const updatedViews = [...views];
  const updatedDefaultViews = [...defaultViews];
  instanceTypes.forEach((instanceType) => {
    const { mTitle } = instanceType;
    const instanceViewId = `${mTitle}-instance-metadata-1`;
    const isViewExists = isViewExistsForInstanceType(
      instanceViewId,
      changedInstanceFieldsRows,
      views,
    );
    if (!isViewExists) {
      updatedViews.push(createView(views, mTitle, instanceViewId));
      updatedDefaultViews.push(createDefaultView(mTitle));
    }
  });
  return { views: updatedViews, defaultViews: updatedDefaultViews };
};

const getUpdatedViewsInForm = (changedInstanceFieldsRows, instanceTypes, form) => {
  // Get upated views and defaultViews in mProperties of form
  const { views, defaultViews } = addViewsInForm(changedInstanceFieldsRows, form, instanceTypes);
  // Add and remove fields from the views.
  const updatedViews = views.map((view) => {
    const columndefs = view?.columndefs?.[0];
    const changedColumnsOfTheView = changedInstanceFieldsRows.filter(
      (changedInstanceFieldsRow) => changedInstanceFieldsRow.viewId === view.id,
    );
    if (changedColumnsOfTheView.length === 0) {
      return view;
    }
    const updatedColumns = [];
    columndefs?.columns.forEach((column) => {
      const found = changedColumnsOfTheView.find(
        (changedColumn) => changedColumn.fieldId === column.id,
      );
      if (!found) return updatedColumns.push(column);
      if (found?.displayValue) {
        return updatedColumns.push({ ...column, name: found.displayValue || null });
      }
      return updatedColumns;
    });

    changedColumnsOfTheView.forEach((changedColumn) => {
      const changedFieldExists = updatedColumns?.find(
        (column) => column.id === changedColumn.fieldId,
      );
      if (!changedFieldExists && changedColumn.checked) {
        updatedColumns.push({
          id: changedColumn.fieldId,
          columnId: `${view.id}:${columndefs.variant}:${changedColumn.fieldId}`,
          name: changedColumn.displayValue || null,
          __typename: 'MetadataFormViewColumnType',
        });
      }
    });
    return { ...view, columndefs: [{ ...columndefs, columns: updatedColumns }] };
  });
  return { views: updatedViews, defaultViews };
};

export default getUpdatedViewsInForm;
