import { memo, useMemo } from 'react';

import { ProviderName } from 'api/providers/provider-types';
import { useGetProvider } from 'api/providers/useGetChannelProvider';
import loadingSpinnerSrc from 'assets/images/loadingSpinner/dina-loader-anpng-60frames.png';
import useToast from 'components/toast/useToast';
import { VStack } from 'layouts/box/Box';
import { useSelectedUserSettingAtom } from 'screens/main/components/header/navbar/settings/atoms';
import { useUserConfig } from 'store';

import NotificationProviderList from './NotificationProviderList';

import { DetailsHeader, LoadingSpinner, RootWrapper } from './styled';

enum UserSettingTypes {
  PROFILE = 'profile',
  USER_TOUR = 'user_tour',
  THEME = 'theme',
  NOTIFICATION = 'notification',
  NOTIFICATION_GENERAL = 'notification-general',
  NOTIFICATION_SLACK = 'notification-slack',
  NOTIFICATION_EMAIL = 'notification-email',
}

const getProviderNameFromSelection = (
  selectedUserSetting: UserSettingTypes,
): {
  label: string;
  providerName: ProviderName;
} => {
  if (selectedUserSetting === UserSettingTypes.NOTIFICATION_EMAIL) {
    return {
      label: 'E-mail',
      providerName: 'SES',
    };
  }
  // We assume that the default provider is Slack
  return {
    label: 'Slack',
    providerName: 'slack',
  };
};

const NotificationProvider = () => {
  const [selectedUserSetting] = useSelectedUserSettingAtom();
  const [user] = useUserConfig();

  const { errorToast } = useToast();

  const selectedProvider = useMemo(
    () => getProviderNameFromSelection(selectedUserSetting as UserSettingTypes),
    [selectedUserSetting],
  );
  const { provider, loading, error } = useGetProvider(user.mId, selectedProvider.providerName);

  if (loading)
    return (
      <VStack justifyContent="center" width="100%">
        <LoadingSpinner src={loadingSpinnerSrc} alt="_loading" />
      </VStack>
    );

  if (error || !provider || !user.mId) {
    errorToast('Failed to load notification provider');
    return <></>;
  }

  return (
    <RootWrapper>
      <DetailsHeader>Notify on {selectedProvider.label} when...</DetailsHeader>
      <NotificationProviderList provider={provider} providerName={selectedProvider.providerName} />
    </RootWrapper>
  );
};

export default memo(NotificationProvider);
