import React, { useState, useContext, useRef, useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import stopAllPropagation from 'components/editor/utils/stopAllPropagation';
import configCtx from 'contexts/configContext';
import ExpandIcon from './components/expandIcon';
import { transitionIcons, options, transitionTypes } from './constants';
import renderSelectIcon from './utils/renderSelectIcon';
import {
  RootWrapper,
  SelectWrapper,
  Input,
  AutocompleteWrapper,
  Autocomplete,
  MenuItem,
  ListItemIcon,
  Select,
  useStyles,
} from './styled';

const { MIX, EFFECT } = transitionTypes;

const TransitionSelect = ({ anchorOrigin, arrowPosition, value, transformOrigin, update }) => {
  const containerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { type: transitionType, value: transitionValue, name } = value;
  const classes = useStyles({ anchorOrigin });
  const { automationTemplateConfigs } = useContext(configCtx);

  const { effects } = automationTemplateConfigs[0] ? automationTemplateConfigs[0].resources : [];

  const openSelectMenu = useCallback(() => setIsOpen(true), []);
  const closeSelectMenu = useCallback(() => setIsOpen(false), []);

  const handleSelectionChange = useCallback(
    ({ target: { value: type } }) => update(options.find((item) => item.type === type)),
    [update],
  );

  const handleInputUpdate = useCallback(
    (newTransitionValue) => {
      update({
        ...value,
        value: newTransitionValue,
      });
    },
    [update, value],
  );

  const handleAutocompleteUpdate = useCallback(
    (newEffectTransition) =>
      update({
        ...value,
        ...newEffectTransition,
      }),
    [update, value],
  );

  const onKeyDown = useCallback(
    (event) => {
      stopAllPropagation(event);

      const { key } = event;

      if ((key === 'ArrowUp' || key === 'ArrowDown') && !isOpen) {
        event.preventDefault();
        openSelectMenu();
      }

      if (key === 'Enter') event.preventDefault();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpen],
  );

  const expandIcons = useMemo(
    () => <ExpandIcon icon={transitionIcons[transitionType]} onClick={openSelectMenu} />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [transitionType],
  );

  const selectOptions = useMemo(
    () =>
      options.map(({ type }) => (
        <MenuItem key={type} value={type}>
          <ListItemIcon>{transitionIcons[type]}</ListItemIcon>

          {type}
        </MenuItem>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <RootWrapper ref={containerRef}>
      {arrowPosition === 'start' && expandIcons}
      <SelectWrapper role="presentation" onClick={stopAllPropagation} onKeyDown={onKeyDown}>
        {transitionType === MIX && (
          <Input
            type="input"
            key={`${transitionType}${transitionValue}`}
            arrowPosition={arrowPosition}
            onUpdate={handleInputUpdate}
            value={transitionValue}
          />
        )}

        {transitionType === EFFECT && effects && effects.length > 0 && (
          <AutocompleteWrapper>
            <Autocomplete
              options={effects}
              arrowPosition={arrowPosition}
              selectedOption={{ name, value: transitionValue }}
              onUpdate={handleAutocompleteUpdate}
            />
          </AutocompleteWrapper>
        )}

        <Select
          variant="standard"
          disableUnderline
          fullWidth
          IconComponent={renderSelectIcon}
          MenuProps={{
            classes: {
              paper: classes.menu,
              list: classes.menuList,
            },
            anchorEl: containerRef.current,
            getContentAnchorEl: null,
            anchorOrigin,
            transformOrigin,
            PaperProps: {
              onMouseLeave: closeSelectMenu,
            },
          }}
          onChange={handleSelectionChange}
          onClose={closeSelectMenu}
          onOpen={openSelectMenu}
          open={isOpen}
          value={transitionType}
        >
          {selectOptions}
        </Select>
      </SelectWrapper>

      {arrowPosition === 'end' && expandIcons}
    </RootWrapper>
  );
};

TransitionSelect.propTypes = {
  /** Anchor origin for material ui popover */
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),
  /** Position of the arrow icon */
  arrowPosition: PropTypes.oneOf(['start', 'end']),
  /** Transform origin for material ui popover */
  transformOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),
  /** Callback to be invoked on update */
  update: PropTypes.func,
  value: PropTypes.shape({
    type: PropTypes.string,
    value: PropTypes.string,
  }),
};

TransitionSelect.defaultProps = {
  anchorOrigin: {
    vertical: 'center',
    horizontal: 'left',
  },
  arrowPosition: 'start',
  transformOrigin: {
    vertical: 'center',
    horizontal: 'left',
  },
  update: (newItem) => {},
  value: { type: MIX, value: '' },
};

export default memo(TransitionSelect);
