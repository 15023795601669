import gql from 'graphql-tag';

export default gql`
  mutation CreatePlaceholder($input: CreatePlaceholderInput) {
    createPlaceholder(input: $input) {
      mId
      mRefId
      mTitle
      mProperties {
        __typename
        ... on PlatformType {
          platform
          account {
            accountId
            accountUrl
            accountLogo
            accountTitle
          }
        }
      }
    }
  }
`;
