import { useMutation } from '@apollo/client';
import graphql from 'graphql-tag';

import { PlatformType } from 'types/graphqlTypes';

const UPDATE_PIN = graphql`
  mutation UpdatePin($input: UpdateMemberInput) {
    updateMember(input: $input) {
      mId
      mRefId
      mProperties {
        __typename
        ... on PlatformType {
          platform
          platformKind
          pinned
        }
      }
    }
  }
`;

const getUpdatedMProperties = (
  mProperties: PlatformType | null | undefined,
  newPinnedValue: boolean,
): PlatformType => {
  if (!mProperties)
    return { platform: 'linear', pinned: newPinnedValue, __typename: 'PlatformType' };
  return { ...mProperties, pinned: newPinnedValue };
};

const useToggleFolderPin = () => {
  const [updatePin] = useMutation(UPDATE_PIN);

  const togglePin = async (
    mId: string,
    mRefId: string,
    mProperties: PlatformType,
    newPinnedValue: boolean,
  ) => {
    const input = {
      mId,
      mRefId,
      mProperties: getUpdatedMProperties(mProperties, newPinnedValue),
    };

    return updatePin({
      variables: {
        input,
      },
    });
  };

  return togglePin;
};

export default useToggleFolderPin;
