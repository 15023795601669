/**
 *
 * @param {array} list of objects that have following pattern: {value:'any'}
 * @returns stringy version of values of the list
 *
 */

const stringifyList = (list = []) =>
  list.reduce((acc, current) => `${acc}${acc ? ', ' : ''}${current.value}`, '');

export default stringifyList;
