import { memo } from 'react';
import styled from '@emotion/styled';

import { ReactComponent as AddIcon } from 'assets/icons/systemicons/add_small.svg';
import { AvatarVariant } from 'components/avatar/Avatar';
import { Button } from 'components/buttons';
import Chip from 'components/chip';
import Text from 'components/text/Text';
import transientOptions from 'theme/helpers';
import { AssignedMember } from 'types';
import { MemberType } from 'types/graphqlTypes';

interface ContentWrapperProps {
  $showTopBorder?: boolean;
}
const ContentWrapper = styled('div', transientOptions)<ContentWrapperProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px 12px 12px;
  border-top: 1px solid
    ${({ $showTopBorder = true, theme }) =>
      !$showTopBorder ? theme.palette.dina.dividerLight : 'transparent'};
`;

const ChipContainer = styled('div')`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
`;

interface ChipProps {
  showTopBorder?: boolean;
  title: string;
  variant: 'chip';
  members: MemberType[] | AssignedMember[];
  avatarVariant?: AvatarVariant;
  onDelete?: (members: AssignedMember[]) => void;
  onAdd?: () => void;
}

function ProfileDetails(props: Readonly<ChipProps>) {
  return (
    <ContentWrapper $showTopBorder={props?.showTopBorder}>
      <Text variant="overline" color="mediumEmphasis">
        {props.title}
      </Text>
      {props.variant === 'chip' && (
        <ChipContainer>
          {props.members?.map((member) => (
            <Chip
              key={`${member?.mId as string}`}
              label={member?.mTitle as string}
              avatarKey={member?.mAvatarKey}
              showAvatar={!!props.avatarVariant}
              width="auto"
              height={32}
              labelType="listItemLabel"
              disabled={!props?.onDelete}
              onDelete={
                props?.onDelete
                  ? () => {
                      props.onDelete?.(
                        (props.members as AssignedMember[]).filter((m) => m.mId !== member.mId),
                      );
                    }
                  : undefined
              }
            />
          ))}
          {props.onAdd && (
            <Button
              usage="outlined"
              variant="outlined"
              height={32}
              width={64}
              onClick={props.onAdd}
            >
              <AddIcon />
              Add
            </Button>
          )}
        </ChipContainer>
      )}
    </ContentWrapper>
  );
}

export default memo(ProfileDetails);
