import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';

import { MemberType, UpdateMemberInput } from 'types/graphqlTypes';

const UPDATE_STUDIO = gql`
  mutation updateStudio($input: UpdateMemberInput) {
    updateMember(input: $input) {
      mId
      mRefId
      mType
      mTitle
      mUpdatedAt
      mMetaData {
        key
        value
      }
    }
  }
`;

interface UpdateStudioType {
  updateMember: MemberType;
}

const useUpdateStudio = () => {
  const [updateStudioMutation] = useMutation<UpdateStudioType>(UPDATE_STUDIO);

  const updateStudio = useCallback(
    async (mId: string, mRefId: string, properties: Omit<UpdateMemberInput, 'mId' | 'mRefId'>) => {
      return updateStudioMutation({
        variables: {
          input: {
            mId,
            mRefId,
            ...properties,
          },
        },
      });
    },
    [],
  );

  return [updateStudio] as const;
};

export default useUpdateStudio;
