/* eslint-disable import/no-extraneous-dependencies */
import getMosAsset from 'components/editor/utils/getMosAsset';
import { elementTypes, actionTypes } from 'components/editor/constants/types';
import { ReactEditor } from 'slate-react';
import { Transforms } from 'slate';
import findKey from 'lodash/findKey';
import notifyChange from 'components/editor/utils/notifyChange';
import { mediaTypes } from 'utils/rundownItemTypes';

const { secondaryTypes, properties } = elementTypes;
/**
 * Replaces overlay graphics with new one
 *
 * @param {Object} editor SlateJS editor instance
 * @param {Object} element SlateJS element to be updated
 * @param {Object} payload Data payload of dropped item
 * @param {Function} update Callback to be invoked on update
 * @returns {Object} SlateJS editor instance
 */

const replaceGraphics = async (editor, element, payload, update) => {
  const mosAsset = getMosAsset(payload, mediaTypes.GRAPHICS);
  if (!mosAsset) return;

  mosAsset.mediaType = mediaTypes.SECONDARY_GRAPHICS;

  try {
    const result = await update({
      type: actionTypes.CREATE_ASSET,
      payload: { document: editor.children, asset: mosAsset },
    });

    const asset = result.data.createAssets[0];
    if (!asset) return;

    const { data } = element;
    const { assets: previousAssets, ...rest } = data;

    const updatedData = {
      ...rest,
      templateVariant: mosAsset.title,
      mId: asset.mId,
      mRefId: asset.mRefId,
      mAssetId: asset.mAssetId,
      provider: mosAsset.provider,
      mosobj: payload,
      typeId: properties[element.type].id,
      templateType: findKey(secondaryTypes, (value) => value === element.type),
    };

    const path = ReactEditor.findPath(editor, element);
    Transforms.setNodes(editor, { data: updatedData }, { at: path });

    notifyChange(editor, update);
    ReactEditor.focus(editor);
  } catch (e) {
    // console.error(e)
  }
};

export default replaceGraphics;
