const columns = [
  {
    field: 'email',
    headerName: 'Email',
    sortable: true,
    sortField: 'searchByEmail',
    searchField: 'searchByEmail',
  },
  {
    field: 'status',
    headerName: 'Status',
    sortable: true,
  },
  {
    field: 'groups',
    headerName: 'Groups',
    sortable: true,
    sortField: 'searchByGroup',
    searchField: 'searchByGroup',
  },
  {
    field: 'lastLogin',
    sortField: 'sortByDate',
    headerName: 'Last Login',
    sortable: true,
  },
  {
    field: 'action',
    headerName: 'Actions',
  },
];

export default columns;
