/**
 * Extracts abstract value given mos xml object
 *
 * @param {Object} xmlDoc Pased mos xml object
 * @param {string} tagName Path to the xmlNode object
 * @returns {String} Abstract value
 */

const getMosValue = (xmlDoc, tagName) => {
  const mosValueObject = xmlDoc.querySelector(tagName);
  const value = mosValueObject ? mosValueObject.textContent : null;

  return value;
};

export default getMosValue;
