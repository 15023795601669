/* eslint-disable jsx-a11y/media-has-caption */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import ConfigCtx from 'contexts/configContext';
import { extractObjectKeyValue } from 'utils/objects';

import { ReactComponent as CloseIcon } from 'assets/icons/systemicons/close.svg';
import { IconButton } from '@material-ui/core';
import useStyles from './mediaViewer-styles';

export const Wrapper = ({ children, onClose }) => {
  const classes = useStyles();
  return (
    <div className={classes.imageDiv}>
      <IconButton title="Close" classes={{ root: classes.button }} onClick={onClose}>
        <CloseIcon className={classes.icon} />
      </IconButton>
      {children}
    </div>
  );
};

const MediaViewer = ({ asset, onClose }) => {
  const classes = useStyles();
  const { feeds } = useContext(ConfigCtx);
  const { itemType, mMetaData, mContentUrl } = asset;

  const { provider, proxy, src, videoPreviewUri, uri, previewUri } =
    extractObjectKeyValue(mMetaData, [
      'provider',
      'proxy',
      'src',
      'videoPreviewUri',
      'uri',
      'previewUri',
    ]) || {};

  const fromFeed = feeds.find((f) => f?.mRefId?.toLowerCase() === provider?.toLowerCase());
  const accessToken = fromFeed && fromFeed.accessToken ? fromFeed.accessToken : '';
  const srcUrl = `
    ${mContentUrl || proxy || src || videoPreviewUri || uri || previewUri}${accessToken}`;

  switch (itemType) {
    case 'image':
      return (
        <Wrapper onClose={onClose}>
          <img src={srcUrl} className={classes.image} alt="imageUrl" />
        </Wrapper>
      );
    case 'video':
      return (
        <video controls width="800" height="500" className={classes.video}>
          <source src={srcUrl} type="video/mp4" />
        </video>
      );
    case 'audio':
      return (
        <audio controls className={classes.audio}>
          <source src={srcUrl} type="audio/mpeg" />
        </audio>
      );
    default:
      return <div />;
  }
};

MediaViewer.propTypes = {
  /** asset info for media view */
  asset: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  ),
  onClose: PropTypes.func,
};

MediaViewer.defaultProps = {
  asset: {},
  onClose: () => {},
};

export default MediaViewer;
