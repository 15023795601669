import gql from 'graphql-tag';

export default gql`
  query getMessagesOfConversation(
    $input: GetMessagesOfConversationInput
    $limit: Int
    $nextToken: String
  ) {
    getMessagesOfConversation(input: $input, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        mId
        mRefId
        mType
        mTitle
        mContent
        mCreatedAt
        mUpdatedAt
        mCreatedById
        mUpdatedById
        __typename
        convoType
      }
      nextToken
    }
  }
`;
