/**
 *  Matches string pattern that starts with "@" and is followed by one or more
 *  Unicode letters, digits, or underscores. It ensures that the string contains only
 *  those characters and does not have any characters before or after them.
 *
 * @type {RegExp}
 */
export const beforeTextRegex = /^@([\p{L}0-9_-]+)$/u;

/**
 * Match either the start of the string or a whitespace character.
 * It can be interpreted as checking if the string either begins
 * with whitespace or is empty
 *
 * @type {RegExp}
 */
export const afterTextRegex = /^(\s|$)/;
