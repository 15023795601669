/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
import styled from '@emotion/styled';
import { atom, useAtom } from 'jotai';

const StyledTag = styled('div')`
  display: inline;
  white-space: pre;
`;

export type CommandPathProps = {
  sections: string[];
};

const commandPath = atom<CommandPathProps>({ sections: [] });

export const useCommandPath = () => useAtom(commandPath);

export function CommandPath() {
  const [{ sections }] = useCommandPath();
  return (
    <div>
      {sections
        .filter((p) => p !== 'home')
        .map((p, i) => (
          <StyledTag key={`${p}-${i}`}>{`${p} / `}</StyledTag>
        ))}
    </div>
  );
}
