import { Svg, Rect, Path } from '@react-pdf/renderer';

const CameraIcon = () => {
  return (
    <Svg style={{ width: 32, height: 32, marginRight: 8 }} viewBox='0 0 32 32'>
      <Rect
        x='0.5'
        y='0.5'
        width='31'
        height='31'
        rx='5.5'
        ry='5.5'
        fill='#A0DBAF'
        stroke='black'
      />
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7 8C5.89543 8 5 8.89543 5 10V22C5 23.1046 5.89543 24 7 24H20C21.1046 24 22 23.1046 22 22V18.2274L25.1557 20.7333C25.3713 20.9111 25.6467 21 25.9341 21H27V11H25.9461C25.6587 11 25.3832 11.1 25.1677 11.2667L22 13.7821V10C22 8.89543 21.1046 8 20 8H7Z'
        fill='#0F141A'
        fillOpacity='0.87'
      />
    </Svg>
  );
};

export default CameraIcon;
