import styled from '@emotion/styled';

import { CommandPrimitive } from 'lib/command';

export const StyledCommand = styled(CommandPrimitive)`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.dina.inputBackground};
  height: 100%;
  width: 100%;
  max-height: 70vh;

  // Borders
  border: 1px solid ${({ theme }) => theme.palette.dina.borderResting};
  border-radius: 6px;
  :focus-within {
    border: 1px solid ${({ theme }) => theme.palette.dina.onFocus};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  /** List item */
  & [cmdk-item] {
    height: 26px;
    padding-left: 8px;
    width: 100%;
    ${({ theme }) => theme.typography.dina.listItemLabelMedium};
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background-color: inherit;

    :hover {
      background-color: ${({ theme }) => theme.palette.dina.onHover};
    }

    // First item in the list
    :first-of-type {
      border-top: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
    }

    // Selected with keyboard
    &[aria-selected='true'] {
      background-color: ${({ theme }) => theme.palette.dina.hoverOverlay};
    }

    // Item is marked as selected
    &.selected {
      color: ${({ theme }) => theme.palette.dina.disabled};
    }
  }

  & [cmdk-input-wrapper] {
    height: 32px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  & [cmdk-input] {
    ${({ theme }) => theme.typography.dina.listItemLabelMedium};
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
    background-color: transparent;
    border: none;
    width: 100%;

    ::placeholder {
      color: ${({ theme }) => theme.palette.dina.watermark};
    }

    :focus {
      outline: none;
      border: none;
    }
  }

  /** List */
  & [cmdk-list] {
    position: absolute;
    top: 1px;
    width: 100%;
    max-height: 300px;
    z-index: 3000;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: ${({ theme }) => theme.palette.dina.surfaceDialogs};
    border: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
    border-top: none;
    animation: fadeIn 0.2s;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    overflow: auto;
  }

  & > [cmdk-group]:not([hidden]) ~ [cmdk-group] {
    padding-top: 0;
  }

  & [cmdk-group-heading] {
    padding: 4px 0px 0px 8px;
    color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
    ${({ theme }) => theme.typography.dina.captionMedium};
  }

  & [cmdk-group-items] {
    position: relative;
  }

  & [cmdk-group] {
    border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
    height: 100%;
  }
`;
