import { Document, Page, Text, View } from '@react-pdf/renderer';

import { MdfsSeparated } from 'api/mdfBlocks/types';
import useGetMembersInfo from 'hooks/useGetMembersInfo';
import { OrderWithMdf } from 'types/forms/forms';
import { MemberType, OptionList } from 'types/graphqlTypes';

import CommonDocFooter from '../components/CommonDocFooter';
import { Order } from '../components/Order';
import StoryIcon from '../components/StoryIcon';
import { styles } from '../styles';

interface Props {
  tasks: OrderWithMdf[];
  subTasks: OrderWithMdf[];
  mdfsSeparated: MdfsSeparated;
  relationMembers: MemberType[];
  contacts: MemberType[];
  groups: string[];
  optionLists: OptionList[];
}

const PlanningDocHeader = () => {
  return (
    <View style={styles.header} fixed>
      <View style={[styles.headerContent, { alignItems: 'center' }]}>
        <StoryIcon />
        <Text style={styles.title}>Tasks</Text>
      </View>
      <View style={[styles.horizontalLine, { paddingTop: '8' }]} />
    </View>
  );
};

const Contents = ({
  tasks,
  mdfsSeparated,
  subTasks,
  relationMembers,
  contacts,
  groups,
  optionLists,
}: Props) => {
  const { getMemberTitle, getMember } = useGetMembersInfo();
  return (
    <View style={[styles.column, styles.content, { gap: '16px', marginBottom: 16 }]}>
      {tasks.map((order, index) => {
        return (
          <Order
            key={order.mId}
            order={order}
            getMemberTitle={getMemberTitle}
            getMember={getMember}
            groups={groups}
            index={index}
            subMdfs={mdfsSeparated.subTypes}
            subOrders={subTasks.filter((subTask) => subTask.mResourceId === order.mId)}
            relationMembers={relationMembers}
            contacts={contacts}
            mdfsSeparated={mdfsSeparated}
            optionLists={optionLists}
          />
        );
      })}
    </View>
  );
};

const TasksPrintDoc = (props: Props) => (
  <Document>
    <Page style={styles.body}>
      <PlanningDocHeader />
      <Contents {...props} />
      <CommonDocFooter />
    </Page>
  </Document>
);

export default TasksPrintDoc;
