const SETTING_TABS_INDEXS = {
  USER_SETTINGS: 0,
  SYSTEM_SETTINGS: 1,
  GROUPS: 2,
  INTEGRATIONS: 3,
  SCHEMAS: 4,
  TASKS: 5,
};

export default SETTING_TABS_INDEXS;
