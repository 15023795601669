import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  ClickAwayListener,
  Popper,
  ListItem,
  ListItemText,
  InputBase,
} from '@material-ui/core';
import Scrollbar from 'components/scrollbar';
import { ReactComponent as CheckSrc } from 'assets/icons/systemicons/check.svg';
import useInputEvents from 'hooks/useInputEvents';
import stringToTime from 'utils/stringToTime';
import useStyles from './timePicker-styles';

// This function generates list of time according to the provided
// interval and format(12/24).
const timeList = (interval, format) => {
  const arr = [];
  const hour = 24;
  const minute = 60;

  if (format === 12) {
    for (let i = 0; Math.floor((interval * i) / minute) < hour; i += 1) {
      const h = Math.floor((interval * i) / minute);
      const m = (interval * i) % minute;
      arr.push(`${h % 12 || 12}:${m < 10 ? 0 : ''}${m}${h / 12 < 1 ? 'am' : 'pm'}`);
    }
  } else {
    for (let i = 0; Math.floor((interval * i) / minute) < hour; i += 1) {
      const h = Math.floor((interval * i) / minute);
      const m = (interval * i) % minute;
      arr.push(`${h < 10 ? 0 : ''}${h}:${m < 10 ? 0 : ''}${m}`);
    }
  }
  return arr;
};

const TimePicker = ({ time: initialTime, onUpdateTime, interval, format, textStyle }) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const divRef = useRef();

  const resetInput = () => {
    setInputValue(initialTime);
    setAnchorEl(null);
  };

  const onUpdate = (newValue) => {
    const newTime = stringToTime(newValue);
    if (newTime) {
      setAnchorEl(null);
      onUpdateTime(newTime);
      setInputValue(newTime);
    } else {
      setInputValue(initialTime);
    }
  };

  const [inputRef, handleKeyDown] = useInputEvents(onUpdate, inputValue || '', initialTime, true);
  const showOrHideInput = (_) => {
    setAnchorEl(divRef.current);
  };
  const handleChange = (event) => setInputValue(event.target.value);

  return (
    <ClickAwayListener onClickAway={resetInput}>
      <div ref={divRef} className={classes.wrapper}>
        {anchorEl ? (
          <InputBase
            classes={{
              root: `${classes.input} ${textStyle || ''}`,
              focused: classes.focused,
            }}
            // className={classes.input}
            defaultValue={inputValue || initialTime}
            variant="outlined"
            autoFocus
            inputRef={inputRef}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            inputProps={{ min: 0, style: { textAlign: 'center' } }}
          />
        ) : (
          <Typography
            classes={{ root: `${classes.text} ${textStyle || ''}` }}
            onClick={showOrHideInput}
          >
            {initialTime}
          </Typography>
        )}
        <Popper id="time-picker" open={!!anchorEl} anchorEl={anchorEl} className={classes.root}>
          <div className={classes.popperContainer}>
            <Scrollbar top={5} bottom={5}>
              {timeList(interval, format).map((time) => (
                <ListItem
                  button
                  disableGutters
                  classes={{ root: classes.listItem, selected: classes.selected }}
                  autoFocus={time === initialTime}
                  selected={time === initialTime}
                  onClick={() => onUpdate(time)}
                  key={time}
                >
                  <ListItemText
                    primary={time}
                    classes={{ root: classes.textRoot, primary: classes.label }}
                  />
                  {time === initialTime && (
                    <div className={classes.check}>
                      <CheckSrc />
                    </div>
                  )}
                </ListItem>
              ))}
            </Scrollbar>
          </div>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

TimePicker.propTypes = {
  /** Initial time value to start with */
  time: PropTypes.string,
  /** Function to call when time is selected to pressed enter */
  onUpdateTime: PropTypes.func,
  /** Interval between each step of time list to show */
  interval: PropTypes.number,
  /** If the date should show 24 or 12 hour format */
  format: PropTypes.number,
};

TimePicker.defaultProps = {
  time: '00:00',
  onUpdateTime: () => {},
  interval: 30,
  format: 24,
};

export default TimePicker;
