import PropTypes from 'prop-types';

import { useContentTabs, useMaxVisibleTabs } from 'store/tabs';

import TabItemsView from './TabItems';

import { StyledDropdownTabsWrapper } from './styled';

const TabItemsContainer = ({ handleDropDownClose }: { handleDropDownClose: () => void }) => {
  const [contentTabs] = useContentTabs();
  const [maxVisibleTabs] = useMaxVisibleTabs();

  return (
    <StyledDropdownTabsWrapper>
      {contentTabs.map((tab, index) => (
        <TabItemsView
          key={tab.id}
          tab={tab}
          visible={index < maxVisibleTabs}
          handleDropDownClose={handleDropDownClose}
        />
      ))}
    </StyledDropdownTabsWrapper>
  );
};

TabItemsContainer.propTypes = {
  /** callback for dropdown close */
  handleDropDownClose: PropTypes.func.isRequired,
};

export default TabItemsContainer;
