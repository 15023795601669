import { ReactComponent as SwitchTheme } from 'assets/icons/search/switch_theme.svg';
import { CommandGroup } from 'lib/command';
import useChangeTheme from 'screens/main/components/header/navbar/settings/components/settingsTabs/userSettings/details/theme/useChangeTheme';

import { CommandItem } from '../CommandItem';

interface Props {
  inputValue: string;
}

const InitiallyHiddenInstructions = ({ inputValue }: Props) => {
  const { toggleTheme, currentTheme } = useChangeTheme();
  const oppositeTheme = currentTheme === 'dark' ? 'light' : 'dark';

  return (
    <CommandGroup heading="Commands">
      <CommandItem
        forceMount={'theme'.includes(inputValue.toLowerCase())}
        value="theme"
        key="theme"
        icon={SwitchTheme}
        onSelect={() => toggleTheme()}
      >
        <span>Switch theme to {oppositeTheme}</span>
      </CommandItem>
    </CommandGroup>
  );
};

export default InitiallyHiddenInstructions;
