import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Row } from '@tanstack/react-table';
import { capitalize } from 'lodash';

import { ReactComponent as MetadataIcon } from 'assets/icons/search/metadata.svg';
import { ParsedMemberType } from 'types/members';

const IconWrapper = styled('span')<{ $color?: string }>`
  width: 20px;
  height: 20px;
  background-color: ${({ theme, $color }) => $color ?? theme.palette.dina.borderHover};
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  svg path {
    fill-opacity: 1;
  }
`;

interface TypeCellProps {
  row: Row<ParsedMemberType>;
}

export function PlanningTypeCell({ row }: Readonly<TypeCellProps>) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading) setLoading(false);
  }, []);

  if (loading) return 'Loading...';

  return (
    <>
      <IconWrapper $color={row.original.commandData?.mColor}>
        <MetadataIcon className="skipOverride" style={{ width: '16px', height: '16px' }} />
      </IconWrapper>
      {capitalize(row.original.commandData?.mTitle)}
    </>
  );
}
