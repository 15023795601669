import { BlockElement } from 'types/editor';

import DescriptionBlock from './description/DescriptionBlock';
import HtmlBlock from './html/HtmlBlock';
import BaseImageBlock from './image/ImageBlock';
import BaseParagraphBlock from './paragraph/ParagraphBlock';
import QuoteBlock from './quote/QuoteBlock';
import TitleBlock from './title/TitleBlock';

export type SupportedBlockTypes =
  | 'title'
  | 'description'
  | 'paragraph'
  | 'image'
  | 'quote'
  | 'html';

export const BlockMap: Record<SupportedBlockTypes, React.FC<BlockElement>> = {
  title: TitleBlock,
  description: DescriptionBlock,
  paragraph: BaseParagraphBlock,
  image: BaseImageBlock,
  quote: QuoteBlock,
  html: HtmlBlock,
};
