import gql from 'graphql-tag';

export default gql`
  query GetPolicy($input: GetMemberInput) {
    getMemberFromId(input: $input) {
      mId
      mRefId
      mTitle
      mProperties {
        __typename
        ... on GroupPolicy {
          policies {
            resourceName
            permissions {
              action
              access
            }
          }
          userCount
        }
      }
    }
  }
`;
