import { useEffect, useRef } from 'react';
import styled from '@emotion/styled';

import { FieldTypeEnum } from 'types/graphqlTypes';

interface Props {
  setValue: (val: string) => void;
  value: string;
  onBlur?: () => void;
  autoFocus?: boolean;
  title?: string;
  type?: FieldTypeEnum;
  error?: string;
  disabled?: boolean;
}

const StyledInput = styled.input<{ showError?: boolean }>`
  width: 100%;
  height: 100%;
  border: none;
  padding-left: 6px;
  border: ${({ showError }) => (showError ? '1px solid orange' : '')};
`;

function LWTextField({
  setValue,
  onBlur,
  value,
  type,
  autoFocus = true,
  error,
  title,
  disabled,
}: Readonly<Props>) {
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (inputRef.current && autoFocus) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [autoFocus]);

  return (
    <StyledInput
      disabled={disabled}
      placeholder={title}
      title={title}
      ref={inputRef}
      type={type === FieldTypeEnum.number ? 'number' : 'text'}
      value={value}
      onChange={(ev) => setValue(ev.target.value)}
      onBlur={onBlur}
      showError={error !== undefined}
    />
  );
}

export default LWTextField;
