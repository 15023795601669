import { Dispatch, SetStateAction, useState } from 'react';

import useCreateUser from 'api/userManagement/useCreateUsers';
import Dialog from 'components/dialogs/DialogBuilder';
import useToast from 'components/toast/useToast';

import { BodyWrapper, Input, InputLabel, InputWrapper } from './styled';

interface CreateNewProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;

const getEmailHelperText = (email: string) => {
  if (!email) {
    return 'Email is required';
  } else if (!emailRegex.test(email)) {
    return 'Email is not valid';
  } else {
    return '';
  }
};

const getUserNameHelperText = (username: string) => {
  if (!username || username.trim() === '') {
    return 'Username is required';
  }
  if (username !== username.replace(' ', '').trim()) {
    return "Username can't contain spaces";
  }
  return '';
};

const CreateNewUserDialog = ({ open, setOpen }: CreateNewProps) => {
  const { errorToast } = useToast();
  const [createUser, loading] = useCreateUser();
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');

  const handleOnCancel = () => {
    setEmail('');
    setUsername('');
    setOpen(false);
  };

  const handleOnClose = () => {
    setOpen(false);
  };

  const handleCreateNew = async () => {
    await createUser(email, username, errorToast, handleOnCancel);
  };

  const emailError = !email || !emailRegex.test(email);
  const usernameError =
    !username || username.trim() === '' || username.replace(' ', '').trim() !== username;

  return (
    <Dialog open={open} onClose={handleOnClose}>
      <Dialog.Header>Create new User</Dialog.Header>
      <Dialog.Body>
        <BodyWrapper>
          <InputWrapper>
            <InputLabel>EMAIL</InputLabel>
            <Input
              variant="filled"
              type="email"
              placeholder=" Enter email"
              value={email}
              helperText={getEmailHelperText(email)}
              error={emailError && open}
              onChange={(e) => setEmail(e.target.value)}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>USERNAME</InputLabel>
            <Input
              variant="filled"
              type="text"
              placeholder=" Enter username"
              value={username}
              helperText={getUserNameHelperText(username)}
              error={usernameError && open}
              onChange={(e) => setUsername(e.target.value)}
            />
          </InputWrapper>
        </BodyWrapper>
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton onCancel={handleOnCancel} />
        <Dialog.ConfirmButton
          onConfirm={handleCreateNew}
          disabled={usernameError || emailError}
          loading={loading}
        />
      </Dialog.Footer>
    </Dialog>
  );
};

export default CreateNewUserDialog;
