/* eslint-disable sort-imports */
import { memo, ReactNode, useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { init, SearchIndex } from 'emoji-mart';
import { BaseRange, Transforms } from 'slate';
import { useSlate } from 'slate-react';

import { useEmojiData } from 'store';
import { EmojiType } from 'types/editor';

import useCombobox, { Position } from '../../hooks/useCombobox';

import { List, ListWrapper, MenuItem, Title } from './styled';

const beforeRegex = /^:([\p{L}0-9_-]+)$/u;
const afterRegex = /^(\s|$)/;

function Portal({ children }: { children: ReactNode }) {
  return createPortal(children, document.body);
}

function EmojiCombobox() {
  const editor = useSlate();
  const [emojiData] = useEmojiData();
  const [filteredEmojis, setFilteredEmojis] = useState<EmojiType[]>([]);

  useEffect(() => {
    init({ data: emojiData }).then(
      () => {},
      () => {},
    );
  }, []);

  const searchEmoji = async (value: string) => {
    const emojis = (await SearchIndex.search(value)) as EmojiType[];
    setFilteredEmojis(emojis.slice(0, 9));
  };

  const insertEmoji = useCallback(
    (emoji: EmojiType, targetNode: BaseRange | null) => {
      Transforms.select(editor, targetNode as BaseRange);
      if (emoji.skins) Transforms.insertText(editor, emoji.skins[0].native);
    },
    [editor],
  );

  const setSearchValue = useCallback((value: string) => {
    searchEmoji(value).then(
      () => {},
      () => {},
    );
  }, []);

  const { target, position, cursor } = useCombobox(
    filteredEmojis,
    insertEmoji,
    setSearchValue,
    {
      beforeExp: beforeRegex,
      afterExp: afterRegex,
    },
    true,
  );

  const showSuggestion = target && filteredEmojis.length > 0;

  return showSuggestion ? (
    <Portal>
      <ListWrapper position={position as Position<string>} elevation={12}>
        <List>
          {filteredEmojis.map((emoji, index) => {
            const { id, skins } = emoji;
            return (
              <MenuItem
                dense
                selected={index === cursor}
                key={id}
                onClick={() => insertEmoji(emoji, target)}
              >
                {skins && <Title>{skins[0].native}</Title>}
                <Title>{id}</Title>
              </MenuItem>
            );
          })}
        </List>
      </ListWrapper>
    </Portal>
  ) : null;
}

export default memo(EmojiCombobox);
