import styled from '@emotion/styled';
import { Menu as MuiMenu, MenuItem as MuiMenuItem } from '@material-ui/core';

export const StyledMenu = styled(MuiMenu)`
  .MuiMenu-paper {
    width: 272px;
    padding: 24px;
    padding-right: 12px;
    background-color: transparent;
    box-shadow: none;
  }
  .MuiMenu-list {
    width: 236px;
    background-color: ${({ theme }) => theme.palette.dina.surfaceDialogs};
    border-radius: 4px;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%),
      0px 3px 14px 2px rgb(0 0 0 / 12%);
  }
`;

export const MenuItem = styled(MuiMenuItem)`
  ${({ theme }) => theme.typography.dina.listItemLabel};
  :hover {
    background-color: ${({ theme }) => theme.palette.dina.whiteHoverOverlay};
  }
  &.Mui-selected,
  &.Mui-selected:hover,
  &.Mui-selected:focus {
    background-color: ${({ theme }) => theme.palette.dina.onSelected};
  }
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const IconWrapper = styled('div')`
  margin-right: 16px;
`;
