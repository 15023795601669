/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable prettier/prettier */
import { RenderRowProps, Row, SortColumn } from 'react-data-grid';
import { useDrag, useDrop } from 'react-dnd';

import { acceptedMTypes } from 'features/gridDeck/utils';
import { MemberType } from 'types/graphqlTypes';

import { MemberColumn, MemberRow } from '../types';

type Index = { index: number };

const isIndex = (p: MemberType | Index): p is Index => {
  const maybeIndex = p as Partial<Index>;
  return typeof maybeIndex.index === 'number';
};
interface DraggableRowRenderProps<R, SR> extends RenderRowProps<R, SR> {
  onRowReorder: (sourceIndex: number, targetIndex: number) => void;
  onAddItem: (item: MemberType, index: number) => void;
  setIsOverRow: React.Dispatch<React.SetStateAction<boolean>>;
  sortColumns: readonly SortColumn[];
  columnNames: Record<string, MemberColumn>;
}

export function DraggableRowRenderer<SR>({
  rowIdx,
  isRowSelected,
  onRowReorder,
  setIsOverRow,
  onAddItem,
  sortColumns,
  columnNames,
  row,
  ...props
}: Readonly<DraggableRowRenderProps<MemberRow, SR>>) {
  const [{ isDragging }, drag] = useDrag({
    type: 'ROW_DRAG',
    item: { index: rowIdx },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver, canDrop, mousePos }, drop] = useDrop({
    accept: ['ROW_DRAG', 'STORY_DRAG'],
    drop(p: MemberType | Index) {
      if (isIndex(p)) {
        onRowReorder(p.index, rowIdx);
      } else {
        onAddItem(p, rowIdx);
      }
    },
    canDrop(p: MemberType | Index) {
      if (isIndex(p)) {
        return !sortColumns.length;
      } else {
        if (!sortColumns.length) return false;
        return (p.mType && acceptedMTypes.includes(p.mType)) ?? false;
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      mousePos: monitor.getDifferenceFromInitialOffset(),
    }),
  });

  setIsOverRow(isOver);

  const classes: string[] = [];
  let warning: string = '';

  if (mousePos && mousePos.y > 0) {
    classes.push('down');
  }

  if (isDragging) {
    classes.push('isDragging');
    if (sortColumns.length) {
      classes.push('disableReorder');
      warning = 'Reorder disabled by sorted column: ' + columnNames[sortColumns[0].columnKey].name;
    }
  }

  if (isOver && canDrop) {
    classes.push('isOver');
  }

  return (
    <Row
      ref={(ref) => {
        if (ref) {
          drag(ref.firstElementChild);
        }
        drop(ref);
      }}
      rowIdx={rowIdx}
      isRowSelected={isRowSelected}
      className={classes.join(' ')}
      row={{ ...row, showWarning: warning }}
      {...props}
    />
  );
}
