import { useCallback, useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { mergeAndRemoveDuplicates } from 'utils/arrayUtils';
import memberTypes from 'operations/memberTypes';
import SEARCH_STORIES from 'operations/queries/searchApi';

const useGetStories = ({ userItemsOnly = false, searchString, assignedMembers, selectedDate }) => {
  const variables = useMemo(() => {
    const filter = {
      mTypes: [memberTypes.STORY, memberTypes.PITCH],
      userItemsOnly,
      searchString,
      assignedMembers,
    };

    if (selectedDate) filter.searchAfter = selectedDate;

    return {
      limit: 25,
      filter,
    };
  }, [userItemsOnly, searchString, assignedMembers, selectedDate]);

  const { data, loading, fetchMore } = useQuery(SEARCH_STORIES, {
    variables,
    fetchPolicy: 'cache-and-network',
  });

  const stories = data?.searchApi?.items;

  const handleFetchMore = useCallback(() => {
    if (data?.searchApi?.nextToken) {
      fetchMore({
        variables: { ...variables, nextToken: data.searchApi.nextToken },
        updateQuery: (prev, { fetchMoreResult }) => {
          const existingItems = prev?.searchApi?.items;
          const newItems = fetchMoreResult.searchApi?.items;
          const uniqueItems = mergeAndRemoveDuplicates(existingItems, newItems, 'mId');

          return {
            ...prev,
            searchApi: {
              nextToken: fetchMoreResult.searchApi?.nextToken,
              items: uniqueItems,
            },
          };
        },
      });
    }
  }, [data?.searchApi?.nextToken, fetchMore, variables]);

  return { items: stories, loading, fetchMore: handleFetchMore };
};

export default useGetStories;
