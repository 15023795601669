import { useEffect, useMemo, useState } from 'react';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';

import useGetGroupPolicy from 'api/useGetGroupPolicy';
import Infobar from 'components/infobar/Infobar';
import LWCheckbox from 'features/orderForm/components/LWCheckbox';
import { Box } from 'layouts/box/Box';
import { MemberType, Rundown, RundownPermissionsType } from 'types/graphqlTypes';

import { useChangedRundownPermissions } from '../../../../../atomsTs';

import { AccessType, GroupType, updatePermissionArray } from './utils';

import { HeaderText, StyledTable, StyledTableCell, StyledTableHeader } from './styled';

const GroupPermission = ({
  group,
  permissions,
  update,
}: {
  group: { label: string; value: string };
  permissions?: RundownPermissionsType;
  update: (value: AccessType) => void;
}) => {
  const [access, setAccess] = useState<AccessType>({
    read: !!permissions?.read.includes(group.value),
    write: !!permissions?.write.includes(group.value),
  });

  const handleOnClick = (type: 'read' | 'write', value: boolean) => {
    const localAccess: AccessType =
      type === 'read'
        ? {
            ...access,
            read: value,
          }
        : { ...access, write: value, read: value ? value : access.read };
    setAccess(localAccess);
    update(localAccess);
  };

  useEffect(() => {
    setAccess({
      read: !!permissions?.read.includes(group.value),
      write: !!permissions?.write.includes(group.value),
    });
  }, [group.value, permissions?.read, permissions?.write]);

  return (
    <TableRow key={group.value}>
      <StyledTableCell>{group.label}</StyledTableCell>
      <StyledTableCell>
        <LWCheckbox
          selected={access.read}
          setValue={(val: boolean) => {
            handleOnClick('read', val);
          }}
          disabled={access.write}
          title={access.write ? 'Read permission required for enabling write.' : ''}
        />
      </StyledTableCell>
      <StyledTableCell>
        <LWCheckbox
          selected={access.write}
          setValue={(val: boolean) => handleOnClick('write', val)}
        />
      </StyledTableCell>
    </TableRow>
  );
};

const PermissionsView = ({ rundown }: { rundown: Rundown }) => {
  const { groupPolicies } = useGetGroupPolicy();
  const allGroups = groupPolicies?.map((policy) => policy.mRefId as string);

  const [changedRP, setChangedRP] = useChangedRundownPermissions();
  const [permissions, setPermissions] = useState<RundownPermissionsType>(
    rundown.permissions ?? { read: allGroups ?? [], write: allGroups ?? [] },
  );

  const groups: GroupType[] = useMemo(
    () =>
      groupPolicies?.map((policy: MemberType) => ({
        value: policy.mRefId as string,
        label: policy.mTitle as string,
      })) ?? [],
    [groupPolicies],
  );

  const handleRPChange = (groupId: string, access: AccessType): void => {
    setChangedRP((prevChangedRP) => {
      const existingIndex = prevChangedRP.findIndex(({ mId }) => mId === rundown.mId);

      if (existingIndex === -1) {
        // GroupId does not exist, add a new entry.
        const newReadPermissions = updatePermissionArray(
          groupId,
          permissions.read,
          access.read && !permissions.read.includes(groupId),
          !access.read && permissions.read.includes(groupId),
        );
        const newWritePermissions = updatePermissionArray(
          groupId,
          permissions.write,
          access.write && !permissions.write.includes(groupId),
          !access.write && permissions.write.includes(groupId),
        );

        setPermissions({ read: newReadPermissions, write: newWritePermissions });

        return [
          ...prevChangedRP,
          {
            mId: rundown.mId,
            permissions: {
              read: newReadPermissions,
              write: newWritePermissions,
            },
          },
        ];
      }

      // GroupId already exists, update the permissions.
      const updatedPermissions = [...prevChangedRP];
      const permissionObject = updatedPermissions[existingIndex];

      const newReadPermissions = updatePermissionArray(
        groupId,
        permissionObject.permissions.read,
        access.read && !permissionObject.permissions.read.includes(groupId),
        !access.read && permissionObject.permissions.read.includes(groupId),
      );

      const newWritePermissions = updatePermissionArray(
        groupId,
        permissionObject.permissions.write,
        access.write && !permissionObject.permissions.write.includes(groupId),
        !access.write && permissionObject.permissions.write.includes(groupId),
      );

      updatedPermissions[existingIndex] = {
        ...permissionObject,
        permissions: { read: newReadPermissions, write: newWritePermissions },
      };
      return updatedPermissions;
    });
  };

  useEffect(() => {
    const changesFound = changedRP.find((cRP) => cRP.mId === rundown.mId);

    if (changesFound) {
      setPermissions(changesFound.permissions);
    } else {
      setPermissions({
        read: rundown.permissions?.read ?? allGroups ?? [],
        write: rundown?.permissions?.write ?? allGroups ?? [],
      });
    }
  }, [rundown]);

  return (
    <>
      <HeaderText as="h6" variant="h6">
        Permissions for <q>{rundown.mTitle ?? 'untitled'}</q>
      </HeaderText>
      <Box container alignItems="flex-start" flex="1" flexDirection="column" width="100%">
        <Box width="100%" padding="0 0.5rem">
          <Infobar>Manage visibility and editability</Infobar>
        </Box>
        <Box container flex="1" width="100%" padding="0.5rem">
          <StyledTable size="small">
            <StyledTableHeader>
              <TableRow>
                <StyledTableCell>Group</StyledTableCell>
                <StyledTableCell>Read</StyledTableCell>
                <StyledTableCell>Write</StyledTableCell>
              </TableRow>
            </StyledTableHeader>
            <TableBody>
              {groups.map((group) => {
                return (
                  <GroupPermission
                    key={`${group.value}+${rundown.mId}`}
                    group={group}
                    permissions={permissions}
                    update={(value) => handleRPChange(group.value, value)}
                  />
                );
              })}
            </TableBody>
          </StyledTable>
        </Box>
      </Box>
    </>
  );
};

export default PermissionsView;
