import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Popper as MuiPopper } from '@material-ui/core';

// eslint-disable-next-line import/prefer-default-export
export const StyledPopper = styled(MuiPopper)`
  z-index: 3000;
  background-color: ${({ theme }) => theme.palette.dina.surfaceDialogs};
  ${({ theme }) => css`
    box-shadow: 0px 0px 2px ${theme.palette.dina.dialogBackdropOverlay},
      0px 0px 4px ${theme.palette.dina.dialogBackdropOverlay},
      0px 12px 24px ${theme.palette.dina.dialogBackdropOverlay};
  `}
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  .arrow {
    position: absolute;
    ::before {
      content: '';
      margin: auto;
      display: block;
      background-color: ${({ theme }) => theme.palette.dina.surfaceCard};
    }
  }

  &[x-placement*='bottom'] .arrow {
    top: -12px;
    ::before {
      width: 24px;
      height: 12px;
      clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
    }
  }
  &[x-placement*='top'] .arrow {
    bottom: -12px;
    ::before {
      width: 24px;
      height: 12px;
      clip-path: polygon(0 0, 50% 100%, 100% 0);
    }
  }
  &[x-placement*='right'] .arrow {
    left: -12px;
    ::before {
      width: 12px;
      height: 24px;
      clip-path: polygon(0 50%, 100% 100%, 100% 0);
    }
  }
  &[x-placement*='left'] .arrow {
    right: -12px;
    ::before {
      width: 12px;
      height: 24px;
      clip-path: polygon(0 100%, 100% 50%, 0 0);
    }
  }
`;
