import { useMutation } from '@apollo/client';
import graphql from 'graphql-tag';

const UPDATE_FOLDER = graphql`
  mutation UpdateFolder($input: UpdateMemberInput) {
    updateMember(input: $input) {
      mId
      mRefId
      mOrder
    }
  }
`;

const useUpdateTemplateOrder = () => {
  const [updateFolder] = useMutation(UPDATE_FOLDER);

  const update = async (mId: string, mRefId: string, mOrder: string[]) => {
    const input = {
      mId,
      mRefId,
      mOrder,
    };

    return updateFolder({
      variables: {
        input,
      },
    });
  };

  return update;
};

export default useUpdateTemplateOrder;
