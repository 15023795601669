import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';

import { AuthContext } from 'contexts/AuthContext';
import UserCtx from 'contexts/UserContext';
import useApolloSubscription from 'hooks/useApolloSubscriptionV2';
import useGetUser from 'hooks/useGetUser';
import useNotification from 'hooks/useNotification';
import useSettingsValue from 'hooks/useSettingsValue';
import useUpdateLeftSidebarCache from 'hooks/useUpdateLeftSidebarCache';
import memberTypes from 'operations/memberTypes';
import GET_DEPARTMENTS from 'operations/queries/getDepartments';
import GET_TEAMS from 'operations/queries/getTeams';
import GET_USERS from 'operations/queries/getUsers';
import { getMembersOfTypeQuery } from 'operations/queryVariables';
import NOTIFY_CONVERSATION_SUBSCRIPTION from 'operations/subscriptions/notifyConversation';
import NOTIFY_MEMBER_UPDATE_SUBSCRIPTION from 'operations/subscriptions/notifyMemberUpdate';
import createMessage from 'operations/utils/cache/notification/createMesasge';
import updateCacheWithNotification from 'operations/utils/cache/notification/updateCacheWithNotification';
import {
  useLeftSelection,
  useMembers,
  useSidebarDatePickerAtom,
  useUnreadConversations,
} from 'store';
import conversationTypes from 'utils/constants/conversationTypes';
import getDefaultValueOfProperty from 'utils/getDefaultValueOfProperty';
import extractNotification from 'utils/notificationHelper/notificationUtils';
import processNotificationPayload from 'utils/notificationHelper/payloadUtils';
import shouldIgnoreSetNotification from 'utils/notificationHelper/setNotificationLogic';

const getUpdatedUsers = async (client, setMembers) => {
  const userPromise = client.query({
    query: GET_USERS,
    variables: getMembersOfTypeQuery(memberTypes.USER),
    fetchPolicy: 'network-only',
  });

  const teamPromise = client.query({
    query: GET_TEAMS,
    variables: getMembersOfTypeQuery(memberTypes.TEAM),
    fetchPolicy: 'network-only',
  });

  const deptPromise = client.query({
    query: GET_DEPARTMENTS,
    variables: getMembersOfTypeQuery(memberTypes.DEPARTMENT),
    fetchPolicy: 'network-only',
  });

  const contactPromise = client.query({
    query: GET_USERS,
    variables: getMembersOfTypeQuery(memberTypes.CONTACT),
    fetchPolicy: 'network-only',
  });

  const responses = await Promise.allSettled([
    userPromise,
    teamPromise,
    deptPromise,
    contactPromise,
  ]);

  const [userResult, teamResult, deptResult, contactResult] = responses.map(
    (response) => response.value,
  );

  if (
    !userResult?.loading &&
    !teamResult?.loading &&
    !deptResult?.loading &&
    !contactResult?.loading
  ) {
    // save users, teams, departements & external contacts on store
    setMembers({
      [memberTypes.USER]: userResult.data.getMembersOftype,
      [memberTypes.TEAM]: teamResult.data.getMembersOftype,
      [memberTypes.DEPARTMENT]: deptResult.data.getMembersOftype,
      [memberTypes.CONTACT]: contactResult.data.getMembersOftype,
    });
  }
};

const specialChatChannels = [
  conversationTypes.STORY,
  conversationTypes.PITCH,
  conversationTypes.RUNDOWN,
  conversationTypes.SPACE,
  conversationTypes.COMMENT,
];

export const NotificationContext = createContext();

const NotificationStore = ({ children }) => {
  const apolloClient = useApolloClient();
  const [, setMembers] = useMembers();
  const { mId } = useContext(UserCtx);
  const [unreadConversations] = useUnreadConversations();
  const [selectedDates] = useSidebarDatePickerAtom();
  const authCtx = useContext(AuthContext);
  const [updateCache] = useUpdateLeftSidebarCache();
  const [leftSelection] = useLeftSelection();
  const sendNotification = useNotification();
  const [getSettingsValue] = useSettingsValue();
  const { getUser } = useGetUser();

  const defaultValue = getDefaultValueOfProperty('notification', 'user.desktopNotifications');
  // Used 'app.desktopNotifications' as fallback property.
  const canShowNotification =
    getSettingsValue(
      'user.desktopNotifications',
      'app.desktopNotifications',
      defaultValue,
    ).toString() === 'true';
  const [notification, setNotification] = useState([{ message: '' }]);
  const [notificationMessage, setNotificationMessage] = useState([...unreadConversations]);
  const [newConversation, setNewConversation] = useState(false);

  const executeCommand = (command, data) => {
    const { name: commandName } = command;

    if (commandName === 'logout') {
      authCtx.logout();
    }
  };

  const notificationData = ({ client, data: subscriptionData }) => {
    const notifyUser = subscriptionData.data.notifyMemberUpdateSubscription;
    const { command } = notifyUser;
    if (command) {
      executeCommand(command, notifyUser);
      return;
    }

    getMessageAndUpdateCache(client, notifyUser);
  };

  const chatNotificationData = ({ client, data: subscriptionData }) => {
    const notifyUser = subscriptionData.data.notifyConversationSubscription;
    getMessageAndUpdateCache(client, notifyUser);
  };

  const getMessageAndUpdateCache = async (client, payload) => {
    const { mUpdatedById, convoType } = payload;
    if (specialChatChannels.includes(convoType)) return;
    // eslint-disable-next-line no-param-reassign
    payload.message = processNotificationPayload(payload, mId, selectedDates);
    // Update cache before sending notification message.
    updateCacheWithNotification(client, payload, updateCache, leftSelection);
    if (
      shouldIgnoreSetNotification(payload, specialChatChannels) ||
      (mUpdatedById === mId && convoType === 'all')
    )
      return;

    const existingUser = getUser(mUpdatedById ?? payload?.message?.mUpdatedById);
    if (!existingUser) {
      await getUpdatedUsers(apolloClient, setMembers);
      setNewConversation(true);
    }
    const newNotificationMessage = createMessage(payload);

    if (newNotificationMessage) {
      const newNotification = extractNotification(
        newNotificationMessage,
        mUpdatedById ?? payload?.message?.mUpdatedById,
        existingUser,
        payload,
      );
      setNotification(newNotification);
    }
  };

  useApolloSubscription(NOTIFY_MEMBER_UPDATE_SUBSCRIPTION, {
    variables: { mIdSubscribed: mId },
    onSubscriptionData: notificationData,
    source: 'NotificationContext',
  });

  useApolloSubscription(NOTIFY_CONVERSATION_SUBSCRIPTION, {
    variables: { mIdSubscribed: mId },
    onSubscriptionData: chatNotificationData,
    source: 'NotificationContext:chatNotification',
  });

  useApolloSubscription(NOTIFY_CONVERSATION_SUBSCRIPTION, {
    variables: { mIdSubscribed: conversationTypes.ALL },
    onSubscriptionData: chatNotificationData,
    source: 'NotificationContext:allConversation',
  });

  useEffect(() => {
    if (!notification.message) return;
    if (!notification.message.length || notification.message.length === 0) return;

    if (notification.mType !== 'convo' && notification.mType !== 'message') {
      notification.mId = `${mId}_notifications`;
    }

    canShowNotification && sendNotification(notification);
    setNotificationMessage(notificationMessage.concat(notification));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  const value = useMemo(
    () => ({ notificationMessage, newConversation, setNewConversation }),
    [notificationMessage, newConversation, setNewConversation],
  );

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};

NotificationStore.propType = {
  children: PropTypes.elementType,
};
export default NotificationStore;
