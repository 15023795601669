import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { deleteFromS3 } from 'utils/s3Utils';
import DELETE_CONTENT_TEMPLATE from 'operations/mutations/deleteContentTemplate';
import GET_FOLDERS from 'operations/queries/getFolders';

const UPDATE_FOLDER = gql`
  mutation UpdateFolder($input: UpdateMemberInput) {
    updateMember(input: $input) {
      mId
      mRefId
      mOrder
    }
  }
`;

const useDeleteTemplate = () => {
  const [deleteTemplateMutation] = useMutation(DELETE_CONTENT_TEMPLATE);
  const [updateFolder] = useMutation(UPDATE_FOLDER);

  const deleteTemplate = async (mId, mRefId, mContentKey) => {
    const input = {
      mId,
      mRefId,
    };
    await deleteTemplateMutation({
      variables: { input },
      update: (proxy, mutationResult) => {
        const { mRefId: deletedId } = mutationResult.data.deleteSingleMember;
        try {
          const folderList = proxy.readQuery({
            query: GET_FOLDERS,
          });

          const newFolderList = folderList.getFolders.map((folder) => {
            if (folder.mRefId === mId) {
              const mOrder = folder?.mOrder?.filter((item) => item !== deletedId);
              if (Array.isArray(mOrder)) {
                updateFolder({
                  variables: {
                    input: {
                      mId: folder.mId,
                      mRefId: folder.mRefId,
                      mOrder,
                    },
                  },
                });
              }

              const newFolder = {
                ...folder,
                items: folder.items.filter((item) => item.mRefId !== deletedId),
                mOrder,
              };
              return newFolder;
            }
            return folder;
          });

          proxy.writeQuery({
            query: GET_FOLDERS,
            data: { getFolders: newFolderList },
          });
        } catch (error) {
          // logger.log(error);
        }
      },
    }).then(() => {
      try {
        deleteFromS3(mContentKey);
      } catch (err) {
        // logger.log(err);
      }
    });
  };

  return [deleteTemplate];
};

export default useDeleteTemplate;
