import { useState } from 'react';
import { atom, useAtom } from 'jotai';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import CreateStory from 'components/createNewV3';
import DraggableDialog from 'components/dialogs/Draggable';
import useCheckUserRight from 'hooks/useCheckUserRight';
import useCreateStory, { CreateStoryInput } from 'hooks/useCreateStory';
import useCreateStoryFromFeed from 'hooks/useCreateStoryFromFeed';
import useDinaNavigate from 'hooks/useDinaNavigate';
import memberTypes from 'operations/memberTypes';
import { useSidebar } from 'store';
import { AssignedMember } from 'types';
import { Metadata } from 'types/forms/forms';
import { MemberTypeEnum } from 'types/graphqlTypes';
import useLogger from 'utils/useLogger';

export interface CreateMemberDialog {
  // Sent in if it should not be the current logged in user.
  mId?: string;
  // Sent in starting title
  mTitle?: string;
  // Only provide if you want to relate the story/pitch to a specific mType.
  // If not provided, it will automatically resolve story vs pitch type.
  relatedType?: MemberTypeEnum.Team | MemberTypeEnum.Department;
  // The original start point for the dialog.
  anchorEl: null | HTMLElement | SVGSVGElement;
  // If provided, add to default assignees in addition to the creator
  preselectedMember?: AssignedMember;
  // Use if you want to only allow creating story or pitch. Will still check actual permissions.
  limitType?: 'story' | 'pitch';
  // Set a starting schedule date
  scheduleDate?: string;
  // Will use the create story feed mutation instead
  useFeedMutation?: true;
  // Create story from feed prop only
  provider?: string;
  // Optional callback invoked when dialog closes
  onClose?: () => void;

  onComplete?: () => void;

  // Force show in center - use if no html element to point the dialog at
  forceShow?: true;

  // Optionally select metadata
  preselectedMetadata?: {
    mdfId: string;
    metadata: Metadata;
  };

  // Content, will be put into the content area of the story.
  mContent?: string;
}

const defaults: CreateMemberDialog = {
  anchorEl: null,
};

const createMemberDialog = atom<CreateMemberDialog>({ ...defaults });

export const useCreateMemberDialog = () => useAtom(createMemberDialog);

const CreateNewDialog = () => {
  const [state, setState] = useCreateMemberDialog();
  const [type, setType] = useState<'story' | 'pitch'>('story');
  const [sideBar] = useSidebar();
  const { navigateTo } = useDinaNavigate();
  const logger = useLogger('createNewDialog');
  const { create: createStoryForUser, loading } = useCreateStory();
  const { create: createStoryFromFeed, loading: feedLoading } = useCreateStoryFromFeed();
  const [checkUserRight] = useCheckUserRight();
  const canCreateStory = checkUserRight('story', 'create');
  const canCreatePitch = checkUserRight('pitch', 'create');

  const { mdfsByMType } = useGetMdfs({ all: true });

  const enableRestrictionOption = checkUserRight('story', 'access-restriction');
  const [dimension, setDimension] = useState({ width: 'fit-content', height: 'fit-content' });

  const mdf = (state?.limitType ?? type) === 'pitch' ? mdfsByMType.pitch : mdfsByMType.story;

  const hasFields = (mdf?.fields ?? []).length > 0;

  const handleClose = () => {
    if (state.onClose) {
      state.onClose();
    }
    setState({
      ...defaults,
    });
  };

  const handleSideBar = () => {
    if (
      !sideBar.leftHidden &&
      (sideBar.leftSelection === memberTypes.STORY ||
        sideBar.leftSelection === memberTypes.PITCH ||
        sideBar.leftSelection === memberTypes.USER)
    ) {
      const elm = document.getElementById('scrollbar');
      if (elm?.firstChild) {
        setTimeout(() => {
          if (elm?.firstChild && 'scrollTop' in elm.firstChild) elm.firstChild.scrollTop = 0;
        }, 1000);
      }
    }
  };

  const handleCreate = async (p: CreateStoryInput) => {
    try {
      const createdId = state.useFeedMutation
        ? await createStoryFromFeed({
            ...p,
            ...(state.mId && { mId: state.mId }),
            provider: state.provider,
          })
        : await createStoryForUser({
            ...p,
            relationType: state.relatedType,
            ...(state.mId && { mId: state.mId }),
          });

      if (!p.keepOpen) {
        handleClose();
      }

      if (p.openStory && createdId) {
        navigateTo(p.type, createdId);
      }

      if (state.onComplete) {
        state.onComplete();
      }

      handleSideBar();
    } catch (e) {
      logger.error(e as string | Error);
    }
  };

  return (
    <DraggableDialog
      open={state.forceShow || Boolean(state.anchorEl)}
      onClose={handleClose}
      initialPosition={state.anchorEl?.getBoundingClientRect()}
      minWidth={hasFields ? 600 : 450}
      minHeight={310}
      dimension={dimension}
      setDimension={setDimension}
    >
      <CreateStory
        disableTypeSwitch={state.limitType !== undefined}
        hideRestrictedOption={!enableRestrictionOption}
        canCreate={type === 'pitch' ? canCreatePitch : canCreateStory}
        loading={loading || feedLoading}
        doCreate={handleCreate}
        onCancel={handleClose}
        setDimension={setDimension}
        preselectedMember={state.preselectedMember}
        scheduleDate={state.scheduleDate}
        mTitle={state.mTitle}
        preselectedMetadata={state.preselectedMetadata}
        mContent={state.mContent}
        mdf={mdf}
        type={state.limitType ?? type}
        setType={setType}
      />
    </DraggableDialog>
  );
};

export default CreateNewDialog;
