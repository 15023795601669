import React from 'react';
import { BasePoint, Editor, Location, NodeEntry, Path, Range, Transforms } from 'slate';

import { CustomElement, CustomText } from 'types';
import preventDefaultEvent from 'utils/preventDefaultEvent';

import isList from '../components/list/utils/isList';
import { insertParagraph } from '../components/paragraph/utils';
import isSection from '../components/sectionDivider/utils/isSection';
import { elementTypes } from '../constants';

import getSelectedElement from './getSelectedElement';
import selectElement from './selectElement';

export const defaultSelection: Range = {
  anchor: { path: [0, 0], offset: 0 },
  focus: { path: [0, 0], offset: 0 },
};

export const anchorInPath =
  (editor: Editor, anchor: BasePoint, path: Path) => (isEdge: typeof Editor.isEnd) =>
    isEdge(editor, anchor, path as unknown as Location);

export const checkIfStrictEqual = (arr1: number[] = [], arr2: number[] = []) =>
  JSON.stringify(arr1) === JSON.stringify(arr2);

export const hasRestrictedBlock = (nodes: CustomElement[] = []): boolean => {
  const restrictedBlock = nodes.find(
    (node) => node.type === elementTypes.MDF_BLOCK || node.type === elementTypes.ORDER_BLOCK,
  );
  return !!restrictedBlock;
};

export const getNodesFromSelection = (editor: Editor) =>
  editor.selection ? (Editor.fragment(editor, editor.selection) as CustomElement[]) : [];

export const isMdfBlock = (type: string) => type === elementTypes.MDF_BLOCK;
export const isOrderBlock = (type: string) => type === elementTypes.ORDER_BLOCK;

export const handleEmptySelection = (
  editor: Editor,
  match: NodeEntry<CustomElement>,
  start: BasePoint,
  end: BasePoint,
  isSectionDivider: boolean,
  event: React.KeyboardEvent<HTMLDivElement>,
) => {
  const [, path] = match;
  const depth = isSectionDivider ? 1 : 0;
  preventDefaultEvent(event);

  // if selection expands outside of one section divider
  if (isSectionDivider && start.path[0] !== end.path[0]) {
    Transforms.unwrapNodes(editor, {
      match: isSection,
      split: true,
    });
    Editor.deleteFragment(editor);
  } else if (!hasRestrictedBlock(getNodesFromSelection(editor))) {
    const limit =
      end.path[depth] + ((Editor.node(editor, end)?.[depth] as CustomText)?.text === '' ? 1 : 0);
    for (let step = start.path[depth]; step < limit; step += 1) {
      Transforms.removeNodes(editor, {
        at: depth ? [...path, start.path[depth]] : path,
      });
    }
  }
};

export const deleteNodes = (
  editor: Editor,
  match: NodeEntry<CustomElement>,
  isSectionDivider: boolean,
  event: React.KeyboardEvent<HTMLDivElement>,
) => {
  const [start, end] = Range.edges(editor.selection ?? defaultSelection);
  if (start.offset === 0 && end.offset === 0) {
    handleEmptySelection(editor, match, start, end, isSectionDivider, event);
  } else if (isSectionDivider) {
    // if it is section divider block
    preventDefaultEvent(event);
    if (start.path[0] !== end.path[0]) {
      insertParagraph(editor, { at: start, mode: 'lowest' });
      Transforms.unwrapNodes(editor, {
        match: isSection,
        split: true,
      });
    }
    Editor.deleteFragment(editor);
  } else if (hasRestrictedBlock(getNodesFromSelection(editor))) {
    event.preventDefault();
  }
};

const isVoidValid = (editor: Editor, element: CustomElement | null | undefined) =>
  element && editor.isVoid(element);

const isEmptyOrVoid = (editor: Editor, element: CustomElement) =>
  Editor.isEmpty(editor, element) || editor.isVoid(element);

export const removeNodesWhenCollapsed = (
  editor: Editor,
  match: NodeEntry<CustomElement>,
  shouldPrevent: boolean,
  isSectionDivider: boolean,
  isStart: boolean,
  event: React.KeyboardEvent<HTMLDivElement>,
) => {
  const [element, path] = match;
  const [nextNode] = Editor.next<CustomElement>(editor, { at: path }) || [];

  if (isSectionDivider) {
    const selectedChild = getSelectedElement(editor, { depth: 2 });
    if (isVoidValid(editor, selectedChild)) {
      if (isStart) insertParagraph(editor, { at: [...path, 1] });
      Transforms.removeNodes(editor, { at: isStart ? [...path, 0] : undefined });
    }
    return preventDefaultEvent(event);
  }
  if (isVoidValid(editor, nextNode)) {
    preventDefaultEvent(event);
    if (!shouldPrevent && isEmptyOrVoid(editor, element)) {
      selectElement(editor, nextNode);
      Transforms.removeNodes(editor, { at: path });
    }
  }
};

export const removeNodesWhenExpanded = (
  editor: Editor,
  match: NodeEntry<CustomElement>,
  isContentElement: boolean,
  isSectionDivider: boolean,
  event: React.KeyboardEvent<HTMLDivElement>,
) => {
  const [start, end] = Range.edges(editor.selection ?? defaultSelection);

  if (isContentElement && checkIfStrictEqual(start.path, end.path)) {
    Transforms.delete(editor);
  }

  deleteNodes(editor, match, isSectionDivider, event);

  if (isContentElement && checkIfStrictEqual(start.path, end.path)) {
    Transforms.delete(editor);
  }
};

export const backspaceOnSectionDivider = (
  editor: Editor,
  match: NodeEntry<CustomElement>,
  event: React.KeyboardEvent<HTMLDivElement>,
) => {
  const [element, path] = match;
  const selectedChild = getSelectedElement(editor, { depth: 2 });
  const hasSingleChild = element.children.length === 1;
  if (selectedChild) {
    if (Editor.isEmpty(editor, selectedChild)) {
      const targetPath = hasSingleChild ? path : [...path, 0];
      preventDefaultEvent(event);
      Transforms.removeNodes(editor, { at: targetPath });
    } else if (
      isList(selectedChild) &&
      selectedChild.children.length === 1 &&
      Editor.isEmpty(editor, selectedChild.children[0] as CustomElement)
    ) {
      preventDefaultEvent(event);
      Transforms.removeNodes(editor, { at: [...path, 0] });
    } else if (!editor.isVoid(selectedChild)) {
      preventDefaultEvent(event);
      Transforms.move(editor, { reverse: true });
    } else if (editor.isVoid(selectedChild)) {
      preventDefaultEvent(event);
      const targetPath = [...path, 0];
      Transforms.removeNodes(editor, { at: targetPath });
      if (hasSingleChild) insertParagraph(editor, { at: targetPath, select: true });
    }
  }
};
