import { MetadataFormType } from 'types/graphqlTypes';

/**
 *
 * @param {*} form Metadata form
 * returns empty metadata field value for a form
 */
const getEmptyMetadataForForm = (form: MetadataFormType | undefined) => {
  if (!(form && form.fields)) return [];

  return form.fields.map(({ id: key, value }) => ({
    key,
    value,
  }));
};

export default getEmptyMetadataForForm;
