import { useEffect } from 'react';
import { atom } from 'jotai';
import { useAtom } from 'jotai/react';
import { isObject } from 'lodash';

interface MimirEvent {
  source: 'MIMIR';
  action: 'mimir_loaded'; // there are others but we care about this one for now
  user: string; // email of currently logged in user @ Mimir
  targetOrigin: string;
}

const mimirLoadedAtom = atom(false);
export const useMimirLoaded = () => useAtom(mimirLoadedAtom);

export const isMimirLoadedEvent = (data: unknown): data is MimirEvent => {
  if (!isObject(data)) return false;
  const maybeMimirEvent = data as Partial<MimirEvent>;
  return maybeMimirEvent.source === 'MIMIR' && maybeMimirEvent.action === 'mimir_loaded';
};

/**
 * Listen to mimir loaded event to have application be aware of when messages can be sent
 * to Mimir.
 */
export const useSideEffectMimir = () => {
  const [, setMimirLoaded] = useMimirLoaded();

  useEffect(() => {
    const onMessageFromFrames = (ev: MessageEvent) => {
      if (isMimirLoadedEvent(ev.data)) {
        setMimirLoaded(true);
        window.removeEventListener('message', onMessageFromFrames);
      }
    };
    window.addEventListener('message', onMessageFromFrames);
    return () => {
      window.removeEventListener('message', onMessageFromFrames);
    };
  }, []);
};
