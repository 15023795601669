import useToast from 'components/toast/useToast';

import { useCopiedInstanceIds } from '../store';

const useCopyInstanceIdsToClipboard = () => {
  const { toast } = useToast();
  const [, setCopiedInstanceIds] = useCopiedInstanceIds();
  const MAXIMUM_COPY_ALLOWED = 45;

  const copyInstanceIds = async (selectedItems: string[]) => {
    if (!selectedItems?.length) return;
    const ids = selectedItems.filter((id) => !id.startsWith('-'));
    if (ids.length >= MAXIMUM_COPY_ALLOWED) {
      toast({
        title: `Cannot copy more than ${MAXIMUM_COPY_ALLOWED} instances`,
        type: 'info',
      });
      return;
    }
    const val = `instanceIds:${ids.join(',')}`;
    setCopiedInstanceIds(val);
    await navigator.clipboard.writeText(val);
  };

  return [copyInstanceIds] as const;
};

export default useCopyInstanceIdsToClipboard;
