import { useMutation } from '@apollo/client';

import SEARCH_REQUEST from 'operations/mutations/searchRequest';

const useChatGPTSearch = () => {
  const [search] = useMutation(SEARCH_REQUEST);

  const searchChatGPT = async (text = '') => {
    let result = '';
    if (text === '') return '';
    await search({
      variables: {
        input: {
          provider: 'chatgpt',
          request: {
            body: text,
          },
        },
      },
      update: (_, mutationResult) => {
        const { body } = mutationResult?.data?.serviceRequest;
        if (body) {
          const bodyObj = JSON.parse(body);
          const [choice] = bodyObj?.choices || [];
          const message = choice?.message?.content || '';
          result = message;
        }
      },
    });
    return result;
  };

  return searchChatGPT;
};

export default useChatGPTSearch;
