/**
 * Hook for handling both hover and arrow key navigation events in left panel
 * This is a combined hook for useHover and arrow key navigation functionality.
 * How to use:
 *
 * import useLeftPanelNavigation from 'hooks/useLeftPanelNavigation';
 *
 * 
 * const [
    hoverRef, 
    isHovered, 
    isSelected, 
    setIsSelected
  ] = useLeftPanelNavigation(openPreviewIndex, index, arrowKeyFired, setArrowKeyFired);
 * ...
 * <div ref={hoverRef}>
 *  {isHovered ? '😁' : '☹️'}
 * </div>
 */
import { useEffect, useRef, useState } from 'react';

const useHover = (isStoryFocused) => {
  const [value, setValue] = useState(false);
  const ref = useRef(null);

  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);

  useEffect(() => {
    setValue(isStoryFocused);
    if (isStoryFocused && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
    }
  }, [isStoryFocused]);

  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener('mouseover', handleMouseOver);
        node.addEventListener('mouseleave', handleMouseOut);

        return () => {
          node.removeEventListener('mouseover', handleMouseOver);
          node.removeEventListener('mouseleave', handleMouseOut);
        };
      }
      return undefined;
    },
    [], // Recall only if ref changes
  );

  return [ref, value];
};

export default useHover;
