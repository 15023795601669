import { Editor, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import { elementTypes } from 'components/editor/constants';
import { ActionTypesEnum } from 'components/editor/constants/types/actionTypes';
import { isAssetInsert, Update } from 'components/editor/types';
import { generateName } from 'components/editor/utils';
import { CustomElement } from 'types';

const { insertNodes, setNodes } = Transforms;

/**
 * Inserts video element
 *
 * @param editor SlateJS editor instance
 * @param update Callback to be invoked on update
 * @param file Uploaded file
 * @param fileUrl Browser object url for the file
 * @returns SlateJS editor instance
 */

const insertVideo = async (editor: Editor, update: Update, file: File, fileUrl: string) => {
  const fileName = generateName(file.type);

  const element = {
    type: elementTypes.VIDEO,
    data: { cache: fileUrl },
    children: [{ text: '' }],
  } as CustomElement;

  insertNodes(editor, element);

  try {
    const data = await update({
      type: ActionTypesEnum.ASSET_INSERT,
      payload: { document: editor.children as CustomElement[], file, fileName },
    });

    if (data && isAssetInsert(data)) {
      const path = ReactEditor.findPath(editor, element);

      setNodes(editor, { data }, { at: path });
    }
  } catch (error) {
    // logger.log(error)
  }

  return editor;
};

export default insertVideo;
