import { memo } from 'react';
import styled from '@emotion/styled';

import { ReactComponent as Open } from 'assets/icons/systemicons/open.svg';
import Tooltip from 'components/tooltip';
import { HStack } from 'layouts/box/Box';

interface Props {
  children: React.ReactNode;
  tooltip?: string;
  onClick: () => void;
}

const StyledOpen = styled(Open)`
  width: 16px;
  flex-shrink: 0;
  flex-grow: 0;
  display: none;
`;

const Wrapper = styled(HStack)`
  cursor: pointer;
  overflow: visible;
  flex-shrink: 0;
  &:hover {
    .do_open_icon {
      display: block;
    }
    * {
      text-decoration: underline;
      color: ${({ theme }) => theme.palette.dina.highEmphasis};
      fill-opacity: 1;
    }
  }
`;

function OpenChild({ children, tooltip, onClick }: Readonly<Props>) {
  return (
    <Tooltip title={tooltip ?? 'Open'} enterDelay={500}>
      <Wrapper onClick={onClick} alignItems="center" gap="6px" justifyContent="start">
        {children}
        <StyledOpen className="do_open_icon" />
      </Wrapper>
    </Tooltip>
  );
}

export default memo(OpenChild);
