import { TableHeader, TableRow } from 'components/dataTable/Table';

import { ColumnFilterProps, DinaTable } from '../types';

import { TableHead } from './TableHead';
interface DataTableHeaderProps<TData> {
  /** The table instance */
  table: DinaTable<TData>;
  /** Optional column filters */
  ColumnFilters?: React.ComponentType<ColumnFilterProps<TData>>;
}

/** Data table header with sort, group, and column filter support*/
export function DataTableHeader<TData>({
  table,
  ColumnFilters,
}: Readonly<DataTableHeaderProps<TData>>) {
  return (
    <TableHeader>
      {table.getHeaderGroups().map((headerGroup) => (
        <TableRow key={headerGroup.id}>
          {headerGroup.headers.map((header, index) => {
            return (
              <TableHead
                header={header}
                key={`${header.id}_${index}`}
                ColumnFilters={ColumnFilters}
                meta={table.options.meta}
                table={table}
                staticColumnIndex={index}
              />
            );
          })}
        </TableRow>
      ))}
    </TableHeader>
  );
}
