import React, { useState, memo, useCallback } from 'react';
import { Backdrop } from '@material-ui/core';
import { useTheme } from '@emotion/react';

import useGetAsset from 'hooks/useGetAsset';
import ICONS from 'assets/icons/icons';
import Icon from 'components/icon';
import { Button } from 'components/buttons';

import Metadata from './components/metadata';

import {
  AnimatedModalContent,
  Modal,
  ContentWrapper,
  HeaderWrapper,
  HeaderContent,
  Title,
  Filename,
  IconButton,
  CloseIcon,
  MainContentWrapper,
  LazyPlayer,
  BgImageWrapper,
  BgImage,
  InfoWrapper,
  AnimatedOverlay,
  InfoIconWrapper,
  AnimatedInfoIcon,
  FooterWrapper,
} from './styled';

const MediaViewer = ({ handleClose, open, asset, accessToken }) => {
  const theme = useTheme();
  const { mId, mRefId, type, uri, href, previewUri, videoPreviewUri, filename } = asset || {};

  const [assetData] = useGetAsset(mId, mRefId, !mId || !mRefId);

  const url = useCallback(() => {
    if (assetData?.mContentUrl) return assetData.mContentUrl;

    if (type && type.toLowerCase() === 'video' && uri)
      return `${videoPreviewUri || uri}${accessToken}`;
    if (type && type.toLowerCase() === 'image' && href)
      return `${previewUri || href}${accessToken}`;
    return `${previewUri || href || uri}${accessToken}`;
  }, [accessToken, href, previewUri, type, uri, videoPreviewUri, assetData]);

  const [isInfoOpen, setIsInfoOpen] = useState(false);

  const toggleOpen = useCallback(() => setIsInfoOpen(!isInfoOpen), [isInfoOpen]);

  const closeModal = useCallback(() => {
    setIsInfoOpen(false);
    handleClose();
  }, [handleClose]);

  const [isHoveringInfoIcon, setIsHoveringInfoIcon] = useState(false);

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 300,
      }}
    >
      <AnimatedModalContent open={open}>
        {open && (
          <ContentWrapper>
            <HeaderWrapper>
              <HeaderContent>
                <Title>Preview</Title>
                <Filename>{filename}</Filename>
              </HeaderContent>
              <IconButton size="small" onClick={closeModal}>
                <CloseIcon />
              </IconButton>
            </HeaderWrapper>
            <MainContentWrapper>
              {type && type.toLowerCase() === 'video' && (
                <LazyPlayer height="450px" width="800px" url={url()} controls />
              )}
              {type && type.toLowerCase() === 'image' && (
                <BgImageWrapper image={url()}>
                  <BgImage src={url()} alt="media" />
                </BgImageWrapper>
              )}
              <InfoWrapper>
                {isInfoOpen && (
                  <AnimatedOverlay open={isInfoOpen}>
                    <Metadata asset={asset} />
                  </AnimatedOverlay>
                )}
                <InfoIconWrapper isInfoOpen={isInfoOpen}>
                  <AnimatedInfoIcon
                    onMouseEnter={() => setIsHoveringInfoIcon(true)}
                    onMouseLeave={() => setIsHoveringInfoIcon(false)}
                    onClick={toggleOpen}
                  >
                    {isInfoOpen ? (
                      <Icon
                        hovered={isHoveringInfoIcon}
                        hoverColor={theme.palette.dina.highEmphasis}
                        icon={ICONS.ARROW_RIGHT}
                        width={24}
                        height={24}
                      />
                    ) : (
                      <Icon
                        hovered={isHoveringInfoIcon}
                        hoverColor={theme.palette.dina.highEmphasis}
                        icon={ICONS.INFO}
                        width={24}
                        height={24}
                      />
                    )}
                  </AnimatedInfoIcon>
                </InfoIconWrapper>
              </InfoWrapper>
            </MainContentWrapper>
            <FooterWrapper>
              <Button
                autoWidth
                onClick={closeModal}
                padding={40}
                usage="outlined"
                variant="outlined"
              >
                Close
              </Button>
            </FooterWrapper>
          </ContentWrapper>
        )}
      </AnimatedModalContent>
    </Modal>
  );
};

export default memo(MediaViewer);
