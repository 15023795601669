import { gql, useQuery } from '@apollo/client';

import { getMembersQuery } from 'operations/queryVariables';
import { MemberType } from 'types/graphqlTypes';

export const GET_WEBHOOKS = gql`
  query GetWebhooks($input: GetMemberInput) {
    getMembers(input: $input) {
      mId
      mRefId
      mType
      mTitle
      mAllowedEntities {
        mType
      }
      metadata
      mInvokeUrl
      mProvider
    }
  }
`;

export interface GetMembersType {
  getMembers: MemberType[];
}

const useGetWebhooks = () => {
  const variables = getMembersQuery('endpoint', 'hook');

  const { data, loading, error } = useQuery<GetMembersType>(GET_WEBHOOKS, {
    variables,
    fetchPolicy: 'cache-and-network',
  });

  return [data?.getMembers, loading, error];
};

export default useGetWebhooks;
