/* eslint-disable max-len */
import { Path, Rect, Svg } from '@react-pdf/renderer';

const CheckboxOn = () => {
  return (
    <Svg style={{ width: 20, height: 20, marginRight: 4 }} viewBox="0 0 24 24">
      <Rect x="3" y="3" width="18" height="18" rx="2" fill="#0C73E9" />
      <Path
        d="M10.1288 14.8904L7.57344 12.3137C7.28289 12.0208 6.80924 12.0208 6.51869 12.3137C6.23153 12.6033 6.23153 13.0702 6.51869 13.3598L10.1288 17L18.4813 8.5778C18.7685 8.28825 18.7685 7.82133 18.4813 7.53178C18.1908 7.2388 17.7171 7.2388 17.4266 7.53178L10.1288 14.8904Z"
        fill="white"
      />
    </Svg>
  );
};

export default CheckboxOn;
