import { Image, Link, Text, View } from '@react-pdf/renderer';
import { v4 as uuidV4 } from 'uuid';

import defaultThumbnail from 'assets/images/default/defaultThumbnail.png';
import getCleanLink from 'components/editor/utils/getCleanLink';
import useStorageImage from 'hooks/useStorageImage';
import { CustomData, CustomElement, CustomText, isCustomElement } from 'types';
import { getThumbnailKey } from 'utils/mediaKey';
import getDirection from 'utils/text/getDirection';

import { getDocumentComponent } from '../docs/utils';
import { styles } from '../styles';
import { MdfBlockProps } from '../types';
import { getHeaderFontSize } from '../utils/getHeaderFontSize';
import { getNodeStyles } from '../utils/getNodeStyles';
import repositionTrailingDotForRTL from '../utils/repositionTrailingDotForRTL';

import AccessoryIcon from './AccessoryIcon';
import AdlibIcon from './AdlibIcon';
import AudioIcon from './AudioIcon';
import { Block } from './Block';
import BreakIcon from './BreakIcon';
import CameraIcon from './CameraIcon';
import CGIcon from './CGIcon';
import CheckboxOff from './CheckboxOff';
import CheckboxOn from './CheckboxOn';
import DveIcon from './DveIcon';
import GraphicsIcon from './GraphicsIcon';
import JingleIcon from './JingleIcon';
import LiveIcon from './LiveIcon';
import TelephoneIcon from './TelephoneIcon';
import VideoClipIcon from './VideoClipIcon';
import VoiceOverIcon from './VoiceOverIcon';

export const typeToIconMap = {
  dve: <DveIcon />,
  jingle: <JingleIcon />,
  break: <BreakIcon />,
  adlib: <AdlibIcon />,
  telephone: <TelephoneIcon />,
  audio: <AudioIcon />,
  accessory: <AccessoryIcon />,
};

export const ImageComponent = ({ data, type }: { data: CustomData; type: string }) => {
  const { mId, mRefId, src, proxy, mTitle, title, mediaType } = data;
  const key = (src || getThumbnailKey(mId, mRefId)) ?? undefined;
  const {
    data: thumbnailData,
    error: storageError,
    loading: storageLoading,
  } = useStorageImage(key);

  const s3ThumbUrl = storageError && !storageLoading ? defaultThumbnail : thumbnailData;
  const imgSrc = s3ThumbUrl ?? proxy;

  if (!imgSrc) return <Text key={uuidV4()}>{type}</Text>;

  return (
    <View key={imgSrc}>
      <Image src={imgSrc} style={styles.image} />
      <Text style={styles.caption}>
        Title: {mTitle ?? title ?? ''} {mediaType ? `Type: ${mediaType}` : ''}
      </Text>
    </View>
  );
};

export const ListItem = ({
  value = '',
  type = 'unordered-list',
  order,
  style = {},
}: {
  value: string | CustomElement;
  type: string;
  order: number;
  style?: Record<string, unknown> | Record<string, unknown>[];
}) => {
  const direction = typeof value === 'string' ? getDirection(value) : 'ltr';

  return (
    <View
      style={
        direction === 'rtl'
          ? { flexDirection: 'row-reverse', alignItems: 'flex-end' }
          : { flexDirection: 'row' }
      }
      key={uuidV4()}
    >
      <View style={styles.bullet}>
        {type === 'unordered-list' ? (
          <Text>{direction === 'rtl' ? ' \u2022' : '\u2022 '}</Text>
        ) : (
          <Text>{direction === 'rtl' ? ` .${order}` : `${order}. `}</Text>
        )}
      </View>
      {typeof value === 'string' ? (
        <Text style={style}>{value}</Text>
      ) : (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        getDocumentComponent({ doc: value })
      )}
    </View>
  );
};

export const renderParagraphBlock = (doc: CustomElement) => {
  const { children } = doc;
  let direction = 'ltr';

  const childrenView = children.map((child, index) => {
    if (isCustomElement(child)) return getDocumentComponent({ doc: child });
    const { text } = child;
    const childStyles = getNodeStyles(child);

    if (index === 0) {
      // determine to render as rtl if there's an arabic word at the begining of the sentence
      direction = getDirection(text);
    }

    const filteredStyleForArabic =
      direction === 'rtl'
        ? childStyles.filter((cStyle) => cStyle.fontStyle !== 'italic')
        : childStyles;

    const modifiedText = repositionTrailingDotForRTL(text);

    return (
      <Text
        key={uuidV4()}
        style={[...filteredStyleForArabic, { flex: '0 0 auto', flexWrap: 'wrap' }]}
      >
        {modifiedText}
      </Text>
    );
  });

  return (
    <View
      key={uuidV4()}
      style={[
        direction === 'rtl'
          ? { flexDirection: 'row-reverse', alignItems: 'flex-end', gap: 4 }
          : { flexDirection: 'row', gap: 4 },
        { textAlign: direction === 'rtl' ? 'right' : 'left', flexWrap: 'wrap' },
      ]}
    >
      {childrenView}
    </View>
  );
};

export const renderHeaderBlock = (doc: CustomElement) => {
  const { type, children } = doc;

  return children.map((child) => {
    if (isCustomElement(child)) return getDocumentComponent({ doc: child });

    const childStyles = getNodeStyles(child);
    const headerStyles = {
      ...childStyles,
      fontSize: getHeaderFontSize(type),
      fontWeight: 600,
    };

    return (
      <Text key={uuidV4()} style={headerStyles}>
        {child.text}
      </Text>
    );
  });
};

export const renderListBlock = (doc: CustomElement) => {
  const { type, children } = doc;

  return (
    <View style={{ marginTop: 8 }} key={uuidV4()}>
      {children.map((child, index) => {
        if (!isCustomElement(child)) return;

        let listChildren = child.children;

        if (listChildren.length > 1) {
          listChildren = listChildren.filter((ch) => isCustomElement(ch));
        }

        return listChildren.map((grandChild) => {
          const childStyles = 'text' in grandChild ? getNodeStyles(grandChild) : undefined;

          return (
            <ListItem
              value={'text' in grandChild ? grandChild.text : grandChild}
              type={type}
              order={index + 1}
              style={childStyles}
              key={uuidV4()}
            />
          );
        });
      })}
    </View>
  );
};

export const renderBlockQuoteBlock = (doc: CustomElement) => {
  const { children } = doc;

  const text = `"${(children[0] as CustomText).text}"`;
  const direction = getDirection(text);

  return (
    <Text
      key={uuidV4()}
      style={direction === 'rtl' ? { color: 'gray', textAlign: 'right' } : styles.blockQuote}
    >
      {text}
    </Text>
  );
};

export const renderCheckListBlock = (doc: CustomElement) => {
  const { children, data } = doc;

  let direction: string = 'ltr';

  const childText = children.map((child, index) => {
    if (isCustomElement(child)) return;
    const { text } = child;
    const childStyles = getNodeStyles({
      ...child,
      strikeThrough: data?.checked,
    });

    if (index === 0) {
      direction = getDirection(text);
    }

    return (
      <Text key={uuidV4()} style={childStyles}>
        {child.text}
      </Text>
    );
  });

  return (
    <View
      style={{
        flexDirection: direction === 'rtl' ? 'row-reverse' : 'row',
        alignItems: 'center',
        marginTop: '4px',
        marginLeft: '8px',
      }}
      key={uuidV4()}
    >
      <View style={styles.checkbox}>{data?.checked ? <CheckboxOn /> : <CheckboxOff />}</View>
      {childText}
    </View>
  );
};

export const renderLinkBlock = (doc: CustomElement) => {
  const { children, data } = doc;

  return (
    <Link src={getCleanLink(data?.href as string)} key={uuidV4()}>
      {children.map((child) => {
        return <Text key={uuidV4()}>{(child as CustomText).text}</Text>;
      })}
    </Link>
  );
};

export const renderPackageBlock = (doc: CustomElement) => {
  const { data, type } = doc;

  return (
    <View style={[styles.column, styles.package]} key={uuidV4()}>
      <View style={[styles.row, styles.primaryItems]}>
        {type.toLowerCase() === 'package' ? <VideoClipIcon /> : <VoiceOverIcon />}
        <Text>{`${data?.templateType} - ${data?.templateVariant}`}</Text>
      </View>
      {data?.assets?.map((asset) => (
        <Text key={uuidV4()} style={{ paddingLeft: '40px', fontSize: '14px' }}>
          {asset?.title ?? ''}
        </Text>
      ))}
    </View>
  );
};

export const renderCameraBlock = (doc: CustomElement) => {
  const { data } = doc;

  return (
    <View style={[styles.row, styles.primaryItems]} key={uuidV4()}>
      <CameraIcon />
      <Text style={styles.camera}>{`${data?.templateType} - ${data?.templateVariant}`}</Text>
    </View>
  );
};

export const renderLiveBlock = (doc: CustomElement) => {
  const { data } = doc;

  return (
    <View style={[styles.row, styles.primaryItems]} key={uuidV4()}>
      <LiveIcon />
      <Text style={styles.live}>{`${data?.templateType} - ${data?.templateVariant}`}</Text>
    </View>
  );
};

export const renderGraphicsBlock = (doc: CustomElement) => {
  const { data, type } = doc;

  return (
    <View style={[styles.row, styles.primaryItems]} key={uuidV4()}>
      {type === 'fullscreenGraphics' ? <GraphicsIcon /> : <CGIcon />}
      <Text style={styles.graphics}>{`${data?.templateType} - ${data?.templateVariant}`}</Text>
    </View>
  );
};

export const renderMentionBlock = (doc: CustomElement) => {
  const { data } = doc;

  return (
    <View key={uuidV4()}>
      <Text style={styles.mention}>{`@${data?.mTitle}`}</Text>;
    </View>
  );
};

export const renderHorizontalRuleBlock = () => {
  return <View style={styles.horizontalLine} key={uuidV4()} />;
};

export const renderMdfBlock = ({
  doc,
  blocks,
  orders,
  mdfsSeparated,
  relationMembers,
  contacts,
  groups,
  optionLists,
}: MdfBlockProps) => {
  const { type, data } = doc;

  if (!blocks || !orders) {
    return (
      <View style={[styles.row, styles.primaryItems]} key={uuidV4()}>
        {typeToIconMap[type as keyof typeof typeToIconMap] ?? null}
        <Text>{type}</Text>
      </View>
    );
  }

  const block = blocks?.find((blk) => blk.mRefId === data?.mId);
  const ordersForBlock = orders?.filter((order) => order.mResourceId === block?.mRefId);

  if (!block) {
    return (
      <View style={[styles.row, styles.primaryItems]} key={uuidV4()}>
        <Text>Could not find item</Text>
      </View>
    );
  }

  const { mTitle, mRefId, mdf, metadata, color } = block;

  return (
    <View key={uuidV4()} style={styles.paddingVertical}>
      <Block
        key={mRefId}
        metadata={metadata}
        fields={mdf?.fields}
        layoutSettings={mdf?.views.default}
        permissions={mdf?.permissions}
        blockTitle={mTitle}
        orders={ordersForBlock}
        subMdfs={mdfsSeparated.subTypes}
        color={color}
        relationMembers={relationMembers}
        contacts={contacts}
        mdfsSeparated={mdfsSeparated}
        groups={groups}
        optionLists={optionLists}
      />
    </View>
  );
};

export const renderDefaultBlock = (doc: CustomElement) => {
  const { type } = doc;

  return (
    <View style={[styles.row, styles.primaryItems]} key={uuidV4()}>
      {typeToIconMap[type as keyof typeof typeToIconMap] ?? null}
      <Text>{type}</Text>
    </View>
  );
};
