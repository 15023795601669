import { Metadata } from 'types/forms/forms';
import { MemberType, MemberTypeEnum } from 'types/graphqlTypes';

import { Block } from './types';

export const BLOCK_PROPS = `
  mId
  mRefId
  mTitle
  metadata
  mType
  mdfId
  mSecId
  mResourceId
  mSecRefId
  mCreatedAt
  mUpdatedAt
  mUpdatedById
  crudAction
`;

export const memberToBlock = (raw: MemberType): Block => {
  let metadata: Metadata = {};
  try {
    metadata = JSON.parse(raw.metadata ?? '{}') as Metadata;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn('Could not parse metadata', raw.metadata);
  }

  return {
    mId: raw.mId!,
    mRefId: raw.mRefId!,
    mdfId: raw.mdfId!,
    mTitle: raw.mTitle!,
    mCreatedAt: raw.mCreatedAt!,
    mUpdatedAt: raw.mUpdatedAt!,
    mUpdatedById: raw.mUpdatedById!,
    crudAction: raw.crudAction!,
    mResourceId: raw.mResourceId!,
    mSecId: raw.mSecId!,
    mSecRefId: raw.mSecRefId!,
    mType: MemberTypeEnum.Block,
    metadata,
  };
};

export const memberToRawBlock = (raw: MemberType): Partial<MemberType> => ({
  mId: raw.mId!,
  mRefId: raw.mRefId!,
  mdfId: raw.mdfId!,
  mTitle: raw.mTitle!,
  mCreatedAt: raw.mCreatedAt!,
  mUpdatedAt: raw.mUpdatedAt!,
  mUpdatedById: raw.mUpdatedById!,
  mResourceId: raw.mResourceId!,
  mSecId: raw.mSecId!,
  mSecRefId: raw.mSecRefId!,
  crudAction: raw.crudAction!,
  mType: raw.mType,
  metadata: raw.metadata,
});
