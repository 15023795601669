import { useEffect } from 'react';

import { createWidgetDefaults, useCreateWidgetMenu } from 'features/widget/CreateWidgetMenu';
import { ResizablePanel, ResizablePanelGroup } from 'lib/resize';

import { LayoutProps } from '../layouts';
import { PanelContent } from '../PanelContent';
import { HorizontalResizeHandle, VerticalResizeHandle } from '../ResizableHandlers';

export default function TwoByTwo({ widgets, ...panelProps }: Readonly<LayoutProps>) {
  const [, setCreateWidget] = useCreateWidgetMenu();

  const topLeftWidget = widgets[0];
  const topRightWidget = widgets[1];
  const bottomLeftWidget = widgets[2];
  const bottomRightWidget = widgets[3];

  useEffect(() => {
    return () => {
      setCreateWidget({ ...createWidgetDefaults });
    };
  }, []);

  return (
    <ResizablePanelGroup direction="vertical">
      <ResizablePanel style={{ marginLeft: '8px', marginRight: '8px' }}>
        <ResizablePanelGroup direction="horizontal">
          <ResizablePanel>
            <PanelContent
              index={0}
              layout="2-2"
              setCreateWidget={setCreateWidget}
              widget={topLeftWidget}
              {...panelProps}
            />
          </ResizablePanel>
          <VerticalResizeHandle />
          <ResizablePanel>
            <PanelContent
              index={1}
              layout="2-2"
              setCreateWidget={setCreateWidget}
              widget={topRightWidget}
              {...panelProps}
            />
          </ResizablePanel>
        </ResizablePanelGroup>
      </ResizablePanel>
      <>
        <HorizontalResizeHandle />
        <ResizablePanel style={{ marginLeft: '8px', marginRight: '8px', marginBottom: '8px' }}>
          <ResizablePanelGroup direction="horizontal">
            <ResizablePanel>
              <PanelContent
                index={2}
                layout="2-2"
                setCreateWidget={setCreateWidget}
                widget={bottomLeftWidget}
                {...panelProps}
              />
            </ResizablePanel>
            <>
              <VerticalResizeHandle />
              <ResizablePanel>
                <PanelContent
                  index={3}
                  layout="2-2"
                  setCreateWidget={setCreateWidget}
                  widget={bottomRightWidget}
                  {...panelProps}
                />
              </ResizablePanel>
            </>
          </ResizablePanelGroup>
        </ResizablePanel>
      </>
    </ResizablePanelGroup>
  );
}
