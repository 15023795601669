import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { StoryTabValue } from 'store';

import { useStoryMolecule } from './store/story';

export interface Pane {
  tab: StoryTabValue;
  tId?: string;
}

/**
 * Represents a search param pane.
 * @description This type is a string that follows the pattern `${StoryTab}.${string}`.
 * StoryTab represents tab ('content', 'notes') and the string after dot is selected item id (tId).
 * Example: 'notes.123456_note_987' or 'content.'
 */
type SearchParamPane = `${StoryTabValue}.${string}`;

const useStoryPanes = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { useQueryTagValue } = useStoryMolecule();
  const queryTag = useQueryTagValue();

  const panes = (searchParams.get(queryTag) ?? '').split(',') as SearchParamPane[];

  const storyPanes: Pane[] = useMemo(
    () =>
      (panes ?? []).map((tabStr) => {
        const [tab, tId] = tabStr.split('.');
        return { tab: tab ?? 'content', tId } as Pane;
      }),
    [panes],
  );

  const updateSearchParams = useCallback(
    (tabsArr: SearchParamPane[]) => {
      setSearchParams((prev) => {
        prev.set(queryTag, tabsArr.join(','));
        return prev;
      });
    },
    [queryTag, setSearchParams],
  );

  const addStoryPane = useCallback(
    (tab?: StoryTabValue, tId?: string) => {
      if (panes.length < 4) {
        updateSearchParams([...panes, `${tab ?? 'content'}.${tId ?? ''}`]);
      }
    },
    [panes, updateSearchParams],
  );

  const updateStoryPane = useCallback(
    (index: number, tab: StoryTabValue, tId?: string) => {
      if (index < panes.length) {
        const tempSPTabs = [...panes];
        tempSPTabs[index] = `${tab}.${tId ?? ''}`;
        updateSearchParams(tempSPTabs);
      }
    },
    [panes, updateSearchParams],
  );

  const removeStoryPane = useCallback(
    (index: number) => {
      if (panes.length > 1 && index < panes.length) {
        const tempSPTabs = panes.filter((_t, i) => i !== index);
        updateSearchParams(tempSPTabs);
      }
    },
    [panes, updateSearchParams],
  );

  useEffect(() => {
    if (!storyPanes.length) {
      addStoryPane();
    }
    // empty tab should only be checked once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    storyPanes,
    addStoryPane,
    updateStoryPane,
    removeStoryPane,
  };
};

export default useStoryPanes;
