import * as React from 'react';

import Slot, { Slottable } from 'lib/slot';

import { ButtonProps } from './types';

import { styles } from './styled';

const BaseButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant = 'contained',
      usage = 'cta',
      size = 'full',
      radius = 'default',
      asChild = false,
      wrap = false,
      className,
      autoWidth,
      children,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';

    return (
      <Comp
        aria-label={props.ariaLabel}
        css={(theme) => !asChild && styles({ ...theme, ...props })}
        data-size={autoWidth ? 'md' : size}
        data-variant={variant}
        data-radius={radius}
        data-usage={usage}
        data-wrap={wrap}
        className={`Button ${className ?? ''}`}
        ref={ref}
        {...props}
      >
        {props.startIcon}
        <Slottable>{children}</Slottable>
      </Comp>
    );
  },
);
BaseButton.displayName = 'BaseButton';

export { BaseButton };
export type { ButtonProps };
