/* eslint-disable no-console */

import { integrationIcons } from 'components/command/command-constants';
import { Thumbnail, ThumbnailWrapper } from 'features/mimirDeck/components/items/styled';
import { HStack } from 'layouts/box/Box';
import { isoToLocaleShort } from 'utils/datetimeHelpers';

import { SearchResultItem } from './types';

import {
  ItemContentWrapper,
  ItemDate,
  ItemDescription,
  ItemFooter as ItemFooterWrapper,
  ItemTitle,
  ItemTitleWrapper,
  SearchDeckItemWrapper,
} from './styled';

interface SearchItemProps {
  onContextClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  item: SearchResultItem;
  onDoubleClick: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    item?: SearchResultItem,
  ) => void;
}

const ItemFooter = ({ item }: { item: SearchResultItem }) => {
  return (
    <ItemFooterWrapper>
      <ItemDate variant="caption">{`${isoToLocaleShort(item.date, true)}`}</ItemDate>
    </ItemFooterWrapper>
  );
};

const SearchPluginDeckItem = ({ item, onContextClick, onDoubleClick }: SearchItemProps) => {
  const Icon = item.icon ? integrationIcons[item.icon] : undefined;
  const url = item.thumbnailUrl;
  return (
    <SearchDeckItemWrapper
      className="search-item"
      onContextMenu={onContextClick}
      onDoubleClick={(e) => onDoubleClick(e, item)}
    >
      <HStack width="100%" gap="8px">
        {url && (
          <ThumbnailWrapper $width="80" $height="56">
            <Thumbnail $src={url} />
          </ThumbnailWrapper>
        )}
        {!url && (
          <div style={{ width: '32px', height: '32px' }} className="search-icon">
            {Icon && <Icon className="skipOverride" />}
          </div>
        )}
        <ItemContentWrapper alignItems="start">
          <ItemTitleWrapper justifyContent="space-between">
            <ItemTitle>{item.title}</ItemTitle>
          </ItemTitleWrapper>
          <ItemDescription>{item.description}</ItemDescription>
          <ItemFooter item={item} />
        </ItemContentWrapper>
      </HStack>
    </SearchDeckItemWrapper>
  );
};

export default SearchPluginDeckItem;
