import {
  inTimingTypes,
  outTimingTypes,
} from 'components/editor/constants/types';

const { MANUAL_IN } = inTimingTypes;
const { MANUAL_OUT } = outTimingTypes;

const checkIfArray = array => (Array.isArray(array) ? array : []);

const getInitialData = ({
  templateType,
  templateVariant = 'VARIANT',
  metaData = [],
  assets = [],
  inTiming = MANUAL_IN,
  startTime = 0,
  outTiming = MANUAL_OUT,
  ...rest
}) => ({
  templateType,
  templateVariant,
  inTiming,
  startTime,
  outTiming,
  metaData: checkIfArray(metaData),
  assets: checkIfArray(assets),
  ...rest,
});

export default getInitialData;
