import { gql } from 'graphql-tag';

export default gql`
  mutation UpdateContact($input: UpdateContactInput) {
    updateContact(input: $input) {
      mId
      mRefId
      mTitle
      mType
      mDescription
      metadata
    }
  }
`;
