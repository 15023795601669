/* eslint-disable max-len */
import { useState } from 'react';
import styled from '@emotion/styled';
import { atom, useAtom } from 'jotai';

import { Button } from 'components/buttons';
import DialogBuilder from 'components/dialogs/DialogBuilder';
import DraggableDialog from 'components/dialogs/Draggable';
import { StyledTextField } from 'components/mdfEditor/fields/text/styled';
import Text from 'components/text/Text';
import Tooltip from 'components/tooltip';
import { ActiveLayoutIcons, LayoutIcons, LAYOUTS, SpaceViewLayout } from 'features/layouts/layouts';
import { HStack, VStack } from 'layouts/box/Box';
import { SpaceViewType } from 'screens/space/types/space';

const SegmentedWrapper = styled(HStack)`
  padding: 3px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.palette.dina.borderHover};
`;

const StyledLayout = styled.div<{ $selected: boolean }>`
  display: flex;
  cursor: pointer;
  border-radius: 4px;
  background: ${({ $selected, theme }) => ($selected ? theme.palette.dina.onSelected : '')};
  width: 40px;
  svg {
    width: 28px;
    height: 28px;
    margin: auto;
    path {
      fill: ${({ theme, $selected }) => ($selected ? 'white' : theme.palette.dina.iconActive)};
      fill-opacity: ${({ $selected }) => ($selected ? 1 : 0.7)};
    }
  }
`;

interface ICreateViewDialog {
  show: boolean;
  onConfirm: (title: string, layout: SpaceViewLayout) => void;
}

const defaults: ICreateViewDialog = {
  show: false,
  onConfirm: () => {},
};

const createViewDialog = atom<ICreateViewDialog>({ ...defaults });

export const useCreateViewDialog = () => useAtom(createViewDialog);

export const getLayout = (view: SpaceViewType): SpaceViewLayout =>
  (view?.configs?.find((c) => c.key === 'layout')?.value ?? 'horizontal') as SpaceViewLayout;

const CreateViewDialog = () => {
  const [state, setState] = useCreateViewDialog();
  const [dimension, setDimension] = useState({ width: 'fit-content', height: 'fit-content' });
  const [title, setTitle] = useState('');
  const [layout, setLayout] = useState<SpaceViewLayout>('horizontal');

  const handleClose = () => {
    setState((prevState) => {
      return {
        ...prevState,
        show: false,
      };
    });
    setTimeout(() => {
      setState({
        ...defaults,
      });
    }, 300);
  };

  return (
    <DraggableDialog
      open={state.show}
      onClose={handleClose}
      minWidth={400}
      minHeight={140}
      dimension={dimension}
      setDimension={setDimension}
    >
      <div>
        <DialogBuilder.Header className={'dragHandler'}>Create new view</DialogBuilder.Header>
        <DialogBuilder.Body>
          <VStack gap="6px">
            <StyledTextField
              fullWidth
              autoFocus
              error={!title}
              variant="filled"
              placeholder="Type title here..."
              value={title}
              style={{ marginTop: '2px' }}
              onChange={(ev) => setTitle(ev.target.value)}
              onFocus={(ev) => {
                ev.currentTarget.select();
              }}
            />
            <Text variant="overline">Choose your layout</Text>
            <SegmentedWrapper justifyContent="start" gap="2px">
              {LAYOUTS.map((l) => {
                const selected = l === layout;
                const Icon = (selected ? ActiveLayoutIcons[l] : LayoutIcons[l]) ?? null;
                return (
                  <StyledLayout key={l} onClick={() => setLayout(l)} $selected={selected}>
                    <Icon className="skipOverride" />
                  </StyledLayout>
                );
              })}
            </SegmentedWrapper>
          </VStack>
        </DialogBuilder.Body>

        <DialogBuilder.Footer>
          <Tooltip title={!title ? 'Title is required' : ''}>
            <span>
              <Button
                ariaLabel="Create view"
                disabled={!title}
                onClick={() => {
                  state.onConfirm(title, layout);
                  handleClose();
                }}
                width={100}
                height={28}
                variant="contained"
              >
                Create
              </Button>
            </span>
          </Tooltip>
        </DialogBuilder.Footer>
      </div>
    </DraggableDialog>
  );
};

export default CreateViewDialog;
