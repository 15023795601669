export const chunkArray = <T>(array: T[], batchSize = 40) => {
  const result = [];
  for (let i = 0, len = array.length; i < len; i += batchSize)
    result.push(array.slice(i, i + batchSize));
  return result;
};

/**
 * @param  {Object[]} array
 * @param  {number} from - index
 * @param  {number} to - index
 */
export const moveArrayItem = <T>(array: T[], from: number, to: number) => {
  array.splice(to, 0, array.splice(from, 1)[0]);
  return array;
};

/**
 * @param  {Object[]} arr
 * @param  {} index - index where to insert
 * @param  {} newItem - new item
 */
export const insertIntoArray = <T>(arr: T[], index: number, newItem: T) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index),
];

/**
 * Merges two arrays and removes the duplicates
 * @param {Object[]} array1
 * @param {Object[]} array2
 * @param {string} key Property key used for removing duplicates
 */
export const mergeAndRemoveDuplicates = <T>(array1: T[], array2: T[], key: keyof T) =>
  array1
    .map((x) => {
      const y = array2.find((item) => x[key] === item[key]);
      if (y) {
        return { ...x, ...y };
      }
      return x;
    })
    .concat(array2.filter((item) => array1.every((x) => x[key] !== item[key])));
