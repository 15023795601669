/* eslint-disable react/destructuring-assignment */
import React from 'react';
import DebounceSCU from './debounceSCU';

class Debounce extends React.Component {
  constructor(props) {
    super(props);
    this.initialComponent = this.props.initialComponent;
    this.state = {
      shouldComponentUpdate: false,
    };
  }

  componentDidMount() {
    this.initialComponent = undefined;
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      // Parent did render. E.g. input change
      clearTimeout(this.timer);
      this.timer = setTimeout(
        () => {
          clearTimeout(this.timer);
          this.setState({
            shouldComponentUpdate: true,
          });
        },
        this.props.ms === undefined ? 250 : this.props.ms,
      );
    } else if (this.state.shouldComponentUpdate === true) {
      // Prevent endless loops
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        shouldComponentUpdate: false, // Reset state
      });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    return (
      <DebounceSCU shouldComponentUpdate={this.state.shouldComponentUpdate}>
        {this.initialComponent === undefined ? this.props.children : this.initialComponent}
      </DebounceSCU>
    );
  }
}

export default Debounce;
