import gql from 'graphql-tag';

export default gql`
  mutation CreateAssets($input: CreateAssetsInput) {
    createAssets(input: $input) {
      mId
      mRefId
      mAssetId
      mTitle
      mType
      mediaType
      mUpdatedAt
      mThumbnailKey
      mThumbnailUrl
      mContentKey
      mContentUrl
      itemType
      mMetaData {
        key
        value
      }
    }
  }
`;
