import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const CalendarWrapper = styled('div')`
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
`;

export const DisplayView = styled('div')`
  ${({ theme }) => css`
    ${theme.typography.dina.subtitle1};
    color: ${theme.palette.dina.highEmphasis};
  `};
  white-space: nowrap;
  outline: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const PickerWrapper = styled('div')`
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ScheduleView = styled('div')`
  ${({ theme, disabled }) => css`
    ${theme.typography.dina.listItemLabelMedium};
    color: ${disabled ? theme.palette.dina.disabled : theme.palette.dina.mediumEmphasis};
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
  `};
  outline: none;
`;
