import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

import { MIdRefId } from 'types/members';

import { removeFromOptionListCache } from './handleOptionListCache';

const DELETE_MEMBER = gql`
  mutation DeleteMember($input: DeleteSingleMemberInput) {
    deleteSingleMember(input: $input) {
      mId
      mRefId
    }
  }
`;

interface DeleteOptionList {
  deleteSingleMember: MIdRefId;
}

export const useDeleteOptionList = () => {
  const [deleteMember] = useMutation<DeleteOptionList>(DELETE_MEMBER);
  const deleteOptionList = useCallback(async (id: string) => {
    await deleteMember({
      variables: { input: { mId: 'optionList', mRefId: id } },
      update(proxy) {
        removeFromOptionListCache(proxy, id);
      },
    });
  }, []);

  return { deleteOptionList };
};
