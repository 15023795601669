import { useContext } from 'react';
import { useMutation } from '@apollo/client';
import UserContext from 'contexts/UserContext';
import UPDATE_CONVOREADAT from 'operations/mutations/updateConvoReadAt';

const useUpdateConvoReadAt = () => {
  const [updateConvoReadAt] = useMutation(UPDATE_CONVOREADAT);
  const user = useContext(UserContext);

  const updateConversation = async (convoId) => {
    await updateConvoReadAt({
      variables: {
        input: {
          mId: convoId,
          mRefId: user.mId,
        },
      },
    });
  };

  return [updateConversation];
};

export default useUpdateConvoReadAt;
