import styled from '@emotion/styled';

import Text from 'components/text';
import Tooltip from 'components/tooltip';
import { Color } from 'features/reusableStyled';
import { HStack } from 'layouts/box/Box';

import { PopoverColors } from './PopoverColors';

interface Props {
  selectedColor: string | null;
  hideLabel?: boolean;
  onColorChoice: (val: string) => void;
  onClearColor: () => void;
}

const ColorWrapper = styled(HStack)`
  cursor: pointer;
  :hover * {
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
`;

export function PopoverColorConfig({
  onColorChoice,
  onClearColor,
  selectedColor,
  hideLabel,
}: Readonly<Props>) {
  return (
    <PopoverColors
      onColorChoice={(c: string) => onColorChoice(c)}
      onClearColor={onClearColor}
      selectedColor={selectedColor ?? undefined}
    >
      <Tooltip title={!hideLabel ? 'Set a color' : ''}>
        <ColorWrapper padding="6px 0 6px 6px" gap="6px">
          <Color $color={selectedColor ?? undefined} $size={16} />
          {!hideLabel && (
            <Text variant="listItemLabel" className="checkbox-label">
              Set a color
            </Text>
          )}
        </ColorWrapper>
      </Tooltip>
    </PopoverColors>
  );
}
