import styled from '@emotion/styled';
import { css } from '@emotion/react';
import SplitPane from 'react-split-pane';

const Split = styled(SplitPane)`
  height: 100%;
  .Resizer {
    box-sizing: border-box;
    height: auto;
    background-clip: padding-box;
    transition: all 150ms ease-in-out;
    ${({ theme }) => css`
      background: ${theme.palette.dina.surfaceAppBackgroundNavLevel1};
      :hover,
      :active {
        background-color: ${theme.palette.dina.onFocus} !important;
        box-shadow: 0px 0px 0px 1px ${theme.palette.dina.onFocus} !important;
      }
    `}
  }
`;

export default Split;
