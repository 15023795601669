import { useState, useEffect, useRef } from 'react';
import { getSignedUrl } from 'utils/s3Utils';

const useGetSignedUrl = (key, skip) => {
  const [url, setUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const isSubscribed = useRef(null);

  useEffect(() => {
    isSubscribed.current = true;

    if (!key && url) setUrl(null);

    if (!skip && key) {
      (async () => {
        try {
          setLoading(true);
          setUrl(await getSignedUrl(key));
        } catch (err) {
          if (isSubscribed.current) {
            setUrl(null);
          }
        } finally {
          if (isSubscribed.current) setLoading(false);
        }
      })();
    } else {
      setLoading(false);
      setUrl(null);
    }

    return () => {
      isSubscribed.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, skip]);

  return { url, loading };
};

export default useGetSignedUrl;
