import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse, ListItem } from '@material-ui/core';
import ArrowDown from 'assets/icons/systemicons/arrows/disclosurearrow_down.svg';
import ArrowUp from 'assets/icons/systemicons/arrows/disclosurearrow_up.svg';
import useStyles from './groupHeader-styles';

const GroupHeader = ({ label, children, collapsed }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(collapsed);
  return (
    <div>
      <ListItem
        button
        onClick={() => setOpen(!open)}
        classes={{ root: classes.listItem }}
      >
        <div className={classes.contentContainer}>
          <div className={classes.labelContainer}>
            <div className={classes.groupRoot}>{label}</div>
            <img
              src={!open ? ArrowUp : ArrowDown}
              alt="expand/collapse icon"
              title="Expand / Collapse"
              className={classes.icon}
            />
          </div>
        </div>
      </ListItem>
      <Collapse in={!open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </div>
  );
};

GroupHeader.propTypes = {
  /** label to show on the input field */
  label: PropTypes.string,
  /** defines whether group header will be initially collapsed or not  */
  collapsed: PropTypes.bool,
  /** children to be rendered in collapsable group header */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
};

GroupHeader.defaultProps = {
  label: '',
  collapsed: false,
  children: null,
};

export default GroupHeader;
