import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';

import dndTypes from 'utils/dndTypes';
import { useOpenGroups } from 'store/sidebar';

import Group from './group-view';

const getIsGroupOpen = (openGroups, id) => {
  const group = openGroups[id];
  return group ? group.open : false;
};

const ListGroup = ({
  title,
  children,
  onEllipsis,
  id,
  hasMore,
  handleCreateClicked,
  type,
  onLoadMore,
  toolTipTitle,
  isOpen,
  disableCollapse,
  ...rest
}) => {
  const [openGroups, setOpenGroups] = useOpenGroups();
  const [isGroupOpen, setIsGroupOpen] = useState(disableCollapse || getIsGroupOpen(openGroups, id)); //force expand if disableCollapse is set to true

  const [{ hovered }, dropRef] = useDrop({
    accept: [dndTypes.STORY, dndTypes.CONTACT],
    drop: (item) => item,
    collect: (monitor) => ({ hovered: monitor.isOver() }),
  });

  const toggleGroupOpen = useCallback(() => {
    if (disableCollapse) return;
    const groups = openGroups;
    groups[id] = { open: !getIsGroupOpen(openGroups, id) };
    setOpenGroups(groups);
    setIsGroupOpen(!isGroupOpen);
  }, [id, isGroupOpen, openGroups, setOpenGroups]);

  useEffect(() => {
    if (hovered && getIsGroupOpen(openGroups, id)) toggleGroupOpen();
  }, [hovered, id, openGroups, toggleGroupOpen]);

  useEffect(() => {
    const groups = openGroups;
    const group = groups[id];
    if (!group) {
      groups[id] = { open: isOpen };
      setOpenGroups(groups);
      setIsGroupOpen(isOpen);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={dropRef}>
      <Group
        open={isGroupOpen}
        title={title}
        onEllipsis={onEllipsis}
        toggleListOpen={toggleGroupOpen}
        toolTipTitle={toolTipTitle}
        handleCreateClicked={handleCreateClicked}
        type={type}
        onLoadMore={onLoadMore}
        hasMore={hasMore}
        disableCollapse={disableCollapse}
        {...rest}
      >
        {children}
      </Group>
    </div>
  );
};

ListGroup.propTypes = {
  /** Title of the list group */
  title: PropTypes.string,
  /** List items */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
  /** should the header have options? */
  onEllipsis: PropTypes.bool,
  /** group id */
  id: PropTypes.string,
  /** has more token */
  hasMore: PropTypes.bool,
  /** load more function */
  onLoadMore: PropTypes.func,
};

ListGroup.defaultProps = {
  title: 'Group',
  children: null,
  onEllipsis: false,
  id: '',
  hasMore: false,
  onLoadMore: () => {},
};

export default ListGroup;
