const DefaultTemplateKey = 'defaultTemplateKey';

/**
 * Returns a settings key for retrieving the default template for an instance.
 * @param platform - Instance platform
 * @param variant - Instance platform variant id (SoMe) or platformKind (linear)
 */
export const getInstanceDefaultTemplateKey = (platform?: string, variant?: string) => {
  if (!platform) return `instance.${DefaultTemplateKey}`;
  if (!variant || variant === platform) {
    return `instance.${platform}.${DefaultTemplateKey}`;
  }
  return `instance.${platform}.${variant}.${DefaultTemplateKey}`;
};
