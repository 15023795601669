import React from 'react';
import PropTypes from 'prop-types';
import Divider from 'components/divider';
import { Collapse as MaterialCollapse, Typography } from '@material-ui/core';
import { ReactComponent as ArrowUp } from 'assets/icons/systemicons/arrows/disclosurearrow_up.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/systemicons/arrows/disclosurearrow_down.svg';

import useStyles from './collapse-styles';

const Collapse = ({ children, title, open, setOpen, header }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.header} onClick={() => setOpen(!open)} role="presentation">
        <div className={classes.arrows}>
          {open ? <ArrowUp /> : <ArrowDown />}
          <Typography className={`${classes.headerTitle} ${classes.unselectable}`}>
            {title}
          </Typography>
        </div>
        {header}
      </div>
      <MaterialCollapse in={open} timeout="auto" mountOnEnter>
        <Divider />
        {children}
      </MaterialCollapse>
    </div>
  );
};

Collapse.propTypes = {
  /** Title to be shown in header */
  title: PropTypes.string,
  /** Elements to be shown in the far right in header */
  header: PropTypes.element,
};

Collapse.defaultProps = {
  title: '',
  header: undefined,
};

export default Collapse;
