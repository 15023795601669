import { Svg, Rect, Path } from '@react-pdf/renderer';

const VideoClipIcon = () => {
  return (
    <Svg style={{ width: 32, height: 32, marginRight: 8 }} viewBox='0 0 32 32'>
      <Rect
        x='0.5'
        y='0.5'
        width='31'
        height='31'
        rx='5.5'
        ry='5.5'
        fill='#89B6F9'
        stroke='black'
      />
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 7C7.89543 7 7 7.89543 7 9V23C7 24.1046 7.89543 25 9 25H23C24.1046 25 25 24.1046 25 23V9C25 7.89543 24.1046 7 23 7H9ZM14 19L19.1429 16L14 13V19ZM9 9H11V11H9V9ZM23 9H21V11H23V9ZM9 12H11V14H9V12ZM23 12H21V14H23V12ZM9 15H11V17H9V15ZM23 15H21V17H23V15ZM9 18H11V20H9V18ZM23 18H21V20H23V18ZM9 21H11V23H9V21ZM23 21H21V23H23V21Z'
        fill='#0F141A'
        fillOpacity='0.87'
      />
    </Svg>
  );
};

export default VideoClipIcon;
