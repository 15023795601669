/* eslint-disable sort-imports */
import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';

import {
  ConfigurableActionMTypes,
  GetIntegrationInput,
  IntegrationEnum,
  IntegrationType,
  IntegrationUserType,
  ListActionType,
  MemberTypeEnum,
} from 'types/graphqlTypes';

import { STANDARD_ACTION_ADMIN_KEYS, STANDARD_ACTION_KEYS } from '../commonKeys';

export const GET_ADMIN_ACTIONS = gql`
  query GetActions($input: GetIntegrationInput) {
    getIntegrations(input: $input) {
      ${STANDARD_ACTION_ADMIN_KEYS}
    }
  }
`;

export const GET_USER_ACTIONS = gql`
  query GetActions($input: GetIntegrationInput) {
    getIntegrations(input: $input) {
      ${STANDARD_ACTION_KEYS}
    }
  }
`;

export interface GetAdminActions {
  getIntegrations: IntegrationType[];
}

export interface GetActions {
  getIntegrations: IntegrationUserType[];
}

interface GetInput {
  input: GetIntegrationInput;
}

export const useGetIntegrations = (integrationType: IntegrationEnum, isAllowed: boolean = true) => {
  const { data, error, loading, refetch } = useQuery<GetActions, GetInput>(GET_USER_ACTIONS, {
    variables: {
      input: {
        type: ListActionType.User,
        integrationType,
      },
    },
    fetchPolicy: 'cache-first',
    skip: !isAllowed,
  });

  const actionsByType = useMemo(() => {
    const dict: Record<ConfigurableActionMTypes, IntegrationUserType[]> = {
      [MemberTypeEnum.Contact]: [],
      [MemberTypeEnum.Instance]: [],
      [MemberTypeEnum.Order]: [],
      [MemberTypeEnum.Rundown]: [],
      [MemberTypeEnum.Story]: [],
      [MemberTypeEnum.ResStory]: [],
      [MemberTypeEnum.User]: [],
      [MemberTypeEnum.Pitch]: [],
      [MemberTypeEnum.ResPitch]: [],
      [MemberTypeEnum.Note]: [],
      [MemberTypeEnum.Block]: [],
      [MemberTypeEnum.Message]: [],
    };
    for (const action of data?.getIntegrations ?? []) {
      for (const type of action.mTypes ?? []) {
        dict[type].push(action);
      }
    }
    return dict;
  }, [data?.getIntegrations]);

  return { integrations: data?.getIntegrations ?? [], actionsByType, error, loading, refetch };
};

export const useGetIntegrationsForAdmin = (type: IntegrationEnum, skip = false) => {
  const { data, error, loading, refetch } = useQuery<GetAdminActions, GetInput>(GET_ADMIN_ACTIONS, {
    variables: {
      input: {
        type: ListActionType.Admin,
        integrationType: type,
      },
    },
    skip,
    fetchPolicy: 'cache-first',
  });

  return { integrations: data?.getIntegrations ?? [], error, loading, refetch };
};
