import { useCallback } from 'react';

import useCheckUserRight from 'hooks/useCheckUserRight';
import { Instance } from 'types';
import getIdentifier from 'utils/instance/getAccountIdentifier';

const useCheckPlatformPermission = () => {
  const [checkUserRight] = useCheckUserRight();
  const checkPlatformPermission = useCallback(
    (instance: Instance | null) => {
      if (!instance?.mProperties) return false;

      const platform = instance.mProperties.platform ?? '';
      if (platform === 'linear') return true;

      const { accountTitle = '' } = instance.mProperties.account ?? {};
      const accountIdentifier = getIdentifier(platform, accountTitle);

      return checkUserRight('platform', accountIdentifier);
    },
    [checkUserRight],
  );
  return { checkPlatformPermission };
};

export default useCheckPlatformPermission;
