import { useEffect, useState } from 'react';

import { ReactComponent as ArrowDown } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_down.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_right.svg';
import { TreeNode as TreeNodeProps } from 'components/editMdfDialog/utils';

import { isPrefixArray } from '../mdfEditor/fields/utils';

import {
  ArrowWrapper,
  NodeBody,
  NodeLabel,
  NodeLabelWrapper,
  StyledTreeNode,
  StyledTreeView,
} from './styled';

interface LocalTreeNodeProps {
  node: TreeNodeProps;
  path: string[];
  value: string[];
  setValue: (value: string[]) => void;
  search: string[];
}

function TreeNode({ node, path, value, setValue, search }: Readonly<LocalTreeNodeProps>) {
  const [isCollapsed, setIsCollapsed] = useState(!isPrefixArray(value, path));

  useEffect(() => {
    if (search) {
      if (isCollapsed && isPrefixArray(search, path)) setIsCollapsed(false);
    }
  }, [search, path]);

  const hasChild = node?.children.length > 0;

  return (
    <StyledTreeNode>
      <NodeBody>
        {hasChild && (
          <ArrowWrapper
            onClick={() => setIsCollapsed(!isCollapsed)}
            $match={isPrefixArray(value, path)}
          >
            {isCollapsed ? <ArrowRight /> : <ArrowDown />}
          </ArrowWrapper>
        )}
        <NodeLabelWrapper onClick={() => setValue(path)}>
          <NodeLabel
            variant="listItemLabel"
            color={JSON.stringify(value) === JSON.stringify(path) ? 'textUrl' : 'highEmphasis'}
            $match={JSON.stringify(path) === JSON.stringify(search)}
          >
            {node.value}
          </NodeLabel>
        </NodeLabelWrapper>
      </NodeBody>
      {!isCollapsed &&
        hasChild &&
        node.children.map((child) => (
          <StyledTreeView key={path.join('_') + '_' + child.value}>
            <TreeNode
              node={child}
              path={[...path, child.value]}
              value={value}
              setValue={setValue}
              search={search}
            />
          </StyledTreeView>
        ))}
    </StyledTreeNode>
  );
}

function TreeView({ node, value, ...rest }: Readonly<LocalTreeNodeProps>) {
  return (
    <StyledTreeView>
      {node.children.map((child) => (
        <TreeNode key={child.value} {...rest} value={value} path={[child.value]} node={child} />
      ))}
    </StyledTreeView>
  );
}

export default TreeView;
