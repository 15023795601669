import { forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { debounce } from 'lodash';

import { CommandInput } from 'lib/command';

interface Props {
  doReset: () => void;
  onDebouncedChange: (val: string) => void;
  showLoading: boolean;
  placeholder: string;
  startValue: string | undefined;
}

export interface CommandInputRef {
  clear: () => void;
  focus: () => void;
}

const CommandInputField = (
  { doReset, startValue, showLoading, placeholder, onDebouncedChange }: Props,
  ref: React.Ref<CommandInputRef>,
) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [inputValue, setInputValue] = useState(startValue ?? '');
  const debouncedChange = useRef(
    debounce((val: string) => {
      onDebouncedChange(val);
    }, 200),
  );

  useEffect(() => {
    debouncedChange.current(inputValue);
  }, [inputValue]);

  /**
   * Expose functions to its parent, without pulling inputValue state
   * up to the parent. This is done for performance reasons.
   */
  useImperativeHandle(ref, () => ({
    clear: () => {
      setInputValue('');
    },
    focus: () => {
      inputRef.current?.focus();
    },
  }));

  return (
    <CommandInput
      ref={inputRef}
      loading={showLoading}
      placeholder={placeholder}
      value={inputValue}
      onValueChange={setInputValue}
      onKeyUp={(ev) => {
        if (ev.currentTarget.value.length === 0 && ev.key === 'Backspace') {
          ev.preventDefault();
          doReset();
        }
      }}
    />
  );
};

export default memo(forwardRef(CommandInputField));
