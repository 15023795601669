/* eslint-disable import/no-dynamic-require */
import URLS from 'utils/constants/urls';
import images from 'assets/images/login/images';
import { ReactComponent as DinaIcon } from 'assets/images/DinaIcon.svg';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';

import { useTranslation } from 'react-i18next';
import { useLoading, useCurrentForm } from './store/atom';
import { DefaultLogin, ForceResetPassword, ForgotPassword, ResetPassword } from './forms';
import {
  Container,
  LeftContainer,
  LeftContentWrapper,
  IconWrapper,
  StyledCaption,
  FormWrapper,
  LinksWrapper,
  StyledDivider,
  RightContainer,
  RightContentWrapper,
  StyledLink,
} from './login-styled';

const { ABOUT, TERMS_CONDITIONS } = URLS;
const image = images[Math.floor(Math.random() * images.length)];

const FormComponent = () => {
  const [currentForm] = useCurrentForm();
  switch (currentForm) {
    case 'FORCE_RESET_PASSWORD':
      return <ForceResetPassword />;
    case 'FORGOT_PASSWORD':
      return <ForgotPassword />;
    case 'RESET_PASSWORD':
      return <ResetPassword />;
    case 'DEFAULT_LOGIN':
    default:
      return <DefaultLogin />;
  }
};

const Login = () => {
  const [loading] = useLoading();
  const { t } = useTranslation();

  return (
    <Container>
      <LeftContainer>
        <LeftContentWrapper>
          <IconWrapper>
            <DinaIcon />
            <StyledCaption>
              {t('loginPage.header.tagLine', 'Story production the smart way.')}
            </StyledCaption>
          </IconWrapper>
          <FormWrapper>
            <FormComponent />
          </FormWrapper>
          <LinksWrapper>
            <StyledLink href={ABOUT} target='_blank' underline='hover'>
              {t('loginPage.footer.aboutText', 'About Dina')}
            </StyledLink>
            <StyledDivider />
            <StyledLink href={TERMS_CONDITIONS} target='_blank' underline='hover'>
              {t('loginPage.footer.terms', 'Terms and Conditions')}
            </StyledLink>
          </LinksWrapper>
        </LeftContentWrapper>
      </LeftContainer>
      <RightContainer $imageUrl={image.src}>
        <RightContentWrapper>
          <StyledLink href={image.url} target='_blank' underline='hover'>
            {t('loginPage.footer.imageCredit', 'Photo by')} {image.credit}
          </StyledLink>
        </RightContentWrapper>
      </RightContainer>
      {loading && <LoadingIndicator />}
    </Container>
  );
};

export default Login;
