import { memo, useCallback, useState } from 'react';
import { useSlate } from 'slate-react';
import { v4 } from 'uuid';

import { DeleteDialog } from 'components/dialogs/CommonDialogs';
import DragAndDrop from 'components/editor/components/dragAndDrop';
import {
  decodeHTMLEntities,
  encodeHTMLEntities,
} from 'components/editor/components/html/utils/encoding';
import { useEditorContext } from 'components/editor/EditorContext';
import { removeBlock } from 'components/editor/utils';
import updateBlock from 'components/editor/utils/updateBlock';
import Text from 'components/text';
import { StyledTextField } from 'features/reusableStyled';
import { Box } from 'layouts/box/Box';
import { BlockElement } from 'types';

import HtmlActions from './HtmlActions';

import { HtmlBlock, HtmlContent, LeftSideWrapper } from './styled';

const createMarkup = (_html?: string) => {
  const input = _html?.replaceAll('\\', '');
  return {
    __html: `${input}`,
  };
};

const HTMLBlock = ({ attributes, children, element }: Readonly<BlockElement>) => {
  const [showHtml, setShowHtml] = useState(true);
  const [showDelete, setShowDelete] = useState(false);
  const data = element?.data ?? {};
  const { html: initialHtml } = data;
  const [htmlContent, setHtmlContent] = useState(decodeHTMLEntities(initialHtml || ''));
  const editor = useSlate();
  const { update, doLock } = useEditorContext();

  const updateHtml = useCallback(() => {
    const updatedData = {
      html: encodeHTMLEntities(htmlContent),
      itemId: v4(),
    };
    updateBlock(editor, element, updatedData, update!, false, undefined);
  }, [element, htmlContent]);

  const doRemoveBlock = useCallback(() => {
    doLock?.();
    removeBlock(editor, element, update!);
    setShowDelete(false);
  }, [updateBlock, setShowDelete, editor]);

  return (
    <HtmlBlock contentEditable={false} {...attributes}>
      <DragAndDrop element={element} isDragDisabled={false}>
        {children}
        <Box container alignItems="start" justifyContent="start">
          <LeftSideWrapper>
            {htmlContent.length > 0 ? (
              <HtmlContent
                $collapsed={!showHtml}
                dangerouslySetInnerHTML={createMarkup(htmlContent)}
              />
            ) : (
              <HtmlContent>Preview</HtmlContent>
            )}
            <HtmlActions
              element={element}
              showHtml={showHtml}
              setShowHtml={setShowHtml}
              onRemoveBlockClick={() => setShowDelete(true)}
            />
          </LeftSideWrapper>
          {showHtml && (
            <Box
              container
              flexDirection="column"
              width="100%"
              alignItems="start"
              padding="0 0 0 6px"
              height="100%"
            >
              <Text variant="avatarHeaderSubtitle">HTML content</Text>
              <StyledTextField
                fullWidth
                variant="filled"
                placeholder="Paste your HTML here"
                value={htmlContent}
                onChange={(e) => setHtmlContent(e.target.value)}
                onBlur={updateHtml}
                multiline
                minRows={11}
                maxRows={11}
              />
            </Box>
          )}
        </Box>
      </DragAndDrop>
      <DeleteDialog
        open={showDelete}
        onClose={() => setShowDelete(false)}
        onClick={doRemoveBlock}
        title="Remove block"
        message="Are you sure you want to remove the image block?"
        confirmLabel="Delete"
      />
    </HtmlBlock>
  );
};

export default memo(HTMLBlock);
