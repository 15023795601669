import React from 'react';
import { isThisMinute } from 'date-fns';
import { ReactComponent as CalendarOff } from 'assets/icons/systemicons/calendar_off.svg';
import { ReactComponent as CalendarOn } from 'assets/icons/systemicons/calendar_on.svg';
import { ReactComponent as TimeIcon } from 'assets/icons/systemicons/time.svg';
import Checkbox from 'components/checkbox';
import Divider from 'components/divider';
import Tooltip from 'components/tooltip';
import Picker from '../picker';
import { DatePicker, DateTime, ResetTextButton, TimePicker, FormControlLabel } from './styled';

const forcePublishTooltip =
  // eslint-disable-next-line max-len
  'Will publish the article on schedule time even if there is media that is not yet ready for publishing. When the media is ready, the article will automatically get updated.';
function ScheduleOptions({
  isCmsBlock,
  showExpiryDate,
  toggleShowExpiryDate,
  publishingTime,
  newPublishingTime,
  handleDateChange,
  isLinear,
  handleTimeChange,
  expiryTime,
  newExpiryTime,
  handleExpiryDateChange,
  handleExpiryTimeChange,
  handleForcePublishClick,
  forcePublish,
  onResetDateTime,
  publishNow,
  isCmsRepublish,
}) {
  return (
    <>
      {!isCmsRepublish && (
        <>
          <DateTime>
            <DatePicker>
              <Picker
                Icon={CalendarOff}
                SecondaryIcon={CalendarOn}
                displaySecondaryIcon={publishingTime || !isThisMinute(newPublishingTime)}
                type="date"
                label="Publish Date"
                timeValue={newPublishingTime}
                onChange={handleDateChange}
              />
            </DatePicker>
            <TimePicker>
              <Picker
                Icon={TimeIcon}
                type="time"
                label="Publish Time"
                timeValue={newPublishingTime}
                disable={isLinear}
                onChange={handleTimeChange}
              />
            </TimePicker>
          </DateTime>
          <ResetTextButton onClick={onResetDateTime} disabled={publishNow}>
            Set to Current Time
          </ResetTextButton>
        </>
      )}
      <FormControlLabel
        control={<Checkbox selected={showExpiryDate} onClick={toggleShowExpiryDate} size={24} />}
        label="Add Expiry Date"
      />
      {showExpiryDate && (
        <DateTime>
          <DatePicker>
            <Picker
              Icon={CalendarOff}
              SecondaryIcon={CalendarOn}
              displaySecondaryIcon={expiryTime || !isThisMinute(expiryTime)}
              type="date"
              label="Expiry Date"
              timeValue={newExpiryTime}
              onChange={handleExpiryDateChange}
              blankDisplayValue=""
            />
          </DatePicker>
          <TimePicker>
            <Picker
              Icon={TimeIcon}
              type="time"
              label="Expiry Time"
              timeValue={newExpiryTime}
              onChange={handleExpiryTimeChange}
              blankDisplayValue=""
            />
          </TimePicker>
        </DateTime>
      )}
      {isCmsBlock && (
        <>
          <Divider />
          <Tooltip title={forcePublishTooltip} maxWidth={386}>
            <FormControlLabel
              control={
                <Checkbox
                  selected={forcePublish === 'true'}
                  onClick={handleForcePublishClick}
                  size={24}
                />
              }
              label="Force Publish"
            />
          </Tooltip>
        </>
      )}
    </>
  );
}

export default ScheduleOptions;
