import { CellContext } from '@tanstack/react-table';

import Text from 'components/text/Text';
import { MemberType } from 'types/graphqlTypes';
import { isoToLocaleShort } from 'utils/datetimeHelpers';

type DateCellProps = Pick<CellContext<MemberType, string>, 'getValue'>;

export default function DateCell({ getValue }: Readonly<DateCellProps>) {
  const date = isoToLocaleShort(getValue());

  return (
    <Text variant="listItemLabel" truncate>
      {date}
    </Text>
  );
}
