import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { ReactComponent as StoryMarketOn } from 'assets/icons/systemicons/storymarket_small_on.svg';
import transientOptions from 'theme/helpers';
import { onImageLoadingError } from 'utils/image/imageUtils';

import { Sizes } from './styled';

interface ThumbnailProps {
  src?: string;
  isPitch?: boolean;
  size?: Sizes;
}

interface CommonProps {
  $size?: Sizes;
  $isPitch?: boolean;
}

const Container = styled('div', transientOptions)<CommonProps>`
  position: relative;
  ${({ $size }) =>
    $size === 'compact'
      ? css`
          width: 24px;
          height: 24px;
        `
      : css`
          width: 32px;
          height: 32px;
        `}
`;

const ThumbnailImg = styled('img', transientOptions)<CommonProps>`
  border-radius: 4px;
  object-fit: cover;
  ${({ $size, $isPitch }) => {
    if ($size === 'default' && $isPitch) {
      return css`
        width: 24px;
        height: 24px;
      `;
    }
    if ($size === 'compact' && $isPitch) {
      return css`
        width: 20px;
        height: 20px;
      `;
    }
    return css`
      width: 100%;
      height: 100%;
    `;
  }}
`;

const PitchIcon = styled(StoryMarketOn, transientOptions)<CommonProps>`
  position: absolute;
  bottom: 0px;
  right: 0px;
  background-color: ${({ theme }) => theme.palette.dina.statusStoryMarket};
  border-radius: 2px;
  ${({ $size }) =>
    $size === 'compact'
      ? css`
          width: 16px;
          height: 16px;
        `
      : css`
          width: 20px;
          height: 20px;
        `};
`;

function Thumbnail({ src, size, isPitch }: ThumbnailProps) {
  return (
    <Container $size={size}>
      <ThumbnailImg src={src} $size={size} onError={onImageLoadingError} $isPitch={isPitch} />
      {isPitch && <PitchIcon $size={size} className="skipOverride" />}
    </Container>
  );
}

export default Thumbnail;
