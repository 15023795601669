import styled from '@emotion/styled';

interface TextButtonProps {
  text: string;
  onClick: () => void;
  type?: 'danger' | 'emphasis';
}

const Button = styled('div')<{ color?: 'danger' | 'emphasis' }>`
  ${({ theme }) => theme.typography.dina.caption};
  cursor: pointer;
  color: ${({ theme, color }) =>
    color === 'danger' ? theme.palette.dina.danger : theme.palette.dina.mediumEmphasis};
  :hover {
    text-decoration-line: underline;
    color: ${({ theme, color }) =>
      color === 'danger' ? theme.palette.dina.danger : theme.palette.dina.highEmphasis};
  }
`;
function TextButton({ text, onClick, type }: Readonly<TextButtonProps>) {
  return (
    <Button color={type} onClick={onClick}>
      {text}
    </Button>
  );
}

export default TextButton;
