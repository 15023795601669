import { InnerWrapper, OuterWrapper } from '../styled';

interface WrapperProps {
  children: React.ReactNode;
}

export function ButtonGroup({ children }: Readonly<WrapperProps>) {
  return (
    <OuterWrapper>
      <InnerWrapper>{children}</InnerWrapper>
    </OuterWrapper>
  );
}
