import { useState } from 'react';

import useFuseSearch from 'hooks/useFuseSearch';
import { useUsers } from 'store/members';

import MemberList from './memberList/MemberList';
import Search from './Search';

function Users() {
  const search = useFuseSearch();
  const [users] = useUsers();

  const [searchString, setSearchString] = useState('');
  const searchedUsers = search(users, ['mTitle'], searchString);

  return (
    <MemberList
      members={searchedUsers}
      title="Users"
      search={<Search searchString={searchString} setSearchString={setSearchString} />}
    />
  );
}

export default Users;
