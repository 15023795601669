/**
 * Extracts id value given mos xml object
 *
 * @param {Object} xmlDoc Parsed mos xml object
 * @returns {String} id value
 */

const getMosId = (xmlDoc) => {
  let item = xmlDoc.querySelector('item');
  if (!item) item = xmlDoc.querySelector('mos');
  if (!item) return null;
  const id = item?.querySelector('mosID')?.textContent;
  return id;
};

export default getMosId;
