import { memo, useCallback } from 'react';
import { useTheme } from '@emotion/react';
import { Editor } from 'slate';
import { useFocused, useSlate } from 'slate-react';

import { ReactComponent as TextIcon } from 'assets/icons/systemicons/editor/text_off.svg';
import { textColors } from 'components/editor/constants';
import { getMark } from 'components/editor/utils';
import { MarkType } from 'components/editor/utils/getMark';

import { ButtonWrapper } from './styled';

const type: MarkType = 'color';

function ColorButton() {
  const theme = useTheme();
  const editor = useSlate();
  const isFocused = useFocused();

  const currentMark = getMark(editor, type);
  const markColor = currentMark ?? textColors[0];

  const handleColorChange = useCallback(
    (color: string) => {
      if (color.includes('ffffff') || color === markColor) {
        Editor.removeMark(editor, type);
        return;
      }

      Editor.addMark(editor, type, color);
    },
    [editor, markColor],
  );

  return (
    <>
      {textColors.map((textColor, index) => (
        <ButtonWrapper
          key={textColor}
          $isFocused={isFocused}
          $isActive={markColor === textColor}
          $textColor={index === 0 ? theme.palette.dina.iconActive : textColor}
        >
          <TextIcon className="skipOverride" onMouseDown={() => handleColorChange(textColor)} />
        </ButtonWrapper>
      ))}
    </>
  );
}

export default memo(ColorButton);
