import { css } from '@emotion/react';
import styled from '@emotion/styled';

import transientOptions from 'theme/helpers';

export const StyledParagraph = styled('p', transientOptions)<{ $locked: boolean }>`
  z-index: 100;
  margin: 0px;
  padding: 8px;
`;

export const DropZone = styled('div', transientOptions)<{ $isOver: boolean }>`
  border: 1px solid transparent;
  border-radius: 4px;
  ${({ $isOver, theme }) =>
    $isOver &&
    css`
      ::before {
        content: '';
        position: absolute;
        background-color: ${$isOver ? theme.palette.dina.onFocus : 'transparent'};
        width: calc(100% - 16px);
        left: 8px;
        bottom: 0;
        height: 3px;
      }
    `}
`;
