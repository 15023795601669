import { useRef } from 'react';

import ScheduleDatePicker from 'components/createNewV3/datePicker/scheduleDatePicker';
import NotificationPopup, { notificationIdentifiers } from 'components/notificationPopup';
import useToast from 'components/toast/useToast';
import AccessControl from 'features/accessControl';
import OrderButton from 'features/orderForm/components/OrderButton';
import useCheckUserRight, { useCanSeeOrderManagement } from 'hooks/useCheckUserRight';
import usePublishDateUpdate from 'hooks/usePublishDateUpdate';
import { Story } from 'types';

import Buttons from './Buttons';
import Dropdown from './Dropdown';
import Share from './Share';
import Title from './Title';

import { Options, TitleDropdownWrapper, ToolbarContainer, VDivider } from './styled';

interface ToolbarProps {
  story: Story;
  isPitch: boolean;
  canUpdateStory: boolean;
  isDescriptionSettingsHidden: boolean;
}

function Toolbar({
  story,
  isPitch,
  canUpdateStory,
  isDescriptionSettingsHidden,
}: Readonly<ToolbarProps>) {
  const [checkUserRight] = useCheckUserRight();
  const canChangeScheduleTime = checkUserRight('story', 'schedule-story');
  const canSelectRange = checkUserRight('feature', 'story-date-range');
  const { canAccessOrderManagement } = useCanSeeOrderManagement();

  const { errorToast } = useToast();

  const [updatePublishDate] = usePublishDateUpdate();

  const notificationRef = useRef(null);
  const canViewStory = story?.__typename !== 'RestrictedErrorType';

  return (
    <ToolbarContainer>
      <ScheduleDatePicker
        dateRange={
          story.mPublishingAt
            ? { startDate: story.mPublishingAt, endDate: story.mPublishingEnd ?? null }
            : null
        }
        onDateRangeChange={(dateRange) => {
          updatePublishDate(
            story.mId,
            dateRange?.startDate ?? null,
            dateRange?.endDate ?? null,
          ).catch((err) => errorToast(err, 'Unable to change schedule'));
        }}
        disableUpdate={!(canUpdateStory && canChangeScheduleTime)}
        canSelectRange={canSelectRange}
        transparent
        hideCloseIcon
      />
      <NotificationPopup
        position="bottom"
        anchor={notificationRef}
        id={notificationIdentifiers.TitleAndOption}
        title="The Story title can now be found on the toolbar!"
        text="To update the Story title, simply click on the text to edit it,
        just as easy as before."
      />
      <TitleDropdownWrapper ref={notificationRef}>
        <Title storyId={story.mId} storyTitle={story.mTitle} canUpdateStory={canUpdateStory} />
      </TitleDropdownWrapper>
      <Options>
        {canViewStory && canUpdateStory && (
          <>
            {canAccessOrderManagement && (
              <>
                <OrderButton
                  dark
                  resourceId={story?.mId ?? ''}
                  resourceTitle={story?.mTitle ?? ''}
                  resourceType={isPitch ? 'pitch' : 'story'}
                  formTypes={isPitch ? ['pitch'] : ['story']}
                />
                <VDivider />
              </>
            )}
            <Share storyId={story.mId} assignedMembers={story.mAssignedMembers} />
            <VDivider />
            <AccessControl
              mType={story?.mType ?? ''}
              storyId={story?.mId ?? ''}
              persistIconColor
              height={32}
            />
            {isPitch && <Buttons story={story} />}
            <Dropdown
              story={story}
              isPitch={isPitch}
              canUpdateStory={canUpdateStory}
              isDescriptionSettingsHidden={isDescriptionSettingsHidden}
            />
          </>
        )}
      </Options>
    </ToolbarContainer>
  );
}

export default Toolbar;
