import { useQuery } from '@apollo/client';

import GET_METADATA_FORMS from 'operations/queries/getMetadataForms';

const useGetLinks = () => {
  const { data, loading, error } = useQuery(GET_METADATA_FORMS, {
    variables: {
      input: {
        mId: 'links',
        mRefId: 'links',
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const links = data?.getMetadataForms?.find((l) => l.mRefId === 'links');

  return [links, loading, error];
};

export default useGetLinks;
