import { useCallback } from 'react';
import Resizer from 'react-image-file-resizer';

import { uploadToS3 } from 'utils/s3Utils';
import useLogger from 'utils/useLogger';

const resizeFile = (file: Blob) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      128,
      128,
      'JPEG',
      99,
      0,
      (uri) => {
        resolve(uri);
      },
      'file',
      128,
      128,
    );
  });

interface File extends Blob {
  name: string;
}

export const createThumbnail = async (file: File, log: (info: string) => void) => {
  try {
    const resized = await resizeFile(file);

    return new window.File([resized as BlobPart], `thumb_${file.name}.jpg`, {
      type: 'image/jpeg',
    });
  } catch (err) {
    log(`Failed to create thumbnail. Error: ${JSON.stringify(err)}`);
    return null;
  }
};

const useCreateAndUploadThumbnail: () => {
  createAndUploadThumbnail: (key: string, file: File) => Promise<void>;
} = () => {
  const logger = useLogger('useCreateAndUploadThumbnail hook');

  const uploadThumbnail = useCallback(async (key: string, thumbnail: File) => {
    try {
      await uploadToS3(key, thumbnail);
    } catch (err) {
      logger.log(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createAndUploadThumbnail = useCallback(async (key: string, file: File) => {
    if (file) {
      const thumbnail = await createThumbnail(file, logger.log);
      await uploadThumbnail(key, thumbnail || file);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { createAndUploadThumbnail };
};

export default useCreateAndUploadThumbnail;
