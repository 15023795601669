import { gql, useMutation } from '@apollo/client';

import { MemberType, UpdateMemberInput } from 'types/graphqlTypes';

import { BLOCK_PROPS } from './utils';

const UPDATE_BLOCK = gql`
  mutation UpdateBlockTitle($input: UpdateMemberInput) {
    updateMember(input: $input) {
      ${BLOCK_PROPS}
    }
  }
`;

interface UpdateBlockTitle {
  updateMember: MemberType;
}

interface UpdateBlockInput {
  input: UpdateMemberInput;
}

export const useUpdateBlockTitle = () => {
  const [update] = useMutation<UpdateBlockTitle, UpdateBlockInput>(UPDATE_BLOCK);

  const updateTitle = async (mId: string, mRefId: string, mTitle: string) => {
    const input: UpdateMemberInput = {
      mId,
      mRefId,
      mTitle,
    };

    return update({
      variables: {
        input,
      },
    });
  };

  return updateTitle;
};
