import { setIdleTimer, clearIdleTimer } from 'utils/idleTimer';
import { useForcedLogout } from 'store';

const useSessionExpirationHandler = () => {
  const [, setShowForcedLogout] = useForcedLogout();
  const StateId = {
    NORMAL: 'normal',
    IDLE1: 'idle1',
    IDLE2: 'idle2',
    FINAL: 'final',
  };

  const getStates = (session) => {
    const {
      sessionExpirationTime = 0,
      idlePeriod1ExpirationTime = 0,
      idlePeriod2ExpirationTime = 0,
      refreshTokenExpirationTime = 0,
    } = session?.mProperties || {};
    return {
      [StateId.NORMAL]: {
        expirationTime: new Date(sessionExpirationTime).getTime(),
        nextState: StateId.IDLE1,
      },
      [StateId.IDLE1]: {
        expirationTime: new Date(idlePeriod1ExpirationTime).getTime(),
        maxIdleTime: 60 * 60 * 1000, // 1 hour = 60 mins
        nextState: StateId.IDLE2,
      },
      [StateId.IDLE2]: {
        expirationTime: new Date(idlePeriod2ExpirationTime).getTime(),
        maxIdleTime: 5 * 60 * 1000, // 5 mins
        nextState: StateId.FINAL,
      },
      [StateId.FINAL]: {
        expirationTime: new Date(refreshTokenExpirationTime).getTime(),
        maxIdleTime: 15 * 1000, // 15 secs
      },
    };
  };

  let idleTimer = null;

  const initSessionState = ({ state, states, onTimeout }) => {
    const { expirationTime, maxIdleTime, nextState } = states[state];

    // eslint-disable-next-line no-console
    console.log(
      `session state=${state} exp=${new Date(
        expirationTime,
      )} maxIdle=${maxIdleTime} next=${nextState}`,
    );

    const currentTime = Date.now();
    if (expirationTime <= currentTime) {
      onTimeout?.();
      return;
    }

    const onExpired = () =>
      nextState ? initSessionState({ state: nextState, states, onTimeout }) : onTimeout?.();

    idleTimer = setIdleTimer({
      expirationTime: expirationTime - currentTime,
      maxIdleTime,
      getIdleTime: maxIdleTime ? getIdleTime : undefined,
      onTimeout,
      onExpired,
    });
  };

  const events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];

  let lastActivityTime = Date.now();
  const setLastActivityTime = () => {
    lastActivityTime = Date.now();
  };
  const getIdleTime = () => Date.now() - lastActivityTime;

  const handleExpiration = (session, logoutFunction) => {
    if (idleTimer) resetExpirationHandler();

    const states = getStates(session);
    initSessionState({
      state: StateId.NORMAL,
      states,
      onTimeout: () => {
        setShowForcedLogout(true);
      },
    });

    window.addEventListener('load', setLastActivityTime, true);
    events.forEach((name) => document.addEventListener(name, setLastActivityTime, true));
    setLastActivityTime();
  };

  const resetExpirationHandler = () => {
    if (idleTimer) clearIdleTimer(idleTimer);
    idleTimer = null;

    window.removeEventListener('load', setLastActivityTime, true);
    events.forEach((name) => document.removeEventListener(name, setLastActivityTime, true));
  };

  return [handleExpiration, resetExpirationHandler];
};

export default useSessionExpirationHandler;
