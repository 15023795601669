import { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';

import useToast from 'components/toast/useToast';
import { useCanAdministrateSearches } from 'hooks/useCheckUserRight';
import { CreateSavedSearch, SavedSearchEntity, VisiblePlaces } from 'types/graphqlTypes';

import { CREATE_SAVED_SEARCH } from './gql';
import { writeToCache } from './handleCacheUpdate';

interface ICreateSavedSearch {
  createSavedSearch: SavedSearchEntity;
}

export const useCreateSavedSearch = (location?: VisiblePlaces) => {
  const { errorToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [createMutation] = useMutation<ICreateSavedSearch>(CREATE_SAVED_SEARCH);
  const { canAdministrate } = useCanAdministrateSearches();

  const createSavedSearch = useCallback(
    async (input: CreateSavedSearch) => {
      try {
        setLoading(true);
        await createMutation({
          variables: {
            input,
          },
          update: (proxy, mutationResult) => {
            const newEntity = mutationResult.data?.createSavedSearch;
            if (newEntity) writeToCache(proxy, newEntity, canAdministrate);
          },
        });
      } catch (err) {
        errorToast(err);
      } finally {
        setLoading(false);
      }
    },
    [createMutation, location],
  );
  return { createSavedSearch, loading };
};
