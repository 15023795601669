import gql from 'graphql-tag';

export default gql`
  mutation CreateSession($input: CreateSessionInput) {
    createSession(input: $input) {
      mId
      mRefId
      mTitle
      mType
      mState
      mProperties {
        __typename
        ... on SessionType {
          sessionExpirationTime
          refreshTokenExpirationTime
          idlePeriod1ExpirationTime
          idlePeriod2ExpirationTime
        }
      }
    }
  }
`;
