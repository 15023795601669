import { isEmptyParagraph } from 'components/editor/components/paragraph/utils/isParagraph';
import { EditorValue } from 'types';

const removeTrailingEmptyParagraphs = (editorValue: EditorValue): EditorValue => {
  const childrenNumber = editorValue.document.length;

  if (childrenNumber < 2) return editorValue;

  const lastNode = editorValue.document[childrenNumber - 1];
  if (!isEmptyParagraph(lastNode)) return editorValue;

  const newEditorValue: EditorValue = { ...editorValue };
  newEditorValue.document = editorValue.document.slice(0, childrenNumber - 1);
  return removeTrailingEmptyParagraphs(newEditorValue);
};

export default removeTrailingEmptyParagraphs;
