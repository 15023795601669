import React, { useState, useCallback, useEffect, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { ClickAwayListener } from '@material-ui/core';
import Fuse from 'fuse.js';
import { RootWrapper, Input, Paper, List, MenuItem } from './styled';

const handleEvent = (event) => {
  event.preventDefault();
  event.stopPropagation();
};

const AutoComplete = ({ className, options, selectedOption, onUpdate }) => {
  const [inputValue, setInputValue] = useState(selectedOption.name || '');
  const [isListOpen, setIsListOpen] = useState(false);
  const [cursor, setCursor] = useState(0);
  const fuse = useMemo(() => new Fuse(options, { keys: ['name'] }), [options]);

  const filteredOptions = useMemo(
    () => fuse.search(inputValue).map(({ item }) => item),
    [fuse, inputValue],
  );

  const optionCount = filteredOptions.length;
  const showSuggestions = isListOpen && optionCount > 0;

  useEffect(() => setCursor(0), [optionCount]);

  useEffect(() => setInputValue(selectedOption.name || ''), [selectedOption.name]);

  const openList = useCallback(() => setIsListOpen(true), []);
  const closeList = useCallback(() => setIsListOpen(false), []);

  const onEnter = useCallback(
    (event, value) => {
      handleEvent(event);

      if (isListOpen) {
        closeList();

        const newOption = value || filteredOptions[cursor];

        setInputValue(newOption.name);
        onUpdate(newOption);
      }
    },
    [closeList, cursor, filteredOptions, isListOpen, onUpdate],
  );

  const onEscape = useCallback(
    (event) => {
      handleEvent(event);
      closeList();
    },
    [closeList],
  );

  const goForward = useCallback(
    () =>
      setCursor((previousCursor) => (previousCursor + 1 === optionCount ? 0 : previousCursor + 1)),
    [optionCount],
  );

  const goBackward = useCallback(
    () =>
      setCursor((previousCursor) =>
        previousCursor - 1 < 0 ? optionCount - 1 : previousCursor - 1,
      ),
    [optionCount],
  );

  const onArrowDown = useCallback(
    (event) => {
      handleEvent(event);
      goForward();
    },
    [goForward],
  );

  const onArrowUp = useCallback(
    (event) => {
      handleEvent(event);
      goBackward();
    },
    [goBackward],
  );

  const onInputKeyDown = useCallback(
    (event) => {
      switch (event.key) {
        case 'ArrowDown':
          onArrowDown(event);
          break;

        case 'ArrowUp':
          onArrowUp(event);
          break;

        case 'Enter':
          onEnter(event);
          break;

        case 'Escape':
          onEscape(event);
          break;

        default:
          openList();
          break;
      }
    },
    [onArrowDown, onArrowUp, onEnter, onEscape, openList],
  );

  const onInputChange = useCallback((event) => setInputValue(event.target.value), []);

  return (
    <RootWrapper>
      <Input
        className={className}
        value={inputValue}
        onChange={onInputChange}
        onKeyDown={onInputKeyDown}
      />

      {showSuggestions && (
        <ClickAwayListener onClickAway={closeList}>
          <Paper>
            <List>
              {filteredOptions.map(({ name, value }, index) => (
                <MenuItem
                  dense
                  selected={index === cursor}
                  key={name}
                  onClick={(event) => onEnter(event, { name, value })}
                >
                  {name}
                </MenuItem>
              ))}
            </List>
          </Paper>
        </ClickAwayListener>
      )}
    </RootWrapper>
  );
};

AutoComplete.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  selectedOption: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  onUpdate: PropTypes.func,
};

AutoComplete.defaultProps = {
  options: [],
  selectedOption: {},
  onUpdate: (newOption) => {},
};

export default memo(AutoComplete);
