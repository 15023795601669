import styled from '@emotion/styled';

export const RelationFieldWrapper = styled('div')`
  display: flex;
  min-width: 200px;
  margin-bottom: 4px;
  flex-direction: column;
  margin-top: 5px;
  gap: 5px;
  overflow: auto;
  max-height: 300px;
`;
