import { ConfigurableActionMTypes, MemberTypeEnum } from 'types/graphqlTypes';

export const configurableActionMTypes: ConfigurableActionMTypes[] = [
  MemberTypeEnum.Story,
  MemberTypeEnum.ResStory,
  MemberTypeEnum.Note,
  MemberTypeEnum.Rundown,
  MemberTypeEnum.Instance,
  MemberTypeEnum.Pitch,
  MemberTypeEnum.ResPitch,
  MemberTypeEnum.Order,
  MemberTypeEnum.Contact,
  MemberTypeEnum.User,
  MemberTypeEnum.Block,
  MemberTypeEnum.Message,
];

export type ConfigurableActionMTypeHook = {
  type: ConfigurableActionMTypes;
  functional: boolean;
};

export const configurableActionMTypesHook: ConfigurableActionMTypeHook[] = [
  { type: MemberTypeEnum.Story, functional: true },
  { type: MemberTypeEnum.Pitch, functional: true },
  { type: MemberTypeEnum.Rundown, functional: true },
  { type: MemberTypeEnum.Instance, functional: true },
  { type: MemberTypeEnum.Order, functional: true },
  { type: MemberTypeEnum.Contact, functional: true },
  { type: MemberTypeEnum.Message, functional: true },
  { type: MemberTypeEnum.Note, functional: false },
  { type: MemberTypeEnum.User, functional: false },
];

export const isConfigurableActionType = (
  obj: MemberTypeEnum | null | undefined,
): obj is ConfigurableActionMTypes => {
  if (!obj) return false;
  return configurableActionMTypes.includes(obj as ConfigurableActionMTypes);
};

export type ConfigurableHookMTypes = {
  type: ConfigurableActionMTypes;
  functional: boolean;
};

export const configurableHookMTypes: ConfigurableHookMTypes[] = [
  { type: MemberTypeEnum.Story, functional: true },
  { type: MemberTypeEnum.Pitch, functional: true },
  { type: MemberTypeEnum.Rundown, functional: true },
  { type: MemberTypeEnum.Instance, functional: true },
  { type: MemberTypeEnum.Order, functional: true },
  { type: MemberTypeEnum.Block, functional: true },
  { type: MemberTypeEnum.Contact, functional: true },
  { type: MemberTypeEnum.Note, functional: false },
  { type: MemberTypeEnum.User, functional: false },
  { type: MemberTypeEnum.Message, functional: true },
];

export const TypeToLabel: Record<ConfigurableActionMTypes, string> = {
  [MemberTypeEnum.Story]: 'Story',
  [MemberTypeEnum.ResStory]: 'Restricted story',
  [MemberTypeEnum.Note]: 'Note',
  [MemberTypeEnum.Rundown]: 'Rundown',
  [MemberTypeEnum.Instance]: 'Instance',
  [MemberTypeEnum.Pitch]: 'Pitch',
  [MemberTypeEnum.ResPitch]: 'Restricted pitch',
  [MemberTypeEnum.Order]: 'Task',
  [MemberTypeEnum.Block]: 'Planning',
  [MemberTypeEnum.Contact]: 'Contact',
  [MemberTypeEnum.User]: 'User',
  [MemberTypeEnum.Message]: 'Message',
};
