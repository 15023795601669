import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import {
  Divider as MaterialDivider,
  List as MaterialList,
  ListItem as MaterialListItem,
  ListItemText as MaterialListItemText,
} from '@material-ui/core';
import { ReactComponent as Add } from 'assets/icons/systemicons/add_small.svg';
import transientOptions from 'theme/helpers';

export const AddIcon = styled(Add)`
  margin: 0px 5px;
`;

export const List = styled(MaterialList)`
  background-color: ${({ theme }) => theme.palette.dina.surfaceCard};
  border-radius: 8px;
  backdrop-filter: blur(30px);
  width: 400px;
  padding: 8px 0;
  max-height: 85vh;
  overflow: auto;
`;

const getWrapperStyles = (theme, disabled = false) => css`
  opacity: 0;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translate3d(0, -12px, 0);
  cursor: ${disabled ? 'not-allowed' : 'pointer'};
  svg * {
    fill: ${theme.palette.dina.highEmphasis};
    fill-opacity: ${disabled ? 0.25 : 0.54};
  }
  :hover {
    svg * {
      fill: ${theme.palette.dina.highEmphasis};
      fill-opacity: ${disabled ? 0.54 : 1};
    }
  }
`;

export const OverwriteWrapper = styled('div')`
  ${({ theme, $disabled }) => getWrapperStyles(theme, $disabled)}

  /** positionFromRight depends on users permission, can be one of 0/1/2 */
  right: ${({ positionFromRight = 2 }) => {
    if (positionFromRight === 2) return '64px';
    if (positionFromRight === 1) return '40px';
    return '0';
  }};
`;

export const DeleteWrapper = styled('div')`
  ${({ theme, $disabled }) => getWrapperStyles(theme, $disabled)}
`;

export const DefaultWrapper = styled('div')`
  ${({ theme, $disabled }) => getWrapperStyles(theme, $disabled)}
  /** positionFromRight depends on users permission, can be one of 0/1 */
  right: ${({ positionFromRight = 1 }) => {
    if (positionFromRight === 1) return '40px';
    return '0';
  }};
`;

export const DefaultIconWrapper = styled('div')`
  ${({ theme, $disabled }) => getWrapperStyles(theme, $disabled)}
  opacity: 1;
  display: block;
`;

export const Divider = styled(MaterialDivider)`
  background: ${({ theme }) => theme.palette.dina.dividerLight};
  margin: 4px 8px;
`;

export const DragHandleWrapper = styled('div')`
  z-index: 1;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translate3d(6px, -12px, 0);
  :hover {
    cursor: grab;
    path {
      fill-opacity: 1;
    }
  }
  :active {
    cursor: grabbing;
    path {
      fill-opacity: 1;
    }
  }
`;

export const ListItemWrapper = styled('div')`
  position: relative;
  display: flex;

  :hover {
    ${DeleteWrapper} {
      opacity: 1;
    }
    ${DragHandleWrapper} {
      opacity: 1;
    }
    ${DefaultWrapper} {
      opacity: 1;
    }
    ${OverwriteWrapper} {
      opacity: 1;
    }
    ${DefaultIconWrapper} {
      display: none;
    }
    background-color: ${({ theme }) => theme.palette.dina.whiteHoverOverlay};
  }
  svg > * {
    fill-opacity: 0.54;
  }
`;

export const ListItem = styled(MaterialListItem, transientOptions)`
  &.MuiListItem-root {
    :hover {
      background-color: ${({ theme, $transparentHover }) =>
        $transparentHover ? 'transparent' : theme.palette.dina.whiteHoverOverlay};
    }
  }
`;

export const ListItemText = styled(MaterialListItemText)`
  .MuiListItemText-primary {
    ${({ theme }) => theme.typography.dina.listItemLabel}
    margin: 0px 40px 0px 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
