import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ListSubheader, List } from '@material-ui/core';
import EllipsisButton from 'components/buttons/ellipsisButton';
import Divider from 'components/divider';
import Popover from 'components/popover';
import { ReactComponent as Minus } from 'assets/icons/systemicons/minus.svg';
import { ReactComponent as Print } from 'assets/icons/systemicons/print.svg';
import { ReactComponent as Download } from 'assets/icons/systemicons/download.svg';
import { ReactComponent as History } from 'assets/icons/systemicons/time.svg';
import { ReactComponent as Duplicate } from 'assets/icons/systemicons/duplicate.svg';
import { ReactComponent as Delete } from 'assets/icons/systemicons/delete.svg';
import { ReactComponent as Padlock } from 'assets/icons/systemicons/padlock_off.svg';
import { ReactComponent as Copy } from 'assets/icons/systemicons/copy.svg';
import { isToday } from 'date-fns';
import { isBeforeToday } from 'screens/rundown/components/editor/utils';
import useGetPlatforms from 'hooks/useGetPlatforms';
import variants from 'utils/instance/variants';
import InstanceVersionHistory from 'features/versionHistory';
import Scrollbar from 'components/scrollbar';
import CategorizedMenu from 'components/menu/createInstanceMenu/MenuItem/CategorizedMenu';
import useGetAutomationTemplates from 'hooks/useGetAutomationTemplates';
import DeleteInstance from './components/deleteInstance';
import FoldersMenuList from '../foldersMenuList';
import MenuItem from '../menuItem';
import useCopyText from 'hooks/useCopyText';
import useStyles from './linear-ellipsis-menu-styles';
import useCheckUserRight from 'hooks/useCheckUserRight';
import { getPlatformIcon } from 'utils/instance/platform';
import { useInstanceMolecule } from 'features/instance/store/instance';
import { createInstanceLink } from 'utils/instance/createInstanceLink';

const topRight = {
  vertical: 'top',
  horizontal: 'right',
};

const centerLeft = {
  vertical: 'center',
  horizontal: 'left',
};

function GeneralMenuList({ canOpenStory, storyId, instanceId }) {
  const classes = useStyles();
  const { copyTooltip, onCopy } = useCopyText('Copy instance link');

  const onCopyClick = () => {
    if (!storyId || !instanceId) return;
    const linkToCopy = createInstanceLink(storyId, instanceId);
    onCopy(linkToCopy, 'Link copied!');
  };

  return (
    <List disablePadding classes={{ root: classes.menuItem }}>
      <ListSubheader classes={{ root: classes.listSubheader }}>GENERAL</ListSubheader>
      <MenuItem
        label="Copy Link"
        title={copyTooltip}
        disabled={!canOpenStory || !storyId || !instanceId}
        showArrow={false}
        icon={<Copy />}
        onClick={onCopyClick}
      />
    </List>
  );
}

const Rundowns = ({
  destinations = [],
  handleDuplicate,
  selectedDate,
  setSelectedDate,
  children,
  updatePosition,
}) => {
  return (
    <Scrollbar autoHeight autoHeightMin={0} autoHeightMax="60vh" top={8} bottom={8}>
      <CategorizedMenu
        onDateChanged={(e) => setSelectedDate(e?.endDate)}
        publishingAt={selectedDate}
        disableDecrement={isToday(selectedDate) || isBeforeToday(selectedDate)}
        items={destinations.map((destination) => destination.account)}
        onClose={(account) => {
          handleDuplicate(account.accountId);
        }}
        updateOnLoading={updatePosition}
        dense
        darker
      >
        {children}
      </CategorizedMenu>
    </Scrollbar>
  );
};

Rundowns.propTypes = {
  destinations: PropTypes.arrayOf(PropTypes.shape({})),
  handleDuplicate: PropTypes.func,
  selectedDate: PropTypes.string,
  setSelectedDate: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  updatePosition: PropTypes.func,
};

const DuplicateInstanceMenu = ({ schedule, handleDuplicate, platformKind, disabled }) => {
  const classes = useStyles();
  const isScheduledBeforeToday = isBeforeToday(schedule);
  const initialSelectedDate = isScheduledBeforeToday ? new Date() : schedule;
  const [selectedDate, setSelectedDate] = useState(initialSelectedDate);
  const { error, loading, destinations } = useGetPlatforms(
    selectedDate || initialSelectedDate,
    variants.LINEAR,
    platformKind,
  );

  const actionRef = useRef(null);

  const anchorRef = useRef(null);

  const [dialogOpen, setDialogOpen] = useState(false);

  const PlatformIcon = getPlatformIcon(variants.LINEAR, platformKind);

  const updatePosition = () => actionRef?.current?.updatePosition();

  return (
    <List disablePadding classes={{ root: classes.menuItem }} ref={anchorRef}>
      <MenuItem
        icon={<Duplicate />}
        label="Duplicate Instance"
        onClick={() => setDialogOpen(true)}
        disabled={disabled || loading}
      />
      <Popover
        action={actionRef}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={dialogOpen}
        anchorEl={dialogOpen && anchorRef.current}
        onClose={() => setDialogOpen(false)}
      >
        <div style={{ width: '300px' }}>
          <Rundowns
            destinations={!error ? destinations : []}
            handleDuplicate={handleDuplicate}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            updatePosition={updatePosition}
          >
            <PlatformIcon />
          </Rundowns>
        </div>
      </Popover>
    </List>
  );
};

DuplicateInstanceMenu.propTypes = {
  schedule: PropTypes.string,
  handleDuplicate: PropTypes.func,
  platformKind: PropTypes.string,
  disabled: PropTypes.bool,
};

const LinearEllipsisMenuView = ({
  onSaveTemplate,
  onSelectTemplate,
  onDeleteTemplate,
  onCreateFolder,
  onDeleteFolder,
  onCreateDuplicate,
  onRemove,
  onDownload,
  onForceUnlock,
  folders,
  hideTemplateOptions,
  hideAutomationTemplates,
  lockedByUser,
  canCreateNewTemplate,
  canDeleteTemplate,
  canDeleteTemplateFolder,
  canPinTemplateFolder,
  canReorderTemplates,
  onDeleteInstance,
  isDeleteEnabled,
  disableEdit,
  title,
  instanceId,
  onRestoreVersion,
  checkVersionRestorability,
  isSavingContent,
  canCreateInstance,
  canScheduleInstance,
  canDeleteInstance,
  canUpdateInstance,
  schedule,
  platformKind,
  canSetDefaultTemplate,
  defaultTemplateRefId,
  onSetDefaultTemplate,
  isContentLoaded,
  isLockedByAnotherUser,
  onClientPrint,
  canOpenStory,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchor, setAnchor] = useState(null);
  const [popoverComponent, setPopoverComponent] = useState(null);
  const [historyDialogOpen, setHistoryDialogOpen] = useState(false);
  const { selectTemplateSet, templateSets, templateSetIndex } = useGetAutomationTemplates();
  const [checkUserRight] = useCheckUserRight();
  const shoultPrintFromClient = checkUserRight('feature', 'client-print');
  const { useInstanceValue } = useInstanceMolecule();
  const instance = useInstanceValue();

  // TODO: AC3 MIGRATION
  // new items to tackle immutability issue while migration

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handlePrintInstance = () => {
    closeMenu();
    if (shoultPrintFromClient) {
      onClientPrint();
    } else {
      onDownload('application/pdf');
    }
  };
  const handleDownloadInstance = () => {
    closeMenu();
    onDownload('application/xml');
  };

  const handleForceUnlock = () => {
    closeMenu();
    onForceUnlock();
  };

  const handleRemove = () => {
    closeMenu();
    onRemove();
  };

  const handleHistory = async () => {
    if (canUpdateInstance) {
      setHistoryDialogOpen(true);
    }
  };

  const handleRestoreVersion = async (content) => {
    await onRestoreVersion(content);
    setHistoryDialogOpen(false);
    setAnchorEl(null);
  };

  const handleDelete = () => {
    handleClosePopover();
    closeMenu();
    onDeleteInstance();
  };
  const OpenDelete = () => {
    setAnchor(anchorEl);
    setPopoverComponent(
      <DeleteInstance
        onCancel={handleClosePopover}
        onOk={handleDelete}
        isDeleteEnabled={isDeleteEnabled}
      />,
    );
  };

  const handleClosePopover = () => {
    setAnchor(null);
    setPopoverComponent(null);
  };

  const handleDuplicate = (rundownId) => {
    onCreateDuplicate(rundownId);
    closeMenu();
  };

  const handleOpenPopover = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    handleClosePopover();
  };

  const moreOptions = [
    {
      icon: <Minus />,
      label: 'Remove from Rundown',
      callback: handleRemove,
      disabled: true,
    },
    {
      icon: <Delete />,
      label: 'Delete Instance',
      callback: OpenDelete,
      disabled: disableEdit || !canDeleteInstance,
    },
    {
      icon: <Print />,
      label: 'Print Instance',
      callback: handlePrintInstance,
      disabled: false,
    },
    {
      icon: <Download />,
      label: 'Download Instance',
      callback: handleDownloadInstance,
      disabled: false,
    },
    {
      icon: <Padlock />,
      label: 'Force unlock',
      callback: handleForceUnlock,
      disabled: disableEdit,
    },
  ];

  return (
    <div>
      {historyDialogOpen && (
        <InstanceVersionHistory
          title={title}
          id={instanceId}
          open={historyDialogOpen}
          onCancel={() => setHistoryDialogOpen(false)}
          onOk={handleRestoreVersion}
          checkVersionRestorability={checkVersionRestorability}
          lockedByUser={lockedByUser}
          isSavingContent={isSavingContent}
        />
      )}
      <div>
        <EllipsisButton onClick={handleOpenPopover} />
        <Popover
          anchorEl={anchorEl}
          onClose={closeMenu}
          anchorOrigin={topRight}
          transformOrigin={topRight}
        >
          <GeneralMenuList
            storyId={instance?.mStoryId}
            instanceId={instance.mId}
            canOpenStory={canOpenStory}
          />
          <div className={classes.menu}>
            {!hideTemplateOptions && (
              <>
                <FoldersMenuList
                  anchorEl={anchorEl}
                  folders={folders}
                  canCreateNewTemplate={canCreateNewTemplate}
                  canDeleteTemplateFolder={canDeleteTemplateFolder}
                  canDeleteTemplate={canDeleteTemplate}
                  canPinTemplateFolder={canPinTemplateFolder}
                  canReorderTemplates={canReorderTemplates}
                  isLockedByAnotherUser={isLockedByAnotherUser}
                  onDeleteFolder={onDeleteFolder}
                  closeMenu={closeMenu}
                  onSaveTemplate={onSaveTemplate}
                  onSelectTemplate={onSelectTemplate}
                  onDeleteTemplate={onDeleteTemplate}
                  onCreateFolder={onCreateFolder}
                  canSetDefaultTemplate={canSetDefaultTemplate}
                  defaultTemplateRefId={defaultTemplateRefId}
                  onSetDefaultTemplate={onSetDefaultTemplate}
                  isContentLoaded={isContentLoaded}
                />
                <Popover
                  noMargin
                  anchorEl={anchor}
                  anchorOrigin={centerLeft}
                  transformOrigin={topRight}
                  onClose={handleClosePopover}
                >
                  {popoverComponent}
                </Popover>
              </>
            )}
            {!hideAutomationTemplates && (
              <>
                <Divider className={classes.divider} />
                <List disablePadding classes={{ root: classes.menuItem }}>
                  <ListSubheader classes={{ root: classes.listSubheader }}>
                    Automation template sets
                  </ListSubheader>

                  <MenuItem
                    label={templateSets[templateSetIndex]?.title || 'Select template set'}
                    onClick={(event) => {
                      setAnchor(event.currentTarget);
                      setPopoverComponent(
                        <List disablePadding className={classes.templateSetMenu}>
                          {templateSets.map(({ title: templateSetTitle, value }) => (
                            <MenuItem
                              key={value}
                              label={templateSetTitle}
                              selected={value === templateSetIndex}
                              onClick={() => {
                                selectTemplateSet(value);
                                setAnchor(null);
                              }}
                              showSecondaryItem={false}
                            />
                          ))}
                        </List>,
                      );
                    }}
                  />
                </List>
              </>
            )}
            <Divider className={classes.divider} />
            <List disablePadding classes={{ root: classes.menuItem }}>
              <ListSubheader classes={{ root: classes.listSubheader }}>MORE OPTIONS</ListSubheader>
              <MenuItem
                key="History"
                icon={<History />}
                label="History"
                onClick={handleHistory}
                showSecondaryItem={false}
                disabled={!canUpdateInstance}
              />
              <DuplicateInstanceMenu
                schedule={schedule}
                setAnchor={setAnchor}
                handleDuplicate={handleDuplicate}
                setPopoverComponent={setPopoverComponent}
                platformKind={platformKind}
                disabled={disableEdit || !canCreateInstance || !canScheduleInstance}
              />
              {moreOptions.map(({ icon, label, callback, disabled }) => (
                <MenuItem
                  key={label}
                  icon={icon}
                  label={label}
                  onClick={callback}
                  showSecondaryItem={false}
                  disabled={disabled}
                />
              ))}
            </List>
          </div>
        </Popover>
      </div>
    </div>
  );
};

LinearEllipsisMenuView.propTypes = {
  /** Callback to be invoked on template selection,
   * with the selected template passed in
   */
  onSelectTemplate: PropTypes.func,
  /** Callback to be invoked when save as template option is clicked,
   * with the templateName passed in
   */
  onSaveTemplate: PropTypes.func,
  /** Callback to be invoked on template deletion,
   * with the template to be deleted passed in
   */
  onDeleteTemplate: PropTypes.func,
  /** Callback to be invoked when remove from rundown is clicked */
  onRemove: PropTypes.func,
  /** List of template folders */
  folders: PropTypes.arrayOf(PropTypes.any),
  /** Callback to be invoked while creating new folder */
  onCreateFolder: PropTypes.func,
  /** Callback to be invoked while deleting a folder */
  onDeleteFolder: PropTypes.func,
  /** Callback to be invoked download of an instance */
  onDownload: PropTypes.func,
  /** Whether to hide template options or not */
  hideTemplateOptions: PropTypes.bool,
  /** Boolean that indicates wether to hide automation template sets */
  hideAutomationTemplates: PropTypes.bool,
  /** boolean that hides create new template from menu */
  canCreateNewTemplate: PropTypes.bool,
  /** boolean that hides delete template from menu */
  canDeleteTemplate: PropTypes.bool,
  /** boolean that hides delete template folder from menu */
  canDeleteTemplateFolder: PropTypes.bool,
  /** Boolean that stops an user from editing an instance */
  disableEdit: PropTypes.bool,
  canPinTemplateFolder: PropTypes.bool,
  canReorderTemplates: PropTypes.bool,
  canSetDefaultTemplate: PropTypes.bool,
  onSetDefaultTemplate: PropTypes.func,
  canOpenStory: PropTypes.bool,
  defaultTemplateRefId: PropTypes.string,
  writeLock: PropTypes.bool,
};

LinearEllipsisMenuView.defaultProps = {
  onSelectTemplate: (_template) => {},
  onSaveTemplate: (_templateName) => {},
  onDeleteTemplate: (_template) => {},
  onRemove: () => {},
  folders: [],
  onCreateFolder: () => {},
  onDeleteFolder: () => {},
  onDownload: () => {},
  hideTemplateOptions: false,
  hideAutomationTemplates: false,
  canCreateNewTemplate: false,
  canDeleteTemplate: false,
  canDeleteTemplateFolder: false,
  disableEdit: false,
  writeLock: false,
};

export default LinearEllipsisMenuView;
