import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

const UPDATE_ASSET = gql`
  mutation updateAsset($input: UpdateMemberInput) {
    updateMember(input: $input) {
      mId
      mRefId
      mTitle
      mAssetId
    }
  }
`;

const useUpdateAsset = () => {
  const [updateAsset] = useMutation(UPDATE_ASSET);

  const createStoryAsset = async (storyId, assetInput, updateCache) => {
    const { id, title, url } = assetInput;

    const input = {
      mId: storyId,
      mRefId: id,
    };

    if (title) input.mTitle = title;
    if (url) input.mAssetId = url;

    const promise = await updateAsset({
      variables: {
        input,
      },
    });

    return promise;
  };

  return [createStoryAsset];
};

export default useUpdateAsset;
