/**
 * @param  {} settings
 * @param  {} propertyName
 * @param  {} fallbackProperty
 * @param  {} defaultValue
 *
 * @returns {any}
 */
const getSettingsProperty = (
  settings = {},
  propertyName = '',
  fallbackProperty = '',
  defaultValue = undefined,
) => {
  if (settings) {
    const property = settings[propertyName];
    if (property || property === 0) return property;
    const fallbackValue = settings[fallbackProperty];
    if (fallbackValue || fallbackValue === 0) return fallbackValue;
  }
  return defaultValue;
};

export default getSettingsProperty;
