import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { IconButton as MaterialIconButton } from '@material-ui/core';
import close from 'assets/icons/systemicons/close_small.svg';
import edit from 'assets/icons/systemicons/edit_alt.svg';
import useStyles from './commonComponents-styles';

const IconButton = memo(MaterialIconButton);

const CommonComponents = ({ hideCloseButton, id, onClose, showEditButton, onEdit }) => {
  const classes = useStyles();

  const handleClose = useCallback(() => {
    onClose(id);
  }, [id, onClose]);

  return (
    <>
      {!hideCloseButton && (
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <img src={close} alt="close" className={classes.closeImage} />
        </IconButton>
      )}
      {showEditButton && (
        <IconButton className={classes.editButton} onClick={onEdit}>
          <img src={edit} alt="close" className={classes.editImage} />
        </IconButton>
      )}
    </>
  );
};

CommonComponents.propTypes = {
  /** id of the asset */
  id: PropTypes.string,
  /** Boolean to hide close button */
  hideCloseButton: PropTypes.bool,
  /** Boolean to show edit button for image component */
  showEditButton: PropTypes.bool,
  /** Callback for clicking close button */
  onClose: PropTypes.func,
  /** Callback for edit button click */
  onEdit: PropTypes.func,
};

CommonComponents.defaultProps = {
  id: '',
  hideCloseButton: false,
  showEditButton: false,
  onClose: () => {},
  onEdit: () => {},
};

export default memo(CommonComponents);
