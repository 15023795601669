import { useMemo, useState } from 'react';
import styled from '@emotion/styled';

import { ReactComponent as PrintIcon } from 'assets/icons/systemicons/print.svg';
import { ReactComponent as History } from 'assets/icons/systemicons/time.svg';
import ListItem from 'components/ellipsisDropdown/listItem-view';
import ContentPrint from 'features/print/ContentPrint';
import VersionHistory from 'features/versionHistory';
import { useStoryMolecule } from 'screens/storyV2/store/story';
import { useStoryPaneMolecule } from 'screens/storyV2/store/storyPane';
import { EditorValue } from 'types';
import { MemberTypeEnum } from 'types/graphqlTypes';
import { getUserIdFromLockedId } from 'utils/lock/lockTokenV2';

const actions = [
  { key: 'versions', label: 'Versions', icon: <History /> },
  { key: 'print', label: 'Print', icon: <PrintIcon /> },
] as const;

const DropdownWrapper = styled('div')`
  padding: 0 8px;
  border-radius: 4px;
  width: 160px;
  border: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  border-radius: 6px;
  padding-block: 8px;
  margin-block: -8px;
`;

interface Props {
  onClose: () => void;
}

const ContentTabDropdown = ({ onClose }: Props) => {
  const { useStory, useCanUpdateStoryValue } = useStoryMolecule();
  const { restoreVersionFnRef, onCheckVersionRestorabilityRef } = useStoryPaneMolecule();

  const [story] = useStory();
  const canUpdateStory = useCanUpdateStoryValue();

  const [historyDialogOpen, setHistoryDialogOpen] = useState(false);
  const [printDialogOpen, setPrintDialogOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const onPrintContent = () => {
    setPrintDialogOpen(true);
  };

  const onClosePrintDialog = () => {
    setPrintDialogOpen(false);
    onClose();
  };

  const handleHistory = () => {
    setHistoryDialogOpen(true);
  };

  const handleRestoreVersion = async (content: EditorValue) => {
    setIsSaving(true);
    await restoreVersionFnRef.current?.(content);
    setHistoryDialogOpen(false);
    setIsSaving(false);
    onClose();
  };

  const actionsWithHandlers = useMemo(() => {
    return actions.map((action) => {
      switch (action.key) {
        case 'versions':
          return {
            ...action,
            onClick: handleHistory,
            disabled: historyDialogOpen || !canUpdateStory,
          };
        case 'print':
          return { ...action, onClick: onPrintContent, disabled: printDialogOpen };
        default:
          return action;
      }
    });
  }, [canUpdateStory, historyDialogOpen, printDialogOpen]);

  return (
    <DropdownWrapper>
      {actionsWithHandlers.map((action) => (
        <ListItem
          key={action.key}
          onClick={action.onClick}
          text={action.label}
          firstChild={action.icon}
          disabled={action.disabled}
          excludeDivider
        />
      ))}

      {story && historyDialogOpen && (
        <VersionHistory
          title={story.mTitle}
          id={story.mId}
          open={historyDialogOpen}
          onCancel={() => setHistoryDialogOpen(false)}
          onOk={handleRestoreVersion}
          checkVersionRestorability={onCheckVersionRestorabilityRef.current ?? (() => false)}
          isSavingContent={isSaving}
          versionElement={MemberTypeEnum.Story}
          lockedByUser={getUserIdFromLockedId(story.locked ?? '') ?? undefined}
        />
      )}

      {printDialogOpen && story && (
        <ContentPrint isDialogOpen={true} onCloseDialog={onClosePrintDialog} story={story} />
      )}
    </DropdownWrapper>
  );
};

export default ContentTabDropdown;
