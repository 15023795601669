import React from 'react';

import { Button } from 'components/buttons';

import Header from '../header';

import { RootWrapper, ContentWrapper, FooterWrapper } from './confirmDialog-styled';

const ConfirmDialog = ({ onSaveAndClose, onDiscardAndClose, onClose }) => (
  <RootWrapper>
    <Header onClose={onClose} title="You have unsaved changes" />
    <ContentWrapper>
      Please choose whether to discard or save the changes you have made before you close the
      dialog.
    </ContentWrapper>
    <FooterWrapper>
      <Button width={128} usage="outlined" variant="outlined" onClick={onClose}>
        Cancel
      </Button>
      <Button width={136} usage="warning" variant="contained" onClick={onDiscardAndClose}>
        Discard & Close
      </Button>
      <Button width={136} usage="significant" variant="contained" onClick={onSaveAndClose}>
        Save & Close
      </Button>
    </FooterWrapper>
  </RootWrapper>
);

export default ConfirmDialog;
