import { useCallback, useEffect, useState } from 'react';
import { BaseRange, Editor, Range } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';

import preventDefaultAndStopPropagation from 'utils/preventDefaultAndStopPropagation';

const { isCollapsed, edges } = Range;

const toPixel = (num: number) => `${num}px`;

export interface Position<T> {
  top: T;
  left: T;
  bottom: T;
}

interface TextRegExp {
  beforeExp: RegExp;
  afterExp: RegExp;
}

/**
 * A custom hook that provides autocomplete functionality for slate editor.
 *
 * @param suggestions - An array of suggestions for autocompletion.
 * @param onEnter - A callback function triggered when the user confirms a suggestion.
 * @param setSearchValue - A function to update the search value based on user input.
 * @param matcher - An object containing regular expressions used for text matching.
 * @param switchDOMRange - boolean to determine which DOM range to use for portal position
 */

const useCombobox = <T>(
  suggestions: T[],
  onEnter: (suggestion: T, target: BaseRange | null) => void,
  setSearchValue: (val: string) => void,
  { beforeExp, afterExp }: TextRegExp,
  switchDOMRange = false,
) => {
  const editor = useSlate();
  const { selection } = editor;
  const suggestionCount = (suggestions || []).length;

  const [position, setPosition] = useState<Position<string | null>>({
    top: null,
    left: null,
    bottom: null,
  });
  const [target, setTarget] = useState<BaseRange | null>(null);
  const [cursor, setCursor] = useState(0);

  useEffect(() => {
    if (selection && isCollapsed(selection)) {
      const [start] = edges(selection);
      const { before, range, string, after } = Editor;
      const wordBefore = before(editor, start, { unit: 'word' });
      const beforePoint = wordBefore && before(editor, wordBefore);
      const beforeRange = beforePoint && range(editor, beforePoint, start);
      const beforeText = beforeRange && string(editor, beforeRange);
      const beforeMatch = beforeText && beforeText.match(beforeExp);
      const afterPoint = after(editor, start);
      const afterRange = range(editor, start, afterPoint);
      const afterText = string(editor, afterRange);
      const afterMatch = afterText.match(afterExp);

      if (beforeMatch && afterMatch) {
        setTarget(beforeRange);
        const [, valueToSet] = beforeMatch;
        setSearchValue(valueToSet);
        setCursor(0);
        return;
      }
      setTarget(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selection]);

  useEffect(() => {
    if (target && suggestionCount > 0) {
      const domRange = switchDOMRange
        ? window?.getSelection()?.getRangeAt(0)
        : ReactEditor.toDOMRange(editor as ReactEditor, target);

      if (!domRange) return;

      const rect = domRange.getBoundingClientRect();
      const documentHeight = document.body.getBoundingClientRect().height;

      const isAboveBisectHeight = rect.y > documentHeight / 2;

      setPosition({
        bottom: isAboveBisectHeight ? toPixel(documentHeight - rect.bottom + 24) : null,
        left: toPixel(rect.left),
        top: isAboveBisectHeight ? null : toPixel(rect.top + 24),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestionCount, target, editor]);

  const onArrowDown = useCallback(
    (event: KeyboardEvent) => {
      preventDefaultAndStopPropagation(event);
      setCursor((previousCursor) =>
        previousCursor + 1 >= suggestionCount ? 0 : previousCursor + 1,
      );
    },
    [suggestionCount],
  );

  const onArrowUp = useCallback(
    (event: KeyboardEvent) => {
      preventDefaultAndStopPropagation(event);
      setCursor((previousCursor) =>
        previousCursor <= 0 ? suggestionCount - 1 : previousCursor - 1,
      );
    },
    [suggestionCount],
  );

  const handleEnter = useCallback(() => {
    onEnter(suggestions[cursor], target);
  }, [suggestions, cursor, target]);

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowDown':
          onArrowDown(event);
          break;

        case 'ArrowUp':
          onArrowUp(event);
          break;

        case 'Enter':
          preventDefaultAndStopPropagation(event);
          handleEnter();
          setTarget(null);
          break;

        case 'Escape':
          setTarget(null);
          break;
        default:
          break;
      }
    },
    [onArrowDown, onArrowUp, handleEnter],
  );

  const showCombobox = target && suggestionCount > 0;

  useEffect(() => {
    if (showCombobox) window.addEventListener('keydown', onKeyDown, true);

    return () => {
      window.removeEventListener('keydown', onKeyDown, true);
    };
  }, [onKeyDown, showCombobox]);

  return { target, cursor, position };
};

export default useCombobox;
