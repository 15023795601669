import { useContext } from 'react';

import { useGetOptionLists } from 'api/optionLists/useGetOptionLists';
import Dialog from 'components/dialog';
import { initialValues } from 'components/editor/constants';
import variants from 'components/editor/constants/types/editorVariants';
import LoadingIndicator from 'components/loadingIndicator';
import PdfViewer from 'components/pdfViewer/PdfViewer';
import UserContext from 'contexts/UserContext';
import useTextStorage from 'hooks/useTextStorage';
import { Story } from 'types';

import ErrorView from './components/ErrorView';
import ContentPrintDoc from './docs/ContentPrintDoc';
import useGetBlocksAndOrders from './hooks/useGetBlocksAndOrders';
import useGetContactsAndRelationMembers from './hooks/useGetContactsAndRelationMembers';

import { LoadingWrapper, PrintViewWrapper } from './styled';

interface Props {
  isDialogOpen: boolean;
  onCloseDialog: () => void;
  story: Story;
}

const View = ({ story }: { story: Story }) => {
  const { groups } = useContext(UserContext);
  const { data, loading, error } = useTextStorage(story.mContentKey);

  const initialValue = initialValues(variants.GENERAL, undefined, undefined, false);

  const {
    mdfsSeparated,
    blocksWithMdf,
    ordersWithMdf,
    blocksLoading,
    mdfsLoading,
    ordersLoading,
    mdfsError,
    blocksError,
    ordersError,
  } = useGetBlocksAndOrders(story.mId);

  const {
    relationMembers,
    relationsLoading,
    relationsError,
    contacts,
    contactsLoading,
    contactsError,
  } = useGetContactsAndRelationMembers(blocksWithMdf, ordersWithMdf, [], mdfsSeparated);

  const { optionLists, loading: optionsLoading, error: optionsError } = useGetOptionLists();

  if (
    loading ||
    blocksLoading ||
    mdfsLoading ||
    ordersLoading ||
    relationsLoading ||
    contactsLoading ||
    optionsLoading
  )
    return (
      <LoadingWrapper>
        <LoadingIndicator />
      </LoadingWrapper>
    );

  if (
    error ||
    blocksError ||
    mdfsError ||
    ordersError ||
    relationsError ||
    contactsError ||
    optionsError
  ) {
    const errors = {
      error: error ?? null,
      blocksError: blocksError ?? null,
      mdfsError: mdfsError ?? null,
      ordersError: ordersError ?? null,
      relationsError: relationsError ?? null,
      contactsError: contactsError ?? null,
      optionsError: optionsError ?? null,
    };

    return <ErrorView errors={errors} />;
  }

  return (
    <PrintViewWrapper>
      <PdfViewer>
        <ContentPrintDoc
          content={data ?? initialValue}
          story={story}
          blocks={blocksWithMdf}
          orders={ordersWithMdf}
          mdfsSeparated={mdfsSeparated}
          relationMembers={relationMembers}
          contacts={contacts}
          groups={groups}
          optionLists={optionLists}
        />
      </PdfViewer>
    </PrintViewWrapper>
  );
};

const ContentPrint = ({ isDialogOpen, onCloseDialog, story }: Props) => {
  return (
    <Dialog container={undefined} open={isDialogOpen} onClose={onCloseDialog}>
      <View story={story} />
    </Dialog>
  );
};

export default ContentPrint;
