import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
    borderRadius: '8px 8px 0 0',
    overflow: 'hidden',
  },
  newRibbon: {
    position: 'absolute',
    borderRadius: '8px 0px 0px 0px',
    top: 0,
    left: 0,
    width: '40px',
    height: '40px',
  },
  newText: {
    position: 'absolute',
    left: 0,
    top: 0,
    transform: 'rotate(-45deg) translate3d(-15%,75%,0)',
    ...theme.typography.dina.iconLabel,
    color: theme.palette.dina.highEmphasis,
    lineHeight: '11px',
  },
  graphics: {
    position: 'absolute',
    width: '32px',
    height: '32px',
    top: '50%',
    left: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
  },
}));

export default useStyles;
