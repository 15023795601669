/* eslint-disable react/prop-types */
import { memo, useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { FormControlLabel } from '@material-ui/core';
import { Editor, Range, Transforms } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';

import useDeleteBlock from 'api/mdfBlocks/useDeleteMdfBlock';
import Checkbox from 'components/checkbox';
import Dialog from 'components/dialogs/DialogBuilder';
import { elementTypes } from 'components/editor/constants';
import { useEditorMolecule } from 'components/editor/store';
import { removeBlock } from 'components/editor/utils';
import {
  getNodesFromSelection,
  isMdfBlock,
  isOrderBlock,
} from 'components/editor/utils/ElementKeyDownUtils';
import Infobar from 'components/infobar';
import useDeleteOrder from 'components/orderFormDialog/api/useDeleteOrder';
import Text from 'components/text';
import useToast from 'components/toast/useToast';
import { useSplitViewMolecule } from 'features/splitView/store';
import { VStack } from 'layouts/box/Box';
import { CustomElement } from 'types';

import variants from '../../constants/types/editorVariants';
import { useEditorContext } from '../../EditorContext';

const StyledFormControlLabel = styled(FormControlLabel)`
  width: max-content;
  margin-left: 0;

  .MuiFormControlLabel-label {
    font-size: 14px;
    margin-left: 4px;
  }
`;

export const BLOCK_MENU_ID = 'blockMenu';

function DeleteDialog() {
  const editor = useSlate();
  const { errorToast } = useToast();
  const { variant, update } = useEditorContext();
  const [shouldDeleteFromDb, setShouldDeleteFromDb] = useState<boolean>(false);
  const { useSelectedBlockId } = useSplitViewMolecule();
  const { useShowDeleteAllDialog } = useEditorMolecule();
  const [, setSelectedBlockId] = useSelectedBlockId();

  const [showDeleteAllDialog, setShowDeleteAllDialog] = useShowDeleteAllDialog();

  const { deleteBlock } = useDeleteBlock();
  const { deleteOrder } = useDeleteOrder();

  const handleDialogClose = useCallback(() => {
    setShowDeleteAllDialog(false);
    setShouldDeleteFromDb(false);
    ReactEditor.focus(editor);
  }, []);

  const deleteFromDb = useCallback(async (blocks: CustomElement[]) => {
    const restrictedBlocks = blocks.filter(
      (block) => isMdfBlock(block.type) || isOrderBlock(block.type),
    );

    const promises = restrictedBlocks.reduce((acc, currentBlock) => {
      const { mResourceId, mId } = currentBlock.data ?? {};
      if (!mId || !mResourceId) return acc;
      if (currentBlock.type === elementTypes.MDF_BLOCK)
        return [...acc, deleteBlock({ mId: mResourceId, mRefId: mId })];
      if (currentBlock.type === elementTypes.ORDER_BLOCK)
        return [...acc, deleteOrder({ mId, mResourceId })];

      return acc;
    }, [] as Promise<void>[]);

    try {
      await Promise.allSettled(promises);
    } catch (err) {
      errorToast(err, 'Delete failed!');
    } finally {
      setSelectedBlockId(undefined);
    }
  }, []);

  const confirmDelete = useCallback(async () => {
    setShowDeleteAllDialog(false);
    setShouldDeleteFromDb(false);

    if (!editor.selection) return;

    /* remove block(s) from editor */
    const selectedNodes = getNodesFromSelection(editor);
    if (selectedNodes.length === 1 && update) removeBlock(editor, selectedNodes[0], update);

    const [start, end] = Range.edges(editor.selection);
    if (start.offset === 0 && end.offset === 0) {
      Transforms.removeNodes(editor, { voids: true, hanging: true });
    } else Editor.deleteFragment(editor);

    if (editor.selection) {
      Transforms.select(editor, editor.selection);
      ReactEditor.focus(editor);
    }

    /* remove from db if checkbox selected */
    if (shouldDeleteFromDb) await deleteFromDb(selectedNodes);
  }, [editor, shouldDeleteFromDb]);

  return (
    <Dialog open={showDeleteAllDialog} onClose={handleDialogClose}>
      <Dialog.Header>{'Delete selected'}</Dialog.Header>
      <Dialog.Body>
        <VStack gap="12px">
          <Text variant="body2" color="highEmphasis">
            Are you sure you want to delete selected block(s) from{' '}
            {variant === variants.GENERAL ? 'story' : variant}?
          </Text>
          <Infobar>
            To remove a block from the available tasks/blocks list, select the delete option below
          </Infobar>
          <StyledFormControlLabel
            value="deleteFromList"
            control={
              <Checkbox
                selected={shouldDeleteFromDb}
                size={24}
                onClick={() => setShouldDeleteFromDb((prev) => !prev)}
              />
            }
            label={'Delete from tasks/blocks'}
          />
        </VStack>
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton autoFocus label="Cancel" />
        <Dialog.ConfirmButton label="Delete" usage="danger" onClick={confirmDelete} />
      </Dialog.Footer>
    </Dialog>
  );
}

function Dialogs() {
  return <DeleteDialog />;
}

export default memo(Dialogs);
