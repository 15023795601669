import { makeStyles } from '@material-ui/styles';

const commonTextStyles = (theme) => ({
  ...theme.typography.dina.listItemLabel,
  fontWeight: 'normal',
  fontStyle: 'normal',
  letterSpacing: '0.25px',
  width: '100%',
});

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '144px',
    minWidth: '144px',
    height: '16px',
    textAlign: 'right',
    paddingLeft: '16px',
    overflow: 'hidden',
  },
  labelRoot: {
    ...commonTextStyles(theme),
    color: theme.palette.dina.mediumEmphasis,
  },
  valueContainer: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '16px',
    width: 'calc( 100% - 144px )',
    paddingLeft: '12px',
  },
  valueRoot: {
    ...commonTextStyles(theme),
    color: theme.palette.dina.highEmphasis,
  },
  divider: {
    marginTop: '8px',
    marginLeft: '156px',
  },
  container: {
    marginTop: '8px',
  },
}));

export default useStyles;
