import React from 'react';

import { ButtonProps } from 'components/buttons';

import Dialog from './DialogBuilder';

interface Props {
  open: boolean;
  onClose: () => void;
  onClick: () => void | Promise<void>;
  title: string;
  message: string | React.ReactElement;
  loading?: boolean;
  usage?: ButtonProps['usage'];
  confirmLabel?: string;
  cancelLabel?: string;
  style?: React.CSSProperties;
  overlayStyle?: React.CSSProperties;
}

function Template({
  open,
  onClose,
  onClick,
  title,
  loading,
  message,
  usage,
  confirmLabel,
  cancelLabel,
  style,
  overlayStyle,
}: Readonly<Props>) {
  return (
    <Dialog open={open} onClose={onClose} style={style} overlayStyles={overlayStyle}>
      <Dialog.Header showCloseIcon={false}>{title}</Dialog.Header>
      <Dialog.Body>{message}</Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton label={cancelLabel} />
        <Dialog.ConfirmButton
          onClick={onClick}
          usage={usage}
          label={confirmLabel}
          loading={loading}
        />
      </Dialog.Footer>
    </Dialog>
  );
}

export const ConfirmDialog = ({ confirmLabel, usage, ...rest }: Props) => (
  <Template {...rest} confirmLabel={confirmLabel ?? 'Ok'} usage={usage ?? 'cta'} />
);
export const WarningDialog = ({ confirmLabel, ...rest }: Props) => (
  <Template {...rest} usage="warning" confirmLabel={confirmLabel ?? 'Ok'} />
);
export const DeleteDialog = ({ confirmLabel, ...rest }: Props) => (
  <Template {...rest} usage="danger" confirmLabel={confirmLabel ?? 'Delete'} />
);
