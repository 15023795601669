import { Item, ItemParams, PredicateParams } from 'react-contexify';

import { useGetIntegrations } from 'api/config/useGetIntegrations';
import { Menu } from 'features/contextMenu/styled';
import { HStack } from 'layouts/box/Box';
import { isConfigurableActionType } from 'screens/main/components/header/navbar/settings/components/integrations/constants';
import { IntegrationEnum, MemberType } from 'types/graphqlTypes';

import { OpenMemberItem } from './memberItems/OpenMember';
import { PreviewMemberItem } from './memberItems/PreviewMember';
import useActionTrigger from './useActionTrigger';

import { IconWrapper, MenuContent, StyledImage, StyledText } from './styled';

type PossibleTypes = 'action';

interface MemberMenuProps {
  member: MemberType;
  close?: () => void;
}

interface Props {
  menuId?: string;
  // Domain items are specific to a domain and are passed in as props
  domainItems?: React.ReactNode[];
}

export function MemberContextMenu({ menuId = 'memberMenu', domainItems }: Readonly<Props>) {
  const { trigger } = useActionTrigger();
  const { integrations: actions, actionsByType } = useGetIntegrations(IntegrationEnum.CustomAction);

  const handleClickEvent = (data: ItemParams<MemberMenuProps>, type: PossibleTypes) => {
    const { member } = data.props ?? {};
    if (!member) return;

    const { mType, mId, mTitle } = member;
    const action = actions.find((act) => act.mRefId === data.id);
    const isCustomAction = type === 'action' && action && mId && mType;

    if (isCustomAction) {
      trigger(
        action,
        {
          actionId: action.mRefId,
          resourceId: mId,
          resourceType: mType,
        },
        mTitle ?? 'Unknown',
      );
    }

    if (data.props?.close) data.props.close();
  };

  const shouldActionBeHidden = (data: PredicateParams<MemberMenuProps, MemberMenuProps>) => {
    const mType = data.props?.member?.mType;
    if (isConfigurableActionType(mType) && data.id) {
      const availableActions = actionsByType[mType];
      return !availableActions.find((a) => a.mRefId === data.id);
    }
    return true;
  };

  return (
    <Menu id={menuId} style={{ zIndex: 3100 }}>
      <PreviewMemberItem key="preview" />
      <OpenMemberItem key="open" />
      {domainItems ?? null}
      {actions.map((a) => (
        <Item
          id={a.mRefId}
          key={a.mRefId}
          hidden={shouldActionBeHidden}
          onClick={(data: ItemParams<MemberMenuProps>) => handleClickEvent(data, 'action')}
        >
          <HStack gap="6px" alignItems="center" maxWidth="300px">
            <IconWrapper>{a.iconUrl && <StyledImage src={a.iconUrl} />}</IconWrapper>
            <MenuContent justifyContent="center" alignItems="start" gap="2px">
              <div>{a.mTitle}</div>
              {a.mDescription && <StyledText variant="caption">{a.mDescription}</StyledText>}
            </MenuContent>
          </HStack>
        </Item>
      ))}
    </Menu>
  );
}
