const processNotificationPayload = (payload, mId, selectedDates) => {
  const defaultMessage = {
    loggedInUserId: mId,
    selectedDates,
  };

  if (!payload || !payload.message) return defaultMessage;
  try {
    const message = JSON.parse(payload.message);
    message.loggedInUserId = mId;
    message.selectedDates = selectedDates;
    return message;
  } catch (error) {
    return defaultMessage;
  }
};

export default processNotificationPayload;
