import { atom, useAtom, useAtomValue } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { createScope, molecule, useMolecule } from 'jotai-molecules';

import { SidepanelTabValue } from 'features/sidepanel/Sidepanel';
import { atomWithSessionStorage } from 'utils/atoms/atomWithSessionStorage';

type ActiveView = 'story' | 'rundown' | 'preview';

export const SplitScope = createScope<string | undefined>(undefined);

const splitViewMolecule = molecule((_getMol, getScope) => {
  const scope = getScope(SplitScope);
  const [view, index] = (scope ?? 'story:0').split(':') as [ActiveView, string];

  const blockExpandStateAtom = atomWithSessionStorage<Record<string, boolean>>(
    `editor-block-collapse-state:${view}${index}`,
    {},
  );
  const showSidePanelAtom = atomWithStorage<boolean>(`sidepanel:${view}${index}`, true);
  const selectedBlockIdAtom = atom<string | undefined>(undefined);
  const sidepanelTabAtom = atomWithStorage<SidepanelTabValue>(
    `sidepanel-tab:${view}${index}`,
    'planning',
  );
  const forceOpenIdAtom = atom<string | undefined>(undefined);
  const activeViewAtom = atom<ActiveView>(view);
  const showCommentAtom = atom<boolean>(false);

  return {
    useForceOpenId: () => useAtom(forceOpenIdAtom),
    useShowSidePanel: () => useAtom(showSidePanelAtom),
    useSelectedBlockId: () => useAtom(selectedBlockIdAtom),
    useSidepanelTab: () => useAtom(sidepanelTabAtom),
    useBlockExpandState: () => useAtom(blockExpandStateAtom),
    useActiveView: () => useAtomValue(activeViewAtom),
    useShowComment: () => useAtom(showCommentAtom),
  };
});

export const useSplitViewMolecule = () => useMolecule(splitViewMolecule);
