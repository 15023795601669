import uuidv1 from 'uuid/v1';

const transformChangedMetadataFields = (changedMetadataField) =>
  changedMetadataField.map((changedField) => {
    const [id, attribute] = changedField?.key?.split(':');
    if (attribute === 'options') {
      const optionNames = changedField?.value.split(',');
      const options = [];
      optionNames.forEach((optionName) => {
        const name = optionName.trim();
        if (name) {
          const option = {
            icon: null,
            id: uuidv1(),
            title: name,
            value: name,
            __typename: 'MetadataFormOptionType',
          };
          options.push(option);
        }
      });
      return { id, [attribute]: options };
    }
    if (attribute === 'name') {
      const name = changedField.value;
      if (!name) {
        return null;
      }
    }

    return { id, [attribute]: changedField.value };
  });

const getUpdatedMetadataFields = (changedMetadataField, fields) => {
  const updatedFields = transformChangedMetadataFields(changedMetadataField);

  return fields.map((field) => {
    const updatedAttributesInField = updatedFields.filter(
      (updatedField) => updatedField.id === field.id,
    );
    let updatedField = field;
    updatedAttributesInField.forEach((updatedAttribute) => {
      updatedField = { ...updatedField, ...updatedAttribute };
    });
    return updatedField;
  });
};

export default getUpdatedMetadataFields;
