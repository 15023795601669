/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import { useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Table, TableBody, TableHead, TableRow } from '@material-ui/core';
import { isEqual, keyBy } from 'lodash';

import { useCreateIntegration } from 'api/config/useCreateIntegration';
import { useDeleteIntegration } from 'api/config/useDeleteIntegration';
import { useGetIntegrationsForAdmin } from 'api/config/useGetIntegrations';
import { ReactComponent as Add } from 'assets/icons/systemicons/plus_small.svg';
import { IconButton } from 'components/buttons';
import { DeleteDialog } from 'components/dialogs/CommonDialogs';
import { useEditStringDialog } from 'components/editStringDialog/EditStringDialog';
import { CloseIcon } from 'components/orderFormDialog/styled';
import Scrollbar from 'components/scrollbar';
import SplitBar from 'components/split/SplitBar';
import useToast from 'components/toast/useToast';
import { HStack } from 'layouts/box/Box';
import { IntegrationEnum, IntegrationType } from 'types/graphqlTypes';

import { useChangedPlugins } from '../../atomsTs';
import { HeaderCell } from '../settingsTabs/systemSettings/details/studios/styled';

import { EditPluginRow } from './EditPluginRow';

export const programmaticIdRegex = /^[a-zA-Z0-9-_]*$/;

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const columns = [
  { key: 'mTitle', name: 'Widget name' },
  { key: 'mDescription', name: 'Optional description' },
  { key: 'iconUrl', name: 'Icon URL (24x24px)' },
  { key: 'auth', name: 'Auth' },
  { key: 'endpoint', name: 'Endpoint' },
  { key: 'mActive', name: 'Active' },
  { key: 'mUpdatedAt', name: 'Updated' },
  { key: 'action', name: '' },
];

export function SearchPlugins() {
  const { errorToast } = useToast();
  const [selectedPlugin, setSelectedPlugin] = useState<IntegrationType | null>(null);
  const { integrations: plugins } = useGetIntegrationsForAdmin(IntegrationEnum.SearchPlugin);
  const { createIntegration } = useCreateIntegration();
  const { deleteIntegration } = useDeleteIntegration();
  const [, showEditStringDialog] = useEditStringDialog();
  const [changedPlugins, setChangedPlugins] = useChangedPlugins();
  const [pluginToDelete, setPluginToDelete] = useState<IntegrationType | null>(null);

  const pluginsById = useMemo(() => {
    return keyBy(plugins, (plugin) => plugin.mRefId);
  }, [plugins]);

  const doCreatePlugin = useCallback(() => {
    showEditStringDialog({
      headerText: 'Create new search plugin',
      required: true,
      inputLabel: 'Identifier',
      validator: (val: string) => {
        if (val.length < 3) {
          return 'Minimum length is 3 characters';
        }
        if (!programmaticIdRegex.test(val)) {
          return 'Only alphanumeric characters allowed';
        }
        if (pluginsById[val]) {
          return 'Plugin already exists';
        }
        return true;
      },
      onConfirm: (val) => {
        createIntegration(val, IntegrationEnum.SearchPlugin).catch(errorToast);
      },
    });
  }, [showEditStringDialog, createIntegration, pluginsById]);

  const onDelete = useCallback(
    (id: string) => {
      const plugin = plugins.find((a) => a.mRefId === id);
      if (plugin) {
        setPluginToDelete(plugin);
      }
    },
    [setPluginToDelete, plugins],
  );

  const onPluginChange = useCallback(
    (pluginUpdates: Partial<IntegrationType>, originalPlugin: IntegrationType) => {
      const updatedPlugin: IntegrationType = {
        ...originalPlugin,
        ...pluginUpdates,
      };
      if (!changedPlugins[originalPlugin.mRefId] && !isEqual(updatedPlugin, originalPlugin)) {
        setChangedPlugins((prevState) => {
          return {
            ...prevState,
            [originalPlugin.mRefId]: updatedPlugin,
          };
        });
      } else if (changedPlugins[originalPlugin.mRefId]) {
        const updatedChangedAction: IntegrationType = {
          ...changedPlugins[originalPlugin.mRefId],
          ...pluginUpdates,
        };
        if (isEqual(updatedChangedAction, originalPlugin)) {
          const copy = { ...changedPlugins };
          delete copy[originalPlugin.mRefId];
          setChangedPlugins(copy);
        } else {
          setChangedPlugins((prevState) => {
            return {
              ...prevState,
              [originalPlugin.mRefId]: updatedChangedAction,
            };
          });
        }
      }
    },
    [changedPlugins, setChangedPlugins],
  );

  const onResetPlugin = useCallback(
    (id: string) => {
      if (changedPlugins[id]) {
        const copy = { ...changedPlugins };
        delete copy[id];
        setChangedPlugins(copy);
      }
    },
    [setChangedPlugins, changedPlugins],
  );

  const doDeletePlugin = useCallback(
    (idToDelete: string | undefined) => {
      if (idToDelete) {
        deleteIntegration(idToDelete, IntegrationEnum.SearchPlugin)
          .then(() => {
            if (changedPlugins[idToDelete]) {
              const copy = { ...changedPlugins };
              delete changedPlugins[idToDelete];
              setChangedPlugins(copy);
            }
          })
          .catch(errorToast);
      }
      setPluginToDelete(null);
    },
    [deleteIntegration, changedPlugins, setChangedPlugins, setPluginToDelete],
  );

  const memoizedTable = useMemo(() => {
    return (
      <Scrollbar
        valueChanged={undefined}
        closeToBottom={undefined}
        top={undefined}
        bottom={undefined}
        dark={undefined}
        showHorizontal={undefined}
      >
        <Table aria-label="plugins-table">
          <TableHead key="plugins-table-head">
            <TableRow key="plugins-header">
              <HeaderCell key="externalId">
                <HStack>
                  <IconButton
                    title="Create new action"
                    size={24}
                    iconSize={20}
                    usage="text"
                    onClick={doCreatePlugin}
                  >
                    <Add />
                  </IconButton>
                  Id
                </HStack>
              </HeaderCell>
              {columns.map((col) => (
                <HeaderCell key={col.key}>{col.name}</HeaderCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody key="plugins-table-body">
            {plugins.map((plugin) => (
              <EditPluginRow
                hasChanges={changedPlugins[plugin.mRefId] !== undefined}
                key={plugin.mRefId}
                plugin={changedPlugins[plugin.mRefId] ?? plugin}
                isSelected={plugin.mRefId === selectedPlugin?.mRefId}
                onSelect={setSelectedPlugin}
                onDeletePlugin={onDelete}
                onUpdatePlugin={(val) => onPluginChange(val, plugin)}
                onResetPlugin={onResetPlugin}
              />
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
    );
  }, [
    plugins,
    changedPlugins,
    selectedPlugin,
    doCreatePlugin,
    setSelectedPlugin,
    onDelete,
    onPluginChange,
    onResetPlugin,
  ]);

  return (
    <Wrapper>
      {selectedPlugin ? (
        <SplitBar
          split={undefined}
          style={{
            height: '100%',
          }}
          primary="second"
          pane1Style={{
            minWidth: '500px',
          }}
          pane2Style={{
            minWidth: '250px',
            maxWidth: '500px',
          }}
        >
          {memoizedTable}
          <div style={{ width: '100%', height: '100%', padding: '8px' }}>
            <CloseIcon
              style={{ position: 'absolute', top: '3px', right: '3px' }}
              onClick={() => {}}
            />
          </div>
        </SplitBar>
      ) : (
        <>{memoizedTable}</>
      )}
      <DeleteDialog
        open={pluginToDelete !== null}
        onClose={() => setPluginToDelete(null)}
        onClick={() => doDeletePlugin(pluginToDelete?.mRefId)}
        title="Delete search plugin?"
        message={`Are you sure you want to delete ${pluginToDelete?.mTitle}? Note that this will not automatically remove all configured decks with this plugin. These decks will be marked as disabled. `}
      />
    </Wrapper>
  );
}
