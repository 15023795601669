import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';

import Dialog from 'components/dialogs/DialogBuilder';
import Tooltip from 'components/tooltip';
import { useEditFieldValueDialog } from 'features/mdf/mdf-utils';
import useMetadata from 'hooks/useMetadata';

import { MdfEditor } from './MdfEditor';

const HeaderWrapper = styled('div')`
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 380px;
`;

export function EditFieldValueDialog() {
  const [open, setOpen] = useState(false);
  const [state, setState] = useEditFieldValueDialog();
  const { metadata, errorMap, updateFieldValues } = useMetadata(
    state?.mdf,
    undefined,
    state?.viewType,
  );

  const fieldError = state?.editMode ? undefined : errorMap[state?.fieldId ?? ''];
  const fieldToRender = useMemo(() => {
    if (!state) return [];
    return state.mdf.fields.filter((f) => f.fieldId === state.fieldId);
  }, [state?.fieldId, state?.mdf]);

  useEffect(() => {
    if (state === null) {
      setOpen(false);
    } else {
      setOpen(true);
      if (state?.startValue) {
        updateFieldValues([{ fieldId: state.fieldId, value: state.startValue }]);
      }
    }
  }, [state]);

  const onCancel = useCallback(() => {
    if (state?.onCancel) {
      state.onCancel();
    }
    setState(null);
  }, [state, setState]);

  const onConfirm = useCallback(() => {
    const value = state !== null ? metadata[state.fieldId] : null;
    if (state?.onConfirm && value !== null) {
      state.onConfirm(value);
    }
    setOpen(false);
    onCancel();
  }, [metadata, state, setOpen, onCancel]);

  return (
    <Dialog open={open} onClose={onCancel} style={{ width: '450px' }}>
      <Dialog.Header>
        <Tooltip title={state?.headerText ?? ''}>
          <HeaderWrapper>{state?.headerText}</HeaderWrapper>
        </Tooltip>
      </Dialog.Header>
      <Dialog.Body>
        {state && (
          <MdfEditor
            autoFocus
            view={state.viewType}
            fields={fieldToRender}
            defaultLayoutSettings={state.mdf.views.default}
            layoutSettings={state.mdf.views[state.viewType]}
            metadata={metadata}
            permissions={state.mdf.permissions}
            updateFieldValue={updateFieldValues}
            errorMap={errorMap}
            moreVerticalSpace={true}
            fieldEditMode={state.editMode}
          />
        )}
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton onCancel={onCancel} />
        <Tooltip title={fieldError ?? ''}>
          <Dialog.ConfirmButton
            label={state?.confirmLabel ?? 'Confirm'}
            onClick={onConfirm}
            disabled={Boolean(fieldError)}
          />
        </Tooltip>
      </Dialog.Footer>
    </Dialog>
  );
}
