import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  rndRoot: {
    '&:focus': {
      outline: 'none',
    },
  },
  childrenContainer: {
    width: '100%',
    height: '100%',
    boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.25), 0px 12px 24px rgba(0, 0, 0, 0.25)',
  },
}));

export default useStyles;
