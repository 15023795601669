import styled from '@emotion/styled/macro';
import { borderColor, edgeColor } from '../../utils/styleUtils';

export const LeftEdge = styled('div')`
  width: 8px;
  height: 100%;
  background-color: ${({ isAuto, isGraphic, theme }) =>
    isAuto ? edgeColor({ isGraphic, theme }) : 'transparent'};
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  position: relative;

  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: calc(50% - 6px);
    width: 100%;
    border-top-left-radius: 4px;
    border-top: ${({ isSelected, isGraphic, theme }) =>
      borderColor({ isSelected, isGraphic, theme })};
    border-left: ${({ isSelected, isGraphic, theme }) =>
      borderColor({ isSelected, isGraphic, theme })};
  }

  ::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: calc(50% - 6px);
    width: 100%;
    border-bottom-left-radius: 4px;
    border-bottom: ${({ isSelected, isGraphic, theme }) =>
      borderColor({ isSelected, isGraphic, theme })};
    border-left: ${({ isSelected, isGraphic, theme }) =>
      borderColor({ isSelected, isGraphic, theme })};
  }
`;

export const LeftEdgeWrapper = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
`;
