import React from 'react';

import useSettingsValue from 'hooks/useSettingsValue';
import useCheckUserRight from 'hooks/useCheckUserRight';

import { useSelectedMenuSystemSettings, useSelectedCategory } from '../../../../../atoms';
import List from '../../../../collapseList';
import { CATEGORY_TYPES, MENU_TYPES } from '../../../../../utils/settingTypes';

const Rundown = () => {
  const [getSettingsValue] = useSettingsValue();
  const [checkUserRight] = useCheckUserRight();
  const [selectedMenu, setSelectedMenu] = useSelectedMenuSystemSettings();
  const [category, setCategory] = useSelectedCategory();

  const canEnableStudios = getSettingsValue('rundown.enableStudios') === 'true';
  const canShowStudios = canEnableStudios && checkUserRight('rundown', 'show-studios', true);

  const onClickRundownSettings = (event, menuType, categoryType) => {
    event.stopPropagation();
    setSelectedMenu(menuType);
    setCategory(categoryType);
  };

  return (
    <List>
      <List.Header selected={category === CATEGORY_TYPES.RUNDOWN} hideCreateButton>
        Rundown
      </List.Header>
      <List.Body>
        <List.Item
          key="rundown-setings"
          selected={selectedMenu === MENU_TYPES.RUNDOWN_SETTINGS}
          onClick={(event) =>
            onClickRundownSettings(event, MENU_TYPES.RUNDOWN_SETTINGS, CATEGORY_TYPES.RUNDOWN)
          }
        >
          Rundown settings
        </List.Item>
        <List.Item
          key="rundown-grid-view"
          selected={selectedMenu === MENU_TYPES.RUNDOWN_VIEWS}
          onClick={(event) =>
            onClickRundownSettings(event, MENU_TYPES.RUNDOWN_VIEWS, CATEGORY_TYPES.RUNDOWNVIEWS)
          }
        >
          Rundown Views
        </List.Item>
        <List.Item
          key="rundown-permissions"
          selected={selectedMenu === MENU_TYPES.RUNDOWN_PERMISSIONS}
          onClick={(event) =>
            onClickRundownSettings(event, MENU_TYPES.RUNDOWN_PERMISSIONS, CATEGORY_TYPES.RUNDOWN)
          }
        >
          Permissions
        </List.Item>
        {canShowStudios && (
          <List.Item
            key="rundown-studios"
            selected={selectedMenu === MENU_TYPES.RUNDOWN_STUDIOS}
            onClick={(event) =>
              onClickRundownSettings(event, MENU_TYPES.RUNDOWN_STUDIOS, CATEGORY_TYPES.RUNDOWN)
            }
          >
            Studios
          </List.Item>
        )}
      </List.Body>
    </List>
  );
};

export default Rundown;
