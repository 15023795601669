import React, { memo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSlate } from 'slate-react';
import AutoComplete from 'components/autoCompleteBase';
import useChangeCollapse from 'components/editor/hooks/useChangeCollapse';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import { elementTypes } from 'components/editor/constants/types';
import { refreshSelection } from 'components/editor/utils';
import LocationBase from '../locationBase';

const menuItems = [];
const AutoCompleteBase = memo(AutoComplete);

const Location = ({ attributes, children, element }) => {
  const { data } = element;
  const { locationData, collapsed } = data;
  const [, setLocation] = useState();
  const readOnly = false;
  const [onChangeCollapse] = useChangeCollapse(element);
  const editor = useSlate();

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    getClearProps,
    groupedOptions,
    getPopupIndicatorProps,
    value,
    popupOpen,
  } = useAutocomplete({
    id: 'location-box',
    options: locationData,
    autoHighlight: true,
    getOptionLabel: (option) => option.value,
  });

  useEffect(() => {
    value && setLocation(value);
  }, [value]);

  const onBlur = useCallback(() => {
    refreshSelection(editor, element);
  }, [editor, element]);

  return (
    <div {...attributes} onBlur={onBlur}>
      {children}
      <LocationBase
        menuItems={menuItems}
        readOnly={readOnly}
        title="LOCATION"
        updateCollapsed={onChangeCollapse}
        collapsed={collapsed}
        collapsedContent=""
      >
        <AutoCompleteBase
          readOnly={readOnly}
          content={[]}
          listWidthOffset={155}
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          getListboxProps={getListboxProps}
          getOptionProps={getOptionProps}
          getClearProps={getClearProps}
          groupedOptions={groupedOptions}
          getPopupIndicatorProps={getPopupIndicatorProps}
          value={value}
          popupOpen={popupOpen}
        />
      </LocationBase>
    </div>
  );
};

Location.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

Location.defaultProps = {
  attributes: {},
  children: null,
  element: {
    children: [],
    data: { src: '' },
    type: elementTypes.LOCATION,
  },
};

export default memo(Location);
