import React, { memo, useMemo } from 'react';
import { CSS } from '@dnd-kit/utilities';

import { RenderItemProps } from 'components/sortableList';
import Tooltip from 'components/tooltip';
import { ColorStripe } from 'features/reusableStyled';
import { HStack } from 'layouts/box/Box';
import { SavedSearch } from 'types/graphqlTypes';

import { GroupPolicy } from './SavedSearches';

import { SavedSearchLabelText, StyledHStack, SubText } from './styled';
interface SavedSearchItemProps {
  item: SavedSearch;
  selected: boolean;
  groupMap: Record<string, GroupPolicy>;
  parentSize: string;
  onClick: () => void;
  onKeyDown: (ev: React.KeyboardEvent<HTMLElement>) => void;
  onContextMenu: (ev: React.MouseEvent<HTMLElement>) => void;
}

const getLabel = (type: 'shared' | 'private', userGroups?: string[]) => {
  if (type === 'private') return 'Private';
  return !userGroups?.length ? 'Everyone' : 'Shared';
};

function SavedSearchItem({
  item,
  selected,
  groupMap,
  onClick,
  onKeyDown,
  onContextMenu,
  setNodeRef,
  parentSize,
  listeners,
  transform,
  transition,
  attributes,
}: Readonly<SavedSearchItemProps & RenderItemProps>) {
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const groupTooltip = useMemo(() => {
    return item.visibleTo.map((v) => groupMap[v]?.label).join(', ');
  }, [groupMap, JSON.stringify(item.visibleTo)]);

  return (
    <StyledHStack
      justifyContent="start"
      alignItems="center"
      width="100%"
      onClick={onClick}
      onContextMenu={onContextMenu}
      $selected={selected}
      onKeyDown={onKeyDown}
      ref={setNodeRef}
      style={style}
      gap={parentSize === 'small' ? '6px' : '8px'}
      {...attributes}
      {...listeners}
    >
      {item.color ? (
        <ColorStripe $color={item.color} style={{ marginTop: '1px' }} />
      ) : (
        <div style={{ width: '4px' }} />
      )}
      <HStack justifyContent="space-between" width="100%" gap="4px">
        <SavedSearchLabelText variant="caption" $small={parentSize === 'small'}>
          {item.label}
        </SavedSearchLabelText>
        {parentSize === 'max' && (
          <Tooltip title={groupTooltip} enterDelay={500}>
            <SubText variant="caption">{getLabel(item.type, item.visibleTo)}</SubText>
          </Tooltip>
        )}
      </HStack>
    </StyledHStack>
  );
}

export default memo(SavedSearchItem);
