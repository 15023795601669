import { useCallback, useId, useEffect } from 'react';

/**
 * @param { DOMRect } rect The rect to the DOM element this highlight should be drawn on.
 * If falsy, hook will return early
 * @param { Boolean } visible When true, this hook will
 * draw a highlight rectangle on top of the provided rectangle
 */
const useHighlightRectangle = (rect, visible) => {
  const rectangleDivId = useId();
  const createHighlightRectangle = useCallback(() => {
    if (rect) {
      const rectangleDiv = getHighlightRectangle(rect);
      rectangleDiv.id = rectangleDivId;
      document.body.appendChild(rectangleDiv);
    }
  }, [rect, rectangleDivId]);

  useEffect(() => {
    if (visible) {
      createHighlightRectangle();
    }
    return () => {
      document.getElementById(rectangleDivId)?.remove();
    };
  }, [createHighlightRectangle, rectangleDivId, visible]);
};

function getHighlightRectangle(rect) {
  const divElement = document.createElement('div');
  divElement.style.position = 'absolute';
  divElement.style.border = '2px solid orange';
  divElement.style.zIndex = 9999;
  divElement.style.top = `${rect.y}px`;
  divElement.style.width = `${rect.width}px`;
  divElement.style.height = `${rect.height}px`;
  divElement.style.left = `${rect.x}px`;
  divElement.style.borderRadius = '4px';
  divElement.style.pointerEvents = 'none';
  return divElement;
}

export default useHighlightRectangle;
