import styled from '@emotion/styled';
import { ReactComponent as NotificationIconComponent } from 'assets/icons/systemicons/editor/PushMsgGeneric.svg';

export const NotificationIcon = styled(NotificationIconComponent)`
  width: 24px;
  height: 24px;
  margin: 8px;
  path {
    fill: ${({ theme }) => theme.palette.dina.highEmphasis};
    fill-opacity: 0.54;
  }
`;

export const CheckboxWrapper = styled('div')`
  margin-bottom: 8px;
`;

export const ContentWrapper = styled('div')`
  margin-left: 44px;
`;

export const AutocompleteWrapper = styled('div')`
  width: 380px;
  margin-top: 16px;
`;

export const CollapsedWrapper = styled('span')`
  display: flex;
  flex-direction: column;
`;

export const DestinationWrapper = styled('span')`
  color: ${({ theme }) => theme.palette.dina.statusWarning};
`;
