import { Text } from 'slate';

/**
 * Finds character count on a textnode with spaces and newline
 *
 * @param {Object[]} nodes text node
 * @returns {Number} character counter
 */

const getCharacterCount = (nodes) =>
  nodes.reduce((acc, node) => {
    const { text } = node;
    if (Text.isText(node)) {
      const textLength = acc + text.length;

      return textLength;
    }
    return 0;
  }, 0);

export default getCharacterCount;
