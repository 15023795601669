import { useMutation } from '@apollo/client';
import ARCHIVE_MEMBER from 'operations/mutations/archiveMember';
import GET_TEAMS from 'operations/queries/getTeams';
import GET_DEPARTMENTS from 'operations/queries/getDepartments';
import GET_RUNDOWNS from 'operations/queries/getRundowns';
import GET_ALL_RUNDOWN_TEMPLATES from 'operations/queries/getAllRundownTemplates';
import GET_MEMBERS_OF from 'operations/queries/getMembersOf';
import GET_INSTANCES_OF from 'operations/queries/getInstance';
import useLogger from 'utils/useLogger';
import memberTypes from 'operations/memberTypes';
import {
  getMembersOfTypeQuery,
  getMembersOfQuery,
  getStoryInstancesQuery,
} from 'operations/queryVariables';

const useArchiveMember = () => {
  const [archiveMemberMutation] = useMutation(ARCHIVE_MEMBER);
  const logger = useLogger('use archive member');
  const archiveMember = async (
    mId,
    memberType = memberTypes.TEAM,
    rundownId = '',
    storyId = '',
  ) => {
    const input = {
      mId,
    };
    if (rundownId) {
      input.rundownId = rundownId;
    }

    let memberQuery = GET_TEAMS;
    let memberTypeOf = memberTypes.TEAM_USER;

    switch (memberType) {
      case memberTypes.DEPARTMENT:
        memberTypeOf = memberTypes.DEPARTMENT_USER;
        memberQuery = GET_DEPARTMENTS;
        break;
      case memberTypes.TEAM:
        memberTypeOf = memberTypes.TEAM_USER;
        memberQuery = GET_TEAMS;
        break;

      default:
        memberQuery = GET_TEAMS;
        memberTypeOf = memberTypes.TEAM_USER;
    }

    await archiveMemberMutation({
      variables: { input },
      update: (proxy, mutationResult) => {
        const { mUpdatedById } = mutationResult.data.archiveMember;
        try {
          //update instance cache
          if (storyId) {
            const instances = proxy.readQuery({
              query: GET_INSTANCES_OF,
              variables: getStoryInstancesQuery(storyId),
            });
            if (instances?.getMembersOfTypeBySecId) {
              const newInstancesList = instances?.getMembersOfTypeBySecId?.filter(
                (instance) => instance?.mId !== mId,
              );
              proxy.writeQuery({
                query: GET_INSTANCES_OF,
                variables: getStoryInstancesQuery(storyId),
                data: { getMembersOfTypeBySecId: newInstancesList },
              });
            }
          }

          const member = proxy.readQuery({
            query: memberQuery,
            variables: getMembersOfTypeQuery(memberType),
          });

          const newTeams = member.getMembersOftype.filter((team) => team.mId !== mId);

          proxy.writeQuery({
            query: memberQuery,
            variables: getMembersOfTypeQuery(memberType),
            data: { getMembersOftype: newTeams },
          });

          const myMembers = proxy.readQuery({
            query: GET_MEMBERS_OF,
            variables: getMembersOfQuery(mUpdatedById, memberTypeOf),
          });

          const wasMyMember = myMembers.getMembersOf.filter((team) => team.mId !== mId);

          proxy.writeQuery({
            query: getMembersOfQuery,
            variables: getMembersOfQuery(mUpdatedById, memberTypeOf),
            data: { getMembersOf: wasMyMember },
          });
        } catch (error) {
          // logger.log(error);
        }
      },
    });
  };

  const archiveMasterRundown = async (mId) => {
    const input = { mId };

    await archiveMemberMutation({
      variables: { input },
      update: (proxy) => {
        try {
          const cacheInput = { mId: '' };

          const allRundowns = proxy.readQuery({
            query: GET_RUNDOWNS,
            variables: cacheInput,
          });

          const allTemplateRundowns = proxy.readQuery({
            query: GET_ALL_RUNDOWN_TEMPLATES,
            variables: cacheInput,
          });

          const updatedRundowns = allRundowns?.getRundowns?.filter(
            (rundown) => rundown.mId !== mId,
          );
          const updatedTemplateRundowns = allTemplateRundowns?.getAllRundownTemplates?.filter(
            (rundown) => rundown.mId !== mId,
          );

          proxy.writeQuery({
            query: GET_RUNDOWNS,
            variables: cacheInput,
            data: { getRundowns: updatedRundowns },
          });

          proxy.writeQuery({
            query: GET_ALL_RUNDOWN_TEMPLATES,
            variables: cacheInput,
            data: { getAllRundownTemplates: updatedTemplateRundowns },
          });
        } catch (error) {
          logger.log(error);
        }
      },
    });
  };

  return [archiveMember, archiveMasterRundown];
};

export default useArchiveMember;
