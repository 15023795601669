import { Node } from 'slate';

/**
 * Converts SlateJS nodes to plain text
 *
 * @param {Object[]} document SlateJS document instance
 * @returns {String}
 */

const serializeToText = (document = []) =>
  document
    ? document
        .map(node => Node.string(node))
        .join(' ')
        .trim()
    : '';

export default serializeToText;
