import * as React from 'react';

import Tooltip from 'components/tooltip/Tooltip';

import { BaseButton } from './Base';
import { ButtonProps } from './types';

import { DisableWrapper } from './styled';

interface BtnProps extends ButtonProps {
  title?: string | React.ReactElement;
}

function TooltipWrapper({
  children,
  title,
  disabled,
}: {
  children: React.ReactElement;
  title?: string | React.ReactElement;
  disabled?: boolean;
}) {
  return title ? (
    <Tooltip title={title}>
      {disabled ? <DisableWrapper>{children}</DisableWrapper> : children}
    </Tooltip>
  ) : (
    children
  );
}

const Button = React.forwardRef<HTMLButtonElement, BtnProps>(
  ({ title = '', disabled, children, ...props }, ref) => {
    return (
      <TooltipWrapper title={title} disabled={disabled}>
        <BaseButton ref={ref} disabled={disabled} {...props}>
          {children}
        </BaseButton>
      </TooltipWrapper>
    );
  },
);
Button.displayName = 'Button';

export default Button;
