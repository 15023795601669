import { isDateRange } from 'api/search';
import Text, { TextProps } from 'components/text/Text';
import Tooltip from 'components/tooltip/Tooltip';
import { isoToLocaleShort } from 'utils/datetimeHelpers';

import { DateRange } from './DatePicker';

import { DateLabel } from './styled';

const NoDateSelectedLabel = 'Select a date';

interface DateDefaultProps extends TextProps {
  date?: string | DateRange;
  onClick: (ev: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  hint?: string;
}

const generateUserVisibleDate = (val?: string | DateRange): string => {
  if (typeof val === 'string') {
    const displayDate = new Intl.DateTimeFormat('default', {
      dateStyle: 'full',
      timeStyle: 'short',
    }).format(new Date(val));
    return displayDate;
  } else if (val && isDateRange(val)) {
    return `${isoToLocaleShort(val.startDate)} - ${isoToLocaleShort(val.endDate ?? val.startDate)}`;
  }
  return NoDateSelectedLabel;
};

function DateDefault({ date, onClick, variant, color, hint }: Readonly<DateDefaultProps>) {
  const label = generateUserVisibleDate(date);
  const dateSelected = label !== NoDateSelectedLabel;

  return (
    <Tooltip title={hint ?? 'Edit default date'}>
      <DateLabel onClick={onClick}>
        <Text
          capitalize={dateSelected}
          variant={variant ?? 'listItemLabel'}
          color={color ?? (date ? 'highEmphasis' : 'mediumEmphasis')}
        >
          {label}
        </Text>
      </DateLabel>
    </Tooltip>
  );
}

export default DateDefault;
