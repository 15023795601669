import { isProductionMode } from 'utils/signedCookie';

/**
 * Generates an instance link based on the provided story ID and instance ID.
 *
 * This function constructs a URL for accessing a specific instance of a story
 * based on the story ID and instance ID. The resulting URL includes the protocol
 * (HTTP or HTTPS, depending on the production mode), the current host, and the
 * relevant story and instance IDs.
 *
 * @param storyId - The unique identifier of the story.
 * @param instanceId - The unique identifier of the instance.
 *
 * @returns A string representing the instance link URL.
 */
export const createInstanceLink = (storyId: string, instanceId: string) =>
  `http${isProductionMode ? 's' : ''}://${
    window.location.host
  }/story/${storyId}?storyPanes=instances.${instanceId}`;
