import { selectItems } from '../constants';
import checkIfArray from './checkIfArray';

const getInitialData = ({
  templateType,
  templateVariant = 'VARIANT',
  transition = selectItems[0],
  metaData = [],
  assets = [],
  ...rest
}) => ({
  templateType,
  templateVariant,
  transition,
  metaData: checkIfArray(metaData),
  assets: checkIfArray(assets),
  ...rest,
});

export default getInitialData;
