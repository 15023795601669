import { keyBy } from 'lodash';

import { FieldValue, Metadata } from 'types/forms/forms';
import { FieldTypeEnum, Mdf, MdfField } from 'types/graphqlTypes';

export const isSingleArray = (val: FieldValue | null): val is Array<string> => {
  if (Array.isArray(val)) {
    if (!val.length) return true;
    return typeof val[0] === 'string';
  }
  return false;
};

export const isPrefixArray = (arr1: string[], arr2: string[]): boolean =>
  arr1.length >= arr2.length && arr2.every((element, index) => element === arr1[index]);

export const normalizeFilter = (val: string[] | undefined) => {
  if (!val || (Array.isArray(val) && !val.length)) return undefined;
  return val;
};

export const getAllRelevantMdfFields = (metadata: Metadata, mdf: Mdf, subTypeMdfs: Mdf[]) => {
  let map: Record<string, MdfField> = {};
  const byLabel = keyBy(subTypeMdfs, (m) => m.label);

  for (const field of mdf.fields) {
    map[field.fieldId] = field;
    if (field.type === FieldTypeEnum.subtype) {
      for (const alt of field.alternatives ?? []) {
        const subMdf = byLabel[alt.value];
        if (subMdf) {
          map = {
            ...map,
            ...keyBy(subMdf.fields, (f) => f.fieldId),
          };
        }
      }
    }
  }

  return map;
};

const isMissingValue = (value: FieldValue, field: MdfField) => {
  if (!field.required) return false;
  if (field.defaultValue.value === null) return false;
  if (value === undefined || value === null) return true;
  return false;
};

export const getDefaultValuesToSave = (metadata: Metadata, mdf: Mdf, subTypeMdfs: Mdf[]) => {
  let missingValues: Metadata = {};
  for (const field of mdf.fields) {
    if (isMissingValue(metadata[field.fieldId], field)) {
      missingValues[field.fieldId] = field.defaultValue.value;
      if (field.type === FieldTypeEnum.subtype) {
        const subMdf = subTypeMdfs.find((s) => s.label === field.defaultValue.value);
        if (subMdf) {
          missingValues = {
            ...missingValues,
            ...getDefaultValuesToSave(metadata, subMdf, subTypeMdfs),
          };
        }
      }
    }
  }
  return missingValues;
};
