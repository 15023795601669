import { Transforms } from 'slate';
import { actionTypes } from 'components/editor/constants/types';
import notifyChange from 'components/editor/utils/notifyChange';

/**
 * Removes placeholder media from given element
 *
 * @param {Object} editor SlateJS editor instance
 * @param {Object} data Data properties of the SlateJS element
 * @param {Object} placeholder Current placeholder to be removed
 * @param {Function} update Callback to be invoked on update
 * @returns {Object} SlateJS editor instance
 */

const removePlaceholder = async (editor, data, placeholder, update) => {
  const { assets: previousAssets, ...rest } = data;

  const updatedData = {
    ...rest,
    assets: previousAssets.filter(({ mediaType }) => mediaType !== 'video/placeholder'),
  };

  try {
    await update({
      type: actionTypes.REMOVE_PLACEHOLDER,
      payload: { document: editor.children, placeholder },
    });

    Transforms.setNodes(editor, { data: updatedData });
    notifyChange(editor, update);
  } catch (error) {
    // logger.log(error)
  }

  return editor;
};

export default removePlaceholder;
