import { useSelectedInstances } from 'screens/rundown/store/screen';
import { toolbarButtonIds, useRundownToolbarButtons } from 'screens/rundown/store/toolbar';

const useSelectRundownInstance = () => {
  const [, setSelectedIds] = useSelectedInstances();
  const [rundownToolbarButtons, setRundownToolbarButtons] = useRundownToolbarButtons();

  const selectRundownInstance = (instanceIds) => {
    setSelectedIds(instanceIds);
    const newRundownToolbarButtons = rundownToolbarButtons.reduce((accumulator, button) => {
      if (button?.id !== toolbarButtonIds.EDITOR) return [...accumulator, button];
      return [...accumulator, { ...button, selected: true }];
    }, []);
    setRundownToolbarButtons(newRundownToolbarButtons);
  };

  return { selectRundownInstance };
};

export default useSelectRundownInstance;
