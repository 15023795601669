import { useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { keyBy } from 'lodash';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import { ReactComponent as AddIcon } from 'assets/icons/systemicons/add.svg';
import { IconButton } from 'components/buttons';
import Dialog from 'components/dialogs/DialogBuilder';
import { MdfEditor } from 'components/mdfEditor/MdfEditor';
import useToast from 'components/toast/useToast';
import { sanitizeMetadata } from 'features/mdf/mdf-utils';
import useMetadata from 'hooks/useMetadata';
import CREATE_CONTACT from 'operations/mutations/createContact';
import { useSetPreview } from 'store/preview';
import { FieldValue, Metadata } from 'types/forms/forms';
import { MemberTypeEnum } from 'types/graphqlTypes';
import { User } from 'types/members';
import useLogger from 'utils/useLogger';

import { Wrapper } from './styled';

interface CreateContactResult {
  createContact: User;
}

const contactType = MemberTypeEnum.Contact;

/**
 * Helper function to safely get a string value from Metadata
 */
const getTrimmedValue = (value: FieldValue): string => {
  return typeof value === 'string' ? value.trim() : '';
};

/**
 * Returns title generated from metadata.
 * @param metadata - Contact metadata
 * @returns title generated from metadata.
 *  - Precedence:
 *    - title
 *    - name
 *    - firstName + surname
 *    - surname
 *   Returns empty string if no valid metadata is provided
 * @example
 * getTitle({ firstName: 'John', surname: 'Doe' });
 *
 */
export const getTitle = (metadata: Metadata | undefined) => {
  if (!metadata) return '';

  const title = getTrimmedValue(metadata.title);
  if (title) return title;

  const name = getTrimmedValue(metadata.name);
  if (name) return name;

  const firstName = getTrimmedValue(metadata.firstName);
  const surname = getTrimmedValue(metadata.surname);
  if (!firstName) return surname;
  if (!surname) return firstName;
  return `${firstName} ${surname}`;
};

interface Props {
  startMetadata: Metadata;
}

function CreateContact({ startMetadata }: Readonly<Props>) {
  const setPreview = useSetPreview();
  const [open, setOpen] = useState(false);
  const { mdfsByMType, mdfsSeparated } = useGetMdfs({ all: true });
  const { errorToast } = useToast();
  const logger = useLogger('create contact');

  const mdf = mdfsByMType.contact;
  const subTypeByLabel = useMemo(() => {
    return keyBy(mdfsSeparated.subTypes, (m) => m.label);
  }, [mdfsSeparated.subTypes]);

  const { metadata, errorMap, errorTooltip, updateFieldValues } = useMetadata(
    mdf,
    startMetadata,
    'default',
    undefined,
    open,
  );

  const [createContact, { loading }] = useMutation(CREATE_CONTACT, {
    update: (_proxy, mutationResult) => {
      const { createContact: newContact } = mutationResult?.data as CreateContactResult;
      if (newContact) {
        setPreview(newContact);
      }
    },
  });

  const handleOnClose = () => {
    setOpen(false);
  };

  // TODO - replace with proper types & backend calls.
  // We need to sanitize metadata because backend just saves blindly what
  // we give it via graphql.
  const createNewContact = () => {
    if (!mdf) return;
    const sanitizedMetadata = sanitizeMetadata(metadata, mdf, subTypeByLabel);
    const input = {
      // Explicit set to NO TITLE if no title is possible to created from metadata
      mTitle: getTitle(metadata) ?? 'NO TITLE',
      mType: contactType,
      metadata: JSON.stringify(sanitizedMetadata),
    };

    createContact({
      variables: {
        input,
      },
    })
      .catch((e) => {
        logger.log(e);
        errorToast(e);
      })
      .finally(() => {
        handleOnClose();
      });
  };

  return (
    <>
      <IconButton
        style={{ paddingRight: '8px' }}
        usage="text"
        size={24}
        iconSize={18}
        title="Add Contact"
        onClick={() => setOpen(true)}
      >
        <AddIcon />
      </IconButton>
      <Dialog open={open} onClose={handleOnClose} modal>
        <Dialog.Header>Create Contact</Dialog.Header>
        <Dialog.Body>
          <Wrapper>
            {mdf && (
              <MdfEditor
                errorMap={errorMap}
                view="default"
                fields={mdf.fields}
                moreVerticalSpace={true}
                defaultLayoutSettings={mdf.views.default}
                layoutSettings={mdf.views.default}
                metadata={metadata}
                permissions={mdf.permissions}
                updateFieldValue={updateFieldValues}
              />
            )}
          </Wrapper>
        </Dialog.Body>
        <Dialog.Footer>
          <Dialog.CancelButton onCancel={handleOnClose} />
          <Dialog.ConfirmButton
            title={errorTooltip !== undefined ? errorTooltip : undefined}
            label="Create"
            onConfirm={createNewContact}
            disabled={!!errorTooltip || loading}
            loading={loading}
          />
        </Dialog.Footer>
      </Dialog>
    </>
  );
}

export default CreateContact;
