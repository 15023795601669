import { useState } from 'react';
import styled from '@emotion/styled';

import Dialog from 'components/dialogs/DialogBuilder';
import DraggableDialog from 'components/dialogs/Draggable';
import { DialogHeader as DialogHeaderPrimitive, DialogTitle } from 'lib/dialog';

import { VesselProps } from '../VesselFactory';

const StyledDialogTitle = styled(DialogTitle)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export function DialogVessel({ children, open, onClose, title }: Readonly<VesselProps>) {
  const [dimension, setDimension] = useState({
    width: `${window.innerWidth < 720 ? window.innerWidth : '900'}`,
    height: `${window.innerHeight < 1080 ? window.innerHeight : '1080'}`,
  });

  return (
    <DraggableDialog
      open={open}
      onClose={onClose}
      initialPosition={{
        x: 400,
        y: 100,
      }}
      minWidth={500}
      minHeight={500}
      dimension={dimension}
      setDimension={setDimension}
    >
      <DialogHeaderPrimitive className="dragHandler">
        <StyledDialogTitle>{title}</StyledDialogTitle>
      </DialogHeaderPrimitive>
      <Dialog.Body bodyHeight="calc(100% - 38px)" overflow="auto">
        {children}
      </Dialog.Body>
    </DraggableDialog>
  );
}
