import schemas from './schemas';

const getSettingsFromSchema = schemaId => {
  const schema = schemas.find(sch => sch.id === schemaId);
  if (!schema) return {};

  const { categories } = schema;
  if (!categories || !categories.length) return {};

  const settings = {};
  categories.forEach(category => {
    const { properties } = category;
    if (properties) {
      Object.keys(properties).forEach(property => {
        settings[property] = properties[property].default;
      });
    }
  });
  return settings;
};

export default getSettingsFromSchema;
