import memberTypes from 'operations/memberTypes';

const getMType = (type) => {
  switch (type) {
    case memberTypes.DEPARTMENT:
      return memberTypes.DEPARTMENT_STORY;
    case memberTypes.TEAM:
      return memberTypes.TEAM_STORY;
    case memberTypes.USER_BOOKMARK:
      return memberTypes.USER_BOOKMARK;
    case memberTypes.DEPARTMENT_USER:
      return memberTypes.DEPARTMENT_USER;
    case memberTypes.STORY:
      return memberTypes.STORY;
    case memberTypes.PITCH:
      return memberTypes.PITCH;
    default:
      return memberTypes.USER_STORY;
  }
};

export default getMType;
