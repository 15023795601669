import { Slider } from 'lib/slider';

interface Props {
  value: number;
  setValue: (val: number) => void;
  range: [number, number];
}

function DragSlider({ value, setValue, range }: Readonly<Props>) {
  const handleChange = (val: number[]) => {
    setValue(val[0]);
  };

  return (
    <Slider value={[value]} onValueChange={handleChange} min={range[0]} max={range[1]} step={50} />
  );
}

export default DragSlider;
