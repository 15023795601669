import { useEffect, useRef } from 'react';

import {
  useExternalData as useExternalDataAtom,
  useSetAddItemToScratchpad,
} from 'store/scratchpad';

const TIMEOUT_EXPIRE_TIME = 12000;

/**
 * This hook abstracts the usage of the thumbnail data the thumbnail that is displayed
 * in the top right when a user i.e imports media from mimir.
 * The thumbnail will be be shown for @property {TIMEOUT_EXPIRE_TIME} milliseconds
 *
 * @returns {Array} Returns an array containing thumbnail data and a setter function.
 */
export const useExternalData = () => {
  const [externalData, setExternalData] = useExternalDataAtom();
  const addItemToScratchpad = useSetAddItemToScratchpad();
  const timeOut = useRef<0 | NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (timeOut.current) {
      clearTimeout(timeOut.current);
      timeOut.current = null;
    }

    timeOut.current =
      externalData.length &&
      setTimeout(() => {
        setExternalData([]);
      }, TIMEOUT_EXPIRE_TIME);
  }, [externalData, setExternalData]);

  useEffect(() => {
    window.addEventListener('message', addItemToScratchpad, false);

    return () => {
      window.removeEventListener('message', addItemToScratchpad);
    };
  }, [addItemToScratchpad]);

  return [externalData, setExternalData];
};
