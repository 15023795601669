import getDirection from 'utils/text/getDirection';

const repositionTrailingDotForRTL = (text: string): string => {
  const direction = getDirection(text);

  // Return non-RTL text as it is
  if (direction !== 'rtl') return text;

  // Split the text into sentences
  const sentences = text.split(/(?<=\.)/);

  // Process each sentence
  const processedSentences = sentences.map((sentence) => {
    sentence = sentence.trim();
    if (sentence.endsWith('.') && getDirection(sentence) === 'rtl') {
      // For RTL sentences ending with a dot, move the dot to the beginning
      return '.' + sentence.slice(0, -1);
    }
    return sentence;
  });

  // Join the sentences, ensuring there's a space between them
  return processedSentences.join(' ');
};

export default repositionTrailingDotForRTL;
