import { getWords, getRedWords } from 'components/editor/utils';
import { useCallback, useState } from 'react';
import {
  durationTypes,
  getDuration,
  getSeconds,
  getTime,
  getDurationKey,
} from 'screens/rundown/components/editor/utils';

const getDurationField = (metadata, str) => metadata.find((item) => getDurationKey(item) === str);

const defaultDurations = {
  scriptDuration: null,
  totalDuration: null,
};

const useUpdateDurationMeta = (blankMetaData, updateMetadata) => {
  const defWordCount = getDurationField(blankMetaData, durationTypes.WORD_COUNT);
  const defClipDuration = getDurationField(blankMetaData, durationTypes.CLIP_DURATION);
  const defTotalDuration = getDurationField(blankMetaData, durationTypes.TOTAL_DURATION);
  const defSpeakDuration = getDurationField(blankMetaData, durationTypes.SPEAK_DURATION);

  const [durations, setDurations] = useState(defaultDurations);

  /**
   * Extracts only the duration metadata from the given metadata array
   * @param {*} metadata - array of metadata (key/value)
   * @returns duration metadata (key/value)
   */
  const getDurationMetadata = (metadata) => [
    {
      key: defWordCount.key,
      value: getDuration(metadata, durationTypes.WORD_COUNT, defWordCount.value),
    },
    {
      key: defClipDuration.key,
      value: getDuration(metadata, durationTypes.CLIP_DURATION, defClipDuration.value),
    },
    {
      key: defTotalDuration.key,
      value: getDuration(metadata, durationTypes.TOTAL_DURATION, defTotalDuration.value),
    },
    {
      key: defSpeakDuration.key,
      value: getDuration(metadata, durationTypes.SPEAK_DURATION, defSpeakDuration.value),
    },
  ];

  const getDurationFields = (content, hostReadRate, durationFields) => {
    if (!content?.document) return durationFields;

    const words = getWords(content.document);
    const redWords = getRedWords(content.document);
    const redDuration = redWords.reduce((acc, word) => {
      const time = getSeconds(word.split('=')[1]) || 0;
      return acc + time;
    }, 0);
    const wordCount = words ? words.length : 0;
    const readRate = hostReadRate; // words per minute
    const wordsPerSecond = readRate / 60;
    const newSpeakDuration = Math.ceil(wordCount / wordsPerSecond);
    const oldWordCount = getDuration(durationFields, durationTypes.WORD_COUNT);

    const clipDuration = redDuration
      ? getTime(redDuration)
      : getDuration(durationFields, durationTypes.CLIP_DURATION);
    const newTotalDuration = getSeconds(clipDuration) + newSpeakDuration;

    return {
      speakDuration: newSpeakDuration,
      oldSpeakDuration: getSeconds(getDuration(durationFields, durationTypes.SPEAK_DURATION)),
      wordCount,
      oldWordCount,
      clipDuration,
      totalDuration: newTotalDuration,
    };
  };

  const handleUpdateDurationFields = (
    value,
    hostReadRate,
    currentMetadata,
    canUpdateScriptDurationSettingsValue,
  ) => {
    const currentDurations = Array.isArray(currentMetadata)
      ? getDurationMetadata(currentMetadata)
      : [];

    const scriptDurationField = currentMetadata?.find(({ key }) =>
      key.includes(durationTypes.SPEAK_DURATION),
    );
    if (!value?.document) return currentDurations;

    const {
      speakDuration,
      oldSpeakDuration,
      wordCount,
      oldWordCount,
      clipDuration,
      totalDuration,
    } = getDurationFields(value, hostReadRate, currentDurations);

    if (speakDuration !== oldSpeakDuration || (oldWordCount && oldWordCount !== wordCount)) {
      const updatedDurations = [
        { key: defWordCount.key, value: wordCount },
        { key: defClipDuration.key, value: clipDuration },
      ];

      // to filter out both undefined and null
      if (!canUpdateScriptDurationSettingsValue || scriptDurationField?.manual == null) {
        updatedDurations.push(
          { key: defTotalDuration.key, value: getTime(totalDuration) },
          {
            key: defSpeakDuration.key,
            value: getTime(speakDuration),
            autoValue: getTime(speakDuration),
            manual: false,
          },
        );
      } else {
        updatedDurations.push(
          {
            key: defTotalDuration.key,
            value:
              scriptDurationField?.manual === false
                ? getTime(totalDuration)
                : currentDurations?.find(({ key }) => key.includes(durationTypes.TOTAL_DURATION))
                    ?.value,
          },
          {
            key: defSpeakDuration.key,
            value:
              scriptDurationField?.manual === true
                ? scriptDurationField.value
                : getTime(speakDuration),
            autoValue: getTime(speakDuration),
            manual: scriptDurationField?.manual ?? true,
          },
        );
      }

      updateMetadata(updatedDurations);
      return updatedDurations;
    }

    return currentDurations;
  };

  /** set script and total durations */
  const updateScripDuration = useCallback((content, hostReadRate, metadata, lockedByUser) => {
    if (!lockedByUser) {
      setDurations(defaultDurations);
      return;
    }

    const scriptDurationField = metadata?.find(({ key }) =>
      key.includes(durationTypes.SPEAK_DURATION),
    );
    const totalDurationField = metadata?.find(({ key }) =>
      key.includes(durationTypes.TOTAL_DURATION),
    );
    if (!content?.document) return;
    const durationFields = Array.isArray(metadata) ? getDurationMetadata(metadata) : [];
    const { speakDuration, totalDuration } = getDurationFields(
      content,
      hostReadRate,
      durationFields,
    );

    if (scriptDurationField?.manual === true) {
      setDurations({
        scriptDuration: scriptDurationField.value,
        totalDuration: totalDurationField.value,
      });
      return;
    }

    setDurations({
      scriptDuration: getTime(speakDuration),
      totalDuration: getTime(totalDuration),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [handleUpdateDurationFields, updateScripDuration, durations];
};

export default useUpdateDurationMeta;
