import React, { ForwardedRef, forwardRef } from 'react';
import styled from '@emotion/styled';

import Tooltip from 'components/tooltip';

interface Props {
  setValue: (val: boolean) => void;
  selected: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  title?: string;
}

const StyledCheckbox = styled.input`
  width: 16px;
  height: 16px;
  position: relative;
  top: 2px;
  margin-inline: 0px;
  cursor: pointer;
`;

const StyledTooltipChild = styled('div')`
  width: min-content;
`;

function LWCheckbox(
  { setValue, selected, disabled, style, title }: Props,
  ref?: ForwardedRef<HTMLInputElement> | null,
) {
  return title ? (
    <Tooltip title={title}>
      <StyledTooltipChild>
        <StyledCheckbox
          type="checkbox"
          checked={selected}
          disabled={disabled}
          onChange={() => {
            setValue(!selected);
          }}
          style={style}
          ref={ref}
        />
      </StyledTooltipChild>
    </Tooltip>
  ) : (
    <StyledCheckbox
      type="checkbox"
      checked={selected}
      disabled={disabled}
      onChange={() => {
        setValue(!selected);
      }}
      style={style}
      ref={ref}
    />
  );
}

export default forwardRef(LWCheckbox);
