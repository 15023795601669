/* eslint-disable import/no-anonymous-default-export */
const buildQuery = (queryName, variables) => {
  const inputVariables = variables.map((variable) => `"${variable.key}":"${variable.value}"`);

  const queryString = `${queryName}({"input":{${inputVariables.join(',')}}})`;

  return queryString;
};

/**
 * Checks if a query is in cache rootQuery or not
 *
 * @param {ApolloClient} apollo client proxy object
 * @param {String} name of the graphql query i.e - `getMembers`
 * @param {Object[]} list of variable object passed in the query in a {key, value} format: 
 * [
      { key: 'mId', value: item.storyId },
      { key: 'mType', value: memberTypes.STORY_INSTANCE },
   ]
 * @returns {Boolean} returns true if given query is in Cache rootQuery else false
 */
const checkIfQueryInRootQuery = (proxy, queryName, variables) => {
  const rootQuery = proxy.cache.data.data.ROOT_QUERY;

  const queryInCache = buildQuery(queryName, variables);

  if (rootQuery[queryInCache] === undefined) {
    return false;
  }
  return true;
};

export default { checkIfQueryInRootQuery };
