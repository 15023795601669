import { memo, useEffect } from 'react';

import Divider from 'components/divider';
import Scrollbar from 'components/scrollbar';
import useGetKanban from 'features/kanban/api/useGetKanban';
import ReadOnlyKanban from 'features/kanban/ReadOnly';
import { MemberType } from 'types/graphqlTypes';

import { EmptyList, Header, List, Wrapper } from './styled';

interface Props {
  mId: string;
  members: MemberType[];
  customRenderItem?: () => void;
}

const Kanban = ({ mId, members, customRenderItem }: Props) => {
  const { getKanban, loading } = useGetKanban();

  useEffect(() => {
    getKanban({ mId, members, fetchPolicy: 'network-only' }).then(
      () => {},
      () => {},
    );
  }, [members.length]);

  return (
    <Scrollbar>
      <List disablePadding>
        <ReadOnlyKanban
          loading={loading}
          customRenderItem={customRenderItem}
          filterKey="mId"
          darker
          customRenderHeader={undefined}
        />
      </List>
    </Scrollbar>
  );
};

function NewRundownSelector(props: Readonly<Props>) {
  const { members } = props;
  return (
    <div>
      <Header>Select master rundown</Header>
      <Divider />
      <Divider />
      <Wrapper>
        {members?.length !== 0 ? <Kanban {...props} /> : <EmptyList>Nothing to select</EmptyList>}
      </Wrapper>
    </div>
  );
}

export default memo(NewRundownSelector);
