import { useMemo } from 'react';
import styled from '@emotion/styled';

import { ReactComponent as Metadata } from 'assets/icons/systemicons/metadata.svg';
import { ReactComponent as Notification } from 'assets/icons/systemicons/notifications_off.svg';
import Avatar from 'components/avatar/Avatar';
import Text from 'components/text/Text';
import { Box, Flex } from 'layouts/box/Box';
import { ConversationTypeEnum } from 'types/graphqlTypes';
import { Conversation } from 'types/messageHub';
import conversationTypes from 'utils/constants/conversationTypes';

const AvatarWrapper = styled(Box)`
  position: relative;
  height: 24px;
  width: 24px;
  overflow: unset;
`;

const AvatarTop = styled('div')`
  position: absolute;
  top: 0px;
  right: 0px;
`;

const AvatarBottom = styled('div')`
  position: absolute;
  bottom: 0px;
  left: 0px;
`;

const AssigneeCount = styled(Flex)`
  position: absolute;
  bottom: 0px;
  right: 0px;
  padding: 0 2px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.palette.dina.blackInactive};
  border: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

function AvatarView({ conversation }: Readonly<{ conversation?: Conversation }>) {
  const {
    convoType = ConversationTypeEnum.Direct,
    mAvatarKey,
    mAvatarKeys = [],
    name,
  } = conversation ?? {};
  const title = typeof name === 'string' ? name : '';

  const hideBorder = useMemo(
    () => [ConversationTypeEnum.All, ConversationTypeEnum.Notification].includes(convoType),
    [conversationTypes, conversation?.convoType],
  );

  return (
    <AvatarWrapper>
      {convoType === ConversationTypeEnum.All && <Metadata />}
      {convoType === ConversationTypeEnum.Notification && <Notification />}
      {convoType === ConversationTypeEnum.Group && (
        <>
          <AvatarTop>
            <Avatar size={16} imageKey={mAvatarKeys[0]} title={title} tooltipContent={title} />
          </AvatarTop>
          <AvatarBottom>
            <Avatar size={16} imageKey={mAvatarKeys[1]} title={title} tooltipContent={title} />
          </AvatarBottom>
          <AssigneeCount>
            <Text variant="caption" color="whiteHighEmphasis">
              {conversation?.mAssignedMembers?.length}
            </Text>
          </AssigneeCount>
        </>
      )}
      {[
        ConversationTypeEnum.Direct,
        ConversationTypeEnum.Team,
        ConversationTypeEnum.Department,
      ].includes(convoType) && (
        <Avatar
          size={24}
          imageKey={mAvatarKey}
          title={title}
          tooltipContent={title}
          borderWidth={hideBorder ? 0 : 1}
        />
      )}
    </AvatarWrapper>
  );
}

export default AvatarView;
