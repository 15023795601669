/**
 * Convert an instance to an unassigned instance.
 * @param {instance} instance object
 */
const convertToUnassignedInstance = (instance) => ({
  ...instance,
  mProperties: {
    ...instance.mProperties,
    account: {
      ...instance.mProperties.account,
      accountUrl: 'Unassigned',
      accountLogo: 'url@tolog',
      accountTitle: 'Unassigned',
      accountId: null,
      accountRefId: null,
      orderType: null,
    },
  },
  mPublishingAt: null,
});

export default convertToUnassignedInstance;
