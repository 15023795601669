import { ReactComponent as Bold } from 'assets/icons/systemicons/editor/bold_off.svg';
import { ReactComponent as Italic } from 'assets/icons/systemicons/editor/italic_off.svg';
import { ReactComponent as StrikeThrough } from 'assets/icons/systemicons/editor/strikethrough_off.svg';
import { ReactComponent as Underline } from 'assets/icons/systemicons/editor/underline_off.svg';
import { ReactComponent as Uppercase } from 'assets/icons/systemicons/editor/uppercase.svg';
import { markTypes } from 'components/editor/constants';

const { BOLD, ITALIC, UNDERLINE, STRIKE_THROUGH, UPPERCASE } = markTypes;

const iconComponents = {
  [BOLD]: Bold,
  [ITALIC]: Italic,
  [UNDERLINE]: Underline,
  [STRIKE_THROUGH]: StrikeThrough,
  [UPPERCASE]: Uppercase,
};

export default iconComponents;
