import elementTypes from 'components/editor/constants/types/elementTypes';
import getTwitterMetaKey from 'utils/metadata/getTwitterMetaKey';

const useUpdateTwitterThreadCount = (blankMetaData, updateMetadata) => {
  const tweetMetaKey = getTwitterMetaKey(blankMetaData);

  const handleUpdateDurationFields = (value, currentMetadata) => {
    if (!value || !value.document) return [];
    const { document } = value;
    const newCount = document.filter(node => node.type === elementTypes.TWEET_THREAD).length;
    const threadCountMeta = currentMetadata.find(meta => meta.key === tweetMetaKey);
    const oldCount = threadCountMeta && threadCountMeta.value;

    if (newCount !== oldCount) {
      const updatedThreadCount = [{ key: tweetMetaKey, value: newCount }];
      updateMetadata(updatedThreadCount);
      return updatedThreadCount;
    }
    return [];
  };
  return [handleUpdateDurationFields];
};

export default useUpdateTwitterThreadCount;
