import { Transforms } from 'slate';

import isSection from './isSection';

const { unwrapNodes } = Transforms;

/**
 * Unwraps the section-divider children into the editor
 * @param {Object} editor SlateJS editor instance
 * @param {Object} NodeOptions accepted in unwrapNodes
 * @returns void
 */
const unwrapSection = (editor, { at = undefined, split = false } = {}) =>
  unwrapNodes(editor, { match: isSection, at, split });

export default unwrapSection;
