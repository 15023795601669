import React from 'react';

/**
 * Wrap an event with this to determine whether the onClick should be canceled
 * if the user is attempting to select text instead
 */
const clickIfNothingSelected = (ev: React.MouseEvent<HTMLElement>, handler: unknown) => {
  const cellText = document.getSelection();
  if (cellText?.type === 'Range') {
    ev.stopPropagation();
    return;
  } else if (typeof handler === 'function') {
    handler();
  }
};

export default clickIfNothingSelected;
