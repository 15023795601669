import { useRightHidden, useRightSelection } from 'store/sidebar';
import openAssetInMimir, { isMimirAssetItem } from 'utils/openAssetInMimir';

const useOpenAssetInMimir = () => {
  const [, setRightHidden] = useRightHidden();
  const [, setRightSelection] = useRightSelection();
  const openInMimir = (element) => {
    if (!isMimirAssetItem(element)) return;
    setRightHidden(false);
    setRightSelection('storybox');
    openAssetInMimir(element);
  };
  return openInMimir;
};

export default useOpenAssetInMimir;
