import gql from 'graphql-tag';

export default gql`
  query GetMembers($input: GetMemberInput) {
    getMembers(input: $input) {
      mId
      mRefId
      mTitle
      mType
      mDescription
      mAvatarKey
    }
  }
`;
