import { ReactElement } from 'react';

import { Body, Heading, Icon, Wrapper } from './styled';

function EmptyState(props: { message: string } | { children: ReactElement }) {
  return (
    <Wrapper>
      <Icon />
      <Heading>Nothing to show here</Heading>
      <Body>{'message' in props ? props.message : props.children}</Body>
    </Wrapper>
  );
}

export default EmptyState;
