import React from 'react';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import { Tweet as ReactTweet } from 'react-tweet';
import getTweetIdFromURL from 'utils/twitter/getTweetIdFromURL';
import useStyles from './embed-styles';

const EmbedView = ({
  showTwitterEmbed,
  showYoutubeEmbed,
  showFacebookEmbed,
  showInstagramEmbed,
  provider,
  readLock,
  writeLock,
  height = undefined,
}) => {
  const { embeddedEndpoint, previewEndpoint } = provider || {};
  const tweetId = getTweetIdFromURL(previewEndpoint);
  const classes = useStyles({ writeLock, readLock, height });

  switch (true) {
    case showTwitterEmbed:
      return (
        <div className={classes.embedContainer}>
          <ReactTweet fallback={<LoadingIndicator />} id={tweetId} />
        </div>
      );
    case showYoutubeEmbed:
      return (
        <iframe
          src={embeddedEndpoint}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
          title="video"
          className={classes.youtubeIframe}
        />
      );
    case showFacebookEmbed:
      return (
        <div className={classes.facebookIframeContainer}>
          <iframe
            src={embeddedEndpoint}
            frameBorder="0"
            allowFullScreen
            title="facebook-embed"
            className={classes.facebookIframe}
          />
        </div>
      );
    case showInstagramEmbed:
      return (
        <div className={classes.instaIframeContainer}>
          <iframe
            src={embeddedEndpoint}
            frameBorder="0"
            title="instagram-embed"
            className={classes.instagramIframe}
          />
        </div>
      );
    default:
      return <div className={classes.noEmbedContainer} />;
  }
};

export default EmbedView;
