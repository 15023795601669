import gql from 'graphql-tag';

export default gql`
  query GetMembersWithMid($input: GetMemberInput) {
    getMemberFromId(input: $input) {
      mTitle
      mId
      mRefId
      mType
      mStoryId
    }
  }
`;
