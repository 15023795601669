import styled from '@emotion/styled';
import { ReactComponent as SourceIconComponent } from 'assets/icons/systemicons/FeedSource.svg';

export const SourceIcon = styled(SourceIconComponent)`
  margin: 8px;
`;

export const ContentWrapper = styled('div')`
  width: 100%;
`;

export const ChipListWrapper = styled('div')`
  list-style: none;
  margin-bottom: 16px;
`;

export const ListItem = styled('li')`
  margin-top: 4px;
`;
