/* eslint-disable import/no-extraneous-dependencies */
import { useContext, useState, useCallback, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { configurableActionMTypes } from 'screens/main/components/header/navbar/settings/components/integrations/constants';
import { useContextMenu } from 'react-contexify';
import { useQuery, NetworkStatus } from '@apollo/client';
import throttle from 'lodash/throttle';
import List from '@material-ui/core/List';
import memberTypes from 'operations/memberTypes';
import SEARCH_STORIES from 'operations/queries/searchApi';
import NOTIFY_MEMBER_UPDATE_SUBSCRIPTION from 'operations/subscriptions/notifyMemberUpdate';
import UserCtx from 'contexts/UserContext';
import useCheckUserRight from 'hooks/useCheckUserRight';
import useApolloSubscription from 'hooks/useApolloSubscriptionV2';
import useUpdateLeftSidebarCache from 'hooks/useUpdateLeftSidebarCache';
import Group from 'components/listGroup';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import Story from '../listItem';
import getMType from './utils/getMType';
import getFilterMType from './utils/getFilterMType';
import getIsUnscheduled from './utils/getIsUnscheduled';
import { useCreateMemberDialog } from 'components/createNewV3/CreateNewDialog';
import { useUserBookmarks } from 'store/bookmarks';

const getCreateTitle = (createType) => {
  switch (createType) {
    case memberTypes.STORY:
      return 'Create Story';
    case memberTypes.PITCH:
      return 'Create Pitch';
    default:
      return 'Create Story/Pitch';
  }
};

const getHideCreateNewButton = (createType, canCreateStory, canCreatePitch) => {
  switch (createType) {
    case memberTypes.STORY:
      return !canCreateStory;
    case memberTypes.PITCH:
      return !canCreatePitch;
    default:
      return !canCreateStory && !canCreatePitch;
  }
};

const sortMembers = (unsortedMembers, mId, mType, scheduleType) => {
  let ret = [];
  if (scheduleType === 'scheduled') {
    const sortedByPublishingAt =
      unsortedMembers
        .filter(Boolean)
        .sort((a, b) => (a.mPublishingAt < b.mPublishingAt ? 1 : -1)) || [];
    const groupedByPublishingAt = sortedByPublishingAt.reduce((r, a) => {
      // eslint-disable-next-line no-param-reassign
      r[a.mPublishingAt] = [...(r[a.mPublishingAt] || []), a];
      return r;
    }, {});
    ret = Object.values(groupedByPublishingAt)
      .map((arr) => arr.sort((a, b) => (a.mUpdatedAt < b.mUpdatedAt ? 1 : -1)) || [])
      .flat();
  }
  ret =
    unsortedMembers.filter(Boolean).sort((a, b) => (a.mUpdatedAt < b.mUpdatedAt ? 1 : -1)) || [];
  if (mType === memberTypes.TEAM_STORY || mType === memberTypes.DEPARTMENT_STORY) {
    return ret.filter((str) => (str.mAssignedMembers || []).find((m) => m.mId === mId));
  }
  return ret;
};

const GroupContainer = forwardRef(
  (
    {
      mId,
      title,
      type,
      groupData,
      setShowStorybox,
      selectedDates,
      scheduleType,
      subTabs,
      selectedSubTab,
      onBeforeRefetch,
      onAfterRefetch,
      userItemsOnly,
      searchString,
      showLoading,
      assignedMembers: searchAssignedMembers,
      disableCollapse,
      isOpen,
    },
    ref,
  ) => {
    const user = useContext(UserCtx);
    const { mId: userId } = user;
    const [bookmarks] = useUserBookmarks();
    const [, showCreateMemberDialog] = useCreateMemberDialog();
    const [checkUserRight] = useCheckUserRight();
    const canCreateStory = checkUserRight('story', 'create');
    const canCreatePitch = checkUserRight('pitch', 'create');
    const canArchiveStory = checkUserRight('story', 'archive');
    const canArchivePitch = checkUserRight('pitch', 'archive');
    const canUpdateStory = checkUserRight('story', 'update');
    const canUpdatePitch = checkUserRight('pitch', 'update');
    const canSelectRange = checkUserRight('feature', 'story-date-range');
    const [previewItemId, setPreviewItemId] = useState(null);
    const [selectedId, setSelectedId] = useState(null);
    const [, updateOnSubscription, setCurrentFilter] = useUpdateLeftSidebarCache();
    const { show } = useContextMenu({ id: 'memberMenu' });
    const handleKeyPress = useCallback((key, currentId, items, listLength, keyType) => {
      if (keyType === 'released') {
        setPreviewItemId(currentId);
        return;
      }

      const currentIndex = currentId ? items.findIndex((i) => i.mId === currentId) : null;

      if (key === 'ArrowUp') {
        if (currentIndex !== null && currentIndex > 0) {
          const selected = items[currentIndex - 1];
          setSelectedId(selected?.mId);
        }
      }

      if (key === 'ArrowDown') {
        if (currentIndex !== null && currentIndex < listLength - 1) {
          const selected = items[currentIndex + 1];
          setSelectedId(selected?.mId);
        }
      }
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const throttledHandleKeyPress = useCallback(
      throttle(
        (eventData) => {
          handleKeyPress(
            eventData.key,
            eventData.currentId,
            eventData.items,
            eventData.listLength,
            eventData.keyType,
          );
        },
        500,
        { leading: true },
      ),
      [],
    );

    const handleKeyUp = (event) => {
      if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        event.preventDefault();
        event.stopPropagation();
        throttledHandleKeyPress({
          key: event.key,
          currentId: selectedId,
          listLength: members.length,
          items: members,
          keyType: 'released',
        });
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        event.preventDefault();
        event.stopPropagation();
        throttledHandleKeyPress({
          key: event.key,
          currentId: selectedId,
          items: members,
          listLength: members.length,
          keyType: 'pressed',
        });
      }
    };

    const mType = getMType(type);
    const groupType = type === memberTypes.STORY ? type : mType;
    const bookmarkedIds = Object.keys(bookmarks);
    const skip = mType === memberTypes.USER_BOOKMARK && bookmarkedIds.length === 0;

    const mIds = [];

    const isUnscheduled = getIsUnscheduled(scheduleType, mType);
    const assignedMembers = [];

    const mTypes = [getFilterMType(mType, subTabs[selectedSubTab] === 'archived')];

    const filter = {
      searchString,
      mTypes,
      assignedMembers,
      userItemsOnly: mType === memberTypes.USER_STORY || userItemsOnly,
    };

    if (isUnscheduled !== undefined) filter.isUnscheduled = isUnscheduled;

    if (isUnscheduled !== true) {
      const { startDate, endDate } = selectedDates;

      const searchBefore = endDate ? endDate.toISOString() : undefined;
      const searchAfter = startDate ? startDate.toISOString() : undefined;

      if (searchBefore) filter.searchBefore = searchBefore;
      if (searchAfter) filter.searchAfter = searchAfter;
    }

    const variables = {
      limit: 25,
      filter,
    };

    if (mType === memberTypes.USER_STORY) {
      mTypes.push('pitch');
      assignedMembers.push(...searchAssignedMembers);
    } else if (mType === memberTypes.TEAM_STORY || mType === memberTypes.DEPARTMENT_STORY) {
      assignedMembers.push(mId);
      variables.limit = 20;
      if (searchAssignedMembers?.length > 0) {
        filter.assignedMembersMust = [...searchAssignedMembers];
      }
      mTypes.push('pitch');
    } else if (mType === memberTypes.USER_BOOKMARK) {
      mTypes.push('pitch');
      mIds.push(...bookmarkedIds);
      filter.mIds = mIds;
      assignedMembers.push(...searchAssignedMembers);
    } else {
      assignedMembers.push(...searchAssignedMembers);
    }

    setCurrentFilter(filter);

    const { data, error, fetchMore, networkStatus } = useQuery(SEARCH_STORIES, {
      variables,
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      skip,
    });

    useApolloSubscription(NOTIFY_MEMBER_UPDATE_SUBSCRIPTION, {
      variables: {
        mIdSubscribed: 'stories',
      },
      onSubscriptionData: ({ client, data: subscriptionData }) => {
        const item = subscriptionData.data.notifyMemberUpdateSubscription;
        updateOnSubscription(item, userId);
      },
      source: 'stories',
    });

    if (error) {
      const errorMessage = `Error! ${error.message}`;
      return <span style={{ color: 'white' }}>{errorMessage}</span>;
    }
    const hasMore = !!data?.searchApi?.nextToken;

    const fetchMoreStories = async () => {
      const scrollTopBefore = onBeforeRefetch();
      if (hasMore) {
        await fetchMore({
          variables: {
            ...variables,
            nextToken: data.searchApi.nextToken,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => ({
            // Add the new  items to the end of the old  items.
            searchApi: {
              items: [...previousResult.searchApi.items, ...fetchMoreResult.searchApi.items],
              nextToken: fetchMoreResult.searchApi.nextToken,
              __typename: 'MemberType',
            },
          }),
        });
      }
      onAfterRefetch(scrollTopBefore);
    };

    const members = data?.searchApi
      ? sortMembers(data.searchApi.items, mId, mType, scheduleType)
      : [];

    if (networkStatus === NetworkStatus.loading) return showLoading ? <LoadingIndicator /> : null;

    const belongsToSubTab = (story) => {
      if (subTabs[selectedSubTab] === 'scheduled') return story.mPublishingAt;
      if (subTabs[selectedSubTab] === 'unscheduled') return !story.mPublishingAt;
      return true;
    };

    const showMemberDialog = (e) => {
      showCreateMemberDialog({
        mId,
        anchorEl: e.currentTarget.parentNode,
        preselectedMember: groupData,
        relatedType: type,
        limitType: type === memberTypes.STORY || type === memberTypes.PITCH ? type : undefined,
      });
    };

    const handleContextClick = (item, ev) => {
      if (item.mType && configurableActionMTypes.includes(item.mType)) {
        ev.preventDefault();
        ev.stopPropagation();
        show({
          event: ev,
          props: {
            member: item,
          },
        });
      }
    };

    if (data) {
      return (
        <Group
          title={title}
          id={mId}
          hasMore={hasMore}
          handleCreateClicked={(e) => showMemberDialog(e)}
          type={mType === memberTypes.USER_BOOKMARK ? null : 'left'}
          onLoadMore={fetchMoreStories}
          toolTipTitle={getCreateTitle(type)}
          hideCreateNewButton={getHideCreateNewButton(type, canCreateStory, canCreatePitch)}
          isLoadingMore={networkStatus === NetworkStatus.fetchMore}
          isOpen={isOpen}
          disableCollapse={disableCollapse}
        >
          <List disablePadding onKeyDown={handleKeyDown} onKeyUp={handleKeyUp}>
            {members
              .filter((story) => belongsToSubTab(story))
              .map((story, index) => (
                <Story
                  ref={ref}
                  key={story.mId}
                  id={story.mId}
                  refId={story.mRefId}
                  index={index}
                  title={story.mTitle}
                  description={story.mDescription}
                  updatedAt={story.mUpdatedAt}
                  dateRange={{
                    mPublishingAt: story.mPublishingAt,
                    mPublishingEnd: story.mPublishingEnd,
                  }}
                  assignedMembers={story.mAssignedMembers || []}
                  mContentKey={story.mContentKey}
                  imgUrl={story.mThumbnailUrl}
                  mThumbnailKey={story.mThumbnailKey}
                  groupType={groupType}
                  storyType={story.mType}
                  storyCreatedBy={story.mCreatedById}
                  isRestricted={story.mType.includes('res_') || story.isRestricted}
                  isStorySelected={previewItemId !== null && previewItemId === story.mId}
                  isStoryFocused={selectedId !== null && selectedId === story.mId}
                  setShowStorybox={setShowStorybox}
                  setPreviewItemId={setPreviewItemId}
                  setSelectedId={setSelectedId}
                  handleContextClick={(ev) => handleContextClick(story, ev)}
                  canUpdatePitch={canUpdatePitch}
                  canUpdateStory={canUpdateStory}
                  canArchivePitch={canArchivePitch}
                  canArchiveStory={canArchiveStory}
                  canSelectRange={canSelectRange}
                />
              ))}
          </List>
        </Group>
      );
    }

    return null;
  },
);

GroupContainer.propTypes = {
  /** Title of the list group */
  title: PropTypes.string,
  /** group id */
  mId: PropTypes.string.isRequired,
  /** group type */
  type: PropTypes.string.isRequired,
};

GroupContainer.defaultProps = {
  title: 'Group',
};

export default GroupContainer;
