import React from 'react';

import { ReactComponent as DraftIcon } from 'assets/icons/systemicons/draft_off.svg';
import { ReactComponent as Pitch } from 'assets/icons/systemicons/HeaderNavbar/pitch_off.svg';
import { ReactComponent as StoryIcon } from 'assets/icons/systemicons/story_off.svg';

import { BadgeWrapper, Icon, Label } from './styled';

export const enum BadgeVariants {
  DRAFT = 'draft',
  STORY = 'story',
  PITCH = 'pitch',
  INPUT = 'input',
}

export interface BadgeProps {
  children?: React.ReactNode;
  vertical?: boolean;
  icon?: React.ReactNode;
  variant?: BadgeVariants;
}

const iconMap = {
  [BadgeVariants.DRAFT]: <DraftIcon />,
  [BadgeVariants.STORY]: <StoryIcon />,
  [BadgeVariants.PITCH]: <Pitch />,
  [BadgeVariants.INPUT]: undefined,
};

const getIcon = (variant: BadgeVariants) => iconMap[variant];

const getColor = (variant?: BadgeVariants) => {
  switch (variant) {
    case BadgeVariants.DRAFT:
      return 'whiteHighEmphasis';
    case BadgeVariants.STORY:
      return 'whiteHighEmphasis';
    case BadgeVariants.PITCH:
      return 'whiteHighEmphasis';
    case BadgeVariants.INPUT:
      return 'highEmphasis';
    default:
      return 'blackHighEmphasis';
  }
};

export function Badge({ children = '', vertical = false, icon, variant }: Readonly<BadgeProps>) {
  const color = getColor(variant);
  const iconVariant = variant && getIcon(variant);
  const hasIcon = Boolean(icon || iconVariant);

  return (
    <BadgeWrapper variant={variant} vertical={vertical}>
      {hasIcon && <Icon>{icon ?? iconVariant}</Icon>}
      <Label variant="caption" color={color}>
        {children}
      </Label>
    </BadgeWrapper>
  );
}
