import styled from '@emotion/styled';

import Text from 'components/text/Text';

export const GroupsWrapper = styled('div')`
  cursor: pointer;
`;

export const GroupsTitle = styled('div')`
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const CheckBoxLabel = styled(Text)`
  cursor: pointer;
`;

export const BodyWrapper = styled('div')`
  max-height: 400px;
  overflow-y: scroll;
`;
