import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { searchbarActions, searchbarFields } from 'utils/constants/searchbar';
import checkIsEmptyModel from '../../utils/isEmptyModel';
import SavedSearches from '../savedSearches';
import Platforms from '../platforms';

const PopoverContents = ({
  popoverType,
  filterModel,
  savedFilters,
  onCreateFilter,
  onUpdateFilters,
  history,
  currentSearch,
  updateCurrentSearch,
  updateFilterModel,
  updateHistory,
  onClose,
  hideSavedFilters,
  platforms,
}) => {
  const isEmptyModel = checkIsEmptyModel(filterModel);

  const updatePlatforms = useCallback(
    (updatedPlatforms) => {
      updateFilterModel({ type: searchbarActions.UPDATE_PLATFORM, value: updatedPlatforms });
      updateHistory(searchbarFields.PLATFORM, updatedPlatforms);
    },
    [updateHistory, updateFilterModel],
  );

  const handleSelectSearch = useCallback(
    (newModel) => {
      onClose();
      newModel && updateFilterModel({ type: searchbarActions.SELECT, value: newModel });
    },
    [onClose, updateFilterModel],
  );

  const handleCreateNewFilter = useCallback(
    (label) => {
      onCreateFilter({ id: uuid(), label, model: filterModel, isPinned: false });
    },
    [filterModel, onCreateFilter],
  );

  switch (popoverType) {
    case searchbarFields.PLATFORM:
      return (
        <Platforms
          platforms={platforms}
          onClose={onClose}
          filters={filterModel?.platform || []}
          updateFilters={updatePlatforms}
        />
      );
    case searchbarFields.SAVED_SEARCH:
      return (
        <SavedSearches
          onCreateFilter={handleCreateNewFilter}
          savedFilters={savedFilters}
          onUpdateFilters={onUpdateFilters}
          isEmptyModel={isEmptyModel}
          history={history}
          onSelectSearch={handleSelectSearch}
          currentSearch={currentSearch}
          updateCurrentSearch={updateCurrentSearch}
          hideSavedFilters={hideSavedFilters}
        />
      );
    default:
      return <div />;
  }
};

const model = PropTypes.shape({
  label: PropTypes.string,
  model: PropTypes.shape({
    freeText: PropTypes.string,
    user: PropTypes.arrayOf(PropTypes.shape({})),
    team: PropTypes.arrayOf(PropTypes.shape({})),
    department: PropTypes.arrayOf(PropTypes.shape({})),
    platform: PropTypes.arrayOf(PropTypes.shape({})),
  }),
});

PopoverContents.propTypes = {
  popoverType: PropTypes.string,
  onCreateFilter: PropTypes.func,
  onUpdateFilters: PropTypes.func,
  updateCurrentSearch: PropTypes.func,
  updateFilterModel: PropTypes.func,
  updateHistory: PropTypes.func,
  onClose: PropTypes.func,
  savedFilters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      model,
    }),
  ),
  currentSearch: PropTypes.shape({
    type: PropTypes.string,
    identification: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  history: PropTypes.arrayOf(model),
  filterModel: model,
  hideSavedFilters: PropTypes.bool,
};

PopoverContents.defaultProps = {
  popoverType: '',
  onCreateFilter: () => {},
  onUpdateFilters: () => {},
  updateCurrentSearch: () => {},
  updateFilterModel: () => {},
  updateHistory: () => {},
  onClose: () => {},
  savedFilters: [],
  currentSearch: undefined,
  history: [],
  filterModel: {},
  hideSavedFilters: false,
};

export default PopoverContents;
