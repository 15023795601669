import { ApolloCache } from '@apollo/client';

import { insertToCache } from 'api/mdfBlocks/useCreateMdfBlock';
import { deleteFromCache } from 'api/mdfBlocks/useDeleteMdfBlock';
import { memberToRawBlock } from 'api/mdfBlocks/utils';
import { CrudActionEnum, MemberType } from 'types/graphqlTypes';

const updatePlanningBlocks = (proxy: ApolloCache<unknown>, item: MemberType) => {
  const { crudAction } = item;

  switch (crudAction) {
    case CrudActionEnum.Insert:
      return insertToCache(proxy, memberToRawBlock(item));
    case CrudActionEnum.Remove:
      return deleteFromCache(proxy, memberToRawBlock(item));
    default:
      return;
  }
};

export default updatePlanningBlocks;
