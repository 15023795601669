import { Editor } from 'slate';

import { CustomElement } from 'types/editor';

import { elementTypes } from '../constants';

const getSelectedElement = (editor: Editor, { depth = 1 } = {}) => {
  if (!editor) return null;

  const { selection } = editor;

  if (!selection?.anchor) return null;

  const [selectedElement] = Editor.node(editor, selection, { depth });

  return selectedElement as CustomElement;
};

export const isVoidSelected = (editor: Editor) => {
  const selectedElement = getSelectedElement(editor);
  if (selectedElement?.type === elementTypes.SECTION_DIVIDER) {
    const selectedChildElement = getSelectedElement(editor, { depth: 2 });
    return !!selectedChildElement && editor.isVoid(selectedChildElement);
  }
  return !!selectedElement && editor.isVoid(selectedElement);
};

export default getSelectedElement;
