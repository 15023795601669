import { searchbarFields } from 'utils/constants/searchbar';

const getSearchFilters = (filterModel, isInstance = false) => {
  const assignedMembers = [];
  const platformsFilter = [];
  let searchString = '';
  let filterByAndLogic = false;
  Object.keys(filterModel).forEach((key) => {
    switch (key) {
      case searchbarFields.FREE_TEXT:
        searchString = filterModel[key];
        break;
      case searchbarFields.PLATFORM:
        if (isInstance) {
          filterModel[key].forEach((platform) => {
            platformsFilter.push({ name: platform.platform, platformKind: platform.platformKind });
          });
        }
        break;
      case searchbarFields.USER: {
        filterModel[key].forEach((user) => {
          assignedMembers.push(user.mId);
        });
        break;
      }
      case searchbarFields.TEAM: {
        filterModel[key].forEach((member) => {
          assignedMembers.push(member.mId);
        });
        break;
      }
      case searchbarFields.DEPARTMENT: {
        filterModel[key].forEach((member) => {
          assignedMembers.push(member.mId);
        });
        break;
      }
      case searchbarFields.FILTER_BY_AND_LOGIC: {
        filterByAndLogic = filterModel[key];
      }
      default:
        break;
    }
  });

  return { searchString, assignedMembers, platformsFilter, filterByAndLogic };
};

export default getSearchFilters;
