import { useCallback } from 'react';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useVirtualizer } from '@tanstack/react-virtual';

import { TableBody } from 'components/dataTable/Table';
import { EmptyState } from 'features/grids/common/components/EmptyState';
import { ReorderRows } from 'features/grids/widget/components/dnd/ReorderRows';
import { ParsedMemberType } from 'types';

import { DataTableDndBody } from '../../types';

import { MemoTableRowWrapper } from './TableRowWrapper';

export function DndBody({
  table,
  itemIds = [],
  boardId,
  groupId,
  containerRef,
}: Readonly<DataTableDndBody<ParsedMemberType>>) {
  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => containerRef.current,
    getItemKey: useCallback((index: number) => rows[index]?.id ?? index, [rows]),
    estimateSize: () => 37,
    overscan: 50,
    measureElement:
      typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    // debug: true,
  });

  const virtualRows = rowVirtualizer.getVirtualItems();

  return (
    <TableBody style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
      {virtualRows?.length > 0 ? (
        <ReorderRows itemIds={itemIds} tableId={boardId} itemGroupId={groupId}>
          <SortableContext items={itemIds} strategy={verticalListSortingStrategy}>
            {virtualRows.map((virtualRow) => {
              const isSkeletonRow = virtualRow.index >= rows.length;

              return (
                <MemoTableRowWrapper
                  table={table}
                  rows={rows}
                  key={isSkeletonRow ? `skeleton_${virtualRow.key}` : virtualRow.key}
                  measureElement={rowVirtualizer.measureElement}
                  start={virtualRow.start}
                  isSkeletonRow={isSkeletonRow}
                  rowMeta={table.options.meta?.rowMeta}
                  staticRowIndex={virtualRow.index}
                />
              );
            })}
          </SortableContext>
        </ReorderRows>
      ) : (
        <EmptyState>{table.options.meta?.emptyStateText}</EmptyState>
      )}
    </TableBody>
  );
}
