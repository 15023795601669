import gql from 'graphql-tag';

export default gql`
  mutation UpdateConvoReadAt($input: UpdateConversationInput) {
    updateConvoReadAt(input: $input) {
      mId
      mRefId
    }
  }
`;
