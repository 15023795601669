import React from 'react';
import PropTypes from 'prop-types';
import { BodyWrapper, SettingsMenuWrapper, SettingsDetailsWrapper } from '../../../settings-styled';
import UsersAndGroupsMenu from './menu';
import UsersAndGroupsDetails from './details';

const UsersAndGroups = (props) => {
  const { groupPolicies, instanceTypes, kanbanStates } = props;
  return (
    <BodyWrapper>
      <SettingsMenuWrapper>
        <UsersAndGroupsMenu groupPolicies={groupPolicies} />
      </SettingsMenuWrapper>
      <SettingsDetailsWrapper>
        <UsersAndGroupsDetails
          groupPolicies={groupPolicies}
          instanceTypes={instanceTypes}
          kanbanStates={kanbanStates}
        />
      </SettingsDetailsWrapper>
    </BodyWrapper>
  );
};

UsersAndGroups.propTypes = {
  groupPolicies: PropTypes.array,
};

UsersAndGroups.defaultProps = {
  groupPolicies: [],
};

export default UsersAndGroups;
