/**
 *  Hook for creating a new story from Pitch and attach it to a user.
 *  Input: pitchId and userId
 *  Returns the story id of the created story.
 *
 */

import { useMutation } from '@apollo/client';
import CREATE_STORY_FROM_PITCH from 'operations/mutations/createStoryFromPitch';
import memberTypes from 'operations/memberTypes';
import useSettingsValue from 'hooks/useSettingsValue';
import useUpdateLeftSidebarCache from 'hooks/useUpdateLeftSidebarCache';
import useGetArchiveTime from 'hooks/useGetArchiveTime';
import useNewItems from './useNewItems';

const useCreateStoryFromPitch = () => {
  const [createStoryFromPitch] = useMutation(CREATE_STORY_FROM_PITCH);
  const [updateLeftSidebarCache] = useUpdateLeftSidebarCache();
  const [getSettingsValue] = useSettingsValue();
  const [getAutoArchiveTime] = useGetArchiveTime();
  const { addItem } = useNewItems();

  let createdStoryId;

  const createStory = async (mId, userId, mPublishingAt, isConvertToStory) => {
    const isSyncActive = !(getSettingsValue('story.disableAutoSync') === 'true');
    const isAutoArchiveStory = getSettingsValue('app.autoArchiveStory') === 'true';
    const variables = {
      input: {
        mId,
        mCreatedById: userId,
      },
    };
    if (isSyncActive) {
      variables.input.isSyncActive = isSyncActive;
    }
    if (isConvertToStory) {
      variables.input.convertToStory = true;
    }
    if (isAutoArchiveStory) {
      variables.input.ttl = getAutoArchiveTime(mPublishingAt);
    }

    await createStoryFromPitch({
      variables,
      update: (_, mutationResult) => {
        const { createStoryFromPitch: createdStory } = mutationResult.data;
        if (isConvertToStory) updateLeftSidebarCache(createdStory, memberTypes.USER_PITCH, false);
        updateLeftSidebarCache(createdStory, memberTypes.USER_STORY, true);
        addItem(createdStory);
        createdStoryId = createdStory.mId;
      },
    });

    return createdStoryId;
  };

  return createStory;
};

export default useCreateStoryFromPitch;
