import { FolderType, MemberType } from 'types/graphqlTypes';

/**
 * @param items array of items
 * @returns a sorted shallow copy of the items by title
 */
export default (items: (MemberType | FolderType)[]): (MemberType | FolderType)[] =>
  [...items].sort((a, b) =>
    (a?.mTitle ?? '').localeCompare(b?.mTitle ?? '', undefined, { numeric: true }),
  );
