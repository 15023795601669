import { BaseEditor } from 'slate';
import { HistoryEditor } from 'slate-history';
import { ReactEditor, RenderElementProps } from 'slate-react';

import { Metadata as MetadataV2 } from './forms/forms';
import { MMetaDataField } from './graphqlTypes';

export interface BlockAsset {
  // common
  mId?: string;
  mRefId?: string;
  mAssetId?: string;
  itemType?: string;
  assetType?: string;
  mediaType?: string;
  proxy?: string | null;
  // clip asset
  mTitle?: string;
  mProvider?: string;
  itemDuration?: number | string;
  // Mos asset
  id?: string | null;
  provider?: string | null;
  self?: string | null;
  title?: string | null;
  description?: string | null;
  mediaRecordedOn?: string | null;
  thumbnailUrl?: string | null;
  mosobj?: string;
}

export interface Metadata {
  name: string;
  value?: string;
  isVisible?: boolean;
}

export interface BlockElement extends RenderElementProps {
  direction?: string;
}

export interface CustomData {
  mTitle?: string;
  collapsed?: boolean;
  itemId?: string;
  content?: string | SelectBlockOption[];
  type?: string;
  fieldId?: string;
  mId?: string;
  mRefId?: string;
  mType?: string;
  mDescription?: string;
  mSecId?: string;
  mActive?: boolean;
  slashCommand?: string;
  singleOnly?: boolean;
  metadata?: Metadata[] | MetadataV2;
  taskType?: string;
  mResourceId?: string;
  mOwner?: string;
  mAssignee?: string;
  mStatus?: string;
  mCreatedAt?: string;
  href?: string;
  templateType?: string;
  templateVariant?: string;
  assets?: BlockAsset[];
  src?: string;
  proxy?: string;
  title?: string;
  mediaType?: string;
  mdfId?: string;
  checked?: boolean;
  indentLevel?: number;
  quote?: string;
  byline?: string;
  html?: string;
}

export interface CustomText {
  text: string;
  strikeThrough?: boolean;
  underline?: boolean;
  bold?: boolean;
  color?: string;
  uppercase?: boolean;
  italic?: boolean;
}

export type CustomElement = {
  type: string;
  data?: CustomData;
  children: (CustomText | CustomElement)[]; // Allow nesting
};

export type CustomEditor = BaseEditor & ReactEditor & HistoryEditor;

declare module 'slate' {
  interface CustomTypes {
    Editor: CustomEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

/* type guards */

export const isCustomElement = (element: CustomElement | CustomText): element is CustomElement => {
  return 'type' in element;
};

/* Overriden types */
export interface EditorElementProps extends RenderElementProps {
  direction?: string;
}

export interface EditorValue {
  document: CustomElement[];
  version?: string;
  metadata?: MMetaDataField[];
  properties?: {
    story?: string;
    version?: string;
    mUpdatedById: string;
  };
}

export type EditorFontSize = 'default' | 'small' | 'medium' | 'large' | 'xLarge' | 'none';

export type SelectBlockOption = { id: string; value: string };

export interface EmojiType {
  id: string;
  keywords: string[];
  name: string;
  unified?: string;
  native?: string;
  shortcodes?: string;
  emoticons?: string[];
  search?: string;
  skins?: {
    unified: string;
    native: string;
    shortcodes: string;
  }[];
}
