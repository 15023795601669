import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

import { STANDARD_AI_PROMPT_KEYS } from 'api/commonKeys';
import { Alternative, MemberType } from 'types/graphqlTypes';
import { invariant } from 'types/invariant';

export const AI_PROMPTS = gql`
  query GetAiPrompts($input: GetMemberInput) {
    getMember(input: $input) {
      ${STANDARD_AI_PROMPT_KEYS}
    }
  }
`;

export interface FetchPrompts {
  getMember: MemberType;
}

export const convertToPrompts = (member: MemberType | null): Alternative[] => {
  if (member === null) return [];
  invariant(member.mId, 'Expected mId for ai_prompt');
  invariant(member.mRefId, 'Expected mRefId ai_prompt');
  invariant(member.configs, 'Expected configs ai_prompt');

  const config = member.configs.find((c) => c.key === 'ai-prompts');

  return config?.alternatives ?? [];
};

export const useGetAiPrompts = () => {
  const { data, loading, error } = useQuery<FetchPrompts>(AI_PROMPTS, {
    variables: {
      input: {
        mId: 'ai-prompts',
        mRefId: 'ai-prompts',
      },
    },
    fetchPolicy: 'cache-first',
  });

  const result = data?.getMember ?? null;

  return { prompts: convertToPrompts(result), loading, error };
};
