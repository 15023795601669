import React, { useState } from 'react';
import PropTypes from 'prop-types';

import MediaCard from './components/mediaCard';

import {
  ScratchpadInnerRootWrapper,
  ScratchpadInnerWrapper,
  StyledTypography,
  StyledDivider,
  StyledCardContainer,
  StyledCard,
} from './scratchpad-styled';

const ScratchPad = (props) => {
  const { data } = props;
  const [, setCurrentItem] = useState(null);

  return (
    <ScratchpadInnerRootWrapper>
      <ScratchpadInnerWrapper>
        <StyledTypography>Scratchpad</StyledTypography>
        <StyledDivider />
        <StyledCardContainer>
          {data &&
            data.length > 0 &&
            data.map((item) => (
              <StyledCard
                key={item.id}
                onKeyPress={() => {
                  setCurrentItem(item.item);
                }}
                role="button"
                tabIndex="0"
                onClick={() => {
                  setCurrentItem(item.item);
                }}
              >
                <MediaCard {...{ ...item }} />
              </StyledCard>
            ))}
        </StyledCardContainer>
      </ScratchpadInnerWrapper>
    </ScratchpadInnerRootWrapper>
  );
};

ScratchPad.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      fileName: PropTypes.string,
      date: PropTypes.string,
      fileSize: PropTypes.string,
      image: PropTypes.string,
      variant: PropTypes.string,
      isNew: PropTypes.bool,
      duration: PropTypes.number,
      onPlay: PropTypes.func,
    }),
  ),
  onCancel: PropTypes.func,
  onInsert: PropTypes.func,
};

ScratchPad.defaultProps = {
  data: [],
  onCancel: () => {},
  onInsert: () => {},
};

export default ScratchPad;
