import React, { useEffect, useState } from 'react';

import { Split } from './styled';

const LOCAL_STORAGE_SIZE_KEY = 'rightSideSize';

interface Props {
  children: React.ReactNode;
  setIsDragging: (val: boolean) => void;
  rightHidden: boolean;
  defaultRightPanelWidth: number;
}

function RightResizeWrapper({
  children,
  setIsDragging,
  rightHidden,
  defaultRightPanelWidth,
}: Readonly<Props>) {
  const [defaultSize, setDefaultSize] = useState(0);

  const onDragFinished = (size: number) => {
    setIsDragging(false);
    localStorage.setItem(LOCAL_STORAGE_SIZE_KEY, `${size}`);
  };

  useEffect(() => {
    const storedSize =
      parseInt(localStorage.getItem(LOCAL_STORAGE_SIZE_KEY) ?? '', 10) ?? defaultRightPanelWidth;
    const sanitizedSize = storedSize < defaultRightPanelWidth ? defaultRightPanelWidth : storedSize;
    setDefaultSize(sanitizedSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    // @ts-expect-error Split component complains children doesn't exist, but it obviously does.
    <Split
      onDragStarted={() => setIsDragging(true)}
      onDragFinished={onDragFinished}
      resizerStyle={{
        display: rightHidden ? 'none' : '',
        content: '""',
        width: '4px',
        height: '100%',
        textAlign: 'center',
        cursor: 'col-resize',
      }}
      style={{
        position: 'relative',
        overflow: 'visible',
      }}
      split="vertical"
      allowResize={!rightHidden}
      pane1Style={{
        minWidth: rightHidden ? 'calc(100% - 48px)' : '20%',
        maxWidth: rightHidden ? 'calc(100% - 48px)' : `calc(100% - ${defaultRightPanelWidth}px)`,
      }}
      pane2Style={{
        minWidth: rightHidden ? '48px' : `${Math.max(defaultRightPanelWidth, 375)}px`,
        maxWidth: rightHidden ? '48px' : 'calc(80% - 48px)',
      }}
      defaultSize={defaultSize}
      primary="second"
    >
      {children}
    </Split>
  );
}
export default RightResizeWrapper;
