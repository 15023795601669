/* eslint-disable max-len */
import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';

import Text from 'components/text/Text';
import { HStack } from 'layouts/box/Box';

import { BadgeProps, BadgeVariants } from './Badge';

type BadgeWrapperProps = Pick<BadgeProps, 'vertical' | 'variant'>;

const getBadgeBackgroundColor = (variant: BadgeVariants, theme: Theme) => {
  if (variant === BadgeVariants.STORY) return theme.palette.dina.statusStory;
  if (variant === BadgeVariants.PITCH) return theme.palette.dina.statusPitch;
  if (variant === BadgeVariants.INPUT) return theme.palette.dina.buttonBackgroundOutlined;
  return theme.palette.dina.draftBackground;
};

export const BadgeWrapper = styled(HStack)`
  user-select: none;
  background-color: ${({ theme }) => theme.palette.dina.statusWarning};
  padding: 1.5px 10px 1.5px 10px;
  border-radius: 8px;
  ${({ vertical }: BadgeWrapperProps) =>
    vertical &&
    ' writing-mode: vertical-lr; padding: 8px; padding-left: 1.5px; padding-right: 1.5px;'}

  ${({ variant, theme }) =>
    variant &&
    css`
      border-radius: 12px;
      background-color: ${getBadgeBackgroundColor(variant, theme)};
      ${variant === BadgeVariants.INPUT &&
      `width: fit-content; border: 1px solid ${theme.palette.dina.buttonBorderOutlined}; height: 24px; border-radius: 4px; padding: 4px 4px 4px 8px;`};
    `}
`;

export const Label = styled(Text)`
  display: flex;
  align-items: center;
  gap: 2px;
`;

export const Icon = styled('div')`
  margin-right: 4px;
  width: 20px;
  height: 20px;
  svg {
    width: 20px;
    height: 20px;
  }
`;
