import { useCallback, useContext } from 'react';
import { useQuery } from '@apollo/client';
import { omit } from 'lodash';

import useToast from 'components/toast/useToast';
import UserCtx from 'contexts/UserContext';
import useUpdateSettings from 'hooks/useUpdateSettings';
import GET_SETTINGS from 'operations/queries/getSettings';
import { useDinaTheme } from 'store/theme';
import { MMetaDataField } from 'types/graphqlTypes';

interface UserSettings {
  generalSettings: {
    mId: 'settings';
    mRefId: 'general';
    mMetaData: MMetaDataField[];
  };
  userSettings: {
    mId: 'settings';
    mRefId: string;
    mMetaData: MMetaDataField[];
  };
}

function useGetUserSettings(userId: string) {
  const { data } = useQuery<UserSettings>(GET_SETTINGS, {
    variables: {
      generalSettingsInput: { mId: 'settings', mRefId: 'general' },
      userSettingsInput: { mId: 'settings', mRefId: userId },
    },
    fetchPolicy: 'cache-and-network',
  });

  return data?.userSettings ?? null;
}

function getUpdatedMetadata(originalData: MMetaDataField[], value: string): MMetaDataField[] {
  const field = { key: 'theme', value };
  const copyMetadataFields = [...originalData.map((f) => omit(f, '__typename'))];
  const fieldIndex = copyMetadataFields.findIndex((f) => f.key === 'theme');
  if (fieldIndex >= 0) {
    copyMetadataFields.splice(fieldIndex, 1, field);
  } else {
    copyMetadataFields.push(field);
  }
  return copyMetadataFields;
}

function useChangeTheme() {
  const { errorToast } = useToast();
  const user = useContext(UserCtx);
  const { mId: userId } = user;
  const [theme, setTheme] = useDinaTheme();
  const updateSettings = useUpdateSettings();
  const data = useGetUserSettings(userId);

  const toggleTheme = useCallback(() => {
    if (theme === 'dark') {
      setTheme('light');
      updateSettings({
        mId: 'settings',
        mRefId: userId,
        mMetaData: getUpdatedMetadata(data?.mMetaData ?? [], 'light'),
      }).catch(errorToast);
    } else {
      setTheme('dark');
      updateSettings({
        mId: 'settings',
        mRefId: userId,
        mMetaData: getUpdatedMetadata(data?.mMetaData ?? [], 'dark'),
      }).catch(errorToast);
    }
  }, [theme, setTheme, data]);

  return { toggleTheme, currentTheme: theme };
}

export default useChangeTheme;
