import { Editor, Transforms, Element as SlateElement } from 'slate';
import { elementTypes } from '../constants';
import isList from '../components/list/utils/isList';
import getSelectedElement from './getSelectedElement';
import isSection from '../components/sectionDivider/utils/isSection';

const { insertNodes, setNodes, unwrapNodes } = Transforms;

const moveToEnd = (editor) => {
  const block = Editor.above(editor, {
    match: (n) => Editor.isBlock(editor, n) && isList(n),
  });
  if (!block) return;

  const [, blockPath] = block;
  const endBlockPath = Editor.end(editor, blockPath);
  Transforms.select(editor, endBlockPath);
};

/** Move focus away from ordered/unordered list to make sure new block does not added as a child for the list*/
const normalizeOnListFocus = (editor) => {
  const selectedNode = getSelectedElement(editor);

  if (selectedNode?.type === elementTypes.SECTION_DIVIDER) {
    const selectedChildNode = getSelectedElement(editor, { depth: 2 });
    if (!selectedChildNode || !isList(selectedChildNode)) return;
  } else if (!selectedNode || !isList(selectedNode)) return;

  moveToEnd(editor);

  const listItem = { type: 'list-item', children: [{ text: '' }] };
  insertNodes(editor, listItem);

  unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && !isSection(n) && isList(n),
    split: true,
  });

  const newProperties = {
    type: elementTypes.PARAGRAPH,
  };

  setNodes(editor, newProperties, {
    mode: selectedNode?.type === elementTypes.SECTION_DIVIDER ? 'lowest' : 'highest',
  });
};

export default normalizeOnListFocus;
