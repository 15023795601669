import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ListItem, Popover } from '@material-ui/core';

import { ReactComponent as AI } from 'assets/icons/systemicons/AI.svg';
import { ReactComponent as Copy } from 'assets/icons/systemicons/copy.svg';
import { ReactComponent as CopyApproved } from 'assets/icons/systemicons/copyApproved.svg';

export const CopyButton = styled(Copy)`
  &:hover path {
    fill-opacity: 1;
  }
`;

export const CopyApprovedButton = styled(CopyApproved)`
  &:hover path {
    fill-opacity: 1;
  }
`;

export const Container = styled('div')`
  position: absolute;
  z-index: 100;
  top: -10000px;
  left: -10000px;
  margin-top: -16px;
  opacity: 0;
  transition: opacity 0.75s;
`;

export const AIContainer = styled('div')`
  padding: 8px;
  background: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const AIButton = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  ${({ theme }) => css`
    ${theme.typography.dina.button};
    color: ${theme.palette.dina.info};
  `};
`;

export const AIIcon = styled(AI)`
  path {
    fill: ${({ theme }) => theme.palette.dina.info};
    fill-opacity: 1;
  }
`;

export const TextButton = styled('button')`
  position: relative;
  cursor: pointer;
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  border: none;
  outline: none;
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  :hover {
    background-color: transparent;
    border: none;
    outline: none;
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
`;

export const More = styled('div')`
  position: absolute;
  overflow: hidden;
  top: 28px;
  right: -8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  background: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  width: max-content;
  border-radius: 0px 0px 6px 6px;
  ${({ showMoreOptions }: { showMoreOptions?: boolean }) =>
    showMoreOptions
      ? css`
          width: max-content;
        `
      : css`
          width: 0px;
        `}
`;

export const MoreItem = styled(ListItem)`
  background: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
  :hover {
    background: ${({ theme }) => theme.palette.dina.blackHoverOverlay};
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
`;

// Result

export const ResultContainer = styled('div')`
  width: 650px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
  border-radius: 6px;
`;

export const ReplayWrapper = styled('div')`
  flex: 1;
  padding: 12px 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

export const Body = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Label = styled('label')`
  ${({ theme }) => css`
    ${theme.typography.dina.overline};
    color: ${theme.palette.dina.mediumEmphasis};
  `}
`;

export const TextArea = styled('textarea')`
  padding: 8px;
  background-color: inherit;
  border-radius: 6px;
  resize: none;
  outline: none;
  ${({ theme }) => css`
    ${theme.typography.dina.body2};
    color: ${theme.palette.dina.highEmphasis};
    border: 1px solid ${theme.palette.dina.inputBorderResting};
  `}
  :focus {
    ${({ theme }) => css`
      background-color: ${theme.palette.dina.inputBackgroundFocused};
      border-color: ${theme.palette.dina.statusOnFocused};
    `}
  }
`;

export const ButtonsWrapper = styled('div')`
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > :last-of-type {
    display: flex;
    gap: 12px;
  }
`;

export const MUIPopover = styled(Popover)`
  .MuiPopover-paper {
    background-color: transparent;
  }
`;
