import preventDefaultAndPropagation from 'utils/preventDefaultAndStopPropagation';
import Split from './styled';

function SplitBar({
  children,
  split,
  style,
  primary,
  pane1Style,
  pane2Style,
  hideResizer = false,
}) {
  return (
    <Split
      style={{
        position: 'relative',
        ...style,
      }}
      split={split}
      resizerStyle={{
        content: '""',
        zIndex: 100,
        width: hideResizer ? '0px' : split === 'horizontal' ? '100%' : '4px',
        height: hideResizer ? '0px' : split === 'horizontal' ? '4px' : '100%',
        display: 'block',
        textAlign: 'center',
        cursor: split === 'horizontal' ? 'row-resize' : 'col-resize',
        backgroundColor: 'rgba(147, 157, 168, 0.2)',
      }}
      pane1Style={pane1Style}
      pane2Style={pane2Style}
      primary={primary ?? 'first'}
      onResizerClick={(event) => preventDefaultAndPropagation(event)}
    >
      {children}
    </Split>
  );
}

export default SplitBar;
