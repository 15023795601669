import gql from 'graphql-tag';

export default gql`
  mutation UpdateRelatedMemberMutation($input: UpdateRelatedMembersInput) {
    updateRelatedMembers(input: $input) {
      mId
      mRefId
      mTitle
      mPublishingAt
      mPublishingEnd
      mRelatedMembers
    }
  }
`;
