/* eslint-disable sort-imports */
import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';

import { HStack } from 'layouts/box/Box';
import transientOptions from 'theme/helpers';

const commonStyle = css`
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface EmotionCSS {
  theme: Theme;
}

const readOnlyStyles = ({ theme }: EmotionCSS) => css`
  background: ${theme.palette.dina.dividerDark};
  pointer-events: none;
`;

const generalStyles = () => css`
  pointer-events: all;
`;

const autoHeightStyles = css`
  height: auto;
  padding: 0;
  border: none;
`;

export const ToolbarWrapper = styled('div')`
  position: relative;
  background: ${({ theme }) => theme.palette.dina.blackHoverOverlay};
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

interface ContainerProps {
  $readOnly: boolean;
  $disableGeneralToolbar?: boolean;
  $toolbarPosition?: string;
  $autoHeight?: boolean;
}
export const Container = styled('div', transientOptions)<ContainerProps>`
  ${commonStyle};
  ${({ $readOnly, $disableGeneralToolbar }) =>
    $readOnly || $disableGeneralToolbar ? readOnlyStyles : generalStyles};
  ${({ $autoHeight }) => $autoHeight && autoHeightStyles};
`;

export const ToolbarContainer = styled('div')`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
  margin-left: -1px;
`;

export const OuterWrapper = styled('div')`
  height: 40px;
  display: flex;
  align-items: center;
  margin-top: -1px;
  border-top: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  flex: 1;
  :last-of-type {
    flex-grow: 100;
  }
`;

export const InnerWrapper = styled(HStack)`
  gap: 8px;
  padding: 0 8px;
  border-left: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;
