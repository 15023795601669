/**
 * Checks if given slate content is v0.47 or not
 *
 * @param {Object} value SlateJS value
 * @returns {Boolean}
 */

const isOlderSlateValue = ({ version, object }) =>
  !version && object === 'value';

export default isOlderSlateValue;
