import { MutableRefObject, useRef } from 'react';

interface CancellablePromise<T = unknown> {
  promise: Promise<T>;
  cancel: () => void;
}

interface PromiseApi {
  appendPendingPromise: (promise: CancellablePromise<unknown>) => void;
  removePendingPromise: (promise: CancellablePromise<unknown>) => void;
  clearPendingPromises: () => void;
}

const usePromiseApi = (): PromiseApi => {
  const pendingPromises: MutableRefObject<CancellablePromise<unknown>[]> = useRef([]);

  const appendPendingPromise = (promise: CancellablePromise<unknown>): void => {
    pendingPromises.current = [...pendingPromises.current, promise];
  };

  const removePendingPromise = (promise: CancellablePromise<unknown>): void => {
    pendingPromises.current = pendingPromises.current.filter((p) => p !== promise);
  };

  const clearPendingPromises = (): void => {
    pendingPromises.current.forEach((p) => p.cancel());
    pendingPromises.current = [];
  };

  const api: PromiseApi = {
    appendPendingPromise,
    removePendingPromise,
    clearPendingPromises,
  };

  return api;
};

export default usePromiseApi;
