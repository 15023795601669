import { useRef } from 'react';
import { atom, useAtom, useAtomValue } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { createScope, molecule, useMolecule } from 'jotai-molecules';

import { EditorValue, Instance, Note } from 'types';

export type MoveItemProps = {
  itemType?: 'instance' | 'note';
  targetStoryId: string;
  parentStoryId: string;
};

export type MousePosition = {
  x: number | null;
  y: number | null;
};

export const initialMousePosition = {
  x: null,
  y: null,
};

export const PaneScope = createScope<`${string}:${number}` | undefined>(undefined);

const storyPaneMolecule = molecule((_getMol, getScope) => {
  const scope = getScope(PaneScope);
  const [, index] = (scope ?? ':0').split(':');

  const paneIndexAtom = atom<number>(parseInt(index));
  const moveItemDialogAtom = atom<MoveItemProps | null>(null);
  const creatingInstanceAtom = atom(false);
  const newlyAddedInstanceAtom = atom<Instance | null>(null);
  const selectedStoryInstancesAtom = atom<string[]>([]);
  const newlyAddedNoteAtom = atom<Note | null>(null);
  const selectedStoryNotesAtom = atom<string[]>([]);
  const mousePositionAtom = atom<MousePosition>(initialMousePosition);
  const showRightPanelAtom = atomWithStorage<boolean>(`sidepanel:story-${index}`, true);

  const restoreVersionFnRef = useRef<((content: EditorValue) => Promise<void>) | null>(null);
  const onCheckVersionRestorabilityRef = useRef<(() => Promise<boolean>) | null>(null);

  return {
    usePaneIndexValue: () => useAtomValue(paneIndexAtom),
    useCreatingInstance: () => useAtom(creatingInstanceAtom),
    useNewlyAddedInstance: () => useAtom(newlyAddedInstanceAtom),
    useSelectedStoryInstances: () => useAtom(selectedStoryInstancesAtom),
    useSelectedStoryNotes: () => useAtom(selectedStoryNotesAtom),
    useNewlyAddedNote: () => useAtom(newlyAddedNoteAtom),
    useMousePosition: () => useAtom(mousePositionAtom),
    useShowRightPanel: () => useAtom(showRightPanelAtom),
    useMoveItemDialog: () => useAtom(moveItemDialogAtom),
    scope,
    restoreVersionFnRef,
    onCheckVersionRestorabilityRef,
  };
});

export const useStoryPaneMolecule = () => useMolecule(storyPaneMolecule);
