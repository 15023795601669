import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Tours = styled('div')``;
export const ToursHeader = styled('div')`
  ${({ theme }) => css`
    ${theme.typography.dina.h6};
    border-bottom: 1px solid ${theme.palette.dina.dividerLight};
  `};
  padding: 16px;
`;

export const CopyWrapper = styled('div')`
  flex: 0 0 24px;
  margin-left: 4px;
`;

export const ButtonWrapper = styled('div')`
  flex: 0 0 125px;
`;

export const TourDetails = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-right: 10px;
`;

export const TourDescription = styled('div')`
  ${({ theme }) => css`
    ${theme.typography.dina.listItemLabel};
    color: ${theme.palette.dina.mediumEmphasis};
  `};
`;

export const TourTitle = styled('div')`
  ${({ theme }) => theme.typography.dina.listItemLabelMedium};
  margin-bottom: 3px;
`;

export const MenuWrapper = styled('div')`
  width: 25%;
  border-right: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

export const ContentWrapper = styled('div')`
  width: 100%;
`;

export const BodyWrapper = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const Row = styled('div')`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  padding: 12px 16px;
  align-items: center;
`;
