/* eslint-disable react/display-name */
import { ReactComponent as Search } from 'assets/icons/search/search.svg';
import { ReactComponent as BookMarksOff } from 'assets/icons/systemicons/ContainerLeft_Navbar/bookmarks_off.svg';
import { ReactComponent as BookMarksOn } from 'assets/icons/systemicons/ContainerLeft_Navbar/bookmarks_on.svg';
import { ReactComponent as LeftDepartmentsOff } from 'assets/icons/systemicons/ContainerLeft_Navbar/departments_off.svg';
import { ReactComponent as LeftDepartmentsOn } from 'assets/icons/systemicons/ContainerLeft_Navbar/departments_on.svg';
import { ReactComponent as AllInstancesOff } from 'assets/icons/systemicons/ContainerLeft_Navbar/instances_off.svg';
import { ReactComponent as AllInstancesOn } from 'assets/icons/systemicons/ContainerLeft_Navbar/instances_on.svg';
import { ReactComponent as AllStoriesOff } from 'assets/icons/systemicons/ContainerLeft_Navbar/Left_AllStories_off.svg';
import { ReactComponent as AllStoriesOn } from 'assets/icons/systemicons/ContainerLeft_Navbar/Left_AllStories_on.svg';
import { ReactComponent as MyStoriesOff } from 'assets/icons/systemicons/ContainerLeft_Navbar/mystories_off.svg';
import { ReactComponent as MyStoriesOn } from 'assets/icons/systemicons/ContainerLeft_Navbar/mystories_on.svg';
import { ReactComponent as RundownsOff } from 'assets/icons/systemicons/ContainerLeft_Navbar/rundowns_off.svg';
import { ReactComponent as RundownsOn } from 'assets/icons/systemicons/ContainerLeft_Navbar/rundowns_on.svg';
import { ReactComponent as AllPitchesOff } from 'assets/icons/systemicons/ContainerLeft_Navbar/storymarket_off.svg';
import { ReactComponent as AllPitchesOn } from 'assets/icons/systemicons/ContainerLeft_Navbar/storymarket_on.svg';
import { ReactComponent as TeamStoriesOff } from 'assets/icons/systemicons/ContainerLeft_Navbar/teamStories_off.svg';
import { ReactComponent as TeamStoriesOn } from 'assets/icons/systemicons/ContainerLeft_Navbar/teamStories_on.svg';
import { ReactComponent as MyContactsOff } from 'assets/icons/systemicons/ContainerRight_Navbar/contacts_off.svg';
import { ReactComponent as MyContactsOn } from 'assets/icons/systemicons/ContainerRight_Navbar/contacts_on.svg';
import { ReactComponent as DailyNoteOff } from 'assets/icons/systemicons/ContainerRight_Navbar/dailyNotes_off.svg';
import { ReactComponent as DailyNoteOn } from 'assets/icons/systemicons/ContainerRight_Navbar/dailyNotes_on.svg';
import { ReactComponent as RightDepartmentsOff } from 'assets/icons/systemicons/ContainerRight_Navbar/departments_off.svg';
import { ReactComponent as RightDepartmentsOn } from 'assets/icons/systemicons/ContainerRight_Navbar/departments_on.svg';
import { ReactComponent as EvervizOn } from 'assets/icons/systemicons/ContainerRight_Navbar/everviz_blue.svg';
import { ReactComponent as EvervizOff } from 'assets/icons/systemicons/ContainerRight_Navbar/everviz_red.svg';
import { ReactComponent as ContactsOff } from 'assets/icons/systemicons/ContainerRight_Navbar/external_off.svg';
import { ReactComponent as ContactsOn } from 'assets/icons/systemicons/ContainerRight_Navbar/external_on.svg';
import { ReactComponent as GraphicsOff } from 'assets/icons/systemicons/ContainerRight_Navbar/graphics_off.svg';
import { ReactComponent as GraphicsOn } from 'assets/icons/systemicons/ContainerRight_Navbar/graphics_on.svg';
import { ReactComponent as MosOff } from 'assets/icons/systemicons/ContainerRight_Navbar/mos_off.svg';
import { ReactComponent as MosOn } from 'assets/icons/systemicons/ContainerRight_Navbar/mos_on.svg';
import { ReactComponent as PluginOff } from 'assets/icons/systemicons/ContainerRight_Navbar/plugin_off.svg';
import { ReactComponent as PluginOn } from 'assets/icons/systemicons/ContainerRight_Navbar/plugin_on.svg';
import { ReactComponent as StoryBoxOff } from 'assets/icons/systemicons/ContainerRight_Navbar/storybox_off.svg';
import { ReactComponent as StoryBoxOn } from 'assets/icons/systemicons/ContainerRight_Navbar/storybox_on.svg';
import { ReactComponent as TeamsOff } from 'assets/icons/systemicons/ContainerRight_Navbar/teams_off.svg';
import { ReactComponent as TeamsOn } from 'assets/icons/systemicons/ContainerRight_Navbar/teams_on.svg';
import { type ExternalUrl } from 'store/externalUrls';
import { type LeftSelection, type RightSelection } from 'store/sidebar';

import { StyledImage } from '../verticalNavbar-styled';

interface MenuItem {
  title: string;
  SelectedComponent: React.ElementType;
  UnselectedComponent: React.ElementType;
  divider?: boolean;
}

interface LeftMenuItem extends MenuItem {
  selectionType: LeftSelection;
}

interface RightMenuItem extends MenuItem {
  selectionType: RightSelection;
}

/**
 * Title's are mapped to the LeftTabTour, see tours.js
 */
export const leftMenuItems: LeftMenuItem[] = [
  {
    title: 'Search',
    selectionType: 'search',
    SelectedComponent: Search,
    UnselectedComponent: Search,
    divider: true,
  },
  {
    title: 'Bookmarks',
    selectionType: 'usr_bkm',
    SelectedComponent: BookMarksOn,
    UnselectedComponent: BookMarksOff,
    divider: true,
  },
  {
    title: 'My Stories',
    selectionType: 'user',
    SelectedComponent: MyStoriesOn,
    UnselectedComponent: MyStoriesOff,
  },
  {
    title: 'My Teams',
    selectionType: 'tea_usr',
    SelectedComponent: TeamStoriesOn,
    UnselectedComponent: TeamStoriesOff,
  },
  {
    title: 'My Departments',
    selectionType: 'dep_usr',
    SelectedComponent: LeftDepartmentsOn,
    UnselectedComponent: LeftDepartmentsOff,
    divider: true,
  },
  {
    title: 'All Stories',
    selectionType: 'story',
    SelectedComponent: AllStoriesOn,
    UnselectedComponent: AllStoriesOff,
  },
  {
    title: 'All Pitches',
    selectionType: 'pitch',
    SelectedComponent: AllPitchesOn,
    UnselectedComponent: AllPitchesOff,
  },
  {
    title: 'All Instances',
    selectionType: 'instance',
    SelectedComponent: AllInstancesOn,
    UnselectedComponent: AllInstancesOff,
    divider: true,
  },
  {
    title: 'Rundowns',
    selectionType: 'rnd_usr',
    SelectedComponent: RundownsOn,
    UnselectedComponent: RundownsOff,
  },
];

/**
 * Title's are mapped to the RightTabTour, see tours.js
 */
export const rightMenuItems: RightMenuItem[] = [
  {
    title: 'Daily Note',
    selectionType: 'dailyNote',
    SelectedComponent: DailyNoteOn,
    UnselectedComponent: DailyNoteOff,
  },
  {
    title: 'Mos',
    selectionType: 'mos',
    SelectedComponent: MosOn,
    UnselectedComponent: MosOff,
  },
  {
    title: 'Graphics',
    selectionType: 'graphicsbox',
    SelectedComponent: GraphicsOn,
    UnselectedComponent: GraphicsOff,
  },
  {
    title: 'Assets',
    selectionType: 'storybox',
    SelectedComponent: StoryBoxOn,
    UnselectedComponent: StoryBoxOff,
    divider: true,
  },
  {
    title: 'Contacts',
    selectionType: 'contacts',
    SelectedComponent: ContactsOn,
    UnselectedComponent: ContactsOff,
    divider: true,
  },
  {
    title: 'Users',
    selectionType: 'users',
    SelectedComponent: MyContactsOn,
    UnselectedComponent: MyContactsOff,
  },
  {
    title: 'Teams',
    selectionType: 'tea_usr',
    SelectedComponent: TeamsOn,
    UnselectedComponent: TeamsOff,
  },
  {
    title: 'Departments',
    selectionType: 'dpr_usr',
    SelectedComponent: RightDepartmentsOn,
    UnselectedComponent: RightDepartmentsOff,
  },
];

const setIcons = (cur: ExternalUrl) => {
  const { label = '', id, panelIcon } = cur || {};
  const panel = { title: label, selectionType: `generic-${id}` } as RightMenuItem;
  if (panelIcon?.startsWith('http')) {
    panel.SelectedComponent = () => <StyledImage src={panelIcon} />;
    panel.UnselectedComponent = () => <StyledImage src={panelIcon} $unselected={true} />;
    return panel;
  }
  const switchVal = panelIcon ?? label;
  switch (switchVal?.toLowerCase()) {
    case 'everviz':
      panel.SelectedComponent = EvervizOn;
      panel.UnselectedComponent = EvervizOff;
      break;
    case 'graphics':
      panel.SelectedComponent = GraphicsOn;
      panel.UnselectedComponent = GraphicsOff;
      break;
    case 'assets':
      panel.SelectedComponent = StoryBoxOn;
      panel.UnselectedComponent = StoryBoxOff;
      break;
    case 'mos':
      panel.SelectedComponent = MosOn;
      panel.UnselectedComponent = MosOff;
      break;
    default:
      panel.SelectedComponent = PluginOn;
      panel.UnselectedComponent = PluginOff;
  }
  return panel;
};

/**
 * Returns an array of html panels that should be displayed in the right panel.
 */
export const getHtmlPanels = (externalUrls: ExternalUrl[]) => {
  if (!externalUrls) return [];

  const htmlPanels: RightMenuItem[] = externalUrls
    .filter((cur) => cur.showInRightPanel === 'true')
    .map((cur) => setIcons(cur));

  if (htmlPanels.length > 0) {
    htmlPanels[htmlPanels.length - 1].divider = true;
  }

  return htmlPanels;
};
