import { memo, useCallback, useEffect, useMemo } from 'react';
import { useContextMenu } from 'react-contexify';
import { FetchPolicy } from '@apollo/client';
import { ScopeProvider } from 'jotai-molecules';

import { MemberContextMenu } from 'components/contextMenu/MemberContextMenu';
import Grid from 'features/gridDeck';
import useGetGrid, { SubscriptionType } from 'features/gridDeck/api/useGetGrid';
import { RemoveMemberItem } from 'features/gridDeck/components/RemoveItem';
import { gridScope, useGridMolecule } from 'features/gridDeck/store';
import useStoreKanbanUpdates from 'features/kanban/api/useUpdateKanban';
import FilterComponent from 'features/searchDeck/components/filters';
import WidgetWrapper, { FilterComponentType } from 'features/widget/components/WidgetWrapper';
import { WIDGETS } from 'features/widget/constants';
import { MemberType } from 'types/graphqlTypes';

import { StoryWidgetProps } from './types';
import { getPropFilters } from './utils';

interface GetGridProps {
  getGrid: ({ mId, fetchPolicy }: { mId: string; fetchPolicy: FetchPolicy }) => void;
  loading: boolean;
  startSubscription: (mId: string) => [SubscriptionType];
}

const domainContextItems = [<RemoveMemberItem key="remove" />];

const GridDataLoader = ({
  mId,
  federatedSearchString,
}: {
  mId: string;
  federatedSearchString: string | undefined;
}) => {
  const { getGrid, loading, startSubscription }: GetGridProps = useGetGrid();
  const { show } = useContextMenu({ id: `gridItemMenu-${mId}` });
  // Grid store
  const { gridBoardUpdatedListener } = useGridMolecule();
  const { updateKanbanBoard } = useStoreKanbanUpdates();
  const [useUpdateListener] = gridBoardUpdatedListener();

  useUpdateListener(
    useCallback(
      async (newVal, prevVal) => {
        if (JSON.stringify(newVal) !== JSON.stringify(prevVal)) {
          await updateKanbanBoard({
            kanbanBoard: newVal,
          });
        }
      },
      [mId, updateKanbanBoard],
    ),
  );

  useEffect(() => {
    getGrid({ mId, fetchPolicy: 'network-only' });
    const [gridSubscription] = startSubscription(mId);

    return () => {
      gridSubscription?.unsubscribe();
    };
  }, [mId]);

  const onContextMenu = useCallback(
    (event: React.MouseEvent<Element, MouseEvent>, member: MemberType) => {
      show({
        event,
        props: {
          member,
        },
      });
    },
    [show],
  );

  return (
    <>
      <Grid
        loading={loading}
        federatedSearchString={federatedSearchString}
        onContextMenu={onContextMenu}
      />
      <MemberContextMenu menuId={`gridItemMenu-${mId}`} domainItems={domainContextItems} />
    </>
  );
};

function GridWidget({
  layout,
  filters,
  mId,
  mRefId,
  title,
  writeAccess,
  federatedSearchString,
}: Readonly<StoryWidgetProps>) {
  const memoizedFilters = useMemo(() => {
    const propFilters = getPropFilters({ filters });
    return propFilters;
  }, [filters]);

  return (
    <ScopeProvider scope={gridScope} value={mId}>
      <WidgetWrapper
        layout={layout}
        mId={mId}
        mRefId={mRefId}
        title={title}
        writeAccess={writeAccess}
        filterComponent={FilterComponent as FilterComponentType}
        filters={memoizedFilters}
        activeFilters={false}
        type={WIDGETS.STORYGRID}
      >
        <GridDataLoader mId={mId} federatedSearchString={federatedSearchString} />
      </WidgetWrapper>
    </ScopeProvider>
  );
}

export default memo(GridWidget);
