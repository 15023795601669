export type MosAsset = {
  assetType: string;
  mediaType: string;
  id: string | null;
  provider: string | null;
  self: string | null;
  title: string | null;
  description: string | null;
  mediaRecordedOn: string | null;
  thumbnailUrl: string | null;
  proxy: string | null;
};

/**
 * Selects an element from the provided XML document using the specified selector.
 * @param xmlDoc - The XML document to search within.
 * @returns The text content of the selected element, or null if the element is not found.
 */
export const QuerySelector = (xmlDoc: Document) => (selector: string) => {
  let item = xmlDoc.querySelector('item');
  if (!item) item = xmlDoc.querySelector('mos');
  if (!item) return null;
  const mosObject = item.querySelector(selector) as { textContent: string };
  return mosObject ? mosObject.textContent : null;
};

/**
 * Extracts asset properties from mos xml object
 *
 * @param xmlDoc Passed mos xml object
 * @returns mos asset object
 *
 */
const convertIntoMosAsset = (xmlDoc: Document, mediaType: string): MosAsset => {
  const newQuerySelector = QuerySelector(xmlDoc);

  const title = newQuerySelector('itemSlug') || newQuerySelector('objSlug');
  const description = newQuerySelector('mosAbstract') || newQuerySelector('description');

  return {
    mediaType,
    assetType: mediaType,
    id: newQuerySelector('objID'),
    provider: newQuerySelector('mosID'),
    self: newQuerySelector('mosExternalMetadata mosPayload element_uri'),
    title,
    description: description && description !== title ? description : null,
    mediaRecordedOn: newQuerySelector('changed'),
    thumbnailUrl: newQuerySelector('mosExternalMetadata mosPayload payload_uri'),
    proxy: newQuerySelector('mosExternalMetadata mosPayload payload_uri'),
  };
};

export default convertIntoMosAsset;
