import PropTypes from 'prop-types';
import AddMemberDropdown from '../../addMember';
import SelectedMembers from '../../addMemberDialog/SelectedMembers';
import { StyledTextField } from '../../addMember/styled';

function AssignMembers({
  assignedMembers,
  setAssignedMembers,
  messageAssignees,
  setMessageAssignees,
  placeholder,
}) {
  const onRemove = (member) => {
    setAssignedMembers(assignedMembers.filter((m) => m.mId !== member.mId));
  };

  const showTextArea = assignedMembers.length > 1;

  return (
    <>
      <AddMemberDropdown
        variant="all"
        showChips={false}
        injectedMembers={null}
        placeholderText="Add people, teams or departments..."
        selectedMembers={assignedMembers}
        setSelectedMembers={setAssignedMembers}
      />
      <SelectedMembers
        selectedMembers={assignedMembers}
        existingMembers={[]}
        onRemove={onRemove}
        removeTooltipText="Remove"
        hideLabel
      />
      {showTextArea && (
        <StyledTextField
          style={{ width: '100%' }}
          fullWidth
          variant="filled"
          placeholder={placeholder}
          value={messageAssignees === null ? '' : messageAssignees}
          onChange={(e) => setMessageAssignees(e.target.value)}
          multiline
          minRows={2}
        />
      )}
    </>
  );
}

AssignMembers.propTypes = {
  /** List of members assigned to current story */
  assignedMembers: PropTypes.arrayOf(
    PropTypes.shape({
      mId: PropTypes.string.isRequired,
      mType: PropTypes.string.isRequired,
    }),
  ),
  /** Callback to be invoked on assignee update */
  setAssignedMembers: PropTypes.func,
  messageAssignees: PropTypes.string,
  setMessageAssignees: PropTypes.func,
  placeholder: PropTypes.string,
  hideMembers: PropTypes.bool,
};

AssignMembers.defaultProps = {
  assignedMembers: [],
  setAssignedMembers: () => {},
  messageAssignees: '',
  setMessageAssignees: () => {},
  placeholder: 'Type a message...',
  hideMembers: false,
};

export default AssignMembers;
