/* eslint-disable max-len */
import { Path, Rect, Svg } from '@react-pdf/renderer';

const DailyNoteIcon = () => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Rect x={0.5} y={0.5} width={31} height={31} rx={5.5} fill="#fff" stroke="#fff" />
      <Path
        fillRule="evenodd"
        d="M7 1.5C7 1.22386 7.22386 1 7.5 1C7.77614 1 8 1.22386 8 1.5V4.5C8 4.77614 7.77614 5 7.5 5C7.22386 5 7 4.77614 7 4.5V1.5ZM16 1.5C16 1.22386 16.2239 1 16.5 1C16.7761 1 17 1.22386 17 1.5V4.5C17 4.77614 16.7761 5 16.5 5C16.2239 5 16 4.77614 16 4.5V1.5ZM9 3V4V4.5C9 5.32843 8.32843 6 7.5 6C6.67157 6 6 5.32843 6 4.5V4V3H5H4C2.89543 3 2 3.89543 2 5V21C2 22.1046 2.89543 23 4 23H17L18 22L21 19L22 18V5C22 3.89543 21.1046 3 20 3H19H18V4V4.5C18 5.32843 17.3284 6 16.5 6C15.6716 6 15 5.32843 15 4.5V4V3H14H10H9ZM20.5858 18L17 21.5858V18H20.5858ZM21 5V17H17C16.4477 17 16 17.4477 16 18V22H4C3.44772 22 3 21.5523 3 21V5C3 4.44772 3.44772 4 4 4H5V4.5C5 5.88071 6.11929 7 7.5 7C8.88071 7 10 5.88071 10 4.5V4H14V4.5C14 5.88071 15.1193 7 16.5 7C17.8807 7 19 5.88071 19 4.5V4H20C20.5523 4 21 4.44772 21 5ZM6.5 9C6.22386 9 6 9.22386 6 9.5C6 9.77614 6.22386 10 6.5 10H15.5C15.7761 10 16 9.77614 16 9.5C16 9.22386 15.7761 9 15.5 9H6.5ZM6.5 12C6.22386 12 6 12.2239 6 12.5C6 12.7761 6.22386 13 6.5 13H12.5C12.7761 13 13 12.7761 13 12.5C13 12.2239 12.7761 12 12.5 12H6.5ZM6 15.5C6 15.2239 6.22386 15 6.5 15H14.5C14.7761 15 15 15.2239 15 15.5C15 15.7761 14.7761 16 14.5 16H6.5C6.22386 16 6 15.7761 6 15.5Z"
        fillOpacity="0.66"
        fill="black"
      />
    </Svg>
  );
};

export default DailyNoteIcon;
