import { Editor, Node, Operation, Text } from 'slate';

import { CustomText } from 'types';

const shouldApplyUppercase = (editor: Editor, operation: Operation): boolean | undefined => {
  if (operation.type === 'insert_text' || operation.type === 'set_node') {
    const node = Node.get(editor, operation.path);
    return Text.isText(node) && node.uppercase;
  }
  if (operation.type === 'insert_node') {
    return Text.isText(operation.node) && operation.node.uppercase;
  }
  return false;
};

const applyUppercase = (apply: (op: Operation) => void, operation: Operation) => {
  switch (operation.type) {
    case 'insert_text':
      return apply({
        ...operation,
        text: operation.text.toUpperCase(),
      });
    case 'set_node':
      if ((operation.properties as CustomText).text) {
        return apply({
          ...operation,
          properties: {
            ...operation.properties,
            text: (operation.properties as CustomText).text.toUpperCase(),
          },
        });
      }
      break;
    case 'insert_node':
      if (Text.isText(operation.node) && operation.node.uppercase) {
        return apply({
          ...operation,
          node: {
            ...operation.node,
            text: operation.node.text.toUpperCase(),
          },
        });
      }
      break;
  }
  return apply(operation);
};

const withUppercase = (editor: Editor) => {
  const { apply } = editor;

  editor.apply = (operation: Operation) => {
    if (shouldApplyUppercase(editor, operation)) {
      return applyUppercase(apply, operation);
    }
    return apply(operation);
  };

  return editor;
};

export default withUppercase;
