import styled from '@emotion/styled/macro';
import { ButtonBase } from '@material-ui/core';

import Divider from 'components/divider';

export const AlertContainer = styled('div')`
  width: 351px;
  min-height: 79px;
  max-height: 50vh;
  background-color: ${({ theme }) => theme.palette.dina.surfaceCard};
  border-radius: 8px;
  box-shadow: 0px 0px 4px ${({ theme }) => theme.palette.dina.boxShadowDark},
    0px 4px 4px ${({ theme }) => theme.palette.dina.boxShadowDark};
  display: flex;
  box-sizing: border-box;
  border: 0.5px solid ${({ theme }) => theme.palette.dina.borderResting};
  align-items: stretch;
  overflow: hidden;
  user-select: none;
`;

export const IconWrapper = styled('span')`
  min-width: 56px;
  max-width: 56px;
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: center;
  min-height: 79px;
  justify-content: flex-end;
  padding-right: 4px;
`;

export const Message = styled('span')`
  width: 207px;
  min-height: 79px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Time = styled('p')`
  ${({ theme }) => theme.typography.dina.caption};
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
  margin: 8px 8px 0 8px;
`;

export const Title = styled('p')`
  ${({ theme }) => theme.typography.dina.listItemLabelMedium};
  margin: 4px 8px 0 8px;
`;

export const Buttons = styled('div')`
  flex-grow: 1;
  width: 87px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 79px;
`;

export const StyledDivider = styled(Divider)`
  width: 100%;
`;

export const Button = styled(ButtonBase)`
  height: 100%;
  width: 100%;
  flex-grow: 1;
  ${({ theme }) => theme.typography.dina.listItemLabel};
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  &:hover {
    background-color: ${({ theme }) => theme.palette.dina.whiteHoverOverlay};
  }
  &.Mui-disabled {
    color: ${({ theme }) => theme.palette.dina.disabled};
  }
`;
