/* eslint-disable import/no-extraneous-dependencies */
import { useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import words from 'lodash/words';

import useMouseEventListener from 'hooks/useMouseEventListener';
import useSettingsValue from 'hooks/useSettingsValue';
import readTime from 'utils/getReadTime';

const useReadSpeedValue = (ref, text) => {
  const [getSettingsValue] = useSettingsValue();
  const defaultReadSpeed = getSettingsValue('rundown.defaultReadSpeed');
  const [wordCount, setWordCount] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const reset = useCallback(() => {
    setWordCount(0);
    setIsDragging(false);
  }, []);

  const updateTitle = useCallback(() => {
    const selectedText = window.getSelection()?.toString() || '';
    setWordCount(words(selectedText).length);
  }, []);

  const onTextSelection = debounce((e) => {
    if (isDragging) updateTitle();
  }, 10);

  const onDragStart = useCallback(() => {
    setIsDragging(true);
    setWordCount(0);
  }, []);

  const onDragStop = useCallback(() => {
    setIsDragging(false);
  }, []);

  useMouseEventListener('mousedown', onDragStart, ref);
  useMouseEventListener('mousemove', onTextSelection, ref);
  useMouseEventListener('mouseup', onDragStop, ref);

  useEffect(() => {
    reset();
  }, [text, reset]);

  const readSpeed = useMemo(
    () => readTime(defaultReadSpeed, wordCount),
    [defaultReadSpeed, wordCount],
  );

  return [readSpeed];
};

export default useReadSpeedValue;
