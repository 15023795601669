import { memo } from 'react';

import Tooltip from 'components/tooltip';
import useNavigateToAsset from 'hooks/useNavigateToAsset';
import { Box } from 'layouts/box/Box';
import { CustomElement } from 'types';
import { MemberType } from 'types/graphqlTypes';

import {
  Actions,
  StyledDelete,
  StyledOpen,
  StyledRemove,
  StyledSidePanelActive,
  StyledSidePanelInactive,
} from './styled';

interface Props {
  element: CustomElement | undefined;
  showMetadata: boolean;
  hasAsset: boolean;
  isLockedByAnotherUser: boolean;
  onRemoveAssetClick: () => void;
  setShowMetadata: (val: boolean) => void;
  onRemoveBlockClick: () => void;
}

const ImageActions = ({
  element,
  showMetadata,
  hasAsset,
  isLockedByAnotherUser,
  setShowMetadata,
  onRemoveBlockClick,
  onRemoveAssetClick,
}: Readonly<Props>) => {
  const openAsset = useNavigateToAsset();

  if (!element) return null;

  return (
    <Actions
      $collapsed={!showMetadata}
      className="actions"
      container
      flexDirection="column"
      justifyContent="space-between"
      gap="4px"
      height="100%"
    >
      <Box container flexDirection="column" gap="6px" justifyContent="start">
        <Tooltip title="Show metadata">
          {showMetadata ? (
            <StyledSidePanelActive onClick={() => setShowMetadata(!showMetadata)} />
          ) : (
            <StyledSidePanelInactive onClick={() => setShowMetadata(!showMetadata)} />
          )}
        </Tooltip>
        {hasAsset && (
          <>
            <Tooltip title="Open">
              <StyledOpen onClick={() => openAsset(element.data as MemberType)} />
            </Tooltip>
            <Tooltip title={isLockedByAnotherUser ? 'Locked' : 'Remove image'}>
              <StyledRemove onClick={onRemoveAssetClick} />
            </Tooltip>
          </>
        )}
      </Box>
      <Tooltip title="Remove entire block">
        <StyledDelete onClick={onRemoveBlockClick} />
      </Tooltip>
    </Actions>
  );
};

export default memo(ImageActions);
