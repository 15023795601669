import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

export function useLocalStorage<T>({
  defaultValue,
  key,
  dispatchEvent = false,
}: {
  defaultValue: T;
  key?: string;
  dispatchEvent?: boolean;
}): [T, Dispatch<SetStateAction<T>>] {
  const isMounted = useRef(false);

  const [value, setValue] = useState<T>(() => {
    if (!key) {
      return defaultValue;
    }
    try {
      const item = localStorage.getItem(key);
      return item ? (JSON.parse(item) as T) : defaultValue;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error reading from local storage', e);
      return defaultValue;
    }
  });

  useEffect(() => {
    if (!key) return;
    if (isMounted.current) {
      localStorage.setItem(key, JSON.stringify(value));
      if (dispatchEvent)
        window.dispatchEvent(new StorageEvent('storage', { key, newValue: JSON.stringify(value) }));
    } else {
      isMounted.current = true;
    }
  }, [key, value, dispatchEvent]);

  return [value, setValue];
}
