import { useState, useContext } from 'react';
import configCtx from 'contexts/configContext';
import { useQuery, useMutation } from '@apollo/client';
import UPDATE_FORM from 'operations/mutations/updateForm';
import GET_METADATA_FORMS from 'operations/queries/getMetadataForms';
import { saveMetaFormOnContext } from 'operations/global-functions/utils';
import { useChangedMetadataField, useMetadataFields, useChangedInstanceFields } from '../atoms';
import getUpdatedViewsInForm from '../utils/getUpdatedViewsInForm';
import getUpdatedMetadataFields from '../utils/getUpdatedMetadataFields';

const updateFormInContext = (updatedForm, data, config) => {
  const getMetadataForms = data?.getMetadataForms.map((metadataForm) => {
    if (metadataForm.mRefId === 'form-00') {
      return updatedForm;
    }
    return metadataForm;
  });
  saveMetaFormOnContext({ data: { getMetadataForms } }, config);
};

const useUpdateFormMetadataField = () => {
  const [changedMetadataField] = useChangedMetadataField();
  const config = useContext(configCtx);
  const [updateForm] = useMutation(UPDATE_FORM);
  const [loading, setLoading] = useState(false);
  const [metadataFields] = useMetadataFields();
  const [changedInstanceFields] = useChangedInstanceFields();

  const { data } = useQuery(GET_METADATA_FORMS, {
    variables: {
      input: {
        mId: 'form',
      },
    },
    fetchPolicy: 'cache-only',
  });

  const updateMetadataField = async (form, instanceTypes) => {
    const updatedFields = getUpdatedMetadataFields(changedMetadataField, metadataFields);
    const { views, defaultViews } = getUpdatedViewsInForm(
      changedInstanceFields,
      instanceTypes,
      form,
    );
    const mProperties = {
      ...form.mProperties,
      fields: updatedFields,
      views,
      defaultViews,
    };

    setLoading(true);
    const promise = await updateForm({
      variables: {
        input: {
          mId: 'form',
          mRefId: 'form-00',
          mProperties,
        },
      },
    });
    updateFormInContext(promise?.data?.updateForm, data, config);

    setLoading(false);

    return promise;
  };

  return [updateMetadataField, loading];
};

export default useUpdateFormMetadataField;
