/* eslint-disable sort-imports */
import { useCallback, useEffect, useState } from 'react';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import Fade from '@material-ui/core/Fade';

import { Button } from 'components/buttons';
import { CheckboxWithLabel } from 'components/createNewV3/CreateNew';
import Divider from 'components/divider';
import Popover from 'components/popover';

import { EmptyOptions, Group, Panel, Wrapper } from '../styled';
import { GroupedField, OrderColumn, SavedFilter } from '../types';
import { defaultFieldsTitleMap } from '../utils';

import MenuItem from './OrderGridSortMenuItem';

const anchorOrigin = {
  vertical: 'center',
  horizontal: 'right',
};

const transformOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

interface Props {
  anchorEl: EventTarget | null;
  columns: OrderColumn[];
  setColumns: React.Dispatch<React.SetStateAction<OrderColumn[]>>;
  setAnchorEl: React.Dispatch<React.SetStateAction<EventTarget | null>>;
  formId: string;
  groupedMenuItems: GroupedField[];
}

const mustShowFields = ['__formType', '__buttons'];

function OrderGridSortMenu({
  anchorEl,
  setAnchorEl,
  columns,
  setColumns,
  formId,
  groupedMenuItems,
}: Readonly<Props>) {
  const [mOrder, setMOrder] = useState<string[]>([]);
  const [hiddenFields, setHiddenFields] = useState<string[]>([]);

  const popoverOpen = Boolean(anchorEl);

  const savedFilter = window.localStorage.getItem(formId);

  useEffect(() => {
    const newMOrder = columns
      .filter((column) => !mustShowFields.includes(column.key) && column.visible)
      .map((col) => col.key);
    setMOrder(newMOrder);
  }, [columns]);

  useEffect(() => {
    if (savedFilter !== null) {
      const { hidden = [] } = JSON.parse(savedFilter) as SavedFilter;
      setHiddenFields(hidden);
    }
  }, [savedFilter]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const checkboxClick = useCallback(
    (id: string, checked: boolean) => {
      const index = mOrder.findIndex((order) => order === id);
      if (index === -1) {
        setMOrder([...mOrder, id]);
      } else {
        const newMOrder = [...mOrder];
        newMOrder.splice(index, 1);
        setMOrder(newMOrder);
      }
      if (checked) setHiddenFields(hiddenFields.filter((field) => field !== id));
      else setHiddenFields([...hiddenFields, id]);
    },
    [mOrder],
  );

  const handleApply = useCallback(() => {
    window.localStorage.setItem(formId, JSON.stringify({ hidden: hiddenFields, order: mOrder }));
    const copy = [...columns];

    copy.forEach((column) => {
      if (mustShowFields.includes(column.key)) return;
      column.visible = !hiddenFields.includes(column.key);
    });

    setColumns(copy);

    handleClose();
  }, [mOrder, hiddenFields, columns, setColumns]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    const oldIndex = mOrder.findIndex((col) => col === active.id);
    const newIndex = mOrder.findIndex((col) => col === over?.id);
    const updatedOrder = arrayMove([...mOrder], oldIndex, newIndex);
    setMOrder(updatedOrder);
  };

  return (
    <Popover
      id="orderColumnSortMenu"
      open={popoverOpen}
      onClose={handleClose}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      TransitionComponent={Fade}
      noMargin
      position={undefined}
      type={undefined}
      style={{ pointerEvents: 'auto' }}
    >
      <div>
        <Wrapper>
          <Panel>
            {Object.keys(defaultFieldsTitleMap).map((field) => (
              <div key={field}>
                <CheckboxWithLabel
                  label={defaultFieldsTitleMap[field]}
                  onClick={(checked: boolean) => checkboxClick(field, checked)}
                  selected={!hiddenFields.includes(field)}
                  disabled={false}
                />
              </div>
            ))}
            {groupedMenuItems.map((group) => (
              <div key={group.id}>
                <Group>{group.mTitle}</Group>
                {group.fields.length === 0 ? (
                  <EmptyOptions>No options available</EmptyOptions>
                ) : (
                  <>
                    {group.fields.map((field) => (
                      <div key={field.id}>
                        <CheckboxWithLabel
                          label={field.label}
                          onClick={(checked: boolean) => checkboxClick(field.id, checked)}
                          selected={!hiddenFields.includes(field.id)}
                          disabled={false}
                        />
                      </div>
                    ))}
                  </>
                )}
              </div>
            ))}
          </Panel>
          <Divider flexItem orientation="vertical" className={undefined} />
          <Panel>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={onDragEnd}
              modifiers={[restrictToParentElement, restrictToVerticalAxis]}
            >
              <SortableContext items={mOrder} strategy={verticalListSortingStrategy}>
                {mOrder.map((column) => {
                  const columnData = columns.find((col) => col.key === column);
                  if (!columnData) return null;
                  return <MenuItem key={column} column={columnData} />;
                })}
              </SortableContext>
            </DndContext>
          </Panel>
        </Wrapper>
        <Divider className={undefined} />
        <Wrapper>
          <Button usage="outlined" variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleApply}>Apply</Button>
        </Wrapper>
      </div>
    </Popover>
  );
}

export default OrderGridSortMenu;
