import gql from 'graphql-tag';

export default gql`
  subscription createRundownsSubscription {
    createRundownsSubscription {
      mId
      mRefId
      mTitle
      mPublishingAt
      mPlannedDuration
      mThumbnailUrl
      mThumbnailKey
      mRundownTemplateId
      mType
      mState
      version
      mOrder
      mPreorder
      mCoverPhotoUrl
      mCoverPhotoKey
      platformKind
      recurrence {
        duration
        startTime
        timeZone
        dailyExclusive
      }
    }
  }
`;
