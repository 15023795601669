import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { InputBase } from '@material-ui/core';
import Select from 'components/select';
import Divider from 'components/divider';
import { ReactComponent as SearchSvg } from 'assets/icons/systemicons/search.svg';
import { ReactComponent as CloseSmall } from 'assets/icons/systemicons/close_small.svg';
import { ReactComponent as FiltersOn } from 'assets/icons/systemicons/filters_on.svg';
import { ReactComponent as FiltersOff } from 'assets/icons/systemicons/filters_off.svg';
import { ReactComponent as SortUp } from 'assets/icons/systemicons/sort_up.svg';
import { ReactComponent as SortDown } from 'assets/icons/systemicons/sort_down.svg';

export const Container = styled('div')`
  width: 100%;
  height: 50px;
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 4px;
  border-top: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

export const SearchContainer = styled('div')`
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
`;

export const SearchIcon = styled(SearchSvg)`
  position: absolute;
  left: 8px;
  top: 8px;
`;

export const Input = styled(InputBase)`
  ${({ theme }) => css`
    ${theme.typography.dina.caption};
    color: ${theme.palette.dina.highEmphasis};
    border: 0.5px solid ${theme.palette.dina.buttonBorderOutlined};
  `};
  transition: 0.2s;
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 32px;
  border-radius: 6px;
  :hover {
    transition: 0.2s ease-in;
    border: 1px solid ${({ theme }) => theme.palette.dina.inputBorderFocused};
  }
  :focus {
    background-color: rgba(0, 30, 85, 0.16);
    border-color: ${({ theme }) => theme.palette.dina.inputBorderFocused};
  }
`;

export const CloseIcon = styled(CloseSmall)`
  path {
    transition: 0.2s;
    fill: ${({ theme }) => theme.palette.dina.highEmphasis};
    fill-opacity: 0.54;
  }
`;

export const CloseButton = styled('div')`
  position: absolute;
  right: 0;
  top: 0;
  padding: 6px 11px;
  cursor: pointer;
  :hover {
    path {
      fill-opacity: 1;
    }
  }
`;

export const Filters = styled('div')`
  transition: 0.2s;
  width: 40px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.5px solid ${({ theme }) => theme.palette.dina.buttonBorderOutlined};
  border-radius: 6px;
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.palette.dina.whiteHoverOverlay};
    path {
      transition: 0.2s;
      fill-opacity: 1;
    }
  }
`;

export const FiltersOnIcon = styled(FiltersOn)`
  path {
    transition: 0.2s;
    fill: ${({ theme }) => theme.palette.dina.highEmphasis};
    fill-opacity: 0.54;
  }
`;

export const FiltersOffIcon = styled(FiltersOff)`
  path {
    transition: 0.2s;
    fill: ${({ theme }) => theme.palette.dina.highEmphasis};
    fill-opacity: 0.54;
  }
`;

export const Dropdown = styled(Select)`
  width: 140px;
  height: 32px;
`;

export const SortContainer = styled('div')`
  width: 81px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.5px solid ${({ theme }) => theme.palette.dina.buttonBorderOutlined};
  border-radius: 6px;
`;

export const SortButton = styled('div')`
  transition: 0.2s;
  width: 40px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    background-color: ${({ theme }) => theme.palette.dina.whiteHoverOverlay};
    path {
      fill-opacity: 1;
    }
  }
`;

export const SortUpIcon = styled(SortUp)`
  path {
    transition: 0.2s;
    ${({ selected, theme }) => ({
      fill: selected ? theme.palette.dina.onSelected : theme.palette.dina.mediumEmphasis,
    })}
    fill-opacity: 0.54;
  }
`;

export const SortDownIcon = styled(SortDown)`
  path {
    transition: 0.2s;
    ${({ selected, theme }) => ({
      fill: selected ? theme.palette.dina.onSelected : theme.palette.dina.mediumEmphasis,
    })}
    fill-opacity: 0.54;
  }
`;

export const VDivider = styled(Divider)`
  width: 1px;
  height: 20px;
`;
