import { Dispatch, SetStateAction, useContext } from 'react';

import { ReactComponent as ActivateIcon } from 'assets/icons/systemicons/activate_user.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/systemicons/delete.svg';
import { IconButton } from 'components/buttons';
import UserContext from 'contexts/UserContext';

import { ActionWrapper } from './styled';

interface UserValues {
  id: string;
  mRefId: string;
  username: string;
  email: string;
  notListed: boolean;
}

interface ActionPropType {
  setOpen: Dispatch<SetStateAction<boolean>>;
  setValues: Dispatch<SetStateAction<UserValues | undefined>>;
  setOpenDeleteDialog?: Dispatch<SetStateAction<boolean>>;
  isHidden: boolean;
  mId: string;
  mRefId: string;
  username: string;
  email: string;
  status?: string;
}

const Actions = ({
  setOpen,
  setValues,
  setOpenDeleteDialog,
  isHidden,
  mId,
  mRefId,
  username,
  email,
  status,
}: ActionPropType) => {
  const loggedInUser = useContext(UserContext);
  const getTitle = () => {
    if (status === 'EXTERNAL_PROVIDER') {
      return 'For permanent deletion please delete this user from SSO';
    }
    if (mId == loggedInUser.mId) return;
    return 'Delete user';
  };
  return (
    <ActionWrapper>
      <IconButton
        title="Restore User"
        onClick={() => {
          setOpen(true);
          setValues({
            id: mId,
            mRefId: mRefId,
            username: username,
            email: email,
            notListed: isHidden,
          });
        }}
        usage="text"
        variant="contained"
      >
        <ActivateIcon />
      </IconButton>

      <IconButton
        title={getTitle()}
        onClick={() => {
          if (setOpenDeleteDialog) setOpenDeleteDialog(true);
          setValues({
            id: mId,
            mRefId: mRefId,
            username: username,
            email: email,
            notListed: isHidden,
          });
        }}
        usage="text"
        variant="contained"
        disabled={mId === loggedInUser.mId}
      >
        <DeleteIcon />
      </IconButton>
    </ActionWrapper>
  );
};

export default Actions;
