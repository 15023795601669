import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  playButton: {
    position: 'absolute',
    backdropFilter: 'blur(15px)',
    zIndex: 1,
    backgroundColor: theme.palette.dina.blackHoverOverlay,
    '&:hover': {
      backgroundColor: theme.palette.dina.blackHoverOverlay,
    },
    width: '32px',
    height: '32px',
    top: '50%',
    left: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
    padding: 0,
  },
  playImage: {
    width: '32px',
    height: '32px',
  },
  duration: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: '44px',
    height: '16px',
    borderRadius: '4px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: ({ isScratchPad }) =>
      theme.palette.dina[isScratchPad ? 'buttonBackgroundHighEmphasis' : 'blackInactive'],
    backdropFilter: 'blur(15px)',
    // opacity: 0.5,
    ...theme.typography.dina.captionRegular,
    letterSpacing: '.25px',
    lineHeight: '13px',
    color: theme.palette.dina.highEmphasis,
  },
  mimirIcon: {
    position: 'absolute',
    cursor: 'pointer',
    top: 2,
    left: 2,
    width: '16px',
    height: '16px',
    display: 'flex',
  },
  video: {
    borderRadius: '4px',
    outline: 'none',
    background: 'linear-gradient(180deg, rgba(1,0,5,0.7) 0%, rgba(1,0,5,0.7) 0%)',
  },
}));

export default useStyles;
