import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Typography from '@material-ui/core/Typography';

export const StyledTooltipTitleWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: max-content;
  max-width: 252px;
  padding: 0 4px;
`;

export const StyledTooltipTitleHeader = styled(Typography)`
  ${({ theme }) => theme.typography.dina.captionMedium};
  text-transform: capitalize;
`;

export const StyledTooltipDescription = styled(Typography)`
  ${({ theme }) => css`
    ${theme.typography.dina.caption};
    color: ${theme.palette.dina.whiteHighEmphasis};
  `}
  text-transform: capitalize;
`;
