import { useId } from 'react';
import { Fade, Collapse as MaterialCollapse } from '@material-ui/core';
import { atom, useAtom } from 'jotai';
import { createScope, molecule, ScopeProvider, useMolecule } from 'jotai-molecules';

import { ReactComponent as ArrowDown } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_down.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_right.svg';

import useHover from 'hooks/useHover';
import Tooltip from 'components/tooltip';

import {
  ArrowWrapper,
  Container,
  CountText,
  Header,
  HeaderText,
  HeaderTextWrapper,
  List,
  HeaderButton,
  ListItem,
  ListItemText,
  PlusButton,
} from './styled';

export const CollapseScope = createScope(undefined);
export const CollapseMolecule = molecule((_, getScope) => {
  getScope(CollapseScope);

  const isExpandedAtom = atom(false);
  const useScopedIsExpandedAtom = () => useAtom(isExpandedAtom);

  return { useScopedIsExpandedAtom };
});

const selectArrow = (isExpanded) => (isExpanded ? ArrowDown : ArrowRight);
export default function CollapseList({ children, ...restProps }) {
  const randomId = useId();
  return (
    <ScopeProvider scope={CollapseScope} value={randomId}>
      <Container {...restProps}>{children}</Container>
    </ScopeProvider>
  );
}

CollapseList.Header = function CollapseHeader({
  children,
  selected,
  itemCount,
  createButtonTooltip,
  createButtonDisabled,
  onCreateClick,
  hideCreateButton,
}) {
  const [hoverRef, isHovered] = useHover();
  const { useScopedIsExpandedAtom } = useMolecule(CollapseMolecule);
  const [isExpanded, setIsExpanded] = useScopedIsExpandedAtom();

  const createClicked = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onCreateClick();
  };

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const Arrow = selectArrow(isExpanded);

  return (
    <Header $selected={selected} role="menu" aria-label="Expandable list">
      <HeaderButton
        ref={hoverRef}
        onClick={toggleExpanded}
        aria-label={isExpanded ? 'Close' : 'Expand'}
      >
        <HeaderTextWrapper>
          <ArrowWrapper>
            <Arrow />
          </ArrowWrapper>
          <HeaderText>{children}</HeaderText>
        </HeaderTextWrapper>
        <CountText hovered={!hideCreateButton && isHovered}>{itemCount}</CountText>
        {!hideCreateButton && (
          <Fade in={isHovered} timeout={{ enter: 250, exit: 50 }} mountOnEnter unmountOnExit>
            <Tooltip title={createButtonTooltip}>
              <PlusButton onClick={createClicked} $disabled={createButtonDisabled} />
            </Tooltip>
          </Fade>
        )}
      </HeaderButton>
    </Header>
  );
};

CollapseList.Body = function CollapseBody({ children }) {
  const { useScopedIsExpandedAtom } = useMolecule(CollapseMolecule);
  const [isExpanded] = useScopedIsExpandedAtom();

  return (
    <MaterialCollapse in={isExpanded} mountOnEnter unmountOnExit>
      <List>{children}</List>
    </MaterialCollapse>
  );
};

CollapseList.Item = function CollapseItem({
  id,
  children,
  selected,
  onClick,
  italic,
  className,
  itemCount,
}) {
  return (
    <ListItem key={id} $selected={selected} role="menuitem" className={className}>
      <HeaderButton onClick={onClick} aria-label="Select">
        <ListItemText $italic={italic}>{children}</ListItemText>
        <CountText>{itemCount}</CountText>
      </HeaderButton>
    </ListItem>
  );
};
