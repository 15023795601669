import { useEffect } from 'react';
import { atom, useAtom, useSetAtom } from 'jotai';
import { createScope, molecule, useMolecule } from 'jotai-molecules';
import { focusAtom } from 'jotai-optics';
import { v4 as uuidv4 } from 'uuid';

import { KanbanBoardType } from 'screens/space/types/space';
import { MemberType, Rundown } from 'types/graphqlTypes';

type CallbackType = (newVal: KanbanBoardType, prevVal: KanbanBoardType) => Promise<void> | void;
export type UpdateBoardOrderType = { mOrder: string[] };
export type AddMemberType = { laneId: string; memberId: string };
export type CreateLaneType = { mId: string; direction?: 'ltr' | 'rtl' };
export type DeleteLaneType = { mRefId: string };
export type UpdateLaneColorType = { mRefId: string; color: string };
export type UpdateLaneTitleType = { mRefId: string; mTitle: string };
type UpdateLaneItemsInput = Record<string, string[]>;
export type UpdateLaneOrderType = { items?: UpdateLaneItemsInput };

/* -------------------------------------------------------------------------- */
/*                              Kanban board atom                             */
/* -------------------------------------------------------------------------- */

export const noRundownCategoryId = 'rundownsNoCategory';

export const kanbanScope = createScope(undefined);

const kanbanMolecule = molecule((_, getScope) => {
  getScope(kanbanScope);

  const kanbanBoardAtom = atom<KanbanBoardType>({
    lanes: {},
  });
  const useKanbanBoard = () => useAtom(kanbanBoardAtom);

  // Callback hook, used for storing changes when kanban lanes are updated
  const listenersAtom = atom<CallbackType[]>([]);

  const updateKanbanBoardAtom = atom(
    (get) => get(kanbanBoardAtom),
    (get, set, val: KanbanBoardType) => {
      const prevVal = get(kanbanBoardAtom);
      set(kanbanBoardAtom, val);
      const newVal = get(kanbanBoardAtom);
      get(listenersAtom).forEach((callback: CallbackType) => {
        void callback(newVal, prevVal);
      });
    },
  );

  /* -------------------------------------------------------------------------- */
  /*                             Update board order                             */
  /* -------------------------------------------------------------------------- */

  const updateBoardOrderAtom = atom(null, (get, set, { mOrder = [] }: UpdateBoardOrderType) => {
    const kanbanBoard = { ...get(kanbanBoardAtom) };
    if (!kanbanBoard) return;

    const updatedKanbanBoard = { ...kanbanBoard, mOrder };

    set(updateKanbanBoardAtom, updatedKanbanBoard);
  });

  const useUpdateBoardOrder = () => useSetAtom(updateBoardOrderAtom);

  /* -------------------------------------------------------------------------- */
  /*                                Kanban lanes                                */
  /* -------------------------------------------------------------------------- */

  const kanbanLanesAtom = focusAtom(kanbanBoardAtom, (optic) => optic.prop('lanes'));
  const useKanbanLanes = () => useAtom(kanbanLanesAtom);

  const kanbanBoardUpdatedListener = () => {
    const useListener = (callback: CallbackType) => {
      const setListeners = useSetAtom(listenersAtom);
      useEffect(() => {
        setListeners((prev) => [...prev, callback]);
        return () =>
          setListeners((prev) => {
            const index = prev.indexOf(callback);
            return [...prev.slice(0, index), ...prev.slice(index + 1)];
          });
      }, [setListeners, callback]);
    };
    return [useListener];
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Create lane                                */
  /* -------------------------------------------------------------------------- */

  const createLaneAtom = atom(null, (get, set, { mId, direction }: CreateLaneType) => {
    if (!mId) return;

    const newId = uuidv4();

    const newLane = {
      mId: mId,
      mRefId: newId,
      mTitle: '[Untitled]',
      mOrder: [],
    };

    const kanbanBoard = { ...get(kanbanBoardAtom) };
    const lanes = kanbanBoard.lanes ? { ...kanbanBoard.lanes } : {};

    // Determine if the new lane should be added to the start or end of the board
    const isLtr = direction === 'ltr';

    const updatedLanes = isLtr ? { ...lanes, [newId]: newLane } : { [newId]: newLane, ...lanes };
    const mOrder = kanbanBoard.mOrder ?? [];
    const laneOrder = isLtr ? [...mOrder, newId] : [newId, ...mOrder];

    const updatedKanbanBoard = { ...kanbanBoard, mOrder: laneOrder, lanes: updatedLanes };

    set(updateKanbanBoardAtom, updatedKanbanBoard);

    return newLane;
  });

  const useCreateKanbanLane = () => useSetAtom(createLaneAtom);

  /* -------------------------------------------------------------------------- */
  /*                        Create a default rundown lane                       */
  /* -------------------------------------------------------------------------- */

  const createDefaultLaneAtom = atom(null, (get, set, { mOrder }: { mOrder: string[] }) => {
    const newId = noRundownCategoryId;

    const newLane = {
      mId: newId,
      mRefId: newId,
      mTitle: 'Uncategorized',
      mOrder: mOrder,
    };

    const kanbanBoard = { ...get(kanbanBoardAtom) };
    const lanes = kanbanBoard.lanes ? { ...kanbanBoard.lanes } : {};

    const updatedLanes = {
      ...lanes,
      [newId]: newLane,
    };

    const boardOrder = kanbanBoard.mOrder ?? [];
    const updatedKanbanBoard = { ...kanbanBoard, lanes: updatedLanes };

    if (!boardOrder?.includes(newId)) {
      updatedKanbanBoard.mOrder = [...boardOrder, newId];
    }

    set(updateKanbanBoardAtom, updatedKanbanBoard);
  });

  const useCreateDefaultLane = () => useSetAtom(createDefaultLaneAtom);

  /* -------------------------------------------------------------------------- */
  /*                                 Delete lane                                */
  /* -------------------------------------------------------------------------- */

  const deleteLaneAtom = atom(null, (get, set, { mRefId }: DeleteLaneType) => {
    if (!mRefId) return;

    const board = { ...get(kanbanBoardAtom) };
    const lanes = board.lanes ? { ...board.lanes } : {};

    // Add the lane members to the no category lane
    const mOrder = lanes[mRefId]?.mOrder ?? [];
    const noCategoryLane = lanes[noRundownCategoryId];
    lanes[noRundownCategoryId] = {
      ...noCategoryLane,
      mOrder: [...(noCategoryLane?.mOrder ?? []), ...mOrder],
    };

    const boardMOrder = board.mOrder?.filter((id) => id !== mRefId) ?? [];
    const newLanes = { ...lanes };

    Object.keys(newLanes).forEach((lane) => {
      if (!boardMOrder.includes(lane)) delete newLanes[lane];
    });

    const updatedBoard = { ...board, mOrder: boardMOrder, lanes: newLanes };

    set(updateKanbanBoardAtom, updatedBoard);
  });

  const useDeleteKanbanLane = () => useSetAtom(deleteLaneAtom);

  /* -------------------------------------------------------------------------- */
  /*                              Update lane title                             */
  /* -------------------------------------------------------------------------- */

  const updateLaneTitleAtom = atom(
    null,
    (get, set, { mRefId, mTitle = '' }: UpdateLaneTitleType) => {
      if (!mRefId) return;

      const board = { ...get(kanbanBoardAtom) };
      const lanes = board.lanes ? { ...board.lanes } : {};
      lanes[mRefId] = { ...lanes[mRefId], mTitle: mTitle };
      const updatedBoard = { ...board, lanes };

      set(updateKanbanBoardAtom, updatedBoard);
    },
  );

  const useUpdateKanbanLaneTitle = () => useSetAtom(updateLaneTitleAtom);

  /* -------------------------------------------------------------------------- */
  /*                              Update lane order                             */
  /* -------------------------------------------------------------------------- */

  const updateKanbanLaneOrderAtom = atom(null, (get, set, { items }: UpdateLaneOrderType) => {
    if (!items) return;

    const kanbanBoard = { ...get(kanbanBoardAtom) };
    const lanes = kanbanBoard.lanes ? { ...kanbanBoard.lanes } : {};

    Object.values(lanes).forEach((lane) => {
      const laneId = lane?.mRefId;
      const mOrder = items[laneId] ?? [];
      lanes[laneId] = { ...lanes[laneId], mOrder: [...mOrder] };
    });

    const updatedBoard = { ...kanbanBoard, lanes };
    set(updateKanbanBoardAtom, updatedBoard);
  });

  const useUpdateKanbanLaneOrder = () => useSetAtom(updateKanbanLaneOrderAtom);

  /* -------------------------------------------------------------------------- */
  /*                              Update lane color                             */
  /* -------------------------------------------------------------------------- */

  const updateKanbanLaneColorAtom = atom(
    null,
    (get, set, { mRefId, color = '#ffffff' }: UpdateLaneColorType) => {
      if (!mRefId) return;

      const board = { ...get(kanbanBoardAtom) };
      const lanes = board.lanes ? { ...board.lanes } : {};

      lanes[mRefId] = { ...lanes[mRefId], color };
      board.lanes = lanes;

      set(updateKanbanBoardAtom, board);
    },
  );

  const useUpdateKanbanLaneColor = () => useSetAtom(updateKanbanLaneColorAtom);

  /* -------------------------------------------------------------------------- */
  /*                                 Add member                                 */
  /* -------------------------------------------------------------------------- */

  const addMemberAtom = atom(null, (get, set, { laneId, memberId }: AddMemberType) => {
    if (!laneId || !memberId) return;

    const board = { ...get(kanbanBoardAtom) };
    const lanes = board.lanes ? { ...board.lanes } : {};

    const lane = lanes[laneId];
    const mOrder = lane.mOrder ?? [];

    lanes[laneId] = { ...lanes[laneId], mOrder: [...mOrder, memberId] };
    board.lanes = lanes;

    set(updateKanbanBoardAtom, board);
  });

  /* -------------------------------------------------------------------------- */
  /*                               Remove member                                */
  /* -------------------------------------------------------------------------- */

  const removeMemberAtom = atom(null, (get, set, { laneId, memberId }: AddMemberType) => {
    if (!laneId || !memberId) return;

    const board = { ...get(kanbanBoardAtom) };
    const lanes = board.lanes ? { ...board.lanes } : {};

    const lane = lanes[laneId];
    const mOrder = lane.mOrder ?? [];

    lanes[laneId] = { ...lanes[laneId], mOrder: mOrder.filter((id) => id !== memberId) };
    board.lanes = lanes;

    set(updateKanbanBoardAtom, board);
  });

  /* -------------------------------------------------------------------------- */
  /*                               Kanban members                               */
  /* -------------------------------------------------------------------------- */

  type KanbanMembersType = Record<string, MemberType | Rundown>;
  const kanbanMembersAtom = atom<KanbanMembersType>({});

  return {
    kanbanBoardUpdatedListener,
    useAddMember: () => useSetAtom(addMemberAtom),
    useRemoveMember: () => useSetAtom(removeMemberAtom),
    useKanbanBoard,
    useUpdateBoardOrder,
    useKanbanLanes,
    useCreateKanbanLane,
    useCreateDefaultLane,
    useDeleteKanbanLane,
    useUpdateKanbanLaneColor,
    useUpdateKanbanLaneTitle,
    useUpdateKanbanLaneOrder,
    useKanbanMembers: () => useAtom(kanbanMembersAtom),
  };
});

export const useKanbanMolecule = () => useMolecule(kanbanMolecule);
