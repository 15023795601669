import gql from 'graphql-tag';

export default gql`
  subscription notifyMemberUpdate($mIdSubscribed: String!) {
    notifyMemberUpdateSubscription(mIdSubscribed: $mIdSubscribed) {
      mId
      mTitle
      mRefId
      mDescription
      mState
      mPublishingAt
      mPublishingEnd
      mType
      crudAction
      mUpdatedAt
      mUpdatedById
      mCreatedAt
      mContentKey
      mPriority
      mContent
      mContentUrl
      mDefaultContentKey
      mThumbnailUrl
      message
      mAvatarKey
      mStoryId
      mStudios
      mThumbnailKey
      mOrder
      mSyncProviders {
        provider
        mMetaData {
          key
          value
        }
      }
      mMetaData {
        key
        value
        manual
        autoValue
      }
      locked
      items {
        title
        itemId
        templateType
        templateVariant
        iconUrl
        subItems {
          itemId
          title
          templateType
          templateVariant
          iconUrl
        }
      }
      mRelatedMembers
      mProperties {
        __typename
        ... on PlatformType {
          platform
          platformKind
          id
          provider {
            id
            requestId
            state
            message
            embeddedEndpoint
            previewEndpoint
            updateEndpoint
          }
          account {
            id
            accountUrl
            accountLogo
            accountTitle
            accountId
            accountRefId
            orderType
          }
        }
        ... on NoteProperties {
          pinned
          labelId
        }
      }
      mAssignedMembers {
        mId
        mType
      }
      mTemplateId
      isTemplateInstance
      isRestricted
      mUsers {
        mId
        mTitle
      }
      command {
        name
        arguments {
          key
          value
        }
      }
      assets {
        mId
        assetRefId
        itemId
        itemType
        state
      }
      ttl
      messageAssign
      mosObj
      metadata
      mdfId
      mResourceId
      mSecId
      mSecRefId
    }
  }
`;
