import { List as MaterialList } from '@material-ui/core';
import styled from '@emotion/styled';
import transientOptions from 'theme/helpers';

// eslint-disable-next-line import/prefer-default-export
export const List = styled(MaterialList, transientOptions)`
  background-color: ${({ theme }) => theme.palette.dina.surfaceCard};
  border-radius: 8px;
  backdrop-filter: blur(30px);
  max-width: 400px;
  padding: 8px 0;
  max-height: 85vh;
  overflow: auto;
  opacity: ${(props) => (props.$disableChildren ? 0.5 : 1)};
`;
