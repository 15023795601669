import { useState } from 'react';
import styled from '@emotion/styled';

import { ReactComponent as More } from 'assets/icons/systemicons/storyCard/more_vertical.svg';
import Radio from 'components/buttons/radioButton';
import Dropdown, { ListItem } from 'components/ellipsisDropdown';
import Switch from 'components/switch';
import Text from 'components/text/Text';
import { Box } from 'layouts/box/Box';

import { useHideStoryThumbnail, useStoryListItemSize } from '../store/storyOptions';

const RadioContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 8px;
  padding: 0 10px;
`;

const RadioWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

function Options() {
  const [isThumbnailHidden, setIsThumbnailHidden] = useHideStoryThumbnail();
  const [storyListItemSize, setStoryListItemSize] = useStoryListItemSize();
  const [anchorEl, setAnchorEl] = useState(null);
  const listItems = [
    <Box padding="0 10px" key="text-header">
      <Text variant="overline">UI options</Text>
    </Box>,
    <ListItem
      style={{ padding: '0 10px' }}
      text="Show Thumbnail"
      key="showThumbnail"
      iconChild={
        <Switch
          selected={!isThumbnailHidden}
          onClick={() => setIsThumbnailHidden(!isThumbnailHidden)}
          disabled={undefined}
        />
      }
      firstChild={undefined}
    />,
    <RadioContainer key="radio">
      <RadioWrapper onClick={() => setStoryListItemSize('default')} role="presentation">
        <Radio selected={storyListItemSize === 'default'} size={22} />
        <Text variant="listItemLabel">Default list items</Text>
      </RadioWrapper>
      <RadioWrapper onClick={() => setStoryListItemSize('compact')} role="presentation">
        <Radio selected={storyListItemSize === 'compact'} size={22} />
        <Text variant="listItemLabel">Compact list items</Text>
      </RadioWrapper>
    </RadioContainer>,
  ];
  return (
    <Dropdown
      anchorEl={anchorEl}
      padding="0 4px 0 0"
      setAnchorEl={setAnchorEl}
      listItems={listItems}
      IconComponent={<More className="skipOverride" />}
    />
  );
}

export default Options;
