import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useReadOnly } from 'slate-react';
import { elementTypes } from 'components/editor/constants/types';
import styled from '@emotion/styled';
import useCheckUserRight from 'hooks/useCheckUserRight';
import { urlPrefix } from 'components/editor/constants';
import getCleanLink from 'components/editor/utils/getCleanLink';
import LinkedInstance from './components/linkedInstance';
import getInstanceIdFromLink from '../../utils/getInstanceIdFromLink';

const target = '_blank';

const StyledAnchor = styled('a')`
  color: ${({ theme }) => theme.palette.dina.url};
`;

const Link = ({ attributes, children, element }) => {
  const { href } = element.data;
  const [checkUserRight] = useCheckUserRight();
  const showLinkedInstancePreview = checkUserRight('feature', 'instance-link');
  const isReadOnly = useReadOnly();

  const memoizedLink = useMemo(() => {
    if (showLinkedInstancePreview && href.indexOf(urlPrefix.LINKED_INSTANCE_HREF_PREFIX) === 0) {
      return (
        <LinkedInstance
          instanceId={getInstanceIdFromLink(href)}
          attributes={attributes}
          element={element}
          shouldShowPreviewOnClick={isReadOnly}
        >
          {children}
        </LinkedInstance>
      );
    }

    return (
      <StyledAnchor
        rel="noopener noreferrer"
        role="presentation"
        href={getCleanLink(href)}
        target={target}
        {...attributes}
      >
        {children}
      </StyledAnchor>
    );
  }, [children, href, showLinkedInstancePreview, attributes, element, isReadOnly]);

  return memoizedLink;
};

Link.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

Link.defaultProps = {
  attributes: {},
  children: null,
  element: {
    type: elementTypes.LINK,
    data: { href: '' },
    children: [],
  },
};

export default memo(Link);
