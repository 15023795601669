import GET_NOTES from 'operations/queries/getNotes';
import rootQueryChecker from 'utils/rootQueryChecker';
import { getMemberQuery } from 'operations/queryVariables';

const getUpdatedList = (list, item) => {
  const updatedList = list;
  if (item.crudAction === 'REMOVE') {
    return updatedList.filter((note) => note.mRefId !== item.mRefId);
  }

  if (item.crudAction === 'INSERT') {
    const memberExist = updatedList.filter((m) => m.mId === item.mId && m.mRefId === item.mRefId);
    if (memberExist.length === 0) {
      const itemToAdd = item;
      itemToAdd.__typename = 'MemberType';
      updatedList.push(itemToAdd);
    }
  }

  return updatedList;
};

const updateCache = (proxy, item, query, variables) => {
  try {
    const isQueryInCache = rootQueryChecker.checkIfQueryInRootQuery(proxy, 'getNotes', [
      { key: 'mId', value: item.mId },
    ]);
    if (!isQueryInCache) {
      return;
    }

    const list = proxy.readQuery({
      query,
      variables,
    });

    // Write updated member list in the cache.
    proxy.writeQuery({
      query,
      variables,
      data: {
        getNotes: getUpdatedList([...list.getNotes], item),
      },
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

const updateStoryNotes = (proxy, item) => {
  try {
    const { crudAction } = item;
    if (crudAction === 'MODIFY') return;
    const variables = getMemberQuery(item.mId);
    updateCache(proxy, item, GET_NOTES, variables);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};

export default updateStoryNotes;
