import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Dialog from 'components/dialog';
import { DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import { ReactComponent as Add } from 'assets/icons/systemicons/add.svg';
import { ReactComponent as Close } from 'assets/icons/systemicons/close.svg';
import SplitPane from 'react-split-pane';
import { ReactComponent as DragHandle } from 'assets/icons/systemicons/unionDragHandle.svg';

export const NoFieldsMessage = styled.div`
  display: flex;
  height: 100%;
`;

export const CenteredText = styled.div`
  margin: auto;
`;

export const FieldsHeader = styled.div`
  ${({ theme }) => theme.typography.dina.overline};
`;

export const StyledSpan = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Remove = styled(Close)`
  cursor: pointer;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
`;

export const StyledClose = styled(Close)`
  opacity: 0;
  margin-top: -4px;
  &:hover {
    opacity: 1;
  }
`;

export const StyledDialogTitle = styled(DialogTitle)`
  ${({ theme }) => css`
    ${theme.typography.dina.h7};
    border-bottom: 1px solid ${theme.palette.dina.dividerLight};
  `}
  font-size: 14px !important;
  padding: 8px 8px 8px 12px !important;
`;

export const Input = styled.input`
  ${({ theme }) => theme.typography.dina.h6};
  background: transparent;
  border: none;
  outline: none;
  margin-left: 6px;
  flex: 1 1 auto;
  :focus {
    border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  }
`;

export const StyledDialogActions = styled(DialogActions)`
  height: 57px;
`;

export const StyledDialogContent = styled(DialogContent)`
  height: calc(100% - 106px);
`;

export const RootWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px;
`;

export const StyledSpanWrapper = styled.span`
  p {
    width: 100%;
  }
  li:hover .remove {
    opacity: 0.7;
  }
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 70px;
  align-items: center;
`;

export const SettingsContentWrapper = styled.div`
  width: 100%;
`;

export const FieldTopWrapper = styled.div`
  display: flex;
`;

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  li p {
    padding-left: 6px;
  }
`;

export const StyledDialog = styled(Dialog)`
  .MuiDialogContent-root {
    overflow: hidden;
  }
  .MuiPaper-root {
    max-width: initial;
  }
  .MuiDialogContent-root {
    padding: 12px;
  }
  .MuiDialogActions-root {
    border-top: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  }
`;

export const EditingWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const ContentWrapper = styled.div`
  height: 100%;
  display: flex;
`;

export const Split = styled(SplitPane)`
  .Resizer {
    box-sizing: border-box;
    background-clip: padding-box;
    z-index: 100;
    transition: all 150ms ease-in-out;
    :hover,
    :active {
      ${({ theme }) => css`
        background-color: ${theme.palette.dina.onFocus} !important;
        box-shadow: 0px 0px 0px 1px ${theme.palette.dina.onFocus};
      `}
    }
  }
`;

export const StyledConfirmButtonWrapper = styled.div`
  display: flex;
  width: 120px;
  margin-left: auto;
`;

export const StyledCancelButtonWrapper = styled.div`
  display: flex;
  width: 120px;
  margin-right: auto;
`;

export const StyledAdd = styled(Add)`
  cursor: pointer;
  position: relative;
  top: -6px;
  &:hover * {
    fill-opacity: 1;
  }
`;

export const Fields = styled.div`
  width: 220px;
  border-right: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

export const ViewWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

export const PayloadWrapper = styled.div`
  padding-left: 12px;
`;

export const Overline = styled.div`
  ${({ theme }) => theme.typography.dina.overline};
  margin-bottom: 4px;
`;

export const OptionWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  padding-bottom: 4px;
`;

export const StyledFieldWrapper = styled('div')`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  /* align-items: center; */
`;

export const StyledDragHandle = styled(DragHandle)`
  cursor: grab;
  :active {
    cursor: grabbing;
    path {
      fill-opacity: 1;
    }
  }
  :hover {
    path {
      fill-opacity: 1;
    }
  }
  position: relative;
  top: 8px;
`;

export default StyledDialogTitle;
