import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ReactComponent as CloseIcon } from 'assets/icons/systemicons/close.svg';
import Input from '@material-ui/core/OutlinedInput';

export const OutlinedInput = styled(Input)`
  &.MuiOutlinedInput-root {
    height: 32px;
    min-width: 200px;
    border-radius: 2px;
    border: 0.5px solid ${({ theme }) => theme.palette.dina.buttonBorderOutlined};
    :hover {
      ${({ theme }) => css`
        border: 0.5px solid ${theme.palette.dina.buttonBorderOutlined}; // why it is here
        background-color: ${theme.palette.dina.whiteHoverOverlay};
        filter: ${theme.palette.dina.onHover};
      `}
    }
  }
  .MuiOutlinedInput-input {
    ${({ theme }) => theme.typography.dina.listItemLabel};
  }
  &.MuiOutlinedInput-adornedStart {
    padding: 4px;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  &.Mui-focused {
    border: 1px solid ${({ theme }) => theme.palette.dina.onFocus};
    :hover {
      border: 1px solid ${({ theme }) => theme.palette.dina.onFocus};
    }
  }
`;

export const Close = styled(CloseIcon)`
  cursor: pointer;
`;
