import { gql } from 'graphql-tag';

export default gql`
  mutation updateHtmlPanel($input: UpdateMemberInput) {
    updateMember(input: $input) {
      mId
      mRefId
      mTitle
      mType
      mStoryId
      accessToken
      mMetaData {
        key
        value
      }
      mProperties {
        __typename
      }
      configs {
        key
        type
        name
        value
      }
      ttl
    }
  }
`;
