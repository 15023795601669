import gql from 'graphql-tag';

export default gql`
  query GetFolders {
    getFolders {
      mId
      mRefId
      mType
      mTitle
      mOrder
      mProperties {
        __typename
        ... on PlatformType {
          platform
          platformKind
          pinned
        }
      }
      items {
        mId
        mRefId
        mTitle
        mDescription
        mContentKey
      }
    }
  }
`;
