import React from 'react';
import Scrollbar from 'components/scrollbar';
import { MetadataList, ListItem, Key, Value } from './styled';

const Metadata = ({ asset }) => {
  const {
    __typename,
    uri,
    href,
    type,
    previewUri,
    thumbnailUri,
    videoPreviewUri,
    mTitle,
    mDescription,
    mContent,
    accessToken,
    byline,
    coverPhoto,
    date,
    mId,
    priority,
    mPublishedAt,
    rendition,
    section,
    assetTypes,
    thumbnail,
    ...cleanRendition
  } = Object.fromEntries(Object.entries(asset).filter(([_, v]) => v != null));

  const metadata = Object.entries(cleanRendition).map(([key, value]) => ({ key, value }));

  const listItems = metadata.map(({ key, value }) => (
    <ListItem key={key}>
      <Key>{`${key.charAt(0).toUpperCase() + key.slice(1)}:`}</Key>
      <Value>{value}</Value>
    </ListItem>
  ));

  return (
    <Scrollbar>
      <MetadataList>{listItems}</MetadataList>
    </Scrollbar>
  );
};

export default Metadata;
