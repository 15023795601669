/* eslint-disable sort-imports */
import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';

import { IntegrationType } from 'types/graphqlTypes';
import useLogger from 'utils/useLogger';

import { getErrorMessage, STANDARD_ACTION_ADMIN_KEYS } from '../commonKeys';

import { UpdateIntegrationInput, writeIntegrationToCache } from './useCreateIntegration';

const UPDATE_ACTION = gql`
  mutation UpdateAction($input: UpdateIntegrationInput) {
    updateIntegration(input: $input) {
      ${STANDARD_ACTION_ADMIN_KEYS}
    }
  }
`;

interface UpdateIntegration {
  updateIntegration: IntegrationType;
}

interface Input {
  input: UpdateIntegrationInput;
}

export const useUpdateIntegration = () => {
  const [updateMutation] = useMutation<UpdateIntegration, Input>(UPDATE_ACTION);
  const logger = useLogger('UpdateAction');
  const updateIntegration = useCallback(
    async (input: UpdateIntegrationInput) => {
      try {
        const result = await updateMutation({
          variables: {
            input,
          },
          update: (proxy, mutationResult) => {
            const updatedIntegration = mutationResult.data?.updateIntegration;
            if (updatedIntegration) {
              writeIntegrationToCache(proxy, updatedIntegration, input.integrationType);
            }
          },
        });
        return result?.data?.updateIntegration;
      } catch (err) {
        logger.log(getErrorMessage(err, input));
      }
    },
    [updateMutation],
  );
  return { updateIntegration };
};
