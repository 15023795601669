import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Chip from '@material-ui/core/Chip';
import { ReactComponent as UpArrow } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_up.svg';
import transientOptions from 'theme/helpers';

export const MaterialChip = styled(Chip, transientOptions)`
  &.MuiChip-root {
    border-radius: ${(props) => (props.$isPopulated ? '4px' : '6px')};
    flex-grow: ${(props) => (props.$fullWidth ? 1 : 0)};
  }
  &.MuiChip-outlined {
    background-color: ${({ $isPopulated, theme }) =>
      $isPopulated ? 'rgba(255, 152, 0, 0.1)' : theme.palette.dina.blackHoverOverlay};
    border: ${({ $isPopulated, theme }) =>
      `0.5px solid ${theme.palette.dina[$isPopulated ? 'statusWarning' : 'buttonBorderOutlined']}`};
    :hover {
      background-color: ${({ $isPopulated, theme }) =>
        $isPopulated ? 'rgba(255, 152, 0, 0.15)' : theme.palette.dina.statusOnHover};
      filter: ${({ theme }) => theme.palette.dina.onHover};
    }
  }
  .MuiChip-icon {
    margin-right: -10px;
  }
  .MuiChip-label {
    ${({ $isPopulated, theme }) => css`
      ${theme.typography.dina.listItemLabel};
      color: ${theme.palette.dina[$isPopulated ? 'highEmphasis' : 'mediumEmphasis']};
    `}
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    padding: 0 6px 0 12px;
    text-overflow: ellipsis;
  }
  .selectedIcon {
    path {
      fill: ${({ theme }) => theme.palette.dina.statusWarning};
      fill-opacity: 1;
    }
  }
`;

export const ArrowIcon = styled(UpArrow)`
  path {
    fill: ${({ theme }) => theme.palette.dina.highEmphasis};
    fill-opacity: 0.12;
  }
`;

export const CountText = styled('div')`
  ${({ $isPopulated, theme }) => css`
    ${theme.typography.dina.caption};
    color: ${theme.palette.dina[$isPopulated ? 'statusWarning' : 'disabled']};
  `}
  line-height: 14px;
`;
