import React, { useState, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import fieldEnums from 'utils/constants/fieldEnums';
import useGetFieldsForBlock from 'hooks/useGetFieldsForBlock';
import AutoCompleteComponent from 'components/autoCompleteBase';
import ChipGroup from 'components/editor/components/tags/components/chipGroup';
import { checkIfString } from 'components/editor/components/tags/utils/getContent';

const AutoCompleteBase = memo(AutoCompleteComponent);

const AutoComplete = ({ destinations, readOnly, updateDestinations }) => {
  const [getFieldsForBlock] = useGetFieldsForBlock();
  const field = getFieldsForBlock(fieldEnums.NOTIFICATIONS_DESTINATIONS, { options: [] });
  const options = field?.options || [];

  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const handleChange = useCallback(
    (event, newValue) => {
      event.preventDefault();
      /** to filter out unnecessary fields i.e. __typename and title */
      const newDestination = checkIfString(newValue)
        ? { id: newValue, value: newValue }
        : { id: newValue.id, value: newValue.value };

      if (newDestination) {
        const isIncluded = destinations.find((opt) => opt.value === newDestination.value);

        if (!isIncluded) {
          const updatedDestinations = [...destinations, newDestination];
          updateDestinations(updatedDestinations);
        }
        setInputValue('');
        setValue(null);
      }
    },
    [destinations, updateDestinations],
  );

  const removeDestination = useCallback(
    (event, index) => {
      event.preventDefault();
      const updatedDestinations = destinations.filter((_, pos) => pos !== index);
      updateDestinations(updatedDestinations);
    },
    [destinations, updateDestinations],
  );

  const sortedOptions = [...options].sort((a, b) => a.value.localeCompare(b.value));

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    getClearProps,
    groupedOptions,
    getPopupIndicatorProps,
    popupOpen,
  } = useAutocomplete({
    id: 'notification-box',
    options: sortedOptions,
    autoHighlight: true,
    getOptionLabel: (option) => (checkIfString(option) ? option : option.value),
    value,
    onChange: handleChange,
    inputValue,
    onInputChange: (_, newValue) => setInputValue(newValue),
  });

  return (
    <>
      <AutoCompleteBase
        content={destinations}
        readOnly={readOnly}
        value={inputValue}
        placeholder="Start typing to find destinations..."
        {...{
          getRootProps,
          getInputProps,
          getListboxProps,
          getOptionProps,
          getClearProps,
          groupedOptions,
          getPopupIndicatorProps,
          popupOpen,
        }}
        listWidthOffset={200}
      />
      <ChipGroup list={destinations} removeChip={removeDestination} />
    </>
  );
};

AutoComplete.propTypes = {
  /** a list of selected destinations */
  destinations: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.string,
      id: PropTypes.string,
    }),
  ),
  /** boolean that indicates a readOnly component */
  readOnly: PropTypes.bool,
  /** callback to update destination */
  updateDestinations: PropTypes.func,
};

AutoComplete.defaultProps = {
  destinations: [],
  readOnly: false,
  updateDestinations: () => {},
};

export default memo(AutoComplete);
