import getTimeString from 'utils/getTimeString';

const SECOND_REGEX = /^([0-5]?[0-9])$/;
const MINUTE_SECOND_REGEX = /^([0-5]?[0-9])([0-5][0-9])$/;
const HOUR_MINUTE_SECOND_REGEX = /^([0-1]?[0-9]|2[0-3])([0-5][0-9])([0-5][0-9])$/;
const NUMBER_WITH_S_REGEX = /^\d+[s|S]$/;
const NUMBER_WITH_M_REGEX = /^\d+[m|M]$/;
const NUMBER_WITH_H_REGEX = /^\d+[h|h]$/;
const NUMBER_WITH_MS_REGEX = /^([0-5]?[0-9])[M|m]([0-5]?[0-9])[S|s]$/;
const NUMBER_WITH_HMS_REGEX = /^([0-1]?[0-9]|2[0-3])[H|h]([0-5]?[0-9])[M|m]([0-5]?[0-9])[S|s]$/;
const NUMBER_MS_WITH_CHARACTER_REGEX = /^([0-5]?[0-9])[.|,|:|;|'|"|?|\-|_|!|/|M|m]([0-5]?[0-9])$/;
// eslint-disable-next-line max-len
const NUMBER_HMS_WITH_CHARACTER_REGEX = /^([0-1]?[0-9]|2[0-3])[.|,|:|;|'|"|?|\-|_|!|/|H|h]([0-5]?[0-9])[.|,|:|;|'|"|?|\-|_|!|/|M|m]([0-5]?[0-9])$/;

const HMS_SPLIT_REGEX = /[H|h|M|m|S|s]/;
const CHARACTER_SPLIT_REGEX = /[.|,|:|;|'|"|?|\-|_|!|/|M|m|H|h]/;

const isError = false;

const MS_IN_MINUTES = 60 * 1000;
const MS_IN_HOUR = 60 * 60 * 1000;
const MS_IN_SECOND = 1000;

const convertIntoSeconds = (input, multiplier) => {
  const convertedNumber = Number(input);
  return convertedNumber * multiplier;
};

const getValue = (list = [], preserveHours = false) => {
  switch (list.length) {
    case 3: {
      const durationFromHours = convertIntoSeconds(list[0], MS_IN_HOUR);
      const durationFromMinutes = convertIntoSeconds(list[1], MS_IN_MINUTES);
      const durationFromSeconds = convertIntoSeconds(list[2], MS_IN_SECOND);
      const totalDuration = durationFromHours + durationFromMinutes + durationFromSeconds;
      const value = getTimeString(totalDuration, { preserveSign: false, preserveHours });
      return value;
    }
    case 2: {
      const durationFromMinutes = convertIntoSeconds(list[0], MS_IN_MINUTES);
      const durationFromSeconds = convertIntoSeconds(list[1], MS_IN_SECOND);
      const totalDuration = durationFromMinutes + durationFromSeconds;
      const value = getTimeString(totalDuration, { preserveSign: false, preserveHours });
      return value;
    }
    case 1: {
      const durationFromSeconds = convertIntoSeconds(list[0], MS_IN_SECOND);
      const totalDuration = durationFromSeconds;
      const value = getTimeString(totalDuration, { preserveSign: false, preserveHours });
      return value;
    }
    default:
      return preserveHours ? '00:00:00' : '00:00';
  }
};

/** this function verify & converts the input string as hh:mm:ss string
 * @param  {string} input
 */
const convertInputStringToTimeString = (input, preserveHours = false) => {
  const trimmedInput = input.trim();

  if (SECOND_REGEX.test(trimmedInput) || trimmedInput === '60') {
    return { value: getValue([trimmedInput], preserveHours), isError };
  }

  if (MINUTE_SECOND_REGEX.test(trimmedInput)) {
    const convertedString = trimmedInput.length % 2 !== 0 ? `0${trimmedInput}` : trimmedInput;
    const list = convertedString.match(/.{1,2}/g);
    return { value: getValue(list, preserveHours), isError };
  }

  if (HOUR_MINUTE_SECOND_REGEX.test(trimmedInput)) {
    const convertedString = trimmedInput.length % 2 !== 0 ? `0${trimmedInput}` : trimmedInput;
    const list = convertedString.match(/.{1,2}/g);
    return { value: getValue(list, preserveHours), isError };
  }

  if (NUMBER_WITH_S_REGEX.test(trimmedInput)) {
    const slicedString = trimmedInput.slice(0, -1);
    return { value: getValue([slicedString], preserveHours), isError };
  }

  if (NUMBER_WITH_M_REGEX.test(trimmedInput)) {
    const slicedString = trimmedInput.slice(0, -1);
    return { value: getValue([slicedString, 0], preserveHours), isError };
  }

  if (NUMBER_WITH_H_REGEX.test(trimmedInput)) {
    const slicedString = trimmedInput.slice(0, -1);
    return { value: getValue([slicedString, 0, 0], preserveHours), isError };
  }

  if (NUMBER_WITH_MS_REGEX.test(trimmedInput)) {
    const list = trimmedInput.split(HMS_SPLIT_REGEX).filter((item) => item !== '');
    return { value: getValue(list, preserveHours), isError };
  }

  if (NUMBER_WITH_HMS_REGEX.test(trimmedInput)) {
    const list = trimmedInput.split(HMS_SPLIT_REGEX);
    return { value: getValue(list, preserveHours), isError };
  }

  if (NUMBER_MS_WITH_CHARACTER_REGEX.test(trimmedInput)) {
    const list = trimmedInput.split(CHARACTER_SPLIT_REGEX);
    return { value: getValue(list, preserveHours), isError };
  }

  if (NUMBER_HMS_WITH_CHARACTER_REGEX.test(trimmedInput)) {
    const list = trimmedInput.split(CHARACTER_SPLIT_REGEX);
    return { value: getValue(list, preserveHours), isError };
  }

  return { value: preserveHours ? '00:00:00' : '00:00', isError: true };
};

export default convertInputStringToTimeString;
