import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import { ReactComponent as CloseIconComponent } from 'assets/icons/systemicons/close.svg';
import { Button } from '@material-ui/core';

export const RootWrapper = styled('div')`
  position: relative;
`;

export const ContentWrapper = styled('div')`
  ${(props) => (props.showHeader ? { paddingTop: '32px', paddingBottom: '16px' } : '')}
`;

export const Header = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  height: 32px;
  width: 100%;
  display: flex;
  padding-left: 8px;
  padding-right: 8px;
  user-select: none;
  pointer-events: none;
`;

export const ThumbnailWrapper = styled('div')`
  width: 32px;
  height: 32px;
  position: relative;
  margin-right: 8px;
`;

export const ThumbnailImage = styled('img')`
  width: 26px;
  height: 26px;
  border-radius: 2px;
  object-fit: cover;
`;

export const PublishingPointImage = styled('img')`
  width: 22px;
  height: 22px;
  position: absolute;
  right: 0;
  bottom: 0;
`;

export const ThreadWrapper = styled('div')`
  width: 100%;
  height: 100%;
`;

export const ThreadHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  height: 31px;
  align-items: center;
`;

export const ThreadLabel = styled('p')`
  ${({ theme }) => theme.typography.dina.overline};
  margin: 0;
`;

export const CloseIcon = styled(CloseIconComponent)`
  cursor: pointer;
  pointer-events: ${(props) => (props.readOnly ? 'none' : 'auto')};
`;

export const ConfirmButton = styled(Button)`
  ${({ theme }) => css`
    ${theme.typography.dina.caption};
    color: ${theme.palette.dina.highEmphasis};
    background-color: ${theme.palette.dina.statusError};
  `}
  text-transform: none;
  padding: 0px;
  height: 24px;
  width: 144px;
  pointer-events: ${(props) => (props.readOnly ? 'none' : 'auto')};
  :hover {
    ${({ theme }) => css`
      filter: ${theme.palette.dina.onHover};
      -webkit-filter: ${theme.palette.dina.onHover};
      background-color: ${theme.palette.dina.statusError};
    `}
  }
`;
