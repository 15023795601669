import { useCallback } from 'react';

import { ReactComponent as CloseIcon } from 'assets/icons/systemicons/close.svg';
import Avatar, { AvatarVariant } from 'components/avatar/Avatar';
import { IconButton } from 'components/buttons';
import { TextVariants } from 'types';

import { ChipLabel, ChipWrapper } from './styled';

interface ChipProps {
  avatarKey?: string;
  variant?: AvatarVariant;
  onDelete?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  height?: string | number;
  width?: string | number;
  label?: string;
  showAvatar?: boolean;
  onChipClick?: () => void;
  CustomAvatar?: React.ReactElement;
  disabled?: boolean;
  labelType?: keyof TextVariants;
}

const Chip = ({
  avatarKey = '',
  variant = 'user',
  onDelete,
  height = 32,
  width = 128,
  label = '',
  showAvatar = false,
  onChipClick = () => {},
  CustomAvatar,
  disabled,
  labelType = 'chipLabel',
  ...rest
}: ChipProps) => {
  const handleDelete = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      onDelete?.(event);
    },
    [onDelete],
  );

  return (
    <ChipWrapper
      $height={height}
      $width={width}
      onClick={onChipClick}
      disabled={disabled}
      {...rest}
    >
      {showAvatar &&
        (CustomAvatar ?? <Avatar imageKey={avatarKey} size={24} variant={variant} title={label} />)}
      <ChipLabel variant={labelType}>{label}</ChipLabel>
      {onDelete && !disabled && (
        <IconButton onClick={handleDelete} usage="text" size={24} iconSize={18}>
          <CloseIcon />
        </IconButton>
      )}
    </ChipWrapper>
  );
};

export default Chip;
