import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  tabWrapper: {
    height: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 8,
    marginRight: 8,
    pointerEvents: ({ writeLock, readLock }) => (!writeLock || readLock ? 'none' : 'auto'),
  },
  embedContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  embedPlaceholder: {
    height: 400,
    width: '100%',
    position: 'relative',
  },
  youtubeIframe: {
    width: '100%',
    height: ({ height }) => height ?? '100%',
  },
  facebookIframeContainer: {
    height: ({ height }) => height ?? '100%',
    width: '100%',
    padding: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  facebookIframe: {
    width: '100%',
    height: '100%',
    borderRadius: 8,
    background: 'white',
  },
  instaIframeContainer: {
    height: ({ height }) => height ?? '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 16,
  },
  instagramIframe: {
    height: '100%',
    borderRadius: '8px',
  },
  noEmbedContainer: {
    height: ({ height }) => height ?? '100%',
  },
}));

export default useStyles;
