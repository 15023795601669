import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    width: 430,
  },
  dialogTitle: {
    ...theme.typography.dina.body2,
  },
  body: {
    height: '80px',
    overflow: 'hidden',
  },
  checkboxContainer: {
    width: '388px',
    height: '24px',
  },
  checkBoxText: {
    ...theme.typography.dina.caption,
    lineHeight: '14px',
    fontSize: '12px',
    letterSpacing: '0.25px',
    marginTop: '7px',
    marginLeft: '7px',
    width: '100%',
    height: '24px',
    color: theme.palette.dina.highEmphasis,
  },
  labelText: {
    ...theme.typography.dina.caption,
    lineHeight: '14px',
    fontSize: '12px',
    fontStyle: 'italic',
    letterSpacing: '0.25px',
    marginTop: '7px',
    width: '100%',
    height: '24px',
    color: theme.palette.dina.mediumEmphasis,
  },
  labelTextContainer: {
    height: '24px',
    marginLeft: '20px',
  },
}));

export default useStyles;
