import { ReactEditor } from 'slate-react';
import { selectElement } from 'components/editor/utils';

/**
 *
 * @param {Object} editor SlateJS editor instance
 * @param {Object} element SlateJS element selected
 * @return {undefined}
 */
const refreshSelection = (editor, element) => {
  if (!editor || !element) return;

  if (editor.selection) {
    ReactEditor.deselect(editor);
  }
  selectElement(editor, element);
};

export default refreshSelection;
