import { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

import configCtx from 'contexts/configContext';
// eslint-disable-next-line sort-imports
import { MemberType, MMetaDataField } from 'types/graphqlTypes';
import { defaultSettings } from 'utils/settings/mergeSettings';

const UPDATE_SETTINGS = gql`
  mutation updateSettings($input: UpdateSettingsInput) {
    updateSettings(input: $input) {
      metadata
    }
  }
`;

interface DataMember {
  updateSettings: MemberType;
}

interface QueryResult {
  data: DataMember;
}

interface Settings {
  [x: string]: string;
}

interface UpdateSettingsInput {
  mId: string;
  mRefId: string;
  mMetaData: MMetaDataField[];
}

const useUpdateSettings = () => {
  const [updateSettings] = useMutation(UPDATE_SETTINGS);

  const config = useContext(configCtx);

  const updateSystemSettings = async ({
    mId = 'settings',
    mRefId = 'general',
    mMetaData = [],
  }: UpdateSettingsInput) => {
    const promise = (await updateSettings({
      variables: {
        input: {
          mId,
          mRefId,
          mMetaData,
        },
      },
    })) as QueryResult;

    const result = promise?.data?.updateSettings;
    const metadataString = result.metadata || '{}';

    const updatedSettings = JSON.parse(metadataString) as Settings;

    const mergedSettings = { ...defaultSettings, ...updatedSettings } as Settings;

    config.settings = [mergedSettings];
  };

  return updateSystemSettings;
};

export default useUpdateSettings;
