import styled from '@emotion/styled';

export const RootWrapper = styled('div')`
  width: 100%;
  height: calc(100% - 95px);
`;

export const ErrorWrapper = styled('div')`
  height: 100px;
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
  display: flex;
  align-items: center;
  justify-content: center;
`;
