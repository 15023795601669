/* eslint-disable max-len */
import { Rect, Svg } from '@react-pdf/renderer';

const CheckboxOff = () => {
  return (
    <Svg style={{ width: 20, height: 20, marginRight: 4 }} viewBox="0 0 24 24">
      <Rect x="3.5" y="3.5" width="17" height="17" rx="1.5" stroke="#0F141A" strokeOpacity="0.7" />
    </Svg>
  );
};

export default CheckboxOff;
