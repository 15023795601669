import { useCallback } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

import { removeOrderFromCache } from 'screens/space/api/useGetOrdersAndForms';
import { Order } from 'types/forms/forms';

export type OrderDeleteKeys = Pick<Order, 'mId' | 'mResourceId'>;
interface DeleteOrder {
  deleteOrder: null;
}

const DELETE_MEMBER = gql`
  mutation DeleteOrder($input: DeleteOrderInput) {
    deleteOrder(input: $input) {
      mId
      mResourceId
    }
  }
`;

const useDeleteOrder = () => {
  const client = useApolloClient();
  const [deleteMember] = useMutation<DeleteOrder>(DELETE_MEMBER);

  const deleteOrderFn = useCallback(async (keys: OrderDeleteKeys) => {
    await deleteMember({
      variables: { input: keys },
    });
    removeOrderFromCache(client, keys.mResourceId, keys.mId);
  }, []);

  return { deleteOrder: deleteOrderFn };
};

export default useDeleteOrder;
