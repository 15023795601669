import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ReactComponent as FeedsIcon } from 'assets/icons/systemicons/HeaderNavbar/feeds_off.svg';
import useChangeCollapse from 'components/editor/hooks/useChangeCollapse';
import { elementTypes } from 'components/editor/constants/types';
import HtmlViewer from './components/viewer';
import Box from '../box';
import DragAndDrop from '../dragAndDrop';

export const Icon = styled(FeedsIcon)`
  margin: 8px;
`;

const FeedBlock = ({ attributes, children, element }) => {
  const { data } = element;

  const [onChangeCollapse] = useChangeCollapse(element);

  const { document, source, collapsed = false } = data || {};

  const renderHtmlBlock = useMemo(
    () => (
      <Box
        iconComponent={<Icon className="skipOverride" />}
        title={`Feed item from ${source}`}
        updateCollapsed={onChangeCollapse}
        collapsed={collapsed}
        collapsedContent={source}
        hideEllipsisButton
        type="feed"
      >
        <HtmlViewer document={document} />
      </Box>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [collapsed, onChangeCollapse],
  );

  return (
    <div {...attributes}>
      <DragAndDrop element={element}>
        {children}
        {renderHtmlBlock}
      </DragAndDrop>
    </div>
  );
};

FeedBlock.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

FeedBlock.defaultProps = {
  attributes: {},
  children: null,
  element: {
    children: [],
    data: {},
    type: elementTypes.FEED,
  },
};

export default memo(FeedBlock);
