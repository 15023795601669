import type { ColumnDefTemplate, Header, HeaderContext, Row } from '@tanstack/react-table';

import type { DinaTable } from '../types';

/** Check if the event is a win ctrl or mac meta key */
const isCmdEvent = (event: React.KeyboardEvent<HTMLTableCellElement>) => {
  return (
    (event.ctrlKey && navigator.userAgent.toLowerCase().includes('win')) ||
    (event.metaKey && navigator.userAgent.toLowerCase().includes('mac'))
  );
};

interface KeyboardShortcutsProps<T> {
  cellElements?: Array<HTMLTableCellElement>;
  header?: Header<T, unknown>;
  cellValue?: ColumnDefTemplate<HeaderContext<T, unknown>>;
  containerElement?: HTMLTableElement;
  event: React.KeyboardEvent<HTMLTableCellElement>;
  parentElement?: HTMLTableRowElement;
  table: DinaTable<T>;
  row?: Row<T>;
}

const navigationKeys = [
  'ArrowRight',
  'ArrowLeft',
  'ArrowUp',
  'ArrowDown',
  'Home',
  'End',
  'PageUp',
  'PageDown',
];

/** Keyboard navigation for cells */
export function cellKeyboardShortcuts<T>({
  cellElements,
  header,
  containerElement,
  event,
  parentElement,
  table,
  row,
}: Readonly<KeyboardShortcutsProps<T>>) {
  if (!table.options.enableKeyboardShortcuts) return;

  // Current cell, or the cell you navigate away from if event = arrow key press.
  const currentCell = event.currentTarget;

  const assignButtonCell = currentCell.querySelector('div[id="assign-button"]');
  const inputCellChild = currentCell.querySelector('input');
  const selectCellChild = currentCell?.querySelector('select');

  if (['Enter'].includes(event.key)) {
    if (assignButtonCell) {
      (assignButtonCell as HTMLDivElement).click();
    } else if (header?.column?.getCanSort()) {
      event.preventDefault();
      header.column.toggleSorting();
    } else if (row?.getCanExpand()) {
      row.toggleExpanded();
    } else if (inputCellChild) {
      // Focus / blur input field.
      if (document.activeElement !== inputCellChild) {
        inputCellChild.focus();
      } else {
        inputCellChild.blur();
        currentCell.focus();
      }
    } else if (selectCellChild) {
      // Focus / blur select field.
      if (document.activeElement !== selectCellChild) {
        // open select
        selectCellChild.focus();
      } else {
        selectCellChild.blur();
        currentCell.focus();
      }
    }
  } else if (['Escape'].includes(event.key)) {
    currentCell.focus();
  } else if (navigationKeys.includes(event.key)) {
    event.preventDefault();

    const currentRow = parentElement ?? currentCell.closest('tr');
    const tableElement = containerElement ?? currentCell.closest('table');
    const allCells = cellElements || Array.from(tableElement?.querySelectorAll('th, td') || []);
    const currentCellIndex = allCells.indexOf(currentCell);

    const currentIndex = parseInt(currentCell.getAttribute('data-index') ?? '0');
    let nextCell: HTMLElement | undefined = undefined;

    // Find first or last cell in row
    const findEdgeCell = (rowIndex: 'c' | 'f' | 'l', edge: 'f' | 'l') => {
      const rowElement =
        rowIndex === 'c'
          ? currentRow
          : rowIndex === 'f'
          ? tableElement?.querySelector('tr')
          : tableElement?.lastElementChild?.lastElementChild;
      const rowCells = Array.from(rowElement?.children || []);
      const targetCell = edge === 'f' ? rowCells[0] : rowCells[rowCells.length - 1];
      return targetCell as HTMLElement;
    };

    // Find top or bottom cell in column
    const findBottomTopCell = (columnIndex: number, edge: 'b' | 't') => {
      const rowElement =
        edge === 't'
          ? tableElement?.querySelector('tr')
          : tableElement?.lastElementChild?.lastElementChild;
      const rowCells = Array.from(rowElement?.children || []);
      const targetCell = rowCells[columnIndex];
      return targetCell as HTMLElement;
    };

    const findAdjacentCell = (columnIndex: number, searchDirection: 'f' | 'b') => {
      const searchArray =
        searchDirection === 'f'
          ? allCells.slice(currentCellIndex + 1)
          : allCells.slice(0, currentCellIndex).reverse();
      return searchArray.find((cell) => cell.matches(`[data-index="${columnIndex}"]`)) as
        | HTMLElement
        | undefined;
    };

    switch (event.key) {
      case 'ArrowRight':
        nextCell = findAdjacentCell(currentIndex + 1, 'f');
        break;
      case 'ArrowLeft':
        nextCell = findAdjacentCell(currentIndex - 1, 'b');
        break;
      case 'ArrowUp':
        nextCell = findAdjacentCell(currentIndex, 'b');
        break;
      case 'ArrowDown':
        nextCell = findAdjacentCell(currentIndex, 'f');
        break;
      case 'Home':
        nextCell = findEdgeCell(isCmdEvent(event) ? 'f' : 'c', 'f');
        break;
      case 'End':
        nextCell = findEdgeCell(isCmdEvent(event) ? 'l' : 'c', 'l');
        break;
      case 'PageUp':
        nextCell = findBottomTopCell(currentIndex, 't');
        break;
      case 'PageDown':
        nextCell = findBottomTopCell(currentIndex, 'b');
        break;
    }

    if (nextCell) {
      nextCell.focus();
    }
  }
}
