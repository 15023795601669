import gql from 'graphql-tag';

export default gql`
  mutation updateGroup($input: UpdateMemberInput) {
    updateMember(input: $input) {
      mId
      mRefId
      mTitle
    }
  }
`;
