export type GroupType = {
  value: string;
  label: string;
};

export type AccessType = {
  read: boolean;
  write: boolean;
};

// Helper function to update permissions array.
export const updatePermissionArray = (
  groupId: string,
  permissionArray: string[],
  shouldAdd: boolean,
  shouldRemove: boolean,
): string[] => {
  if (shouldRemove) {
    return permissionArray.filter((val) => val !== groupId);
  }

  if (shouldAdd) {
    return [...permissionArray, groupId];
  }

  return permissionArray;
};
