export const checkIfString = (val) => typeof val === 'string';

const normalizeContent = (content) => {
  if (!content) return [];
  return content.split(',').map((val) => {
    const trimmedValue = val.trim();
    return { id: trimmedValue, value: trimmedValue };
  });
};

const getContent = (content) => (checkIfString(content) ? normalizeContent(content) : content);

export default getContent;
