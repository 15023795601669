import { useContext, useMemo } from 'react';
import { keyBy } from 'lodash';

import configCtx from 'contexts/configContext';
import { InstanceStateType } from 'types/graphqlTypes';

const useGetStatuses = () => {
  const { kanbanBoardStates: instanceStatuses } = useContext(configCtx) as unknown as {
    kanbanBoardStates: InstanceStateType[]; // Fix when configCtx is typed
  };
  const instanceStatusesById: Record<string, InstanceStateType> = useMemo(() => {
    return keyBy(instanceStatuses, (state) => state.id);
  }, [instanceStatuses]);
  return { instanceStatusesById, instanceStatuses };
};

export default useGetStatuses;
