import { ReactComponent as ScheduleOff } from 'assets/icons/systemicons/storyCard/scheduled_off.svg';
import { ReactComponent as ScheduleOn } from 'assets/icons/systemicons/storyCard/scheduled_on.svg';
import Assignees from 'components/assignees/Assignees';
import Text from 'components/text/Text';
import Tooltip from 'components/tooltip';
import PersistentTheme from 'theme/persistentTheme';
import { AssignedMember } from 'types';
import { isoToLocaleShort } from 'utils/datetimeHelpers';

import Thumbnail from './Thumbnail';

import {
  AssigneesWrapper,
  Body,
  BookmarkOffIcon,
  BookmarkOnIcon,
  BookmarkWrapper,
  Container,
  Details,
  EllipsisIcon,
  Options,
  PadlockIcon,
  Schedule,
  ScheduleIcon,
  ScheduleText,
  ScheduleWrapper,
  Sizes,
  Timings,
  Title,
} from './styled';

interface StoryCardProps {
  selected?: boolean;
  size?: Sizes;
  hideThumbnail?: boolean;
  hideBottomBorder?: boolean;
  isPitch?: boolean;
  title: string;
  image?: string;
  scheduledAt?: {
    startDate: string;
    endDate: string;
  };
  updatedAt?: string;
  isRestricted?: boolean;
  assignedUsers?: AssignedMember[];
  isHovered?: boolean;
  isBookmarked?: boolean;
  enableUpdate?: boolean;
  onMoreClick?: () => void;
  onScheduleClick: () => void;
  onBookmarkClick: () => void;
}

interface PersistentThemeProps {
  readonly selected?: boolean;
  readonly children: React.ReactElement;
}

function PersistentThemeWrapper({ selected, children }: PersistentThemeProps) {
  if (selected) {
    return <PersistentTheme>{children}</PersistentTheme>;
  }
  return children;
}

function StoryCard({
  selected = false,
  size = 'default',
  hideThumbnail = false,
  hideBottomBorder = false,
  isPitch,
  title,
  image,
  scheduledAt,
  updatedAt,
  isRestricted,
  assignedUsers = [],
  isHovered,
  isBookmarked,
  enableUpdate,
  onMoreClick,
  onScheduleClick,
  onBookmarkClick,
}: Readonly<StoryCardProps>) {
  return (
    <PersistentThemeWrapper selected={selected}>
      <Container $size={size}>
        {!hideThumbnail && <Thumbnail src={image} size={size} isPitch={isPitch} />}
        <Body $hideBottomBorder={hideBottomBorder}>
          <Timings>
            <ScheduleWrapper>
              <Schedule onClick={onScheduleClick}>
                <ScheduleIcon>
                  {scheduledAt?.startDate ? (
                    <ScheduleOn className="skipOverride" />
                  ) : (
                    <ScheduleOff className="skipOverride" />
                  )}
                </ScheduleIcon>
                <ScheduleText variant="caption">
                  {scheduledAt?.startDate
                    ? isoToLocaleShort(scheduledAt?.startDate)
                    : 'Unscheduled'}
                  {scheduledAt?.startDate &&
                    scheduledAt?.endDate &&
                    ` - ${isoToLocaleShort(scheduledAt?.endDate)}`}
                </ScheduleText>
              </Schedule>
            </ScheduleWrapper>
            <Text variant="caption">{updatedAt}</Text>
          </Timings>
          <Details>
            <Title variant="listItemLabelMedium" color="highEmphasis">
              {title}
            </Title>
            <Options $hovered={!!isHovered}>
              {isRestricted && <PadlockIcon className="skipOverride" />}
              <Tooltip title={`${isBookmarked ? 'Remove from' : 'Add to'} bookmarks`}>
                <BookmarkWrapper onClick={onBookmarkClick} role="presentation">
                  {isBookmarked ? (
                    <BookmarkOnIcon className="skipOverride" />
                  ) : (
                    <BookmarkOffIcon className="skipOverride" />
                  )}
                </BookmarkWrapper>
              </Tooltip>
              <AssigneesWrapper>
                <Assignees members={assignedUsers} size={20} maxAvatarToShow={2} />
              </AssigneesWrapper>
              {enableUpdate && onMoreClick && (
                <EllipsisIcon className="skipOverride" onClick={onMoreClick} />
              )}
            </Options>
          </Details>
        </Body>
      </Container>
    </PersistentThemeWrapper>
  );
}

export default StoryCard;
