import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, FormControlLabel } from '@material-ui/core';
import Divider from 'components/divider';
import Checkbox from 'components/checkbox';
import useStyles from './deleteInstance-styles';

const DeleteInstance = (props) => {
  const {
    onCancel,
    onOk,
    isDeleteEnabled,
    checkBoxText,
    warningText,
    labelText,
    disabledText,
  } = props;
  const rootRef = useRef(null);
  const [isSelected, setIsSelected] = useState(false);
  const classes = useStyles();

  const handleOk = () => {
    onOk();
  };

  const toggleSelect = () => {
    setIsSelected(!isSelected);
  };

  return (
    <div className={classes.root} ref={rootRef}>
      <div className={classes.header}>
        <Typography classes={{ root: classes.headerText }}>{labelText}</Typography>
      </div>
      <div className={classes.dividerTop}>
        <Divider />
      </div>
      <div className={classes.body}>
        {!isDeleteEnabled ? (
          <div className={classes.checkboxContainer}>
            <FormControlLabel
              value="sendMessage"
              control={
                <Checkbox
                  value="isSelected"
                  selected={isSelected}
                  onClick={toggleSelect}
                  size={24}
                />
              }
              label={checkBoxText}
              classes={{
                label: classes.checkBoxText,
                root: classes.checkbox,
              }}
            />

            <div className={classes.labelTextContainer}>
              <Typography classes={{ root: classes.labelText }}>{warningText}</Typography>
            </div>
          </div>
        ) : (
          <div className={classes.checkboxContainer}>
            <Typography classes={{ root: classes.labelText }}>{disabledText}</Typography>
          </div>
        )}
      </div>
      <div className={classes.dividerBottom}>
        <Divider />
      </div>
      <div className={classes.buttonContainer}>
        <Button
          classes={{ root: classes.createButton, label: classes.okButtonLabel }}
          onClick={handleOk}
          disabled={isSelected === false || isDeleteEnabled}
        >
          Delete
        </Button>
        <Button
          classes={{ root: classes.cancelButton, label: classes.cancelButtonLabel }}
          onClick={onCancel}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

DeleteInstance.propTypes = {
  /** Callback to be invoked when cancel button is clicked */
  onCancel: PropTypes.func,
  /** Callback to be invoked when create button is clicked */
  onOk: PropTypes.func,
  /** text to show beside checkbox icon   */
  checkBoxText: PropTypes.string,
  /** text about the consequence of the action */
  warningText: PropTypes.string,
  /** label of the popover */
  labelText: PropTypes.string,
  /** text to show when delete is disabled */
  disabledText: PropTypes.string,
};

DeleteInstance.defaultProps = {
  onCancel: () => {},
  onOk: () => {},
  checkBoxText: 'Check this box to confirm you want to delete the Instance',
  warningText: 'Once deleted, the Instance cannot be restored',
  labelText: 'Delete this Instance?',
  disabledText: 'This instance is scheduled. You can not delete already scheduled instances.',
};

export default DeleteInstance;
