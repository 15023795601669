import React, { useContext, useEffect, useMemo, useState } from 'react';
import { capitalize } from 'lodash';

import { ReactComponent as CloseIcon } from 'assets/icons/systemicons/close.svg';
import { Button } from 'components/buttons';
import CheckBox from 'components/checkbox';
import QuickSearch from 'components/command/QuickSearch';
import AssignMembers from 'components/createNewV3/assignMember';
import { LoadingButtonIndicator } from 'components/loadingIndicator';
import { DateRange } from 'components/mdfEditor/fields/date/DatePicker';
import { MdfEditor } from 'components/mdfEditor/MdfEditor';
import SplitBar from 'components/split';
import Tabs from 'components/tabs/contained';
import Text from 'components/text/Text';
import Tooltip from 'components/tooltip';
import UserCtx from 'contexts/UserContext';
import useCheckUserRight from 'hooks/useCheckUserRight';
import { CreateStoryInput } from 'hooks/useCreateStory';
import useMetadata from 'hooks/useMetadata';
import { Box } from 'layouts/box/Box';
import { AssignedMember } from 'types';
import { Metadata } from 'types/forms/forms';
import { Mdf, SearchItemTypeEnum } from 'types/graphqlTypes';

import DatePickerButton from './datePicker/scheduleDatePicker';

import {
  ButtonsWrapper,
  CheckBoxLabel,
  CheckBoxWithOptions,
  CheckBoxWrapper,
  CloseButton,
  ContentWrapper,
  Footer,
  Header,
  MetadataWrapper,
  RestrictedInfo,
  RestrictedText,
  WarningIcon,
  Wrapper,
} from './styled';

type TabValues = 'story' | 'pitch';

interface Tab {
  label: string;
  id: TabValues;
}

const tabs: Tab[] = [
  { label: 'Story', id: 'story' },
  { label: 'Pitch', id: 'pitch' },
];

const tabLabels = tabs.map((tab) => tab.label);

interface CheckboxWithLabelProps {
  selected: boolean;
  onClick: (val: boolean) => void;
  label: string;
  disabled?: boolean;
}

export const CheckboxWithLabel = ({
  selected,
  onClick,
  label,
  disabled,
}: CheckboxWithLabelProps) => (
  <CheckBoxWrapper onClick={() => onClick(!selected)} role="presentation">
    <CheckBox disabled={disabled} selected={selected} disableHoverEffect size={24} />
    <CheckBoxLabel disabled={disabled}>{label}</CheckBoxLabel>
  </CheckBoxWrapper>
);

interface CreateNewProps {
  disableTypeSwitch: boolean;
  canCreate: boolean;
  doCreate: (props: CreateStoryInput) => Promise<void>;
  onCancel: () => void;
  hideRestrictedOption: boolean;
  loading: boolean;
  setDimension: React.Dispatch<
    React.SetStateAction<{
      width: string;
      height: string;
    }>
  >;
  type: TabValues;
  setType: (val: TabValues) => void;
  mdf: Mdf | undefined;
  preselectedMember?: AssignedMember;
  preselectedMetadata?: {
    metadata: Metadata;
  };
  scheduleDate?: string;
  mTitle?: string;
  mContent?: string;
}

const pluralLabel: Record<TabValues, string> = {
  story: 'stories',
  pitch: 'pitches',
};

const CreateNew = ({
  disableTypeSwitch,
  canCreate,
  doCreate,
  onCancel,
  loading,
  hideRestrictedOption,
  setDimension,
  preselectedMember,
  preselectedMetadata,
  scheduleDate,
  mTitle,
  mContent,
  type,
  setType,
  mdf,
}: CreateNewProps) => {
  const [keepOpen, setKeepOpen] = useState(false);
  const [showCreated, setShowCreated] = useState(false);
  const [openStory, setOpenStory] = useState(true);
  const publishingTimes = { startDate: scheduleDate ?? new Date().toISOString(), endDate: null };

  const { metadata, errorMap, errorTooltip, updateFieldValues } = useMetadata(
    mdf,
    preselectedMetadata?.metadata,
    'story_create',
  );

  // type useContext/UserContext
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  const { attributes: user } = useContext(UserCtx) as unknown as { attributes: AssignedMember };
  const [title, setTitle] = useState('');
  const [restricted, setRestricted] = useState(false);
  const [scheduled, setScheduled] = useState<DateRange | null>(publishingTimes);
  const [submitting, setSubmitting] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState<AssignedMember[]>([
    ...(preselectedMember ? [preselectedMember] : []),
    { ...user, isCreator: true },
  ]);

  const [messageAssignees, setMessageAssignees] = useState('');
  const [checkUserRight] = useCheckUserRight();
  const canSelectRange = checkUserRight('feature', 'story-date-range');
  const canSelectMetadata = (mdf?.fields ?? []).length > 0;

  const titleErrorTooltip = !title?.trim() ? 'Title is required' : '';
  const disabledTooltip = !canCreate
    ? `You do not have permission to create ${pluralLabel[type]}`
    : '';

  const disableCreate = Boolean(errorTooltip ?? titleErrorTooltip ?? disabledTooltip);

  useEffect(() => {
    setDimension({ width: '400px', height: selectedMembers.length > 1 ? '480px' : '410px' });
    setTitle(mTitle?.trim() ?? '');
  }, []);

  const handleCreate = () => {
    if (submitting || !title.trim().length) return;
    setSubmitting(true);
    const { startDate, endDate } = scheduled ?? { startDate: undefined, endDate: undefined };
    const assignedMembers = selectedMembers.map(({ mId, mType }) => ({ mId, mType }));
    if (title)
      void doCreate({
        type,
        keepOpen,
        openStory,
        mId: user.mId,
        mCreatedById: user.mId,
        mTitle: title.trim(),
        isRestricted: restricted,
        mPublishingAt: startDate,
        mPublishingEnd: endDate ?? undefined,
        mAssignedMembers: assignedMembers,
        messageAssign: messageAssignees,
        metadata: JSON.stringify(metadata),
        mContent: mContent ?? undefined,
      }).finally(() => {
        setSubmitting(false);
        setShowCreated(true);
        setTimeout(() => {
          setShowCreated(false);
        }, 2000);
      });
  };

  const toggleRestriction = () => setRestricted((pre) => !pre);

  const onKeyDown = (ev: React.KeyboardEvent) => {
    if (ev.key === 'Enter' && !disableCreate) {
      handleCreate();
    }
  };

  const onTabChange = (label: string) => {
    const selectedTab = tabs.find((t) => t.label === label) as Tab;
    setType(selectedTab.id);
  };
  const activeTab = type === 'story' ? tabLabels[0] : tabLabels[1];

  const MainStoryLayout = useMemo(() => {
    return (
      <ContentWrapper>
        {!disableTypeSwitch && (
          <Tabs
            activeBackground={type === 'pitch' ? '#0BAA32' : undefined}
            tabs={tabLabels}
            setActiveTab={onTabChange}
            activeTab={activeTab}
            styleProps={{ fullWidth: true }}
          />
        )}
        <Text variant="overline">{capitalize(type)} title</Text>
        <QuickSearch
          autoFocus
          doPreviewOnSelect
          allowClear
          searchString={title}
          setSearchString={setTitle}
          onKeyDown={onKeyDown}
          titleErrorTooltip={titleErrorTooltip}
          toolbarState={{
            sortBy: 'createdAt',
            order: 'desc',
            mTypes: [SearchItemTypeEnum.pitch, SearchItemTypeEnum.story],
            rangeBy: null,
            statusFilter: [],
            mdfId: null,
            defaultMdfId: null,
            createdByIds: [],
            assignedIds: [],
            isFiltering: false,
          }}
        />
        <Text variant="overline">Schedule date</Text>
        <DatePickerButton
          dateRange={scheduled}
          onDateRangeChange={setScheduled}
          canSelectRange={canSelectRange}
          disableUpdate={false}
          fullWidth
        />
        {!hideRestrictedOption && (
          <CheckBoxWithOptions>
            <Tooltip
              title={
                <RestrictedInfo>
                  <WarningIcon className="skipOverride" />
                  <RestrictedText>
                    Only you and assigned members
                    <br /> can access this {type}.
                  </RestrictedText>
                </RestrictedInfo>
              }
            >
              <div style={{ width: '150px' }}>
                <CheckboxWithLabel
                  selected={restricted}
                  disabled={false}
                  onClick={toggleRestriction}
                  label="Restrict access"
                />
              </div>
            </Tooltip>
          </CheckBoxWithOptions>
        )}
        <CheckBoxWithOptions>
          <Text variant="overline">Members</Text>
          <AssignMembers
            assignedMembers={selectedMembers}
            setAssignedMembers={setSelectedMembers}
            messageAssignees={messageAssignees}
            setMessageAssignees={setMessageAssignees}
            placeholder="Type an optional message to new members.."
          />
        </CheckBoxWithOptions>
      </ContentWrapper>
    );
  }, [
    type,
    setTitle,
    setScheduled,
    canSelectRange,
    scheduled,
    hideRestrictedOption,
    selectedMembers,
    setSelectedMembers,
    messageAssignees,
    setMessageAssignees,
    restricted,
    toggleRestriction,
  ]);

  return (
    <Wrapper>
      <Header className={'dragHandler'}>
        Create new {submitting && '- creating...'} {type}
        {showCreated && !submitting && '- created!'}
        <CloseButton onClick={onCancel}>
          <CloseIcon />
        </CloseButton>
      </Header>
      {canSelectMetadata ? (
        <SplitBar
          style={undefined}
          split={undefined}
          primary="first"
          pane1Style={{
            minWidth: '300px',
            maxWidth: '80%',
          }}
          pane2Style={{
            minWidth: '10%',
            maxWidth: '70%',
          }}
        >
          {MainStoryLayout}
          <MetadataWrapper>
            {mdf && (
              <MdfEditor
                fields={mdf.fields}
                defaultLayoutSettings={mdf.views.default}
                layoutSettings={mdf.views.story_create}
                view="story_create"
                metadata={metadata}
                permissions={mdf.permissions}
                updateFieldValue={updateFieldValues}
                errorMap={errorMap}
              />
            )}
          </MetadataWrapper>
        </SplitBar>
      ) : (
        MainStoryLayout
      )}
      <Footer>
        <Box container margin="0 0 0 -3px">
          <Tooltip title="Keep dialog open to create more stories/pitches">
            <span>
              <CheckboxWithLabel
                selected={keepOpen}
                disabled={false}
                onClick={() => setKeepOpen(!keepOpen)}
                label="Keep open"
              />
            </span>
          </Tooltip>
          <Tooltip title="Opens new story/pitch in a tab">
            <span style={{ marginLeft: '5px' }}>
              <CheckboxWithLabel
                selected={openStory}
                disabled={false}
                onClick={() => setOpenStory(!openStory)}
                label="Open in tab"
              />
            </span>
          </Tooltip>
        </Box>
        <ButtonsWrapper>
          <Button
            title={errorTooltip ?? titleErrorTooltip ?? disabledTooltip}
            ariaLabel={`Create ${type}`}
            disabled={Boolean(errorTooltip ?? titleErrorTooltip ?? disabledTooltip) || submitting}
            onClick={handleCreate}
            usage={type}
            variant="contained"
            size="sm"
            width={100}
          >
            {loading ? <LoadingButtonIndicator /> : `Create ${type}`}
          </Button>
        </ButtonsWrapper>
      </Footer>
    </Wrapper>
  );
};

export default CreateNew;
