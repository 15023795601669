import { FieldValue, Metadata } from 'types/forms/forms';
import { FieldTypeEnum, Mdf, MdfField } from 'types/graphqlTypes';

export const isMissingValue = (value: FieldValue, field: MdfField) => {
  if (field.defaultValue.value === null) return false;
  if (value === undefined || value === null) return true;
  return false;
};

/**
 * Returns a partial metadata payload containing any missing default values in the provided payload.
 * @param metadata The payload to check
 * @param mdf The primary schema
 * @param subTypeMdfs All possible subtype schemas. This function will pick the ones it needs
 *  based on the primary schema.
 * @returns A payload containing the missing default values.
 */
export const getDefaultValues = (metadata: Metadata, mdf: Mdf, subTypeMdfs: Mdf[]) => {
  let missingValues: Metadata = {};
  for (const field of mdf.fields) {
    if (isMissingValue(metadata[field.fieldId], field)) {
      missingValues[field.fieldId] = field.defaultValue.value;
      if (field.type === FieldTypeEnum.subtype) {
        const subMdf = subTypeMdfs.find((s) => s.label === field.defaultValue.value);
        if (subMdf) {
          missingValues = {
            ...missingValues,
            ...getDefaultValues(metadata, subMdf, subTypeMdfs),
          };
        }
      }
    }
  }
  return missingValues;
};
