import { elementTypes } from 'components/editor/constants/types';

const { HEADING_ONE, HEADING_TWO, HEADING_THREE, HEADING_FOUR } = elementTypes;

const components = {
  [HEADING_ONE]: 'h1',
  [HEADING_TWO]: 'h2',
  [HEADING_THREE]: 'h3',
  [HEADING_FOUR]: 'h4',
};

export default components;
