/* eslint-disable max-len */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { outTimingTypes } from 'components/editor/constants/types';
import { RightEdgeWrapper, RightEdge, MiddleEdge } from './styled';

const { MANUAL_OUT } = outTimingTypes;

const OutIconView = ({ outTimingType, isGraphic, isSelected, ...rest }) => {
  const isManual = outTimingType === MANUAL_OUT;

  return (
    <RightEdgeWrapper>
      <RightEdge isManual={isManual} isGraphic={isGraphic} isSelected={isSelected}>
        <MiddleEdge outTimingType={outTimingType} />
      </RightEdge>
    </RightEdgeWrapper>
  );
};

OutIconView.propTypes = {
  /** Specifies if the type is 'OVERLAY_GRAPHICS' */
  isGraphic: PropTypes.bool,
  /** Timing type for out */
  outTimingType: PropTypes.oneOf(Object.values(outTimingTypes)),
};

OutIconView.defaultProps = {
  isGraphic: false,
  outTimingType: MANUAL_OUT,
};

export default memo(OutIconView);
