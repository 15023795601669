/* eslint-disable no-console */
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { debounce, keyBy, omit } from 'lodash';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import { useGetOrderForms } from 'api/order_forms/useGetOrderForms';
import { SearchParameters, useSearch } from 'api/search';
import useGetFeed from 'api/useGetFeed';
import useOpenMember from 'components/contextMenu/useOpenMember';
import { MetadataWrapper } from 'components/createNewV3/styled';
import VerticalDivider from 'components/editor/components/toolbar/components/verticalDivider';
import { MdfDropdown } from 'components/mdfEditor/MdfDropdown';
import { MdfEditor } from 'components/mdfEditor/MdfEditor';
import Text from 'components/text/Text';
import Tooltip from 'components/tooltip';
import {
  CommandToolbarProps,
  DefaultMdfTypes,
  Destination,
  Section,
  SortOption,
  treatAsSection,
} from 'features/command/command-types';
import {
  isFiltering as calcIsFiltering,
  isFiltering,
  toolbarFilterDefaults,
  toSearchParameters,
} from 'features/command/command-utils';
import { sanitizeMetadata } from 'features/mdf/mdf-utils';
import MimirItem from 'features/mimirDeck/components/items';
import useMimirSearch, {
  MimirItem as MimirInterface,
} from 'features/mimirDeck/hooks/useMimirSearch';
import OrderFormDropdown from 'features/orderForm/components/OrderFormDropdown';
import useDinaNavigate from 'hooks/useDinaNavigate';
import { useLocalStorage } from 'hooks/useLocalStorage';
import useOpenMimirItem from 'hooks/useOpenMimirItem';
import { VStack } from 'layouts/box/Box';
import { CommandDialog, CommandGroup, CommandList, CommandMounted } from 'lib/command';
import { ResizablePanel, ResizablePanelGroup } from 'lib/resize';
import { useSettingsOpen } from 'screens/main/components/header/navbar/settings/atomsTs';
import { useAllMembersKeyed, useFeedSources } from 'store';
import { useSetPreview } from 'store/preview';
import { MdfId, MdfIds } from 'types';
import { Metadata, NewFieldValue } from 'types/forms/forms';
import {
  Mdf,
  MemberType,
  MemberTypeEnum,
  SavedSearch,
  SearchItemTypeEnum,
  VisiblePlaces,
} from 'types/graphqlTypes';
import { OrderFormMemberType } from 'types/memberTypes/order_form';

import SavedSearches from './savedSearch/SavedSearches';
import { CommandToolbar } from './toolbar/CommandToolbar';
import { FeedItem } from './views/FeedCommandItem';
import InitiallyHiddenInstructions from './views/InitiallyHiddenInstructions';
import InitiallyHiddenSearchGroup from './views/InitiallyHiddenSearchGroup';
import InstructionGroup from './views/InstructionGroup';
import MetadataGroup from './views/MetadataGroup';
import NavigationGroup from './views/NavigationGroup';
import SearchResults from './views/search-results/SearchResults';
import { mdfIdToMType, mTypeToLabel } from './command-constants';
import CommandFooter from './CommandFooter';
import CommandInputField, { CommandInputRef } from './CommandInputField';
import { CommandItem } from './CommandItem';
import { CommandPathProps, useCommandPath } from './CommandPath';
import SortPopper from './SortPopper';

import {
  CommandHeader,
  FuzzyIcon,
  HeaderWrapper,
  SearchTotal,
  StyledVerticalResizeHandle,
} from './styled';
const defaultPlaceholderText = 'Type a command or search...';

/**
 * This must be included for each command item that may not have unique text content.
 *
 * This is necessary for cmdk to look at the contents as a unique value. For example, many feed
 * items may have practically the same content. Without including a unique id in the actual DOM
 * tree of the item, cmdk struggles seeing the difference and treats it as the same item.
 */
export const UniqueId = ({ id }: { id: string }) => <div style={{ display: 'none' }}>{id}</div>;

const showSearchResultForSections = [
  'rundowns',
  'stories',
  'instances',
  'contacts',
  'tasks',
  'notes',
];

interface Props {
  location: VisiblePlaces | 'default';
  availableSorts?: SortOption[];
  isMounted?: boolean;
  hideToolbar?: boolean;
  darkHeader?: boolean;
  allowStaticSearchOptions?: boolean;
  setSearchParams?: (searchObject: SearchParameters) => void;
  searchParams?: SearchParameters;
  headerAppendSlot?: React.ReactElement;
}

interface CommandLayoutStorage {
  leftSidebar: boolean;
  rightSidebar: boolean;
}

const getToolbarStateFromInput = (input: SearchParameters): CommandToolbarProps => {
  const props: CommandToolbarProps = {
    ...input.toolbarState,
    isFiltering: false,
  };
  return {
    ...props,
    isFiltering: isFiltering(props),
  };
};

const CommandMenu = ({
  location,
  darkHeader = false,
  hideToolbar = false,
  isMounted = false,
  allowStaticSearchOptions = true,
  setSearchParams,
  searchParams,
  headerAppendSlot,
  availableSorts = [],
}: Props) => {
  const [storedState, setStoredState] = useLocalStorage<CommandLayoutStorage>({
    key: `command-${location}`,
    defaultValue: {
      leftSidebar: !!searchParams?.mdfId,
      rightSidebar: false,
    },
    dispatchEvent: true,
  });

  const { navigateTo } = useDinaNavigate();
  const setPreview = useSetPreview();
  const [toolbarHeight, setToolbarHeight] = useState(32);
  const [allMembersKeyed] = useAllMembersKeyed();
  const [toolbarState, setToolbarState] = useState<CommandToolbarProps>(
    searchParams
      ? getToolbarStateFromInput(searchParams)
      : {
          ...toolbarFilterDefaults,
        },
  );
  const [pathState, setPathState] = useCommandPath();
  const { orderForms } = useGetOrderForms();
  const [, setSettingsOpen] = useSettingsOpen();
  const { openItem } = useOpenMember();
  const openAssetInMimir = useOpenMimirItem();
  const { search: searchInMimir, loading } = useMimirSearch({});
  const [searchPayload, setSearchPayload] = useState<Metadata>(
    searchParams?.metadataFilter ? { ...searchParams?.metadataFilter } : {},
  );
  const [livePayload, setLivePayload] = useState<Metadata>(
    searchParams?.metadataFilter ? { ...searchParams?.metadataFilter } : {},
  );

  const [activeSavedSearch, setActiveSavedSearch] = useState<string | null>(null);
  const [selectedOrderForm, setSelectedOrderForm] = useState<OrderFormMemberType | null>(null);
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState(searchParams?.searchString ?? '');
  const [mimirItems, setMimirItems] = useState<MimirInterface[]>([]);
  const [openSearchesDialog, setOpenSearchesDialog] = useState(false);
  const commandInputRef = useRef<CommandInputRef | null>(null);
  const { mdfs, mdfsSeparated } = useGetMdfs({ all: true });
  const mdfMap = useMemo(() => {
    return keyBy(mdfs, (mdf) => mdf.id);
  }, [mdfs]);

  const subTypeByLabel = useMemo(() => {
    return keyBy(mdfsSeparated.subTypes, (mdf) => mdf.label);
  }, [mdfsSeparated.subTypes]);

  const orderMdfMap = useMemo(() => {
    const orderMdfs: Record<string, Mdf> = {};
    orderForms.forEach((o) => {
      const mdf = o.mSecId ? mdfMap[o.mSecId] : undefined;
      if (mdf) {
        orderMdfs[o.mDescription] = mdf;
      }
    });
    return orderMdfs;
  }, [mdfMap, orderForms]);

  const showTaskMetadataSearch = toolbarState.mTypes.includes(SearchItemTypeEnum.order);
  const activeSection = pathState.sections[pathState.sections.length - 1];
  const activeMdf =
    mdfMap[toolbarState.mdfId ?? toolbarState.defaultMdfId ?? ''] ||
    orderMdfMap[toolbarState.mdfId ?? ''];

  const sParams = searchParams
    ? { ...searchParams }
    : {
        skip: isMounted ? false : !open,
        searchString: searchValue,
        toolbarState,
        metadataFilter: searchPayload,
        mdfId: toolbarState.mdfId ?? undefined,
      };
  const { items: searchItems, loading: searchLoading, handleLoadMore, total } = useSearch(sParams);

  const [sources] = useFeedSources();
  const { items, loading: feedsLoading } = useGetFeed({
    selectedProviders: sources ?? [],
    searchString: searchValue,
    noSubscribe: true,
    noSearch: !pathState.sections.includes('feeds'),
  });

  const debouncedMetadata = useRef(
    debounce((val: Metadata) => {
      setSearchPayload(val);
    }, 500),
  );

  useEffect(() => {
    setActiveSavedSearch(null);
  }, [livePayload, searchValue, toolbarState]);

  useEffect(() => {
    debouncedMetadata.current(livePayload);
  }, [livePayload]);

  const showLoading = loading || feedsLoading || searchLoading;
  const isHome = activeSection === 'home' || !activeSection;
  const isSearch =
    toolbarState.isFiltering ||
    showSearchResultForSections.includes(activeSection) ||
    activeMdf !== undefined;

  const shouldFilter = activeSection !== 'feeds' && activeSection !== 'assets';
  const debouncedMimirSearch = useRef(
    debounce(async (searchString: string) => {
      const result = await searchInMimir(searchString);
      setMimirItems(result);
    }, 1000),
  ).current;

  useEffect(() => {
    if (activeSection === 'assets') {
      debouncedMimirSearch(searchValue)?.catch((err) => console.error(err));
    }
  }, [searchValue, debouncedMimirSearch, activeSection]);

  useEffect(() => {
    return () => {
      debouncedMimirSearch.cancel();
    };
  }, [open]);

  const setShowLeftPanel = useCallback(
    (val: boolean) => {
      setStoredState((prevValue) => {
        return {
          ...prevValue,
          leftSidebar: val,
        };
      });
    },
    [setStoredState],
  );

  const setShowRightPanel = useCallback(
    (val: boolean) => {
      setStoredState((prevValue) => {
        return {
          ...prevValue,
          rightSidebar: val,
        };
      });
    },
    [setStoredState],
  );

  useEffect(() => {
    if (setSearchParams) {
      // We do not want to set mdfId for default mTypes - many older stories will not
      // have this set, and will not appear if we send in that mdfId to search.
      const shouldSetMdfId = !MdfIds.includes(activeMdf?.id as MdfId);
      setSearchParams({
        skip: false,
        searchString: searchValue,
        toolbarState: { ...toolbarState },
        metadataFilter: searchPayload,
        mdfId: shouldSetMdfId ? activeMdf?.id : undefined,
      });
    }
  }, [setSearchParams, searchValue, toolbarState, searchPayload, activeMdf]);

  useEffect(() => {
    const downKey = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen((prev) => !prev);
      }
    };
    document.addEventListener('keydown', downKey);
    return () => document.removeEventListener('keydown', downKey);
  }, []);

  const placeholder = useMemo(() => {
    if (toolbarState.isFiltering && !hideToolbar) {
      return 'Type to search further or press backspace to reset';
    }
    if (toolbarState.mTypes.length) {
      return `Search in ${toolbarState.mTypes.map((m) => mTypeToLabel[m]).join(', ')}`;
    } else {
      return defaultPlaceholderText;
    }
  }, [toolbarState.mTypes, toolbarState.isFiltering, hideToolbar]);

  const clearValue = useCallback(() => {
    commandInputRef.current?.clear();
  }, [commandInputRef]);

  const popSection = useCallback(() => {
    setPathState((p: CommandPathProps) => {
      const x = [...p.sections];
      x.splice(-1, 1);
      return { sections: x };
    });
  }, []);

  const navigateToPage = useCallback(
    (val: Destination) => {
      if (val === 'settings') {
        setSettingsOpen(true);
      } else {
        navigateTo(val);
      }
      setOpen(false);
    },
    [setOpen, setSettingsOpen, navigateTo],
  );

  const updateFilterState = useCallback(
    (val: Partial<CommandToolbarProps>) => {
      setToolbarState((prevState) => {
        const newValue = {
          ...prevState,
          ...val,
        };
        return {
          ...newValue,
          isFiltering: calcIsFiltering(newValue),
        };
      });
    },
    [setToolbarState],
  );

  const goToMdf = useCallback(
    (mdf: Mdf | null) => {
      clearValue();
      setShowLeftPanel(true);
      setLivePayload({});

      if (mdf && mdfIdToMType[mdf.id as DefaultMdfTypes]) {
        updateFilterState({
          mTypes: [mdfIdToMType[mdf.id as DefaultMdfTypes]],
          defaultMdfId: mdf.id as DefaultMdfTypes,
          mdfId: null,
        });
      } else if (mdf === null) {
        updateFilterState({
          mTypes: [],
          defaultMdfId: null,
        });
      } else {
        updateFilterState({
          defaultMdfId: null,
          mdfId: mdf.id,
          mTypes: [],
        });
      }
    },
    [clearValue, setShowLeftPanel, setLivePayload],
  );

  const goToSection = useCallback(
    (val: Section | SearchItemTypeEnum) => {
      if (!treatAsSection(val)) {
        updateFilterState({
          mTypes: [val],
        });
      } else {
        setPathState({ sections: [...pathState.sections, val] });
      }
      clearValue();
    },
    [setPathState, pathState, updateFilterState],
  );

  const updateFieldValues = useCallback(
    (p: NewFieldValue[]) => {
      const updatedMd: Metadata = {};
      for (const update of p) {
        updatedMd[update.fieldId] = update.value;
      }
      const sanitizedMetadata = sanitizeMetadata(
        { ...livePayload, ...updatedMd },
        activeMdf,
        subTypeByLabel,
      );
      setLivePayload(sanitizedMetadata);
    },
    [setLivePayload, activeMdf, subTypeByLabel, livePayload],
  );

  const clearFieldValue = useCallback(
    (fieldId: string) => {
      setLivePayload((prevState) => ({
        ...omit(prevState, fieldId),
      }));
    },
    [setLivePayload],
  );

  const resetAll = useCallback(() => {
    setPathState({ sections: [] });
    setLivePayload({});
    setSelectedOrderForm(null);
    updateFilterState({ ...toolbarFilterDefaults });
  }, [setLivePayload, setToolbarState]);

  const doReset = useCallback(() => {
    if (hideToolbar) return;
    if (!isHome) popSection();
    resetAll();
  }, [isHome, hideToolbar, popSection, resetAll]);

  const onOrderFormSelect = useCallback(
    (val: OrderFormMemberType | null) => {
      setSelectedOrderForm(val);
      updateFilterState({
        mdfId: val?.mSecId ?? null,
        mTypes: [SearchItemTypeEnum.order], // Force search to Task only.
      });
    },
    [setSelectedOrderForm, updateFilterState],
  );

  const doOpen = useCallback(
    (val: MemberType) => {
      if ([MemberTypeEnum.Order, MemberTypeEnum.Block].includes(val.mType!)) {
        setPreview(val);
      } else {
        openItem(val);
      }
      setOpen(false);
    },
    [openItem, setPreview, setOpen],
  );

  const applySavedSearch = useCallback(
    (s: SavedSearch) => {
      const params = toSearchParameters(s.filters);
      setSearchPayload(params.metadataFilter ?? {});
      setLivePayload(params.metadataFilter ?? {});
      setSearchValue(params.searchString);
      setToolbarState(params.toolbarState);

      setTimeout(() => {
        setActiveSavedSearch(s.id);
      }, 0);
    },
    [setSearchPayload, setLivePayload, setSearchValue, setToolbarState, setActiveSavedSearch],
  );

  const startFilteringLessImportantOptions = searchValue.length > 2;
  const CommandPart = useMemo(() => {
    return (
      <CommandList>
        {allowStaticSearchOptions && isHome && !isSearch && (
          <>
            {startFilteringLessImportantOptions && (
              <>
                <InitiallyHiddenInstructions inputValue={searchValue} />
                <InitiallyHiddenSearchGroup goToSection={goToSection} inputValue={searchValue} />
              </>
            )}
            {!isMounted && <NavigationGroup navigateTo={navigateToPage} />}
            {!isMounted && <InstructionGroup setOpen={setOpen} />}
            {startFilteringLessImportantOptions && (
              <MetadataGroup goToMdf={goToMdf} mdfs={mdfs} inputValue={searchValue} />
            )}
            <SearchResults
              searchValue={searchValue}
              sortedBy={toolbarState.sortBy}
              loading={searchLoading}
              openItem={doOpen}
              setOpen={setOpen}
              items={searchItems}
              members={allMembersKeyed}
              loadMore={handleLoadMore}
            />
          </>
        )}

        {(!allowStaticSearchOptions || isSearch) && (
          <SearchResults
            searchValue={searchValue}
            sortedBy={toolbarState.sortBy}
            loading={searchLoading}
            openItem={doOpen}
            setOpen={setOpen}
            items={searchItems}
            members={allMembersKeyed}
            loadMore={handleLoadMore}
          />
        )}

        {activeSection === 'feeds' && items.length > 0 && (
          <CommandGroup heading="Search results">
            {items.map((feedItem, i) => (
              <CommandItem key={feedItem.mId} type="feed">
                <>
                  <UniqueId id={feedItem.mId ?? feedItem.mTitle ?? `${i}`} />
                  <FeedItem item={feedItem} />
                </>
              </CommandItem>
            ))}
          </CommandGroup>
        )}

        {activeSection === 'assets' && mimirItems.length > 0 && (
          <CommandGroup heading="Search results">
            {mimirItems.map((mimirItem) => (
              <CommandItem key={mimirItem.id} type="asset">
                <>
                  <UniqueId id={mimirItem.id} />
                  <MimirItem item={mimirItem} onClick={() => openAssetInMimir(mimirItem.id)} />
                </>
              </CommandItem>
            ))}
          </CommandGroup>
        )}
      </CommandList>
    );
  }, [
    isSearch,
    searchValue,
    mdfs,
    allMembersKeyed,
    startFilteringLessImportantOptions,
    activeSection,
    mimirItems,
    searchItems,
    setOpen,
    handleLoadMore,
    openItem,
    isMounted,
  ]);

  const CommandComponent = isMounted ? CommandMounted : CommandDialog;

  const onKeyDown = (ev: React.KeyboardEvent<HTMLDivElement>) => {
    if ((ev.ctrlKey || ev.metaKey) && ev.key === 'ArrowRight') {
      ev.preventDefault();
      ev.stopPropagation();
      setShowRightPanel(!storedState.rightSidebar);
    }
    if ((ev.ctrlKey || ev.metaKey) && ev.key === 'ArrowLeft') {
      ev.preventDefault();
      ev.stopPropagation();
      setShowLeftPanel(!storedState.leftSidebar);
    }
    if ((ev.ctrlKey || ev.metaKey) && (ev.key === 'ArrowUp' || ev.key === 'ArrowDown')) {
      ev.preventDefault();
      ev.stopPropagation();
    }

    if ((ev.ctrlKey || ev.metaKey) && ev.key === 's') {
      ev.preventDefault();
      ev.stopPropagation();
      if (!storedState.rightSidebar) setShowRightPanel(true);
      setTimeout(() => {
        setOpenSearchesDialog(true);
      }, 0);
    }
  };

  const toggleFuzzy = useCallback(() => {
    setToolbarState((prev) => {
      const fuzzy = !!prev.fuzzy;
      return {
        ...prev,
        fuzzy: !fuzzy,
      };
    });
  }, [setToolbarState]);

  const dialogWidth = useMemo(() => {
    if (!storedState.leftSidebar && !storedState.rightSidebar) return 40;
    if (storedState.leftSidebar && storedState.rightSidebar) return 75;
    return 62;
  }, [storedState]);

  return (
    <CommandComponent
      open={isMounted || open}
      onDialogFocus={() => commandInputRef.current?.focus()}
      onOpenChange={setOpen}
      shouldFilter={shouldFilter}
      innerHeight={toolbarHeight}
      dialogWidth={dialogWidth}
      onKeyDown={onKeyDown}
    >
      <HeaderWrapper gap="2px" $dark={darkHeader}>
        <CommandInputField
          ref={commandInputRef}
          // searchValue is passed to ensure the searchString sticks between cmdk sessions,
          // otherwise the state in the input field will reset to empty string
          startValue={searchParams?.searchString ?? searchValue}
          doReset={doReset}
          showLoading={showLoading}
          placeholder={placeholder}
          onDebouncedChange={setSearchValue}
        />
        <VerticalDivider />
        <Tooltip title="Enable fuzzy search">
          <FuzzyIcon $active={toolbarState.fuzzy} onClick={toggleFuzzy} />
        </Tooltip>
        <VerticalDivider />
        <SearchTotal>{total} hits</SearchTotal>
        {headerAppendSlot && (
          <>
            <VerticalDivider />
            {headerAppendSlot}
          </>
        )}
      </HeaderWrapper>
      <CommandToolbar
        hideToolbar={hideToolbar}
        toolbarState={toolbarState}
        searchParams={sParams}
        setToolbarState={setToolbarState}
        setToolbarHeight={setToolbarHeight}
        toolbarHeight={toolbarHeight}
        resetAll={resetAll}
        resetMetadata={() => setLivePayload({})}
        clearFieldValue={clearFieldValue}
        metadataFilter={livePayload}
        mdfs={mdfs}
        mdfId={activeMdf?.id}
        items={searchItems}
        totalSearchHits={total}
        allMembersKeyed={allMembersKeyed}
        showLeftPanel={storedState.leftSidebar}
        toggleLeftPanel={() => setShowLeftPanel(!storedState.leftSidebar)}
        showRightPanel={storedState.rightSidebar}
        toggleRightPanel={() => setShowRightPanel(!storedState.rightSidebar)}
        darkHeader={darkHeader}
      />
      <ResizablePanelGroup direction="horizontal">
        {storedState.leftSidebar && (
          <>
            <ResizablePanel
              order={1}
              defaultSize={25}
              minSize={20}
              maxSize={40}
              style={{ margin: '0 0 4px 4px' }}
              id="metadata-sidepanel"
            >
              <CommandHeader justifyContent="start">Metadata</CommandHeader>
              <MetadataWrapper>
                <VStack>
                  {!hideToolbar && (
                    <>
                      <Text variant="overline">
                        Filter by {showTaskMetadataSearch ? 'task type' : 'metadata'}
                      </Text>
                      {showTaskMetadataSearch ? (
                        <OrderFormDropdown
                          onSelect={onOrderFormSelect}
                          selectedOrderForm={selectedOrderForm}
                        />
                      ) : (
                        <MdfDropdown
                          hideLabel
                          systemOnly
                          placeholder="Select type"
                          value={activeMdf?.id ?? ''}
                          onSelectMdf={goToMdf}
                        />
                      )}
                    </>
                  )}
                  {activeMdf && (
                    <MdfEditor
                      fireOnChange
                      forceDateRange
                      ignoreDefaultValue
                      ignorePermissions
                      fields={activeMdf.fields}
                      defaultLayoutSettings={activeMdf.views.default}
                      view="search_view"
                      layoutSettings={activeMdf.views.search_view}
                      metadata={livePayload}
                      permissions={activeMdf.permissions}
                      updateFieldValue={updateFieldValues}
                    />
                  )}
                </VStack>
              </MetadataWrapper>
            </ResizablePanel>
            <StyledVerticalResizeHandle />
          </>
        )}
        <ResizablePanel order={2} id="search-results">
          <CommandHeader justifyContent="space-between">
            <span>Search results</span>
            <SortPopper
              toolbarState={toolbarState}
              setToolbarState={setToolbarState}
              availableSorts={availableSorts}
            />
          </CommandHeader>
          {CommandPart}
        </ResizablePanel>
        {storedState.rightSidebar && (
          <>
            <StyledVerticalResizeHandle tabIndex={-1} />
            <ResizablePanel
              order={3}
              defaultSize={25}
              minSize={15}
              maxSize={40}
              id="saved-searches"
            >
              <SavedSearches
                location={location}
                selectedSavedSearch={activeSavedSearch}
                onApplySavedSearch={applySavedSearch}
                toolbarState={toolbarState}
                searchString={searchValue}
                metaDataFilter={livePayload}
                openDialog={openSearchesDialog}
                setOpenDialog={setOpenSearchesDialog}
              />
            </ResizablePanel>
          </>
        )}
      </ResizablePanelGroup>
      {!isMounted && <CommandFooter />}
    </CommandComponent>
  );
};

export default memo(CommandMenu);
