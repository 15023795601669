import { Svg, Path, Rect } from '@react-pdf/renderer';

const CGIcon = () => {
  return (
    <Svg style={{ width: 32, height: 32, marginRight: 8 }} viewBox='0 0 32 32'>
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.25 14.2131L7.44636 14.8506L5.98455 16.8058L6.89364 17.4504L8.20273 15.4599L9.55545 17.5L10.4573 16.8341L9.01727 14.9144L11.25 14.2698L10.9155 13.193L8.75545 13.9723L8.82818 11.5H7.71545L7.78091 13.9368L5.58455 13.1434L5.25 14.2131ZM17.963 19.7179C18.543 19.1925 18.8755 18.4626 18.9606 17.5281H17.499C17.4217 18.1537 17.238 18.6009 16.948 18.8697C16.658 19.1384 16.2249 19.2727 15.6488 19.2727C15.0185 19.2727 14.5391 19.0241 14.2104 18.5267C13.8856 18.0294 13.7232 17.3075 13.7232 16.361V15.5849C13.7309 14.6504 13.9049 13.9425 14.2452 13.4612C14.5893 12.9759 15.0804 12.7333 15.7184 12.7333C16.2675 12.7333 16.6831 12.8737 16.9654 13.1544C17.2515 13.4311 17.4294 13.8844 17.499 14.514H18.9606C18.8678 13.5555 18.5372 12.8135 17.9688 12.2881C17.4004 11.7627 16.6503 11.5 15.7184 11.5C15.0263 11.5 14.4153 11.6705 13.8856 12.0114C13.3597 12.3523 12.9557 12.8376 12.6734 13.4672C12.3911 14.0969 12.25 14.8249 12.25 15.6511V16.4693C12.2616 17.2754 12.4066 17.9833 12.685 18.5929C12.9634 19.2025 13.3578 19.6738 13.8682 20.0067C14.3825 20.3356 14.976 20.5 15.6488 20.5C16.6116 20.5 17.383 20.2393 17.963 19.7179ZM25.4798 20.1811C26.0211 19.9686 26.4445 19.6557 26.75 19.2426V15.8857H23.5484V17.0408H25.2884V18.7553C24.9713 19.1083 24.4377 19.2848 23.6876 19.2848C23.0032 19.2848 22.4677 19.0241 22.081 18.5027C21.6982 17.9773 21.5068 17.2413 21.5068 16.2948V15.621C21.5184 14.6705 21.6943 13.9505 22.0346 13.4612C22.3787 12.9679 22.8795 12.7213 23.5368 12.7213C24.5537 12.7213 25.1434 13.2487 25.3058 14.3035H26.7442C26.6321 13.3971 26.2995 12.7032 25.7466 12.2219C25.1937 11.7406 24.4474 11.5 23.5078 11.5C22.4135 11.5 21.5609 11.873 20.95 12.619C20.3391 13.361 20.0336 14.3937 20.0336 15.7172V16.4031C20.0452 17.2293 20.1979 17.9532 20.4918 18.5749C20.7895 19.1925 21.211 19.6678 21.7562 20.0007C22.3014 20.3336 22.9278 20.5 23.6354 20.5C24.3237 20.5 24.9385 20.3937 25.4798 20.1811Z'
        fill='#0F141A'
        fillOpacity='0.87'
      />
      <Rect
        x='0.5'
        y='0.5'
        width='31'
        height='31'
        rx='5.5'
        ry='5.5'
        stroke='#0F141A'
        strokeOpacity='0.87'
      />
    </Svg>
  );
};

export default CGIcon;
