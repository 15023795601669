import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import play from 'assets/icons/systemicons/Play.svg';
import videoClip from 'assets/icons/systemicons/video.svg';
import textOff from 'assets/icons/systemicons/text_off.svg';
import PDF from 'assets/icons/systemicons/filetype/PDF.svg';
import Doc from 'assets/icons/systemicons/filetype/Doc.svg';
import PPT from 'assets/icons/systemicons/filetype/PPT.svg';
import fallbackImage from 'assets/images/default/defaultCoverPhoto.png';

import {
  Root,
  ThumbnailWrapper,
  Image,
  Video,
  PlayIconWrapper,
  ClipIconWrapper,
  DurationWrapper,
  Duration,
  DocumentThumbWrapper,
  DocImage,
  MetadataWrapper,
  Filename,
  Byline,
} from './mediaCard-styled';

const documentThumbIconSelector = (mimetype) => {
  if (!mimetype) return textOff;

  if (mimetype.toLowerCase().includes('pdf')) return PDF;
  if (mimetype.toLowerCase().includes('word')) return Doc;
  if (mimetype.toLowerCase().includes('ppt')) return PPT;

  return textOff;
};

const MediaCard = ({
  thumbnailSrc,
  src,
  type,
  filename,
  duration,
  byline,
  onClick,
  mimetype,
  fullWidth,
  showHighlight,
  onAssetSet,
  onFocus,
}) => {
  const [metadataDuration, setMetadataDuration] = useState('0:00');

  const formatDuration = useCallback((seconds) => {
    const formatted = new Date(seconds * 1000).toISOString().substr(11, 8);
    return formatted.substr(0, 2) === '00' ? formatted.substr(4) : formatted;
  }, []);

  const onLoadedMetaData = useCallback(
    (e) => {
      setMetadataDuration(formatDuration(e.target.duration));
    },
    [formatDuration],
  );

  const videoDuration = duration ? formatDuration(duration) : metadataDuration;
  return (
    <Root showHighlight={showHighlight} fullWidth={fullWidth} onClick={onClick} key={filename}>
      <ThumbnailWrapper onClick={onAssetSet} role="presentation">
        {type === 'application' && (
          <DocumentThumbWrapper>
            <DocImage src={documentThumbIconSelector(mimetype)} alt="thumbnail" />
          </DocumentThumbWrapper>
        )}
        {type === 'video' && (
          <>
            {src ? (
              <Video onLoadedMetadata={onLoadedMetaData} poster={thumbnailSrc}>
                <source src={src} type="video/mp4" />
              </Video>
            ) : (
              <Image src={thumbnailSrc} alt="thumbnail" />
            )}
            <PlayIconWrapper>
              <img src={play} alt="play-icon" />
            </PlayIconWrapper>
            <ClipIconWrapper>
              <img src={videoClip} alt="video-clip-icon" />
            </ClipIconWrapper>
            <DurationWrapper>
              <Duration>{videoDuration}</Duration>
            </DurationWrapper>
          </>
        )}
        {type !== 'video' && type !== 'application' && <Image src={thumbnailSrc} alt="thumbnail" />}
      </ThumbnailWrapper>
      <MetadataWrapper onClick={onFocus} role="presentation">
        <Filename fullWidth={fullWidth}>{filename}</Filename>
        <Byline fullWidth={fullWidth}>{byline}</Byline>
      </MetadataWrapper>
    </Root>
  );
};

MediaCard.propTypes = {
  /** onClick function */
  onClick: PropTypes.func,
  /** src for thumbnail image */
  thumbnailSrc: PropTypes.string,
  /** src for video */
  src: PropTypes.string,
  /** type: video/image */
  type: PropTypes.string,
  /** filename */
  filename: PropTypes.string,
  /** duration */
  duration: PropTypes.string,
  /** byline */
  byline: PropTypes.string,
  /** byline */
  mimetype: PropTypes.string,
  /** Full width  */
  fullWidth: PropTypes.bool,
  /** Highlight border card  */
  showHighlight: PropTypes.bool,
  /** onFocus */
  onFocus: PropTypes.func,
  /** onAssetSet */
  onAssetSet: PropTypes.func,
};

MediaCard.defaultProps = {
  onClick: () => {},
  thumbnailSrc: fallbackImage,
  src: null,
  type: 'image',
  filename: null,
  duration: null,
  byline: null,
  mimetype: null,
  fullWidth: false,
  showHighlight: false,
  onFocus: () => {},
  onAssetSet: () => {},
};

export default MediaCard;
