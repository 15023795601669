import { memo } from 'react';

import Collapsible from 'components/collapsible/Collapsible';
import { KanbanLaneReadOnlyProps } from 'features/kanban/types/kanban';

import GroupHeader from './ReadOnlyLaneHeader';

function ReadOnlyLane({
  label,
  color,
  count,
  mRefId,
  children,
  dense = false,
  darker = false,
}: KanbanLaneReadOnlyProps) {
  return (
    <Collapsible
      trigger={
        <GroupHeader
          label={label}
          color={color}
          count={count}
          mRefId={mRefId}
          dense={dense}
          darker={darker}
        />
      }
      content={children}
    />
  );
}

export default memo(ReadOnlyLane);
