import styled from '@emotion/styled';

import Text from 'components/text/Text';
import { VStack } from 'layouts/box/Box';

export const DateLabel = styled('div')`
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  &:hover {
    span {
      color: ${({ theme }) => theme.palette.dina.highEmphasis} !important;
    }
  }
`;

export const DateWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const StyledText = styled(Text)`
  font-weight: 200;
  position: relative;
  top: -1px;
`;

export const StyledImage = styled('img')`
  max-width: 100%;
  height: auto;

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const MenuContent = styled(VStack)`
  text-overflow: ellipsis;
  white-space: pre;
`;

export const IconWrapper = styled('div')`
  width: 24px;
  padding: 0 2px;
  flex-grow: 0;
  flex-shrink: 0;

  svg {
    width: 20px;
    height: 20px;
  }
`;
