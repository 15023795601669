import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '96px',
    height: '64px',
    borderRadius: '4px',
    overflow: 'hidden',
    position: 'relative',
    '&:hover $dragButton': {
      opacity: 1,
    },
  },
  dragButton: {
    position: 'absolute',
    opacity: 0,
    left: 'calc(50% - 32px/2)',
    top: 'calc(50% - 32px/2 - 4px)',
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    backgroundColor: theme.palette.dina.blackMediumEmphasis,
    '&:hover': {
      backgroundColor: theme.palette.dina.blackMediumEmphasis,
    },
  },
}));

export default useStyles;
