import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useAtom } from 'jotai';

import { NewItemObject, newItemsAtom } from 'store/create';
import { MemberType } from 'types/graphqlTypes';

export default function useNewItems() {
  const [newItems, setNewItems] = useAtom(newItemsAtom);
  const timeoutsRef = useRef<{ [key: string]: NodeJS.Timeout }>({});

  // Function to remove an object
  const removeItem = useCallback(
    (id: string) => {
      setNewItems((prevObjects) => prevObjects.filter((obj) => obj.id !== id));

      // Clear the timeout for this object
      if (timeoutsRef.current[id]) {
        clearTimeout(timeoutsRef.current[id]);
        delete timeoutsRef.current[id];
      }
    },
    [setNewItems],
  );

  // Function to add a new object
  const addItem = useCallback(
    (data: MemberType) => {
      const newObject: NewItemObject = {
        id: data.mId!,
        data,
        timestamp: Date.now(),
      };

      setNewItems((prevObjects) => {
        if (prevObjects.find((i) => i.id === data.mId)) return prevObjects;
        return [newObject, ...prevObjects];
      });

      // Set a timeout to remove the object after 30 seconds
      timeoutsRef.current[data.mId!] = setTimeout(() => {
        removeItem(data.mId!);
      }, 30000);
    },
    [removeItem, setNewItems],
  );

  // Clear all timeouts when the component unmounts
  useEffect(() => {
    return () => {
      Object.values(timeoutsRef.current).forEach(clearTimeout);
    };
  }, []);

  const items = useMemo(() => newItems.map((item) => item.data), [newItems]);

  return { newItems: items, addItem, removeItem };
}
