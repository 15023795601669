import { useCallback, useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import { useDeleteOrderForm } from 'api/order_forms/useDeleteOrderForm';
import { useGetOrderForms } from 'api/order_forms/useGetOrderForms';
import { DeleteDialog } from 'components/dialogs/CommonDialogs';
import Scrollbar from 'components/scrollbar';
import useToast from 'components/toast/useToast';
import { VerticalResizeHandle } from 'features/layouts/ResizableHandlers';
import EditOrderProperties from 'features/sidepanel/EditOrderBlock';
import { Box, VStack } from 'layouts/box/Box';
import { ResizablePanel, ResizablePanelGroup } from 'lib/resize';
import { Metadata } from 'types/forms/forms';
import { OrderFormMemberType } from 'types/memberTypes/order_form';

import { useChangedMdfs, useChangedOrderForms, useSelectedFormId } from '../../atomsTs';
import SettingsListItem from '../layout/SettingsListItem';
import { SettingsListSection } from '../layout/SettingsListSection';

import { CreateOrderConfigDialog } from './CreateOrderConfigDialog';
import EditOrderFormProperties from './EditOrderFormProperties';

export function OrderConfigs() {
  const { errorToast } = useToast();
  const { orderForms } = useGetOrderForms();
  const [changedOrderForms, setChangedOrderForms] = useChangedOrderForms();
  const [changedMdfs] = useChangedMdfs();
  const { deleteOrderForm } = useDeleteOrderForm();
  const [metadata, setMetadata] = useState<Metadata>({});
  const [assignee, setAssignee] = useState<string>();
  const [owner, setOwner] = useState<string>();
  const [status, setStatus] = useState<string>();
  const [orderFormToDelete, setOrderFormToDelete] = useState<OrderFormMemberType | null>(null);
  const [doSelectId, setDoSelectId] = useState<string | null>(null);
  const [startSection, setStartSection] = useState<string | null>(null);
  const [selectedFormId, setSelectedFormId] = useSelectedFormId();
  const { mdfsSeparated } = useGetMdfs({ all: true });

  const selected = useMemo(() => {
    if (!selectedFormId) return null;
    return changedOrderForms[selectedFormId] ?? orderForms.find((o) => o.mRefId === selectedFormId);
  }, [changedOrderForms, selectedFormId, orderForms]);

  const mdf = useMemo(() => {
    if (!selected?.mSecId) return null;
    return (
      changedMdfs[selected.mSecId] ?? mdfsSeparated.custom.find((o) => o.id === selected.mSecId)
    );
  }, [changedMdfs, mdfsSeparated, selected]);

  const doDeleteForm = useCallback(() => {
    if (orderFormToDelete) {
      deleteOrderForm(orderFormToDelete.mId, orderFormToDelete.mRefId)
        .then(() => {
          if (orderFormToDelete.mRefId === selectedFormId) {
            setSelectedFormId(orderForms.length ? orderForms[0].mRefId : null);
          }
        })
        .catch(errorToast)
        .finally(() => setOrderFormToDelete(null));
    }
  }, [orderFormToDelete, setSelectedFormId, setOrderFormToDelete, deleteOrderForm]);

  useEffect(() => {
    if (doSelectId) {
      const form = orderForms.find((o) => o.mRefId === doSelectId);
      if (form) {
        setSelectedFormId(form.mRefId);
        setDoSelectId(null);
      }
    } else if (!doSelectId && !selectedFormId && orderForms.length) {
      setSelectedFormId(orderForms[0].mRefId);
    }
  }, [orderForms, doSelectId]);

  const onFormChange = useCallback(
    (form: OrderFormMemberType | null, originalForm: OrderFormMemberType | null) => {
      if (form === null || originalForm === null) return;
      if (!changedOrderForms[form.mRefId] && !isEqual(form, originalForm)) {
        setChangedOrderForms({
          ...changedOrderForms,
          [form.mRefId]: form,
        });
      } else if (changedOrderForms[form.mRefId]) {
        if (isEqual(form, originalForm)) {
          const copy = { ...changedOrderForms };
          delete changedOrderForms[form.mRefId];
          setChangedOrderForms(copy);
        } else {
          setChangedOrderForms((prevState) => {
            return {
              ...prevState,
              [form.mRefId]: form,
            };
          });
        }
      }
    },
    [changedOrderForms, setChangedOrderForms],
  );

  const topLevels = useMemo(() => {
    const data: Record<string, OrderFormMemberType[]> = {};
    const ungrouped: OrderFormMemberType[] = [];
    for (const form of orderForms) {
      if (!form.mTitle) {
        ungrouped.push(form);
        continue;
      }

      if (data[form.mTitle]) {
        data[form.mTitle].push(form);
      } else {
        data[form.mTitle] = [form];
      }
    }

    const keys = Object.keys(data);
    keys.sort((a, b) => {
      if (a === b) return 0;
      return a > b ? 1 : -1;
    });

    const copy: Record<string, OrderFormMemberType[]> = {};
    for (const key of keys) {
      copy[key] = data[key];
    }

    return {
      tree: data,
      ungrouped,
    };
  }, [orderForms]);

  return (
    <ResizablePanelGroup direction="horizontal" style={{ height: '100%' }}>
      <ResizablePanel order={1} id="tasks" defaultSize={10} minSize={10} maxSize={25}>
        <SettingsListSection label="Task menu" onAddClick={() => setStartSection('')}>
          <Scrollbar style={{ height: 'calc(100% - 30px)' }}>
            {topLevels.ungrouped.map((o) => (
              <Box key={o.mRefId} width="100%">
                <SettingsListItem
                  color={o.mColor ?? undefined}
                  style={{ padding: '2px 0 2px 26px' }}
                  key={o.mRefId}
                  onClick={() => setSelectedFormId(o.mRefId)}
                  onDeleteClick={() => setOrderFormToDelete(o)}
                  hasChange={!!changedOrderForms[o.mRefId]}
                  label={o.mDescription}
                  selected={selected?.mRefId === o.mRefId}
                />
              </Box>
            ))}
            {Object.entries(topLevels.tree).map(([key, value]) => {
              return (
                <VStack key={key} width="100%">
                  <Box width="100%">
                    <SettingsListItem
                      header
                      onAddClick={() => setStartSection(key)}
                      addTooltip="Add new form to this section"
                      hasChange={false}
                      label={key !== '0' ? key : ''}
                      selected={false}
                    />
                  </Box>
                  {value.map((o) => (
                    <Box key={o.mRefId} width="100%">
                      <SettingsListItem
                        style={{ padding: o.mColor ? '2px 0 2px 12px' : '2px 0 2px 26px' }}
                        key={o.mRefId}
                        color={o.mColor ?? undefined}
                        onClick={() => setSelectedFormId(o.mRefId)}
                        onDeleteClick={() => setOrderFormToDelete(o)}
                        hasChange={!!changedOrderForms[o.mRefId]}
                        label={o.mDescription}
                        selected={selected?.mRefId === o.mRefId}
                      />
                    </Box>
                  ))}
                </VStack>
              );
            })}
          </Scrollbar>
        </SettingsListSection>
      </ResizablePanel>
      <VerticalResizeHandle lightBackground />
      <ResizablePanel order={2} defaultSize={25} minSize={10} id="config">
        <Scrollbar>
          {selected ? (
            <EditOrderFormProperties
              orderForm={selected}
              onFormChange={(changedForm: OrderFormMemberType) =>
                onFormChange(changedForm, selected)
              }
            />
          ) : (
            'Create your first order form'
          )}
        </Scrollbar>
      </ResizablePanel>
      <VerticalResizeHandle lightBackground />
      <ResizablePanel order={3} defaultSize={25} id="preview">
        <Scrollbar>
          <Box padding="6px">
            {selected && mdf && (
              <EditOrderProperties
                doPreview={{
                  mdf,
                  form: selected,
                  metadata,
                  assignee,
                  owner,
                  status,
                  setMetadata,
                  setAssignee,
                  setStatus,
                  setOwner,
                }}
              />
            )}
          </Box>
        </Scrollbar>
      </ResizablePanel>
      {startSection !== null && (
        <CreateOrderConfigDialog
          startSection={startSection}
          modalOpen={startSection !== null}
          setModalOpen={() => setStartSection(null)}
          setSelectId={(val) => setDoSelectId(val)}
        />
      )}
      <DeleteDialog
        open={Boolean(orderFormToDelete)}
        onClose={() => setOrderFormToDelete(null)}
        onClick={doDeleteForm}
        title="Delete order form?"
        message={`Are you sure you want to delete ${orderFormToDelete?.mTitle}?`}
      />
    </ResizablePanelGroup>
  );
}
