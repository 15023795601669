import { useCallback, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { useTheme } from '@emotion/react';
import { capitalize } from 'lodash';

import useMoveItemsToStory, { getItemsFromCache, getNotesFromCache } from 'api/useMoveItemsToStory';
import { ReactComponent as CopyIcon } from 'assets/icons/systemicons/copy.svg';
import { ReactComponent as MoveIcon } from 'assets/icons/systemicons/move_off.svg';
import { Button, RadioButton } from 'components/buttons';
import Dialog from 'components/dialogs/DialogBuilder';
import Text from 'components/text';
import { VStack } from 'layouts/box/Box';
import { useStoryPaneMolecule } from 'screens/storyV2/store/storyPane';
import { MemberType, MemberTypeEnum, MoveItemsToStoryInput } from 'types/graphqlTypes';

import useItemMoveConfigs from './utils/useItemMoveConfigs';
import { ReviewFailedItems } from './ComponentUtils';

import { StyledFormControlLabel } from './styled';

type Operation = 'copy' | 'move' | null;

function ItemMoveDialog() {
  const theme = useTheme();
  const client = useApolloClient();

  const { moveItemsToStory } = useMoveItemsToStory();
  const { useMoveItemDialog } = useStoryPaneMolecule();
  const [moveItemProps, setMoveItemProps] = useMoveItemDialog();

  const [selectedOperation, setSelectedOperation] = useState<Operation>(null);
  const [failedItemProps, setFailedItemProps] = useState<MemberType[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const {
    selectedItems,
    inputKeys,
    setSelectedItems,
    canMoveOrCopy,
    itemType,
    permissionLabel,
    radioLabel,
  } = useItemMoveConfigs() ?? {};

  const processFailedItems = useCallback(
    (
      input: MoveItemsToStoryInput,
      results: MemberType[] | undefined,
      properties: { parentId: string; itemType: 'note' | 'instance' },
    ) => {
      if (results && !input.copy) {
        const failedKeys = input.keys.filter(
          (item) =>
            !results.find((result) => {
              if (result.mType === MemberTypeEnum.Instance) return item.mId === result.mId;
              if (result.mType === MemberTypeEnum.Note) return item.mRefId === result.mRefId;
            }),
        );

        const failedItems =
          properties.itemType === 'note'
            ? getNotesFromCache(client, properties.parentId, failedKeys)
            : getItemsFromCache(client, failedKeys);

        if (failedItems.length) setFailedItemProps(failedItems);

        if (failedKeys.length) {
          const predicate = properties.itemType === 'note' ? 'mRefId' : 'mId';
          setSelectedItems?.(failedKeys.map((item) => item[predicate]));
          setSelectedOperation(null);
          return;
        }
        setSelectedItems?.([]);
      }

      setSelectedOperation(null);
      setMoveItemProps(null);
    },
    [setMoveItemProps, setSelectedOperation, setSelectedItems, setFailedItemProps],
  );

  const onConfirm = useCallback(
    async (operation: Operation) => {
      if (!moveItemProps || !inputKeys?.length || !itemType) return;

      const { targetStoryId, parentStoryId } = moveItemProps;

      const input: MoveItemsToStoryInput = {
        keys: inputKeys,
        targetStoryId: targetStoryId,
      };
      if (operation === 'copy') input.copy = true;

      setLoading(true);
      const results = await moveItemsToStory(input, parentStoryId);
      processFailedItems(input, results, { parentId: parentStoryId, itemType });
      setLoading(false);
    },
    [selectedItems, moveItemProps, setSelectedItems, inputKeys, processFailedItems],
  );

  const closeDialog = useCallback(() => {
    setFailedItemProps(null);
    setMoveItemProps(null);
    setSelectedOperation(null);
  }, []);

  const radioStrokeColor = selectedOperation ? undefined : theme.palette.dina.statusWarning;

  return (
    <Dialog open={!!moveItemProps} onClose={closeDialog}>
      {failedItemProps ? (
        <ReviewFailedItems failedItemProps={failedItemProps} />
      ) : (
        <>
          <Dialog.Header>Confirm Copy or Move</Dialog.Header>
          <Dialog.Body padding="16px">
            <VStack gap="8px">
              <Text variant="body2" color="highEmphasis" style={{ fontWeight: '700' }}>
                Please choose whether to Copy or Move the content:
              </Text>
              <VStack>
                <StyledFormControlLabel
                  control={
                    <RadioButton
                      size={20}
                      value="copy"
                      strokeColor={radioStrokeColor}
                      selected={selectedOperation === 'copy'}
                      onClick={setSelectedOperation}
                    />
                  }
                  label={`Copy ${radioLabel ?? ''}`}
                />
                <StyledFormControlLabel
                  control={
                    <RadioButton
                      size={20}
                      value="move"
                      strokeColor={radioStrokeColor}
                      selected={selectedOperation === 'move'}
                      onClick={setSelectedOperation}
                    />
                  }
                  label={`Move ${radioLabel ?? ''}`}
                />
              </VStack>
              <Text color="statusWarning" variant="body2">
                This action cannot be undone.
              </Text>
            </VStack>
          </Dialog.Body>
          <Dialog.Footer>
            <Dialog.CancelButton autoFocus label="Cancel" />
            {selectedOperation ? (
              <Dialog.ConfirmButton
                icon={
                  selectedOperation === 'move' ? (
                    <MoveIcon className="skipOverride" />
                  ) : (
                    <CopyIcon className="skipOverride" />
                  )
                }
                title={permissionLabel}
                loading={loading}
                disabled={!canMoveOrCopy}
                label={capitalize(selectedOperation)}
                usage={selectedOperation === 'copy' ? 'warning' : 'danger'}
                onClick={() => onConfirm(selectedOperation)}
              />
            ) : (
              <Button
                disabled
                usage="outlined"
                variant="outlined"
                width={180}
                style={{ opacity: '0.35' }}
              >
                Please make a choice
              </Button>
            )}
          </Dialog.Footer>
        </>
      )}
    </Dialog>
  );
}

export default ItemMoveDialog;
