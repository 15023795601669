import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    padding: 0,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  sidebarHeader: {
    height: '40px',
    paddingLeft: '8px',
    display: 'flex',
    alignItems: 'center',
  },
  sidebarContent: {
    flexGrow: 1,
  },
  divider: {
    position: 'relative',
    top: -1,
  },
  formControlLabel: {
    margin: 0,
  },
  label: {
    ...theme.typography.dina.caption,
    color: theme.palette.dina.highEmphasis,
  },
}));

export default useStyles;
