import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSlate } from 'slate-react';
import { useEditorContext } from 'components/editor/EditorContext';
import useStyles from './styles';
import insertBlock from '../iconButton/utils/insertBlock';
import insertWithNoSelection from './utils/insertWithNoSelection';

const TextButton = ({ type, label, icon, height, width, action }) => {
  const editor = useSlate();
  const { update } = useEditorContext();
  const classes = useStyles();

  const handleClick = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (editor.selection) insertBlock({ editor, update, type, data: {} });
      else insertWithNoSelection({ editor, update, type, data: {} });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <div
      className={classes.root}
      role="presentation"
      onClick={handleClick}
      style={{ height, width }}
    >
      {icon}
      <div className={classes.label}>{label}</div>
    </div>
  );
};

TextButton.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

TextButton.defaultProps = {
  height: 24,
  width: 120,
};

export default memo(TextButton);
