import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { ReactComponent as DeleteIcon } from 'assets/icons/systemicons/close.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/systemicons/email.svg';
import { ReactComponent as ChatResting } from 'assets/icons/systemicons/HeaderNavbar/chat_resting.svg';
import Text from 'components/text/Text';

export const Delete = styled(DeleteIcon)`
  height: 24px;
  width: 0px;
  transition: width 200ms;
  ${({ disabled }: { disabled?: boolean }) =>
    disabled
      ? css`
          opacity: 0.35;
        `
      : css`
          cursor: pointer;
          :hover {
            path {
              fill-opacity: 1;
            }
          }
        `}
`;

export const SelectedMembersWrapper = styled('div')`
  padding: 6px 0px;
`;

export const Chat = styled(ChatResting)`
  cursor: pointer;
  height: 20px;
  width: 0px;
  margin: 2px;
  transition: width 200ms;
  :hover {
    path {
      fill-opacity: 1;
    }
  }
`;

export const Email = styled(EmailIcon)`
  height: 20px;
  cursor: pointer;
  width: 0px;
  margin: 2px;
  transition: width 200ms;
  :hover {
    path {
      fill-opacity: 1;
    }
  }
`;

export const SelectMember = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 7px;
  margin-inline: -6px;
  border-radius: 6px;
  position: relative;

  &:hover {
    background: rgba(255, 255, 255, 0.05);

    ${Delete} {
      width: 24px;
    }
    ${Email} {
      width: 20px;
    }
    ${Chat} {
      width: 20px;
    }
  }
`;

export const SelectMemberAvatar = styled('div')`
  display: flex;
`;

export const SelectMemberTitleWrapper = styled('div')`
  height: 24px;
  flex: 1;
  display: flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SelectMemberTitle = styled(Text)`
  :hover {
    text-decoration: underline;
  }
`;

export const SelectMemberHeader = styled('div')`
  ${({ theme }) => css`
    ${theme.typography.dina.overline};
    border-bottom: 1px solid ${theme.palette.dina.dividerLight};
  `}
  padding: 10px 16px 6px 4px;
  margin-bottom: 6px;
  font-weight: 600;
`;

export const Label = styled('div')`
  ${({ theme }) => theme.typography.dina.overline};
  padding: 16px 16px 4px 4px;
  font-weight: 600;
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  width: max-content;
  margin-left: 0;

  .MuiFormControlLabel-label {
    font-size: 14px;
  }
`;
