/* eslint-disable no-sequences */
/* eslint-disable import/prefer-default-export */
import { useEffect } from 'react';
import { atom, useAtom, useSetAtom } from 'jotai';
import { focusAtom } from 'jotai-optics';
import uuidv4 from 'uuid/v4';

/** Decks */
const decksAtom = atom({
  feedDecks: [],
});

// Feed decks --------------------------------------------
export const feedDecksAtom = focusAtom(decksAtom, (optic) => optic.prop('feedDecks'));
/** Get and/or update the feed decks */
export const useFeedDecks = () => useAtom(feedDecksAtom);

// Create --------------------------------------------
const createDeck = atom(null, (get, set) => {
  const decks = get(feedDecksAtom);
  const updatedDecks = [...decks, { id: uuidv4(), title: 'New deck', providers: [], filters: [] }];
  set(updateFeedDecksAtom, updatedDecks);
});
/** Create a new deck */
export const useCreateDeck = () => useSetAtom(createDeck);

// Delete --------------------------------------------
const deleteFeedDeckAtom = atom(null, (get, set, nextValue) => {
  const { id } = nextValue;
  const decks = get(feedDecksAtom);

  if (!id || !decks.length > 0) return;

  set(updateFeedDecksAtom, [...decks.filter((deck) => deck.id !== id)]);
});
/** Delete feed deck, input: { id } */
export const useDeleteFeedDeck = () => useSetAtom(deleteFeedDeckAtom);

// Update title--------------------------------------------
const updateFeedDeckTitle = atom(null, (get, set, nextValue) => {
  const { id, title = '' } = nextValue;
  const decks = get(feedDecksAtom);

  if (!id || !decks.length > 0) return;

  const updatedDecks = decks.map((deck) => {
    if (deck.id === id) {
      return { ...deck, title };
    }
    return deck;
  });
  set(updateFeedDecksAtom, updatedDecks);
});
/** Update deck title, input: { id, title } */
export const useUpdateFeedDeckTitle = () => useSetAtom(updateFeedDeckTitle);

const removeEmpty = (values) =>
  Object.keys(values).reduce((acc, k) => (!values[k] && delete acc[k], acc), values);

// Update filters --------------------------------------------
const updateFeedDeckFilters = atom(null, (get, set, nextValue) => {
  const { id, filters = {} } = nextValue;
  const decks = get(feedDecksAtom);

  if (!id || !decks.length > 0) return;

  const newFilters = { key: 'filters', value: removeEmpty(filters) };

  const updatedDecks = decks.map((deck) => {
    if (deck.id === id) {
      const noDupes = deck.filters?.filter((f) => f.key !== 'filters');
      return { ...deck, filters: [...noDupes, newFilters] };
    }
    return deck;
  });

  set(updateFeedDecksAtom, updatedDecks);
});
/** Update feed deck filters, input: { id, filters } */
export const useUpdateFeedDeckFilters = () => useSetAtom(updateFeedDeckFilters);

// Update providers--------------------------------------------
const updateFeedDeckProviders = atom(null, (get, set, nextValue) => {
  const { id, providers = [] } = nextValue;
  const decks = get(feedDecksAtom);

  if (!id || !decks.length > 0) return;

  const updatedDecks = decks.map((deck) => {
    if (deck.id === id) return { ...deck, providers };
    return deck;
  });

  set(updateFeedDecksAtom, updatedDecks);
});
/** Update feed deck providers, input: { id, providers } */
export const useUpdateFeedDeckProviders = () => useSetAtom(updateFeedDeckProviders);

const updateFeedDecksAtom = atom(
  (get) => get(feedDecksAtom),
  (get, set, val) => {
    const prevVal = get(feedDecksAtom);
    set(feedDecksAtom, val);
    const newVal = get(feedDecksAtom);
    get(listenersAtom).forEach((callback) => {
      callback(newVal, prevVal);
    });
  },
);

const listenersAtom = atom([]);

/** Callback hook, used for storing changes when `updateFeedDecksAtom` is updated */
export function decksUpdatedListener() {
  const useListener = (callback) => {
    const setListeners = useSetAtom(listenersAtom);
    useEffect(() => {
      setListeners((prev) => [...prev, callback]);
      return () =>
        setListeners((prev) => {
          const index = prev.indexOf(callback);
          return [...prev.slice(0, index), ...prev.slice(index + 1)];
        });
    }, [setListeners, callback]);
  };
  return [useListener];
}
