import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { sortBy } from 'lodash';

const findNearest = (value: number, sortedBreakpoints: Breakpoint[]): string => {
  const label: string = 'max';

  const max = sortedBreakpoints[sortedBreakpoints.length - 1];
  if (max.breakpoint < value) {
    return 'max';
  }

  for (let i = sortedBreakpoints.length - 1; i >= 0; i--) {
    const val = sortedBreakpoints[i];
    const nextVal = sortedBreakpoints[i - 1];
    if (value <= val.breakpoint && (!nextVal || value > nextVal.breakpoint)) return val.label;
  }
  return label;
};

type Breakpoint = {
  label: string;
  breakpoint: number;
};

/**
 * An optimized breakpoint detection, will only change state
 * on break point changes, and is therefore quite performant.
 *
 * EG:
 *
 * {
 *    small: 100,
 *    medium: 200,
 * }
 *
 * Will fire a change to small if width is smaller than 100, and medium if width
 * is smaller than 200 but higher than 100.
 *
 * Hook will fire with "max" if the current width is higher than the highest provided value.
 *
 */
const useMonitorSize = <T extends HTMLElement>(breakPoints: Record<string, number>) => {
  const [size, setSize] = useState<string>('max');
  const elementRef = useRef<HTMLElement>();
  const resizerRef = useRef<ResizeObserver>();

  // make sure the 'max' prop is not set from outside.
  if (Object.keys(breakPoints).includes('max')) {
    throw new Error('max property not supported as the hook may return it');
  }

  const sortedBreakpoints = useMemo(() => {
    const sorted: Breakpoint[] = [];
    Object.entries(breakPoints).forEach(([key, value]) => {
      sorted.push({
        label: key,
        breakpoint: value,
      });
    });
    return sortBy(sorted, (b) => b.breakpoint);
  }, [breakPoints]);

  const ref = useCallback(
    (node: T) => {
      if (!node) return;

      if (elementRef.current !== node) {
        elementRef.current = node;
      }

      resizerRef.current = new ResizeObserver(() => {
        setSize(findNearest(node.clientWidth, sortedBreakpoints));
      });
      resizerRef.current.observe(node);
    },
    [setSize, sortedBreakpoints],
  );

  useEffect(() => {
    return () => {
      if (elementRef.current) resizerRef.current?.unobserve(elementRef.current);
      resizerRef?.current?.disconnect();
    };
  }, []);

  return { ref, size };
};

export default useMonitorSize;
