// eslint-disable-next-line sort-imports
import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';

import { ReactComponent as DesktopIconComponent } from 'assets/icons/systemicons/desktop.svg';
import { ReactComponent as TabletIconComponent } from 'assets/icons/systemicons/landscape_off.svg';
import { ReactComponent as LandscapeIconComponent } from 'assets/icons/systemicons/mobile_landscape.svg';
import { ReactComponent as PortraitIconComponent } from 'assets/icons/systemicons/mobile_portait.svg';

export const getIconStyles = ({ theme }: { theme: Theme }) => css`
  & path {
    fill: ${theme.palette.dina.highEmphasis};
    fill-opacity: 1;
    transition: 0.3s ease;
  }
`;

export const PortraitIcon = styled(PortraitIconComponent)`
  ${({ theme }) => getIconStyles({ theme })}
`;
export const LandscapeIcon = styled(LandscapeIconComponent)`
  ${({ theme }) => getIconStyles({ theme })}
`;
export const TabletIcon = styled(TabletIconComponent)`
  ${({ theme }) => getIconStyles({ theme })}
`;
export const DesktopIcon = styled(DesktopIconComponent)`
  ${({ theme }) => getIconStyles({ theme })}
`;

export const TabsContainer = styled('div')`
  width: 100%;
  height: 48px;
`;

export const IframeContainer = styled('div')`
  width: 100%;
  height: calc(100% - 164px);
  background-color: ${(props) => props.theme.palette.dina.statusOnPress};
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

export const Iframe0 = styled('div')`
  width: 375px;
  height: 740px;
  padding: 20px;
`;

export const Iframe1 = styled('div')`
  width: 723px;
  height: 375px;
`;

export const Iframe2 = styled('div')`
  width: 1024px;
  height: 768px;
  padding: 25px;
`;

export const Iframe3 = styled('div')`
  width: 100%;
  height: 100%;
`;

export const Iframe = styled('iframe')<{ hasPreview?: boolean }>`
  width: 100%;
  height: 100%;
  border: none;
  background-color: ${(props) =>
    props.hasPreview
      ? props.theme.palette.dina.highEmphasis
      : props.theme.palette.dina.statusOnPress};
`;
