import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AutoCompleteBase from 'components/autoCompleteBase';
import ChipGroup from 'components/editor/components/tags/components/chipGroup';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import GeneralLabel from '../generalLabel';
import SeverityIndicator from '../severityIndicator';
import ContainerBox from '../containerBox';

const ComboBoxView = ({ label, severity, usage, options, renderedValue, onChange }) => {
  const parsedValue = renderedValue ? JSON.parse(renderedValue) : [];

  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const handleChange = (event, newObj) => {
    event.preventDefault();
    if (newObj) {
      const isIncluded = parsedValue.find((opt) => opt.value === newObj.value);

      if (!isIncluded) {
        const newData = [...parsedValue, { id: newObj.id, value: newObj.value }];
        onChange(JSON.stringify(newData));
      }

      setInputValue('');
      setValue(null);
    }
  };

  const removeChip = (event, index) => {
    event.preventDefault();
    const newData = parsedValue.filter((_, pos) => pos !== index);
    onChange(JSON.stringify(newData));
  };

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    getClearProps,
    groupedOptions,
    getPopupIndicatorProps,
    popupOpen,
  } = useAutocomplete({
    id: 'source-box',
    options,
    autoHighlight: true,
    getOptionLabel: (option) => option.value,
    value,
    onChange: handleChange,
    inputValue,
    onInputChange: (_, newValue) => setInputValue(newValue),
  });

  return (
    <ContainerBox
      Indicator={<SeverityIndicator {...{ severity }} />}
      Label={<GeneralLabel {...{ severity, label, usage }} />}
      Field={
        <div>
          <AutoCompleteBase
            content={parsedValue}
            value={inputValue}
            inputUsage="metadata"
            listWidthOffset={210}
            placeholder={`Start typing to find ${label}...`}
            {...{
              getRootProps,
              getInputProps,
              getListboxProps,
              getOptionProps,
              getClearProps,
              groupedOptions,
              getPopupIndicatorProps,
              popupOpen,
            }}
          />
          <ChipGroup list={parsedValue} removeChip={removeChip} />
        </div>
      }
      usage={usage}
    />
  );
};

ComboBoxView.propTypes = {
  /** label for the composite field */
  label: PropTypes.string,
  /** color for label, indicator and input underline */
  severity: PropTypes.string,
  /** input label  */
  inputLabel: PropTypes.string,
  /** input assistive text */
  inputDescription: PropTypes.string,
};

ComboBoxView.defaultProps = {
  label: 'Label',
  severity: 'regular',
  inputLabel: 'Input Label',
  inputDescription: '',
};

export default ComboBoxView;
