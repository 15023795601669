import { BaseEditor } from 'slate';

import isSection from './isSection';

const isSelectionInsideSectionDivider = (editor: BaseEditor) => {
  const selectedNodes = editor.getFragment();
  return selectedNodes?.length === 1 && isSection(selectedNodes[0]);
};

export default isSelectionInsideSectionDivider;
