import DropdownMenu from 'components/dropdownMenu/DropdownMenu';
import PlatformIcons from 'components/menu/createInstanceMenu/PlatformIcons';
import { HStack } from 'layouts/box/Box';
import { Platform } from 'types';
import { AccountType } from 'types/graphqlTypes';
import { getPlatformIcon, getPlatformLabel, PlatformData } from 'utils/instance/platform';

interface Props {
  item: Platform;
  setSelectedPlatform: (platform: PlatformData) => void;
}

export function DropdownSubItems({ item, setSelectedPlatform }: Readonly<Props>) {
  const { mProperties } = item;
  const { platformIcon, platform, accounts = [], platformKind } = mProperties;

  const Icon = PlatformIcons[platformIcon as string] ?? getPlatformIcon(platform, platformKind);
  const label = getPlatformLabel(platform, platformKind);

  const handleSelectPlatform = (account: AccountType) => {
    setSelectedPlatform({
      platform,
      account,
      platformKind,
    });
  };

  return (
    <DropdownMenu.Sub key={platformKind ?? platform}>
      <DropdownMenu.SubTrigger count={accounts.length}>
        <Icon />
        {label}
      </DropdownMenu.SubTrigger>
      <DropdownMenu.Portal>
        <DropdownMenu.SubContent>
          {accounts.map((account, index) => (
            <DropdownMenu.Item
              key={account.accountTitle + index.toString()}
              onSelect={() => handleSelectPlatform(account as AccountType)}
            >
              <HStack gap="6px">
                <Icon /> {account.accountTitle}
              </HStack>
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.SubContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Sub>
  );
}
