import styled from '@emotion/styled';

import { ReactComponent as ArrowIcon } from 'assets/icons/systemicons/arrows/arrow_double_unboxed.svg';
import { ReactComponent as EmojiSvg } from 'assets/icons/systemicons/editor/emoji_small.svg';

export const EmojiButton = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
  :hover {
    svg > path {
      fill-opacity: 1;
    }
  }
`;

export const Arrow = styled(ArrowIcon)`
  path {
    fill: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
`;

export const EmojiIcon = styled(EmojiSvg)`
  path {
    fill: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
`;
