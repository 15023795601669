import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: '1400',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
    height: '100%',
  },
  text: {
    ...theme.typography.dina.listItemLabel,
    color: theme.palette.dina.mediumEmphasis,
    textTransform: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    '&:hover': {
      borderRadius: '2px',
      backgroundColor: theme.palette.dina.whiteHoverOverlay,
      boxShadow: `inset 0 0 0 1px ${theme.palette.dina.inputBorderResting}`,
    },
  },
  input: {
    ...theme.typography.dina.listItemLabel,
    color: theme.palette.dina.mediumEmphasis,
    textTransform: 'none',
    width: '100%',
    height: '100%',
  },
  focused: {
    outline: 'blue',
    borderRadius: '2px',
    backgroundColor: theme.palette.dina.blackHoverOverlay,
    boxShadow: `inset 0 0 0 1px ${theme.palette.dina.onFocusOpacity}`,
  },

  popperContainer: {
    backgroundColor: theme.palette.dina.surfaceCardDark,
    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.25), 0px 0px 5px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '200px',
    width: '90px',
    margin: '3px',
  },
  listItem: {
    height: '40px',
    padding: '0px 10px 0px 16px',
    '&$selected, &$selected:hover': {
      backgroundColor: theme.palette.dina.onSelected,
    },
    '&:hover': {
      backgroundColor: theme.palette.dina.statusOnHover,
    },
  },
  textRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    ...theme.typography.dina.listItemLabel,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  selected: {},
  check: {
    '& path': {
      'fill-opacity': 1,
    },
  },
}));

export default useStyles;
