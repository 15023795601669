import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const MetadataWrapper = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Header = styled('div')`
  ${({ theme }) => css`
    background: ${theme.palette.dina.surfaceCard};
    background-color: ${theme.palette.dina.backgroundResting};
    border-bottom: 1px solid ${theme.palette.dina.dividerLight};
  `};
  padding-inline: 12px;
  height: 40px;
  display: flex;
  align-items: center;
`;

export const MetadataFieldsWrapper = styled('div')`
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
