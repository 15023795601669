import { gql } from '@apollo/client';

export default gql`
  query searchMembers($input: SearchItemInput) {
    searchItem(input: $input) {
      total
      nextToken
      items {
        mId
        mRefId
        metadata
        mdfId
        mType
        mTitle
        mDescription
        mAvatarKey
      }
    }
  }
`;
