import { format, isToday, isTomorrow, isYesterday } from 'date-fns';

const getRelativeDateTime = (
  date: string | number | Date,
  fillerString = ' at ',
  showRelative = true,
  dateFormat = 'D MMM[.] GGGG HH:mm',
  timeFormat = 'HH:mm',
) => {
  const timeString = format(date, timeFormat);

  if (!showRelative) return timeString;

  if (isToday(date)) return `today${fillerString}${timeString}`;

  if (isTomorrow(date)) return `tomorrow${fillerString}${timeString}`;

  if (isYesterday(date)) return `yesterday${fillerString}${timeString}`;

  return `${format(date, dateFormat)}`;
};

export default getRelativeDateTime;
