import { useCallback, useContext } from 'react';
import { useMutation } from '@apollo/client';

import UserContext from 'contexts/UserContext';

import { GET_ORDERING, UPDATED_ORDERING } from './gql';
import { Ordering, OrderingEnum } from './types';
import { orderingKey } from './utils';

interface UpdateOrdering {
  updateOrdering: Ordering;
}

export const useUpdateOrdering = (type: OrderingEnum) => {
  const { mId } = useContext(UserContext);
  const [updateOrderingMutation] = useMutation<UpdateOrdering>(UPDATED_ORDERING);
  const updateOrdering = useCallback(
    async (order: string[]) => {
      await updateOrderingMutation({
        variables: { input: { type, order } },
        optimisticResponse: {
          updateOrdering: {
            id: orderingKey(type, mId),
            type,
            order,
          },
        },
        update: (proxy) => {
          proxy.writeQuery({
            query: GET_ORDERING,
            variables: {
              input: {
                type,
              },
            },
            data: {
              getOrdering: {
                id: orderingKey(type, mId),
                type,
                order,
              },
            },
          });
        },
      });
    },
    [updateOrderingMutation, type],
  );

  return {
    updateOrdering,
  };
};
