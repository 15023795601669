import { Editor } from 'slate';

import isUrl from 'utils/isUrl';

import wrapLink from './wrapLink';

/**
 * Wraps editor with overriden plugin functionalites
 *
 * @param editor SlateJS editor instance
 * @returns SlateJS editor instance
 */

const withLink = (editor: Editor) => {
  const { insertData, insertText } = editor;

  editor.insertText = (text) => {
    if (text && isUrl(text)) wrapLink(editor, text?.replace(/\t/g, ' '));
    else insertText(text?.replace(/\t/g, ' '));
  };

  editor.insertData = (data) => {
    const text = data.getData('text/plain');

    if (text && isUrl(text)) wrapLink(editor, text?.replace(/\t/g, ' '));
    else insertData(data);
  };

  return editor;
};

export default withLink;
