import gql from 'graphql-tag';

export default gql`
  query GetFeed($input: GetFeedsInput, $limit: Int) {
    getFeeds(input: $input, searchIndex: "feeds", limit: $limit) {
      __typename
      nextToken
      items {
        __typename
        provider
        mId
        version
        mPublishedAt
        section
        priority
        pubstatus
        ednotes
        newscodes
        mTitle
        byline
        mContent
        mDescription
        mediatopics
        location
        embargoed
        href
        copyrightnotice
        usageterms
        infosource
        storyList
        language
        firstcreated
        versioncreated
        revision
        slugline
        assets {
          duration
          headline
          byline
          type
          renditions {
            href
            uri
            mimetype
            duration
            videoaspectratio
            format
            height
            width
            filename
            duration
            rendition
            sizeinbytes
            fileextension
            orientation
            previewUri
            thumbnailUri
            videoPreviewUri
          }
        }
      }
    }
  }
`;
