import React from 'react';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';
import { ContainerWrapper, RootWrapper, TypographyWrapper } from './styled';

const DatePicker = ({
  text,
  isToday,
  isSelected,
  isOtherMonth,
  isWeekend,
  todaySelectorType,
  rangePosition,
  disabled,
  selectingRange,
}) => {
  let containerClassName = null;
  let rootClassName = 'root';
  if (isToday) rootClassName = 'rootToday';
  if (isToday && todaySelectorType === 'diamond') rootClassName = 'rootTodaySelected';
  if (isToday && todaySelectorType === 'cropped' && !isSelected) containerClassName = 'container';
  if (isSelected) rootClassName = 'rootSelected';
  if (isSelected && rangePosition) rootClassName = `rootSelected${capitalize(rangePosition)}`;

  let textClassName = 'text';
  if (isWeekend && !isSelected) textClassName = 'textWeekend';
  if (isOtherMonth) textClassName = 'textDimmed';
  if (isToday && todaySelectorType === 'diamond' && !isSelected)
    textClassName = 'textTodaySelectedDiamond';
  if (isSelected) textClassName = 'textSelected';

  return (
    <ContainerWrapper $containerType={containerClassName}>
      <RootWrapper $rootType={rootClassName} $disabled={disabled} $selectingRange={selectingRange}>
        <TypographyWrapper $typographyType={textClassName}>{text}</TypographyWrapper>
      </RootWrapper>
    </ContainerWrapper>
  );
};

DatePicker.propTypes = {
  /** text within the date picker */
  text: PropTypes.string,
  /** boolean that indicates today's date */
  isToday: PropTypes.bool,
  /** boolean that indicates a selected date */
  isSelected: PropTypes.bool,
  /** boolean that indicates that the date is of different month */
  isOtherMonth: PropTypes.bool,
  /** boolean that indicates a weekend */
  isWeekend: PropTypes.bool,
  /** defines the type of today selector */
  todaySelectorType: PropTypes.oneOf(['default', 'cropped', 'diamond']),
  /** Position of datePicker in the selected range  */
  rangePosition: PropTypes.string,
  /** show hover effect on range selecting */
  selectingRange: PropTypes.bool,
};

DatePicker.defaultProps = {
  text: '',
  isToday: false,
  isSelected: false,
  isOtherMonth: false,
  isWeekend: false,
  todaySelectorType: 'diamond',
  rangePosition: '',
  selectingRange: false,
};

export default DatePicker;
