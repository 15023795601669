import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';

import useDebounce from 'hooks/useDebounce';

import { Chips } from './components/Chips';
import { Command } from './components/Command';
import { MultiSelectContext } from './hooks/useMultiSelect';
import { transToGroupOption } from './utils/utils';
import { type GroupOption, type MultiSelectProps, type MultiSelectRef, type Option } from './types';

/**
 * Multi-select component.
 * @example
 * <MultiSelect>
 *  <MultiSelectInput />
 *  <MultiSelectList />
 * </MultiSelect>
 */
const MultiSelect = forwardRef<MultiSelectRef, MultiSelectProps>(
  (
    {
      commandProps,
      creatable = false,
      defaultOptions: arrayDefaultOptions = [],
      delay,
      groupBy,
      onChange,
      onSearch,
      options: arrayOptions,
      triggerSearchOnFocus = false,
      value,
      children,
    }: MultiSelectProps,
    ref: React.Ref<MultiSelectRef>,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const mouseOn = useRef<boolean>(false);

    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selected, setSelected] = useState<Option[]>(value || []);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState<GroupOption>(
      transToGroupOption(arrayDefaultOptions, groupBy),
    );

    const debouncedSearchTerm = useDebounce(inputValue, delay);

    const handleUnselect = useCallback(
      (option: Option) => {
        const newOptions = selected.filter((s) => s.value !== option.value);
        setSelected(newOptions);
        onChange?.(newOptions);
      },
      [onChange, selected],
    );

    useImperativeHandle(
      ref,
      () => ({
        selectedValue: [...selected],
        input: inputRef.current as HTMLInputElement,
        focus: () => inputRef.current?.focus(),
      }),
      [selected],
    );

    useEffect(() => {
      if (value) setSelected(value);
    }, [value]);

    useEffect(() => {
      /** If `onSearch` is provided, do not trigger options updated. */
      if (!arrayOptions || onSearch) {
        return;
      }
      const newOption = transToGroupOption(arrayOptions || [], groupBy);
      if (JSON.stringify(newOption) !== JSON.stringify(options)) {
        setOptions(newOption);
      }
    }, [arrayDefaultOptions, arrayOptions, groupBy, onSearch, options]);

    useEffect(() => {
      const doSearch = async () => {
        setIsLoading(true);
        const res = await onSearch?.(debouncedSearchTerm);
        setOptions(transToGroupOption(res || [], groupBy));
        setIsLoading(false);
      };

      const exec = async () => {
        if (!onSearch || !open) return;
        if (triggerSearchOnFocus) await doSearch();
        if (debouncedSearchTerm) await doSearch();
      };

      void exec();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchTerm, groupBy, open, triggerSearchOnFocus]);

    return (
      <MultiSelectContext.Provider
        value={{
          commandProps,
          creatable,
          debouncedSearchTerm,
          handleUnselect,
          inputRef,
          inputValue,
          isLoading,
          mouseOn,
          onChange,
          onSearch,
          open,
          options,
          selected,
          setInputValue,
          setOpen,
          setSelected,
          triggerSearchOnFocus,
        }}
      >
        <div>
          <Command {...commandProps}>{children}</Command>
          {selected.length > 0 && (
            <Chips {...{ onChange, handleUnselect, setInputValue, selected, setSelected }} />
          )}
        </div>
      </MultiSelectContext.Provider>
    );
  },
);

export default MultiSelect;
MultiSelect.displayName = 'MultiSelect';
