import { MouseEventHandler, useCallback, useEffect, useMemo } from 'react';
import { DragPreviewImage, useDrag } from 'react-dnd';
import { useQuery } from '@apollo/client';

import DragMultiple from 'assets/images/rundown/DragMultiple.png';
import DragSingle from 'assets/images/rundown/DragSingle.png';
import useImageUrl from 'hooks/useImageUrl';
import GET_INSTANCE_FROM_CACHE from 'operations/queries/getInstanceFromLocalResolver';
import { PreviewType, useClosePreview, usePreviewValue, useSetPreview } from 'store/preview';
import { Instance } from 'types';
import { MemberType } from 'types/graphqlTypes';
import accessibleOnClick from 'utils/accessibleOnClick';
import distanceInWord from 'utils/distanceInWords';
import dndTypes from 'utils/dndTypes';
import isFloated from 'utils/instance/isFloat';
import { defaultLinearPlatformKind } from 'utils/instance/platform';
import instanceVariants from 'utils/instance/variants';

import { useDetailsMolecule } from '../store';

import InstanceItem from './InstanceItem';

import { EmptyDiv } from './styled';

const getSortedArrayByKey = (selectedIds: Record<string, string> = {}) =>
  Object.keys(selectedIds)
    .sort((a, b) => +a - +b)
    .map((key) => selectedIds[key]);

interface DraggableInstanceItemProps {
  mId: string;
  index: number;
  order: string;
  onPreviewClose: () => void;
}

const shouldClosePreview = (previewValue: PreviewType, mId: string) => {
  if (typeof previewValue === 'string') return previewValue === mId;
  return previewValue?.mId === mId;
};

function DraggableInstanceItem({
  mId,
  index,
  order,
  onPreviewClose,
}: Readonly<DraggableInstanceItemProps>) {
  const {
    useSelectedIdsValue,
    useCurrentOrderValue,
    useSelectedRundown,
    useSetCurrentOrder,
    useSetSelectSingleItem,
    useSetToggleCurrentItemFromSelectedIds,
    useSetShiftItemsToSelectedIds,
  } = useDetailsMolecule();

  const selectedIds = useSelectedIdsValue();
  const currentOrder = useCurrentOrderValue();
  const [selectedRundown] = useSelectedRundown();
  const changeCurrentOrder = useSetCurrentOrder();
  const selectSingleItem = useSetSelectSingleItem();
  const toggleCurrentItemFromSelectedIds = useSetToggleCurrentItemFromSelectedIds();
  const shiftItemsToSelectedIds = useSetShiftItemsToSelectedIds();
  const previewValue = usePreviewValue();
  const setPreview = useSetPreview();
  const closePreview = useClosePreview();

  const selected = useMemo(() => selectedIds[index] === mId, [index, mId, selectedIds]);

  const [, dragRef, preview] = useDrag({
    type: dndTypes.INSTANCE,
    item: {
      type: dndTypes.INSTANCE,
      payload: {
        id: mId,
        ids: selected ? getSortedArrayByKey(selectedIds) : [mId],
        platform: instanceVariants.LINEAR,
        platformKind: selectedRundown?.platformKind || defaultLinearPlatformKind,
      },
    },
  });

  const getPreviewImage = () => {
    if (!selected) return DragSingle;
    return Object.entries(selectedIds).length > 1 ? DragMultiple : DragSingle;
  };

  const previewImage = getPreviewImage();

  const { data } = useQuery<{ instance: Instance } | undefined>(GET_INSTANCE_FROM_CACHE, {
    variables: {
      input: {
        mId,
        mRefId: mId,
      },
    },
  });

  const onDragStart = () => {
    if (!selected) selectSingleItem({ id: mId, index });
  };

  const handleClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      const dataObject = { ignoreSelectedIds: false };
      if (order !== currentOrder) {
        changeCurrentOrder(order);
        dataObject.ignoreSelectedIds = true;
      }

      if (!(event.altKey || event.ctrlKey || event.metaKey || event.shiftKey)) {
        if (shouldClosePreview(previewValue, mId)) {
          setPreview(null);
          closePreview();
        }
        setPreview((data?.instance as MemberType) ?? null);
        selectSingleItem({ id: mId, index });
        return;
      }

      if (event.ctrlKey || event.metaKey) {
        if (dataObject.ignoreSelectedIds) {
          selectSingleItem({ id: mId, index });
        } else {
          setPreview(null);
          toggleCurrentItemFromSelectedIds({ id: mId, index });
        }
        return;
      }
      if (event.shiftKey) {
        shiftItemsToSelectedIds({
          index,
          orderType: order,
          ignoreSelectedIds: dataObject.ignoreSelectedIds,
        });
      }
    },
    [
      changeCurrentOrder,
      currentOrder,
      data?.instance,
      index,
      mId,
      order,
      selectSingleItem,
      setPreview,
      shiftItemsToSelectedIds,
      toggleCurrentItemFromSelectedIds,
      previewValue,
    ],
  );

  const { mTitle, items, mUpdatedAt, mMetaData, mThumbnailKey } = data?.instance || {};

  const thumbnail = useImageUrl(mThumbnailKey ?? '');

  const isFloat = isFloated(mMetaData || []);

  const timeString = distanceInWord(mUpdatedAt ?? '');

  useEffect(() => {
    if (Object.getOwnPropertyNames(selectedIds).length > 1 && selected) {
      onPreviewClose();
      closePreview();
      return;
    }
    if (!previewValue && selected && Object.getOwnPropertyNames(selectedIds).length === 1) {
      onPreviewClose();
      closePreview();
    }
  }, [
    closePreview,
    index,
    mId,
    previewValue,
    selected,
    selectedIds,
    toggleCurrentItemFromSelectedIds,
  ]);

  if (!data) return <EmptyDiv />;

  return (
    <div ref={dragRef} onDragStart={onDragStart} {...accessibleOnClick(handleClick, 'button')}>
      <DragPreviewImage connect={preview} src={previewImage} />
      <InstanceItem
        title={mTitle}
        thumbnail={thumbnail}
        platformKind={selectedRundown?.platformKind}
        items={items}
        timingInfo={timeString}
        selected={selected}
        showFocus={(previewValue as MemberType)?.mId === mId}
        isFloat={isFloat}
        showDivider
      />
    </div>
  );
}

export default DraggableInstanceItem;
