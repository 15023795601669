import { useState, useRef, useImperativeHandle, forwardRef } from 'react';

import Dialog from 'components/dialog';
import { AddDialog } from 'features/feedViewer/components';

import useUpdateElementStyle from './hooks/useUpdateElementStyle';
import StoryListDialog from '../storyListDialog';
import { useCreateMemberDialog } from 'components/createNewV3/CreateNewDialog';

const initialAnchorData = {
  anchorEl: null,
  dialog: null,
  data: null,
};

const dialogVariants = {
  CREATE: 'create',
  STORY_LIST: 'storyList',
  ADD: 'add',
};

const FeedItemDialogs = forwardRef((_props, ref) => {
  const [, showCreateMemberDialog] = useCreateMemberDialog();
  const [anchorData, setAnchorData] = useState(initialAnchorData);
  const [addClass, removeClass] = useUpdateElementStyle();
  const previousAnchorRef = useRef(null);

  const assignAnchor = (currentTarget, data, dialogVariant) => {
    if (previousAnchorRef.current) removeClass(previousAnchorRef.current);
    setAnchorData({ anchorEl: currentTarget, dialog: dialogVariant, data });
    previousAnchorRef.current = currentTarget;
    addClass(currentTarget);
  };

  useImperativeHandle(ref, () => ({
    onStoryCountClick(...args) {
      assignAnchor(...args, dialogVariants.STORY_LIST);
    },
  }));

  const openCreateDialog = (e, data) => {
    showCreateMemberDialog({
      mId: data.mId,
      mTitle: data?.mTitle,
      provider: data?.provider,
      anchorEl: e.currentTarget,
      useFeedMutation: true,
    });
  };

  const openAddDialog = () => setAnchorData({ ...anchorData, dialog: dialogVariants.ADD });
  const closeDialog = () => {
    removeClass(anchorData?.anchorEl);
    setAnchorData(initialAnchorData);
  };

  const { dialog, data, anchorEl } = anchorData;

  return (
    <>
      {dialog === dialogVariants.CREATE ? (
        <div>TODO - Refactor this component.</div>
      ) : (
        <Dialog open={Boolean(anchorEl)} onClose={closeDialog}>
          {dialog === dialogVariants.STORY_LIST && (
            <StoryListDialog
              data={data}
              onClose={closeDialog}
              onAddToFeed={openAddDialog}
              onCreateNew={(ev) => openCreateDialog(ev, data)}
            />
          )}
          {dialog === dialogVariants.ADD && (
            <AddDialog provider={data.provider} feedId={data.mId} close={closeDialog} />
          )}
        </Dialog>
      )}
    </>
  );
});

export default FeedItemDialogs;
