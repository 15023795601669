import styled from '@emotion/styled';

import transientOptions from 'theme/helpers';

import TextField from '@material-ui/core/TextField';

export const DTInputWrapper = styled('div', transientOptions)`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.5rem;
  height: 40px;

  border: 1px solid ${({ theme }) => theme.palette.dina.buttonBorderOutlined};
  border-radius: 6px;

  &:focus-within {
    border: 1px solid
      ${({ theme, $hasError }) => ($hasError ? 'red' : theme.palette.dina.statusOnFocused)};
  }
`;

export const StyledDateInput = styled(TextField)`
  .MuiFormLabel-root {
    display: none;
  }

  .MuiInput-root {
    margin-top: 0;
  }

  .MuiInput-input {
    padding: 6px 0;
    text-align: center;
  }

  input::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
`;

export const TimePickerWrapper = styled('div')`
  height: 28px;
  width: 65px;
  margin: 1px 0px 0px 2px;
`;
