import React, { memo, useCallback } from 'react';
import { useEditorContext } from 'components/editor/EditorContext';
import { mediaTypes } from 'utils/rundownItemTypes';
import addMedia from 'components/editor/components/photoGallery/utils/addMedia';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import { useReadOnly, useSelected, useSlate } from 'slate-react';
import styled from '@emotion/styled';

const { CLIP } = mediaTypes;

const DropZone = styled('div')`
  position: relative;
  opacity: ${(props) => (props.isDragging ? 0.4 : 1)};
  pointer-events: ${(props) => (props.readOnly ? 'none' : 'all')};
  border-radius: 4px;
  width: 100%;
  background: ${({ showHighlight, theme }) =>
    showHighlight && theme.palette.dina.blackHoverOverlay};
  box-shadow: ${({ hovered, theme }) =>
    hovered ? `0 0 0 1px ${theme.palette.dina.onFocus}` : `none`};
`;

const MediaDropZone = ({ children, element, index, galleryItems }) => {
  const readOnly = useReadOnly();
  const isSelected = useSelected(element);
  const editor = useSlate();
  const { update, allowVideoInPhotogallery } = useEditorContext();
  const showHighlight = !readOnly && isSelected;

  const onDrop = useCallback(
    ({ payload }) => {
      addMedia(editor, element, galleryItems, payload, update, index);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  const [{ didDrop, hovered }, dropRef] = useDrop({
    accept: CLIP,
    canDrop: (item) => {
      if (readOnly) return false;
      const { itemType } = item?.payload;
      if (!allowVideoInPhotogallery && itemType === 'video') return false;
      return itemType === 'image' || itemType === 'video';
    },
    drop: (item) => {
      if (!didDrop) onDrop(item);
    },
    collect: (monitor) => ({
      didDrop: monitor.didDrop(),
      hovered: monitor.canDrop() && monitor.isOver(),
    }),
  });

  return (
    <DropZone hovered={hovered} readOnly={readOnly} showHighlight={showHighlight} ref={dropRef}>
      {children}
    </DropZone>
  );
};

MediaDropZone.propTypes = {
  /** Drag and drop wrapped children */
  children: PropTypes.node.isRequired,
  /** SlateJS element */
  element: PropTypes.shape({}).isRequired,
  /** Callable onDragEnd */
  index: PropTypes.number,
  /** Whether block is disabled or not */
  galleryItems: PropTypes.arrayOf(PropTypes.shape({})),
};

MediaDropZone.defaultProps = {
  index: 0,
  galleryItems: [{}],
};

export default memo(MediaDropZone);
