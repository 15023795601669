import MemberDetails from 'screens/main/components/rightPanel/memberDetails/MemberDetails';
import { MemberType, MemberTypeEnum } from 'types/graphqlTypes';

import BlockPreview from '../components/BlockPreview';
import Draft from '../components/draft/Draft';
import Instance from '../components/Instance';
import OrderPreview from '../components/OrderPreview';
import Story from '../components/Story';

type KeyType = MemberTypeEnum;

export interface PreviewProps {
  member: MemberType;
  onClose: () => void;
}

export const childMap: { [key in KeyType]?: React.FC<PreviewProps> } = {
  [MemberTypeEnum.Order]: OrderPreview,
  [MemberTypeEnum.Block]: BlockPreview,
  [MemberTypeEnum.Story]: Story,
  [MemberTypeEnum.ResStory]: Story,
  [MemberTypeEnum.Pitch]: Story,
  [MemberTypeEnum.ResPitch]: Story,
  [MemberTypeEnum.Instance]: Instance,
  [MemberTypeEnum.Draft]: Draft,
  [MemberTypeEnum.User]: MemberDetails,
  [MemberTypeEnum.Department]: MemberDetails,
  [MemberTypeEnum.Team]: MemberDetails,
  [MemberTypeEnum.Contact]: MemberDetails,
} as const;
