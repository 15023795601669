import { useCallback, useMemo } from 'react';

import { useGetMdf } from 'api/mdf/useGetMdf';
import { useGetBlock } from 'api/mdfBlocks/useGetMdfBlock';
import { useUpdateBlockTitle } from 'api/mdfBlocks/useUpdateMdfBlock';
import Input from 'components/input/Input';
import { MdfEditor } from 'components/mdfEditor/MdfEditor';
import { FieldHeader } from 'components/mdfEditor/styled';
import useToast from 'components/toast/useToast';
import useMetadata from 'hooks/useMetadata';
import { Box } from 'layouts/box/Box';
import { Metadata } from 'types/forms/forms';
import preventDefaultAndPropagation from 'utils/preventDefaultAndStopPropagation';

import { FieldWrapper } from './styled';

const stringifyDeps = (mRefId?: string, metadata?: Metadata) =>
  JSON.stringify([mRefId ?? '', metadata ?? '']);

interface EditMdfProps {
  resourceId?: string;
  blockId?: string;
  mdfId?: string;
}

function EditMdfBlock({ resourceId, blockId, mdfId }: Readonly<EditMdfProps>) {
  const updateTitle = useUpdateBlockTitle();
  const { errorToast } = useToast();
  const { block, loading } = useGetBlock(resourceId, blockId);
  const { mdf } = useGetMdf(mdfId);
  const { metadata, updateFieldValues, errorMap } = useMetadata(
    mdf,
    block?.metadata,
    'default',
    block ?? undefined,
    useMemo(() => stringifyDeps(block?.mRefId, block?.metadata), [block?.mRefId, block?.metadata]),
  );

  const doUpdateTitle = useCallback(
    (val: string) => {
      if (block?.mId && block?.mRefId) updateTitle(block.mId, block.mRefId, val).catch(errorToast);
    },
    [block, updateTitle, errorToast],
  );

  return (
    mdf &&
    block &&
    !loading && (
      <div onClick={preventDefaultAndPropagation} onKeyDown={() => {}}>
        <FieldWrapper>
          <FieldHeader variant="overline">Block title</FieldHeader>
          <Input initialValue={block.mTitle ?? ''} updateValue={doUpdateTitle} />
        </FieldWrapper>
        <Box margin="5px 0 0 0">
          <MdfEditor
            view="default"
            fields={mdf.fields}
            defaultLayoutSettings={mdf.views.default}
            layoutSettings={mdf.views.default}
            metadata={metadata}
            permissions={mdf.permissions}
            updateFieldValue={updateFieldValues}
            errorMap={errorMap}
          />
        </Box>
      </div>
    )
  );
}

export default EditMdfBlock;
