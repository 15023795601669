import styled from '@emotion/styled/macro';

export const RootWrapper = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const InputWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 8px;
  margin-top: 6px;
`;

export const DropdownWrapper = styled('div')`
  padding: 0 8px;
`;
