import React, { useCallback, memo, useMemo } from 'react';
import { useSlate, ReactEditor } from 'slate-react';
import { useDrag } from 'react-dnd';
import useGetAutomationTemplates from 'hooks/useGetAutomationTemplates';
import insertPrimaryAutomationElement from 'components/editor/components/primaryAutomation/utils/insertPrimaryAutomationElement';
import insertSecondaryAutomationElement from 'components/editor/components/secondaryAutomation/utils/insertSecondaryAutomationElement';
import { useEditorContext } from 'components/editor/EditorContext';
import useCheckUserRight from 'hooks/useCheckUserRight';
import tooltipEnum from 'components/editor/constants/tooltipEnum';
import Tooltip from 'components/tooltip';
import primaryTypeValues from 'components/editor/constants/types/primaryTypeValues';
import { getDefaultTemplate } from 'components/editor/utils';
import { secondaryTypes } from 'utils/rundownItemTypes';
import notifyChange from 'components/editor/utils/notifyChange';
import iconComponents from './constants/iconComponents';
import { IconWrapper } from './styled';
import normalizeOnListFocus from 'components/editor/utils/normalizeOnListFocus';

const stopPropagation = (event) => event.stopPropagation();

const createAsset = async (editor, update, cgAsset) => {
  try {
    const result = await update({
      type: 'create-asset',
      payload: { document: editor.children, asset: cgAsset },
    });

    return result?.data?.createAssets[0];
  } catch (e) {
    // console.error(e)
  }

  return null;
};

const AutomationButton = ({ type }) => {
  const editor = useSlate();
  const { update } = useEditorContext();
  const [, dragRef] = useDrag({ type, item: { type } });
  const IconComponent = iconComponents[type];

  const [checkUserRight] = useCheckUserRight();
  const canShowStarCG = checkUserRight('feature', 'starcg');

  const { templates } = useGetAutomationTemplates();

  const insertAutomationItem = useCallback(
    async () => {
      const isPrimary = primaryTypeValues.includes(type);
      const {
        variant: templateVariant,
        name,
        description,
        fields,
      } = getDefaultTemplate(type, templates);
      normalizeOnListFocus(editor);
      const data = { templateVariant, name, description };
      if (fields?.length) {
        data.metaData = fields.map(({ name: fieldName, value, isVisible }) => ({
          name: fieldName,
          value,
          isVisible: isVisible == null ? true : isVisible,
        }));
      }
      if (isPrimary) insertPrimaryAutomationElement({ editor, type, update, data });
      else {
        if (type === secondaryTypes.OVERLAY_GRAPHICS && canShowStarCG) {
          data.starcg = { graphicsTemplateType: '', fields: [] };
          data.protocol = 'starcg';
          const cgAsset = {
            provider: 'Dina',
            assetType: 'graphics',
            mediaType: 'graphics/starcg',
            itemType: 'graphics',
          };
          const asset = await createAsset(editor, update, cgAsset);
          data.mId = asset?.mId;
          data.mRefId = asset?.mRefId;
          data.provider = cgAsset.provider;
        }

        insertSecondaryAutomationElement(editor, type, data);
      }

      if (type === secondaryTypes.OVERLAY_GRAPHICS && canShowStarCG) {
        notifyChange(editor, update);
      }

      ReactEditor.focus(editor);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [templates],
  );

  const renderIcon = useMemo(
    () => (
      <Tooltip title={tooltipEnum[type]}>
        <IconWrapper
          ref={dragRef}
          onClick={insertAutomationItem}
          onMouseDown={stopPropagation}
          role="presentation"
        >
          <IconComponent />
        </IconWrapper>
      </Tooltip>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dragRef, insertAutomationItem],
  );

  return renderIcon;
};

export default memo(AutomationButton);
