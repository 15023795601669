import { useCallback } from 'react';

import { ReactComponent as Schema } from 'assets/icons/systemicons/schema.svg';
import { ReactComponent as SubtypeSchema } from 'assets/icons/systemicons/schema_subtype.svg';
import useGetPlatforms from 'hooks/useGetPlatforms';
import { Mdf } from 'types/graphqlTypes';
import { getPlatformIcon } from 'utils/instance/platform';

import { MdfIcons } from './mdf-utils';

export const useGetMdfIcon = () => {
  const { platformVariants } = useGetPlatforms(new Date());

  const getIcon = useCallback(
    (mdf: Mdf) => {
      if (mdf.isSubtype) {
        return SubtypeSchema;
      }
      if (MdfIcons[mdf.id]) return MdfIcons[mdf.id];
      const platform = platformVariants[mdf.id];
      if (platform) {
        return getPlatformIcon(platform.icon);
      }
      return Schema;
    },
    [platformVariants],
  );

  return getIcon;
};
