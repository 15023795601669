import { useEffect } from 'react';

import { createWidgetDefaults, useCreateWidgetMenu } from 'features/widget/CreateWidgetMenu';
import { ResizablePanel, ResizablePanelGroup } from 'lib/resize';

import { LayoutProps } from '../layouts';
import { PanelContent } from '../PanelContent';
import { VerticalResizeHandle } from '../ResizableHandlers';

export default function OneByOneHorizontal({ widgets, ...panelProps }: Readonly<LayoutProps>) {
  const [, setCreateWidget] = useCreateWidgetMenu();

  const leftWidget = widgets[0];
  const middleWidget = widgets[1];
  const rightWidget = widgets[2];

  useEffect(() => {
    return () => {
      setCreateWidget({ ...createWidgetDefaults });
    };
  }, []);

  return (
    <ResizablePanelGroup direction="horizontal">
      <ResizablePanel style={{ margin: '2px 0 8px 8px' }}>
        <PanelContent
          index={0}
          layout="1-1-1-horizontal"
          setCreateWidget={setCreateWidget}
          widget={leftWidget}
          {...panelProps}
        />
      </ResizablePanel>
      <VerticalResizeHandle />
      <ResizablePanel style={{ margin: '2px 0 8px 0' }}>
        <PanelContent
          index={1}
          layout="1-1-1-horizontal"
          setCreateWidget={setCreateWidget}
          widget={middleWidget}
          {...panelProps}
        />
      </ResizablePanel>
      <VerticalResizeHandle />
      <ResizablePanel style={{ margin: '2px 8px 8px 0px' }}>
        <PanelContent
          index={2}
          layout="1-1-1-horizontal"
          setCreateWidget={setCreateWidget}
          widget={rightWidget}
          {...panelProps}
        />
      </ResizablePanel>
    </ResizablePanelGroup>
  );
}
