import {
  addDays,
  endOfDay,
  getISODay,
  lastDayOfISOWeek,
  startOfDay,
  startOfISOWeek,
  startOfMonth,
  subDays,
} from 'date-fns';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { atomWithLocation } from 'jotai-location';
import { createScope, molecule, useMolecule } from 'jotai-molecules';
import { focusAtom } from 'jotai-optics';

import { SearchDateRange } from 'api/search';
import { CommandToolbarProps } from 'features/command/command-types';
import { toolbarFilterDefaults } from 'features/command/command-utils';
import { MemberType } from 'types/graphqlTypes';
import { defaultTabs, TimeVariant, timeVariants } from 'utils/planningViews';

export type SearchResult = {
  items: MemberType[];
  total: number;
  loading: boolean;
};

const defaultTab = defaultTabs.find((tab) => tab.index === 1) ?? defaultTabs[0];

const calculateDateRange = (dateIso: string, timeVariant: TimeVariant): SearchDateRange => {
  const date = new Date(dateIso);
  switch (timeVariant) {
    case timeVariants.MONTH: {
      const firstDayInMonth = startOfDay(startOfMonth(date));
      const startDate = subDays(firstDayInMonth, getISODay(firstDayInMonth) - 1).toISOString();
      return {
        from: startDate,
        to: endOfDay(addDays(startDate, 41)).toISOString(),
      };
    }
    case timeVariants.WEEK:
      return {
        from: startOfDay(startOfISOWeek(date)).toISOString(),
        to: endOfDay(lastDayOfISOWeek(date)).toISOString(),
      };
    case timeVariants.DAY:
    default:
      return {
        from: startOfDay(date).toISOString(),
        to: endOfDay(date).toISOString(),
      };
  }
};

export const StoryHubScope = createScope(undefined);

const defaults: CommandToolbarProps = {
  ...toolbarFilterDefaults,
};

const storyHubMolecule = molecule((_getMol, getScope) => {
  getScope(StoryHubScope);

  const toolbarState = atom<CommandToolbarProps>({ ...defaults });

  /** Tabs */
  const tabAtom = atomWithStorage('storyHub-tab', defaultTab);

  /** Scheduled */
  const scheduledAtom = atomWithStorage('storyHub-scheduled', true);
  const combinedScheduledAtom = atomWithStorage('storyHub-combinedScheduled', false);

  /** Date */
  const dateAtom = atomWithStorage<string>('storyHub-date', new Date().toISOString());

  const dateRangeAtom = atom((get) => {
    const timeVariant = get(tabAtom).timeVariant;
    const date = get(dateAtom);
    return calculateDateRange(date, timeVariant);
  });
  /** Search */

  const urlParams = atomWithLocation();

  /** View */

  const showMDFAtom = atomWithStorage<boolean>('storyHub-showMDF', true);

  const statesViewAtom = atomWithStorage<string>('storyHub-statesView', 'status-normal');

  const statesCollapsedAtom = atomWithStorage<{ [x: string]: boolean }>(
    'storyHub-statesCollapsed',
    {},
  );

  const activeItemAtom = atom<MemberType | null>(null);

  /** Polling */
  const pollingAtom = atom(true);

  /** Aggregation */
  const bucketSizeAtom = atom<number>(0);

  /** Search Result */

  const searchResultsAtom = atom<SearchResult>({
    items: [],
    total: 0,
    loading: false,
  });

  const itemsAtom = focusAtom(searchResultsAtom, (optic) => optic.prop('items'));
  const loadingAtom = focusAtom(searchResultsAtom, (optic) => optic.prop('loading'));
  const totalAtom = focusAtom(searchResultsAtom, (optic) => optic.prop('total'));

  return {
    useStoryHubToolbarState: () => useAtom(toolbarState),
    useStoryHubParams: () => useAtom(urlParams),
    useStoryHubTab: () => useAtom(tabAtom),
    useStoryHubDate: () => useAtom(dateAtom),
    useStoryHubDateValue: () => useAtomValue(dateAtom),
    useStoryHubDateRange: () => useAtomValue(dateRangeAtom),
    useShowMDF: () => useAtom(showMDFAtom),
    useSearchResults: () => useAtomValue(searchResultsAtom),
    useSetResults: () => useSetAtom(searchResultsAtom),
    useStoryHubLoading: () => useAtom(loadingAtom),
    useTotal: () => useAtom(totalAtom),
    useSearchItems: () => useAtom(itemsAtom),
    useTotalValue: () => useAtomValue(totalAtom),
    useSearchItemsValue: () => useAtomValue(itemsAtom),
    useStatesView: () => useAtom(statesViewAtom),
    useStatesCollapsed: () => useAtom(statesCollapsedAtom),
    useActiveItem: () => useAtom(activeItemAtom),
    useScheduled: () => useAtom(scheduledAtom),
    useCombinedScheduled: () => useAtom(combinedScheduledAtom),
    useStoryHubPolling: () => useAtom(pollingAtom),
    useBucketSize: () => useAtom(bucketSizeAtom),
  };
});

export const useStoryHubMolecule = () => useMolecule(storyHubMolecule);

export const aggregationFieldName = 'mPublishingAt';

export const BUCKET_DATE_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss.SSSZ';
