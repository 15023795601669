import { elementTypes } from 'components/editor/constants/types';
import { CustomElement } from 'types';

const { UNORDERED_LIST, ORDERED_LIST } = elementTypes;

/**
 * Determines if given type is list type or not
 *
 * @param type Type of the element node
 * @returns true or false
 */

const isList = ({ type }: CustomElement) => [UNORDERED_LIST, ORDERED_LIST].includes(type);

export default isList;
