import { isAfter } from 'date-fns';

const isValidSession = (session) => {
  if (!session) return false;
  const { mState } = session;

  if (mState !== 'active') return false;

  const sessionExpirationTime = session.mProperties?.sessionExpirationTime;

  if (!sessionExpirationTime) return false;

  const currentTime = new Date();
  return isAfter(new Date(sessionExpirationTime), currentTime);
};

export default isValidSession;
