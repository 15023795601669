import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as EditOff } from 'assets/icons/systemicons/edit_off.svg';
import useStyles from './editButton-styles';

const EditButton = ({ onClick, width, height, disabled }) => {
  const classes = useStyles({ height, width });
  return (
    <div
      role="presentation"
      onClick={onClick}
      className={`${classes.root} ${disabled ? classes.disabled : ''}`}
    >
      <EditOff className={classes.icon} />
      <div className={classes.label}>CMS editing</div>
    </div>
  );
};

EditButton.propTypes = {
  /** onClick callback function */
  onClick: PropTypes.func,
  /** height of the button */
  height: PropTypes.number,
  /** width of the button */
  width: PropTypes.number,
  /** disabled  */
  disabled: PropTypes.bool,
};

EditButton.defaultProps = {
  onClick: () => {},
  height: 32,
  width: 136,
  disabled: false,
};

export default EditButton;
