import { createPortal } from 'react-dom';

import { usePortal } from './store';

function Portal() {
  const [children] = usePortal();

  return children ? createPortal(<>{children}</>, document.body) : null;
}

export default Portal;
