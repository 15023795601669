import { useContext } from 'react';
import { OnDataOptions } from '@apollo/client';

import UserCtx from 'contexts/UserContext';
import GET_COMMENTS from 'operations/queries/getComments';
import { CrudActionEnum, MemberType } from 'types/graphqlTypes';

type QueryResult = {
  getMessagesOfConversation: { items: MemberType[] };
};

interface SubscriptionEvent {
  updateContentChat: MemberType;
}

const useUpdateCommentsCache = () => {
  const { mId: userId } = useContext(UserCtx);

  const updateCommentCache = ({
    client,
    data: subscriptionData,
  }: OnDataOptions<SubscriptionEvent>) => {
    const updatedComment = subscriptionData?.data?.updateContentChat;
    if (!updatedComment) return;
    if (updatedComment.mUpdatedById === userId) return;

    const { getMessagesOfConversation } =
      client.readQuery<QueryResult>({
        query: GET_COMMENTS,
        variables: { input: { mId: updatedComment.mId } },
      }) ?? {};

    const items = getMessagesOfConversation?.items || [];
    let newCommentList;

    if (updatedComment?.crudAction === CrudActionEnum.Delete) {
      newCommentList = updatedComment.mState
        ? []
        : items.filter(({ mRefId }) => mRefId !== updatedComment.mRefId);
    } else {
      const isFound = items.find((comment) => comment.mRefId === updatedComment.mRefId);
      newCommentList = isFound ? undefined : [updatedComment, ...items];
    }

    if (newCommentList) {
      client.writeQuery({
        query: GET_COMMENTS,
        variables: { input: { mId: updatedComment.mId } },
        data: {
          getMessagesOfConversation: {
            ...getMessagesOfConversation,
            items: newCommentList,
          },
        },
      });
    }
  };

  return [updateCommentCache];
};

export default useUpdateCommentsCache;
