import { elementTypes } from 'components/editor/constants/types';
import { CustomElement } from 'types/editor';

const { HEADING_ONE, HEADING_TWO, HEADING_THREE, HEADING_FOUR } = elementTypes;

const headingElementsSet = new Set([HEADING_ONE, HEADING_TWO, HEADING_THREE, HEADING_FOUR]);

const isHeading = (element: CustomElement) => element.type && headingElementsSet.has(element.type);

export default isHeading;
