/* eslint-disable sort-imports */
import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';

import { useEditorCommands } from 'store/editor';
import { CreateMemberInput } from 'types/graphqlTypes';
import { EditorCommandConfigType } from 'types/memberTypes/editorCommands';
import useLogger from 'utils/useLogger';

import { getErrorMessage, STANDARD_EDITOR_COMMAND_KEYS } from './useGetEditorCommands';

const CREATE_COMMAND = gql`
  mutation CreateCommand($input: CreateMemberInput) {
    createMember(input: $input) {
      ${STANDARD_EDITOR_COMMAND_KEYS}
    }
  }
`;

interface CreateCommand {
  createMember: EditorCommandConfigType;
}

const useCreateEditorCommand = () => {
  const [create] = useMutation<CreateCommand>(CREATE_COMMAND);
  const [, setEditorCommands] = useEditorCommands();
  const logger = useLogger('useCreateEditorCommand');

  const createCommand = useCallback(async (input: CreateMemberInput) => {
    try {
      await create({
        variables: {
          input,
        },
        update: (_proxy, mutationResult) => {
          const newCommandConfig = mutationResult.data?.createMember;
          if (newCommandConfig) setEditorCommands((prev) => [...prev, newCommandConfig]);
        },
      });
    } catch (err) {
      logger.log(getErrorMessage(err, input));
    }
  }, []);

  return { createCommand };
};

export default useCreateEditorCommand;
