import { atom } from 'jotai';

export function atomWithSessionStorage<T>(key: string, initialValue: T) {
  const getInitialValue = () => {
    try {
      const item = sessionStorage.getItem(key);
      if (item !== null) {
        return JSON.parse(item) as T;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(`Error reading ${key} from sessionStorage:`, error);
    }
    return initialValue;
  };

  const baseAtom = atom(getInitialValue());

  const derivedAtom = atom(
    (get) => get(baseAtom),
    (get, set, update: T | ((prev: T) => T)) => {
      const nextValue =
        typeof update === 'function' ? (update as (prev: T) => T)(get(baseAtom)) : update;
      set(baseAtom, nextValue);
      try {
        sessionStorage.setItem(key, JSON.stringify(nextValue));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn(`Error writing ${key} to sessionStorage:`, error);
      }
    },
  );

  return derivedAtom;
}
