/**
 *
 * @param sourcePath {Number[]} the initial path of the dragged element
 * @param destinationPath {Number[]} the initial path of the drop target
 * @returns {Number[]} the final path (position) of the dragged element
 */
const getDropPath = (sourcePath: number[], destinationPath: number[]): number[] => {
  if (destinationPath.length === 0) return [];

  const [sourceRoot] = sourcePath;
  const [destinationRoot] = destinationPath;

  // if the dragged element is initially on the (relative) root element
  if (sourcePath.length === 1) {
    // destination drop target is also on the (relative) root element
    if (destinationPath.length === 1)
      return [sourceRoot < destinationRoot ? destinationRoot : destinationRoot + 1];
    else {
      // destination drop target is on nested element
      const offset = sourceRoot < destinationRoot ? 1 : 0;
      return [
        destinationRoot - offset,
        ...destinationPath.slice(1, -1),
        destinationPath[destinationPath.length - 1] + 1,
      ];
    }
  }

  // source path is nested but destination drop target is on root element
  if (destinationPath.length === 1) return [destinationRoot + 1];

  // both drag element and drop target are nested
  // and they have the same root element
  if (sourceRoot === destinationRoot)
    return [destinationRoot, ...getDropPath(sourcePath.slice(1), destinationPath.slice(1))];

  // both drag element and drop target are nested, but their root is different
  return [
    destinationRoot,
    ...destinationPath.slice(1, -1),
    destinationPath[destinationPath.length - 1] + 1,
  ];
};

export default getDropPath;
