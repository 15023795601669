import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from './styled';
import FieldComponents from './fields/fields';

const getStyles = (direction) => {
  if (direction === 'horizontal') {
    return {
      maxWidth: '200px',
    };
  }
  return {};
};

/**
 * Renders a given form model. Should be kept lightweight for performance.
 */
function MetadataEditor({ model, payload, setPayload, style, direction = 'vertical' }) {
  if (!model || !model?.mProperties || !model?.mProperties?.fields) {
    return null;
  }
  const {
    mProperties: { fields = [] },
  } = model;
  const visibleFields = fields.filter((f) => !f.id.startsWith('DEFAULT#'));

  return (
    <Wrapper style={style} direction={direction}>
      {visibleFields.map((field) => {
        const Field = FieldComponents[field.type];
        if (!Field) {
          const Error = FieldComponents.error;
          return <Error type={field?.type} />;
        }
        return (
          <Field
            style={getStyles(direction)}
            key={field.id}
            fieldModel={field}
            value={field.type === 'boolean' ? Boolean(payload?.[field.id]) : payload?.[field.id]}
            setValue={(val) => setPayload({ value: val, fieldId: field.id })}
          />
        );
      })}
    </Wrapper>
  );
}

MetadataEditor.propTypes = {
  style: PropTypes.object,
  model: PropTypes.shape({
    mProperties: PropTypes.shape({
      defaultView: PropTypes.shape({}),
      defaultViews: PropTypes.array,
      fields: PropTypes.array,
      type: PropTypes.oneOf(['boolean', 'select', 'text']),
    }),
    mRefId: PropTypes.string,
    mTitle: PropTypes.string,
    id: PropTypes.string,
  }),
  payload: PropTypes.object,
  setPayload: PropTypes.func,
  direction: PropTypes.oneOf(['vertical', 'horizontal']),
};

MetadataEditor.defaultProps = {
  model: null,
  style: {},
  payload: {},
  setPayload: () => {},
};

export default MetadataEditor;
