import { useCallback, useEffect, useState, useMemo, memo } from 'react';

import LoadingIndicator from 'components/loadingIndicator';
import List from 'features/feedViewer/components/list';

import ReadOnlyLane from './components/lane/ReadOnlyLane';
import Item from './components/Item';
import { useKanbanMolecule } from './store/kanban';

import { RenderItem, LaneWrapper } from './styled';

const Lane = ({ container, members, dense, darker, customRenderHeader, getRenderFunction }) => {
  const childrenComponent = useMemo(
    () => (
      <>
        {members?.map((member) => {
          return <Item member={member} key={member?.mId} renderItem={getRenderFunction} />;
        })}
      </>
    ),
    [members, getRenderFunction],
  );

  if (customRenderHeader) {
    const Comp = customRenderHeader;
    return (
      <Comp container={container} members={members}>
        {childrenComponent}
      </Comp>
    );
  }
  return (
    <ReadOnlyLane
      mRefId={container?.mRefId}
      label={container?.mTitle}
      color={container?.color}
      count={members?.length}
      dense={dense}
      darker={darker}
    >
      {childrenComponent}
    </ReadOnlyLane>
  );
};

function ReadOnlyKanban({
  loading = false,
  customRenderItem,
  customRenderHeader,
  filterKey = 'mRundownTemplateId',
  dense = false,
  darker = false,
  setToggleRender = (bool) => {},
}) {
  const { useKanbanBoard, useKanbanLanes, useKanbanMembers } = useKanbanMolecule();
  const [kanbanBoard] = useKanbanBoard();
  const [kanbanLanes] = useKanbanLanes();
  const [kanbanMembers] = useKanbanMembers();

  const laneOrder = useMemo(() => kanbanBoard?.mOrder ?? [], [kanbanBoard?.mOrder]);

  const generatedItems = useMemo(() => {
    if (!laneOrder) return {};
    return Object.fromEntries(
      laneOrder.map((id) => [id, kanbanLanes[id]?.mOrder?.filter(Boolean) || []]).filter(Boolean),
    );
  }, [JSON.stringify(laneOrder), JSON.stringify(kanbanLanes)]);

  // laneIds and memberIds
  const [containers, setContainers] = useState(Object.keys({ ...generatedItems }));
  const [items, setItems] = useState({ ...generatedItems });

  useEffect(() => {
    setContainers(Object.keys({ ...generatedItems }));
    setItems({ ...generatedItems });
  }, [generatedItems]);

  useEffect(() => {
    setToggleRender?.((prev) => !prev);
  }, [generatedItems]);

  const getContainer = useCallback((containerId) => kanbanLanes[containerId], [kanbanLanes]);
  const getContainerItemsIds = useCallback((containerId) => items[containerId], [items]);

  /** Render function for each lane child
   * @param member rendered member.
   * */
  const getRenderFunction = useCallback(
    ({ member }) => {
      const Comp = customRenderItem;
      return (
        <RenderItem>
          <Comp member={member} />
        </RenderItem>
      );
    },
    [customRenderItem],
  );

  return (
    <List>
      <List.Body>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <>
            {containers?.map((containerId) => {
              const container = getContainer(containerId);
              const containerItemsIds = getContainerItemsIds(containerId);

              // Logic to get the rundown members that are part of an specific lane
              const members = Object.values(kanbanMembers)
                .map((member) => {
                  if (!containerItemsIds.includes(member[filterKey])) return null;
                  return member;
                })
                .filter(Boolean);

              if (!members?.length) return null;

              return (
                <LaneWrapper key={containerId} $dense={dense}>
                  <Lane
                    container={container}
                    members={members}
                    dense={dense}
                    darker={darker}
                    customRenderHeader={customRenderHeader}
                    getRenderFunction={getRenderFunction}
                  />
                </LaneWrapper>
              );
            })}
          </>
        )}
      </List.Body>
    </List>
  );
}

export default memo(ReadOnlyKanban);
