import { useCallback } from 'react';

import { LinearPlatform, Platform } from 'types';
import getIdentifier from 'utils/instance/getAccountIdentifier';

import useCheckUserRight from './useCheckUserRight';

const useFilterPlatforms = () => {
  const [checkUserRight] = useCheckUserRight();

  const filterPlatforms = useCallback(
    (platforms: Platform[]) =>
      platforms.reduce((acc, platform) => {
        if ((platform as LinearPlatform)?.id === 'linear') {
          acc.push(platform);
        } else {
          const { mTitle, mProperties } = platform;
          const { accounts = [] } = mProperties || {};

          if (accounts.length !== 0) {
            const filteredAccounts = accounts.filter((account) =>
              checkUserRight('platform', getIdentifier(mTitle, account.accountTitle)),
            );
            acc.push({
              ...platform,
              mProperties: {
                ...mProperties,
                accounts: [...filteredAccounts],
              },
            });
          }
        }
        return acc;
      }, [] as Platform[]),
    [checkUserRight],
  );

  return { filterPlatforms };
};

export default useFilterPlatforms;
