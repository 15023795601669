import styled from '@emotion/styled';
import { Typography, List as MuiList, MenuItem as MuiMenuItem, Paper } from '@material-ui/core';

export const ListWrapper = styled(Paper)`
  position: absolute;
  ${({ position }) =>
    position.bottom
      ? {
          bottom: position.bottom,
          left: position.left,
        }
      : {
          top: position.top,
          left: position.left,
        }};
  z-index: 2000;
  background-color: ${({ theme }) => theme.palette.dina.surfaceDialogs};
`;

export const List = styled(MuiList)`
  border-radius: 4px;
  max-height: 320px;
  overflow-y: auto;
`;

export const MenuItem = styled(MuiMenuItem)`
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
`;

export const Title = styled(Typography)`
  margin-left: 8px;
`;
