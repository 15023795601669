import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableHead, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import uuidv1 from 'uuid/v1';

const useStyles = makeStyles(theme => ({
  tableHeader: {
    height: '24px',
    ...theme.typography.dina.overline,
    lineHeight: '12px',
    letterSpacing: '1px',
    textAlign: 'left',
    paddingLeft: '8px',
    border: `1px solid ${theme.palette.dina.dividerLight}`,
    backgroundColor: theme.palette.dina.blackHoverOverlay,
    '&:last-child': {
      paddingRight: 4,
    },
  },
}));

const TableHeader = ({ columns, tableStyle }) => {
  const classes = useStyles();
  return (
    <TableHead>
      <TableRow>
        {columns.map(column => (
          <TableCell
            key={uuidv1()}
            style={tableStyle}
            classes={{
              root: classes.tableHeader,
            }}
          >
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

TableHeader.propTypes = {
  /** Fields to be mapped on metadata on instance view */
  columns: PropTypes.arrayOf(PropTypes.object),
};

TableHeader.defaultProps = {
  columns: [],
};
export default TableHeader;
