import elementTypes from 'components/editor/constants/types/elementTypes';
import { getDurationKey } from 'screens/rundown/components/editor/utils';
import { MMetaDataField } from 'types/graphqlTypes';

const getTwitterMetaKey = (blankMetaData: MMetaDataField[]) => {
  const findKey = (str: string) => {
    const searched = blankMetaData.find((item) => getDurationKey(item) === str);
    if (searched) return searched.key;
    return elementTypes.TWEET_THREAD;
  };
  const tweetMetaKey = findKey(elementTypes.TWEET_THREAD);
  return tweetMetaKey;
};

export default getTwitterMetaKey;
