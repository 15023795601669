import { useEffect, useRef, useState } from 'react';
type FocusableElement = HTMLDivElement;

const useRundownListHasFocus = () => {
  const ref = useRef<FocusableElement>(null);
  const [hasFocus, setHasFocus] = useState<boolean>(false);

  const handleClick = (event: MouseEvent) => {
    setHasFocus(ref.current?.contains(event.target as HTMLElement) ?? false);
  };

  useEffect(() => {
    window.addEventListener('mousedown', handleClick);
    return () => {
      // Unbind the event listener on clean up
      window.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return [ref, hasFocus, setHasFocus] as const;
};

export default useRundownListHasFocus;
