import uuidv1 from 'uuid/v1';
import { Transforms, Editor } from 'slate';
import RundownItemTypes, { primaryTypes } from 'utils/rundownItemTypes';

const defaultOptions = {
  automationType: 'PRIMARY',
};

const { insertNodes, setNodes } = Transforms;

/**
 * Inserts secondary automation element
 *
 * @param {Object} editor SlateJS editor instance
 * @param {String} type Type of the element
 * @param {Object} data Data properties for the element
 * @param {Boolean} isNewline Should the element be inserted on a newline
 * @returns {Object} SlateJS editor instance
 */

const insertMosObj = (editor, type, data = {}, isNewline = true, index) => {
  const element = {
    type,
    data: {
      ...defaultOptions,
      ...data,
      typeId: RundownItemTypes.properties[primaryTypes.MOS]?.id ?? 500,
      itemId: uuidv1(),
      templateType: 'MOS',
    },
    children: [{ text: '' }],
  };
  try {
    if (isNewline) {
      insertNodes(editor, element, isNaN(index) ? {} : { at: [index + 1] });
    } else {
      const [{ text }] = Editor.node(editor, editor.selection);
      if (text !== '') {
        insertNodes(editor, element);
      }
      setNodes(editor, element);
    }
  } catch (error) {
    // console.log(error);
  }
  return editor;
};

export default insertMosObj;
