import { useMemo } from 'react';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import Fade from '@material-ui/core/Fade';
import { atom, useAtom } from 'jotai';

import { useGetIntegrationsForAdmin } from 'api/config/useGetIntegrations';
import { ReactComponent as FeedIcon } from 'assets/icons/search/feed.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search/search.svg';
import { ReactComponent as AssetsIcon } from 'assets/icons/systemicons/ContainerRight_Navbar/storybox_off.svg';
import { ReactComponent as LinkIcon } from 'assets/icons/systemicons/internet.svg';
import { ReactComponent as KanbanIcon } from 'assets/icons/systemicons/kanban.svg';
import { ReactComponent as GridIcon } from 'assets/icons/systemicons/list.svg';
import { ReactComponent as NoteIcon } from 'assets/icons/systemicons/notes.svg';
import { ReactComponent as PluginIcon } from 'assets/icons/systemicons/plugin_off.svg';
import { ReactComponent as TaskIcon } from 'assets/icons/systemicons/tasks.svg';
import Text from 'components/text/Text';
import { WIDGETS } from 'features/widget/constants';
import { useCanSeeDataTable, useCanSeeGridDecks, useCanSeePlugins } from 'hooks/useCheckUserRight';
import { Box, HStack } from 'layouts/box/Box';
import {
  StyledMenu,
  StyledMenuItem,
} from 'screens/main/components/header/navbar/tabs/components/contextMenu/styled';
import { StyledImg } from 'screens/space/components/dialogs/styled';
import { useCreateWidget } from 'screens/space/store/widgets';
import { IntegrationEnum, IntegrationType } from 'types/graphqlTypes';

interface WidgetMenuItem {
  label: string;
  description: string;
  type: WIDGETS;
  icon: EmotionJSX.Element | string | null;
  config?: IntegrationType;
}

const menuItems: WidgetMenuItem[] = [
  {
    label: 'Feed',
    description: 'Show a filtered list of feeds',
    type: WIDGETS.FEED,
    icon: <FeedIcon />,
  },
  {
    label: 'Assets',
    description: 'Show assets',
    type: WIDGETS.ASSETS,
    icon: <AssetsIcon />,
  },
  {
    label: 'Search',
    description: 'Save any filtered search',
    type: WIDGETS.SEARCH,
    icon: <SearchIcon />,
  },
  {
    label: 'Note',
    description: 'A text editor',
    type: WIDGETS.NOTE,
    icon: <NoteIcon />,
  },
  {
    label: 'Tasks',
    description: 'Show a filtered list of tasks',
    type: WIDGETS.TASKS,
    icon: <TaskIcon />,
  },
  {
    label: 'Kanban',
    description: 'Organize content via a kanban board',
    type: WIDGETS.KANBAN,
    icon: <KanbanIcon />,
  },
  {
    label: 'Iframe',
    description: 'Display an external website',
    type: WIDGETS.IFRAME,
    icon: <LinkIcon />,
  },
];

const getCustomDecks = (plugins: IntegrationType[]): WidgetMenuItem[] => {
  return plugins.map((plg) => {
    const icon = plg.iconUrl ? <StyledImg src={plg.iconUrl} /> : <PluginIcon />;
    return {
      label: plg.mTitle,
      description: plg.mDescription ?? 'Search plugin',
      type: WIDGETS.SEARCH_PLUGIN,
      icon,
      config: plg,
    };
  });
};

export interface ICreateWidgetMenu {
  show: boolean;
  x: number;
  y: number;
  index?: number;
}

export const createWidgetDefaults: ICreateWidgetMenu = {
  x: 0,
  y: 0,
  show: false,
};

const createWidgetMenu = atom<ICreateWidgetMenu>({ ...createWidgetDefaults });

export const useCreateWidgetMenu = () => useAtom(createWidgetMenu);

export function CreateWidgetMenu() {
  const [state, setState] = useCreateWidgetMenu();
  const { canAccessGridDecks } = useCanSeeGridDecks();
  const { canAccessDataTableWidget } = useCanSeeDataTable();
  const { canAccessPlugins } = useCanSeePlugins();
  const { integrations: plugins } = useGetIntegrationsForAdmin(
    IntegrationEnum.SearchPlugin,
    !canAccessPlugins,
  );

  const activePlugins = useMemo(() => {
    return plugins.filter((pl) => pl.mActive);
  }, [plugins]);

  const createWidget = useCreateWidget();

  const items = useMemo(() => {
    const customDecks = getCustomDecks(activePlugins);

    if (!canAccessGridDecks) return [...menuItems, ...customDecks];
    const gridWidgets: WidgetMenuItem[] = [
      {
        label: 'Grid',
        description: 'Free form grid',
        type: WIDGETS.STORYGRID,
        icon: <GridIcon />,
      },
    ];

    if (canAccessDataTableWidget) {
      gridWidgets.push({
        label: 'Data Table',
        description: 'Free form grid',
        type: WIDGETS.TABLE,
        icon: <GridIcon />,
      });
    }

    return [...menuItems, ...gridWidgets, ...customDecks];
  }, [canAccessGridDecks, canAccessDataTableWidget, activePlugins]);

  return (
    <StyledMenu
      keepMounted
      open={state.show}
      onClose={() => setState({ ...createWidgetDefaults })}
      anchorReference="anchorPosition"
      anchorPosition={{ top: state.y, left: state.x }}
      TransitionComponent={Fade}
    >
      {items.map(({ label, description, type, icon, config }) => (
        <StyledMenuItem
          button
          onClick={() => {
            createWidget({ type, config, index: state.index });
            setState({ ...createWidgetDefaults });
          }}
          key={label}
        >
          <HStack gap="4px">
            {icon}
            <Text variant="listItemLabel" color="highEmphasis">
              {label}
            </Text>
          </HStack>

          <Box maxWidth="172px">
            <Text as="p" variant="caption" style={{ textAlign: 'right' }}>
              {description}
            </Text>
          </Box>
        </StyledMenuItem>
      ))}
    </StyledMenu>
  );
}
