import { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import UPDATE_NOTE from 'operations/mutations/editNote';
import { EditorValue, Note } from 'types';
import { UpdateNoteInput } from 'types/graphqlTypes';

type UpdateNoteInputType = {
  input: UpdateNoteInput;
};

type UpdateNoteReturnType = {
  editNote: Note;
};

const useUpdateNote = () => {
  const [updateMember] = useMutation<UpdateNoteReturnType, UpdateNoteInputType>(UPDATE_NOTE);

  const updateNote = useCallback(
    async ({
      note,
      updatedProperties = note.mProperties,
      content = null,
      isCancelEvent,
      audit,
      createVersion,
    }: {
      note: Note;
      updatedProperties?: Note['mProperties'];
      content?: EditorValue | null;
      isCancelEvent?: boolean;
      audit?: UpdateNoteInput['audit'];
      createVersion?: boolean;
    }) => {
      const { mId, mRefId, mProperties, mTitle, mDescription, locked } = note;

      const hasUpdatedProperties = Object.keys(updatedProperties ?? {}).length > 0;

      const input: UpdateNoteInput = {
        mId,
        mRefId,
        mTitle,
        mDescription,
        locked: locked ?? null,
        mProperties: hasUpdatedProperties
          ? {
              __typename: 'NoteProperties',
              pinned: updatedProperties?.pinned ?? false,
              labelId: updatedProperties?.labelId ?? null,
            }
          : { ...mProperties, __typename: 'NoteProperties' },
        ...(isCancelEvent && { isCancelEvent }),
        ...(content && { content: JSON.stringify(content) }),
        ...(audit && { audit }),
        ...(createVersion && { createVersion }),
      };

      const response = await updateMember({
        variables: {
          input,
        },
      });

      return response?.data?.editNote;
    },
    [updateMember],
  );

  return { updateNote };
};

export default useUpdateNote;
