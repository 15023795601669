import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  listHeader: {
    zIndex: 10,
    top: 0,
    height: 34,
    padding: '0 12px 0 4px',
    position: 'sticky',
    backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel1,
    borderBottom: `1px solid ${theme.palette.dina.dividerLight}`,
    display: 'flex',
    alignItems: 'center',
    cursor: ({ disableCollapse }) => (disableCollapse ? 'default' : 'pointer'),
    '&:hover': {
      background: theme.palette.dina.whiteHoverOverlay,
    },
  },
  buttonOverride: {
    '&:hover': {
      backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel1,
      filter: 'brightness(1.08)',
    },
  },
  listHeaderText: {
    ...theme.typography.dina.listItemGroupHeadersLabel,
    flex: 1,
    '&:first-of-type': {
      paddingLeft: 6,
    },
  },
  rightItems: {
    display: 'flex',
    alignItems: 'center',
    '&>*': {
      ...theme.typography.dina.caption,
      color: theme.palette.dina.mediumEmphasis,
    },
  },
  storyCount: {
    ...theme.typography.dina.caption,
    color: theme.palette.dina.disabled,
    marginInline: 4,
  },
  loadMoreButtonContainer: {
    borderBottom: `1px solid ${theme.palette.dina.dividerLight}`,
    '& > :first-of-type': {
      borderRadius: '0px',
    },
    '& > :hover': {
      background: theme.palette.dina.surfaceAppBackgroundNavLevel1,
    },
  },
}));

export default useStyles;
