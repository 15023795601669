import React, { useState } from 'react';
import Tooltip from 'components/tooltip';
import NewFieldDialog from './NewFieldDialog';
import { Fields, StyledAdd, FieldsHeader } from '../styled';

function Header({ form, addNewField }) {
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <Fields>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <FieldsHeader>Fields</FieldsHeader>
        <Tooltip title="Add field">
          <StyledAdd onClick={() => setOpenDialog(true)} />
        </Tooltip>
      </div>
      <NewFieldDialog
        open={openDialog}
        setOpen={setOpenDialog}
        onNewField={addNewField}
        fields={form?.mProperties?.fields ?? []}
      />
    </Fields>
  );
}

export default Header;
