import { useMutation } from '@apollo/client';

import { DailyNote } from 'types';
import { DailyNoteInput } from 'types/graphqlTypes';

import { CREATE_DAILY_NOTE } from './graphql';

const useCreateDailyNote = () => {
  const [mutation, { loading, data, error }] = useMutation<{ createDailyNote: DailyNote }>(
    CREATE_DAILY_NOTE,
  );

  const createDailyNote = async (input: DailyNoteInput) => {
    const result = await mutation({
      variables: {
        input,
      },
    });

    return result?.data?.createDailyNote ?? null;
  };

  return {
    createDailyNote,
    loading,
    data,
    error,
  };
};

export default useCreateDailyNote;
