import { ApolloCache } from '@apollo/client';

import { OptionList } from 'types/graphqlTypes';

import { GET_LISTS, GetOptionLists } from './useGetOptionLists';

export const removeFromOptionListCache = (client: ApolloCache<unknown>, id: string) => {
  const cachedLists = client.readQuery<GetOptionLists>({
    query: GET_LISTS,
  });

  if (cachedLists?.getOptionLists) {
    const updated = [...cachedLists.getOptionLists.filter((f) => f.id !== id)];
    client.writeQuery({
      query: GET_LISTS,
      data: {
        getOptionLists: updated,
      },
    });
  }
};

export const writeOptionListToCache = (client: ApolloCache<unknown>, newList: OptionList) => {
  const cachedLists = client.readQuery<GetOptionLists>({
    query: GET_LISTS,
  });

  const all = [newList, ...(cachedLists?.getOptionLists ?? [])];
  client.writeQuery({
    query: GET_LISTS,
    data: {
      getOptionLists: all,
    },
  });
};
