import { css } from '@emotion/react';
import styled from '@emotion/styled';

import transientOptions from 'theme/helpers';

type WrapperProps = {
  $disabled?: boolean;
};

export const Wrapper = styled('div', transientOptions)<WrapperProps>`
  width: 24px;
  height: 24px;
  ${({ $disabled }) =>
    $disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`;
