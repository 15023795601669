import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Switch as MaterialSwitch } from '@material-ui/core';
import useStyles from './SwitchStyles';

const Switch = ({ selected, disabled, onClick, ...rest }) => {
  const classes = useStyles();
  const firstUpdate = useRef(true);
  const controlled = useRef(selected !== null);
  const [selectedState, setSelectedState] = useState(!!selected);

  const onChange = () => {
    if (!controlled.current) {
      setSelectedState(!selectedState);
    }

    if (onClick) {
      onClick(!selectedState);
    }
  };

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    if (controlled.current) {
      setSelectedState(selected);
    } else {
      setSelectedState(!selectedState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <MaterialSwitch
      checked={selectedState}
      disabled={disabled}
      onChange={onChange}
      value="checked"
      color="default"
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        checked: classes.checked,
        disabled: classes.disabled,
        track: classes.track,
        thumb: classes.thumb,
      }}
      {...rest}
    />
  );
};

Switch.propTypes = {
  /** If passed in as prop, Switch acts as a controlled component;
   * defines if the Switch is checked or not. */
  selected: PropTypes.bool,
  /** Defines if the Switch is enabled or not. */
  disabled: PropTypes.bool,
  /** Callback to be invoked when switch is toggled. */
  onClick: PropTypes.func,
};

Switch.defaultProps = {
  selected: null,
  disabled: false,
  onClick: null,
};

export default Switch;
