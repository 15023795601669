/* eslint-disable react/no-array-index-key */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ListItem from './listItem';

export const ListWrapper = styled('div')`
  height: calc(100% - 40px);
  width: 100%;
  overflow-y: auto;
`;

const ListView = ({ data, onSelectionChange, selectedIndex }) => (
  <ListWrapper>
    {data.map((item, index) => (
      <ListItem
        key={index}
        label={item.variant}
        selected={index === selectedIndex}
        onClick={() => {
          onSelectionChange(index);
        }}
      />
    ))}
  </ListWrapper>
);

ListView.propTypes = {
  /** data to be shown on the list */
  data: PropTypes.arrayOf(PropTypes.object),
  /** onSelectionChange callback function */
  onSelectionChange: PropTypes.func,
};

ListView.defaultProps = {
  data: [],
  onSelectionChange: () => {},
};

export default memo(ListView);
