import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Typography from '@material-ui/core/Typography';
import Scrollbar from 'components/scrollbar';
import { isBeforeToday } from 'screens/rundown/components/editor/utils';
import { isToday } from 'date-fns';
import { useSelectedDate } from 'screens/story/components/header/atoms';
import MenuItem from '../MenuItem/InstanceMenuItem';
import PlatformIcons from '../PlatformIcons';
import { getPlatformLabel, getPlatformIcon } from 'utils/instance/platform';

const Container = styled.div`
  width: 300px;
  padding: 8px 0;
`;

const Title = styled(Typography)`
  font-size: 10px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  margin-left: 15px;
`;

const getPublishingAt = (publishingAt) => {
  if (!publishingAt || isBeforeToday(publishingAt)) return new Date().toISOString();
  return publishingAt;
};

const InstanceListItem = ({ mTitle, mProperties, onClose, canScheduleInstance, publishingAt }) => {
  const { platformIcon, platform, accounts, platformKind } = mProperties;
  const [selectedDate, setSelectedDate] = useSelectedDate(new Date().toISOString());
  const Icon = PlatformIcons[platformIcon] ?? getPlatformIcon(platform, platformKind);
  const label = getPlatformLabel(platform, platformKind);
  const filteredAccounts =
    canScheduleInstance || mTitle !== 'current rundowns'
      ? accounts
      : accounts.filter((account) => account.accountTitle === 'Unassigned');

  const onDateChanged = (e) => {
    setSelectedDate(e?.endDate);
  };

  useEffect(() => {
    setSelectedDate(getPublishingAt(publishingAt));
  }, [publishingAt]);

  const disableDecrement = isToday(selectedDate) || isBeforeToday(selectedDate);

  return (
    <MenuItem
      key={mTitle}
      label={label}
      noOfSubInstance={filteredAccounts.length}
      items={filteredAccounts}
      onClose={(account) => {
        onClose({ platform, account, platformKind });
      }}
      publishingAt={selectedDate}
      onDateChanged={onDateChanged}
      disableDecrement={disableDecrement}
      isLinear={platform === 'linear'}
    >
      <Icon />
    </MenuItem>
  );
};

const SingleInstance = ({ instanceItems, onClose, canScheduleInstance, publishingAt }) => (
  <Scrollbar autoHeight autoHeightMin={0} autoHeightMax="65vh" top={8} bottom={8}>
    <Container>
      <Title>Create Instance</Title>
      {instanceItems
        .filter(
          (item) => item?.mProperties?.platform !== 'task' && item?.mProperties?.accounts?.length,
        )
        .map((item) => (
          <InstanceListItem
            key={item.mProperties.platformKind ?? item.mProperties.platform}
            onClose={onClose}
            canScheduleInstance={canScheduleInstance}
            publishingAt={publishingAt}
            {...item}
          />
        ))}
      {instanceItems
        .filter(
          (item) => item?.mProperties?.platform === 'task' && item?.mProperties?.accounts?.length,
        )
        .map((item, index) => (
          <>
            {index === 0 && <Title>Create Task</Title>}
            <InstanceListItem key={item.mProperties.platform} onClose={onClose} {...item} />
          </>
        ))}
    </Container>
  </Scrollbar>
);

SingleInstance.propTypes = {
  /** Content that will be shown on the Menu */
  instanceItems: PropTypes.oneOfType([PropTypes.array]),
  /** onClose function */
  onClose: PropTypes.func,
};

SingleInstance.defaultProps = {
  instanceItems: [],
  onClose: () => {},
};

export default SingleInstance;
