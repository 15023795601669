import { Transforms } from 'slate';
import { elementTypes } from 'components/editor/constants';

/**
 * Inserts paragraph element
 *
 * @param {Object} editor SlateJS editor instance
 * @param {Object} options SlateJS node options
 * @param {String} text Text content for the children
 * @returns {Object} SlateJS editor instance
 */

const insertParagraph = (editor, options = {}, text = '') => {
  Transforms.insertNodes(
    editor,
    {
      type: elementTypes.PARAGRAPH,
      children: [{ text }],
    },
    options,
  );

  return editor;
};

export default insertParagraph;
