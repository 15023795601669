import React, { memo, useCallback, useMemo, useState } from 'react';

import { useGetOrderForms } from 'api/order_forms/useGetOrderForms';
import { ReactComponent as Chevron } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_down.svg';
import { ReactComponent as ChevronActive } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_up.svg';
import Button from 'components/buttons/button/Button';
import DropdownMenu from 'components/dropdownMenu/DropdownMenu';
import Text from 'components/text';
import { Color } from 'features/reusableStyled';
import { getStatusColors, getStatusOption } from 'features/sidepanel/utils/utils';
import { Box, HStack, VStack } from 'layouts/box/Box';
import { FieldValue } from 'types/graphqlTypes';
import { OrderFormMemberType } from 'types/memberTypes/order_form';

interface Props {
  form?: OrderFormMemberType;
  formId?: string;
  value?: string;
  setValue: (val: string) => void;
  fullSpace?: boolean;
  showLabel?: boolean;
  style?: React.CSSProperties;
}

function StatusCell({
  form: passedForm,
  style,
  showLabel,
  formId,
  setValue,
  value,
}: Readonly<Props>) {
  const { keyedOrderForms } = useGetOrderForms();
  const [open, setOpen] = useState(false);

  const form = formId ? keyedOrderForms[formId] : passedForm;

  const options = useMemo(() => {
    return form !== undefined ? getStatusOption(form) : [];
  }, []);

  const colors = useMemo(() => {
    if (!form) return {};
    return getStatusColors(form);
  }, [form]);

  const selectedAlternative = useMemo(() => {
    if (!value) return options?.[0];
    return options.find((alt) => alt.value === value);
  }, [value]);

  const doSetValue = useCallback(
    (ev: Event, val: FieldValue) => {
      ev?.stopPropagation();
      if (typeof val === 'string') setValue(val);
    },
    [setValue],
  );

  const onOpenChange = useCallback(
    (val: boolean) => {
      setOpen(val);
    },
    [setOpen],
  );

  return (
    <DropdownMenu onOpenChange={onOpenChange}>
      <DropdownMenu.Trigger>
        <VStack gap="4px" style={style}>
          {showLabel && (
            <Text variant="overline" style={{ fontWeight: 400 }}>
              Status
            </Text>
          )}
          <Button
            variant="outlined"
            usage="outlined"
            height={26}
            style={{ width: 'fit-content', justifyContent: 'start' }}
          >
            <HStack gap="4px">
              {colors[selectedAlternative?.value ?? ''] && (
                <Color
                  $color={colors[selectedAlternative?.value ?? '']}
                  $size={12}
                  style={{ marginRight: '2px' }}
                />
              )}
              <Text variant="listItemLabel" truncate>
                {selectedAlternative?.label ?? value}
              </Text>
            </HStack>
            <Box container justifyContent="end">
              {open ? (
                <ChevronActive style={{ flexShrink: 0 }} />
              ) : (
                <Chevron style={{ flexShrink: 0 }} />
              )}
            </Box>
          </Button>
        </VStack>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content style={{ minWidth: '120px' }} align="start">
          <div onClick={(ev) => ev.stopPropagation()}>
            {options.map((alt) => (
              <DropdownMenu.Item onSelect={(ev) => doSetValue(ev, alt.value)} key={alt.id}>
                <HStack width="100%" gap="4px">
                  {colors[alt?.value ?? ''] && (
                    <Color $color={colors[alt?.value ?? '']} $size={12} />
                  )}
                  {alt.label}
                </HStack>
              </DropdownMenu.Item>
            ))}
          </div>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu>
  );
}

export default memo(StatusCell);
