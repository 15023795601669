import { Editor, Text, Transforms } from 'slate';

import { CustomElement } from 'types';

/**
 * Converts a string to uppercase or lowercase based on the `isActive` flag.
 */
const convertToCase = (text: string, isActive: boolean) => {
  return isActive ? text.toLowerCase() : text.toUpperCase();
};

/**
 * Toggles the case of text within the current selection in a Slate editor.
 * This function iterates through all text nodes in the selection and converts
 * their text to either uppercase or lowercase, depending on the `isActive` flag.
 *
 * It preserves the structure of the document, including void nodes.
 */
const toggleCase = (editor: Editor, isActive: boolean) => {
  const { selection } = editor;
  if (!selection) return;

  for (const [node, path] of Editor.nodes(editor, {
    at: selection,
    match: Text.isText,
  })) {
    const [parentNode] = Editor.parent(editor, path);

    if (!Editor.isVoid(editor, parentNode as CustomElement)) {
      const start = Editor.start(editor, path);
      const end = Editor.end(editor, path);
      const range = { anchor: start, focus: end };

      Transforms.insertText(editor, convertToCase(node.text, isActive), { at: range });
      Transforms.select(editor, selection);
    }
  }
};

export default toggleCase;
