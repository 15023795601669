import { useSelectedMenuSystemSettings } from '../../../../../atoms';
import { editorSettings } from '../../menu/editor/EditorMenu';

import Commands from './Commands';

function EditorDetails() {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [selectedMenu] = useSelectedMenuSystemSettings();

  if (selectedMenu === editorSettings.COMMANDS) return <Commands />;

  return null;
}

export default EditorDetails;
