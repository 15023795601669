import useOpenMember from 'components/contextMenu/useOpenMember';
import EditMdfBlock from 'features/sidepanel/EditMdfBlock';
import { Box } from 'layouts/box/Box';
import { MemberType } from 'types/graphqlTypes';

import type { PreviewProps } from '../utils/childMap';

import { OrderHeader } from './Headers';

export default function BlockPreview({
  member,
  onClose,
}: Readonly<PreviewProps & { member: MemberType }>) {
  const { openItem } = useOpenMember();

  if (!member) {
    return <div>No preview found</div>;
  }

  return (
    <Box width="800px" height="100%">
      <OrderHeader
        order={member}
        onClose={onClose}
        title={member?.mTitle ?? ''}
        handleOpenOrder={() => openItem(member)}
      />
      <Box padding="10px" overflow="auto" height="calc(100% - 42px)">
        <EditMdfBlock blockId={member.mRefId} mdfId={member.mdfId} resourceId={member.mId} />
      </Box>
    </Box>
  );
}
