import styled from '@emotion/styled';

export const StyledBaseInput = styled('input')`
  ${({ theme }) => theme.typography.dina.listItemLabel};
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  display: flex;
  width: 100%;
  height: 40px;
  border: none;
  background-color: transparent;
  ::placeholder {
    color: ${({ theme }) => theme.palette.dina.watermark};
  }
  :focus-visible {
    outline: none;
  }
  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  :required {
    border: 2px solid ${({ theme }) => theme.palette.dina.statusWarning};
    background-color: ${({ theme }) => theme.palette.dina.statusWarningBackground};
  }
`;

export const Input = styled('input')`
  // Layout
  display: flex;
  height: 32px;
  min-height: 32px;
  padding: 4px 8px;
  flex: 1 1 auto;
  width: 100%;

  // Styling
  background: ${({ theme }) => theme.palette.dina.buttonBackgroundOutlined};
  border: 1px solid ${({ theme }) => theme.palette.dina.inputBorderResting};
  border-radius: 6px;

  // Hover
  :hover {
    background: ${({ theme }) => theme.palette.dina.backgroundHover};
  }

  :read-only {
    pointer-events: none;
    opacity: 0.7;
    background-color: transparent;
  }
  // Required
  :required {
    background: ${({ theme }) => theme.palette.dina.statusWarningBackground};
    border: 1px solid ${({ theme }) => theme.palette.dina.statusWarning};
  }

  // Focus
  :focus {
    background: ${({ theme }) => theme.palette.dina.onFocusedBackground};
    border: 1px solid ${({ theme }) => theme.palette.dina.onFocus};
  }

  :focus-visible {
    outline: none;
  }

  // Typography
  ${({ theme }) => theme.typography.dina.listItemLabel}

  // Placeholder
  ::placeholder {
    color: ${({ theme }) => theme.palette.dina.watermark};
  }
`;
