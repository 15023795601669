import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { Collapse } from '@material-ui/core';

import { ReactComponent as AddIcon } from 'assets/icons/systemicons/add_round.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_down.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_right.svg';
import { IconButton } from 'components/buttons';
import LoadingIndicator from 'components/loadingIndicator';
import { useUploadingFiles } from 'store';

import {
  ArrowWrapper,
  GroupHeader,
  ItemCount,
  Label,
  LabelContainer,
  StickyHeader,
  UploadProgressContainer,
} from './group-styled';

const GroupView = ({
  label,
  children,
  variant,
  collapsed,
  setCollapsed,
  itemCount = 0,
  onAddClick,
  Input = undefined,
  showAddButton,
  type = '',
  storyId = '',
  stickyHeader = false,
}: {
  label: string;
  variant?: string;
  collapsed?: boolean;
  setCollapsed?: Dispatch<SetStateAction<boolean>>;
  itemCount?: number;
  onAddClick?: (event: unknown) => void;
  showAddButton?: boolean;
  type?: string;
  storyId?: string;
  stickyHeader?: boolean;
  children?: React.ReactNode;
  Input?: React.ReactNode;
}) => {
  const [open, setOpen] = useState(collapsed);
  const [uploadingFiles] = useUploadingFiles();

  const showButton = typeof showAddButton === 'undefined' ? true : showAddButton;

  const showUploadProgress = uploadingFiles.find(
    (f) => f.mediaType?.includes(type) && f.storyId?.includes(storyId),
  );

  const Header = useCallback(
    () => (
      <GroupHeader
        button
        onClick={() => {
          setOpen((pre) => !pre);
          if (setCollapsed) setCollapsed((pre) => !pre);
        }}
        variant={variant}
      >
        <LabelContainer>
          <ArrowWrapper>{!open ? <ArrowDown /> : <ArrowRight />}</ArrowWrapper>
          <Label>{label}</Label>
          {showUploadProgress && (
            <UploadProgressContainer>
              <LoadingIndicator />
            </UploadProgressContainer>
          )}
          {!showUploadProgress && itemCount >= 0 && showButton && (
            <ItemCount>{itemCount}</ItemCount>
          )}
          {showButton && onAddClick && (
            <IconButton
              usage="text"
              size={24}
              iconSize={18}
              title={`Add ${label}`}
              onClick={(event: React.MouseEvent) => {
                event.preventDefault();
                event.stopPropagation();
                onAddClick(event);
              }}
              className="addIcon"
            >
              <AddIcon />
            </IconButton>
          )}
        </LabelContainer>
      </GroupHeader>
    ),
    [itemCount, label, onAddClick, open, setCollapsed, showButton, showUploadProgress, variant],
  );

  return (
    <>
      {stickyHeader ? (
        <StickyHeader disableGutters>
          <Header />
        </StickyHeader>
      ) : (
        <Header />
      )}
      {Input}
      <Collapse in={!open} timeout="auto" mountOnEnter>
        {children}
      </Collapse>
    </>
  );
};

export default GroupView;
