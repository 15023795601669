/**
 * @typedef {Object} DateRange
 * @property {string} startDate - First ISO date within the range
 * @property {string} endDate - Last ISO date within the range
 * @property {Object} data - An object containing days as keys and empty arrays as value
 */

/**
 * Returns starting date & ending date & a data structure
 * containing every day within a time range according to the given time & time variant
 * @param {string} time - A date within the range of time variant.
 * @param {('date'|'week'|'month')} timeVariant - Variant which defines the range of time.
 * @returns {DateRange}
 */

import {
  getISODay,
  startOfMonth,
  startOfISOWeek,
  lastDayOfISOWeek,
  startOfDay,
  endOfDay,
  eachDay,
  format,
  addDays,
  subDays,
} from 'date-fns';

const TimeVariant = {
  Day: 'date',
  Week: 'week',
  Month: 'month',
};

const getDates = (time, timeVariant) => {
  let startDate;
  let endDate;

  if (timeVariant === TimeVariant.Day) {
    startDate = startOfDay(time);
    endDate = endOfDay(time);
  }

  if (timeVariant === TimeVariant.Week) {
    startDate = startOfDay(startOfISOWeek(time));
    endDate = endOfDay(lastDayOfISOWeek(time));
  }

  if (timeVariant === TimeVariant.Month) {
    startDate = startOfDay(startOfMonth(time));
    startDate = subDays(startDate, getISODay(startDate) - 1);
    endDate = endOfDay(addDays(startDate, 41));
  }

  const data = {};
  eachDay(startDate, endDate).forEach(date => {
    data[format(date, 'YYYY-MM-DD')] = [];
  });

  return {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    data,
  };
};

export { TimeVariant };
export default getDates;
