import { elementTypes } from 'components/editor/constants/types';
import { CustomElement, isCustomElement } from 'types/editor';

const isParagraph = (element: CustomElement) => element?.type === elementTypes.PARAGRAPH;

export const isEmptyParagraph = (element: CustomElement): boolean => {
  if (element?.type === elementTypes.PARAGRAPH) {
    const firstChild = element.children?.[0];
    if (isCustomElement(firstChild)) return false;
    return firstChild?.text === '';
  }
  // Return false if not a paragraph or text is not empty
  return false;
};

export default isParagraph;
