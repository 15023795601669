import { makeStyles } from '@material-ui/styles';

const commonFontStyles = (theme) => ({
  ...theme.typography.dina.listItemLabel,
  fontWeight: 'normal',
  fontStyle: 'normal',
  width: '100%',
});

const useStyles = makeStyles((theme) => ({
  standard: {
    display: 'flex',
    alignItems: 'center',
    height: '32px',
    paddingLeft: '5px',
  },
  compact: {
    display: 'flex',
    alignItems: 'center',
    height: '32px',
    paddingLeft: '12px',
  },
  mandatory: {
    ...commonFontStyles(theme),
    color: theme.palette.dina.statusWarning,
  },
  regular: {
    ...commonFontStyles(theme),
    color: theme.palette.dina.mediumEmphasis,
  },
}));

export default useStyles;
