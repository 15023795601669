import words from 'lodash/words';
import { Text } from 'slate';

import textColors from 'components/editor/constants/textColors';
import { CustomElement, CustomText, isCustomElement } from 'types';

const [white] = textColors;

const whiteColors = [white, '#fefefe'];

const filterNonColoredNodes = (
  nodes: (CustomElement | CustomText)[],
  ignoreColoredText = true,
  initialValue: string[] = [],
): string[] => {
  if (!nodes || !Array.isArray(nodes)) return initialValue;

  return nodes
    ? nodes.reduce((accumulator, node) => {
        if (
          Text.isText(node) &&
          (!ignoreColoredText || !node?.color || whiteColors.includes(node?.color))
        ) {
          accumulator.push(node?.text?.trim());
          return accumulator;
        }

        if (!isCustomElement(node)) return accumulator;

        const { children } = node;
        if (!children || !Array.isArray(children)) return accumulator;

        return filterNonColoredNodes(children, ignoreColoredText, accumulator);
      }, initialValue)
    : [];
};

/**
 * Finds non-colored words given SlateJS document
 *
 * @param {Object[]} document SlateJS document instance
 * @returns {String[]} Non-colored words
 */

const getWords = (document: CustomElement[], ignoreColoredText = true): string[] => {
  if (!Array.isArray(document)) return [];

  const nodes = filterNonColoredNodes(document, ignoreColoredText, []);
  const text = nodes.join(' ');

  return words(text);
};

export default getWords;
