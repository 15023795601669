import React from 'react';
import CalendarView from './calendar-view';
import { useExpandedAtom } from './store';

const Calendar = (props) => {
  const [isExpanded, setIsExpanded] = useExpandedAtom();

  return <CalendarView isExpanded={isExpanded} setIsExpanded={setIsExpanded} {...props} />;
};

export default Calendar;
