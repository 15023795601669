import styled from '@emotion/styled';

export const RootWrapper = styled('div')`
  width: 100%;
  height: calc(100% - 50px);
`;

export const ErrorWrapper = styled('div')`
  height: 100px;
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EmailWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

export const BlankDiv = styled('div')`
  margin-left: 24px;
`;
