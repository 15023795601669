import gql from 'graphql-tag';

export default gql`
  mutation DeleteMessage($input: CreateMessageInput) {
    deleteMessage(input: $input) {
      mId
      mRefId
      crudAction
      contentIds
    }
  }
`;
