import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

import { useEditorCommands } from 'store/editor';
import { MIdRefId } from 'types/members';

const DELETE_MEMBER = gql`
  mutation DeleteMember($input: DeleteSingleMemberInput) {
    deleteSingleMember(input: $input) {
      mId
      mRefId
    }
  }
`;

interface DeleteEditorCommand {
  deleteSingleMember: MIdRefId;
}

const useDeleteEditorCommand = () => {
  const [, setEditorCommands] = useEditorCommands();
  const [deleteMember] = useMutation<DeleteEditorCommand>(DELETE_MEMBER);

  const deleteEditorCommand = useCallback(async (mId: string, mRefId: string) => {
    await deleteMember({
      variables: { input: { mId, mRefId } },
      update: (_proxy, mutationResult) => {
        const deletedCommand = mutationResult.data?.deleteSingleMember;

        if (deletedCommand)
          setEditorCommands((prev) =>
            prev.filter((command) => command.mRefId !== deletedCommand?.mRefId),
          );
      },
    });
  }, []);

  return { deleteEditorCommand };
};

export default useDeleteEditorCommand;
