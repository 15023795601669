import { memo, useCallback, useMemo, useState } from 'react';

import Radio from 'components/buttons/radioButton';
import {
  ColumnHeader,
  StyledText,
  ToolbarItem,
  ToolbarItemColumn,
  ToolbarItemLabel,
} from 'components/command/toolbar/styled';
import Popover from 'components/dialogs/PopoverBuilder';
import { CloseIcon } from 'components/orderFormDialog/styled';
import { CommandToolbarProps, Option, Order, SortOption } from 'features/command/command-types';
import { isFiltering } from 'features/command/command-utils';
import { HStack } from 'layouts/box/Box';
import { SortDirection } from 'types/graphqlTypes';

import { sortToOption, ToolbarIcons } from './command-constants';

const orders: Order[] = [
  {
    key: 'desc',
    label: 'Descending',
  },
  {
    key: 'asc',
    label: 'Ascending',
  },
];

interface SortPopperProps {
  toolbarState: CommandToolbarProps;
  setToolbarState: React.Dispatch<React.SetStateAction<CommandToolbarProps>>;
  availableSorts: SortOption[];
}

const getSortLabel = (sortedBy: SortOption, ord: SortDirection) => {
  if (sortedBy === 'best') return 'Sort';
  return `Sorted by ${sortToOption[sortedBy].shortLabel}, ${ord}`;
};
const SortPopper = ({
  toolbarState,
  setToolbarState,
  availableSorts = [],
}: Readonly<SortPopperProps>) => {
  const [open, setOpen] = useState(false);

  const availableOptions = useMemo(() => {
    if (availableSorts.length) {
      const opt: Option[] = [];
      for (const o of availableSorts) {
        opt.push(sortToOption[o]);
      }
      return opt;
    }
    return Object.values(sortToOption);
  }, [availableSorts]);

  const updateState = useCallback(
    (val: Partial<CommandToolbarProps>) => {
      if (setToolbarState)
        setToolbarState((prevState) => {
          const newValue = {
            ...prevState,
            ...val,
          };
          return {
            ...newValue,
            isFiltering: isFiltering(newValue),
          };
        });
    },
    [setToolbarState],
  );

  const setSortOption = useCallback(
    (val: SortOption) => {
      updateState({ sortBy: val });
      setOpen(false);
    },
    [updateState],
  );

  const setSortDirection = useCallback(
    (val: SortDirection) => {
      updateState({ order: val });
      setOpen(false);
    },
    [updateState],
  );

  return (
    <Popover onOpenChange={setOpen} open={open}>
      <Popover.Trigger style={{ all: 'unset' }}>
        <StyledText disabled variant="caption">
          <ToolbarIcons.Sort />
          {getSortLabel(toolbarState.sortBy, toolbarState.order)}
        </StyledText>
      </Popover.Trigger>
      <Popover.Content style={{ minWidth: 'fit-content' }}>
        <ToolbarItem>
          <ToolbarItemColumn>
            <ColumnHeader variant="overline">Sort results by</ColumnHeader>
            {availableOptions.map((op) => (
              <HStack key={op.key} onClick={() => setSortOption(op.key)} margin="0 0 4px 0">
                <Radio selected={toolbarState.sortBy === op.key} size={24} />
                <ToolbarItemLabel variant="listItemLabel">{op.label}</ToolbarItemLabel>
              </HStack>
            ))}
          </ToolbarItemColumn>
          <ToolbarItemColumn className={toolbarState.sortBy === 'best' ? 'disabled' : ''}>
            <ColumnHeader variant="overline">Sort direction</ColumnHeader>
            {orders.map((op) => (
              <HStack
                key={op.key}
                onClick={() => (toolbarState.sortBy !== 'best' ? setSortDirection(op.key) : {})}
                margin="0 0 4px 0"
              >
                <Radio selected={toolbarState.order === op.key} size={24} />
                <ToolbarItemLabel variant="listItemLabel">{op.label}</ToolbarItemLabel>
              </HStack>
            ))}
          </ToolbarItemColumn>
          <Popover.Close asChild>
            <CloseIcon />
          </Popover.Close>
        </ToolbarItem>
      </Popover.Content>
    </Popover>
  );
};

export default memo(SortPopper);
