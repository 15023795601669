import { useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import difference from 'lodash/difference';
import map from 'lodash/map';

import UPDATE_MEMBER from 'operations/mutations/updateMember';
import UPDATE_MEMBERS from 'operations/mutations/updateMembers';
import GET_MEMBERS from 'operations/queries/getMembers';
import GET_MEMBERS_OF from 'operations/queries/getMembersOf';
import { MemberTypeEnum } from 'types/graphqlTypes';
import { User } from 'types/members';

const getIdsByAction = (oldMembers: User[], newMembers: User[]) => {
  const oldIds = map(oldMembers, 'mId');
  const newIds = map(newMembers, 'mId');

  const removedIds = difference(oldIds, newIds);
  const addedIds = difference(newIds, oldIds);
  return { addedIds, removedIds };
};

const useMemberDetails = (member?: User | null) => {
  const [updateMember] = useMutation(UPDATE_MEMBER);
  const [updateMembers] = useMutation(UPDATE_MEMBERS);

  const isContact = useMemo(() => member?.mType === MemberTypeEnum.Contact, [member?.mType]);

  const isUser = useMemo(() => member?.mType === MemberTypeEnum.User, [member?.mType]);

  const isTeam = useMemo(() => member?.mType === MemberTypeEnum.Team, [member?.mType]);

  const isDepartment = useMemo(() => member?.mType === MemberTypeEnum.Department, [member?.mType]);

  const { data: teamData } = useQuery<{ getMembersOf: User[] }>(GET_MEMBERS_OF, {
    variables: {
      input: {
        mId: member?.mId,
        membersOfType: isUser ? MemberTypeEnum.TeaUsr : MemberTypeEnum.DepTea,
      },
    },
    fetchPolicy: 'cache-and-network',
    skip: isTeam || isContact,
  });

  const { data: departmentData } = useQuery<{ getMembersOf: User[] }>(GET_MEMBERS_OF, {
    variables: {
      input: {
        mId: member?.mId,
        membersOfType: isUser ? MemberTypeEnum.DepUsr : MemberTypeEnum.DepTea,
      },
    },
    fetchPolicy: 'cache-and-network',
    skip: isDepartment || isContact,
  });

  const { data: userData } = useQuery<{ getMembers: User[] }>(GET_MEMBERS, {
    variables: {
      input: {
        mId: member?.mId,
        mType: isTeam ? MemberTypeEnum.TeaUsr : MemberTypeEnum.DepUsr,
      },
    },
    fetchPolicy: 'cache-and-network',
    skip: isContact || isUser,
  });

  const updateDescription = debounce(async (newDescription: string) => {
    await updateMember({
      variables: {
        input: {
          mId: member?.mId,
          mDescription: newDescription,
        },
      },
    });
  }, 300);

  const updateTeam = async (newTeams: User[]) => {
    if (!teamData?.getMembersOf) return;
    const { addedIds, removedIds } = getIdsByAction(teamData?.getMembersOf, newTeams);

    await updateMembers({
      variables: {
        newMembers: {
          members: addedIds.map((mId) => ({
            mId,
            mRefId: member?.mId,
            mType: isUser || isContact ? MemberTypeEnum.TeaUsr : MemberTypeEnum.DepTea,
          })),
        },
        removedMembers: {
          members: removedIds.map((mId) => ({
            mId,
            mRefId: member?.mId,
          })),
        },
      },
      update: (proxy) => {
        proxy.writeQuery({
          query: GET_MEMBERS_OF,
          variables: {
            input: {
              mId: member?.mId,
              membersOfType: isUser || isContact ? MemberTypeEnum.TeaUsr : MemberTypeEnum.DepTea,
            },
          },
          data: {
            getMembersOf: newTeams,
          },
        });
      },
    });
  };

  const updateDepartment = async (newDepartments: User[]) => {
    if (!departmentData?.getMembersOf) return;

    const { addedIds, removedIds } = getIdsByAction(departmentData?.getMembersOf, newDepartments);

    await updateMembers({
      variables: {
        newMembers: {
          members: addedIds.map((mId) => ({
            mId,
            mRefId: member?.mId,
            mType: isUser || isContact ? MemberTypeEnum.DepUsr : MemberTypeEnum.DepTea,
          })),
        },
        removedMembers: {
          members: removedIds.map((mId) => ({
            mId,
            mRefId: member?.mId,
          })),
        },
      },
      update: (proxy) => {
        proxy.writeQuery({
          query: GET_MEMBERS_OF,
          variables: {
            input: {
              mId: member?.mId,
              membersOfType: isUser || isContact ? MemberTypeEnum.DepUsr : MemberTypeEnum.DepTea,
            },
          },
          data: {
            getMembersOf: newDepartments,
          },
        });
      },
    });
  };

  const updateUser = async (newUsers: User[]) => {
    if (!userData?.getMembers) return;

    const { addedIds, removedIds } = getIdsByAction(userData?.getMembers, newUsers);

    await updateMembers({
      variables: {
        newMembers: {
          members: addedIds.map((mRefId) => ({
            mId: member?.mId,
            mRefId,
            mType: isTeam ? MemberTypeEnum.TeaUsr : MemberTypeEnum.DepUsr,
          })),
        },
        removedMembers: {
          members: removedIds.map((mRefId) => ({
            mId: member?.mId,
            mRefId,
          })),
        },
      },
      update: (proxy) => {
        proxy.writeQuery({
          query: GET_MEMBERS,
          variables: {
            input: {
              mId: member?.mId,
              mType: isTeam ? MemberTypeEnum.TeaUsr : MemberTypeEnum.DepUsr,
            },
          },
          data: {
            getMembers: newUsers,
          },
        });
      },
    });
  };

  return {
    users: userData?.getMembers,
    teams: teamData?.getMembersOf,
    departments: departmentData?.getMembersOf,
    updateDescription,
    updateTeam: !isUser ? updateTeam : undefined,
    updateDepartment: !isUser ? updateDepartment : undefined,
    updateUser: !isUser ? updateUser : undefined,
  };
};

export default useMemberDetails;
