import React from 'react';

import Tooltip from 'components/tooltip';
import Collapse from 'screens/main/components/header/navbar/settings/components/collapseList';
import { Fields, List, StyledSpan, StyledClose, StyledSpanWrapper } from '../styled';

function FormFields({ form, selectedField, setSelectedField, onDeleteField, children }) {
  const fields = form?.mProperties?.fields ?? [];
  return (
    <Fields>
      <List>
        <div>{children}</div>
        {fields.map((field) => (
          <StyledSpanWrapper key={field.id}>
            <Collapse.Item
              className='field-item'
              selected={selectedField?.id === field.id}
              onClick={() => setSelectedField(field)}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <StyledSpan className='label'>{field.label}</StyledSpan>
                <Tooltip title='Remove field'>
                  <StyledClose className='remove' onClick={() => onDeleteField(field.id)} />
                </Tooltip>
              </div>
            </Collapse.Item>
          </StyledSpanWrapper>
        ))}
      </List>
    </Fields>
  );
}

export default FormFields;
