import { CustomElement } from 'types';

/**
 * Gets the indent level from element data
 *
 * @param element SlateJS element
 * @returns Current indent level
 */
const getIndentLevel = (element: CustomElement) => {
  const { data = {} } = element;
  const { indentLevel = 0 } = data;

  return indentLevel;
};

export default getIndentLevel;
