/* eslint-disable no-console */
import { Auth } from '@aws-amplify/auth';

const AuthStates = {
  VERIFIED: 'verified',
  NOT_VERIFIED: 'not_verified',
  VERIFYING: 'verifying',
};
const AuthStateId = 'state';

const getAuthState = () => {
  const { localStorage } = window;
  return localStorage.getItem(AuthStateId);
};

const setAuthState = (state) => {
  const { localStorage } = window;
  const currentState = getAuthState();
  localStorage.setItem(AuthStateId, state);
  return currentState;
};

const openFederatedSignIn = async (context) => {
  try {
    await Auth.federatedSignIn();
  } catch (error) {
    console.error(`Auth.federateSignIn failed: ${error}`);
  }
};

const getSignInUser = (context) => {
  const user = { mId: '-', groups: [] };
  if (!context?.user) {
    return user;
  }

  const idToken = context.user.getSignInUserSession().getIdToken();
  if (idToken && idToken.payload) {
    user.mId = idToken.payload['custom:subalias'] || idToken.payload.sub;
  }
  user.groups = context.groups;
  return user;
};

const toBoolean = (strbool) => strbool?.toUpperCase() === 'TRUE';

const UseHostedUI = toBoolean(import.meta.env.REACT_APP_USE_HOSTED_UI);
const UseAuthAudit = toBoolean(import.meta.env.REACT_APP_AUTH_AUDIT);

/** Cognito authorization */
const CognitoAuth = {
  Auth: {
    region: import.meta.env.REACT_APP_AWS_AUTH_REGION,
    userPoolId: import.meta.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: import.meta.env.REACT_APP_AWS_COGNITO_USER_POOL_CLIENT_ID,
    identityPoolId: import.meta.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  },
};

/** Hosted UI authorization */
const HostedUIAuth = {
  Auth: {
    region: import.meta.env.REACT_APP_AWS_AUTH_REGION,
    userPoolId: import.meta.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: import.meta.env.REACT_APP_AWS_COGNITO_USER_POOL_CLIENT_ID,
    identityPoolId: import.meta.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    oauth: {
      domain: import.meta.env.REACT_APP_AWS_COGNITO_DOMAIN,
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: `${window.origin}/`,
      redirectSignOut: `${window.origin}/`,
      responseType: 'code', // or 'token', REFRESH token will only be created for responseType=code
    },
  },
};

if (import.meta.env.REACT_APP_AUTHENICATION_FLOW) {
  HostedUIAuth.Auth.authenticationFlowType = import.meta.env.REACT_APP_AUTHENICATION_FLOW;
}

const getAuthConfiguration = () => (UseHostedUI ? HostedUIAuth : CognitoAuth);

const isGroupMember = (userGroups, groups) => groups.some((group) => userGroups.includes(group));

export {
  openFederatedSignIn,
  getSignInUser,
  getAuthConfiguration,
  UseHostedUI,
  UseAuthAudit,
  isGroupMember as default,
  getAuthState,
  setAuthState,
  AuthStates,
};
