/* eslint-disable camelcase */
import { ReactComponent as cms } from 'assets/icons/systemicons/publishing_points/cms.svg';
import { ReactComponent as defaultIcon } from 'assets/icons/systemicons/publishing_points/default.svg';
import { ReactComponent as facebook } from 'assets/icons/systemicons/publishing_points/facebook.svg';
import { ReactComponent as instagram } from 'assets/icons/systemicons/publishing_points/instagram.svg';
import { ReactComponent as linkedin } from 'assets/icons/systemicons/publishing_points/linkedin.svg';
import { ReactComponent as audio } from 'assets/icons/systemicons/publishing_points/radio.svg';
import { ReactComponent as segment } from 'assets/icons/systemicons/publishing_points/segment.svg';
import { ReactComponent as snapchat } from 'assets/icons/systemicons/publishing_points/snapchat.svg';
import { ReactComponent as tiktok } from 'assets/icons/systemicons/publishing_points/tiktok.svg';
import { ReactComponent as task } from 'assets/icons/systemicons/publishing_points/todo_on.svg';
import { ReactComponent as custom_A } from 'assets/icons/systemicons/publishing_points/tools/custom_A.svg';
import { ReactComponent as custom_B } from 'assets/icons/systemicons/publishing_points/tools/custom_B.svg';
import { ReactComponent as custom_C } from 'assets/icons/systemicons/publishing_points/tools/custom_C.svg';
import { ReactComponent as custom_D } from 'assets/icons/systemicons/publishing_points/tools/custom_D.svg';
import { ReactComponent as custom_E } from 'assets/icons/systemicons/publishing_points/tools/custom_E.svg';
import { ReactComponent as custom_F } from 'assets/icons/systemicons/publishing_points/tools/custom_F.svg';
import { ReactComponent as custom_G } from 'assets/icons/systemicons/publishing_points/tools/custom_G.svg';
import { ReactComponent as custom_H } from 'assets/icons/systemicons/publishing_points/tools/custom_H.svg';
import { ReactComponent as custom_I } from 'assets/icons/systemicons/publishing_points/tools/custom_I.svg';
import { ReactComponent as custom_J } from 'assets/icons/systemicons/publishing_points/tools/custom_J.svg';
import { ReactComponent as custom_K } from 'assets/icons/systemicons/publishing_points/tools/custom_K.svg';
import { ReactComponent as custom_L } from 'assets/icons/systemicons/publishing_points/tools/custom_L.svg';
import { ReactComponent as custom_M } from 'assets/icons/systemicons/publishing_points/tools/custom_M.svg';
import { ReactComponent as custom_N } from 'assets/icons/systemicons/publishing_points/tools/custom_N.svg';
import { ReactComponent as custom_O } from 'assets/icons/systemicons/publishing_points/tools/custom_O.svg';
import { ReactComponent as custom_P } from 'assets/icons/systemicons/publishing_points/tools/custom_P.svg';
import { ReactComponent as custom_Q } from 'assets/icons/systemicons/publishing_points/tools/custom_Q.svg';
import { ReactComponent as custom_R } from 'assets/icons/systemicons/publishing_points/tools/custom_R.svg';
import { ReactComponent as custom_S } from 'assets/icons/systemicons/publishing_points/tools/custom_S.svg';
import { ReactComponent as custom_T } from 'assets/icons/systemicons/publishing_points/tools/custom_T.svg';
import { ReactComponent as custom_U } from 'assets/icons/systemicons/publishing_points/tools/custom_U.svg';
import { ReactComponent as custom_V } from 'assets/icons/systemicons/publishing_points/tools/custom_V.svg';
import { ReactComponent as custom_W } from 'assets/icons/systemicons/publishing_points/tools/custom_W.svg';
import { ReactComponent as custom_X } from 'assets/icons/systemicons/publishing_points/tools/custom_X.svg';
import { ReactComponent as custom_Y } from 'assets/icons/systemicons/publishing_points/tools/custom_Y.svg';
import { ReactComponent as custom_Z } from 'assets/icons/systemicons/publishing_points/tools/custom_Z.svg';
import { ReactComponent as linear } from 'assets/icons/systemicons/publishing_points/tv.svg';
import { ReactComponent as twitter } from 'assets/icons/systemicons/publishing_points/twitter.svg';
import { ReactComponent as youtube } from 'assets/icons/systemicons/publishing_points/youtube.svg';

const PlatformIcons: Record<string, React.FC<React.SVGProps<SVGSVGElement>>> = {
  twitter,
  linear,
  tv: linear,
  audio,
  radio: audio,
  cms,
  tiktok,
  linkedin,
  facebook,
  segment,
  task,
  instagram,
  youtube,
  snapchat,
  custom_A,
  custom_B,
  custom_C,
  custom_D,
  custom_E,
  custom_F,
  custom_G,
  custom_H,
  custom_I,
  custom_J,
  custom_K,
  custom_L,
  custom_M,
  custom_N,
  custom_O,
  custom_P,
  custom_Q,
  custom_R,
  custom_S,
  custom_T,
  custom_U,
  custom_V,
  custom_W,
  custom_X,
  custom_Y,
  custom_Z,
  defaultIcon,
};

export default PlatformIcons;
