import { useCallback, useState } from 'react';
import { ApolloQueryResult } from '@apollo/client';
import { ColumnDef, TableMeta } from '@tanstack/react-table';

import useDeleteBlock from 'api/mdfBlocks/useDeleteMdfBlock';
import { GetBlocks } from 'api/mdfBlocks/useGetMdfBlocks';
import { DeleteDialog } from 'components/dialogs/CommonDialogs';
import useToast from 'components/toast/useToast';
import { DataTableToolbar } from 'features/grids/common/components/toolbar/DataTableToolbar';
import { DataTable } from 'features/grids/common/DataTable';
import { useDataTable } from 'features/grids/common/useDataTable';
import { Resource } from 'hooks/useResourceDetails';
import { ParsedMemberType } from 'types';
import { Mdf } from 'types/graphqlTypes';

import { DataTableFilterField } from '../common/types';

import { CreateDropdown } from './components/menus/CreateDropdown';
import { useGetPlanningColumns } from './hooks/useGetColumns';
import { useGetMeta } from './hooks/useGetMeta';

type DayGridTableProps = {
  data: ParsedMemberType[];
  columns: ColumnDef<ParsedMemberType>[];
  meta: TableMeta<ParsedMemberType>;
  resource: Resource;
  filterFields: DataTableFilterField<ParsedMemberType>[];
};

/** Initialize the table instance */
function PlanningGridTable({ resource, filterFields, ...props }: Readonly<DayGridTableProps>) {
  const { table } = useDataTable({
    ...props,
    filterFields,
    storageKey: 'story-planning-grid',
    getRowId: (originalRow) => `${originalRow.mRefId}`,
  });

  return (
    <DataTable table={table}>
      <DataTableToolbar table={table} filterFields={filterFields}>
        <CreateDropdown resource={resource} />
      </DataTableToolbar>
    </DataTable>
  );
}

type PlanningGridProps = {
  blocks: ParsedMemberType[];
  mdfs: Mdf[];
  refetch: () => Promise<ApolloQueryResult<GetBlocks>>;
  resource: Resource;
  filterFields: DataTableFilterField<ParsedMemberType>[];
};

/** Initialize columns, meta and groom data */
export function PlanningGrid({ blocks, mdfs, refetch, ...rest }: Readonly<PlanningGridProps>) {
  const { errorToast } = useToast();
  const columns = useGetPlanningColumns(blocks, mdfs);

  const [blockToDelete, setBlockToDelete] = useState<{ mId: string; mRefId: string } | null>(null);

  const { deleteBlock } = useDeleteBlock();

  // ─── Remove Item From Grid ───────────────────────────────────────────
  const onRemoveItem = useCallback((block: { mId: string; mRefId: string }) => {
    setBlockToDelete(block);
  }, []);

  const closeDeleteDialog = useCallback(() => {
    setBlockToDelete(null);
  }, []);

  const handleDeleteBlock = useCallback(
    (keys: { mId: string; mRefId: string } | null) => {
      if (keys) {
        deleteBlock(keys)
          .catch(errorToast)
          .finally(() => {
            refetch().catch(errorToast);
            closeDeleteDialog();
          });
      }
    },
    [deleteBlock, refetch, closeDeleteDialog],
  );

  const meta = useGetMeta({ onRemoveItem });

  return (
    <>
      <PlanningGridTable data={blocks} columns={columns} meta={meta} {...rest} />
      <DeleteDialog
        open={Boolean(blockToDelete)}
        onClose={closeDeleteDialog}
        onClick={() => handleDeleteBlock(blockToDelete)}
        title="Delete block?"
        message="Are you sure you want to delete this block? This cannot be undone!"
      />
    </>
  );
}
