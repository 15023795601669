import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
// eslint-disable-next-line sort-imports
import { positionValues, Scrollbars } from 'react-custom-scrollbars';
import styled from '@emotion/styled';
import debounce from 'lodash/debounce';

const HorizontalTrack = styled.div`
  transition: opacity 200ms ease;
  z-index: 99;
  position: absolute;
  display: flex;
  width: 100%;
  left: 0px;
  bottom: 0px;
  height: 10px !important;
`;
const HorizontalThumb = styled.div`
  height: 10px !important;
  background: transparent;
  width: 100%;
  &:hover {
    display: block;
  }
  &:hover {
    & > :first-of-type {
      background: ${({ theme }) =>
        theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.4)' : 'rgba(255, 255, 255, 0.4)'};
    }
  }
  &:active {
    & > :first-of-type {
      background: ${({ theme }) =>
        theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.4)' : 'rgba(255, 255, 255, 0.4)'};
    }
  }
`;
const HorizontalThumbInner = styled.div`
  width: 100%;
  height: 4px;
  background: ${({ theme }) =>
    theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.15)' : 'rgba(255, 255, 255, 0.15)'};
  border-radius: 10px;
`;

const VerticalTrack = styled.div`
  ${({ top, bottom }: { top: number; bottom: number }) => ({
    top: `${top || 0}px`,
    bottom: `${bottom || 0}px`,
  })}
  right: 0;
  transition: opacity 200ms ease;
  z-index: 99;
  position: absolute;
  display: flex;
  justify-content: center;
`;

const VerticalThumb = styled.div`
  background: transparent;
  justify-content: center;
  width: 100%;
  &:hover {
    display: block;
  }
  &:hover {
    & > :first-of-type {
      background: ${({ theme }) =>
        theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.4)' : 'rgba(255, 255, 255, 0.4)'};
    }
  }
  &:active {
    & > :first-of-type {
      background: ${({ theme }) =>
        theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.4)' : 'rgba(255, 255, 255, 0.4)'};
    }
  }
`;

const VerticalThumbInner = styled.div`
  width: 4px;
  height: 100%;
  background: ${({ theme }) =>
    theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.15)' : 'rgba(255, 255, 255, 0.15)'};
  border-radius: 10px;
`;

interface Props {
  children: React.ReactNode;
  valueChanged?: string | null;
  closeToBottom?: () => Promise<void> | void;
  top?: number;
  bottom?: number;
  dark?: boolean;
  showHorizontal?: boolean;
  showVertical?: boolean;
  initialY?: number;
  fromTop?: ({ scrollTop, scrollHeight }: { scrollTop: number; scrollHeight: number }) => void;
  autoHeight?: boolean;
  autoHeightMin?: number | string;
  autoHeightMax?: number | string;
  style?: React.CSSProperties;
}

export default function Scrollbar({
  children,
  initialY,
  valueChanged = null,
  closeToBottom = () => {},
  top = 8,
  bottom = 8,
  dark = undefined,
  showHorizontal = false,
  showVertical = true,
  fromTop,
  ...rest
}: Readonly<Props>) {
  const scrollbar = useRef<Scrollbars>(null);

  useEffect(() => {
    if (scrollbar && valueChanged) {
      scrollbar?.current?.scrollToTop();
      scrollbar?.current?.scrollToLeft();
    }
  }, [valueChanged]);

  useLayoutEffect(() => {
    if (scrollbar && initialY) {
      scrollbar?.current?.scrollTop(initialY);
    }
  }, []);

  const closeToBottomHandler = debounce(() => {
    void closeToBottom();
  }, 300);

  const handleUpdate = useCallback(
    (values: positionValues) => {
      const { scrollTop, scrollHeight, clientHeight } = values;
      const buffer = 50; // 50px from scroll bottom

      // the value will be greater than one just before the scroll reaches the bottom
      const val = (scrollTop + buffer) / (scrollHeight - clientHeight);
      if (val > 1) {
        closeToBottomHandler();
      }
    },
    [closeToBottomHandler],
  );

  const onStop = () => {
    if (!fromTop || scrollbar?.current === null) return;
    const { scrollTop, scrollHeight } = scrollbar.current.getValues();
    fromTop({ scrollTop, scrollHeight });
  };

  return (
    <Scrollbars
      hideTracksWhenNotNeeded
      onScrollStop={onStop}
      onUpdate={handleUpdate}
      ref={scrollbar}
      style={{ height: '100%' }}
      renderTrackHorizontal={(props) => (
        <HorizontalTrack
          {...props}
          className="track-horizontal"
          style={{ display: !showHorizontal ? 'none' : '' }}
        />
      )}
      renderThumbHorizontal={(props) => (
        <HorizontalThumb dark={dark} {...props} className="thumb-horizontal">
          <HorizontalThumbInner />
        </HorizontalThumb>
      )}
      renderThumbVertical={(scrollProps) => (
        <VerticalThumb
          dark={dark}
          {...scrollProps}
          style={{ display: showVertical ? 'flex' : 'none' }}
        >
          <VerticalThumbInner />
        </VerticalThumb>
      )}
      renderTrackVertical={(scrollProps) => (
        <VerticalTrack {...scrollProps} style={{ width: '12px' }} top={top} bottom={bottom} />
      )}
      {...rest}
    >
      {children}
    </Scrollbars>
  );
}
