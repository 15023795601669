import isHotkey from 'is-hotkey';
import { Editor, Element, Transforms } from 'slate';

import { elementTypes } from 'components/editor/constants';

/**
 * Handles onKeyDown event on quote elements
 *
 * @param editor SlateJS editor instance
 * @param event React synthetic event
 * @returns SlateJS editor instance
 */

const onQuoteKeyDown = (editor: Editor, event: React.KeyboardEvent<HTMLDivElement>) => {
  const [match] = Editor.nodes(editor, {
    match: (node) => Element.isElement(node) && node.type === elementTypes.QUOTE,
  });

  if (match && isHotkey('shift+enter')(event)) {
    event.preventDefault();
    Transforms.insertText(editor, '\n');
  }

  return editor;
};

export default onQuoteKeyDown;
