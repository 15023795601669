import styled from '@emotion/styled';
import * as SliderPrimitive from '@radix-ui/react-slider';

export const Root = styled(SliderPrimitive.Root)`
  position: relative;
  display: flex;
  width: 100%;
  touch-action: none;
  align-items: center;
  user-select: none;
  height: 40px;
`;

export const Track = styled(SliderPrimitive.Track)`
  position: relative;
  flex: 1;
  height: 8px;
  width: 100%;
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.palette.dina.hoverOverlay};
  background-color: ${({ theme }) => theme.palette.dina.inputBackground};
`;

export const Range = styled(SliderPrimitive.Range)`
  position: absolute;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.dina.onFocus};
`;

export const Thumb = styled(SliderPrimitive.Thumb)`
  position: relative;
  display: block;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 6px solid ${({ theme }) => theme.palette.dina.highEmphasis};
  background-color: ${({ theme }) => theme.palette.dina.onFocus};
  transition: background-color 0.2s ease-in-out;
  outline: none;
  cursor: pointer;

  &:focus-visible {
    outline: none;
    ring: 2px solid ${({ theme }) => theme.palette.dina.buttonBackgroundSignificantAction};
    ring-offset: 2px;
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;
