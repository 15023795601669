import styled from '@emotion/styled/macro';

import { ColorVariants } from 'types';

interface RootProps {
  bg: keyof ColorVariants;
  border: keyof ColorVariants;
}

export const Root = styled('div')<RootProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  background-color: ${({ theme, bg }) => theme.palette.dina[bg]};
  padding: 6px 4px;
  border: 1px solid ${({ theme, border }) => theme.palette.dina[border]};
  border-radius: 6px;
  width: 100%;
  .infobarIcon {
    flex-shrink: 0;
    flex-grow: 0;
    width: 32px;
    height: 32px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      path {
        fill: ${({ border, theme }) => theme.palette.dina[border]};
        fill-opacity: 1;
      }
    }
  }

  & > :not(.infobarIcon) {
    width: calc(100% - 32px);
  }
`;
