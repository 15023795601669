import { useEffect, useState } from 'react';
import _debounce from 'lodash/debounce';

/**
 * Hook to manage debounced loading state.
 *
 * @param isLoading - Indicates whether loading should be shown. Defaults to `true`.
 * @param debounceTime - Time in milliseconds to debounce the loading state. Defaults to `500`.
 * @returns A boolean indicating whether loading should be shown.
 */
const useDebouncedLoading = (
  isLoading: boolean | undefined = true,
  debounceTime: number | undefined = 500,
) => {
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    // Debounce the setLoading function
    const debouncedSetLoading = _debounce(setShowLoading, debounceTime);

    // Call debouncedSetLoading when isLoading prop changes
    debouncedSetLoading(isLoading);

    // Cleanup the debounced function on component unmount
    return () => debouncedSetLoading.cancel();
  }, [isLoading]);

  return showLoading;
};

export default useDebouncedLoading;
