import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

export const ListBody = styled('ul')`
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;

  ${({ horizontal }) =>
    horizontal &&
    css`
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: minmax(200px, 1fr);
      overflow-x: auto;
      min-height: 100%;
      gap: 8px;
      padding: 4px !important;
    `}
`;

export const ListWrapper = styled('div')`
  height: 100%;
`;

export const Header = styled('div')`
  width: 100%;
`;

export const CollapseButton = styled('button')`
  ${({ theme }) => css`
    ${theme.typography.dina.listItemGroupHeadersLabel};
    border-bottom: 1px solid ${theme.palette.dina.dividerLight};
    background-color: ${theme.palette.dina.surfaceAppBackgroundNavLevel1};
  `}
  width: 100%;
  height: 40px;
  padding-left: 12px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 99;
  :hover {
    filter: brightness(108%);
  }
`;

export const CollapseButtonLabelWrapper = styled('span')`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const CollapseHeaderCount = styled('span')`
  ${({ theme }) => theme.typography.dina.caption};
  position: absolute;
  right: 24px;
`;
