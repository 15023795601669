/**
 * @param  {} resource
 * @param  {String} action
 * @param  {Boolean} defaultValue
 *
 * @returns {Boolean}
 */
const getResourcePermission = (resource, action, defaultValue) => {
  const { permissions = [] } = resource ?? {};
  const permission = permissions.find((p) => p.action === action);
  if (!permission) return defaultValue;
  return !!(permission.access && permission.access === 'allow');
};

/**
 * @param  {Array} policies
 * @param  {String} resourceName
 * @param  {String} action
 *
 * @returns {Boolean}
 */
const checkUserRight = (policies = [], resourceName = 'feature', action = '') => {
  const defaultPermission = resourceName !== 'feature';
  const resource = policies.find((r) => r.resourceName === resourceName);
  if (!resource) return defaultPermission;
  return getResourcePermission(resource, action, defaultPermission);
};

export default checkUserRight;
