const saveMetaFormsOnContext = (metadataResult, config) => {
  // eslint-disable-next-line no-param-reassign
  config.metadataForms = mergeForms(metadataResult);
};

export const blockFormSplit = (mRefId) => {
  const split = mRefId.split('#');
  const formType = split[0];
  const platform = split[1];
  const blockType = split[2];
  return {
    formType,
    platform,
    blockType,
  };
};

export const getBlockForms = (metadataResult) => {
  if (!metadataResult) return {};
  const { data } = metadataResult;
  if (!data) return {};
  const { getMetadataForms: forms } = data;
  if (!forms) return {};
  const blockForms = {};
  forms.forEach((form) => {
    const { mProperties, mTitle, mRefId } = form;
    const { formType, platform, blockType } = blockFormSplit(mRefId);
    if (formType === 'block' && platform && blockType) {
      if (!blockForms[platform]) blockForms[platform] = {};

      blockForms[platform][blockType] = {
        id: mRefId,
        mTitle,
        mRefId,
        mProperties,
      };
    }
  });
  return blockForms;
};

const mergeForms = (metadataResult) => {
  if (!metadataResult) return {};
  const { data } = metadataResult;

  if (!data) return [];
  const { getMetadataForms: forms } = data;
  if (!forms) return [];
  const ret = {
    mTitle: 'title',
    mRefId: 'refId',
    defaultViews: [],
    fields: [],
    views: [],
  };
  forms.forEach((form) => {
    const { mProperties, mTitle, mRefId } = form;
    ret.mTitle = mTitle;
    ret.mRefId = mRefId;
    ret.defaultView = (mProperties && mProperties.defaultView) || ret.defaultView;
    ret.defaultViews = [...ret.defaultViews, ...((mProperties && mProperties.defaultViews) || [])];
    ret.fields = [...ret.fields, ...((mProperties && mProperties.fields) || [])];
    ret.views = [...ret.views, ...((mProperties && mProperties.views) || [])];
  });

  const defaultForm = forms.find((f) => f.mRefId === 'form-00');
  if (defaultForm) {
    const { mProperties, mTitle, mRefId } = defaultForm;
    ret.mTitle = mTitle;
    ret.mRefId = mRefId;
    ret.defaultView = (mProperties && mProperties.defaultView) || ret.defaultView;
  }

  return [ret];
};

export default saveMetaFormsOnContext;
