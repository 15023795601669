import { ApolloCache, useMutation } from '@apollo/client';

import memberTypes from 'operations/memberTypes';
import DELETE_CONTACT from 'operations/mutations/deleteContact';
import GET_STORY from 'operations/queries/getStory';
import SEARCH_MEMBER from 'operations/queries/searchMembers';
import { getMemberQuery, getMembersSearchQuery } from 'operations/queryVariables';
import { useStoryMolecule } from 'screens/storyV2/store/story';
import { User } from 'types';
import useLogger from 'utils/useLogger';

interface CachedList {
  searchItem: { items: User[]; total: number; nextToken?: string; __typename: string };
}

interface MembersSearchQuery {
  input: {
    perPagelimit: number;
    mTypes: string[];
    searchString?: string;
  };
}

const updateCacheAfterContactDeletion = (
  proxy: ApolloCache<unknown>,
  id: string,
  variables: MembersSearchQuery,
  cacheData: CachedList | null,
) => {
  const filteredMembers = cacheData?.searchItem?.items?.filter((member) => member.mId !== id);

  proxy.writeQuery({
    query: SEARCH_MEMBER,
    variables: variables,
    data: {
      searchItem: {
        items: filteredMembers,
        total: cacheData?.searchItem?.total,
        nextToken: cacheData?.searchItem?.nextToken,
        __typename: cacheData?.searchItem?.__typename,
      },
    },
  });
};

const useDeleteContact = () => {
  const logger = useLogger('use delete contact');
  const { useStory } = useStoryMolecule();
  const [story] = useStory();

  const [deleteContactMutation, { loading }] = useMutation(DELETE_CONTACT);
  const deleteContact = async (id: string) => {
    const deleteMember = {
      mId: id,
    };

    const deleteSingleMember = {
      mId: id,
      mRefId: id,
    };

    const searchString = localStorage.getItem('searchString');

    try {
      await deleteContactMutation({
        variables: {
          deleteMember,
          deleteSingleMember,
        },
        update: (proxy) => {
          if (story) {
            const updatedStory = {
              ...story,
              mAssignedMembers: story?.mAssignedMembers?.filter((member) => member.mId !== id),
            };

            proxy.writeQuery({
              query: GET_STORY,
              variables: getMemberQuery(story?.mId),
              data: {
                getStory: updatedStory,
              },
            });
          }

          if (searchString) {
            const parsedSearchString = JSON.parse(searchString ?? '{}') as string;

            const cacheWithSearchString = proxy.readQuery<CachedList>({
              query: SEARCH_MEMBER,
              variables: getMembersSearchQuery(memberTypes.CONTACT, 50, parsedSearchString),
            });

            updateCacheAfterContactDeletion(
              proxy,
              id,
              getMembersSearchQuery(memberTypes.CONTACT, 50, parsedSearchString),
              cacheWithSearchString,
            );
          } else {
            const cacheWithoutSearchString = proxy.readQuery<CachedList>({
              query: SEARCH_MEMBER,
              variables: getMembersSearchQuery(memberTypes.CONTACT, 50),
            });

            updateCacheAfterContactDeletion(
              proxy,
              id,
              getMembersSearchQuery(memberTypes.CONTACT, 50),
              cacheWithoutSearchString,
            );
          }
        },
      });
    } catch (e) {
      logger.log(e);
    }
  };

  return { deleteContact, loading };
};

export default useDeleteContact;
