import { useEffect } from 'react';

import { createWidgetDefaults, useCreateWidgetMenu } from 'features/widget/CreateWidgetMenu';
import { ResizablePanel, ResizablePanelGroup } from 'lib/resize';

import { LayoutProps } from '../layouts';
import { PanelContent } from '../PanelContent';
import { VerticalResizeHandle } from '../ResizableHandlers';

export default function OneByOneByOneHorizontal({ widgets, ...panelProps }: Readonly<LayoutProps>) {
  const [, setCreateWidget] = useCreateWidgetMenu();

  const leftWidget = widgets[0];
  const rightWidget = widgets[1];

  useEffect(() => {
    return () => {
      setCreateWidget({ ...createWidgetDefaults });
    };
  }, []);

  return (
    <ResizablePanelGroup direction="horizontal">
      <ResizablePanel style={{ marginLeft: '8px', marginBottom: '8px', marginTop: '2px' }}>
        <PanelContent
          index={0}
          layout="1-1-horizontal"
          setCreateWidget={setCreateWidget}
          widget={leftWidget}
          {...panelProps}
        />
      </ResizablePanel>
      <>
        <VerticalResizeHandle />
        <ResizablePanel style={{ marginRight: '8px', marginBottom: '8px', marginTop: '2px' }}>
          <PanelContent
            index={1}
            layout="1-1-horizontal"
            setCreateWidget={setCreateWidget}
            widget={rightWidget}
            {...panelProps}
          />
        </ResizablePanel>
      </>
    </ResizablePanelGroup>
  );
}
