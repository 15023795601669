import { CustomFields } from 'utils/metadata';

const allowedInsertionPlatformMetadata = ['cms'];

/**
 * Builds metadata for allowed platform by views[]
 *
 * @param {String} viewId
 * @param {Array} views
 * @param {Array} fields
 * @returns {Array}
 */
const buildMetadata = (viewId, views, fields) => {
  const platformFields = views.find((view) => view.id === viewId)?.columndefs[0]?.columns || [];

  const fieldsMap = fields.reduce((map, field) => {
    if (!field.value) return map;

    map.set(field.id, {
      key: field.id,
      value: field.value,
    });
    return map;
  }, new Map());

  return platformFields.map((platformfield) => fieldsMap.get(platformfield.id)).filter(Boolean);
};

/**
 * Matches platformTypeId with type field of defaultViews object
 * to get the defaultView value
 *
 * @param {String} platformType
 * @param {Array} defaultViews
 * @returns {String}
 */
const findViewId = (platformType, defaultViews) =>
  defaultViews.find((view) => view.type === platformType)?.defaultView || '';

/**
 * Construct metadata for instance by platform
 *
 * @param {String} platform
 * @param {Array} metadataForms
 * @param {Object} payload
 * @returns {Array}
 */
const getPlatformMetadata = (platform, metadataForms, payload) => {
  const { title } = payload;
  const { views = [], fields = [], defaultViews = [] } = metadataForms[0];

  let metadata = [];

  if (allowedInsertionPlatformMetadata.includes(platform)) {
    const platformTypeId = `${platform}-instance-metadata`;
    const viewId = findViewId(platformTypeId, defaultViews);
    metadata = buildMetadata(viewId, views, fields);
  }

  const defaultMetadata = [{ key: CustomFields.STORY_TITLE, value: title }];

  return [...metadata, ...defaultMetadata];
};

/** Exports */
export default getPlatformMetadata;
