import {
  ChangeEvent,
  FocusEventHandler,
  KeyboardEventHandler,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Popover from '@material-ui/core/Popover';

import spinnerSrc from 'assets/images/loadingSpinner/dina-loader-anpng-60frames.png';
import Divider from 'components/divider';
import useInputEvents from 'hooks/useInputEvents';
import { MMetaDataField } from 'types/graphqlTypes';
import convert from 'utils/convertInputStringToTimeString';

import {
  DurationInput,
  EditableInputWrapper,
  InfoSummaryWrapper,
  TitleBoxWrapper,
  TitleWrapper,
  ViewContainer,
} from './styled';

interface InfoSummaryProps {
  title: string;
  duration: string;
  disabled: boolean;
  manualDurationField?: MMetaDataField;
  manualDurationSettingsValue?: boolean;
  onUpdate?: (value: string, manual?: boolean) => Promise<void>;
}

const InfoSummary = ({
  title = '',
  duration = '00:00',
  disabled = false,
  manualDurationField,
  manualDurationSettingsValue,
  onUpdate,
}: InfoSummaryProps) => {
  const [anchorEl, setAnchorEl] = useState<null | (EventTarget & HTMLDivElement)>(null);
  const [value, setValue] = useState(duration || '00:00');
  const [error, setError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const customClipStyle = useMemo(() => {
    if (!manualDurationField || !manualDurationSettingsValue) return false;

    return manualDurationField?.manual && manualDurationSettingsValue;
  }, [manualDurationField, manualDurationSettingsValue]);

  const onUpdateInput = async (newValue: string) => {
    setAnchorEl(null);
    if (newValue === duration) setValue(newValue);
    else {
      const { value: formattedValue, isError } = convert(newValue.trim());
      const clipAutoDuration = manualDurationField?.autoValue ?? formattedValue;
      const clipDurationValue = isError ? clipAutoDuration : formattedValue;
      if (isError) setError(true);
      setValue(clipDurationValue);

      if (!onUpdate) return;
      setIsSaving(true);
      if (!isError) {
        await onUpdate(clipDurationValue, true);
      } else {
        await onUpdate(clipDurationValue);
      }
      setIsSaving(false);
    }
  };

  const [inputRef, onKeyDown, onBlur] = useInputEvents(onUpdateInput, value, duration);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => setValue(event.target.value);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (error) setError(false);
    }, 2000);
    return () => {
      clearTimeout(timeOut);
    };
  }, [error]);

  useEffect(() => setValue(duration), [duration]);

  return (
    <InfoSummaryWrapper>
      <ViewContainer
        $disabled={disabled}
        onClick={(e) => !disabled && setAnchorEl(e.currentTarget)}
      >
        <TitleBoxWrapper>
          <TitleWrapper>{title}</TitleWrapper>
          {isSaving && <img src={spinnerSrc} alt="spinner" />}
        </TitleBoxWrapper>
        <DurationInput type="text" value={duration} $customClipStyle={customClipStyle} disabled />
      </ViewContainer>
      <Divider orientation="vertical" flexItem />
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => setAnchorEl(null)}
      >
        <EditableInputWrapper>
          <DurationInput
            autoFocus
            value={value}
            type="text"
            ref={inputRef}
            onChange={onChange}
            onKeyDown={onKeyDown as KeyboardEventHandler<HTMLInputElement>}
            onBlur={onBlur as FocusEventHandler<HTMLInputElement>}
            $error={error}
            disabled={disabled}
          />
        </EditableInputWrapper>
      </Popover>
    </InfoSummaryWrapper>
  );
};

export default InfoSummary;
