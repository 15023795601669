import MergeByProp from './MergeByProp';

export const actionTypes = {
  UPDATE_META: 'UPDATE_META',
  UPDATE_TRANSITION: 'UPDATE_TRANSITION',
};

/** Reducer that handles different action on the metadata */

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.UPDATE_META:
      return {
        ...state,
        metaData: MergeByProp(state.metaData, [payload], 'name'),
      };
    case actionTypes.UPDATE_TRANSITION:
      return {
        ...state,
        transitionData: state.transitionData.map((item) => {
          if (item.type === payload.type) return { ...item, ...payload };
          return item;
        }),
      };
    default:
      throw new Error();
  }
};

export default reducer;
