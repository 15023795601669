import { createRoot } from 'react-dom/client';

import './i18n';

import App from './App';

const container: HTMLElement | null = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');

const root = createRoot(container);
root.render(<App />);
