/* eslint-disable import/no-extraneous-dependencies */
import differenceBy from 'lodash/differenceBy';

/**
 * Returns array added items and removed items consisting only the propName specified
 *
 * @param {Object[]} array1 initial list of items
 * @param {Object[]} array2 updated list of items
 * @param {string} propName props to differentiate and return from the array of object
 * @returns {Object[]} [addedItems, removedItems], array consisting addedItems array and
 * removedItems array of propName
 *
 * [Note: Not specifying the propName will return array consisting of object not string ]
 */

const differenceByProp = (array1, array2, propName) => {
  const extractValue = (item) => (propName ? item[propName] : item);
  const addedValues = differenceBy(array2, array1, propName).map(extractValue);
  const removedValues = differenceBy(array1, array2, propName).map(extractValue);
  return [addedValues, removedValues];
};

export default differenceByProp;
