import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';

export const UnorderedList = styled('ul')`
  margin: 0;
  height: auto;
  padding: 16px 0 10px 0;
`;

export const ListItem = styled('li')`
  display: flex;
  justify-content: row;
  margin-bottom: 6px;
`;

export const Key = styled('p')`
  ${({ theme }) => css`
    ${theme.typography.dina.captionMedium};
    color: ${theme.palette.dina.highEmphasis};
  `}
  margin: 0;
  width: 112px;
  margin-right: 8px;
  overflow-wrap: break-word;
  text-align: right;
`;

export const Value = styled('p')`
  ${({ theme }) => css`
    ${theme.typography.dina.caption};
    color: ${theme.palette.dina.highEmphasis};
  `}
  margin: 0;
  max-width: 240px;
  overflow-wrap: break-word;
`;
