import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => {
  const buttonStyle = {
    width: '136px',
    height: '40px',
    margin: '8px 8px 8px 0',
    borderRadius: '4px',
    textTransform: 'none',
    color: theme.palette.dina.mediumEmphasis,
  };
  return {
    root: {
      width: '420px',
      height: ({ secondaryText }) => (!secondaryText ? '200px' : '248px'),
      backgroundColor: theme.palette.dina.surfaceCard,
      backdropFilter: 'blur(30px)',
      borderRadius: '8px',
      display: 'flex',
      flexDirection: 'column',
    },
    headerText: {
      ...theme.typography.dina.h5,
      lineHeight: '28px',
      height: '64px',
      margin: '0 8px 0 16px',
      display: 'flex',
      alignItems: 'center',
    },
    body: {
      margin: '16px 16px 8px 17px',
      flexGrow: 1,
    },
    bodyText: {
      ...theme.typography.dina.body2,
      lineHeight: '20px',
      fontWeight: 'normal',
    },
    secondBodyText: {
      marginTop: '20px',
    },
    footer: {
      height: '57px',
      width: '100%',
    },
    buttonContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'row-reverse',
    },
    overwriteButton: {
      ...buttonStyle,
      color: theme.palette.dina.highEmphasis,
      backgroundColor: theme.palette.dina.statusError,
      '&:hover': {
        backgroundColor: theme.palette.dina.statusError,
      },
    },
    okButton: {
      ...buttonStyle,
      backgroundColor: theme.palette.dina.blackHoverOverlay,
      backdropFilter: 'blur(15px)',
      border: `1px solid ${theme.palette.dina.buttonBorderOutlined}`,
    },
    cancelButton: {
      ...buttonStyle,
      '&:hover': {
        backgroundColor: theme.palette.dina.whiteHoverOverlay,
      },
    },
  };
});

export default useStyles;
