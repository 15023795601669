import { useState } from 'react';
import { useMutation } from '@apollo/client';

import { ReactComponent as AddIcon } from 'assets/icons/systemicons/add.svg';
import { IconButton } from 'components/buttons';
import Dialog from 'components/dialogs/DialogBuilder';
import Text from 'components/text/Text';
import { VStack } from 'layouts/box/Box';
import CREATE_DEPARTMENT from 'operations/mutations/createDepartment';
import { useDepartments } from 'store/members';
import { MemberTypeEnum } from 'types/graphqlTypes';
import { User } from 'types/members';
import useLogger from 'utils/useLogger';

import { StyledTextField } from './styled';

function CreateDepartment() {
  const logger = useLogger('RightSidebar-createDepartment');
  const [departments, setDepartments] = useDepartments();
  const [createDepartment, { loading }] = useMutation<{ createMember: User }>(CREATE_DEPARTMENT, {
    update: (_proxy, mutationResult) => {
      const { createMember } = mutationResult.data ?? {};

      const exists = departments.find((department) => department.mId === createMember?.mId);

      if (!exists && createMember) {
        setDepartments([...departments, createMember]);
      }
    },
  });

  const [open, setOpen] = useState(false);
  const [departmentName, setDepartmentName] = useState('');
  const [description, setDescription] = useState('');

  const onClose = () => {
    setDepartmentName('');
    setDescription('');
    setOpen(false);
  };

  const createNewDepartment = async () => {
    try {
      await createDepartment({
        variables: {
          input: {
            mType: MemberTypeEnum.Department,
            mTitle: departmentName,
            mDescription: description.length ? description : undefined,
          },
        },
      });
    } catch (error) {
      logger.log(error);
    }
    onClose();
  };

  return (
    <>
      <IconButton
        usage="text"
        size={24}
        iconSize={18}
        title="Add Department"
        onClick={() => setOpen(true)}
      >
        <AddIcon />
      </IconButton>
      <Dialog open={open} onClose={onClose}>
        <Dialog.Header>Create Department</Dialog.Header>
        <Dialog.Body>
          <VStack height="100%" width="100%" gap="12px">
            <VStack width="100%" gap="4px">
              <Text variant="overline">Title</Text>
              <StyledTextField
                fullWidth
                type="text"
                variant="filled"
                placeholder="Enter title here"
                onChange={(e) => setDepartmentName(e.target.value)}
                value={departmentName}
                error={departmentName?.length === 0}
                helperText={
                  departmentName?.length === 0 ? 'Department title is required' : undefined
                }
              />
            </VStack>

            <VStack width="100%" gap="4px">
              <Text variant="overline">Description</Text>
              <StyledTextField
                fullWidth
                variant="filled"
                type="text"
                placeholder="Enter description here"
                onChange={(e) => setDescription(e.target.value)}
                value={description}
                multiline={true}
                minRows={4}
                maxRows={10}
              />
            </VStack>
          </VStack>
        </Dialog.Body>
        <Dialog.Footer>
          <Dialog.CancelButton onCancel={onClose} />
          <Dialog.ConfirmButton
            disabled={!departmentName}
            title="Create Department"
            label="Create"
            onConfirm={createNewDepartment}
            loading={loading}
          />
        </Dialog.Footer>
      </Dialog>
    </>
  );
}

export default CreateDepartment;
