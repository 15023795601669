import styled from '@emotion/styled/macro';

import { instanceFooterLabel } from 'theme/styledTypography';
import transientOptions from 'theme/helpers';
import { Button } from '@material-ui/core';

export const RootWrapper = styled('div')`
  border-left: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Years = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
`;

export const ButtonWrapper = styled(Button, transientOptions)`
  width: 100%;
  max-height: 24px;
  min-height: 24px;
  margin: 8px 0;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: ${({ $selected, theme }) => ($selected ? theme.palette.dina.onSelected : null)};
  :hover {
    background-color: ${({ $selected, theme }) =>
      $selected ? theme.palette.dina.onSelected : theme.palette.dina.dividerLight};
  }

  &.MuiButton-text {
    ${instanceFooterLabel}
    letter-spacing: 0.25px;
    color: ${({ $selected, theme }) =>
      $selected ? theme.palette.dina.highEmphasis : theme.palette.dina.mediumEmphasis};
    text-transform: none;
  }
`;
