import styled from '@emotion/styled';
import { IconButton } from '@material-ui/core';
import transientOptions from 'theme/helpers';

export const RootWrapper = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const BlankDiv = styled('div', transientOptions)`
  margin-left: ${({ $margin }) => $margin};
`;

export const ArrowWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

export const CountText = styled('div')`
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
`;

export const PlusButton = styled(IconButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :disabled {
    opacity: 0.35;
  }
`;
