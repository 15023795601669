/* eslint-disable react/prop-types */
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import type { Column, RenderCellProps, RowsChangeData, SortColumn } from 'react-data-grid';
import DataGrid from 'react-data-grid';
import { keyBy } from 'lodash';

import { useGetOrderForms } from 'api/order_forms/useGetOrderForms';
import { useDatePicker } from 'components/mdfEditor/fields/date/DatePicker';
import useToast from 'components/toast/useToast';
import { useTreeChoiceDialog } from 'components/treeChoiceDialog/TreeChoiceDialog';
import { useEditFieldValueDialog } from 'features/mdf/mdf-utils';
import useUpdateOrder from 'screens/space/api/useUpdateOrder';
import { useAllMembersKeyed } from 'store';
import { useSetPreview } from 'store/preview';
import { Metadata, Order, ResourceType } from 'types/forms/forms';
import { Mdf, MemberTypeEnum } from 'types/graphqlTypes';
import { AssignedMember } from 'types/members';

import 'react-data-grid/lib/styles.css';

import { EmptyRows, OrderGridWrapper } from '../styled';
import { GroupedField, OrderColumn, OrderRow } from '../types';
import {
  extractColumnsFromOrders,
  extractGroupedMenuItemsFromOrders,
  extractRowsFromOrders,
  getFieldIdFromKey,
  getFieldMap,
  getFormMap,
  OrderRowToOrderMap,
} from '../utils';

import { useDeleteOrderAtom } from './OrderGridFull';
import OrderGridSortMenu from './OrderGridSortMenu';
import useGoToOrderResource from './useGoToOrderResource';
interface Props {
  orders: Order[];
  maxHeight?: number;
  mdfs: Mdf[];
  closeDialog?: () => void;
  showFilters: boolean;
  formId: string;
  loading: boolean;
}

export interface MapValue {
  value: boolean;
  column: Column<OrderRow, unknown>;
  immutable: boolean;
}
export type BooleanMap = Record<string, MapValue>;

export interface Filter extends Omit<OrderRow, 'id'> {
  enabled: boolean;
  status: string;
  ownerId: string;
  assigneeId: string;
}

// Context is needed to read filter values otherwise columns are
// re-created when filters are changed and filter loses focus
export const FilterContext = createContext<Filter | undefined>(undefined);

function rowKeyGetter(row: OrderRow) {
  return row.id;
}

const EmptyRowsRenderer = (props: { loading: boolean }) => {
  return <EmptyRows>{props.loading ? 'Loading ...' : 'No orders found'}</EmptyRows>;
};

type Comparator = (a: OrderRow, b: OrderRow) => number;

function getComparator(sortColumn: string): Comparator {
  return (a, b) => {
    const valA = a[sortColumn] ?? '';
    const valB = b[sortColumn] ?? '';

    if (typeof valA === 'boolean' && typeof valB === 'boolean') {
      return valA === valB ? 0 : a[sortColumn] ? 1 : -1;
    }

    if (typeof valB === 'string' && typeof valA === 'string') {
      return valA < valB ? -1 : valA > valB ? 1 : 0;
    }
    return 0;
  };
}

const OrderGrid = ({
  orders,
  mdfs,
  closeDialog,
  maxHeight,
  showFilters,
  formId,
  loading,
}: Props) => {
  const setPreview = useSetPreview();
  const columnsInLocalStorage = window.localStorage.getItem(formId);
  const { orderForms } = useGetOrderForms();
  const [anchorEl, setAnchorEl] = useState<EventTarget | null>(null);
  const [columns, setColumns] = useState<OrderColumn[]>([]);
  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
  const [rows, setRows] = useState<OrderRow[]>([]);
  const [groupedMenuItems, setGroupedMenuItems] = useState<GroupedField[]>([]);
  const [filters, setFilters] = useState(
    (): Filter => ({
      enabled: false,
      status: '__all',
      ownerId: 'all',
      assigneeId: 'all',
    }),
  );
  const [allMembersKeyed] = useAllMembersKeyed();
  const [, setPicker] = useDatePicker();
  const [, showEditFieldDialog] = useEditFieldValueDialog();
  const { updateOrder } = useUpdateOrder();
  const [, setOrderToDelete] = useDeleteOrderAtom();
  const [, openTreeChoiceDialog] = useTreeChoiceDialog();
  const { goToResource } = useGoToOrderResource();
  const { errorToast } = useToast();

  const doGoToResource = useCallback(
    (id: string, resourceType: ResourceType) => {
      goToResource(id, resourceType)
        .then(() => {
          if (closeDialog) closeDialog();
        })
        .catch(errorToast);
    },
    [goToResource, closeDialog],
  );

  const showPreview = useCallback(
    (id: string, title: string) => {
      const order = orders.find((ord) => ord.mId === id);
      if (order) {
        setPreview({
          mId: order.mResourceId,
          mRefId: order.mId,
          mTitle: title,
          mType: MemberTypeEnum.Order,
          mResourceType: order.mResourceType as MemberTypeEnum,
          mUpdatedAt: order.mUpdatedAt,
          mCreatedAt: order.mCreatedAt,
        });
      }
    },
    [setPreview, orders],
  );

  const handleDeleteOrder = useCallback(
    (props: RenderCellProps<OrderRow, unknown>) => {
      const mId = props.row.id;
      const mResourceId = props.row.__resourceId;
      setOrderToDelete({ mId, mResourceId });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rows, setOrderToDelete],
  );

  const showSortMenu = useCallback((ev: React.MouseEvent<SVGElement, MouseEvent>) => {
    ev.preventDefault();
    setAnchorEl(ev.currentTarget);
  }, []);

  const orderFormMap = useMemo(() => {
    return keyBy(orderForms, (orderForm) => orderForm.mRefId);
  }, [orderForms]);

  const filteredRows = useMemo(() => {
    const filtered = rows.filter((r) => {
      if (!filters.enabled) return true;
      return (
        (filters.status !== 'All' && filters.status !== '__all'
          ? r.__status === filters.status
          : true) &&
        (filters.ownerId !== 'all' ? r.__ownerId === filters.ownerId : true) &&
        (filters.assigneeId !== 'all' ? r.__assignee === filters.assigneeId : true)
      );
    });

    if (sortColumns.length === 0) return filtered;
    return [...filtered].sort((a, b) => {
      for (const sort of sortColumns) {
        const comparator = getComparator(sort.columnKey);
        const compResult = comparator(a, b);
        if (compResult !== 0) {
          return sort.direction === 'ASC' ? compResult : -compResult;
        }
      }
      return 0;
    });
  }, [rows, filters, sortColumns]);

  // There's a bug with this library together with typescript where a harmless error
  // is caught by webpack 60 times per second. See issue + work around.
  // https://github.com/adazzle/react-data-grid/issues/2712
  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (e.message.includes('ResizeObserver loop')) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div',
        );
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  useEffect(() => {
    const fieldMap = getFieldMap(mdfs);
    const formMap = getFormMap(mdfs);

    const cols = extractColumnsFromOrders(
      orders,
      fieldMap,
      formMap,
      orderFormMap,
      // fix when useAllMembersKeyed is typed
      allMembersKeyed as unknown as Record<string, AssignedMember>,
      doGoToResource,
      handleDeleteOrder,
      setFilters,
      showSortMenu,
      setPicker,
      showPreview,
      showEditFieldDialog,
      openTreeChoiceDialog,
      columnsInLocalStorage,
    );
    const rws = extractRowsFromOrders(orders, formMap, orderFormMap);

    const grpMenuIts = extractGroupedMenuItemsFromOrders(orders, formMap);

    setColumns(cols);
    setRows(rws);
    setGroupedMenuItems(grpMenuIts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders, mdfs, columnsInLocalStorage]);

  useEffect(() => {
    setFilters({
      enabled: showFilters,
      status: '__all',
      ownerId: 'all',
      assigneeId: 'all',
    });
  }, [showFilters]);

  const onNewRows = (rws: OrderRow[], data: RowsChangeData<OrderRow, unknown>) => {
    setRows(rws);
    const key = data.column.key;
    for (const index of data.indexes) {
      const order = rws[index];
      if (order) {
        const orderKey = OrderRowToOrderMap[key];
        const value = order[key];
        if (orderKey && value) {
          void updateOrder({
            mId: order.id,
            mResourceId: order.__resourceId,
            [orderKey]: value,
          }).finally(() => {});
        } else {
          const partialUpdate: Metadata = { [getFieldIdFromKey(key)]: value };
          void updateOrder({
            mId: order.id,
            mResourceId: order.__resourceId,
            metadata: JSON.stringify(partialUpdate),
          }).finally(() => {});
        }
      }
    }
  };

  return (
    <OrderGridWrapper maxHeight={maxHeight}>
      <FilterContext.Provider value={filters}>
        <DataGrid
          style={{ height: '100%' }}
          sortColumns={sortColumns}
          onSortColumnsChange={setSortColumns}
          defaultColumnOptions={{ sortable: true, resizable: true }}
          headerRowHeight={filters.enabled ? 40 : undefined}
          columns={columns.filter((col) => col.visible)}
          rows={filteredRows}
          renderers={{ noRowsFallback: <EmptyRowsRenderer loading={loading} /> }}
          rowKeyGetter={rowKeyGetter}
          onRowsChange={onNewRows}
        />
      </FilterContext.Provider>
      <OrderGridSortMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        columns={columns}
        setColumns={setColumns}
        formId={formId}
        groupedMenuItems={groupedMenuItems}
      />
    </OrderGridWrapper>
  );
};

export default OrderGrid;
