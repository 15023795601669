import { useMemo } from 'react';

import LoadingIndicator from 'components/loadingIndicator';
import Scrollbar from 'components/scrollbar';
import useFilterPlatforms from 'hooks/useFilterPlatforms';
import useGetPlatforms from 'hooks/useGetPlatforms';
import { useSelectedDate } from 'screens/story/components/header/atoms';
import { useStoryMolecule } from 'screens/storyV2/store/story';
import { Platform, PlatformAccount } from 'types';
import { pitchTypes } from 'utils/pitch';

import OtherMenuItems from './OtherMenuItems';
import PlatformItem from './PlatformItem';

import { CreateMenuLabel, StyledCreateMenuWrapper, StyledDivider } from './styled';

interface PlatformsAccumulatorType {
  validPlatforms: Platform[];
  validTasks: Platform[];
}

function CreateStoryItemsMenu({
  closeMenu,
  paneIndex,
}: {
  closeMenu: () => void;
  paneIndex?: number;
}) {
  const { useStory, useCanCreateInstances } = useStoryMolecule();
  const [story] = useStory();
  const canCreateInstances = useCanCreateInstances();

  const [selectedDate] = useSelectedDate();
  const { platforms, loading: platformsLoading } = useGetPlatforms(
    selectedDate ? new Date(selectedDate) : new Date(),
  );
  const { filterPlatforms } = useFilterPlatforms();

  const isStory = useMemo(() => {
    if (!story?.mType) return false;
    return !pitchTypes.has(story.mType);
  }, [story?.mType]);

  const { validPlatforms, validTasks } = useMemo(() => {
    const initialValue: PlatformsAccumulatorType = {
      validPlatforms: [],
      validTasks: [],
    };

    return filterPlatforms(platforms).reduce(
      (acc: PlatformsAccumulatorType, cur: Platform): PlatformsAccumulatorType => {
        if (!(cur.mProperties.accounts as PlatformAccount[]).length) {
          return acc;
        }

        if (cur.mProperties.platform === 'task') {
          acc.validTasks.push(cur);
        } else {
          acc.validPlatforms.push(cur);
        }

        return acc;
      },
      initialValue,
    );
  }, [platforms, filterPlatforms]);

  return (
    <Scrollbar autoHeight autoHeightMin={0} autoHeightMax="85vh" top={8} bottom={8}>
      <StyledCreateMenuWrapper>
        {isStory && (
          <>
            {/** instances */}
            {canCreateInstances && (
              <>
                <CreateMenuLabel>Create new instance</CreateMenuLabel>
                {platformsLoading && <LoadingIndicator />}
                {validPlatforms.map((vP) => (
                  <PlatformItem
                    platform={vP}
                    key={vP.mProperties.platformKind || vP.mProperties.platform}
                    closeMenu={closeMenu}
                    paneIndex={paneIndex}
                  />
                ))}
              </>
            )}

            {/** tasks */}
            {!!validTasks.length && (
              <>
                <CreateMenuLabel>Create Task</CreateMenuLabel>
                {validTasks.map((vP) => (
                  <PlatformItem
                    platform={vP}
                    key={vP.mProperties.platform}
                    closeMenu={closeMenu}
                    paneIndex={paneIndex}
                  />
                ))}
                <StyledDivider />
              </>
            )}
          </>
        )}
        {/** others */}
        <CreateMenuLabel>{isStory ? 'Others' : 'Options'}</CreateMenuLabel>
        <OtherMenuItems closeMenu={closeMenu} isStory={isStory} paneIndex={paneIndex} />
      </StyledCreateMenuWrapper>
    </Scrollbar>
  );
}

export default CreateStoryItemsMenu;
