/* eslint-disable sort-imports */
import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';

import { MemberType, UpdateMemberInput } from 'types/graphqlTypes';
import useLogger from 'utils/useLogger';

import { getErrorMessage, STANDARD_ORDER_FORM_KEYS } from '../commonKeys';

import { writeOrderFormToCache } from './handleOrderFormCache';

const UPDATE_ORDER_FORM = gql`
  mutation UpdateOrderForm($input: UpdateMemberInput) {
    updateMember(input: $input) {
      ${STANDARD_ORDER_FORM_KEYS}
    }
  }
`;

interface UpdateMember {
  updateMember: MemberType;
}

interface Input {
  input: UpdateMemberInput;
}

export const useUpdateOrderForm = () => {
  const [updateMutation] = useMutation<UpdateMember, Input>(UPDATE_ORDER_FORM);
  const logger = useLogger('UpdateOrderForm');
  const updateOrderForm = useCallback(
    async (input: UpdateMemberInput) => {
      try {
        const result = await updateMutation({
          variables: {
            input,
          },
          update(proxy, mutationResult) {
            if (mutationResult.data) {
              writeOrderFormToCache(proxy, mutationResult.data.updateMember);
            }
          },
        });
        return result?.data?.updateMember;
      } catch (err) {
        logger.log(getErrorMessage(err, input));
      }
    },
    [updateMutation],
  );
  return { updateOrderForm };
};
