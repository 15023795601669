// eslint-disable-next-line sort-imports
import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';

const commonScheduleButtonStyle = ({ theme }: { theme: Theme }) => css`
  border: 0.5px solid ${theme.palette.dina.buttonBackgroundOutlined};
  background-color: ${theme.palette.dina.blackHoverOverlay};
  border-radius: 4px;
  height: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;

  :hover {
    & > :first-child {
      path: {
        transition: 0.2s ease-in;
        fill-opacity: 1;
      }
    }

    & > :last-child {
      transition: 0.2s ease-in;
      color: ${theme.palette.dina.highEmphasis};
    }
  }
`;

export const ScheduleButton = styled('div')`
  ${({ theme }) => commonScheduleButtonStyle({ theme })};
  padding: 4px;
  gap: 0.25rem;
`;

export const ScheduleButtonDateTime = styled('div')<{ $isDisabled?: boolean }>`
  ${({ theme }) => commonScheduleButtonStyle({ theme })};
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ $isDisabled }) => ($isDisabled ? 0.5 : 1)};

  &:hover {
    svg {
      path: {
        fill-opacity: ${({ $isDisabled }) => ($isDisabled ? 0.5 : 1)};
      }
    }
  }
`;
