import { elementTypes } from 'components/editor/constants/types';

const { PARAGRAPH } = elementTypes;

/**
 * Find the last valid element except paragraph
 *
 * @param {Object[]} nodes
 * @returns {Object} last valid node element
 */

const findLastValidNode = (nodes) =>
  nodes
    .slice()
    .reverse()
    .find((node) => node.type === PARAGRAPH);

export default findLastValidNode;
