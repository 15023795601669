import { atom, useAtom } from 'jotai';

export interface ExternalUrl {
  mTitle: string;
  id: string;
  externalUrl: string;
  type: string;
  label?: string;
  showInRightPanel?: string;
  panelIcon?: string;
  mosId?: string;
  mosObjectType?: string;
  iconUrl?: string;
  iconXmlTag?: string;
}

export const externalUrlsAtom = atom<ExternalUrl[]>([]);
export const useExternalUrls = () => useAtom(externalUrlsAtom);
