import styled from '@emotion/styled';

import { ReactComponent as Back } from 'assets/icons/systemicons/arrows/disclosurearrow_left.svg';

export const CloseButton = styled('div')`
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 40px;
  height: 40px;
  right: 0;
  top: 0;
  cursor: pointer;
  :hover,
  :focus {
    path {
      fill-opacity: 1;
    }
  }
`;

export const BackIcon = styled(Back)`
  height: 40px;
  width: 40px;
  padding: 8px;
  cursor: pointer;
  :hover {
    path {
      fill-opacity: 1;
    }
  }
  :focus {
    path {
      fill-opacity: 1;
    }
  }
`;
