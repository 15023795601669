import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { isEmpty, keyBy } from 'lodash';

import { SupportedBlockTypes } from 'components/editor/componentsV2/elementComponents';
import useGetPlatforms from 'hooks/useGetPlatforms';
import { MdfId, MdfIds, MTypeToMdfId, SupportedMetadataTypes } from 'types';
import { GetMdfsInput, Mdf, MdfType, MemberTypeEnum } from 'types/graphqlTypes';

import { STANDARD_MDF_KEYS } from '../commonKeys';

import { toMdfDto } from './useCreateMdf';

export const GET_MDFS = gql`
  query GetMdfs($input: GetMdfsInput) {
    getMdfs(input: $input) {
      ${STANDARD_MDF_KEYS}
    }
  }
`;

export interface GetMdfs {
  getMdfs: MdfType[];
}

interface GetInput {
  input: GetMdfsInput;
}

export const isSupportedMetadata = (mType: MemberTypeEnum): mType is SupportedMetadataTypes => {
  return MTypeToMdfId[mType as SupportedMetadataTypes] !== undefined;
};

export const useGetInstanceBlockMdf = (type: SupportedBlockTypes | undefined) => {
  const { data } = useQuery<GetMdfs, GetInput>(GET_MDFS, {
    variables: {
      input: {
        all: true,
      },
    },
    fetchPolicy: 'cache-first',
    skip: !type,
  });

  const mdfs = useMemo(() => {
    return keyBy((data?.getMdfs ?? []).map(toMdfDto), (mdf) => mdf.id);
  }, [data]);

  const mdf = useMemo(() => {
    return mdfs[`block-${type}`] as Mdf | undefined;
  }, [mdfs, type]);

  return { mdf };
};

export const useGetMdfs = (props: GetMdfsInput) => {
  const { platformVariants } = useGetPlatforms(new Date());
  const { data, error, loading, refetch } = useQuery<GetMdfs, GetInput>(GET_MDFS, {
    variables: {
      input: {
        ...props,
      },
    },
    fetchPolicy: 'cache-first',
    skip: !props || isEmpty(props),
  });

  const mdfs = useMemo(() => {
    return (data?.getMdfs ?? []).map(toMdfDto);
  }, [data]);

  const mdfsSeparated = useMemo(() => {
    const defaults: Mdf[] = [];
    const custom: Mdf[] = [];
    const instances: Mdf[] = [];
    const blocks: Mdf[] = [];
    const subTypes: Mdf[] = [];
    if (!data?.getMdfs)
      return { defaults: [], instances: [], custom: [], subTypes: [], blocks: [] };
    for (const rawMdf of data.getMdfs) {
      if (MdfIds.includes(rawMdf.id as MdfId)) {
        defaults.push(toMdfDto(rawMdf));
      } else if (rawMdf.isSubtype) {
        subTypes.push(toMdfDto(rawMdf));
      } else if (platformVariants[rawMdf.id]) {
        instances.push(toMdfDto(rawMdf));
      } else if (rawMdf.id.startsWith('block-')) {
        blocks.push(toMdfDto(rawMdf));
      } else {
        custom.push(toMdfDto(rawMdf));
      }
    }
    return {
      defaults,
      blocks,
      custom,
      subTypes,
      instances,
    };
  }, [data, platformVariants]);

  const mdfsByMType: Record<SupportedMetadataTypes, Mdf | undefined> = useMemo(() => {
    return {
      [MemberTypeEnum.Story]: mdfs.find((mdf) => mdf.id === MTypeToMdfId[MemberTypeEnum.Story]),
      [MemberTypeEnum.Pitch]: mdfs.find((mdf) => mdf.id === MTypeToMdfId[MemberTypeEnum.Pitch]),
      [MemberTypeEnum.User]: mdfs.find((mdf) => mdf.id === MTypeToMdfId[MemberTypeEnum.User]),
      [MemberTypeEnum.Contact]: mdfs.find((mdf) => mdf.id === MTypeToMdfId[MemberTypeEnum.Contact]),
      [MemberTypeEnum.Rundown]: mdfs.find((mdf) => mdf.id === MTypeToMdfId[MemberTypeEnum.Rundown]),
    };
  }, [mdfs]);

  return { mdfs, mdfsSeparated, mdfsByMType, error, loading, refetch };
};
