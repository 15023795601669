import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as ArchiveOffSrc } from 'assets/icons/systemicons/ellipsis_menu/archive_off.svg';
import { ReactComponent as UnarchiveOffSrc } from 'assets/icons/systemicons/ellipsis_menu/archive_unarchive_off.svg';
import { ReactComponent as BookmarkOffSrc } from 'assets/icons/systemicons/ellipsis_menu/favorite_off.svg';
import { ReactComponent as BookmarkOnSrc } from 'assets/icons/systemicons/ellipsis_menu/favorite_on.svg';
import { ReactComponent as JoinSrc } from 'assets/icons/systemicons/ellipsis_menu/participate_join.svg';
import { ReactComponent as LeaveSrc } from 'assets/icons/systemicons/ellipsis_menu/participate_leave.svg';
import { Button as ButtonComponent, ButtonProps } from 'components/buttons';
import Tooltip from 'components/tooltip';

import { ButtonText, ButtonWrapper, MainWrapper } from './styled';

interface Props {
  usage: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  Icon: React.ReactNode;
  text: string;
  disabled?: boolean;
  title?: string;
}

const WrappedButton = (
  { usage, onClick, Icon, text, disabled = false, title }: Props,
  ref: React.LegacyRef<HTMLDivElement>,
) => (
  <ButtonWrapper disabled={disabled} title={title} ref={ref}>
    <ButtonComponent
      usage={usage as keyof ButtonProps['usage']}
      variant="discreet"
      onClick={onClick}
      disabled={disabled}
      title={title}
      radius="none"
      width={70}
      height="100%"
    >
      <p>
        {Icon}
        <ButtonText>{text}</ButtonText>
      </p>
    </ButtonComponent>
  </ButtonWrapper>
);

const Button = forwardRef(WrappedButton);

interface SubMenuProps {
  groupType: string;
  archived: boolean;
  bookmarked: boolean;
  joined: boolean;
  clickHandler: (type: string) => void;
  showArchiveButton: boolean;
  disableLeaveButton: boolean;
}

const SubMenu = ({
  archived,
  bookmarked,
  joined,
  clickHandler,
  showArchiveButton,
  disableLeaveButton,
}: SubMenuProps) => {
  const handleArchiveButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    clickHandler('archive');
  };

  const handleBookmarkButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    clickHandler('bookmark');
  };

  const handleJoinButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    clickHandler('join');
  };

  const onClickButtonsWrapper = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <MainWrapper onClick={onClickButtonsWrapper} onDoubleClick={onClickButtonsWrapper}>
      {showArchiveButton && (
        <Button
          usage="danger"
          onClick={handleArchiveButtonClick}
          Icon={archived ? <UnarchiveOffSrc /> : <ArchiveOffSrc />}
          text={archived ? 'Unarchive' : 'Archive'}
        />
      )}

      <Button
        usage="warning"
        onClick={handleBookmarkButtonClick}
        Icon={bookmarked ? <BookmarkOnSrc /> : <BookmarkOffSrc />}
        text={bookmarked ? 'Unmark' : 'Bookmark'}
      />
      {!archived && (
        <Tooltip hide={!disableLeaveButton} title={'Story must have one assignee'}>
          <Button
            usage="cta"
            onClick={handleJoinButtonClick}
            Icon={joined ? <LeaveSrc /> : <JoinSrc />}
            text={joined ? 'Leave' : 'Join'}
            disabled={disableLeaveButton}
          />
        </Tooltip>
      )}
    </MainWrapper>
  );
};

SubMenu.propTypes = {
  clickHandler: PropTypes.func,
  bookmarked: PropTypes.bool,
};

SubMenu.defaultProps = {
  clickHandler: () => {},
  bookmarked: false,
};

export default SubMenu;
