import React from 'react';
import PropTypes from 'prop-types';
import { useDrag } from 'react-dnd';
import { Typography } from '@material-ui/core';

import RundownIcon from 'components/rundownIcons';
import { mediaTypes } from 'utils/rundownItemTypes';
import { ReactComponent as Drag } from 'assets/icons/systemicons/drag.svg';
import useStyles from './thumbGraphic-styles';

const ThumbGraphic = (props) => {
  const { data, title } = props;
  const classes = useStyles();
  const [, dragRef] = useDrag({
    type: mediaTypes.GRAPHICS,
    item: {
      type: mediaTypes.GRAPHICS,
      payload: data,
    },
  });
  return (
    <div className={classes.container} ref={dragRef}>
      <div className={classes.rundownIcon}>
        <RundownIcon variant="overlayGraphics" isNormalSize noBackground />
      </div>
      <Typography className={classes.title} classes={{ root: classes.titleText }}>
        {title}
      </Typography>
      <Drag className={classes.dragButton} />
    </div>
  );
};

ThumbGraphic.propTypes = {
  /** Title of the graphic */
  title: PropTypes.string,
};

ThumbGraphic.defaultProps = {
  title: '',
};

export default ThumbGraphic;
