import React, { memo, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import useInputEvents from 'hooks/useInputEvents';
import TextAreaComponent from 'components/textArea';

const TextArea = memo(TextAreaComponent);

const Description = ({ readOnly, initialDescription, updateDescription, direction }) => {
  const [description, setDescription] = useState(initialDescription);

  const onUpdateDescription = useCallback(
    (newDescription) => {
      if (newDescription === initialDescription) setDescription(initialDescription);
      else updateDescription(newDescription);
    },
    [initialDescription, updateDescription],
  );

  const [descriptionInputRef, handleDescriptionKeydown, handleDescriptionBlur] = useInputEvents(
    onUpdateDescription,
    description,
    initialDescription,
  );

  const handleDescriptionChange = useCallback((newDescription) => {
    setDescription(newDescription);
  }, []);

  useEffect(() => {
    setDescription(initialDescription);
  }, [initialDescription]);

  return (
    <TextArea
      severity="regular"
      type="SoMe"
      disableLabel
      rows={3}
      description="Add a Description to the stream"
      value={description}
      onChange={handleDescriptionChange}
      placeholder="Add Description"
      inputRef={descriptionInputRef}
      onKeyDown={handleDescriptionKeydown}
      onBlur={handleDescriptionBlur}
      disabled={readOnly}
      direction={direction}
    />
  );
};

Description.propTypes = {
  /** value of initial description */
  initialDescription: PropTypes.string,
  /** boolean to disable input */
  readOnly: PropTypes.bool,
  /** callback to update description */
  updateDescription: PropTypes.func,
};

Description.defaultProps = {
  initialDescription: '',
  readOnly: false,
  updateDescription: () => {},
};

export default memo(Description);
