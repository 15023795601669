import React, { useState, useEffect } from 'react';
import Divider from 'components/divider';
import Scrollbar from 'components/scrollbar/scrollbar';
import fieldTypes from '../../../../../utils/fieldTypes';
import {
  RootWrapper,
  SettingsContentWrapper,
  FieldTopWrapper,
  OptionsWrapper,
} from './metadataField-styled';
import SettingsContent from '../settingsContent';
import {
  useSelectedMenuSystemSettings,
  useChangedMetadataField,
  useMetadataFields,
} from '../../../../../atoms';

const fieldTypesOptions = [];
for (const [key, value] of Object.entries(fieldTypes)) {
  fieldTypesOptions.push({ id: key, title: value, value });
}

const metadataFieldProperties = [
  {
    key: 'name',
    label: 'Name',
    type: fieldTypes.TEXT,
    propName: 'name',
    placeholder: 'Type the field name',
  },
  {
    key: 'label',
    label: 'Label',
    type: fieldTypes.TEXT,
    propName: 'label',
    placeholder: 'Type the field label',
  },
  {
    key: 'description',
    label: 'Description',
    type: fieldTypes.TEXT,
    propName: 'description',
    placeholder: 'Type the description',
  },
  {
    key: 'type',
    label: 'Type',
    type: fieldTypes.SELECT,
    propName: 'type',
    options: { fieldTypesOptions },
  },
  {
    key: 'mostag',
    label: 'MOS tag',
    type: fieldTypes.TEXT,
    propName: 'mostag',
    placeholder: 'Type the MOS tag',
  },
  {
    key: 'readonly',
    label: 'Read only?',
    type: fieldTypes.BOOLEAN,
    propName: 'readonly',
    description: 'The Metadata field is a read only field',
  },
];

const MetadataField = () => {
  const [selectedMenu] = useSelectedMenuSystemSettings();
  const [changedMetadataField, setChangedMetadataField] = useChangedMetadataField();
  const [metadataFields] = useMetadataFields();

  const field = metadataFields?.find((metadataField) => metadataField.id === selectedMenu);

  const options = field?.options?.map((option) => option.title);

  const [showOptions, setShowOptions] = useState(false);

  useEffect(() => {
    if (field?.type === fieldTypes.SELECT || field?.type === fieldTypes.MENU) {
      setShowOptions(true);
    } else {
      setShowOptions(false);
    }
  }, [field]);

  const onChangeType = (val) => {
    if (val === fieldTypes.SELECT || val === fieldTypes.MENU) setShowOptions(true);
    else {
      setShowOptions(false);
    }
  };

  const onChangeMetadataFieldSettings = (val, contentKey, originalValue) => {
    const [, attribute] = contentKey.split(':');
    const changedSetting = changedMetadataField.find((x) => x.key === contentKey);
    if (changedSetting) {
      if (changedSetting?.originalValue === val) {
        setChangedMetadataField(changedMetadataField.filter((x) => x.key !== changedSetting.key));
      } else {
        changedSetting.value = val;
      }
    } else {
      setChangedMetadataField([
        ...changedMetadataField,
        {
          key: contentKey,
          value: val,
          originalValue,
        },
      ]);
    }
    if (attribute === 'type') {
      onChangeType(val);
    }
  };

  const getValue = (type, value) => {
    if (type === fieldTypes.BOOLEAN) {
      return value?.toString() === 'true';
    }
    if (type === fieldTypes.NUMBER) {
      return value?.toString();
    }
    return value || '';
  };

  const viewSettingsContent = (property) => (
    <SettingsContentWrapper key={`${field.id}:${property.key}`}>
      <SettingsContent
        contentKey={`${field.id}:${property.key}`}
        label={property.label}
        type={property.type}
        value={getValue(property.type, field[property?.propName])}
        description={property.description}
        placeholder={property.placeholder}
        onChange={onChangeMetadataFieldSettings}
        changedSettings={changedMetadataField}
        options={fieldTypesOptions}
        width={`${property.type === fieldTypes.SELECT ? '60' : '100'}`}
      />

      {showOptions && property.type === fieldTypes.SELECT && (
        <OptionsWrapper>
          <SettingsContent
            key={`${field.id}:options`}
            contentKey={`${field.id}:options`}
            placeholder="Type list items here..."
            description="Define the list of options to be presented in a drop-down list.
             Use comma separated values."
            type={fieldTypes.TEXT}
            value={options?.join(', ')}
            options={fieldTypesOptions}
            onChange={onChangeMetadataFieldSettings}
            changedSettings={changedMetadataField}
          />
        </OptionsWrapper>
      )}
      <Divider />
    </SettingsContentWrapper>
  );

  return (
    <RootWrapper>
      <Scrollbar>
        {selectedMenu === field?.id && (
          <>
            <FieldTopWrapper>
              {metadataFieldProperties
                ?.slice(0, 2)
                .map((property) => viewSettingsContent(property))}
            </FieldTopWrapper>
            {metadataFieldProperties
              ?.slice(2, metadataFieldProperties.length)
              .map((property) => viewSettingsContent(property))}
          </>
        )}
      </Scrollbar>
    </RootWrapper>
  );
};

export default MetadataField;
