import { ReactComponent as ArrowDown } from 'assets/icons/search/caret.svg';
import { Flex, HStack } from 'layouts/box/Box';
import { CommandInput } from 'lib/command';

import { type MultiSelectContextProps, type MultiSelectInputProps } from '../types';

type MultiInputProps = Pick<
  MultiSelectInputProps,
  'inputProps' | 'placeholder' | 'hidePlaceholderWhenSelected' | 'disabled'
>;
type ContextProps = Pick<
  MultiSelectContextProps,
  | 'debouncedSearchTerm'
  | 'inputRef'
  | 'inputValue'
  | 'mouseOn'
  | 'onSearch'
  | 'selected'
  | 'setInputValue'
  | 'setOpen'
  | 'triggerSearchOnFocus'
>;

type InputProps = MultiInputProps & ContextProps;

/** Multi select input field */
export const Input = ({
  debouncedSearchTerm,
  disabled,
  hidePlaceholderWhenSelected,
  inputProps,
  inputRef,
  inputValue,
  mouseOn,
  onSearch,
  placeholder,
  selected,
  setInputValue,
  setOpen,
  triggerSearchOnFocus,
}: Readonly<InputProps>) => {
  // Input value state.
  const onValueChange = (value: string) => {
    setInputValue(value);
    inputProps?.onValueChange?.(value);
  };

  // Close dropdown when input is blurred.
  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (mouseOn.current === false) {
      setOpen(false);
    }
    inputProps?.onBlur?.(event);
  };

  // Trigger search when input is focused.
  const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    setOpen(true);
    if (triggerSearchOnFocus && onSearch) {
      void onSearch(debouncedSearchTerm);
    }
    inputProps?.onFocus?.(event);
  };

  const placeholderText = hidePlaceholderWhenSelected && selected.length !== 0 ? '' : placeholder;

  return (
    <HStack justifyContent="space-between" width="100%">
      <CommandInput
        {...inputProps}
        ref={inputRef}
        value={inputValue}
        disabled={disabled}
        hideSearchIcon
        onValueChange={onValueChange}
        onBlur={onBlur}
        onFocus={onFocus}
        placeholder={placeholderText}
      />
      <Flex width="24px">
        <ArrowDown />
      </Flex>
    </HStack>
  );
};
