import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './textBox-styles';
import GeneralLabel from '../generalLabel';
import SeverityIndicator from '../severityIndicator';
import ContainerBox from '../containerBox';

const TextBox = ({ value, description, height }) => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.boxContainer} style={{ height }}>
        {value}
      </div>
      <div className={classes.helperText}>{description}</div>
    </div>
  );
};

const TextBoxView = ({ severity, label, height, usage, ...rest }) => (
  <ContainerBox
    Indicator={<SeverityIndicator {...{ severity }} />}
    Label={<GeneralLabel {...{ severity, usage, label }} />}
    Field={<TextBox height={height} {...rest} />}
    usage={usage}
  />
);

TextBoxView.propTypes = {
  height: PropTypes.number,
  label: PropTypes.string,
  severity: PropTypes.string,
};

TextBoxView.defaultProps = {
  height: 32,
  label: '',
  severity: 'regular',
};
export default TextBoxView;
