import { memo, useCallback, useMemo } from 'react';

import {
  MultiSelect,
  MultiSelectInput,
  MultiSelectList,
  type Option,
} from 'components/combobox/MultiSelect';
import { ProviderType } from 'features/spaceDeck/SpaceDeck';

interface ProviderFilterProps {
  providers?: ProviderType[];
  selectedProviders?: ProviderType[];
  onChange: (val: ProviderType[]) => void;
}

// Get value string array from option array
const getSelectedValues = (selected: Option[]) => selected.map((option) => option.value);

// Convert provider to combobox option
const providerToOption = (provider: ProviderType) => ({
  value: provider.mRefId as string,
  label: provider.mTitle ?? (provider.mRefId as string),
});

const getSelectedOptions = (selectedProviders: ProviderType[], providers: ProviderType[]) => {
  const selectedOptions = [];

  for (const selected of selectedProviders) {
    const provider = providers.find((t) => t.mRefId === selected.mRefId);
    if (provider) selectedOptions.push(providerToOption(provider));
  }

  return selectedOptions;
};

function ProviderFilter({
  providers = [],
  selectedProviders = [],
  onChange,
}: Readonly<ProviderFilterProps>) {
  const handleChange = useCallback(
    (val: Option[]) => {
      const ids = getSelectedValues(val);
      const newProviders = ids.map((id) => {
        return providers.find((p) => p.mRefId === id);
      });

      onChange(newProviders as ProviderType[]);
    },
    [onChange],
  );

  const options = useMemo(
    () => providers.map((provider) => providerToOption(provider)),
    [providers],
  );

  const placeholder =
    selectedProviders.length > 0
      ? `${selectedProviders.length} provider${selectedProviders.length > 1 ? 's' : ''} selected`
      : 'Choose Provider...';

  return (
    <MultiSelect
      value={getSelectedOptions(selectedProviders, providers)}
      onChange={handleChange}
      options={options}
    >
      <MultiSelectInput placeholder={placeholder} />
      <MultiSelectList />
    </MultiSelect>
  );
}

export default memo(ProviderFilter);
