import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, OkButton, CancelButton } from './footer-styled';

const Footer = ({ onCancel, onOk, isOkDisabled }) => (
  <Wrapper>
    <CancelButton onClick={onCancel}>Cancel</CancelButton>
    <OkButton className="okButton" disabled={isOkDisabled} onClick={onOk}>
      Apply
    </OkButton>
  </Wrapper>
);

Footer.propTypes = {
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  isOkDisabled: PropTypes.bool,
};

Footer.defaultProps = {
  onCancel: () => {},
  onOk: () => {},
  isOkDisabled: false,
};

export default Footer;
