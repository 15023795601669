import React, { memo, useCallback } from 'react';
import { DragPreviewImage, useDrag, useDrop } from 'react-dnd';
import styled from '@emotion/styled';
import { useReadOnly, useSelected } from 'slate-react';

import useDragEnd from 'components/editor/hooks/useDragEnd';
import transientOptions from 'theme/helpers';
import { CustomElement } from 'types';
import dndTypes from 'utils/dndTypes';

const DropZone = styled('div', transientOptions)<{
  $isDragging?: boolean;
  readOnly?: boolean;
  $hovered?: boolean;
  $showHighlight?: boolean;
}>`
  position: relative;
  opacity: ${({ $isDragging }) => ($isDragging ? 0.4 : 1)};
  pointer-events: ${({ readOnly }) => (readOnly ? 'none' : 'all')};
  border-radius: 4px;
  background: ${({ $showHighlight, theme }) =>
    $showHighlight && theme.palette.dina.blackHoverOverlay};
  ::before {
    content: '';
    position: absolute;
    background-color: ${({ $hovered, $isDragging, theme }) =>
      $hovered && !$isDragging ? theme.palette.dina.onFocus : 'transparent'};
    width: calc(100% - 16px);
    left: 8px;
    bottom: 0;
    height: 3px;
  }
`;

interface DragAndDropProps {
  element: CustomElement;
  children: React.ReactNode;
  isDragDisabled?: boolean;
  hideHighlight?: boolean;
  dragPreviewImageSrc?: string;
  enableReadOnly?: boolean;
}

const DragAndDrop = ({
  element,
  children,
  isDragDisabled,
  hideHighlight,
  dragPreviewImageSrc,
  enableReadOnly,
}: Readonly<DragAndDropProps>) => {
  const isEditorReadonly = useReadOnly();
  const readOnly = enableReadOnly && isEditorReadonly;
  const isSelected = useSelected();
  const showHighlight = !readOnly && !hideHighlight && isSelected;

  const [onDragEnd] = useDragEnd();

  const [{ isDragging }, drag, preview] = useDrag({
    type: dndTypes.EDITOR_BLOCK,
    item: () => ({ type: dndTypes.EDITOR_BLOCK, payload: element }),
    canDrag: () => !readOnly,
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  const [{ hovered }, drop] = useDrop({
    accept: dndTypes.EDITOR_BLOCK,
    canDrop: () => {
      return !readOnly && !isDragging;
    },

    drop: (item: { payload: unknown }) => onDragEnd(item.payload, element),
    collect: (monitor) => ({ hovered: monitor.canDrop() && monitor.isOver() }),
  });

  const attachRef = useCallback(
    (elm: HTMLDivElement | null) => {
      if (!isDragDisabled) drag(elm);
      drop(elm);
    },
    [isDragDisabled, drag, drop],
  );

  return (
    <>
      {dragPreviewImageSrc && <DragPreviewImage connect={preview} src={dragPreviewImageSrc} />}
      <DropZone
        $hovered={hovered}
        $isDragging={isDragging}
        readOnly={readOnly}
        $showHighlight={showHighlight}
        ref={(ref) => attachRef(ref)}
      >
        {children}
      </DropZone>
    </>
  );
};

export default memo(DragAndDrop);
