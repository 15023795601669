import { useQuery } from '@apollo/client';

import GET_USERS from 'operations/queries/getAllDbUsers';
import { MemberType } from 'types/graphqlTypes';

interface QueryUsersList {
  getUsers: MemberType[];
}

const useGetUsers = (mType: string) => {
  const input = {
    mType: mType,
  };
  const { data, error, loading } = useQuery<QueryUsersList>(GET_USERS, {
    variables: {
      input: input,
    },
    fetchPolicy: 'cache-and-network',
  });
  return { data, error, loading };
};

export default useGetUsers;
