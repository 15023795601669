import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from '@emotion/styled';
import { ScopeProvider } from 'jotai-molecules';
import { isEqual } from 'lodash';

import useGetKanban from 'features/kanban/api/useGetKanban';
import ReadOnlyKanban from 'features/kanban/ReadOnly';
import { kanbanScope } from 'features/kanban/store/kanban';
import DatePickerButton from 'screens/main/components/leftArea/sidebar/stories/datePicker';
import { AtomDateRange } from 'store';
import { MemberType } from 'types/graphqlTypes';
import { PlatformAccount } from 'types/members';

import InstanceMenuItem from './InstanceMenuItem';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

const mId = 'rundowngroups';

type AccountType = PlatformAccount & {
  platformKind?: string;
  publishingAt?: string;
};

interface KanbanProps {
  items: AccountType[];
  customRenderItem?: ({ member }: { member: MemberType }) => JSX.Element;
  updateOnLoading?: () => void;
  dense?: boolean;
  darker?: boolean;
  setToggleRender?: Dispatch<SetStateAction<boolean>>;
}

const Kanban = ({
  items,
  customRenderItem,
  updateOnLoading = () => {},
  dense = false,
  darker = false,
  setToggleRender = () => {},
}: KanbanProps) => {
  const { getKanban, loading } = useGetKanban();

  const [localItems, setLocalItems] = useState(items);

  const members = useMemo(
    () =>
      items.map((item) => {
        const mRundownTemplateId = item.rundownTemplateId;
        const newItem = { ...item };
        delete newItem.rundownTemplateId;
        return {
          mId: item.accountId as string,
          mRefId: item.accountId as string,
          mTitle: item.accountTitle,
          mRundownTemplateId,
          mProperties: {
            account: newItem,
          },
        };
      }),
    [localItems],
  );

  useEffect(() => {
    if (!isEqual(items, localItems)) {
      setLocalItems(items);
    }
  }, [items, localItems]);

  useEffect(() => {
    getKanban({ mId, members, fetchPolicy: 'network-only' }).then(
      () => {},
      () => {},
    );
  }, [members]);

  useEffect(() => {
    setTimeout(updateOnLoading);
  }, [loading]);

  return (
    <ReadOnlyKanban
      loading={loading}
      customRenderItem={customRenderItem}
      dense={dense}
      darker={darker}
      customRenderHeader={undefined}
      setToggleRender={setToggleRender}
    />
  );
};

interface Props {
  onDateChanged?: (e: AtomDateRange) => void;
  publishingAt: string;
  disableDecrement?: boolean;
  items: AccountType[];
  children: ReactNode;
  onClose: (account: AccountType) => void;
  onDoubleClick?: (event: MouseEvent) => void;
  updateOnLoading?: () => void;
  hideDatePicker?: boolean;
  selectedValue?: string;
  dense?: boolean;
  darker?: boolean;
  setToggleRender?: Dispatch<SetStateAction<boolean>>;
}

function CategorizedMenu({
  onDateChanged = () => {},
  publishingAt = new Date().toISOString(),
  disableDecrement,
  items = [],
  onClose,
  onDoubleClick,
  children,
  updateOnLoading,
  hideDatePicker = false,
  selectedValue = undefined,
  dense = false,
  darker = false,
  setToggleRender = () => {},
}: Props): JSX.Element {
  const { unassigned, assigned } = items.reduce(
    (accumulator, currentItem: AccountType) => {
      if (currentItem.isUnassigned) {
        accumulator.unassigned.push(currentItem);
      } else {
        accumulator.assigned.push(currentItem);
      }
      return accumulator;
    },
    { assigned: [] as AccountType[], unassigned: [] as AccountType[] },
  );

  const customRenderItem = useCallback(
    ({ member }: { member: MemberType }) => {
      return (
        <InstanceMenuItem
          label={member.mTitle}
          noOfSubInstance={undefined}
          items={undefined}
          onClose={() => {
            onClose(member?.mProperties?.account as AccountType);
          }}
          onDoubleClick={onDoubleClick}
          unassignedVariant={undefined}
          publishingAt={undefined}
          onDateChanged={undefined}
          isLinear={undefined}
          disableDecrement={undefined}
          selected={member.mId === selectedValue}
          dense={dense}
        >
          {children}
        </InstanceMenuItem>
      );
    },

    [onClose, selectedValue, dense],
  );

  const memoizedDatePicker = useMemo(
    () => (
      <DatePickerButton
        selectedDate={publishingAt}
        onSelectedDateChange={onDateChanged}
        enableMultiselect={false}
        hideDropdown
        disableDecrement={disableDecrement}
        disableScheduleInPast
        canSelectRange={false}
        showQuickSelect={false}
      />
    ),

    [onDateChanged, publishingAt, disableDecrement],
  );

  return (
    <Wrapper>
      {!hideDatePicker && memoizedDatePicker}
      {unassigned.map((item) => (
        <InstanceMenuItem
          key={item.accountId ?? item.accountTitle}
          label={item.accountTitle}
          noOfSubInstance={undefined}
          items={item}
          onClose={onClose}
          onDoubleClick={onDoubleClick}
          unassignedVariant={true}
          publishingAt={undefined}
          onDateChanged={undefined}
          isLinear={undefined}
          disableDecrement={undefined}
          selected={selectedValue === item.accountId || selectedValue === item.accountTitle}
          dense={dense}
        >
          {children}
        </InstanceMenuItem>
      ))}
      {assigned.length > 0 && (
        <ScopeProvider scope={kanbanScope} value="newInstance">
          <Kanban
            items={assigned}
            customRenderItem={customRenderItem}
            updateOnLoading={updateOnLoading}
            dense={dense}
            darker={darker}
            setToggleRender={setToggleRender}
          />
        </ScopeProvider>
      )}
    </Wrapper>
  );
}

export default CategorizedMenu;
