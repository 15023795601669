import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'components/buttons';
import Divider from 'components/divider';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  buttonDiv: {
    height: '56px',
    padding: '0 8px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '8px',
  },
}));

const Footer = ({ onOk, onCancel, ...rest }) => {
  const classes = useStyles();

  return (
    <div>
      <Divider />
      <div className={classes.buttonDiv} {...rest}>
        <Button width={144} variant="discreet" usage="text" onClick={onCancel}>
          Cancel
        </Button>
        <Button width={144} variant="contained" usage="significant" onClick={onOk}>
          Ok
        </Button>
      </div>
    </div>
  );
};

Footer.propTypes = {
  /** onOk callback function */
  onOk: PropTypes.func,
  /** onClose callback function  */
  onClose: PropTypes.func,
};

Footer.defaultProps = {
  onOk: () => {},
  onClose: () => {},
};

export default Footer;
