import { BlockAsset, Metadata } from 'types';
import getTimeString from 'utils/getTimeString';
import { mediaTypes } from 'utils/rundownItemTypes';

const spaceAfterComma = (value: string) => `${value}, `;
/**
 * Appends a comma and space to a string if it has a value, otherwise returns an empty string.
 */
const appendWithComma = (value: string | undefined) => `${value ? spaceAfterComma(value) : ''}`;

/**
 * Concatenates metadata values into title and tooltip prefixes.
 * Iterates through a list of `Metadata` objects and constructs a single object containing:
 *
 * @param metadata An array of `Metadata` objects.
 * @returns An object containing `titlePrefix` and `tooltipPrefix` strings.
 */
export const concatenateMetaValues = (metadata: Metadata[]) => {
  if (!metadata?.length) return { titlePrefix: '', tooltipPrefix: '' };

  return metadata.reduce(
    (mObj, mdata) => {
      if (!mdata?.isVisible) return mObj;

      return {
        titlePrefix: mdata?.value
          ? `${appendWithComma(mObj.titlePrefix)}${mdata.value}`
          : mObj.titlePrefix,

        tooltipPrefix: `${appendWithComma(mObj.tooltipPrefix)}${mdata.name}: ${
          mdata.value ?? '""'
        }`,
      };
    },
    { titlePrefix: '', tooltipPrefix: '' },
  );
};

/**
 * Generates a subtitle string from a block asset.
 *
 * @param asset A `BlockAsset` object.
 * @param accumulator An optional string to prepend to the subtitle.
 * @returns An object with `type` and `value` properties for the generated subtitle.
 */
const generateSubtitle = (asset: BlockAsset, accumulator = '') => {
  if (!asset) return { type: 'emptyAsset', value: accumulator };

  const parts = [];

  if (accumulator) parts.push(accumulator);
  if (asset.mTitle) parts.push(asset.mTitle);
  else if (asset.title) parts.push(asset.title);

  if (asset.itemDuration) parts.push(getTimeString(Number(asset.itemDuration)));

  return { type: asset.assetType ?? asset.itemType, value: parts.join(' | ') };
};

/**
 * Generates a tooltip string from an array of block assets.
 *
 * @param assets An array of `BlockAsset` objects.
 * @param accumulator An optional string to start building the tooltip.
 * @returns A string representing the generated tooltip.
 */
const generateTooltip = (assets: BlockAsset[], accumulator: string) => {
  if (!assets.length) return accumulator;

  return assets
    .reduce(
      (acc, asset) => {
        const { value } = generateSubtitle(asset);
        return value ? [...acc, value] : acc;
      },
      [accumulator],
    )
    .filter((value) => value)
    .join('\n');
};

/**
 * Retrieves subtitle and tooltip strings based on metadata and assets.
 *
 * @param metaData An array of `Metadata` objects.
 * @param assets An array of `BlockAsset` objects.
 * @returns An object containing `subtitle` and `tooltip` strings.
 */
export const getSubtitleStrings = (metaData: Metadata[], assets: BlockAsset[]) => {
  const { titlePrefix, tooltipPrefix } = concatenateMetaValues(metaData);

  const existingAssets = Array.isArray(assets) ? assets : [];

  const graphicAsset = existingAssets.find((ast) => ast?.assetType === mediaTypes.GRAPHICS);
  const [asset] = graphicAsset ? [graphicAsset] : existingAssets;

  const subtitle = generateSubtitle(asset, titlePrefix);
  const tooltip = generateTooltip(existingAssets, tooltipPrefix);

  return { subtitle, tooltip };
};

/**
 * Splits a title string by slashes, trims leading/trailing whitespace,
 * and limits to the first three lines.
 */
export const splitTitleBySlash = (title: string) =>
  title
    .split('/')
    .slice(0, 3)
    .map((line) => line.trim());
