import gql from 'graphql-tag';

export default gql`
  mutation CreateTeam($input: CreateMemberInput) {
    createMember(input: $input) {
      mId
      mRefId
      mTitle
      mDescription
      mAvatarKey
      mType
    }
  }
`;
