import { useEffect } from 'react';

import { createWidgetDefaults, useCreateWidgetMenu } from 'features/widget/CreateWidgetMenu';
import { ResizablePanel, ResizablePanelGroup } from 'lib/resize';

import { LayoutProps } from '../layouts';
import { PanelContent } from '../PanelContent';
import { HorizontalResizeHandle, VerticalResizeHandle } from '../ResizableHandlers';

export default function ThreeByOneVertical({ widgets, ...panelProps }: Readonly<LayoutProps>) {
  const [, setCreateWidget] = useCreateWidgetMenu();

  const topLeftWidget = widgets[0];
  const topMiddleWidget = widgets[1];
  const topRightWidget = widgets[2];
  const bottomOne = widgets[3];

  useEffect(() => {
    return () => {
      setCreateWidget({ ...createWidgetDefaults });
    };
  }, []);

  return (
    <ResizablePanelGroup direction="vertical">
      <ResizablePanel style={{ marginRight: '8px', marginLeft: '8px' }}>
        <ResizablePanelGroup direction="horizontal">
          <ResizablePanel>
            <PanelContent
              index={0}
              layout="3-1-vertical"
              setCreateWidget={setCreateWidget}
              widget={topLeftWidget}
              {...panelProps}
            />
          </ResizablePanel>
          <VerticalResizeHandle />
          <ResizablePanel>
            <PanelContent
              index={1}
              layout="3-1-vertical"
              setCreateWidget={setCreateWidget}
              widget={topMiddleWidget}
              {...panelProps}
            />
          </ResizablePanel>
          <VerticalResizeHandle />
          <ResizablePanel>
            <PanelContent
              index={2}
              layout="3-1-vertical"
              setCreateWidget={setCreateWidget}
              widget={topRightWidget}
              {...panelProps}
            />
          </ResizablePanel>
        </ResizablePanelGroup>
      </ResizablePanel>
      <>
        <HorizontalResizeHandle />
        <ResizablePanel style={{ marginLeft: '8px', marginRight: '8px' }}>
          <PanelContent
            index={3}
            layout="3-1-vertical"
            setCreateWidget={setCreateWidget}
            widget={bottomOne}
            {...panelProps}
          />
        </ResizablePanel>
      </>
    </ResizablePanelGroup>
  );
}
