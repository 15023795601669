/* eslint-disable import/prefer-default-export */
import React, { lazy, Suspense } from 'react';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';

const ReactPlayerLazy = lazy(() => import('react-player/lazy'));

export const LazyPlayer = ({ playerRef, ...rest }) => (
  <Suspense fallback={<LoadingIndicator />}>
    <ReactPlayerLazy ref={playerRef} {...rest} />
  </Suspense>
);
