import { CustomText } from 'types/editor';

import { styles } from '../styles';

export const getNodeStyles = (node: CustomText) => {
  const { bold, color, underline, strikeThrough, italic } = node;
  const nodeStyles: Record<string, unknown>[] = [];

  if (bold) nodeStyles.push(styles.bold);

  if (italic) nodeStyles.push(styles.italic);

  if (color) nodeStyles.push({ color: color === '#fff' || color === '#ffffff' ? '#000' : color });

  if (underline && !strikeThrough) nodeStyles.push(styles.underline);

  if (strikeThrough && !underline) nodeStyles.push(styles.strikeThrough);

  if (underline && strikeThrough) nodeStyles.push(styles.underlineAndST);

  return nodeStyles;
};
