import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';

import { ReactComponent as ClearAll } from 'assets/icons/search/clear_all.svg';
import Popover from 'components/dialogs/PopoverBuilder';
import Tooltip from 'components/tooltip';
import { Color } from 'features/reusableStyled';
import { Box } from 'layouts/box/Box';

const StyledClearAll = styled(ClearAll)`
  width: 24px;
  cursor: pointer;
  :hover path {
    fill-opacity: 1;
  }
`;

interface Props {
  children: React.ReactElement;
  selectedColor?: string;
  onColorChoice: (val: string) => void;
  onClearColor: () => void;
}

const availableColors = [
  '#E0A5F5',
  '#C900FC',
  '#7A05FC',
  '#410BFF',
  '#119BFD',
  '#1CFCFE',
  '#6CE880',
  '#19FF47',
  '#00AB11',
  '#DFF401',
  '#FF9800',
  '#FF5722',
  '#DF0100',
  '#795548',
  '#9E9E9E',
  '#607D8B',
];

export function PopoverColors({
  children,
  onColorChoice,
  onClearColor,
  selectedColor,
}: Readonly<Props>) {
  const [colorsOpen, setColorsOpen] = useState(false);

  const doColorChoice = useCallback(
    (color: string) => {
      onColorChoice(color);
      setColorsOpen(false);
    },
    [onColorChoice, setColorsOpen],
  );

  return (
    <Popover open={colorsOpen} onOpenChange={(val) => setColorsOpen(val)}>
      <Popover.Trigger style={{ all: 'unset' }}>{children}</Popover.Trigger>
      <Popover.Content style={{ minWidth: '100px' }}>
        <Box container flexDirection="row" flexWrap="wrap" gap="4px" maxWidth="270px">
          <Tooltip title="Clear color">
            <StyledClearAll
              onClick={() => {
                onClearColor();
                setColorsOpen(false);
              }}
            />
          </Tooltip>
          {availableColors.map((color) => (
            <Color
              $size={24}
              key={color}
              $color={color}
              onClick={() => doColorChoice(color)}
              $selected={selectedColor === color}
            />
          ))}
        </Box>
      </Popover.Content>
    </Popover>
  );
}
