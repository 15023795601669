import styled from '@emotion/styled';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const transientOptions = {
  shouldForwardProp: (propName) => !propName.startsWith('$'),
};

export const MUIMenuItem = styled(MenuItem, transientOptions)`
  &.Mui-selected {
    background-color: ${({ theme }) => theme.palette.dina.onSelected};
    &:hover {
      background-color: ${({ theme }) => theme.palette.dina.statusOnHover};
    }
  }
  &.MuiMenuItem-root {
    :not(:last-of-type) {
      ${({ $isUnassigned }) =>
        $isUnassigned && {
          borderBottom: '1px solid rgba(172, 184, 198, 0.25)',
        }}
    }
    :last-of-type {
      border-bottom-right-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    }

    display: flex;
    gap: 14px;
    height: 32px;
    &:hover {
      background-color: ${({ theme }) => theme.palette.dina.statusOnHover};
    }
  }
`;

export const MUIListItemIcon = styled(ListItemIcon)`
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  min-width: 0;
  width: 24px;
  height: 24px;
`;

export const SubInstanceWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const MUIListItemText = styled(ListItemText, transientOptions)`
  & > span {
    &.MuiListItemText-primary {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-transform: capitalize;
      ${({ $isTitle, $isUnassigned, theme }) => ({
        fontStyle: $isUnassigned ? 'italic' : 'normal',
        color: $isTitle ? theme.palette.dina.highEmphasis : theme.palette.dina.mediumEmphasis,
        flex: $isTitle ? 1 : 0,
      })}
    }
  }
`;

export const PopupContainer = styled.div`
  width: 300px;
  padding: 8px 0;
  overflow: hidden;
`;
