import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

type Sizes = 'default' | 'compact';

const hideStoryThumbnailAtom = atomWithStorage('storyListItemHideThumbnail', false);
export const useHideStoryThumbnail = () => useAtom(hideStoryThumbnailAtom);

const storyListItemSizeAtom = atomWithStorage<Sizes>('storyListItemSize', 'default');
export const useStoryListItemSize = () => useAtom(storyListItemSizeAtom);
