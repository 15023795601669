import { atom, useAtom, useSetAtom } from 'jotai';

import { currentTab } from 'store/tabs';
import { SyncProviderType } from 'types/graphqlTypes';
import syncSyncProviders from 'utils/syncSyncProviders';

/** mimir provider for story */
export const mimirProviderAtomForStory = atom<SyncProviderType[] | null>(null);
export const useMimirProviderForStory = () => useAtom(mimirProviderAtomForStory);

/** mimir provider for instance*/
export const mimirProviderAtomForInstance = atom<SyncProviderType[] | null>(null);
export const useMimirProviderForInstance = () => useAtom(mimirProviderAtomForInstance);

const backgroundSyncFolderSetAtom = atom(null, (get, set) => {
  const tab = get(currentTab);
  const syncProvidersForStory = get(mimirProviderAtomForStory);
  set(mimirProviderAtomForInstance, null);

  if (tab?.type !== 'pitch') {
    syncSyncProviders(syncProvidersForStory);
    return;
  }
  set(mimirProviderAtomForStory, null);
});

export const useSetBackgroundSyncFolder = () => useSetAtom(backgroundSyncFolderSetAtom);
