import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';

import { CreateOptionList, OptionList } from 'types/graphqlTypes';
import useLogger from 'utils/useLogger';

import { getErrorMessage, STANDARD_OPTION_LIST_KEYS } from '../commonKeys';

import { writeOptionListToCache } from './handleOptionListCache';

const CREATE_LIST = gql`
  mutation CreateList($input: CreateOptionListInput) {
    createOptionList(input: $input) {
      ${STANDARD_OPTION_LIST_KEYS}
    }
  }
`;

interface CreateOptionListWrapper {
  createOptionList: OptionList;
}

export const useCreateOptionList = () => {
  const [createMutation] = useMutation<CreateOptionListWrapper>(CREATE_LIST);
  const logger = useLogger('CreateList');
  const createOptionList = useCallback(
    async (input: CreateOptionList) => {
      try {
        const result = await createMutation({
          variables: {
            input,
          },
          update: (proxy, mutationResult) => {
            const newList = mutationResult.data?.createOptionList;
            if (newList) {
              writeOptionListToCache(proxy, newList);
            }
          },
        });
        return result.data?.createOptionList;
      } catch (err) {
        logger.log(getErrorMessage(err, input));
      }
    },
    [createMutation],
  );
  return { createOptionList };
};
