import { memo } from 'react';

import { Root } from './styled';

interface WrapperProps {
  children: React.ReactNode;
  className?: string;
  showRightMargin?: boolean;
  rightMarginWidth?: number;
}

function Wrapper({
  children,
  showRightMargin = false,
  rightMarginWidth = 4,
  className,
}: Readonly<WrapperProps>) {
  return (
    <Root
      className={className}
      $showRightMargin={showRightMargin}
      $rightMarginWidth={rightMarginWidth}
    >
      {children}
    </Root>
  );
}

export default memo(Wrapper);
