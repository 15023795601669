import { gql } from 'graphql-tag';

export default gql`
  query GetUsers($input: GetMemberOfTypeInput) {
    getMembersOftype(input: $input) {
      mId
      mRefId
      mTitle
      mDescription
      mType
      mAvatarKey
      mMetaData {
        key
        value
      }
      mdfId
      metadata
    }
  }
`;
