import { Dictionary, groupBy } from 'lodash';

import { useGetOrderForms } from 'api/order_forms/useGetOrderForms';
import { MenuItem } from 'features/contextMenu/ContextMenu';
import { useOrderFormFilter } from 'store';
import { OrderFormMemberType } from 'types/memberTypes/order_form';

const splitGroupedFromUngrouped = (data: Record<string, OrderFormMemberType[]>) => {
  const filtered: Record<string, OrderFormMemberType[]> = { ...data };
  const ungrouped = [...(filtered[''] ?? [])];
  delete filtered[''];
  return {
    filtered,
    ungrouped,
  };
};

const toMenuItem = (form: OrderFormMemberType): MenuItem => ({
  label: form.mDescription,
  id: `${form.mRefId}#${form.mSecId}`,
  color: form.mColor ?? undefined,
});

export const useGetOrderMenu = () => {
  const [formFilter] = useOrderFormFilter();
  const { orderForms, loading } = useGetOrderForms();

  if (loading) return { menuItems: [] };

  let formsByResourceTitle: Dictionary<OrderFormMemberType[]> | undefined = undefined;
  if (formFilter.types === null) {
    formsByResourceTitle = groupBy(orderForms, (orderForm) => orderForm.mTitle);
  } else {
    const filtered: OrderFormMemberType[] = [];
    for (const f of orderForms) {
      const typesConfig = f.configs.find((c) => c.key === 'types');
      if (typesConfig?.values?.some((t) => formFilter.types?.includes(t))) {
        filtered.push(f);
      }
    }

    formsByResourceTitle = groupBy(filtered, (orderForm) => orderForm.mTitle);
  }

  if (formsByResourceTitle) {
    const newMenuItems: MenuItem[] = [];
    const split = splitGroupedFromUngrouped(formsByResourceTitle);

    for (const f of split.ungrouped) {
      newMenuItems.push(toMenuItem(f));
    }
    for (const [section, forms] of Object.entries(split.filtered)) {
      newMenuItems.push({
        label: section,
        id: `SPACE#${section}`,
        children: forms.map(toMenuItem),
      });
    }
    return { menuItems: newMenuItems };
  }

  return { menuItems: [] };
};
