import { createContext, useContext } from 'react';

import { EditorContextProps } from './types';

const editorContextInitialState: EditorContextProps = {
  update: async () => {},
  containerRef: { current: null },
  variant: 'general',
};

const EditorContext = createContext<EditorContextProps>(editorContextInitialState);

export const useEditorContext = () => useContext(EditorContext);

export default EditorContext;
