import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.dina.backgroundResting,
    border: `1px solid ${theme.palette.dina.borderResting}`,
    boxSizing: 'border-box',
    backdropFilter: 'blur(15px)',
    borderRadius: '12px',
    margin: '4px 0px',

    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  header: {
    height: '48px',
    width: '100%',
    padding: '0 12px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:hover': {
      cursor: 'pointer',
    },
    '&:hover $arrows': {
      cursor: 'pointer',
      '& path': {
        transition: '0.2s',
        fill: theme.palette.dina.iconActive,
        fillOpacity: 1,
      },
    },
  },
  arrows: {
    display: 'flex',
    alignItems: 'center',
    '& path': {
      transition: '0.2s',
      fill: theme.palette.dina.iconInactive,
      fillOpacity: 1,
    },
  },
  headerTitle: {
    ...theme.typography.dina.subtitle1,
    color: theme.palette.dina.highEmphasis,
    marginLeft: '12px',
  },
  unselectable: {
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-khtml-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    'user-select': 'none',
  },
}));

export default useStyles;
