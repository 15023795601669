import React, { useState, useEffect } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FilledInput from '@material-ui/core/FilledInput';

import { ReactComponent as Close } from 'assets/icons/systemicons/close.svg';
import { ReactComponent as VisibilityOn } from 'assets/icons/systemicons/visibility_on.svg';
import { ReactComponent as VisibilityOff } from 'assets/icons/systemicons/visibility_off.svg';

import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';
import * as EmailValidator from 'email-validator';
import telephoneValidator from 'utils/telephoneValidator/telephoneValidator';
import InputFieldTypes, { usageUtils } from './InputFieldTypes';
import useStyles from './InputFieldStyle';

const InputField = (props) => {
  const {
    type,
    label,
    description,
    severity,
    disabled,
    usage,
    value,
    placeholder,
    onChange,
    onClear,
    autoFocus,
    disableLabel,
    optionalLabel: optLabel,
    onBlur,
    onFocus,
    hideEndAdornment,
    direction,
    multiline,
    ...other
  } = props;

  const [fieldTouched, setFieldTouched] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [renderedLabel, setRenderedLabel] = useState(label);
  const optionalLabel = optLabel || label;
  const classes = useStyles();

  const handleChangeValue = (event) => {
    event.preventDefault();
    onChange(event.target.value);
    setFieldTouched(event.target.value);
  };

  const handleClickIconButton = (event) => {
    event.preventDefault();
    if (severity !== 'error' && type === InputFieldTypes.PASSWORD) {
      setShowPassword(!showPassword);
    } else {
      onChange('');
      onClear(event);
      setFieldTouched(false);
      if (renderedLabel !== label) setRenderedLabel(label);
    }
  };

  const handleOnBlur = (event) => {
    onBlur(event);
  };

  const getMandatoryUnderlineStyle = () =>
    fieldTouched ? classes.cssUnderlineNotTouched : classes.cssUnderlineTouched;

  const getEmailFieldUnderlineStyle = () => {
    if (EmailValidator.validate(fieldTouched)) {
      return severity === 'mandatory'
        ? classes.cssUnderlineNotTouched
        : classes.cssUnderlineRegular;
    }

    return classes.cssUnderlineTouched;
  };

  const getTelephoneFieldUnderlineStyle = () => {
    if (telephoneValidator(fieldTouched)) {
      return severity === 'mandatory'
        ? classes.cssUnderlineNotTouched
        : classes.cssUnderlineRegular;
    }

    return classes.cssUnderlineTouched;
  };

  const getEditingUnderlineStyle = () => classes.cssUnderlineEditing;

  const ReturnUnderlineStyle = () => {
    if (type === InputFieldTypes.EMAIL && fieldTouched) {
      return getEmailFieldUnderlineStyle();
    }

    if (type === InputFieldTypes.TELEPHONE && fieldTouched) {
      return getTelephoneFieldUnderlineStyle();
    }

    if (severity === 'mandatory') {
      return getMandatoryUnderlineStyle();
    }

    if (severity === 'editing') {
      return getEditingUnderlineStyle();
    }

    return classes.cssUnderlineRegular;
  };

  const ReturnType = () => {
    if (type === InputFieldTypes.PASSWORD) {
      return showPassword ? InputFieldTypes.TEXT : InputFieldTypes.PASSWORD;
    }
    return type;
  };

  const isUsageMeta = usage === usageUtils.METADATA;
  const isUsageEditor = usage === usageUtils.EDITOR;

  const getFilledLabelStyle = () => {
    if (isUsageMeta) return classes.filledLabel;
    if (isUsageEditor) return classes.editorFilledLabel;
    return '';
  };

  const getInputRootStyle = () => {
    if (isUsageMeta) return classes.filledInputRootMeta;
    if (isUsageEditor) return classes.filledInputRootEditor;
    return classes.filledInputRoot;
  };
  const getInputStyle = () => {
    if (isUsageMeta) return classes.filledInputMeta;
    if (isUsageEditor)
      return classes[disableLabel ? 'filledInputEditorNoLabel' : 'filledInputEditor'];
    return classes.filledInput;
  };
  const getHelperTextStyle = () => {
    if (isUsageMeta) return classes.formHelperRootMeta;
    if (isUsageEditor) return classes.formHelperRootEditor;
    return classes.formHelperRoot;
  };
  const getIconSize = () => (isUsageMeta || isUsageEditor ? 'small' : 'medium');

  const handleFocusCaptured = (event) => {
    event.preventDefault();
    if (optionalLabel && renderedLabel !== optionalLabel) setRenderedLabel(optionalLabel);
    onFocus(event);
  };

  const handleBlurCaptured = (event) => {
    event.preventDefault();
    if (renderedLabel !== label) setRenderedLabel(label);
    onBlur(event);
  };

  useEffect(() => {
    if (value.length > 0 && renderedLabel !== optionalLabel) setRenderedLabel(optionalLabel);
  }, [optionalLabel, renderedLabel, value.length]);

  useEffect(() => {
    setRenderedLabel(label);
  }, [label]);

  return (
    <div className={classes.inputFieldDiv}>
      <FormControl
        variant="filled"
        error={severity === 'error' && true}
        disabled={disabled}
        dir={direction}
        fullWidth
      >
        {!disableLabel && (
          <InputLabel
            htmlFor={renderedLabel}
            classes={{
              root: classes.labelRoot,
              filled: getFilledLabelStyle(),
              shrink: classes.shrink,
              focused: classes.focused,
              disabled: classes.disabled,
              error: classes.error,
            }}
          >
            {renderedLabel}
          </InputLabel>
        )}
        <FilledInput
          data-testid="input"
          type={ReturnType()}
          value={value}
          placeholder={placeholder}
          onChange={handleChangeValue}
          onBlur={handleOnBlur}
          disableUnderline={disabled}
          autoFocus={autoFocus}
          multiline={multiline}
          rows={multiline ? 4 : 1}
          onFocusCapture={handleFocusCaptured}
          onBlurCapture={handleBlurCaptured}
          classes={{
            root: getInputRootStyle(),
            underline: ReturnUnderlineStyle(),
            input: getInputStyle(),
            error: classes.error,
            disabled: classes.disabled,
          }}
          endAdornment={
            value &&
            !disabled &&
            !hideEndAdornment && (
              <InputAdornment
                position="end"
                classes={{
                  root: classes.adornmentRoot,
                }}
              >
                <IconButton
                  className={classes.iconButton}
                  tabIndex="-1"
                  onClick={handleClickIconButton}
                  size={getIconSize()}
                >
                  {type === InputFieldTypes.PASSWORD &&
                    severity !== 'error' &&
                    (showPassword ? <VisibilityOff /> : <VisibilityOn />)}
                  {(type !== InputFieldTypes.PASSWORD || severity === 'error') && <Close />}
                </IconButton>
              </InputAdornment>
            )
          }
          {...other}
        />
        <FormHelperText
          classes={{
            root: getHelperTextStyle(),
            error: classes.error,
            disabled: classes.disabled,
          }}
        >
          {description}
        </FormHelperText>
      </FormControl>
    </div>
  );
};

InputField.propTypes = {
  /** Values: text, email, tel or password */
  type: PropTypes.string,
  /** Label stating the purpose of the input field (text) */
  label: PropTypes.string,
  /** Optional label, shown when input is focused or has some value */
  optionalLabel: PropTypes.string,
  /** Assistive help text shown at the bottom if defined (text)  */
  description: PropTypes.string,
  /** Values: regular, mandatory, error, editing */
  severity: PropTypes.string,
  /** Values: true or false */
  disabled: PropTypes.bool,
  /** Indicates if the input field should get focus when it is loaded.
   * Values: true or false */
  autoFocus: PropTypes.bool,
  /** Value of the input field */
  value: PropTypes.string,
  /** A short hint displayed in the input */
  placeholder: PropTypes.string,
  /** onChange function */
  onChange: PropTypes.func,
  /** defines the usage of input field */
  usage: PropTypes.string,
  /** onClear callback when input value is cleared pressing icon button */
  onClear: PropTypes.func,
  /** disable the label of input field */
  disableLabel: PropTypes.bool,
  /** onBlur callback */
  onBlur: PropTypes.func,
  /** onFocus callback */
  onFocus: PropTypes.func,
  /** Values: true or false for endAdornment in input field */
  hideEndAdornment: PropTypes.bool,
  /** function to be run on down */
  onKeyDown: PropTypes.func,
};

InputField.defaultProps = {
  type: InputFieldTypes.TEXT,
  label: undefined,
  optionalLabel: undefined,
  description: undefined,
  severity: 'regular',
  disabled: false,
  value: '',
  placeholder: '',
  autoFocus: false,
  onBlur: () => {},
  onFocus: () => {},
  onChange: () => {},
  usage: usageUtils.NORMAL,
  onClear: () => {},
  disableLabel: false,
  hideEndAdornment: false,
  onKeyDown: () => {},
};

export default InputField;
