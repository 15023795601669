import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSetActiveTour, useActiveTour, tours } from 'store';

import { Button } from 'components/buttons';
import NotificationPopup from 'components/notificationPopup';

import { Bottom, SkipTour, TourButtonWrapper, CountText } from './styled';

function TourPopup({ tourId, tourStep, position, anchor }) {
  if (!tourId) {
    throw new Error('Invalid identifier in TourPopup');
  }

  const [activeTour, setActiveTour] = useSetActiveTour();
  const [currentStep, setCurrentStep] = useActiveTour();
  const [visible, setVisible] = useState(false);

  const doNext = () => setCurrentStep('NEXT');
  const doPrevious = () => setCurrentStep('PREVIOUS');

  const doStopTour = async () => {
    setCurrentStep('RESET');
    setActiveTour(null);
  };

  const tourIdentifiers = Object.values(tours[tourId]?.steps ?? {}).map((step) => step.id) ?? [];
  const max = tourIdentifiers.length;
  const isMyTurn =
    tourId === activeTour && tourIdentifiers.indexOf(tourStep?.id) + 1 === currentStep;
  useEffect(() => {
    setVisible(isMyTurn);
  }, [isMyTurn]);

  if (!tourStep) {
    return null;
  }

  return (
    <NotificationPopup
      title={tourStep.title}
      text={tourStep.text}
      imageUrl={tourStep.imageUrl}
      id={tourId}
      isVisible={visible}
      position={position}
      anchor={anchor}
      useHighlight
    >
      <TourBottom
        doStopTour={doStopTour}
        doNext={doNext}
        doPrevious={doPrevious}
        currentStep={currentStep}
        max={max}
      />
    </NotificationPopup>
  );
}

function TourBottom({ doStopTour, doPrevious, doNext, currentStep, max }) {
  return (
    <Bottom>
      <SkipTour onClick={doStopTour}>Skip tour</SkipTour>
      <TourButtonWrapper>
        <Button
          onClick={doPrevious}
          disabled={currentStep === 1}
          variant="outlined"
          usage="outlined"
        >
          Previous
        </Button>
        <CountText>{`${currentStep} of ${max}`}</CountText>
        {currentStep !== max ? (
          <Button onClick={doNext} disabled={currentStep === max}>
            Next
          </Button>
        ) : (
          <Button onClick={doStopTour}>End tour</Button>
        )}
      </TourButtonWrapper>
    </Bottom>
  );
}
TourPopup.propTypes = {
  tourId: PropTypes.string.isRequired,
  tourStep: PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  position: PropTypes.string,
  anchor: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.shape({
      current: PropTypes.node,
    }),
  ]),
};

TourPopup.defaultProps = {
  position: 'bottom',
  anchor: null,
};

export default TourPopup;
