import { useCallback, useMemo, useState } from 'react';
// eslint-disable-next-line sort-imports
import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';

import { ReactComponent as PadlockOff } from 'assets/icons/systemicons/padlock_off.svg';
import { ReactComponent as PadlockOn } from 'assets/icons/systemicons/padlock_on.svg';
import LoadingIndicator from 'components/loadingIndicator';
import Tooltip from 'components/tooltip';
import { Box } from 'layouts/box/Box';
import transientOptions from 'theme/helpers';

import AccessDialog from './components/dialog';
import useAccessControl from './useAccessControl';

type CommonProps = { $isRestricted: boolean; theme: Theme; $persistIconColor?: boolean };

type IconProps = Omit<CommonProps, 'theme'>;

interface Props {
  mType: string;
  storyId: string;
  persistIconColor?: boolean;
  height?: number;
}

const getFillColor = ({ theme, $isRestricted, $persistIconColor }: CommonProps) => {
  if ($isRestricted) return theme.palette.dina.statusWarning;
  if ($persistIconColor) return theme.palette.dina.iconSelectedWhite;
  return theme.palette.dina.highEmphasis;
};

const commonStyle = ({ theme, $isRestricted, $persistIconColor }: CommonProps) => css`
  cursor: pointer;
  path {
    fill: ${getFillColor({ theme, $isRestricted, $persistIconColor })};
    fill-opacity: 0.54;
  }
  :hover {
    path {
      fill-opacity: 1;
      filter: ${theme.palette.dina.onHover};
    }
  }
`;

const PadlockOnButton = styled(PadlockOn, transientOptions)<IconProps>`
  ${({ theme, $isRestricted, $persistIconColor }) =>
    commonStyle({ theme, $isRestricted, $persistIconColor })}
`;

const PadlockOffButton = styled(PadlockOff, transientOptions)<IconProps>`
  ${({ theme, $isRestricted, $persistIconColor }) =>
    commonStyle({ theme, $isRestricted, $persistIconColor })}
`;

function AccessControl({ mType, persistIconColor = false, storyId, height = 48 }: Readonly<Props>) {
  const { isRestricted, loading, toggleRestriction, canChangeRestriction } = useAccessControl({
    mType,
  });

  const [open, setOpen] = useState(false);

  const handleOpenDialog = () => {
    if (canChangeRestriction) setOpen(true);
  };
  const handleCloseDialog = useCallback(() => setOpen(false), []);

  const handleControlAccess = useCallback(() => {
    handleCloseDialog();
    toggleRestriction(storyId).then(
      () => {},
      () => {},
    );
  }, [handleCloseDialog, toggleRestriction, storyId]);

  const PadlockButton = isRestricted ? PadlockOnButton : PadlockOffButton;

  const tooltipTitle = useMemo(() => {
    if (!canChangeRestriction) return 'You are not allowed to restrict this story';
    return isRestricted ? 'Remove Restrictions' : 'Restrict Access';
  }, [canChangeRestriction, isRestricted]);

  return (
    <>
      <Box container height={`${height}px`} width={`${height}px`} onClick={handleOpenDialog}>
        {loading ? (
          <div style={{ position: 'relative' }}>
            <LoadingIndicator height={24} width={24} />
          </div>
        ) : (
          <Tooltip title={tooltipTitle}>
            <PadlockButton
              $isRestricted={isRestricted}
              $persistIconColor={persistIconColor}
              className="skipOverride"
            />
          </Tooltip>
        )}
      </Box>
      {canChangeRestriction && (
        <AccessDialog
          open={open}
          isRestricted={isRestricted}
          onClose={handleCloseDialog}
          onControlAccess={handleControlAccess}
        />
      )}
    </>
  );
}

export default AccessControl;
