import { ReactComponent as UsersOn } from 'assets/icons/systemicons/ContainerRight_Navbar/contacts_on.svg';
import { ReactComponent as UsersOff } from 'assets/icons/systemicons/ContainerRight_Navbar/contacts_off.svg';
import { ReactComponent as InstancesOn } from 'assets/icons/systemicons/ContainerLeft_Navbar/instances_on.svg';
import { ReactComponent as InstancesOff } from 'assets/icons/systemicons/ContainerLeft_Navbar/instances_off.svg';
import { ReactComponent as TeamsOn } from 'assets/icons/systemicons/ContainerRight_Navbar/teams_on.svg';
import { ReactComponent as TeamsOff } from 'assets/icons/systemicons/ContainerRight_Navbar/teams_off.svg';
import { ReactComponent as DepartmentsOn } from 'assets/icons/systemicons/ContainerRight_Navbar/departments_on.svg';
import { ReactComponent as DepartmentsOff } from 'assets/icons/systemicons/ContainerRight_Navbar/departments_off.svg';

const chipData = {
  user: { title: 'People', SelectedIcon: UsersOn, UnselectedIcon: UsersOff },
  platform: { title: 'Platforms', SelectedIcon: InstancesOn, UnselectedIcon: InstancesOff },
  unit: { title: 'Units', SelectedIcon: TeamsOn, UnselectedIcon: TeamsOff },
  team: { title: 'Teams', SelectedIcon: TeamsOn, UnselectedIcon: TeamsOff },
  department: {
    title: 'Departments',
    SelectedIcon: DepartmentsOn,
    UnselectedIcon: DepartmentsOff,
  },
};

export default chipData;
