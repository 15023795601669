import gql from 'graphql-tag';

export default gql`
  query GetGroupUsers($input: GetGroupUserInput) {
    getGroupUsers(input: $input) {
      items {
        mId
      }
    }
  }
`;
