import { useCallback } from 'react';

import Debounce from 'components/debounce';
import { MdfEditor } from 'components/mdfEditor/MdfEditor';
import MetadataComponent from 'components/metadata';
import useToast from 'components/toast/useToast';
import useInstanceMdf from 'features/instance/hooks/useInstanceMdf';
import useInstanceMetadata from 'features/instance/hooks/useInstanceMetadata';
import { useInstanceMolecule } from 'features/instance/store/instance';
import useUpdateInstance from 'hooks/useUpdateInstance';
import respectHostReadSpeed from 'screens/rundown/utils/respectHostReadSpeed';
import { MMetaDataField } from 'types/graphqlTypes';

function Metadata() {
  const { errorToast } = useToast();
  const [updateInstance] = useUpdateInstance();
  const { useDisableEdit, useInstanceValue } = useInstanceMolecule();
  const { mdf, metadata, errorMap, updateFieldValues } = useInstanceMdf();
  const {
    fields,
    pairedMetadata,
    parameterFields,
    hostReadSpeed,
    canUpdateScriptDurationSettingsValue,
  } = useInstanceMetadata();
  const instance = useInstanceValue();
  const [disableEdit] = useDisableEdit();

  const onUpdateMetadata = useCallback(
    async (newMetadata: MMetaDataField[]) => {
      if (!instance) return;

      try {
        await updateInstance({
          instanceId: instance.mId,
          metadata: respectHostReadSpeed(
            newMetadata,
            hostReadSpeed,
            canUpdateScriptDurationSettingsValue,
          ) as MMetaDataField[],
          audit: { source: 'rundown-new-instance:onUpdateMetadata' },
          items: undefined,
          unlock: false,
          autosave: false,
          version: undefined,
          content: undefined,
          onMetadataUpdated: () => {},
        });
      } catch (err) {
        errorToast(err);
      }
    },
    [instance, hostReadSpeed, canUpdateScriptDurationSettingsValue],
  );

  if (mdf)
    return (
      <MdfEditor
        style={{ padding: '10px', overflow: 'auto' }}
        fields={mdf.fields}
        defaultLayoutSettings={mdf.views.default}
        layoutSettings={[]}
        metadata={metadata ?? {}}
        permissions={mdf.permissions}
        view="default"
        errorMap={errorMap}
        readonly={disableEdit}
        updateFieldValue={updateFieldValues}
      />
    );

  return (
    <Debounce ms={2000}>
      <MetadataComponent
        fields={fields}
        metadata={pairedMetadata}
        parameterFields={parameterFields}
        onUpdateMeta={onUpdateMetadata}
        disableEdit={disableEdit}
        showHeader={false}
        onBackClick={undefined}
        usage={undefined}
      />
    </Debounce>
  );
}

export default Metadata;
