import { useState } from 'react';
import { Skeleton } from '@material-ui/lab';

import AddMemberDropdown from 'components/addMember';
import MemberLabel from 'components/addMember/MemberLabel';
import { MdfEditor } from 'components/mdfEditor/MdfEditor';
import { FieldHeader } from 'components/mdfEditor/styled';
import { SelectAssigneeWrapper } from 'components/orderFormDialog/styled';
import Scrollbar from 'components/scrollbar/scrollbar';
import Text from 'components/text/Text';
import { Metadata, NewFieldValue } from 'types/forms/forms';
import { Mdf, MemberTypeEnum } from 'types/graphqlTypes';
import { AssignedMember } from 'types/members';
import { EditorCommandConfigType } from 'types/memberTypes/editorCommands';

import { Block, BlockTitle, BlockWrapper } from './styled';

interface PreviewProps {
  selectedCommand: EditorCommandConfigType;
  mdf?: Mdf;
}

function MdfPreview({ selectedCommand, mdf }: Readonly<PreviewProps>) {
  const [mdfPayload, setMdfPayload] = useState<Metadata>({});

  const updateFieldValues = (val: NewFieldValue[]) => {
    const updatedMd: Metadata = {};
    for (const update of val) {
      updatedMd[update.fieldId] = update.value;
    }
    setMdfPayload((prev) => ({
      ...prev,
      ...updatedMd,
    }));
  };

  return (
    <>
      <Text variant="overline">Block preview:</Text>
      <BlockWrapper>
        <BlockTitle>
          <Text variant="listItemLabel">{selectedCommand.mTitle}</Text>
        </BlockTitle>
        <Block>
          {mdf ? (
            <MdfEditor
              view="default"
              fields={mdf.fields}
              updateFieldValue={updateFieldValues}
              metadata={mdfPayload}
              layoutSettings={[]}
              defaultLayoutSettings={mdf.views.default}
              permissions={mdf.permissions}
            />
          ) : (
            <>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </>
          )}
        </Block>
      </BlockWrapper>
    </>
  );
}

function OrderFormPreview({ mdf }: Readonly<Omit<PreviewProps, 'selectedCommand'>>) {
  const [isFindingAssignee, setIsFindingAssignee] = useState(false);
  const [assignee, setAssignee] = useState<AssignedMember | null>(null);

  const onSelectAssignee = (newAssignee: AssignedMember[]) => {
    setAssignee(newAssignee[0]);
  };

  const onClose = () => {
    setIsFindingAssignee(false);
  };

  const onRemoveClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.stopPropagation();
    setAssignee(null);
  };

  return mdf ? (
    <>
      <Text variant="overline">Order form:</Text>
      <MdfEditor
        view="order_form"
        fields={mdf.fields}
        updateFieldValue={() => {}}
        metadata={{}}
        layoutSettings={mdf.views.order_form}
        defaultLayoutSettings={mdf.views.default}
        permissions={mdf.permissions}
      />
      <SelectAssigneeWrapper>
        <FieldHeader>Assignee</FieldHeader>
        {isFindingAssignee ? (
          <AddMemberDropdown
            variant="all"
            singleChoice
            autoFocus
            disableClearable={true}
            selectedMembers={assignee ? [assignee] : []}
            setSelectedMembers={onSelectAssignee}
            placeholderText="Set assignee"
            noOptionsText="No matches found"
            onClose={onClose}
            injectedMembers={undefined}
          />
        ) : (
          <MemberLabel
            variant="form"
            memberId={assignee?.mId}
            onClick={() => setIsFindingAssignee(true)}
            onRemoveClick={onRemoveClick}
          />
        )}
      </SelectAssigneeWrapper>
    </>
  ) : null;
}

function CommandPreview({ selectedCommand, mdf }: Readonly<PreviewProps>) {
  return (
    <Scrollbar>
      {selectedCommand.mResourceType === MemberTypeEnum.Mdf && (
        <MdfPreview selectedCommand={selectedCommand} mdf={mdf} />
      )}
      {selectedCommand.mResourceType === MemberTypeEnum.OrderForm && <OrderFormPreview mdf={mdf} />}
    </Scrollbar>
  );
}

export default CommandPreview;
