import { Version } from 'types';
import accessibleOnClick from 'utils/accessibleOnClick';

import { useVersionHistoryMolecule } from '../../../../atoms';
import TimelineEntry from '../timelineEntry';

import {
  MarginTop,
  TimelineConnector,
  TimelineConnectorGap,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from './styled';

interface SeparatorProps {
  isSelected: boolean;
  isLastElement: boolean;
  count: number;
}
function Separator({ isSelected, isLastElement, count }: Readonly<SeparatorProps>) {
  return (
    <TimelineSeparator>
      {count !== 0 && <TimelineConnectorGap $selected={isSelected} />}
      {count === 0 && <MarginTop />}
      <TimelineDot $selected={isSelected} />
      <TimelineConnector $selected={isSelected} $lastElement={isLastElement} />
    </TimelineSeparator>
  );
}

interface TimelineViewProps {
  item: Version;
  count: number;
  versionCount: number;
}
function TimelineView({ item, count = 0, versionCount = 0 }: Readonly<TimelineViewProps>) {
  const { useCurrentAudit } = useVersionHistoryMolecule();
  const [currentAudit, setCurrentAudit] = useCurrentAudit();
  const isSelected = currentAudit?.count === count;
  const isLastElement = count === versionCount - 1;

  const handleItemClick = () => {
    setCurrentAudit({ ...item, count });
  };

  return (
    <TimelineItem $selected={isSelected} {...accessibleOnClick(handleItemClick)}>
      <Separator isSelected={isSelected} isLastElement={isLastElement} count={count} />
      <TimelineContent>
        <TimelineEntry item={item} index={count} />
      </TimelineContent>
    </TimelineItem>
  );
}

export default TimelineView;
