/**
 * Prevents default event behavior
 *
 * @param {Object} event React synthetic event
 * @returns {Null}
 */

const preventDefaultEvent = event => event.preventDefault();

export default preventDefaultEvent;
