import * as React from 'react';

import { EndAdornment, Input, StartAdornment, Wrapper } from 'components/input/InputFieldStyled';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
}

export const InputField = React.forwardRef<HTMLInputElement, InputProps>(
  ({ type, startAdornment, endAdornment, ...props }, ref) => {
    return (
      <Wrapper>
        {startAdornment && <StartAdornment>{startAdornment}</StartAdornment>}
        <Input
          type={type}
          ref={ref}
          startAdornment={Boolean(startAdornment)}
          endAdornment={Boolean(endAdornment)}
          {...props}
        />
        {endAdornment && <EndAdornment>{endAdornment}</EndAdornment>}
      </Wrapper>
    );
  },
);

InputField.displayName = 'InputField';
