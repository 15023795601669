const USER_SETTING_TYPES = {
  PROFILE: 'profile',
  USER_TOUR: 'user_tour',
  THEME: 'theme',
  NOTIFICATION: 'notification',
  NOTIFICATION_GENERAL: 'notification-general',
  NOTIFICATION_SLACK: 'notification-slack',
  NOTIFICATION_EMAIL: 'notification-email',
  PREFERENCES: 'preferences',
};

export default USER_SETTING_TYPES;
