import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';

export const ToolbarWrapper = styled('div')`
  display: flex;
  align-items: center;
  opacity: 0.6;
  container: toolbar / size;
  width: 100%;
`;

export const EditorWrapper = styled('div')`
  ${({ theme }) => theme.typography.dina.body2};
  border-radius: 8px;
  border: ${({ theme }) => `0.5px solid ${theme.palette.dina.inputBorderResting}`};
  overflow: hidden;
  :focus-within {
    border: 0.5px solid ${({ theme }) => theme.palette.dina.inputBorderFocused};
    ${ToolbarWrapper} {
      opacity: 1;
    }
  }
`;

export const ButtonsWrapper = styled('div')`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.palette.dina.dividerLight};
    background: ${theme.palette.dina.backgroundResting};
  `}
  display: flex;
  height: 40px;
  width: 100%;
  align-items: center;
  overflow: hidden;
  justify-content: flex-end;
  gap: 4px;
  padding-right: 4px;
`;
