import { useEffect, useMemo, useState } from 'react';
import { keyBy } from 'lodash';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import Dialog from 'components/dialogs/DialogBuilder';
import Infobar from 'components/infobar';
import { UserFriendlyLabel } from 'components/mdfEditor/fields/fields';
import { StyledTextField } from 'components/mdfEditor/fields/text/styled';
import { programmaticIdRegex } from 'screens/main/components/header/navbar/settings/components/integrations/EditActions';
import { MdfField } from 'types/graphqlTypes';

import { createNewField } from '../utils';

import { FieldHeader } from './EditFieldModel';

interface Props {
  onNewField: (field: MdfField) => void;
  fields: MdfField[];
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function NewFieldDialog({ onNewField, fields, open, setOpen }: Readonly<Props>) {
  const { mdfs } = useGetMdfs({ all: true });
  const [id, setId] = useState('');
  const existingFieldIds = fields.map((f) => f.fieldId);

  const fieldsMap = useMemo(() => {
    let map: Record<string, { field: MdfField; mdfLabel: string }> = {};
    mdfs.forEach((mdf) => {
      const processedFields = mdf.fields.map((f) => {
        return {
          field: f,
          mdfLabel: mdf.label,
        };
      });
      map = {
        ...map,
        ...keyBy(processedFields, (v) => v.field.fieldId),
      };
    });
    return map;
  }, [mdfs]);

  const existsElseWhere = useMemo(() => {
    return fieldsMap[id] ?? null;
  }, [id, fieldsMap]);

  const onCreate = () => {
    const field = createNewField(id);
    if (existsElseWhere) {
      field.type = existsElseWhere.field.type;
      field.defaultValue = { value: null };
      field.existsElseWhere = true;
    }
    onNewField(field);
    setOpen(false);
  };

  const description = useMemo(() => {
    if (!id) {
      return 'Required value';
    } else if (!programmaticIdRegex.test(id)) {
      return 'Only alphanumeric characters are allowed';
    } else if (existingFieldIds.includes(id)) {
      return 'Identifier already configured in this schema';
    }
    return '';
  }, [id, existingFieldIds]);

  useEffect(() => {
    if (!open) {
      setId('');
    }
  }, [open, setId]);

  const onKeyUp = (ev: React.KeyboardEvent) => {
    if (ev.key === 'Enter' && description.length === 0) {
      onCreate();
    }
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <Dialog.Header>Create new field</Dialog.Header>
      <Dialog.Body>
        <FieldHeader>Programmatic identifier</FieldHeader>
        <StyledTextField
          error={description.length > 0}
          helperText={description}
          autoFocus
          variant="filled"
          fullWidth
          placeholder="Enter a unique id"
          value={id}
          onChange={(event) => setId(event.target.value)}
          onKeyUp={onKeyUp}
        />
        {existsElseWhere && (
          <div style={{ marginTop: '10px' }}>
            <Infobar>
              A field with identifier &apos;{id}&apos; already exists in schema &apos;
              {existsElseWhere.mdfLabel}&apos;. This field can still be added, but it will be locked
              to the same type &apos;{UserFriendlyLabel[existsElseWhere.field.type]}&apos;
            </Infobar>
          </div>
        )}
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton />
        <Dialog.ConfirmButton label="Create" onClick={onCreate} />
      </Dialog.Footer>
    </Dialog>
  );
}

export default NewFieldDialog;
