import { addDays, differenceInDays, isSameDay } from 'date-fns';
import capitalize from 'lodash/capitalize';

import getRelativeDate from 'utils/getRelativeDate';

export const DROPDOWN_VALUES = {
  ALL_TIME: 'all-time',
  SELECTED_DATES: 'selected-dates',
  NEWER: 'newer',
  OLDER: 'older',
};

export const items = [
  {
    value: DROPDOWN_VALUES.ALL_TIME,
    title: 'Show All Time',
    selectionTitle: '—',
  },
  {
    value: DROPDOWN_VALUES.SELECTED_DATES,
    title: 'Just Selected Dates',
    selectionTitle: 'Selected',
    showTopDivider: true,
  },
  {
    value: DROPDOWN_VALUES.NEWER,
    title: 'Newer',
    selectionTitle: '& Newer',
    info: 'Show selected date\nand forwards in time',
    showTopDivider: true,
  },
  {
    value: DROPDOWN_VALUES.OLDER,
    title: 'Older',
    selectionTitle: '& Older',
    info: 'Show selected date\nand backwards in time',
    showTopDivider: true,
  },
];

const DATE_FORMAT = 'dd. D MMM YY';

type Time = Date | string;
type TimeRange = { startDate: Time | null; endDate: Time | null };

/**
 *  show appropriate date text for a selected time
 */
export const outputTimeFormatForDate = (newTime: Time) =>
  capitalize(getRelativeDate(newTime, DATE_FORMAT));

/**
 *  show appropriate date text for a selected time range
 */
export const outputTimeFormatForDateRange = (range: TimeRange) => {
  if (!range) return 'No date selected';

  const { startDate, endDate } = range;

  if (!startDate && !endDate) return 'All time';

  if (!startDate) return outputTimeFormatForDate(endDate as Time);
  if (!endDate) return outputTimeFormatForDate(startDate);

  if (isSameDay(startDate, endDate)) return capitalize(getRelativeDate(endDate, DATE_FORMAT));

  return `${capitalize(getRelativeDate(startDate, DATE_FORMAT))} - ${capitalize(
    getRelativeDate(endDate, DATE_FORMAT),
  )}`;
};

/**
 *  Moves the selected date range (both start date and end date) forwards or backwards in time
 */
export const moveDateRangeByOffset = (dateRange: TimeRange, option = { backwards: false }) => {
  const multiplier = option?.backwards ? -1 : 1;
  const offset =
    (differenceInDays(dateRange.endDate as Time, dateRange.startDate as Time) + 1) * multiplier;

  return {
    startDate: addDays(dateRange.startDate as Time, offset),
    endDate: addDays(dateRange.endDate as Time, offset),
  };
};
