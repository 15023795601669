import { CommandItem } from 'lib/command';

import { type MultiSelectContextProps, type MultiSelectListProps } from '../types';
import { isOptionsExist } from '../utils/utils';

type CreatableItemProps = MultiSelectListProps &
  Pick<
    MultiSelectContextProps,
    | 'creatable'
    | 'debouncedSearchTerm'
    | 'inputValue'
    | 'isLoading'
    | 'onChange'
    | 'onSearch'
    | 'options'
    | 'selected'
    | 'setInputValue'
    | 'setSelected'
  >;

/** Creatable item for dropdown list, only renders when creatable = true */
export const CreatableItem = ({
  creatable,
  debouncedSearchTerm,
  inputValue,
  isLoading,
  maxSelected = Number.MAX_SAFE_INTEGER,
  onChange,
  onMaxSelected,
  onSearch,
  options,
  selected,
  setInputValue,
  setSelected,
}: Readonly<CreatableItemProps>) => {
  if (!creatable) return undefined;

  if (
    isOptionsExist(options, [{ value: inputValue, label: inputValue }]) ||
    selected.find((s) => s.value === inputValue)
  ) {
    return undefined;
  }

  /** New option component */
  const Item = (
    <CommandItem
      value={inputValue}
      onMouseDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onSelect={(value: string) => {
        if (selected.length >= maxSelected) {
          onMaxSelected?.(selected.length);
          return;
        }
        setInputValue('');
        const newOptions = [...selected, { value, label: value }];
        setSelected(newOptions);
        onChange?.(newOptions);
      }}
    >
      {`Create "${inputValue}"`}
    </CommandItem>
  );

  // Creatable item should be shown only when there is no search result.
  if (!onSearch && inputValue.length > 0) {
    return Item;
  }

  // When onSearch is provided, show the creatable item only after loading is done.
  if (onSearch && debouncedSearchTerm.length > 0 && !isLoading) {
    return Item;
  }

  return undefined;
};
