import { memo } from 'react';

import { elementTypes, markTypes } from 'components/editor/constants';

import { ToolbarContainer } from '../../styled';
import { ButtonGroup } from '../ButtonGroup';
import CheckListButton from '../checkListButton/CheckListButton';
import ColorButton from '../colorButton';
import ElementButton from '../elementButton';
import ImageButton from '../imageButton';
import LinkButton from '../linkButton';
import ListButton from '../listButton';
import MarkButton from '../markButton';
import UppercaseButton from '../uppercaseButton/UppercaseButton';
import VideoButton from '../videoButton';

const { BOLD, ITALIC, UNDERLINE, STRIKE_THROUGH } = markTypes;

const { UNORDERED_LIST, HEADING_FOUR, HEADING_THREE, HEADING_TWO, ORDERED_LIST } = elementTypes;

function NotesToolbar() {
  return (
    <ToolbarContainer>
      <ButtonGroup>
        <ElementButton type={HEADING_TWO} />
        <ElementButton type={HEADING_THREE} />
        <ElementButton type={HEADING_FOUR} />
      </ButtonGroup>
      <ButtonGroup>
        <MarkButton type={BOLD} />
        <MarkButton type={ITALIC} />
        <MarkButton type={UNDERLINE} />
        <MarkButton type={STRIKE_THROUGH} />
        <UppercaseButton />
      </ButtonGroup>
      <ButtonGroup>
        <ListButton type={UNORDERED_LIST} />
        <ListButton type={ORDERED_LIST} />
        <CheckListButton />
      </ButtonGroup>
      <ButtonGroup>
        <ColorButton />
      </ButtonGroup>
      <ButtonGroup>
        <VideoButton />
        <ImageButton />
        <ImageButton isGif />
      </ButtonGroup>
      <ButtonGroup>
        <LinkButton />
      </ButtonGroup>
    </ToolbarContainer>
  );
}

export default memo(NotesToolbar);
