const payload: Record<string, unknown> = {
  order: {
    mActive: true,
    mdfId: '<unique-mdf-id>',
    mId: '<unique-order-id>',
    mResourceId: '<unique-resource-id>',
    mResourceType: 'story',
    mType: 'order',
    mSpaceId: '<unique-space-id>',
    mFormId: '<unique-form-id>',
    mOwner: '<unique-user-id>',
    mAssignee: '<unique-user-id>',
    mCreatedAt: '2023-08-29T16:27:27.553Z',
    mCreatedById: '<unique-user-id>',
    mStatus: 'in_progress',
    mUpdatedAt: '2023-09-26T13:46:52.840Z',
    mUpdatedById: '<unique-user-id>',
    metadata: {
      camera: 'sony_GHV',
      ygraphics: true,
    },
    crudAction: 'MODIFY',
  },
  story: {
    event: 'story',
    type: 'update',
    time: '2023-12-21T05:33:09.554Z',
    update: { action: 'story-content' },
    story: {
      mId: '<story-unique-id>',
      mRefId: '<story-unique-id>',
      mTitle: 'Title name',
      mPublishingAt: '2023-11-30T18:00:00.000Z',
      metadata: {},
      contentUrl: 's3 signed URL',
    },
  },
  note: {
    event: 'note',
    type: 'update',
    time: '2023-12-21T05:36:14.097Z',
    update: { action: 'note-content' },
    note: {
      mId: '<story-unique-id>',
      mRefId: '<note-unique-id>',
      mTitle: 'Title name',
      contentUrl: 's3 signed URL',
    },
  },
  instance: {
    id: '<instance_id>',
    title: 'Title name',
    content: {
      document: [{}],
      version: 'Version',
    },
    publishingAt: '2023-12-21T05:33:09.554Z',
    isPreparing: 'true',
    index: 'index',
    metadata: {},
    state: '',
    createdAt: '2023-12-21T05:33:09.554Z',
    updatedAt: '2023-12-21T05:33:09.554Z',
    updatedById: 'uuid',
    mMetaData: [{}],
    version: 1234,
    isPlaceholder: true,
  },
  rundown: {
    metadata: {},
    mMetaData: {
      key: 'string',
      value: 'string',
      autoValue: 'string',
      mostag: 'string',
      manual: true,
    },
    publishingAt: '2023-12-21T05:33:09.554Z',
  },
  pitch: {
    event: 'pitch',
    type: 'update',
    time: '2023-12-21T05:33:09.554Z',
    update: { action: 'pitch' },
    pitch: {
      mId: '<pitch-unique-id>',
      mRefId: '<pitch-unique-id>',
      mTitle: 'Title name',
      mPublishingAt: '2023-11-30T18:00:00.000Z',
      metadata: {},
      contentUrl: 's3 signed URL',
    },
  },
  contact: {
    id: 'string',
    type: 'contact',
    title: 'string',
    description: 'string',
    mdfId: 'string',
    metadata: {},
    createdAt: '2023-12-21T05:33:09.554Z',
    createdById: 'string',
    updatedAt: '2023-12-21T05:33:09.554Z',
    updatedById: 'string',
    version: 'number',
  },
  user: {
    id: 'string',
    type: 'contact',
    title: 'string',
    description: 'string',
    mdfId: 'string',
    metadata: {},
    createdAt: '2023-12-21T05:33:09.554Z',
    createdById: 'string',
    updatedAt: '2023-12-21T05:33:09.554Z',
    updatedById: 'string',
    version: 'number',
  },
};

const getPayload = (mType: string) => payload[mType];

export default getPayload;
