import * as React from 'react';
import styled from '@emotion/styled';
import { Drawer as DrawerPrimitive } from 'vaul';

import { dialogBoxShadow } from 'theme/utils/commonStyles';

export const StyledContent = styled(DrawerPrimitive.Content)`
  position: fixed;
  display: flex;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 1250;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.palette.dina.surfaceCard};
  ${dialogBoxShadow};
  :focus-visible {
    outline: none;
  }
`;

const Drawer = ({ ...props }: React.ComponentProps<typeof DrawerPrimitive.Root>) => (
  <DrawerPrimitive.Root {...props} />
);
Drawer.displayName = 'Drawer';

const DrawerTrigger = DrawerPrimitive.Trigger;

const DrawerPortal = DrawerPrimitive.Portal;

const DrawerClose = DrawerPrimitive.Close;

const DrawerOverlay = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Overlay>
>(({ ...props }, ref) => <DrawerPrimitive.Overlay ref={ref} {...props} />);
DrawerOverlay.displayName = DrawerPrimitive.Overlay.displayName;

const DrawerContent = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Content>
>(({ children, ...props }, ref) => (
  <DrawerPortal>
    <StyledContent ref={ref} {...props}>
      {children}
    </StyledContent>
  </DrawerPortal>
));
DrawerContent.displayName = 'DrawerContent';

const DrawerHeader = ({ ...props }: React.HTMLAttributes<HTMLDivElement>) => <div {...props} />;
DrawerHeader.displayName = 'DrawerHeader';

const DrawerFooter = ({ ...props }: React.HTMLAttributes<HTMLDivElement>) => <div {...props} />;
DrawerFooter.displayName = 'DrawerFooter';

const DrawerTitle = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Title>
>(({ ...props }, ref) => <DrawerPrimitive.Title ref={ref} {...props} />);
DrawerTitle.displayName = DrawerPrimitive.Title.displayName;

const DrawerDescription = React.forwardRef<
  React.ElementRef<typeof DrawerPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Description>
>(({ ...props }, ref) => <DrawerPrimitive.Description ref={ref} {...props} />);
DrawerDescription.displayName = DrawerPrimitive.Description.displayName;

export {
  Drawer,
  DrawerPortal,
  DrawerOverlay,
  DrawerTrigger,
  DrawerClose,
  DrawerContent,
  DrawerHeader,
  DrawerFooter,
  DrawerTitle,
  DrawerDescription,
};
