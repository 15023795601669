import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Modal as MuiModal, IconButton as MuiIconButton } from '@material-ui/core';
import { ReactComponent as CloseIconComponent } from 'assets/icons/systemicons/close.svg';

export const Modal = styled(MuiModal)`
  display: flex;
  align-items: center;
  justify-content: center;
  :focus {
    outline: none;
  }
`;

export const AnimatedModalContent = styled('div')`
  :focus {
    outline: none;
  }
  transition: transform 0.2s;
  opacity: ${({ open }) => (open ? 1 : 0)};
`;

export const ContentWrapper = styled('div')`
  ${({ theme }) => css`
    background-color: ${theme.palette.dina.surfaceCardDark};
    box-shadow: ${theme.palette.mode === 'light'
      ? `0px 0px 2px rgba(0, 0, 0, 0.15), 0px 0px 6px rgba(0, 0, 0, 0.15),
      0px 12px 24px rgba(0, 0, 0, 0.15)`
      : `0px 0px 2px rgba(0, 0, 0, 0.25), 0px 0px 6px rgba(0, 0, 0, 0.25),
      0px 12px 24px rgba(0, 0, 0, 0.25)`};
  `}
  width: 70vw;
  height: 75vh;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  :focus {
    outline: none;
  }
`;

export const HeaderWrapper = styled('div')`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.palette.dina.dividerLight};
    background: ${theme.palette.dina.backgroundResting};
  `}
  height: 63px;
  display: flex;
  justify-content: space-between;
`;

export const HeaderContent = styled('span')`
  display: flex;
  flex-direction: column;
  padding: 15.5px 59px 0 16px;
`;

export const Title = styled('p')`
  ${({ theme }) => theme.typography.dina.overline}
  margin:0;
  margin-bottom: 2px;
`;

export const Filename = styled('p')`
  ${({ theme }) => css`
    ${theme.typography.dina.subtitle1}
    color: ${theme.palette.dina.highEmphasis};
  `}
  margin:0;
  overflow: hidden;
  width: 50vw;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
`;

export const IconButton = styled(MuiIconButton)`
  margin-right: 16px;
  :hover {
    background-color: transparent;
  }
`;

export const CloseIcon = styled(CloseIconComponent)`
  :hover {
    path {
      fill: ${({ theme }) => theme.palette.dina.highEmphasis};
      fill-opacity: 1;
    }
  }
`;

export const BodyWrapper = styled('div')`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.palette.dina.dividerLight};
    background-color: ${theme.palette.dina.highEmphasis};
    color: ${theme.palette.dina.blackHighEmphasis};
  `}
  flex-grow: 1;
  padding: 12px 24px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-color: ${({ theme }) => theme.palette.dina.dividerDark};
  }
`;

export const FooterWrapper = styled('div')`
  background: ${({ theme }) => theme.palette.dina.backgroundResting};
  height: 57px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 8px;
`;
