import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Avatar from 'components/avatar/Avatar';

const AvatarCellWrapper = styled('div')`
  display: flex;
  min-width: 325px;
  align-items: center;
  > :first-of-type {
    margin-right: 8px;
  }
`;

const AvatarCell = ({ mTitle, mAvatarKey }) => (
  <AvatarCellWrapper>
    <Avatar imageKey={mAvatarKey} size={24} title={mTitle} />
    {mTitle}
  </AvatarCellWrapper>
);

AvatarCell.propTypes = {
  /** text in the cell */
  mTitle: PropTypes.string,
  /** avatar image */
  mAvatarKey: PropTypes.string,
};

AvatarCell.defaultProps = {
  mTitle: '',
  mAvatarKey: '',
};

export default AvatarCell;
