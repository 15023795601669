import React from 'react';
import PropTypes from 'prop-types';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Wrapper } from './styled';
import FieldComponents from './fields/fields';
import FieldWrapper from './fields/FieldWrapper';

/**
 * This component is meant to be used in form editing experience. It has reordering support,
 * and any form editing feature should be added to this component to keep the actual
 * form rendering component MetadataEditor.jsx as lightweight as possible.
 */
function EditableMetadataEditor({ model, payload, setPayload, style, setFields }) {
  const fields = model?.mProperties?.fields ?? [];
  const visibleFields = fields.filter((f) => !f.id.startsWith('DEFAULT#'));

  const ids = fields.map((f) => f.id);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    const oldIndex = fields.findIndex((item) => item.id === active.id);
    const newIndex = fields.findIndex((item) => item.id === over.id);
    const updatedOrder = arrayMove(fields, oldIndex, newIndex);
    setFields(updatedOrder);
  };

  if (!model || !model?.mProperties || !model?.mProperties?.fields) {
    return null;
  }

  return (
    <Wrapper style={style}>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={ids} strategy={verticalListSortingStrategy}>
          {visibleFields.map((field) => {
            const Field = FieldComponents[field.type];
            if (!Field) {
              const Error = FieldComponents.error;
              return <Error type={field?.type} />;
            }
            return (
              <FieldWrapper key={field.id} field={field}>
                <Field
                  fieldModel={field}
                  value={
                    field.type === 'boolean' ? Boolean(payload?.[field.id]) : payload?.[field.id]
                  }
                  setValue={(val) => setPayload({ value: val, fieldId: field.id })}
                />
              </FieldWrapper>
            );
          })}
        </SortableContext>
      </DndContext>
    </Wrapper>
  );
}

EditableMetadataEditor.propTypes = {
  model: PropTypes.shape({
    mProperties: PropTypes.shape({
      defaultView: PropTypes.shape({}),
      defaultViews: PropTypes.array,
      fields: PropTypes.array,
      type: PropTypes.oneOf(['boolean', 'select', 'text']),
    }),
    mRefId: PropTypes.string,
    mTitle: PropTypes.string,
    id: PropTypes.string,
  }),
  payload: PropTypes.object,
  setPayload: PropTypes.func,
  setFields: PropTypes.func,
};

EditableMetadataEditor.defaultProps = {
  model: null,
  payload: {},
  setPayload: () => {},
  setFields: () => {},
};

export default EditableMetadataEditor;
