import { useMemo } from 'react';
import styled from '@emotion/styled';
import { capitalize } from 'lodash';

import { ReactComponent as Copy } from 'assets/icons/systemicons/copy.svg';
import { ReactComponent as Delete } from 'assets/icons/systemicons/delete.svg';
import { ReactComponent as Space } from 'assets/icons/systemicons/spaces_off.svg';
import { IconButton } from 'components/buttons';
import Comments from 'features/comments';
import { IconMap } from 'utils/member/member-utils';

import { ActionType, OrderRow } from '../types';

const StyledDiv = styled.div<{ showCopyIcon: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
`;

interface Props {
  onClick: (type: ActionType) => void;
  row: OrderRow;
  hideStuff?: boolean;
}

function OrderActions({ onClick, row, hideStuff }: Readonly<Props>) {
  const ResourceIcon = useMemo(() => IconMap[row.__resourceType], [row.__resourceType]);
  const showCopyIcon = false;
  return (
    <StyledDiv showCopyIcon={showCopyIcon}>
      {!hideStuff && (
        <IconButton
          usage="text"
          size={20}
          iconSize={18}
          title="Go to home space"
          onClick={() => onClick('go-to-space')}
        >
          <Space />
        </IconButton>
      )}
      <IconButton
        usage="text"
        size={20}
        iconSize={18}
        title={`Go to ${capitalize(row.__resourceType)}`}
        onClick={() => onClick('go-to-resource')}
      >
        <ResourceIcon />
      </IconButton>
      {showCopyIcon && (
        <IconButton
          usage="text"
          size={20}
          iconSize={20}
          title="Copy order id"
          onClick={() => onClick('copy')}
        >
          <Copy />
        </IconButton>
      )}
      {!hideStuff && (
        <IconButton
          usage="text"
          size={20}
          iconSize={20}
          title="Delete order"
          onClick={() => onClick('delete')}
        >
          <Delete />
        </IconButton>
      )}
      <Comments
        blockId={row.id}
        resourceId={row.__resourceId}
        resourceType="order"
        spaceId={row.__spaceId}
        formId={row.__formId}
        ownerId={row?.__ownerId?.length ? row.__ownerId : undefined}
        assigneeId={row?.__assignee?.length ? row.__assignee : undefined}
      />
    </StyledDiv>
  );
}

export default OrderActions;
