import { Alternative, AuthType } from 'types/graphqlTypes';

export const authOptions: Alternative[] = [
  {
    id: AuthType.None,
    label: 'None',
    value: AuthType.None,
  },
  {
    id: AuthType.Apikey,
    label: 'API key',
    value: AuthType.Apikey,
  },
];

export const getAuthLabel = (authType: string | undefined) => {
  if (!authType) return '';
  const authOption = authOptions.find((option) => option.value === authType);
  return authOption ? authOption.label : '';
};
