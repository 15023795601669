import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import memberTypes from 'operations/memberTypes';

import { useUploadingFiles } from 'store';
import UPDATE_MEMBER_IMAGE from 'operations/mutations/updateMemberImage';
import UPDATE_RUNDOWN_IMAGE from 'operations/mutations/updateRundownImage';
import useCreateAsset from 'hooks/useCreateAsset';
import { getFileAssetData } from 'utils/assetData';
import useLogger from 'utils/useLogger';

import UploadMediaInputView from './uploadMediaInput-view';

const UploadMediaInput = ({
  mId,
  mRefId,
  coverPhoto,
  onStart,
  onComplete,
  inputRef,
  mType,
  memberType,
  disabled,
}) => {
  const logger = useLogger('upload media input container');
  const [updateMemberImage] = useMutation(UPDATE_MEMBER_IMAGE);
  const [updateRundownImage] = useMutation(UPDATE_RUNDOWN_IMAGE);
  const [, setUploadingFile] = useUploadingFiles();
  const isRundown = mType === memberTypes.RUNDOWN_TEMPLATE;

  const [createStoryAsset] = useCreateAsset();

  const updateStory = async (mAvatarKey, mThumbnailKey) => {
    try {
      await updateMemberImage({
        variables: {
          input: {
            mId,
            mAvatarKey,
            mThumbnailKey,
          },
        },
      });
    } catch (error) {
      logger.log(error);
    }
  };

  const updateRundown = async (mCoverPhotoKey, mThumbnailKey) => {
    try {
      await updateRundownImage({
        variables: {
          input: {
            mId,
            mRefId,
            mCoverPhotoKey,
            mThumbnailKey,
            mPayload: [{ crudAction: 'UPDATE' }],
          },
        },
      });
    } catch (error) {
      logger.log(error);
    }
  };

  const createAsset = async (file, url) => {
    const assetData = getFileAssetData(mId, file);

    if (url) {
      assetData.renditions = [
        {
          mId,
          source: url,
          variant: 'main',
          fileName: file.name,
        },
      ];
    }

    const { mediaType } = assetData;
    setUploadingFile({ storyId: mId, mediaType, fileName: file.name });
    const createdAsset = await createStoryAsset(mId, assetData, true, undefined, memberType);
    setUploadingFile({ fileName: file.name });

    return createdAsset;
  };

  const uploadCoverphoto = async (file) => {
    const response = await createAsset(file);

    const [asset] = response?.data?.createAssets || [];

    await (isRundown ? updateRundown : updateStory)(asset?.mContentKey, asset?.mThumbnailKey);
  };

  const handleInput = async (event) => {
    const file = event.target.files[0];

    if (onStart) {
      onStart();
    }

    /* If media is coverphoto for Story or Rundown - create asset entry
    then update thumbnail and coverphoto key for both of them,
    else create regular asset */
    if (coverPhoto) {
      await uploadCoverphoto(file);
    } else {
      await createAsset(file);
    }

    if (onComplete) {
      onComplete();
    }
  };

  const handleClick = (event) => {
    /* eslint-disable-next-line no-param-reassign */
    event.target.value = null;
  };

  return (
    <UploadMediaInputView
      coverPhoto={coverPhoto}
      onInput={handleInput}
      onClick={handleClick}
      inputRef={inputRef}
    />
  );
};

UploadMediaInput.propTypes = {
  mId: PropTypes.string.isRequired,
  mRefId: PropTypes.string,
  coverPhoto: PropTypes.bool,
  onComplete: PropTypes.func,
  onStart: PropTypes.func,
  mType: PropTypes.string,
  disabled: PropTypes.bool,
};

UploadMediaInput.defaultProps = {
  mRefId: undefined,
  coverPhoto: false,
  mType: memberTypes.STORY,
  onComplete: undefined,
  onStart: undefined,
  disabled: false,
};

export default UploadMediaInput;
