import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const RootWrapper = styled('span')`
  ${({ theme, isCurrentUser }) =>
    isCurrentUser
      ? css`
          background-color: ${theme.palette.dina.warningBackground};
          color: ${theme.palette.dina.warningBorder};
        `
      : css`
          background-color: ${theme.palette.dina.onFocusedBackground};
          color: ${theme.palette.dina.url};
        `}
  padding: 1px 3px;
  margin-right: 3px;
  border-radius: 5px;
  box-shadow: ${({ showBorder, theme }) =>
    showBorder ? `0 0 0 1px ${theme.palette.dina.onFocus}` : 'none'};
  span {
    white-space: nowrap;
  }
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
