import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: ({ width }) => width,
    height: ({ height }) => height,
    backgroundImage: ({ src }) => `url(${src || null})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative',
    cursor: ({ src }) => (src ? 'pointer' : 'default'),
    pointerEvents: 'all',
  },
}));

export default useStyles;
