import gql from 'graphql-tag';

export default gql`
  mutation searchRequest($input: ServiceRequestInput) {
    serviceRequest(input: $input) {
      body
      ok
      provider
      status
      statusText
    }
  }
`;
