import { MouseEvent, useState } from 'react';
import { ClickAwayListener } from '@material-ui/core';

import { Button } from 'components/buttons';
import Text from 'components/text';
import useCreateConvo from 'hooks/useCreateConvo';
import useGetUser from 'hooks/useGetUser';
import { Box, HStack, VStack } from 'layouts/box/Box';
import { useIsMessageHubOpen, useSelectedConversationId } from 'store';
import { getUserIdFromLockedId } from 'utils/lock/lockToken';

import { Container, MessageContainer, Progress } from './styled';

interface Props {
  readLock: boolean;
  writeLock: boolean;
  lockedBy: string;
  isCancelled?: boolean;
  isSaving: boolean;
  //* Todo: change to required prop
  lockedId?: string;
  isCurrentUser?: boolean;
  onDone: () => Promise<void>;
  onCancel?: () => Promise<void>;
  onForceUnlock?: () => Promise<void>;
  collapsed?: boolean;
  canUnlock?: boolean;
}

export default function LockedIndicator({
  readLock,
  writeLock,
  lockedBy,
  lockedId,
  isCurrentUser,
  isCancelled,
  isSaving,
  onDone,
  onCancel,
  onForceUnlock,
  collapsed,
  canUnlock = true,
}: Readonly<Props>) {
  const { createConvo } = useCreateConvo();
  const [, setIsMessageHubOpen] = useIsMessageHubOpen();
  const [, setSelectedConvoId] = useSelectedConversationId();
  const { getUser } = useGetUser();
  const [waitForConfirmation, setWaitForConfirmation] = useState(false);

  const handleChat = async () => {
    if (!lockedId) return;
    const userId = getUserIdFromLockedId(lockedId);
    if (!userId) return;
    const user = getUser(userId);
    if (!user) return;

    await createConvo(user, (convo) => {
      setSelectedConvoId(convo?.mId);
      setIsMessageHubOpen(true);
    });
  };

  const onDoneClick = async (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    await onDone();
  };

  const onForceUnlockConfirm = async () => {
    await onForceUnlock?.();
    setWaitForConfirmation(false);
  };

  const onForceUnlockPress = () => {
    setWaitForConfirmation(true);
  };

  return (
    (readLock || writeLock) && (
      <Container $readLock={readLock} $writeLock={writeLock} $collapsed={collapsed}>
        <Box>
          {readLock && (
            <VStack>
              {isCurrentUser ? (
                <Text
                  variant="listItemLabelMedium"
                  color="blackHighEmphasis"
                  truncate
                  style={{ flex: 1 }}
                >
                  You&rsquo;re editing in another pane...
                </Text>
              ) : (
                <>
                  <Text
                    variant="listItemLabelMedium"
                    color="blackHighEmphasis"
                    style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                  >{`${lockedBy} is editing...`}</Text>
                  {lockedId && (
                    <Text
                      variant="captionRegularUnderline"
                      color="blackHighEmphasis"
                      onClick={handleChat}
                    >
                      Send a message (Open Chat)
                    </Text>
                  )}
                </>
              )}
            </VStack>
          )}
        </Box>
        <HStack justifyContent="flex-end" gap="6px">
          {writeLock && (
            <>
              {onCancel && (
                <Button
                  usage="significant"
                  variant="outlined"
                  width={104}
                  onClick={() => {
                    void onCancel?.();
                  }}
                  size="md"
                >
                  Cancel
                </Button>
              )}

              <Button usage="white" onClick={(e) => void onDoneClick(e)} size="md" width={104}>
                Save
              </Button>
            </>
          )}
          {readLock && onForceUnlock && (
            <ClickAwayListener
              onClickAway={() => {
                setWaitForConfirmation(false);
              }}
            >
              <Button
                style={{ overflow: 'inherit' }}
                usage={waitForConfirmation ? 'danger' : 'warning'}
                variant="outlined"
                size="md"
                disabled={!canUnlock}
                onClick={waitForConfirmation ? onForceUnlockConfirm : onForceUnlockPress}
                title={canUnlock ? undefined : 'You do not have permission to unlock'}
              >
                {waitForConfirmation ? 'Confirm Unlock' : 'Force Unlock'}
              </Button>
            </ClickAwayListener>
          )}
        </HStack>
        {(isSaving || isCancelled) && (
          <MessageContainer>
            <Progress variant="indeterminate" disableShrink size={24} thickness={1} />
            <Text variant="button" color="whiteHighEmphasis">
              {isCancelled ? 'Cancelling' : 'Saving'}
            </Text>
          </MessageContainer>
        )}
      </Container>
    )
  );
}
