import GET_ASSET from 'operations/queries/getAsset';
import { useQuery } from '@apollo/client';

const useGetAsset = (mId, mRefId, skip = false, fetchType = 'cache-and-network') => {
  let asset = null;
  const { data, error, loading } = useQuery(GET_ASSET, {
    variables: {
      input: {
        mId,
        mRefId,
      },
    },
    fetchPolicy: fetchType,
    nextFetchPolicy: fetchType,
    skip: skip || !mId || !mRefId,
  });

  if (skip) return [null];

  asset = data && data.getAsset;

  return [asset, error, loading];
};

export default useGetAsset;
