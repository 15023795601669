import { useMutation } from '@apollo/client';

import useCreateTemplate from 'hooks/useCreateTemplate';
import updateMember from 'operations/mutations/updateMember';
import { UpdateMemberInput } from 'types/graphqlTypes';
import { CustomFields, filter } from 'utils/metadata';
import { uploadToS3 } from 'utils/s3Utils';
import useLogger from 'utils/useLogger';
type UpdateMemberInputType = {
  input: UpdateMemberInput;
};

const DefaultContentFilename = 'content.data';

/**
 * Returns a valid file to upload to S3
 * @param template - Template to upload
 * @param filename - Filename for the file to upload
 * @return Corresponding File object
 */
const getFile = (template: object, filename: string = DefaultContentFilename): File =>
  new window.File([JSON.stringify(template)], filename, { type: 'text/plain' });

interface SaveTemplateParams {
  folderId: string;
  templateTitle: string;
  description?: string;
  overwriteData?: TemplateData;
  metadata: object;
  mProperties: object;
}

interface CreateTemplateResponse {
  data: {
    createContentTemplate: TemplateData;
  };
}

interface TemplateData {
  mId?: string;
  mRefId?: string;
  mContentKey?: string;
  mDescription?: string;
  mTitle?: string;
}

interface Template {
  metadata: object;
  properties: {
    folder: string;
    template: string;
    title: string;
    description: string;
  };
}

const areStringsEqual = (str1: string, str2: string): boolean => str1.trim() === str2.trim();

const useSaveTemplate = (editorValueRef: React.RefObject<object>) => {
  const logger = useLogger('use save template');
  const [createTemplate] = useCreateTemplate();
  const [updateTemplate] = useMutation<UpdateMemberInputType>(updateMember);

  const save = async ({
    folderId,
    templateTitle,
    description,
    overwriteData,
    metadata,
    mProperties,
  }: SaveTemplateParams): Promise<void> => {
    if (!editorValueRef.current) return;

    try {
      let tpldata: TemplateData = overwriteData ?? { mRefId: '', mContentKey: '' };
      if (!overwriteData) {
        const result = (await createTemplate(
          folderId,
          templateTitle,
          description,
          mProperties,
        )) as CreateTemplateResponse;
        tpldata = result?.data?.createContentTemplate || { mRefId: '', mContentKey: '' };
      } else if (description && !areStringsEqual(description, tpldata.mDescription ?? '')) {
        const variables = {
          input: {
            mId: tpldata.mId,
            mDescription: description,
            mRefId: tpldata.mRefId,
          },
        };

        await updateTemplate({
          variables,
        });
      }

      const template: Template = {
        ...(editorValueRef.current as Template),
        metadata: filter(metadata || (editorValueRef.current as Template).metadata, {
          ignoreNull: true,
          excludeKeys: [CustomFields.STORY_TITLE],
        }),
        properties: {
          folder: folderId,
          template: tpldata.mRefId ?? '',
          title: templateTitle,
          description: description ?? '',
        },
      };

      await uploadToS3(tpldata.mContentKey ?? '', getFile(template));
    } catch (err) {
      logger.log(err);
    }
  };

  return [save];
};

export default useSaveTemplate;
