/* eslint-disable sort-imports */
import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';

import { useEditorCommands } from 'store/editor';
import { EditorCommandConfigType } from 'types/memberTypes/editorCommands';

import { ChangedEditorCommandType, useChangedEditorCommands } from '../atomsTs';

import { STANDARD_EDITOR_COMMAND_KEYS } from './useGetEditorCommands';

const UPDATE_EDITOR_COMMAND = gql`
  mutation updateEditorCommand($input: UpdateMemberInput) {
    updateMember(input: $input) {
      ${STANDARD_EDITOR_COMMAND_KEYS}
    }
  }
`;

interface UpdateEditorCommandType {
  updateMember: EditorCommandConfigType;
}

const useUpdateEditorCommands = () => {
  const [changedCommands] = useChangedEditorCommands();
  const [, setEditorCommands] = useEditorCommands();
  const [loading, setLoading] = useState(false);
  const [updateMutation] = useMutation<UpdateEditorCommandType>(UPDATE_EDITOR_COMMAND);

  const updateEditorCommand = async (changedCommand: ChangedEditorCommandType) => {
    const variables = {
      input: {
        mId: 'mdfBlock',
        ...changedCommand,
      },
    };

    return updateMutation({
      variables,
      update: (_cache, result) => {
        const { updateMember: updatedCommand } = result?.data ?? {};
        if (!updatedCommand) return;

        setEditorCommands((prev) =>
          prev.map((prevCommand) =>
            prevCommand.mRefId === updatedCommand.mRefId ? updatedCommand : prevCommand,
          ),
        );
      },
    });
  };

  /* update multiple webhook endpoints */
  const updateEditorCommands = async () => {
    setLoading(true);
    const promises = changedCommands.map((command) => updateEditorCommand(command));

    await Promise.allSettled(promises);
    setLoading(false);
  };

  return { updateEditorCommands, loading };
};

export default useUpdateEditorCommands;
