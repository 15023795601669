import { ItemParams } from 'react-contexify';

import { ReactComponent as Open } from 'assets/icons/systemicons/open.svg';
import { MemberTypeEnum } from 'types/graphqlTypes';

import useOpenMember from '../useOpenMember';

import { ContextItem, MemberMenuProps } from './Base';

const typesThatCannotBeOpenedInTab: MemberTypeEnum[] = [
  MemberTypeEnum.Draft,
  MemberTypeEnum.Contact,
  MemberTypeEnum.Team,
  MemberTypeEnum.Department,
  MemberTypeEnum.User,
];

type PropsFromTriggerType = {
  propsFromTrigger?: MemberMenuProps;
};

const checkIfDisabled = ({ propsFromTrigger }: PropsFromTriggerType) => {
  return typesThatCannotBeOpenedInTab.includes(propsFromTrigger?.member?.mType as MemberTypeEnum);
};

export function OpenMemberItem({ ...contextProps }) {
  const { openItem } = useOpenMember();

  const handleClickEvent = (data: ItemParams<MemberMenuProps>) => {
    const { member } = data.props ?? {};
    if (member) openItem(member);
  };

  const isDisabled = checkIfDisabled(contextProps);

  return (
    <ContextItem
      id="open"
      label="Open in tab"
      disabled={isDisabled}
      icon={<Open />}
      onClick={(data: ItemParams<MemberMenuProps>) => handleClickEvent(data)}
      {...contextProps}
    />
  );
}
