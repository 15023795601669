import styled from '@emotion/styled';

import { ReactComponent as DragHandle } from 'assets/icons/systemicons/unionDragHandle.svg';
import Text from 'components/text/Text';

export const StyledFieldWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
`;

export const FieldHeader = styled(Text)`
  user-select: none;
  font-weight: 400;
`;
FieldHeader.defaultProps = {
  variant: 'overline',
  color: 'mediumEmphasis',
};

export const StyledDragHandle = styled(DragHandle)`
  cursor: grab;
  :active {
    cursor: grabbing;
    path {
      fill-opacity: 1;
    }
  }
  :hover {
    path {
      fill-opacity: 1;
    }
  }
`;

export const MdfDropdownWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

export const Wrapper = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 6px;
  .MuiFormControl-root {
    width: 100%;
  }
`;

export const SelectWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  .MuiFormControl-root {
    width: 100%;
  }
`;

export const Field = styled('span')``;
