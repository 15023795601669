/* eslint-disable no-console */
import { Editor } from 'slate';

import { Update } from '../types';

import notifyChange from './notifyChange';

const onEditorKeyDown = ({
  editor,
  event,
  update,
  onSave,
  onEscape,
}: {
  editor: Editor;
  event: React.KeyboardEvent<HTMLDivElement>;
  update?: Update;
  onSave?: () => Promise<void>;
  onEscape?: () => void;
}) => {
  if ((event.ctrlKey || event.metaKey) && event.key === 's') {
    event.stopPropagation();
    event.preventDefault();
    notifyChange(editor, update, 'userInitiated');
  }

  if (event.key === 'Escape') {
    onEscape?.();
  }

  if (
    (event.ctrlKey || event.metaKey) &&
    event.altKey &&
    (event.key === 'ß' || event.key === 's')
  ) {
    onSave?.().then(
      () => {},
      () => {},
    );
  }

  return editor;
};

export default onEditorKeyDown;
