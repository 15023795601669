import { useCallback, useMemo } from 'react';
import styled from '@emotion/styled';

import { ReactComponent as AddIcon } from 'assets/icons/systemicons/add.svg';
import { IconButton } from 'components/buttons';
import Divider from 'components/divider';
import Text from 'components/text/Text';
import { HStack, VStack } from 'layouts/box/Box';
import { useMembersDialog } from 'store/members';
import { User } from 'types/members';
import alphaSort from 'utils/alphaSort';

import MemberListItem from '../memberList/MemberListItem';

const Title = styled(HStack)`
  width: 100%;
  height: 28px;
  padding-inline: 12px 6px;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
`;

interface Props {
  type: 'user' | 'team' | 'department';
  listTitle: string;
  members: User[];
  updateMember?: (newMembers: User[]) => void;
}

const DetailListView = ({ type, listTitle, members, updateMember }: Props) => {
  const [, setGetMembers] = useMembersDialog();

  const sortedMembers = useMemo(() => alphaSort(members, 'mTitle') as User[], [members]);

  const addMember = useCallback(() => {
    setGetMembers({
      variant: type,
      open: true,
      startWith: [...members],
      dialogTitle: `Add ${listTitle}`,
      removeTooltipText: `Remove ${type}`,
      subHeader: listTitle,
      onOk: updateMember,
    });
  }, [listTitle, members, setGetMembers, type, updateMember]);

  return (
    <VStack width="100%">
      <Title>
        <Text variant="overline" color="highEmphasis" style={{ flex: 1 }}>
          {listTitle} ({sortedMembers.length})
        </Text>
        {updateMember && (
          <IconButton
            usage="text"
            size={24}
            iconSize={18}
            title={`Add ${listTitle}`}
            onClick={addMember}
          >
            <AddIcon />
          </IconButton>
        )}
      </Title>
      <Divider orientation="horizontal" />

      {sortedMembers.map((member) => (
        <MemberListItem key={member.mId} member={member} />
      ))}
    </VStack>
  );
};

DetailListView.defaultProps = {
  listTitle: 'Members',
  members: [],
  onItemClick: () => {},
};

export default DetailListView;
