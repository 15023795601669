import { DateRange } from 'components/mdfEditor/fields/date/DatePicker';

type ValueType = string | number | boolean | object | null | DateRange;

// Set a value in session storage
const setSessionStorage = (key: string, value: ValueType): void => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

// Get a value from session storage
const getSessionStorage = (key: string): ValueType => {
  const value = sessionStorage.getItem(key);
  return value ? (JSON.parse(value) as ValueType) : null;
};

export { setSessionStorage, getSessionStorage };
