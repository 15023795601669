import { useState } from 'react';
import { useMutation } from '@apollo/client';
import capitalize from 'lodash/capitalize';

import { ReactComponent as DeleteIcon } from 'assets/icons/systemicons/delete.svg';
import { IconButton } from 'components/buttons';
import { DeleteDialog } from 'components/dialogs/CommonDialogs';
import useDeleteContact from 'hooks/useDeleteContact';
import ARCHIVE_MEMBER from 'operations/mutations/archiveMember';
import { useDepartments, useTeams } from 'store/members';
import { useClosePreview } from 'store/preview';
import { MemberTypeEnum } from 'types/graphqlTypes';
import { User } from 'types/members';

interface Props {
  member: User;
}

function DeleteMember({ member }: Readonly<Props>) {
  const [teams, setTeams] = useTeams();
  const closePreview = useClosePreview();
  const [departments, setDepartments] = useDepartments();
  const { deleteContact, loading: deleteLoading } = useDeleteContact();
  const [archiveMember, { loading: archiveLoading }] = useMutation(ARCHIVE_MEMBER);

  const [open, setOpen] = useState(false);

  const action = member.mType === MemberTypeEnum.Contact ? 'Delete' : 'Archive';
  const type = capitalize(member.mType);

  const onConfirm = async () => {
    if (member.mType === MemberTypeEnum.Contact) {
      await deleteContact(member.mId);
    } else {
      await archiveMember({
        variables: {
          input: {
            mId: member.mId,
          },
        },
        update: () => {
          if (member.mType === MemberTypeEnum.Team) {
            setTeams(teams.filter((team) => team.mId !== member.mId));
          }

          if (member.mType === MemberTypeEnum.Department) {
            setDepartments(departments.filter((department) => department.mId !== member.mId));
          }
        },
      });
    }
    setOpen(false);
    closePreview();
  };

  return (
    <>
      <IconButton
        usage="text"
        size={24}
        iconSize={20}
        title={`${action} ${type}`}
        onClick={() => setOpen(true)}
      >
        <DeleteIcon />
      </IconButton>
      <DeleteDialog
        open={open}
        onClose={() => setOpen(false)}
        onClick={onConfirm}
        title={`${action} ${type}?`}
        confirmLabel={action}
        message={`${type}: "${member.mTitle}" will be ${action.toLocaleLowerCase()}d.`}
        loading={deleteLoading || archiveLoading}
      />
    </>
  );
}

export default DeleteMember;
