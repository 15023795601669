import { useCallback, useState } from 'react';
import { gql, useMutation } from '@apollo/client';

import { ProviderOutput } from './provider-types';

const UPDATE_PROVIDER = gql`
  mutation UpdateProvider($input: UpdateProviderInput) {
    updateProvider(input: $input) {
      member {
        mId
        mType
      }
      providerDetails {
        id
        resourceUrl
        resourcePermit
      }
    }
  }
`;

interface CreateChannelInput {
  storyId: string;
}

interface UpdateProvider {
  updateProvider: ProviderOutput;
}

export const useCreateChannel = () => {
  const [loading, setLoading] = useState(false);
  const [createMutation] = useMutation<UpdateProvider>(UPDATE_PROVIDER);
  const createChannel = useCallback(
    async (input: CreateChannelInput) => {
      try {
        setLoading(true);
        const result = await createMutation({
          variables: {
            input: {
              memberId: input.storyId,
              providerName: 'slack',
              providerDetails: {
                resourcePermit: true,
              },
            },
          },
        });
        return result.data;
      } finally {
        setLoading(false);
      }
    },
    [createMutation],
  );
  return { createChannel, loading };
};
