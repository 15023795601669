import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ReactComponent as AddIcon } from 'assets/icons/systemicons/plus_small_circle.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/systemicons/minus_small_cirkle.svg';

export const MainRootWrapper = styled('div')`
  display: inline-flex;
  flex-direction: column;
  min-width: 1000px;
`;

export const RootWrapper = styled('div')`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  high: 100%;
  background-color: ${({ theme }) => theme.palette.dina.backgroundResting};
`;
export const ColumnsViewWrapper = styled('div')`
  display: inline-flex;
  flex-direction: column;
  width: fit-content;
  height: 102px;
  border-top: 2px solid ${({ theme }) => theme.palette.dina.dividerLight};
  border-bottom: 2px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;
export const TableHead = styled('div')`
  ${({ theme }) => css`
    ${theme.typography.dina.overline};
    border-bottom: 1px solid ${theme.palette.dina.dividerLight};
  `}
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
`;

export const HeaderLabel = styled('div')`
  ${({ theme }) => theme.typography.dina.h7}
  width: 100%;
  margin-left: 16px;
`;

export const StyledAddIcon = styled(AddIcon)`
  padding: 4px;
  border-radius: 50%;
  :hover {
    background-color: rgba(255, 255, 255, 0.1);
    path {
      fill: ${({ theme }) => theme.palette.dina.warningBorder};
    }
    opacity: 1;
  }
`;

export const StyledRemoveIcon = styled(RemoveIcon)`
  padding: 4px;
  border-radius: 50%;
  :hover {
    background-color: rgba(255, 255, 255, 0.1);
    path {
      fill: ${({ theme }) => theme.palette.dina.warningBorder};
    }
    opacity: 1;
  }
`;
