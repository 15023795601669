import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import loadingDark from 'assets/images/loadingSpinner/dark.png';
import loadingLight from 'assets/images/loadingSpinner/light.png';
import { ThemeMode } from 'store';

interface ImageStyleProps {
  height: number;
  width: number;
}

const Image = styled('img')`
  position: absolute;
  z-index: 1;
  ${({ height, width }: ImageStyleProps) => css`
    left: calc(50% - ${height / 2}px);
    top: calc(50% - ${width / 2}px);
    height: ${height}px;
    width: ${width}px;
  `};

  &.dark {
    filter: invert(100%);
  }
`;

const InlineIndicator = styled('img')`
  ${({ height, width }: ImageStyleProps) => css`
    height: ${height}px;
    width: ${width}px;
  `};

  &.dark {
    filter: invert(100%);
  }
`;

interface LoadingButtonIndicatorProps {
  style?: React.CSSProperties;
  className?: string;
  inline?: boolean;
  size?: number;
}

export function LoadingButtonIndicator({
  style,
  className,
  inline,
  size = 24,
}: LoadingButtonIndicatorProps) {
  const theme = useTheme();
  const mode = theme.palette.mode as ThemeMode;

  return inline ? (
    <InlineIndicator
      style={style}
      src={mode === 'light' ? loadingDark : loadingLight}
      height={size}
      width={size}
      alt="button-spinner"
      className={className}
    />
  ) : (
    <Image
      src={mode === 'light' ? loadingDark : loadingLight}
      height={24}
      width={24}
      alt="button-spinner"
      className={className}
    />
  );
}

interface LoadingIndicatorProps {
  className?: string;
  height?: number;
  width?: number;
}

const LoadingIndicator = ({ className, height = 64, width = 64 }: LoadingIndicatorProps) => {
  const theme = useTheme();
  return (
    <Image
      src={theme.palette.mode === 'light' ? loadingDark : loadingLight}
      height={height}
      width={width}
      alt="spinner"
      className={className}
    />
  );
};

export default LoadingIndicator;
