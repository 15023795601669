import styled from '@emotion/styled';

import Text from 'components/text/Text';

const NotificationCount = styled(Text)`
  pointer-events: none;
  padding: 1px 6px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.palette.dina.buttonBackgroundDangerousAction};
  border: 1px solid ${({ theme }) => theme.palette.dina.whiteMediumEmphasis};
`;

const Notification = ({ notificationCount = 0 }: { notificationCount?: number }) => {
  return (
    notificationCount > 0 && (
      <NotificationCount variant="listItemLabel" color="whiteHighEmphasis">
        {notificationCount}
      </NotificationCount>
    )
  );
};

export default Notification;
