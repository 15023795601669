import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { ReactComponent as DeleteIcon } from 'assets/icons/systemicons/delete.svg';

const iconStyles = css`
  cursor: pointer;
  width: 18px;
  height: 18px;
  &:hover path {
    fill-opacity: 1;
  }
`;

export const RemoveIcon = styled(DeleteIcon)`
  ${iconStyles}
`;
