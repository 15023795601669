import React from 'react';
import styled from '@emotion/styled';

import { ReactComponent as Close } from 'assets/icons/systemicons/close.svg';
import { ReactComponent as Edit } from 'assets/icons/systemicons/edit.svg';
import { MemberIcon } from 'components/memberIcon/MemberIcon';
import Text from 'components/text/Text';
import Tooltip from 'components/tooltip';
import { Box } from 'layouts/box/Box';
import type { MemberType } from 'types/graphqlTypes';
import type { EditorCommandConfigType } from 'types/memberTypes/editorCommands';
import type { OrderFormMemberType } from 'types/memberTypes/order_form';
import { getMemberTitle } from 'utils/member/member-utils';

interface RelationItemProps {
  member: MemberType;
  tooltip?: string | null;
  doHover?: boolean; // toggle hover effect. useful if used in lists
  commands?: Record<string, EditorCommandConfigType | undefined>;
  forms?: Record<string, OrderFormMemberType | undefined>;
  showEditIcon?: boolean;
  showRemoveIcon?: boolean;
  onOpenClick: (ev: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  onEditClick?: (ev: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  onRemoveClick?: (ev: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}

const StyledWrapper = styled(Box)<{ $doHover?: boolean }>`
  height: 24px;
  width: 100%;
  max-width: 400px;
  padding-left: 3px;
  border-radius: 4px;
  flex-shrink: 0;
  &:hover {
    background: ${({ $doHover, theme }) =>
      $doHover ? theme.palette.dina.storyTimelineTickDefault : 'none'};
    .relation-actions {
      display: block;
    }
  }
`;
const StyledActionWrapper = styled(Box)`
  flex-shrink: 0;
  display: none;
  justify-content: start;
  gap: 1px;
`;

const iconStyles = `
  cursor: pointer;
  &:hover path {
    fill-opacity: 1;
  }
  justify-self: end;
  flex-shrink: 0;
  position: relative;
  top: 2px;
`;

const CloseIcon = styled(Close)`
  ${iconStyles}
`;

const EditIcon = styled(Edit)`
  ${iconStyles}
`;

const StyledText = styled(Text)`
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
`;

export function RelationItem({
  member,
  doHover,
  forms,
  commands,
  showEditIcon,
  showRemoveIcon,
  tooltip,
  onRemoveClick,
  onEditClick,
  onOpenClick,
}: Readonly<RelationItemProps>) {
  return (
    <StyledWrapper container justifyContent="space-between" $doHover={doHover}>
      <Box container justifyContent="start" gap="6px">
        <MemberIcon member={member} />
        <Tooltip title={tooltip === null ? '' : tooltip ?? 'Open'} enterDelay={500}>
          <StyledText variant="listItemLabelMedium" onClick={onOpenClick}>
            {getMemberTitle(member, forms ?? {}, commands ?? {})}
          </StyledText>
        </Tooltip>
      </Box>
      <StyledActionWrapper container flexDirection="row" className="relation-actions">
        {showEditIcon && (
          <Tooltip title="Change relation">
            <EditIcon onClick={onEditClick} />
          </Tooltip>
        )}
        {showRemoveIcon && (
          <Tooltip title="Remove">
            <CloseIcon onClick={onRemoveClick} />
          </Tooltip>
        )}
      </StyledActionWrapper>
    </StyledWrapper>
  );
}
