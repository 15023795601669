import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

import { MIdRefId } from 'types/members';

import { removeFromMdfCache } from './handleMdfCache';

const DELETE_MEMBER = gql`
  mutation DeleteMember($input: DeleteSingleMemberInput) {
    deleteSingleMember(input: $input) {
      mId
      mRefId
    }
  }
`;

interface DeleteMdf {
  deleteSingleMember: MIdRefId;
}

export const useDeleteMdf = () => {
  const [deleteMember] = useMutation<DeleteMdf>(DELETE_MEMBER);
  const deleteMdf = useCallback(async (mId: string) => {
    await deleteMember({
      variables: { input: { mId, mRefId: mId } },
      update(proxy) {
        removeFromMdfCache(proxy, mId);
      },
    });
  }, []);

  return { deleteMdf };
};
