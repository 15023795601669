import React from 'react';
import PropTypes from 'prop-types';

import ResizableDialog from 'components/resizableDialog';
import DialogWrapper from 'components/instanceCard/components/shared/dialogWrapper';

import { Iframe, IframeWrapper } from './styled';

function IframeViewer({ id, open, title, closeDialog, src }) {
  return (
    <ResizableDialog open={open} initialHeight="80%" initialWidth="80%">
      <DialogWrapper closeDialog={closeDialog} label={title} mode="browsing">
        <IframeWrapper>
          <Iframe
            id={id}
            src={src}
            title={title}
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
          />
        </IframeWrapper>
      </DialogWrapper>
    </ResizableDialog>
  );
}

IframeViewer.propTypes = {
  /** Title of the preview dialog */
  title: PropTypes.string,
  /** Callback to close preview dialog */
  closeDialog: PropTypes.func,
  /** Source url for preview component */
  src: PropTypes.string,
  /** Id of the iframe */
  id: PropTypes.string,
  /** is the dialog open */
  open: PropTypes.bool,
};

IframeViewer.defaultProps = {
  title: 'iframe viewer',
  closeDialog: () => {},
  src: '',
  id: 'iframe-viewer',
  open: false,
};

export default IframeViewer;
