import { useContext, useState } from 'react';
import UserCtx from 'contexts/UserContext';
import { useApolloClient } from '@apollo/client';

import useUpdateSettings from 'hooks/useUpdateSettings';
import GET_SETTINGS from 'operations/queries/getSettings';

import { useChangedGeneralSystemSettings } from '../atoms';

const useUpdateSystemSettings = () => {
  const [changedGeneralSystemSettings] = useChangedGeneralSystemSettings();
  const [loading, setLoading] = useState(false);
  const user = useContext(UserCtx);
  const { mId: userId } = user;

  const client = useApolloClient();

  const updateSettings = useUpdateSettings();

  const updateSystemSettings = async () => {
    const mMetaData = changedGeneralSystemSettings.map(({ originalValue, ...rest }) => ({
      ...rest,
    }));

    setLoading(true);
    await updateSettings({ mId: 'settings', mRefId: 'general', mMetaData });

    await client.query({
      query: GET_SETTINGS,
      variables: {
        generalSettingsInput: { mId: 'settings', mRefId: 'general' },
        userSettingsInput: { mId: 'settings', mRefId: userId },
      },
      fetchPolicy: 'network-only',
    });

    setLoading(false);
  };
  return [updateSystemSettings, loading];
};

export default useUpdateSystemSettings;
