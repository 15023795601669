/* eslint-disable import/prefer-default-export */
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const getColorOption = (colors) => ({
  outlined: {
    color: colors.mediumEmphasis,
    backgroundColor: `background-color: rgba(0,0,0,0.08)`,
    hover: `background-color: rgba(255,255,255,0.08)`,
  },
});

export const Button = styled('button')`
  ${({ theme }) => css`
    ${theme.typography.dina.caption};
    background-color: ${theme.palette.dina.blackHoverOverlay};
    border: 1px dashed ${theme.palette.dina.buttonBorderOutlined};
  `};
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  height: ${({ height }) => (height ? `${height}px` : 'auto')};
  user-select: none;
  cursor: pointer;
  transition: transform 150ms;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;

  svg {
    margin: 0;
    :hover {
      fill: ${({ theme }) => theme.palette.dina.highEmphasis};
      opacity: 1;
    }
  }

  :hover {
    ${({ theme }) => css`
      color: ${theme.palette.dina.highEmphasis};
      background-color: ${theme.palette.dina.whiteHoverOverlay};
    `}
    transform: scale(1.005);
  }
  :active {
    transform: scale(0.98);
  }

  :focus {
    border: 3px solid ${({ theme }) => theme.palette.dina.onFocus};
  }

  :focus-visible {
    outline: none;
  }

  ${({ color, theme }) => {
    const option = getColorOption(theme.palette.dina)[color];
    option &&
      css`
        ${option.backgroundColor};
        color: ${option.color};
        :hover {
          ${option.hover};
        }
      `;
  }};

  ${({ selected, theme }) =>
    selected &&
    css`
      background-color: ${theme.palette.dina.onSelected};
      color: ${theme.palette.dina.highEmphasis};
      :hover {
        background-color: ${theme.palette.dina.blackHoverOverlay};
      }
    `}

  ${({ disabled, theme }) =>
    disabled &&
    css`
      opacity: 0.65;
      cursor: default;
      :hover {
        transform: scale(1);
        background-color: ${theme.palette.dina.blackHoverOverlay};
      }
    `}
`;
