import { css } from '@emotion/react';
import styled from '@emotion/styled';

const colorMap: Record<string, string> = {
  video: '#89B6F940',
  image: '#E272834D',
  audio: '#E459CE33',
  file: '#A0DBAF33',
  folder: 'rgba(255,255,255,.05)',
};

export const Slider = styled('div')`
  width: 2px;
  height: 100%;
  background: white;
  position: absolute;
  z-index: 5;
  top: 0;
  opacity: 0.6;
  pointer-events: none;
`;

export const ThumbnailOverlay = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

export const TypeIcon = styled('div')`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.5);
  bottom: 3px;
  left: 3px;
  position: absolute;
  svg {
    position: relative;
    left: 2px;
    top: -1px;
  }
`;

export const TimeCode = styled('div')`
  position: absolute;
  right: 3px;
  bottom: 3px;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.5);
  font-size: 9px;
  padding: 2px 3px 1px 3px;
  ${({ theme }) => css`
    color: ${theme.palette.dina.mediumEmphasis};
  `}
`;

export const ItemWrapper = styled('li')`
  height: 64px;
  display: flex;
  gap: 6px;
  width: 100%;
  padding-right: 12px;
  padding-left: 3px;
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.palette.dina.whiteHoverOverlay};
    filter: brightness(108%);
  }
`;

export const Details = styled('div')`
  flex: 1;
  min-width: 0;
  padding-top: 4px;
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.palette.dina.dividerLight};
    color: ${theme.palette.dina.highEmphasis};
  `}
`;

export const Title = styled('div')`
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 10px;
`;

export const Created = styled('div')`
  font-size: 8px;
  ${({ theme }) => css`
    color: ${theme.palette.dina.mediumEmphasis};
  `}
`;

export const ThumbnailWrapper = styled('div')<{ $width: string; $height?: string }>`
  position: relative;
  margin-top: 3px;
  border-radius: 3px;
  padding: 3px;
  ${({ $height }) => `height: ${$height ?? 56}px`};
  overflow: hidden;
  ${({ $width }) => `flex: 0 0 ${$width}px`};
`;

export const Thumbnail = styled('div')<{ $src: string }>`
  ${({ $src }) => `background-image: url(${$src})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: black;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 3px;
`;

export const PlaceholderThumbnail = styled('div')<{ $type: string }>`
  background: ${({ $type }) => colorMap[$type]};
  border: 2px solid ${({ $type }) => colorMap[$type]};
  position: absolute;
  display: flex;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  svg {
    margin: auto;
  }
`;

export const HoverThumbnail = styled('img')<{ $imgFactor: number }>`
  transform: scale(${({ $imgFactor }) => `${$imgFactor}`});
  transform-origin: left top;
  position: absolute;
`;
