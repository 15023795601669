import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

import useGetMember from 'api/useGetMember';
import { Note, WithRequired } from 'types';
import { CreateMemberInput, MemberType, MemberTypeEnum } from 'types/graphqlTypes';

const CREATE_NOTE = gql`
  mutation CreateNote($input: CreateMemberInput) {
    createMember(input: $input) {
      mId
      mRefId
      mType
      mStoryId
      mContentKey
      mUpdatedAt
      locked
      mDefaultContentKey
    }
  }
`;

type CreateNoteResponse = { createMember: Note };
type CreateNoteInput = { input: CreateMemberInput };

/**
 * Fetches or creates a note member if not found.
 *
 * @param entity - The entity object containing required `mId` `mRefId` and `mStoryId` properties.
 * @param entity.mId - The unique identifier for the note. (required)
 * @param entity.mRefId - The reference ID for the note. (required)
 * @param entity.mStoryId - The ID of the parent item holding the note. (required)
 *
 * @returns An object with `data`, `loading`, and `error` properties
 * reflecting the member data, loading state, and any errors encountered.
 */
const useCreateNoteIfNotFound = (
  entity: WithRequired<MemberType, 'mId' | 'mRefId' | 'mStoryId'>,
) => {
  const { mId, mRefId, mStoryId } = entity;

  const { data, loading, error, refetch } = useGetMember<Note>({
    mId,
    mRefId,
    fetchPolicy: 'cache-and-network',
  });
  const [create, { data: note, loading: creating, error: createError }] = useMutation<
    CreateNoteResponse,
    CreateNoteInput
  >(CREATE_NOTE);

  useEffect(() => {
    if (!mId) return;
    if (data || loading || creating || note || !mStoryId || createError) return;

    const input: CreateMemberInput = {
      mId,
      mRefId,
      mType: MemberTypeEnum.Note,
      mStoryId,
    };

    // create note entity
    create({ variables: { input } }).then(
      () => {
        refetch().then(
          () => {},
          () => {},
        );
      },
      () => {},
    );
  }, [create, creating, data, loading, mId, mRefId, mStoryId, note, refetch, createError]);

  return {
    data: data ?? note?.createMember,
    loading: loading ?? creating,
    error: error ?? createError,
  };
};

export default useCreateNoteIfNotFound;
