// eslint-disable-next-line sort-imports
import { useQuery, type WatchQueryFetchPolicy } from '@apollo/client';

import GET_INSTANCE from 'operations/queries/getInstance';
import { getStoryInstancesQuery } from 'operations/queryVariables';
import { Instance } from 'types';
import { GetMembersOfTypeBySecIdInput, MemberTypeEnum } from 'types/graphqlTypes';

type GetInstancesReturnType = {
  getMembersOfTypeBySecId: Instance[];
};

type GetInstancesInputType = {
  input: GetMembersOfTypeBySecIdInput;
};

const useInstances = (storyId: string, fetchPolicy: WatchQueryFetchPolicy | undefined) => {
  const { data, error, loading, refetch } = useQuery<GetInstancesReturnType, GetInstancesInputType>(
    GET_INSTANCE,
    {
      variables: getStoryInstancesQuery(storyId) as {
        input: { mSecId: string; mType: MemberTypeEnum };
      },
      fetchPolicy,
    },
  );

  let instances: Instance[] = [];
  if (data?.getMembersOfTypeBySecId) {
    const { getMembersOfTypeBySecId } = data;
    instances = [...getMembersOfTypeBySecId];
  }

  return { instances, error, loading, refetch };
};

export default useInstances;
