import {
  useSelectedGroupCategoryAtom,
  useSelectedGroupName,
} from 'screens/main/components/header/navbar/settings/atoms';
import {
  useSelectedLeftMenu,
  useSelectedOrganization,
} from 'screens/main/components/header/navbar/settings/atomsTs';
import List from 'screens/main/components/header/navbar/settings/components/collapseList';
import GROUP_SETTING_TYPES from 'screens/main/components/header/navbar/settings/utils/groupSettingTypes';
import { MemberType } from 'types/graphqlTypes';

interface Props {
  groupPolicies: MemberType[];
}

export default function Menu({ groupPolicies }: Readonly<Props>) {
  const [selectedLeftMenu, setSelectedLeftMenu] = useSelectedLeftMenu();
  const [category, setCategory] = useSelectedGroupCategoryAtom();
  const [selectedOrganizationMenu, setSelectedOrganizationMenu] = useSelectedOrganization();
  const [, setSelectedGroupName] = useSelectedGroupName();

  const onClickGroups = (group: MemberType) => {
    setSelectedOrganizationMenu(GROUP_SETTING_TYPES.GROUP);
    setCategory(GROUP_SETTING_TYPES.GROUP);
    if (group.mRefId) setSelectedLeftMenu(group.mRefId);
    setSelectedGroupName(group.mTitle);
  };

  return (
    <List>
      <List.Header
        itemCount={groupPolicies?.length}
        selected={
          category === GROUP_SETTING_TYPES.GROUP &&
          selectedOrganizationMenu === GROUP_SETTING_TYPES.GROUP
        }
        hideCreateButton
        createButtonTooltip={undefined}
        createButtonDisabled={undefined}
        onCreateClick={undefined}
      >
        Groups
      </List.Header>
      <List.Body>
        {groupPolicies?.map((item) => (
          <List.Item
            key={item.mRefId}
            selected={
              selectedLeftMenu === item?.mRefId &&
              selectedOrganizationMenu === GROUP_SETTING_TYPES.GROUP
            }
            onClick={() => onClickGroups(item)}
            itemCount={item?.mProperties?.userCount}
            id={item.mRefId}
            italic={undefined}
            className={undefined}
          >
            {item.mTitle}
          </List.Item>
        ))}
      </List.Body>
    </List>
  );
}
