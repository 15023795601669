import { ReactEditor } from 'slate-react';
import getMosAsset from 'components/editor/utils/getMosAsset';
import { elementTypes, actionTypes } from 'components/editor/constants/types';
import insertSecondaryAutomationElement from 'components/editor/components/secondaryAutomation/utils/insertSecondaryAutomationElement';
import notifyChange from 'components/editor/utils/notifyChange';
import { mediaTypes } from 'utils/rundownItemTypes';

const { OVERLAY_GRAPHICS } = elementTypes;

const onGraphicsDrop = async ({ payload }, editor, element, update) => {
  const mosAsset = getMosAsset(payload, mediaTypes.GRAPHICS);

  if (!mosAsset) return;

  mosAsset.mediaType = mediaTypes.SECONDARY_GRAPHICS;

  try {
    const result = await update({
      type: actionTypes.CREATE_ASSET,
      payload: { document: editor.children, asset: mosAsset },
    });

    const asset = result.data.createAssets[0];

    if (!asset) return;

    insertSecondaryAutomationElement(
      editor,
      OVERLAY_GRAPHICS,
      {
        templateVariant: asset.mTitle,
        mId: asset.mId,
        mRefId: asset.mRefId,
        mAssetId: asset.mAssetId,
        id: asset.mAssetId,
        provider: mosAsset.provider,
        mosobj: payload,
      },
      false,
    );

    notifyChange(editor, update);
    ReactEditor.focus(editor);
  } catch (e) {
    // console.log(e)
  }
};

export default onGraphicsDrop;
