import { useMemo, useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';

import EditTitleCell from 'features/grids/common/components/cells/EditTitleCell';
import { PlanningTypeCell } from 'features/grids/common/components/cells/PlanningTypeCell';
import { useGetMdfColumns } from 'features/mdf/useGetMdfColumns';
import { ParsedMemberType } from 'types';
import type { Mdf } from 'types/graphqlTypes';
import { isoToLocaleShort } from 'utils/datetimeHelpers';

import { actionColumn } from './actionColumn';

const defaultPlanningColumns: ColumnDef<ParsedMemberType>[] = [
  {
    accessorKey: 'commandData.mTitle',
    header: 'Type',
    size: 180,
    cell: ({ row }) => <PlanningTypeCell row={row} />,
    filterFn: 'arrIncludesSome',
  },
  {
    accessorKey: 'mTitle',
    header: 'Title',
    cell: ({ row, getValue, table }) => (
      <EditTitleCell row={row} getValue={getValue} updateTitle={table.options.meta?.updateTitle} />
    ),
  },
  {
    accessorKey: 'mCreatedAt',
    cell: ({ getValue }) => isoToLocaleShort(getValue()),
    header: 'Created',
    size: 140,
  },
  {
    accessorKey: 'mUpdatedAt',
    cell: ({ getValue }) => isoToLocaleShort(getValue()),
    header: 'Updated',
    size: 140,
  },
];

export function useGetPlanningColumns(blocks: ParsedMemberType[], mdfs: Mdf[]) {
  const [blockIds, setBlockIds] = useState<string[]>([]);
  if (blocks.length !== blockIds.length) {
    setBlockIds(blocks.map((block) => block.mRefId as string));
  }
  const mdfDefinitions = useGetMdfColumns(blocks, mdfs);

  const columns = useMemo(() => {
    return [...defaultPlanningColumns, ...mdfDefinitions, actionColumn];
  }, [blockIds]);

  return columns;
}
