import { useEffect } from 'react';

import { createWidgetDefaults, useCreateWidgetMenu } from 'features/widget/CreateWidgetMenu';
import { ResizablePanel, ResizablePanelGroup } from 'lib/resize';

import { LayoutProps } from '../layouts';
import { PanelContent } from '../PanelContent';
import { HorizontalResizeHandle, VerticalResizeHandle } from '../ResizableHandlers';

export default function OneByThreeVertical({ widgets, ...panelProps }: Readonly<LayoutProps>) {
  const [, setCreateWidget] = useCreateWidgetMenu();

  const topWidget = widgets[0];
  const bottomLeftWidget = widgets[1];
  const bottomMiddleWidget = widgets[2];
  const bottomRightWidget = widgets[3];

  useEffect(() => {
    return () => {
      setCreateWidget({ ...createWidgetDefaults });
    };
  }, []);

  return (
    <ResizablePanelGroup direction="vertical">
      <ResizablePanel style={{ marginLeft: '8px', marginTop: '2px', marginRight: '8px' }}>
        <PanelContent
          index={0}
          layout="1-3-vertical"
          setCreateWidget={setCreateWidget}
          widget={topWidget}
          {...panelProps}
        />
      </ResizablePanel>
      <>
        <HorizontalResizeHandle />
        <ResizablePanel style={{ marginRight: '8px', marginBottom: '8px', marginLeft: '8px' }}>
          <ResizablePanelGroup direction="horizontal">
            <ResizablePanel>
              <PanelContent
                index={1}
                layout="1-3-vertical"
                setCreateWidget={setCreateWidget}
                widget={bottomLeftWidget}
                {...panelProps}
              />
            </ResizablePanel>
            <VerticalResizeHandle />
            <ResizablePanel>
              <PanelContent
                index={2}
                layout="1-3-vertical"
                setCreateWidget={setCreateWidget}
                widget={bottomMiddleWidget}
                {...panelProps}
              />
            </ResizablePanel>
            <VerticalResizeHandle />
            <ResizablePanel>
              <PanelContent
                index={3}
                layout="1-3-vertical"
                setCreateWidget={setCreateWidget}
                widget={bottomRightWidget}
                {...panelProps}
              />
            </ResizablePanel>
          </ResizablePanelGroup>
        </ResizablePanel>
      </>
    </ResizablePanelGroup>
  );
}
