import { WIDGETS } from 'features/widget/constants';
import { FilterType } from 'types/widget';

type FiltersProps = {
  filters?: FilterType[];
};

export const getPropFilters = ({ filters }: FiltersProps) => {
  return filters?.find((f) => f.key === 'filters')?.value;
};

export const minWidthByType = {
  [WIDGETS.FEED]: '300px',
  [WIDGETS.IFRAME]: '300px',
  [WIDGETS.KANBAN]: '360px',
  [WIDGETS.ASSETS]: '300px',
  [WIDGETS.MIMIR]: '300px',
  [WIDGETS.SEARCH]: '580px',
  [WIDGETS.STORYGRID]: '580px',
  [WIDGETS.SEARCH_PLUGIN]: '300px',
  [WIDGETS.TASKS]: '600px',
  [WIDGETS.NOTE]: '300px',
  [WIDGETS.TABLE]: '580px',
};
