import styled from '@emotion/styled';
import * as ScrollArea from '@radix-ui/react-scroll-area';
const SCROLLBAR_SIZE = 10;

export const ScrollAreaRoot = styled(ScrollArea.Root)`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const ScrollAreaViewport = styled(ScrollArea.Viewport)`
  width: 100%;
  height: 100%;
`;

export const ScrollAreaScrollbar = styled(ScrollArea.Scrollbar)`
  display: flex;
  user-select: none;
  touch-action: none;
  padding: 2px;
  transition: background 160ms ease-out;
  &[data-orientation='vertical'] {
    width: ${SCROLLBAR_SIZE}px;
  }
  &[data-orientation='horizontal'] {
    flex-direction: column;
    height: ${SCROLLBAR_SIZE}px;
  }
`;

export const ScrollAreaThumb = styled(ScrollArea.Thumb)`
  flex: 1;
  background: ${({ theme }) => theme.palette.dina.scrollbar};
  &:hover {
    background: ${({ theme }) => theme.palette.dina.scrollbarHover};
  }
  border-radius: ${SCROLLBAR_SIZE}px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    min-width: 44px;
    min-height: 44px;
  }
`;
