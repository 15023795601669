import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const RootWrapper = styled('div')`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.palette.dina.dividerLight};
    background: ${theme.palette.dina.backgroundResting};
  `}
  width: 100%;
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  flex-shrink: 0;
  user-select: none;
`;

export const Title = styled('div')`
  ${({ theme }) => theme.typography.dina.h5};
  display: flex;
  align-items: center;
  line-height: 28px;
`;

export const CloseButton = styled('div')`
  width: 24px;
  height: 24px;
  cursor: pointer;
  :hover {
    path {
      fill: ${({ theme }) => theme.palette.dina.highEmphasis};
      fill-opacity: 1;
    }
  }
`;
