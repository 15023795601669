import { useMutation } from '@apollo/client';

import { MemberType, MutationUpdateBoardArgs, UpdateBoardActionEnum } from 'types/graphqlTypes';

import { addMemberToCache, removeMemberFromCache, reorderMembersInCache } from './cacheUpdate';
import { UPDATE_BOARD } from './graphql';
import { MutationType } from './types';

export const useUpdateBoardColumns = (boardId: string) => {
  const [updateBoard] = useMutation<MutationType, MutationUpdateBoardArgs>(UPDATE_BOARD);

  /** Add a new member to a column */
  const addMember = async (member: MemberType, columnId: string) => {
    const inputProps = {
      boardId,
      columnId,
      crudAction: UpdateBoardActionEnum.AddMember,
    };

    await updateBoard({
      variables: {
        input: {
          ...inputProps,
          memberId: member.mId,
        },
      },
      optimisticResponse: {
        updateBoard: {
          ...inputProps,
          member: member,
          memberId: member.mId,
          // For consistency with optimistic response and update function
          memberIds: null,
          __typename: 'BoardUpdatedType',
        },
      },
      update(cache, { data }) {
        if (!data?.updateBoard) return;
        addMemberToCache(cache, data.updateBoard);
      },
    });
  };

  /** Remove a member from a column */
  const removeMember = async (memberId: string, columnId: string) => {
    const inputProps = {
      boardId,
      columnId,
      crudAction: UpdateBoardActionEnum.RemoveMember,
    };

    await updateBoard({
      variables: {
        input: {
          ...inputProps,
          memberId,
        },
      },
      optimisticResponse: {
        updateBoard: {
          ...inputProps,
          memberId,
          // For consistency with optimistic response and update function
          member: null,
          memberIds: null,
          __typename: 'BoardUpdatedType',
        },
      },
      update(cache, { data }) {
        if (!data?.updateBoard) return;
        removeMemberFromCache(cache, data.updateBoard);
      },
    });
  };

  /** Reorder members in a column */
  const reorderMembers = async (memberOrder: string[], columnId: string) => {
    const inputProps = {
      boardId,
      columnId,
      crudAction: UpdateBoardActionEnum.ReorderMembers,
    };

    await updateBoard({
      variables: {
        input: {
          ...inputProps,
          memberOrder,
        },
      },
      optimisticResponse: {
        updateBoard: {
          ...inputProps,
          memberIds: memberOrder,
          // For consistency with optimistic response and update function
          member: null,
          memberId: null,
          __typename: 'BoardUpdatedType',
        },
      },
      update(cache, { data }) {
        if (!data?.updateBoard) return;
        reorderMembersInCache(cache, data.updateBoard);
      },
    });
  };

  return { addMember, removeMember, reorderMembers };
};
