import React, { forwardRef, useCallback, useEffect, useState } from 'react';

import { Input } from './styled';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onBlur?: () => void;
  initialValue?: string;
  updateValue?: (value: string) => void;
  required?: boolean;
  autoFocus?: boolean;
}

const InputField = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type = 'text',
      onBlur = () => {},
      initialValue = '',
      updateValue = () => {},
      required,
      autoFocus = false,
      ...props
    },
    ref,
  ) => {
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    const handleKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
          if (value !== initialValue) {
            updateValue(value);
          } else {
            onBlur();
          }
        } else if (event.key === 'Escape') {
          setValue(initialValue);
          onBlur();
        }
      },
      [initialValue, updateValue, value],
    );

    const handleBlur = useCallback(() => {
      if (value !== initialValue) {
        updateValue(value);
      } else {
        onBlur();
      }
    }, [initialValue, updateValue, value]);

    return (
      <Input
        type={type}
        ref={ref}
        required={required}
        value={value}
        onChange={({ target }) => {
          setValue(target.value);
        }}
        autoFocus={autoFocus}
        onFocus={({ target }) => {
          target.select();
        }}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        {...props}
      />
    );
  },
);

InputField.displayName = 'InputField';

export default InputField;
