import React, { useState, useEffect, useCallback, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import { LazyPlayer } from 'components/reactPlayer';
import { ReactComponent as EmbeddedVideoIcon } from 'assets/icons/systemicons/editor/blocks_EmbeddedVideo_off.svg';
import play from 'assets/icons/systemicons/Play_WithCircleBackground.svg';
import getMilliseconds from 'utils/getMilliseconds';
import getTimeString from 'utils/getTimeString';
import MediaDialog from 'components/editor/components/primaryAutomation/components/mediaDialog';
import {
  PlayImage,
  ThumbnailWrapper,
  IconButton,
  EmptyThumbnail,
  DurationIndicator,
} from './styled';

const Thumbnail = ({ src }) => {
  const [mediaDialogOpen, setMediaDialogOpen] = useState(false);
  const [canPlayVideo, setCanPlayVideo] = useState(true);
  const [duration, setDuration] = useState();
  const playerRef = useRef(null);

  const closeMediaDialog = useCallback(() => {
    setMediaDialogOpen(false);
  }, []);

  const openMediaDialog = useCallback(() => {
    setMediaDialogOpen(true);
  }, []);

  useEffect(() => {
    setDuration();
  }, [src]);

  const handleDuration = useCallback((newDuration) => {
    if (newDuration) setDuration(getTimeString(getMilliseconds(newDuration)));
    else setDuration();
  }, []);

  const handlePlay = useCallback(() => {
    playerRef.current.showPreview();
    openMediaDialog();
  }, [openMediaDialog]);

  const handleReady = useCallback(() => {
    playerRef.current.showPreview();
  }, []);

  const handleError = useCallback(() => {
    setCanPlayVideo(false);
    setDuration();
  }, []);

  if (src && canPlayVideo)
    return (
      <ThumbnailWrapper onClick={handlePlay} role="presentation">
        <LazyPlayer
          key={src}
          playerRef={playerRef}
          width={156}
          height={88}
          url={src}
          onReady={handleReady}
          onError={handleError}
          onDuration={handleDuration}
          playIcon={
            <IconButton onClick={handlePlay}>
              <PlayImage src={play} alt="play" />
            </IconButton>
          }
        />
        {duration && <DurationIndicator>{duration}</DurationIndicator>}
        {mediaDialogOpen && (
          <MediaDialog
            asset={{ mediaType: 'video', mContentUrl: src }}
            open={mediaDialogOpen}
            onClose={closeMediaDialog}
            showPlayer
          />
        )}
      </ThumbnailWrapper>
    );

  return (
    <EmptyThumbnail>
      <EmbeddedVideoIcon />
    </EmptyThumbnail>
  );
};

Thumbnail.propTypes = {
  /** url of video */
  src: PropTypes.string,
};

Thumbnail.defaultProps = {
  src: '',
};

export default memo(Thumbnail);
