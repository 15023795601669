import React from 'react';
import PropTypes from 'prop-types';
import useInputEvents from 'hooks/useInputEvents';
import { ReactComponent as CloseIcon } from 'assets/icons/systemicons/close_small.svg';
import { ReactComponent as EnterIcon } from 'assets/icons/systemicons/enter.svg';
import { InputAdornment, FilledInput, ClickAwayListener } from '@material-ui/core';
import useStyles from './inputWithButtons-styles';

const Button = ({ variant, onClick, children }) => {
  const classes = useStyles();
  return (
    <div
      role="button"
      tabIndex="0"
      onKeyDown={() => {}}
      onClick={onClick}
      className={`${classes.button} ${classes[variant]}`}
    >
      {children}
    </div>
  );
};

const TitleInputView = ({
  title,
  setTitle,
  initialTitle,
  onClose,
  onUpdate,
  onClickAway,
  disableEdit,
}) => {
  const classes = useStyles();

  const onUpdateInput = (newValue) => {
    const value = newValue.trim();
    if (!value) setTitle(initialTitle);
    else if (value === initialTitle) setTitle(value);
    else onUpdate(value);
    onClose();
  };

  const [inputRef, handleKeyDown] = useInputEvents(onUpdateInput, title, initialTitle, true);

  const onChange = (event) => setTitle(event.target.value);

  const handleEnterClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onUpdateInput(title);
  };

  const handleCloseClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setTitle(initialTitle);
    onClose();
  };

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <FilledInput
        autoFocus
        className={classes.input}
        type="text"
        inputRef={inputRef}
        value={title}
        onKeyDown={handleKeyDown}
        onChange={onChange}
        disabled={disableEdit}
        classes={{
          root: classes.filledInput,
          underline: classes.underline,
          input: classes.input,
          disabled: classes.disabled,
          error: classes.error,
        }}
        inputProps={{ className: classes.inputProp }}
        endAdornment={
          <InputAdornment position="end" className={classes.inputAdornment}>
            <Button variant="danger" onClick={handleCloseClick}>
              <CloseIcon />
            </Button>
            <Button variant="approved" onClick={handleEnterClick}>
              <EnterIcon className={classes.icon} />
            </Button>
          </InputAdornment>
        }
      />
    </ClickAwayListener>
  );
};

TitleInputView.propTypes = {
  /** Title of the instance */
  title: PropTypes.string,
  /** setTitle on the parent */
  setTitle: PropTypes.func,
  /** initial title of the instance */
  initialTitle: PropTypes.string,
  /** onClose title input callback */
  onClose: PropTypes.func,
  /** Callback to be invoked on title update */
  onUpdate: PropTypes.func,
  /** Callback fired when clicked outside of input field */
  onClickAway: PropTypes.func,
  /** Boolean that stops an user from editing an instance */
  disableEdit: PropTypes.bool,
};

TitleInputView.defaultProps = {
  title: '',
  setTitle: () => {},
  initialTitle: '',
  onClose: () => {},
  onUpdate: (newTitle) => {},
  onClickAway: () => {},
  disableEdit: false,
};

export default TitleInputView;
