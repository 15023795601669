import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    position: 'absolute',
  },
  dialogPaper: {
    margin: '0px',
    backgroundColor: theme.palette.dina.surfaceCard,
    borderRadius: '8px',
    overflow: 'auto',
    maxHeight: 'calc(100% - 32px)'
  },
}));

export default useStyles;
