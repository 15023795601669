import { useCallback, useState } from 'react';
import { useContextMenu } from 'react-contexify';

import { Button } from 'components/buttons';
import Dialog from 'components/dialogs/DialogBuilder';
import DraggableDialog from 'components/dialogs/Draggable';
import Text from 'components/text/Text';
import OrderGridFull from 'features/orderForm/components/OrderGridFull';
import { useOrderFormFilter, useOrdersDialog, useOrdersOpenDialog } from 'store';
import { GetOrderEnum } from 'types/graphqlTypes';
import { IconMap } from 'utils/member/member-utils';

import { AddIcon, GridWrapper, TitleWrapper } from './styled';

function OrdersDialog() {
  const [open, setOpen] = useOrdersOpenDialog();
  const [, setFormFilter] = useOrderFormFilter();
  const { show } = useContextMenu({ id: 'orderForms' });
  const [state] = useOrdersDialog();
  const ResourceIcon = IconMap[state.resourceType];
  const [dimension, setDimension] = useState({ width: '80vw', height: '70vh' });

  const initialWidth = window.innerWidth * 0.1;
  const initialHeight = window.innerHeight * 0.15;

  const initialPosition = { x: initialWidth, y: initialHeight };

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const doAddOrder = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setFormFilter({ types: state.formTypes ?? null });
      show({
        event,
        props: {
          resourceId: state.resourceId,
          resourceType: state.resourceType,
        },
      });
    },
    [state],
  );

  return (
    <DraggableDialog
      open={open}
      onClose={onClose}
      dimension={dimension}
      setDimension={setDimension}
      initialPosition={initialPosition}
    >
      <Dialog.Header className={'dragHandler'}>
        <TitleWrapper>
          <ResourceIcon />
          <Text>{`Orders for ${state.resourceType} "${state.resourceTitle}"`}</Text>
        </TitleWrapper>
      </Dialog.Header>
      <Dialog.Body>
        <GridWrapper>
          <OrderGridFull
            resourceId={state.resourceId}
            resourceType={GetOrderEnum.Resource}
            closeDialog={onClose}
            hideCompletedSwitch={true}
            headerSlot={
              <Button
                width={200}
                height={32}
                variant="outlined"
                usage="cta"
                onClick={doAddOrder}
                style={{ alignSelf: 'flex-end' }}
              >
                <AddIcon />
                <Text
                  variant="button"
                  color="whiteHighEmphasis"
                >{`Add task to ${state.resourceType}`}</Text>
              </Button>
            }
          />
        </GridWrapper>
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton label="Close" />
      </Dialog.Footer>
    </DraggableDialog>
  );
}

export { OrdersDialog };
