import { PlatformSectionConfig } from 'types/graphqlTypes';

/**
 * Extract the metadata value of a certain element
 * that is defined in the config property
 * within the platformStructure definition of a platform
 */
export const getMetaDataValueFromConfig = (
  config?: PlatformSectionConfig[],
  elementType?: string,
  key?: string,
) =>
  config
    ?.find((con) => con?.block === elementType)
    ?.mMetaData?.find((metaData) => metaData?.key === key)?.value;
