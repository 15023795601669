const sortRundownsByStartTime = (rundowns) =>
  rundowns.sort((a, b) => {
    if (a.recurrence.startTime > b.recurrence.startTime) return 1;
    if (a.recurrence.startTime < b.recurrence.startTime) return -1;

    if (a.mTitle.toLowerCase() > b.mTitle.toLowerCase()) return 1;
    if (a.mTitle.toLowerCase() < b.mTitle.toLowerCase()) return -1;
    return 0;
  }) || [];

// eslint-disable-next-line import/prefer-default-export
export { sortRundownsByStartTime };
