import React from 'react';
import PropTypes from 'prop-types';
import { useDrag } from 'react-dnd';

import Thumbnail from 'components/thumbnail';
import { mediaTypes } from 'utils/rundownItemTypes';
import { ReactComponent as Drag } from 'assets/icons/systemicons/drag.svg';
import useStyles from './thumbVideo-styles';

const ThumbVideo = (props) => {
  const { data, image, duration } = props;
  const classes = useStyles({ image });

  const [, dragRef] = useDrag({
    type: mediaTypes.CLIP,
    item: { type: mediaTypes.CLIP, payload: data },
  });

  return (
    <div className={classes.container} ref={dragRef}>
      <Thumbnail
        hidePlayButton
        videoThumbnailImage={image}
        width={96}
        height={64}
        type="video"
        duration={duration}
        usage="scratchPad"
        hideCloseButton
      />
      <Drag className={classes.dragButton} />
    </div>
  );
};

ThumbVideo.propTypes = {
  /** duration of the video */
  duration: PropTypes.number,
  /** Thumbnail of the video */
  image: PropTypes.string,
};

ThumbVideo.defaultProps = {
  duration: 0,
  image: null,
};

export default ThumbVideo;
