import gql from 'graphql-tag';

export default gql`
  query GetPlatforms {
    getPlatforms(input: { mType: platform }) {
      mTitle
      mRefId
      mProperties {
        __typename
        ... on PlatformType {
          platform
          platformIcon
          platformStructure {
            id
            name
            variant
            sections {
              blocks
              id
              name
            }
            config {
              block
              mMetaData {
                key
                value
              }
            }
            blocks
            allowVideoInPhotogallery
          }
          accounts {
            accountUrl
            accountLogo
            accountTitle
            accountId
            recurrence {
              startTime
              duration
              timeZone
            }
          }
        }
      }
    }
  }
`;
