import gql from 'graphql-tag';

export default gql`
  query GetSettings($generalSettingsInput: GetMemberInput, $userSettingsInput: GetMemberInput) {
    generalSettings: getMember(input: $generalSettingsInput) {
      mId
      mRefId
      mMetaData {
        key
        value
      }
    }
    userSettings: getMember(input: $userSettingsInput) {
      mId
      mRefId
      mMetaData {
        key
        value
      }
    }
  }
`;
