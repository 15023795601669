import styled from '@emotion/styled';
import { ReactComponent as VideoIconComponent } from 'assets/icons/systemicons/editor/video_off.svg';
import { ReactComponent as AudioIconComponent } from 'assets/icons/systemicons/editor/audio_on.svg';

export const VideoIcon = styled(VideoIconComponent)`
  margin: 8px;
  path {
    fill: ${({ theme }) => theme.palette.dina.blackMediumEmphasis};
    fill-opacity: 1;
  }
`;
export const AudioIcon = styled(AudioIconComponent)`
  margin: 8px;
  path {
    fill: ${({ theme }) => theme.palette.dina.blackMediumEmphasis};
    fill-opacity: 1;
  }
`;

export const ThumbnailWrapper = styled('div')`
  margin-bottom: 16px;
`;

export const Label = styled('p')`
  ${({ theme }) => theme.typography.dina.overline};
  font-weight: 500;
`;
