import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import { CommandList } from 'lib/command';

import { type MultiSelectContextProps } from '../types';

interface DropdownListProps
  extends Pick<MultiSelectContextProps, 'open' | 'mouseOn' | 'inputRef' | 'isLoading'> {
  children: React.ReactNode;
}

/** Render the dropdown list */
export const DropdownList = ({
  inputRef,
  isLoading,
  mouseOn,
  open,
  children,
}: Readonly<DropdownListProps>) => {
  return (
    <div style={{ position: 'relative' }}>
      {open && (
        <CommandList
          onMouseLeave={() => {
            mouseOn.current = false;
          }}
          onMouseEnter={() => {
            mouseOn.current = true;
          }}
          onMouseUp={() => {
            inputRef.current?.focus();
          }}
        >
          {isLoading ? <LoadingIndicator /> : children}
        </CommandList>
      )}
    </div>
  );
};
