import { CreateStringUnionType } from 'types';
import { PlatformStructure } from 'types/graphqlTypes';

import variants from '../constants/types/editorVariants';
import { EditorVariant } from '../types';

const { TWITTER, FACEBOOK, INSTAGRAM, LINKEDIN } = variants;

/** Allowed Platform variants which will have extra blocks if configured */
export const conditionalBlocksPlatformVariants = [TWITTER, FACEBOOK, INSTAGRAM, LINKEDIN];

export type DesiredVariants = CreateStringUnionType<typeof conditionalBlocksPlatformVariants>;

/**
 * Find out unique extra blocks from Db platformStructure's blocks[]
 *
 * @param {String} variant
 * @param {Object} platformStructure
 * @returns
 */
export const getConditionalBlocks = (
  variant: EditorVariant,
  platformStructure: PlatformStructure | undefined,
) => {
  if (!conditionalBlocksPlatformVariants.includes(variant as DesiredVariants) || !platformStructure)
    return;

  const { blocks } = platformStructure;
  if (!blocks || !Array.isArray(blocks)) return;

  return [...new Set(blocks)];
};
