import { DialogVessel } from '../components/DraggableDialog';
import { DrawerPreview } from '../components/Vessels';
import { VesselProps } from '../VesselFactory';

export enum VesselComponents {
  DRAWER = 'drawer',
  DIALOG = 'dialog',
}

/** Map of "Vessels" that can hold the preview content. */
export const vesselMap: { [key in VesselComponents]?: React.FC<VesselProps> } = {
  [VesselComponents.DRAWER]: DrawerPreview,
  [VesselComponents.DIALOG]: DialogVessel,
} as const;
