import LoadingIndicator from 'components/loadingIndicator';
import useDebouncedLoading from 'hooks/useDebouncedLoading';

interface Props extends React.ComponentProps<typeof LoadingIndicator> {
  isLoading?: boolean;
  debounceTime?: number;
}

const DebouncedLoadingIndicator = ({ isLoading = true, debounceTime = 500, ...rest }: Props) => {
  const showLoading = useDebouncedLoading(isLoading, debounceTime);

  return showLoading ? <LoadingIndicator {...rest} /> : null;
};

export default DebouncedLoadingIndicator;
