import { useEffect, useRef, useState } from 'react';
import { Storage } from '@aws-amplify/storage';

const useStorageImage = (key?: string, skipDownload = false) => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<string | null>(null);
  const isSubscribed = useRef<boolean>(false);

  useEffect(() => {
    isSubscribed.current = true;

    if (!key && data) setData(null);

    if (!skipDownload && key) {
      void (async () => {
        setLoading(true);

        try {
          const url = await Storage.get(key, { customPrefix: { public: '' } });
          const response = await fetch(url);

          if (response.ok) {
            const blob = await response.blob();
            const result = URL.createObjectURL(blob);

            if (isSubscribed.current) setData(result);
          } else {
            throw new Error(response.statusText);
          }
        } catch (err: unknown) {
          if (isSubscribed.current) setError((err as { message: string })?.message);
        } finally {
          if (isSubscribed.current) setLoading(false);
        }
      })();
    }

    return () => {
      isSubscribed.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, skipDownload]);

  return { error, loading, data };
};

export default useStorageImage;
