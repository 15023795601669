import { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import useToast from 'components/toast/useToast';
import { useCanAdministrateSearches } from 'hooks/useCheckUserRight';
import { SavedSearch, SavedSearchEntity, VisiblePlaces } from 'types/graphqlTypes';

import { UPDATE_SAVED_SEARCH } from './gql';
import { writeToCache } from './handleCacheUpdate';

interface UpdateSavedSearch {
  updateSavedSearch: SavedSearchEntity;
}

export const getSavedSearchEntity = (s: SavedSearch): SavedSearchEntity => {
  return {
    ...s,
    filters: JSON.stringify(s.filters),
  };
};

export const useUpdateSavedSearch = (location?: VisiblePlaces) => {
  const { errorToast } = useToast();
  const [updateMutation] = useMutation<UpdateSavedSearch>(UPDATE_SAVED_SEARCH);
  const { canAdministrate } = useCanAdministrateSearches();

  const updateSavedSearch = useCallback(
    async (input: SavedSearch) => {
      try {
        await updateMutation({
          variables: {
            input: getSavedSearchEntity(input),
          },
          optimisticResponse: {
            updateSavedSearch: {
              ...getSavedSearchEntity(input),
            },
          },
          update: (proxy, mutationResult) => {
            if (mutationResult.data?.updateSavedSearch) {
              writeToCache(proxy, mutationResult.data?.updateSavedSearch, canAdministrate);
            }
          },
        });
      } catch (err) {
        errorToast(err);
      }
    },
    [updateMutation, location],
  );
  return { updateSavedSearch };
};
