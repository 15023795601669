import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  inputMenuItem: {
    width: '100%',
    padding: '0px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  title: {
    ...theme.typography.dina.caption,
    color: theme.palette.dina.highEmphasis,
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },

  menuItem: {
    '&$menuItemSelected, &$menuItemSelected:hover, &$menuItemSelected:focus': {
      backgroundColor: theme.palette.dina.onSelected,
    },
    '&:hover': {
      backgroundColor: theme.palette.dina.whiteHoverOverlay,
    },
  },

  optionTitle: {
    ...theme.typography.dina.caption,
    color: theme.palette.dina.highEmphasis,
    lineHeight: '20px',
    paddingLeft: '10px',
    paddingRight: '15px',
  },

  selectContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  select: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  input: {
    padding: '0px',
  },
  menu: {
    background: theme.palette.dina.surfaceAppBackgroundMain,
  },
  list: {
    minWidth: 120,
    maxHeight: 250,
    padding: 0,
    marginTop: '8px',
    marginBottom: '8px',
  },
  focused: {},
  menuItemSelected: {},
}));

export default useStyles;
