/* eslint-disable sort-imports */
import styled from '@emotion/styled';
import { Divider as MuiDivider, DividerProps } from '@material-ui/core';

const StyledDivider = styled(MuiDivider)`
  background: ${({ theme }) => theme.palette.dina.dividerLight};
`;

function Divider({ className = '', ...rest }: DividerProps) {
  return <StyledDivider className={className} {...rest} />;
}

export default Divider;
