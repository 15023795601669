/* eslint-disable import/prefer-default-export */
import styled from '@emotion/styled';
import { ReactComponent as CodeIcon } from 'assets/icons/systemicons/editor/code_off.svg';

export const InputWrapper = styled('div')`
  margin-bottom: 8px;
`;

export const BoxIcon = styled(CodeIcon)`
  width: 24px;
  height: 24px;
  margin: 8px;
`;

export const HtmlThumbWrapper = styled('div')`
  overflow: hidden;
  width: 288px;
  height: 162px;
`;

export const PreviewWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 9px;
  margin-bottom: 16px;
  flex-wrap: wrap;
`;
