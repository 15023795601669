import mergeUserRights from 'utils/mergeUserRights';
import groupPoliciesConfig from 'config/permissions.json';

const calculateUserRights = (groups, members) => {
  const { policies } = groupPoliciesConfig;

  const initialUserRights = policies.map((policy) => {
    const { permissions } = policy;
    const permissionsNew = permissions.map((permission) => ({
      ...permission,
      access: permission.defaultAccess || 'deny',
    }));
    return { ...policy, permissions: permissionsNew };
  });

  let userRights = initialUserRights;

  if (groups.length > 0) {
    groups.forEach((group) => {
      const matchedPolicy = members?.find((policy) => policy.mRefId === group);
      if (matchedPolicy) {
        const mergedUserRights = mergeUserRights(userRights, matchedPolicy.mProperties.policies);
        userRights = mergedUserRights;
      }
    });
  }
  return userRights;
};

export default calculateUserRights;
