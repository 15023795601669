import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => {
  const buttonStyle = {
    ...theme.typography.dina.button,
    width: '100%',
    borderRadius: '4px',
    height: '40px',
    textTransform: 'none',
    '&:focus': {
      boxShadow: `0 0 0 2px ${theme.palette.dina.onFocus}`,
    },
  };
  return {
    buttonContainer: {
      display: 'flex',
    },
    cancelButton: {
      ...buttonStyle,
      margin: '8px 4px 8px 8px',
      border: `0.5px solid ${theme.palette.dina.buttonBorderOutlined}`,
      backgroundColor: theme.palette.dina.backgroundResting,
      '&:hover': {
        '-webkit-filter': theme.palette.dina.onHover,
      },
    },
    okButton: {
      ...buttonStyle,
      margin: '8px 8px 8px 4px',
      backgroundColor: theme.palette.dina.paletteAccentPurple,
      '&:hover': {
        '-webkit-filter': theme.palette.dina.onHover,
        backgroundColor: theme.palette.dina.paletteAccentPurple,
      },
    },
    publishButton: {
      ...buttonStyle,
      margin: '8px 8px 8px 4px',
      backgroundColor: theme.palette.dina.paletteAccentOrange,
      color: theme.palette.dina.blackHighEmphasis,
      '&:hover': {
        '-webkit-filter': theme.palette.dina.onHover,
        backgroundColor: theme.palette.dina.paletteAccentOrange,
      },
      '&:disabled': {
        color: theme.palette.dina.highEmphasis,
        opacity: 0.35,
      },
    },
    confirmPublishButton: {
      ...buttonStyle,
      margin: '8px 8px 8px 4px',
      backgroundColor: theme.palette.dina.paletteAccentRed,
      '&:hover': {
        '-webkit-filter': theme.palette.dina.onHover,
        backgroundColor: theme.palette.dina.paletteAccentRed,
      },
    },
    scheduleButton: {
      ...buttonStyle,
      margin: '8px 8px 8px 4px',
      backgroundColor: theme.palette.dina.statusApproved,
      '&:hover': {
        '-webkit-filter': theme.palette.dina.onHover,
        backgroundColor: theme.palette.dina.statusApproved,
      },
      '&:disabled': {
        color: theme.palette.dina.highEmphasis,
        opacity: 0.35,
      },
    },
  };
});

export default useStyles;
