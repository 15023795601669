import React, { useRef, useState, useCallback, memo } from 'react';
import { useSlate } from 'slate-react';
import { Transforms } from 'slate';
import { actionTypes, elementTypes } from 'components/editor/constants/types';
import tooltipEnum from 'components/editor/constants/tooltipEnum';
import notifyChange from 'components/editor/utils/notifyChange';
import { useEditorContext } from 'components/editor/EditorContext';
import { ReactComponent as HourglassIcon } from 'assets/icons/systemicons/hourglass.svg';
import Dialog from 'components/dialog';
import CreateNew from 'components/createNew';
import generatePlaceholderProps from 'utils/generatePlaceholderProps';
import useStyles from './styles';
import normalizeOnListFocus from 'components/editor/utils/normalizeOnListFocus';

const { select, insertNodes } = Transforms;

const PlaceholderButton = () => {
  const classes = useStyles();
  const editor = useSlate();
  const { selection } = editor;
  const selectionCacheRef = useRef(selection);
  const { containerRef, getPlaceholderConfig, update } = useEditorContext();
  const [dialogOpen, setDialogOpen] = useState(false);

  const closeDialog = useCallback(() => setDialogOpen(false), []);
  const openDialog = useCallback(() => setDialogOpen(true), []);
  const placeholderConfigs = getPlaceholderConfig();

  const handleOpenDialog = useCallback(
    (event) => {
      event.preventDefault();

      selectionCacheRef.current = selection;
      openDialog();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selection],
  );

  const handleCreate = useCallback(
    async (title) => {
      closeDialog();

      try {
        if (selectionCacheRef.current) select(editor, selectionCacheRef.current);

        const result = await update({
          type: actionTypes.CREATE_PLACEHOLDER,
          payload: { document: editor.children, title },
        });

        normalizeOnListFocus(editor);
        const element = {
          type: elementTypes.PLACEHOLDER,
          data: {
            ...result,
            mTitle: title,
            itemDuration: 0,
            itemType: 'video',
            mediaType: 'video/placeholder',
          },
          children: [{ text: '' }],
        };

        insertNodes(editor, element);
        notifyChange(editor, update);
      } catch (error) {
        // logger.log(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleCancel = useCallback(() => {
    if (selectionCacheRef.current) select(editor, selectionCacheRef.current);
    closeDialog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={classes.icon}>
        <HourglassIcon
          title={tooltipEnum.PLACEHOLDER}
          alt="Insert Placeholder"
          onMouseDown={handleOpenDialog}
        />
      </div>
      <Dialog container={containerRef.current} open={dialogOpen} onClose={handleCancel}>
        <CreateNew
          variant="placeholder"
          onCreate={handleCreate}
          onCancel={handleCancel}
          placeholderNameConfigs={generatePlaceholderProps(placeholderConfigs)}
        />
      </Dialog>
    </>
  );
};

export default memo(PlaceholderButton);
