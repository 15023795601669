import { useQuery } from '@apollo/client';

import { GET_ORDERING } from './gql';
import { Ordering, OrderingEnum } from './types';

export interface GetOrdering {
  getOrdering: Ordering;
}

export const useGetOrdering = (type: OrderingEnum) => {
  const { data, loading } = useQuery<GetOrdering>(GET_ORDERING, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        type,
      },
    },
  });

  return {
    ordering: data?.getOrdering ?? null,
    loading,
  };
};
