import React, { useState, useEffect, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import useInputEvents from 'hooks/useInputEvents';
import stopEventPropagation from 'utils/stopEventPropagation';
import { Input, TextArea } from './styled';

const InputBase = ({ className, onUpdate, type, value, placeholder }) => {
  const [localValue, setLocalValue] = useState(value);

  const update = useCallback(
    (newValue) => {
      if (newValue === value) setLocalValue(newValue);
      else onUpdate(newValue);
    },
    [onUpdate, value],
  );

  const [inputRef, onKeyDown, onBlur] = useInputEvents(update, localValue, value);

  useEffect(() => setLocalValue(value), [value]);

  const onChange = useCallback((event) => {
    setLocalValue(event.target.value);
  }, []);

  if (type === 'input')
    return (
      <Input
        ref={inputRef}
        value={localValue}
        className={className}
        onMouseDown={stopEventPropagation}
        onBlur={onBlur}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
      />
    );

  return (
    <TextArea
      ref={inputRef}
      value={localValue}
      className={className}
      onMouseDown={stopEventPropagation}
      onChange={onChange}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
    />
  );
};

InputBase.propTypes = {
  /** CSS class handle */
  className: PropTypes.string,
  /** Callback to be invoked on value update */
  onUpdate: PropTypes.func,
  /** Type for the text input */
  type: PropTypes.oneOf(['input', 'textarea']),
  /** Value for the input */
  value: PropTypes.string,
  placeholder: PropTypes.string,
};

InputBase.defaultProps = {
  className: '',
  onUpdate: (newValue) => {},
  type: 'input',
  value: '',
  placeholder: '',
};

export default memo(InputBase);
