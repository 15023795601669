import { useMemo, useRef } from 'react';
import { type Table as TableType } from '@tanstack/react-table';

import { Table } from 'components/dataTable/Table';
import { DataTableHeader } from 'features/grids/common/components/TableHeader';
import { parseCellId } from 'features/grids/common/utils/cellUtils';
import { ReorderRows } from 'features/grids/widget/components/dnd/ReorderRows';
import { DndBody } from 'features/grids/widget/components/dnd/TableDndBody';
import { ParsedMemberType } from 'types';

interface DataTableProps {
  table: TableType<ParsedMemberType>;
  boardId: string;
  groupId?: string;
  itemIds?: string[];
}

/** Sets up a data table with drag and drop support */
export function GridWidgetDataTable({
  table,
  boardId,
  groupId,
  itemIds,
}: Readonly<DataTableProps>) {
  const virtualizationContainer = useRef<HTMLDivElement>(null);

  const { columnSizing, columnSizingInfo, columnVisibility } = table.getState();
  const columns = table.options.columns;

  const columnSizeVars = useMemo(() => {
    const headers = table.getFlatHeaders();
    const colSizes: { [key: string]: number } = {};
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i];
      const colSize = header.getSize();
      colSizes[`--header-${parseCellId(header.id)}-size`] = colSize;
      colSizes[`--col-${parseCellId(header.column.id)}-size`] = colSize;
    }
    return colSizes;
  }, [columns, columnSizing, columnSizingInfo, columnVisibility]);

  return (
    <ReorderRows itemIds={itemIds} tableId={boardId} itemGroupId={groupId}>
      <div
        ref={virtualizationContainer}
        style={{
          overflow: 'auto',
          position: 'relative',
          height: '100%',
          width: '100%',
        }}
      >
        <Table style={{ ...columnSizeVars }}>
          <DataTableHeader table={table} />
          <DndBody
            table={table}
            itemIds={itemIds}
            boardId={boardId}
            groupId={groupId}
            containerRef={virtualizationContainer}
          />
        </Table>
      </div>
    </ReorderRows>
  );
}
