import { Editor, Element } from 'slate';

import { ActionTypesEnum } from 'components/editor/constants/types/actionTypes';
import primaryTypeValues from 'components/editor/constants/types/primaryTypeValues';
import { Update } from 'components/editor/types';
import { CustomElement } from 'types';

/**
 * Handles onKeyDown event on primary automation elements
 *
 * @param editor SlateJS editor instance
 * @param event React synthetic event
 * @param update Callback to be invoked on update
 * @returns SlateJS editor instance
 */

const onPrimaryKeyDown = (
  editor: Editor,
  event: React.KeyboardEvent<HTMLDivElement>,
  update?: Update,
) => {
  const [match] = Editor.nodes<CustomElement>(editor, {
    match: (node) => Element.isElement(node) && primaryTypeValues.includes(node.type),
  });

  if (match) {
    const { key } = event;
    const isBackspace = key === 'Backspace';

    if (isBackspace) {
      const [removedElement] = match;

      update?.({
        type: ActionTypesEnum.AUTOMATION_REMOVE,
        payload: { document: editor.children as CustomElement[], removedElement },
      })?.then(
        () => {},
        () => {},
      );
    }
  }

  return editor;
};

export default onPrimaryKeyDown;
