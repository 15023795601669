import gql from 'graphql-tag';

export default gql`
  query GetAllRundownTemplates($mId: String) {
    getAllRundownTemplates(mId: $mId) {
      mId
      mRefId
      mOrder
      mTitle
      mCoverPhotoUrl
      mCoverPhotoKey
      mThumbnailKey
      mType
      version
      platformKind
      metadata
      mdfId
      recurrence {
        duration
        startTime
        timeZone
        dailyExclusive
      }
      permissions {
        read
        write
      }
    }
  }
`;
