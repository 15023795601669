import { useMemo } from 'react';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import { useGetBlocks } from 'api/mdfBlocks/useGetMdfBlocks';
import { groomMemberMetadata } from 'features/grids/common/hooks/useGroomData';
import { Resource } from 'hooks/useResourceDetails';
import { useEditorCommands } from 'store';
import { ParsedMemberType } from 'types';

import { DataTableFilterField } from '../common/types';

import { PlanningGrid } from './PlanningGrid';

type PlanningContainerProps = {
  resourceId: string;
  resource: Resource;
};

/** Fetch data, groom it and fetch mdfs */
export function PlanningGridContainer({ resourceId, ...rest }: Readonly<PlanningContainerProps>) {
  const { blocks, refetch } = useGetBlocks(resourceId);
  const [editorCommands] = useEditorCommands();

  const {
    mdfsSeparated: { custom: mdfs },
  } = useGetMdfs({ all: true });

  const mdfInfusedBlocks = groomMemberMetadata(blocks, mdfs);
  const mergedWithCommands = mdfInfusedBlocks.map((block) => ({
    ...block,
    commandData: editorCommands?.find((c) => c.mRefId === block.mSecId),
  }));

  const uniqueValues = useMemo(
    () => Array.from(new Set(mergedWithCommands.map((block) => block.commandData?.mTitle))),
    [mergedWithCommands],
  );

  // @ts-expect-error need to fix merged type..
  const filterFields: DataTableFilterField<ParsedMemberType>[] = useMemo(
    () => [
      {
        label: 'Title',
        value: 'mTitle',
        placeholder: 'Type to find...',
      },
      {
        label: 'Type',
        value: 'commandData_mTitle',
        options: uniqueValues.map((value) => ({
          label: value,
          value,
          withCount: true,
        })),
      },
    ],
    [mergedWithCommands],
  );

  return (
    <PlanningGrid
      blocks={mergedWithCommands}
      mdfs={mdfs}
      refetch={refetch}
      filterFields={filterFields}
      {...rest}
    />
  );
}
