/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Select as MaterialSelect,
  OutlinedInput,
  InputBase,
  MenuItem,
  InputLabel,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import Divider from 'components/divider';
import { ReactComponent as ArrowDoubleIcon } from 'assets/icons/systemicons/arrows/arrow_double_unboxed.svg';
import { ReactComponent as CheckSrc } from 'assets/icons/systemicons/check.svg';
import useStyles from './select-styles';

const Select = ({
  items,
  selectedValue,
  label,
  onChange,
  usage,
  hideLabel,
  placeholder,
  disabled,
  open,
  ...rest
}) => {
  const isDatePicker = usage === 'datePicker';
  const isEditor = usage === 'editor';
  const isDetails = usage === 'details';
  const isNotes = usage === 'notes';
  const classes = useStyles({ isEditor, isDatePicker, isNotes });
  const isGridView = usage === 'gridView';

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  const renderValue = () => {
    if (!selectedValue && selectedValue !== 0)
      return placeholder ? <div className={classes.title}>{placeholder}</div> : '—';

    const selectedItem = items.find(({ value }) => value === selectedValue);

    return selectedItem ? (
      <MenuItem
        disableGutters
        disabled={disabled}
        classes={{
          root: classes.menuItem,
          selected: classes.menuItemSelected,
        }}
        ListItemClasses={{
          button: classes.buttonHoverTransparent,
        }}
      >
        {selectedItem.icon && (
          <ListItemIcon classes={{ root: classes.listItemIcon }}>{selectedItem.icon}</ListItemIcon>
        )}
        <ListItemText
          primary={selectedItem?.selectionTitle || selectedItem.title}
          classes={{
            primary: isGridView || isDatePicker || isNotes ? classes.title : '',
          }}
        />
      </MenuItem>
    ) : (
      <MenuItem
        disableGutters
        disabled={disabled}
        classes={{
          root: classes.menuItem,
          selected: classes.menuItemSelected,
        }}
        ListItemClasses={{
          button: classes.buttonHoverTransparent,
        }}
      >
        <ListItemText
          primary="—"
          classes={{
            primary: isGridView || isDatePicker || isNotes ? classes.title : '',
          }}
        />
      </MenuItem>
    );
  };

  const selectMenuItem = (value, title, icon, info, itemDisabled) => (
    <MenuItem
      {...{ value }}
      key={value}
      selected={selectedValue === value}
      classes={{
        root: classes.menuItem,
        selected: classes.menuItemSelected,
        // primary: classes.publishTitle,
      }}
      ListItemClasses={{
        button: classes.buttonHoverOverlay,
      }}
      data-testid={`input-${value}`}
      disabled={itemDisabled}
    >
      {icon && (
        <ListItemIcon classes={{ root: classes[isEditor ? 'listItemIconEditor' : 'listItemIcon'] }}>
          {icon}
        </ListItemIcon>
      )}
      <ListItemText
        primary={title}
        classes={{ primary: isGridView ? classes.title : classes.publishTitle }}
      />
      {info && (
        <Typography classes={{ root: classes.infoText }} align="right">
          {info}
        </Typography>
      )}
      {(isDatePicker || isNotes) && selectedValue === value && (
        <div className={classes.check}>
          <CheckSrc />
        </div>
      )}
    </MenuItem>
  );

  return (
    <div {...rest}>
      {!isEditor && !isGridView && !isDatePicker && !isNotes && !hideLabel && (
        <InputLabel
          htmlFor={label}
          classes={{
            root: classes.inputLabel,
          }}
        >
          {label}
        </InputLabel>
      )}

      <MaterialSelect
        open={open}
        value={selectedValue || ''}
        onChange={handleChange}
        disabled={disabled}
        classes={{
          icon: classes.gridSelectIcon,
          root: classes.root,
          select: classes.select,
        }}
        IconComponent={ArrowDoubleIcon}
        {...{ renderValue }}
        displayEmpty
        input={
          isDetails ? (
            <InputBase
              fullWidth
              name={label}
              id={label}
              classes={{
                root: classes.outlinedInput,
                focused: classes.focused,
                disabled: classes.disabled,
                error: classes.error,
              }}
            />
          ) : (
            <OutlinedInput
              fullWidth
              name={label}
              labelWidth={0}
              id={label}
              classes={{
                root: isGridView ? classes.modifiedOutlinedInput : classes.outlinedInput,
                notchedOutline: classes.notchedOutline,
                input: isGridView ? classes.input : '',
                focused: classes.focused,
                disabled: classes.disabled,
                error: classes.error,
              }}
            />
          )
        }
        MenuProps={{
          classes: {
            paper: classes.menu,
          },
        }}
        variant="standard"
      >
        {items && items.length > 0 ? (
          items.map(({ value, title, icon, info, showTopDivider, disabled: itemDisabled }) =>
            showTopDivider ? (
              <div {...{ value }} key={value}>
                <Divider className={classes.divider} />
                {selectMenuItem(value, title, icon, info, itemDisabled)}
              </div>
            ) : (
              selectMenuItem(value, title, icon, info, itemDisabled)
            ),
          )
        ) : (
          <MenuItem
            value=""
            classes={{
              root: classes.menuItem,
              selected: classes.menuItemSelected,
            }}
          >
            No options available
          </MenuItem>
        )}
      </MaterialSelect>
    </div>
  );
};

Select.propTypes = {
  /** Items that should be rendered as select options. */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      title: PropTypes.string,
      icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      info: PropTypes.string,
      showTopDivider: PropTypes.bool,
    }),
  ),
  /** Currently selected option's value. */
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** usage of the select input: default or gridView */
  usage: PropTypes.string,
  /** Label for the select. */
  label: PropTypes.string,
  /** Callback to be invoked when an option is selected.
   * Gets the currently selected value as argument.
   */
  onChange: PropTypes.func,
  /** Hides the input label */
  hideLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  /** controls the opening of the options */
  open: PropTypes.bool,
};

Select.defaultProps = {
  items: [],
  selectedValue: '',
  usage: 'default',
  label: 'label',
  onChange: () => {},
  hideLabel: false,
  disabled: false,
  open: undefined,
};

export default Select;
