import { useCallback } from 'react';
import { ApolloCache, useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

import { MemberType } from 'types/graphqlTypes';

import { getCachedBlocks, writeBlocksToCache } from './useCreateMdfBlock';

interface DeleteBlock {
  deleteSingleMember: Pick<MemberType, 'mId' | 'mRefId'>;
}

const DELETE_SINGLE_MEMBER = gql`
  mutation DeleteBlock($input: DeleteSingleMemberInput) {
    deleteSingleMember(input: $input) {
      mId
      mRefId
    }
  }
`;

export const deleteFromCache = (
  proxy: ApolloCache<unknown>,
  deletedBlock: Pick<MemberType, 'mId' | 'mRefId'>,
) => {
  const cachedBlocks = getCachedBlocks(proxy, deletedBlock);

  const alreadyExists = cachedBlocks?.find((block) => block.mRefId === deletedBlock.mRefId);
  if (!alreadyExists) return;

  const updatedBlocks = cachedBlocks.filter((block) => block.mRefId !== deletedBlock.mRefId);
  writeBlocksToCache(proxy, deletedBlock, updatedBlocks);
};

const useDeleteBlock = () => {
  const [deleteSingleMember] = useMutation<DeleteBlock>(DELETE_SINGLE_MEMBER);

  const deleteBlock = useCallback(async (keys: { mId: string; mRefId: string }) => {
    await deleteSingleMember({
      variables: { input: keys },
      update: (proxy, mutationResult) => {
        const deletedBlock = mutationResult.data?.deleteSingleMember;
        if (deletedBlock) deleteFromCache(proxy, deletedBlock);
      },
    });
  }, []);

  return { deleteBlock };
};

export default useDeleteBlock;
