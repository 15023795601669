import { useEffect, useState } from 'react';

import Dialog from 'components/dialogs/DialogBuilder';
import DraggableDialog from 'components/dialogs/Draggable';
import Tabs from 'components/tabs/contained';
import OrderGridFull from 'features/orderForm/components/OrderGridFull';
import { useMyOrdersDialog, useMyOrdersOpenDialog } from 'store';
import { GetOrderEnum } from 'types/graphqlTypes';

import { ContentWrapper, GridWrapper } from './styled';

type TabValues = 'assigned' | 'owned';

export function getQueryType(selected: TabValues): GetOrderEnum {
  return selected === 'assigned' ? GetOrderEnum.Assignee : GetOrderEnum.Owner;
}

interface Tab {
  label: string;
  id: TabValues;
}

const tabs: Tab[] = [
  { label: 'Assigned to me', id: 'assigned' },
  { label: 'Owned by me', id: 'owned' },
];

function MyOrdersDialog() {
  const [showAssigned, setShowAssigned] = useState<TabValues>('assigned');
  const [queryType, setQueryType] = useState<GetOrderEnum>(getQueryType('assigned'));
  const [dimension, setDimension] = useState({ width: '80vw', height: '70vh' });

  const [open, setOpen] = useMyOrdersOpenDialog();
  const [state] = useMyOrdersDialog();

  useEffect(() => {
    setQueryType(getQueryType(showAssigned));
  }, [showAssigned, state]);

  const onClose = () => {
    setShowAssigned('assigned');
    setOpen(false);
  };

  const onTabChange = (label: string) => {
    const selectedTab = tabs.find((t) => t.label === label) as Tab;
    setShowAssigned(selectedTab.id);
  };

  const tabLabels = tabs.map((tab) => tab.label);
  const activeTab = showAssigned === 'assigned' ? tabLabels[0] : tabLabels[1];

  const initialWidth = window.innerWidth * 0.1;
  const initialHeight = window.innerHeight * 0.15;

  const initialPosition = { x: initialWidth, y: initialHeight };

  return (
    <DraggableDialog
      open={open}
      onClose={onClose}
      dimension={dimension}
      setDimension={setDimension}
      initialPosition={initialPosition}
    >
      <Dialog.Header className={'dragHandler'}>Your order overview</Dialog.Header>
      <Dialog.Body>
        <ContentWrapper>
          <GridWrapper>
            <OrderGridFull
              resourceId={state.resourceId}
              resourceType={queryType}
              closeDialog={onClose}
              doSubscribe={showAssigned !== 'assigned'}
              headerSlot={
                <Tabs
                  tabs={tabLabels}
                  setActiveTab={onTabChange}
                  activeTab={activeTab}
                  styleProps={{ fullWidth: true }}
                />
              }
            />
          </GridWrapper>
        </ContentWrapper>
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton onCancel={onClose} label="Close" />
      </Dialog.Footer>
    </DraggableDialog>
  );
}

export { MyOrdersDialog };
