import { memo } from 'react';
import styled from '@emotion/styled';
import Checkbox from '@material-ui/core/Checkbox';

import PriorityMinimal from 'components/priorityLabel';

import { PriorityFilterWrapper } from './styled';

const StyledCheckbox = styled(Checkbox)`
  padding: 4px;
  transform: scale(1.25);
  &:first-of-type {
    margin-left: -2px;
  }
`;

const PRIORITIES = [1, 2, 3, 4, 5, 6];

interface PriorityFilterProps {
  priorityValues?: string[];
  onChange: (val: { name: string; value: string[] }) => void;
}

function PriorityFilter({ priorityValues = [], onChange }: Readonly<PriorityFilterProps>) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Remove the value if it exists
    if (priorityValues?.includes(event.target.name)) {
      const newValues = priorityValues.filter((value) => value !== event.target.name);
      const noneSelected = newValues.length === 0;

      return onChange({
        name: 'priorities',
        value: noneSelected ? [] : newValues,
      });
    }

    // Add the value if it doesn't exist
    const newValues = [...(priorityValues ?? []), event.target.name];
    const allSelected = newValues.length === PRIORITIES.length;

    return onChange({
      name: 'priorities',
      value: allSelected ? [] : newValues,
    });
  };

  const isStateEmpty = !priorityValues || priorityValues?.length <= 0;

  return (
    <PriorityFilterWrapper>
      {PRIORITIES.map((value) => (
        <StyledCheckbox
          key={value}
          checked={isStateEmpty || priorityValues.includes(value.toString())}
          icon={<PriorityMinimal noColor priorityLevel={value.toString()} type="minimal" />}
          checkedIcon={<PriorityMinimal priorityLevel={value.toString()} type="minimal" />}
          name={value.toString()}
          onChange={handleChange}
        />
      ))}
    </PriorityFilterWrapper>
  );
}

export default memo(PriorityFilter);
