import memberTypes from 'operations/memberTypes';
import checkImage from 'assets/icons/systemicons/status/ready_small.svg';
import conversationTypes from 'utils/constants/conversationTypes';
import errorIcon from 'assets/icons/systemicons/error_alt.svg';
import breakingIcon from 'assets/icons/systemicons/breaking-prio.svg';

export const extractMessageFromSlateObj = (content) => {
  try {
    const msg = JSON.parse(content);
    const { document = [] } = msg || {};
    const { children = [] } = document[0] || {};
    const { text = '' } = children[0] || {};
    if (text) return text.replace(/(\r\n|\n|\r)/gm, ', ')?.replaceAll(', ,', ', ') ?? content;
    return content;
  } catch (err) {
    return content;
  }
};

export const extractNotificationProperties = (content) => {
  try {
    const msg = JSON.parse(content);
    const {
      properties: {
        instance: instanceId = '',
        story,
        title: alertTitle = '',
        text = '',
        highlightedText = '',
        variant: directMessageVariant = '',
        mAvatarUrl,
        isTemplateInstance,
        mTemplateId,
        mId,
        mRefId,
        mType,
        mResourceId,
        mResourceType,
      } = {},
    } = msg || {};

    return {
      instanceId,
      storyId: mResourceType === 'story' || mResourceType === 'pitch' ? mId : story,
      alertTitle,
      mAvatarUrl,
      highlightedText,
      directMessageVariant,
      text,
      isTemplateInstance,
      mTemplateId,
      mId,
      mRefId,
      mType,
      mResourceId,
      mResourceType,
    };
  } catch (error) {
    return {};
  }
};

export const getAlertTitle = ({ mType, updatedBy, convoType }) => {
  switch (mType) {
    case memberTypes.CONVERSATION:
      return `Added to conversation by ${updatedBy}`;
    case memberTypes.MESSAGE:
      return `${convoType === conversationTypes.MENTION ? 'Mention' : 'Message'} from ${updatedBy}`;
    case memberTypes.STORY:
      return 'Story assignment';
    case memberTypes.PITCH:
      return 'Pitch assignment';
    case memberTypes.INSTANCE:
      return 'Instance assignment';
    case memberTypes.FEED:
      return 'Feed item added successfully!';
    case 'breaking':
      return 'Breaking news';
    case 'error':
      return 'Something went wrong';
    default:
      return '';
  }
};

export const getAlertAvatar = ({ mType, mUpdatedById, avatar }, getUser) => {
  switch (mType) {
    case memberTypes.CONVERSATION:
    case memberTypes.MESSAGE: {
      const user = getUser(mUpdatedById);
      const { mAvatarKey } = user || {};
      return mAvatarKey;
    }
    case memberTypes.STORY:
    case memberTypes.PITCH:
      return avatar;
    case memberTypes.FEED:
    case memberTypes.INSTANCE:
      return checkImage;
    case 'breaking':
      return breakingIcon;
    case 'error':
      return errorIcon;

    default:
      return undefined;
  }
};

export const openMessageHub = (notificationObject, openConversation, openContentFunc) => {
  const { mId, mType } = notificationObject;
  switch (mType) {
    case memberTypes.CONVERSATION:
    case memberTypes.TEAM:
    case memberTypes.DEPARTMENT:
    case memberTypes.MESSAGE: {
      openConversation(mId);
      break;
    }
    case memberTypes.STORY:
    case memberTypes.PITCH:
    case memberTypes.FEED:
    case memberTypes.INSTANCE: {
      openContentFunc(notificationObject);
      break;
    }
    default:
  }
};
