import { Editor, Element } from 'slate';

import { elementTypes } from 'components/editor/constants/types';
import { CustomElement } from 'types';

import { getNodesFromSelection, hasRestrictedBlock } from './ElementKeyDownUtils';

const elementTypeValues = Object.values(elementTypes);

const onRestrictedElementKeyDown = (
  editor: Editor,
  event: React.KeyboardEvent<HTMLDivElement>,
  showDeleteAllDialog: () => void,
) => {
  const [match] = Editor.nodes<CustomElement>(editor, {
    match: (node) => Element.isElement(node) && elementTypeValues.includes(node.type),
  });

  if (match) {
    const { key } = event;
    const isBackspace = key === 'Backspace';
    const isDelete = key === 'Delete';

    if (isBackspace || isDelete) {
      const selectedNodes = getNodesFromSelection(editor);
      if (selectedNodes.length && hasRestrictedBlock(selectedNodes)) {
        showDeleteAllDialog();
      }
    }
  }

  return editor;
};

export default onRestrictedElementKeyDown;
