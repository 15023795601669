import Tooltip from 'components/tooltip';

interface ButtonProps {
  title: string;
  children: React.ReactElement;
  disabled: boolean;
  disabledTitle?: string;
}

export function ButtonWrapper({ title, disabledTitle, children, disabled }: Readonly<ButtonProps>) {
  const tooltipTitle = disabledTitle && disabled ? disabledTitle : title;

  return (
    <Tooltip title={tooltipTitle} hide={!tooltipTitle}>
      {disabled && disabledTitle ? <span>{children}</span> : children}
    </Tooltip>
  );
}
