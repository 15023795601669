import React, { memo, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelected, useFocused } from 'slate-react';
import UserContext from 'contexts/UserContext';
import { elementTypes } from 'components/editor/constants/types';
import accessibleOnClick from 'utils/accessibleOnClick';
import { RootWrapper } from './styled';
import { useSetPreview } from 'store/preview';

const Mention = ({ attributes, children, element }) => {
  const { mId: userId } = useContext(UserContext);
  const setPreview = useSetPreview();
  const selected = useSelected();
  const focused = useFocused();
  const { mId, mTitle } = element?.data ?? {};

  const handleOpenItem = useCallback(() => {
    if (mId) {
      setPreview(mId);
    }
  }, [mId]);

  return (
    <RootWrapper {...attributes} showBorder={selected && focused} isCurrentUser={mId === userId}>
      <span {...accessibleOnClick(handleOpenItem, 'presentation')}>@{mTitle}</span>
      {children}
    </RootWrapper>
  );
};

Mention.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({
    data: PropTypes.shape({
      mId: PropTypes.string,
      mTitle: PropTypes.string,
    }),
  }),
};

Mention.defaultProps = {
  attributes: {},
  children: null,
  element: {
    type: elementTypes.MENTION,
    data: { mTitle: '' },
    children: [],
  },
};

export default memo(Mention);
