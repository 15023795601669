import { useEffect } from 'react';

import { Drawer, DrawerContent } from 'lib/Drawer';

import { VesselProps } from '../VesselFactory';

export enum PreviewComponents {
  DRAWER = 'drawer',
}

export function DrawerPreview({ children, open, onClose }: Readonly<VesselProps>) {
  // Close drawer when escape key is pressed.
  useEffect(() => {
    const onEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') onClose();
    };
    window.addEventListener('keydown', onEscape);
    return () => window.removeEventListener('keydown', onEscape);
  }, [onClose]);

  return (
    <Drawer direction="right" open={open} onClose={onClose} modal={false} dismissible={false} fixed>
      <DrawerContent>{children}</DrawerContent>
    </Drawer>
  );
}
