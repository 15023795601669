import Checkbox from 'components/checkbox';
import { useChangedRows } from 'screens/main/components/header/navbar/settings/atomsTs';
import { GroupPermissionsRowPropType, PermissionType } from 'types/groupPermissions/permissions';

import { CheckboxCell, GroupPermissionTableRow, PermissionCell } from './styled';

const GroupPermissionsRow = (props: GroupPermissionsRowPropType) => {
  const { row, selectedGroupRefId } = props;
  const [changedRows, setChangedRows] = useChangedRows();
  const isChanged = (id: string) =>
    changedRows.find((changed: PermissionType) => changed.id === id);

  const setRows = (rows: PermissionType[]) => {
    setChangedRows(rows);
  };

  const onCheck = () => {
    // If rows is already in changed rows then remove it
    if (isChanged(row.id)) {
      setRows(changedRows.filter((changedRow) => changedRow.id !== row.id));
    } else {
      setRows([
        ...changedRows,
        {
          permission: row.permission,
          resourceName: row.resourceName,
          id: row.id,
          action: row.action,
          access: !row.access,
          groupRefId: selectedGroupRefId,
        },
      ]);
    }
  };

  return (
    <GroupPermissionTableRow onClick={onCheck} key={row.permission}>
      <CheckboxCell>
        <Checkbox selected={isChanged(row.id) ? !row.access : row.access} size={24} />
      </CheckboxCell>
      <PermissionCell>{row.permission}</PermissionCell>
    </GroupPermissionTableRow>
  );
};

export default GroupPermissionsRow;
