import { useEffect, forwardRef, useRef } from 'react';
import { useDrag } from 'react-dnd';
import DndTypes from 'utils/dndTypes';
import useGetAssignedMembers from 'hooks/useGetAssignedMembers';
import useDinaNavigate from 'hooks/useDinaNavigate';
import useSelectRundownInstance from 'hooks/useSelectRundownInstance';
import useMouseClickEvents from 'hooks/useMouseClickEvents';
import useCheckUserRight from 'hooks/useCheckUserRight';
import { CustomFields } from 'utils/metadata';
import publishingPoints from 'components/instanceCard/utils/publishingPoints';
import KanbanCard from 'components/kanbanCard';
import getIdentifier from 'utils/instance/getAccountIdentifier';
import { defaultLinearPlatformKind } from 'utils/instance/platform';

import { ListItem } from './listItem-styled';
import { usePreviewValue, useSetPreview, useClosePreview } from 'store/preview';

export const InstanceItemVariant = {
  INSTANCE_LIST_ITEM: 'instance_list_item',
  CREATE_LINK_LIST_ITEM: 'create_link_list_item',
};

const getMetaDataValue = (mMetaData, key) => {
  const foundMetaData = (mMetaData || []).find((metaData) => metaData.key === key);
  return foundMetaData?.value;
};

const InstanceItem = (props, ref) => {
  const {
    instance,
    isInstanceItemSelected,
    setOpenPreviewIndex,
    setCurrentFocusedIndex,
    index,
    onSelectInstance,
    variant,
    disabled,
  } = props;
  const {
    mId,
    mTitle: title,
    items,
    mProperties,
    mUpdatedAt,
    mState,
    mPublishingAt,
    mRelatedMembers,
    mAssignedMembers,
    mStoryId,
    isTemplateInstance,
    mTemplateId,
    mMetaData,
  } = instance;

  const blockTitle = getMetaDataValue(mMetaData, 'block_title');

  const { platform: instanceType, account = {}, platformKind } = mProperties ?? {};
  const storyTitle = getMetaDataValue(mMetaData, CustomFields.STORY_TITLE);

  const { accountTitle, accountId } = account;
  const accountIdentifier = getIdentifier(instanceType, accountTitle);
  const [checkUserRight] = useCheckUserRight();
  const hasPermissionForPlatform = checkUserRight('platform', accountIdentifier);
  const canScheduleInstance = checkUserRight('instance', 'schedule') && hasPermissionForPlatform;

  const isInstanceItemVariant = variant === InstanceItemVariant.INSTANCE_LIST_ITEM;
  const [getAssignedMembers] = useGetAssignedMembers(mAssignedMembers || []);
  const preview = usePreviewValue();
  const setPreview = useSetPreview();
  const closePreview = useClosePreview();

  const { navigateTo } = useDinaNavigate();
  const { selectRundownInstance } = useSelectRundownInstance();

  const isOpenStoryDisabled = Boolean(isTemplateInstance || mTemplateId);

  const handleOpenStory = (event) => {
    if (preview) closePreview();

    if (isOpenStoryDisabled) {
      handleOpenRundown();
      return;
    }
    navigateTo('story', mStoryId, {
      tab: 'instances',
      entityId: mId,
    });
  };

  const [assignedUsers, assignedTeams, assignedDepartments] = getAssignedMembers();
  const assignedMembers = [...assignedUsers, ...assignedTeams, ...assignedDepartments];

  const destination = account?.accountTitle;

  const handleOpenRundown = () => {
    if (instanceType === publishingPoints.LINEAR && destination !== 'Unassigned' && accountId) {
      navigateTo(isTemplateInstance ? 'rundowntemplate' : 'rundown', accountId);
      selectRundownInstance([mId]);
    }
  };

  const payload = {
    id: mId,
    platform: instanceType,
    publishingAt: mPublishingAt,
    statusId: mState,
    accountId: account.accountId,
    accountRefId: account.accountRefId,
    platformKind: platformKind || defaultLinearPlatformKind,
    ...instance,
  };

  const [{ isDragging }, dragRef] = useDrag({
    canDrag: () => canScheduleInstance,
    type: DndTypes.INSTANCE_LIST_ITEM,
    item: { type: DndTypes.INSTANCE_LIST_ITEM, payload },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const isDraggingRef = useRef(isDragging);

  useEffect(() => {
    isDraggingRef.current = isDragging;
  }, [isDragging]);

  const handleClick = () => {
    if (!isDraggingRef.current) {
      setPreview(instance);
      setCurrentFocusedIndex(index);
      setOpenPreviewIndex(index);
    }
  };

  const handleClickInstance = (event) => {
    if (!isDraggingRef.current) {
      if (isInstanceItemVariant) handleClick();
      else onSelectInstance(event, index, mId);
    }
  };

  useEffect(() => {
    if (!isInstanceItemVariant) return;

    if (isInstanceItemSelected && !preview) {
      setCurrentFocusedIndex(null);
      setOpenPreviewIndex(null);
      return;
    }

    if (isInstanceItemSelected && preview.mId !== instance.mId) {
      setPreview(instance);
      setCurrentFocusedIndex(index);
      setOpenPreviewIndex(index);
    }
  }, [isInstanceItemSelected, isInstanceItemVariant, preview, instance, setPreview]);

  const [onClick, onDoubleClick] = useMouseClickEvents(handleClickInstance, handleOpenStory);

  return (
    <ListItem
      button
      onMouseDown={onClick}
      onDoubleClick={onDoubleClick}
      onContextMenu={(e) => e.preventDefault()}
      ref={isInstanceItemVariant ? dragRef : null}
      disabled={disabled}
      selected={isInstanceItemSelected && !disabled}
      $isInstanceItemVariant={isInstanceItemVariant}
    >
      <KanbanCard
        destination={destination}
        mRelatedMembers={mRelatedMembers}
        updatedAt={mUpdatedAt}
        storyTitle={storyTitle}
        blockTitle={blockTitle}
        title={title}
        mPublishingAt={mPublishingAt}
        items={items}
        mState={mState}
        assignedUsers={assignedMembers}
        platform={instanceType}
        platformKind={platformKind}
        canOpenDestination={
          instanceType === publishingPoints.LINEAR && destination !== 'Unassigned'
        }
        openDestination={handleOpenRundown}
      />
    </ListItem>
  );
};

const forwardedInstanceItem = forwardRef(InstanceItem);
export default forwardedInstanceItem;
