/* eslint-disable sort-imports */
import { useMemo, useState } from 'react';
import { Table, TableBody, TableHead, TableRow } from '@material-ui/core';

import { LayoutSettings, MdfField, Views, ViewTypes } from 'types/graphqlTypes';

import { ConfigColors } from './ConfigColors';
import { Column, HeaderCell, Root } from './EditFieldModel';
import { FieldSettingsRow } from './FieldSettingsRow';

interface Props {
  fields: MdfField[];
  views: Views;
  selectedView: ViewTypes;
  onUpdateSettings: (settings: LayoutSettings, view: ViewTypes) => void;
}

const columns: Column[] = [
  {
    id: 'fieldId',
    name: 'Field id',
  },
  {
    id: 'visible',
    name: 'Visible',
  },
  {
    id: 'label',
    name: 'Label',
  },
  {
    id: 'tooltip',
    name: 'Tooltip',
  },
  {
    id: 'additional',
    name: 'More options',
  },
];

export function EditFieldLayout({
  fields,
  views,
  selectedView,
  onUpdateSettings,
}: Readonly<Props>) {
  const [fieldToColorConfig, setFieldToColorConfig] = useState<MdfField | null>(null);

  const settingsToConfigure = useMemo(() => {
    if (!fieldToColorConfig) return null;
    return views[selectedView].find((s) => s.fieldId === fieldToColorConfig.fieldId);
  }, [fieldToColorConfig, selectedView]);

  return (
    <Root>
      <Table aria-label="mdf-table">
        <TableHead key="mdf-table-head">
          <TableRow key="mdf-header">
            {columns.map((col) => (
              <HeaderCell key={col.id}>{col.name}</HeaderCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody key="studio-table-body">
          {fields.map((field) => (
            <FieldSettingsRow
              key={field.fieldId}
              field={field}
              onUpdateSettings={onUpdateSettings}
              defaultFieldSettings={views.default.find(
                (settings) => settings.fieldId === field.fieldId,
              )}
              fieldSettings={views[selectedView].find(
                (settings) => settings.fieldId === field.fieldId,
              )}
              selectedView={selectedView}
              onDoColorConfig={() => setFieldToColorConfig(field)}
            />
          ))}
        </TableBody>
      </Table>
      {settingsToConfigure && (
        <ConfigColors
          open={Boolean(fieldToColorConfig)}
          setOpen={() => setFieldToColorConfig(null)}
          settings={settingsToConfigure}
          onUpdateSettings={(updated: LayoutSettings) => onUpdateSettings(updated, selectedView)}
          alternatives={fieldToColorConfig?.alternatives ?? []}
          optionListId={fieldToColorConfig?.optionListId}
        />
      )}
    </Root>
  );
}
