import { useCallback, useMemo, useState } from 'react';
import { atom, useAtom } from 'jotai';

import { ReactComponent as ArrowDown } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_down.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_up.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/systemicons/close_small.svg';
import { IconButton } from 'components/buttons';
import Dialog from 'components/dialogs/DialogBuilder';
import { createTree } from 'components/editMdfDialog/utils';
import { StyledTextField } from 'components/mdfEditor/fields/text/styled';
import Text from 'components/text/Text';

import TreeView from './TreeView';

import {
  CountText,
  CurrentChoice,
  SearchArrowWrapper,
  SearchMatchCount,
  SearchWrapper,
  TreeViewWrapper,
} from './styled';

export interface TreeChoice {
  open: boolean;
  fieldId?: string;
  selected: string[];
  setSelected: (value: string[] | []) => void;
  treeAlternatives?: string[][];
}

const defaults: TreeChoice = {
  open: false,
  selected: [],
  setSelected: () => {},
  treeAlternatives: undefined,
};

const treeChoiceDialog = atom<TreeChoice>(defaults);
export const useTreeChoiceDialog = () => useAtom(treeChoiceDialog);

function TreeChoiceDialog() {
  const [state, setState] = useTreeChoiceDialog();
  const [matches, setMatches] = useState<string[][]>([]);
  const [searchIndex, setSearchIndex] = useState(0);

  const tree = useMemo(() => createTree(state.treeAlternatives ?? []), [state.treeAlternatives]);

  function countMatches(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const foundMatches = e.target.value
      ? (state.treeAlternatives ?? []).filter((alternative) =>
          alternative[alternative.length - 1].toLowerCase().includes(e.target.value.toLowerCase()),
        )
      : [];
    setSearchIndex(0);
    setMatches(foundMatches);
  }

  const onSelect = useCallback(
    (val: string[]) => {
      setState({ ...state, selected: val });
    },
    [state, setState],
  );

  const onRemove = useCallback(() => {
    setState({ ...state, selected: [] });
  }, [state, setState]);

  const onClose = useCallback(() => {
    setMatches([]);
    setSearchIndex(0);
    setState(defaults);
  }, [setState]);

  const onConfirm = useCallback(() => {
    state.setSelected(state.selected ?? []);
    onClose();
  }, [state]);

  return (
    <Dialog open={state.open} onClose={onClose}>
      <Dialog.Header>Edit {state.fieldId}</Dialog.Header>
      <Dialog.Body>
        <CurrentChoice>
          {state.selected.length ? (
            <Text variant="listItemLabel" color="highEmphasis">
              {state.selected.join(' ▸ ')}
            </Text>
          ) : (
            <Text variant="listItemLabelItalic" color="watermark">
              Select a choice
            </Text>
          )}
          <IconButton
            title="Clear selection"
            size={24}
            iconSize={10}
            usage="text"
            onClick={onRemove}
            disabled={!state.selected.length}
          >
            <DeleteIcon />
          </IconButton>
        </CurrentChoice>
        <SearchWrapper>
          <StyledTextField
            placeholder="Type to find..."
            autoFocus
            variant="filled"
            onChange={countMatches}
            fullWidth
          />
          <SearchMatchCount>
            <SearchArrowWrapper>
              <IconButton
                height={20}
                width={28}
                usage="text"
                onClick={() => {
                  if (searchIndex > 0) setSearchIndex(searchIndex - 1);
                }}
              >
                <ArrowUp />
              </IconButton>
              <IconButton
                height={20}
                width={28}
                usage="text"
                onClick={() => {
                  if (searchIndex < matches.length - 1) setSearchIndex(searchIndex + 1);
                }}
              >
                <ArrowDown />
              </IconButton>
            </SearchArrowWrapper>
            <CountText variant="caption" color="highEmphasis">
              {matches?.length ? searchIndex + 1 : 0}/{matches.length}
            </CountText>
          </SearchMatchCount>
        </SearchWrapper>
        {tree?.length ? (
          <TreeViewWrapper>
            <TreeView
              node={{ value: '', isCollapsed: false, children: tree }}
              path={[]}
              value={state.selected}
              setValue={onSelect}
              search={matches[searchIndex]}
            />
          </TreeViewWrapper>
        ) : (
          <Text as="p" variant="captionItalic" color="statusWarning" style={{ margin: '12px 4px' }}>
            No alternatives have been added!
          </Text>
        )}
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton />
        <Dialog.ConfirmButton onConfirm={onConfirm} label="Confirm" />
      </Dialog.Footer>
    </Dialog>
  );
}

export default TreeChoiceDialog;
