import gql from 'graphql-tag';

export default gql`
  query getMessagesOfConversation(
    $input: GetMessagesOfConversationInput
    $limit: Int
    $nextToken: String
  ) {
    getMessagesOfConversation(input: $input, limit: $limit, nextToken: $nextToken) {
      __typename
      items {
        __typename
        mId
        mRefId
        mType
        mTitle
        mContent
        mCreatedAt
        mUpdatedAt
        mCreatedById
        mUpdatedById
        convoType
        mResourceId
        mResourceType
        mStoryId
        mSecId
        mSecRefId
        mTertId
        mTertRefId
        mState
      }
      nextToken
    }
  }
`;
