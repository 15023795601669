import uuidv1 from 'uuid/v1';

export const createNewForm = (cmsRefId, blockType) => ({
  mId: 'form',
  mRefId: `block#${cmsRefId}#${blockType}`,
  mTitle: blockType,
  mType: 'config',
  mProperties: {
    fields: [],
    defaultViews: [],
    __typename: 'MetadataFormType',
  },
});

export const createOption = (value) => ({
  id: uuidv1(),
  value,
  title: 'Visible label',
});

export const normalizeField = (field) => {
  const updatedField = { ...field };
  if (field.type === 'select' && !field?.options) {
    updatedField.options = [];
    return updatedField;
  }
  return field;
};
