const specTypes = {
  GRID: 'grid',
  METADATA: 'metadata',
};

const entityTypes = {
  RUNDOWN: 'rundown',
  INSTANCE: 'instance',
  STORY: 'story',
};

export { specTypes, entityTypes };
