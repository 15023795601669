import { atom, useAtom } from 'jotai';

import { ProviderName } from 'api/providers/provider-types';
import { Metadata } from 'types/forms/forms';
import { Alternative, IntegrationType, Mdf, RundownPermissionsType } from 'types/graphqlTypes';
import { PermissionType } from 'types/groupPermissions/permissions';
import { PanelType, User } from 'types/members';
import { EditorCommandConfigType } from 'types/memberTypes/editorCommands';
import { OrderFormMemberType } from 'types/memberTypes/order_form';

/**
 * Set an error to disable save button
 */
const disableSaveErrors = atom<Record<string, string>>({});
export const useDisableSaveError = () => useAtom(disableSaveErrors);

/**
 * State for selecting a mdf schema in schema tab
 */
const doSelectAtom = atom<string | null>(null);
export const useSelectMdfSchema = () => useAtom(doSelectAtom);

/**
 * Selected settings tab state
 */
const tabIndex = atom(0);
export const useTabSettingsIndex = () => useAtom(tabIndex);

const selectedFormId = atom<string | null>(null);
export const useSelectedFormId = () => useAtom(selectedFormId);

/** Changed user profile settings */
const changedProfileMetadata = atom<Metadata | null>(null);
export const useChangedProfileMetadata = () => useAtom(changedProfileMetadata);

/** Changed user settings */
const changedUserSettingsAtom = atom<
  {
    key: string;
    value: string | number | object | boolean | null | undefined;
    originalValue?: string | number | object | boolean | null | undefined;
  }[]
>([]);
export const useChangedUserSettings = () => useAtom(changedUserSettingsAtom);

export interface ChangedProfilePictureProps {
  file: File;
  key: string;
}

const changedProfilePicture = atom<ChangedProfilePictureProps | null>(null);
export const useChangedProfilePicture = () => useAtom(changedProfilePicture);

export interface ChangedTeamsOrDeptsTypes {
  old: User[];
  new: User[];
}

const changedProfileTeams = atom<ChangedTeamsOrDeptsTypes | null>(null);
export const useChangedProfileTeams = () => useAtom(changedProfileTeams);

const changedProfileDepartments = atom<ChangedTeamsOrDeptsTypes | null>(null);
export const useChangedProfileDepartments = () => useAtom(changedProfileDepartments);

/** Changed rows */
const changedRowsAtom = atom<PermissionType[]>([]);
export const useChangedRows = () => useAtom(changedRowsAtom);

/** Selected item from left menu */
const selectedLeftMenuAtom = atom<string | null>(null);
export const useSelectedLeftMenu = () => useAtom(selectedLeftMenuAtom);

/** Changed mdfs in system settings */
const changedMdfsAtom = atom<Record<string, Mdf>>({});
export const useChangedMdfs = () => useAtom(changedMdfsAtom);

/** Changed order forms in system settings */
const changedOrderFormsAtom = atom<Record<string, OrderFormMemberType>>({});
export const useChangedOrderForms = () => useAtom(changedOrderFormsAtom);

/** Changed actions in system settings */
const changedActionsAtom = atom<Record<string, IntegrationType>>({});
export const useChangedActions = () => useAtom(changedActionsAtom);

/** Changed actions in system settings */
const changedPluginsAtom = atom<Record<string, IntegrationType>>({});
export const useChangedPlugins = () => useAtom(changedPluginsAtom);

/** Changed webhooks in system settings */
const changedIntegrationWebhookAtom = atom<Record<string, IntegrationType>>({});
export const useChangedIntegrationWebhooks = () => useAtom(changedIntegrationWebhookAtom);

/** Changed ai prompt in system settings */
const changedPrompts = atom<Alternative[] | null>(null);
export const useChangedPrompts = () => useAtom(changedPrompts);

/** TODO: To be merged with atoms when converted to typescript */
/** HtmlPanel  */
const panelsAtom = atom<PanelType[]>([]);
export const usePanels = () => useAtom(panelsAtom);

/** Changed panel  */
const changedPanelsAtom = atom<PanelType[]>([]);
export const useChangedPanels = () => useAtom(changedPanelsAtom);

/** New Panel Id  */
const newPanelIdAtom = atom('');
export const useNewPanelId = () => useAtom(newPanelIdAtom);

/** Changed user settings */
const selectedOrganizationAtom = atom<string | null>(null);
export const useSelectedOrganization = () => useAtom(selectedOrganizationAtom);

/* changed webhooks urls in system settings */
export interface ChangedWebhookType {
  key: string;
  value: string;
  originalValue: string;
  severity: string;
}

/** Control settings panel open/close */
const settingsOpen = atom(false);
export const useSettingsOpen = () => useAtom(settingsOpen);

const changedRundownPermissions = atom<
  {
    mId: string;
    permissions: RundownPermissionsType;
  }[]
>([]);

export const useChangedRundownPermissions = () => useAtom(changedRundownPermissions);

/* changed editor commands in system settings */

export type ChangedEditorCommandType = Partial<Omit<EditorCommandConfigType, 'mId' | 'mType'>>;

const changedEditorCommands = atom<ChangedEditorCommandType[]>([]);
export const useChangedEditorCommands = () => useAtom(changedEditorCommands);

/** Changed provider notification settings */
const changedProviderNotificationSettingsAtom = atom<
  {
    providerName: ProviderName;
    preferences: Record<string, boolean>; // {[key]:value}
  }[]
>([]);
export const useChangedProviderNotificationSettings = () =>
  useAtom(changedProviderNotificationSettingsAtom);
