import { useCallback, useContext } from 'react';

import configCtx from 'contexts/configContext';

type SettingType = {
  [key: string]: string | number;
};

const useSettingsValue = () => {
  const config = useContext(configCtx);

  const [settings] = config.settings as SettingType[];

  const getSettings = useCallback(
    (propertyName: string, fallbackProperty?: string, defaultValue?: string) => {
      if (settings) {
        const property = settings[propertyName];
        if (property || property === 0) return property;
        const fallbackValue = fallbackProperty ? settings[fallbackProperty] : null;
        if (fallbackValue || fallbackValue === 0) return fallbackValue;
      }
      return defaultValue;
    },
    [settings],
  );

  return [getSettings];
};

export default useSettingsValue;
