/**
 * Formats a duration given in miliseconds to string representation
 *
 * @param {number} duration Duration given in miliseconds
 * @returns {string} string representation of given duration (i.e. 1m 10s)
 */

export const formatDuration = duration => {
  const convertedDuration = new Date(1000 * Math.round(duration / 1000));
  const hours = convertedDuration.getUTCHours();
  const minutes = convertedDuration.getUTCMinutes();
  const seconds = convertedDuration.getUTCSeconds();
  return (
    (hours ? `${hours}h ` : '') + (minutes ? `${minutes}m ` : '') + (seconds ? `${seconds}s` : '')
  );
};

/**
 * Finds the difference of two duration and formats in string with positive/negative sign
 *
 * @param {number} duration1 First duration given in miliseconds
 * @param {number} duration2 Second duration given in miliseconds
 * @returns {string} string representation of difference between two duration with sign
 */
export const convertDifferenceInString = (duration1, duration2) => {
  const sign = duration1 !== duration2 && (duration1 > duration2 ? '-' : '+');
  const timeOver = formatDuration(Math.abs(duration1 - duration2));
  return `${sign || ''}${timeOver}`;
};
