import { memo, useCallback, useEffect, useState } from 'react';

import MemberLabel from 'components/addMember/MemberLabel';
import { AssignedMember } from 'types';

import { CellProps } from '../fields';
import { normalizeFilter } from '../utils';

import { StyledDropdown, Wrapper } from './styled';

function UserCell({ fieldModel, setValue, value, disableEdit }: Readonly<CellProps>) {
  const [localValue, setLocalValue] = useState(value);
  const [isEditing, setIsEditing] = useState(false);

  const { filter, required } = fieldModel;
  const normalizedFilter = normalizeFilter(filter);

  useEffect(() => {
    if (value !== localValue) {
      setLocalValue(value);
    }
  }, [value]);

  const selectAssignee = useCallback(
    (m: AssignedMember | null) => {
      setLocalValue(m ? m.mId : null);
      setValue(m ? m.mId : null);
    },
    [setLocalValue, setValue],
  );

  return isEditing ? (
    <Wrapper>
      <StyledDropdown
        variants={normalizedFilter}
        variant="all"
        singleChoice
        autoFocus
        showChips
        selectOnFocus
        disableClearable={required}
        selectedMembers={[]}
        setSelectedMembers={(val: AssignedMember[]) => selectAssignee(val?.[0] ?? null)}
        onClose={() => setIsEditing(false)}
        placeholderText="Find assignee"
        noOptionsText="Could not find assignee"
        injectedMembers={undefined}
      />
    </Wrapper>
  ) : (
    <MemberLabel
      variant="grid"
      memberId={localValue as string}
      onClick={() => {
        if (!disableEdit) setIsEditing(true);
      }}
    />
  );
}

export default memo(UserCell);
