import { useMemo } from 'react';
import Fade from '@material-ui/core/Fade';

import useTabs from 'hooks/useTabs';
import { ContentTab, useContentTabs } from 'store/tabs';

import {
  StyledDivider as Divider,
  StyledMenu as Menu,
  StyledMenuItem as MenuItem,
  StyledMenuItemText as MenuText,
} from './styled';

interface Props {
  tab: ContentTab;
  onClose: () => void;
  contextMenuPos: {
    mouseX: number | null;
    mouseY: number | null;
  };
}

const ContextMenuContainer = ({ tab, onClose, contextMenuPos }: Props) => {
  const { closeTab, closeOtherTabs, closeAllTabs, closeLeftTabs, closeRightTabs } = useTabs();

  const [contentTabs] = useContentTabs();

  const hasOtherTabs = useMemo(() => {
    const otherTabs = contentTabs.filter((t) => t.id !== tab.id);
    return otherTabs.length > 0;
  }, [contentTabs, tab.id]);

  const hasTabsToLeft = useMemo(() => {
    const tabsToLeft = contentTabs.filter((_t, index) => index < contentTabs.indexOf(tab));
    return tabsToLeft.length > 0;
  }, [contentTabs, tab]);

  const hasTabsToRight = useMemo(() => {
    const tabsToRight = contentTabs.filter((_t, index) => index > contentTabs.indexOf(tab));
    return tabsToRight.length > 0;
  }, [contentTabs, tab]);

  const handleCloseActiveTab = () => {
    closeTab(tab);
    onClose();
  };

  const handleCloseOtherTabs = () => {
    closeOtherTabs(tab);
    onClose();
  };

  const handleCloseAllTabs = () => {
    closeAllTabs();
    onClose();
  };

  const handleCloseTabsToLeft = () => {
    closeLeftTabs(tab);
    onClose();
  };

  const handleCloseTabsToRight = () => {
    closeRightTabs(tab);
    onClose();
  };

  return (
    <Menu
      keepMounted
      open={contextMenuPos.mouseX !== null}
      onClose={onClose}
      anchorReference="anchorPosition"
      anchorPosition={
        contextMenuPos.mouseY !== null && contextMenuPos.mouseX !== null
          ? { top: contextMenuPos.mouseY, left: contextMenuPos.mouseX }
          : undefined
      }
      TransitionComponent={Fade}
    >
      <MenuItem button onClick={handleCloseActiveTab}>
        <MenuText>Close Tab</MenuText>
        {/* <TabAction>{`Ctrl + W \n⌘ + W`}</TabAction> */}
      </MenuItem>
      <MenuItem button onClick={handleCloseOtherTabs} disabled={!hasOtherTabs}>
        <MenuText>Close Other Tabs</MenuText>
      </MenuItem>
      <MenuItem button onClick={handleCloseAllTabs} disabled={contentTabs.length === 0}>
        <MenuText>Close All Tabs</MenuText>
        {/* <TabAction>{`Ctrl + Shift + W\n⌘ + Shift + W`}</TabAction> */}
      </MenuItem>

      <Divider />

      <MenuItem button onClick={handleCloseTabsToLeft} disabled={!hasTabsToLeft}>
        <MenuText>Close Tabs to the Left</MenuText>
      </MenuItem>
      <MenuItem button onClick={handleCloseTabsToRight} disabled={!hasTabsToRight}>
        <MenuText>Close Tabs to the Right</MenuText>
      </MenuItem>
    </Menu>
  );
};

export default ContextMenuContainer;
