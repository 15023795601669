import { makeStyles } from '@material-ui/styles';

const useStyles = () =>
  makeStyles((theme) => ({
    root: {
      marginTop: '10px',
      marginLeft: '10px',
      width: 'calc(100vw - 20px)',
      height: 'calc(100vh - 20px)',
      overflowY: 'scroll',
    },
    body1: {
      color: '#ffffff',
      fontStyle: 'normal',
      fontSize: '13.333px',
      lineHeight: 'normal',
      fontWeight: 300,
    },
  }));

export default useStyles;
