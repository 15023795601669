/* eslint-disable import/prefer-default-export */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';

import { ReactComponent as AddIcon } from 'assets/icons/systemicons/add.svg';
import { RectangleButton } from 'components/buttons';
import ThumbnailComponent from 'components/thumbnail';

const ThumbnailWrapper = styled('div')`
  margin-right: 8px;
`;

const Thumbnail = ({ src, onAdd, onRemove, readOnly }) => (
  <ThumbnailWrapper>
    {src ? (
      <ThumbnailComponent src={src} size={88} onClose={onRemove} type="image" />
    ) : (
      <RectangleButton onClick={onAdd} disabled={readOnly} width={88} height={88}>
        <AddIcon />
        Add Thumbnail
      </RectangleButton>
    )}
  </ThumbnailWrapper>
);

Thumbnail.propTypes = {
  /** thumbnail image source */
  src: PropTypes.string,
  /** callback to add thumbnail */
  onAdd: PropTypes.func,
  /** callback to remove thumbnail */
  onRemove: PropTypes.func,
  /** boolean to stop editing */
  readOnly: PropTypes.bool,
};

Thumbnail.defaultProps = {
  src: '',
  onAdd: () => {},
  onRemove: () => {},
  readOnly: false,
};

export default memo(Thumbnail);
