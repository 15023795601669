/**
 * Slate block types that contains an asset
 */
const AssetTypes = new Set(['image', 'live-stream', 'placeholder', 'video']);

/**
 * Returns true if the given type is an asset block type
 * @param {*} type - slate block type
 * @returns true if the block is an asset
 */
const isAssetType = (type) => AssetTypes.has(type);

/**
 * Returns true if the given asset is a placeholder
 * @param {*} asset
 */
const isPlaceholder = (asset) => {
  if (!asset) return false;
  const { mediaType } = asset;
  return mediaType && mediaType.includes('placeholder');
};

const getAssets = (content) => {
  if (!content) return [];
  const { document } = content;
  if (!Array.isArray(document)) return [];
  return document.reduce((aggAssets, { type, data }) => {
    if (!data) return aggAssets;
    if (isAssetType(type)) aggAssets.push(data);
    else if (Array.isArray(data.assets)) aggAssets.push(...data.assets);
    return aggAssets;
  }, []);
};

/**
 * Function for content statistics
 * @param {*} content - Content as an slate object
 */
const contentStat = (content) => {
  const assets = getAssets(content);

  const placeholders = assets.reduce((phs, asset) => {
    if (isPlaceholder(asset)) phs.push(asset);
    return phs;
  }, []);

  /**
   * Returns true if a placeholder with the same name (title) already exixts
   * @param {*} name
   */
  const hasPlaceholder = (name) => placeholders.some((ph) => ph.title === name);

  return { hasPlaceholder, assets, placeholders };
};

export { contentStat as default };
