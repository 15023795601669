const searchbarFields = {
  FREE_TEXT: 'freeText',
  USER: 'user',
  TEAM: 'team',
  DEPARTMENT: 'department',
  PLATFORM: 'platform',
  UNIT: 'unit',
  SAVED_SEARCH: 'savedSearches',
  FILTER_BY_AND_LOGIC: 'filterByAndLogic',
};

const searchbarActions = {
  CLEAR: 'clear',
  UPDATE_FREE_TEXT: 'freeText',
  UPDATE_USER: 'user',
  UPDATE_TEAM: 'team',
  UPDATE_DEPARTMENT: 'department',
  UPDATE_PLATFORM: 'platform',
  SELECT: 'select',
  UPDATE_UNIT: 'unit',
  UPDATE_FILTER_BY_AND_LOGIC: 'updateFilterByAndLogic',
};

const currentSearchTypes = {
  HISTORY: 'history',
  SAVED_FILTERS: 'savedFilter',
};

export { searchbarFields, searchbarActions, currentSearchTypes };
