import { useState } from 'react';

import { ReactComponent as AddIcon } from 'assets/icons/systemicons/add_small.svg';
import { Button } from 'components/buttons';
import Tooltip from 'components/tooltip';

import CreateUserDialog from '../createNew';

import { RootWrapper, Text, TextButtonWrapper } from './styled';
const hostedUI = import.meta.env.REACT_APP_USE_HOSTED_UI as string | undefined;
const isHostedUI = hostedUI ? hostedUI.toUpperCase() === 'TRUE' : false;

const UsersHeader = ({ selectedLeftMenu }: { selectedLeftMenu: string | null }) => {
  const [showAddUser, setShowAddUser] = useState(false);

  const handleAdd = () => {
    setShowAddUser(true);
  };

  return (
    <RootWrapper>
      <TextButtonWrapper>
        <Text>{selectedLeftMenu === 'active-users' ? 'Active Users' : 'Disabled Users'}</Text>
        {selectedLeftMenu === 'active-users' && (
          <Tooltip
            title={
              !isHostedUI
                ? 'create new user'
                : 'user creation is not possible as user is created by external identity provider'
            }
          >
            <div>
              <Button
                usage="outlined"
                variant="outlined"
                height={32}
                onClick={handleAdd}
                disabled={!!isHostedUI}
              >
                <AddIcon />
                Add User
              </Button>
            </div>
          </Tooltip>
        )}
      </TextButtonWrapper>
      <CreateUserDialog open={showAddUser} setOpen={setShowAddUser} />
    </RootWrapper>
  );
};

export default UsersHeader;
