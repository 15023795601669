/* eslint-disable max-len */

import React, { ForwardedRef, forwardRef } from 'react';
import styled from '@emotion/styled';

import { ReactComponent as PlusIcon } from 'assets/icons/systemicons/plus.svg';

const Wrapper = styled('div')<{ isOver: boolean }>`
  box-sizing: padding-box;
  width: 100%;
  height: 100%;
  display: flex;
  cursor: pointer;
  border: 1px dashed;
  border-color: ${({ isOver, theme }) =>
    isOver ? theme.palette.dina.highEmphasis : theme.palette.dina.disabled};
  border-radius: 4px;
  svg path {
    ${({ isOver }) => (isOver ? 'fill-opacity: 1' : '')}
  }
  &:hover {
    border-color: white;
    svg path {
      fill-opacity: 1;
    }
  }
`;

const StyledPlusIcon = styled(PlusIcon)`
  margin: auto;
`;

const StyledMessage = styled('div')`
  margin: auto;
`;

interface Props {
  isOver: boolean;
  style?: React.CSSProperties;
  onClick?: (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

function PlaceholderLayout(
  { style, onClick, isOver }: Props,
  ref?: ForwardedRef<HTMLDivElement> | null,
) {
  return (
    <Wrapper ref={ref} style={style} onClick={(ev) => onClick?.(ev)} isOver={isOver}>
      {isOver ? <StyledMessage>Drop to move widget here</StyledMessage> : <StyledPlusIcon />}
    </Wrapper>
  );
}

export default forwardRef(PlaceholderLayout);
