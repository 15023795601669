import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import styled from '@emotion/styled/macro';

import { ReactComponent as DragHandle } from 'assets/icons/systemicons/unionDragHandle.svg';

import { OrderColumn } from '../types';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const DragHandleWrapper = styled.div`
  display: flex;
  align-items: center;
  :hover {
    cursor: grab;
    path {
      fill-opacity: 1;
    }
  }
  :active {
    cursor: grabbing;
    path {
      fill-opacity: 1;
    }
  }
`;

interface Props {
  column: OrderColumn;
}

function OrderGridSortMenuItem({ column }: Readonly<Props>) {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition } =
    useSortable({
      id: column.key,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
    <Wrapper ref={setNodeRef} style={style} {...attributes}>
      <DragHandleWrapper ref={setActivatorNodeRef} {...listeners}>
        <DragHandle />
        {column.name}
      </DragHandleWrapper>
    </Wrapper>
  );
}

export default OrderGridSortMenuItem;
