import { atom, useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

import { EditorFontSize } from 'types';

export const toolbarButtonIds = {
  ASSETS: 'assets',
  READY: 'ready',
  PREPARING: 'preparing',
  EDITOR: 'editor',
};

export const assetsLocations = {
  LEFT: 'left',
  RIGHT: 'right',
};

export type ToolbarButtonId = 'assets' | 'ready' | 'preparing' | 'editor';

interface ToolbarButton {
  id: ToolbarButtonId;
  selected: boolean;
}

const RND_DEFAULT_TOOLBAR_BUTTONS: ToolbarButton[] = [
  { id: 'assets', selected: true },
  { id: 'ready', selected: true },
  { id: 'preparing', selected: true },
  { id: 'editor', selected: true },
];

interface RundownToolbarState {
  toolbarButtons: ToolbarButton[];
  editorFontSize: EditorFontSize;
  assetsLocation: 'left' | 'right';
}

const RND_DEFAULT_STATE: RundownToolbarState = {
  toolbarButtons: RND_DEFAULT_TOOLBAR_BUTTONS,
  editorFontSize: 'small',
  assetsLocation: 'left',
};

const RND_STORAGE_KEY = 'rundown';

const savedRundownStates = JSON.parse(
  window.localStorage.getItem(RND_STORAGE_KEY) ?? '{}',
) as RundownToolbarState;
const savedRundownToolbarButtons = savedRundownStates?.toolbarButtons || [];

const mergedToolbarButtons = RND_DEFAULT_TOOLBAR_BUTTONS.map(
  (toolbarButton) =>
    savedRundownToolbarButtons.find((button) => button.id === toolbarButton.id) || toolbarButton,
);
const mergedValue = {
  ...RND_DEFAULT_STATE,
  ...savedRundownStates,
  toolbarButtons: mergedToolbarButtons,
};

const rundownStorageAtom = atom(mergedValue);

const rundownAtom = atom(
  (get) => get(rundownStorageAtom),
  (_get, set, nextValue: RundownToolbarState) => {
    set(rundownStorageAtom, nextValue);
    localStorage.setItem(RND_STORAGE_KEY, JSON.stringify(nextValue));
  },
);

export const useRundown = () => useAtom(rundownAtom);

const rundownToolbarButtonsAtom = atom(
  (get) => get(rundownAtom)?.toolbarButtons,
  (get, set, nextValue: ToolbarButton[]) => {
    const currentState = get(rundownAtom);

    const updatedState = {
      ...currentState,
      toolbarButtons: nextValue,
    };
    set(rundownAtom, updatedState);
  },
);

export const useRundownToolbarButtons = () => useAtom(rundownToolbarButtonsAtom);

const rundownEditorFontSizeAtom = atom(
  (get) => get(rundownAtom)?.editorFontSize,
  (get, set, nextValue: EditorFontSize) => {
    const currentState = get(rundownAtom);

    const updatedState = {
      ...currentState,
      editorFontSize: nextValue,
    };
    set(rundownAtom, updatedState);
  },
);

export const useRundownEditorFontSize = () => useAtom(rundownEditorFontSizeAtom);

const rundownAssetsLocationAtom = atom(
  (get) => get(rundownAtom)?.assetsLocation,
  (get, set, nextValue: 'left' | 'right') => {
    const currentState = get(rundownAtom);
    const updatedState = { ...currentState, assetsLocation: nextValue };
    set(rundownAtom, updatedState);
  },
);

export const useRundownAssetsLocation = () => useAtom(rundownAssetsLocationAtom);

const rundownGridViewAtom = atomWithStorage('currentRundownGridview', 'rundown-grid-1');
export const useRundownGridView = () => useAtom(rundownGridViewAtom);

const rundownGridViewListAtom = atom([]);
export const useRundownGridViewList = () => useAtom(rundownGridViewListAtom);
