import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import { gql } from 'graphql-tag';

const QUERY = gql`
  query GetMember($input: GetMemberInput) {
    getMember(input: $input) {
      mStoryId
      isTemplateInstance
      mProperties {
        ... on PlatformType {
          account {
            accountId
            accountTitle
          }
        }
      }
    }
  }
`;

interface FetchMember {
  getMember: {
    mStoryId: string;
    isTemplateInstance?: boolean;
    mProperties: { account?: { accountId?: string; accountTitle?: string } };
  };
}

const useGetInstanceDetails = () => {
  const client = useApolloClient();
  const getInstanceURLParams = useCallback(
    async (instanceId: string) => {
      const result = await client.query<FetchMember>({
        query: QUERY,
        variables: {
          input: {
            mId: instanceId,
          },
        },
        fetchPolicy: 'network-only',
      });
      if (result.data.getMember) {
        const { mStoryId, isTemplateInstance, mProperties } = result.data.getMember;
        if (mProperties.account?.accountId === mStoryId) {
          return isTemplateInstance
            ? { path: 'rundowntemplate', id: mStoryId }
            : { path: 'rundown', id: mStoryId };
        }
        return { path: 'story', id: mStoryId };
      }
    },
    [client],
  );

  return { getInstanceURLParams };
};

export default useGetInstanceDetails;
