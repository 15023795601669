import { capitalize } from 'lodash';

import { OrderFormMemberType } from 'types/memberTypes/order_form';
import getRelativeDateTime from 'utils/getRelativeDateTime';

export const doFormatLocale = (date: string) =>
  capitalize(getRelativeDateTime(date, undefined, true, 'MMM D YYYY,  hh:mm a', 'hh:mm a'));

export const getStatusColors = (form: OrderFormMemberType) => {
  const formConfigs = form.configs;
  const statusesConfig = formConfigs?.find((config) => config.key === 'status_colors');
  const colorMap: Record<string, string> = {};
  (statusesConfig?.alternatives ?? []).forEach((alt) => {
    colorMap[alt.id] = alt.value;
  });
  return colorMap;
};

export const getStatusOption = (form: OrderFormMemberType) => {
  const formConfigs = form.configs;
  const statusesConfig = formConfigs?.find((config) => config.key === 'statuses');
  return (
    statusesConfig?.alternatives?.map((alt) => ({
      ...alt,
      title: alt.label,
      value: alt.value.split('#')[1],
    })) ?? []
  );
};
