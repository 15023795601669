import { atom, useAtom } from 'jotai';
import { processScheduleResources } from 'utils/scheduleResourceHelper';

const initialResourceValue = {
  agency: [],
  category: [],
  client: [],
  contact: [],
  project: [],
  request: [],
  resource: [],
  status: [],
};

const resourcesAtom = atom(initialResourceValue);
const scheduleResourcesAtom = atom(
  (get) => get(resourcesAtom),
  (get, set, resourcesResult) => {
    const currentResources = get(resourcesAtom);
    const processedResult = processScheduleResources(resourcesResult);
    set(resourcesAtom, { ...currentResources, ...processedResult });
  },
);

// eslint-disable-next-line import/prefer-default-export
export const useScheduleResources = () => useAtom(scheduleResourcesAtom);
