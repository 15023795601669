/**
 * Converts seconds to hh:mm:ss formatted time string
 *
 * @param {Number} secondsToConvert Seconds to be converted
 * @returns {String} Converted time string
 */

const getTime = secondsToConvert => {
  const sign = Math.sign(secondsToConvert) === -1 ? '-' : '';
  const absoluteSecondsToConvert = Math.abs(secondsToConvert);
  const hours = Math.floor(absoluteSecondsToConvert / 3600);
  const hh = hours ? `${hours.toString().padStart(2, '0')}:` : '';
  const minutes = Math.floor((absoluteSecondsToConvert - hours * 3600) / 60);
  const mm = `${minutes.toString().padStart(2, '0')}:`;
  const seconds = absoluteSecondsToConvert - hours * 3600 - minutes * 60;
  const ss = `${seconds.toString().padStart(2, '0')}`;

  return sign + hh + mm + ss;
};

export default getTime;
