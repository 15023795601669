import { appendWithSuffix, getInstanceKeys, getNoteKeys } from 'api/useMoveItemsToStory';
import useCheckUserRight from 'hooks/useCheckUserRight';

import { useStoryPaneMolecule } from '../store/storyPane';

const useItemMoveConfigs = () => {
  const [checkUserRight] = useCheckUserRight();
  const { useSelectedStoryInstances, useMoveItemDialog, useSelectedStoryNotes } =
    useStoryPaneMolecule();

  const [selectedInstances, setSelectedInstances] = useSelectedStoryInstances();
  const [selectedNotes, setSelectedNotes] = useSelectedStoryNotes();
  const [moveItemProps] = useMoveItemDialog();

  const canMoveOrCopyInstance = checkUserRight('instance', 'move-or-copy-to-story');
  const canMoveOrCopyNote = checkUserRight('story', 'move-or-copy-notes-to-story');

  const itemType = moveItemProps?.itemType;

  if (!itemType) return;

  if (itemType === 'instance')
    return {
      itemType,
      selectedItems: selectedInstances,
      setSelectedItems: setSelectedInstances,
      inputKeys: getInstanceKeys(selectedInstances),
      canMoveOrCopy: canMoveOrCopyInstance,
      permissionLabel: !canMoveOrCopyInstance
        ? 'You do not have permission to move or copy Instances between stories'
        : '',
      radioLabel: `${selectedInstances.length} Instance${appendWithSuffix(
        selectedInstances.length,
      )}`,
    };

  if (itemType === 'note')
    return {
      itemType,
      inputKeys: getNoteKeys(selectedNotes, moveItemProps.parentStoryId),
      selectedItems: selectedNotes,
      setSelectedItems: setSelectedNotes,
      canMoveOrCopy: canMoveOrCopyNote,
      permissionLabel: !canMoveOrCopyNote
        ? 'You do not have permission to move or copy Notes between stories'
        : '',
      radioLabel: `${selectedNotes.length} Note${appendWithSuffix(selectedNotes.length)}`,
    };
};

export default useItemMoveConfigs;
