import styled from '@emotion/styled';

import { VStack } from 'layouts/box/Box';

export const StyledVStack = styled(VStack)`
  & > *:not(:last-of-type) {
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  }
`;
