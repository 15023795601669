import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import { makeStyles } from '@material-ui/styles';
import InputBase from 'components/editor/components/inputBase';
import {
  Select as MuiSelect,
  MenuItem as MuiMenuItem,
  ListItemIcon as MuiListItemIcon,
} from '@material-ui/core';
import AutoCompleteComponent from './components/autocomplete';

export const RootWrapper = styled('div')`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  min-width: 96px;
  height: 100%;
  min-height: 24px;
  position: relative;
`;

export const SelectWrapper = styled('div')`
  width: 100%;
`;

export const Input = styled(InputBase)`
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: calc(100% - 2px);
  height: 100%;
  padding-right: ${(props) => (props.arrowPosition === 'end' ? '42px' : '0px')};
  padding-left: ${(props) => (props.arrowPosition === 'start' ? '42px' : '8px')};
  :focus {
    ${({ theme }) => css`
      background-color: ${theme.palette.dina.statusOnFocusedDiscreetFill};
      border: 1px solid ${theme.palette.dina.onSelected};
    `}
  }
`;

export const AutocompleteWrapper = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 2px);
  height: 100%;
`;

export const Autocomplete = styled(AutoCompleteComponent)`
  padding-right: ${(props) => (props.arrowPosition === 'end' ? '42px' : '0px')};
  padding-left: ${(props) => (props.arrowPosition === 'start' ? '42px' : '8px')};
`;

export const ListItemIcon = styled(MuiListItemIcon)`
  width: 40px;
`;

export const MenuItem = styled(MuiMenuItem)`
  ${({ theme }) => theme.typography.dina.listItemLabel};
  :hover {
    background-color: ${({ theme }) => theme.palette.dina.whiteHoverOverlay};
  }
  :focus {
    background-color: ${({ theme }) => theme.palette.dina.whiteHoverOverlay};
  }
  &.Mui-selected,
  &.Mui-selected:hover,
  &.Mui-selected:focus {
    background-color: ${({ theme }) => theme.palette.dina.onSelected};
    ${ListItemIcon} {
      svg {
        opacity: 1;
        path {
          fill-opacity: 1;
        }
      }
    }
  }
`;

export const Select = styled(MuiSelect)`
  .MuiSelect-root {
    height: 100%;
    padding: 0px;
    display: none;
    align-items: center;
    &.MuiInputBase-input {
      text-transform: uppercase;
      ${({ theme }) => theme.typography.dina.listItemLabelMedium};
      ${ListItemIcon} {
        display: none;
      }
    }
  }
`;

export const useStyles = makeStyles(({ palette }) => ({
  menu: {
    width: 232,
    padding: 24,
    paddingLeft: ({ anchorOrigin }) => (anchorOrigin.horizontal === 'left' ? 12 : 24),
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  menuList: {
    width: 196,
    backgroundColor: palette.dina.surfaceDialogs,
    borderRadius: 4,
    boxShadow:
      '0px 5px 5px -3px rgba(0,0,0,0.2),' +
      '0px 8px 10px 1px rgba(0,0,0,0.14),' +
      '0px 3px 14px 2px rgba(0,0,0,0.12)',
  },
}));
