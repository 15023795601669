const columns = [
  {
    field: 'email',
    headerName: 'Email',
    sortable: true,
    sortField: 'searchByEmail',
    searchField: 'searchByEmail',
  },
  {
    field: 'status',
    headerName: 'Status',
    sortable: true,
  },
  {
    field: 'disabledAt',
    headerName: 'Disabled At',
    sortable: true,
    sortField: 'searchByDisableAt',
    searchField: 'searchByDisableAt',
  },
  {
    field: 'lastLogin',
    headerName: 'Last Login',
    sortable: true,
  },
  {
    field: 'action',
    headerName: 'Actions',
  },
];

export default columns;
