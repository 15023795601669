import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';

import Divider from 'components/divider';
import Infobar from 'components/infobar/Infobar';
import MessageInput from 'components/messageInput';
import useCreateMessage from 'hooks/useCreateMessage';
import useGetUser from 'hooks/useGetUser';
import { Box, VStack } from 'layouts/box/Box';
import GET_MEMBERS from 'operations/queries/getMembers';
import { useUsers } from 'store/members';
import { ConversationTypeEnum, MemberTypeEnum } from 'types/graphqlTypes';
import { User } from 'types/members';
import checkIsNotificationConversation from 'utils/messageHub/checkIsNotificationConversation';

import { useConversationMolecule } from '../../store/conversation';

import Header from './Header';
import Messages from './Messages';

const MessageInputWrapper = styled('div')`
  margin: 8px;
  z-index: 1;
`;

const MessageContainer = styled('div')`
  position: relative;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;
  padding-bottom: 8px;
`;

function MessageContentsContainer() {
  const [users] = useUsers();
  const { getUsers } = useGetUser();
  const { useCurrentConversation } = useConversationMolecule();
  const currentConversation = useCurrentConversation();
  const [createMessage] = useCreateMessage();

  const isAllChat = useMemo(
    () => currentConversation?.convoType === ConversationTypeEnum.All,
    [currentConversation?.convoType],
  );

  const isDirectOrGroup = useMemo(
    () =>
      currentConversation?.convoType === ConversationTypeEnum.Direct ||
      currentConversation?.convoType === ConversationTypeEnum.Group,
    [currentConversation?.convoType],
  );

  const isTeamOrDept = useMemo(
    () =>
      currentConversation?.convoType === ConversationTypeEnum.Team ||
      currentConversation?.convoType === ConversationTypeEnum.Department,
    [currentConversation?.convoType],
  );

  const { data: teamOrDeptUser } = useQuery<{ getMembers: User[] }>(GET_MEMBERS, {
    variables: {
      input: {
        mId: currentConversation?.mId,
        mType:
          currentConversation?.convoType === ConversationTypeEnum.Team
            ? MemberTypeEnum.TeaUsr
            : MemberTypeEnum.DepUsr,
      },
    },
    fetchPolicy: 'cache-and-network',
    skip: !isTeamOrDept,
  });

  const members = useMemo(() => {
    if (isAllChat) return users;
    if (isDirectOrGroup) {
      return getUsers(currentConversation?.mAssignedMembers?.map((member) => member.mId) ?? []);
    }
    if (isTeamOrDept) return teamOrDeptUser?.getMembers;
    return [];
  }, [
    currentConversation?.mAssignedMembers,
    getUsers,
    isAllChat,
    isDirectOrGroup,
    isTeamOrDept,
    teamOrDeptUser?.getMembers,
    users,
  ]);

  if (!currentConversation?.mId) return <div />;

  const { mId, name, convoType } = currentConversation;
  const showInput = !checkIsNotificationConversation(convoType);

  const handleSendMessage = async (
    newMessage: JSON,
    mRefId: string,
    crudAction?: 'UPDATE' | 'DELETE',
  ) => {
    await createMessage(mId, JSON.stringify(newMessage), convoType, mRefId, crudAction);
  };

  return (
    <VStack height="100%" width="100%" overflow="hidden">
      <Header />
      <MessageContainer>
        <Messages />
      </MessageContainer>
      {showInput && (
        <Box width="100%">
          <Divider />
          <MessageInputWrapper>
            {name === 'Ghost User' ? (
              <Infobar>{`You can't reply to this conversation.
                User is no longer available.`}</Infobar>
            ) : (
              <MessageInput
                onSend={handleSendMessage}
                showDoneButton={true}
                users={members}
                isEditingMessage={undefined}
                setIsEditingMessage={undefined}
                handleSaveMessage={undefined}
                handleCancelEdit={undefined}
              />
            )}
          </MessageInputWrapper>
        </Box>
      )}
    </VStack>
  );
}

export default MessageContentsContainer;
