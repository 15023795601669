/* eslint-disable import/prefer-default-export */
import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const PreviewWrapper = styled('div')`
  ${({ theme }) => css`
    ${theme.typography.dina.captionMedium};
    background-color: ${theme.palette.dina.blackHoverOverlay};
    border: 1px dashed ${theme.palette.dina.iconInactive};
  `}
  width: 88px;
  height: 88px;
  border-radius: 8px;
  box-sizing: border-box;
  backdrop-filter: blur(15px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
`;
