import React, { memo, useCallback } from 'react';
import { useSlate } from 'slate-react';
import { useEditorContext } from 'components/editor/EditorContext';
import DropZone from 'components/editor/components/dropZone';
import { mediaTypes } from 'utils/rundownItemTypes';
import addMedia from 'components/editor/components/image/utils/addMedia';

const { CLIP } = mediaTypes;

const MediaDropZone = ({ children, element }) => {
  const editor = useSlate();
  const { update } = useEditorContext();

  const onDrop = useCallback(
    ({ type, payload }) => {
      if (type === CLIP && payload && payload.itemType && payload.itemType === 'image')
        addMedia(editor, element, payload, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  return (
    <DropZone accept={[CLIP]} {...{ onDrop, element }}>
      {children}
    </DropZone>
  );
};

export default memo(MediaDropZone);
