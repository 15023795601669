import { memo, useCallback } from 'react';
import capitalize from 'lodash/capitalize';

import { NotificationPreference, ProviderName, ProviderOutput } from 'api/providers/provider-types';

import { useChangedProviderNotificationSettings } from '../../../../../atomsTs';

import NotificationChoiceItem from './NotificationChoiceItem';

import { ChoiceGroup, ChoiceGroupHeader, ChoiceList } from './styled';

interface NotificationProviderListProps {
  provider: ProviderOutput;
  providerName: ProviderName;
}

const NotificationProviderList = ({ provider, providerName }: NotificationProviderListProps) => {
  const preferences = provider.notificationPreferences || [];
  const [changedPreferences, setChangedPreferences] = useChangedProviderNotificationSettings();

  const handleUpdate = useCallback(
    // changedProviderNotificationSettings needs to track what changes to mutate on save
    (items: NotificationPreference[]) => {
      const changedProvider = changedPreferences.find((cp) => cp.providerName === providerName);

      const updatedState: Record<string, boolean> = {};
      for (const item of items) {
        if (!item.key) continue;
        updatedState[item.key] = Boolean(item.value);
      }

      if (!changedProvider) {
        setChangedPreferences([
          ...changedPreferences,
          {
            providerName,
            preferences: updatedState,
          },
        ]);
      } else {
        setChangedPreferences(
          changedPreferences.map((prov) => {
            if (prov.providerName !== providerName) return prov;
            return {
              ...prov,
              preferences: {
                ...prov.preferences,
                ...updatedState,
              },
            };
          }),
        );
      }
    },
    [changedPreferences, providerName, setChangedPreferences],
  );

  return (
    <ChoiceList>
      {preferences.map((preference) => (
        <ChoiceGroup key={`${providerName}-${preference.key || preference.title}`}>
          <ChoiceGroupHeader>{capitalize(preference.title)}</ChoiceGroupHeader>
          <ChoiceList>
            {preference.subPreferences?.map((subPreference) => (
              <NotificationChoiceItem
                key={subPreference.key || subPreference.title}
                item={subPreference}
                onUpdate={handleUpdate}
              />
            ))}
          </ChoiceList>
        </ChoiceGroup>
      ))}
    </ChoiceList>
  );
};

export default memo(NotificationProviderList);
