import { makeStyles } from '@material-ui/styles';

const commonSecondaryStyles = (theme, borderRadius) => ({
  background: theme.palette.dina.blackHoverOverlay,
  border: `1px solid ${theme.palette.dina.buttonBackgroundOutlined}`,
  borderRadius,
});

const commonSvgStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate3d(-50%, -50%, 0)',
};

const useStyles = (width, height, borderRadius) =>
  makeStyles((theme) => ({
    camera: {
      background: theme.palette.dina.timelineCamera,
    },
    package: {
      background: theme.palette.dina.timelineVideoClip,
    },
    live: {
      background: theme.palette.dina.timelineLive,
    },
    fullscreenGraphics: {
      background: theme.palette.dina.timelineGraphic,
    },
    break: {
      background: theme.palette.dina.timelineBreak,
    },
    telephone: {
      background: theme.palette.dina.timelineBreak,
    },
    mos: {
      background: theme.palette.dina.timelineBreak,
    },
    jingle: {
      background: theme.palette.dina.timelineBreak,
    },
    adlib: {
      borderTop: `${height}px solid ${theme.palette.dina.timelineCamera}`,
      borderRight: `${width}px solid ${theme.palette.dina.timelineVideoClip}`,
    },
    dve: {
      borderTop: `${height}px solid ${theme.palette.dina.timelineLive}`,
      borderRight: `${width}px solid ${theme.palette.dina.timelineGraphic}`,
    },
    voiceOver: {
      borderTop: `${height}px solid ${theme.palette.dina.timelineVideoClip}`,
      borderRight: `${width}px solid ${theme.palette.dina.timelineCamera}`,
    },

    overlayGraphics: {
      ...commonSecondaryStyles(theme, borderRadius),
    },
    audio: {
      ...commonSecondaryStyles(theme, borderRadius),
    },
    textTelePrompter: {
      ...commonSecondaryStyles(theme, borderRadius),
    },
    accessory: { ...commonSecondaryStyles(theme, borderRadius) },
    mainDiv: {
      position: 'relative',
      width,
      height,
      minWidth: width,
      minHeight: height,
      borderRadius,
      overflow: 'hidden',
    },
    errorDiv: {
      position: 'relative',
      width,
      height,
      borderRadius,
      overflow: 'hidden',
      boxShadow: `0 0 0 3px red`,
    },
    errorStateDiv: {
      width,
      height,
      backgroundImage: `linear-gradient(
        45deg,
        black 25%,
        transparent 25%,
        transparent 75%,
        black 75%
      ),
      linear-gradient(
        45deg,
        black 25%,
        white 25%,
        white 75%,
        black 75%
      )`,
      backgroundSize: '10px 10px',
      backgroundPosition: '0 0, 5px 5px',
      opacity: 0.8,
      position: 'absolute',
      top: 0,
      left: 0,
    },
    noBackground: {
      background: 'transparent',
    },
    iconContainer: {
      ...commonSvgStyles,
    },
    meta: {
      ...commonSvgStyles,
      '& path': {
        fill: theme.palette.dina.blackMediumEmphasis,
        fillOpacity: '1',
      },
    },
    gridSvgClass: {
      position: 'absolute',
      left: -5.5,
      bottom: -28.5,
      '& path': {
        fill: theme.palette.dina.blackMediumEmphasis,
        fillOpacity: '1',
      },
    },
    altSvgClass: {
      position: 'absolute',
      left: -1.5,
      bottom: -28.5,
      '& path': {
        fill: theme.palette.dina.blackMediumEmphasis,
        fillOpacity: '1',
      },
    },
    gridContainer: {
      position: 'absolute',
      top: 1,
      left: 4,
    },
    editor: {
      ...commonSvgStyles,
      '& path': {
        fill: theme.palette.dina.surfaceCard,
        fillOpacity: '1',
      },
    },
    default: {
      ...commonSvgStyles,
    },
    labelRoot: {
      ...theme.typography.dina.iconLabel,
      position: 'absolute',
      top: 0,
      left: 0,
    },
  }));

export default useStyles;
