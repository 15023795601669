import React from 'react';
import { Svg, Rect, Path } from '@react-pdf/renderer';

const convertSvgToReactPDF = () => {
  return (
    <Svg style={{ width: 32, height: 32, marginRight: 8 }} viewBox='0 0 32 32'>
      <Rect x='0.5' y='0.5' width='31' height='31' rx='5.5' ry='5.5' stroke='black' />
      <Path
        d='M24.01 19.38C22.78 19.38 21.59 19.18 20.48 18.82C20.13 18.7 19.74 18.79 19.47 19.06L17.9 21.03C15.07 19.68 12.42 17.13 11.01 14.2L12.96 12.54C13.23 12.26 13.31 11.87 13.2 11.52C12.83 10.41 12.64 9.22 12.64 7.99C12.64 7.45 12.19 7 11.65 7H8.19C7.65 7 7 7.24 7 7.99C7 17.28 14.73 25 24.01 25C24.72 25 25 24.37 25 23.82V20.37C25 19.83 24.55 19.38 24.01 19.38Z'
        fill='#0F141A'
        fillOpacity='0.87'
      />
    </Svg>
  );
};

export default convertSvgToReactPDF;
