import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useStyles from './input-view-styles';

const InputView = ({ value, onUpdate, ...rest }) => {
  const classes = useStyles();
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const handleBlur = event => {
    event.preventDefault();
    if (!event.currentTarget.contains(event.relatedTarget)) {
      onUpdate(localValue);
    }
  };

  return (
    <form
      onSubmit={event => {
        event.preventDefault();
        onUpdate(localValue);
      }}
    >
      <input
        className={classes.input}
        value={localValue}
        onChange={event => setLocalValue(event.target.value)}
        onBlur={handleBlur}
        {...rest}
      />
    </form>
  );
};

InputView.propTypes = {
  /** Initial value to show on the input field */
  value: PropTypes.string,
  /** onUpdate callback function  */
  onUpdate: PropTypes.func,
};

InputView.defaultProps = {
  value: '',
  onUpdate: () => {},
};

export default InputView;
