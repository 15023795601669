import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { ReactComponent as AddIcon } from 'assets/icons/systemicons/add_small.svg';
import { Button } from 'components/buttons';

const ButtonWrapper = styled('div')`
  margin-bottom: 16px;
  padding-top: 8px;
`;

const Footer = ({ onClickHandler, shouldDisable }) => (
  <ButtonWrapper>
    <Button
      ariaLabel="Add"
      autoWidth
      padding={40}
      disabled={shouldDisable}
      onClick={onClickHandler}
      startIcon={<AddIcon />}
      usage="outlined"
      variant="outlined"
    >
      Add
    </Button>
  </ButtonWrapper>
);

Footer.propTypes = {
  /** Callable for the footer button */
  onClickHandler: PropTypes.func.isRequired,
  shouldDisable: PropTypes.bool,
};

Footer.defaultProps = {
  shouldDisable: false,
};

export default Footer;
