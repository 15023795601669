import gql from 'graphql-tag';

export default gql`
  mutation updateGroupPermissions($input: UpdateGroupPermissionsInput) {
    updateGroupPermissions(input: $input) {
      groupPermissions {
        mId
        mRefId
        mTitle
        mProperties {
          __typename
          ... on GroupPolicy {
            policies {
              resourceName
              permissions {
                action
                access
              }
            }
            userCount
          }
        }
      }
    }
  }
`;
