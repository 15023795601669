import { memo, MouseEventHandler, useCallback } from 'react';
import styled from '@emotion/styled';

import { useSetPreview } from 'store/preview';
import { MemberTypeEnum } from 'types/graphqlTypes';
import accessibleOnClick from 'utils/accessibleOnClick';

const StyledAnchor = styled('a')<{ shouldShowPreviewOnClick?: boolean }>`
  color: ${({ theme }) => theme.palette.dina.url};
  text-decoration: underline;
  cursor: ${(props) => (props.shouldShowPreviewOnClick ? 'pointer' : 'text')};
`;

interface LinkedInstanceProps {
  instanceId: string;
  attributes: Record<string, string>;
  children: React.ReactNode;
  shouldShowPreviewOnClick?: boolean;
}

const LinkedInstance = ({
  instanceId,
  attributes,
  children,
  shouldShowPreviewOnClick = false,
}: LinkedInstanceProps) => {
  const setPreview = useSetPreview();

  const onClick: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (e) => {
      if (!shouldShowPreviewOnClick) return;
      // if the editor is in open mode we will not open the preview
      // instead we will show the hovering toolbar.
      e.preventDefault();
      e.stopPropagation();
      setPreview({ mId: instanceId, mRefId: instanceId, mType: MemberTypeEnum.Instance });
    },
    [instanceId, setPreview, shouldShowPreviewOnClick],
  );

  return (
    <StyledAnchor
      {...attributes}
      {...accessibleOnClick(onClick, 'presentation')}
      shouldShowPreviewOnClick={shouldShowPreviewOnClick}
    >
      {children}
    </StyledAnchor>
  );
};

export default memo(LinkedInstance);
