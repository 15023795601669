import Text from 'components/text';

import { ErrorWrapper, Pre, PreWrapper } from '../styled';

const ErrorView = ({ errors }: { errors: Record<string, unknown> }) => {
  return (
    <ErrorWrapper>
      <Text variant="caption" color="statusError">
        Encountered following error while printing
      </Text>
      <PreWrapper>
        <Pre>{JSON.stringify(errors, null, 2)}</Pre>
      </PreWrapper>
    </ErrorWrapper>
  );
};

export default ErrorView;
