import Tooltip from 'components/tooltip';

interface Props {
  children: React.ReactElement;
  disableEdit?: boolean;
  readonly?: boolean;
  forceAllowPointerEvents?: boolean;
}
function TooltipWrapper({
  children,
  disableEdit,
  readonly,
  forceAllowPointerEvents,
}: Readonly<Props>) {
  if (readonly) {
    return <div style={{ pointerEvents: 'none' }}>{children}</div>;
  }

  if (disableEdit) {
    return (
      <Tooltip title="You don't have permission to edit.">
        <div>
          <div style={{ pointerEvents: forceAllowPointerEvents ? 'inherit' : 'none' }}>
            {children}
          </div>
        </div>
      </Tooltip>
    );
  }

  return children;
}

export default TooltipWrapper;
