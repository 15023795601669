const fieldTypes = {
  SELECT: 'select',
  MENU: 'menu',
  BOOLEAN: 'boolean',
  FUNCTION: 'function',
  TEXT: 'text',
  NUMBER: 'number',
  GRID: 'grid',
  COMBOBOX: 'combobox',
  SHORTCUT: 'shortcut',
};

export default fieldTypes;
