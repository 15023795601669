const inTimingTypes = {
  MANUAL_IN: 'manual-in',
  AUTO_IN: 'auto-in',
};

const outTimingTypes = {
  MANUAL_OUT: 'manual-out',
  ITEM_OUT: 'item-out',
  INSTANCE_OUT: 'instance-out',
  AUTO_OUT: 'auto-out',
};

export { inTimingTypes, outTimingTypes };
