import styled from '@emotion/styled';

export const TextAreaStyled = styled('textarea')<{
  height?: number | string;
}>`
  ${({ theme }) => theme.typography.dina.listItemLabel};
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  height: ${({ height }) => (height ? `${height}px` : 'auto')};
  width: 100%;
  border-radius: 6px;
  resize: vertical;
  border: 1px solid ${({ theme }) => theme.palette.dina.inputBorderResting};
  padding-block: 8px;
  background-color: ${({ theme }) => theme.palette.dina.inputBackground};
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  padding-inline: 8px;
  ::placeholder {
    color: ${({ theme }) => theme.palette.dina.watermark};
  }
  :focus-visible {
    outline: none;
    border-color: ${({ theme }) => theme.palette.dina.inputBorderFocused};
    background: ${({ theme }) => theme.palette.dina.inputBackgroundFocused};
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;
