import styled from '@emotion/styled';
import { List as MuiList, MenuItem as MuiMenuItem, Paper, Typography } from '@material-ui/core';

import { Position } from '../../hooks/useCombobox';

interface ListWrapperProps {
  position: Position<string>;
}

export const ListWrapper = styled(Paper)<ListWrapperProps>`
  position: absolute;
  ${({ position }) =>
    position.bottom
      ? {
          bottom: position.bottom,
          left: position.left,
        }
      : {
          top: position.top,
          left: position.left,
        }};
  z-index: 2000;
  background-color: ${({ theme }) => theme.palette.dina.surfaceDialogs};
`;

export const List = styled(MuiList)`
  border-radius: 4px;
`;

export const MenuItem = styled(MuiMenuItem)`
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
`;

export const Title = styled(Typography)`
  margin-left: 8px;
`;
