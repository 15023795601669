import { useContext } from 'react';
import keyBy from 'lodash/keyBy';

import { BlockWithLabelAndMdf, MdfsSeparated } from 'api/mdfBlocks/types';
import { useSearch } from 'api/search';
import UserContext from 'contexts/UserContext';
import { CommandToolbarProps } from 'features/command/command-types';
import { useGetMembersFromFieldValue } from 'features/gridDeck/api/useBatchGetItems';
import { MiniMember, OrderWithMdf } from 'types/forms/forms';
import { Metadata, SearchItemTypeEnum } from 'types/graphqlTypes';
import { findContactIdsInMetadata } from 'utils/mdf/findContactIdsInMetadata';
import { findRelationMembersInMetadata } from 'utils/mdf/findRelationMembersInMetadata';

const baseToolbarState: CommandToolbarProps = {
  sortBy: 'createdAt',
  order: 'desc',
  rangeBy: null,
  defaultMdfId: null,
  mdfId: '',
  isFiltering: false,
  statusFilter: [],
  mTypes: [SearchItemTypeEnum.contact],
  assignedIds: [],
  createdByIds: [],
};

const useGetContactsAndRelationMembers = (
  blocksWithMdf: BlockWithLabelAndMdf[] = [],
  tasksWithMdf: OrderWithMdf[] = [],
  subTasksWithMdf: OrderWithMdf[] = [],
  mdfsSeparated?: MdfsSeparated,
) => {
  const { groups } = useContext(UserContext);

  const subTypes = keyBy(mdfsSeparated?.subTypes, (mdf) => mdf.label);

  const contactMdf = mdfsSeparated?.defaults.find((mdf) =>
    mdf.id.toLowerCase().includes('contact'),
  );

  const items = [...blocksWithMdf, ...tasksWithMdf, ...subTasksWithMdf];

  const { contacts: contactIds, relationMembers: relationMiniMembers } = items.reduce(
    (acc, item) => {
      const { mdf, metadata } = item;

      acc.contacts.push(...findContactIdsInMetadata(mdf, metadata, groups, subTypes));
      acc.relationMembers.push(...findRelationMembersInMetadata(mdf, metadata, groups, subTypes));

      return acc;
    },
    { contacts: [] as string[], relationMembers: [] as MiniMember[] },
  );

  const relationMembers = Array.from(relationMiniMembers.values());

  const {
    members: relationMembersData,
    loading: relationsLoading,
    error: relationsError,
  } = useGetMembersFromFieldValue(relationMembers);

  const {
    items: contactsData,
    loading: contactsLoading,
    error: contactsError,
  } = useSearch({
    skip: false,
    searchString: '',
    mIds: contactIds,
    toolbarState: baseToolbarState,
  });

  const { contacts: connectedContacts, relationMembers: connectedRelationMembers } = [
    ...contactsData,
    ...relationMembersData,
  ].reduce(
    (acc, member) => {
      if (!member.metadata) return acc;

      let metadata: Metadata;
      try {
        metadata = JSON.parse(member.metadata) as Metadata;
      } catch {
        return acc;
      }

      acc.contacts.push(...findContactIdsInMetadata(contactMdf, metadata, groups, subTypes));
      acc.relationMembers.push(
        ...findRelationMembersInMetadata(contactMdf, metadata, groups, subTypes),
      );

      return acc;
    },
    { contacts: [] as string[], relationMembers: [] as MiniMember[] },
  );

  const {
    members: connectedRelationMembersData,
    loading: connectedRelationsLoading,
    error: connectedRelationsError,
  } = useGetMembersFromFieldValue(connectedRelationMembers);

  const {
    items: connectedContactsData,
    loading: connectedContactsLoading,
    error: connectedContactsError,
  } = useSearch({
    skip: false,
    searchString: '',
    mIds: connectedContacts,
    toolbarState: baseToolbarState,
  });

  const allRelationFields = [...relationMembersData, ...connectedRelationMembersData];

  const allContacts = [...contactsData, ...connectedContactsData];

  return {
    relationMembers: allRelationFields,
    relationsLoading: relationsLoading || connectedRelationsLoading,
    relationsError: relationsError || connectedRelationsError,
    contacts: allContacts,
    contactsLoading: contactsLoading || connectedContactsLoading,
    contactsError: contactsError || connectedContactsError,
  };
};

export default useGetContactsAndRelationMembers;
