import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

import { MIdRefId } from 'types/members';

import { removeFromOrderFormCache } from './handleOrderFormCache';

const DELETE_MEMBER = gql`
  mutation DeleteMember($input: DeleteSingleMemberInput) {
    deleteSingleMember(input: $input) {
      mId
      mRefId
    }
  }
`;

interface DeleteOrderForm {
  deleteSingleMember: MIdRefId;
}

export const useDeleteOrderForm = () => {
  const [deleteMember] = useMutation<DeleteOrderForm>(DELETE_MEMBER);
  const deleteOrderForm = useCallback(async (mId: string, mRefId: string) => {
    await deleteMember({
      variables: { input: { mId, mRefId } },
      update(proxy) {
        removeFromOrderFormCache(proxy, mRefId, mId);
      },
    });
  }, []);

  return { deleteOrderForm };
};
