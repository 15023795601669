import { Flex } from 'layouts/box/Box';

import { Input } from './components/Input';
import { useMultiSelect } from './hooks/useMultiSelect';
import { type MultiSelectInputProps } from './types';

/** Multi-select input component. */
export const MultiSelectInput = ({
  disabled,
  hidePlaceholderWhenSelected,
  inputProps,
  placeholder,
}: Readonly<MultiSelectInputProps>) => {
  const {
    debouncedSearchTerm,
    inputRef,
    inputValue,
    mouseOn,
    onSearch,
    selected,
    setInputValue,
    setOpen,
    triggerSearchOnFocus,
  } = useMultiSelect();

  return (
    <div
      onClick={() => {
        if (disabled) return;
        inputRef.current?.focus();
      }}
    >
      <Flex
        gap="2px"
        flexWrap="wrap"
        justifyContent="flex-start"
        position="relative"
        padding="0 0 0 8px"
      >
        <Input
          {...{
            debouncedSearchTerm,
            disabled,
            hidePlaceholderWhenSelected,
            inputProps,
            inputRef,
            inputValue,
            mouseOn,
            onSearch,
            placeholder,
            selected,
            setInputValue,
            setOpen,
            triggerSearchOnFocus,
          }}
        />
      </Flex>
    </div>
  );
};
