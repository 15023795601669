import gql from 'graphql-tag';

export default gql`
  mutation AuditFailure($input: AddAuditErrorInput) {
    addAuditError(input: $input) {
      mId
      mActionId
      mType
    }
  }
`;
