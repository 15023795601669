/* eslint-disable import/no-extraneous-dependencies */
import filter from 'lodash/filter';
import isString from 'lodash/isString';
import values from 'lodash/values';

/**
 * Gets type values given type object
 *
 * @param {Object} types SlateJS node types enum
 * @returns {String[]} SlateJS node type values
 */

const getTypeValues = (types) => filter(values(types), isString);

export default getTypeValues;
