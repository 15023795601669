import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import InputField from 'components/inputField';
import Box from '../box';

const Input = memo(InputField);

export const InputWrapper = styled('div')`
  margin-bottom: 16px;
`;

const InputBlock = ({
  readOnly,
  value,
  onChange,
  icon,
  label,
  description,
  placeholder,
  hideEllipsisButton,
  collapsed,
  collapsedContent,
  updateCollapsed,
  direction,
  ...rest
}) => (
  <Box
    iconComponent={icon}
    title={label}
    hideEllipsisButton={hideEllipsisButton}
    readOnly={readOnly}
    collapsed={collapsed}
    collapsedContent={collapsedContent}
    updateCollapsed={updateCollapsed}
  >
    <InputWrapper>
      <Input
        description={description}
        disableLabel
        onChange={onChange}
        value={value}
        usage="editor"
        disabled={readOnly}
        placeholder={placeholder}
        direction={direction}
        {...rest}
      />
    </InputWrapper>
  </Box>
);

InputBlock.propTypes = {
  /**
   * whether the field is readonly or not
   */
  readOnly: PropTypes.bool,
  /**
   * value of the input field
   */
  value: PropTypes.string,
  /**
   * setValue callback for the input field
   */
  onChange: PropTypes.func,
  /**
   * icon to show on the box
   */
  icon: PropTypes.element,
  /**
   * label of the input field
   */
  label: PropTypes.string,
  /**
   * assitive text to show below the field
   */
  description: PropTypes.string,
  /**
   * placeholder value
   */
  placeholder: PropTypes.string,
};

InputBlock.defaultProps = {
  readOnly: false,
  value: '',
  onChange: () => {},
  icon: '',
  label: '',
  description: '',
  placeholder: '',
};

export default memo(InputBlock);
