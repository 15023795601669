import styled from '@emotion/styled';

import { StyledTextField } from 'components/mdfEditor/fields/text/styled';

export const InputLabel = styled('div')`
  font-size: 10px;
  margin-bottom: 4px;
`;

export const BodyWrapper = styled('div')`
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

export const InputWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
`;

export const Input = styled(StyledTextField)`
  width: 100%;
`;
