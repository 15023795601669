import React, { useMemo, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { useSlate } from 'slate-react';
import { mediaTypes } from 'utils/rundownItemTypes';
import { useEditorContext } from 'components/editor/EditorContext';
import { elementTypes } from 'components/editor/constants/types';
import DropZone from 'components/editor/components/dropZone';
import addGraphics from './utils/addGraphics';
import replaceGraphics from './utils/replaceGraphics';

const { AUDIO, OVERLAY_GRAPHICS, ACCESSORY } = elementTypes;
const { GRAPHICS } = mediaTypes;

const GraphicsDropZone = ({ children, element, canDropGraphics }) => {
  const editor = useSlate();
  const { update } = useEditorContext();
  const { type } = element;

  const onDrop = useCallback(
    ({ payload }) => {
      if (type === OVERLAY_GRAPHICS) replaceGraphics(editor, element, payload, update);

      if (type === ACCESSORY) addGraphics(editor, element, payload, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  const graphicsDropZoneAcceptedTypes = useMemo(() => {
    if (type === AUDIO) return [];
    const acceptedTypes = [];
    if (canDropGraphics || type === OVERLAY_GRAPHICS) acceptedTypes.push(GRAPHICS);
    return acceptedTypes;
  }, [canDropGraphics, type]);

  return (
    <DropZone accept={graphicsDropZoneAcceptedTypes} {...{ onDrop, element }}>
      {children}
    </DropZone>
  );
};

GraphicsDropZone.propTypes = {
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

GraphicsDropZone.defaultProps = {
  children: null,
  element: {
    children: [],
  },
};

export default memo(GraphicsDropZone);
