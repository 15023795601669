import { ApolloCache } from '@apollo/client';

import { SavedSearchEntity, VisiblePlaces } from 'types/graphqlTypes';

import { GET_SAVED_SEARCHES } from './gql';
import { GetSavedSearches } from './useGetSavedSearch';

const visiblePlaces: VisiblePlaces[] = ['contacts_sidebar', 'left_sidebar'];

const writeToLocation = (
  client: ApolloCache<unknown>,
  newSearch: SavedSearchEntity,
  canAdministrate: boolean,
  location?: VisiblePlaces,
) => {
  const cachedSearches = client.readQuery<GetSavedSearches>({
    query: GET_SAVED_SEARCHES,
    variables: {
      input: {
        all: canAdministrate,
        location,
      },
    },
  });

  const updated = [
    newSearch,
    ...(cachedSearches?.getSavedSearches ?? []).filter((c) => c.id !== newSearch.id),
  ];

  client.writeQuery({
    query: GET_SAVED_SEARCHES,
    variables: {
      input: {
        all: canAdministrate,
        location,
      },
    },
    data: {
      getSavedSearches: updated,
    },
  });
};

const doRemove = (
  client: ApolloCache<unknown>,
  id: string,
  canAdministrate: boolean,
  location?: VisiblePlaces,
) => {
  const cachedSearches = client.readQuery<GetSavedSearches>({
    query: GET_SAVED_SEARCHES,
    variables: {
      input: {
        all: canAdministrate,
        location,
      },
    },
  });

  const updated = (cachedSearches?.getSavedSearches ?? []).filter((c) => c.id !== id);
  client.writeQuery({
    query: GET_SAVED_SEARCHES,
    variables: {
      input: {
        all: canAdministrate,
        location,
      },
    },
    data: {
      getSavedSearches: updated,
    },
  });
};

export const writeToCache = (
  client: ApolloCache<unknown>,
  newSearch: SavedSearchEntity,
  canAdministrate: boolean,
) => {
  // Default
  writeToLocation(client, newSearch, canAdministrate);

  // Ensure cache for various locations updates too
  if (newSearch.visibleIn?.length) {
    newSearch.visibleIn.forEach((s) => writeToLocation(client, newSearch, canAdministrate, s));
  }

  // In case visibleIn has changed, remove from caches it's no longer part of
  for (const v of visiblePlaces) {
    if (!newSearch.visibleIn?.includes(v)) {
      doRemove(client, newSearch.id, canAdministrate, v);
    }
  }
};

export const removeFromCache = (
  client: ApolloCache<unknown>,
  id: string,
  canAdministrate: boolean,
) => {
  doRemove(client, id, canAdministrate);
  visiblePlaces.forEach((v) => doRemove(client, id, canAdministrate, v));
};
