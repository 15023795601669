import { useState } from 'react';

import { Button } from 'components/buttons';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import { EditorValue } from 'types';

import { useVersionHistoryMolecule } from '../../atoms';

import { Wrapper } from './styled';

interface DialogFooterProps {
  isProcessingSubmit: boolean;
  restricted?: boolean;
  onCancel: () => void;
  onOk: (content: EditorValue) => Promise<void>;
}

function DialogFooter({
  onCancel,
  onOk,
  isProcessingSubmit,
  restricted,
}: Readonly<DialogFooterProps>) {
  const { useSelectedContent, useCurrentAudit } = useVersionHistoryMolecule();
  const [selectedContent] = useSelectedContent();

  const [submitConfirmed, setSubmitConfirmed] = useState(false);
  const [currentAudit] = useCurrentAudit();
  const { contentKey } = currentAudit ?? {};

  const handleSubmit = () => {
    if (!submitConfirmed) {
      setSubmitConfirmed(true);
    } else {
      onOk(selectedContent as EditorValue)
        .then(() => setSubmitConfirmed(false))
        .catch(() => {});
    }
  };

  return (
    <Wrapper>
      <Button variant="outlined" usage="outlined" width={192} onClick={onCancel}>
        Cancel
      </Button>
      <Button
        variant="contained"
        usage={submitConfirmed ? 'danger' : 'warning'}
        width={192}
        onClick={handleSubmit}
        disabled={!currentAudit || !contentKey || restricted}
        ariaLabel="Restore instance version"
      >
        {isProcessingSubmit && <LoadingIndicator width={24} height={24} />}
        {!isProcessingSubmit && submitConfirmed && 'Click again to Restore'}
        {!isProcessingSubmit && !submitConfirmed && 'Restore this version'}
      </Button>
    </Wrapper>
  );
}

export default DialogFooter;
