import { Svg, Rect, Path, Defs, ClipPath, G } from '@react-pdf/renderer';

const DveIcon = () => {
  return (
    <Svg style={{ width: 32, height: 32, marginRight: 8, borderRadius: 5.5 }} viewBox='0 0 32 32'>
      <G clipPath='url(#clip0_2040_49)'>
        <Path d='M32 32L0 32L32 0L32 32Z' fill='#FFBE55' />
        <Path d='M0 0H32L0 32V0Z' fill='#E459CE' />
      </G>
      <Rect x='0.5' y='0.5' width='31' height='31' rx='5.5' ry='5.5' stroke='black' />
      <Path
        d='M25.6927 20.2406C25.9068 20.3342 26.157 20.237 26.2424 20.0195C26.7314 18.7744 26.9999 17.4186 26.9999 16C26.9999 9.92487 22.0751 5 15.9999 5C14.5813 5 13.2254 5.26854 11.9803 5.75757C11.7629 5.84295 11.6657 6.09324 11.7594 6.30722C11.853 6.52137 12.1024 6.61819 12.3202 6.53345C13.4611 6.08965 14.7021 5.84615 15.9999 5.84615C21.6078 5.84615 26.1538 10.3922 26.1538 16C26.1538 17.2978 25.9103 18.5387 25.4665 19.6796C25.3818 19.8975 25.4785 20.1469 25.6927 20.2406Z'
        fill='#0F141A'
        fillOpacity='0.87'
      />
      <Path
        d='M22.5911 18.8836C22.8051 18.9773 23.0558 18.8801 23.1374 18.6612C23.4464 17.8328 23.6153 16.9361 23.6153 16C23.6153 11.7941 20.2058 8.38462 15.9999 8.38462C15.0638 8.38462 14.1671 8.55353 13.3387 8.86253C13.1198 8.94418 13.0227 9.1948 13.1163 9.40885C13.21 9.62305 13.4593 9.71932 13.6789 9.63916C14.4029 9.37493 15.1846 9.23077 15.9999 9.23077C19.7385 9.23077 22.7692 12.2615 22.7692 16C22.7692 16.8154 22.625 17.5971 22.3608 18.321C22.2806 18.5406 22.3769 18.7899 22.5911 18.8836Z'
        fill='#0F141A'
        fillOpacity='0.87'
      />
      <Path
        d='M8.19389 23.8061C12.3521 27.9643 19.0331 28.0623 23.3101 24.0999C23.4815 23.9411 23.4814 23.6722 23.3162 23.5069L18.8947 19.0855C19.7171 18.3136 20.2308 17.2168 20.2308 16C20.2308 13.6634 18.3366 11.7692 16 11.7692C14.7832 11.7692 13.6864 12.2829 12.9145 13.1053L8.49306 8.68378C8.32783 8.51855 8.05887 8.51845 7.90008 8.68986C3.93773 12.9669 4.03567 19.6479 8.19389 23.8061Z'
        fill='#0F141A'
        fillOpacity='0.87'
      />
      <Defs>
        <ClipPath id='clip0_2040_49'>
          <Rect width='32' height='32' rx='6' fill='white' />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default DveIcon;
