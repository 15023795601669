const setExtUrls = (extUrlResult, setExternalUrls) => {
  const getValue = (configs, key) => {
    const item = configs.find((c) => c.key === key);
    if (item) return item.value;
    return undefined;
  };

  const externalUrls = [
    ...extUrlResult.data.getMemberFromId.map(({ configs, mTitle, mRefId }) => ({
      id: mRefId,
      label: mTitle,
      type: configs[0].type,
      externalUrl: configs[0].value,
      mTitle: configs[0].name,
      panelIcon: getValue(configs, 'panelIcon'),
      mosId: getValue(configs, 'mosId'),
      mosObjectType: getValue(configs, 'mosObjectType'),
      iconUrl: getValue(configs, 'iconUrl'),
      iconXmlTag: getValue(configs, 'iconXmlTag'),
      showInRightPanel: getValue(configs, 'showInRightPanel'),
    })),
  ];
  setExternalUrls(externalUrls);
};

export default setExtUrls;
