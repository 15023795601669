import { Row, TableMeta } from '@tanstack/react-table';

import Tooltip from 'components/tooltip';
import { ParsedMemberType } from 'types/members';

import { RemoveIcon } from './styled';

export interface ExtendedTableMeta extends TableMeta<ParsedMemberType> {
  onRemove: (member: ParsedMemberType) => void;
}

interface Props {
  row: Row<ParsedMemberType>;
  tableMeta: ExtendedTableMeta;
}

/** Row actions component */
export function RowActions({ row, tableMeta }: Readonly<Props>) {
  const { onRemove } = tableMeta;

  return (
    <Tooltip title="Remove">
      <RemoveIcon onClick={() => onRemove(row.original)} />
    </Tooltip>
  );
}
