import { durationTypes } from 'screens/rundown/components/editor/utils';

/**
 * Checks the given existing value is null
 * if not, returns fallback
 *
 * @param {*} existingValue
 * @param {*} param1
 * @returns {String}
 */
const checkNull = (existingValue, { value: defaultValue }) =>
  existingValue === null ? defaultValue : existingValue;

/**
 * finds field value key from given mMetadata array
 *
 * @param {*} mMetaData
 * @param {*} fieldId
 * @returns {String}
 */
const findFieldValue = (mMetaData, fieldId) =>
  mMetaData?.find(({ key }) => key === fieldId)?.value || null;

/**
 * For all objects of mMetadata array return pairs by the fields array
 * For only script duration Object, returns { key, value, autoValue, manual }
 *
 * @param {*} mMetaData
 * @param {*} fields
 * @returns {Array}
 */
const returnFieldPairs = (mMetaData, fields) =>
  fields?.map((field) => {
    const isSpeakDurationField = field.key?.includes(durationTypes.SPEAK_DURATION);

    if (isSpeakDurationField) {
      const { value, manual, autoValue } = mMetaData?.find(({ key }) => key === field.id) || {};
      return {
        key: field.id,
        value: checkNull(value, field),
        manual: checkNull(manual, field),
        autoValue: checkNull(autoValue, field),
      };
    }

    const value = findFieldValue(mMetaData, field.id);
    return {
      key: field.id,
      value: checkNull(value, field),
    };
  }) || [];

export default returnFieldPairs;
