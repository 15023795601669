import styled from '@emotion/styled';

import Text from 'components/text/Text';
import { HStack, VStack } from 'layouts/box/Box';

export const SearchResultList = styled('div')`
  overflow: auto;
  width: 100%;
`;

export const Wrapper = styled(VStack)`
  height: 100%;
  padding: 50px 3px 3px 3px;
  justify-content: start;
`;

export const HeaderWrapper = styled(HStack)`
  position: absolute;
  top: 0;
  gap: 8px;
  padding: 10px 8px;
  width: 100%;
  z-index: 1;
`;

export const ItemDate = styled(Text)`
  padding: 2px 0;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ItemFooter = styled(HStack)`
  ${({ theme }) => theme.typography.dina.caption};
  align-content: center;
  width: 100%;
  justify-content: end;
  gap: 4px;
  margin-top: 4px;
  svg path {
    fill-opacity: 0.7;
  }
`;

export const ItemContentWrapper = styled(VStack)`
  width: 100%;
`;

export const ItemTitleWrapper = styled(HStack)`
  width: 100%;
  gap: 4px;
`;

export const ItemTitle = styled('div')`
  ${({ theme }) => theme.typography.dina.listItemLabelBold};
  width: 100%;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ItemDescription = styled('div')`
  ${({ theme }) => theme.typography.dina.caption};
  margin-top: 4px;
  position: relative;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const IntersectionObserver = styled('div')`
  height: 20px;
  width: 100%;
  pointer-events: none;
`;

export const SearchDeckItemWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 4px;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.palette.dina.hoverOverlay};
  }
  em {
    font-style: normal;
    color: ${({ theme }) => theme.palette.dina.statusSearchMatchBG};
  }
`;
