import { Svg, Rect, Path } from '@react-pdf/renderer';

const AdlibIcon = () => {
  return (
    <Svg style={{ width: 32, height: 32, marginRight: 8 }} viewBox='0 0 32 32'>
      <Rect x='0.5' y='0.5' width='31' height='31' rx='5.5' ry='5.5' stroke='black' />
      <Path d='M11 10H15V22H11V10Z' fill='#0F141A' fillOpacity='0.87' />
      <Path d='M17 10H21V22H17V10Z' fill='#0F141A' fillOpacity='0.87' />
    </Svg>
  );
};

export default AdlibIcon;
