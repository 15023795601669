const normalizeIntoGalleryItemData = (data) => ({
  mId: data.mId,
  mRefId: data.mRefId,
  mTitle: data.title,
  mProvider: data.provider,
  itemDuration: data.itemDuration,
  itemType: data.itemType,
  mediaType: data.mediaType || data.itemType,
  source: data.source,
  caption: data.caption,
  index: data.index,
});

export default normalizeIntoGalleryItemData;
