import { createScope, molecule, useMolecule } from 'jotai-molecules';

export const RundownScope = createScope<string | null>(null);

const rundownMolecule = molecule((_getMol, getScope) => {
  const scope = getScope(RundownScope);

  return { scope };
});
export const useRundownMolecule = () => useMolecule(rundownMolecule);
