import * as React from 'react';

import { Range, Root, Thumb, Track } from './styled';

const Slider = React.forwardRef<
  React.ElementRef<typeof Root>,
  React.ComponentPropsWithoutRef<typeof Root>
>(({ ...props }, ref) => (
  <Root ref={ref} {...props}>
    <Track>
      <Range />
    </Track>
    <Thumb />
  </Root>
));

Slider.displayName = Root.displayName;

export { Slider };
