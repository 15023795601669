/**
 *  Hook for unschedule instance state
 */

import { useMutation, useApolloClient } from '@apollo/client';
import UPDATE_INSTANCE from 'operations/mutations/updateInstance';
import UPDATE_STORY_INSTANCE from 'operations/mutations/updateStoryInstance';
import UPDATE_RUNDOWN_SYNC from 'operations/mutations/updateRundownSync';
import GET_INSTANCE_FROM_CACHE from 'operations/queries/getInstanceFromCache';
import GET_INSTANCE from 'operations/queries/getInstance';
import { getStoryInstancesQuery } from 'operations/queryVariables';
import memberTypes from 'operations/memberTypes';
import convertToUnassignedInstance from 'utils/instance/unassignedInstance';
import useLogger from 'utils/useLogger';

const useUnscheduleInstance = () => {
  const logger = useLogger('use unschedule instance');
  const client = useApolloClient();
  const [updateInstance] = useMutation(UPDATE_INSTANCE);
  const [updateStoryInstance] = useMutation(UPDATE_STORY_INSTANCE);
  const [updateRundownSync] = useMutation(UPDATE_RUNDOWN_SYNC);

  const updateCachedStoryInstances = (updatedInstances) => {
    updatedInstances.forEach((instance) => {
      if (instance.mId[0] === '-') return; // ignoring empty placeholders
      try {
        // Checking if updated instance is available in cache.
        const rootQuery = client.cache.data.data.ROOT_QUERY;
        // Query to read from cache.
        const queryInCache = `getMembersOfTypeBySecId({"input":{"mSecId":"${instance.mStoryId}",
          "mType":"${memberTypes.INSTANCE}"}})`;
        // If query doesn't exists in cache return.
        if (rootQuery[queryInCache] === undefined) {
          return;
        }

        const instanceQueryProps = {
          query: GET_INSTANCE,
          variables: getStoryInstancesQuery(instance.mStoryId),
        };

        const data = client.cache.readQuery(instanceQueryProps);
        const updatingInstanceIndex = data.getMembersOfTypeBySecId.findIndex(
          (storyInstance) => storyInstance.mId === instance.mId,
        );

        if (updatingInstanceIndex > -1) {
          const instanceData = data.getMembersOfTypeBySecId[updatingInstanceIndex];

          data.getMembersOfTypeBySecId[updatingInstanceIndex] =
            convertToUnassignedInstance(instanceData);

          client.cache.writeQuery({ ...instanceQueryProps, data: { ...data } });
        }
      } catch (error) {
        logger.log(error);
      }
    });
  };

  const removeInstancesFromRundown = async (
    instances,
    removeInstanceCallback,
    params,
    orderType,
    rundownId,
  ) => {
    if (!rundownId) return;
    try {
      const mPayload = instances
        .filter((instance) => {
          if (instance.mId[0] === '-') return true; // passing the empty placeholder
          const { mProperties } = instance;
          const { account, platform } = mProperties;
          const { accountId: smId } = account;
          const mId = smId || rundownId;
          return platform === 'linear' && mId;
        })
        .map((instance) => ({
          crudAction: 'REMOVE',
          value: {
            mId: instance.mId,
            destination: orderType,
          },
        }));

      const variables = {
        input: {
          mId: rundownId,
          mRefId: rundownId,
          mPayload,
        },
      };

      await updateRundownSync({
        variables,
      });

      updateCachedStoryInstances(instances);
      if (removeInstanceCallback) {
        removeInstanceCallback(params);
      }
    } catch (error) {
      logger.log(error);
    }
  };

  const unschedule = async (
    instance,
    removeInstanceCallback,
    params,
    shouldChangePlatform,
    orderType,
    rundownId,
    type,
  ) => {
    let instanceToRemove = instance;
    const { mId, mProperties } = instanceToRemove;

    if (!mProperties) {
      const data = client.readFragment({
        id: mId,
        fragment: GET_INSTANCE_FROM_CACHE,
      });

      instanceToRemove = data;
    }

    const input = shouldChangePlatform
      ? convertToUnassignedInstance(instanceToRemove)
      : { mId, mPublishingAt: null };

    const updateInstanceCallback =
      type && type === 'storyInstance' ? updateStoryInstance : updateInstance;

    updateInstanceCallback({
      variables: {
        input,
      },
    });
  };

  const unscheduleRundownInstances = async (
    instanceIds, // array of instance ids or single instance object
    removeInstanceCallback,
    params,
    orderType,
    rundownId,
  ) => {
    try {
      const instances = Array.isArray(instanceIds)
        ? instanceIds.map((mId) => {
            // generating dummy object for empty placeholders as we can not get them from cache.
            if (mId[0] === '-') {
              return { mId };
            }

            return client.readFragment({
              id: mId,
              fragment: GET_INSTANCE_FROM_CACHE,
            });
          })
        : [instanceIds];

      const destination = !orderType || orderType === 'preparing' ? 'mPreorder' : orderType;

      await removeInstancesFromRundown(
        instances,
        removeInstanceCallback,
        params,
        destination,
        rundownId,
      );
    } catch (error) {
      logger.log(error);
    }
  };

  return [unschedule, unscheduleRundownInstances];
};

export default useUnscheduleInstance;
