import { gql } from '@apollo/client';

const postEventMutation = gql`
  mutation PostEventMutation($input: PostEventInput) {
    postEvent(input: $input) {
      mId
    }
  }
`;

export default postEventMutation;
