import styled from '@emotion/styled';
import { Table, TableCell, TableHead } from '@material-ui/core';

import Text from 'components/text/Text';

export const HeaderText = styled(Text)`
  padding: 0.5rem;
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
`;

export const StyledListItem = styled(Text)<{ $selected: boolean }>`
  cursor: pointer;
  padding: 0.5rem 1rem;
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
  background-color: ${({ theme, $selected }) =>
    $selected ? theme.palette.dina.onSelected : 'transparent'};
  width: 100%;
  transition: all 0.1s ease-in-out;

  :hover {
    background-color: ${({ theme, $selected }) =>
      !$selected && theme.palette.dina.whiteHoverOverlay};
  }
`;

export const StyledTable = styled(Table)`
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

export const StyledTableHeader = styled(TableHead)`
  background-color: ${({ theme }) => theme.palette.dina.backgroundResting};
`;

export const StyledTableCell = styled(TableCell)`
  ${({ theme }) => theme.typography.dina.overline}
`;
