import gql from 'graphql-tag';

export default gql`
  mutation leaveConversation($input: LeaveConversationInput) {
    leaveConversation(input: $input) {
      mId
      mRefId
    }
  }
`;
