/* eslint-disable import/prefer-default-export */
export const parseJSON = (data) => {
  if (typeof data !== 'string') return data;
  let item;
  try {
    item = JSON.parse(data);
  } catch (e) {
    item = undefined;
  }
  return item;
};
