import { useState } from 'react';

import useFuseSearch from 'hooks/useFuseSearch';
import { useDepartments } from 'store/members';

import MemberList from './memberList/MemberList';
import Search from './Search';

function Departments() {
  const search = useFuseSearch();
  const [departments] = useDepartments();

  const [searchString, setSearchString] = useState('');
  const searchedDepartments = search(departments, ['mTitle'], searchString);

  return (
    <MemberList
      members={searchedDepartments}
      title="Departments"
      search={<Search searchString={searchString} setSearchString={setSearchString} />}
    />
  );
}

export default Departments;
