// eslint-disable-next-line import/prefer-default-export
export const humanReadableFileSize = (bytes) => {
  let workingBytes = bytes;

  if (isNaN(workingBytes)) {
    return 'unknown';
  }

  const segmentSize = 1024;
  if (Math.abs(workingBytes) < segmentSize) {
    return `${workingBytes} B`;
  }
  const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let u = -1;
  do {
    workingBytes /= segmentSize;
    // eslint-disable-next-line no-plusplus
    ++u;
  } while (Math.abs(workingBytes) >= segmentSize && u < units.length - 1);

  return `${workingBytes.toFixed(1)} ${units[u]}`;
};
