import gql from 'graphql-tag';

export default gql`
  mutation updateMember($input: UpdateMemberInput) {
    updateMember(input: $input) {
      mBookmarks {
        bookmarkedId
        bookmarkedType
      }
    }
  }
`;
