import { memo, useEffect } from 'react';
import { FetchPolicy } from '@apollo/client';

import useGetKanban from 'features/kanban/api/useGetKanban';
import ReadOnlyKanban from 'features/kanban/ReadOnly';
import useDidMount from 'hooks/useDidMount';
import { MemberType } from 'types/graphqlTypes';

type SelectedDatesType = {
  start: Date | null;
  end: Date | null;
};
interface Props {
  mId: string;
  members: MemberType[];
  customRenderItem?: () => void;
  selectedDates: SelectedDatesType;
}

interface GetKanbanProps {
  getKanban: ({
    mId,
    members,
    fetchPolicy,
  }: {
    mId: string;
    members: MemberType[];
    fetchPolicy: FetchPolicy;
  }) => void;
  loading: boolean;
  getKanbanWithoutLoading: ({ mId, members }: { mId: string; members: MemberType[] }) => void;
}

const ReadOnlyKanbanLoader = ({
  mId,
  members,
  customRenderItem,
  selectedDates,
}: Props): JSX.Element => {
  const didMount = useDidMount();
  const { getKanban, loading, getKanbanWithoutLoading }: GetKanbanProps = useGetKanban();

  useEffect(() => {
    getKanban({ mId, members, fetchPolicy: 'network-only' });
  }, [JSON.stringify(selectedDates)]);

  useEffect(() => {
    if (didMount) getKanbanWithoutLoading({ mId, members });
  }, [didMount, JSON.stringify(members)]);

  return (
    <ReadOnlyKanban
      loading={loading}
      customRenderItem={customRenderItem}
      customRenderHeader={undefined}
    />
  );
};

ReadOnlyKanbanLoader.displayName = 'ReadOnlyKanbanLoader';

const areEqual = (prevProps: Props, nextProps: Props) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
};

export default memo(ReadOnlyKanbanLoader, areEqual);
