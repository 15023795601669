import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as Close } from 'assets/icons/systemicons/close.svg';
import transientOptions from 'theme/helpers';

export const Wrapper = styled('div')`
  width: 100%;
  height: 100%;
  min-height: inherit;
  min-width: inherit;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  :focus {
    outline: none;
  }
`;

export const Header = styled('div')`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.palette.dina.dividerLight};
    background: ${theme.palette.dina.backgroundResting};
  `};
  height: 65px;
  display: flex;
  justify-content: space-between;
  :hover {
    cursor: grab;
  }
`;

export const HeaderText = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 15.5px 59px 0 16px;
  margin-bottom: 10px;
`;

export const HeaderTitle = styled(Typography)`
  ${({ theme }) => theme.typography.dina.overline};
  margin-bottom: 2px;
`;

export const Filename = styled(Typography)`
  ${({ theme }) => css`
    ${theme.typography.dina.subtitle1};
    color: ${theme.palette.dina.highEmphasis};
  `}
  overflow: hidden;
  width: 300px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
`;

export const AnimatedClose = styled('div')`
  padding-top: 15.5px;
  margin-right: 16px;
  transition: transform 0.2s;
  :hover {
    transform: scale(1.05);
  }
`;

export const AnimatedInfo = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  transition: transform 0.2s;
  :hover {
    transform: scale(1.05);
  }
`;

export const InfoOverlay = styled('div')<{ open: boolean }>`
  ${({ theme }) => css`
    background: ${theme.palette.dina.dialogBackdropOverlay};
    color: ${theme.palette.dina.whiteHighEmphasis};
  `}
  backdrop-filter: blur(30px);
  padding-left: 16px;
  height: 100%;
  transition: transform 0.2s;
  width: ${({ open }) => (open ? '400px' : 0)};
`;

export const CloseIcon = styled(Close)`
  :hover {
    path {
      fill-opacity: 1;
    }
  }
`;

export const Content = styled('div')`
  position: relative;
  overflow: hidden;
  flex: 1;
`;

export const InfoContainer = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
`;

interface BgImageProp {
  $image?: string;
}

export const BgImage = styled('div', transientOptions)<BgImageProp>`
  width: 100%;
  height: 100%;
  background: url(${({ $image }) => $image});
  background-size: cover;
`;

export const Image = styled('img')`
  width: 100%;
  height: 100%;
  backdrop-filter: blur(30px);
  object-fit: contain;
`;

export const InfoIconWrapper = styled('span')`
  height: 40px;
  width: 40px;
  background: ${({ theme }) => theme.palette.dina.dialogBackdropOverlay};
  ${(props: { isOpen: boolean }) =>
    props.isOpen
      ? css`
          position: absolute;
          right: 0;
          background: none;
        `
      : css`
          backdrop-filter: blur(30px);
          border-radius: 0 0 0 6px;
        `};
  path {
    fill: ${({ theme }) => theme.palette.dina.whiteHighEmphasis};
  }
  :hover {
    path {
      fill: ${({ theme }) => theme.palette.dina.whiteHighEmphasis};
      fill-opacity: 1;
    }
  }
`;

export const Footer = styled('div')`
  background: ${({ theme }) => theme.palette.dina.backgroundResting};
  min-height: 44px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 8px;
`;

/** metadata.js */
export const MetadataList = styled('ul')`
  margin: 0;
  height: auto;
  padding: 16px 0 10px 0;
`;

export const ListItem = styled('li')`
  display: flex;
  justify-content: row;
  margin-bottom: 6px;
`;

export const Key = styled(Typography)`
  ${({ theme }) => css`
    ${theme.typography.dina.captionMedium};
    color: ${theme.palette.dina.highEmphasis};
  `}
  width: 112px;
  margin-right: 8px;
  overflow-wrap: break-word;
  text-align: right;
`;

export const Value = styled(Typography)`
  ${({ theme }) => css`
    ${theme.typography.dina.caption};
    color: ${theme.palette.dina.highEmphasis};
  `}
  max-width: 240px;
  overflow-wrap: break-word;
`;
