import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { ReactComponent as FeedsOff } from 'assets/icons/systemicons/HeaderNavbar/feeds_off.svg';
import transientOptions from 'theme/helpers';

type Position = 'left' | 'right';

export const Container = styled('div', transientOptions)<{ $position?: Position }>`
  z-index: 100;
  position: absolute;
  flex: 1;
  height: 100%;
  width: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
  ${({ $position, theme }) => {
    if ($position === 'right') {
      return css`
        right: 0;
        border-left: 1px solid ${theme.palette.dina.dividerLight};
      `;
    }
    if ($position === 'left') {
      return css`
        left: 0;
        border-right: 1px solid ${theme.palette.dina.dividerLight};
      `;
    }
    return '';
  }}
`;

export const TabsWrapper = styled('div')`
  margin-block: auto;
`;

const roundIndicator = ({ theme }: { theme: Theme }) => css`
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  top: 19px;
  border-radius: 5px;
  background-color: ${theme.palette.dina.onFocus};
`;

export const StyledTabs = styled(Tabs, transientOptions)<{
  $hidden?: boolean;
  $position: Position;
}>`
  .MuiTabs-indicator {
    transition: none;
    width: 100%;
    background-color: ${({ $hidden, theme }) =>
      $hidden ? 'transparent' : theme.palette.dina.onFocus};
    ::after {
      ${({ $hidden }) => ($hidden ? roundIndicator : '')}
      ${({ $position }) => ($position === 'right' ? 'right: -5px' : 'left: -5px')};
    }
  }
`;

const dividerStyles = ({ theme }: { theme: Theme }) => css`
  content: '';
  position: absolute;
  margin-block: auto;
  height: 1px;
  width: 32px;
  background-color: ${theme.palette.dina.dividerLight};
  bottom: -9px;
`;

export const StyledTab = styled(Tab, transientOptions)<{
  $notificationCount: number;
  $divider?: boolean;
  $hasUpdate?: boolean;
  $hidden?: boolean;
}>`
  opacity: 1;
  overflow: visible;
  z-index: 1;
  min-width: 48px;
  width: 100%;
  height: 48px;
  margin-bottom: ${({ $divider }) => ($divider ? '17px' : '0px')};
  svg {
    path {
      fill: ${({ theme }) => theme.palette.dina.iconActive};
      fill-opacity: 0.65;
    }
  }

  &::before {
    content: ${({ $notificationCount, $hasUpdate }) =>
      $hasUpdate ? "''" : ($notificationCount > 0 && `'${$notificationCount}'`) || "''"};
    position: absolute;
    top: 10%;
    left: 10%;
    min-width: 16px;
    min-height: 16px;
    height: 10px;
    padding: 0 5px;
    border-radius: ${({ $hasUpdate }) => ($hasUpdate ? '50%' : '10%')};
    background-color: ${({ theme }) => theme.palette.dina.statusError}; /* Using theme color */
    display: ${({ $notificationCount, $hasUpdate }) =>
      $hasUpdate || $notificationCount > 0 ? 'flex' : 'none'};
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 10px;
    box-sizing: border-box;
    z-index: 2;
    letter-spacing: normal;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  }

  &::after {
    ${({ $divider }) => ($divider ? dividerStyles : '')}
  }

  &:hover,
  &.Mui-focusVisible {
    background-color: ${({ theme }) => theme.palette.dina.hoverOverlay};
    svg {
      path {
        fill: ${({ theme }) => theme.palette.dina.iconActive};
        fill-opacity: 1;
      }
    }
  }
  &.Mui-selected {
    ${({ $hidden, theme }) => css`
      svg {
        path {
          fill: ${$hidden ? theme.palette.dina.iconActive : theme.palette.dina.iconSelectedWhite};
          fill-opacity: ${$hidden ? 0.65 : 1};
        }
      }
      &:hover,
      &.Mui-focusVisible {
        background-color: ${$hidden ? theme.palette.dina.hoverOverlay : 'transparent'};
        svg {
          path {
            fill: ${$hidden ? theme.palette.dina.iconActive : theme.palette.dina.iconSelectedWhite};
            fill-opacity: 1;
          }
        }
      }
    `}
  }
`;

export const TickerButton = styled('div')`
  width: 48px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:focus {
    icon {
      color: ${({ theme }) => theme.palette.dina.iconSelectedWhite};
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.dina.hoverOverlay};

    svg {
      path {
        fill: ${({ theme }) => theme.palette.dina.iconSelectedWhite};
        fill-opacity: 1;
      }
    }
  }
`;

export const TickerIcon = styled(FeedsOff)`
  width: 20px;
  height: 20px;
`;

export const ButtonPlaceholder = styled('span')`
  height: 40px;
`;
export const StyledImage = styled('img', transientOptions)<{ $unselected?: boolean }>`
  max-width: 100%;
  height: auto;
  -webkit-filter: ${({ $unselected }) => ($unselected ? 'grayscale(40%)' : '')};
  filter: ${({ $unselected }) => ($unselected ? 'grayscale(40%)' : '')};
`;
