import React, { useState, useEffect } from 'react';
import { useAuthContext } from 'contexts/AuthContext';

import AuthAudit from './authAudit-view';

function AuthAuditContainer() {
  const { user, groups, urlParams } = useAuthContext();
  const [props, setProps] = useState({});

  useEffect(() => {
    setProps({ user, groups, urlParams });
  }, [user, groups, urlParams]);

  return <AuthAudit props={props} />;
}

export default AuthAuditContainer;
