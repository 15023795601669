/* eslint-disable sort-imports */
import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';

import { Alternative, MemberType } from 'types/graphqlTypes';
import useLogger from 'utils/useLogger';

import { getErrorMessage, STANDARD_AI_PROMPT_KEYS } from '../commonKeys';

import { convertToPrompts } from './useGetAiPrompts';

const UPDATE_PROMPTS = gql`
  mutation UpdatePrompts($input: UpdateMemberInput) {
    updateMember(input: $input) {
      ${STANDARD_AI_PROMPT_KEYS}
    }
  }
`;

interface UpdateMember {
  updateMember: MemberType;
}

export const useUpdatePrompts = () => {
  const [updateMutation] = useMutation<UpdateMember>(UPDATE_PROMPTS);
  const logger = useLogger('UpdatePrompts');
  const updatePrompts = useCallback(
    async (prompts: Alternative[]) => {
      try {
        const result = await updateMutation({
          variables: {
            input: {
              mId: 'ai-prompts',
              mRefId: 'ai-prompts',
              configs: [
                {
                  key: 'ai-prompts',
                  alternatives: prompts,
                },
              ],
            },
          },
        });
        return convertToPrompts(result?.data?.updateMember ?? null);
      } catch (err) {
        logger.log(getErrorMessage(err, prompts));
      }
    },
    [updateMutation],
  );
  return { updatePrompts };
};
