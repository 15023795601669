export type DroppedAsset = {
  id: string;
  title?: string;
  thumbnailUrl?: string;
  thumbnail?: string;
  itemDuration?: number;
  mediaDuration?: number;
  size?: number;
  extension?: string;
  provider?: string;
  itemType: string;
  mediaType?: string;
  proxy?: string;
  itemState?: string;
};

export type NormalizedAsset = {
  id: string;
  title?: string;
  src?: string;
  thumbnailUrl?: string;
  duration?: number;
  size?: number;
  extension?: string;
  provider?: string;
  itemType: string;
  assetType: string;
  mediaType: string;
  itemState?: string;
  proxy?: string;
};

const normalizeAssetData = (data: DroppedAsset): NormalizedAsset => ({
  id: data.id,
  title: data.title,
  src: data.thumbnailUrl,
  thumbnailUrl: data.thumbnailUrl || data.thumbnail,
  duration: data.itemDuration || data.mediaDuration,
  size: data.size,
  extension: data.extension,
  provider: data.provider,
  itemType: data.itemType,
  itemState: data.itemState,
  assetType: data.itemType,
  mediaType: data.mediaType || data.itemType,
  proxy: data.proxy,
});

export default normalizeAssetData;
