import { ReactComponent as More } from 'assets/icons/systemicons/more_vertical.svg';
import EllipsisDropdown from 'components/ellipsisDropdown';
import ListItem from 'components/ellipsisDropdown/listItem-view';

const RundownListDropdown = ({ toggleMasterRundown, anchorEl, setAnchorEl, padding }) => {
  const listItems = [
    <ListItem
      text="Edit Master Rundowns"
      key="edit-master-rundowns"
      onClick={toggleMasterRundown}
      excludeDivider
    />,
  ];

  return (
    <div>
      <EllipsisDropdown
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        listItems={listItems}
        IconComponent={<More className="skipOverride" />}
        padding={padding}
      />
    </div>
  );
};

export default RundownListDropdown;
