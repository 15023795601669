/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';

import bn from './locales/bn/translation.json';
import en from './locales/en/translation.json';
import no from './locales/no/translation.json';

i18next.use(initReactI18next).init({
  resources: {
    bn,
    en,
    no,
  },
  interpolation: {
    escapeValue: false, // according to documentation React takes care of XSS protection
  },
});
export default i18next;
