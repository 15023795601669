import { useState } from 'react';
import PropTypes from 'prop-types';
import ArrowRight from 'assets/icons/systemicons/arrows/disclosurearrow_right.svg';
import Scrollbar from 'components/scrollbar';
import Popover from 'components/popover/Popover';
import CategorizedMenu from './CategorizedMenu';
import {
  MUIMenuItem,
  MUIListItemIcon,
  SubInstanceWrapper,
  MUIListItemText,
  PopupContainer,
} from './instanceMenuItem-styled';

const InstanceMenuItem = ({
  label,
  children,
  noOfSubInstance,
  items,
  onClose,
  unassignedVariant,
  publishingAt,
  onDateChanged,
  isLinear = false,
  disableDecrement,
  onDoubleClick,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState(false);
  const [, setToggleRender] = useState(false);
  const handleClick = (event) => {
    if (noOfSubInstance === undefined) {
      onClose(items);
    } else {
      setAnchorEl(event.currentTarget);
      setSelected(true);
    }
  };

  return (
    <>
      <MUIMenuItem
        selected={selected}
        onClick={handleClick}
        onDoubleClick={onDoubleClick}
        $isUnassigned={unassignedVariant}
        {...rest}
      >
        <MUIListItemIcon>{children}</MUIListItemIcon>
        <MUIListItemText primary={label} $isTitle $isUnassigned={unassignedVariant} />
        {noOfSubInstance && (
          <SubInstanceWrapper>
            <MUIListItemText primary={noOfSubInstance} />
            <MUIListItemIcon>
              <img src={ArrowRight} alt="" />
            </MUIListItemIcon>
          </SubInstanceWrapper>
        )}
      </MUIMenuItem>
      {noOfSubInstance && (
        <Popover
          noMargin
          onClose={() => {
            setAnchorEl(null);
            setSelected(false);
          }}
          style={{ top: -8 }}
          position="right"
          anchorEl={anchorEl}
        >
          <Scrollbar autoHeight autoHeightMin={0} autoHeightMax="60vh" top={8} bottom={8}>
            <PopupContainer>
              {isLinear ? (
                <CategorizedMenu
                  onDateChanged={onDateChanged}
                  publishingAt={publishingAt}
                  disableDecrement={disableDecrement}
                  items={items}
                  onClose={(account) => {
                    setAnchorEl(null);
                    onClose(account);
                  }}
                  onDoubleClick={onDoubleClick}
                  dense
                  darker
                  setToggleRender={setToggleRender}
                >
                  {children}
                </CategorizedMenu>
              ) : (
                <>
                  {items.map((item, index) => (
                    <InstanceMenuItem
                      key={item.accountTitle + index.toString()}
                      label={item.accountTitle}
                      unassignedVariant={item.isUnassigned}
                      onClose={(account) => {
                        setAnchorEl(null);
                        onClose(account);
                      }}
                      onDoubleClick={onDoubleClick}
                      items={item}
                    >
                      {children}
                    </InstanceMenuItem>
                  ))}
                </>
              )}
            </PopupContainer>
          </Scrollbar>
        </Popover>
      )}
    </>
  );
};

InstanceMenuItem.propTypes = {
  label: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  noOfSubInstance: PropTypes.number,
  items: PropTypes.array,
  onClose: PropTypes.func,
  onDoubleClick: PropTypes.func,
  unassignedVariant: PropTypes.bool,
  publishingAt: PropTypes.string,
  onDateChanged: PropTypes.func,
  isLinear: PropTypes.bool,
  disableDecrement: PropTypes.bool,
};

export default InstanceMenuItem;
