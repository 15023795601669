import { atom } from 'jotai';

import { MemberType } from 'types/graphqlTypes';

// Define the structure of our objects
export interface NewItemObject {
  id: string;
  data: MemberType;
  timestamp: number;
}

// Create a Jotai atom to store our array of objects
export const newItemsAtom = atom<NewItemObject[]>([]);
