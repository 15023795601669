import { makeStyles } from '@material-ui/styles';

const commonInputStyle = (theme) => ({
  width: '100%',
  height: '100%',
  color: theme.palette.dina.mediumEmphasis,
  '&$focused $notchedOutline': {
    borderColor: theme.palette.dina.onSelected,
    borderWidth: '1px',
  },
});

const useStyles = makeStyles((theme) => ({
  selectRoot: {
    padding: 0,
    paddingLeft: '8px',
  },
  icon: {
    top: 'calc(50% - 16px)',
    right: '15px',
  },
  disabledOutline: {
    ...commonInputStyle(theme),
    '& $notchedOutline': {
      borderColor: 'transparent',
    },
    '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
      borderColor: 'transparent',
      backgroundColor: theme.palette.dina.statusOnHover,
    },
  },
  regular: {
    ...commonInputStyle(theme),
    '& $notchedOutline': {
      borderColor: theme.palette.dina.buttonBorderOutlined,
    },
    '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
      borderColor: theme.palette.dina.buttonBorderOutlined,
      backgroundColor: theme.palette.dina.statusOnHover,
    },
  },
  mandatory: {
    ...commonInputStyle(theme),
    '& $notchedOutline': {
      borderColor: theme.palette.dina.statusWarning,
    },
    '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
      borderColor: theme.palette.dina.statusWarning,
      backgroundColor: theme.palette.dina.statusOnHover,
    },
  },
  menu: {
    background: theme.palette.dina.surfaceAppBackgroundMain,
  },
  title: {
    ...theme.typography.dina.listItemLabelMedium,
    color: theme.palette.dina.highEmphasis,
  },
  selectItemText: {
    ...theme.typography.dina.listItemLabel,
    color: theme.palette.dina.mediumEmphasis,
    fontWeight: 'normal',
  },
  listItemIcon: {
    minWidth: '32px',
  },
  selectMenuItem: {
    height: ({ itemHeight }) => itemHeight,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  menuItem: {
    height: ({ itemHeight }) => itemHeight,
    '&:hover': {
      backgroundColor: theme.palette.dina.statusOnHover,
    },
    '&$menuItemSelected, &$menuItemSelected:hover, &$menuItemSelected:focus': {
      backgroundColor: theme.palette.dina.onSelected,
    },
  },
  notchedOutline: {},
  focused: {},
  disabled: {},
  error: {},
  menuItemSelected: {},
}));

export default useStyles;
