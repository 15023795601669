import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useReadOnly } from 'slate-react';
import InputField from 'components/inputField';
import { elementTypes } from 'components/editor/constants/types';
import LocationBase from '../locationBase';

const Input = memo(InputField);
const menuItems = [];

const CheckIn = ({ attributes, children, element }) => {
  const [value, setValue] = useState();
  const readOnly = useReadOnly();
  return (
    <div {...attributes}>
      {children}
      <LocationBase {...{ menuItems, readOnly }} title="CHECK IN">
        <Input
          disableLabel
          onChange={setValue}
          value={value}
          usage="editor"
          disabled={readOnly}
          placeholder="Start typing to find a location..."
        />
      </LocationBase>
    </div>
  );
};

CheckIn.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

CheckIn.defaultProps = {
  attributes: {},
  children: null,
  element: {
    children: [],
    data: { src: '' },
    type: elementTypes.CHECK_IN,
  },
};

export default memo(CheckIn);
