import GET_INSTANCE_FROM_CACHE from 'operations/queries/getInstanceFromCache';

const emptyData = {
  mTitle: 'loading...',
  mThumbnailKey: null,
  mStoryId: null,
  mCreatedAt: null,
  mUpdatedAt: null,
  mUpdatedById: null,
  mContentKey: null,
  mDefaultContentKey: null,
  mPublishingAt: null,
  mTemplateId: null,
  isTemplateInstance: null,
  locked: null,
  mState: 'todo',
  mType: 'instance',
  mProperties: {
    __typename: 'PlatformType',
    platform: 'linear',
    platformKind: null,
    account: {
      accountUrl: null,
      accountLogo: null,
      accountTitle: null,
      accountId: null,
      accountRefId: null,
      orderType: null,
      __typename: 'AccountType',
    },
  },
  items: [],
  mMetaData: [],
  mAssignedMembers: [],
  mRelatedMembers: [],
  assets: [],
};

const resolveInstanceCache = (parent, args, { cache, getCacheKey }) => {
  const id = getCacheKey({ ...args.input, __typename: 'MemberType' });
  const tempData = { ...args.input, ...emptyData, __typename: 'MemberType' };
  try {
    const data = cache.readFragment({
      id,
      fragment: GET_INSTANCE_FROM_CACHE,
    });

    if (data) return data;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
  return tempData;
};

export default resolveInstanceCache;
