import { ReactEditor } from 'slate-react';
import getDefaultTemplate from 'components/editor/utils/getDefaultTemplate';
import insertSecondaryAutomationElement from 'components/editor/components/secondaryAutomation/utils/insertSecondaryAutomationElement';

const onSecondaryDrop = ({ type }, editor, templates) => {
  const { variant: templateVariant, name, description } = getDefaultTemplate(type, templates);
  const data = { templateVariant, name, description };

  insertSecondaryAutomationElement(editor, type, data, false);

  ReactEditor.focus(editor);
};

export default onSecondaryDrop;
