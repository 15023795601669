// eslint-disable-next-line sort-imports
import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/styles';

import { ReactComponent as ArrowLeftComponent } from 'assets/icons/systemicons/arrows/disclosurearrow_left.svg';
import { ReactComponent as ArrowRightComponent } from 'assets/icons/systemicons/arrows/disclosurearrow_right.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/systemicons/filters_off.svg';
import { ReactComponent as Instance } from 'assets/icons/systemicons/instance.svg';
import { ReactComponent as TodayIconComponent } from 'assets/icons/systemicons/today.svg';
import Button from 'components/buttons/button';
import Divider from 'components/divider';
import { Box } from 'layouts/box/Box';
import transientOptions from 'theme/helpers';

interface IconProps {
  $disabled?: boolean;
}

interface CommonSvgProps extends IconProps {
  theme: Theme;
  $disabled?: boolean;
}
interface ExtensibleFlexProps {
  $flexDirection?: string;
  $justifyContent?: string;
  $alignItems?: string;
  $flex?: string;
  $gap?: string | number;
}

export const getCommonSvgProperties = ({ $disabled, theme }: CommonSvgProps) => css`
  path {
    fill: ${theme.palette.mode === 'light' && theme.palette.dina.blackHighEmphasis};
  }
  &:hover {
    cursor: ${$disabled ? 'not-allowed' : 'pointer'};
    path {
      fill-opacity: ${$disabled ? 0.54 : 1};
    }
  }
`;

const getFlexStyles = ({
  $flex,
  $flexDirection,
  $alignItems,
  $justifyContent,
  $gap,
}: ExtensibleFlexProps) => css`
  display: flex;
  flex-direction: ${$flexDirection ?? 'row'};
  justify-content: ${$justifyContent ?? 'flex-start'};
  align-items: ${$alignItems ?? 'stretch'};
  flex: ${$flex ?? '0 1 auto'};
  gap: ${typeof $gap === 'number' ? `${$gap}px` : $gap ?? '0'};
`;

export const FilterIconComponent = styled(FilterIcon, transientOptions)<{ $hasFilters: boolean }>`
  cursor: pointer;

  path {
    fill: ${({ theme, $hasFilters }) =>
      $hasFilters ? theme.palette.dina.statusWarning : theme.palette.dina.mediumEmphasis};
    fill-opacity: ${({ $hasFilters }) => ($hasFilters ? 1 : 0.54)};
  }

  :hover {
    path {
      fill: ${({ theme, $hasFilters }) =>
        $hasFilters ? theme.palette.dina.statusWarning : theme.palette.dina.mediumEmphasis};
      fill-opacity: 1;
    }
  }
`;

export const InstanceIcon = styled(Instance)<{ $active?: boolean }>`
  path {
    stroke: ${({ theme }) => theme.palette.dina.mediumEmphasis};
    fill: ${({ theme, $active }) => ($active ? theme.palette.dina.mediumEmphasis : 'none')};
  }
`;

// export const BookmarkIconWrapper = styled('div')`
//   height: 32px;
//   width: 48px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border: 1px solid ${({ theme }) => theme.palette.dina.inputBorderResting};
//   border-radius: 6px;
// `;

export const SearchWrapper = styled('div')`
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  align-items: center;
  justify-content: space-between;
  row-gap: 0.5rem;
  column-gap: 0.5rem;
  width: 100%;
  padding: 0.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.inputBorderResting};
`;

export const SearchField = styled(TextField)<{ value: string }>`
  flex: 1 0 300px;

  .MuiInputBase-root {
    height: 32px;
    border-radius: 6px;

    input {
      &::placeholder {
        opacity: 0.7;
        ${({ theme }) => theme.typography.dina.listItemLabel};
        color: ${({ theme }) => theme.palette.dina.highEmphasis};
      }
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.dina.inputBorderResting};
    border-width: 1px;
  }

  .MuiInputBase-input {
    color: ${({ theme, value }) =>
      value ? theme.palette.dina.statusWarning : theme.palette.dina.mediumEmphasis};
  }

  .Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme }) => theme.palette.dina.inputBorderFocused};
      border-width: 1px;
    }
    .MuiInputBase-input {
      color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
    }
  }

  :hover :not(.Mui-focused) {
    .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme }) => theme.palette.dina.buttonBorderOutlined};
      border-width: 1px;
    }
  }
`;

export const FiltersWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 1rem 0.5rem;
  width: 360px;
  gap: 1rem;
`;

export const FilterHeader = styled('div')`
  ${({ theme }) => theme.typography.dina.h7};
  color: ${({ theme }) =>
    theme.palette.mode === 'light'
      ? theme.palette.dina.blackHighEmphasis
      : theme.palette.dina.whiteHighEmphasis};
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
`;

export const StyledFilterButton = styled(Button, transientOptions)<{ $active?: boolean }>`
  flex: 1 0 auto;
  justify-content: space-between;
  font-size: 14px;
  background: transparent;
  color: ${({ $active, theme }) =>
    $active ? theme.palette.dina.buttonBackgroundWarning : theme.palette.dina.mediumEmphasis};
  text-transform: capitalize;

  svg {
    margin-right: 0;
    margin-left: 0;

    path {
      fill: ${({ theme }) => theme.palette.dina.mediumEmphasis};
      fill-opacity: ${({ theme }) => (theme.palette.mode === 'light' ? 0.65 : 0.54)};
    }
  }
  &:hover {
    color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
    svg {
      path {
        fill-opacity: 1;
      }
    }
  }
`;

export const SortAndFilterListContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: max-content;
  padding: 0.5rem 1rem;
  max-height: 670px;
  overflow-y: auto;

  .MuiFormControlLabel-root {
    text-transform: capitalize;
  }
`;

export const StyledFormControlLabel = styled(FormControlLabel, transientOptions)<{
  $checked: boolean;
}>`
  flex: 0 0 auto;
  margin-left: 0;
  margin-right: 0;
  color: ${({ theme, $checked }) =>
    $checked ? theme.palette.dina.statusWarning : theme.palette.dina.highEmphasis};

  .MuiFormControlLabel-label {
    font-size: 14px;
  }
`;

export const IndividualFilterWrapper = styled('div')<ExtensibleFlexProps>`
  ${(props) => getFlexStyles(props)}
  padding: 0 0.5rem;
`;

export const FilterContentWrapper = styled('div', transientOptions)<
  ExtensibleFlexProps & { $width?: string | number }
>`
  ${(props) => getFlexStyles(props)};

  width: ${({ $width }) => (typeof $width === 'number' ? `${$width}px` : $width ?? 'auto')};
  svg {
    flex: 0 0 auto;
  }
`;

export const FilterButtonWrapper = styled('div')<ExtensibleFlexProps>`
  ${(props) => getFlexStyles(props)}
`;

export const CheckboxWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
`;

export const CheckboxLabel = styled('label')`
  ${({ theme }) => theme.typography.dina.listItemLabel}
  text-transform: capitalize;
  cursor: pointer;
`;

export const StylesSwitch = withStyles((theme) => ({
  switchBase: {
    color: 'white',
    width: 'max-content',
    '&$checked': {
      color: 'white',
    },
    '&$checked + $track': {
      // eslint-disable-next-line max-len
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      backgroundColor: theme.palette.dina.onFocus,
    },
  },
  checked: {},
  track: {},
}))(Switch);

export const FilterDateWrapper = styled('div')`
  display: flex;
  align-items: center;
  flex: 1 0 160px;
  max-width: 202px;
  user-select: none;
`;

export const FilterLabel = styled('p', transientOptions)<{ $selected: boolean }>`
  ${({ theme }) => theme.typography.dina.listItemLabelMedium};
  color: ${({ theme, $selected }) =>
    $selected ? theme.palette.dina.statusWarning : theme.palette.dina.mediumEmphasis};
  cursor: pointer;
  word-break: break-all;
  max-width: 130px;
`;

export const RadioButtonWithLabel = styled('div')`
  display: flex;
  align-items: center;
  text-transform: capitalize;
  gap: 0.5rem;
  cursor: pointer;
`;

export const SortListLabel = styled('div')`
  ${({ theme }) => theme.typography.dina.overline};
  color: ${({ theme }) => theme.palette.dina.whiteInactive};
  margin-bottom: 0.5rem;
`;

export const StyledDivider = styled(Divider)`
  margin: 0.5rem 0;
  height: 1px;
`;

export const StyledLabelContainer = styled('div')`
  max-width: calc(100% - 24px);
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  text-align: start;
`;

export const ArrowLeft = styled(ArrowLeftComponent, transientOptions)<IconProps>`
  ${(props) => getCommonSvgProperties(props)};
`;

export const ArrowRight = styled(ArrowRightComponent, transientOptions)<IconProps>`
  ${(props) => getCommonSvgProperties(props)}
`;

export const TodayIcon = styled(TodayIconComponent)<IconProps>`
  ${(props) => getCommonSvgProperties(props)}
`;

export const RundownGroupLabelWrapper = styled('div')`
  display: flex;
  gap: 4px;
  align-items: center;
`;

type RundownGroupGroupColorProps = {
  $color?: string;
};
export const RundownGroupColor = styled('span', transientOptions)<RundownGroupGroupColorProps>`
  width: 16px;
  height: 16px;
  background-color: ${({ $color = '#ffffff' }) => $color};
  border: 1px solid ${({ theme }) => theme.palette.dina.buttonBorderOutlined};
  outline: none;
  border-radius: 50%;
`;

export const RundownGroupsWrapper = styled('div')`
  margin-left: 11px;
`;
