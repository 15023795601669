import React from 'react';
import PropTypes from 'prop-types';

import { useSelectedUserSettingAtom } from '../../../../atoms';
import USER_SETTING_TYPES from '../../../../utils/userSettingTypes';
import UserTour from './userTour';
import Profile from './profile';
import UserTheme from './theme';
import Notification from './notification';
import NotificationProvider from './notification/NotificationProvider';

import { RootWrapper } from './details-styled';
import UserPreferences from './preferences/UserPreferences';

const Details = ({ handleCloseSettings, groupPolicies, systemSettings }) => {
  const [selectedUserSetting] = useSelectedUserSettingAtom();
  const getComponent = () => {
    switch (selectedUserSetting) {
      case USER_SETTING_TYPES.PROFILE:
        return <Profile groupPolicies={groupPolicies} />;
      case USER_SETTING_TYPES.USER_TOUR:
        return <UserTour handleCloseSettings={handleCloseSettings} />;
      case USER_SETTING_TYPES.THEME:
        return <UserTheme />;
      case USER_SETTING_TYPES.NOTIFICATION:
      case USER_SETTING_TYPES.NOTIFICATION_GENERAL:
        return <Notification systemSettings={systemSettings} />;
      case USER_SETTING_TYPES.NOTIFICATION_SLACK:
      case USER_SETTING_TYPES.NOTIFICATION_EMAIL:
        return <NotificationProvider />;
      case USER_SETTING_TYPES.PREFERENCES:
        return <UserPreferences />;
      default:
        return <div />;
    }
  };
  return <RootWrapper>{getComponent()}</RootWrapper>;
};

Details.propTypes = {
  systemSettings: PropTypes.object,
  handleCloseSettings: PropTypes.func,
  groupPolicies: PropTypes.array,
};

Details.defaultProps = {
  systemSettings: {},
  handleCloseSettings: () => {},
  groupPolicies: [],
};

export default Details;
