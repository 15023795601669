import React from 'react';
import styled from '@emotion/styled';
import { Popper as MuiPopper } from '@material-ui/core';

export const StyledPopper = styled(MuiPopper)`
  z-index: 1300;
  background-color: ${({ theme }) => theme.palette.dina.surfaceDialogs};
  border-radius: 8px;

  box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.25), 0px 0px 4px 0px rgba(0, 0, 0, 0.25),
    0px 0px 2px 0px rgba(0, 0, 0, 0.25);
`;

const Popper = ({ children, anchorEl, position = 'top', ...rest }) => {
  const open = Boolean(anchorEl);

  return (
    <StyledPopper
      open={open}
      placement={position}
      anchorEl={anchorEl}
      {...rest}
      modifiers={{
        preventOverflow: {
          enabled: true,
          boundariesElement: 'viewport',
        },
      }}
    >
      <div>{children}</div>
    </StyledPopper>
  );
};

export default Popper;
