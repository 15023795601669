import { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import memberTypes from 'operations/memberTypes';
import CREATE_USER from 'operations/mutations/createUsers';
import GET_ALL_USERS from 'operations/queries/getAllDbUsers';
import { getMembersOfTypeQuery } from 'operations/queryVariables';
import { useMembers } from 'store';
import { AssignedMember } from 'types';
import { MemberType } from 'types/graphqlTypes';
import useLogger from 'utils/useLogger';

interface QueryUsersList {
  getUsers: MemberType[];
}

interface CreateUserType {
  createUsers: MemberType[];
}

type CreateUserStatusFunction = (
  email: string,
  username: string,
  errorToast: (err: unknown, description?: string) => void,
  handleOnCancel: () => void,
) => Promise<void>;

type UseCreateUserHook = () => [CreateUserStatusFunction, boolean];

const useCreateUser: UseCreateUserHook = () => {
  const logger = useLogger('CreateUser');
  const [createUser, { loading }] = useMutation<CreateUserType>(CREATE_USER);
  const [members, setMembers] = useMembers();

  const createNewUser: CreateUserStatusFunction = useCallback(
    async (email, username, errorToast, handleOnCancel) => {
      const variables = {
        input: {
          users: [
            {
              email,
              username,
            },
          ],
        },
      };
      await createUser({
        variables,
        onError: (error) => {
          logger.log(error.message);
          errorToast(error, 'Something went wrong. Please try again');
        },
        update: (client, mutationResult) => {
          const [createSingleUser] = mutationResult?.data?.createUsers || [];
          if (createSingleUser) {
            if (!createSingleUser?.mTitle) {
              createSingleUser.mTitle = username;
            }
            try {
              setMembers({
                ...members,
                user: [...members.user, createSingleUser as AssignedMember],
              });
              const usersList = client.readQuery<QueryUsersList>({
                query: GET_ALL_USERS,
                variables: getMembersOfTypeQuery(memberTypes.USER),
              });
              const usersInCache = usersList?.getUsers ? [...usersList.getUsers] : [];

              const isAlreadyInCache = usersInCache?.find((user) => {
                return user.mId === createSingleUser.mId;
              });

              if (isAlreadyInCache) return;

              const updatedUsers = [...usersInCache, createSingleUser];
              client.writeQuery({
                query: GET_ALL_USERS,
                variables: getMembersOfTypeQuery(memberTypes.USER),
                data: {
                  getUsers: updatedUsers,
                },
              });
              handleOnCancel();
            } catch (err) {
              logger.log(err);
            }
          }
        },
      });
    },
    [createUser, logger, members, setMembers],
  );
  return [createNewUser, loading];
};

export default useCreateUser;
