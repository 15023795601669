import styled from '@emotion/styled';

import {
  Tooltip,
  TooltipContent,
  TooltipContentProps,
  TooltipProvider,
  TooltipTrigger,
} from 'lib/Tooltip';

export enum TooltipPlacement {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}

interface TooltipProps extends Omit<TooltipContentProps, 'title'> {
  /** The delay in milliseconds before showing the tooltip. **/
  enterDelay?: number;
  /** Placement of the tooltip **/
  placement?: TooltipPlacement;
  /**  What will be displayed in the tooltip **/
  title?: string | React.ReactElement;
  /** The content that will trigger the tooltip **/
  children: React.ReactNode | React.ReactElement;
  /** If true, the tooltip will not be displayed **/
  hide?: boolean;
  /** For manual control of the tooltip **/
  open?: boolean;
  /** Callback when the tooltip is opened or closed, used with open for manual control **/
  onOpenChange?: (open: boolean) => void;
}

const StyledContent = styled(TooltipContent)`
  ${({ theme }) => theme.typography.dina.caption};
  color: ${({ theme }) => theme.palette.dina.whiteHighEmphasis};
  background-color: ${({ theme }) => theme.palette.dina.tooltip};
  padding: 4px;
  box-shadow: 0px 0px 4px ${({ theme }) => theme.palette.dina.boxShadowDark};
  z-index: 3500;
  max-width: 300px;
  user-select: none;
  border: 1px solid ${({ theme }) => theme.palette.dina.tooltipBorder};
  border-radius: 4px;
`;

export default function HoverTooltip({
  children,
  enterDelay = 0,
  placement = TooltipPlacement.BOTTOM,
  title = '',
  hide = false,
  open = undefined,
  onOpenChange = undefined,
  ...rest
}: Readonly<TooltipProps>) {
  // Do not show tooltip if hide is true
  if (hide) return <>{children}</>;

  return (
    <TooltipProvider>
      <Tooltip
        open={open}
        onOpenChange={onOpenChange}
        delayDuration={enterDelay}
        disableHoverableContent
      >
        <TooltipTrigger asChild>{children}</TooltipTrigger>
        {title && (
          <StyledContent side={placement} sideOffset={8} {...rest}>
            {title}
          </StyledContent>
        )}
      </Tooltip>
    </TooltipProvider>
  );
}
