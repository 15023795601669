import { Svg, Rect, Path } from '@react-pdf/renderer';

const BreakIcon = () => {
  return (
    <Svg style={{ width: 32, height: 32, marginRight: 8 }} viewBox='0 0 32 32'>
      <Rect x='0.5' y='0.5' width='31' height='31' rx='5.5' ry='5.5' stroke='black' />
      <Path
        d='M12.689 27C12.4254 27 12.2023 26.7896 12.2023 26.5191C12.2023 26.2486 12.4254 26.0383 12.689 26.0383C18.9459 26.0383 24.0366 21.0091 24.0366 14.8279C24.0366 9.93898 20.0107 5.95173 15.0518 5.95173C11.1476 5.95173 7.96338 9.08743 7.96338 12.9545C7.96338 15.99 10.458 18.4645 13.5408 18.4645C15.9442 18.4645 17.9014 16.531 17.9014 14.1566C17.9014 12.3133 16.3803 10.8106 14.5042 10.8106C13.0642 10.8106 11.8879 11.9727 11.8879 13.3953C11.8879 13.6557 11.6749 13.8761 11.4011 13.8761C11.1273 13.8761 10.9245 13.6557 10.9245 13.3852C10.9245 11.4317 12.5369 9.8388 14.5144 9.8388C16.9177 9.8388 18.8749 11.7723 18.8749 14.1466C18.8749 17.0519 16.4817 19.4162 13.5408 19.4162C9.9307 19.4262 7 16.5209 7 12.9545C7 8.56648 10.6101 5 15.0518 5C20.538 5 25 9.40802 25 14.8279C25 21.5401 19.4834 27 12.689 27Z'
        fill='#0F141A'
        fillOpacity='0.87'
      />
    </Svg>
  );
};

export default BreakIcon;
