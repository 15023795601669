import gql from 'graphql-tag';

export default gql`
  query GetStatusViews($input: GetMemberInput) {
    getMemberFromId(input: $input) {
      mId
      mTitle
      mType
      mProperties {
        __typename
        ... on StatusViewType {
          defaultView
          states {
            id
            name
            description
            icon
            backgroundColor
          }
          views {
            id
            name
            states {
              id
            }
          }
        }
      }
    }
  }
`;
