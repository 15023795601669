import styled from '@emotion/styled/macro';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Typography from '@material-ui/core/Typography';

export const GroupHeader = styled(ListItem)<{
  variant?: string;
}>`
  .addIcon {
    display: none;
  }
  &.MuiListItem-root {
    padding: 0px;
    padding-right: ${(props) => `${props.variant ? '0px' : '8px'}`};
    border-bottom: ${({ variant, theme }) =>
      `1px solid ${variant ? theme.palette.dina.dividerLight : theme.palette.dina.dividerDark}`};
    background-color: ${({ variant, theme }) =>
      `${variant ? 'transparent' : theme.palette.dina.blackHoverOverlay}`};
    border-top: ${({ variant, theme }) =>
      `${variant ? '0px' : '1px'}} solid ${theme.palette.dina.dividerDark}`};
    &:hover {
      background-color: ${({ theme }) => theme.palette.dina.whiteHoverOverlay};
      .addIcon {
        display: flex;
      }
    }
  }
`;

export const LabelContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  justify-content: space-between;
  padding: 0px 4px;
`;

export const Label = styled(Typography)`
  ${({ theme }) => theme.typography.dina.listItemGroupHeadersLabel};
  font-weight: normal;
  line-height: 15px;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
`;

export const ArrowWrapper = styled('div')`
  height: 24px;
  width: 24px;
`;

export const UploadProgressContainer = styled('div')`
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  & > :first-of-type {
    height: 18px;
    width: 18px;
    position: static;
  }
`;

export const ItemCount = styled(Typography)`
  ${({ theme }) => theme.typography.dina.captionRegular};
  height: 30px;
  display: flex;
  align-items: center;
`;
export const StickyHeader = styled(ListSubheader)`
  &.MuiListSubheader-sticky {
    line-height: 30px;
    backdrop-filter: blur(60px);
    -webkit-backdrop-filter: blur(60px);
  }
`;
