import { ScrollAreaProps } from '@radix-ui/react-scroll-area';

import { ScrollAreaRoot, ScrollAreaScrollbar, ScrollAreaThumb, ScrollAreaViewport } from './styled';

interface Props extends ScrollAreaProps {
  children: React.ReactNode;
}

export default function ScrollArea({ children, ...rest }: Readonly<Props>) {
  return (
    <ScrollAreaRoot {...rest}>
      <ScrollAreaViewport>{children}</ScrollAreaViewport>
      <ScrollAreaScrollbar orientation="vertical">
        <ScrollAreaThumb />
      </ScrollAreaScrollbar>
      <ScrollAreaScrollbar orientation="horizontal">
        <ScrollAreaThumb />
      </ScrollAreaScrollbar>
    </ScrollAreaRoot>
  );
}
