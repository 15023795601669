import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { Typography } from '@material-ui/core';

import { ReactComponent as CalendarOff } from 'assets/icons/systemicons/calendar_off.svg';
import { ReactComponent as CalendarOn } from 'assets/icons/systemicons/calendar_on.svg';
import { ReactComponent as Close } from 'assets/icons/systemicons/close.svg';
import transientOptions from 'theme/helpers';

export const CloseIcon = styled(Close)`
  cursor: pointer;
  &:hover path {
    fill-opacity: 1;
  }
  justify-self: end;
  margin-right: 4px;
`;

export const DatePickerButton = styled('div', transientOptions)<{
  $disabled?: boolean;
  $fullWidth?: boolean;
  $transparent?: boolean;
}>`
  height: 32px;
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'max-content')};
  border: 1px solid
    ${({ theme, $transparent }) =>
      theme.palette.dina[$transparent ? 'whiteButtonBorderOutlined' : 'buttonBorderOutlined']};
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  background-color: ${({ $transparent }) =>
    $transparent ? 'transparent' : 'rgba(255, 255, 255, 0.09)'};
  transition: 0.1s ease-in;
  :hover {
    background-color: ${({ theme }) => theme.palette.dina.statusOnHover};
  }
  > :last-child {
    margin-left: 4px;
    margin-right: 8px;
  }
  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: default;
      border-color: rgba(255, 255, 255, 0.2);
      * {
        color: rgba(255, 255, 255, 0.8);
        path {
          fill-opacity: 0.6 !important;
        }
      }
    `}
`;

export const Icon = styled(CalendarOn, transientOptions)<{ $dimmed?: boolean }>`
  margin-right: 4px;
  margin-left: 6px;
  width: 18px;
  height: 18px;
  ${({ $dimmed }) =>
    !$dimmed &&
    css`
      path {
        fill-opacity: 1 !important;
      }
    `}
`;

export const IconOff = styled(CalendarOff, transientOptions)<{ $bright?: boolean }>`
  margin-right: 4px;
  margin-left: 8px;
  width: 18px;
  height: 18px;
  ${({ $bright }) =>
    $bright &&
    css`
      path {
        fill-opacity: 1 !important;
      }
    `}
`;

export const Label = styled(Typography, transientOptions)<{ $dimmedText?: boolean }>`
  color: ${({ $dimmedText }) =>
    $dimmedText
      ? ({ theme }) => theme.palette.dina.mediumEmphasis
      : ({ theme }) => theme.palette.dina.highEmphasis};
  white-space: nowrap;
`;

export const CalendarContainer = styled('div')`
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
`;
