import {
  getAlertTitle,
  getAlertAvatar,
  extractNotificationProperties,
  openMessageHub,
} from 'screens/main/components/header/navbar/alert/utils';
import { useIsMessageHubOpen, useSelectedConversationId } from 'store';
import { useRightHidden, useRightSelection } from 'store/sidebar';
import checkIsSystemMessage from 'utils/messageHub/checkIsSystemMessage';
import getDefaultValueOfProperty from 'utils/getDefaultValueOfProperty';
import useSettingsValue from 'hooks/useSettingsValue';
import useGetUser from 'hooks/useGetUser';
import useVisibilityState from 'hooks/useVisibilityState';
import useSelectRundownInstance from './useSelectRundownInstance';
import memberTypes from 'operations/memberTypes/memberTypes';
import infoImage from 'assets/icons/systemicons/info_off.svg';
import BreakingPrio from 'assets/icons/systemicons/breaking-prio.svg';
import useFeedViewer from 'hooks/useFeedViewer';

import conversationTypes from 'utils/constants/conversationTypes';
import useDinaNavigate from './useDinaNavigate';

export const checkNotificationPromise = () => {
  try {
    Notification.requestPermission().then();
  } catch (e) {
    return false;
  }
  return true;
};

const handleNotification = (
  permission,
  notification,
  getUser,
  openConversation,
  navigateTo,
  selectRundownInstance,
  setRightHidden,
  setRightSelection,
  isSoundEnabled,
) => {
  if (permission !== 'granted') return;
  try {
    const { mStoryId, convoType, updatedBy } = notification;
    const isSystemMessage = checkIsSystemMessage(notification);

    const { instanceId, storyId, alertTitle, highlightedText, text, mTemplateId } = mStoryId
      ? extractNotificationProperties(notification.mContent)
      : {};

    const notificationTitle = isSystemMessage
      ? alertTitle || 'System Message'
      : getAlertTitle(notification);

    const notificationIcon = isSystemMessage ? infoImage : getAlertAvatar(notification, getUser);
    const [message] = notification?.message || [];

    const notificationBody = isSystemMessage
      ? `${text}\n${highlightedText}`
      : `${updatedBy} ${message}${notification?.itemTitle ?? ''}`;

    const options = {
      body: notificationBody,
      silent: !isSoundEnabled,
      icon: notificationIcon,
    };

    const newNotification = new Notification(notificationTitle, options);

    window.navigator.vibrate(200);

    newNotification.onclick = () => {
      window.parent.parent.focus();
      newNotification.close();

      const openContentFunc = (notificationObject) => {
        const { mStoryId, mType, mRefId, mTemplateId } = notificationObject;
        const isInstance = mType === memberTypes.INSTANCE;
        if (mTemplateId) {
          navigateTo('rundown', mStoryId);
        } else {
          navigateTo('story', mStoryId, {
            tab: isInstance ? 'instances' : 'content',
            entityId: isInstance ? mRefId : undefined,
          });
        }
      };

      if (storyId) {
        if (mTemplateId) {
          navigateTo('rundown', storyId);
          if (instanceId) {
            selectRundownInstance([instanceId]);
          } else if (convoType === conversationTypes.MENTION) {
            setRightHidden(false);
            setRightSelection(selectionTypes.CHAT);
          }
        } else {
          navigateTo('story', storyId, {
            tab: instanceId ? 'instances' : 'content',
            entityId: instanceId,
          });
          if (convoType === conversationTypes.MENTION) {
            setRightHidden(false);
            setRightSelection(selectionTypes.CHAT);
          }
        }
      } else openMessageHub(notification, openConversation, openContentFunc);
    };
  } catch (error) {
    // console.log(error);
  }
};

const handleBreakingFeedNotification = (
  permission,
  notification,
  openFeedViewer,
  isSoundEnabled,
) => {
  if (permission !== 'granted') return;
  try {
    const { text } = notification;

    const options = {
      body: text,
      silent: !isSoundEnabled,
      icon: BreakingPrio,
    };

    const newNotification = new Notification('Breaking news', options);
    window.navigator.vibrate(200);

    newNotification.onclick = () => {
      window.parent.parent.focus();
      newNotification.close();
      openFeedViewer(notification.provider, notification.mId);
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

const useNotification = () => {
  const { navigateTo } = useDinaNavigate();
  const { getUser } = useGetUser();
  const isVisible = useVisibilityState();
  const [, setIsMessageHubOpen] = useIsMessageHubOpen();
  const [, setSelectedConversationId] = useSelectedConversationId();
  const { selectRundownInstance } = useSelectRundownInstance();
  const [, setRightHidden] = useRightHidden();
  const [, setRightSelection] = useRightSelection();
  const [getSettingsValue] = useSettingsValue();
  const [, openFeedViewer] = useFeedViewer();
  const defaultValue = getDefaultValueOfProperty('notification', 'user.notificationSound');
  const isSoundEnabled =
    getSettingsValue('user.notificationSound', '', defaultValue).toString() === 'true';
  const openConversation = (conversationId) => {
    setIsMessageHubOpen(true);
    setSelectedConversationId(conversationId);
  };

  const sendNotification = (notification) => {
    if (isVisible) return;

    if (!('Notification' in window)) return;

    if (notification.mType === 'breaking') {
      Notification.requestPermission().then((permission) => {
        handleBreakingFeedNotification(permission, notification, openFeedViewer, isSoundEnabled);
      });
      return;
    }

    if (checkNotificationPromise()) {
      Notification.requestPermission().then((permission) => {
        handleNotification(
          permission,
          notification,
          getUser,
          openConversation,
          navigateTo,
          selectRundownInstance,
          setRightHidden,
          setRightSelection,
          isSoundEnabled,
        );
      });
      return;
    }

    Notification.requestPermission((permission) => {
      handleNotification(
        permission,
        notification,
        getUser,
        openConversation,
        navigateTo,
        selectRundownInstance,
        setRightHidden,
        setRightSelection,
      );
    });
  };
  return sendNotification;
};

export default useNotification;
