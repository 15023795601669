import gql from 'graphql-tag';

export default gql`
  query GetStory($input: GetMemberInput) {
    getStory(input: $input) {
      __typename
      ... on MemberType {
        mId
        mRefId
        mTitle
        mType
        mDescription
        mAvatarKey
        mThumbnailKey
        mThumbnailUrl
        mContentKey
        mCreatedAt
        mCreatedById
        mUpdatedById
        mUpdatedAt
        mState
        locked
        mPublishingAt
        mPublishingEnd
        mSyncActive
        ttl
        metadata
        mdfId
        mMetaData {
          key
          value
        }
        mSyncProviders {
          mId
          provider
          mMetaData {
            key
            value
          }
        }
        mAssignedMembers {
          mId
          mType
        }
      }
      ... on RestrictedErrorType {
        mId
        mRefId
        mType
        message
      }
    }
  }
`;
