import { memo, useCallback } from 'react';
import { useSlate } from 'slate-react';

import { ReactComponent as QuoteIcon } from 'assets/icons/systemicons/editor/quote_off.svg';
import { elementTypes } from 'components/editor/constants';
import { isElementActive, toggleElement } from 'components/editor/utils';

import ButtonBase from '../buttonBase/ButtonBase';

const { QUOTE } = elementTypes;

function QuoteButton() {
  const editor = useSlate();
  const isActive = isElementActive(editor, QUOTE);

  const onMouseDown = useCallback(
    (event: React.MouseEvent<SVGSVGElement>) => {
      event.preventDefault();
      toggleElement(editor, QUOTE, isActive);
    },
    [editor, isActive],
  );

  return (
    <ButtonBase
      isActive={isActive}
      type={elementTypes.QUOTE}
      IconComponent={QuoteIcon}
      onMouseDown={onMouseDown}
    />
  );
}

export default memo(QuoteButton);
