import { MouseEvent, useCallback, useMemo } from 'react';
import { useTheme } from '@emotion/react';

import { BlockWithLabel } from 'api/mdfBlocks/types';
import { useGetOrderForms } from 'api/order_forms/useGetOrderForms';
import { ReactComponent as SidebarActive } from 'assets/icons/systemicons/side_preview.svg';
import Avatar, { AvatarVariant } from 'components/avatar/Avatar';
import Text from 'components/text';
import Tooltip from 'components/tooltip';
import Comments from 'features/comments';
import OrderButton from 'features/orderForm/components/OrderButton';
import StatusCell from 'features/orderForm/components/StatusCell';
import { Resource } from 'hooks/useResourceDetails';
import useSettingsValue from 'hooks/useSettingsValue';
import { Box } from 'layouts/box/Box';
import useUpdateOrder from 'screens/space/api/useUpdateOrder';
import { useAllMembers, useAllMembersKeyed, useDinaTheme } from 'store';
import { Order } from 'types/forms/forms';
import { Alternative, MemberTypeEnum } from 'types/graphqlTypes';

import EditMdfBlock from './EditMdfBlock';
import EditOrderBlock from './EditOrderBlock';
import { ToggleItem, ToggleItemProps } from './ToggleList';

import {
  ContentWrapper,
  LabelWrapper,
  OwnerIcon,
  StyledPreviewButton,
  StyledText,
  TaskIcon,
} from './styled';

export const TASK_MENU_ID = 'taskItemMenu';
export const PLANNING_MENU_ID = 'planningItemMenu';
export const SAVED_SEARCH_MENU_ID = 'savedSearchMenu';

export const getSupportedResourceType = (resource: Resource) => {
  switch (resource.mType) {
    case MemberTypeEnum.Instance:
      return [MemberTypeEnum.Instance, resource.mProperties?.platform ?? 'default'];
    case MemberTypeEnum.Pitch:
    case MemberTypeEnum.ResPitch:
      return [MemberTypeEnum.Pitch];

    case MemberTypeEnum.Story:
    case MemberTypeEnum.ResStory:
      return [MemberTypeEnum.Story];
    default:
      return [];
  }
};

export const scrollItemIntoView = (id: string) => {
  const rootElement = document.getElementById(`toggle-${id}`);
  if (rootElement) {
    rootElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
};

interface MemberAvatarProps {
  mOwner?: string;
  mAssignee?: string;
}

export function MemberAvatar({ mOwner, mAssignee }: Readonly<MemberAvatarProps>) {
  const [members] = useAllMembers();

  const owner = useMemo(() => members.find((m) => m.mId === mOwner), [members, mOwner]);
  const assignee = useMemo(() => members.find((m) => m.mId === mAssignee), [members, mAssignee]);

  return (
    <Box container gap="2px">
      {owner && (
        <Tooltip title={`Created by ${owner.mTitle}`}>
          <Box position="relative" overflow="unset">
            <Avatar
              className="avatar"
              variant={owner.mType as AvatarVariant}
              imageKey={owner.mAvatarKey}
              size={20}
              title={owner.mTitle}
            />
            <OwnerIcon className="skipOverride" />
          </Box>
        </Tooltip>
      )}
      {assignee && (
        <Tooltip title={`Assigned to ${assignee.mTitle}`}>
          <Box>
            <Avatar
              className="avatar"
              variant={assignee.mType as AvatarVariant}
              imageKey={assignee.mAvatarKey}
              size={20}
              title={assignee.mTitle}
            />
          </Box>
        </Tooltip>
      )}
    </Box>
  );
}

interface PlanningItemProps extends Pick<ToggleItemProps, 'id' | 'open'> {
  block?: BlockWithLabel | null;
  selected: boolean;
  resourceId: string;
  onSelect: (event: MouseEvent<HTMLButtonElement>) => void;
  openSidepanel?: () => void;
  onContextMenu?: (event: React.MouseEvent<HTMLDivElement>) => void;
  showComment?: boolean;
  updatePane?: () => void;
}

export function PlanningItem({
  block,
  resourceId,
  selected,
  onSelect,
  onContextMenu,
  openSidepanel,
  showComment,
  updatePane,
  ...rest
}: Readonly<PlanningItemProps>) {
  const [theme] = useDinaTheme();
  const [membersKeyed] = useAllMembersKeyed();
  const [getSettingsValue] = useSettingsValue();
  const showOrderButton = getSettingsValue('app.showOrderButtonInPlanningItems') === 'true';
  const updatedBy = useMemo(() => {
    if (block) {
      return membersKeyed[block.mUpdatedById];
    }
    return null;
  }, [block, membersKeyed]);

  const handleOpenSidepanel: React.MouseEventHandler<HTMLElement> = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      openSidepanel?.();
    },
    [openSidepanel],
  );

  return (
    <ToggleItem
      onSelect={onSelect}
      selected={selected}
      color={block?.color}
      onContextMenu={onContextMenu}
      trigger={
        <LabelWrapper container justifyContent="space-between">
          <Box
            container
            flexDirection="column"
            alignItems="start"
            width={`calc(100% - ${openSidepanel ? 196 : 146}px)`}
          >
            {block ? (
              <>
                <StyledText variant="listItemLabel" color="highEmphasis" truncate>
                  {block.commandLabel}
                </StyledText>
                {block.mTitle && (
                  <StyledText variant="caption" color="mediumEmphasis" truncate>
                    {block.mTitle}
                  </StyledText>
                )}
              </>
            ) : (
              <Text variant="listItemLabel">This planning item appears to be deleted</Text>
            )}
          </Box>
          {block && (
            <Box container gap="6px" margin="0 2px 0 0">
              <Tooltip title="Open in side panel">
                {openSidepanel && (
                  <StyledPreviewButton
                    container
                    height="28px"
                    width="28px"
                    onClick={handleOpenSidepanel}
                  >
                    <SidebarActive className="skipOverride" />
                  </StyledPreviewButton>
                )}
              </Tooltip>
              {updatedBy && (
                <Avatar
                  tooltipContent={`Last updated by ${updatedBy.mTitle}`}
                  className="avatar"
                  variant="user"
                  imageKey={updatedBy.mAvatarKey}
                  size={16}
                  title={updatedBy.mTitle}
                />
              )}
              {showOrderButton && (
                <OrderButton
                  dark={theme === 'dark'}
                  resourceId={block.mRefId}
                  resourceTitle={block.mTitle}
                  resourceType={block.mType}
                  formTypes={[block.mType]}
                />
              )}
              {resourceId && (
                <Comments
                  blockId={block.mRefId}
                  resourceId={resourceId}
                  resourceType={block.mType}
                  showComment={showComment}
                  updatePane={updatePane}
                />
              )}
            </Box>
          )}
        </LabelWrapper>
      }
      content={
        block ? (
          <EditMdfBlock blockId={block.mRefId} mdfId={block.mdfId} resourceId={resourceId} />
        ) : null
      }
      {...rest}
    />
  );
}

export type OrderWithLabel = Order & {
  formLabel: string;
  statusLabel: string;
  statusOptions: Alternative[];
  formColor?: string | null;
};
interface TaskItemProps extends Pick<ToggleItemProps, 'id' | 'open'> {
  order?: OrderWithLabel | null;
  selected: boolean;
  onSelect: (event: MouseEvent<HTMLButtonElement>) => void;
  openSidepanel?: () => void;
  onContextMenu?: (event: React.MouseEvent<HTMLDivElement>) => void;
  showComment?: boolean;
  updatePane?: () => void;
}

export function TaskItem({
  order,
  selected,
  onSelect,
  openSidepanel,
  onContextMenu,
  showComment,
  updatePane,
  ...rest
}: Readonly<TaskItemProps>) {
  const theme = useTheme();
  const { keyedOrderForms } = useGetOrderForms();
  const { updateOrder } = useUpdateOrder();
  const [activeTheme] = useDinaTheme();
  const [getSettingsValue] = useSettingsValue();
  const showOrderButton = getSettingsValue('app.showOrderButtonInTasks') === 'true';

  const form = useMemo(() => {
    return order?.mFormId ? keyedOrderForms[order.mFormId] : null;
  }, [order?.mFormId, keyedOrderForms]);

  const updateOrderProperty = useCallback(
    (key: string, value: string) => {
      if (!order) return;

      updateOrder({
        mId: order.mId,
        mResourceId: order.mResourceId,
        [key]: value,
      }).then(
        () => {},
        () => {},
      );
    },
    [order, updateOrder],
  );

  const handleOpenSidepanel: React.MouseEventHandler<HTMLElement> = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      openSidepanel?.();
    },
    [openSidepanel],
  );

  const Label = useMemo(() => {
    if (!form) {
      return (
        <Text variant="listItemLabel" color="mediumEmphasis">
          Form configuration not found
        </Text>
      );
    }
    if (order) {
      return (
        <Text variant="listItemLabel" color="highEmphasis" truncate>
          {order.formLabel}
        </Text>
      );
    }
    return (
      <Text variant="listItemLabel" color="mediumEmphasis">
        This task appears to be deleted
      </Text>
    );
  }, [order, form]);

  return (
    <ToggleItem
      color={order?.formColor ?? theme.palette.dina.buttonBackgroundHighEmphasis}
      onSelect={onSelect}
      selected={selected}
      onContextMenu={onContextMenu}
      trigger={
        <LabelWrapper container width="100%" justifyContent="space-between">
          <Box container alignItems="center" gap="4px">
            <TaskIcon className="skipOverride" />
            {Label}
          </Box>
          {order && form ? (
            <Box container gap="6px" padding="0 5px 0 0" flexShrink={0}>
              <Tooltip title="Open in side panel">
                {openSidepanel && (
                  <StyledPreviewButton
                    container
                    height="28px"
                    width="28px"
                    onClick={handleOpenSidepanel}
                  >
                    <SidebarActive className="skipOverride" />
                  </StyledPreviewButton>
                )}
              </Tooltip>
              <Box container flexShrink={0}>
                <MemberAvatar mOwner={order?.mOwner} mAssignee={order?.mAssignee} />
              </Box>
              <Box container flexShrink={0}>
                <StatusCell
                  fullSpace
                  value={order.mStatus}
                  formId={order.mFormId}
                  setValue={(val: string) => updateOrderProperty('mStatus', val)}
                />
              </Box>
              {showOrderButton && (
                <div onClick={(ev) => ev.stopPropagation()}>
                  <OrderButton
                    dark={activeTheme === 'dark'}
                    resourceId={order.mId}
                    resourceTitle={order.formLabel}
                    resourceType="order"
                    formTypes={['order']}
                  />
                </div>
              )}
              <Box container flexShrink={0}>
                <Comments
                  blockId={order.mId}
                  resourceId={order.mResourceId}
                  resourceType="order"
                  spaceId={order.mSpaceId}
                  formId={order.mFormId}
                  ownerId={order.mOwner}
                  assigneeId={order.mAssignee}
                  showComment={showComment}
                  updatePane={updatePane}
                />
              </Box>
            </Box>
          ) : null}
        </LabelWrapper>
      }
      content={order ? <EditOrderBlock orderId={order.mId} /> : null}
      {...rest}
    />
  );
}

interface FallbackProps {
  label: string;
}
export function Fallback({ label }: Readonly<FallbackProps>) {
  return (
    <ContentWrapper>
      <Text variant="listItemLabelMedium">{label}</Text>
    </ContentWrapper>
  );
}
