import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '132px',
    height: '133px',
    borderRadius: '8px',
    background: theme.palette.dina.surfaceCard,
    display: 'flex',
    flexDirection: 'column',
    outline: 'none',
    boxShadow: `0px 1px 3px rgba(0, 0, 0, 0.2),
      0px 1px 1px rgba(0, 0, 0, 0.25),
      0px 0px 1px rgba(0, 0, 0, 0.25)`,
    '&:focus': {
      boxShadow: `0 0 0 2px ${theme.palette.dina.onFocus}`,
    },
  },
  thumbnail: {
    borderRadius: '8px 8px 0 0',
    width: '132px',
    height: '68px',
  },
  contentCell: {
    width: '100%',
    height: '65px',
    borderRadius: '0 0 8px 8px',
  },
  fileName: {
    ...theme.typography.dina.listItemLabel,
    margin: '8px 12px 4px',
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
  },
  date: {
    ...theme.typography.dina.captionRegular,
    margin: '0 12px 0',
    color: theme.palette.dina.mediumEmphasis,
  },
}));

export default useStyles;
