/* eslint-disable import/no-extraneous-dependencies */
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import uniq from 'lodash/uniq';
import styled from '@emotion/styled';

import useDinaNavigate from 'hooks/useDinaNavigate';
import { dialogBoxShadow } from 'theme/utils/commonStyles';
import Deck from 'components/deck';
import List from 'features/feedViewer/components/list';
import LoadingIndicator from 'components/loadingIndicator';
import { ReactComponent as CloseIcon } from 'assets/icons/systemicons/close.svg';
import ListItem from 'components/listItems/story';
import { Button } from 'components/buttons';

import useGetStories from './apis/useGetStories';

const Body = styled('div')`
  width: 350px;
  display: flex;
  max-height: 50vh;
  min-height: 65px;
  flex-direction: column;
`;

const HeaderWrapper = styled('div')`
  position: relative;
`;

const CloseButton = styled(CloseIcon)`
  right: 8px;
  top: 12px;
  cursor: pointer;
  position: absolute;
  :hover {
    path {
      fill-opacity: 1;
    }
  }
`;

const CustomDeck = styled('div')`
  border-radius: 8px;
  ${dialogBoxShadow};
`;

function StoryListDialog({ data, onClose, onCreateNew, onAddToFeed }) {
  const { navigateTo } = useDinaNavigate();
  const [storyList, loading] = useGetStories(uniq(data?.storyList || []));

  const openStory = (story) => {
    navigateTo(story.mType, story.mId);
  };

  const stories = useMemo(() => storyList?.searchApi?.items || [], [storyList?.searchApi?.items]);

  return (
    <CustomDeck>
      <HeaderWrapper>
        <Deck.Header>
          <Deck.HeaderTitle>Associated Stories & Pitches</Deck.HeaderTitle>
        </Deck.Header>
        <CloseButton onClick={onClose} />
      </HeaderWrapper>
      <Body>
        {loading ? (
          <LoadingIndicator height={48} width={48} />
        ) : (
          <List>
            <List.Body>
              {stories.map((item) => (
                <ListItem key={item.mId} onClick={() => openStory(item)}>
                  <ListItem.Thumbnail src={item?.mThumbnailUrl} type={item.mType} />
                  <ListItem.Text>
                    <ListItem.Title>{item?.mTitle}</ListItem.Title>
                    <ListItem.Updated>{item?.mUpdatedAt}</ListItem.Updated>
                  </ListItem.Text>
                </ListItem>
              ))}
            </List.Body>
          </List>
        )}
      </Body>
      <Deck.Footer>
        <Button variant="outlined" usage="outlined" onClick={onAddToFeed}>
          Add to existing...
        </Button>
        <Button variant="contained" usage="cta" onClick={onCreateNew}>
          Create new...
        </Button>
      </Deck.Footer>
    </CustomDeck>
  );
}

StoryListDialog.propTypes = {
  data: PropTypes.shape({
    storyList: PropTypes.arrayOf(PropTypes.string),
  }),
  onCreateNew: PropTypes.func,
  onAddToFeed: PropTypes.func,
  onClose: PropTypes.func,
};

StoryListDialog.defaultProps = {
  data: {
    storyList: [],
  },
  onClose: () => {},
  onCreateNew: () => {},
  onAddToFeed: () => {},
};

export default StoryListDialog;
