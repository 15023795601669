import { atom, useAtom } from 'jotai';
import { createScope, molecule, useMolecule } from 'jotai-molecules';
import { BaseRange } from 'slate';

export const EditorScope = createScope(undefined);

const editorMolecule = molecule((_, getScope) => {
  getScope(EditorScope);

  const showDeleteAllDialogAtom = atom<boolean>(false);
  const commandTargetAtom = atom<BaseRange | null>(null);
  const printPlanningBlocks = atom(false);
  const printTasks = atom(false);
  const showHoveringToolbarAtom = atom(true);

  return {
    useShowDeleteAllDialog: () => useAtom(showDeleteAllDialogAtom),
    usePrintPlanningBlocks: () => useAtom(printPlanningBlocks),
    useCommandTarget: () => useAtom(commandTargetAtom),
    usePrintTasks: () => useAtom(printTasks),
    useShowHoveringToolbar: () => useAtom(showHoveringToolbarAtom),
  };
});

export const useEditorMolecule = () => useMolecule(editorMolecule);
