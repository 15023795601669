import gql from 'graphql-tag';

export default gql`
  subscription UpdateRundownSubscription($mId: String, $mRefId: String) {
    updateRundownSubscription(mId: $mId, mRefId: $mRefId) {
      metadata
      mdfId
      mAssignedMembers {
        mId
        mType
      }
      mTitle
      mOrder
      mPreorder
      mId
      mRefId
      mState
      mType
      mStudios
      mPreparingState
      mPlannedDuration
      mPrePlannedDuration
      mPublishingAt
      version
      mUpdatedAt
      mProperties {
        __typename
        ... on RundownStateType {
          ready {
            state
            startTime
            stopTime
            currentInstance {
              mId
              startTime
            }
          }
          preparing {
            state
            startTime
            stopTime
            currentInstance {
              mId
              startTime
            }
          }
        }
      }
      recurrence {
        duration
        startTime
        timeZone
        dailyExclusive
      }
      mMetaData {
        key
        value
      }
    }
  }
`;
