import React, { useState } from 'react';

import { StyledPopper } from './styled';

function PopperWithArrow({ open, position, anchor, children }) {
  const [arrowRef, setArrowRef] = useState(null);
  const ref = anchor?.current ?? anchor;

  return (
    <StyledPopper
      open={open}
      anchorEl={ref}
      position={position}
      placement={position}
      modifiers={{
        flip: {
          enabled: true,
        },
        preventOverflow: {
          enabled: true,
          boundariesElement: 'window',
        },
        arrow: {
          enabled: true,
          element: arrowRef,
        },
        offset: {
          offset: '0,10',
        },
      }}
    >
      <span className="arrow" ref={setArrowRef} />
      {children}
    </StyledPopper>
  );
}

export default PopperWithArrow;
