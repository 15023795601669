const providerStates = {
  FAILED: 'failed',
  READY: 'ready',
  SCHEDULED: 'scheduled',
  PENDING: 'pending',
  PUBLISHED: 'processed',
  ASSIGNED: 'assigned',
  EXPIRED: 'expired',
  DELETED: 'deleted',
  DRAFT: 'draft',
  UNKNOWN: 'unknown',
};

export default providerStates;
