import styled from '@emotion/styled';

import { Flex } from 'layouts/box/Box';
import transientOptions from 'theme/helpers';

export const StyledTab = styled(Flex, transientOptions)<{ $isSelected: boolean }>`
  cursor: pointer;
  z-index: 1;
  border-radius: 8px 8px 0 0;
  height: 41px;
  width: 44px;
  background: ${({ theme, $isSelected }) =>
    $isSelected ? theme.palette.dina.primary700 : 'transparent'};

  svg {
    path {
      fill: ${({ theme }) => theme.palette.dina.whiteHighEmphasis};
      fill-opacity: ${({ $isSelected }) => ($isSelected ? 1 : 0.65)};
    }
  }

  :hover {
    background: ${({ theme, $isSelected }) =>
      $isSelected ? theme.palette.dina.primary700 : theme.palette.dina.whiteHoverOverlay};
    color: ${({ theme }) => theme.palette.dina.highEmphasis};

    svg {
      path {
        fill: ${({ theme }) => theme.palette.dina.whiteHighEmphasis};
        fill-opacity: 1;
      }
    }
  }
`;
