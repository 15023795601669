import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Table as MaterialTable,
  TableHead as MaterialTableHead,
  TableBody,
  TableContainer,
  TableRow as MaterialTableRow,
  TableCell,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import Scrollbar from 'components/scrollbar';
import Select from 'components/metadata/select';
import { ReactComponent as SortDownSmall } from 'assets/icons/systemicons/sort_down_small.svg';
import { ReactComponent as ArrowLeft } from 'assets/icons/systemicons/arrows/disclosurearrow_left.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/systemicons/arrows/disclosurearrow_right.svg';
import useMouseClickEvents from 'hooks/useMouseClickEvents';
import getFieldProperty from './utils/getFieldProperty';
import useStyles from './table-styles';

export const keys = {
  arrowUp: 'ArrowUp',
  arrowDown: 'ArrowDown',
  enter: 'Enter',
  delete: 'Delete',
};

const sort = {
  ASC: 'asc',
  DESC: 'desc',
};

const getComparison = (firstValue, secondValue) => {
  if (secondValue === null && firstValue === null) {
    return 0;
  }
  if (secondValue === null || secondValue < firstValue) {
    return -1;
  }
  if (firstValue === null || secondValue > firstValue) {
    return 1;
  }
  return 0;
};

const descendingComparator = (a, b, orderBy) => {
  if (
    typeof a[orderBy] === 'string' &&
    !isNaN(Date.parse(a[orderBy])) &&
    typeof b[orderBy] === 'string' &&
    !isNaN(Date.parse(b[orderBy]))
  ) {
    const firstDate = new Date(a[orderBy]).getTime() / 1000;
    const secondDate = new Date(b[orderBy]).getTime() / 1000;
    return getComparison(secondDate, firstDate);
  }
  return getComparison(a[orderBy], b[orderBy]);
};

const getComparator = (order, orderBy) =>
  order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);

const sortData = (array, comparator, shouldSort) =>
  shouldSort
    ? array
        .map((el, index) => [el, index])
        .sort((a, b) => {
          const order = comparator(a[0], b[0]);
          if (order !== 0) return order;
          return a[1] - b[1];
        })
        .map((el) => el[0])
    : array;

const paginateData = (array, page, rowsPerPage, shouldPaginate) =>
  shouldPaginate ? array.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : array;

const TableHead = ({ classes, columns, orderBy, order, onSortRequest }) => {
  const createSortHandler = (property) => (event) => {
    onSortRequest(event, property);
  };
  return (
    <MaterialTableHead className={classes.head}>
      <MaterialTableRow className={classes.headRow}>
        {columns.map((column, index) => {
          const columnField = getFieldProperty(column, 'sortField', 'field');
          return column.sortable ? (
            <TableCell
              classes={{ root: classes.headCell }}
              key={column.field}
              sortDirection={orderBy === columnField ? order : false}
            >
              <TableSortLabel
                classes={{
                  root: classes.headRoot,
                  active: classes.headActive,
                  icon: classes.headIcon,
                }}
                IconComponent={({ className }) => (
                  <SortDownSmall className={`${className} skipOverride`} />
                )}
                active={orderBy === columnField}
                direction={orderBy === columnField ? order : sort.ASC}
                onClick={createSortHandler(columnField)}
              >
                {column.headerName}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell classes={{ root: classes.headCell }} key={column.field}>
              {column.headerName}
            </TableCell>
          );
        })}
      </MaterialTableRow>
    </MaterialTableHead>
  );
};

const TableRow = ({ classes, columns, row, rowId, rowIndex, selected, handleRowClick }) => {
  const [handleClick, handleDoubleClick] = useMouseClickEvents(
    (e) => handleRowClick(e, rowId, rowIndex),
    (e) => handleRowClick(e, rowId, rowIndex),
  );
  return (
    <MaterialTableRow
      className={classes.tableRow}
      classes={{ selected: classes.selectedRow }}
      selected={selected === rowIndex}
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}
    >
      {columns.map((column, index) => (
        <TableCell
          classes={{
            root: classes.bodyCell,
            body: classes.bodyText,
          }}
          className={`${rowIndex % 2 ? classes.alter : ''} ${
            selected === rowIndex ? classes.selected : ''
          }`}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
        >
          <div>{row[column.field]}</div>
        </TableCell>
      ))}
    </MaterialTableRow>
  );
};

const TablePagination = ({
  classes,
  count,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  rowsPerPageOptions,
}) => {
  const options = rowsPerPageOptions.map((option) => ({
    value: option,
    title: `${option} rows per page`,
  }));

  const handlePageChange = (button) => {
    if (button === 'previous') {
      setPage(Math.max(page - 1, 0));
    }
    if (button === 'next') {
      setPage(Math.min(page + 1, Math.ceil(count / rowsPerPage) - 1));
    }
  };

  const handleRowsPerPageChange = (value) => {
    setRowsPerPage(value);
    setPage(0);
  };

  return (
    <div className={classes.paginationWrapper}>
      <div className={classes.rowsPerPage}>
        <Select
          hideLabel
          options={options}
          selectedValue={rowsPerPage}
          onChange={handleRowsPerPageChange}
        />
      </div>
      <div className={classes.rowsCount}>
        <div
          className={`${classes.arrow} ${page === 0 ? classes.disabled : ''}`}
          onClick={() => handlePageChange('previous')}
          role="presentation"
        >
          <ArrowLeft />
        </div>
        <Typography className={classes.rowsCountText}>{`${Math.max(
          page * rowsPerPage,
          1,
        )} – ${Math.min((page + 1) * rowsPerPage, count)} of  ${count}`}</Typography>
        <div
          className={`${classes.arrow} ${
            page === Math.ceil(count / rowsPerPage) - 1 ? classes.disabled : ''
          }`}
          onClick={() => handlePageChange('next')}
          role="presentation"
        >
          <ArrowRight />
        </div>
      </div>
    </div>
  );
};

const TableView = ({
  columns,
  rows,
  defaultSort,
  setDefaultSort,
  onItemSelect,
  onItemDelete,
  selectItemOnArrow,
  selectable,
  defaultSelect,
  pagination,
  rowsPerPageOptions,
  loadingComponent,
  tableFocused,
  keysToCheck,
}) => {
  const classes = useStyles();
  const sortField = columns.find((column) => column.sortable);
  const [order, setOrder] = useState(sort.ASC);
  const [orderBy, setOrderBy] = useState(getFieldProperty(sortField, 'sortField', 'field'));
  const [selected, setSelected] = useState(selectable && defaultSelect ? 0 : null);
  const [page, setPage] = useState(pagination ? 0 : null);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [keyPressed, setKeyPressed] = useState(null);
  const scrollTop = 37;
  const scrollBottom = 7;

  const downHandler = useCallback(
    (e) => {
      if (keysToCheck.includes(e.key)) {
        e.preventDefault();
        setKeyPressed(e.key);
      }
    },
    [keysToCheck],
  );
  const upHandler = useCallback(
    (e) => {
      if (keysToCheck.includes(e.key)) {
        setKeyPressed(null);
      }
    },
    [keysToCheck],
  );

  useEffect(() => {
    if (tableFocused) {
      window.addEventListener('keydown', downHandler);
      window.addEventListener('keyup', upHandler);
    } else {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    }
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, [tableFocused, upHandler, downHandler]);

  useEffect(() => {
    // eslint-disable-next-line default-case
    switch (keyPressed) {
      case keys.arrowUp: {
        setKeyPressed(null);
        if (selected > 0) setSelected(selected - 1);
        if (selectItemOnArrow) {
          onItemSelect(
            paginateData(
              sortData(rows, getComparator(order, orderBy), !defaultSort),
              page,
              rowsPerPage,
              pagination,
            )[selected].rowId,
          );
        }
        break;
      }
      case keys.arrowDown: {
        setKeyPressed(null);
        const count = pagination ? rowsPerPage - 1 : rows.length - 1;
        if (selected < count) setSelected(selected + 1);
        if (selectItemOnArrow) {
          onItemSelect(
            paginateData(
              sortData(rows, getComparator(order, orderBy), !defaultSort),
              page,
              rowsPerPage,
              pagination,
            )[selected].rowId,
          );
        }
        break;
      }
      case keys.enter: {
        setKeyPressed(null);
        if (selected !== null) {
          const paginatedData = paginateData(
            sortData(rows, getComparator(order, orderBy), !defaultSort),
            page,
            rowsPerPage,
            pagination,
          )[selected];
          onItemSelect(paginatedData && paginatedData.rowId);
        }
        break;
      }
      case keys.delete: {
        setKeyPressed(null);
        if (selected !== null) {
          const paginatedData = paginateData(
            sortData(rows, getComparator(order, orderBy), !defaultSort),
            page,
            rowsPerPage,
            pagination,
          )[selected];
          onItemDelete(paginatedData && paginatedData.rowId);
        }
        break;
      }
    }
  }, [
    defaultSort,
    keyPressed,
    onItemDelete,
    onItemSelect,
    order,
    orderBy,
    page,
    pagination,
    rows,
    rowsPerPage,
    selectItemOnArrow,
    selected,
  ]);

  useEffect(() => {
    if (defaultSelect && selected === null) {
      setSelected(0);
    }
    if (!defaultSelect) {
      setSelected(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSelect]);

  useEffect(() => {
    if (defaultSort) {
      setOrder(sort.ASC);
      setOrderBy('');
    }
  }, [defaultSort]);

  useEffect(() => {
    if (rows) {
      setPage(0);
    }
  }, [rows]);

  const handleSortRequest = (_, property) => {
    const isAsc = orderBy === property && order === sort.ASC;
    setDefaultSort(false);
    setOrder(isAsc ? sort.DESC : sort.ASC);
    setOrderBy(property);
  };

  const handleRowClick = (value, rowId, rowIndex) => {
    if (
      selectable &&
      rowId !== undefined &&
      rowId !== null &&
      rowIndex !== undefined &&
      rowIndex !== null
    ) {
      setSelected(rowIndex);
      onItemSelect(rowId);
    }
  };

  return (
    <TableContainer className={classes.container}>
      <Scrollbar top={scrollTop} bottom={scrollBottom}>
        <MaterialTable stickyHeader classes={{ stickyHeader: classes.stickyHeader }}>
          <TableHead
            classes={classes}
            columns={columns}
            orderBy={orderBy}
            order={order}
            onSortRequest={handleSortRequest}
          />
          <TableBody className={classes.tableBody}>
            {paginateData(
              sortData(rows, getComparator(order, orderBy), !defaultSort),
              page,
              rowsPerPage,
              pagination,
            ).map((row, index) =>
              row.loading ? (
                // eslint-disable-next-line react/no-array-index-key
                <tr key={index}>
                  <td
                    className={index % 2 ? classes.loadingAlter : classes.loading}
                    colSpan={columns.length}
                  >
                    {loadingComponent}
                  </td>
                </tr>
              ) : (
                <TableRow
                  classes={classes}
                  columns={columns}
                  row={row}
                  rowId={row.rowId}
                  rowIndex={index}
                  selected={selected}
                  handleRowClick={handleRowClick}
                  key={row.rowId}
                />
              ),
            )}
            {pagination && (
              <tr>
                <td className={classes.emptyRow} colSpan={columns.length} />
              </tr>
            )}
          </TableBody>
        </MaterialTable>
      </Scrollbar>
      {pagination && (
        <div className={classes.paginationContainer}>
          <TablePagination
            count={rows.length}
            {...{ classes, page, setPage, rowsPerPage, setRowsPerPage, rowsPerPageOptions }}
          />
        </div>
      )}
    </TableContainer>
  );
};

TableView.propTypes = {
  /* Table columns */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      headerName: PropTypes.string,
      description: PropTypes.string,
      sortable: PropTypes.bool,
      width: PropTypes.number,
      valueGetter: PropTypes.func,
    }),
  ),
  /* Table rows */
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      rowId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ),

  /* Should table use default sorted data */
  defaultSort: PropTypes.bool,
  /* set the default sort value for table */
  setDefaultSort: PropTypes.func,
  /* Function to trigger when the item is clicked or press enter while selected */
  onItemSelect: PropTypes.func,
  /* Function to trigger when the item is selected and pressed delete */
  onItemDelete: PropTypes.func,
  /* Should select item on arrow up/down */
  selectItemOnArrow: PropTypes.bool,
  /* Are table rows selectable */
  selectable: PropTypes.bool,
  /* Should the first row of the table be selected by default */
  defaultSelect: PropTypes.bool,
  /* Should table show pagination */
  pagination: PropTypes.bool,
  /* Options for rows per page */
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  /* Options for rows per page */
  loadingComponent: PropTypes.element,
  /* If the table is focused */
  tableFocused: PropTypes.bool,
  /* Which keypress should be checked */
  keysToCheck: PropTypes.arrayOf(PropTypes.oneOf(Object.values(keys))),
};

TableView.defaultProps = {
  columns: [],
  rows: [],
  defaultSort: false,
  setDefaultSort: () => {},
  onItemSelect: () => {},
  onItemDelete: () => {},
  selectItemOnArrow: false,
  selectable: false,
  defaultSelect: true,
  pagination: false,
  rowsPerPageOptions: [20, 50, 100],
  tableFocused: true,
  loadingComponent: undefined,
  keysToCheck: [],
};

export default TableView;
