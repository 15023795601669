import PropTypes from 'prop-types';
import {
  addWeeks,
  getISOWeek,
  eachDay,
  format,
  isToday,
  isSameMonth,
  isWeekend,
  isSameDay,
  isThisMonth,
  getISODay,
  isWithinRange,
} from 'date-fns';
import Divider from 'components/divider';
import getDates, { TimeVariant } from 'utils/getDates';
import { chunkArray } from 'utils/arrayUtils';
import { isBeforeToday } from 'screens/rundown/components/editor/utils';
import DatePicker from '../datePicker';
import {
  RootWrapper,
  CalendarHeader,
  TypographyWrapper,
  CalendarBody,
  CalendarRow,
  Row,
  Cell,
  DateDivider,
} from './styled';

const daysOfWeek = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

const getWeekNumbers = (time) => {
  const weekNumbers = [...Array(6).keys()].map((a) => getISOWeek(addWeeks(time, a)));
  return weekNumbers;
};

const selectCalendarHeaderStyle = (index) => {
  const date = new Date();
  if (isThisMonth(date) && getISODay(date) === index + 1) return 'cellTextToday';
  if (index > 4) return 'cellTextHoliday';
  return 'cellText';
};

const DateSelector = ({
  date,
  setDate,
  onDateSelect,
  startDate,
  endDate,
  selectRange,
  disableScheduleInPast,
  ...rest
}) => {
  const { startDate: sDate, endDate: eDate } = getDates(date, TimeVariant.Month);

  const weekNumbers = getWeekNumbers(sDate);

  const isCurrentWeek = getISOWeek(new Date());

  const chunkedArray = chunkArray(eachDay(sDate, eDate), 7);

  const dateSelectedChecker = (selectedRangeDate) => {
    const isoRangeDate = new Date(selectedRangeDate).toISOString();

    // for single date checker without range
    if (!selectRange) return [isSameDay(isoRangeDate, date), null];

    if (!startDate) {
      // date is not selected or not within a range
      return [false, null];
    }

    if (!endDate) {
      if (isSameDay(isoRangeDate, startDate)) {
        // date is selected as the start of the range
        return [true, null];
      }
      // date is not selected or not within a range
      return [false, null];
    }

    if (isSameDay(startDate, endDate)) {
      if (isSameDay(isoRangeDate, startDate)) {
        // date is selected as a single-day range
        return [true, null];
      }
      // date is not selected or not within a range
      return [false, null];
    }

    if (isSameDay(isoRangeDate, endDate)) {
      // date is selected as the end of the range
      return [true, 'right'];
    }

    if (isSameDay(isoRangeDate, startDate)) {
      // date is selected as the start of the range
      return [true, 'left'];
    }

    if (startDate < endDate && !isWithinRange(isoRangeDate, startDate, endDate)) {
      // date is not selected or not within a range
      return [false, null];
    }

    if (isSameDay(startDate, endDate)) {
      // date is selected as a single-day range
      return [true, null];
    }

    // date is selected and within the range
    return [true, 'middle'];
  };

  return (
    <RootWrapper>
      <CalendarHeader>
        <Cell>
          <TypographyWrapper $typographyType="weekText">Week</TypographyWrapper>
        </Cell>
        {daysOfWeek.map((dayOfWeek, index) => (
          <Cell key={dayOfWeek}>
            <TypographyWrapper $typographyType={selectCalendarHeaderStyle(index)}>
              {dayOfWeek}
            </TypographyWrapper>
          </Cell>
        ))}
      </CalendarHeader>
      <Divider />

      <CalendarBody>
        {weekNumbers.map((week, index) => (
          <CalendarRow key={week}>
            <Row>
              <Cell>
                <TypographyWrapper
                  $typographyType={isCurrentWeek === week ? 'cellTextToday' : 'weekText'}
                >
                  {week}
                </TypographyWrapper>
              </Cell>
              <Divider orientation="vertical" flexItem />

              {chunkedArray[index].map((d) => {
                const disabled = disableScheduleInPast && isBeforeToday(d);
                const [isSelected, rangePosition] = dateSelectedChecker(d);

                return (
                  <Cell
                    role="presentation"
                    onClick={() => {
                      if (disabled) return;
                      if (!startDate || endDate || !selectRange) {
                        setDate(d.toISOString());
                        onDateSelect(d.toISOString(), 'date');
                      } else {
                        onDateSelect(d.toISOString(), 'endDate');
                      }
                    }}
                    key={d}
                  >
                    <DatePicker
                      text={format(d, 'D')}
                      isWeekend={isWeekend(d)}
                      isOtherMonth={!isSameMonth(d, date)}
                      isToday={isToday(d)}
                      isSelected={isSelected}
                      rangePosition={rangePosition}
                      disabled={disabled}
                      selectingRange={selectRange && startDate && !endDate}
                      {...rest}
                    />
                  </Cell>
                );
              })}
            </Row>
            <DateDivider />
          </CalendarRow>
        ))}
      </CalendarBody>
    </RootWrapper>
  );
};

DateSelector.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
  setDate: PropTypes.func,
  onDateSelect: PropTypes.func,
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  isSelectingRange: PropTypes.bool,
};

DateSelector.defaultProps = {
  setDate: () => {},
  onDateSelect: () => {},
  startDate: '',
  endDate: '',
  isSelectingRange: false,
};

export default DateSelector;
