const branchName: string | undefined = import.meta.env.REACT_APP_REPO_BRANCHNAME as
  | string
  | undefined;

const urls = {
  ABOUT: 'https://7mountains.com/',
  TERMS_CONDITIONS: 'https://fonngroup.com/fonn-group-general-terms-and-conditions/',
  CHANGELOG_URL: branchName?.startsWith('prod')
    ? 'https://sites.google.com/7mountains.com/dina-changelog'
    : 'https://sites.google.com/7mountains.com/dina-changelog-stage',
  HELP_CENTER_URL: 'https://fonngroup.com/help-center',
  SEND_FEEDBACK_URL: 'https://survey-eu1.hsforms.com/1MunJm-P_Sne3SDvxEZF4IAex5sq',
};

export default urls;
