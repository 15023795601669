import { useDrop } from 'react-dnd';
import { Editor } from 'slate';
import { useSlate, useReadOnly, ReactEditor } from 'slate-react';
import selectElement from 'components/editor/utils/selectElement';
import dndTypes from 'utils/dndTypes';
import isSection from '../../sectionDivider/utils/isSection';

/**
 * Hook to declare dropzone for a node
 * returns a ref for the dropzone
 */

const useDropZone = (accept, onDrop, element) => {
  const editor = useSlate();
  const readOnly = useReadOnly();

  const [collected, dropRef] = useDrop({
    accept,
    canDrop: (item) => {
      if (isSection(item.payload)) {
        const path = ReactEditor.findPath(editor, element);
        if (path.length > 0) {
          const [parentNode] = Editor.parent(editor, path);
          if (isSection(parentNode)) {
            return parentNode?.data?.itemId !== item.payload?.data?.itemId;
          }
        }
      }
      return !readOnly;
    },
    drop: (item) => {
      if (item.type === dndTypes.EDITOR_BLOCK) onDrop(item);
      else if (!collected.didDrop) {
        onDrop(item);
      }
    },
    collect: (monitor) => {
      if (monitor.getItemType() === dndTypes.EDITOR_BLOCK)
        return { hovered: monitor.canDrop() && monitor.isOver() };
      const isActive = monitor.canDrop() && monitor.isOver();

      if (isActive) {
        selectElement(editor, element);
      }

      return { didDrop: monitor.didDrop() };
    },
  });

  return [collected, dropRef];
};

export default useDropZone;
