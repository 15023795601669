import { elementTypes } from 'components/editor/constants';
import { generateName } from 'components/editor/utils';
import { Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import actionTypes from 'components/editor/constants/types/actionTypes';

const { insertNodes, setNodes } = Transforms;

/**
 * Inserts image element
 *
 * @param {Object} editor SlateJS editor instance
 * @param {Function} update Callback to be invoked on update
 * @param {Object} file Uploaded file
 * @param {Object} fileUrl Browser object url for the file
 * @returns {Object} SlateJS editor instance
 */

const insertImage = async (editor, update, file, fileUrl) => {
  const fileName = generateName(file.type);

  const element = {
    type: elementTypes.IMAGE,
    data: { cache: fileUrl },
    children: [{ text: '' }],
  };

  insertNodes(editor, element);

  try {
    const data = await update({
      type: actionTypes.ASSET_INSERT,
      payload: { document: editor.children, file, fileName },
    });

    // TODO: Added this condition as data is undefined if update is () => {}
    if (data) {
      const path = ReactEditor.findPath(editor, element);
      setNodes(editor, { data }, { at: path });
    }
  } catch (error) {
    // logger.log(error)
  }

  return editor;
};

export default insertImage;
