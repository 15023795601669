import memberTypes from 'operations/memberTypes';

const getIsUnscheduled = (scheduleType, mType) => {
  if (
    mType === memberTypes.USER_STORY ||
    mType === memberTypes.TEAM_STORY ||
    mType === memberTypes.DEPARTMENT_STORY
  )
    return undefined;
  if (scheduleType === 'scheduled') return false;
  if (scheduleType === 'unscheduled') return true;
  return undefined;
};

export default getIsUnscheduled;
