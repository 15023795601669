import { useState, useRef, useEffect, lazy, Suspense } from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import { Switch, Typography } from '@material-ui/core';

import { ReactComponent as Close } from 'assets/icons/systemicons/close.svg';
import { Button } from 'components/buttons';
import useFuseSearch from 'hooks/useFuseSearch';
import Table, { keys } from 'components/table';
import Tabs from 'components/tabs/contained';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import getFieldProperty from 'components/table/utils/getFieldProperty';
import Divider from 'components/divider';
import Select from 'components/select';

import getHighlighted from './utils/getHighlighted';
import useStyles from './searchTable-styles';
import searchbarPositions from './constants/searchbarPositions';
import SearchInput from './components/searchInput';
import { Flex } from 'layouts/box/Box';

const ResourceTimeline = lazy(() => import('components/resource-timeline'));

const SearchTableView = ({
  title,
  columns,
  rows,
  onClose,
  tabs,
  tabIndex,
  onTabChange,
  pagination,
  rowsPerPageOptions,
  onItemSelect,
  closeOnSelect,
  showFooter,
  onOk,
  inputType,
  selectedItems,
  setSelectedItems,
  onDeleteSelectedItem,
  tabContentType,
  timelineItems,
  storyInformationForTimeline,
  resourceTimelineDropdownItems,
  selectedType,
  onChangeType,
  showAvailableResourceOnly,
  setShowAvailableResourceOnly,
  shouldDisableTypeFilter,
  showTitle,
  defaultSelect,
  showSearchIcon,
  searchbarPosition,
  usageType,
  selectable,
}) => {
  const classes = useStyles();
  const [tableData, setTableData] = useState(rows);
  const [searchText, setSearchText] = useState('');
  const [defaultSort, setDefaultSort] = useState(false);
  const [groups, setGroups] = useState([]);

  const inputRef = useRef();

  useEffect(() => {
    if (rows && searchText === '') {
      setTableData(rows);
      return;
    }

    if (rows && searchText !== '') {
      const searchedData = getSearchData(rows, searchText);
      setTableData(getHighlighted(searchedData, searchText, classes));
    }
  }, [rows]);

  useEffect(() => {
    if (tableData) {
      const computedGroups = tableData.reduce((prev, curr) => {
        prev.push({
          ...curr,
          id: curr.rowId,
          content: ReactDOMServer.renderToString(curr.mTitle),
          showButton: curr.showButton,
        });
        return prev;
      }, []);
      setGroups(computedGroups);
    }
  }, [tableData]);

  const search = useFuseSearch(
    {
      shouldSort: true,
      includeMatches: true,
    },
    false,
  );

  const getSearchData = (rows, text) => {
    return search(
      rows,
      tabContentType === 'resource-timeline'
        ? ['mTitle']
        : columns.map((column) => getFieldProperty(column, 'searchField', 'field')),
      text,
    );
  };

  const onValueChange = (event) => {
    const val = event?.target?.value || '';
    setSearchText(val);
    if (val === '') {
      setDefaultSort(false);
      setTableData(rows);
    } else {
      setDefaultSort(true);
      const searchedData = getSearchData(rows, val);
      setTableData(getHighlighted(searchedData, val, classes));
    }
  };

  const setItems = (items) => {
    setDefaultSort(false);
    setSelectedItems(items);
  };

  const clearInput = () => {
    inputRef.current.value = '';
    inputRef.current.focus();
    setTableData(rows);
    setSearchText('');
  };

  const onSelect = (rowId) => {
    const selectedItem = rows.find((row) => row.rowId === rowId);
    if (selectedItem) {
      onItemSelect(selectedItem);
      if (closeOnSelect) {
        onClose();
      }
    }
  };

  const handleOkClick = () => {
    onOk();
    onClose();
  };

  const SearchInputField = () => (
    <SearchInput
      inputType={inputType}
      selectedItems={selectedItems}
      onDeleteSelectedItem={onDeleteSelectedItem}
      onValueChange={onValueChange}
      clearInput={clearInput}
      setItems={setItems}
      showSearchIcon={showSearchIcon}
      usageType={usageType}
      inputRef={inputRef}
      searchbarPosition={searchbarPosition}
    />
  );

  const tabLabels = tabs?.map((tab) => tab.label);

  const handleTabChange = (label) => {
    const index = tabLabels.findIndex((tab) => tab === label);
    const tab = tabs[index];
    onTabChange(tab, index);
  };

  return (
    <div className={classes.root}>
      {showTitle && (
        <div className={classes.titleContainer}>
          <Typography className={classes.title}>{title}</Typography>
          <div className={classes.closeButtonContainer} onClick={onClose} role="presentation">
            <Close />
          </div>
        </div>
      )}
      {tabs && (
        <Flex width="100%" padding="0 8px">
          <Tabs
            tabs={tabLabels}
            activeTab={tabLabels[tabIndex]}
            setActiveTab={handleTabChange}
            styleProps={{
              fullWidth: true,
            }}
          />
        </Flex>
      )}

      {searchbarPosition === searchbarPositions.TOP && SearchInputField()}
      {tabContentType === 'resource-timeline' ? (
        <>
          <div className={classes.resourceTimelineHeader}>
            <div className={classes.typeFilter}>
              <Select
                selectedValue={selectedType}
                items={resourceTimelineDropdownItems}
                onChange={onChangeType}
                disabled={shouldDisableTypeFilter}
                hideLabel
              />
            </div>
            <div className={classes.toggle}>
              <span>Only show available</span>
              <Switch
                classes={{
                  thumb: classes.toggleThumb,
                  colorPrimary: classes.primary,
                  checked: classes.checked,
                  track: classes.track,
                }}
                color="primary"
                checked={showAvailableResourceOnly}
                onChange={setShowAvailableResourceOnly}
                name="showAvailableResourceOnlySwitch"
                inputProps={{ 'aria-label': 'showAvailableResourceOnlySwitch' }}
              />
            </div>
          </div>
          <div className={classes.timelineContainer}>
            <Suspense fallback={<LoadingIndicator />}>
              <ResourceTimeline
                key={`resourceTimeline-${selectedType}-${Math.ceil(Math.random() * 10)}`}
                groups={groups}
                items={timelineItems}
                onItemSelect={onSelect}
                storyInformation={storyInformationForTimeline}
              />
            </Suspense>
          </div>
        </>
      ) : (
        <div className={classes.tableContainer}>
          <Table
            columns={columns}
            rows={tableData}
            defaultSort={defaultSort}
            setDefaultSort={setDefaultSort}
            onItemSelect={onSelect}
            selectable={selectable}
            pagination={pagination}
            rowsPerPageOptions={rowsPerPageOptions}
            keysToCheck={[keys.arrowUp, keys.arrowDown, keys.enter]}
            defaultSelect={defaultSelect}
          />
        </div>
      )}
      {searchbarPosition === searchbarPositions.BOTTOM && SearchInputField()}
      {showFooter && (
        <>
          <Divider />
          <div className={classes.footer}>
            <Button width={120} height={32} usage="outlined" variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <Button
              width={120}
              height={32}
              variant="contained"
              usage="significant"
              onClick={handleOkClick}
            >
              Ok
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

SearchTableView.propTypes = {
  /* Title of the search table */
  title: PropTypes.string,

  /* Table columns */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      headerName: PropTypes.string,
      description: PropTypes.string,
      sortable: PropTypes.bool,
      width: PropTypes.number,
      valueGetter: PropTypes.func,
    }),
  ),
  /* Table rows */
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      rowId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ),

  /* Function to run on close button click */
  onClose: PropTypes.func,
  /* Index to determine which tab is selected */
  tabIndex: PropTypes.number,
  /* Function to run on tab change */
  onTabChange: PropTypes.func,
  /* Should table show pagination */
  pagination: PropTypes.bool,
  /* Options for rows per page */
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  /** Function to run when we select a row */
  onItemSelect: PropTypes.func,
  /** boolean that calls onClose callback on item select */
  closeOnSelect: PropTypes.bool,
  /** Boolean to show footer */
  showFooter: PropTypes.bool,
  /** Callback of OK button */
  onOk: PropTypes.func,
  /** type of input for search table */
  inputType: PropTypes.oneOf(['default', 'chip']),
  /** a list of selected items to be shown in 'chip' input  */
  selectedItems: PropTypes.arrayOf(
    PropTypes.shape({
      mId: PropTypes.string,
      mTitle: PropTypes.string,
      mAvatarUrl: PropTypes.string,
    }),
  ),
  /** callback of the delete button of the chip in 'chip' input */
  onDeleteSelectedItem: PropTypes.func,
  /** type of tab content for search table with tabs and chips */
  tabContentType: PropTypes.oneOf(['default', 'resource-timeline']),
  /** Boolean to show title */
  showTitle: PropTypes.bool,
  /** Boolean to default select table row */
  defaultSelect: PropTypes.bool,
  /** Boolean to show search icon */
  showSearchIcon: PropTypes.bool,
  /** Postion of search bar Top or bottom */
  searchbarPosition: PropTypes.oneOf(Object.values(searchbarPositions)),
  /** Type to use styles in settings search bar */
  usageType: PropTypes.string,
  /** Boolean to select table row */
  selectable: PropTypes.bool,
};

SearchTableView.defaultProps = {
  title: 'Search',
  columns: [],
  rows: [],
  onClose: () => {},
  tabIndex: 0,
  onTabChange: () => {},
  pagination: false,
  rowsPerPageOptions: [20, 50, 100],
  onItemSelect: () => {},
  closeOnSelect: true,
  showFooter: false,
  onOk: () => {},
  inputType: 'default',
  selectedItems: [],
  onDeleteSelectedItem: () => {},
  tabContentType: 'default',
  showTitle: true,
  defaultSelect: true,
  showSearchIcon: false,
  searchbarPosition: searchbarPositions.TOP,
  usageType: '',
  selectable: true,
};

export default SearchTableView;
