import styled from '@emotion/styled';

import Avatar, { AvatarVariant } from 'components/avatar/Avatar';

interface AvatarCellProps {
  mTitle?: string;
  mAvatarKey?: string;
  avatarVariant?: AvatarVariant;
}

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  > :first-of-type {
    margin-right: 8px;
  }
`;

const AvatarCell = ({ mTitle = '', mAvatarKey, avatarVariant = 'user' }: AvatarCellProps) => {
  return (
    <Wrapper>
      <Avatar imageKey={mAvatarKey} size={24} variant={avatarVariant} title={mTitle} />
      {mTitle}
    </Wrapper>
  );
};

export default AvatarCell;
