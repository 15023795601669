import gql from 'graphql-tag';

export default gql`
  mutation CreateStoryInstance($input: CreateInstanceInput) {
    createInstance(input: $input) {
      mId
      mRefId
      mTitle
      mState
      mType
      mUpdatedAt
      mCreatedAt
      mUpdatedById
      mPublishingAt
      mPublishingEnd
      mStoryId
      locked
      mThumbnailKey
      mAvatarKey
      mContentKey
      mDefaultContentKey
      mDescription
      mAssignedMembers {
        mId
        mType
      }
      metadata
      mMetaData {
        key
        value
        autoValue
        manual
      }
      mRelatedMembers
      mProperties {
        __typename
        ... on PlatformType {
          id
          platform
          platformKind
          provider {
            requestId
            state
            message
            embeddedEndpoint
            previewEndpoint
            updateEndpoint
          }
          account {
            id
            accountUrl
            accountLogo
            accountTitle
            accountId
            accountRefId
            orderType
          }
        }
      }
    }
  }
`;
