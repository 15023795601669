import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import useStyles from './generalLabel-styles';

const GeneralLabel = ({ label, severity, usage, ...rest }) => {
  const classes = useStyles();
  return (
    <div className={classes[usage]} {...rest}>
      <Typography
        classes={{ root: classes[severity] }}
      >{`${label}:`}</Typography>
    </div>
  );
};

GeneralLabel.propTypes = {
  /** label to show  */
  label: PropTypes.string,
  /** severity indicated the color of the text */
  severity: PropTypes.string,
  /** usage of the label */
  usage: PropTypes.string,
};

GeneralLabel.defaultProps = {
  label: '',
  severity: 'regular',
  usage: 'standard',
};

export default GeneralLabel;
