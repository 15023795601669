import { ReactComponent as EditOffIcon } from 'assets/icons/systemicons/edit.svg';
import { ReactComponent as ArchivedIcon } from 'assets/icons/systemicons/status/archived.svg';
import { ReactComponent as FailedIcon } from 'assets/icons/systemicons/status/error.svg';
import { ReactComponent as ExpiredIcon } from 'assets/icons/systemicons/status/expired.svg';
import { ReactComponent as InProgressIcon } from 'assets/icons/systemicons/status/inProgress.svg';
import { ReactComponent as InReviewIcon } from 'assets/icons/systemicons/status/inReview.svg';
import { ReactComponent as PendingIcon } from 'assets/icons/systemicons/status/pending.svg';
import { ReactComponent as PublishedIcon } from 'assets/icons/systemicons/status/published.svg';
import { ReactComponent as ReadyIcon } from 'assets/icons/systemicons/status/ready.svg';
import { ReactComponent as ToDoIcon } from 'assets/icons/systemicons/status/toDo.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/systemicons/status/warning_small.svg';
import { Version } from 'types';
import { AuditActionEnum } from 'types/graphqlTypes';

const stateComponents: Record<string, React.FC<React.SVGProps<SVGSVGElement>>> = {
  todo: ToDoIcon,
  progress: InProgressIcon,
  review: InReviewIcon,
  ready: ReadyIcon,
  pending: PendingIcon,
  publish: PublishedIcon,
  archive: ArchivedIcon,
  fail: FailedIcon,
  expire: ExpiredIcon,
};

/**
 * Function to determine the icon component based on the action ID and new state.
 *
 * @param {AuditActionEnum} actionId - The action ID
 * @param {string} newState - The new state
 * @return {React.ComponentType} The icon component based on the action and state
 */
const iconComponent = (actionId: Version['actionId'], newState: string) => {
  if (actionId === AuditActionEnum.StateChanged) return stateComponents[newState] ?? WarningIcon;
  return EditOffIcon;
};

/**
 * Formats the ID by removing the prefix, replacing hyphens with spaces, and
 * capitalizing the first letter.
 *
 * @param {string} id - the ID to be formatted
 * @return {string} the formatted ID
 */
export const formatId = (id: string) => {
  const withoutPrefix = id?.replace(/^\d+-/, '');
  const withoutHyphens = withoutPrefix?.replace(/-/g, ' ');
  const capitalized = withoutHyphens?.charAt(0)?.toUpperCase() + withoutHyphens?.slice(1);
  return capitalized;
};

/**
 * Generates timeline item props based on the provided version data.
 *
 * @param {Version} param - Object containing id, type, actionId, and labels properties.
 * @return {Object} Object containing itemHeight, eventState, customEvent, assignee, and
 * validType properties.
 */
export const getTimelineItemProps = ({ id, type, actionId, labels }: Version) => {
  let itemHeight = 48;

  const [eventState, customEvent] = labels ?? [null, null];

  if (eventState) itemHeight += 20;
  if (customEvent) itemHeight += 22;
  if (actionId === AuditActionEnum.StateChanged) itemHeight += 20;

  const assigneeId = type === 'user' && id ? id : null;
  if (assigneeId) itemHeight += 20;

  const validType = type?.includes('instance') ? 'Instance ' : null;

  return { itemHeight, eventState, customEvent, assigneeId, validType };
};

/**
 * Static verbs for various action ids
 */
export const updatingVerbByActionId = {
  [AuditActionEnum.StateChanged]: 'Status change by ',
  [AuditActionEnum.Version]: 'Edited by ',
  [AuditActionEnum.Created]: 'Created by ',
  [AuditActionEnum.Assigned]: 'Was assigned by ',
};

export default iconComponent;
