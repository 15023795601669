/* eslint-disable react/react-in-jsx-scope */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { elementTypes } from 'components/editor/constants/types';
import listComponents from '../list/constants/listComponents';

const listItemStyle = css`
  margin: 0;
  padding: 2px 0px;
`;

const ListItem = ({ attributes, children, element }) => {
  const ListComponent = useMemo(() => listComponents[element.type], [element.type]);

  return (
    <ListComponent css={listItemStyle} {...attributes}>
      {children}
    </ListComponent>
  );
};

ListItem.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

ListItem.defaultProps = {
  attributes: {},
  children: null,
  element: {
    type: elementTypes.LIST_ITEM,
    children: [],
  },
};

export default memo(ListItem);
