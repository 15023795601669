import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { TextField } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import transientOptions from 'theme/helpers';

export const TextWrapper = styled('div')`
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  width: 100%;
  margin-bottom: 5px;
  > :first-of-type {
    margin-bottom: 4px;
  }
`;

export const Field = styled('span')``;

export const StyledTextField = styled(TextField, transientOptions)`
  svg path {
    fill: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
  .MuiFilledInput-root {
    padding: 2px;
    width: 100%;
    padding: 4px;
    display: inline-flex;
    flex-flow: row wrap;
    min-height: 24px;
    border-radius: 4px;
    &:hover {
      background-color: ${({ theme }) => theme.palette.dina.outlineButtonOnHover};
    }
    &.Mui-focused {
      ${({ theme, $hasError }) => css`
        border-color: ${$hasError ? `1px solid red` : theme.palette.dina.statusOnFocused};
        background: ${theme.palette.dina.statusOnFocusedDiscreetFill};
      `}
    }
  }
  input {
    width: 100%;
    font-size: 14px;
    padding: 4px 6px;
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder {
      color: ${({ theme }) => theme.palette.dina.disabled};
    }
  }
  .MuiFilledInput-underline {
    &:hover:not($disabled):not($focused):not($error):before {
      border-bottom: none;
    }
    &:after {
      border-color: transparent;
    }
    &:before {
      border-color: transparent;
    }
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;
