import { forwardRef, memo } from 'react';

import { DNDKitUseSortableProps } from 'types/dndkit/sortable';
import { MemberType } from 'types/graphqlTypes';

interface RenderItemProps extends DNDKitUseSortableProps {
  member?: MemberType;
  parentLaneId: string;
}

interface Props extends DNDKitUseSortableProps {
  disabled?: boolean;
  renderItem?: (props: RenderItemProps) => JSX.Element;
  member?: MemberType;
  parentLaneId: string;
}

const Item = forwardRef(
  ({ listeners, renderItem, transform, transition, member, parentLaneId }: Props, ref) =>
    renderItem
      ? renderItem({
          listeners,
          ref,
          transform,
          transition,
          member,
          parentLaneId,
        })
      : null,
);

Item.displayName = 'Item';

export default memo(Item);
