/* eslint-disable react/no-array-index-key */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { ChipListWrapper, ChipWrapper, CloseIcon } from './styled';

const ChipGroup = ({ list, removeChip }) => (
  <ChipListWrapper>
    {list.map((tag, index) => (
      <li key={`${tag.value}-${index}`}>
        <ChipWrapper>
          {tag.value}
          <CloseIcon
            onClick={(event) => {
              removeChip(event, index);
            }}
          />
        </ChipWrapper>
      </li>
    ))}
  </ChipListWrapper>
);

ChipGroup.propTypes = {
  /** list of content to show on chip */
  list: PropTypes.arrayOf(PropTypes.object),
  /** removeChip callback function */
  removeChip: PropTypes.func,
};

ChipGroup.defaultProps = {
  list: [],
  removeChip: () => {},
};

export default memo(ChipGroup);
