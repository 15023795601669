import { useCallback, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ListItem, Slide } from '@material-ui/core';
import PropTypes from 'prop-types';

import Calendar from 'components/calendar';
import { ConfirmDialog } from 'components/dialogs/CommonDialogs';
import LinearProgress from 'components/linearProgress';
import Popover from 'components/shared/popover';
import StoryCard from 'components/storyCard';
import memberTypes from 'operations/memberTypes';
import useCheckUserRight from 'hooks/useCheckUserRight';
import useLeftPanelNavigation from 'hooks/useLeftPanelNavigation';
import useMouseClickEvents from 'hooks/useMouseClickEvents';
import dndTypes from 'utils/dndTypes';

import { useHideStoryThumbnail, useStoryListItemSize } from '../store/storyOptions';

import SubMenu from './subMenu';

import useStyles from './styles';

const getMType = (archived, isPitch) => {
  if (!archived) return undefined;
  return isPitch ? memberTypes.ARCHIVED_PITCH : memberTypes.ARCHIVED_STORY;
};

const DialogContent = ({
  title,
  dialog,
  groupType,
  archived,
  joined,
  isPitch,
  closeDialog,
  confirmDialog,
}) => {
  const itemType = isPitch || groupType === memberTypes.PITCH ? 'Pitch' : 'Story';
  const dialogPropertiesMap = {
    archive: {
      itemType: itemType,
      dialogTitle: archived ? 'Un-archive' : 'Archive',
      confirmButtonLabel: archived ? 'Unarchive' : 'Archive',
      confirmButtonType: archived ? 'danger' : 'significant',
      info: `${itemType}: "${title}" will be ${
        archived ? 'un' : ''
      }archived. Are you sure you want to ${archived ? 'un' : ''}archive the ${itemType}?`,
    },
    join: {
      itemType: itemType,
      dialogTitle: joined ? 'Leave' : 'Join',
      confirmButtonLabel: joined ? 'Leave' : 'Join',
      confirmButtonType: joined ? 'danger' : 'significant',
      info: `Are you sure you want to ${joined ? 'leave' : 'join'} the ${itemType}?`,
    },
  };

  const dialogProperties = dialogPropertiesMap[dialog];

  if (!dialogProperties) {
    return null;
  }

  const { dialogTitle, confirmButtonLabel, confirmButtonType, info } = dialogProperties;

  return (
    <ConfirmDialog
      open={!!dialog}
      onClose={closeDialog}
      onClick={confirmDialog}
      title={`${dialogTitle} ${dialogProperties.itemType}?`}
      confirmLabel={confirmButtonLabel}
      usage={confirmButtonType}
      message={info}
    />
  );
};

DialogContent.propTypes = {
  title: PropTypes.string.isRequired,
  dialog: PropTypes.string,
  groupType: PropTypes.string.isRequired,
  archived: PropTypes.bool.isRequired,
  joined: PropTypes.bool.isRequired,
  isPitch: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  confirmDialog: PropTypes.func.isRequired,
};

DialogContent.defaultProps = {
  dialog: null,
};

const Story = (props) => {
  const {
    title,
    updatedAt,
    selectedDateRange,
    image,
    id,
    onClick,
    onSameClick,
    onDoubleClick,
    selected,
    assignedUsers,
    groupType,
    archived,
    bookmarked,
    joined,
    subMenuActionHandler,
    onUpdatePublishDate,
    dragSourceEnabled,
    isPitch,
    isStorySelected,
    isStoryFocused,
    isRestricted,
    storyType,
    handleDrop,
    enableUpdate,
    showArchiveButton,
    canSelectRange,
    disableLeaveButton,
    handleContextClick,
  } = props;

  const [storyListItemSize] = useStoryListItemSize();
  const [hideStoryThumbnail] = useHideStoryThumbnail();
  const [hoverRef, isHovered] = useLeftPanelNavigation(isStoryFocused);
  const [isSubMenuOpen, setSubMenuOpen] = useState(false);
  const [dialog, setDialog] = useState(null);
  const [handleClick, handleDoubleClick] = useMouseClickEvents(onClick, onDoubleClick);
  const [popAnchorEl, setPopAnchorEl] = useState(null);
  const [dropEffect, setDropEffect] = useState(false);
  const [checkUserRight] = useCheckUserRight();
  const canChangeScheduleTime = checkUserRight('story', 'schedule-story');
  const handleDropEffect = () => {
    setDropEffect(true);

    setTimeout(() => {
      setDropEffect(false);
    }, 1500);
  };

  const [, dragRef] = useDrag({
    type: isPitch ? dndTypes.PITCH : dndTypes.STORY,
    item: () => props,
    canDrag: () => dragSourceEnabled && enableUpdate,
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  const [{ hovered }, dropRef] = useDrop({
    accept: [dndTypes.MEMBER, dndTypes.FEED_ITEM],
    drop: (item, monitor) => {
      if (!enableUpdate) return;
      const type = monitor.getItemType();

      if (type === dndTypes.FEED_ITEM) {
        handleDropEffect();
        return { storyId: id, storyType };
      }

      if (type === dndTypes.MEMBER) {
        handleDrop(monitor.getItem().mId, id, monitor.getItem().type);
      }
    },
    collect: (monitor) => ({ hovered: monitor.isOver() }),
  });

  const classes = useStyles({ hovered, isPitch });
  const closeDialog = () => setDialog(null);
  const openDialog = (btn) => setDialog(btn);

  const subMenuClickHandler = (btn) => {
    closeSubMenu();
    if (btn === 'archive' || btn === 'join') {
      openDialog(btn);
    } else {
      subMenuActionHandler(btn);
    }
  };

  const handleBookmarkClick = useCallback(
    (e) => {
      e.stopPropagation();
      subMenuActionHandler('bookmark');
    },
    [subMenuActionHandler],
  );

  const onMoreClick = (e) => {
    e.stopPropagation();
    setSubMenuOpen(true);
  };

  const closeSubMenu = () => setSubMenuOpen(false);

  const confirmDialog = () => {
    closeDialog();
    const mType = getMType(archived, isPitch);
    subMenuActionHandler(dialog, mType);
  };

  const handleClose = () => {
    setPopAnchorEl(null);
  };

  const handleScheduleClick = (e) => {
    e.stopPropagation();
    if (enableUpdate) setPopAnchorEl(e.currentTarget);
  };

  const handleChange = (newDates) => {
    onUpdatePublishDate(newDates);
    handleClose();
  };

  const handleUnschedule = () => {
    onUpdatePublishDate();
    handleClose();
  };

  const attachRef = useCallback((elm) => {
    dragRef(elm);
    dropRef(elm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleListItemClick = (e) => {
    if (!isStorySelected) {
      handleClick(e);
    } else {
      onSameClick(e);
    }
  };

  return (
    <div className={classes.focusDiv} ref={attachRef}>
      <ListItem
        button
        selected={selected}
        className={`${(popAnchorEl || isStoryFocused) && classes.popoverItem}`}
        classes={{
          root: classes.listItem,
          selected: classes.selected,
        }}
        onClick={handleListItemClick}
        onDoubleClick={handleDoubleClick}
        onContextMenu={handleContextClick}
        ref={hoverRef}
        disableGutters
      >
        <StoryCard
          selected={selected}
          size={storyListItemSize}
          hideThumbnail={hideStoryThumbnail}
          isPitch={isPitch}
          title={title}
          image={image}
          scheduledAt={selectedDateRange}
          updatedAt={updatedAt}
          isRestricted={isRestricted}
          assignedUsers={assignedUsers}
          isHovered={isHovered}
          isBookmarked={bookmarked}
          enableUpdate={enableUpdate}
          onMoreClick={onMoreClick}
          onScheduleClick={handleScheduleClick}
          onBookmarkClick={handleBookmarkClick}
        />
        <Slide
          in={isSubMenuOpen}
          timeout={{ enter: 250, exit: 250 }}
          mountOnEnter
          unmountOnExit
          direction="left"
        >
          <div className={classes.subMenu} onMouseLeave={closeSubMenu}>
            <SubMenu
              groupType={groupType}
              archived={archived}
              bookmarked={bookmarked}
              joined={joined}
              clickHandler={subMenuClickHandler}
              showArchiveButton={showArchiveButton}
              disableLeaveButton={joined && disableLeaveButton}
            />
          </div>
        </Slide>
        {dropEffect && <LinearProgress height={1} left={32} position="bottom" />}
      </ListItem>
      <Popover
        anchorEl={popAnchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        {canChangeScheduleTime ? (
          <div className={classes.calendarContainer}>
            <Calendar
              selectedDateRange={selectedDateRange}
              changeSelectedDateRange={handleChange}
              onUnschedule={handleUnschedule}
              selectRange={canSelectRange}
              onClose={handleClose}
              showTimePicker
            />
          </div>
        ) : (
          <div style={{ padding: '4px' }}>
            You do not have permission to update the schedule time
          </div>
        )}
      </Popover>
      <DialogContent
        title={title}
        dialog={dialog}
        groupType={groupType}
        archived={archived}
        joined={joined}
        isPitch={isPitch}
        closeDialog={closeDialog}
        confirmDialog={confirmDialog}
      />
    </div>
  );
};

Story.propTypes = {
  /** Story title */
  title: PropTypes.string.isRequired,
  /** Url for thumbnail */
  image: PropTypes.string,
  /** bookmarked */
  bookmarked: PropTypes.bool,
};

Story.defaultProps = {
  image: '',
  bookmarked: false,
};

export default Story;
