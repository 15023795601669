const markTypes = {
  BOLD: 'bold',
  ITALIC: 'italic',
  UNDERLINE: 'underline',
  STRIKE_THROUGH: 'strikeThrough',
  COLOR: 'color',
  UPPERCASE: 'uppercase',
};

export default markTypes;
