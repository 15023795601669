import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import useStyles from './authAudit-styles';

const AuthAudit = ({ props }) => {
  const classes = useStyles()();

  const text = JSON.stringify(props, 0, 1);

  return (
    <>
      <Paper className={classes.root}>
        <Typography className={classes.body1}>
          <pre>{text}</pre>
        </Typography>
      </Paper>
    </>
  );
};

export default AuthAudit;
