import { isObject } from 'lodash';

import { SearchParameters } from 'api/search';
import { FieldValue, MiniMember } from 'types/forms/forms';
import { MemberType, MemberTypeEnum } from 'types/graphqlTypes';

export const MAX_RELATIONS = 25;

export type MemberKeys = Pick<MemberType, 'mId' | 'mRefId'>;

/**
 * These mTypes are members that are connected to another mType via mId.
 */
export const connectedMTypes = [
  MemberTypeEnum.Asset,
  MemberTypeEnum.Block,
  MemberTypeEnum.Order,
  MemberTypeEnum.OrderForm,
];

export const getMiniKeys = (member: MemberType, swapKeys: boolean) => {
  const idKey: keyof MemberType = swapKeys ? 'mRefId' : 'mId';
  const connectedKey: keyof MemberType = swapKeys ? 'mId' : 'mRefId';
  return { id: member[idKey]!, connectedId: member[connectedKey]!, type: member.mType! };
};

export const getKeys = (miniMember: MiniMember, swapKeys: boolean) => {
  const idKey: keyof MiniMember = swapKeys ? 'connectedId' : 'id';
  const connectedKey: keyof MiniMember = swapKeys ? 'id' : 'connectedId';
  return { mId: miniMember[idKey]!, mRefId: miniMember[connectedKey]! };
};

export const toMiniMember = (member: MemberType): MiniMember => {
  return getMiniKeys(member, connectedMTypes.includes(member.mType!));
};

export const getKeysFromMiniMember = (miniMember: MiniMember): MemberKeys => {
  return getKeys(miniMember, connectedMTypes.includes(miniMember.type));
};

export const isMiniMember = (value: unknown): value is MiniMember => {
  if (!value) return false;
  if (typeof value !== 'object') return false;
  const maybeMiniMember = value as Partial<MiniMember>;
  return typeof maybeMiniMember.id === 'string' && typeof maybeMiniMember.type === 'string';
};

export const isMiniMemberArray = (value: unknown): value is MiniMember[] => {
  if (!Array.isArray(value)) return false;
  return value.every(isMiniMember);
};

export const getInputFromValue = (value: FieldValue): MemberKeys[] | undefined => {
  if (isMiniMemberArray(value)) {
    return value.map(getKeysFromMiniMember);
  }
};

export const isSearchParameters = (val: unknown): val is SearchParameters => {
  if (!isObject(val)) return false;
  const maybe = val as Partial<SearchParameters>;
  return maybe.toolbarState !== undefined;
};
