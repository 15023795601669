import Divider from 'components/divider';
import { MemberType } from 'types/graphqlTypes';

import { useSelectedLeftMenu } from '../../../../../atomsTs';

import ActiveUserList from './ActiveUsers';
import DisabledUserList from './disabledUsers';
import Header from './header';

import { RootWrapper } from './styled';

interface UserProps {
  groupPolicies: MemberType[];
}

const Users = ({ groupPolicies }: UserProps) => {
  const [selectedLeftMenu] = useSelectedLeftMenu();
  const viewHelper = () => {
    switch (selectedLeftMenu) {
      case 'active-users':
        return <ActiveUserList groupPolicies={groupPolicies} />;
      case 'disabled-users':
        return <DisabledUserList />;
      default:
        return <div />;
    }
  };

  return (
    <RootWrapper>
      <Header selectedLeftMenu={selectedLeftMenu} />
      <Divider />
      {viewHelper()}
    </RootWrapper>
  );
};

export default Users;
