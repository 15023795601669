import React, { useMemo, useState } from 'react';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import { useGetOrderForms } from 'api/order_forms/useGetOrderForms';
import AddMemberDropdown from 'components/addMember';
import MemberLabel from 'components/addMember/MemberLabel';
import Dialog from 'components/dialogs/DialogBuilder';
import DraggableDialog from 'components/dialogs/Draggable';
import LoadingIndicator from 'components/loadingIndicator';
import { MdfEditor } from 'components/mdfEditor/MdfEditor';
import { FieldHeader } from 'components/mdfEditor/styled';
import { Color } from 'features/reusableStyled';
import useMetadata from 'hooks/useMetadata';
import { HStack } from 'layouts/box/Box';
import { useOrderFormDialog, useOrderFormOpenDialog } from 'store';
import type { Mdf } from 'types/graphqlTypes';
import { AssignedMember } from 'types/members';

import useCreateOrder from './api/useCreateOrder';

import { ContentWrapper, FormWrapper, SelectAssigneeWrapper } from './styled';

function OrderFormDialog() {
  const [dimension, setDimension] = useState({
    width: `${window.innerWidth < 300 ? window.innerWidth : '350'}`,
    height: `${window.innerHeight < 600 ? window.innerHeight : '600'}`,
  });

  const [open, setOpen] = useOrderFormOpenDialog();
  const [state] = useOrderFormDialog();
  const { mdfsSeparated } = useGetMdfs({ all: true });

  const mdf: Mdf | undefined = mdfsSeparated.custom.find((md) => md.id === state.mdfId);
  const { metadata, errorMap, errorTooltip, updateFieldValues } = useMetadata(
    mdf,
    undefined,
    'order_form',
    undefined,
    open,
  );

  const [assignee, setAssignee] = useState<AssignedMember | null>(null);
  const [isFindingAssignee, setIsFindingAssignee] = useState(false);

  const [isCreating, setIsCreating] = useState(false);
  const [createOrder] = useCreateOrder();
  const { orderForms, loading } = useGetOrderForms();

  const orderForm = useMemo(() => {
    return orderForms.find((o) => o.mRefId === state.mFormId);
  }, [orderForms, state.mFormId]);

  const onCloseDialog = () => {
    setOpen(false);
    if (state.onCancel) state.onCancel();
  };

  const onConfirm = async () => {
    setIsCreating(true);
    if (!isCreating && mdf) {
      const result = await createOrder(
        state.resourceId,
        state.mFormId,
        state.resourceType,
        metadata,
        assignee?.mId,
      ).finally(() => setIsCreating(false));
      if (state.onConfirm && result) state.onConfirm(result);
    }
    setAssignee(null);
    setOpen(false);
  };

  const onClose = () => {
    setIsFindingAssignee(false);
  };

  const onRemoveClick = (ev: React.MouseEvent<Element, MouseEvent>) => {
    ev.stopPropagation();
    setAssignee(null);
  };

  const onSelectAssignee = (newAssignee: AssignedMember[]) => {
    setAssignee(newAssignee[0]);
  };

  const hasResources = orderForm !== undefined && mdf !== undefined;

  return (
    <DraggableDialog
      open={open}
      onClose={onCloseDialog}
      initialPosition={{
        x: 400,
        y: 100,
      }}
      minWidth={300}
      minHeight={300}
      dimension={dimension}
      setDimension={setDimension}
    >
      <Dialog.Header className="dragHandler">
        <HStack gap="4px">
          {orderForm?.mColor && <Color $size={16} $color={orderForm.mColor} />}
          {orderForm?.mTitle
            ? `${orderForm?.mTitle} > ${orderForm?.mDescription}`
            : orderForm?.mDescription}
        </HStack>
      </Dialog.Header>
      <Dialog.Body bodyHeight="calc(100% - 84px)" overflow="auto">
        <ContentWrapper>
          {loading ? (
            <div style={{ width: '350px', height: '30vh' }}>
              <LoadingIndicator />
            </div>
          ) : hasResources ? (
            <FormWrapper>
              <MdfEditor
                view="order_form"
                fields={mdf.fields}
                defaultLayoutSettings={mdf.views.default}
                layoutSettings={mdf.views.order_form}
                metadata={metadata}
                permissions={mdf.permissions}
                updateFieldValue={updateFieldValues}
                errorMap={errorMap}
              />
              <SelectAssigneeWrapper>
                <FieldHeader>Assignee</FieldHeader>
                {isFindingAssignee ? (
                  <AddMemberDropdown
                    variant="all"
                    singleChoice
                    autoFocus
                    disableClearable={true}
                    selectedMembers={assignee ? [assignee] : []}
                    setSelectedMembers={onSelectAssignee}
                    placeholderText="Set assignee"
                    noOptionsText="No matches found"
                    onClose={onClose}
                    injectedMembers={undefined}
                  />
                ) : (
                  <MemberLabel
                    variant="form"
                    memberId={assignee?.mId}
                    onClick={() => setIsFindingAssignee(true)}
                    onRemoveClick={onRemoveClick}
                  />
                )}
              </SelectAssigneeWrapper>
            </FormWrapper>
          ) : (
            mdf && (
              <FormWrapper>
                <MdfEditor
                  view="order_form"
                  fields={mdf.fields}
                  defaultLayoutSettings={mdf.views.default}
                  layoutSettings={mdf.views.order_form}
                  metadata={metadata}
                  permissions={mdf.permissions}
                  updateFieldValue={updateFieldValues}
                  errorMap={errorMap}
                />
                <SelectAssigneeWrapper>
                  <FieldHeader>Assignee</FieldHeader>
                  {isFindingAssignee ? (
                    <AddMemberDropdown
                      variant="all"
                      singleChoice
                      autoFocus
                      disableClearable={true}
                      selectedMembers={assignee ? [assignee] : []}
                      setSelectedMembers={onSelectAssignee}
                      placeholderText="Set assignee"
                      noOptionsText="No matches found"
                      onClose={onClose}
                      injectedMembers={undefined}
                    />
                  ) : (
                    <MemberLabel
                      variant="form"
                      memberId={assignee?.mId}
                      onClick={() => setIsFindingAssignee(true)}
                      onRemoveClick={onRemoveClick}
                    />
                  )}
                </SelectAssigneeWrapper>
              </FormWrapper>
            )
          )}
        </ContentWrapper>
      </Dialog.Body>

      <Dialog.Footer>
        <Dialog.CancelButton onCancel={onCloseDialog} />
        <Dialog.ConfirmButton
          title={errorTooltip ?? 'Submit'}
          label={'Submit'}
          onConfirm={onConfirm}
          disabled={!!errorTooltip || loading || isCreating || !orderForm}
        />
      </Dialog.Footer>
    </DraggableDialog>
  );
}

export default OrderFormDialog;
