import { useCallback, useEffect, useState } from 'react';

const browserPrefixes = ['moz', 'ms', 'o', 'webkit'];

const getHiddenPropertyName = (prefix) => (prefix ? `${prefix}Hidden` : 'hidden');

const getBrowserPrefix = () =>
  browserPrefixes.find((browserPrefix) => getHiddenPropertyName(browserPrefix) in document) || null;

const useVisibilityState = () => {
  const [isVisible, setIsVisible] = useState(true);

  const getVisibilityEvent = (prefix) =>
    prefix ? `${prefix}visibilitychange` : 'visibilitychange';

  const browserPrefix = getBrowserPrefix();
  const hiddenPropertyName = getHiddenPropertyName(browserPrefix);
  const visibilityEventName = getVisibilityEvent(browserPrefix);

  const onVisible = useCallback(() => {
    if (isVisible) return;
    setIsVisible(true);
  }, [isVisible]);

  const onHidden = useCallback(() => {
    if (!isVisible) return;
    setIsVisible(false);
  }, [isVisible]);

  const handleVisibilityChange = useCallback(
    (forcedFlag) => {
      if (typeof forcedFlag === 'boolean') {
        if (forcedFlag) return onVisible();
        return onHidden();
      }

      if (document[hiddenPropertyName]) {
        return onHidden();
      }

      return onVisible();
    },
    [hiddenPropertyName, onHidden, onVisible],
  );

  useEffect(() => {
    document.addEventListener(visibilityEventName, handleVisibilityChange, false);
    document.addEventListener('focus', () => handleVisibilityChange(true), false);
    document.addEventListener('blur', () => handleVisibilityChange(false), false);
    window.addEventListener('focus', () => handleVisibilityChange(true), false);
    window.addEventListener('blur', () => handleVisibilityChange(false), false);
    return () => {
      document.removeEventListener(visibilityEventName, handleVisibilityChange, false);
      document.removeEventListener('focus', () => handleVisibilityChange(true), false);
      document.removeEventListener('blur', () => handleVisibilityChange(false), false);
      window.removeEventListener('focus', () => handleVisibilityChange(true), false);
      window.removeEventListener('blur', () => handleVisibilityChange(false), false);
    };
  }, [handleVisibilityChange, visibilityEventName]);

  return isVisible;
};

export default useVisibilityState;
