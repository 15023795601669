import gql from 'graphql-tag';

export default gql`
  subscription notifyConversation($mIdSubscribed: String!) {
    notifyConversationSubscription(mIdSubscribed: $mIdSubscribed) {
      mId
      mTitle
      mRefId
      mType
      convoType
      mUpdatedAt
      mUpdatedById
      mCreatedAt   
      mCreatedById
      message
      mAssignedMembers {
        mId
        mType
      }
    }
  }
`;
