/* eslint-disable sort-imports */
import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { TableCell } from '@material-ui/core';

import { ReactComponent as DeleteIcon } from 'assets/icons/systemicons/delete.svg';
import transientOptions from 'theme/helpers';

import { buttonStatus, ButtonStatusType } from './utils';

export const RootWrapper = styled('div')`
  width: 100%;
`;

export const Header = styled('p')`
  ${({ theme }) => theme.typography.dina.h6};
  margin: 0;
  height: 56px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  background-color: ${({ theme }) => theme.palette.dina.backgroundResting};
`;

export const CellStyle = ({ theme }: { theme: Theme }) => css`
  padding: 8px;
  height: 40px;
  border-bottom: 1px solid ${theme.palette.dina.dividerLight};
  ${theme.typography.dina.listItemLabel};
`;

export const HeaderCell = styled(TableCell)`
  ${CellStyle};
  ${({ theme }) => theme.typography.dina.overline};
  background-color: ${({ theme }) => theme.palette.dina.backgroundResting};
`;

export const RowCell = styled(TableCell)`
  ${CellStyle};
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  position: relative;
`;

export const TitleCell = styled(TableCell)`
  ${CellStyle};
  width: 30%;
`;

interface StatusCellProps {
  $status?: string;
}
export const StatusCell = styled(TableCell, transientOptions)<StatusCellProps>`
  ${CellStyle};
  ${({ $status, theme }) =>
    $status !== 'connected' &&
    css`
      color: ${theme.palette.dina.errorText};
    `};
  pointer-events: none;
`;

interface DeleteButtonProps {
  $status: ButtonStatusType;
  $disabled: boolean;
}
export const DeleteButton = styled(DeleteIcon, transientOptions)<DeleteButtonProps>`
  position: absolute;
  z-index: 1;
  left: calc(50% - 12px);
  top: calc(50% - 12px);
  cursor: pointer;
  :hover {
    path {
      fill-opacity: 1;
      stroke-opacity: 1;
    }
  }
  ${({ $status, theme }) =>
    $status === buttonStatus.CONFIRM &&
    css`
      path {
        stroke: ${theme.palette.dina.errorText};
        fill: ${theme.palette.dina.errorText};
      }
      :hover {
        path {
          fill-opacity: 1;
          stroke-opacity: 1;
        }
      }
    `};
  ${({ $disabled }) =>
    $disabled &&
    css`
      user-select: none;
      pointer-events: none;
      path {
        fill-opacity: 0.3;
        stroke-opacity: 0.3;
      }
    `};
`;

interface InputProps {
  disabled: boolean;
  $error: boolean;
}

export const Input = styled('input', transientOptions)<InputProps>`
  ${({ theme }) => theme.typography.dina.listItemLabel};
  outline: none;
  background-color: transparent;
  border: 1px solid transparent;
  height: 32px;
  border-radius: 2px;
  width: 100%;
  :hover {
    border: 1px solid ${({ theme }) => theme.palette.dina.inputBorderResting};
    background-color: ${({ theme }) => theme.palette.dina.whiteHoverOverlay};
  }
  :focus {
    border: 1px solid ${({ theme }) => theme.palette.dina.onFocus};
    background-color: ${({ theme }) => theme.palette.dina.blackHoverOverlay};
  }
  ${({ $error, theme }) =>
    $error &&
    css`
      border: 1px solid ${theme.palette.dina.errorText};
      :focus {
        border: 1px solid ${theme.palette.dina.errorText};
      }
    `};
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `};
`;
