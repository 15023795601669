/**
 * Hook for activating or disabling sync provider to a story
 */

import { useMutation } from '@apollo/client';
import { getMemberQuery } from 'operations/queryVariables';
import activateStorySync from 'operations/mutations/activateStorySync';
import disableStorySync from 'operations/mutations/disableStorySync';
import GET_STORY from 'operations/queries/getStory';
import useLogger from 'utils/useLogger';

const useSetStorySync = () => {
  const logger = useLogger('use set story sync');
  const [activateMutation] = useMutation(activateStorySync);
  const [disableMutation] = useMutation(disableStorySync);

  const updateStory = (proxy, storyId, syncActive) => {
    try {
      let hasQueryInCache = true;
      let story;
      try {
        story = proxy.readQuery({
          query: GET_STORY,
          variables: getMemberQuery(storyId),
        });
      } catch (err) {
        // eslint-disable-next-line no-console
        hasQueryInCache = false;
      }

      if (hasQueryInCache) {
        const newStory = { ...story.getStory, mSyncActive: syncActive };
        proxy.writeQuery({
          query: GET_STORY,
          variables: getMemberQuery(storyId),
          data: {
            getStory: newStory,
          },
        });
      }
    } catch (err) {
      logger.log(err);
    }
  };
  const setStorySync = async (mId, providers, deactivated = false) => {
    await activateMutation({
      variables: {
        input: {
          mId,
          activeProviders: providers,
          deactivated,
        },
      },
      update: (proxy) => updateStory(proxy, mId, providers),
    });
  };

  return [setStorySync];
};

export default useSetStorySync;
