/* eslint-disable no-param-reassign */

const getDomRange = (selection: Selection) => {
  if (selection && selection.rangeCount > 0) {
    return selection.getRangeAt(0);
  }
};

const useMovePortalToViewPort = (el: HTMLDivElement) => {
  if (!el) return;

  const domSelection = window.getSelection();
  if (domSelection) {
    const domRange = getDomRange(domSelection);

    if (!domRange) return;

    const rect = domRange.getBoundingClientRect();
    el.style.opacity = '1';
    el.style.top = `${rect.top + window.scrollY - el.offsetHeight}px`;
    el.style.left = `${Math.abs(
      rect.left + window.scrollX - el.offsetWidth / 2 + rect.width / 2,
    )}px`;
  }
};

export default useMovePortalToViewPort;
