import { SyntheticEvent } from 'react';

type EventType = SyntheticEvent | KeyboardEvent | MouseEvent;

const preventDefaultAndPropagation = (event: EventType) => {
  event.preventDefault();
  event.stopPropagation();
};

export default preventDefaultAndPropagation;
