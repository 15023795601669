import styled from '@emotion/styled';

export const Content = styled('div')`
  ${({ theme }) => theme.typography.dina.caption};
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 8px;
`;

export const Wrapper = styled('div')`
  width: 360px;
  display: flex;
  flex-direction: column;
`;

export const CheckboxWrapper = styled('div')`
  padding-left: 6px;
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
`;

export const Bottom = styled('div')`
  border-top: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  height: 44px;
  padding-inline: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Image = styled('div')`
  height: 200px;
  border-radius: 8px;
  background-image: ${(props) => `url(${props.imageUrl})`};
  background-size: cover;
  box-shadow: 0px 0px 1px ${({ theme }) => theme.palette.dina.dividerLight};
`;

export const TextWrapper = styled('div')`
  height: ${({ height }) => (height ? `${height}px` : 'auto')};
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px 4px;
`;
