import React from 'react';
import { Popover as MaterialPopover, Fade } from '@material-ui/core';
import useStyles from './popover-styles';

const Popover = ({ anchorEl, onClose, children, ...rest }) => {
  const classes = useStyles();
  
  return (
    <MaterialPopover
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorEl={anchorEl}
      PaperProps={{
        elevation: 2, style: {
          marginTop: '1rem', // Adjust the top margin as needed
          maxHeight: 'calc(100vh - 8rem)', // Limit the maximum height 
        }, }}
      classes={{
        root: classes.dialogRoot,
        paper: classes.dialogPaper,
        paperScrollPaper: classes.paperScrollPaper,
      }}
      TransitionComponent={Fade}
      {...rest}
    >
      {children}
    </MaterialPopover>
  );
};

export default Popover;
