import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  article: {
    ...theme.typography.dina.h5,
    lineHeight: '28px',
    color: theme.palette.dina.mediumEmphasis,
    marginBottom: '12px',
  },
  contentContainer: {
    cursor: 'default',
    display: 'flex',
    transition: '0.3s ease',
    boxSizing: 'border-box',
    border: `1px solid ${theme.palette.dina.blackHoverOverlay}`,
    borderRadius: '4px',
    background: theme.palette.dina.blackHoverOverlay,
    '&:hover': {
      background: theme.palette.dina.backgroundHover,
      border: `1px solid ${theme.palette.dina.borderHover}`,
    },
  },
  iconWrapper: {
    width: '40px',
    backgroundColor: theme.palette.dina.blackHoverOverlay,
    borderRadius: '4px 0 0 4px',
    margin: 0,
  },
  icon: {
    width: '22px',
    height: '22px',
    margin: '8px',
  },
  contentWrapper: {
    width: '100%',
    padding: '16px',
    overflow: 'hidden',
  },
  text: {
    ...theme.typography.dina.body2,
    marginBottom: '8px',
  },
  editButtonRoot: {
    backgroundColor: theme.palette.dina.buttonBackgroundHighEmphasis,
    height: '40px',
    width: '160px',
    borderRadius: '4px',
    marginBottom: '24px',
    '&:hover': {
      backgroundColor: theme.palette.dina.buttonBackgroundHighEmphasis,
    },
    '&:focus': {
      boxShadow: `0 0 0 2px ${theme.palette.dina.onFocus}`,
    },
  },
  editButtonText: {
    ...theme.typography.dina.button,
    textTransform: 'none',
  },
  addButtons: {
    width: '280px',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '4px',
  },
  addButtonRoot: {
    backgroundColor: theme.palette.dina.timelineVideoClip,
    '&:hover': {
      backgroundColor: theme.palette.dina.timelineVideoClip,
    },
    height: '40px',
    width: '136px',
    borderRadius: '4px',
    '&:focus': {
      boxShadow: `0 0 0 2px ${theme.palette.dina.onFocus}`,
    },
  },
  addButtonText: {
    ...theme.typography.dina.button,
    textTransform: 'none',
    color: theme.palette.dina.blackHighEmphasis,
    '&:hover': {
      color: theme.palette.dina.blackHighEmphasis,
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  addIcon: {
    marginRight: '4px',
  },
  editBtnWrapper: {
    marginBottom: 24,
  },
}));

export default useStyles;
