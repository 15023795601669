import { Node } from 'slate';

import elementTypes from 'components/editor/constants/types/elementTypes';
import { CustomElement } from 'types';

const { SECTION_DIVIDER } = elementTypes;

const isSection = (element: Node) => (element as CustomElement)?.type === SECTION_DIVIDER;

export default isSection;
