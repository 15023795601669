import gql from 'graphql-tag';

import useApolloSubscription from 'hooks/useApolloSubscriptionV2';

import useUpdateCommentsCache from './useUpdateCommentsCache';

const NOTIFY_COMMENT = gql`
  subscription comments($ids: String) {
    updateContentChat(contentIds: $ids) {
      __typename
      mId
      mRefId
      mType
      mTitle
      mContent
      mCreatedAt
      mUpdatedAt
      mCreatedById
      mUpdatedById
      convoType
      crudAction
      contentIds
      mResourceId
      mResourceType
      mStoryId
      mSecId
      mSecRefId
      mTertId
      mTertRefId
      mState
    }
  }
`;

const useCommentSubscription = (contentIds: string[]) => {
  const [updateCommentCache] = useUpdateCommentsCache();

  useApolloSubscription(NOTIFY_COMMENT, {
    variables: { ids: JSON.stringify(contentIds) },
    onSubscriptionData: updateCommentCache,
    skip: !contentIds?.length,
    source: 'useCommentSubscription',
  });
};

export default useCommentSubscription;
