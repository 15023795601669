import { ReactEditor } from 'slate-react';
import getMosAsset from 'components/editor/utils/getMosAsset';
import { elementTypes } from 'components/editor/constants/types';
import notifyChange from 'components/editor/utils/notifyChange';
import insertMosObj from 'components/editor/components/mos/utils/insertMosObj';
import { mediaTypes } from 'utils/rundownItemTypes';

const { MOS } = elementTypes;

const onMosDrop = ({ payload, iconUrl }, editor, element, update) => {
  const asset = getMosAsset(payload, mediaTypes.MOS);
  if (asset) {
    const { description, title, provider, id } = asset;
    insertMosObj(
      editor,
      MOS,
      {
        description,
        title,
        mosobj: payload,
        provider,
        id,
        iconUrl,
      },
      false,
    );

    notifyChange(editor, update);
    ReactEditor.focus(editor);
  }
};

export default onMosDrop;
