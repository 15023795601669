const makeCompactGiven = (columns, rows) =>
  rows.map((row) =>
    columns.map((column) => {
      const searchedObj = row.find((item) => column.id === item.key);
      if (searchedObj) return searchedObj;
      return { key: column.id, value: '' };
    }),
  );

export default makeCompactGiven;
