import { memo, useCallback } from 'react';

import {
  MultiSelect,
  MultiSelectInput,
  MultiSelectList,
  type Option,
} from 'components/combobox/MultiSelect';
import { topLevelMediaTopics } from 'utils/constants/mediaTopics';

import { FilterCommonTypes } from './types';

interface MediaTopicsFilterProps extends FilterCommonTypes {
  mediatopics?: string[];
  propertyName?: string;
  placeholder?: string;
}

// Get value string array from option array
const getSelectedValues = (selected: Option[]) => selected.map((option) => option.value);

// Convert media topic to combobox option
const topicToOption = (topic: (typeof topLevelMediaTopics)[0]) => ({
  value: topic.id,
  label: topic.name,
});

// Get selected options from string array
const getSelectedOptions = (topicIds: string[]) => {
  const selected = [];

  for (const id of topicIds) {
    const topic = topLevelMediaTopics.find((t) => t.id === id);
    if (topic) selected.push(topicToOption(topic));
  }

  return selected;
};

const OPTIONS = topLevelMediaTopics.map((topic) => topicToOption(topic));

function MediaTopicsFilter({
  mediatopics = [],
  onChange,
  propertyName = 'mediatopics',
  placeholder = 'Showing all media topics',
}: Readonly<MediaTopicsFilterProps>) {
  const handleChange = useCallback(
    (val: Option[]) => {
      onChange({
        name: propertyName,
        value: getSelectedValues(val),
      });
    },
    [onChange],
  );

  const placeholderText =
    mediatopics.length > 0
      ? `${mediatopics.length} media topic${mediatopics.length > 1 ? 's' : ''} selected`
      : placeholder;

  return (
    <MultiSelect value={getSelectedOptions(mediatopics)} onChange={handleChange} options={OPTIONS}>
      <MultiSelectInput placeholder={placeholderText} />
      <MultiSelectList />
    </MultiSelect>
  );
}

export default memo(MediaTopicsFilter);
