import gql from 'graphql-tag';

export default gql`
  query GetMember($input: GetMemberInput) {
    getMember(input: $input) {
      mId
      mRefId
      mTitle
      mDescription
      mAvatarKey
      mOrder
      mCreatedAt
      mUpdatedAt
      mSyncActive
      mMetaData {
        key
        value
      }
      mSyncProviders {
        provider
        mMetaData {
          key
          value
        }
      }
      mType
      mOrder
    }
  }
`;
