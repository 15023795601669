import { useMemo } from 'react';
import { Table, TableBody, TableHead, TableRow } from '@material-ui/core';

import { useStudios } from 'store';

import Row from './Row';
import { columns, createRows } from './utils';

import { Header, HeaderCell, RootWrapper } from './styled';

function Studios() {
  const [studios] = useStudios();

  const rows = useMemo(() => createRows(studios), [studios]);

  return (
    <RootWrapper>
      <Header>Studios</Header>
      <Table aria-label="studio-table">
        <TableHead key="studio-table-header">
          <TableRow key="header">
            {columns.map((column) => (
              <HeaderCell key={column.key}>{column.name}</HeaderCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody key="studio-table-body">
          {rows.map((studio) => (
            <Row key={studio.mRefId} studio={studio} />
          ))}
        </TableBody>
      </Table>
    </RootWrapper>
  );
}

export default Studios;
