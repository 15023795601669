import { useQuery } from '@apollo/client';
import memberTypes from 'operations/memberTypes';

import SEARCH_STORIES from 'operations/queries/searchApi';

const useGetStories = (mIds) => {
  const filter = {
    mTypes: [memberTypes.STORY, memberTypes.PITCH],
    mIds: [...mIds],
  };

  const variables = {
    filter,
    limit: 25,
  };

  const { data, loading } = useQuery(SEARCH_STORIES, {
    variables,
    fetchPolicy: 'cache-and-network',
  });

  return [data, loading];
};

export default useGetStories;
