import gql from 'graphql-tag';

export default gql`
  mutation CreateDepartment($input: CreateMemberInput) {
    createMember(input: $input) {
      mId
      mRefId
      mTitle
      mDescription
      mType
    }
  }
`;
