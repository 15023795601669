import { atom, useAtom } from 'jotai';
import { createScope, molecule, useMolecule } from 'jotai-molecules';

export const SpaceScope = createScope(undefined);

const spaceMolecule = molecule((_getMol, getScope) => {
  getScope(SpaceScope);

  const selectedStoryIdAtom = atom('');
  const useSelectedStoryId = () => useAtom(selectedStoryIdAtom);

  return {
    useSelectedStoryId,
  };
});
export const useSpaceMolecule = () => useMolecule(spaceMolecule);

/* The id of the selected space view (tab) */
const activeSpaceViewAtom = atom<string | undefined>(undefined);

const activeSpaceView = atom<string | undefined, [string | undefined], void>(
  (get) => get(activeSpaceViewAtom),
  (_get, set, nextValue) => {
    set(activeSpaceViewAtom, nextValue);
  },
);
export const useActiveSpaceView = () => useAtom(activeSpaceView);
