/**
 *
 * @param {*} string
 * @returns Boolean
 */
const makeValidUrl = (string) => {
  if (!string) return '';

  try {
    const newUrl = new URL(string);
    return newUrl.href;
  } catch (err) {
    return `https://${string}/`;
  }
};

export default makeValidUrl;
