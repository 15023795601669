import React, { useMemo, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Transforms } from 'slate';
import { useEditorContext } from 'components/editor/EditorContext';
import useGetAutomationTemplates from 'hooks/useGetAutomationTemplates';
import { useSlate, useSelected, useReadOnly, useFocused } from 'slate-react';
import { elementTypes } from 'components/editor/constants/types';
import useDragEnd from 'components/editor/hooks/useDragEnd';
import variants from 'components/editor/constants/types/editorVariants';
import getTypeValues from 'components/editor/utils/getTypeValues';
import { mediaTypes } from 'utils/rundownItemTypes';
import dndTypes from 'utils/dndTypes';
import { NativeTypes } from 'react-dnd-html5-backend';
import {
  onAssetDrop,
  onGraphicsDrop,
  onPrimaryDrop,
  onSecondaryDrop,
  useDropZone,
  onMosDrop,
} from './utils';

const onTextDrop = ({ text }, editor) => text && Transforms.insertText(editor, text);

const DropZone = styled('div')`
  position: relative;
  background: ${({ showHighlight, theme }) =>
    showHighlight && theme.palette.dina.blackHoverOverlay};
  border-radius: 4px;
  ::before {
    content: '';
    position: absolute;
    background-color: ${({ hovered, theme }) =>
      hovered ? theme.palette.dina.onFocus : 'transparent'};
    width: calc(100% - 16px);
    left: 8px;
    bottom: 0;
    height: 3px;
  }
`;

const StyledParagraph = styled('p')`
  z-index: 100;
  margin: 0px;
  padding: 8px;
`;

const primaryTypeValues = getTypeValues(elementTypes.primaryTypes);
const secondaryTypeValues = getTypeValues(elementTypes.secondaryTypes);
const { GRAPHICS } = mediaTypes;
const { CLIP } = mediaTypes;
const { MOS } = mediaTypes;
const { ASSET, EDITOR_BLOCK } = dndTypes;
const { LINEAR } = variants;

const Paragraph = ({ attributes, children, element }) => {
  const editor = useSlate();
  const readOnly = useReadOnly();
  const isFocused = useFocused();
  const isSelected = useSelected();
  const [onDragEnd] = useDragEnd();
  const { templates } = useGetAutomationTemplates();
  const { variant, update } = useEditorContext();

  const showHighlight = !readOnly && isFocused && isSelected && variant !== variants.MESSAGE;

  const assetTypes = variant === LINEAR ? [] : [CLIP, ASSET];

  const dropZoneAccept = useMemo(
    () => [
      ...primaryTypeValues,
      ...secondaryTypeValues,
      GRAPHICS,
      MOS,
      NativeTypes.TEXT,
      ...assetTypes,
      EDITOR_BLOCK,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onDrop = useCallback((item) => {
    if (item.type === dndTypes.EDITOR_BLOCK) onDragEnd(item.payload, element);
    if (item.type === GRAPHICS) onGraphicsDrop(item, editor, element, update);
    if (item.type === MOS) onMosDrop(item, editor, element, update);
    else if (item.type === NativeTypes.TEXT) onTextDrop(item, editor);
    else if (assetTypes.includes(item.type)) onAssetDrop(item, editor, element, update);
    else if (secondaryTypeValues.includes(item.type)) onSecondaryDrop(item, editor, templates);
    else if (primaryTypeValues.includes(item.type)) onPrimaryDrop(item, editor, update, templates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [{ hovered }, dropRef] = useDropZone(dropZoneAccept, onDrop, element);

  return (
    <DropZone hovered={hovered} showHighlight={showHighlight} {...attributes}>
      <StyledParagraph ref={dropRef}>{children}</StyledParagraph>
    </DropZone>
  );
};

Paragraph.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

Paragraph.defaultProps = {
  attributes: {},
  children: null,
  element: {
    type: elementTypes.PARAGRAPH,
    data: { indentLevel: 0 },
    children: [],
  },
};

export default memo(Paragraph);
