import { atom, useAtom } from 'jotai';

const STORY_STORAGE_KEY = 'story';

type StoryStorageType = {
  isTimelineShown: boolean;
  editorFontSize: 'small' | 'medium' | 'large'; // Assuming a limited set of options
  isDescriptionShown: boolean;
};

const defaultStoryStorageValues: StoryStorageType = {
  isTimelineShown: false,
  editorFontSize: 'small',
  isDescriptionShown: true,
};

const storyStorageAtom = atom<StoryStorageType>(
  (localStorage.getItem(STORY_STORAGE_KEY)
    ? JSON.parse(localStorage.getItem(STORY_STORAGE_KEY) as string)
    : defaultStoryStorageValues) as StoryStorageType,
);

const storyToolbarAtom = atom(
  (get) => get(storyStorageAtom),
  (_get, set, nextValue: StoryStorageType) => {
    set(storyStorageAtom, nextValue);
    localStorage.setItem(STORY_STORAGE_KEY, JSON.stringify(nextValue));
  },
);

export const useStoryToolbar = () => useAtom(storyToolbarAtom);

const isTimelineShownAtom = atom(
  (get) => get(storyToolbarAtom)?.isTimelineShown,
  (get, set, nextValue: StoryStorageType['isTimelineShown']) => {
    set(storyToolbarAtom, {
      ...get(storyToolbarAtom),
      isTimelineShown: nextValue,
    });
  },
);

export const useIsTimelineShown = () => useAtom(isTimelineShownAtom);

const isDescriptionShownAtom = atom(
  (get) => get(storyToolbarAtom)?.isDescriptionShown,
  (get, set, nextValue: StoryStorageType['isDescriptionShown']) => {
    set(storyToolbarAtom, {
      ...get(storyToolbarAtom),
      isDescriptionShown: nextValue,
    });
  },
);
export const useIsDescriptionShown = () => useAtom(isDescriptionShownAtom);

const storyEditorFontSizeAtom = atom(
  (get) => get(storyToolbarAtom)?.editorFontSize,
  (get, set, nextValue: StoryStorageType['editorFontSize']) => {
    const currentState = get(storyToolbarAtom);

    const updatedState = {
      ...currentState,
      editorFontSize: nextValue,
    };
    set(storyToolbarAtom, updatedState);
  },
);

export const useStoryEditorFontSize = () => useAtom(storyEditorFontSizeAtom);
