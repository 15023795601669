import { createContext, useCallback, useMemo, useState } from 'react';
import type { Column, RenderRowProps, RowsChangeData, SortColumn } from 'react-data-grid';
import DataGrid from 'react-data-grid';
import { useDrop } from 'react-dnd';
import { keyBy } from 'lodash';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import useUpdateMetadata from 'api/useUpdateMetadata';
import LoadingIndicator from 'components/loadingIndicator';
import { useDatePicker } from 'components/mdfEditor/fields/date/DatePicker';
import useToast from 'components/toast/useToast';
import { useTreeChoiceDialog } from 'components/treeChoiceDialog/TreeChoiceDialog';
import { useEditFieldValueDialog } from 'features/mdf/mdf-utils';
import useDinaNavigate from 'hooks/useDinaNavigate';
import useGetInstanceDetails from 'hooks/useGetInstanceDetails';
import { Box } from 'layouts/box/Box';
import { useAllMembersKeyed } from 'store';
import { useSetPreview } from 'store/preview';
import { isContentType } from 'store/tabs';
import { Metadata, ResourceType } from 'types/forms/forms';
import { MemberType, MemberTypeEnum } from 'types/graphqlTypes';
import { AssignedMember } from 'types/members';

import 'react-data-grid/lib/styles.css';

import { DraggableRowRenderer } from '../orderForm/components/DraggableRowRenderer';
import { MemberColumn, MemberRow, OrderRow } from '../orderForm/types';

import { useDataGridErrorSuppressor } from './hooks/useDataGridErrorSuppressor';
import { getComparator } from './utils/compare';
import { useGridMolecule } from './store';
import {
  acceptedMTypes,
  extractColumnsFromMemberType,
  extractRowsFromMemberType,
  getFieldIdFromKey,
  getFieldMap,
} from './utils';

import { AcceptingDropEmptyRows, GridWrapper } from './styled';

interface Props {
  loading: boolean;
  federatedSearchString?: string;
  onContextMenu: (event: React.MouseEvent, row: MemberType) => void;
}

export interface MapValue {
  value: boolean;
  column: Column<OrderRow, unknown>;
  immutable: boolean;
}
export type BooleanMap = Record<string, MapValue>;

export interface Filter extends Omit<OrderRow, 'id'> {
  enabled: boolean;
  status: string;
  ownerId: string;
  assigneeId: string;
}

// Context is needed to read filter values otherwise columns are
// re-created when filters are changed and filter loses focus
export const FilterContext = createContext<Filter | undefined>(undefined);

const rowKeyGetter = (row: MemberRow) => row.id;

const EmptyRowsRenderer = ({
  loading,
  onAddItem,
}: {
  loading: boolean;
  onAddItem: (item: MemberType, idx: number) => void;
}) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ['STORY_DRAG'],
    drop(p: MemberType) {
      onAddItem(p, 0);
    },
    canDrop(p: MemberType) {
      return (p.mType && acceptedMTypes.includes(p.mType)) ?? false;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const label = isOver
    ? 'Drop to add'
    : 'Drag stories, pitches, rundowns or instances here to get started';

  return (
    <AcceptingDropEmptyRows isOver={isOver && canDrop} ref={(ref) => drop(ref)}>
      {loading ? 'Loading ...' : label}
    </AcceptingDropEmptyRows>
  );
};

/**
 * Grid widget component
 * - Renders a grid of members with drag and drop functionality
 * @param {boolean} loading - Whether the grid is loading
 * */
export default function Grid({ loading, federatedSearchString, onContextMenu }: Readonly<Props>) {
  useDataGridErrorSuppressor();

  const setPreview = useSetPreview();
  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
  const [isOverRow, setIsOverRow] = useState(false);

  const { errorToast } = useToast();
  const { navigateTo } = useDinaNavigate();
  const [, setPicker] = useDatePicker();
  const [, showEditFieldDialog] = useEditFieldValueDialog();
  const [, openTreeChoiceDialog] = useTreeChoiceDialog();

  // Store
  const [allMembersKeyed] = useAllMembersKeyed();
  const { useGridBoard, useGridMembers, useUpdateBoardOrder, useAddMember, useRemoveMember } =
    useGridMolecule();
  const addMember = useAddMember();
  const removeMember = useRemoveMember();
  const [gridBoard] = useGridBoard();
  const updateBoardOrder = useUpdateBoardOrder();
  const [gridMembers, setGridMembers] = useGridMembers();

  // API
  const { getInstanceURLParams } = useGetInstanceDetails();
  const { mdfsByMType } = useGetMdfs({ all: true });
  const updateMetadata = useUpdateMetadata();

  const updateItemInGrid = useCallback(
    (id: string, updatedMetadata?: string) => {
      if (updatedMetadata) {
        setGridMembers((prev) => {
          if (prev[id]) {
            const updatedMember = { ...prev[id] };
            updatedMember.metadata = updatedMetadata;
            return {
              ...prev,
              [id]: updatedMember,
            };
          } else {
            return prev;
          }
        });
      }
    },
    [setGridMembers],
  );

  const items = useMemo(
    () => gridBoard?.mOrder?.map((id) => gridMembers[id]).filter((item) => item) ?? [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(gridBoard), JSON.stringify(gridMembers)],
  );

  const showPreview = useCallback(
    (id: string) => {
      const previewMember = items.find((m) => m.mId === id);
      if (previewMember) {
        setPreview(previewMember);
      }
    },
    [setPreview, items],
  );

  // ─── Remove Item From Grid ───────────────────────────────────────────
  const onRemoveItem = useCallback(
    (itemId: string) => {
      removeMember({ memberId: itemId });
    },
    [removeMember],
  );

  // ─── Add Item To Grid ────────────────────────────────────────────────
  const onAddItem = useCallback(
    (item: MemberType, idx: number) => {
      addMember({ memberId: item.mId as string, member: item, idx });
    },
    [addMember],
  );

  // ─── Open story, instance etc. in a new tab ──────────────────────────
  const goToResource = useCallback((id: string, type: ResourceType) => {
    if (type == 'instance') {
      getInstanceURLParams(id)
        .then((details) => {
          if (details) {
            if (details.path === 'story') {
              navigateTo(details.path, details.id, {
                tab: 'instances',
                entityId: id,
              });
            } else {
              navigateTo(details.path as 'rundown' | 'rundowntemplate', details.id);
            }
          }
        })
        .catch(errorToast);
    } else if (isContentType(type)) {
      navigateTo(type, id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fieldMap = useMemo(() => {
    return getFieldMap(mdfsByMType);
  }, [mdfsByMType]);

  const columns: MemberColumn[] = useMemo(() => {
    if (loading) return [];

    return extractColumnsFromMemberType(
      items,
      fieldMap,
      mdfsByMType,
      // fix when useAllMembersKeyed is typed
      allMembersKeyed as unknown as Record<string, AssignedMember>,
      goToResource,
      setPicker,
      showPreview,
      showEditFieldDialog,
      openTreeChoiceDialog,
      onRemoveItem,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, fieldMap, mdfsByMType, loading]);

  const rows = useMemo(() => {
    if (loading) return [];

    return extractRowsFromMemberType(items, mdfsByMType);
  }, [items, mdfsByMType, loading]);

  const columnNames = useMemo(() => {
    return keyBy(columns, (c) => c.key);
  }, [columns]);

  const rowsFreeTextFiltered = useMemo(() => {
    if (!federatedSearchString) return rows;
    return rows.filter((r) => {
      return r.__storyTitle.includes(federatedSearchString);
    });
  }, [rows, federatedSearchString]);

  const filteredRows = useMemo(() => {
    if (sortColumns.length === 0) return rowsFreeTextFiltered;
    return [...rowsFreeTextFiltered].sort((a, b) => {
      for (const sort of sortColumns) {
        const comparator = getComparator(sort.columnKey);
        const compResult = comparator(a, b);
        if (compResult !== 0) {
          return sort.direction === 'ASC' ? compResult : -compResult;
        }
      }
      return 0;
    });
  }, [rowsFreeTextFiltered, sortColumns]);

  // ─── Handles New Rows ─────────────────────────────────────────────────
  const onNewRows = (rws: MemberRow[], data: RowsChangeData<MemberRow, unknown>) => {
    const key = data.column.key;

    for (const index of data.indexes) {
      const item = rws[index];
      if (item) {
        const value = item[key];
        if (value === undefined || value === null) return;

        const sourceItem = items.find((itm) => {
          return itm.mRefId === item.id;
        });
        let metadata: Metadata = {};
        if (sourceItem?.metadata) {
          try {
            metadata = JSON.parse(sourceItem?.metadata) as Metadata;
          } catch (err: unknown) {
            errorToast(err);
          }
        }
        const partialUpdate: Metadata = { [getFieldIdFromKey(key)]: value };
        updateMetadata(item.id, item.mRefId, partialUpdate, metadata, item.__type as MemberTypeEnum)
          .then((result) => updateItemInGrid(item.id, result.data?.updateMetadata.metadata))
          .catch(errorToast);
      }
    }
  };

  // ─── Renders Each Row ────────────────────────────────────────────────
  const renderRow = useCallback(
    (key: React.Key, props: RenderRowProps<MemberRow>) => {
      // TODO: Check if this is recreated for each row

      function onRowReorder(fromIndex: number, toIndex: number) {
        const newOrder = [...(gridBoard.mOrder ?? [])];
        newOrder.splice(toIndex, 0, newOrder.splice(fromIndex, 1)[0]);
        updateBoardOrder({ mOrder: newOrder });
      }

      const contextMenuItem: MemberType = {
        mId: props.row.id,
        mType: props.row.__type as MemberTypeEnum,
        mRefId: props.row.mRefId,
        mTitle: props.row.__storyTitle,
      };

      return (
        <DraggableRowRenderer
          key={key}
          {...props}
          setIsOverRow={setIsOverRow}
          onRowReorder={onRowReorder}
          onAddItem={onAddItem}
          sortColumns={sortColumns}
          columnNames={columnNames}
          onContextMenu={(event) => onContextMenu(event, contextMenuItem)}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setIsOverRow, gridBoard, sortColumns, columnNames],
  );

  // ─── Drag And Drop Handling ──────────────────────────────────────────
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ['STORY_DRAG'],
    drop(p: MemberType) {
      if (!isOverRow && items.length) {
        onAddItem(p, items.length);
      }
    },
    canDrop(p: MemberType) {
      return (p.mType && acceptedMTypes.includes(p.mType)) ?? false;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  if (loading)
    return (
      <Box>
        <LoadingIndicator />
      </Box>
    );

  return (
    <GridWrapper
      maxHeight="100%"
      ref={(ref: HTMLDivElement) => drop(ref)}
      isOver={isOver && canDrop}
    >
      <DataGrid
        sortColumns={sortColumns}
        onSortColumnsChange={setSortColumns}
        defaultColumnOptions={{ sortable: true, resizable: true }}
        columns={columns}
        rows={filteredRows}
        renderers={{
          noRowsFallback: <EmptyRowsRenderer loading={false} onAddItem={onAddItem} />,
          renderRow,
        }}
        rowKeyGetter={rowKeyGetter}
        onRowsChange={onNewRows}
      />
    </GridWrapper>
  );
}
