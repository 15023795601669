import { getMdfByMTypeFromMdfs } from 'features/grids/common/components/utils/mdfUtils';
import { getErrorMap } from 'hooks/useMdfErrorMap';
import { ParsedMemberType } from 'types';
import { Metadata } from 'types/forms/forms';
import { FieldTypeEnum, Mdf, MdfField, MemberType } from 'types/graphqlTypes';
import { getDefaultValues } from 'utils/mdf/utils';

export type MemberAutoSave = Pick<MemberType, 'mType' | 'mId' | 'mRefId'>;

// eslint-disable-next-line @typescript-eslint/comma-dangle
const keyByForEach = <T,>(arr: T[], f: (x: T) => string): Record<string, T> => {
  return arr.reduce((acc, x) => {
    acc[f(x)] = x;
    return acc;
  }, {} as Record<string, T>);
};

const getMdf = (row: ParsedMemberType, mdfs: Mdf[]): Mdf | undefined => {
  if (row.mdfId) return mdfs.find((m) => m.id === row.mdfId);
  return getMdfByMTypeFromMdfs(row, mdfs);
};

const getFieldMap = (mdf?: Mdf): Record<string, MdfField> => {
  if (!mdf) return {};
  return keyByForEach<MdfField>(mdf.fields, (f) => f.fieldId);
};

const getSubTypeByLabelMap = (subTypes: Mdf[]): Record<string, Mdf> => {
  return keyByForEach<Mdf>(subTypes, (s) => s.label);
};

const getValidFieldMap = (
  fieldMap: Record<string, MdfField>,
  metadata: Metadata,
  subTypeByLabelMap: Record<string, Mdf>,
): Record<string, MdfField> => {
  if (!metadata) return {};

  let allFields: Record<string, MdfField> = { ...fieldMap };
  for (const [key, value] of Object.entries(metadata)) {
    if (fieldMap[key]?.type === FieldTypeEnum.subtype && subTypeByLabelMap[value as string]) {
      allFields = {
        ...allFields,
        ...keyByForEach<MdfField>(subTypeByLabelMap[value as string].fields, (f) => f.fieldId),
      };
    }
  }

  return allFields;
};

const getMemberErrorMap = (metadata: Metadata, subTypes: Mdf[], mdf?: Mdf) => {
  const fieldMap = getFieldMap(mdf);
  const subTypeByLabelMap = getSubTypeByLabelMap(subTypes);
  const validFieldMap = getValidFieldMap(fieldMap, metadata, subTypeByLabelMap);
  const errorMap = mdf ? getErrorMap(validFieldMap, metadata) : {};

  return { errorMap, validFieldMap };
};

const getMemberMetadata = (member: ParsedMemberType, subTypes: Mdf[], mdf?: Mdf) => {
  if (!mdf) return {};
  const defaultValues = getDefaultValues(member.metadata, mdf, subTypes);
  return {
    ...member.metadata,
    ...defaultValues,
  };
};

/** Groom the data with mdf */
export const groomMemberMetadata = (
  data: ParsedMemberType[],
  mdfs: Mdf[],
  subTypes: Mdf[] = [],
) => {
  return data.map((row) => {
    const mdf = getMdf(row, mdfs);
    const metadata = getMemberMetadata(row, subTypes, mdf);
    const { errorMap, validFieldMap } = getMemberErrorMap(metadata, subTypes, mdf);

    return { ...row, mdf, metadata, errorMap, validFieldMap };
  });
};
