import Text from 'components/text/Text';
import { Box } from 'layouts/box/Box';
import { Select, SelectValue } from 'lib/select';

import { Content, Group, Item, Trigger } from './styled';

type ItemType = {
  value: string;
  label: string;
};

interface DialogSelectProps {
  label: string;
  value: string;
  onValueChange: (value: string) => void;
  items: ItemType[];
}

/** INFO: This component is used in the configurable dialog.
 * It will replace the select component that is located in components. */
export default function DialogSelect({
  label,
  value,
  onValueChange,
  items,
}: Readonly<DialogSelectProps>) {
  return (
    <Box container justifyContent="space-between">
      <Text variant="listItemLabel" color="highEmphasis" as="p">
        {label}
      </Text>
      <Select value={value} onValueChange={onValueChange} defaultValue="edit">
        <Trigger>
          <SelectValue placeholder="Select" />
        </Trigger>
        <Content>
          <Group>
            {items.map((item) => (
              <Item key={item.value} value={item.value}>
                {item.label}
              </Item>
            ))}
          </Group>
        </Content>
      </Select>
    </Box>
  );
}
