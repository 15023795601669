const columns = [
  {
    field: 'name',
    headerName: 'Name',
    sortable: true,
    sortField: 'mTitle',
    searchField: 'mTitle',
  },
  {
    field: 'type',
    headerName: 'Type',
    sortable: true,
  },
  {
    field: 'email',
    headerName: 'Email',
    sortable: true,
  },
];

export default columns;
