/* eslint-disable max-len */
import { elementTypes } from 'components/editor/constants/types';
import { ReactComponent as AudioIcon } from 'assets/icons/systemicons/editor/Secondary_Audio_Small.svg';
import { ReactComponent as AccessoriesIcon } from 'assets/icons/systemicons/editor/secondary_code_small.svg';
import { ReactComponent as GraphicsIcon } from 'assets/icons/systemicons/editor/secondary_graphic.svg';

const { ACCESSORY, AUDIO, OVERLAY_GRAPHICS } = elementTypes;

const iconComponents = {
  [ACCESSORY]: AccessoriesIcon,
  [AUDIO]: AudioIcon,
  [OVERLAY_GRAPHICS]: GraphicsIcon,
};

export default iconComponents;
