import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { Button } from 'components/buttons';
import useDinaNavigate from 'hooks/useDinaNavigate';
import useGetUser from 'hooks/useGetUser';
import useSelectRundownInstance from 'hooks/useSelectRundownInstance';
import memberTypes from 'operations/memberTypes';
import { useRightHidden, useRightSelection } from 'store/sidebar';
import conversationTypes from 'utils/constants/conversationTypes';

import MessageBubble from '../../header/navbar/messageHub/components/messageContent/MessageBubble';

const ButtonWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 2;
`;

const Messages = ({
  messages,
  hasMore,
  onLoadMore,
  userId,
  onDeleteMessage,
  onClose,
  onUpdateMessage,
  suggestedUsers,
}) => {
  const { navigateTo } = useDinaNavigate();
  const { getUser } = useGetUser();
  const [, setRightHidden] = useRightHidden();
  const [, setRightSelection] = useRightSelection();
  const { selectRundownInstance } = useSelectRundownInstance();

  const handleDelete = async (mRefId) => {
    await onDeleteMessage(mRefId);
  };

  const handleOpenStory = async (title, mStoryId, page, instanceId, publishingDate, convoType) => {
    if (page === memberTypes.RUNDOWN) {
      navigateTo(page, mStoryId);
      if (instanceId) {
        selectRundownInstance([instanceId]);
      } else if (convoType === conversationTypes.MENTION) {
        setRightHidden(false);
        setRightSelection(selectionTypes.CHAT);
      }
    } else {
      navigateTo(page, mStoryId, {
        tab: instanceId ? 'instances' : 'content',
        entityId: instanceId,
      });
      if (convoType === conversationTypes.MENTION) {
        setRightHidden(false);
        setRightSelection(selectionTypes.CHAT);
      }
    }
    onClose();
  };

  return (
    <>
      {messages.map((message) => (
        <MessageBubble
          key={message.mRefId}
          message={message}
          userId={userId}
          showEditButton
          showDeleteButton
          getUser={getUser}
          onDelete={handleDelete}
          openStory={handleOpenStory}
          onUpdateMessage={onUpdateMessage}
          suggestedUsers={suggestedUsers}
        />
      ))}
      {hasMore && (
        <ButtonWrapper>
          <Button onClick={onLoadMore} variant="discreet" usage="text" title="Load more messages">
            Load more
          </Button>
        </ButtonWrapper>
      )}
    </>
  );
};

Messages.propTypes = {
  /** list of messages */
  messages: PropTypes.arrayOf(PropTypes.shape({})),
  /** boolean that indicates more messages */
  hasMore: PropTypes.bool,
  /** callback to load more message */
  onLoadMore: PropTypes.func,
  onDeleteMessage: PropTypes.func,
  onUpdateMessage: PropTypes.func,
  /** id of the user */
  userId: PropTypes.string,
  /** ref containing the id of last message */
  lastMessageRef: PropTypes.shape({
    current: PropTypes.string,
  }),
  /** callback to close message hub */
  onClose: PropTypes.func,
  suggestedUsers: PropTypes.arrayOf(PropTypes.shape({})),
};

Messages.defaultProps = {
  messages: [],
  hasMore: false,
  onLoadMore: () => {},
  onDeleteMessage: () => {},
  onUpdateMessage: () => {},
  userId: '',
  lastMessageRef: { current: null },
  onClose: () => {},
  suggestedUsers: [],
};

export default Messages;
