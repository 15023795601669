import styled from '@emotion/styled/macro';
import { Typography } from '@material-ui/core';

interface ItemWrapperProps {
  $itemHeight: number;
}
export const ItemWrapper = styled('div')<ItemWrapperProps>`
  position: relative;
  width: 234px;
  height: ${({ $itemHeight }) => `${$itemHeight}px`};
  min-height: 42px;
  max-height: 90px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px 10px;
`;

export const EventWrapper = styled('div')`
  position: relative;
  height: 20px;
  left: 0px;
  top: 2px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px;
  margin-bottom: 4px;
`;

interface EventLayoutProps {
  $index: number;
}
export const EventLayout = styled('div')<EventLayoutProps>`
  position: relative;
  height: 20px;
  left: 0px;
  top: ${({ $index }) => ($index === 0 ? '0px' : '4px')};

  background: rgba(94, 114, 138, 0.5);
  border-radius: 2px;
  padding: 4px;
  margin: 0px 4px;
`;

export const EventLabel = styled(Typography)`
  ${({ theme }) => theme.typography.dina.captionMedium};
  position: static;
  left: 4px;
  right: 12px;
  top: 1.5px;
  bottom: 1.5px;
  text-transform: uppercase;

  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  flex: none;
  order: 1;
  align-self: center;
`;

export const CustomEventWrapper = styled('div')`
  position: relative;
  height: 20px;
  left: 0px;
  top: 4px;
  margin-bottom: 4px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px;
`;

export const CustomEventLayout = styled('div')`
  position: relative;
  width: 100%;
  height: 18px;
  left: 0px;
  top: 0px;

  background: rgba(94, 114, 138, 0.5);
  border-radius: 2px;
  margin: 0px 4px;
`;

export const CustomEventLabel = styled(Typography)`
  ${({ theme }) => theme.typography.dina.captionMedium};
  position: relative;
  left: 4px;
  right: 12px;
  top: 1.5px;
  bottom: 1.5px;
  text-transform: uppercase;

  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  flex: none;
  order: 1;
  align-self: center;
`;

export const TimeStampWrapper = styled('div')`
  height: 13px;
  margin: 0px 2px;
  position: relative;
`;

export const TimeStampLabel = styled(Typography)`
  ${({ theme }) => theme.typography.dina.captionRegular};
  position: relative;
  left: 4px;
  right: 0px;
  top: calc(50% - 13px / 2 + 4.5px);
  bottom: 1.5px;

  color: ${({ theme }) => theme.palette.dina.highEmphasis};
  flex: none;
  order: 1;
  align-self: center;
`;

export const UpdatedByWrapper = styled('div')`
  height: 13px;
  margin: 0px 2px;
  position: relative;
`;

export const UpdatedByLabel = styled(Typography)`
  ${({ theme }) => theme.typography.dina.captionSmall};
  position: relative;
  left: 4px;
  right: 0px;
  top: calc(50% - 11px / 2 + 7.5px);

  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  flex: none;
  order: 1;
  align-self: center;
`;

export const IconWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;

  svg {
    width: 18px;
    height: 18px;

    path {
      fill-opacity: 1;
    }
  }
`;

export const StateLabel = styled('span')`
  ${({ theme }) => theme.typography.dina.listItemLabel};
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const InstanceStateWrapper = styled('div')`
  position: relative;
  height: 19px;
  left: 4px;
  top: 6px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const AvatarWrapper = styled('div')`
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const UserWrapper = styled('div')`
  position: relative;
  height: 19px;
  left: 4px;
  top: 6px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 3px;
`;

export const PersonName = styled('span')`
  ${({ theme }) => theme.typography.dina.listItemLabel};
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
