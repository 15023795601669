import { ReactComponent as DinaIcon } from 'assets/images/DinaIcon.svg';
import { Button } from 'components/buttons';
import Text from 'components/text/Text';
import { Flex, VStack } from 'layouts/box/Box';
import URLS from 'utils/constants/urls';

type Props = {
  onLogout: () => Promise<void>;
};

const versionNumber = (import.meta.env.REACT_APP_VERSION as string) || 'N.NN.N';
const versionDateUtc = (import.meta.env.REACT_APP_VERSION_DATE as string) || new Date().toString();
const versionDate = new Date(versionDateUtc).toLocaleDateString();
const { CHANGELOG_URL } = URLS;

export default function Footer({ onLogout }: Props) {
  return (
    <VStack width="100%" padding="8px 8px 16px 8px">
      <Button variant="contained" usage="danger" onClick={() => void onLogout()}>
        Log out
      </Button>
      <VStack padding="16px 8px 8px 8px" width="100%">
        <Text variant="captionSmall">Dina by Dina Media Tech AS</Text>
        <Text
          as="a"
          href={CHANGELOG_URL}
          target="_blank"
          rel="noopener noreferrer"
          variant="captionSmall"
        >
          Version {versionNumber}, {versionDate}
        </Text>

        <Text variant="captionSmall">Copyright © Dina Media Tech AS</Text>
        <Text variant="captionSmall">All rights reserved</Text>
      </VStack>
      <Flex alignSelf="flex-end" margin="-28px 0 0 0">
        <DinaIcon />
      </Flex>
    </VStack>
  );
}
