/**
 * Returns array that is merged with two array passed based on prop
 *
 * @param {Object[]} dstArray where the other array will be merged
 * @param {Object[]} srcArray which will be merged with array1
 * @param {String} prop based on which array2 will be merged with array1
 * @returns {Object[]} merged array of array1 and array2
 *
 */

const MergeByProp = (dstArray, srcArray, prop) => {
  // Create a new array to avoid mutating the original array
  const newArray = dstArray.map(({ name, value }) => ({ name, value }));

  srcArray.forEach((srcItem) => {
    const index = newArray.findIndex((dstItem) => dstItem[prop] === srcItem[prop]);
    if (index >= 0) {
      // Merge the properties from srcItem into dstItem
      newArray[index] = { ...newArray[index], ...srcItem };
    } else {
      // If the item doesn't exist in newArray, add it
      newArray.push(srcItem);
    }
  });
  return newArray;
};

export default MergeByProp;
