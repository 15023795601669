import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox as MaterialCheckbox } from '@material-ui/core';
import useStyles from './CheckboxStyle';
import { CheckboxSelected, CheckboxUnselected, CheckboxIndeterminate } from './CheckboxIcon';

const Checkbox = (props) => {
  const { value, selected, disabled, onClick, size, disableHoverEffect, indeterminate, ...others } =
    props;
  const [mouseDown, setMouseDown] = useState(false);
  const classes = useStyles({ size, disableHoverEffect });

  const renderIcon = () => {
    if (indeterminate)
      return (
        <CheckboxIndeterminate
          mouseDown={mouseDown}
          className={{ classIcon: classes.indeterminateIcon, classWrapper: classes.iconWrapper }}
        />
      );

    if (mouseDown)
      return (
        <CheckboxSelected
          mousedown={mouseDown}
          className={{
            classIcon: classes.checkedIcon,
            classWrapper: classes.iconWrapper,
          }}
        />
      );

    return (
      <CheckboxUnselected
        className={{
          classIcon: classes.uncheckedIcon,
          classWrapper: classes.iconWrapper,
        }}
      />
    );
  };

  const renderCheckedIcon = () =>
    disabled ? (
      <CheckboxSelected
        mousedown={mouseDown}
        className={{
          classIcon: classes.uncheckedIcon,
          classWrapper: classes.iconWrapper,
        }}
      />
    ) : (
      <CheckboxSelected
        mousedown={mouseDown}
        className={{
          classIcon: classes.checkedIcon,
          classWrapper: classes.iconWrapper,
        }}
      />
    );

  const renderInditerminateIcon = () => (
    <CheckboxIndeterminate
      mousedown={mouseDown}
      className={{
        classIcon: classes.indeterminateIcon,
        classWrapper: classes.iconWrapper,
      }}
    />
  );

  return (
    <MaterialCheckbox
      checked={selected}
      value={value}
      disabled={disabled}
      onChange={() => onClick(value)}
      onMouseDown={() => setMouseDown(true)}
      onMouseUp={() => setMouseDown(false)}
      classes={{
        root: classes.root,
        checked: classes.checked,
        disabled: classes.disabled,
      }}
      indeterminate={indeterminate}
      icon={renderIcon()}
      checkedIcon={renderCheckedIcon()}
      indeterminateIcon={renderInditerminateIcon()}
      {...others}
    />
  );
};

Checkbox.propTypes = {
  /** Value of the checkbox (string) */
  value: PropTypes.string,
  /** Values: true/false */
  selected: PropTypes.bool,
  /** Values: true/false */
  disabled: PropTypes.bool,
  /** onClick function: marks the value selected when clicked */
  onClick: PropTypes.func,
  /** size of the checkbox  */
  size: PropTypes.number,
  /** whether the hover effect will be disabled or not */
  disableHoverEffect: PropTypes.bool,
  /** whether the checkbox is indeterminate */
  indeterminate: PropTypes.bool,
  id: PropTypes.string,
};

Checkbox.defaultProps = {
  value: '',
  selected: false,
  disabled: false,
  onClick: () => {},
  size: 40,
  disableHoverEffect: false,
  indeterminate: false,
};

export default Checkbox;
