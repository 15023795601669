import styled from '@emotion/styled';
import { ListItem } from '@material-ui/core';

import Divider from 'components/divider';

export const ListItemWrapper = styled(ListItem)`
  flex: 1;
  width: 100%;
  max-height: 38px;
  min-height: 32px;
  * {
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
  :hover {
    background: ${({ theme }) => theme.palette.dina.outlineButtonOnHover};
    svg path {
      fill-opacity: 1;
    }
  }
`;

export const IconWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: 12px;
`;

export const IconDivider = styled(Divider)<{ $icon: boolean }>`
  width: ${({ $icon }) => ($icon ? 'calc(100% - 52px)' : '100%')};
  margin: ${({ $icon }) => ($icon ? '4px 0px 4px 52px' : '4px 0px')}
  background: ${({ theme }) => theme.palette.dina.dividerLight};
`;
