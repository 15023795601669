import { isKeyHotkey } from 'is-hotkey';
import { Editor, Element, Range, Transforms } from 'slate';

import { elementTypes } from 'components/editor/constants';

const { nodes } = Editor;

/**
 * Handles onKeyDown event on mention element
 *
 * @param editor SlateJS editor instance
 * @param event React synthetic event
 * @returns SlateJS editor instance
 */

const onMentionKeyDown = (editor: Editor, event: React.KeyboardEvent<HTMLDivElement>) => {
  const { selection } = editor;
  const [match] = nodes(editor, {
    match: (node) => Element.isElement(node) && node.type === elementTypes.MENTION,
  });

  if (selection && match && Range.isCollapsed(selection)) {
    const { nativeEvent } = event;
    if (isKeyHotkey('left', nativeEvent)) {
      event.preventDefault();
      Transforms.move(editor, { unit: 'offset', reverse: true });
      return editor;
    }
    if (isKeyHotkey('right', nativeEvent)) {
      event.preventDefault();
      Transforms.move(editor, { unit: 'offset' });
      return editor;
    }
  }

  return editor;
};

export default onMentionKeyDown;
