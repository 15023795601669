import { useCallback, useContext, useEffect, useMemo } from 'react';
import { isEqual } from 'lodash';

import ConfigContext from 'contexts/configContext';
import useDebouncedCallback from 'hooks/useDebouncedCallback';
import useSettingsValue from 'hooks/useSettingsValue';
import useUpdateDurationMeta from 'hooks/useUpdateDurationMeta';
import {
  durationTypes,
  getDuration,
  getDurationKey,
} from 'screens/rundown/components/editor/utils';
import respectHostReadSpeed from 'screens/rundown/utils/respectHostReadSpeed';
import { EditorValue } from 'types';
import { MMetaDataField } from 'types/graphqlTypes';
import getEmptyMetadataForForm from 'utils/getEmptyMetadata';
import getHostReadSpeed from 'utils/instance/getHostReadSpeed';
import variants from 'utils/instance/variants';
import { entityTypes, specTypes } from 'utils/metadata';
import getFormBySpec from 'utils/rundown/getFormBySpec';
import returnFieldPairs from 'utils/rundown/returnFieldPairs';

import { useInstanceMolecule } from '../store/instance';
import { getVariant } from '../utils';

export const useInstanceMetadata = () => {
  const {
    useInstanceValue,
    useMetadata,
    usePublishingPointValue,
    useLockedByCurrentUser,
    useDurations,
    editorValueRef,
  } = useInstanceMolecule();

  const { metadataForms } = useContext(ConfigContext);
  const [getSettingsValue] = useSettingsValue();

  const [metadata, setMetadata] = useMetadata();
  const [lockedByCurrentUser] = useLockedByCurrentUser();
  const instance = useInstanceValue();
  const publishingPoint = usePublishingPointValue();
  const [, setDurations] = useDurations();

  const defaultReadSpeed = getSettingsValue('rundown.defaultReadSpeed') as number;
  const canUpdateScriptDurationSettingsValue =
    getSettingsValue('rundown.instance.updateScriptDurationField') === 'true';
  const autoClipDurationSettingsValue =
    getSettingsValue('rundown.enableAutoClipDuration') === 'true';

  const form = useMemo(() => metadataForms[0], [metadataForms]);

  const blankMetaData = useMemo(() => getEmptyMetadataForForm(form), [form]);

  const defaultMetadata: MMetaDataField[] = useMemo(
    () =>
      instance?.mMetaData ??
      blankMetaData.map(({ key, value }) => ({
        key,
        value,
        __typename: 'mMetaDataField',
      })),
    [instance?.mMetaData, blankMetaData],
  );

  const variant = getVariant(instance?.mProperties?.platform ?? '');

  const [, setScriptDuration, durations] = useUpdateDurationMeta(blankMetaData, setMetadata) as [
    (
      value: EditorValue | null,
      hostReadRate: boolean,
      currentMetadata: MMetaDataField[],
      canUpdateScriptDurationSettingsValue: boolean,
    ) => {
      key: string;
      value: string | null;
    }[],
    (
      content: EditorValue | null,
      hostReadRate: number,
      metadata: MMetaDataField[],
      lockedByUser: boolean,
    ) => void,
    {
      scriptDuration: string | null;
      totalDuration: string | null;
    },
  ];

  const hostReadSpeed = useMemo(
    () => getHostReadSpeed(defaultMetadata, defaultReadSpeed),
    [defaultMetadata, defaultReadSpeed],
  );

  const memoizedMetadata = useMemo(
    () =>
      respectHostReadSpeed(
        instance?.mMetaData,
        hostReadSpeed,
        canUpdateScriptDurationSettingsValue,
      ) as MMetaDataField[],
    [canUpdateScriptDurationSettingsValue, hostReadSpeed, instance?.mMetaData],
  );

  const findDurationKey = (str: string) =>
    blankMetaData.find((item) => getDurationKey(item) === str)?.key;

  const [fields, parameterFields] = useMemo(
    () =>
      getFormBySpec(form, {
        target: publishingPoint,
        entity: entityTypes.INSTANCE,
        type: specTypes.METADATA,
      }) as [MMetaDataField[], MMetaDataField[]],
    [form, publishingPoint],
  );

  const pairedMetadata = useMemo(
    () =>
      returnFieldPairs(instance?.mMetaData, fields) as unknown as {
        key: string;
        value: MMetaDataField;
      },
    [fields, instance?.mMetaData],
  );

  const clipAutoDurationField = useMemo(
    () => metadata?.find(({ key }) => key === '05-clip-duration'),
    [metadata],
  );
  const scriptDurationField = useMemo(
    () => metadata?.find(({ key }) => key === '08-speak-duration'),
    [metadata],
  );

  const updateMetadataState = useCallback(
    (newMetaFields: MMetaDataField[]) => {
      const updatedMetadata = metadata.map((meta) => {
        const nMeta = newMetaFields.find((field) => field.key === meta.key);
        return nMeta || meta;
      });
      setMetadata(updatedMetadata);
    },
    [metadata, setMetadata],
  );

  const updateScripDuration = useCallback(
    ({
      content,
      updatedMetadata,
    }: {
      // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
      content: EditorValue | null;
      updatedMetadata: MMetaDataField[];
    }) => {
      if (variant !== variants.LINEAR) return;
      setScriptDuration(content, hostReadSpeed, updatedMetadata, lockedByCurrentUser);
    },
    [lockedByCurrentUser, setScriptDuration, variant, hostReadSpeed],
  );

  const [debouncedScriptDurationUpdate] = useDebouncedCallback(updateScripDuration, 300);

  useEffect(() => {
    if (instance?.mMetaData && !isEqual(metadata, memoizedMetadata)) {
      setMetadata(memoizedMetadata);
    }
  }, [instance?.mMetaData, memoizedMetadata, metadata, setMetadata]);

  useEffect(() => {
    if (instance?.mMetaData) {
      updateScripDuration({
        content: editorValueRef.current,
        updatedMetadata: instance?.mMetaData,
      });
    }
  }, [editorValueRef, instance?.mMetaData, updateScripDuration]);

  useEffect(() => {
    const clipDuration = getDuration(metadata, durationTypes.CLIP_DURATION);
    const scriptDuration =
      (
        durations as {
          scriptDuration: string | null;
          totalDuration: string | null;
        }
      )?.scriptDuration ?? getDuration(metadata, durationTypes.SPEAK_DURATION);
    const totalDuration =
      (
        durations as {
          scriptDuration: string | null;
          totalDuration: string | null;
        }
      )?.totalDuration ?? getDuration(metadata, durationTypes.TOTAL_DURATION);

    setDurations({
      clip: clipDuration,
      script: scriptDuration,
      total: totalDuration,
    });
  }, [durations, metadata, setDurations]);

  return {
    hostReadSpeed,
    canUpdateScriptDurationSettingsValue,
    blankMetaData,
    defaultMetadata,
    updateMetadataState,
    autoClipDurationSettingsValue,
    findDurationKey,
    pairedMetadata,
    fields,
    parameterFields,
    clipAutoDurationField,
    scriptDurationField,
    updateScripDuration,
    debouncedScriptDurationUpdate,
  };
};

export default useInstanceMetadata;
