import styled from '@emotion/styled';
import MuiListItemText from '@material-ui/core/ListItemText';
import MuiMenuItem from '@material-ui/core/MenuItem';

import transientOptions from 'theme/helpers';

const MenuItem = styled(MuiMenuItem)`
  &.Mui-selected {
    background-color: ${({ theme }) => theme.palette.dina.onSelected};
  }

  &.MuiMenuItem-root {
    width: 100%;
    display: flex;
    gap: 14px;
    &:hover {
      background-color: ${({ theme }) => theme.palette.dina.statusOnHover};
    }
  }
`;

export const MenuItemText = styled(MuiListItemText, transientOptions)<{
  $isTitle?: boolean;
  $isUnassigned?: boolean;
}>`
  & > span {
    &.MuiListItemText-primary {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-transform: capitalize;
      ${({ $isTitle, $isUnassigned, theme }) => ({
        fontStyle: $isUnassigned ? 'italic' : 'normal',
        color: $isTitle ? theme.palette.dina.highEmphasis : theme.palette.dina.mediumEmphasis,
        flex: $isTitle ? 1 : 0,
      })}
    }
  }
`;

export const MenuInfoWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

export default MenuItem;
