import type { Table } from '@tanstack/react-table';

import ICONS from 'assets/icons/icons';
import { IconButton } from 'components/buttons';
import Icon from 'components/icon';

interface ClearButtonProps<TData> {
  table: Table<TData>;
}

export function ClearButton<TData>({ table }: Readonly<ClearButtonProps<TData>>) {
  return (
    <IconButton
      type="reset"
      title={'Clear filter'}
      variant="outlined"
      usage="outlined"
      onClick={() => table.resetColumnFilters()}
      height={32}
    >
      <Icon icon={ICONS.CLEAR} height={24} width={24} />
    </IconButton>
  );
}
