import React from 'react';
import PropTypes from 'prop-types';
import { Select as MaterialSelect, OutlinedInput, MenuItem, ListItemText } from '@material-ui/core';
import { ReactComponent as ArrowDoubleIcon } from 'assets/icons/systemicons/arrows/arrow_double.svg';
import useStyles from './select-styles';

const SelectView = ({
  options,
  selectedValue,
  onChange,
  severity,
  disableOutline,
  itemHeight,
  disableEdit,
  disable,
}) => {
  const classes = useStyles({ itemHeight });

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  const renderValue = () => {
    if (!selectedValue) return '—';

    const selectedOption = options.find(({ value }) => value === selectedValue);

    return selectedOption ? (
      <MenuItem
        classes={{
          root: classes.selectMenuItem,
        }}
        disableRipple
        disableGutters
      >
        <ListItemText
          primary={selectedOption.title}
          classes={{ primary: classes.selectItemText }}
        />
      </MenuItem>
    ) : null;
  };

  return (
    <MaterialSelect
      disabled={disable}
      value={selectedValue || ''}
      onChange={handleChange}
      classes={{
        root: classes.selectRoot,
        icon: classes.icon,
      }}
      IconComponent={ArrowDoubleIcon}
      {...{ renderValue }}
      input={
        <OutlinedInput
          fullWidth
          classes={{
            root: disableOutline ? classes.disabledOutline : classes[severity],
            notchedOutline: classes.notchedOutline,
            focused: classes.focused,
            disabled: classes.disabled,
            error: classes.error,
          }}
        />
      }
      MenuProps={{
        classes: {
          paper: classes.menu,
        },
      }}
      variant="standard"
    >
      {options && options.length > 0 ? (
        options.map(({ value, icon, title }) => (
          <MenuItem
            {...{ value }}
            key={value}
            disableRipple
            disabled={disableEdit}
            classes={{
              root: classes.menuItem,
              selected: classes.menuItemSelected,
            }}
          >
            <ListItemText primary={title} classes={{ primary: classes.selectItemText }} />
          </MenuItem>
        ))
      ) : (
        <MenuItem
          value=""
          classes={{
            root: classes.menuItem,
            selected: classes.menuItemSelected,
          }}
        >
          <ListItemText
            primary="No options available"
            classes={{ primary: classes.selectItemText }}
          />
        </MenuItem>
      )}
    </MaterialSelect>
  );
};

SelectView.propTypes = {
  /** Items that should be rendered as select options. */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      title: PropTypes.string,
    }),
  ),
  /** Currently selected option's value. */
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Callback to be invoked when an option is selected.
   * Gets the currently selected value as argument.
   */
  onChange: PropTypes.func,
  /** severity of the select component: mandatory or regular */
  severity: PropTypes.string,
  /** whether to disable the outline or not */
  disableOutline: PropTypes.bool,
  /** height of the menu options */
  itemHeight: PropTypes.number,
  /** Boolean that stops an user from editing */
  disableEdit: PropTypes.bool,
};

SelectView.defaultProps = {
  options: [],
  selectedValue: '',
  onChange: () => {},
  severity: 'regular',
  disableOutline: false,
  itemHeight: 32,
  disableEdit: false,
};

export default SelectView;
