import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import { Typography } from '@material-ui/core';
import { ReactComponent as UrlIconComponent } from 'assets/icons/systemicons/editor/link.svg';
import { ReactComponent as OpenIconComponent } from 'assets/icons/systemicons/editor/open_small.svg';
import { ReactComponent as PreviewIconComponent } from 'assets/icons/systemicons/editor/visibility.svg';
import { ReactComponent as AtomOffComponent } from 'assets/icons/systemicons/editor/atom_off.svg';

export const AtomOffIcon = styled(AtomOffComponent)``;

export const UrlIcon = styled(UrlIconComponent)`
  margin: 8px;
  path {
    fill: ${({ theme }) => theme.palette.dina.blackMediumEmphasis};
    fill-opacity: 1;
  }
`;

export const OpenIcon = styled(OpenIconComponent)`
  path {
    fill: ${({ theme }) => theme.palette.dina.iconInactive};
    fill-opacity: 1;
  }
  :hover {
    cursor: pointer;
    path {
      fill: ${({ theme }) => theme.palette.dina.iconActive};
      fill-opacity: 1;
    }
  }
`;

export const PreviewIcon = styled(PreviewIconComponent)`
  path {
    fill: ${({ theme }) => theme.palette.dina.iconInactive};
    fill-opacity: 1;
  }
  :hover {
    cursor: pointer;
    path {
      fill: ${({ theme }) => theme.palette.dina.iconActive};
      fill-opacity: 1;
    }
  }
`;

export const Title = styled(Typography)`
  ${({ theme }) => theme.typography.dina.listItemLabel};
  ${({ theme, $default }) => {
    if (theme.palette.mode === 'light')
      return css`
        color: ${theme.palette.dina.blackMediumEmphasis};
      `;
    if ($default)
      return css`
        color: ${theme.palette.dina.whiteHighEmphasis};
      `;
    return css`
      color: ${theme.palette.dina.whiteInactive};
    `;
  }};

  font-weight: 400;
  max-width: 640px;
  letter-spacing: 0.25px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  align-self: center;
  cursor: pointer;
  :hover {
    filter: ${({ theme }) => theme.palette.dina.onHover};
    cursor: text;
  }
`;

export const IconsWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;

export const UrlWrapper = styled('div')`
  height: 32px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ $hasMetadata, $isInput }) => ($hasMetadata || $isInput ? '8px' : '0px')};
  margin-top: ${({ $isInput }) => ($isInput ? '8px' : '0px')};
`;

export const TitleWrapper = styled('div')`
  width: 80%;
`;
