import { getTime } from 'screens/rundown/components/editor/utils';

/*
 * Calculates how much time it might take to read a some text,
 * given a read speed per minute and a number of words to be read.
 */

const readTime = (readSpeed, wordCount) => {
  const wordsPerSecond = readSpeed / 60;
  return getTime(Math.ceil(wordCount / wordsPerSecond));
};

export default readTime;
