import { TableRow } from 'components/dataTable/Table';

import { TableRowProps, typedMemo } from '../types';

import { MemoTableCell } from './TableCell';

function Row<T>({
  row,
  measureElement,
  start,
  isSkeletonRow,
  rowMeta,
  table,
  staticRowIndex,
}: Readonly<TableRowProps<T>>) {
  return (
    <TableRow
      data-index={staticRowIndex}
      style={{
        transform: `translateY(${start}px)`,
      }}
      ref={measureElement}
      // onDoubleClick={() => rowMeta?.onDoubleClick?.(row.original)}
      onContextMenu={(event) => rowMeta?.onContextMenu?.(event, row.original)}
    >
      {row.getVisibleCells().map((cell, index) => (
        <MemoTableCell
          key={cell.id}
          cell={cell}
          isSkeletonRow={isSkeletonRow}
          row={row}
          table={table}
          staticColumnIndex={index}
        />
      ))}
    </TableRow>
  );
}

export const MemoTableRow = typedMemo(Row);
