import { memo, useCallback } from 'react';

import { useLeftHidden, useLeftSelection, useSidebarSelectedSubtab } from 'store/sidebar';

import VerticalNavbar, { navbarPosition } from '../verticalNavbar';

import Sidebar from './sidebar';

import { LeftContainer, StyledDrawer as Drawer } from './leftArea-styled';

const LeftArea = () => {
  const [leftHidden, setLeftHidden] = useLeftHidden();
  const [leftSelection, setLeftSelection] = useLeftSelection();
  const [, setSelectedSubtab] = useSidebarSelectedSubtab();

  const handleSelectionChanged = useCallback((val) => {
    setLeftSelection(val);
    setSelectedSubtab(0);
  }, []);

  const toggleLeftHidden = () => {
    setLeftHidden(!leftHidden);
  };

  return (
    <LeftContainer>
      <VerticalNavbar
        toggleSidebar={toggleLeftHidden}
        selectionChanged={handleSelectionChanged}
        hidden={leftHidden}
        selectionType={leftSelection}
        position={navbarPosition.LEFT}
      />
      <Drawer variant="persistent" anchor="left" open={!leftHidden && Boolean(leftSelection)}>
        <Sidebar selectionType={leftSelection} />
      </Drawer>
    </LeftContainer>
  );
};

export default memo(LeftArea);
