const addLeadingZeroes = (number: number) => number.toString().padStart(2, '0');

const getHours = (hours: number, preserveHours: boolean) => {
  if (hours) return `${addLeadingZeroes(hours)}:`;
  return preserveHours ? '00:' : '';
};

interface TimeFormatConversionOptions {
  preserveSign?: boolean;
  includeFrames?: boolean;
  fps?: number;
  preserveHours?: boolean;
}

/**
 * Converts milliseconds to hh:mm:ss / hh:mm:ss:ff (ff=frames) formatted time string
 */
const getTimeString = (milliseconds = 0, options?: TimeFormatConversionOptions) => {
  const {
    preserveSign = false,
    includeFrames = false,
    fps = 25,
    preserveHours = false,
  } = options ?? {};
  const parsedMilliseconds = Number(milliseconds);

  if (Number.isNaN(parsedMilliseconds) || parsedMilliseconds === 0)
    return includeFrames ? '00:00;00' : '00:00';

  const sign = Math.sign(parsedMilliseconds) === -1 ? '-' : '+';
  const parsedFrames = includeFrames
    ? Math.floor(Math.abs(parsedMilliseconds % 1000) / (1000 / fps))
    : 0;

  const parsedSeconds = Math.floor(Math.abs(parsedMilliseconds / 1000));
  const hours = Math.floor(parsedSeconds / 3600);
  const minutes = Math.floor((parsedSeconds - hours * 3600) / 60);
  const seconds = parsedSeconds - hours * 3600 - minutes * 60;
  const hh = getHours(hours, preserveHours);
  const mm = `${addLeadingZeroes(minutes)}:`;
  const ss = `${addLeadingZeroes(seconds)}`;
  const ff = `;${addLeadingZeroes(parsedFrames)}`;

  const formattedTime = hh + mm + ss;

  return (preserveSign ? sign : '') + formattedTime + (includeFrames ? ff : '');
};

export default getTimeString;
