/* eslint-disable no-param-reassign */
import { useState, useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowDropDownUnboxed } from 'assets/icons/systemicons/arrows/arrow_drop_down_discreet_unboxed.svg';
import Dropdown from './components/lowerthirdDropdown';
import SelectView from './components/select';
import { getSubtitleStrings, splitTitleBySlash } from './utils';
import Tooltip from 'components/tooltip/Tooltip';
import {
  Arrow,
  DropdownWrapper,
  SubTitle,
  Title,
  TitlesWrapper,
  Wrapper,
  SelectWrapper,
  Circle,
  PreLine,
} from './styled';
import { allowsGraphics, mediaTypes } from 'utils/rundownItemTypes';

const TitleBox = ({
  title,
  meta,
  assets,
  graphicsDestination,
  updateVariant,
  updateGraphicsDestination,
  spec = [],
  resources,
  isGraphic,
  type,
}) => {
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const titles = useMemo(() => title && splitTitleBySlash(title), [title]);
  const { subtitle, tooltip } = useMemo(() => getSubtitleStrings(meta, assets), [meta, assets]);
  const [isLowerthirdSelectOpen, setIsLowerthirdSelectOpen] = useState(false);

  const openSelectMenu = useCallback(() => setIsSelectOpen(true), []);
  const closeSelectMenu = useCallback(() => setIsSelectOpen(false), []);
  const openLowerthirdSelectMenu = useCallback(() => setIsLowerthirdSelectOpen(true), []);
  const closeLowerthirdSelectMenu = useCallback(() => setIsLowerthirdSelectOpen(false), []);

  const handleSelection = useCallback(
    (selectedValue) => {
      const template = spec.find((temp) => temp.variant === selectedValue);
      if (template) {
        const { variant, fields, name, description } = template;
        updateVariant(variant, fields, name, description);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateVariant],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleLowerthirdSelection = useCallback(
    (selectedValue) => {
      const lowerThird = resources.graphicsDestinations.find((d) => d.value === selectedValue);
      if (lowerThird) {
        const { value } = lowerThird;
        updateGraphicsDestination(value);
      }
    },
    [resources, updateGraphicsDestination],
  );

  const hasGraphics = subtitle.type === mediaTypes.GRAPHICS && !allowsGraphics(type);

  return (
    <Wrapper>
      <SelectWrapper onClick={openSelectMenu} role="presentation">
        {!isGraphic && (
          <Arrow>
            <ArrowDropDownUnboxed />
          </Arrow>
        )}
        <TitlesWrapper>
          {spec?.length > 0 && (
            <SelectView
              isOpen={isSelectOpen}
              items={spec}
              onChange={handleSelection}
              onClose={closeSelectMenu}
              onOpen={openSelectMenu}
              selectedValue={title}
            />
          )}

          {spec?.length === 0 &&
            titles?.length > 0 &&
            titles.map((line) => (
              <Title title={line} key={line}>
                {line}
              </Title>
            ))}

          <Tooltip title={<PreLine>{tooltip}</PreLine>}>
            <SubTitle $hasGraphics={hasGraphics}>
              {hasGraphics && <Circle />}
              {subtitle.value}
            </SubTitle>
          </Tooltip>
        </TitlesWrapper>
      </SelectWrapper>
      {isGraphic && resources?.graphicsDestinations?.length > 0 && (
        <DropdownWrapper onClick={openLowerthirdSelectMenu}>
          <Dropdown
            isOpen={isLowerthirdSelectOpen}
            items={resources.graphicsDestinations}
            onChange={handleLowerthirdSelection}
            onClose={closeLowerthirdSelectMenu}
            selectedValue={
              !graphicsDestination
                ? resources.graphicsDestinations.find((r) => r.type === 'default').value
                : graphicsDestination
            }
          />
        </DropdownWrapper>
      )}
    </Wrapper>
  );
};

TitleBox.propTypes = {
  /** templateVariant to show as title */
  title: PropTypes.string,
  /** metaData to show as subtitle */
  meta: PropTypes.arrayOf(PropTypes.object),
  /** on Title box click callback function */
  updateVariant: PropTypes.func,
  /** assets */
  assets: PropTypes.arrayOf(PropTypes.object),
  /** type of the primary automation where component is used for */
  type: PropTypes.string,
};

TitleBox.defaultProps = {
  title: 'VARIANT',
  meta: [],
  updateVariant: () => {},
  assets: [],
  type: undefined,
};

export default memo(TitleBox);
