import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const PriorityFilterWrapper = styled('div')`
  ${({ theme }) => css`
    background-color: ${theme.palette.dina.inputBackground};
    border-radius: 6px;
    display: flex;
    height: 32px;
    width: 100%;
    padding: 8px;
    border: 1px solid ${theme.palette.dina.borderResting};

    :focus-within {
      border: 1px solid ${theme.palette.dina.onFocus};
    }
  `}
`;
