import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import transientOptions from 'theme/helpers';

interface ButtonWrapperProps {
  $isActive: boolean;
  $isFocused: boolean;
  $textColor: string;
}
export const ButtonWrapper = styled('div', transientOptions)<ButtonWrapperProps>`
  ${({ $isActive, $isFocused, theme }) =>
    $isActive &&
    $isFocused &&
    css`
      background-color: ${theme.palette.dina.iconDisabled};
    `};
  height: 24px;
  width: 24px;
  border-radius: 4px;
  cursor: pointer;
  svg {
    path {
      fill: ${({ $textColor }) => $textColor};
      fill-opacity: 0.8;
    }
  }
  :hover {
    svg {
      path {
        fill-opacity: 1;
      }
    }
  }
`;
