/* eslint-disable no-console */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { debounce } from 'lodash';

import { ReactComponent as SearchIconSvg } from 'assets/icons/systemicons/search.svg';
import Checkbox from 'components/checkbox';
import { StyledFormControl } from 'components/command/toolbar/styled';
import { Input } from 'components/input/styled';
import { LoadingButtonIndicator } from 'components/loadingIndicator';
import Scrollbar from 'components/scrollbar';
import Text from 'components/text/Text';
import Tooltip from 'components/tooltip';
import List from 'features/feedViewer/components/list';
import { HeaderWrapper } from 'features/searchPlugin/styled';
import useCheckUserRight from 'hooks/useCheckUserRight';
import useOpenMimirItem from 'hooks/useOpenMimirItem';
import { VStack } from 'layouts/box/Box';
import { FilterValueType } from 'types/widget';

import MimirItem from './components/items';
import useMimirSearch, { MimirItem as MimirItemInterface } from './hooks/useMimirSearch';

export const Wrapper = styled(VStack)`
  height: 100%;
  padding: 50px 3px 3px 3px;
  justify-content: start;
`;

interface Props {
  filters?: FilterValueType;
  federatedSearchString?: string;
  onSearchStringChange: (val: string) => void;
}

export default function MimirDeck({
  filters,
  onSearchStringChange,
  federatedSearchString = '',
}: Readonly<Props>) {
  const [semanticSearch, setSemanticSearch] = useState(false);
  const [checkUserRight] = useCheckUserRight();
  const supportsMimirSemanticSearch = checkUserRight('feature', 'mimir-semantic-search');
  const openAssetInMimir = useOpenMimirItem();
  const [searchInput, setSearchInput] = useState((filters?.searchString as string) ?? '');
  const [items, setItems] = useState<MimirItemInterface[]>([]);

  const specificFolderId = useMemo(() => {
    return typeof filters?.folderId === 'string' ? filters.folderId : undefined;
  }, [filters]);

  const { search, loading, noMoreResults, fetchMore } = useMimirSearch({
    folderId: specificFolderId,
  });

  const fetchMoreItems = async () => {
    if (!loading) {
      const nextBatch = await fetchMore();
      if (nextBatch) {
        setItems((prevItems) => {
          return [...prevItems, ...nextBatch];
        });
      }
    }
  };

  const debouncedMimirSearch = useRef(
    debounce(async (searchString: string, resetSearch?: boolean, semSearch?: boolean) => {
      const result = await search(searchString, resetSearch, semSearch);
      setItems(result);
    }, 1000),
  ).current;

  const debouncedSearchStringUpdate = useRef(
    debounce((newValue: string, oldValue: string) => {
      if (newValue !== oldValue) {
        onSearchStringChange(newValue);
      }
    }, 300),
  );

  useEffect(() => {
    debouncedMimirSearch(
      federatedSearchString ? `${searchInput} ${federatedSearchString}` : searchInput,
      undefined,
      semanticSearch,
    )?.catch((err: unknown) => {
      console.error(err);
    });
    debouncedSearchStringUpdate.current(searchInput, (filters?.searchString ?? '') as string);
  }, [searchInput, federatedSearchString, semanticSearch]);

  const openInMimir = useCallback((item: MimirItemInterface) => {
    openAssetInMimir(item.id);
  }, []);

  return (
    <Wrapper>
      <HeaderWrapper>
        {loading ? (
          <LoadingButtonIndicator inline size={16} style={{ marginRight: '5px' }} />
        ) : (
          <SearchIconSvg />
        )}
        <Input
          value={searchInput}
          onChange={(val) => setSearchInput(val.currentTarget.value)}
          placeholder="Search here..."
        />
        {supportsMimirSemanticSearch && (
          <Tooltip title="Semantic search">
            <StyledFormControl
              control={
                <Checkbox
                  onClick={() => setSemanticSearch(!semanticSearch)}
                  selected={semanticSearch}
                />
              }
              label={''}
            />
          </Tooltip>
        )}
      </HeaderWrapper>
      <Scrollbar closeToBottom={() => void fetchMoreItems()}>
        <List>
          <List.Body>
            {items.map((item) => (
              <MimirItem item={item} key={item.id} onClick={() => openInMimir(item)} />
            ))}
          </List.Body>
        </List>
        {noMoreResults && <Text variant="caption">No more results</Text>}
      </Scrollbar>
    </Wrapper>
  );
}
