import { css } from '@emotion/react';
import styled from '@emotion/styled';

import transientOptions from 'theme/helpers';

interface LinkWrapperProps {
  $url: boolean;
}
export const LinkWrapper = styled('div', transientOptions)<LinkWrapperProps>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${({ $url, theme }) =>
    !$url
      ? css`
          :hover {
            span {
              flex: 1;
              cursor: pointer;
              color: ${theme.palette.dina.highEmphasis} !important;
            }
          }
        `
      : css`
          :hover .edit-icn {
            display: flex;
          }
        `}
`;

export const IconWrapper = styled('div')`
  flex-grow: 0;
  flex-shrink: 0;
  gap: 4px;
  margin: 0 4px;
  width: 42px;
  display: none;
  cursor: pointer;
`;

export const StyledSpan = styled.span`
  cursor: pointer;
  ${({ theme }) => css`
    color: ${theme.palette.dina.mediumEmphasis};
    :hover {
      color: ${theme.palette.dina.highEmphasis};
    }
  `}
`;

export const StyledA = styled.a`
  min-height: 24px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
`;
