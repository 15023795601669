import styled from '@emotion/styled';

import { HoverCard, HoverCardContent, HoverCardTrigger } from 'lib/HoverCard';

interface HoverCardProps {
  /** The children that will trigger the hover card. **/
  children: React.ReactNode;
  /** The content to display when the card is hovered. **/
  content: React.ReactNode;
}

const StyledContent = styled(HoverCardContent)`
  border: 1px solid ${({ theme }) => theme.palette.dina.tooltipBorder};
  background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
  box-shadow: 0px 0px 4px ${({ theme }) => theme.palette.dina.boxShadowDark};
  max-width: 340px;
  outline: none;
  z-index: 3400;
`;

export default function Card({ children, content }: Readonly<HoverCardProps>) {
  return (
    <HoverCard openDelay={500} closeDelay={0}>
      <HoverCardTrigger asChild>{children}</HoverCardTrigger>
      <StyledContent align="start">{content}</StyledContent>
    </HoverCard>
  );
}
