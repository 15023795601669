import { Editor, Element, Point, Range, Transforms } from 'slate';

import { elementTypes } from '../constants';

/**
 * Enhances the given `Editor` instance with a custom `deleteBackward` behavior that handles
 * checklist items.
 *
 * @param editor - The `Editor` instance to be enhanced.
 * @returns The enhanced `Editor` instance.
 */
const withChecklist = (editor: Editor) => {
  const { deleteBackward } = editor;

  /* Override the `deleteBackward` function with custom behavior */
  editor.deleteBackward = (...args) => {
    const { selection } = editor;

    /* Check if the selection is collapsed (i.e., a cursor) */
    if (selection && Range.isCollapsed(selection)) {
      const [match] = Editor.nodes(editor, {
        match: (n) =>
          !Editor.isEditor(n) && Element.isElement(n) && n.type === elementTypes.CHECKLIST,
      });

      if (match) {
        const [, path] = match;
        const start = Editor.start(editor, path);

        /* If the cursor is at the start of the checklist */
        if (Point.equals(selection.anchor, start)) {
          const newProperties: Partial<Element> = {
            type: 'paragraph',
          };
          Transforms.setNodes(editor, newProperties, {
            match: (n) =>
              !Editor.isEditor(n) && Element.isElement(n) && n.type === elementTypes.CHECKLIST,
          });
          return editor;
        }
      }
    }

    deleteBackward(...args);
  };

  return editor;
};

export default withChecklist;
