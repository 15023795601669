import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    // animation: '$animate 10s ease-in-out 1',
    // animationDelay: '10s',
  },
  '@keyframes animate': {
    '0%': { opacity: 1 },
    '20%': { opacity: 0 },
    '100%': { opacity: 0 },
  },
  thumbnail: {
    boxShadow:
      '0px 8px 16px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.25)',
    marginBottom: '8px',
    borderRadius: '8px',
  },
}));

export default useStyles;
