/**
 *  Hook for sending a feed item to a story.
 *  It takes the mId of the story, and mId and provider from the feed item.
 */
import { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

import { ServiceRequestResponse } from 'types/graphqlTypes';
import useLogger from 'utils/useLogger';

const PREVIEW_URL_REQUEST = gql`
  mutation previewUrlRequest($input: ServiceRequestInput) {
    serviceRequest(input: $input) {
      body
      provider
    }
  }
`;

interface GetPreviewUrlType {
  serviceRequest: ServiceRequestResponse;
}

const useGetPreviewUrl = () => {
  const [getPreviewUrl] = useMutation<GetPreviewUrlType>(PREVIEW_URL_REQUEST);
  const [previewUrl, setPreviewUrl] = useState<string>();
  const logger = useLogger('useGetPreviewUrl');
  const fetchPreviewUrl = useCallback((provider: string, url: string) => {
    const variables = {
      input: {
        provider,
        request: {
          url,
          method: 'GET',
        },
      },
    };

    getPreviewUrl({
      variables,
    })
      .then((r) => {
        const result = r.data?.serviceRequest?.body as string;
        setPreviewUrl(result);
      })
      .catch((e) => logger.warn(`Error: ${e}`));
  }, []);

  return [fetchPreviewUrl, previewUrl, setPreviewUrl] as const;
};

export default useGetPreviewUrl;
