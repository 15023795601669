/* eslint-disable import/prefer-default-export */
import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const RootWrapper = styled('div')`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.palette.dina.dividerLight};
    background: ${theme.palette.dina.backgroundResting};
  `}
  display: flex;
  height: 44px;
  width: 100%;
  align-items: center;
  overflow: hidden;
  justify-content: flex-end;
  gap: 8px;
  padding-right: 8px;
`;
