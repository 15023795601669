import { memo, useRef, useState } from 'react';
import { ScopeProvider } from 'jotai-molecules';

import AdvancedDialog from 'components/advancedDialog';
import Warning from 'components/instanceCard/components/warning';
import Popover from 'components/popover';
import useGetUser from 'hooks/useGetUser';
import useVersionHistory from 'hooks/useVersionHistory';
import { EditorValue } from 'types';
import { MemberTypeEnum } from 'types/graphqlTypes';

import DialogBody from './components/dialogBody';
import DialogFooter from './components/dialogFooter';
import { VersionHistoryScope } from './atoms';

interface VersionHistoryProps {
  id: string;
  title: string;
  open: boolean;
  lockedByUser?: string;
  isSavingContent: boolean;
  versionElement?:
    | MemberTypeEnum.Story
    | MemberTypeEnum.Instance
    | MemberTypeEnum.Note
    | MemberTypeEnum.DailyNote;
  restricted?: boolean;
  onCancel: () => void;
  onOk: (content: EditorValue) => Promise<void> | void;
  checkVersionRestorability: () => Promise<boolean> | boolean;
}

function VersionHistory({
  open = false,
  title = 'Instance Version History',
  id,
  onCancel,
  onOk,
  checkVersionRestorability,
  lockedByUser,
  isSavingContent,
  versionElement = MemberTypeEnum.Instance,
  restricted,
}: Readonly<VersionHistoryProps>) {
  const { getUserTitle } = useGetUser();
  const [showWarning, setShowWarning] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isProcessingSubmit, setIsProcessingSubmit] = useState(isSavingContent);
  const rootRef = useRef(null);

  const { versions, versionsLoading } = useVersionHistory(id);

  const handleOnOk = async (newContent: EditorValue) => {
    setIsProcessingSubmit(true);
    const versionRestorable = await checkVersionRestorability();
    if (versionRestorable) {
      await onOk(newContent);
    } else {
      setAnchorEl(rootRef.current);
      setShowWarning(true);
    }
    setIsProcessingSubmit(false);
  };

  const handleHideWarning = () => setAnchorEl(null);

  return (
    <div ref={rootRef}>
      <ScopeProvider scope={VersionHistoryScope} uniqueValue={true}>
        <AdvancedDialog
          isOpen={open}
          onClose={onCancel}
          initialWidth="45%"
          initialHeight="60%"
          minWidth={840}
          minHeight={640}
          subtitle="version history"
          itemId={id}
          title={title}
          footerComponent={
            <DialogFooter
              onCancel={onCancel}
              onOk={handleOnOk}
              isProcessingSubmit={isProcessingSubmit}
              restricted={restricted}
            />
          }
        >
          <DialogBody isMenuLoading={versionsLoading} versions={versions} />
        </AdvancedDialog>
      </ScopeProvider>
      {showWarning && (
        <Popover anchorEl={anchorEl} onClose={handleHideWarning} position="center">
          <div>
            <Warning
              title={getUserTitle(lockedByUser ?? '')}
              onClose={handleHideWarning}
              onConfirm={handleHideWarning}
              variant={`${versionElement}Locked`}
            />
          </div>
        </Popover>
      )}
    </div>
  );
}

export default memo(VersionHistory);
