import gql from 'graphql-tag';

export default gql`
  subscription notifyBreakingFeed {
    notifyBreakingFeedSubscription {
      mId
      mRefId
      mTitle
      provider
      mPublishingAt
    }
  }
`;
