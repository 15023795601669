import React from 'react';
import PropTypes from 'prop-types';
import { ICON_INTERNALS } from 'assets/icons/icons';
import { useTheme } from '@emotion/react';

const Icon = (props) => {
  const theme = useTheme();
  const {
    icon,
    color = theme.palette.dina.iconInactive,
    hoverColor = theme.palette.dina.iconActive,
    hovered,
    width,
    height,
    onClick,
    style,
    ...rest
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={ICON_INTERNALS[icon].viewBox}
      onClick={onClick}
      style={style}
      className="skipOverride"
      {...rest}
    >
      <path
        d={ICON_INTERNALS[icon].path}
        fillRule={ICON_INTERNALS[icon].fillRule}
        fill={hovered ? hoverColor : color}
      />
    </svg>
  );
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  hovered: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.shape({}),
};

Icon.defaultProps = {
  width: 8,
  height: 16,
  color: undefined,
  hoverColor: undefined,
  hovered: false,
  onClick: () => {},
  style: {},
};

export default Icon;
