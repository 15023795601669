import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ReactComponent as PlaceholderIconComponent } from 'assets/icons/systemicons/hourglass.svg';
import transientOptions from 'theme/helpers';

export const MediaWrapper = styled('div')`
  display: flex;
  align-items: center;
  height: 96px;
  width: 100%;
  margin-bottom: 10px;
  gap: 8px;
`;

export const ButtonGroup = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 9px;
`;

export const IconWrapper = styled('div', transientOptions)`
  width: ${({ $mediaWidth }) => `${$mediaWidth}px`};
  height: 88px;
  border: 1px dashed ${({ theme }) => theme.palette.dina.statusApproved};
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PlaceholderIcon = styled(PlaceholderIconComponent, transientOptions)`
  ${({ $active, theme }) =>
    $active &&
    css`
    path {
      fill: ${theme.palette.dina.statusApproved}
      fill-opacity: 1;
    }
  `}
`;

export const ThumbnailWrapper = styled('div')`
  position: relative;
  border-radius: 4px;
  margin-right: 8px;
  overflow: hidden;
`;

export const ActionButtonWithOpenIcon = styled('div')`
  display: flex;
  align-items: center;
  width: 220px;
  gap: 4px;
`;
