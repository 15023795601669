import { gql } from 'graphql-tag';

export default gql`
  query GetAllUsers($input: GetUsersInput) {
    getUsers(input: $input) {
      mId
      mRefId
      mLastLogin
      mTitle
      mMetaData {
        value
        key
      }
      user {
        email
        provider
        status
      }
      metadata
      disabledAt
    }
  }
`;
