import React from 'react';
import { Editor, Element, Node, Range, Transforms } from 'slate';
import { v1 as uuidv1 } from 'uuid';

import { CustomElement, CustomText } from 'types';
import preventDefaultAndPropagation from 'utils/preventDefaultAndStopPropagation';

import isSection from '../components/sectionDivider/utils/isSection';
import { elementTypes } from '../constants';

import { isMdfBlock, isOrderBlock } from './ElementKeyDownUtils';
import isElementActive from './isElementActive';

const { removeNodes, insertNodes } = Transforms;

type CustomChild = CustomElement | CustomText;

const updateItemIds = (nodes: CustomChild[]) =>
  nodes.map((node) => {
    if (!Element.isElement(node) || !node?.data?.itemId) return node;

    const updatedNode = { ...node, data: { ...node.data, itemId: uuidv1() } };

    if (isSection(node)) updatedNode.children = updateItemIds(node.children);

    return updatedNode;
  });

const checkIfStrictEqual = (elm1: CustomChild, elm2: CustomChild) =>
  JSON.stringify(elm1) === JSON.stringify(elm2);

/**
 * Unwraps incomplete section divider
 * @param nodes Editor nodes
 * @param editor SlateJS editor instance
 * @returns unwrapped nodes
 */
const unwrapIncompleteSectionDividers = (nodes: CustomElement[], editor: Editor) => {
  if (nodes?.length === 1) {
    const [node] = nodes;
    const copiedChildrenCount = node?.children?.length;

    if (isSection(node) && copiedChildrenCount) {
      const nodeInEditor = editor.children.find(
        (element) =>
          Element.isElement(element) &&
          isSection(element) &&
          element?.data?.itemId === node?.data?.itemId,
      ) as CustomElement;

      const nodeChildrenCount = nodeInEditor?.children?.length;

      /** if copied data does not have all of the children of the source */
      if (nodeChildrenCount !== copiedChildrenCount) return [...node.children];

      /** Unwrap data if first and last child does not strictly match the source */
      if (
        !checkIfStrictEqual(nodeInEditor?.children?.[0], node?.children?.[0]) ||
        !checkIfStrictEqual(
          nodeInEditor?.children?.[nodeChildrenCount - 1],
          node?.children?.[copiedChildrenCount - 1],
        )
      )
        return [...node.children];
    }
  }

  return nodes;
};

export const handleRestrictionOnSelection = (
  editor: Editor,
  event: React.ClipboardEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
) => {
  const [match] = Editor.nodes<CustomElement>(editor, {
    match: (node) => Element.isElement(node) && (isOrderBlock(node.type) || isMdfBlock(node.type)),
  });
  if (match) event.preventDefault();
};

/**
 *
 * Paste operation is only handled when itemId of automation items needs to be changed
 * Otherwise slate handles it for us
 *
 */
const onPaste = (event: React.ClipboardEvent<HTMLDivElement>, editor: Editor) => {
  handleRestrictionOnSelection(editor, event);
  const clipboardData = event.clipboardData.getData('application/x-slate-fragment');
  if (!clipboardData) return;

  const decoded = decodeURIComponent(window.atob(clipboardData));
  if (decoded) {
    const parsedData = JSON.parse(decoded) as CustomElement[];
    const shouldChangeItemId = parsedData.find((node) => node?.data?.itemId);

    if (shouldChangeItemId) {
      preventDefaultAndPropagation(event);

      /**
       *
       * Data copied from section-divider will always have itemId.
       *
       * If some (not all) children of a section-divider is copied, the data in the clipboard
       * will be a section-divider with the copied elements as children.
       *
       * We have to unwrap the children if copied data only contains some of the children of that
       * single section-divider. If all of children of the section-divider are copied then the whole
       * section-divider component is pasted.
       *
       */
      const unwrappedNodes = unwrapIncompleteSectionDividers(parsedData, editor);

      const updatedNodes = updateItemIds(unwrappedNodes);

      const { selection } = editor;
      if (selection && Range.isCollapsed(selection)) {
        const { path } = selection.anchor;
        const newLocation = [path[0]];

        const node = Node.parent(editor, path) as CustomElement;

        if (Editor.isVoid(editor, node)) {
          removeNodes(editor, { at: newLocation });
          insertNodes(editor, updatedNodes, { at: newLocation });
          return;
        }
      }

      Editor.deleteFragment(editor);
      Editor.insertFragment(editor, updatedNodes);
    }

    const isCheckListActive = isElementActive(editor, elementTypes.CHECKLIST);
    if (isCheckListActive) {
      event.preventDefault();
      const updatedNodes = parsedData.map((node) => ({ ...node, type: elementTypes.CHECKLIST }));
      Editor.deleteFragment(editor);
      Editor.insertFragment(editor, updatedNodes);
    }
  }
};

export default onPaste;
