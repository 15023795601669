import { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import { useCanAdministrateSearches } from 'hooks/useCheckUserRight';
import { DeleteSavedSearchInput } from 'types/graphqlTypes';

import { DELETE_SAVED_SEARCH } from './gql';
import { removeFromCache } from './handleCacheUpdate';

interface DeleteSavedSearch {
  deleteSavedSearch: DeleteSavedSearchInput;
}

export const useDeleteSavedSearch = () => {
  const [doDelete] = useMutation<DeleteSavedSearch>(DELETE_SAVED_SEARCH);
  const { canAdministrate } = useCanAdministrateSearches();
  const deleteSavedSearch = useCallback(
    async (id: string, type: 'shared' | 'private') => {
      await doDelete({
        variables: { input: { id, type } },
        update: (proxy) => {
          removeFromCache(proxy, id, canAdministrate);
        },
      });
    },
    [doDelete],
  );

  return { deleteSavedSearch };
};
