import gql from 'graphql-tag';

export default gql`
  subscription updateFeed($filters: String) {
    updateFeedSubscription(filters: $filters) {
      __typename
      provider
      mId
      infosource
      version
      mPublishedAt
      section
      priority
      pubstatus
      ednotes
      newscodes
      mTitle
      byline
      mContent
      mDescription
      location
      embargoed
      href
      copyrightnotice
      usageterms
      storyList
      firstcreated
      versioncreated
      language
      revision
      slugline
      assets {
        duration
        headline
        byline
        type
        renditions {
          href
          uri
          mimetype
          duration
          videoaspectratio
          format
          height
          filename
          width
          duration
          rendition
          sizeinbytes
          fileextension
          orientation
          previewUri
          thumbnailUri
          videoPreviewUri
        }
      }
    }
  }
`;
