/**
 *  Hook for getting a single member
 */
import { useQuery, WatchQueryFetchPolicy } from '@apollo/client';

import { getKeysFromMiniMember } from 'components/mdfEditor/fields/relation/relation-utils';
import memberTypes from 'operations/memberTypes';
import GET_MEMBER from 'operations/queries/getMember';
import GET_INSTANCE from 'operations/queries/getSingleInstance';
import GET_SPACE from 'operations/queries/getSpace';
import { MiniMember } from 'types/forms/forms';
import { GetMemberInput, MemberType } from 'types/graphqlTypes';

const QUERIES = {
  [memberTypes.SPACE]: GET_SPACE,
  [memberTypes.INSTANCE]: GET_INSTANCE,
  default: GET_MEMBER,
};

type GetMemberInputType = {
  input: GetMemberInput;
};

export interface GetMemberProps {
  mId: string;
  mRefId: string;
  type?: string;
  skip?: boolean;
  fetchPolicy?: WatchQueryFetchPolicy;
}

const useGetMember = <T extends MemberType>({
  mId,
  mRefId,
  type = 'default',
  skip = false,
  fetchPolicy = 'cache-and-network',
}: GetMemberProps) => {
  const { data, error, loading, refetch } = useQuery<{ getMember: T }, GetMemberInputType>(
    QUERIES[type],
    {
      variables: {
        input: {
          mId,
          mRefId,
        },
      },
      fetchPolicy,
      skip,
    },
  );

  const member = data?.getMember;

  return { data: member, error, loading, refetch };
};

export const useGetMiniMember = (member: MiniMember | null) => {
  const dataKeys = member ? getKeysFromMiniMember(member) : null;
  const { data, loading, error } = useGetMember({
    mId: dataKeys?.mId ?? '',
    mRefId: dataKeys?.mRefId ?? '',
    skip: !dataKeys,
  });

  return { member: data, loading, error };
};

export default useGetMember;
