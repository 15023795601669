import { useEffect } from 'react';
import { atom, useAtom, useSetAtom } from 'jotai';
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { SpaceViewLayout } from 'features/layouts/layouts';
import { WIDGETS } from 'features/widget/constants';
import { WidgetType } from 'screens/space/types/space';
import { IntegrationType } from 'types/graphqlTypes';
import { FilterValueType } from 'types/widget';

type CallbackType = (
  newVal: (WidgetType | undefined)[],
  prevVal: (WidgetType | undefined)[],
) => void;

/* -------------------------------------------------------------------------- */
/*                                   Widgets                                  */
/* -------------------------------------------------------------------------- */
const widgetsAtom = atom<(WidgetType | undefined)[]>([]);
/** Get and set the widgets */
export const useWidgets = () => useAtom(widgetsAtom);

// Atom with the id of a newly created widget
const newWidgetId = atom<string | null>(null);
export const useNewWidgetId = () => useAtom(newWidgetId);

// Atom that listens to changes
const listenersAtom = atom<CallbackType[]>([]);

// Atom that invokes a callback when listenersAtom is updated
const updateWidgetsAtom = atom(
  (get) => get(widgetsAtom),
  (get, set, val: (WidgetType | undefined)[]) => {
    const prevVal = get(widgetsAtom);
    set(widgetsAtom, val);
    const newVal = get(widgetsAtom);
    get(listenersAtom).forEach((callback: CallbackType) => {
      callback(newVal, prevVal);
    });
  },
);

/* -------------------------------------------------------------------------- */
/*                                   Create                                   */
/* -------------------------------------------------------------------------- */
export type CreateWidgetType = {
  type: WIDGETS;
  config?: IntegrationType;
  index: number | undefined;
};
const createWidget = atom(null, (get, set, { type, config, index }: CreateWidgetType) => {
  if (!type) return;

  const widgets = get(widgetsAtom) ?? {};

  const newWidget: WidgetType = {
    mId: `widget#${uuidv4()}`,
    mRefId: uuidv4(),
    type,
    title: `${type} widget`,
    config,
  };

  if (type === WIDGETS.FEED) {
    newWidget.providers = [];
    newWidget.filters = [];
  }

  if (typeof index === 'number') {
    const copy = [...widgets];
    copy[index] = newWidget;
    set(updateWidgetsAtom, copy);
  } else {
    const clone = cloneDeep(widgets);
    clone[clone.length] = newWidget;
    set(updateWidgetsAtom, clone);
  }
  set(newWidgetId, newWidget.mId);
});

/** Create a new widget, input { type } */
export const useCreateWidget = () => useSetAtom(createWidget);

/* -------------------------------------------------------------------------- */
/*                                   Delete                                   */
/* -------------------------------------------------------------------------- */
export type DeleteWidgetType = { id: string; layout: SpaceViewLayout };
const deleteWidgetAtom = atom(null, (get, set, { id, layout }: DeleteWidgetType) => {
  if (!id) return;

  const widgets = get(widgetsAtom) ?? [];
  if (layout === 'horizontal') {
    set(
      updateWidgetsAtom,
      widgets.filter((widget) => widget?.mId !== id),
    );
  } else {
    const copy = cloneDeep(widgets);
    const idx = copy.findIndex((a) => a?.mId === id);
    if (idx >= 0) {
      copy[idx] = undefined;
    }
    set(updateWidgetsAtom, copy);
  }
});

/** Delete Widget, input: { id } */
export const useDeleteWidget = () => useSetAtom(deleteWidgetAtom);

/* -------------------------------------------------------------------------- */
/*                                Move widgets                                */
/* -------------------------------------------------------------------------- */
export type MoveWidget = {
  id: string;
  targetIdOrIndex: string | number;
  replace?: boolean;
};

const moveWidget = atom(null, (get, set, { id, targetIdOrIndex, replace = true }: MoveWidget) => {
  const widgets = get(widgetsAtom) ?? {};
  const sourceIdx = widgets.findIndex((w) => w?.mRefId === id);
  const targetIdx =
    typeof targetIdOrIndex === 'number'
      ? targetIdOrIndex
      : widgets.findIndex((w) => w?.mRefId === targetIdOrIndex);
  const sourceWidget = widgets[sourceIdx];

  if (targetIdx < 0 || sourceIdx < 0) return;

  // Replace will swap the positions of the widgets
  if (replace) {
    const updatedOrder = cloneDeep(widgets);
    updatedOrder[sourceIdx] = widgets[targetIdx];
    updatedOrder[targetIdx] = sourceWidget;
    set(updateWidgetsAtom, updatedOrder);
  } else {
    // Move to new index without swapping
    const copy = cloneDeep(widgets).filter((w) => w?.mRefId !== sourceWidget?.mRefId);
    copy.splice(targetIdx, 0, sourceWidget);
    set(updateWidgetsAtom, copy);
  }
});

/** Create a new widget, input { type } */
export const useMoveWidget = () => useSetAtom(moveWidget);

/* -------------------------------------------------------------------------- */
/*                                Update title                                */
/* -------------------------------------------------------------------------- */
const updateWidgetTitle = atom(null, (get, set, { id, title = '' }) => {
  if (!id) return;

  const allWidgets = get(widgetsAtom) ?? {};

  const updatedWidgets = allWidgets.map((widget) => {
    if (widget?.mId === id) return { ...widget, title } as WidgetType;
    return widget;
  });

  set(updateWidgetsAtom, [...updatedWidgets]);
});

/** Update widget title, input: { id, title } */
export const useUpdateWidgetTitle = () => useSetAtom(updateWidgetTitle);

/** Remove empty values from object */
const removeEmpty = (values: FilterValueType) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  Object.keys(values).reduce((acc, k) => (!values[k] && delete acc[k], acc), values);

/* -------------------------------------------------------------------------- */
/*                               Update filters                               */
/* -------------------------------------------------------------------------- */
export type UpdateWidgetFiltersType = { id: string; filters: FilterValueType };
const updateWidgetFilters = atom(null, (get, set, { id, filters }: UpdateWidgetFiltersType) => {
  if (!id) return;

  const allWidgets = get(widgetsAtom);

  const updatedWidgets = allWidgets.map((widget) => {
    if (widget?.mId === id) {
      const prevFilters = widget.filters ?? [];
      const noDupes = prevFilters.filter((f) => f.key !== 'filters');
      return {
        ...widget,
        filters: [...noDupes, { key: 'filters', value: removeEmpty(filters) }],
      };
    }
    return widget;
  });
  set(updateWidgetsAtom, updatedWidgets);
});
/** Update widget filters, input: { id, filters, type }  */
export const useUpdateWidgetFilters = () => useSetAtom(updateWidgetFilters);

/* -------------------------------------------------------------------------- */
/*                           Update widget providers                          */
/* -------------------------------------------------------------------------- */
export type UpdateWidgetProvidersType = {
  id: string;
  providers: { mRefId: string }[];
  type: WIDGETS;
};

const updateWidgetProviders = atom(
  null,
  (get, set, { id, providers = [], type }: UpdateWidgetProvidersType) => {
    if (!id || !type) return;

    const allWidgets = get(widgetsAtom) ?? {};

    const updatedWidgets = allWidgets.map((widget) => {
      if (widget?.mId === id) return { ...widget, providers };
      return widget;
    });

    set(updateWidgetsAtom, updatedWidgets);
  },
);
/** Update widget providers, input: { id, providers, type }  */
export const useUpdateWidgetProviders = () => useSetAtom(updateWidgetProviders);

/* -------------------------------------------------------------------------- */
/*           Callback, invoked when updateFeedWidgetsAtom is updated          */
/* -------------------------------------------------------------------------- */
export function widgetsUpdatedListener() {
  const useListener = (callback: CallbackType) => {
    const setListeners = useSetAtom(listenersAtom);
    useEffect(() => {
      setListeners((prev) => [...prev, callback]);
      return () =>
        setListeners((prev) => {
          const index = prev.indexOf(callback);
          return [...prev.slice(0, index), ...prev.slice(index + 1)];
        });
    }, [setListeners, callback]);
  };
  return [useListener];
}
