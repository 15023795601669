import React from 'react';
import PropTypes from 'prop-types';
import fieldTypes from 'utils/rundown/fieldTypes';

import useSettingsValue from 'hooks/useSettingsValue';
import SettingsContentForm from '../../../../settingsContentForm/SettingsContentForm';

import {
  useSelectedMenuSystemSettings,
  useChangedGeneralSystemSettings,
} from '../../../../../atoms';

const General = (props) => {
  const { systemSettings, categories } = props;
  const { mMetaData } = systemSettings?.generalSettings;
  const [getSettingsValue] = useSettingsValue();
  const [selectedMenu] = useSelectedMenuSystemSettings();
  const [changedGeneralSystemSettings, setChangedGeneralSystemSettings] =
    useChangedGeneralSystemSettings();

  const getValue = (key, type) => {
    const systemMetaData = mMetaData?.find((metaData) => metaData.key === key);
    const value = systemMetaData?.value || getSettingsValue(key) || '';
    if (type === fieldTypes.BOOLEAN) {
      return typeof value === 'boolean' ? value : value?.toLowerCase() === 'true';
    }
    if (type === fieldTypes.NUMBER) {
      return value?.toString();
    }
    return value;
  };

  const onChangeGeneralSettings = (val, contentKey, originalValue) => {
    const changedSetting = changedGeneralSystemSettings.find((x) => x.key === contentKey);
    if (changedSetting) {
      if (changedSetting?.originalValue === val) {
        setChangedGeneralSystemSettings(
          changedGeneralSystemSettings.filter((x) => x.key !== changedSetting.key),
        );
      } else {
        changedSetting.value = val;
      }
    } else {
      setChangedGeneralSystemSettings([
        ...changedGeneralSystemSettings,
        {
          key: contentKey,
          value: val,
          originalValue,
        },
      ]);
    }
  };

  return (
    <SettingsContentForm
      systemSettings={systemSettings}
      categories={categories}
      onChangeSettings={onChangeGeneralSettings}
      changedSettings={changedGeneralSystemSettings}
      getValue={getValue}
      selectedMenu={selectedMenu}
    />
  );
};

General.propTypes = {
  systemSettings: PropTypes.object,
  categories: PropTypes.array,
};

General.defaultProps = {
  systemSettings: {},
  categories: [],
};

export default General;
