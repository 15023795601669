import gql from 'graphql-tag';

export default gql`
  mutation CreateRundownFromTemplate($input: CreateRundownInput) {
    createRundownFromTemplate(input: $input) {
      mId
      mRefId
      mTitle
      mPublishingAt
      mPlannedDuration
      mThumbnailUrl
      mThumbnailKey
      mRundownTemplateId
      mType
      mState
      version
      platformKind
      recurrence {
        duration
        startTime
        timeZone
        dailyExclusive
      }
    }
  }
`;
