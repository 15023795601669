import { keyBy } from 'lodash';

import { isInstance, isPitch, isStory } from 'features/storyHub/utils';
import { Mdf, MemberType, MemberTypeEnum } from 'types/graphqlTypes';
import {
  MTypeToMdfId,
  ParsedMemberType,
  PlatformAccount,
  SupportedMetadataTypes,
} from 'types/members';

export const getMdfByMTypeFromKeyedMdfs = (
  item: MemberType | ParsedMemberType,
  mdfsKeyed: Record<string, Mdf | undefined>,
) => {
  const mType = item.mType ?? '';
  if (!mType) return;
  if (isStory(mType)) return mdfsKeyed[MemberTypeEnum.Story];
  if (isPitch(mType)) return mdfsKeyed[MemberTypeEnum.Pitch];

  const mdfIdFromType = MTypeToMdfId[mType as SupportedMetadataTypes];
  if (mdfIdFromType) return mdfsKeyed[mdfIdFromType];

  if (isInstance(mType)) {
    const mProperties = item.mProperties;
    if (!mProperties) return;
    if (mProperties.platformKind === 'audio') return mdfsKeyed['linear-audio'];

    if (mProperties.platformKind) {
      const platformKindMdf = mdfsKeyed[mProperties.platformKind as string];
      if (platformKindMdf) return platformKindMdf;
    }

    const accountId = (mProperties.account as PlatformAccount)?.accountId ?? '';
    if (accountId) {
      const accountMdf = mdfsKeyed[accountId];
      if (accountMdf) return accountMdf;
    }
    if (mProperties.platform) return mdfsKeyed[mProperties.platform as string];
  }
  return undefined;
};

export const getMdfByMTypeFromMdfs = (
  item: MemberType | ParsedMemberType,
  mdfs: Mdf[],
): Mdf | undefined => {
  const keyedMdf = keyBy(mdfs, (m) => m.id);
  return getMdfByMTypeFromKeyedMdfs(item, keyedMdf);
};
