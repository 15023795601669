import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Box } from 'layouts/box/Box';
import { TextareaAutosize } from 'lib/textarea';
import transientOptions from 'theme/helpers';

export const QuoteBlockWrapper = styled(Box, transientOptions)<{ $isOver?: boolean }>`
  width: 100%;
  position: relative;
  max-width: 430px;
  padding: 7px 7px 3px 19px;
  border: 1px ${({ $isOver }) => ($isOver ? 'dashed transparent' : 'solid transparent')};
  border-radius: 4px;
  border-color: ${({ theme, $isOver }) =>
    $isOver ? theme.palette.dina.mediumEmphasis : theme.palette.dina.inputBorderResting};
  &:hover {
    border-color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
    svg path {
      fill-opacity: 1 !important;
    }
  }
`;

export const QuoteInput = styled(TextareaAutosize)<{ $locked: boolean }>`
  ${({ theme }) => theme.typography.dina.body2Italic};
  font-size: 16px !important;
  line-height: 23px;
  outline: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  margin-left: 6px;
  resize: none;
  width: 100%;
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
  ${({ $locked, theme }) =>
    !$locked &&
    css`
      :focus {
        border: 1px solid ${theme.palette.dina.onFocus};
        background-color: ${theme.palette.dina.blackHoverOverlay};
      }
    `}
  ::placeholder {
    color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  }
`;

export const BylineInput = styled(TextareaAutosize)<{ $locked: boolean }>`
  ${({ theme }) => theme.typography.dina.captionItalic};
  max-width: 200px;
  font-weight: 200;
  text-align: right;
  outline: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  margin-left: 6px;
  resize: none;
  width: 100%;
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
  ${({ $locked, theme }) =>
    !$locked &&
    css`
      :focus {
        border: 1px solid ${theme.palette.dina.onFocus};
        background-color: ${theme.palette.dina.blackHoverOverlay};
      }
    `}
  ::placeholder {
    color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  }
`;

export const ContactDrop = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  * {
    margin: auto;
  }
`;
