import React from 'react';

import { ReactComponent as FallbackIcon } from 'assets/icons/systemicons/info_off.svg';
import Text, { TextProps } from 'components/text/Text';
import { ColorVariants } from 'types';

import { Root } from './styled';

type Colors = keyof ColorVariants;

interface RootProps extends TextProps {
  children: React.ReactNode;
  background?: Colors;
  border?: Colors;
  icon?: React.ReactNode;
}

export default function Infobar({
  variant = 'listItemLabel',
  color = 'highEmphasis',
  background = 'infoBackground',
  border = 'infoBorder',
  icon,
  children,
}: RootProps) {
  return (
    <Root bg={background} border={border}>
      <span className="infobarIcon">{icon ?? <FallbackIcon />}</span>
      <Text as="div" variant={variant} color={color}>
        {children}
      </Text>
    </Root>
  );
}
