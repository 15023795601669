import { useCallback, useContext, useRef, useState } from 'react';

import { ReactComponent as ConvertIcon } from 'assets/icons/systemicons/convert.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/systemicons/copy.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/systemicons/warning.svg';
import { DialogTwoStepButton } from 'components/dialogs/components/Parts';
import NotificationPopup, { notificationIdentifiers } from 'components/notificationPopup';
import useToast from 'components/toast/useToast';
import USER_CONTEXT from 'contexts/UserContext';
import useCreateStoryFromPitch from 'hooks/useCreateStoryFromPitch';
import useDinaNavigate from 'hooks/useDinaNavigate';
import useSettingsValue from 'hooks/useSettingsValue';
import useTabs from 'hooks/useTabs';
import { Flex } from 'layouts/box/Box';
import { Story } from 'types';

const duplicatePitchToStoryValues = {
  label1: 'Duplicate as Story',
  label2: 'Confirm Duplicate',
  popupTitle: 'Duplicate Pitch as Story',
  popupBody:
    // eslint-disable-next-line max-len
    'Duplicating the pitch as a story will retain the original pitch. The story will be linked to the original pitch for reference',
  icon1: <CopyIcon />,
  icon2: <WarningIcon />,
};

const convertPitchToStoryValues = {
  label1: 'Convert to Story',
  label2: 'Confirm Convert',
  popupTitle: 'Convert Pitch to Story',
  popupBody:
    // eslint-disable-next-line max-len
    'Converting the pitch to a story will remove the original pitch. Once converted, there will be no reference to the original pitch.',
  icon1: <ConvertIcon />,
  icon2: <WarningIcon />,
};

interface ButtonsProps {
  story: Story;
}

const Buttons = ({ story }: ButtonsProps) => {
  const popupRef = useRef(null);
  const { mId: userId } = useContext(USER_CONTEXT);
  const { navigateTo } = useDinaNavigate();
  const { updateTab } = useTabs();
  const createStoryFromPitch = useCreateStoryFromPitch();

  const { errorToast } = useToast();
  const [getSettingsValue] = useSettingsValue();
  const isConvertToStory = getSettingsValue('app.convertPitchToStory') === 'true';

  const [creatingStory, setCreatingStory] = useState(false);

  const createPitchToStory = useCallback(async () => {
    setCreatingStory(true);
    const storyId = (await createStoryFromPitch(
      story?.mId,
      userId,
      story?.mPublishingAt,
      isConvertToStory,
    ).catch((err: unknown) => {
      errorToast(err);
    })) as string;

    setCreatingStory(false);
    if (isConvertToStory)
      updateTab({
        id: storyId,
        type: 'story',
      });
    navigateTo('story', storyId);
  }, [
    navigateTo,
    setCreatingStory,
    createStoryFromPitch,
    story?.mId,
    story?.mPublishingAt,
    story?.mTitle,
    userId,
  ]);

  return (
    <Flex ref={popupRef}>
      <NotificationPopup
        position="bottom"
        anchor={popupRef}
        id={isConvertToStory ? notificationIdentifiers.Convert : notificationIdentifiers.Duplicate}
        title={
          isConvertToStory
            ? convertPitchToStoryValues.popupTitle
            : duplicatePitchToStoryValues.popupTitle
        }
        text={
          isConvertToStory
            ? convertPitchToStoryValues.popupBody
            : duplicatePitchToStoryValues.popupBody
        }
      />
      <DialogTwoStepButton
        onConfirm={createPitchToStory}
        usage1={'story'}
        usage2={isConvertToStory ? 'danger' : 'warning'}
        label1={
          isConvertToStory ? convertPitchToStoryValues.label1 : duplicatePitchToStoryValues.label1
        }
        label2={
          isConvertToStory ? convertPitchToStoryValues.label2 : duplicatePitchToStoryValues.label2
        }
        icon1={
          isConvertToStory ? convertPitchToStoryValues.icon1 : duplicatePitchToStoryValues.icon1
        }
        icon2={
          isConvertToStory ? convertPitchToStoryValues.icon2 : duplicatePitchToStoryValues.icon2
        }
        disabled={creatingStory}
        loading={creatingStory}
      />
    </Flex>
  );
};

export default Buttons;
