import { useCallback, useState } from 'react';

const useUploadProgress = () => {
  const [uploadProgress, setUploadProgress] = useState(0);

  const setUploadStatus = useCallback((progressEvent) => {
    const currentProgress = (progressEvent.loaded / progressEvent.total) * 100;
    Math.trunc(currentProgress) % 10 === 0 && setUploadProgress(currentProgress);
    if (progressEvent.loaded === progressEvent.total) {
      setTimeout(() => setUploadProgress(0), 2000);
    }
  }, []);

  const isUploading = uploadProgress > 0;

  return [uploadProgress, setUploadStatus, isUploading, setUploadProgress];
};

export default useUploadProgress;
