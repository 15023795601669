import { memo, useEffect, useState } from 'react';

import { StyledTextField } from 'components/mdfEditor/fields/text/styled';
import Text from 'components/text/Text';

import { FilterComponentType, FilterProps } from './types';

function IframeFilters({ filters = {}, updateFilters }: Readonly<FilterProps>): JSX.Element {
  const { url } = filters || { url: '' };
  const [liveValue, setLiveValue] = useState('');

  useEffect(() => {
    if (url) {
      setLiveValue(url as string);
    }
  }, [filters]);

  const onUrlChange = (val: string) => {
    updateFilters({
      ...filters,
      url: val,
    });
  };

  return (
    <>
      <Text variant="overline" style={{ userSelect: 'none' }}>
        URL
      </Text>
      <StyledTextField
        variant="filled"
        fullWidth
        placeholder="Enter url"
        onChange={(ev) => setLiveValue(ev.target.value)}
        value={liveValue}
        inputProps={{
          onBlur: () => {
            onUrlChange(liveValue);
          },
        }}
      />
    </>
  );
}

export const FilterComponent: FilterComponentType = memo(IframeFilters) as FilterComponentType;
