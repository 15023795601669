import styled from '@emotion/styled/macro';

import transientOptions from 'theme/helpers';
import pitchBg from 'assets/images/pitchBackground.svg';

export const StoryItem = styled('li', transientOptions)`
  user-select: none;
  cursor: pointer;
  background-image: ${({ $isPitch, $selected }) => $isPitch && !$selected && `url(${pitchBg})`};
  background-color: ${({ $selected, theme }) =>
    $selected ? theme.palette.dina.onFocus : 'transparent'};
  :hover {
    background-color: ${({ $selected, theme }) =>
      $selected ? theme.palette.dina.onFocus : theme.palette.dina.whiteHoverOverlay};
    filter: brightness(108%);
  }
`;

export const Item = styled('div')`
  display: flex;
  flex: 1 1 auto;
  margin-left: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

export const Thumbnail = styled('img')`
  width: 40px;
  height: 40px;
  margin: 12px 8px 12px 0;
  border-radius: 4px;
  object-fit: cover;
`;

export const PitchThumbnailWrapper = styled('div')`
  width: 40px;
  height: 40px;
  position: relative;
  margin: 12px 8px 12px 0;

  svg {
    height: 24px;
    width: 24px;
    background-color: ${({ theme }) => theme.palette.dina.statusPitch};
    border-radius: 4px;
  }

  img {
    height: 24px;
    width: 24px;
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0;
  }
`;

export const TextWrapper = styled('div')`
  padding-top: 10px;
  padding-right: 14px;
  display: flex;
  flex: 1;
  align-self: flex-start;
  align-items: flex-start;
  justify-content: space-between;
`;

export const Title = styled('p')`
  ${({ theme }) => theme.typography.dina.listItemLabelMedium};
  display: -webkit-box !important;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  margin: 0;
`;

export const Updated = styled('p')`
  ${({ theme }) => theme.typography.dina.caption};
  margin-left: 4px;
  white-space: nowrap;
`;
