import React from 'react';

import { ReactComponent as Chevron } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_right.svg';
import {
  Content,
  Label,
  MenuItem,
  Separator,
  Sub,
  SubContent,
  SubTrigger,
} from 'components/dropdownMenu/styled';
import Text from 'components/text';
import {
  DropdownMenu as Menu,
  DropdownMenuPortal as Portal,
  DropdownMenuTrigger as Trigger,
} from 'lib/dropdown';

interface DropdownMenuProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  onOpenChange?: (val: boolean) => void;
  asChild?: boolean;
  align?: 'center' | 'end' | 'start';
}

interface DropdownMenuSubProps {
  children: React.ReactNode;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  count?: number;
}

function DropdownMenu({ children, onOpenChange }: Readonly<DropdownMenuProps>) {
  return <Menu onOpenChange={onOpenChange}>{children}</Menu>;
}

DropdownMenu.Trigger = function DropdownMenuTrigger({
  children,
  asChild = true,
}: Readonly<DropdownMenuProps>) {
  return <Trigger asChild={asChild}>{children}</Trigger>;
};

DropdownMenu.Portal = function DropdownMenuPortal({ children }: Readonly<DropdownMenuProps>) {
  return <Portal>{children}</Portal>;
};

DropdownMenu.Content = function DropdownMenuContent({
  children,
  align,
  style,
}: Readonly<DropdownMenuProps>) {
  return (
    <Content style={style} align={align ?? 'center'}>
      {children}
    </Content>
  );
};

DropdownMenu.Header = function DropdownMenuHeader({ children }: Readonly<DropdownMenuProps>) {
  return (
    <>
      <Label>{children}</Label>
      <Separator />
    </>
  );
};

DropdownMenu.Separator = function DropdownMenuSeparator() {
  return <Separator horizontalInset={true} />;
};

DropdownMenu.Item = function DropdownMenuItem({
  children,
  onSelect,
}: Readonly<DropdownMenuProps & { onSelect?: (event: Event) => void }>) {
  return <MenuItem onSelect={onSelect}>{children}</MenuItem>;
};

DropdownMenu.Sub = function DropdownMenuSub({
  children,
  ...props
}: Readonly<DropdownMenuSubProps>) {
  return <Sub {...props}>{children}</Sub>;
};

DropdownMenu.SubContent = function DropdownMenuSubContent({
  children,
}: Readonly<DropdownMenuProps>) {
  return <SubContent>{children}</SubContent>;
};

DropdownMenu.SubTrigger = function DropdownMenuSubTrigger({
  children,
  count,
}: Readonly<DropdownMenuSubProps>) {
  return (
    <SubTrigger>
      {children}
      <span className="chevron">
        {count && <Text variant="caption">{count}</Text>}
        <Chevron />
      </span>
    </SubTrigger>
  );
};

DropdownMenu.Footer = function DropdownMenuFooter({ children }: Readonly<DropdownMenuProps>) {
  return (
    <>
      <Separator />
      {children}
    </>
  );
};

export default DropdownMenu;
