import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { Typography } from '@material-ui/core';
import { Button } from 'components/buttons';

import { ReactComponent as CalendarOff } from 'assets/icons/systemicons/calendar_off.svg';
import { ReactComponent as CalendarOn } from 'assets/icons/systemicons/calendar_on.svg';
import transientOptions from 'theme/helpers';

const commonLabelStyle = ({ theme }) => css`
  ${theme.typography.dina.button};
  font-weight: normal;
  line-height: 14px;
`;

export const StyledButton = styled(Button)`
  ${({ $dark, theme }) =>
    $dark
      ? css`
          border-color: ${theme.palette.dina.whiteButtonBorderOutlined};
          :hover {
            border-color: ${theme.palette.dina.whiteButtonBorderOutlined};
            background-color: ${theme.palette.dina.whiteHoverOverlay};
          }
          ${Label} {
            color: ${theme.palette.dina.whiteHighEmphasis};
          }
          ${Icon},
          ${IconOff} {
            path {
              fill: ${theme.palette.dina.whiteHighEmphasis};
            }
          }
        `
      : ''}
`;
StyledButton.defaultProps = {
  variant: 'outlined',
  usage: 'text',
  height: 32,
};

export const Icon = styled(CalendarOn, transientOptions)`
  margin-right: 4px;
  margin-left: 8px;
  ${({ $dimmed }) =>
    !$dimmed &&
    css`
      path {
        fill-opacity: 1 !important;
      }
    `}
`;

export const IconOff = styled(CalendarOff)`
  margin-right: 4px;
  margin-left: 8px;
`;

export const Label = styled(Typography, transientOptions)`
  ${commonLabelStyle};
  color: ${({ $dimmedText }) =>
    $dimmedText
      ? ({ theme }) => theme.palette.dina.mediumEmphasis
      : ({ theme }) => theme.palette.dina.highEmphasis};
  white-space: nowrap;
`;

export const CalendarContainer = styled('div')`
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  & * {
    pointer-events: auto;
  }
`;
