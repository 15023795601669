import gql from 'graphql-tag';

export default gql`
  mutation DeleteContact(
    $deleteMember: DeleteMemberInput
    $deleteSingleMember: DeleteSingleMemberInput
  ) {
    deleteMember: deleteMember(input: $deleteMember) {
      mId
      mRefId
    }

    deleteSingleMember: deleteSingleMember(input: $deleteSingleMember) {
      mId
      mRefId
    }
  }
`;