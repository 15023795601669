import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Scrollbar from 'components/scrollbar';
import { RootWrapper, Months, ButtonWrapper } from './styled';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const MonthSelector = ({ month, onMonthSelect }) => {
  useEffect(() => {
    const el = document.getElementById(`ms-${months[month]}`);
    el?.scrollIntoView({ block: 'nearest' });
  }, [month]);

  return (
    <RootWrapper>
      <Scrollbar top={5} bottom={5}>
        <Months>
          {months.map((m, index) => (
            <ButtonWrapper
              id={`ms-${m}`}
              key={m}
              $selected={month === index}
              onClick={() => {
                onMonthSelect(index);
              }}
            >
              {m}
            </ButtonWrapper>
          ))}
        </Months>
      </Scrollbar>
    </RootWrapper>
  );
};

MonthSelector.propTypes = {
  month: PropTypes.number,
  onMonthSelect: PropTypes.func,
};

MonthSelector.defaultProps = {
  month: new Date().getMonth(),
  onMonthSelect: () => {},
};

export default MonthSelector;
