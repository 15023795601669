import styled from '@emotion/styled';
import { css } from '@emotion/react';

const fullWidthShadow = ({ fullWidth, theme }) =>
  fullWidth &&
  css`
    box-shadow: ${theme === 'light'
      ? `0px 0px 1px rgba(0, 0, 0, 0.15), 0px 1px 1px rgba(0, 0, 0, 0.15),
          0px 1px 3px rgba(0, 0, 0, 0.1)`
      : `0px 0px 1px rgba(0, 0, 0, 0.25), 0px 1px 1px rgba(0, 0, 0, 0.25),
          0px 1px 3px rgba(0, 0, 0, 0.2)`};
    :hover {
      box-shadow: 0 0 0 2px ${theme.palette.dina.statusOnFocused};
    }
  `;

const showHighLightStyles = ({ showHighlight, fullWidth, theme }) =>
  showHighlight &&
  !fullWidth &&
  css`
    box-shadow: ${showHighlight
      ? `0 0 0 2px ${theme.palette.dina.statusOnFocused}`
      : `0 0 0 1px ${theme.palette.dina.borderResting}`};
    background: ${showHighlight
      ? theme.palette.dina.backgroundSelected
      : theme.palette.dina.blackHoverOverlay};
    :hover {
      box-shadow: ${showHighlight
        ? `0 0 0 2px ${theme.palette.dina.statusOnFocused}`
        : `0 0 0 1px ${theme.palette.dina.blockBorderHover}`};
      background: ${showHighlight
        ? theme.palette.dina.backgroundSelected
        : theme.palette.dina.backgroundHover};
    }
  `;

export const Root = styled('div')`
  display: flex;
  flex-direction: row;
  min-width: 260px;
  max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '460px')};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  height: 56px;
  border-radius: 6px;
  border: 0.5px solid ${({ theme }) => theme.palette.dina.buttonBorderOutlined};
  cursor: pointer;
  user-select: none;
  transition: transform 0.2s;
  :hover {
    transform: scale(1.02);
  }
  ${showHighLightStyles};
  ${fullWidthShadow};
`;

export const DocumentThumbWrapper = styled('span')`
  display: flex;
  justify-content: center;
  height: 100%;
`;

export const DocImage = styled('img')`
  width: 20px;
`;

export const ThumbnailWrapper = styled('span')`
  height: 100%;
  width: 100px;
  position: relative;
  overflow: hidden;
  border-radius: 6px 0 0 6px;
`;

const thumbnailStyle = css`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const Image = styled('img')`
  ${thumbnailStyle}
`;

export const Video = styled('video')`
  ${thumbnailStyle}
`;

export const PlayIconWrapper = styled('span')`
  position: absolute;
  left: 50%;
  top: 50%;
  background-color: ${({ theme }) => theme.palette.dina.backgroundResting};
  height: 24px;
  width: 24px;
  backdrop-filter: blur(15px);
  border-radius: 50%;
  transform: translate3d(-50%, -50%, 0);
`;

export const ClipIconWrapper = styled('span')`
  height: 20px;
  width: 20px;
  background-color: ${({ theme }) => theme.palette.dina.blackInactive};
  position: absolute;
  left: 4px;
  bottom: 4px;
  text-align: center;
  border-radius: 4px;
`;
export const DurationWrapper = styled('span')`
  height: 20px;
  min-width: 46px;
  background-color: ${({ theme }) => theme.palette.dina.blackInactive};
  position: absolute;
  right: 4px;
  bottom: 4px;
  text-align: center;
  padding: 2px 4px;
  border-radius: 4px;
`;

export const Duration = styled('p')`
  ${({ theme }) => css`
    ${theme.typography.dina.caption};
    color: ${theme.palette.dina.highEmphasis};
  `}
  margin: 0;
`;

export const MetadataWrapper = styled('span')`
  flex: 1;
  min-width: 0;
  margin: 6.5px 40px 0 8px;
`;

const textOverflowStyle = css`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
`;

export const Filename = styled('p')`
  ${({ fullWidth, theme }) => css`
    ${theme.typography.dina.listItemLabel};
    color: ${fullWidth ? theme.palette.dina.blackHighEmphasis : theme.palette.dina.highEmphasis};
  `}
  ${textOverflowStyle};
  margin: 0;
`;

export const Byline = styled('p')`
  ${({ fullWidth, theme }) => css`
    ${theme.typography.dina.captionMedium};
    color: ${fullWidth
      ? theme.palette.dina.blackMediumEmphasis
      : theme.palette.dina.mediumEmphasis};
  `}
  ${textOverflowStyle};
  -webkit-line-clamp: 1;
  margin: 0;
`;
