/* eslint-disable import/no-extraneous-dependencies */
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import uniqBy from 'lodash/uniqBy';

const FindByProp = (array, prop, comparator) => array.find((item) => item[prop] === comparator);

const getSpecifierString = (array) => array.join('-');

const returnField = (form, viewType, gridViewId) => {
  const { defaultViews, views, fields } = form;
  if (!defaultViews) {
    return [];
  }

  const searchedView = defaultViews.find((view) => view.type === viewType);
  if (!searchedView || searchedView.defaultView === 'empty') return [];

  const view = FindByProp(views, 'id', gridViewId || searchedView.defaultView);
  if (!view) return [];

  const selectedView = FindByProp(view.columndefs, 'variant', searchedView.variant);

  if (!selectedView || !selectedView.columns) return [];

  const parameterFields = [];

  const newFields = selectedView.columns.map((column) => {
    const searchedField = FindByProp(fields, 'id', column.id);
    if (searchedField && searchedField.parameters)
      searchedField.parameters.forEach((parameter) => {
        parameterFields.push(FindByProp(fields, 'id', parameter.id));
      });
    return {
      ...searchedField,
      ...omitBy(column, isNil),
    };
  });

  return [newFields, uniqBy(parameterFields, 'id')];
};

/**
 *
 * @param {Object } form form specification from the backend
 * @param {Object } spec consists {target, entity, type} to search views according to spec
 *
 * return array of view according to spec
 */

const getFormBySpec = (form, spec) => {
  if (!form) return [[], []];
  const { target, entity, type, gridViewId } = spec;
  const specArray = [target, entity, type].filter(Boolean);
  const viewExist = gridViewId ? form.views.find((view) => view.id === gridViewId) : true;
  const viewId = viewExist ? gridViewId : 'rundown-grid-1';
  const arrayLength = specArray.length;
  let newFields = [];
  for (let i = 0; i < arrayLength; i += 1) {
    newFields = returnField(form, getSpecifierString(specArray), viewId);
    if (newFields.length > 0) break;
    specArray.shift();
  }

  return newFields;
};

export default getFormBySpec;
