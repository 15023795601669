import GET_INSTANCE from 'operations/queries/getInstance';
import { getStoryInstancesQuery } from 'operations/queryVariables';
import rootQueryChecker from 'utils/rootQueryChecker';
import memberTypes from 'operations/memberTypes';

const getUpdatedList = (list, item) => {
  const memberUpdated = item;
  const updatedList = [...list];
  if (item.crudAction === 'INSERT') {
    memberUpdated.__typename = 'MemberType';
    const memberExist = updatedList.filter((m) => m.mId === item.mId);
    if (memberExist.length === 0) {
      updatedList.push(memberUpdated);
    }
    return updatedList;
  }
  if (item.crudAction === 'REMOVE') {
    return updatedList.filter((m) => m.mId !== item.mId);
  }
  return updatedList;
};

const updateCache = (proxy, item, query, variables) => {
  try {
    const isQueryInCache = rootQueryChecker.checkIfQueryInRootQuery(
      proxy,
      'getMembersOfTypeBySecId',
      [
        { key: 'mSecId', value: item.storyId },
        { key: 'mType', value: memberTypes.INSTANCE },
      ],
    );
    if (!isQueryInCache) {
      return;
    }
    const list = proxy.readQuery({
      query,
      variables,
    });

    // Write updated member list in the cache.
    proxy.writeQuery({
      query,
      variables,
      data: {
        getMembersOfTypeBySecId: getUpdatedList(list.getMembersOfTypeBySecId, item),
      },
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

const updateInstanceToStory = (proxy, item) => {
  try {
    const variables = getStoryInstancesQuery(item.storyId);
    updateCache(proxy, item, GET_INSTANCE, variables);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};

export default updateInstanceToStory;
