import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'components/tooltip';
import { FieldHeader } from '../../styled';
import { TextWrapper, StyledTextField } from './styled';

function TextField({ fieldModel, value, setValue, style }) {
  const [liveValue, setLiveValue] = useState('');

  const { label, id } = fieldModel;

  useEffect(() => {
    setLiveValue(value ?? '');
  }, [value]);

  const onChange = (event) => {
    setLiveValue(event.target.value);
  };

  return (
    <Tooltip title={fieldModel.description ?? ''}>
      <TextWrapper key={id} style={style}>
        <FieldHeader>{label}</FieldHeader>
        <StyledTextField
          variant='filled'
          placeholder='Enter your value here'
          onChange={onChange}
          value={liveValue}
          inputProps={{
            onBlur: () => {
              setValue(liveValue);
            },
          }}
        />
      </TextWrapper>
    </Tooltip>
  );
}

TextField.propTypes = {
  fieldModel: PropTypes.shape({
    type: PropTypes.oneOf(['boolean', 'select', 'text']),
    id: PropTypes.string,
    description: PropTypes.string,
    options: PropTypes.array,
  }),
  value: PropTypes.string,
  setValue: PropTypes.func,
};

TextField.defaultProps = {
  fieldModel: null,
  value: null,
  setValue: () => {},
};

export default TextField;
