/**
 * Stops both synthetic and native event propagation
 *
 * @param {Object} event React synthetic event
 * @returns {Null}
 */

const stopAllPropagation = (event) => {
  event.stopPropagation();
  event.nativeEvent.stopImmediatePropagation();
};

export default stopAllPropagation;
