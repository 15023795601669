import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  switchBase: {
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.dina.statusOnHover,
    },
  },
  track: {
    background: theme.palette.dina.selectionControlTrackInactive,
    opacity: 1,
  },
  thumb: {
    position: 'relative',
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      width: 40,
      height: 40,
      borderRadius: '50%',
      backgroundColor: 'transparent',
      transform: 'translate3d(-25%, -25%, 0)',
      transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
  },
  checked: {
    '& + $track': {
      background: theme.palette.dina.onSelected,
      opacity: 1,
    },
    '&$disabled': {
      '& $thumb': {
        backgroundColor: theme.palette.dina.iconActive,
        boxShadow:
          '0px 1px 3px rgba(0, 0, 0, 0.2),' +
          '0px 2px 2px rgba(0, 0, 0, 0.12),' +
          '0px 0px 2px rgba(0, 0, 0, 0.14)',
      },
      '& + $track': {
        background: theme.palette.dina.selectionControlTrackInactive,
      },
    },
  },
  disabled: {
    '&:not($checked) $thumb': {
      backgroundColor: theme.palette.dina.iconDisabled,
      boxShadow:
        '0px 1px 3px rgba(0, 0, 0, 0.2),' +
        '0px 2px 2px rgba(0, 0, 0, 0.12),' +
        '0px 0px 2px rgba(0, 0, 0, 0.14)',
    },
    '& + $track': {
      background: theme.palette.dina.selectionControlTrackInactive,
    },
  },
}));

export default useStyles;
