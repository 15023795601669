import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CellContext } from '@tanstack/react-table';

import { Input } from 'components/input/Base';
import { ParsedMemberType } from 'types/members';

type TitleCellProps = Pick<CellContext<ParsedMemberType, string>, 'row' | 'getValue'> & {
  updateTitle?: (block: { mId: string; mRefId: string }, updatedTitle: string) => void;
};

export default function EditTitleCell({ getValue, updateTitle, row }: Readonly<TitleCellProps>) {
  const [localTitle, setLocalTitle] = useState(getValue());

  const inputRef = useRef<HTMLInputElement | null>(null);

  // Used to skip update from a blur if the user escapes out
  const canSaveRef = useRef(true);

  useEffect(() => {
    const title = getValue() ?? '';
    if (title !== localTitle) {
      setLocalTitle(title);
    }
  }, [getValue]);

  const doUpdateTitle = useCallback(
    (title: string) => {
      if (canSaveRef.current && title !== getValue()) {
        updateTitle?.({ mId: row.original.mId, mRefId: row.original.mRefId! }, title);
      } else {
        canSaveRef.current = true;
      }
    },
    [updateTitle, row.original],
  );

  const handleKeyDown = useCallback(
    (ev: React.KeyboardEvent<HTMLInputElement>) => {
      if (ev.key === 'Enter') {
        canSaveRef.current = true;
        inputRef.current?.blur();
      } else if (ev.key === 'Escape') {
        canSaveRef.current = false;
        setLocalTitle(getValue());
        inputRef.current?.blur();
      }
    },
    [localTitle],
  );

  return (
    <Input
      ref={inputRef}
      value={localTitle}
      onChange={(ev) => setLocalTitle(ev.target.value)}
      onBlur={() => doUpdateTitle(localTitle)}
      onKeyDown={handleKeyDown}
    />
  );
}
