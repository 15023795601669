import { View, Text } from '@react-pdf/renderer';
import format from 'date-fns/format';

import { styles } from '../styles';

const CommonDocFooter = () => {
  return (
    <View style={[styles.footer]} fixed>
      <View style={[styles.horizontalLine, { marginBottom: 4 }]} />
      <View style={styles.footerContent}>
        <Text>{format(new Date(), 'MMM D YYYY, HH:mm:ss (Z)')}</Text>
        <Text render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`} />
      </View>
    </View>
  );
};

export default CommonDocFooter;
