import styled from '@emotion/styled/macro';

export const SideBarWrapper = styled('div')<{ height: number }>`
  height: ${({ height }) => `${height}px`};
  overflow: auto;
`;

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const FormsWrapper = styled('div')`
  width: 100%;
  height: 100%;
`;
