import React, { useState, memo } from 'react';

import useGetSignedUrl from 'hooks/useGetSignedUrl';
import { onImageLoadingError } from 'utils/image/imageUtils';
import { getThumbnailKey } from 'utils/mediaKey';

import MediaDialog from 'components/editor/components/primaryAutomation/components/mediaDialog';

import { ReactComponent as PlayIcon } from 'assets/icons/systemicons/Play_WithCircleBackground.svg';

import { Image, PlayIconWrapper } from './styled';

const GalleryItemImage = ({ mId, mRefId, proxy = '', itemType }) => {
  const key = getThumbnailKey(mId, mRefId);
  const { url: imageData, loading } = useGetSignedUrl(key, !key);

  const [open, setOpen] = useState(false);

  const openMediaDialog = () => setOpen(true);
  const closeMediaDialog = () => setOpen(false);

  const imageSrc = key ? imageData : proxy;

  return (
    <>
      <Image
        height="66px"
        width="66px"
        src={loading ? '' : imageSrc}
        alt="alternative"
        onError={onImageLoadingError}
      />

      <PlayIconWrapper onClick={openMediaDialog} $isClickable={Boolean(itemType)}>
        {itemType === 'video' ? <PlayIcon /> : null}
      </PlayIconWrapper>
      {itemType ? (
        <MediaDialog asset={{ mId, mRefId, itemType }} open={open} onClose={closeMediaDialog} />
      ) : null}
    </>
  );
};

export default memo(GalleryItemImage);
