import styled from '@emotion/styled/macro';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';

import Scrollbar from 'components/scrollbar';

export const StyledTabIconWrapper = styled('div')`
  margin-right: 10px;
  width: 24px;
  height: 24px;
  svg {
    width: 24px;
    height: 24px;
    path {
      fill: ${({ theme }) => theme.palette.dina.iconActive};
      fill-opacity: 0.54;
    }
  }
`;

export const StyledIconButton = styled(IconButton)`
  width: 24px;
  height: 24px;
  padding: 0;
  display: none;
  svg {
    path {
      fill: ${({ theme }) => theme.palette.dina.iconActive};
      fill-opacity: 0.54;
    }
  }
  &:hover {
    svg {
      path {
        fill-opacity: 1;
      }
    }
  }
`;

export const StyledDropdownTabsWrapper = styled(Scrollbar)`
  display: inherit;
  flex-direction: inherit;
  width: inherit;
  max-height: 350px;
  overflow-y: auto;

  > div:nth-of-type(1) {
    position: relative !important;
  }
`;

export const StyledDropdownListItemLabel = styled('span')`
  flex: 1;
  ${({ theme }) => theme.typography.dina.listItemLabel};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledDropdownListItemIcons = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  gap: 8px;
  width: 56px;
  height: 100%;
  svg {
    :first-of-type {
      path {
        fill: ${({ theme }) => theme.palette.dina.iconActive};
        fill-opacity: 0.54;
      }
    }
  }
`;

export const StyledDropdownListItem = styled(ListItem)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  min-height: 40px;
  width: 100%;
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
  padding: 0 1rem;
  :last-of-type {
    border-radius: 0 0 4px 4px;
  }
  :hover {
    background-color: ${({ theme }) => theme.palette.dina.hoverOverlay};
    ${StyledTabIconWrapper} {
      svg {
        path {
          fill: ${({ theme }) => theme.palette.dina.iconActive};
          fill-opacity: 1;
        }
      }
    }
    ${StyledIconButton} {
      display: block;
    }
  }
  &.Mui-disabled {
    opacity: 0.19;
  }
  &.Mui-selected {
    background-color: ${({ theme }) => theme.palette.dina.onSelected};
    ${StyledTabIconWrapper} {
      svg {
        path {
          fill: ${({ theme }) => theme.palette.dina.iconActive};
          fill-opacity: 1;
        }
      }
    }
    ${StyledTabIconWrapper} {
      svg {
        path {
          fill: ${({ theme }) => theme.palette.dina.iconSelectedWhite};
        }
      }
    }
    ${StyledDropdownListItemLabel} {
      color: ${({ theme }) => theme.palette.dina.whiteHighEmphasis};
    }
    ${StyledDropdownListItemIcons} {
      svg {
        :first-of-type {
          path {
            fill: ${({ theme }) => theme.palette.dina.iconSelectedWhite};
          }
        }
      }
    }
    ${StyledIconButton} {
      svg {
        path {
          fill: ${({ theme }) => theme.palette.dina.iconSelectedWhite};
        }
      }
    }
    &:hover {
      background-color: ${({ theme }) => theme.palette.dina.onSelected};
    }
  }
`;
