/* eslint-disable react/prop-types */
import React, { MouseEventHandler, useCallback } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import useGetMember from 'api/useGetMember';
import { ReactComponent as Close } from 'assets/icons/systemicons/close.svg';
import { ReactComponent as Open } from 'assets/icons/systemicons/open_small.svg';
import Avatar, { AvatarVariant } from 'components/avatar/Avatar';
import useOpenMember from 'components/contextMenu/useOpenMember';
import Tooltip from 'components/tooltip';
import { Center } from 'layouts/box/Box';

const MemberWrapper = styled('div')<{ variant: 'grid' | 'form' | 'small' }>`
  cursor: pointer;
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  position: relative;
  gap: 8px;
  background: transparent;
  .member-details {
    font-size: 13px;
  }
  border: 1px solid transparent;
  :hover {
    span {
      color: ${({ theme }) => theme.palette.dina.highEmphasis} !important;
    }
  }
  ${({ variant, theme }) => {
    if (variant === 'grid') {
      return css`
        gap: 4px;
        height: 100%;
        max-width: 100%;
        overflow: hidden;
      `;
    }
    if (variant === 'small') {
      return css`
        gap: 4px;
        height: 16px;
      `;
    }
    if (variant === 'form') {
      return css`
        background: ${theme.palette.dina.buttonBackgroundOutlined};
        border-color: ${theme.palette.dina.inputBorderResting};
        padding-left: 8px;
      `;
    }
  }}

  &:hover .open-member-details {
    display: block;
  }
`;

const Details = styled.div`
  width: 100%;
  display: flex;
  white-space: pre;
  flex-direction: column;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
  div:last-child {
    font-size: 11px;
  }
`;

const CloseIcon = styled(Close)`
  cursor: pointer;
  &:hover path {
    fill-opacity: 1;
  }
  justify-self: end;
  margin-right: 4px;
`;

const Placeholder = styled.div`
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
`;

const StyledOpen = styled(Open)`
  width: 24px;
  cursor: pointer;
  &:hover path {
    fill-opacity: 1;
  }
`;

const OpenWrapper = styled('div')<{ $rightPos: string }>`
  display: none;
  position: absolute;
  border-radius: 3px;
  right: ${({ $rightPos }) => $rightPos};
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1} !important;
`;

interface Props {
  memberId?: string;
  variant: 'grid' | 'form' | 'small';
  style?: React.CSSProperties;
  onClick?: MouseEventHandler;
  onRemoveClick?: MouseEventHandler;
  readOnly?: boolean;
}

const MemberLabel: React.FC<Props> = ({
  memberId,
  variant,
  onClick,
  onRemoveClick,
  style,
  readOnly,
}) => {
  const { data } = useGetMember({
    mId: memberId ?? '',
    mRefId: memberId ?? '',
    fetchPolicy: 'cache-first',
    skip: !memberId,
  });
  const { openItem } = useOpenMember();

  const doOpenItem = useCallback(
    (ev: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      ev.stopPropagation();
      if (data) openItem(data);
    },
    [openItem, data],
  );

  const showClearButton = onRemoveClick && !readOnly;

  return (
    <MemberWrapper onClick={onClick} variant={variant} style={style} className="member-wrapper">
      {data ? (
        <>
          <Avatar
            className="avatar"
            variant={data.mType as AvatarVariant}
            imageKey={data?.mAvatarKey}
            size={variant === 'small' ? 16 : 24}
            title={data.mTitle}
          />
          <Details className="member-details">{data?.mTitle}</Details>
          <Tooltip title="Open details">
            <OpenWrapper
              className="open-member-details"
              $rightPos={showClearButton ? '24px' : '4px'}
            >
              <StyledOpen onClick={doOpenItem} />
            </OpenWrapper>
          </Tooltip>
          {showClearButton && (
            <Tooltip title="Remove assignee">
              <Center>
                <CloseIcon onClick={onRemoveClick} />
              </Center>
            </Tooltip>
          )}
        </>
      ) : (
        <Placeholder>{readOnly ? 'Unknown' : 'Find member'}</Placeholder>
      )}
    </MemberWrapper>
  );
};

export default MemberLabel;
