import {
  MenuItem as MaterialMenuItem,
  ListItemText as MaterialListItemText,
} from '@material-ui/core';
import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import transientOptions from 'theme/helpers';
import { ReactComponent as PinOffIcon } from 'assets/icons/systemicons/pin_off.svg';

export const OptionsWrapper = styled('div')`
  ${({ theme }) => theme.typography.dina.caption}
  display: flex;
  align-items: center;
  justify-content: right;
  text-align: right;
  width: 88px;
  z-index: 999;
`;

export const DisplayWrapper = styled('div')`
  display: none;
  :hover {
    svg {
      path {
        fill-opacity: 1;
      }
    }
  }
`;

export const IconWrapper = styled('div')`
  width: 24px;
  height: 24px;
  margin-right: 12px;
  padding: 0;
  svg {
    path {
      fill: ${({ theme }) => theme.palette.dina.highEmphasis};
    }
  }
`;

export const MenuItem = styled(MaterialMenuItem, transientOptions)`
  max-width: 400px;
  height: 40px;
  opacity: ${(props) => (props.$isMuted ? 0.5 : 1)};
  :hover {
    background-color: ${({ theme }) => theme.palette.dina.hoverOverlay};
    ${DisplayWrapper} {
      display: flex;
    }
  }
`;

export const ListItemText = styled(MaterialListItemText)`
  .MuiListItemText-primary {
    ${({ theme }) => css`
      ${theme.typography.dina.listItemLabel};
      overflow: hidden;
      text-overflow: ellipsis;
    `}
  }
`;

export const PinOff = styled(PinOffIcon)`
  :hover {
    path {
      fill-opacity: 1;
    }
  }
`;
