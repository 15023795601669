import { memo, useCallback } from 'react';
import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns';

import ArrowNavigator from 'components/arrowNavigator';
import { TimeVariant, timeVariants } from 'utils/planningViews';

const FUNCTION_ENUM = {
  [timeVariants.DAY]: {
    increment: addDays,
    decrement: subDays,
  },
  [timeVariants.WEEK]: {
    increment: addWeeks,
    decrement: subWeeks,
  },
  [timeVariants.MONTH]: {
    increment: addMonths,
    decrement: subMonths,
  },
  [timeVariants.YEAR]: {
    increment: addYears,
    decrement: subYears,
  },
};

type TimeNavigatorProps = {
  timeVariant: TimeVariant;
  time?: Date | string;
  onChange: (date: string) => void;
  buttonHeight?: number;
  buttonWidth?: number;
  tooltipTitle?: { decrement?: string; increment?: string; reset?: string };
  disabled?: boolean | { decrement?: boolean; increment?: boolean; reset?: boolean };
  dark?: boolean;
};

function TimeNavigator(props: Readonly<TimeNavigatorProps>) {
  const {
    timeVariant,
    time = new Date().toISOString(),
    onChange,
    buttonHeight = 32,
    buttonWidth = 32,
    tooltipTitle = { reset: 'Today' },
    disabled = false,
    dark = false,
  } = props;

  const handleChange = useCallback(
    (changedTime: Date) => {
      onChange(changedTime.toISOString());
    },
    [onChange],
  );

  const handleReset = useCallback(() => {
    handleChange(new Date());
  }, [handleChange]);

  const handleIncrement = useCallback(() => {
    handleChange(FUNCTION_ENUM[timeVariant].increment(time, 1));
  }, [handleChange, timeVariant, time]);

  const handleDecrement = useCallback(() => {
    handleChange(FUNCTION_ENUM[timeVariant].decrement(time, 1));
  }, [handleChange, timeVariant, time]);

  return (
    <ArrowNavigator
      handleDecrement={handleDecrement}
      handleIncrement={handleIncrement}
      handleReset={handleReset}
      tooltipTitle={tooltipTitle}
      buttonHeight={buttonHeight}
      buttonWidth={buttonWidth}
      disabled={disabled}
      dark={dark}
    />
  );
}

export default memo(TimeNavigator);
