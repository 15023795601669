import { Svg, Path, Rect } from '@react-pdf/renderer';

const AccessoryIcon = () => {
  return (
    <Svg style={{ width: 32, height: 32, marginRight: 8 }} viewBox='0 0 32 32'>
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.0602 11.1286C14.2656 11.3135 14.2822 11.6299 14.0972 11.8353L10.3488 15.9984L14.1001 20.1647C14.285 20.3701 14.2685 20.6865 14.0631 20.8715C13.8577 21.0564 13.5412 21.0398 13.3563 20.8344L9.02 16.0185L9 16.0005L13.3534 11.1656C13.5383 10.9602 13.8548 10.9436 14.0602 11.1286ZM17.9385 20.8714C17.7331 20.6865 17.7165 20.3701 17.9014 20.1647L21.6499 16.0016L17.8985 11.8353C17.7136 11.6299 17.7302 11.3135 17.9356 11.1285C18.141 10.9436 18.4574 10.9602 18.6423 11.1656L22.9786 15.9815L22.9986 15.9995L18.6453 20.8344C18.4603 21.0398 18.1439 21.0564 17.9385 20.8714Z'
        fill='#0F141A'
        fillOpacity='0.87'
      />
      <Rect
        x='0.5'
        y='0.5'
        width='31'
        height='31'
        rx='5.5'
        ry='5.5'
        stroke='#0F141A'
        strokeOpacity='0.87'
      />
    </Svg>
  );
};

export default AccessoryIcon;
