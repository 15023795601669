import { UNTITLED_STORY } from 'utils/constants';

import { StyledTooltipDescription, StyledTooltipTitleWrapper } from './styled';

export default function TabToolTip({ title }: Readonly<{ title?: string }>) {
  return (
    <StyledTooltipTitleWrapper>
      <StyledTooltipDescription>{title ?? UNTITLED_STORY}</StyledTooltipDescription>
    </StyledTooltipTitleWrapper>
  );
}
