import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  listSubheader: {
    ...theme.typography.dina.overline,
  },
  menuItem: {
    marginLeft: 0,
  },
}));

export default useStyles;
