import styled from '@emotion/styled/macro';

import { Typography, Button } from '@material-ui/core';

export const RootWrapper = styled('div')`
  margin: 5px 0;
`;

export const Navigator = styled('div')`
  float: left;
  padding: 3px 0px;
`;

export const DropDown = styled('div')`
  float: right;
  margin: auto;
  max-width: 45%;
  padding: 5px 10px;
`;

export const ButtonWrapper = styled(Button)`
  max-width: 28px;
  max-height: 28px;
  min-width: 28px;
  min-height: 28px;
  padding: 0px;
  :hover {
    & * {
      fill-opacity: 1;
    }
  }
  :disabled {
    & * {
      fill-opacity: 0.25;
    }
  }
`;

export const Icon = styled('img')`
  margin-right: 4px;
  margin-left: 8px;
`;

export const TypographyWrapper = styled(Typography)`
  display: inline;
  padding: 3px 0;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.25px;
  outline: none;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  :hover {
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
`;

export const CalendarContainer = styled('div')`
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
`;
