import React, { forwardRef, memo, useRef } from 'react';

import Badge from 'components/badge';
import { IconButton } from 'components/buttons';
import { useSelectIcon } from 'components/deck';
import { WIDGETS } from 'features/widget/constants';

import { Metadata, Title, Top, Wrapper } from './styled';

interface CollapsedDeckProps {
  /** Set is ticker visible callback */
  onClick: () => void;
  /** Title of the deck */
  title?: string;
  /** Providers of the deck */
  providers?: Array<{
    mRefId: string;
    mTitle?: string;
  }>;
  /** Count of the deck */
  count?: number;
  type?: WIDGETS;
  isOver: boolean;
  isDragging: boolean;
  drawLeftBorder: boolean;
}

function CollapsedDeck(
  {
    onClick,
    title = 'Deck',
    type = WIDGETS.FEED,
    providers = [],
    count = 0,
    isOver,
    isDragging,
    drawLeftBorder,
  }: Readonly<CollapsedDeckProps>,
  ref: React.Ref<HTMLDivElement>,
) {
  const initialCount = useRef(count);

  const { Icon, setIsHovered } = useSelectIcon(type);

  // Number of updates after the initial render
  const diff = count - initialCount.current;

  return (
    <Wrapper ref={ref} $isOver={isOver} $drawLeftBorder={drawLeftBorder} $isDragging={isDragging}>
      <Top>
        <IconButton onClick={onClick} title="Expand" variant="discreet" usage="text" height={32}>
          <Icon onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} />
        </IconButton>
        {diff > 0 && <Badge vertical>{`${diff} update${diff > 1 ? 's' : ''}`}</Badge>}
        <Title>{title}</Title>
      </Top>
      <span>
        <Metadata>{type}</Metadata>
        {providers.map((provider) => (
          <Metadata key={provider.mRefId}>{provider.mRefId}</Metadata>
        ))}
      </span>
    </Wrapper>
  );
}

export default memo(forwardRef(CollapsedDeck));
