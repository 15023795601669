import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import { outTimingTypes } from 'components/editor/constants/types';
import { borderColor, edgeColor } from '../../utils/styleUtils';

const { ITEM_OUT, INSTANCE_OUT } = outTimingTypes;

const commonStyle = css`
  position: absolute;
  left: 1px;
  top: 50%;
  width: 6px;
  height: 6px;
  transform: translateY(-50%);
  border-radius: 0.5px;
`;

const instanceOutStyle = css`
  ${commonStyle};
  background: rgba(15, 20, 26, 0.7);
`;

const itemOutStyle = ({ theme }) => css`
  ${commonStyle};
  border: 1px solid ${theme.palette.dina.blackMediumEmphasis};
`;

export const RightEdge = styled('div')`
  width: 8px;
  height: 100%;
  background-color: ${({ isManual, isGraphic, theme }) =>
    isManual ? 'transparent' : edgeColor({ isGraphic, theme })};
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  position: relative;

  ::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: calc(50% - 6px);
    width: 100%;
    border-top-right-radius: 4px;
    border-top: ${({ isSelected, isGraphic, theme }) =>
      borderColor({ isSelected, isGraphic, theme })};
    border-right: ${({ isSelected, isGraphic, theme }) =>
      borderColor({ isSelected, isGraphic, theme })};
  }

  ::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    height: calc(50% - 6px);
    width: 100%;
    border-bottom-right-radius: 4px;
    border-bottom: ${({ isSelected, isGraphic, theme }) =>
      borderColor({ isSelected, isGraphic, theme })};
    border-right: ${({ isSelected, isGraphic, theme }) =>
      borderColor({ isSelected, isGraphic, theme })};
  }
`;

export const MiddleEdge = styled('div')`
  ${(props) => {
    if (props.outTimingType === ITEM_OUT) return itemOutStyle;
    if (props.outTimingType === INSTANCE_OUT) return instanceOutStyle;
    return null;
  }}
`;

export const RightEdgeWrapper = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
`;
