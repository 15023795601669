import { useContext } from 'react';

import UserCtx from 'contexts/UserContext';
import { NotificationContext } from 'contexts/NotificationContext';
import useCheckUserRight, { useCanSeeOrderManagement } from 'hooks/useCheckUserRight';

import DigitalClock from 'components/digitalClock/DigitalClock';
import MyOrdersButton from 'features/orderForm/components/MyOrdersButton';
import Scratchpad from '../scratchPad';
import MessageHub from '../messageHub/MessageHub';
import Settings from '../settings';
import Links from '../linksPanel';

import {
  UserContentWrapper,
  StyledTabDivider as Divider,
  OrdersButtonWrapper,
} from './userContent-styled';

const UserContent = () => {
  const { attributes } = useContext(UserCtx);
  const { notificationMessage } = useContext(NotificationContext);
  const { canAccessOrderManagement } = useCanSeeOrderManagement();
  const [checkUserRight] = useCheckUserRight();
  const { mTitle, mAvatarKey, mId } = attributes;
  const linksPanelFeatureFlag = checkUserRight('feature', 'links-panel');

  return (
    <UserContentWrapper>
      {canAccessOrderManagement && (
        <OrdersButtonWrapper>
          <MyOrdersButton userId={mId} />
        </OrdersButtonWrapper>
      )}

      {linksPanelFeatureFlag && <Links />}
      <Scratchpad />
      <MessageHub messages={notificationMessage} />
      <Divider orientation="vertical" flexItem />
      <DigitalClock copyOnClick />
      <Divider orientation="vertical" flexItem />
      <Settings avatar={mAvatarKey} username={mTitle} userId={mId} />
    </UserContentWrapper>
  );
};

export default UserContent;
