import { useEffect, useCallback } from 'react';

import { useSetToggleBothSidebars } from 'store/sidebar';

export const useToggleSidebar = () => {
  const toggleSidebar = useSetToggleBothSidebars();

  const keyLookup = useCallback(
    (ev) => {
      const match = (ev.metaKey || ev.ctrlKey) && ev.key === '.';
      match && toggleSidebar();
    },
    [toggleSidebar],
  );

  useEffect(() => {
    const downHandler = (ev) => keyLookup(ev);

    window.addEventListener('keydown', downHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [keyLookup]);
};

export default useToggleSidebar;
