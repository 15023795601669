import { useContext, useMemo } from 'react';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { keyBy } from 'lodash';

import { CellMap } from 'components/mdfEditor/fields/fields';
import UserContext from 'contexts/UserContext';
import { getFieldMap } from 'features/gridDeck/utils';
import { getMdfByMTypeFromKeyedMdfs } from 'features/grids/common/components/utils/mdfUtils';
import { hasPermission } from 'features/mdf/mdf-utils';
import { getFieldKey } from 'features/orderForm/utils';
import { PreviewType } from 'store/preview';
import { Metadata, NewFieldValue } from 'types/forms/forms';
import { FieldTypeEnum, FieldValue, LayoutSettings, Mdf, MdfField } from 'types/graphqlTypes';
import { ParsedMemberType } from 'types/members';

type UpdateDataType = (member: ParsedMemberType, val: NewFieldValue[]) => void;

export type MetadataCell = CellContext<ParsedMemberType, unknown> & {
  errorValue: string;
  updateData: UpdateDataType;
  metadata: Metadata;
  mdf?: Mdf;
  setPreview: (val: PreviewType) => void;
};

interface GridWidgetMdfColumnProps {
  groups: string[];
  fieldModelMap: Map<string, MdfField & { formId: string; settings: LayoutSettings }>;
}

const getMaxWidth = (fieldModel: MdfField) => {
  switch (fieldModel.type) {
    case FieldTypeEnum.checkbox:
      return 100;
    case FieldTypeEnum.choice:
      return 160;
    case FieldTypeEnum.text:
      return 300;
    default:
      return undefined;
  }
};

export const getMdfColumnDefinitions = ({
  groups,
  fieldModelMap,
}: GridWidgetMdfColumnProps): ColumnDef<ParsedMemberType>[] => {
  const columns: ColumnDef<ParsedMemberType>[] = [];

  // Loop through all unique field models and generate columns.
  for (const fieldModel of fieldModelMap.values()) {
    if (!fieldModel) continue;

    columns.push({
      accessorKey: `metadata.${fieldModel.fieldId}`,
      header: fieldModel.settings?.label,
      maxSize: getMaxWidth(fieldModel),
      aggregatedCell: () => null,
      sortingFn: (a, b) => {
        // TODO: Extract this sorting function into a separate fn. Support for more field types.
        const aValue = a.original.metadata[fieldModel.fieldId];
        const bValue = b.original.metadata[fieldModel.fieldId];

        const stringifyValue = (value: FieldValue) => {
          if (value === null || value === undefined) return '';
          if (typeof value === 'object') return JSON.stringify(value);
          return value.toString();
        };

        const aString = stringifyValue(aValue);
        const bString = stringifyValue(bValue);

        if (aString === bString) return 0;
        if (aString === '') return -1;
        if (bString === '') return 1;
        return aString.localeCompare(bString);
      },
      cell: (context) => {
        const { cell, row, setPreview, table } = context as MetadataCell;
        const { metadata, mdf, errorMap } = row.original;
        const errorValue = errorMap ? errorMap[cell.column.id.split('_').pop() ?? ''] : '';

        const Cell = CellMap[fieldModel.type];
        if (Cell && mdf) {
          // -- Do not render cell if the field does not belong to the form
          if (row.original.mdfId && fieldModel.formId !== row.original.mdfId) return null;

          return (
            <Cell
              onClick={() => {
                if (fieldModel.type === FieldTypeEnum.subtype) {
                  setPreview(row.original);
                }
              }}
              key={`${row.id}-${fieldModel.fieldId}`}
              mdf={mdf}
              disableEdit={!hasPermission(mdf.permissions.write[fieldModel.fieldId], groups)}
              fieldModel={fieldModel}
              value={metadata[fieldModel.fieldId]}
              errorValue={errorValue}
              fieldSettings={fieldModel.settings}
              setValue={(newValue) => {
                if (newValue !== row.original.metadata[fieldModel.fieldId]) {
                  if (table.options.meta?.updateData) {
                    table.options.meta?.updateData(row.original, [
                      { fieldId: fieldModel.fieldId, value: newValue },
                    ]);
                  }
                }
              }}
            />
          );
        }
        return <span>{mdf ? 'Missing field' : 'Missing schema'}</span>;
      },
    });
  }

  return columns;
};

export const useGetMdfColumns = (items: ParsedMemberType[], mdfs: Mdf[]) => {
  // Groups user is a part of. Used to determine if a field has read/write
  const { groups } = useContext(UserContext);
  // Map of MDF schemas by ID.
  const mdfMap = useMemo(() => keyBy(mdfs, (m) => m.id), [mdfs]);
  // Map of all fields by field ID.
  const fieldMap = useMemo(() => {
    return getFieldMap(mdfMap);
  }, [mdfMap]);
  // Set of MDF Ids used in the items.
  const usedMdfIds = new Set<string>();

  // Loop through member items and add mdfIds to the usedMdfIds set.
  for (const member of items) {
    // If the member has an MDF ID, add it to the usedMdfIds set.
    if (member.mdfId) {
      usedMdfIds.add(member.mdfId);
    } else {
      // If not, try to get default MDF by member type
      const id = getMdfByMTypeFromKeyedMdfs(member, mdfMap)?.id;
      if (id) usedMdfIds.add(id);
    }
  }

  // Get this columns map further out?
  const fieldModelMap = new Map<string, MdfField & { formId: string; settings: LayoutSettings }>();
  // Loop through all used MDFs and add fields to the columnsMap.
  for (const mdfId of usedMdfIds.values()) {
    const mdf = mdfMap[mdfId];

    if (mdf) {
      for (const field of mdf.fields) {
        const key = getFieldKey(field.fieldId, mdf.id);
        fieldModelMap.set(key, fieldMap[key]);
      }
    }
  }

  const mdfDefinitions = useMemo(
    () => getMdfColumnDefinitions({ fieldModelMap, groups }),
    [fieldModelMap, groups],
  );

  return mdfDefinitions;
};
