import { useCallback, useMemo } from 'react';

import useTabs from 'hooks/useTabs';
import { isContentType, useContentTabs, useCurrentTabId, useCurrentTabType } from 'store/tabs';

import {
  StyledDropdownListItem as ListItem,
  StyledDropdownListItemLabel as ListItemLabel,
} from '../tabItems/styled';

const FixedItemsContainer = ({ handleDropDownClose }: { handleDropDownClose: () => void }) => {
  const [contentTabs] = useContentTabs();
  const [currentTabId] = useCurrentTabId();
  const [currentTabType] = useCurrentTabType();
  const { closeActiveTab, closeInactiveTabs, closeAllTabs } = useTabs();

  const hasInactiveTabs = useMemo(() => {
    const inactiveTabs = contentTabs.filter((t) => t.id !== currentTabId);
    return inactiveTabs.length > 0;
  }, [currentTabId, contentTabs]);

  const handleCloseActiveTab = useCallback(() => {
    if (isContentType(currentTabType)) closeActiveTab();
    handleDropDownClose();
  }, [closeActiveTab, handleDropDownClose, currentTabType]);

  const handleCloseInactiveTabs = useCallback(() => {
    closeInactiveTabs();
    handleDropDownClose();
  }, [closeInactiveTabs, handleDropDownClose]);

  const handleCloseAllTabs = useCallback(() => {
    closeAllTabs();
    handleDropDownClose();
  }, [closeAllTabs, handleDropDownClose]);

  return (
    <>
      <ListItem button onClick={handleCloseActiveTab} disabled={!currentTabId}>
        <ListItemLabel>Close Active Tab</ListItemLabel>
        {/* <TabAction>{`Ctrl + W \n⌘ + W`}</TabAction> */}
      </ListItem>
      <ListItem button onClick={handleCloseInactiveTabs} disabled={!hasInactiveTabs}>
        <ListItemLabel>Close Inactive Tabs</ListItemLabel>
      </ListItem>
      <ListItem button onClick={handleCloseAllTabs} disabled={contentTabs.length === 0}>
        <ListItemLabel>Close All Tabs</ListItemLabel>
        {/* <TabAction>{`Ctrl + Shift + W\n⌘ + Shift + W`}</TabAction> */}
      </ListItem>
    </>
  );
};

export default FixedItemsContainer;
