import { useCallback, useState } from 'react';
import Fade from '@material-ui/core/Fade';

import { ReactComponent as DropdownIcon } from 'assets/icons/systemicons/arrows/arrow_drop_down_discreet.svg';
import Popover from 'components/popover';
import { useContentTabs, useMaxVisibleTabs } from 'store/tabs';

import FixedItems from './components/fixedItems';
import TabItems from './components/tabItems';

import {
  StyledDivider as Divider,
  StyledDropDownContentList as List,
  StyledDropDownWrapper,
  StyledTabsOverline,
  StyledWrapper as Wrapper,
} from './styled';

const anchorOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

const transformOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

const TabsDropdownContainer = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [contentTabs] = useContentTabs();
  const [maxVisibleTabs] = useMaxVisibleTabs();

  const handleDropDownClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropDownClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const dropDownOpen = Boolean(anchorEl);
  const dropDownId = dropDownOpen ? 'tabs-dropdown' : undefined;

  return (
    <>
      <StyledDropDownWrapper onClick={handleDropDownClick} active={!!dropDownOpen}>
        {contentTabs.length > maxVisibleTabs && <>+{contentTabs.length - maxVisibleTabs}</>}
        <DropdownIcon />
      </StyledDropDownWrapper>
      <Popover
        id={dropDownId}
        open={dropDownOpen}
        onClose={handleDropDownClose}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        TransitionComponent={Fade}
      >
        <Wrapper>
          <List disablePadding>
            {/* Fixed dropdown items */}
            <FixedItems handleDropDownClose={handleDropDownClose} />
            <Divider />
            <StyledTabsOverline>Open Tabs</StyledTabsOverline>
            {/* Dynamic Tab items */}
            <TabItems handleDropDownClose={handleDropDownClose} />
          </List>
        </Wrapper>
      </Popover>
    </>
  );
};

export default TabsDropdownContainer;
