import DinaSrc from 'assets/images/initialLoading/dinaLogo.png';
import Text from 'components/text';
import { HStack, VStack } from 'layouts/box/Box';
import URLS from 'utils/constants/urls';

import { DinaLogo, Footer, Loading, Wrapper } from './loading-styled';

export default function LoadingView() {
  return (
    <Wrapper>
      <DinaLogo />
      <Loading />
      <Text variant="caption" color="highEmphasis">
        Loading Dina, please wait...
      </Text>
      <Footer>
        <VStack gap="6px">
          <Text variant="captionSmall">
            Copyright © Dina Media Tech AS
            <br />
            All rights reserved.
          </Text>
          <Text
            variant="captionSmall"
            as="a"
            href={URLS.TERMS_CONDITIONS}
            target="_blank"
            rel="noopener"
          >
            Click here for legal notice
          </Text>
        </VStack>
        <HStack gap="4px">
          <img src={DinaSrc} alt="Dina" />
          <Text variant="caption">Dina Media Tech AS</Text>
        </HStack>
      </Footer>
    </Wrapper>
  );
}
