import { useCallback, useMemo } from 'react';
import { useContextMenu } from 'react-contexify';
import { TableMeta } from '@tanstack/react-table';

import { useUpdateBlockTitle } from 'api/mdfBlocks/useUpdateMdfBlock';
import useUpdateMetadata from 'api/useUpdateMetadata';
import useToast from 'components/toast/useToast';
import { ErrorMap, getErrorMap } from 'hooks/useMdfErrorMap';
import { ParsedMemberType } from 'types';
import { Metadata, NewFieldValue } from 'types/forms/forms';

const canSave = (mdToSave: Metadata, errorMap: ErrorMap) => {
  for (const key of Object.keys(mdToSave)) {
    if (errorMap[key]) return false;
  }
  return true;
};

/** Enriches the table with additional functionality. */
export function useGetMeta({
  onRemoveItem,
}: {
  onRemoveItem: (block: { mId: string; mRefId: string }) => void;
}) {
  const { show } = useContextMenu({ id: 'memberMenu' });

  const { errorToast } = useToast();
  const updateTitle = useUpdateBlockTitle();
  const updateMetadata = useUpdateMetadata();

  const onContextMenu = useCallback(
    (event: React.MouseEvent<Element, MouseEvent>, member: ParsedMemberType) => {
      show({
        event,
        props: {
          member,
        },
      });
    },
    [show],
  );

  const onUpdateTitle = useCallback(
    (block: { mId: string; mRefId: string }, updatedTitle: string) => {
      updateTitle(block.mId, block.mRefId, updatedTitle).catch(errorToast);
    },
    [],
  );

  /** Update individual metadata fields */
  const updateData = useCallback((member: ParsedMemberType, val: NewFieldValue[]) => {
    const updates: Metadata = {};
    for (const update of val) {
      updates[update.fieldId] = update.value;
    }

    const updatedErrorMap = member.validFieldMap
      ? getErrorMap(member.validFieldMap, { ...member.metadata, ...updates })
      : {};

    if (canSave(updates, updatedErrorMap) && member.mId && member.mRefId && member.mType) {
      updateMetadata(
        member.mId,
        member.mRefId,
        updates,
        member.metadata,
        member.mType,
        member.mdf?.id,
        member.mTitle,
      ).catch(errorToast);
    }
  }, []);

  const onRemove = useCallback((member: ParsedMemberType) => {
    const block = { mId: member.mId, mRefId: member.mRefId as string };
    onRemoveItem(block);
  }, []);

  const meta: TableMeta<ParsedMemberType> = useMemo(() => {
    return {
      rowMeta: {
        onContextMenu,
      },
      emptyStateText: 'No planning items found',
      updateData,
      updateTitle: onUpdateTitle,
      onRemove,
    };
  }, [onContextMenu, updateData]);

  return meta;
}
