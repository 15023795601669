/* eslint-disable max-len */
export const ICON_INTERNALS = {
  bookmarkSmallOn: {
    viewBox: '0 0 16 16',
    path: 'M4.50857 9.52673L2.93329 14.7856C2.83511 15.1129 3.20384 15.3806 3.48529 15.1864L8.0002 12.0635L12.5151 15.1864C12.7966 15.3806 13.1646 15.1129 13.0671 14.7856L11.4918 9.52673L15.8569 6.198C16.1289 5.99073 15.9878 5.55655 15.646 5.54927L10.158 5.422L8.34129 0.241636C8.22784 -0.0805455 7.77184 -0.0805455 7.65911 0.241636L5.84238 5.422L0.353656 5.54927C0.0118377 5.55655 -0.128526 5.99073 0.142747 6.198L4.50857 9.52673Z',
  },
  moreVertical: {
    viewBox: '0 0 4 16',
    path: 'M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z',
  },
  disclosureArrowRight: {
    viewBox: '0 0 8 16',
    path: 'M0.870832 0.163056C0.684697 -0.0409435 0.369468 -0.0549435 0.164319 0.129056C-0.0408293 0.315056 -0.0558401 0.631056 0.130295 0.836056L6.64702 7.99906L0.130295 15.1631C-0.0558401 15.3681 -0.0408293 15.6841 0.164319 15.8701C0.369468 16.0541 0.684697 16.0401 0.870832 15.8361L8 7.99906L0.870832 0.163056Z',
  },
  disclosureArrowUp: {
    viewBox: '0 0 16 8',
    path: 'M0.163484 7.12868L7.99948 -0.000488281L15.8365 7.12868C16.0405 7.31482 16.0545 7.63004 15.8705 7.83519C15.6845 8.04034 15.3685 8.05535 15.1635 7.86922L7.99948 1.35249L0.836484 7.86922C0.631484 8.05535 0.315484 8.04034 0.129484 7.83519C-0.0545163 7.63004 -0.0405163 7.31482 0.163484 7.12868Z',
  },
  disclosureArrowDown: {
    viewBox: '0 0 16 8',
    path: 'M19.8365 8.8704L12.0005 15.9996L4.16348 8.8704C3.95948 8.68427 3.94548 8.36904 4.12948 8.16389C4.31548 7.95874 4.63148 7.94373 4.83648 8.12987L12.0005 14.6466L19.1635 8.12987C19.3685 7.94373 19.6845 7.95874 19.8705 8.16389C20.0545 8.36904 20.0405 8.68427 19.8365 8.8704Z',
  },
  source: {
    viewBox: '0 0 24 24',
    path: 'M13 19C16.866 19 20 15.866 20 12C20 8.13401 16.866 5 13 5C10.687 5 8.63608 6.12178 7.36153 7.85096L6.51129 7.31956C7.96439 5.3086 10.3294 4 13 4C17.4182 4 21 7.58172 21 12C21 16.4183 17.4182 20 13 20C10.3294 20 7.96439 18.6914 6.51129 16.6804L7.36153 16.149C8.63608 17.8782 10.687 19 13 19ZM11 12L3 17V7L11 12Z',
  },
  bookmarkSearchOff: {
    viewBox: '0 0 24 24',
    path: 'M5.00095 21.4831L5.01 3.00062C5.01 2.44637 5.4588 2 6 2H18C18.5477 2 19 2.45228 19 3V21.4835L12 17.912L5.00095 21.4831ZM12 19L20 23V3C20 1.9 19.1 1 18 1H6C4.9 1 4.01 1.9 4.01 3L4 23L12 19ZM14.0356 12.532C15.3447 10.9682 15.2645 8.63543 13.7949 7.16584C12.2405 5.61139 9.72029 5.61139 8.16584 7.16584C6.61139 8.72029 6.61139 11.2405 8.16584 12.7949C9.63543 14.2645 11.9682 14.3447 13.532 13.0356L16.2297 15.9211C16.3258 16.0239 16.4879 16.0266 16.5874 15.9271L16.9271 15.5874C17.0266 15.4879 17.0239 15.3258 16.9211 15.2297L14.0356 12.532ZM13.0913 7.86947C14.2571 9.0353 14.2571 10.9255 13.0913 12.0913C11.9255 13.2571 10.0353 13.2571 8.86947 12.0913C7.70365 10.9255 7.70365 9.0353 8.86947 7.86947C10.0353 6.70365 11.9255 6.70365 13.0913 7.86947Z',
  },
  plus: {
    viewBox: '0 0 24 24',
    path: 'M12 3C11.4477 3 11 3.44772 11 4V11H4C3.44772 11 3 11.4477 3 12C3 12.5523 3.44772 13 4 13H11V20C11 20.5523 11.4477 21 12 21C12.5523 21 13 20.5523 13 20V13H20C20.5523 13 21 12.5523 21 12C21 11.4477 20.5523 11 20 11H13V4C13 3.44772 12.5523 3 12 3Z',
  },
  audio: {
    viewBox: '0 0 24 24',
    path: 'M12 3.5C12 3.22386 12.2239 3 12.5 3C12.7761 3 13 3.22386 13 3.5V20.5C13 20.7761 12.7761 21 12.5 21C12.2239 21 12 20.7761 12 20.5V3.5ZM16 6.5C16 6.22386 16.2239 6 16.5 6C16.7761 6 17 6.22386 17 6.5V17.5C17 17.7761 16.7761 18 16.5 18C16.2239 18 16 17.7761 16 17.5V6.5ZM8.5 6C8.22386 6 8 6.22386 8 6.5V17.5C8 17.7761 8.22386 18 8.5 18C8.77614 18 9 17.7761 9 17.5V6.5C9 6.22386 8.77614 6 8.5 6ZM14 8.5C14 8.22386 14.2239 8 14.5 8C14.7761 8 15 8.22386 15 8.5V15.5C15 15.7761 14.7761 16 14.5 16C14.2239 16 14 15.7761 14 15.5V8.5ZM10.5 8C10.2239 8 10 8.22386 10 8.5V15.5C10 15.7761 10.2239 16 10.5 16C10.7761 16 11 15.7761 11 15.5V8.5C11 8.22386 10.7761 8 10.5 8ZM18 10.5C18 10.2239 18.2239 10 18.5 10C18.7761 10 19 10.2239 19 10.5V13.5C19 13.7761 18.7761 14 18.5 14C18.2239 14 18 13.7761 18 13.5V10.5ZM6.5 10C6.22386 10 6 10.2239 6 10.5V13.5C6 13.7761 6.22386 14 6.5 14C6.77614 14 7 13.7761 7 13.5V10.5C7 10.2239 6.77614 10 6.5 10ZM20 11.75C20 11.4739 20.2239 11.25 20.5 11.25C20.7761 11.25 21 11.4739 21 11.75V12.25C21 12.5261 20.7761 12.75 20.5 12.75C20.2239 12.75 20 12.5261 20 12.25V11.75ZM4.5 11.25C4.22386 11.25 4 11.4739 4 11.75V12.25C4 12.5261 4.22386 12.75 4.5 12.75C4.77614 12.75 5 12.5261 5 12.25V11.75C5 11.4739 4.77614 11.25 4.5 11.25Z',
  },
  feedsOff: {
    viewBox: '0 0 24 24',
    path: 'M11.5 22H10.5C10.5 15.7 15.7 10.5 22 10.5V11.5C16.2 11.5 11.5 16.2 11.5 22ZM14.5 22H15.5C15.5 18.4 18.4 15.5 22 15.5V14.5C17.9 14.5 14.5 17.9 14.5 22ZM19 22H21C21.6 22 22 21.6 22 21V19C20.3 19 19 20.3 19 22ZM13.4 2H12.4C12.4 7.7 7.7 12.4 2 12.4V13.4C8.3 13.4 13.4 8.3 13.4 2ZM9.4 2H8.4C8.4 5.5 5.5 8.4 2 8.4V9.4C6.1 9.4 9.4 6.1 9.4 2ZM4.9 2H3C2.4 2 2 2.4 2 3V4.9C3.6 4.9 4.9 3.6 4.9 2Z',
  },
  arrowsDouble: {
    viewBox: '0 0 24 24',
    path: 'M12 7L9 11L15 11L12 7Z M12 17L15 13H9L12 17Z',
  },
  h1Off: {
    viewBox: '0 0 24 24',
    path: 'M12.0427 17H13.25V7H12.0427V11.2995H7.45107V7H6.25V17H7.45107V12.3777H12.0427V17ZM16.9182 17H17.75V9.85645H17.6203L15.25 10.8281V11.6484L16.9182 10.9795V17Z',
  },
  description: {
    viewBox: '0 0 24 24',
    path: 'M5 9H19V11H5V9Z M5 13H13V15H5V13Z',
  },
  contents: {
    viewBox: '0 0 24 24',
    path: 'M5 8H19V9H5V8Z M5 12H13V13H5V12Z M5 16H15V17H5V16Z',
  },
  archiveOff: {
    viewBox: '0 0 24 24',
    path: 'M7.5 4C7.22386 4 7 4.22386 7 4.5V5H17V4.5C17 4.22386 16.7761 4 16.5 4H7.5ZM5.5 6C5.22386 6 5 6.22386 5 6.5V8H19V6.5C19 6.22386 18.7761 6 18.5 6H5.5ZM3 8L4 7V8V8.41421V9H5H19H20V8.41421V8V7L21 8L21.5607 8.56066C21.842 8.84196 22 9.2235 22 9.62132V19C22 19.5523 21.5523 20 21 20H3C2.44772 20 2 19.5523 2 19V9.62132C2 9.2235 2.15803 8.84197 2.43934 8.56066L3 8ZM4 10H3V19H21V10H20H4ZM9 12H8V14C8 14.5523 8.44772 15 9 15H16C16.5523 15 17 14.5523 17 14V12H16V14H9V12Z',
  },
  filtersOff: {
    viewBox: '0 0 24 24',
    path: 'M5 8.5C5 7.12 6.12 6 7.5 6C8.881 6 10 7.12 10 8.5C10 9.881 8.881 11 7.5 11C6.12 11 5 9.881 5 8.5ZM8 11.965C9.696 11.723 11 10.264 11 8.5C11 6.737 9.696 5.278 8 5.036L8 2L7 2V5.036C5.304 5.278 4 6.737 4 8.5C4 10.264 5.304 11.723 7 11.965L7 22H8L8 11.965ZM14 15.5C14 14.12 15.12 13 16.5 13C17.881 13 19 14.12 19 15.5C19 16.881 17.881 18 16.5 18C15.12 18 14 16.881 14 15.5ZM17 18.965C18.696 18.723 20 17.264 20 15.5C20 13.737 18.696 12.278 17 12.036V2L16 2V12.036C14.304 12.278 13 13.737 13 15.5C13 17.264 14.304 18.723 16 18.965L16 22H17L17 18.965Z',
  },
  locationOff: {
    viewBox: '0 0 24 24',
    path: 'M12 3C8.68228 3 6 5.68228 6 9C6 10.1102 6.37577 11.4297 7.00635 12.8372C7.63106 14.2317 8.47285 15.6406 9.32896 16.9094C10.1832 18.1755 11.0393 19.2841 11.6827 20.0769C11.7955 20.2159 11.9017 20.345 12 20.4634C12.0983 20.345 12.2045 20.2159 12.3173 20.0769C12.9607 19.2841 13.8168 18.1755 14.671 16.9094C15.5272 15.6406 16.3689 14.2317 16.9936 12.8372C17.6242 11.4297 18 10.1102 18 9C18 5.68228 15.3177 3 12 3ZM12.6583 21.2355C14.4007 19.1537 19 13.2555 19 9C19 5.13 15.87 2 12 2C8.13 2 5 5.13 5 9C5 13.2555 9.59927 19.1537 11.3417 21.2355C11.7488 21.7219 12 22 12 22C12 22 12.2512 21.7219 12.6583 21.2355ZM15 9C15 10.6569 13.6569 12 12 12C10.3431 12 9 10.6569 9 9C9 7.34315 10.3431 6 12 6C13.6569 6 15 7.34315 15 9ZM14 9C14 10.1046 13.1046 11 12 11C10.8954 11 10 10.1046 10 9C10 7.89543 10.8954 7 12 7C13.1046 7 14 7.89543 14 9Z',
  },
  instance: {
    viewBox: '0 0 24 24',
    path: 'M13.0628 3.08628C13.1866 2.90439 13.4433 3.03308 13.4069 3.25886L12.2818 10.2493H17.8059C17.9666 10.2493 18.0575 10.4576 17.9595 10.6016L10.9372 20.9137C10.8134 21.0956 10.5567 20.9669 10.5931 20.7411L11.7182 13.7507H6.19409C6.03335 13.7507 5.94253 13.5424 6.04053 13.3984L13.0628 3.08628Z',
  },
  user: {
    viewBox: '0 0 24 24',
    path: 'M12 1C5.92 1 1 5.92 1 12C1 18.08 5.92 23 12 23C18.08 23 23 18.08 23 12C23 5.92 18.08 1 12 1ZM12 22C9.71 22 7.61 21.22 5.92 19.92C6.56 16.43 9.11 13.9 12 13.9C14.88 13.9 17.44 16.43 18.08 19.91V19.92C16.39 21.22 14.29 22 12 22ZM8.54 9.45C8.54 7.54 10.09 5.99 12 5.99C13.91 5.99 15.46 7.54 15.46 9.45C15.46 11.36 13.91 12.91 12 12.91C10.09 12.91 8.54 11.36 8.54 9.45ZM18.93 19.2C18.24 16.34 16.46 14.17 14.18 13.32C15.53 12.55 16.46 11.12 16.46 9.45C16.46 6.99 14.46 4.99 12 4.99C9.54 4.99 7.54 6.99 7.54 9.45C7.54 11.11 8.47 12.55 9.82 13.32C7.55 14.17 5.77 16.34 5.08 19.2C3.18 17.38 2 14.83 2 12C2 6.49 6.49 2 12 2C17.51 2 22 6.49 22 12C22 14.83 20.82 17.38 18.93 19.2Z',
  },
  teamsOff: {
    viewBox: '0 0 24 24',
    fillRule: 'evenodd',
    path: 'M7 2H17C19.76 2 22 4.24 22 7V17C22 19.76 19.76 22 17 22H7C4.24 22 2 19.76 2 17V7C2 4.24 4.24 2 7 2ZM15.1 21C14.52 19.23 13.35 18 12 18C10.64 18 9.48 19.23 8.9 21H15.1ZM12 14C11.17 14 10.5 14.67 10.5 15.5C10.5 16.33 11.17 17 12 17C12.83 17 13.5 16.33 13.5 15.5C13.5 14.67 12.83 14 12 14ZM21 16.4342C20.5011 15.5389 19.8415 14.8374 19.08 14.42C19.64 13.96 20 13.28 20 12.5C20 11.12 18.88 10 17.5 10C16.78 10 16.14 10.31 15.68 10.79C15.16 9.72 14.43 8.89 13.57 8.42C14.13 7.96 14.5 7.27 14.5 6.49C14.5 5.12 13.38 4 12 4C10.62 4 9.49999 5.12 9.49999 6.5C9.49999 7.28 9.86999 7.97 10.43 8.43C9.56999 8.9 8.83999 9.73 8.31999 10.8C7.85999 10.31 7.21999 10 6.49999 10C5.11999 10 3.99999 11.12 3.99999 12.5C3.99999 13.28 4.35999 13.96 4.91999 14.42C4.1585 14.83 3.49888 15.5349 3 16.4326V7C3 4.79 4.79 3 7 3H17C19.21 3 21 4.79 21 7V16.4342ZM20.7374 18.4291C20.1625 19.9328 18.7064 21 17 21H16.15C15.67 19.36 14.74 18.05 13.58 17.42C14.14 16.96 14.5 16.28 14.5 15.5C14.5 14.12 13.38 13 12 13C10.62 13 9.5 14.12 9.5 15.5C9.5 16.28 9.86 16.96 10.42 17.42C9.26 18.05 8.33 19.36 7.85 21H7C5.2897 21 3.83094 19.928 3.2587 18.4188C3.78691 16.4165 5.04173 15 6.49999 15C7.87999 15 8.99999 13.88 8.99999 12.5C8.99999 12.3 8.96999 12.1 8.91999 11.92C9.50999 10.19 10.67 9 12 9C13.33 9 14.49 10.19 15.08 11.92C15.03 12.1 15 12.3 15 12.5C15 13.88 16.12 15 17.5 15C18.9607 15 20.2173 16.4213 20.7374 18.4291ZM4.99999 12.5C4.99999 13.33 5.66999 14 6.49999 14C7.32999 14 7.99999 13.33 7.99999 12.5C7.99999 11.67 7.32999 11 6.49999 11C5.66999 11 4.99999 11.67 4.99999 12.5ZM10.5 6.5C10.5 7.33 11.17 8 12 8C12.83 8 13.5 7.33 13.5 6.5C13.5 5.67 12.83 5 12 5C11.17 5 10.5 5.67 10.5 6.5ZM16 12.5C16 13.33 16.67 14 17.5 14C18.33 14 19 13.33 19 12.5C19 11.67 18.33 11 17.5 11C16.67 11 16 11.67 16 12.5Z',
  },
  departmentsOff: {
    viewBox: '0 0 24 24',
    path: 'M11 8H9V10H11V8Z M11 14H9V16H11V14Z M9 11H11V13H9V11Z M15 14H13V16H15V14Z M13 11H15V13H13V11Z M15 8H13V10H15V8Z M7 2H17C19.76 2 22 4.24 22 7V17C22 19.76 19.76 22 17 22H7C4.24 22 2 19.76 2 17V7C2 4.24 4.24 2 7 2ZM13 18V21H16V7H8V21H11V18H13ZM17 21C19.21 21 21 19.21 21 17V7C21 4.79 19.21 3 17 3H7C4.79 3 3 4.79 3 7V17C3 19.21 4.79 21 7 21V6.5C7 6.22 7.22 6 7.5 6H16.5C16.78 6 17 6.22 17 6.5V21Z',
  },
  info: {
    viewBox: '0 0 24 24',
    fillRule: 'evenodd',
    path: 'M12.484 8.814a1.707 1.707 0 100-3.414 1.707 1.707 0 000 3.414zM14.79 17.192c-.08.238-.713 1.408-2.808 1.408-2.094 0-2.305-1.311-2.182-2.138.058-.395.407-2.452.622-3.722l.161-.951c.088-.52-.158-.792-.554-.827-.396-.036-.889-.106-.827-.51.052-.362.492-.37.739-.37h3.141c.423 0 .643.184.643.554 0 .317-.042.626-.085.943l-.003.025c-.103.664-.3 1.767-.481 2.778-.145.811-.28 1.564-.346 1.983-.251 1.488 1.046.808 1.496.572.055-.029.098-.051.123-.062.22-.105.44.088.36.317z M23 12c0 6.075-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1s11 4.925 11 11zm-1 0c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10z',
  },
  // Misc. Icons
  clear: {
    viewBox: '0 0 24 24',
    path: 'M18.0608 12L12 1L5.93917 12C4.00635 15.5108 5.15205 19.9933 8.50169 22.0192C9.60366 22.6792 10.8106 23 11.9913 23C11.9913 23 11.9913 23 12 23C12 23 12 23 12.0087 23C13.1982 23 14.3963 22.6792 15.4983 22.0192C18.8479 19.9933 19.9936 15.5017 18.0608 12ZM6.70006 12.4583L12 2.83333L17.2999 12.4583C17.4836 12.7883 17.6235 13.1183 17.746 13.4667C16.8889 13.3842 16.0318 13.4575 15.2359 13.6867C14.064 14.0167 12.962 14.6675 12.0525 15.6392C11.1429 16.6108 10.0322 17.2617 8.86901 17.5917C8.02941 17.83 7.13734 17.9125 6.23653 17.8025C6.18405 17.6467 6.13158 17.4908 6.08785 17.3258C5.66805 15.6667 5.87795 13.9433 6.70006 12.4583Z',
  },
  close: {
    viewBox: '0 0 24 24',
    path: 'M12 11.294l5.648-5.648a.5.5 0 01.706.706L12.706 12l5.648 5.648a.5.5 0 01-.706.706L12 12.706l-5.648 5.648a.5.5 0 01-.706-.706L11.294 12 5.646 6.352a.5.5 0 01.706-.706L12 11.294z',
  },
  closeSmall: {
    viewBox: '0 0 24 24',
    path: 'M16.8563 7.14368C16.6647 6.95211 16.3541 6.95211 16.1626 7.14368L12 11.3062L7.83744 7.14368C7.64587 6.95211 7.33526 6.95211 7.14368 7.14368C6.95211 7.33526 6.95211 7.64587 7.14368 7.83744L11.3062 12L7.14368 16.1626C6.95211 16.3541 6.95211 16.6647 7.14368 16.8563C7.33526 17.0479 7.64587 17.0479 7.83744 16.8563L12 12.6938L16.1626 16.8563C16.3541 17.0479 16.6647 17.0479 16.8563 16.8563C17.0479 16.6647 17.0479 16.3541 16.8563 16.1626L12.6938 12L16.8563 7.83744C17.0479 7.64587 17.0479 7.33526 16.8563 7.14368Z',
  },
  arrowRight: {
    viewBox: '0 0 24 24',
    path: 'M4.00003 11V13H16L10.5 18.5L11.92 19.92L19.84 12L11.92 4.08002L10.5 5.50002L16 11H4.00003Z',
  },
  // Filetype icons
  photoOff: {
    viewBox: '0 0 24 24',
    path: 'M22 19.7778C22 21 21 22 19.7778 22H4.22222C3 22 2 21 2 19.7778V4.22222C2 3 3 2 4.22222 2H19.7778C21 2 22 3 22 4.22222V19.7778ZM19.7778 20.8889C20.3864 20.8889 20.8889 20.3864 20.8889 19.7778V4.22222C20.8889 3.61365 20.3864 3.11111 19.7778 3.11111H4.22222C3.61365 3.11111 3.11111 3.61365 3.11111 4.22222V19.7778C3.11111 20.3864 3.61365 20.8889 4.22222 20.8889H19.7778ZM10.8889 17.0111L8.11111 13.6667L4.22222 18.6667H19.7778L14.7778 12L10.8889 17.0111Z',
  },
};

const ICONS = {
  BOOKMARK_SMALL_ON: 'bookmarkSmallOn',
  MORE_VERTICAL: 'moreVertical',
  DISCLOSURE_ARROW_RIGHT: 'disclosureArrowRight',
  DISCLOSURE_ARROW_UP: 'disclosureArrowUp',
  DISCLOSURE_ARROW_DOWN: 'disclosureArrowDown',
  SOURCE: 'source',
  BOOKMARK_SEARCH_OFF: 'bookmarkSearchOff',
  PLUS: 'plus',
  AUDIO: 'audio',
  FEEDS_OFF: 'feedsOff',
  ARROWS_DOUBLE: 'arrowsDouble',
  H1_OFF: 'h1Off',
  DESCRIPTION: 'description',
  CONTENTS: 'contents',
  ARCHIVE_OFF: 'archiveOff',
  FILTERS_OFF: 'filtersOff',
  LOCATION_OFF: 'locationOff',
  INSTANCE: 'instance',
  USER: 'user',
  TEAMS_OFF: 'teamsOff',
  DEPARTMENTS_OFF: 'departmentsOff',
  INFO: 'info',
  // Misc. Icons
  CLEAR: 'clear',
  CLOSE: 'close',
  CLOSE_SMALL: 'closeSmall',
  ARROW_RIGHT: 'arrowRight',
  // Filetype icons
  PHOTO_OFF: 'photoOff',
};

export default ICONS;
