import Approved, { ReactComponent as ApprovedComponent } from './approved.svg';
import ApprovedSmall, { ReactComponent as ApprovedSmallComponent } from './approved_small.svg';
import Archived, { ReactComponent as ArchivedComponent } from './archived.svg';
import ArchivedSmall, { ReactComponent as ArchivedSmallComponent } from './archived_small.svg';
import Error, { ReactComponent as ErrorComponent } from './error.svg';
import ErrorSmall, { ReactComponent as ErrorSmallComponent } from './error_small.svg';
import Expired, { ReactComponent as ExpiredComponent } from './expired.svg';
import ExpiredSmall, { ReactComponent as ExpiredSmallComponent } from './expired_small.svg';
import InProgress, { ReactComponent as InProgressComponent } from './inProgress.svg';
import InProgressSmall, {
  ReactComponent as InProgressSmallComponent,
} from './inProgress_small.svg';
import InReview, { ReactComponent as InReviewComponent } from './inReview.svg';
import InReviewSmall, { ReactComponent as InReviewSmallComponent } from './inReview_small.svg';
import Pending, { ReactComponent as PendingComponent } from './pending.svg';
import PendingSmall, { ReactComponent as PendingSmallComponent } from './pending_small.svg';
import Published, { ReactComponent as PublishedComponent } from './published.svg';
import PublishedSmall, { ReactComponent as PublishedSmallComponent } from './published_small.svg';
import Ready, { ReactComponent as ReadyComponent } from './ready.svg';
import ReadySmall, { ReactComponent as ReadySmallComponent } from './ready_small.svg';
import ToDo, { ReactComponent as ToDoComponent } from './toDo.svg';
import ToDoSmall, { ReactComponent as ToDoSmallComponent } from './toDo_small.svg';
import WarningSmall, { ReactComponent as WarningSmallComponent } from './warning_small.svg';

// Create an object with keys based on the keys from statusIcons
const statusIcons = {
  approved: Approved,
  todo: ToDo,
  inProgress: InProgress,
  inReview: InReview,
  ready: Ready,
  published: Published,
  approved_small: ApprovedSmall,
  todo_small: ToDoSmall,
  inProgress_small: InProgressSmall,
  inReview_small: InReviewSmall,
  ready_small: ReadySmall,
  published_small: PublishedSmall,
  warning_small: WarningSmall,
  error: Error,
  error_small: ErrorSmall,
  expired_small: ExpiredSmall,
  expired: Expired,
  pending: Pending,
  pending_small: PendingSmall,
  archived: Archived,
  archived_small: ArchivedSmall,
} as const;

export const statusIconComponents = {
  approved: ApprovedComponent,
  todo: ToDoComponent,
  inProgress: InProgressComponent,
  inReview: InReviewComponent,
  ready: ReadyComponent,
  published: PublishedComponent,
  approved_small: ApprovedSmallComponent,
  todo_small: ToDoSmallComponent,
  inProgress_small: InProgressSmallComponent,
  inReview_small: InReviewSmallComponent,
  ready_small: ReadySmallComponent,
  published_small: PublishedSmallComponent,
  warning_small: WarningSmallComponent,
  error: ErrorComponent,
  error_small: ErrorSmallComponent,
  expired_small: ExpiredSmallComponent,
  expired: ExpiredComponent,
  pending: PendingComponent,
  pending_small: PendingSmallComponent,
  archived: ArchivedComponent,
  archived_small: ArchivedSmallComponent,
};

export default statusIcons;
