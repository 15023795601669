import styled from '@emotion/styled';

import { Label } from 'lib/label';
import Slot from 'lib/slot';

export const SectionTitleWrapper = styled(Slot)`
  height: 24px;
  display: flex;
  align-items: center;
  padding-left: 2px;
`;

export const DividerWrapper = styled(Slot)`
  margin-block-start: 8px;
  margin-block-end: 2px;
`;

export const StyledLabel = styled(Label)`
  ${({ theme }) => theme.typography.dina.overline};
  display: inline-block;
  padding: 0 2px 4px 2px;
`;

export const LoadingIconWrapper = styled('div')`
  position: relative;
  width: 24px;
  height: 24px;
`;
