import AppTitle, { AppTitleProps } from './AppTitle';
import Navbar from './navbar';
import Toolbar from './toolbar';

const Header = ({ appTitle }: AppTitleProps) => (
  <>
    <AppTitle appTitle={appTitle} />
    <Navbar />
    <Toolbar />
  </>
);

export default Header;
