import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { capitalize } from 'lodash';

import { ReactComponent as DeleteIcon } from 'assets/icons/systemicons/delete.svg';
import { ReactComponent as Open } from 'assets/icons/systemicons/open.svg';
import Tooltip from 'components/tooltip';
import { HStack } from 'layouts/box/Box';
import { MemberTypeEnum } from 'types/graphqlTypes';

export type GridActionType = 'go-to-resource' | 'remove';
interface Props {
  onClick: (type: GridActionType) => void;
  mType: MemberTypeEnum;
  hideStuff?: boolean;
}

const iconStyles = css`
  cursor: pointer;
  width: 18px;
  height: 18px;
  &:hover path {
    fill-opacity: 1;
  }
`;

const OpenIcon = styled(Open)`
  ${iconStyles}
`;

const RemoveIcon = styled(DeleteIcon)`
  ${iconStyles}
`;

/**
 * Action buttons at the end of each grid item
 * @param onClick - callback for the action
 * @param mType - memberType, used for tooltip
 */
export default function GridActions({ onClick, mType }: Readonly<Props>) {
  return (
    <HStack gap="6px" height="100%">
      <Tooltip title={`Go to ${capitalize(mType)}`}>
        <OpenIcon onClick={() => onClick('go-to-resource')} />
      </Tooltip>
      <Tooltip title="Remove">
        <RemoveIcon onClick={() => onClick('remove')} />
      </Tooltip>
    </HStack>
  );
}
