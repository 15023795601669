import styled from '@emotion/styled';

import { ReactComponent as SearchIcon } from 'assets/icons/systemicons/search.svg';

export const Icon = styled(SearchIcon)`
  width: 40px;
  height: 40px;
  margin-bottom: 8px;
  path {
    fill-opacity: 0.54;
  }
`;

export const Heading = styled('span')`
  ${({ theme }) => theme.typography.dina.h6};
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
`;

export const Body = styled('span')`
  ${({ theme }) => theme.typography.dina.body2};
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
`;

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
  gap: 8px;
  user-select: none;

  background: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
`;
