import { memo, useMemo } from 'react';
import styled from '@emotion/styled';
import { ScopeProvider } from 'jotai-molecules';

import { kanbanScope } from 'features/kanban/store/kanban';
import WidgetWrapper, { FilterComponentType } from 'features/widget/components/WidgetWrapper';
import { WIDGETS } from 'features/widget/constants';

import { FilterComponent } from './IframeFilters';
import { WidgetProps } from './types';
import { getPropFilters } from './utils';

const StyledIframe = styled('iframe')`
  width: 100%;
  height: 100%;
  border: 0;
`;

function IFrameWidget({ mId, mRefId, title, filters, writeAccess, layout }: Readonly<WidgetProps>) {
  const memoizedFilters = useMemo(() => {
    const propFilters = getPropFilters({ filters: filters });
    return propFilters;
  }, [filters]);

  return (
    <ScopeProvider scope={kanbanScope} value={mId}>
      <WidgetWrapper
        mRefId={mRefId}
        layout={layout}
        mId={mId}
        title={title}
        filterComponent={FilterComponent as FilterComponentType}
        filters={memoizedFilters}
        writeAccess={writeAccess}
        type={WIDGETS.IFRAME}
      >
        {memoizedFilters?.url && <StyledIframe src={memoizedFilters?.url as string} />}
      </WidgetWrapper>
    </ScopeProvider>
  );
}

export default memo(IFrameWidget);
