import { format, parse } from 'date-fns';

/**
 * Converts an ISO date string to a custom formatted date string.
 *
 * @param inputDateStr - The ISO date string to convert.
 * @param formatExp - The format expression for desired date format. Defaults to 'MMM. d YY, HH:mm'.
 * @returns The formatted date string.
 */
export const convertISODate = (inputDateStr: string, formatExp = 'MMM. d YYYY, HH:mm'): string =>
  format(parse(inputDateStr), formatExp);
