import React from 'react';
import Icon from 'components/icon/icon-view';
import ICONS from 'assets/icons/icons';
import { useTheme } from '@emotion/react';

const SavedFilterIcon = () => {
  const theme = useTheme();
  return (
    <>
      <Icon
        icon={ICONS.BOOKMARK_SEARCH_OFF}
        height={24}
        width={24}
        color={theme.palette.dina.iconInactive}
      />
      <Icon icon={ICONS.ARROWS_DOUBLE} height={24} width={24} />
    </>
  );
};

const BookmarkIcon = () => {
  const theme = useTheme();
  return (
    <Icon
      icon={ICONS.BOOKMARK_SEARCH_OFF}
      height={24}
      width={24}
      color={theme.palette.dina.iconInactive}
    />
  );
};

const ClearIcon = () => <Icon icon={ICONS.CLEAR} height={24} width={24} />;

export { SavedFilterIcon, BookmarkIcon, ClearIcon };
