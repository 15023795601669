import { Transforms, Editor } from 'slate';
import uuidv1 from 'uuid/v1';
import { elementTypes } from 'components/editor/constants/types';

const { TWEET_THREAD, DESCRIPTION, PARAGRAPH } = elementTypes;

/**
 * Inserts tweet thread element
 *
 * @param {Object} options
 * @param {Object} options.editor SlateJS editor instance
 * @param {Function} options.update Callback to be invoked on update
 * @returns {Object} SlateJS editor instance
 */

const { insertNodes } = Transforms;

const insertTweetThread = ({ editor, update, data }) => {
  const [, nextPath] = Editor.next(editor, {
    match: (node) => node.type === TWEET_THREAD || node.type === PARAGRAPH,
  });
  const descriptionElement = {
    type: DESCRIPTION,
    data: { ...data, itemId: uuidv1() },
    children: [{ text: '' }],
  };
  const tweetThreadElement = {
    type: TWEET_THREAD,
    data: { itemId: uuidv1() },
    children: [descriptionElement],
  };

  insertNodes(editor, tweetThreadElement, { at: nextPath });

  update({
    type: 'tweet-thread-insert',
    payload: {
      document: editor.children,
      insertedElement: tweetThreadElement,
    },
  });

  return editor;
};

export default insertTweetThread;
