import { Message, MessageObject, NotifyUser } from 'types/messageHub';
import getMessage from 'utils/notificationHelper/messageTextUtils';

import doNotify from './notificationConfig';

const createMessage = (notifyUser: NotifyUser) => {
  const messages: string[] = [];
  const messageObject: MessageObject = {
    messages,
    messageType: '',
    messageTypeString: '',
    mId: '',
    mType: '',
    itemTitle: '',
    mRefId: '',
  };

  try {
    const { loggedInUserId, mUpdatedById } = notifyUser.message;
    const selfUpdate = mUpdatedById === loggedInUserId;
    const notify = !selfUpdate && doNotify(notifyUser.message, loggedInUserId);

    const message = notify ? (getMessage(notifyUser) as Message) : undefined;

    if (message) {
      messages.push(message?.message ?? '');
      const {
        messageType,
        messageTypeString,
        mId,
        mRefId,
        mType,
        itemTitle,
        mTemplateId,
        isTemplateInstance,
        mPublishingAt,
        messageAssign,
      } = message;
      messageObject.messageType = messageType;
      messageObject.messageTypeString = messageTypeString;
      messageObject.mId = mId;
      messageObject.mType = mType;
      messageObject.itemTitle = itemTitle;
      messageObject.mRefId = mRefId;
      messageObject.mTemplateId = mTemplateId;
      messageObject.isTemplateInstance = isTemplateInstance;
      messageObject.mPublishingAt = mPublishingAt;
      messageObject.messageAssign = messageAssign;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('error in getTitle:', error);
  }
  return messageObject;
};

export default createMessage;
