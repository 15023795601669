import { memo, useCallback, useEffect, useState } from 'react';
import { useContextMenu } from 'react-contexify';
import useIntersectionObserver from '@react-hook/intersection-observer';

import { SortOption } from 'features/command/command-types';
import { Box } from 'layouts/box/Box';
import { CommandGroup } from 'lib/command';
import { configurableActionMTypes } from 'screens/main/components/header/navbar/settings/components/integrations/constants';
import { useEditorCommandsKeyed } from 'store';
import { AssignedMember } from 'types';
import { ConfigurableActionMTypes, MemberType, MemberTypeEnum } from 'types/graphqlTypes';

import SearchItem from './SearchItem';

import { IntersectionObserver } from './styled';

interface SearchResultProps {
  openItem: (item: MemberType) => void;
  setOpen: (val: boolean) => void;
  loadMore: () => Promise<void>;
  loading: boolean;
  searchValue: string;
  sortedBy: SortOption;
  items: MemberType[];
  members: Record<string, AssignedMember>;
}

const getKey = (item: MemberType) => {
  if (
    item.mType && [
      MemberTypeEnum.Note,
      MemberTypeEnum.Asset,
      MemberTypeEnum.Order.includes(item.mType),
    ]
  ) {
    return item.mRefId;
  }
  return item.mId;
};

const SearchResults = ({
  items,
  sortedBy,
  searchValue,
  loadMore,
  openItem,
  setOpen,
  loading,
  members,
}: SearchResultProps) => {
  const [editorCommands] = useEditorCommandsKeyed();
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const { isIntersecting } = useIntersectionObserver(ref, { pollInterval: 500 });
  const { show } = useContextMenu({ id: 'memberMenu' });

  const onContextMenu = useCallback(
    (item: MemberType, ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (item.mType && configurableActionMTypes.includes(item.mType as ConfigurableActionMTypes)) {
        ev.preventDefault();
        ev.stopPropagation();
        show({
          event: ev,
          props: {
            member: item,
            close: () => setOpen(false),
          },
        });
      }
    },
    [show],
  );

  useEffect(() => {
    if (isIntersecting) {
      void loadMore();
    }
  }, [isIntersecting, loadMore]);

  return (
    <CommandGroup>
      {items.length === 0 && (
        <Box padding="12px">{loading ? 'Searching ..' : 'No results found'}</Box>
      )}
      {items.map((item, i) => {
        const key = getKey(item);
        return (
          <SearchItem
            item={item}
            value={searchValue.replace(/"/g, '\\"')}
            index={i}
            key={key}
            sortedBy={sortedBy}
            members={members}
            openItem={openItem}
            onContextMenu={onContextMenu}
            commandById={editorCommands}
          />
        );
      })}
      {items.length > 0 && <IntersectionObserver ref={setRef} />}
    </CommandGroup>
  );
};

export default memo(SearchResults);
