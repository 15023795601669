import { useCallback } from 'react';

import { useAllMembers, useAllMembersKeyed } from 'store';

/**
 * A hook that exposes getUser(userId), getUserTitle(userId) and getUsers(userId) callbacks
 * where userId is the id that is needed to be searched in the list of users
 *
 * @returns {{getUser,getUserTitle,getUsers}}
 */

const useGetMembersInfo = () => {
  const [members] = useAllMembers();
  const [membersKeyed] = useAllMembersKeyed();

  const getMember = useCallback((memberId: string) => membersKeyed[memberId], [membersKeyed]);

  const getMembers = useCallback(
    (memberIds: string[]) => members.filter((member) => memberIds.includes(member.mId)),
    [members],
  );

  const getMemberTitle = useCallback(
    (memberId: string) => membersKeyed[memberId]?.mTitle,
    [membersKeyed],
  );

  const getMemberAvatarKey = useCallback(
    (memberId: string) => membersKeyed[memberId]?.mAvatarKey,
    [membersKeyed],
  );

  return { getMember, getMemberTitle, getMembers, getMemberAvatarKey };
};

export default useGetMembersInfo;
