import styled from '@emotion/styled';
import { ReactComponent as ImageIconComponent } from 'assets/icons/systemicons/editor/photo_off.svg';

export const ImageIcon = styled(ImageIconComponent)`
  margin: 8px;
  path {
    fill: ${({ theme }) => theme.palette.dina.blackMediumEmphasis};
    fill-opacity: 1;
  }
`;

export const MediaWrapper = styled('div')`
  padding: 8px;
`;
