const durationTypes = {
  PLANNED_DURATION: 'planned-duration',
  OVERUNDER_DURATION: 'overunder-duration',
  SPEAK_DURATION: 'speak-duration',
  CLIP_DURATION: 'clip-duration',
  TOTAL_DURATION: 'total-duration',
  WORD_COUNT: 'word-count',
  HOST_READ_SPEED: 'hostReadSpeed',
};

export default durationTypes;
