const fieldEnums = {
  CUSTOM_SOURCES: 'custom-sources',
  AUTHORS: 'authors',
  CUSTOM_TAGS: 'custom-tags',
  LIVE_STREAMS: 'live-streams',
  NOTIFICATIONS_DESTINATIONS: 'notifications-destinations',
  CUSTOM_SECTIONS: 'custom-sections',
};

export default fieldEnums;
