/**
 *  Hook for Updating user config
 */
import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';

import { Order, UpdateOrder } from 'types/forms/forms';

import { ORDER_PROPS } from './useGetOrdersAndForms';

const UPDATE_ORDER = gql`
  mutation UpdateOrder($input: UpdateOrderInput) {
    updateOrder(input: $input) {
      ${ORDER_PROPS}
    }
  }
`;

interface UpdateMemberOrder {
  updateMember: Order;
}

const useUpdateOrder = () => {
  const [update] = useMutation<UpdateMemberOrder>(UPDATE_ORDER);

  const updateOrder = useCallback(
    async (order: UpdateOrder) => {
      await update({
        variables: {
          input: order,
        },
      });
    },
    [update],
  );

  return { updateOrder };
};

export default useUpdateOrder;
