import styled from '@emotion/styled';
import { List as MuiList, MenuItem as MuiMenuItem, Paper } from '@material-ui/core';

import { ReactComponent as TaskSvg } from 'assets/icons/search/task.svg';
import transientOptions from 'theme/helpers';

import { Position } from '../../hook/useCustomCombobox';

interface ListWrapperProps {
  position: Position<string>;
}

export const ListWrapper = styled(Paper)<ListWrapperProps>`
  position: absolute;
  ${({ position }) =>
    position.bottom
      ? {
          bottom: position.bottom,
          left: position.left,
        }
      : {
          top: position.top,
          left: position.left,
        }};
  z-index: 1400;
  background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
  border-radius: 4px;
  overflow: hidden;
`;

export const List = styled(MuiList)`
  max-height: 350px;
  overflow-y: auto;
`;

export const MenuItem = styled(MuiMenuItem)`
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
  height: 32px;
  padding: 0 8px;
  svg {
    margin-left: 8px;
  }
`;

export const Info = styled('p')`
  ${({ theme }) => theme.typography.dina.overline};
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  font-weight: normal;
  margin: 0;
  margin-left: 32px;
  text-align: right;
  flex: 1;
`;

export const TaskIcon = styled(TaskSvg, transientOptions)<{ $selected?: boolean }>`
  path {
    fill: ${({ theme, $selected }) => ($selected ? 'white' : theme.palette.dina.highEmphasis)};
    fill-opacity: 0.8;
  }
  rect {
    stroke: ${({ theme, $selected }) => ($selected ? 'white' : theme.palette.dina.highEmphasis)};
    stroke-opacity: 0.7;
  }
`;
