import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import useUpdateLeftSidebarCache from 'hooks/useUpdateLeftSidebarCache';

const UNARCHIVE_MEMBER = gql`
  mutation UnarchiveStory($input: ArchiveStoryInput) {
    unarchiveMember(input: $input) {
      mId
    }
  }
`;

const useUnarchiveMember = () => {
  const [mutate] = useMutation(UNARCHIVE_MEMBER);
  const [update] = useUpdateLeftSidebarCache();

  const unarchive = async (mId, mType) => {
    await mutate({
      variables: {
        input: {
          mId,
        },
      },
      update: () => {
        update({ mId, mRefId: mId }, mType, false);
      },
    });
  };

  return [unarchive];
};

export default useUnarchiveMember;
