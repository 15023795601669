import styled from '@emotion/styled';

export const RootWrapper = styled('div')`
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const BodyWrapper = styled('div')`
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  height: 100%;
`;

export const SettingsMenuWrapper = styled('div')`
  width: 25%;
  min-width: 260px;
  border-right: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

export const SettingsDetailsWrapper = styled('div')`
  width: 75%;
`;

export const ProfileWrapper = styled('div')`
  width: 360px;
  border-radius: 8px;
  overflow: hidden;
  margin: -8px 0 -8px;
`;

export const SettingsWrapper = styled('div')`
  width: 85vw;
  height: 85vh;
  background-color: ${({ theme }) => theme.palette.dina.surfaceCardDark};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoadingWrapper = styled('div')`
  width: 100%;
  max-height: calc(100% - 64px);
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
  position: fixed;
`;
