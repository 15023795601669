import React from 'react';

import {
  Assets,
  Feeds,
  Iframe,
  Kanban,
  Note,
  Orders,
  Search,
  SearchPlugin,
  StoryGrid,
  Table,
} from 'screens/space/components/widgets';
import { WidgetProperties } from 'screens/space/types/space';
import { IntegrationType } from 'types/graphqlTypes';

import { WIDGETS } from '../constants';

const FeedWidget = ({ widget, writeAccess, searchString, layout }: Readonly<WidgetProperties>) => (
  <Feeds
    layout={layout}
    providers={widget.providers}
    writeAccess={writeAccess}
    mId={widget.mId}
    mRefId={widget.mRefId}
    title={widget.title}
    filters={widget.filters}
    federatedSearchString={searchString}
  />
);

const StoryGridWidget = ({
  layout,
  widget,
  selectedDate,
  writeAccess,
  searchString,
}: Readonly<WidgetProperties>) => {
  return (
    <StoryGrid
      layout={layout}
      writeAccess={writeAccess}
      mId={widget.mId}
      mRefId={widget.mRefId}
      title={widget.title}
      filters={widget.filters}
      selectedDate={selectedDate}
      federatedSearchString={searchString}
    />
  );
};

const DataTableWidget = ({
  layout,
  widget,
  writeAccess,
  searchString,
}: Readonly<WidgetProperties>) => {
  return (
    <Table
      mRefId={widget.mRefId}
      layout={layout}
      writeAccess={writeAccess}
      mId={widget.mId}
      title={widget.title}
      federatedSearchString={searchString}
    />
  );
};

const SearchWidget = ({
  layout,
  widget,
  selectedDate,
  writeAccess,
  searchString,
}: Readonly<WidgetProperties>) => {
  return (
    <Search
      layout={layout}
      selectedDate={selectedDate}
      writeAccess={writeAccess}
      mId={widget.mId}
      mRefId={widget.mRefId}
      title={widget.title}
      filters={widget.filters}
      federatedSearchString={searchString}
    />
  );
};

const SearchPluginWidget = ({
  layout,
  widget,
  selectedDate,
  searchString,
  writeAccess,
  config,
}: Readonly<WidgetProperties>) => {
  // TODO - handle better.
  if (!config?.mRefId) {
    return <div>Configuration missing</div>;
  }

  return (
    <SearchPlugin
      layout={layout}
      config={config}
      selectedDate={selectedDate}
      writeAccess={writeAccess}
      mId={widget.mId}
      mRefId={widget.mRefId}
      title={config.mTitle}
      filters={widget.filters}
      federatedSearchString={searchString}
    />
  );
};

const AssetsWidget = ({
  widget,
  writeAccess,
  searchString,
  layout,
}: Readonly<WidgetProperties>) => (
  <Assets
    layout={layout}
    mId={widget.mId}
    mRefId={widget.mRefId}
    title={widget.title}
    filters={widget.filters}
    writeAccess={writeAccess}
    federatedSearchString={searchString}
  />
);

const TasksWidget = ({ widget, spaceId, writeAccess, layout }: Readonly<WidgetProperties>) => (
  <Orders
    layout={layout}
    mId={widget.mId}
    mRefId={widget.mRefId}
    spaceId={spaceId}
    title={widget.title}
    filters={widget.filters}
    writeAccess={writeAccess}
  />
);

const KanbanWidget = ({ widget, writeAccess, layout }: Readonly<WidgetProperties>) => {
  return (
    <Kanban
      layout={layout}
      mRefId={widget.mRefId}
      mId={widget.mId}
      title={widget.title}
      filters={widget.filters}
      writeAccess={writeAccess}
    />
  );
};

const IframeWidget = ({ widget, writeAccess, layout }: Readonly<WidgetProperties>) => (
  <Iframe
    layout={layout}
    mId={widget.mId}
    mRefId={widget.mRefId}
    title={widget.title}
    filters={widget.filters}
    writeAccess={writeAccess}
  />
);

const NoteWidget = ({
  widget,
  writeAccess,
  viewRef,
  spaceId,
  layout,
}: Readonly<WidgetProperties>) => (
  <Note
    layout={layout}
    mId={widget.mId}
    mRefId={widget.mRefId}
    viewRef={viewRef}
    title={widget.title}
    writeAccess={writeAccess}
    spaceId={spaceId}
  />
);

export const componentMap = {
  [WIDGETS.FEED]: FeedWidget,
  [WIDGETS.IFRAME]: IframeWidget,
  [WIDGETS.KANBAN]: KanbanWidget,
  [WIDGETS.ASSETS]: AssetsWidget,
  [WIDGETS.MIMIR]: AssetsWidget,
  [WIDGETS.SEARCH]: SearchWidget,
  [WIDGETS.STORYGRID]: StoryGridWidget,
  [WIDGETS.SEARCH_PLUGIN]: SearchPluginWidget,
  [WIDGETS.TASKS]: TasksWidget,
  [WIDGETS.NOTE]: NoteWidget,
  [WIDGETS.TABLE]: DataTableWidget,
};

interface Props {
  type: WIDGETS;
  properties: WidgetProperties;
  ref?: React.LegacyRef<HTMLDivElement>;
  config?: IntegrationType;
}

/** Render widgets */
export default function RenderWidget({ type, properties, config, ref }: Readonly<Props>) {
  if (type in componentMap) {
    const ComponentToRender = componentMap[type] as React.FC<WidgetProperties>;
    return <ComponentToRender {...properties} config={config} ref={ref} />;
  }

  return null;
}
