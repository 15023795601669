import React, { forwardRef, memo } from 'react';

import { ReactComponent as AddIcon } from 'assets/icons/systemicons/add.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/systemicons/delete_small.svg';
import { ReactComponent as EditIcon } from 'assets/icons/systemicons/edit.svg';
import { IconButton } from 'components/buttons';
import Text from 'components/text';
import Tooltip from 'components/tooltip';
import { Color } from 'features/reusableStyled';
import { HStack } from 'layouts/box/Box';

import { ListTitle, Wrapper } from './styled';

interface Props {
  selected: boolean;
  label: string;
  hasChange: boolean;
  color?: string;
  style?: React.CSSProperties;
  hideActions?: boolean;
  addTooltip?: string;
  header?: boolean;
  tooltip?: string;
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  onClick?: () => void;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
  onAddClick?: () => void;
}

function SettingsListItem(
  {
    hideActions,
    addTooltip,
    label,
    tooltip,
    onClick,
    onEditClick,
    onDeleteClick,
    onAddClick,
    selected,
    hasChange,
    color,
    Icon,
    header,
    style,
  }: Readonly<Props>,
  ref: React.Ref<HTMLDivElement>,
) {
  return (
    <Tooltip title={tooltip ?? ''}>
      <Wrapper ref={ref} onClick={onClick} $selected={selected} $header={header} style={style}>
        {Icon && <Icon style={{ width: '18px', marginRight: '2px' }} />}
        {color && <Color $color={color} $size={12} />}
        <ListTitle>
          {hasChange ? (
            <Text variant="listItemLabelItalic" color="statusWarning">
              {`* ${label}`}
            </Text>
          ) : (
            <Text variant="listItemLabel" color={selected ? 'whiteHighEmphasis' : 'highEmphasis'}>
              {label}
            </Text>
          )}
        </ListTitle>
        {!hideActions && (
          <HStack padding="0 8px 0 0">
            {onAddClick && (
              <IconButton
                title={addTooltip ?? 'Add'}
                usage="text"
                size={24}
                iconSize={18}
                onClick={onAddClick}
                className="editIcon"
              >
                <AddIcon />
              </IconButton>
            )}
            {onEditClick && (
              <IconButton
                title="Edit"
                usage="text"
                size={24}
                iconSize={18}
                onClick={onEditClick}
                className="editIcon"
              >
                <EditIcon />
              </IconButton>
            )}
            {onDeleteClick && (
              <IconButton
                title="Delete"
                usage="text"
                size={24}
                onClick={onDeleteClick}
                className="deleteIcon"
              >
                <DeleteIcon />
              </IconButton>
            )}
          </HStack>
        )}
      </Wrapper>
    </Tooltip>
  );
}

export default memo(forwardRef(SettingsListItem));
