import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowDropDownUnboxed } from 'assets/icons/systemicons/arrows/arrow_drop_down_discreet_unboxed.svg';
import styled from '@emotion/styled/macro';

const IconWrapper = styled('div')`
  width: 32px;
  margin-right: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 100;
  user-select: none;
`;

const View = ({ icon, onClick }) => (
  <IconWrapper role="presentation" onClick={onClick}>
    {icon}
    <ArrowDropDownUnboxed />
  </IconWrapper>
);

View.propTypes = {
  /** Transition icon to be shown */
  icon: PropTypes.node,
  /** Callback to be invoked on click */
  onClick: PropTypes.func,
};

View.defaultProps = {
  icon: null,
  onClick: () => {},
};

export default memo(View);
