import { ApolloClient } from '@apollo/client';
import uniqBy from 'lodash/uniqBy';

import { SearchItemInput } from 'types/graphqlTypes';

import { Queries } from './queries';
import { GetInput, SearchResult } from './types';

export const search = async (
  client: ApolloClient<object>,
  input: SearchItemInput,
  fetchAll = false,
  queryType: keyof typeof Queries = 'default',
) => {
  const { data, error, loading } = await client.query<SearchResult, GetInput>({
    query: Queries[queryType],
    variables: { input },
    fetchPolicy: 'network-only',
  });

  let items = data?.searchItem?.items ?? [];
  let hasMore =
    typeof data?.searchItem.total === 'number' ? items.length < data?.searchItem.total : false;

  if (fetchAll && data?.searchItem.nextToken && items.length < data?.searchItem.total) {
    while (data?.searchItem.nextToken && items.length < data?.searchItem.total) {
      const fetchMoreResult = await client.query<SearchResult, GetInput>({
        query: Queries[queryType],
        variables: {
          input: {
            ...input,
            searchAfter: data?.searchItem.nextToken,
          },
        },
        fetchPolicy: 'network-only',
      });

      items = uniqBy([...items, ...fetchMoreResult.data.searchItem.items], 'mId');
      hasMore = items.length < fetchMoreResult.data.searchItem.total;
    }
  }

  return {
    items,
    error,
    loading,
    total: data?.searchItem?.total ?? 0,
    hasMore,
  };
};
