import Avatar, { AvatarVariant } from 'components/avatar/Avatar';
import { getIcon, hasAvatar } from 'components/command/command-constants';
import { Center } from 'layouts/box/Box';
import { ParsedMemberType } from 'types';
import { MemberType, MemberTypeEnum } from 'types/graphqlTypes';

interface MemberIconProps {
  member: MemberType | ParsedMemberType;
}
interface IconProps extends MemberIconProps {
  size?: number;
}

export const Icon = ({ member, size = 20 }: IconProps) => {
  if (member.mIcon)
    return (
      <Center style={{ width: size, height: size, flexShrink: 0, whiteSpace: 'nowrap' }}>
        {member.mIcon}
      </Center>
    );

  const TypeIcon = getIcon(member);
  if (!TypeIcon) return false;

  return (
    <TypeIcon
      style={{ width: size, height: size, flexShrink: 0 }}
      className={member.mType === MemberTypeEnum.Asset ? 'dimmed' : 'skipOverride'}
    />
  );
};

export function MemberIcon({ member }: Readonly<MemberIconProps>) {
  const supportsAvatar = member.mType ? hasAvatar.includes(member.mType) : false;

  if (supportsAvatar)
    return (
      <Avatar
        variant={member.mType as AvatarVariant}
        imageKey={member.mAvatarKey}
        size={24}
        title={member.mTitle}
      />
    );

  return <Icon member={member} />;
}
