import { primaryTypes } from 'utils/rundownItemTypes';

/**
 * Finds meta items given editor nodes
 *
 * @param {Object[]} nodes Editor nodes
 * @returns {Object[]} Meta items
 */

const filterEditorMeta = (nodes = undefined) => {
  if (!nodes) return [];

  const primaryMeta = Object.values(primaryTypes);

  return nodes.reduce((items, { type, data }) => {
    if (data?.automationType === 'PRIMARY' && primaryMeta.includes(type)) {
      items.push({
        title: type,
        itemId: data.itemId,
        templateType: data.templateType,
        templateVariant: data.templateVariant,
        iconUrl: data.iconUrl,
      });
    }
    return items;
  }, []);
};

export default filterEditorMeta;
