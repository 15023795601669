import { useCallback } from 'react';

import { useMultiSelect } from '../hooks/useMultiSelect';
import { type MultiSelectProps } from '../types';

import { StyledCommand } from './styled';

type CommandProps = Pick<MultiSelectProps, 'commandProps' | 'children'>;

/** Command is used as the combobox engine */
export const Command = ({ commandProps, children }: Readonly<CommandProps>) => {
  const { handleUnselect, selected, onSearch, creatable, inputRef } = useMultiSelect();

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      const input = inputRef.current;
      if (input) {
        // Remove the last selected option.
        if (e.key === 'Delete' || e.key === 'Backspace') {
          if (input.value === '' && selected.length > 0) {
            const lastSelectOption = selected[selected.length - 1];
            if (!lastSelectOption.fixed) {
              handleUnselect(selected[selected.length - 1]);
            }
          }
        }

        if (e.key === 'Escape') {
          input.blur();
          // Prevent closing the underlying dialog
          e.stopPropagation();
          e.preventDefault();
        }
      }
    },
    [handleUnselect, selected],
  );

  const commandFilter = useCallback(() => {
    if (commandProps?.filter) return commandProps.filter;

    if (creatable) {
      return (value: string, search: string) => {
        return value.toLowerCase().includes(search.toLowerCase()) ? 1 : -1;
      };
    }
    // Using default cmdk filter.
    return undefined;
  }, [creatable, commandProps?.filter]);

  // Do not filter if onSearch is provided. Override by setting shouldFilter.
  const shouldFilter = commandProps?.shouldFilter ?? !onSearch;

  return (
    <StyledCommand
      {...commandProps}
      onKeyDown={(e) => {
        handleKeyDown(e);
        commandProps?.onKeyDown?.(e);
      }}
      shouldFilter={shouldFilter}
      filter={commandFilter()}
    >
      {children}
    </StyledCommand>
  );
};
