import urlPrefix from 'components/editor/constants/urlPrefix';

const COLON_DOUBLE_SLASH = '://';

/**
 * if the href doesn't have any protocol prefix(https://, http://, ws://, etc.), we will add https:// prefix.
 * @param {string} href
 * @returns {string}
 */
const getCleanLink = (href) => {
  if (typeof href !== 'string') return '';

  if (href.startsWith('dina://')) {
    return href;
  }

  return href?.indexOf(COLON_DOUBLE_SLASH) > -1 ? href : `${urlPrefix.HTTPS_PREFIX}${href}`;
};

export default getCleanLink;
