import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  buttonRoot: {
    backgroundColor: 'transparent',
    padding: ({ padding }) => padding,
    '& path': {
      fillOpacity: 0.54,
    },
    '&:hover': {
      background: 'transparent',
      '& > :first-child': {
        '& > :first-child': {
          '& path': {
            fillOpacity: 1,
          },
        },
      },
    },
  },
}));

export default useStyles;
