import gql from 'graphql-tag';

export const USR_DATA_KEYS = {
  mRefId: 'usr_data',
  mType: 'usr_data',
};

export default gql`
  query GetUserData($input: GetMemberInput) {
    getMember(input: $input) {
      mId
      mRefId
      mType
      mSeen
      mMetaData {
        key
        value
      }
    }
  }
`;
