import { ReactNode } from 'react';
import { ConnectDragSource, DropTarget, DropTargetMonitor, useDrop } from 'react-dnd';
import {
  ApolloCache,
  DefaultContext,
  MutationFunctionOptions,
  OperationVariables,
} from '@apollo/client';

import useSettingsValue from 'hooks/useSettingsValue';
import memberTypes from 'operations/memberTypes';
import dndTypes from 'utils/dndTypes';

import { useInstanceMolecule } from '../store/instance';
import { StyledInstanceInnerWrapper, StyledInstanceWrapper } from '../styled';

interface Props {
  connectDropTarget: ConnectDragSource;
  handleUserDrop: (members: { mId: string; mType: string }[]) => Promise<void>;
  hovered: boolean;
  children: ReactNode;
  canUpdateInstance: boolean;
  storyId: string;
  updateRelatedMembersMutation: (
    options?:
      | MutationFunctionOptions<{}, OperationVariables, DefaultContext, ApolloCache<object>>
      | undefined,
  ) => Promise<void>;
}

const Instance = ({
  canUpdateInstance,
  connectDropTarget,
  children,
  hovered,
  storyId,
  updateRelatedMembersMutation,
}: Props) => {
  const { useInstanceValue } = useInstanceMolecule();
  const instance = useInstanceValue();

  const [getSettingsValue] = useSettingsValue();
  const isDefaultOneWayLink = getSettingsValue('app.defaultOneWayInstanceLinks') === 'true';

  const [{ isInstanceHovered }, dropRef] = useDrop({
    accept: [dndTypes.INSTANCE_LIST_ITEM],
    drop: (item, monitor: DropTargetMonitor<{ payload: { mId: string } }>) => {
      if (!canUpdateInstance) return undefined;
      void updateRelatedMembersMutation({
        variables: {
          input: {
            mId: instance?.mId,
            mRelatedMembers: [
              {
                mId: monitor.getItem().payload.mId || '',
                crudAction: 'CREATE',
              },
            ],
            isUniDirectional: isDefaultOneWayLink,
          },
        },
      });
      return { id: storyId };
    },
    canDrop: (_, monitor: DropTargetMonitor<{ payload: { mId: string } }>) =>
      monitor.getItem().payload.mId !== instance?.mId,
    collect: (monitor) => ({
      isInstanceHovered: canUpdateInstance && monitor.canDrop() && monitor.isOver(),
    }),
  });

  return connectDropTarget(
    <div ref={dropRef} style={{ width: '100%', height: '100%' }}>
      <StyledInstanceWrapper $hovered={hovered} $isInstanceHovered={isInstanceHovered}>
        {instance && <StyledInstanceInnerWrapper>{children}</StyledInstanceInnerWrapper>}
      </StyledInstanceWrapper>
    </div>,
  );
};

export default DropTarget(
  dndTypes.MEMBER,
  {
    drop({ handleUserDrop, storyId }: Props, monitor) {
      const item: { type: string; id: string } = monitor.getItem();
      if (item.type !== memberTypes.USER) return undefined;

      void handleUserDrop([{ mId: item.id, mType: item.type }]);
      return { id: storyId };
    },
    canDrop(props: Props, monitor) {
      const item: { type: string; id: string } = monitor.getItem();
      const canDrop = props.canUpdateInstance && item.type === memberTypes.USER;
      return canDrop;
    },
  },
  (connect, monitor) => {
    let hovered = false;
    // https://stackoverflow.com/questions/63146094/react-dnd-expected-to-find-a-valid-target
    let timeout: NodeJS.Timeout | null = null;

    const updateHovered = () => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        hovered = monitor.canDrop() && monitor.isOver();
      }, 50);
    };

    updateHovered();

    return {
      connectDropTarget: connect.dropTarget(),
      hovered,
    };
  },
)(Instance);
