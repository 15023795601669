import { useState } from 'react';

import Footer from './components/footer/Footer';
import Header from './components/header/AppMenuHeader';
import Info from './components/info';
import MobileConfig from './components/mobileConfig';

import { StyledVStack } from './styled';

interface AppMenuProps {
  username: string;
  avatar: string;
  onLogout: () => Promise<void>;
  onClose: () => void;
  setSettingOpen: () => void;
}

export default function AppMenu({
  avatar = '',
  username = '',
  onLogout,
  onClose,
  setSettingOpen,
}: AppMenuProps) {
  const [showQR, setShowQR] = useState(false);
  return (
    <StyledVStack>
      <Header {...{ avatar, username, onClose, showQR, setShowQR }} />

      {showQR ? (
        <MobileConfig />
      ) : (
        <Info setShowQR={setShowQR} setSettingOpen={setSettingOpen} onClose={onClose} />
      )}
      <Footer {...{ onLogout }} />
    </StyledVStack>
  );
}
