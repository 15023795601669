import { VesselComponents, vesselMap } from './utils/vesselMap';

export interface VesselProps {
  children: React.ReactNode;
  onClose: () => void;
  open: boolean;
  type?: VesselComponents;
  title?: string;
}

/** Factory for rendering the correct preview component */
export default function VesselFactory({
  type = VesselComponents.DRAWER,
  ...rest
}: Readonly<VesselProps>) {
  const PreviewComponent = vesselMap[type];
  if (!PreviewComponent) return null;

  return <PreviewComponent {...rest} />;
}
