import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { elementTypes } from 'components/editor/constants/types';
import DragAndDrop from '../dragAndDrop';

const HorizontalRuleWrapper = styled('div')`
  padding: 4px 8px;
`;

const StyledHorizontalRule = styled('hr')`
  border-color: ${({ theme }) => theme.palette.dina.dividerLight};
  border-style: solid;
`;

const HorizontalRule = ({ attributes, children, element }) => (
  <div {...attributes}>
    <DragAndDrop element={element} isDragDisabled>
      {children}
      <HorizontalRuleWrapper contentEditable={false}>
        <StyledHorizontalRule />
      </HorizontalRuleWrapper>
    </DragAndDrop>
  </div>
);

HorizontalRule.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

HorizontalRule.defaultProps = {
  attributes: {},
  children: null,
  element: {
    type: elementTypes.HORIZONTAL_RULE,
    children: [],
  },
};

export default memo(HorizontalRule);
