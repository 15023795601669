import { MMetaDataField } from 'types/graphqlTypes';

export const FLOAT = 'isFloat';

const ifIncludedIn =
  (array: MMetaDataField[] = []) =>
  (str: string) =>
    array.find((item) => item.key.includes(str));

const isFloat = (metadata: MMetaDataField[] = []) => {
  const float = ifIncludedIn(metadata)(FLOAT);
  return float?.value === 'true';
};

export default isFloat;
