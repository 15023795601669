import { useEffect, useMemo, useState } from 'react';
import { cloneDeep } from 'lodash';

import Scrollbar from 'components/scrollbar/scrollbar';
import { PanelType } from 'types/members';

import { useSelectedMenuSystemSettings } from '../../../../../atoms';
import { useChangedPanels, usePanels } from '../../../../../atomsTs';
import fieldTypes from '../../../../../utils/fieldTypes';
import SettingsContent from '../settingsContent/settingsContent-view';

import { RootWrapper, Warning } from './styled';
type KeyValue = {
  key: string;
  type: string;
};
const panelTypesOptions = [
  { id: 'mos', title: 'Mos automation', value: 'mos' },
  { id: 'graphics', title: 'Graphics', value: 'pilotedge' },
  { id: 'assets', title: 'Assets', value: 'mimir' },
  { id: 'generic', title: 'Generic', value: 'generic' },
];

const fieldProperties = [
  {
    key: 'title',
    label: 'Title',
    type: fieldTypes.TEXT,
    propName: 'name',
    placeholder: 'Site name',
    description: 'Site name',
  },
  {
    key: 'type',
    label: 'Panel type',
    type: fieldTypes.SELECT,
    propName: 'type',
    placeholder: 'generic',
    description: 'The function type of the html panel',
  },
  {
    key: 'url',
    label: 'Panel Url',
    type: fieldTypes.TEXT,
    propName: 'value',
    placeholder: 'https://...',
    description: 'URL to the HTML panel',
  },
  {
    key: 'showInRightPanel',
    label: 'Show in right nav bar?',
    type: fieldTypes.BOOLEAN,
    propName: 'readonly',
    description: 'Show in right nav bar?',
  },
  {
    key: 'panelIcon',
    label: 'Panel Icon',
    type: fieldTypes.TEXT,
    propName: 'value',
    placeholder: 'type icon name or url',
    description: 'URL to icon of the site ("http://....)    or icon name (graphics, assets, mos)',
  },
  {
    key: 'mosId',
    label: 'mos Id',
    type: fieldTypes.TEXT,
    propName: 'mosId',
    placeholder: 'mosId',
  },
  {
    key: 'mosObjectType',
    label: 'mos Object Type',
    type: fieldTypes.TEXT,
    propName: 'mosObjectType',
    placeholder: 'mosObjectType',
  },
  {
    key: 'iconUrl',
    label: 'Mos Icons Url',
    type: fieldTypes.TEXT,
    propName: 'iconUrl',
    placeholder: 'iconUrl',
  },
  {
    key: 'iconXmlTag',
    label: 'Mos Icons Xml Tag',
    type: fieldTypes.TEXT,
    propName: 'iconXmlTag',
    placeholder: 'xml tag for icon',
  },
];

const standardFields = ['title', 'type', 'url'];
const mosFields = ['mosId', 'mosObjectType', 'iconUrl', 'iconXmlTag'];

const getIncludedFields = (panel: PanelType) => {
  const iniConfig = panel?.configs?.length ? panel.configs[0] : null;
  const { type = 'generic' } = iniConfig || {};
  const showInRightPanel = panel.configs?.find((config) => config.key === 'showInRightPanel');
  const includeFields = [...standardFields];

  if (type === 'generic' || showInRightPanel?.value === 'true') {
    includeFields.push('showInRightPanel');
  }

  if (showInRightPanel?.value === 'true') {
    includeFields.push('panelIcon');
  }

  if (type === 'mos') includeFields.push(...mosFields);
  if (type === 'pilotedge') {
    includeFields.push('mosId');
  }
  return includeFields;
};

function HtmlPanelDetails() {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [selectedSettings] = useSelectedMenuSystemSettings();
  const [changedPanels, setChangedPanels] = useChangedPanels();
  const [panels] = usePanels();
  const [valueChanged, setValueChanged] = useState(false);
  const [warning, setWarning] = useState('');

  const panel: PanelType | undefined = useMemo(() => {
    const foundChangedPanel = changedPanels?.find(
      (pnl: PanelType) => pnl.mRefId === selectedSettings,
    );
    if (foundChangedPanel) return foundChangedPanel;

    const orgPanel = panels?.find((pnl: PanelType) => pnl.mRefId === selectedSettings);

    if (orgPanel) return cloneDeep(orgPanel);

    return undefined;
  }, [changedPanels, selectedSettings, panels]);

  const checkExisting = (type: string): PanelType | undefined => {
    const mainPanels = panels?.filter(
      (item1: PanelType) =>
        !changedPanels.some((item2: PanelType) => item1.mRefId === item2.mRefId),
    );
    const mergedPanels = [...mainPanels, ...changedPanels];
    const existing = mergedPanels?.find((pnl: PanelType) =>
      pnl.configs?.length ? pnl.configs[0].type === type : false,
    );
    return existing || undefined;
  };

  const getValue = (pnl: PanelType | undefined, property: KeyValue) => {
    if (!pnl) return '';
    const { configs } = pnl;
    const { key, type } = property;
    if (key === 'title') {
      return pnl.mTitle;
    }
    if (key === 'type') {
      return pnl.configs?.length ? pnl.configs[0].type : '';
    }
    const value = configs?.find((config) => config.key === key)?.value;
    if (type === fieldTypes.BOOLEAN) {
      return value?.toString() === 'true';
    }
    if (type === fieldTypes.NUMBER) {
      return value?.toString();
    }
    return value || '';
  };

  useEffect(() => {
    setWarning('');
  }, [selectedSettings]);

  const setValue = (pnl: PanelType, key: string, value: string) => {
    if (key === 'type' && (value === 'mos' || value === 'pilotedge' || value === 'mimir')) {
      const existing = checkExisting(value);
      if (existing && pnl.configs?.length) {
        pnl.configs[0].type = 'generic';
        const optionTitle = panelTypesOptions.find((option) => option.value === value);
        setWarning(
          `Only one panel of type "${optionTitle?.title || ''}" is allowed. Panel "${
            existing?.mTitle || ''
          }" is already defined with panel type "${optionTitle?.title || ''}"`,
        );
        return;
      }
    }

    setWarning('');
    const { configs } = pnl;
    const strVal = value?.toString();
    if (key === 'title') {
      pnl.mTitle = strVal;
      return;
    }
    if (key === 'type') {
      if (!configs?.length) return;
      configs[0].type = strVal;

      if (strVal === 'externalurl' || strVal === 'generic') {
        configs[0].name = pnl.mRefId;
        return;
      }
      configs[0].name = strVal;

      return;
    }

    const cfg = configs?.find((config) => config.key === key);

    if (!cfg) {
      if (!configs) return;
      configs.push({ key, value: strVal });
    }
    if (cfg) {
      cfg.value = strVal;
    }
  };

  const getFieldOptions = (key: string) => {
    switch (key) {
      case 'type':
        return panelTypesOptions;

      default:
        return [];
    }
  };

  const getPanelFields = () => {
    if (!panel) return [];
    const includedFields = getIncludedFields(panel);
    const ret = fieldProperties.filter((prop) => includedFields.includes(prop.key));
    return ret;
  };

  const onChangedPanelField = (val: string, contentKey: string) => {
    if (!panel) return;
    const changedPanel = changedPanels.find((pnl: PanelType) => pnl.mRefId === panel?.mRefId);
    setValue(panel, contentKey, val);
    if (!changedPanel) {
      const mergedPanels: PanelType[] = [...changedPanels, panel];
      setChangedPanels(mergedPanels);
    }
    setValueChanged(!valueChanged);
  };

  if (!panel) return <Warning>Select panel</Warning>;
  return (
    <RootWrapper>
      {warning && <Warning>{warning}</Warning>}
      <Scrollbar>
        {panel && (
          <>
            {getPanelFields().map((property) => (
              <SettingsContent
                key={`${panel?.mRefId || ''}:${property.key}`}
                contentKey={property.key}
                label={property.label}
                type={property.type}
                value={getValue(panel, property)}
                description={property.description}
                placeholder={property.placeholder}
                onChange={onChangedPanelField}
                changedSettings={[]}
                options={getFieldOptions(property.key)}
              />
            ))}
          </>
        )}
      </Scrollbar>
    </RootWrapper>
  );
}

export default HtmlPanelDetails;
