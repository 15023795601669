import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { Menu, MenuItem } from '@material-ui/core';

import { ReactComponent as DownSvg } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_down.svg';
import { ReactComponent as RightSvg } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_right.svg';
import { ReactComponent as CloseSvg } from 'assets/icons/systemicons/close.svg';
import { ReactComponent as OwnerIconSvg } from 'assets/icons/systemicons/owner_indicator.svg';
import { ReactComponent as PlusSvg } from 'assets/icons/systemicons/plus_small_circle.svg';
import { ReactComponent as SearchSvg } from 'assets/icons/systemicons/search.svg';
import { ReactComponent as TaskSvg } from 'assets/icons/systemicons/task_small.svg';
import Text from 'components/text/Text';
import { SearchField } from 'features/search/styled';
import { Box } from 'layouts/box/Box';
import { CollapsibleTrigger as RCollapsibelTrigger } from 'lib/collapsible';
import transientOptions from 'theme/helpers';

const iconStyle = ({ theme }: { theme: Theme }) => css`
  path {
    fill: ${theme.palette.dina.highEmphasis};
  }
`;

export const StyledPreviewButton = styled(Box)`
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.palette.dina.iconInactive};
  * {
    fill: ${({ theme }) => theme.palette.dina.highEmphasis};
    fill-opacity: 0.54;
  }
  &:hover {
    border-color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
    * {
      fill: ${({ theme }) => theme.palette.dina.highEmphasis};
      fill-opacity: 1;
    }
  }
`;

export const ContentWrapper = styled('div')`
  padding: 8px 16px 16px;
  height: calc(100% - 32px);
  overflow-y: auto;
`;

export const FieldWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  gap: 4px;
`;

export const Button = styled('button', transientOptions)`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.dina.inputBackground};
  border: none;
  cursor: pointer;
  padding: 0 2px;
  border-bottom: 1px solid transparent;
  overflow: hidden;
  display: flex;
  align-items: center;
  filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.25));
  height: 48px;
  border-color: ${({ theme }) => theme.palette.dina.outlineButtonOnHover};
  &.group-open {
    border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  }
  :hover {
    background-color: ${({ theme }) => theme.palette.dina.outlineButtonOnHover};
  }
  :focus-visible {
    outline: 2px solid ${({ theme }) => theme.palette.dina.statusOnFocused};
  }

  &[aria-expanded='true'] {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export const MdfWrapper = styled('div')`
  margin: 0px 8px 8px;
`;

export const ListWrapper = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  gap: 8px;
  padding: 8px;
`;

interface SelectionIndicatorProps {
  $selected: boolean;
  $color?: string;
}

export const SelectionIndicator = styled('div', transientOptions)<SelectionIndicatorProps>`
  box-shadow: ${({ $selected, theme }) =>
    $selected
      ? `0 0 0 1px ${theme.palette.dina.statusOnFocused}`
      : `0 0 0 1px ${theme.palette.dina.borderResting}`};
  border-radius: 6px;
  position: relative;
  cursor: pointer;
  padding-left: 4px;
  width: 100%;
  ::before {
    position: absolute;
    left: 0;
    top: 0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    content: '';
    width: 4px;
    height: 100%;
    background-color: ${({ theme, $color }) => $color ?? theme.palette.dina.borderHover};
  }
  button[data-state='open'] {
    & .right {
      display: none;
    }
  }
  button[data-state='closed'] {
    & .down {
      display: none;
    }
  }
`;

export const LabelWrapper = styled(Box)`
  user-select: none;
  width: calc(100% - 24px);
  height: 100%;
  ${StyledPreviewButton} {
    display: none;
  }
  :hover {
    ${StyledPreviewButton} {
      display: flex;
    }
  }
`;

export const StyledText = styled(Text)`
  text-align: left;
  flex: 1;
`;

export const SearchWrapper = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  background-color: ${({ theme }) => theme.palette.dina.inputBackground};
  padding: 0 4px;
  gap: 4px;

  ${SearchField} {
    background-color: ${({ theme }) => theme.palette.dina.inputBackground};
    flex: 1;
  }
`;

export const FilterWrapper = styled(Box)``;

export const CloseIcon = styled(CloseSvg)`
  cursor: pointer;
  ${iconStyle};
  :hover {
    path {
      fill-opacity: 1;
    }
  }
`;

export const SearchIcon = styled(SearchSvg)`
  path {
    fill: ${({ theme }) => theme.palette.dina.highEmphasis};
    fill-opacity: 0.7;
  }
`;

export const AddIconWrapper = styled(Box)`
  border-right: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

export const PlusIcon = styled(PlusSvg)`
  cursor: pointer;
  path {
    fill: ${({ theme }) => theme.palette.dina.highEmphasis};
    fill-opacity: 0.7;
  }
  :hover {
    path {
      fill-opacity: 1;
    }
  }
`;

export const StyledMenu = styled(Menu)`
  & .MuiMenu-paper {
    background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel2};
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    width: 220px;
    height: 32px;
    &.MuiListItem-root {
      ${({ theme }) => theme.typography.dina.listItemLabel};
    }
  }
`;

export const OwnerIcon = styled(OwnerIconSvg)`
  position: absolute;
  top: -2px;
  right: -2px;
`;

export const DownIcon = styled(DownSvg)`
  ${iconStyle};
`;

export const RightIcon = styled(RightSvg)`
  ${iconStyle};
`;

export const TaskIcon = styled(TaskSvg)`
  ${iconStyle};
  path {
    fill-opacity: 0.7;
  }
`;

export const CollapsibleTrigger = styled(RCollapsibelTrigger)`
  :focus-visible {
    outline: none;
  }
`;
