import { Dictionary, keyBy } from 'lodash';

import { isMiniMemberArray } from 'components/mdfEditor/fields/relation/relation-utils';
import { getSubMdf, hasPermission, shouldFilterField } from 'features/mdf/mdf-utils';
import { Metadata } from 'types/forms/forms';
import { FieldTypeEnum, Mdf, MdfField, MemberTypeEnum } from 'types/graphqlTypes';

export const findContactIdsInMetadata = (
  mdf: Mdf | undefined,
  metadata: Metadata,
  groups: string[],
  subTypes: Dictionary<Mdf>,
): string[] => {
  if (!mdf?.fields || !mdf.views?.default) return [];

  const contacts = new Set<string>();
  const settingsMap = keyBy(mdf.views.default, 'fieldId');

  const hasReadPermission = (fieldId: string) =>
    hasPermission(mdf.permissions?.read[fieldId], groups);

  const processUserField = (field: MdfField) => {
    if (field.filter?.includes('contact')) {
      const fieldValue = metadata[field.fieldId]?.toString();
      if (fieldValue) {
        contacts.add(fieldValue);
      }
    }
  };

  const processRelationField = (field: MdfField) => {
    const fieldValue = metadata[field.fieldId];
    if (isMiniMemberArray(fieldValue)) {
      for (const member of fieldValue) {
        if (member.type === MemberTypeEnum.Contact) {
          contacts.add(member.id);
        }
      }
    }
  };

  const processSubtypeField = (field: MdfField) => {
    const subMdf = getSubMdf(field, metadata, subTypes);
    if (subMdf) {
      const subContactIds = findContactIdsInMetadata(subMdf, metadata, groups, subTypes);
      subContactIds.forEach((subContactId) => {
        contacts.add(subContactId);
      });
    }
  };

  for (const field of mdf.fields) {
    if (
      !shouldFilterField(field, settingsMap, settingsMap, false, hasReadPermission(field.fieldId))
    )
      continue;

    switch (field.type) {
      case FieldTypeEnum.user:
        processUserField(field);
        break;
      case FieldTypeEnum.relation:
        processRelationField(field);
        break;
      case FieldTypeEnum.subtype:
        processSubtypeField(field);
        break;
    }
  }

  return Array.from(contacts);
};
