/* eslint-disable max-len */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelected, useReadOnly } from 'slate-react';
import variants from 'components/editor/constants/types/editorVariants';
import { useEditorContext } from 'components/editor/EditorContext';
import { elementTypes } from 'components/editor/constants/types';
import styled from '@emotion/styled';

const StyledParagraph = styled('p')`
  z-index: 100;
  margin: 0px;
  padding: 8px;
  background: ${({ showHighlight, theme }) =>
    showHighlight && theme.palette.dina.blackHoverOverlay};
  border-radius: 4px;
`;

const SimpleParagraph = ({ attributes, children, element }) => {
  const readOnly = useReadOnly();
  const isSelected = useSelected(element);
  const { variant } = useEditorContext();

  const showHighlight = !readOnly && isSelected && variant !== variants.MESSAGE;
  return (
    <StyledParagraph showHighlight={showHighlight} {...attributes}>
      {children}
    </StyledParagraph>
  );
};
SimpleParagraph.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

SimpleParagraph.defaultProps = {
  attributes: {},
  children: null,
  element: {
    type: elementTypes.PARAGRAPH,
    data: { indentLevel: 0 },
    children: [],
  },
};

export default memo(SimpleParagraph);
