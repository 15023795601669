/* eslint-disable react/no-danger */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { PreviewWrapper, HtmlContent } from './styled';

const Preview = <PreviewWrapper>Preview</PreviewWrapper>;

const createMarkup = (html) => {
  const input = html?.replaceAll('\\', '' ?? '');
  return {
    __html: `${input}`,
  };
};

const HtmlThumbnail = ({ html }) => {
  if (!html) return Preview;
  return <HtmlContent dangerouslySetInnerHTML={createMarkup(html)} />;
};

HtmlThumbnail.propTypes = {
  /** html content as a string */
  html: PropTypes.string,
};
HtmlThumbnail.defaultProps = {
  html: null,
};

export default memo(HtmlThumbnail);
