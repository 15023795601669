/* eslint-disable import/no-extraneous-dependencies */
import { useContext, useEffect, useRef } from 'react';
import { useApolloClient } from '@apollo/client';
import { isWithinRange } from 'date-fns';
import { cloneDeep } from 'lodash';
import gql from 'graphql-tag';

import UserCtx from 'contexts/UserContext';
import memberTypes from 'operations/memberTypes';
import GET_MEMBERS_OF from 'operations/queries/getMembersOf';
import GET_SPACES from 'operations/queries/searchApi';
import { getMembersOfQuery } from 'operations/queryVariables';
import {
  scheduleTypes,
  subtabs,
  useSidebar,
  useSidebarDatePickerAtom,
  useSidebarSelectedSubtab,
} from 'store/sidebar';
import useLogger from 'utils/useLogger';
import { CrudActionEnum } from 'types/graphqlTypes';

const ARCHIVED_TYPE_PREFIX = `${scheduleTypes.ARCHIVED}_`;

const isArchived = (mType) => mType.startsWith(ARCHIVED_TYPE_PREFIX);

const GET_STORIES = gql`
  query SearchApi($filter: SearchFilter, $nextToken: String, $limit: Int) {
    searchApi(filter: $filter, nextToken: $nextToken, limit: $limit) {
      items {
        mId
        mRefId
        mType
      }
      nextToken
    }
  }
`;

const getIsUnscheduled = (scheduleType, leftSelection) => {
  if (leftSelection === memberTypes.STORY || leftSelection === memberTypes.PITCH) {
    if (scheduleType === scheduleTypes.SCHEDULED) return false;
    if (scheduleType === scheduleTypes.UNSCHEDULED) return true;
  }
  return undefined;
};

const getAssignedMembersMIds = (client, userId, type) => {
  const members = client.readQuery({
    query: GET_MEMBERS_OF,
    variables: getMembersOfQuery(userId, type),
  });
  const memberIds = members.getMembersOf.map((member) => member.mId);
  return memberIds;
};

const getAssignedMembers = (client, leftSelection, userId) => {
  switch (leftSelection) {
    case memberTypes.USER:
      return [];
    case memberTypes.TEAM_USER:
      return getAssignedMembersMIds(client, userId, memberTypes.TEAM_USER);
    case memberTypes.DEPARTMENT_USER:
      return getAssignedMembersMIds(client, userId, memberTypes.DEPARTMENT_USER);
    default:
      return [];
  }
};

const getRelationType = (parentType, storyType) => {
  if (parentType === memberTypes.TEAM) {
    switch (storyType) {
      case memberTypes.PITCH:
      case memberTypes.RESTRICTED_PITCH:
        return memberTypes.TEAM_PITCH;
      default:
        return memberTypes.TEAM_STORY;
    }
  }

  if (parentType === memberTypes.DEPARTMENT) {
    switch (storyType) {
      case memberTypes.PITCH:
      case memberTypes.RESTRICTED_PITCH:
        return memberTypes.DEPARTMENT_PITCH;
      default:
        return memberTypes.DEPARTMENT_STORY;
    }
  }

  if (isArchived(storyType)) return storyType;

  if (storyType === memberTypes.INSTANCE) {
    return memberTypes.INSTANCE;
  }

  return storyType === memberTypes.PITCH || storyType === memberTypes.RESTRICTED_PITCH
    ? memberTypes.USER_PITCH
    : memberTypes.USER_STORY;
};

const writeToCache = (client, member, variables, logger, isAddAction = true, isSpaceType) => {
  try {
    const storyList = client.readQuery({
      query: isSpaceType ? GET_SPACES : GET_STORIES,
      variables,
    });

    const { items, nextToken } = storyList?.searchApi || {};
    const item = items?.find((i) => i.mId === member.mId);

    if (isAddAction && item) return;

    // TODO - figure out why we need to inject this to make the cache work.
    const copy = cloneDeep(member);
    if (isSpaceType) {
      copy.mThumbnailUrl = '';
      copy.mCreatedAt = '';
      copy.mState = '';
      copy.mPublishingEnd = '';
      copy.isRestricted = false;
      copy.mAssignedMembers = [];
      copy.mPriority = '';
      copy.mPublishingAt = '';
      copy.mThumbnailKey = '';
    }

    const filteredMembers = items?.filter((i) => i.mId !== member.mId);
    const newItems = isAddAction ? [copy, ...filteredMembers] : filteredMembers;

    client.writeQuery({
      query: isSpaceType ? GET_SPACES : GET_STORIES,
      variables,
      data: {
        searchApi: {
          items: newItems,
          __typename: 'PaginatedMemberType',
          nextToken,
        },
      },
    });
  } catch (err) {
    logger.error(err);
  }
};

const getMTypes = (leftSelection, scheduleType) => {
  switch (leftSelection) {
    case memberTypes.STORY:
      return [
        scheduleType !== scheduleTypes.ARCHIVED ? memberTypes.STORY : memberTypes.ARCHIVED_STORY,
      ];
    case memberTypes.PITCH:
      return [
        scheduleType !== scheduleTypes.ARCHIVED ? memberTypes.PITCH : memberTypes.ARCHIVED_PITCH,
      ];
    case memberTypes.SPACE:
      return [memberTypes.SPACE];
    case memberTypes.USER:
    case memberTypes.TEAM_USER:
    case memberTypes.DEPARTMENT_USER:
      return [memberTypes.STORY, memberTypes.PITCH];
    case memberTypes.INSTANCE:
      return [
        scheduleType !== scheduleTypes.ARCHIVED
          ? memberTypes.INSTANCE
          : memberTypes.ARCHIVED_INSTANCE,
      ];
    default:
      return [];
  }
};

const shouldUpdateCache = (
  member,
  createType,
  leftSelection,
  scheduleType,
  user,
  currentFilter,
) => {
  if (leftSelection === memberTypes.USER_BOOKMARK || leftSelection === memberTypes.RUNDOWN_USER)
    return false;

  const isTeamStory =
    createType === memberTypes.TEAM_STORY || createType === memberTypes.TEAM_PITCH;

  const isDepartmentStory =
    createType === memberTypes.DEPARTMENT_STORY || createType === memberTypes.DEPARTMENT_PITCH;

  const isUserPitch = createType === memberTypes.USER_PITCH;
  const isUserStory = createType === memberTypes.USER_STORY;
  const isInstance = createType === memberTypes.INSTANCE;
  const isSpace = createType === memberTypes.SPACE;

  const spacesSelected = leftSelection === memberTypes.SPACE;
  const userStoriesSelected = leftSelection === memberTypes.USER;
  const teamStoriesSelected = leftSelection === memberTypes.TEAM_USER;
  const departmentStoriesSelected = leftSelection === memberTypes.DEPARTMENT_USER;
  const allPitchesSelected = leftSelection === memberTypes.PITCH;
  const allStoriesSelected = leftSelection === memberTypes.STORY;
  const allInstancesSelected = leftSelection === memberTypes.INSTANCE;

  if (spacesSelected && isSpace) return true;

  if (
    allStoriesSelected &&
    scheduleType === scheduleTypes.ARCHIVED &&
    (createType === memberTypes.ARCHIVED_STORY ||
      createType === memberTypes.ARCHIVED_RESTRICTED_STORY)
  )
    return true;

  if (
    allPitchesSelected &&
    scheduleType === scheduleTypes.ARCHIVED &&
    (createType === memberTypes.ARCHIVED_PITCH ||
      createType === memberTypes.ARCHIVED_RESTRICTED_PITCH)
  )
    return true;

  if (
    allInstancesSelected &&
    scheduleType === scheduleTypes.ARCHIVED &&
    createType === memberTypes.ARCHIVED_INSTANCE
  )
    return true;

  if (
    currentFilter.assignedMembers?.length &&
    !member.mAssignedMembers?.some((m) => !!currentFilter.assignedMembers?.includes(m.mId))
  ) {
    return false;
  }

  if ((isUserStory || isUserPitch) && userStoriesSelected) return true;

  if (currentFilter.userItemsOnly && !member.mAssignedMembers?.find((m) => m.mId === user.mId)) {
    return false;
  }

  if ((isUserStory || isUserPitch) && userStoriesSelected) return true;
  if (isTeamStory && teamStoriesSelected) return true;
  if (isDepartmentStory && departmentStoriesSelected) return true;

  const isScheduled = Boolean(member?.mPublishingAt);

  const matchedScheduleType =
    scheduleType === scheduleTypes.ALL ||
    (!isScheduled && scheduleType === scheduleTypes.UNSCHEDULED) ||
    (isScheduled && scheduleType === scheduleTypes.SCHEDULED);

  if (isUserPitch && allPitchesSelected && matchedScheduleType) {
    return true;
  }

  if (isUserStory && allStoriesSelected && matchedScheduleType) return true;

  if (!member?.mProperties?.platform) return false;

  const { platform } = member?.mProperties ?? {};

  const matchedPlatform =
    !currentFilter.platforms?.length || currentFilter.platforms.includes(platform);

  return isInstance && allInstancesSelected && matchedScheduleType && matchedPlatform;
};

const useUpdateLeftSidebarCache = () => {
  const logger = useLogger('useUpdateLeftSidebarCache');
  const client = useApolloClient();
  const user = useContext(UserCtx);
  const [{ leftSelection: leftSelectedTab }] = useSidebar();
  const [selectedDates] = useSidebarDatePickerAtom();
  const { startDate, endDate } = selectedDates;
  const newDate = new Date().toISOString();

  const [selectedSubtab] = useSidebarSelectedSubtab();
  const selectedScheduleType = subtabs[selectedSubtab];

  const allowedSubscriptions = ['create', 'remove', 'delete', 'insert', 'update_type'];

  /* For some reason  update function do no get updated state of context properties.
  Handling this by using ref */
  const leftSelectionRef = useRef(leftSelectedTab);
  const scheduleTypeRef = useRef(selectedScheduleType);
  const selectedSubtabRef = useRef(selectedSubtab);

  useEffect(() => {
    leftSelectionRef.current = leftSelectedTab;
  }, [leftSelectedTab]);

  useEffect(() => {
    scheduleTypeRef.current = selectedScheduleType;
  }, [selectedScheduleType]);

  useEffect(() => {
    selectedSubtabRef.current = selectedSubtab;
  }, [selectedSubtab]);

  const update = (member, createType, isAddAction = true) => {
    const leftSelection = leftSelectionRef.current;
    if (!member) return;

    if (member.crudAction?.toLowerCase() === 'update') return;

    const scheduleType = scheduleTypeRef.current;
    const currentFilter = getFilter();
    if (currentFilter.searchString) return;

    if (
      isAddAction &&
      !shouldUpdateCache(member, createType, leftSelection, scheduleType, user, currentFilter)
    )
      return;

    // Existing bug discovered, newDate is before startDate, this code crashes and
    // affects downstream code such as hanging spinners.
    try {
      if (!isWithinRange(newDate, startDate, endDate)) return;
    } catch (err) {
      console.error('Handle error: ', err);
      return;
    }
    const defaultFilter = {
      searchString: '',
      isUnscheduled: getIsUnscheduled(scheduleType, leftSelection),
      mTypes: getMTypes(leftSelection, scheduleType),
      assignedMembers: getAssignedMembers(client, leftSelection, user.mId),
      searchBefore: endDate ? endDate.toISOString() : undefined,
      searchAfter: startDate ? startDate.toISOString() : undefined,
    };

    const filter = {
      searchString: currentFilter.searchString || defaultFilter.searchString,
      mTypes: getMTypes(leftSelection, scheduleType),
      assignedMembers: currentFilter.assignedMembers,
      userItemsOnly: currentFilter.userItemsOnly,
    };

    const spaceFilter = {
      mTypes: [memberTypes.SPACE],
    };

    if (currentFilter.isUnscheduled !== undefined)
      filter.isUnscheduled = currentFilter.isUnscheduled;

    if (currentFilter.searchBefore) filter.searchBefore = currentFilter.searchBefore;
    if (currentFilter.searchAfter) filter.searchAfter = currentFilter.searchAfter;

    if (createType === memberTypes.INSTANCE || createType === memberTypes.ARCHIVED_INSTANCE) {
      filter.platformsFilter = currentFilter.platformsFilter;
    }

    const isSpaceType = member?.mType === memberTypes.SPACE;

    const variables = {
      limit: isSpaceType ? 100 : 25,
      filter: isSpaceType ? spaceFilter : filter,
    };

    writeToCache(client, member, variables, logger, isAddAction, isSpaceType);
  };

  /**
   * Depending on the `mType` of the data, we will know if the operation is
   * archiving / un-archiving of a story. It will be determined by if
   * `mType` contains the string `archived` in it or not.
   *
   * Now depending on `crudAction` and `selectedSubTab`, the story will be added to
   * or removed from the left sidebar.
   *
   * If `crudAction` is `CREATE` that means this is an archiving operation. So we will add the
   * story to the archived subTab if it is open and
   * remove it from the other subTabs if any other subTab is open.
   *
   * If `crudAction` is `REMOVE` that means this is an un-archiving operation.
   * So we will remove it from archived subTab and add it to other subTabs in the same manner.
   */
  const getMember = (data, currentSubTab) => {
    const isArchivedSubTabOpen = currentSubTab === 'archived';
    const { crudAction, mType } = data;
    const member = { ...data };

    if (!isArchived(mType)) return member;

    if (isArchivedSubTabOpen) return member;

    if (crudAction === 'CREATE' || crudAction === 'REMOVE') {
      member.crudAction = crudAction === 'CREATE' ? 'REMOVE' : 'CREATE';
      member.mType = member.mType.slice(9);
      return member;
    }

    return member;
  };

  const shouldAdd = (crudAction, mType) => {
    if (crudAction === 'CREATE') return true;
    if (crudAction === 'INSERT') return !isArchived(mType);
    return false;
  };

  const updateOnSubscription = (data, userId) => {
    const member = getMember(data, subtabs[selectedSubtabRef.current]);

    const { crudAction, mType, mAssignedMembers } = member;

    const isRestricted = mType.includes('res_');

    if (!crudAction || !allowedSubscriptions.includes(crudAction.toLowerCase())) return;

    const leftSelection = leftSelectionRef.current;

    const createType = getRelationType(memberTypes.USER, mType);

    const addItem = shouldAdd(crudAction, mType);

    const currentFilter = getFilter();

    if (currentFilter.searchString) return;

    // adding restricted story to the list will be supported in later version
    if (addItem && isRestricted) return;

    if (
      crudAction === CrudActionEnum.Update_Type &&
      leftSelection === memberTypes.STORY &&
      !isRestricted
    ) {
      update(member, createType, true);
      return;
    }

    if (crudAction === CrudActionEnum.Update_Type && leftSelection === memberTypes.PITCH) {
      update(member, createType, false);
      return;
    }

    if (
      leftSelection === memberTypes.STORY ||
      leftSelection === memberTypes.PITCH ||
      leftSelection === memberTypes.INSTANCE ||
      leftSelection === memberTypes.SPACE
    ) {
      update(member, createType, addItem);
      return;
    }

    if (leftSelection === memberTypes.USER) {
      const assignedUser = mAssignedMembers.find(
        (m) => m.mType === memberTypes.USER && m.mId === userId,
      );
      if (!assignedUser) return;
      update(member, createType, addItem);
      return;
    }

    if (
      leftSelection === memberTypes.TEAM_USER ||
      leftSelectedTab === memberTypes.DEPARTMENT_USER
    ) {
      const { assignedMembers } = currentFilter;

      mAssignedMembers
        .filter((m) => m.mType === memberTypes.TEAM || m.mType === memberTypes.DEPARTMENT)
        .forEach((m) => {
          if (!assignedMembers.includes(m.mId)) return;
          const relationType = getRelationType(m.mType, mType);
          update(member, relationType, addItem);
        });
    }
  };

  const setFilter = (filter) => {
    window.sessionStorage.setItem('currentFilter', JSON.stringify(filter));
  };

  const getFilter = () => {
    const jsonFilter = window.sessionStorage.getItem('currentFilter');
    return (jsonFilter && JSON.parse(jsonFilter)) || {};
  };

  return [update, updateOnSubscription, setFilter];
};

export default useUpdateLeftSidebarCache;
