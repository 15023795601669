import { gql } from '@apollo/client';

const COMMON_KEYS = `
  id
  label
  color
  type
  visibleTo
  visibleIn
  filters
`;

export const UPDATE_SAVED_SEARCH = gql`
  mutation UpdateSavedSearch($input: UpdateSavedSearchInput) {
    updateSavedSearch(input: $input) {
      ${COMMON_KEYS}
    }
  }
`;

export const GET_SAVED_SEARCHES = gql`
  query GetSavedSearches($input: GetSavedSearchesInput) {
    getSavedSearches(input: $input) {
      ${COMMON_KEYS}
    }
  }
`;

export const DELETE_SAVED_SEARCH = gql`
  mutation DeleteSavedSearch($input: DeleteSavedSearchInput) {
    deleteSavedSearch(input: $input) {
      id
    }
  }
`;

export const CREATE_SAVED_SEARCH = gql`
  mutation CreateSavedSearch($input: CreateSavedSearchInput) {
    createSavedSearch(input: $input) {
      ${COMMON_KEYS}
    }
  }
`;
