import { Editor, Transforms } from 'slate';

import { elementTypes } from 'components/editor/constants';

import { isElementActive } from '../../../utils';
import insertParagraph from '../../paragraph/utils/insertParagraph';

const { HORIZONTAL_RULE, PARAGRAPH } = elementTypes;

/**
 * Handles onKeyDown event on horizontal rule elements
 *
 * @param editor SlateJS editor instance
 * @param event React synthetic event
 * @returns SlateJS editor instance
 */

const onHorizontalRuleKeyDown = (editor: Editor, event: React.KeyboardEvent<HTMLDivElement>) => {
  const isActive = isElementActive(editor, HORIZONTAL_RULE);

  if (isActive) {
    const { key } = event;
    const isEnter = key === 'Enter';
    const isBackspace = key === 'Backspace';

    if (isEnter || isBackspace) event.preventDefault();

    if (isEnter) insertParagraph(editor);

    if (isBackspace) Transforms.setNodes(editor, { type: PARAGRAPH });
  }

  return editor;
};

export default onHorizontalRuleKeyDown;
