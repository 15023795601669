import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

export const FormContainer = styled('div')`
  width: 328px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const FormTitle = styled(Typography)`
  ${({ theme }) => theme.typography.dina.h4};
`;

export const Subtitle = styled(Typography)`
  ${({ theme }) => css`
    ${theme.typography.dina.listItemLabelMedium}
    color: ${theme.palette.dina.mediumEmphasis};
  `}
`;

export const InfoText = styled(Typography)`
  ${({ theme }) => css`
    ${theme.typography.dina.h7}
    color: ${theme.palette.dina.mediumEmphasis};
  `}
`;

export const Form = styled('form')`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > :last-child {
    margin-top: 12px;
  }
`;

export const StyledLink = styled(Link)`
  ${({ theme }) => theme.typography.dina.caption}
`;

export const LinksWrapper = styled('div')`
  text-align: right;
`;

export const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.dina.buttonBackgroundSignificantAction};

  .MuiButton-label {
    font-size: 14px;
    color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  }

  &.Mui-disabled {
    opacity: 0.25;
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.dina.buttonBackgroundSignificantAction};

    .MuiButton-label {
      color: ${({ theme }) => theme.palette.dina.highEmphasis};
    }
  }
`;
