import { gql } from '@apollo/client';

export default gql`
  query GetVersionAudits($input: AuditInput) {
    getAudits(input: $input) {
      mId
      mEventId
      mUpdatedAt
      mUpdatedBy
      mActionId
      mLabels {
        mCreatedAt
        mLabelId
        mMessage
        mUpdatedAt
      }
      mPayload {
        ... on AuditVersionType {
          mContentKey
        }
        ... on AuditInstanceStateType {
          mUpdatedById
          mUpdatedAt
          oldState
          newState
        }
        ... on AuditPublishingType {
          platform
          mPublishingAt
        }
        ... on AuditAssetType {
          mId
          mTitle
          mType
          mStoryId
          mAssetId
          mMediaType
        }
        ... on AuditMemberType {
          mId
          mTitle
          mType
        }
      }
    }
  }
`;
