import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import Text from 'components/text/Text';
import transientOptions from 'theme/helpers';

interface ContainerProps {
  $size?: number;
  $overflow?: number;
  $dark?: boolean;
}

export const OverflowText = styled(Text)`
  user-select: none;
`;

export const Container = styled('div', transientOptions)<ContainerProps>`
  display: flex;
  align-items: center;
  ${({ onClick, theme, $dark }) =>
    onClick &&
    css`
      cursor: pointer;
      :hover {
        ${OverflowText} {
          text-decoration: underline;
          text-underline-offset: 2px;
          color: ${$dark
            ? theme.palette.dina.whiteHighEmphasis
            : theme.palette.dina.highEmphasis} !important;
        }
      }
    `}
  ${({ $size = 32 }) => css`
    > :not(:first-of-type) {
      margin-left: -${Math.ceil($size / 5)}px;
    }
  `}
  ${({ $overflow }) =>
    $overflow
      ? css`
          > :last-of-type {
            margin-left: 2px;
          }
        `
      : ''}
`;

export const TooltipText = styled(Text)`
  white-space: pre-line;
`;

export const AssignButton = styled('div', transientOptions)<{
  $disabled?: boolean;
  $size?: number;
}>`
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
  ${({ $size = 32 }) => css`
    height: ${$size}px;
    width: ${$size}px;
  `}
  box-sizing: border-box;
  border-radius: 50%;
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover svg path {
    fill-opacity: ${({ $disabled }) => !$disabled && 1};
  }
`;
