import { memo, useCallback, useEffect, useState } from 'react';
import capitalize from 'lodash/capitalize';

import { NotificationPreference } from 'api/providers/provider-types';
import { HStack } from 'layouts/box/Box';
import { Checkbox } from 'lib/checkbox';

import { ChoiceItem, SubChoiceItem, SubChoiceList } from './styled';

interface NotificationChoiceProps {
  item: NotificationPreference;
  onUpdate: (updatedItems: NotificationPreference[]) => void;
  isSubPreference?: boolean;
}

const NotificationChoiceItem = ({ item, onUpdate, isSubPreference }: NotificationChoiceProps) => {
  const [subPreferences, setSubpreferences] = useState<NotificationPreference[]>(
    item.subPreferences ?? [],
  );

  const inherentChecked = item.subPreferences
    ? item.subPreferences.some((subItem) => subItem.value)
    : item.value;

  const [isChecked, setIsChecked] = useState(inherentChecked);

  useEffect(() => {
    // checked state might be updated by changes outside, override local state
    if (inherentChecked !== isChecked) {
      setIsChecked(inherentChecked);
    }
  }, [inherentChecked]);

  const handleSubPrefUpdate = useCallback(
    (updatedItems: NotificationPreference[]) => {
      onUpdate(updatedItems);
      setSubpreferences(
        subPreferences.map(
          (subItem) =>
            updatedItems.find((updatedItem) => updatedItem.key === subItem.key) || subItem,
        ),
      );
    },
    [onUpdate, subPreferences],
  );

  const handleToggle = useCallback(() => {
    const updatedItems: NotificationPreference[] = [
      {
        ...item,
        value: !isChecked,
      },
    ];

    if (subPreferences.length) {
      const updatedSubPreferences = subPreferences.map((subItem) => ({
        ...subItem,
        value: !isChecked,
      }));
      updatedItems.push(...updatedSubPreferences);
      setSubpreferences(updatedSubPreferences);
    }
    onUpdate(updatedItems);
    setIsChecked(!isChecked);
  }, [isChecked, item, onUpdate, subPreferences]);

  const ChoiceWrapper = isSubPreference ? SubChoiceItem : ChoiceItem;

  const isIndeterminate = subPreferences.some((subItem) => subItem.value !== isChecked);
  return (
    <ChoiceWrapper>
      <HStack gap="8px">
        <Checkbox indeterminate={isIndeterminate} checked={!!isChecked} onClick={handleToggle} />
        {capitalize(item.title)}
      </HStack>
      <SubChoiceList>
        {subPreferences.map((subItem) => (
          <NotificationChoiceItem
            key={subItem.key || subItem.title}
            item={subItem}
            onUpdate={handleSubPrefUpdate}
            isSubPreference
          />
        ))}
      </SubChoiceList>
    </ChoiceWrapper>
  );
};

export default memo(NotificationChoiceItem);
