import { useContext, useRef } from 'react';
import { Mutex } from 'async-mutex';
import { atom } from 'jotai';
import { createScope, molecule, useMolecule } from 'jotai-molecules';

import useToast from 'components/toast/useToast';
import UserContext from 'contexts/UserContext';
import { notesMolecule } from 'features/notes/store';
import { EditorValue, Note } from 'types';
import {
  getScopeFromLockedId,
  getTimestampFromLockedId,
  getUserIdFromLockedId,
  resetLockToken,
  setLockToken,
} from 'utils/lock/lockTokenV2';
import { isSubscriptionUpdateNeeded } from 'utils/subscription/isSubscriptionUpdateNeeded';
import useLogger from 'utils/useLogger';

import useUpdateNote from '../api/useUpdateNote';
import destructureEditorValue from '../utils/destructureEditorValue';

export interface NoteState {
  note: Note | null;
  content: EditorValue | null;
  isLocked: boolean;
  lockedBy: string | null;
  isLoading: boolean;
  isSaving: boolean;
  canUpdate: boolean;
}

// Mutex to prevent race conditions in locking and unlocking operations
const noteStateMutex = new Mutex();

export const NoteScope = createScope<string | undefined>(undefined);

export const noteMolecule = molecule((getMol, getScope) => {
  getScope(NoteScope);

  const notesMol = getMol(notesMolecule);
  const { currentEditingScope } = notesMol;

  const { mId: currentUserId } = useContext(UserContext);

  const { updateNote } = useUpdateNote();

  const { error: errorLog } = useLogger('contentStore');
  const { errorToast } = useToast();

  const baseAtom = atom<NoteState>({
    note: null,
    content: null,
    isLocked: false,
    lockedBy: null,
    isLoading: false,
    isSaving: false,
    canUpdate: false,
  });

  /** current editor value, keeps track of latest changes */
  const editorValueRef = useRef<EditorValue | null>(null);
  /** interval ref for debounced save */
  const saveTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  /** interval ref for periodic save */
  const periodicSaveRef = useRef<NodeJS.Timeout | null>(null);

  const setNoteAtom = atom(null, (get, set, note: Note | null) => {
    /** check if we should update state for subscription data */
    if (!isSubscriptionUpdateNeeded<Note>(note, get(baseAtom).note, currentUserId)) return;

    set(baseAtom, (prev) => ({
      ...prev,
      note,
      isLocked: !!note?.locked,
      lockedBy: note?.locked ?? null,
    }));

    // Reset the lock token if the note is not locked
    // This is to ensure that the lock token is not stale
    if (!note?.locked) resetLockToken(currentEditingScope);
  });

  const updateEditPermissionAtom = atom(null, (get, set, canUpdate: boolean) => {
    set(baseAtom, (prev) => ({ ...prev, canUpdate }));
  });

  const updateContentAtom = atom(null, (get, set, content: EditorValue | null) => {
    const { note } = get(baseAtom);
    if (note) {
      set(baseAtom, (prev) => ({ ...prev, content }));
    }
  });

  const saveContentAtom = atom(
    null,
    async (
      get,
      set,
      {
        content,
        /** If true, the content will be uploaded but the local state will not be updated,
         * preventing a re-render. */
        silent = false,
        createVersion = false,
      }: { content: EditorValue | null; silent?: boolean; createVersion?: boolean },
    ) => {
      const { note, content: initialContent, lockedBy, isLocked, canUpdate } = get(baseAtom);
      const isSameScope = currentEditingScope === getScopeFromLockedId(lockedBy);
      const lockedUserId = getUserIdFromLockedId(lockedBy);

      /** This will only update content if called from the locked scope
       * And the user is the locked user  */
      if (
        note &&
        canUpdate &&
        content &&
        isLocked &&
        isSameScope &&
        lockedUserId === currentUserId
      ) {
        const release = await noteStateMutex.acquire();

        set(baseAtom, (prev) => ({
          ...prev,
          isSaving: true,
          content: silent ? prev.content : content,
        }));

        try {
          const response = await updateNote({
            note,
            content,
            createVersion,
            audit: { message: 'update note content' },
          });

          set(baseAtom, (prev) => ({
            ...prev,
            note: {
              ...(prev.note as Note),
              ...(response ?? {}),
            },
            content: silent ? prev.content : content,
            isSaving: false,
          }));

          release();
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
          errorToast(error, 'There was an error trying to save content');
          if (error instanceof Error) errorLog(error);

          set(baseAtom, (prev) => ({ ...prev, isSaving: false, content: initialContent }));
          release();
        }
      }
    },
  );

  // Atom to handle locking the note by the current user, prevents multiple locks at once
  const lockContentAtom = atom(null, async (get, set, userId: string) => {
    const { note, isLocked, lockedBy, canUpdate } = get(baseAtom);

    if (note && canUpdate) {
      // return early if the note is already locked
      if (isLocked) return lockedBy;

      const release = await noteStateMutex.acquire();

      // Update state & set the lock token for the current editing scope
      const lockToken = setLockToken(currentEditingScope, userId);
      set(baseAtom, (prev) => ({ ...prev, isLoading: true, isLocked: true, lockedBy: lockToken }));

      try {
        const lockResponse = await updateNote({
          note: { ...note, locked: lockToken ?? undefined },
          audit: { message: 'lock note' },
        });

        // Update state with the new lock status returned from the server
        set(baseAtom, (prev) => ({
          ...prev,
          note: { ...(prev.note as Note), ...(lockResponse ?? {}) },
          isLoading: false,
          isLocked: !!lockResponse?.locked,
          lockedBy: lockResponse?.locked ?? null,
        }));

        release();
        return lockResponse?.locked;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        errorToast(error, 'There was an error trying to lock story');
        if (error instanceof Error) errorLog(error);

        // Reset the lock token if the lock operation fails
        resetLockToken(currentEditingScope);
        set(baseAtom, (prev) => ({
          ...prev,
          isLoading: false,
          isLocked: false,
          lockedBy: null,
        }));

        release();
        return null;
      }
    }
  });

  // Atom to handle unlocking the content, ensuring no concurrent unlock operations
  const unlockNoteAtom = atom(
    null,
    async (
      get,
      set,
      {
        content,
        cancelled,
        source,
        forceUnlock = false,
      }: { content: EditorValue | null; cancelled: boolean; source: string; forceUnlock?: boolean },
    ) => {
      const { note, isLocked, lockedBy, content: initialContent, canUpdate } = get(baseAtom);

      if (
        note &&
        canUpdate &&
        ((isLocked &&
          getUserIdFromLockedId(lockedBy) === currentUserId &&
          getScopeFromLockedId(lockedBy) === currentEditingScope) ||
          forceUnlock)
      ) {
        const release = await noteStateMutex.acquire();

        /** handle title & description values */
        let mTitle = note.mTitle;
        let mDescription = note.mDescription;

        const isSameScope = currentEditingScope === getScopeFromLockedId(lockedBy);
        const lockedUserId = getUserIdFromLockedId(lockedBy);

        /** only update mTitle and mDescription when saved by locked user from locked scope */
        if (content && isSameScope && lockedUserId === currentUserId && !forceUnlock) {
          const { mTitle: newMTitle, mDescription: newMDescription } =
            destructureEditorValue(content);
          mTitle = newMTitle ?? mTitle;
          mDescription = newMDescription ?? mDescription;
        }

        const updatedNote: Note = {
          ...note,
          mTitle,
          mDescription,
          locked: undefined,
        };

        // Update state optimistically & reset the lock token for the current editing scope
        await Promise.all([
          set(baseAtom, (prev) => ({
            ...prev,
            note: updatedNote,
            content: forceUnlock ? prev.content : content,
            isSaving: true,
            isLocked: false,
            lockedBy: null,
          })),
          resetLockToken(currentEditingScope),
        ]);

        try {
          const unlockResponse = await updateNote({
            note: updatedNote,
            isCancelEvent: cancelled,
            ...(!cancelled && content && { content }),
            audit: { source, message: cancelled ? 'cancelled' : 'unlock note' },
          });

          set(baseAtom, (prev) => ({
            ...prev,
            note: {
              ...updatedNote,
              ...(unlockResponse ?? {}),
            },
            isSaving: false,
            isLocked: !!unlockResponse?.locked,
            lockedBy: unlockResponse?.locked ?? null,
            content: !unlockResponse?.mLastVersion ? null : prev.content,
          }));

          release();
          return unlockResponse?.locked;
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
          errorToast(error, 'There was an error trying to unlock story');
          if (error instanceof Error) errorLog(error);

          // Set the lock token back to previous state if the unlock operation fails
          const userId = getUserIdFromLockedId(lockedBy);
          const previousLockedTime = getTimestampFromLockedId(lockedBy);
          if (userId) setLockToken(currentEditingScope, userId, previousLockedTime ?? undefined);

          set(baseAtom, (prev) => ({
            ...prev,
            content: initialContent,
            isLoading: false,
            isSaving: false,
            isLocked,
            lockedBy,
          }));

          release();
          return lockedBy;
        }
      }
    },
  );

  return {
    baseAtom,
    setNoteAtom,
    lockContentAtom,
    unlockNoteAtom,
    updateEditPermissionAtom,
    updateContentAtom,
    saveContentAtom,
    editorValueRef,
    saveTimeoutRef,
    periodicSaveRef,
  };
});

export const useNoteMolecule = () => useMolecule(noteMolecule);
