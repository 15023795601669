import styled from '@emotion/styled';

export const InputWrapper = styled('div')`
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
`;

export const TextAreaWrapper = styled('div')`
  margin-top: 8px;
  margin-bottom: 16px;
`;
