import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { InputAdornment } from '@material-ui/core';
import Tooltip from 'components/tooltip';
import { ReactComponent as SearchIcon } from 'assets/icons/systemicons/search.svg';
import useInputEvents from 'hooks/useInputEvents';
import { OutlinedInput, Close } from './input-styled';

const Input = ({ value, updateValue }) => {
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const onUpdate = useCallback(
    (newValue) => {
      const trimmedValue = newValue.trim();
      if (trimmedValue === value) setInputValue(value);
      else updateValue(trimmedValue);
    },
    [updateValue, value],
  );

  const resetInput = useCallback(
    (event) => {
      event.preventDefault();
      if (value) updateValue('');
      setInputValue('');
    },
    [updateValue, value],
  );

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const [inputRef, handleKeydown, handleBlur] = useInputEvents(onUpdate, inputValue, value);

  return (
    <OutlinedInput
      value={inputValue}
      variant="outlined"
      fullWidth
      ref={inputRef}
      onKeyDown={handleKeydown}
      onBlur={handleBlur}
      placeholder="Type to find..."
      onChange={handleInputChange}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
      endAdornment={
        Boolean(inputValue) && (
          <InputAdornment position="end">
            <Tooltip title="Empty text field">
              <Close onMouseDown={resetInput} />
            </Tooltip>
          </InputAdornment>
        )
      }
    />
  );
};

Input.propTypes = {
  value: PropTypes.string,
  updateValue: PropTypes.func,
};

Input.defaultProps = {
  value: '',
  updateValue: () => {},
};

export default Input;
