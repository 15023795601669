import notifyConfig from 'utils/sampleData/notifyConfig';

const doNotify = (item, currentUser) => {
  const notify = notifyConfig[item.mType];
  if (!notify || !notify.notify) return false;
  if (!notify.currentUserOnly) return true;
  if (item.user.mId === currentUser) return true;
  return false;
};
export default doNotify;
