import { makeStyles } from '@material-ui/styles';
import fallBackImage from 'assets/images/default/defaultCoverPhoto.png';

const useStyles = (image) =>
  makeStyles((theme) => ({
    root: {
      backgroundImage:
        theme.palette.mode === 'light'
          ? `linear-gradient(180deg, rgba(255,255,255, 0.75) 0%, rgba(255,255,255,0.95) 100%),
      url(${image}), url(${fallBackImage})`
          : `linear-gradient(180deg, rgba(50,62,77, 0.75) 0%, rgba(32,42,54,0.95) 100%),
      url(${image}), url(${fallBackImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      width: '100%',
      minHeight: 112,
      height: 112,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    image: {
      width: 46,
      height: 46,
      margin: '2px 12px 12px 12px',
    },
    img: {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      borderRadius: '8px',
    },
    title: {
      ...theme.typography.dina.listItemLabel,
      color: theme.palette.dina.highEmphasis,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingTop: '4px',
    },
    top: {
      paddingTop: '12px',
    },
    icon: {
      margin: '0 12px',
    },
    buttonRoot: {
      ...theme.typography.dina.rightColumnHeaderText,
      textTransform: 'none',
      borderRadius: '4px',
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    bottom: {
      display: 'flex',
    },
  }));

export default useStyles;
