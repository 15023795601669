import styled from '@emotion/styled/macro';
import transientOptions from 'theme/helpers';

import { ReactComponent as Open } from 'assets/icons/systemicons/open.svg';

export const ContentWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const SelectWrapper = styled('div')`
  min-width: 86px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const VerticalBorder = styled('div')`
  height: 100%;
  border-right: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  margin-left: 8px;
`;

export const TitleBoxWrapper = styled('div', transientOptions)`
  box-sizing: border-box;
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  padding-inline: 4px 0;
  overflow: hidden;

  @container (max-width: 600px) {
    width: 210px;
  }

  @container (max-width: 540px) {
    width: 150px;
  }

  @container (max-width: 480px) {
    width: 90px;
  }
`;

export const ThumbnailWrapper = styled.div`
  width: 71px;
  min-width: 71px;
  max-width: 71px;
  height: 40px;
  position: relative;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
`;

export const BoxWrapper = styled('div')`
  box-sizing: border-box;
  width: 100%;
  pointer-events: ${({ readOnly }) => (readOnly ? 'none' : 'all')};
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  container-type: inline-size;
`;

export const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const PlaceholdersWrapper = styled('div')`
  min-width: 40px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  cursor: pointer;
`;

export const PlaceholderIcon = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
`;

export const PlayIcon = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-20%, -50%, 0);
`;

export const OpenIcon = styled(Open)`
  path {
    fill-opacity: 0.54;
  }

  :hover {
    path {
      fill-opacity: 1;
    }
  }
`;
