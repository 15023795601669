import { useEffect } from 'react';

import { createWidgetDefaults, useCreateWidgetMenu } from 'features/widget/CreateWidgetMenu';
import { ResizablePanel, ResizablePanelGroup } from 'lib/resize';

import { LayoutProps } from '../layouts';
import { PanelContent } from '../PanelContent';
import { HorizontalResizeHandle, VerticalResizeHandle } from '../ResizableHandlers';

export default function OneByTwoHorizontal({ widgets, ...panelProps }: Readonly<LayoutProps>) {
  const [, setCreateWidget] = useCreateWidgetMenu();

  const leftWidget = widgets[0];
  const rightTopWidget = widgets[1];
  const rightBottomWidget = widgets[2];

  useEffect(() => {
    return () => {
      setCreateWidget({ ...createWidgetDefaults });
    };
  }, []);

  return (
    <ResizablePanelGroup direction="horizontal">
      <ResizablePanel style={{ marginLeft: '8px', marginBottom: '8px', marginTop: '2px' }}>
        <PanelContent
          index={0}
          layout="1-2-horizontal"
          setCreateWidget={setCreateWidget}
          widget={leftWidget}
          {...panelProps}
        />
      </ResizablePanel>
      <>
        <VerticalResizeHandle />
        <ResizablePanel style={{ marginRight: '8px', marginBottom: '8px', marginTop: '2px' }}>
          <ResizablePanelGroup direction="vertical">
            <ResizablePanel>
              <PanelContent
                index={1}
                layout="1-2-horizontal"
                setCreateWidget={setCreateWidget}
                widget={rightTopWidget}
                {...panelProps}
              />
            </ResizablePanel>
            <HorizontalResizeHandle />
            <ResizablePanel>
              <PanelContent
                index={2}
                layout="1-2-horizontal"
                setCreateWidget={setCreateWidget}
                widget={rightBottomWidget}
                {...panelProps}
              />
            </ResizablePanel>
          </ResizablePanelGroup>
        </ResizablePanel>
      </>
    </ResizablePanelGroup>
  );
}
