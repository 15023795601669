import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { createScope, molecule, useMolecule } from 'jotai-molecules';

import { Rundown } from 'types/graphqlTypes';

export const DetailsScope = createScope<string | undefined>(undefined);

const detailsMolecule = molecule((_, getScope) => {
  getScope(DetailsScope);

  const selectedRundownAtom = atom<Rundown | null>(null);
  const currentOrderAtom = atom('mOrder');
  const currentAnchorAtom = atom(0);
  const selectedIdsAtom = atom<Record<string, string>>({});

  const filteredMorderAtom = atom((get) => {
    const selectedRundown = get(selectedRundownAtom);
    if (selectedRundown?.mOrder) return selectedRundown.mOrder.filter((mId) => mId[0] !== '-');

    return [];
  });

  const filteredPreorderAtom = atom((get) => {
    const selectedRundown = get(selectedRundownAtom);
    if (selectedRundown?.mPreorder)
      return selectedRundown.mPreorder.filter((mId) => mId[0] !== '-');

    return [];
  });

  const toggleCurrentItemFromSelectedIdsAtom = atom(
    null,
    (get, set, next: { id: string; index: number }) => {
      const { id, index } = next;
      const newSelectedIds = { ...get(selectedIdsAtom) };

      if (newSelectedIds[index]) {
        delete newSelectedIds[index];
      } else {
        newSelectedIds[index] = id;
        set(currentAnchorAtom, index);
      }
      set(selectedIdsAtom, newSelectedIds);
    },
  );

  const shiftItemsToSelectedIdsAtom = atom(
    null,
    (get, set, next: { index: number; orderType: string; ignoreSelectedIds: boolean }) => {
      const { index, orderType, ignoreSelectedIds } = next;
      const currentAnchor = get(currentAnchorAtom);
      const filteredMorder = get(filteredMorderAtom);
      const filteredPreorder = get(filteredPreorderAtom);
      const selectedIds = get(selectedIdsAtom);

      const [startPosition, endPosition] = [index, ignoreSelectedIds ? 0 : currentAnchor].sort(
        (a, b) => a - b,
      );
      const order = orderType === 'mOrder' ? filteredMorder : filteredPreorder;

      const newlySelectedIds: Record<string, string> = {};

      order.slice(startPosition, endPosition + 1).forEach((id, offset) => {
        newlySelectedIds[startPosition + offset] = id;
      });
      const alreadySelectedIds = ignoreSelectedIds ? {} : { ...selectedIds };

      set(selectedIdsAtom, { ...alreadySelectedIds, ...newlySelectedIds });
      set(currentAnchorAtom, index);
    },
  );

  const selectSingleItemAtom = atom(null, (get, set, next: { id: string; index: number }) => {
    const { id, index } = next;
    const newSelectedIds: Record<string, string> = {};
    newSelectedIds[index] = id;
    set(selectedIdsAtom, newSelectedIds);
    set(currentAnchorAtom, index);
  });

  const changeCurrentOrderAtom = atom(null, (_get, set, next: string) => {
    set(currentOrderAtom, next);
  });

  return {
    useSelectedRundown: () => useAtom(selectedRundownAtom),
    useFilteredMOrder: () => useAtom(filteredMorderAtom),
    useFilteredMPreOrder: () => useAtom(filteredPreorderAtom),
    useSetToggleCurrentItemFromSelectedIds: () => useSetAtom(toggleCurrentItemFromSelectedIdsAtom),
    useSetShiftItemsToSelectedIds: () => useSetAtom(shiftItemsToSelectedIdsAtom),
    useSetCurrentOrder: () => useSetAtom(changeCurrentOrderAtom),
    useSetSelectSingleItem: () => useSetAtom(selectSingleItemAtom),
    useSelectedIdsValue: () => useAtomValue(selectedIdsAtom),
    useCurrentOrderValue: () => useAtomValue(currentOrderAtom),
  };
});

export const useDetailsMolecule = () => useMolecule(detailsMolecule);
