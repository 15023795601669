import { atom, useAtom } from 'jotai';

import { UserBookmark } from 'types';

export type UserKeyedBookmarkType = {
  [x: string]: UserBookmark;
};

const userKeyedBookmarksAtom = atom<UserKeyedBookmarkType>({});

export const useUserBookmarks = () => useAtom(userKeyedBookmarksAtom);
