import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Rnd } from 'react-rnd';

const ResizableBox = ({
  children,
  initialSize,
  enableResizing,
  bounds,
  minWidth,
  minHeight,
  onResizeStart,
  onResize,
  onResizeStop,
}) => {
  const [size, setSize] = useState(initialSize);
  return (
    <Rnd
      style={{
        position: 'static',
        '&:focus': {
          outline: 'none',
        },
      }}
      size={size}
      onResize={(...args) => {
        setSize({
          width: args[2].style.width,
          height: args[2].style.height,
        });
        onResize && onResize(...args);
      }}
      enableResizing={enableResizing}
      disableDragging
      bounds={bounds}
      minWidth={minWidth}
      minHeight={minHeight}
      onResizeStart={onResizeStart}
      onResizeStop={onResizeStop}
    >
      {children}
    </Rnd>
  );
};

ResizableBox.propTypes = {
  /** Initial size of the box */
  initialSize: PropTypes.exact({
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  /** Which part of the box should be resizable */
  enableResizing: PropTypes.shape({
    bottom: PropTypes.bool,
    bottomLeft: PropTypes.bool,
    bottomRight: PropTypes.bool,
    left: PropTypes.bool,
    right: PropTypes.bool,
    top: PropTypes.bool,
    topLeft: PropTypes.bool,
    topRight: PropTypes.bool,
  }).isRequired,
  /** Resize bound */
  bounds: PropTypes.oneOf(['parent', 'window', 'body']).isRequired,
  /** Minimum width of the box */
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Minimum height of the box */
  minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Function called when resize starts */
  onResizeStart: PropTypes.func,
  /** Function called when resizing */
  onResize: PropTypes.func,
  /** FFunction called when resize stops */
  onResizeStop: PropTypes.func,
};

ResizableBox.defaultProps = {
  onResizeStart: undefined,
  onResize: undefined,
  onResizeStop: undefined,
  minWidth: 0,
  minHeight: 0,
};

export default ResizableBox;
