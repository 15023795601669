import { useSelectedCategory, useSelectedMenuSystemSettings } from '../../../../../atoms';
import { CATEGORY_TYPES } from '../../../../../utils/settingTypes';
import List from '../../../../collapseList';

export const editorSettings = {
  COMMANDS: 'commands',
};

function Editor() {
  const [category, setCategory] = useSelectedCategory();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [selectedMenu, setSelectedMenu] = useSelectedMenuSystemSettings();

  const onMenuClick = (
    event: React.MouseEvent<HTMLLIElement>,
    menuType: string,
    categoryType: string,
  ) => {
    event.preventDefault();
    setSelectedMenu(menuType);
    setCategory(categoryType);
  };
  return (
    <List>
      <List.Header
        selected={category === CATEGORY_TYPES.EDITOR}
        hideCreateButton
        itemCount={undefined}
        createButtonTooltip={undefined}
        createButtonDisabled={undefined}
        onCreateClick={undefined}
      >
        Editor
      </List.Header>
      <List.Body>
        <List.Item
          id={editorSettings.COMMANDS}
          key={editorSettings.COMMANDS}
          selected={selectedMenu === editorSettings.COMMANDS}
          onClick={(event: React.MouseEvent<HTMLLIElement>) =>
            onMenuClick(event, editorSettings.COMMANDS, CATEGORY_TYPES.EDITOR)
          }
          italic={undefined}
          className={undefined}
          itemCount={undefined}
        >
          Commands
        </List.Item>
      </List.Body>
    </List>
  );
}

export default Editor;
