import { memo, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { Fade, Menu } from '@material-ui/core';

import { ReactComponent as More } from 'assets/icons/systemicons/more_vertical.svg';
import { IconButton } from 'components/buttons';
import ListItem from 'components/ellipsisDropdown/listItem-view';
import Text from 'components/text/Text';
import useDidMount from 'hooks/useDidMount';
import useHover from 'hooks/useHover';
import useMenuAnchor from 'hooks/useMenuAnchor';
import { useSelectedConversationId } from 'store';
import { Conversation } from 'types/messageHub';

import { useConversationMolecule } from '../../store/conversation';
import AvatarView from '../Avatar';

const NotificationCount = styled(Text)`
  padding: 2px 6px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.dina.buttonBackgroundDangerousAction};
`;

const StyledMenu = styled(Menu)`
  .MuiMenu-paper {
    width: 400;
    padding: 24px 12px 24px 24px;
    background-color: transparent;
    box-shadow: none;
  }
  .MuiMenu-list {
    width: 400 - 24 - 12;
    background-color: ${({ theme }) => theme.palette.dina.surfaceDialogs};
    border-radius: 4px;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
      0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
`;

const MenuIcon = styled('div')`
  height: 40px;
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

const DELETED_USER = 'Ghost User';

export interface MenuProps {
  menuItems?: { title: string; icon: React.ReactElement; action: string }[];
  onMenuSelect?: (action: string, mId: string) => void;
}

interface ListItemProps extends MenuProps {
  conversation: Conversation;
}
function ConversationListItem({
  conversation,
  menuItems = [],
  onMenuSelect = () => null,
}: Readonly<ListItemProps>) {
  const [selectedConversationId] = useSelectedConversationId();

  const {
    useCurrentConversation,
    useSetCurrentConversation,
    useNotificationsCount,
    useSetNotifications,
  } = useConversationMolecule();
  const currentConversation = useCurrentConversation();
  const setCurrentConversation = useSetCurrentConversation();
  const notifications = useNotificationsCount();
  const setNotifications = useSetNotifications();

  const { mId, convoLeaveOption } = conversation;
  const selected = currentConversation?.mId === mId;

  const didMount = useDidMount();

  const { anchorEl, closeMenu, toggleMenu } = useMenuAnchor();
  const [hoverRef, isHovered] = useHover<HTMLDivElement>();

  const handleToggleMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    toggleMenu(event);
  };

  const notificationCount = notifications[mId] || 0;

  useEffect(() => {
    if (didMount && notificationCount > 0 && currentConversation?.mId === mId)
      setNotifications(mId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [didMount, notificationCount, mId, currentConversation?.mId]);

  useEffect(() => {
    if (
      conversation.mId === selectedConversationId &&
      conversation.mId !== currentConversation?.mId
    ) {
      setCurrentConversation(conversation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentConversation?.mId, selectedConversationId]);

  const handleConversationSelect = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (notificationCount > 0) setNotifications(mId);
    setCurrentConversation(conversation);
  };

  const menuOptions = useMemo(
    () =>
      menuItems?.map(({ title, icon, action }) => (
        <ListItem
          key={`${title}_${action}`}
          text={title}
          firstChild={<MenuIcon>{icon}</MenuIcon>}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            closeMenu();
            onMenuSelect(action, mId);
          }}
          excludeDivider
          maxHeight={32}
          disabled={action === 'leave-conversation' && !convoLeaveOption}
        />
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [menuItems, convoLeaveOption, mId],
  );

  return (
    <div ref={hoverRef}>
      <ListItem
        style={{ paddingInline: '8px 12px' }}
        text={
          <Text
            variant={!selected && notificationCount > 0 ? 'listItemLabelBold' : 'listItemLabel'}
            color={
              selected
                ? 'whiteHighEmphasis'
                : notificationCount > 0
                ? 'highEmphasis'
                : 'mediumEmphasis'
            }
          >
            {conversation.name ?? DELETED_USER}
          </Text>
        }
        firstChild={<AvatarView conversation={conversation} />}
        iconChild={
          <>
            {menuItems?.length > 0 && (
              <>
                <Fade in={isHovered} timeout={{ enter: 250, exit: 250 }} mountOnEnter unmountOnExit>
                  <div>
                    {/** prevents ref error * */}
                    <IconButton usage="text" size={24} iconSize={16} onClick={handleToggleMenu}>
                      <More />
                    </IconButton>
                  </div>
                </Fade>

                <StyledMenu
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={closeMenu}
                  getContentAnchorEl={null}
                  PaperProps={{ onMouseLeave: closeMenu }}
                >
                  {menuOptions}
                </StyledMenu>
              </>
            )}
            {!selected && notificationCount > 0 && (
              <NotificationCount variant="caption" color="whiteHighEmphasis">
                {`${notificationCount > 99 ? 99 : notificationCount}`}
              </NotificationCount>
            )}
          </>
        }
        excludeDivider
        maxHeight={34}
        selected={selected}
        onClick={handleConversationSelect}
        whiteSpace="nowrap"
      />
    </div>
  );
}

export default memo(ConversationListItem);
