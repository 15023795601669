import React, { useMemo } from 'react';
import { Item, ItemParams, Separator, Submenu } from 'react-contexify';

import { Color } from 'features/reusableStyled';

import 'react-contexify/dist/ReactContexify.css';

import { Menu } from './styled';

const MENU_ID = 'menu-id';

export interface MenuItem {
  id: string;
  color?: string;
  label?: React.ReactElement | string;
  separator?: boolean;
  children?: MenuItem[];
}

interface Props {
  onClick: (val: ItemParams) => void;
  id: string;
  menuItems?: MenuItem[];
}

function ContextMenu({ id = MENU_ID, menuItems = [], onClick, ...rest }: Readonly<Props>) {
  const children = useMemo(() => {
    const elements: React.ReactElement[] = [];

    menuItems.forEach((item) => {
      if (item.separator) {
        elements.push(<Separator key={item.id} />);
        return;
      }

      if (item.children && item.children.length > 0) {
        elements.push(
          <Submenu label={item.label} id={item.id ?? item.label} key={item.id ?? item.label}>
            {item.children.map((subItem) => {
              if (subItem.separator) return <Separator key={subItem.id} />;
              return (
                <Item id={subItem.id ?? subItem.label} key={subItem.id} onClick={onClick}>
                  {subItem.color && (
                    <Color $size={12} $color={subItem.color} style={{ marginRight: '4px' }} />
                  )}
                  {subItem.label}
                </Item>
              );
            })}
          </Submenu>,
        );
        return;
      }

      elements.push(
        <Item id={item.id} key={item.id} onClick={onClick}>
          {item.color && <Color $size={12} $color={item.color} style={{ marginRight: '4px' }} />}
          {item.label}
        </Item>,
      );
    });

    return elements;
  }, [menuItems]);

  return (
    <Menu id={id} style={{ zIndex: 2400 }} {...rest}>
      {children}
    </Menu>
  );
}

export default ContextMenu;
