import React, { ImgHTMLAttributes, useEffect, useRef, useState } from 'react';

import defaultFallbackSrc from 'assets/images/default/defaultThumbnail.png';

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  alt: string;
  width?: number;
  height?: number;
  fallbackSrc?: string;
  [key: string]: unknown;
}

const ImageComponent: React.FC<ImageProps> = ({
  src,
  alt,
  width,
  height,
  fallbackSrc,
  ...props
}) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [optimizedSrc, setOptimizedSrc] = useState('');
  const [blurDataURL, setBlurDataURL] = useState('');

  const generateBlurDataURL = async (imgSrc: string) => {
    return new Promise<string>((resolve) => {
      const img = new Image();
      img.src = imgSrc;
      img.crossOrigin = 'anonymous';

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.naturalWidth;
        canvas.height = img.naturalHeight;
        ctx?.drawImage(img, 0, 0);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ctx!.filter = 'blur(20px)';
        ctx?.drawImage(
          canvas,
          0,
          0,
          canvas.width,
          canvas.height,
          0,
          0,
          canvas.width,
          canvas.height,
        );

        resolve(canvas.toDataURL('image/jpeg'));
      };
    });
  };

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    const element = imageRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsIntersecting(true);
            observer.unobserve(entry.target); // Stop observing once intersecting
          }
        });
      },
      {
        rootMargin: '0px',
      },
    );

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, []);

  useEffect(() => {
    const optimizeImage = async () => {
      try {
        const tempBlurDataURL = await generateBlurDataURL(src);
        setBlurDataURL(tempBlurDataURL);

        setOptimizedSrc(src);
      } catch (error) {
        // TODO: Get rid of the eslint-disable comment by fixing: defaultFallbackSrc as string
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        setOptimizedSrc(fallbackSrc || (defaultFallbackSrc as string) || src);
        // Use fallbackSrc if provided, otherwise use src
      }
    };

    if (isIntersecting) {
      void optimizeImage();
    }
  }, [isIntersecting, src, fallbackSrc]);

  return (
    <div
      style={{
        position: 'relative',
        width: width ? `${width}px` : '100%',
        height: height ? `${height}px` : 0,
        paddingBottom: height ? 0 : '100%',
      }}
    >
      <img
        ref={imageRef}
        src={isLoading ? blurDataURL : optimizedSrc}
        alt={alt}
        onLoad={handleImageLoad}
        style={{
          opacity: isLoading ? 0 : 1,
          transition: 'opacity 0.3s',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
        {...props}
      />
    </div>
  );
};

export default ImageComponent;
