import { useEffect } from 'react';

const WHITE_LIST = ['ResizeObserver loop completed with undelivered notifications.'];

// There's a bug with this library together with typescript where a harmless error
// is caught by webpack 60 times per second. See issue + work around.
// https://github.com/adazzle/react-data-grid/issues/2712
export const useDataGridErrorSuppressor = () => {
  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (WHITE_LIST.includes(e.message)) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div',
        );
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);
};
