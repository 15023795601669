import GET_CONVERSATIONS_OF_USER from 'operations/queries/getConversationsOfUser';

const updateCache = (cache, conversation, variables, crudAction) => {
  try {
    const { getConversationsOfUser } = cache.readQuery({
      query: GET_CONVERSATIONS_OF_USER,
      variables,
    });

    const isFound = getConversationsOfUser.find(
      (cachedConversation) => cachedConversation?.mRefId === conversation.mRefId,
    );

    let newConversationList;
    if (crudAction !== 'REMOVE') {
      newConversationList = isFound
        ? getConversationsOfUser
        : [conversation, ...getConversationsOfUser];
    } else {
      newConversationList = getConversationsOfUser.filter(
        (cachedConversation) => cachedConversation?.mRefId !== conversation.mRefId,
      );
    }
    cache.writeQuery({
      query: GET_CONVERSATIONS_OF_USER,
      variables,
      data: {
        getConversationsOfUser: newConversationList,
      },
    });
  } catch (error) {
    // logger.log(error);
  }
};

const updateConversationsOfUser = (proxy, item) => {
  try {
    const { user, crudAction } = item.message;
    const variables = {
      input: {
        mId: user.mId,
      },
    };
    updateCache(proxy, item, variables, crudAction);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};

export default updateConversationsOfUser;
