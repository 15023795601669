import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import useInputEvents from 'hooks/useInputEvents';
import InputField from 'components/inputField';

const Input = memo(InputField);

const Title = ({ readOnly, title, setTitle, initialTitle, updateTitle, direction }) => {
  const onUpdateTitle = useCallback(
    async (newTitle) => {
      if (newTitle === initialTitle) setTitle(initialTitle);
      else updateTitle(newTitle);
    },
    [initialTitle, setTitle, updateTitle],
  );
  const [titleInputRef, handleTitleKeydown, handleTitleBlur] = useInputEvents(
    onUpdateTitle,
    title,
    initialTitle,
  );

  const handleTitleChange = useCallback(
    (newTitle) => {
      setTitle(newTitle);
    },
    [setTitle],
  );

  return (
    <Input
      description="Add a Title to the stream"
      disableLabel
      onChange={handleTitleChange}
      value={title}
      usage="editor"
      disabled={readOnly}
      placeholder="Add Title"
      inputRef={titleInputRef}
      onKeyDown={handleTitleKeydown}
      onBlur={handleTitleBlur}
      direction={direction}
    />
  );
};

Title.propTypes = {
  /** state of the title */
  title: PropTypes.string,
  /** callback to change title state */
  setTitle: PropTypes.func,
  /** value of initial title */
  initialTitle: PropTypes.string,
  /** boolean to disable input */
  readOnly: PropTypes.bool,
  /** callback to update title */
  updateTitle: PropTypes.func,
};

Title.defaultProps = {
  title: '',
  setTitle: () => {},
  initialTitle: '',
  readOnly: false,
  updateTitle: () => {},
};

export default memo(Title);
