import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => {
  const iconButtonStyle = {
    position: 'absolute',
    width: '24px',
    height: '24px',
    backdropFilter: 'blur(15px)',
    zIndex: 1,
    backgroundColor: theme.palette.dina.blackHoverOverlay,
    '&:hover': {
      backgroundColor: theme.palette.dina.blackHoverOverlay,
    },
  };
  return {
    closeButton: {
      ...iconButtonStyle,
      top: '4px',
      right: '4px',
    },
    closeImage: {
      width: '10px',
      height: '10px',
    },
    editButton: {
      ...iconButtonStyle,
      bottom: '4px',
      right: '4px',
    },
    editImage: {
      width: '24px',
      height: '24px',
    },
  };
});

export default useStyles;
