import styled from '@emotion/styled';
import * as ContextMenuPrimitive from '@radix-ui/react-context-menu';

import { dialogBoxShadow } from 'theme/utils/commonStyles';

export const SubTrigger = styled(ContextMenuPrimitive.SubTrigger)`
  display: flex;
  cursor: default;
  align-items: center;
  border-radius: 6px;
  padding: 6px 8px;
  outline: none;
  &.inset {
    padding-left: 12px;
  }
  &[data-state='open'] {
    background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel2};
    color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  }
`;

export const SubContent = styled(ContextMenuPrimitive.SubContent)`
  z-index: 50;
  min-width: 8rem;
  overflow: hidden;
  border-radius: 0.25rem;
  border: 1px solid ${({ theme }) => theme.palette.dina.borderResting};
  background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel2};
  padding: 0.25rem;
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  ${dialogBoxShadow};
  &.data-[state='open'] {
    animation: fadeIn 0.2s ease-in-out;
    transform: scale(1);
  }
  &.data-[state='closed'] {
    animation: fadeOut 0.2s ease-in-out;
    transform: scale(0.95);
  }
  &.data-[side='bottom'] {
    animation: slideInFromTop 0.2s ease-in-out;
  }
  &.data-[side='left'] {
    animation: slideInFromRight 0.2s ease-in-out;
  }
  &.data-[side='right'] {
    animation: slideInFromLeft 0.2s ease-in-out;
  }
  &.data-[side='top'] {
    animation: slideInFromBottom 0.2s ease-in-out;
  }
`;

export const Content = styled(ContextMenuPrimitive.Content)`
  min-width: 260px;
  z-index: 1500;
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.palette.dina.borderResting};
  background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
  padding: 4px;
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  ${dialogBoxShadow};
  &.data-[state='open'] {
    animation: fadeIn 0.2s ease-in-out;
    transform: scale(1);
  }
  &.data-[state='closed'] {
    animation: fadeOut 0.2s ease-in-out;
    transform: scale(0.95);
  }
  &.data-[side='bottom'] {
    animation: slideInFromTop 0.2s ease-in-out;
  }
  &.data-[side='left'] {
    animation: slideInFromRight 0.2s ease-in-out;
  }
  &.data-[side='right'] {
    animation: slideInFromLeft 0.2s ease-in-out;
  }
  &.data-[side='top'] {
    animation: slideInFromBottom 0.2s ease-in-out;
  }
`;

export const Item = styled(ContextMenuPrimitive.Item)`
  position: relative;
  display: flex;
  cursor: pointer;
  align-items: center;
  border-radius: 6px;
  padding: 6px 8px;
  ${({ theme }) => theme.typography.dina.listItemLabel};
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  outline: none;
  :hover {
    background-color: ${({ theme }) => theme.palette.dina.hoverOverlay};
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
  &.inset {
    padding-left: 12px;
  }
  &.focus {
    background-color: ${({ theme }) => theme.palette.dina.backgroundHover};
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
  &.data-[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }
`;

export const Label = styled(ContextMenuPrimitive.Label)`
  padding: 6px 8px;
  ${({ theme }) => theme.typography.dina.h7};
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
  &.inset {
    padding-left: 12px;
  }
`;

export const Separator = styled(ContextMenuPrimitive.Separator)`
  margin-block: 6px;
  border-top: 1px solid ${({ theme }) => theme.palette.dina.borderResting};
`;

export const Shortcut = styled('span')`
  margin-left: auto;
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
`;
