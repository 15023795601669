/* eslint-disable sort-imports */
import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';

import { GetMemberInput, MdfType } from 'types/graphqlTypes';

import { STANDARD_MDF_KEYS } from '../commonKeys';

import { toMdfDto } from './useCreateMdf';

const GET_MDF = gql`
  query GetMdf($input: GetMemberInput) {
    getMdf(input: $input) {
      ${STANDARD_MDF_KEYS}
    }
  }
`;

interface GetMdf {
  getMdf: MdfType;
}

interface GetMemberInputType {
  input: GetMemberInput;
}

export const useGetMdf = (mId: string | undefined | null) => {
  const { data, error, loading, refetch } = useQuery<GetMdf, GetMemberInputType>(GET_MDF, {
    variables: {
      input: {
        mId: mId ?? '',
      },
    },
    fetchPolicy: 'cache-first',
    skip: !mId,
  });

  const mdf = useMemo(() => {
    return data?.getMdf ? toMdfDto(data.getMdf) : undefined;
  }, [data]);

  return { mdf, error, loading, refetch };
};
