import React from 'react';
import PropTypes from 'prop-types';
import { ButtonBase } from '@material-ui/core';
import { ReactComponent as ArrowLeft } from 'assets/icons/systemicons/arrows/disclosurearrow_left.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/systemicons/arrows/disclosurearrow_right.svg';

const TabScrollButton = props => {
  const {
    customRootClass,
    className: classNameProp,
    direction,
    orientation,
    disabled,
    ...others
  } = props;

  return (
    <ButtonBase
      classes={{ root: customRootClass }}
      className={classNameProp}
      component="div"
      {...others}
    >
      {direction === 'left' ? <ArrowLeft /> : <ArrowRight />}
    </ButtonBase>
  );
};

TabScrollButton.propTypes = {
  /**
   * Custom root class to override scroll button design.
   */
  customRootClass: PropTypes.string,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * Which direction should the button indicate?
   */
  direction: PropTypes.oneOf(['left', 'right']).isRequired,
  /**
   * If `true`, the element will be disabled.
   */
  disabled: PropTypes.bool,
  /**
   * The tabs orientation (layout flow direction).
   */
  orientation: PropTypes.oneOf(['horizontal', 'vertical']).isRequired,
};

TabScrollButton.defaultProps = {
    customRootClass: '',
    disabled: 'false',
    className: '',
};

export default TabScrollButton;
