import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { OrderingEnum } from 'api/ordering/types';
import { useGetOrdering } from 'api/ordering/useGetOrdering';
import { useCanAdministrateSearches } from 'hooks/useCheckUserRight';
import {
  SavedSearch,
  SavedSearchEntity,
  SearchFilterProps,
  VisiblePlaces,
} from 'types/graphqlTypes';
import { sortByOrdering } from 'utils/sortByOrder';

import { GET_SAVED_SEARCHES } from './gql';

export interface GetSavedSearches {
  getSavedSearches: SavedSearchEntity[];
}

export const toSavedSearchDto = (s: SavedSearchEntity): SavedSearch => {
  try {
    return {
      ...s,
      visibleIn: s.visibleIn ?? [],
      visibleTo: s.visibleTo ?? [],
      filters: JSON.parse(s.filters ?? '{}') as SearchFilterProps,
    };
  } catch (err) {
    return {
      ...s,
      visibleIn: s.visibleIn ?? [],
      visibleTo: s.visibleTo ?? [],
      filters: {},
    };
  }
};

export const locationToOrderingMap: Record<VisiblePlaces | 'default', OrderingEnum> = {
  default: OrderingEnum.savedSearchCommandbar,
  left_sidebar: OrderingEnum.savedSearchLeftSideBar,
  contacts_sidebar: OrderingEnum.savedSearchContacts,
};

export const useGetSavedSearches = (location?: VisiblePlaces) => {
  const { canAdministrate } = useCanAdministrateSearches();

  const { ordering, loading: orderingLoading } = useGetOrdering(
    locationToOrderingMap[location ?? 'default'],
  );
  const { data, error, loading, refetch } = useQuery<GetSavedSearches>(GET_SAVED_SEARCHES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        all: canAdministrate,
        location,
      },
    },
  });

  const searches = useMemo(() => {
    if (orderingLoading || loading) return [];
    const items = data?.getSavedSearches?.map(toSavedSearchDto) ?? [];
    return sortByOrdering<SavedSearch>(items, ordering?.order || []);
  }, [data, ordering, orderingLoading, loading]);

  return {
    searches,
    error,
    loading,
    refetch,
  };
};
