import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { ReactComponent as Delete } from 'assets/icons/systemicons/delete.svg';
import { ReactComponent as Open } from 'assets/icons/systemicons/open.svg';
import { ReactComponent as SidebarActive } from 'assets/icons/systemicons/sidebar_active.svg';
import { ReactComponent as SidebarInactive } from 'assets/icons/systemicons/sidebar_inactive.svg';
import { Box } from 'layouts/box/Box';
import transientOptions from 'theme/helpers';
import { svgHoverStyles } from 'theme/utils/commonStyles';

export const HtmlContent = styled('div', transientOptions)<{ $collapsed?: boolean }>`
  border: 1px solid ${({ theme }) => theme.palette.dina.blockBorderHover};
  ${({ theme }) => css`
    background-color: ${theme.palette.dina.highEmphasis};
    color: ${theme.palette.dina.blackHighEmphasis};
  `}
  white-space: normal;
  user-select: text !important;
  padding: 12px;
  ${({ $collapsed }) =>
    $collapsed &&
    css`
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    `}
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  width: 430px;
  height: 240px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-color: ${({ theme }) => theme.palette.dina.dividerDark};
  }
`;

export const HtmlBlock = styled('div')`
  padding: 8px;
  :hover .actions {
    width: 32px;
  }
`;

export const Actions = styled(Box, transientOptions)<{ $collapsed?: boolean }>`
  z-index: 100;
  padding: 4px 0;
  width: 0px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  ${({ $collapsed }) =>
    $collapsed &&
    css`
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    `}
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundMain};
  -webkit-transition: width 0.11s ease-in-out;
  -moz-transition: width 0.11s ease-in-out;
  -o-transition: width 0.11s ease-in-out;
  transition: width 0.11s ease-in-out;
`;

export const StyledOpen = styled(Open)`
  ${svgHoverStyles}
  width: 32px;
`;

export const StyledDelete = styled(Delete)`
  ${svgHoverStyles}
  width: 32px;
`;

export const StyledSidePanelActive = styled(SidebarActive)`
  ${svgHoverStyles}
  width: 32px;
`;

export const StyledSidePanelInactive = styled(SidebarInactive)`
  ${svgHoverStyles}
  width: 32px;
`;

export const LeftSideWrapper = styled('div')`
  position: relative;
`;
