import { memo } from 'react';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import { GridWidgetContainer } from 'features/grids/widget/GridWidget';
import useGetData from 'features/grids/widget/hooks/useGetData';
import WidgetWrapper from 'features/widget/components/WidgetWrapper';
import { WIDGETS } from 'features/widget/constants';

import { WidgetProps } from './types';

type DataTableWidgetProps = Omit<WidgetProps, 'filters'>;

function DataTableWidget({ mId, ...wrapperProps }: Readonly<DataTableWidgetProps>) {
  const { itemGroupId, items, itemIds, loading } = useGetData(mId);
  const {
    mdfs,
    mdfsSeparated: { subTypes },
  } = useGetMdfs({ all: true });

  if (loading) return <div>Loading...</div>;

  return (
    <WidgetWrapper mId={mId} type={WIDGETS.TABLE} {...wrapperProps}>
      <GridWidgetContainer
        boardId={mId}
        data={items}
        groupId={itemGroupId as string}
        itemIds={itemIds}
        mdfs={mdfs}
        subTypes={subTypes}
      />
    </WidgetWrapper>
  );
}

export default memo(DataTableWidget);
