import styled from '@emotion/styled';

import Text from 'components/text/Text';
import transientOptions from 'theme/helpers';

// All styles specific to tree choice here
export const DefaultChoiceText = styled(Text)`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  :hover {
    cursor: pointer;
    color: ${({ theme }) => theme.palette.dina.highEmphasis} !important;
  }
`;

export const CurrentChoice = styled('div')`
  height: 32px;
  padding-block: 6px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TreeViewWrapper = styled('div')`
  min-height: 40vh;
  max-height: 70vh;
  overflow: scroll;
`;

export const StyledTreeView = styled('ul')`
  list-style-type: none;
  margin: 0px;
  padding-left: 0px;
  display: flex;
  flex-direction: column;
`;

export const StyledTreeNode = styled('li')`
  display: flex;
  flex-direction: column;
  gap: 4px;
  ul {
    padding-left: 16px;
  }
`;

export const NodeBody = styled('div')`
  height: 28px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.palette.dina.hoverOverlay};
  }
`;
interface ArrowWrapperProps {
  $match?: boolean;
}
export const ArrowWrapper = styled('div', transientOptions)<ArrowWrapperProps>`
  height: 24px;
  width: 24px;
  svg {
    path {
      fill: ${({ $match, theme }) =>
        $match ? theme.palette.dina.textUrl : theme.palette.dina.iconActive};
      fill-opacity: 0.54;
    }
  }
  :hover {
    svg {
      path {
        fill-opacity: 1;
      }
    }
  }
`;

export const NodeLabelWrapper = styled('div')`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  :first-of-type {
    margin-left: 24px !important;
  }
`;

interface NodeLabelProps {
  $match: boolean;
}

export const NodeLabel = styled(Text, transientOptions)<NodeLabelProps>`
  transition: all 150ms ease-in;
  background-color: ${({ $match, theme }) => ($match ? theme.palette.dina.gridFloat : 'auto')};
  user-select: none;
  padding: 4px;
  border-radius: 4px;
`;

// search bar
export const SearchWrapper = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const SearchMatchCount = styled('div')`
  display: flex;
  align-items: center;
  gap: 2px;
`;

export const CountText = styled(Text)`
  user-select: none;
  min-width: 22px;
  text-align: right;
`;

export const SearchArrowWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const SearchArrow = styled('div')`
  cursor: pointer;
  height: 20px;
  svg {
    path {
      fill-opacity: 0.54;
    }
  }
  :hover {
    svg {
      path {
        fill-opacity: 1;
      }
    }
  }
`;

interface LocalChoiceProps {
  $error: boolean;
}

export const LocalChoice = styled('div', transientOptions)<LocalChoiceProps>`
  margin-top: 4px;
  padding: 6px 8px;
  border-radius: 4px;
  border: 1px solid
    ${({ $error, theme }) => ($error ? theme.palette.dina.errorBorder : 'transparent')};
`;
