import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DialogContent, DialogActions } from '@material-ui/core';

import { Button } from 'components/buttons';
import Dialog from 'components/dialog';
import Divider from 'components/divider';

import useStyles from './dialogWrapper-styles';

const DialogWrapper = ({ label, closeDialog, children, isPreview, mode, isDirty }) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleClose = () => {
    if (isDirty) {
      setDialogOpen(true);
    } else {
      closeDialog();
    }
  };

  return (
    <div className={classes.container}>
      <div className={`${classes.header} dragHandler`}>
        <div className={classes.titleWrapper}>
          <div className={classes.mode}>{mode}</div>
          <div className={classes.title}>{label}</div>
        </div>
      </div>
      {!isPreview && <Divider />}
      {children}
      <Divider />
      <div className={classes.footer}>
        <div
          role="presentation"
          onClick={() => handleClose()}
          className={!isDirty ? classes.button : classes.buttonWarning}
        >
          {isDirty ? 'Close without saving?' : 'Close'}
        </div>
      </div>
      <Dialog open={dialogOpen}>
        <DialogContent className={classes.warningDialog}>
          <div>Article is not saved in CMS!</div>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} variant="contained" usage="significant">
            Cancel
          </Button>
          <Button onClick={() => closeDialog()} variant="contained" usage="danger">
            Close without saving?
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DialogWrapper.propTypes = {
  /** whether dialog is being used for preview or not */
  isPreview: PropTypes.bool,
  /** Title of the preview dialog */
  label: PropTypes.string,
  /** Callback to close preview dialog */
  closeDialog: PropTypes.func,
  /** children to render insider the dialog wrapper */
  children: PropTypes.element,
  /** mode of the dialog */
  mode: PropTypes.string,
};

DialogWrapper.defaultProps = {
  isPreview: false,
  label: '',
  closeDialog: () => {},
  children: null,
  mode: 'Editing',
};

export default DialogWrapper;
