import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Typography } from '@material-ui/core';

import ICONS from 'assets/icons/icons';
import Icon from 'components/icon';

const OuterWrapper = styled('div')`
  height: 100%;
  width: 100%;
`;

const Wrapper = styled('div')`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.dina.surfaceCard};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
  justify-content: center;
  user-select: none;
`;

const StyledText = styled(Typography)`
  ${({ theme }) => css`
    ${theme.typography.dina.body1};
    color: ${theme.palette.dina.mediumEmphasis};
  `}
  margin-top: 8px;
`;

const LABEL = 'No Feed Item selected';

function FeedViewerEmptyState() {
  return (
    <OuterWrapper>
      <Wrapper>
        <Icon icon={ICONS.FEEDS_OFF} width={64} height={64} />
        <StyledText>{LABEL}</StyledText>
      </Wrapper>
    </OuterWrapper>
  );
}

export default FeedViewerEmptyState;
