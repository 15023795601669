import { memo } from 'react';
import { useDrop } from 'react-dnd';
import { useSlate } from 'slate-react';

import DragAndDrop from 'components/editor/components/dragAndDrop';
import { onAssetDrop } from 'components/editor/components/paragraph/utils';
import { useEditorContext } from 'components/editor/EditorContext';
import Tooltip from 'components/tooltip';
import { BlockElement } from 'types';
import { DroppedAsset } from 'utils/normalizeAssetData';
import { mediaTypes } from 'utils/rundownItemTypes';

import { DropZone, StyledParagraph } from './styled';

const BaseParagraph = ({ attributes, children, element }: Readonly<BlockElement>) => {
  const editor = useSlate();
  const { update, doLock, isLockedByAnotherUser } = useEditorContext();

  const [{ isOver }, drop] = useDrop({
    accept: [mediaTypes.CLIP],
    canDrop: (item: { type: string; payload: DroppedAsset }) => item.payload.itemType === 'image',
    drop(item: { type: string; payload: DroppedAsset }) {
      if (item.payload.itemType === 'image') {
        doLock?.();
        onAssetDrop({ payload: item.payload }, editor, element, update).catch((err) =>
          // eslint-disable-next-line no-console
          console.error(err),
        );
      }
      return;
    },
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
      overId: monitor.getItem<{ id: string }>(),
    }),
  });

  return (
    <DragAndDrop element={element} isDragDisabled={true}>
      <DropZone $isOver={isOver} {...attributes}>
        <Tooltip title={isOver ? 'Drop to place below this paragraph' : ''}>
          <StyledParagraph
            ref={(ref) => {
              drop(ref);
            }}
            $locked={!!isLockedByAnotherUser}
            onClick={doLock}
          >
            {children}
          </StyledParagraph>
        </Tooltip>
      </DropZone>
    </DragAndDrop>
  );
};

export default memo(BaseParagraph);
