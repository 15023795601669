import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import capitalize from 'lodash/capitalize';

import { ProviderOutput } from 'api/providers/provider-types';
import { GET_PROVIDER } from 'api/providers/useGetChannelProvider';
import useToast from 'components/toast/useToast';
import { useUserConfig } from 'store';

import { useChangedProviderNotificationSettings } from '../atomsTs';

interface UpdateProviderInput {
  memberId: string;
  providerName: string;
  notificationPreferences: string; //AWSJSON - {[key]:value}
}

const UPDATE_NOTIFICATION_PREFERENCE = gql`
  mutation UpdateProvider($input: UpdateProviderInput) {
    updateProvider(input: $input) {
      member {
        mId
        mType
      }
      providerDetails {
        id
        resourceUrl
        resourcePermit
      }
      notificationPreferences {
        title
        key
        value
        subPreferences {
          title
          key
          value
          subPreferences {
            title
            key
            value
          }
        }
      }
    }
  }
`;

const useUpdateProviderNotifications = (): { update: () => Promise<void>; loading: boolean } => {
  const [loading, setLoading] = useState(false);
  const [user] = useUserConfig();
  const { errorToast } = useToast();
  const [changedProviderNotificationSettings, setChangedProviderNotificationSettings] =
    useChangedProviderNotificationSettings();
  const [update] = useMutation<{ updateProvider: ProviderOutput }, { input: UpdateProviderInput }>(
    UPDATE_NOTIFICATION_PREFERENCE,
  );

  const handleUpdate = async () => {
    const userId = user.mId;
    if (!userId || !changedProviderNotificationSettings.length) return;

    setLoading(true);
    await Promise.all(
      changedProviderNotificationSettings.map(({ providerName, preferences }) => {
        return update({
          variables: {
            input: {
              memberId: userId,
              providerName,
              notificationPreferences: JSON.stringify(preferences),
            },
          },
          onError: (error) => {
            errorToast(
              error,
              `Failed to update notification settings for ${capitalize(providerName)}`,
            );
          },
          update: (proxy, { data }) => {
            if (data) {
              proxy.writeQuery({
                query: GET_PROVIDER,
                variables: { input: { providerName, memberId: userId } },
                data: { getProvider: data?.updateProvider },
              });
            }
          },
        });
      }),
    );

    setChangedProviderNotificationSettings([]);
    setLoading(false);
  };

  return { update: handleUpdate, loading };
};

export default useUpdateProviderNotifications;
