import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  format as fnsFormat,
  setMinutes,
  setHours,
  getMonth,
  setMonth,
  getYear,
  setYear,
  isAfter,
  startOfDay,
  endOfDay,
} from 'date-fns';
import { ReactComponent as ArrowLeft } from 'assets/icons/systemicons/arrows/disclosurearrow_left.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/systemicons/arrows/disclosurearrow_right.svg';
import Header from './header';
import Footer from './footer';
import QuickSelect from './quickSelect';
import DateSelector from './dateSelector';
import MonthSelector from './monthSelector';
import YearSelector from './yearSelector';

import {
  ContainerWrapper,
  RootWrapper,
  BodyWrapper,
  SelectorsWrapper,
  DateSelectorWrapper,
  MonthSelectorWrapper,
  YearSelectorWrapper,
  OpenButton,
} from './styled';

const Calendar = ({
  selectedDate,
  changeSelectedDate,
  selectedDateRange,
  changeSelectedDateRange,
  selectRange,
  hideUnscheduleButton,
  showTimePicker,
  showQuickSelect,
  showTypeSelect,
  format,
  onUnschedule,
  isExpanded,
  setIsExpanded,
  disableRangeSwitch,
  onClose,
  disableScheduleInPast,
  allowTimeSelection,
  onTypeSelect,
  dateType,
  disabled,
  ...rest
}) => {
  const { startDate: sDate, endDate: eDate } = selectedDateRange;
  const dateValue = selectedDate || sDate || new Date().toISOString();
  const [date, setDate] = useState(dateValue);
  const [selectedMonth, setSelectedMonth] = useState();
  const [selectedYear, setSelectedYear] = useState();
  const [selectedMinuteHour, setSelectedMinuteHour] = useState();
  const [endMinuteHour, setEndMinuteHour] = useState();
  const [openDrawer, setOpenDrawer] = useState(isExpanded);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isDateChanged, setIsDateChanged] = useState(false);

  useEffect(() => {
    setSelectedMonth(getMonth(date));
    setSelectedYear(getYear(date));
    setSelectedMinuteHour(fnsFormat(date, format === 12 ? 'hh:mma' : 'HH:mm'));
  }, [date, format]);

  useEffect(() => {
    setEndMinuteHour(fnsFormat(endDate, format === 12 ? 'hh:mma' : 'HH:mm'));
  }, [endDate, format]);

  useEffect(() => {
    if (!isDateChanged) {
      setStartDate(sDate);
      setEndDate(selectRange ? eDate : sDate);
    }
  }, [eDate, sDate, isDateChanged, selectRange]);

  const handleToggleDrawer = () => {
    setIsExpanded(!isExpanded);
    setOpenDrawer(!openDrawer);
  };

  const handleTypeSelect = useCallback(
    (newType) => {
      setIsDateChanged(true);
      onTypeSelect(newType);
    },
    [setIsDateChanged, onTypeSelect],
  );

  const handleRangeSelect = useCallback(
    (newDate, dateFor) => {
      setIsDateChanged(true);
      if (dateFor === 'date') {
        setDate(startOfDay(newDate).toISOString());
        setStartDate(startOfDay(newDate).toISOString());
        setEndDate(null);
        return;
      }

      let firstDate;
      let lastDate;
      if (isAfter(newDate, startDate)) {
        firstDate = startDate;
        lastDate = endOfDay(newDate).toISOString();
      } else {
        firstDate = startOfDay(newDate).toISOString();
        lastDate = endOfDay(startDate).toISOString();
      }
      setDate(firstDate);
      setStartDate(firstDate);
      setEndDate(lastDate);
    },
    [startDate],
  );

  const handleDateInputChange = useCallback((newDate, dateFor) => {
    setIsDateChanged(true);
    if (dateFor === 'date') {
      setDate(startOfDay(newDate).toISOString());
      setStartDate(startOfDay(newDate).toISOString());
      setEndDate(null);
    } else {
      setEndDate(endOfDay(newDate).toISOString());
    }
  }, []);

  const onApply = () => {
    changeSelectedDateRange({ startDate, endDate });
  };

  const onMonthSelect = (newMonth) => {
    setSelectedMonth(newMonth);
    setDate(setMonth(date, newMonth).toISOString());
  };

  const onYearSelect = (newYear) => {
    setSelectedYear(newYear);
    setDate(setYear(date, newYear).toISOString());
  };

  const onTimeSelect = (newTime, timeFor = 'date') => {
    setIsDateChanged(true);
    timeFor === 'date' ? setSelectedMinuteHour(newTime) : setEndMinuteHour(newTime);
    const hour = newTime.substring(0, 2);
    const minute = newTime.substring(3, 5);
    const meridiem = newTime.substring(5, 6);
    const newDate = new Date(
      setHours(
        setMinutes(timeFor === 'date' ? date : endDate, minute),
        meridiem === 'pm' ? hour + 12 : hour,
      ),
    ).toISOString();

    timeFor === 'date'
      ? (() => {
          setDate(newDate);
          setStartDate(newDate);
          changeSelectedDate(newDate);
        })()
      : setEndDate(newDate);
  };

  const onQuickSelect = (diff) => {
    const today = new Date();
    const lsdate = endOfDay(today).toISOString();
    today.setDate(today.getDate() + diff);
    const ledate = startOfDay(today).toISOString();
    const params = {
      startDate: diff < 0 ? ledate : lsdate,
      endDate: diff < 0 ? lsdate : ledate,
    };
    changeSelectedDateRange(params);
  };

  return (
    <ContainerWrapper>
      <RootWrapper>
        {showQuickSelect && <QuickSelect onQuickSelect={onQuickSelect} isExpanded={isExpanded} />}
        <Header date={date} setDate={setDate} handleToggleDrawer={handleToggleDrawer} />
        <BodyWrapper>
          <DateSelectorWrapper>
            <DateSelector
              {...{
                date,
                setDate,
                startDate,
                endDate,
                ...rest,
              }}
              selectRange={selectRange}
              onDateSelect={handleRangeSelect}
              disableScheduleInPast={disableScheduleInPast}
            />
          </DateSelectorWrapper>

          <SelectorsWrapper $openDrawer={openDrawer}>
            <MonthSelectorWrapper>
              <MonthSelector month={selectedMonth} onMonthSelect={onMonthSelect} />
            </MonthSelectorWrapper>
            <YearSelectorWrapper>
              <YearSelector year={selectedYear} onYearSelect={onYearSelect} />
            </YearSelectorWrapper>
          </SelectorsWrapper>
          <OpenButton
            onClick={handleToggleDrawer}
            title={`${openDrawer ? 'Hide month and years' : 'Show month and years'}`}
          >
            {openDrawer ? <ArrowLeft /> : <ArrowRight />}
          </OpenButton>
        </BodyWrapper>

        <Footer
          showTypeSelect={showTypeSelect}
          onApply={onApply}
          onClose={onClose}
          onUnschedule={onUnschedule}
          isDateChanged={isDateChanged}
          startDate={startDate}
          startTime={selectedMinuteHour}
          endDate={endDate}
          endTime={endMinuteHour}
          selectRange={selectRange}
          showTimePicker={showTimePicker}
          interval={15}
          format={format}
          onTimeSelect={onTimeSelect}
          onDateSelect={handleDateInputChange}
          onTypeSelect={handleTypeSelect}
          dateType={dateType}
          handleRangeSelect={handleRangeSelect}
          hideUnscheduleButton={hideUnscheduleButton || !(selectedDate || sDate || eDate)}
          allowTimeSelection={allowTimeSelection}
          disabled={disabled}
        />
      </RootWrapper>
    </ContainerWrapper>
  );
};

Calendar.propTypes = {
  /** selected date of the calendar */
  selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  /** callback to change the selected date */
  changeSelectedDate: PropTypes.func,
  /** Boolean that shows select range button */
  selectRange: PropTypes.bool,
  /** Boolean that hides unschedule button */
  hideUnscheduleButton: PropTypes.bool,
  /** Callback on unschedule button click */
  onUnschedule: PropTypes.func,
  /** Boolean to make the calendar component expanded */
  isExpanded: PropTypes.bool,
  /** SetState callback to set isExpanded in the globalState */
  setIsExpanded: PropTypes.func,
  /** callback to change selected date */
  changeSelectedDateRange: PropTypes.func,
  /** range of selected date */
  selectedDateRange: PropTypes.shape({
    startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  }),
  /** Boolean that shows time picker */
  showTimePicker: PropTypes.bool,
  /** Interval between each step of time list to show */
  interval: PropTypes.number,
  /** If the date should show 24 or 12 hour format */
  format: PropTypes.number,
  /** defines if we should show the select a range toggle in the calendar header */
  disableRangeSwitch: PropTypes.bool,
  /** disable updating date value */
  disabled: PropTypes.bool,
};

Calendar.defaultProps = {
  selectedDate: '',
  changeSelectedDate: (date) => {},
  selectRange: false,
  hideUnscheduleButton: false,
  onUnschedule: () => {},
  isExpanded: false,
  setIsExpanded: () => {},
  changeSelectedDateRange: () => {},
  selectedDateRange: {
    startDate: null,
    endDate: null,
  },
  showTimePicker: false,
  interval: 15,
  format: 24,
  disableRangeSwitch: false,
  disabled: false,
};

export default Calendar;
