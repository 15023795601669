/* eslint-disable max-len */
import { Path, Svg } from '@react-pdf/renderer';

const StoryIcon = () => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Path
        fillRule="evenodd"
        d="M19 7.41L14.59 3H5V21H19V7.41ZM5 2H15L20 7V21C20 21.55 19.55 22 19 22H5C4.45 22 4 21.55 4 21V3C4 2.45 4.45 2 5 2ZM12 8H7V9H12V8ZM7 10H16V11H7V10ZM14 12H7V13H14V12Z"
        fill="black"
      />
    </Svg>
  );
};

export default StoryIcon;
