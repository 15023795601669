import { elementTypes } from 'components/editor/constants';
import { ReactComponent as HeadingOne } from 'assets/icons/systemicons/editor/h1_off.svg';
import { ReactComponent as HeadingTwo } from 'assets/icons/systemicons/editor/h2_off.svg';
import { ReactComponent as HeadingThree } from 'assets/icons/systemicons/editor/h3_off.svg';
import { ReactComponent as HeadingFour } from 'assets/icons/systemicons/editor/h4_off.svg';

const { HEADING_ONE, HEADING_TWO, HEADING_THREE, HEADING_FOUR } = elementTypes;

const iconComponents = {
  [HEADING_ONE]: HeadingOne,
  [HEADING_TWO]: HeadingTwo,
  [HEADING_THREE]: HeadingThree,
  [HEADING_FOUR]: HeadingFour,
};

export default iconComponents;
