import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputField from 'components/inputField/InputField';
import useInputEvents from 'hooks/useInputEvents';
import GeneralLabel from '../generalLabel';
import SeverityIndicator from '../severityIndicator';
import ContainerBox from '../containerBox';

const InputFieldView = ({
  label,
  severity,
  inputDescription,
  value: initialValue,
  onUpdateInput,
  usage,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState(initialValue);

  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);

  const onUpdate = newValue => {
    if (newValue === initialValue) setInputValue(newValue);
    else onUpdateInput(newValue);
  };
  const [inputRef, handleKeyDown, handleBlur] = useInputEvents(onUpdate, inputValue, initialValue);

  return (
    <ContainerBox
      usage={usage}
      Indicator={<SeverityIndicator {...{ severity }} />}
      Label={<GeneralLabel {...{ severity, label, usage }} />}
      Field={
        <InputField
          usage="metadata"
          description={inputDescription}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          disableLabel
          onChange={setInputValue}
          onClear={() => onUpdateInput('')}
          value={inputValue}
          {...{ severity, inputRef }}
          {...rest}
        />
      }
    />
  );
};

InputFieldView.propTypes = {
  /** label for the composite field */
  label: PropTypes.string,
  /** color for label, indicator and input underline */
  severity: PropTypes.string,
  /** input assistive text */
  inputDescription: PropTypes.string,
  /** Value of the input field */
  value: PropTypes.string,
  /** onUpdateInput callback function */
  onUpdateInput: PropTypes.func,
};

InputFieldView.defaultProps = {
  label: 'Label',
  severity: 'regular',
  inputDescription: '',
  value: '',
  onUpdateInput: () => {},
};

export default InputFieldView;
