import urlPrefix from 'components/editor/constants/urlPrefix';

const getInstanceIdFromLink = (href) => {
  if (typeof href === 'string' && href.startsWith('dina://')) {
    const splittedHref = href.split(urlPrefix.LINKED_INSTANCE_HREF_PREFIX);
    return splittedHref.length === 2 ? splittedHref[1] : '';
  }
  return '';
};

export default getInstanceIdFromLink;
