import actionTypes from './actionTypes';

const updateRow = (state, payload) => {
  const newState = Array.from(state);
  newState[payload.cIndex][payload.rIndex].value = payload.value;
  return newState;
};

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.UPDATE_ROW:
      return updateRow(state, payload);
    case actionTypes.INSERT_ROW:
      return [...state, payload.map(column => ({ key: column.id, value: '' }))];
    default:
      return state;
  }
};

export default reducer;
