import { makeStyles } from '@material-ui/styles';
import { InstanceItemVariant } from 'screens/main/components/leftArea/sidebar/instances/list/listItem/listItem-view';

const useStyles = makeStyles((theme) => {
  const buttonStyle = {
    ...theme.typography.dina.button,
    height: '40px',
    borderRadius: '4px',
    textTransform: 'none',
    fontWeight: 500,
    width: '151.5px',
  };

  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      height: ({ variant }) => {
        switch (variant) {
          case InstanceItemVariant.CREATE_LINK_LIST_ITEM:
            return '760px';
          default:
            return '100%';
        }
      },
      backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel1,
      borderRadius: ({ variant }) => {
        switch (variant) {
          case InstanceItemVariant.CREATE_LINK_LIST_ITEM:
            return '2px';
          default:
            return '0';
        }
      },
      width: ({ variant }) => {
        switch (variant) {
          case InstanceItemVariant.CREATE_LINK_LIST_ITEM:
            return '327px';
          default:
            return 'auto';
        }
      },
    },
    divider: {
      position: 'relative',
      top: -1,
    },
    filterBar: {
      position: 'relative',
      bottom: 0,
      width: '100%',
      height: '43px',
    },
    headerTextWrapper: {
      height: '40px',
    },
    sidebarHeader: {
      height: '40px',
      minHeight: '40px',
      padding: '0 12px 0 12px',
      paddingBottom: '4px',
      display: 'flex',
      alignItems: 'flex-end',
    },
    sidebarHeaderText: ({ variant }) => {
      switch (variant) {
        case InstanceItemVariant.CREATE_LINK_LIST_ITEM:
          return {
            ...theme.typography.dina.h5,
            lineHeight: '28px',
            padding: '15px 15px 15px 15px',
            width: '100%',
            borderBottom: `1px solid ${theme.palette.dina.dividerLight}`,
            color: theme.palette.dina.highEmphasis,
            backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel2,
          };
        default:
          return {
            ...theme.typography.dina.h5,
            lineHeight: '28px',
            color: theme.palette.dina.mediumEmphasis,
          };
      }
    },
    label: {
      ...theme.typography.dina.caption,
    },
    toggle: {
      color: theme.palette.dina.highEmphasis,
    },
    toggleThumb: {
      color: theme.palette.dina.highEmphasis,
    },
    checked: {},
    track: {},
    primary: {
      '&$checked + $track': {
        backgroundColor: theme.palette.dina.iconSelected,
      },
    },
    datepickerContainer: {
      '& hr': {
        display: 'none',
      },
    },
    buttonContainer: {
      padding: '8px',
      background: theme.palette.dina.surfaceCard,
    },
    cancelButton: {
      ...buttonStyle,
      border: `0.5px solid ${theme.palette.dina.buttonBorderOutlined}`,
    },
    createButton: {
      ...buttonStyle,
      backgroundColor: theme.palette.dina.statusApproved,
      marginLeft: '8px',
      '&:hover': {
        backgroundColor: theme.palette.dina.statusApproved,
      },
    },
    disabled: {
      opacity: '0.35',
      color: `${theme.palette.dina.highEmphasis} !important`,
    },
    buttonLabel: {
      color: theme.palette.dina.highEmphasis,
    },
    checkBoxWrapper: {
      height: 56,
      width: '100%',
      padding: 8,
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      borderBottom: `1px solid ${theme.palette.dina.dividerLight}`,
      backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel2,
    },
    checkBoxLabel: {
      ...theme.typography.dina.listItemLabel,
      color: theme.palette.dina.highEmphasis,
    },
  };
});

export default useStyles;
