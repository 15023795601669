import { useMemo, useState } from 'react';

import AddMemberDropdown from 'components/addMember';
import MemberLabel from 'components/addMember/MemberLabel';
import { SelectAssigneeWrapper } from 'components/orderFormDialog/styled';
import Text from 'components/text/Text';
import Tooltip from 'components/tooltip';
import { useAllMembersKeyed } from 'store';
import { FieldValue } from 'types/forms/forms';
import { AssignedMember } from 'types/members';

import { FieldHeader } from '../../styled';
import { FieldProps } from '../fields';
import { normalizeFilter } from '../utils';

export const getTextValue = (value: FieldValue): string => {
  // Should not happen - lets normalize.
  if (Array.isArray(value)) {
    return '';
  }

  if (typeof value === 'string') {
    return value;
  }

  return '';
};

export function UserField({
  fieldModel,
  fieldSettings,
  defaultFieldSettings,
  value,
  setValue,
  errorMessage,
  style,
}: FieldProps) {
  const [allMembersKeyed] = useAllMembersKeyed();
  const [isFindingAssignee, setIsFindingAssignee] = useState(false);
  const { filter } = fieldModel;
  const { hint, label } = fieldSettings ?? defaultFieldSettings;

  const normalizedFilter = normalizeFilter(filter);
  const isContact = normalizedFilter?.includes('contact');

  const onSelectAssignee = (newAssignee: AssignedMember[]) => {
    setValue(newAssignee[0]?.mId ?? '');
  };

  const assignee = useMemo(() => {
    const val = getTextValue(value);
    if (!val) return null;

    return (allMembersKeyed[val] as AssignedMember | undefined) ?? null;
  }, [value, allMembersKeyed]);

  const onRemoveClick = (ev: React.MouseEvent<Element, MouseEvent>) => {
    ev.stopPropagation();
    setValue('');
  };

  const onClose = () => {
    setIsFindingAssignee(false);
  };

  return (
    <Tooltip title={hint ?? ''}>
      <SelectAssigneeWrapper
        style={{ minWidth: 200, marginBottom: '4px', gap: label ? '5px' : '0px', ...(style || {}) }}
      >
        <FieldHeader>{label}</FieldHeader>
        {isFindingAssignee ? (
          <AddMemberDropdown
            variants={normalizedFilter}
            variant="all" // fallback to all in case filter is undefined
            singleChoice
            autoFocus
            disableClearable={true}
            selectedMembers={assignee ? [assignee] : []}
            setSelectedMembers={onSelectAssignee}
            placeholderText="Find member"
            noOptionsText="No matches found"
            onClose={onClose}
            injectedMembers={undefined}
            doCallApi={isContact}
          />
        ) : (
          <>
            <MemberLabel
              variant="form"
              memberId={getTextValue(value)}
              onClick={() => setIsFindingAssignee(true)}
              onRemoveClick={onRemoveClick}
            />
            {errorMessage && (
              <Text variant="caption" color="statusWarning">
                {errorMessage}
              </Text>
            )}
          </>
        )}
      </SelectAssigneeWrapper>
    </Tooltip>
  );
}
