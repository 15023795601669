import { AriaRole, HTMLAttributes, MouseEventHandler } from 'react';

type KeyboardEventHandler = (
  e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
) => void;

type Role = AriaRole | undefined;
type TabIndex = number | undefined;

/**
 * Returns a function that triggers the provided event handler when the Enter key is pressed.
 *
 * @param handler - The event handler to be called when the Enter key is pressed.
 * @returns A function that can be used as an event handler for key events.
 */
export const executeOnEnter = (handler: KeyboardEventHandler) => {
  return (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      handler(e);
    }
  };
};

/**
 * Generates an object that can be used to make an element accessible for keyboard interaction.
 *
 * @param handler - The event handler for keyboard events and clicks.
 * @param role - The ARIA role for the element. Default is 'button'.
 * @param tabIndex - The tabindex value for the element. Default is 0.
 * @returns An object with properties that make the element accessible and trigger
 * the provided event handler.
 */
const accessibleOnClick = (
  handler: KeyboardEventHandler | MouseEventHandler<HTMLElement>,
  role: Role = 'button',
  tabIndex: TabIndex = 0,
): HTMLAttributes<HTMLElement> => {
  return {
    role,
    tabIndex,
    onClick: handler as MouseEventHandler<HTMLElement>,
    onKeyDown: executeOnEnter(handler as KeyboardEventHandler),
  };
};

export default accessibleOnClick;
