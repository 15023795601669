import React from 'react';
import { Editor, Element, isEnd, isStart, Transforms } from 'slate';

import { elementTypes } from 'components/editor/constants';
import { CustomElement } from 'types';
import preventDefaultEvent from 'utils/preventDefaultEvent';

const onCheckListKeyDown = (editor: Editor, event: React.KeyboardEvent<HTMLDivElement>) => {
  const [match] = Editor.nodes<CustomElement>(editor, {
    match: (node) => Element.isElement(node) && node.type === elementTypes.CHECKLIST,
  });

  if (!match) return editor;

  const { key } = event;
  const isEnter = key === 'Enter';

  const [element, path] = match;
  const { selection } = editor;

  if (!selection) return editor;

  if (isEnter) {
    const nodeData = {
      type: elementTypes.CHECKLIST,
      data: { checked: false },
      children: [{ text: '' }],
    };

    if (isEnd(editor, selection.anchor, path)) {
      preventDefaultEvent(event);
      Transforms.insertNodes(editor, nodeData);
      return editor;
    }

    if (isStart(editor, selection.anchor, path)) {
      const { checked } = element.data ?? {};
      if (checked) {
        preventDefaultEvent(event);
        Transforms.insertNodes(editor, { ...nodeData, data: { checked } });
        Transforms.move(editor);
        Transforms.setNodes(editor, { data: { checked: false } });
      }
      return editor;
    }

    preventDefaultEvent(event);
    Transforms.splitNodes(editor);
    Transforms.setNodes(editor, { data: { checked: false } });
  }

  return editor;
};

export default onCheckListKeyDown;
