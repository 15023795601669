import styled from '@emotion/styled/macro';

import Divider from 'components/divider';

export const ButtonWrapper = styled('div')`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 0.5rem;
  border-top: 0.5px solid ${({ theme }) => theme.palette.dina.buttonBorderOutlined};
  justify-content: flex-end;
`;

export const FooterWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
`;

export const DTInputsWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.25rem;
  padding: 0 0.5rem;
  max-width: 494px;
  width: ${({ $withTimePicker }) => ($withTimePicker ? 'auto' : 'min-content')};
`;

export const InputDivider = styled(Divider)`
  margin-top: 1.25rem;
  width: 10px;
  background: ${({ theme }) => theme.palette.dina.mediumEmphasis};
`;

export const DateLabel = styled('div')`
  ${({ theme }) => theme.typography.dina.overline};
  text-transform: uppercase;
  padding: 8px 0 4px 0;
`;

export const DTInputWithLabel = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
