const getGridViewForms = (metadataFormsResult, atom) => {
  const ret = [];
  const { data: { getMetadataForms } = [] } = metadataFormsResult || {};
  getMetadataForms?.forEach((form) => {
    const { mProperties: { views = [] } = [] } = form;
    const gridViews = views?.filter((view) => view.type === 'grid') || [];

    gridViews?.forEach((view) => {
      const { id, name } = view;
      ret.push({ label: name, value: id });
    });
  });
  return ret;
};

export default getGridViewForms;
