/**
 * Finds an object in an array based on a specified property and replaces it if found,
 * otherwise adds it to the array.
 *
 * @param oldArray - The original array to search in.
 * @param newObj - The new object to be added or replace the existing object.
 * @param prop - The property name to search for in the objects.
 *
 * @returns A new array with the object replaced or added.
 *
 * @template T - The type of objects in the array.
 */

// eslint-disable-next-line @typescript-eslint/default-param-last
const findAndReplace = <T>(oldArray: T[], newObj: T, prop: keyof T) => {
  const objIndex = oldArray.findIndex((obj) => obj[prop] === newObj[prop]);
  if (objIndex >= 0)
    return [...oldArray.slice(0, objIndex), newObj, ...oldArray.slice(objIndex + 1)];

  return [newObj, ...oldArray];
};

export default findAndReplace;
