import { ChangeEvent, FocusEventHandler, KeyboardEventHandler, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import MUIInput from '@material-ui/core/Input';

import Text from 'components/text/Text';
import useInputEvents from 'hooks/useInputEvents';
import useStoryDescription from 'hooks/useStoryDescription';

import { useStoryMolecule } from './store/story';
import { useIsDescriptionShown } from './store/toolbar';

const DescriptionWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

const Input = styled(MUIInput)`
  ${({ theme }) => theme.typography.dina.listItemLabel};
  min-height: 32px;
  padding: 4px 8px;
  display: flex;
  flex: 1 0 0;
  align-items: center;
  background: ${({ theme }) => theme.palette.dina.inputBackground};
  backdrop-filter: blur(15px);
  border: 1px solid ${({ theme }) => theme.palette.dina.inputBorderResting};
  border-radius: 6px;
`;

const Description = () => {
  const [isDescriptionShown] = useIsDescriptionShown();
  const { useStory, useCanUpdateStoryValue, useIsDescriptionHiddenSettingsValue } =
    useStoryMolecule();
  const [story] = useStory();
  const canUpdateStory = useCanUpdateStoryValue();
  const isDescriptionHiddenSettings = useIsDescriptionHiddenSettingsValue();
  const { debouncedDescriptionChange, description } = useStoryDescription(
    story?.mId ?? '',
    story?.mDescription ?? '',
    canUpdateStory,
  );

  const [descriptionValue, setDescriptionValue] = useState<string>(description || '');

  const onUpdateDescription = async (newValue: string) => {
    const value = newValue.trim();
    if (value !== description) {
      await debouncedDescriptionChange(value);
    }
  };

  const [descriptionInputRef, onKeyDown, handleBlur] = useInputEvents(
    onUpdateDescription,
    descriptionValue,
    description ?? '',
  );

  const handleDescriptionChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined,
  ) => {
    const val = (event?.target as HTMLInputElement).value;
    setDescriptionValue(val);
  };

  useEffect(() => {
    setDescriptionValue(description || '');
  }, [description]);

  if (isDescriptionHiddenSettings || !isDescriptionShown) return null;

  return (
    <DescriptionWrapper>
      <Text variant="listItemLabelBold">Description</Text>
      <Input
        inputRef={descriptionInputRef}
        fullWidth
        disableUnderline
        placeholder="Type a brief description for the Story..."
        onChange={handleDescriptionChange}
        onBlur={handleBlur as FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined}
        value={descriptionValue || ''}
        disabled={!canUpdateStory}
        onKeyDown={
          onKeyDown as KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
        }
      />
    </DescriptionWrapper>
  );
};

export default Description;
