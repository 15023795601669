import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { TextField } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';

import { HStack } from 'layouts/box/Box';

export const ContentWrapper = styled(HStack)`
  height: 100%;
  flex: 1;
  justify-content: flex-start;
  gap: 4px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

export const FocusDiv = styled('span')`
  width: 100%;
  transform: translateZ(0);

  :last-of-type {
    ${ContentWrapper} {
      border: none;
    }
  }

  &:has(+ div) {
    ${ContentWrapper} {
      border: none;
    }
  }
`;

export const IconWrapper = styled('div')`
  height: 100%;
  padding-right: 4px;
  color: ${({ theme }) => theme.palette.dina.iconInactive};
`;

export const MUIListItem = styled(ListItem)<{ hovered?: boolean }>`
  height: 54px;
  padding: 0 2px 0 6px;
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid
    ${({ theme, hovered }) => (hovered ? theme.palette.dina.statusWarning : 'transparent')};
  background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel2};
  :hover {
    background-color: ${({ theme }) => theme.palette.dina.storyCardHover};
  }
  :focus {
    background-color: ${({ theme }) => theme.palette.dina.onFocus};
  }
`;

// CreateContact.tsx
export const Wrapper = styled('div')`
  height: 70vh;
  overflow: auto;
`;

export const StyledTextField = styled(TextField)`
  svg path {
    fill: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
  .MuiFormHelperText-contained {
    margin: 2px 0 -2px 2px;
    color: ${({ theme }) => theme.palette.dina.statusWarning};
  }
  .MuiFilledInput-root {
    padding: 2px !important;
    width: 100%;
    display: inline-flex;
    flex-flow: row wrap;
    min-height: 24px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.palette.dina.inputBackground};
    border: 1px solid ${({ theme }) => theme.palette.dina.inputBorderResting};
    &:hover {
      background-color: ${({ theme }) => theme.palette.dina.hoverOverlay};
    }
    &.Mui-focused {
      ${({ theme }) => css`
        background: ${theme.palette.dina.statusOnFocusedDiscreetFill};
        border-color: ${theme.palette.dina.statusOnFocused} !important;
      `}
    }
    &.Mui-error {
      border-color: ${({ theme }) => theme.palette.dina.warningBorder};
    }
  }
  input,
  textarea {
    width: 100%;
    font-size: 14px;
    padding: 4px !important;
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder {
      color: ${({ theme }) => theme.palette.dina.disabled};
    }
  }
  .MuiInputBase-inputAdornedEnd {
    padding: 4px 32px 4px 4px !important;
  }
  .MuiFilledInput-underline {
    &:hover:not($disabled):not($focused):not($error):before {
      border-bottom: none;
    }
    &:after {
      border-color: transparent;
    }
    &:before {
      border-color: transparent;
    }
  }
`;
