import { memo } from 'react';
import styled from '@emotion/styled';

const RootWrapper = styled('div')`
  height: 100%;
  display: flex;
  width: 24px;
  justify-content: center;
  align-items: center;
`;

const Vertical = styled('div')`
  width: 1px;
  height: 24px;
  background-color: ${({ theme }) => theme.palette.dina.dividerLight};
`;

function VerticalDivider() {
  return (
    <RootWrapper>
      <Vertical />
    </RootWrapper>
  );
}

export default memo(VerticalDivider);
