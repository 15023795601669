/* eslint-disable max-len */
const createMosMessage = (mosObj, element) => {
  const { data = {} } = element || {};
  const { itemId = 'no-itemID-specified' } = data;
  const parser = new DOMParser();
  const xmlOld = parser.parseFromString(mosObj, 'text/xml');
  const mos = xmlOld.querySelector('mos');
  let itemXML = mos.querySelector('item');
  if (!itemXML) {
    itemXML = mos;
  }

  while (itemXML.querySelector('itemID')) {
    itemXML.querySelector('itemID').remove();
  }
  const itemID = `<itemID>${itemId}</itemID>`;
  const item = `<item>${itemID}${itemXML.innerHTML}</item>`;
  const ncsItem = `<ncsItem>${item}</ncsItem>`;
  const mosMessage = `<mos>${ncsItem}</mos>`;
  return mosMessage;
};

const postMessage = (messageString, frameId) => {
  const ifrm = document.getElementById(frameId);
  if (ifrm) {
    ifrm.contentWindow.postMessage(messageString, '*');
  }
};

const postMosMessage = (messageString, frameId, element) => {
  const mosItemReplace = createMosMessage(messageString, element);
  console.log(`Send message to:${frameId}\n`, mosItemReplace);
  postMessage(mosItemReplace, frameId);
};
export { postMessage, postMosMessage };
