import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Divider as MaterialDivider, Typography } from '@material-ui/core';

/** LinksPanel */
export const IconWrapper = styled.span`
  display: flex;
  position: relative;
  cursor: pointer;
  ${({ theme }) => css`
    svg {
      width: 24px;
      height: 32px;
      path {
        fill: ${theme.palette.dina.whiteHighEmphasis};
        fill-opacity: ${theme.palette.mode === 'light' ? 0.65 : 0.54};
      }
    }
  `}
  &:hover {
    svg {
      path {
        fill-opacity: 1;
      }
    }
  }
`;

export const Panel = styled('div')`
  width: 500px;
  height: 400px;
  user-select: none;
`;

export const Title = styled(Typography)`
  ${({ theme }) => theme.typography.dina.h5};
  line-height: 28px;
  letter-spacing: normal;
  height: 64px;
  margin-left: 16px;
  display: flex;
  align-items: center;
  user-select: none;
`;

export const Divider = styled(MaterialDivider)`
  height: 1px;
  background-color: ${({ theme }) => theme.palette.dina.dividerLight};
`;

export const EmptyState = styled('div')`
  height: 60px;
  padding: 16px;
`;

export const EmptyStateText = styled(Typography)`
  ${({ theme }) => theme.typography.dina.caption};
`;
