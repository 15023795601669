import { Dispatch, SetStateAction, useContext } from 'react';

import { ReactComponent as Deactivate } from 'assets/icons/systemicons/deactivate_user.svg';
import { ReactComponent as UnHideIcon } from 'assets/icons/systemicons/visibility_off.svg';
import { ReactComponent as HideIcon } from 'assets/icons/systemicons/visibility_on.svg';
import { IconButton } from 'components/buttons';
import UserContext from 'contexts/UserContext';

import { ActionWrapper } from './styled';

interface UserValues {
  id: string;
  mRefId: string;
  username: string;
  email: string;
  notListed: boolean;
}

interface ActionPropType {
  setOpen: Dispatch<SetStateAction<boolean>>;
  setValues: Dispatch<SetStateAction<UserValues | undefined>>;
  setOpenUserHideDialog: Dispatch<SetStateAction<boolean>>;
  isHidden: boolean;
  mId: string;
  mRefId: string;
  username: string;
  email: string;
}

const Actions = ({
  setOpen,
  setValues,
  setOpenUserHideDialog,
  isHidden,
  mId,
  mRefId,
  username,
  email,
}: ActionPropType) => {
  const loggedInUser = useContext(UserContext);

  return (
    <ActionWrapper>
      <IconButton
        title="Disable user"
        onClick={() => {
          setOpen(true);
          setValues({
            id: mId,
            mRefId: mRefId,
            username: username,
            email: email,
            notListed: isHidden,
          });
        }}
        usage="text"
        variant="contained"
        disabled={mId === loggedInUser.mId}
      >
        <Deactivate />
      </IconButton>

      <IconButton
        title={isHidden ? 'Expose user' : 'Hide user'}
        onClick={() => {
          setOpenUserHideDialog(true);
          setValues({
            id: mId,
            mRefId: mRefId,
            username: username,
            email: email,
            notListed: isHidden,
          });
        }}
        usage="text"
        variant="contained"
      >
        {isHidden ? <UnHideIcon /> : <HideIcon />}
      </IconButton>
    </ActionWrapper>
  );
};

export default Actions;
