import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';

import transientOptions from 'theme/helpers';

const getColor = (theme: Theme, header: boolean, selected: boolean) => {
  if (selected) {
    return header ? theme.palette.dina.avatarBackground : theme.palette.dina.onFocus;
  }
  return header ? theme.palette.dina.avatarBackground : 'transparent';
};

export const Wrapper = styled('div', transientOptions)<{ $selected?: boolean; $header?: boolean }>`
  cursor: pointer;
  height: 32px;
  padding-left: 8px;
  display: flex;
  align-items: center;
  gap: 4px;
  .deleteIcon,
  .editIcon {
    transition: width 150ms;
    width: 0px;
    overflow: hidden;
  }
  ${({ $header, theme }) =>
    $header &&
    css`
      height: 24px;
      cursor: default !important;
      &:hover {
        background-color: ${theme.palette.dina.avatarBackground} !important;
      }
    `}
  &:hover {
    background-color: ${({ $selected, theme }) =>
      $selected ? theme.palette.dina.onSelected : theme.palette.dina.hoverOverlay};
    .deleteIcon,
    .editIcon {
      width: 24px;
      :hover {
        path {
          fill: ${({ $selected, theme }) =>
            $selected ? theme.palette.dina.whiteHighEmphasis : theme.palette.dina.highEmphasis};
        }
      }
    }
  }
  background: ${({ $selected, $header, theme }) => getColor(theme, !!$header, !!$selected)};
`;

export const ListTitle = styled('div')`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Header = styled('div')`
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  border-top: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  background-color: ${({ theme }) => theme.palette.dina.hoverOverlay};
`;
