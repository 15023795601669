import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { inTimingTypes } from 'components/editor/constants/types';
import { LeftEdge, LeftEdgeWrapper } from './styled';

const { MANUAL_IN, AUTO_IN } = inTimingTypes;

const InIconView = ({ inTimingType, isGraphic, isSelected }) => {
  const isAuto = inTimingType === AUTO_IN;

  return (
    <LeftEdgeWrapper>
      <LeftEdge isAuto={isAuto} isGraphic={isGraphic} isSelected={isSelected} />
    </LeftEdgeWrapper>
  );
};

InIconView.propTypes = {
  /** Timing type for in */
  inTimingType: PropTypes.oneOf(Object.values(inTimingTypes)),
  /** Specifies if the type is 'OVERLAY_GRAPHICS' */
  isGraphic: PropTypes.bool,
};

InIconView.defaultProps = {
  inTimingType: MANUAL_IN,
  isGraphic: false,
};

export default memo(InIconView);
