import Avatar from 'components/avatar/Avatar';
import { useUsersMap } from 'store';
import { Version } from 'types';
import iconComponent, {
  formatId,
  getTimelineItemProps,
  updatingVerbByActionId,
} from 'utils/instance/history';

import {
  AvatarWrapper,
  CustomEventLabel,
  CustomEventLayout,
  CustomEventWrapper,
  EventLabel,
  EventLayout,
  EventWrapper,
  IconWrapper,
  InstanceStateWrapper,
  ItemWrapper,
  PersonName,
  StateLabel,
  TimeStampLabel,
  TimeStampWrapper,
  UpdatedByLabel,
  UpdatedByWrapper,
  UserWrapper,
} from './styled';

interface TimelineEntryProps {
  item: Version;
  index: number;
}
function TimelineEntry({ item, index }: Readonly<TimelineEntryProps>) {
  const userMap = useUsersMap();
  const { itemHeight, eventState, customEvent, assigneeId, validType } = getTimelineItemProps(item);
  const { timestamp, updatedBy, actionId, newState } = item;

  const assignee = assigneeId ? userMap[assigneeId] : null;
  const Icon = newState ? iconComponent(actionId, newState) : null;

  return (
    <ItemWrapper $itemHeight={itemHeight}>
      {eventState && (
        <EventWrapper>
          <EventLayout $index={index}>
            <EventLabel>{eventState}</EventLabel>
          </EventLayout>
        </EventWrapper>
      )}

      {customEvent && (
        <CustomEventWrapper>
          <CustomEventLayout>
            <CustomEventLabel>{customEvent}</CustomEventLabel>
          </CustomEventLayout>
        </CustomEventWrapper>
      )}

      {timestamp && (
        <TimeStampWrapper>
          <TimeStampLabel>{timestamp}</TimeStampLabel>
        </TimeStampWrapper>
      )}

      {newState && Icon && (
        <InstanceStateWrapper>
          <IconWrapper>
            <Icon className="skipOverride" />
          </IconWrapper>
          <StateLabel>{formatId(newState)}</StateLabel>
        </InstanceStateWrapper>
      )}

      {assignee && (
        <UserWrapper>
          <AvatarWrapper>
            <Avatar
              imageKey={assignee?.mAvatarKey}
              size={18}
              variant="user"
              title={assignee?.mTitle}
            />
          </AvatarWrapper>
          <PersonName>{assignee?.mTitle}</PersonName>
        </UserWrapper>
      )}

      {updatedBy && actionId && (
        <UpdatedByWrapper>
          <UpdatedByLabel>
            {validType} {updatingVerbByActionId[actionId]} {updatedBy}
          </UpdatedByLabel>
        </UpdatedByWrapper>
      )}
    </ItemWrapper>
  );
}

export default TimelineEntry;
