import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { capitalize } from 'lodash';

import Checkbox from 'components/checkbox';
import Scrollbar from 'components/scrollbar/scrollbar';
import useGetPlatforms from 'hooks/useGetPlatforms';
import { ConfigType } from 'types/graphqlTypes';
import { OrderFormMemberType } from 'types/memberTypes/order_form';

import { ListWrapper, StyledFormControl } from './styled';

interface Props {
  form: OrderFormMemberType;
  handleUpdate: (upd: Partial<OrderFormMemberType>) => void;
}

const localTypeLabel: Record<string, string> = {
  story: 'Story',
  rundown: 'Rundown',
  instance: 'Instance',
  block: 'Planning item',
  order: 'Task',
  pitch: 'Pitch',
};
const localTypes = ['story', 'rundown', 'block', 'order', 'pitch', 'instance'];

const isInstance = (type: string) => {
  return !localTypes.includes(type);
};

const getSelectedTypes = (form: OrderFormMemberType) =>
  form.configs.find((c) => c.key === 'types')?.values ?? [];

/**
 * Configures what an order form can be tied to, eg story vs instance vs rundown
 */
function EditVisibleInProperties({ form, handleUpdate }: Readonly<Props>) {
  const { platforms } = useGetPlatforms(new Date());
  const [selectedTypes, setSelectedTypes] = useState<string[]>(getSelectedTypes(form));
  const filterableTypes = useMemo(() => {
    const fTypes = [...localTypes];
    for (const platform of platforms) {
      if (platform.mProperties.platformKind === 'audio') {
        fTypes.push('audio');
      } else {
        fTypes.push(platform.mProperties.platform);
      }
    }
    return fTypes;
  }, [platforms]);

  const hasInstance = useMemo(() => {
    for (const type of selectedTypes) {
      if (!localTypes.includes(type)) return true;
    }
    return false;
  }, [selectedTypes]);

  const updateTypes = useCallback(
    (types: string[]) => {
      const idx = form.configs.findIndex((c) => c.key === 'types');
      if (idx >= 0) {
        const copy: ConfigType = { ...form.configs[idx], values: types };
        const newConfigs = [...form.configs.filter((c) => c.key !== 'types'), copy];
        handleUpdate({ configs: newConfigs });
      }
      setSelectedTypes(types);
    },
    [form],
  );

  const toggle = useCallback(
    (type: string, isSelected: boolean) => {
      const currentTypes = selectedTypes || [];
      const updatedTypes = isSelected
        ? currentTypes.filter((n) => n !== type)
        : [...currentTypes, type];
      updateTypes(updatedTypes);
    },
    [selectedTypes, updateTypes],
  );

  useEffect(() => {
    const types = getSelectedTypes(form);
    if (types !== selectedTypes) setSelectedTypes(types);
  }, [form.mRefId]);

  return (
    <ListWrapper>
      <Scrollbar>
        {filterableTypes.map((type: string) => {
          return (
            <div key={type} style={{ marginLeft: isInstance(type) ? '24px' : '0' }}>
              <StyledFormControl
                key={type}
                control={
                  <Checkbox
                    indeterminate={
                      type === 'instance' && !selectedTypes.includes('instance') && hasInstance
                    }
                    onClick={() =>
                      toggle(type, selectedTypes ? selectedTypes.includes(type) : false)
                    }
                    selected={
                      (isInstance(type) && selectedTypes.includes('instance')) ||
                      selectedTypes.includes(type)
                    }
                    disabled={isInstance(type) && selectedTypes.includes('instance')}
                  />
                }
                label={localTypeLabel[type] ?? capitalize(type)}
              />
            </div>
          );
        })}
      </Scrollbar>
    </ListWrapper>
  );
}

export default memo(EditVisibleInProperties);
