import gql from 'graphql-tag';

/**
 * Chain muation to update (add and remove) resources (people, team, department)
 *
 * @param {Object[]} newMembers members to be added
 * @param {Object[]} removedMembers members to be added
 */

const UPDATE_MEMBERS = gql`
  mutation updateMembers(
    $newMembers: CreateMembersInput
    $removedMembers: BacthDeleteMemberRelationInput
  ) {
    addMembers: createMembers(input: $newMembers) {
      mId
      mRefId
      mType
    }

    removeMembersRelation: batchDeleteMemberRelation(input: $removedMembers) {
      mId
      mRefId
    }
  }
`;

export default UPDATE_MEMBERS;
