import { makeStyles } from '@material-ui/styles';
import pitchBG from 'assets/images/pitchBackground.svg';

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: 0,
    backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel2,
    backgroundImage: ({ isPitch }) => isPitch && `url(${pitchBG})`,
    position: 'relative',
    left: 0,
    '&:hover': {
      backgroundColor: theme.palette.dina.storyCardHover,
    },
    '&$selected, &$selected:hover': {
      backgroundColor: theme.palette.dina.onSelected,
    },
  },
  image: {
    width: '48px',
    height: '48px',
    margin: '12px',
  },
  img: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    borderRadius: '4px',
  },
  focusDiv: {
    transform: 'translate3d(0, 0, 0)',
    boxShadow: ({ hovered }) => hovered && `inset 0 0 0 1px ${theme.palette.dina.statusWarning}`,
    border: ({ hovered }) =>
      hovered ? `1px solid ${theme.palette.dina.statusWarning}` : '1px solid transparent',
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
  },
  headline: {
    ...theme.typography.dina.listItemLabel,
    color: theme.palette.dina.highEmphasis,
    display: '-webkit-box',
    lineClamp: 1,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    width: '183px',
    transition: 'all 0.2 linear',
  },
  headlineTruncated: {
    width: '170px',
  },
  dateline: {
    ...theme.typography.dina.caption,
    color: theme.palette.dina.mediumEmphasis,
  },
  selected: {},
  popoverItem: {
    opacity: '0.8',
    boxShadow: `inset 0 0 0 1px ${theme.palette.dina.onFocus}`,
    backgroundColor: theme.palette.dina.statusOnFocusedDiscreetFill,
  },
  secondaryActionRoot: {
    right: 0,
    height: '100%',
  },
  secondaryGrid: {
    cursor: 'pointer',
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    marginRight: '16px',
  },
  subMenu: {
    position: 'absolute',
    right: 0,
    zIndex: 10,
  },
  iconButton: {
    padding: '8px',
  },
  bookmarkIconButton: {
    padding: '8px',
    position: 'absolute',
    transition: 'all 0.5 ease-out',
    right: '34px',
  },
  bookmarkIconButtonHover: {
    right: '56px',
  },
  calendarContainer: {
    borderRadius: '8px',
    backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel2,
    boxShadow: `0px 5px 5px -3px rgba(0,0,0,0.2),
      0px 8px 10px 1px rgba(0,0,0,0.14),
      0px 3px 14px 2px rgba(0,0,0,0.12)`,
  },
}));

export default useStyles;
