import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { Button, Select as MaterialSelect, InputBase, MenuItem } from '@material-ui/core';
import { ReactComponent as Check } from 'assets/icons/systemicons/check.svg';
import Divider from 'components/divider';
import stopAllPropagation from 'components/editor/utils/stopAllPropagation';
import useStyles from './lowerthirdDropdown-styles';

const renderSelectIcon = () => null;

const LowerthirdDropdown = ({
  isOpen,
  items,
  onChange,
  onClose,
  selectedValue,
  showIconInput,
  width,
  ...rest
}) => {
  const classes = useStyles({ width });
  const handleChange = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      onChange(event.target.value);
    },
    [onChange],
  );

  const renderValue = useCallback(() => {
    const selectedItem = items.find(({ value }) => value === selectedValue);
    if (!selectedItem) return <div />;
    return (
      <MenuItem
        disableGutters
        classes={{
          root: classes.inputMenuItem,
        }}
      >
        <Button
          classes={{ root: classes.lowerthirdButton, label: classes.lowerthirdButtonLabel }}
          color="default"
        >
          {selectedItem.value}
        </Button>
      </MenuItem>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  const stopPropagationWhenOpen = useCallback(
    (event) => {
      if (isOpen) stopAllPropagation(event);
    },
    [isOpen],
  );

  return (
    <div role="presentation" className={classes.selectContainer} onClick={stopPropagationWhenOpen}>
      <MaterialSelect
        value={selectedValue || ''}
        onChange={handleChange}
        classes={{
          select: classes.select,
        }}
        IconComponent={renderSelectIcon}
        renderValue={renderValue}
        input={<InputBase id="input" />}
        inputProps={{
          className: classes.input,
        }}
        MenuProps={{
          classes: {
            paper: classes.menu,
            list: classes.list,
          },
        }}
        open={isOpen}
        {...{ onClose }}
        variant="standard"
      >
        {items && items.length > 0 ? (
          items.map(({ name, value }, index) => (
            <MenuItem
              disableGutters
              value={value}
              key={value}
              classes={{
                root: classes.menuItem,
                selected: classes.menuItemSelected,
              }}
              data-testid={`input-${value}`}
            >
              <div className={classes.optionTitle}>{name}</div>
              <div className={classes.optionLetter}>
                <span className={classes.optionLetterValue}>{value}</span>
                <span className={classes.check}>{value === selectedValue && <Check />}</span>
              </div>

              {index === 0 && <Divider className={classes.divider} />}
            </MenuItem>
          ))
        ) : (
          <MenuItem
            value=""
            classes={{
              root: classes.menuItem,
              selected: classes.menuItemSelected,
            }}
          >
            No variant available
          </MenuItem>
        )}
      </MaterialSelect>
    </div>
  );
};

LowerthirdDropdown.propTypes = {
  /** Specifies the open state for Material UI select */
  isOpen: PropTypes.bool,
  /** Items that should be rendered as select options. */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      title: PropTypes.string,
    }),
  ),
  /** Callback to be invoked when an option is selected.
   * Gets the currently selected value as argument.
   */
  onChange: PropTypes.func,
  /** Callback to be invoked for Material UI select onClose event */
  onClose: PropTypes.func,
  /** Currently selected option's value. */
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** usage of the select input: default or gridView */
  usage: PropTypes.string,
};

LowerthirdDropdown.defaultProps = {
  isOpen: false,
  items: [],
  onChange: () => {},
  onClose: () => {},
  selectedValue: '',
  usage: 'default',
};

export default memo(LowerthirdDropdown);
