import { memo, useCallback, useEffect } from 'react';
import { FetchPolicy } from '@apollo/client';

import { Button } from 'components/buttons';
import Text from 'components/text/Text';
import Kanban from 'features/kanban';
import useGetKanban from 'features/kanban/api/useGetKanban';
import useStoreKanbanUpdates from 'features/kanban/api/useUpdateKanban';
import { useKanbanMolecule } from 'features/kanban/store/kanban';
import Scrollbar from 'screens/main/components/scrollbar';
import { useUpdatedKanbanBoardForInstanceFilter } from 'store/story';
import { MemberType } from 'types/graphqlTypes';

import { MasterFirstRow, MasterHeader, MasterSecondRow } from './styled';

interface Props {
  mId: string;
  members: MemberType[];
  customRenderItem?: () => void;
  createMasterRundown: () => void;
  toggleMasterRundown: () => void;
}

interface GetKanbanProps {
  getKanban: ({
    mId,
    members,
    fetchPolicy,
  }: {
    mId: string;
    members: MemberType[];
    fetchPolicy: FetchPolicy;
  }) => void;
  loading: boolean;
}

const KanbanLoader = ({
  mId,
  members,
  customRenderItem,
  createMasterRundown,
  toggleMasterRundown,
}: Props) => {
  const { getKanban, loading }: GetKanbanProps = useGetKanban();

  const { useCreateKanbanLane, kanbanBoardUpdatedListener } = useKanbanMolecule();
  const createKanbanLane = useCreateKanbanLane();
  const [useUpdateListener] = kanbanBoardUpdatedListener();
  const { updateKanbanBoard } = useStoreKanbanUpdates();

  const [, setUpdatedKanbanBoardForInstanceFilter] = useUpdatedKanbanBoardForInstanceFilter();

  const onCreateKanbanLane = useCallback(() => {
    createKanbanLane({ mId });
  }, [mId, createKanbanLane]);

  useUpdateListener(
    useCallback(
      (newVal, prevVal) => {
        if (JSON.stringify(newVal) !== JSON.stringify(prevVal)) {
          setUpdatedKanbanBoardForInstanceFilter(newVal);
          void updateKanbanBoard({
            kanbanBoard: newVal,
          });
        }
      },
      [mId, updateKanbanBoard, setUpdatedKanbanBoardForInstanceFilter],
    ),
  );

  useEffect(() => {
    if (getKanban) getKanban({ mId, members, fetchPolicy: 'network-only' });
  }, [mId, JSON.stringify(members)]);

  return (
    <>
      <MasterHeader>
        <MasterFirstRow>
          <Text variant="h7" color="blackHighEmphasis">
            Master Rundowns
          </Text>
          <Button
            variant="outlined"
            usage="outlined"
            height={32}
            width={106}
            dark
            onClick={toggleMasterRundown}
          >
            Exit Masters
          </Button>
        </MasterFirstRow>
        <MasterSecondRow>
          <Button
            variant="contained"
            usage="cta"
            height={32}
            width={102}
            onClick={onCreateKanbanLane}
          >
            New Group
          </Button>
          <Button variant="contained" usage="significant" height={32} onClick={createMasterRundown}>
            New Master Rundown
          </Button>
        </MasterSecondRow>
      </MasterHeader>
      {/*
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
         @ts-ignore */}
      <Scrollbar>
        <Kanban
          isHorizontal={false}
          kanbanType="rundown"
          loading={loading}
          customRenderItem={customRenderItem}
        />
      </Scrollbar>
    </>
  );
};

export default memo(KanbanLoader);
