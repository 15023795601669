import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { omit } from 'lodash';

import useToast from 'components/toast/useToast';
import {
  CreateMdfInput,
  DefaultValueWrapper,
  LayoutSettings,
  LayoutSettingsEntity,
  Mdf,
  MdfField,
  MdfFieldEntity,
  MdfType,
  Views,
  ViewsEntity,
  ViewTypes,
} from 'types/graphqlTypes';
import useLogger from 'utils/useLogger';

import { STANDARD_MDF_KEYS } from '../commonKeys';

import { writeMdfToCache } from './handleMdfCache';

const CREATE_MDF = gql`
  mutation CreateMdf($input: CreateMdfInput) {
    createMdf(input: $input) {
      ${STANDARD_MDF_KEYS}
    }
  }
`;

interface CreateMdf {
  createMdf: MdfType;
}

const getErrorMessage = <E, I>(err: E, input: I): string =>
  `Could not create mdf: ${err instanceof Error ? err.message : ''} - input: ${JSON.stringify(
    input,
  )}`;

export const toFieldMdfDto = (field: MdfFieldEntity): MdfField => {
  try {
    return {
      ...field,
      defaultValue: JSON.parse(field.defaultValue ?? '{"value": ""}') as DefaultValueWrapper,
      constraint: JSON.parse(field.constraint ?? '{}') as Record<string, unknown>,
      filters: JSON.parse(field.filters ?? '{}') as Record<string, unknown>,
    };
  } catch (err) {
    return {
      ...field,
      defaultValue: { value: null },
      constraint: {},
      filters: undefined,
    };
  }
};

const toLayoutSettings = (settings: LayoutSettingsEntity): LayoutSettings => {
  try {
    return {
      ...settings,
      colors: JSON.parse(settings.colors ?? '{}') as Record<string, string>,
    };
  } catch {
    return omit(settings, 'colors');
  }
};

const toViews = (views: ViewsEntity): Views => {
  const keys = Object.keys(views) as (ViewTypes | '__typename')[];
  const emptyCopy: Record<string, LayoutSettings[]> = {};
  for (const key of keys) {
    if (key === '__typename') continue;
    emptyCopy[key] = [...(views[key] ?? []).map(toLayoutSettings)];
  }
  return emptyCopy as Views;
};

export const toMdfDto = (mdf: MdfType): Mdf => {
  const read = JSON.parse(mdf.permissions.read) as Record<string, string[]>;
  const write = JSON.parse(mdf.permissions.write) as Record<string, string[]>;

  return {
    ...mdf,
    views: toViews(mdf.views),
    permissions: {
      read,
      write,
    },
    fields: mdf.fields.map(toFieldMdfDto),
  };
};

export const useCreateMdf = () => {
  const [createMutation] = useMutation<CreateMdf>(CREATE_MDF);
  const logger = useLogger('CreateMdf');
  const { errorToast } = useToast();
  const createMdf = useCallback(
    async (input: CreateMdfInput) => {
      try {
        const result = await createMutation({
          variables: {
            input,
          },
          update: (proxy, mutationResult) => {
            const newMdf = mutationResult.data?.createMdf;
            if (newMdf) {
              writeMdfToCache(proxy, newMdf);
            }
          },
        });
        return toMdfDto(result?.data?.createMdf as MdfType);
      } catch (err) {
        if (err instanceof Error) {
          errorToast(err, err.message);
          logger.log(getErrorMessage(err, input));
        }
      }
    },
    [createMutation],
  );
  return { createMdf };
};
