import { memo, useCallback } from 'react';

import {
  MultiSelect,
  MultiSelectInput,
  MultiSelectList,
  type Option,
} from 'components/combobox/MultiSelect';
import { AssetType } from 'types/widget';

import { FilterCommonTypes } from './types';

interface MediaTopicsFilterProps extends FilterCommonTypes {
  assets?: AssetType[];
}

// The options for the asset type filter
const OPTIONS = [
  { value: 'audio', label: 'Audio' },
  { value: 'image', label: 'Image' },
  { value: 'video', label: 'Video' },
  { value: 'text', label: 'Text' },
];

// Get selected options from AssetType array
const getSelectedOptions = (assetTypes: AssetType[]) => {
  const selectedAssets = [];

  for (const type of assetTypes) {
    const asset = OPTIONS.find((t) => t.value === type.type);
    if (asset) selectedAssets.push(asset);
  }

  return selectedAssets;
};

// Map options to AssetType array
const getAssetTypesFromOptions = (options: Option[]) =>
  options.map((option) => ({ type: option.value }));

/** Filter on asset type */
function AssetsFilter({ assets = [], onChange }: Readonly<MediaTopicsFilterProps>) {
  const handleChange = useCallback(
    (options: Option[]) => {
      onChange({
        name: 'assets',
        value: getAssetTypesFromOptions(options),
      });
    },
    [onChange],
  );

  const placeholder =
    assets.length > 0
      ? `${assets.length} asset type${assets.length > 1 ? 's' : ''} selected`
      : 'Showing all asset types';

  return (
    <MultiSelect value={getSelectedOptions(assets)} onChange={handleChange} options={OPTIONS}>
      <MultiSelectInput placeholder={placeholder} />
      <MultiSelectList />
    </MultiSelect>
  );
}

export default memo(AssetsFilter);
