import { MMetaDataFieldInput } from 'types/graphqlTypes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ExtractObjectKeyValue = (source: MMetaDataFieldInput[], objKeys: string | string[]) => any;

export const extractObjectKeyValue: ExtractObjectKeyValue = (source, objKeys) => {
  if (!source || !Array.isArray(source)) return null;

  if (!Array.isArray(objKeys)) return source.find(({ key }) => key.includes(objKeys))?.value;

  return source.reduce<{ [key: string]: MMetaDataFieldInput['value'] }>((accumulator, current) => {
    if (objKeys.includes(current.key)) {
      accumulator[current.key] = current.value;
    }
    return accumulator;
  }, {});
};

export default extractObjectKeyValue;
