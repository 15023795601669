/**
 * Returns the value of metadata entry with given key.
 * @param {*} metadata - Array of { key, value }
 * @param {*} key - key to search for
 * @param {*} defaultValue - optional default value, default undefined
 * @returns Metadata value, defaultValue if not found.
 */
const find = (metadata, key, defaultValue = undefined) => {
  if (!Array.isArray(metadata)) return defaultValue;
  const hit = metadata.find((md) => md.key === key);
  return hit ? hit.value : defaultValue;
};

/**
 * Returns merged metadata from two metadata arrays.
 * The second metadata array will take precedence in case of
 * any identical keys
 * @param {*} md1 - Array of { key, value }
 * @param {*} md2 - Array of { key, value }
 * @returns Merged array of { key, value }
 */
const merge = (md1, md2) => {
  if (!Array.isArray(md2)) return Array.isArray(md1) ? md1 : [];
  if (!Array.isArray(md1)) return md2;

  return Array.from(
    md2
      .reduce(
        (map, md) => map.set(md.key, md),
        md1.reduce((map, md) => map.set(md.key, md), new Map()),
      )
      .values(),
  );
};

/**
 * Returns filtered metadata
 * @param {*} metadata - Array of { key, value } to be filtered
 * @param {*} param - Object { includeKeys, excludeKeys } where
 *                      - includeKeys (string[]) - keys to include
 *                      - excludeKeys (string[]) - keys to exclude
 *                      - ignoreNull (boolean) - ignores null values if true
 * @returns filtered metadata as Array of { key, value }
 */
const filter = (metadata, { includeKeys, excludeKeys, ignoreNull }) => {
  if (!Array.isArray(metadata)) return [];
  const include = Array.isArray(includeKeys) ? new Set(includeKeys) : undefined;
  const exclude = Array.isArray(excludeKeys) ? new Set(excludeKeys) : undefined;
  return metadata.filter((md) => {
    if (ignoreNull && md.value == null) return false;
    if (include) {
      if (exclude && exclude.has(md.key)) return false;
      return include.has(md.key);
    }
    return exclude ? !exclude.has(md.key) : true;
  });
};

export { filter, find, merge };
