import { Editor, Element } from 'slate';

import insertParagraph from 'components/editor/components/paragraph/utils/insertParagraph';
import voidTypes from 'components/editor/constants/types/voidTypes';

import { EditorVariant } from '../types';

import matchRestriction from './matchRestriction';

const voidTypeValues = Object.values(voidTypes);

/**
 * Handles onKeyDown event on void elements
 *
 * @returns SlateJS editor instance
 */
const onVoidKeyDown = (
  editor: Editor,
  event: React.KeyboardEvent<HTMLDivElement>,
  variant: EditorVariant,
  isAllowed: boolean,
  isCmsBlock: boolean,
) => {
  const [match] = Editor.nodes(editor, {
    match: (node) => Element.isElement(node) && voidTypeValues.includes(node.type),
  });

  if (match) {
    const { key } = event;
    const isEnter = key === 'Enter';
    const isBackspace = key === 'Backspace';
    const shouldPrevent = !isCmsBlock && isAllowed && matchRestriction(variant);

    if (isBackspace && shouldPrevent) event.preventDefault();

    if (isEnter && !shouldPrevent) {
      event.preventDefault();
      insertParagraph(editor);
    }
  }

  return editor;
};

export default onVoidKeyDown;
