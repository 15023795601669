const nodeTypes = {
  SVG: 'svg',
  DIV: 'DIV',
};

const className = 'opacity-up';

/**
 * Hook that returns apply and remove operator to update
 * className given an element
 */
const useUpdateElementStyle = () => {
  const add = (element) => {
    if (element.nodeName === nodeTypes.SVG) element.firstChild.classList.add(className);
    if (element.nodeName === nodeTypes.DIV) element.lastChild.firstChild.classList.add(className);
  };

  const remove = (element) => {
    if (element.nodeName === nodeTypes.SVG) element.firstChild.classList.remove(className);
    if (element.nodeName === nodeTypes.DIV)
      element.lastChild.firstChild.classList.remove(className);
  };

  return [add, remove];
};

export default useUpdateElementStyle;
