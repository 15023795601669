import { Close, Popover, PopoverAnchor, PopoverContent, PopoverTrigger } from 'lib/popover';

const PopoverNamespace = Object.assign(Popover, {
  Content: PopoverContent,
  Trigger: PopoverTrigger,
  Anchor: PopoverAnchor,
  Close: Close,
});

export default PopoverNamespace;
