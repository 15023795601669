import { useMutation } from '@apollo/client';
import CREATE_FOLDER from 'operations/mutations/createFolder';
import GET_FOLDERS from 'operations/queries/getFolders';
import useLogger from 'utils/useLogger';

const useCreateFolder = () => {
  const [createFolderMutation] = useMutation(CREATE_FOLDER);
  const logger = useLogger('use create folder');

  const create = async (mTitle, mParentId, mProperties) => {
    const input = { mTitle };
    if (mParentId) input.mParentId = mParentId;
    if (mProperties) input.mProperties = mProperties;
    if (!mProperties?.pinned) input.mProperties.pinned = false;
    await createFolderMutation({
      variables: { input },
      update: (proxy, mutationResult) => {
        const { createFolder } = mutationResult.data;
        try {
          const folderList = proxy.readQuery({
            query: GET_FOLDERS,
          });

          const folders = [...folderList.getFolders];

          if (!folderList.getFolders.find((folder) => folder.mRefId === createFolder.mRefId))
            folders.push(createFolder);

          proxy.writeQuery({
            query: GET_FOLDERS,
            data: {
              getFolders: folders,
            },
          });
        } catch (err) {
          logger.error(err);
        }
      },
    });
  };

  return [create];
};

export default useCreateFolder;
