import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#89B6F9',
    borderRadius: 4,
    marginRight: 4,
    cursor: 'pointer',

    '& svg': { height: 16 },
    '& path': {
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      fill: '#516379',
      fillOpacity: 1,
    },
  },
}));

export default useStyles;
