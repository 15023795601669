import { memo, useCallback, useEffect, useMemo } from 'react';

import Divider from 'components/divider';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import Scrollbar from 'components/scrollbar';
import { Version } from 'types';
import { AuditActionEnum } from 'types/graphqlTypes';

import { useVersionHistoryMolecule } from '../../../atoms';

import Timeline from './timeline';

import {
  FormControlLabel,
  Header,
  MenuContainer,
  NoAuditsText,
  StylesSwitch,
  TimeLineWrapper,
  Wrapper,
} from './styled';

interface LeftMenuProps {
  versions: Version[];
  loading: boolean;
}
function LeftMenu({ versions, loading }: Readonly<LeftMenuProps>) {
  const { useShowFullTimeline, useCurrentAudit } = useVersionHistoryMolecule();
  const [, setCurrentAudit] = useCurrentAudit();
  const [showFullTimeline, setShowFullTimeline] = useShowFullTimeline();

  const filteredVersions = useMemo(() => {
    if (showFullTimeline) return versions;

    return versions.filter(({ actionId }) => actionId === AuditActionEnum.Version);
  }, [versions, showFullTimeline]);

  useEffect(() => {
    const [firstAuditItem] = filteredVersions;
    if (firstAuditItem)
      setCurrentAudit({
        ...firstAuditItem,
        count: 0,
      });
  }, [filteredVersions]);

  const handleToggle = useCallback(() => {
    setShowFullTimeline((prev) => !prev);
  }, []);

  const renderAudits = useMemo(() => {
    if (loading) return <LoadingIndicator />;

    if (!filteredVersions?.length) return <NoAuditsText>No version history available</NoAuditsText>;

    return (
      <TimeLineWrapper>
        {filteredVersions?.map((item, index) => (
          <Timeline
            key={item.eventId}
            item={item}
            count={index}
            versionCount={filteredVersions?.length}
          />
        ))}
      </TimeLineWrapper>
    );
  }, [filteredVersions, loading]);

  return (
    <Wrapper>
      <Header>
        <FormControlLabel
          control={<StylesSwitch checked={showFullTimeline} onChange={handleToggle} />}
          label="Show full timeline"
          labelPlacement="end"
        />
      </Header>
      <Divider />
      <MenuContainer>
        <Scrollbar>{renderAudits}</Scrollbar>
      </MenuContainer>
    </Wrapper>
  );
}

export default memo(LeftMenu);
