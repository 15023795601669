import { TableRowWrapperProps } from 'features/grids/common/types';
import { ParsedMemberType } from 'types';

import { Row } from './TableDndRow';

/** TODO: Memoize like its done in /common */
export function MemoTableRowWrapper({
  rows,
  staticRowIndex,
  isSkeletonRow,
  ...rest
}: Readonly<TableRowWrapperProps<ParsedMemberType>>) {
  const row = !isSkeletonRow ? rows[staticRowIndex] : rows[rows.length - 1];

  return <Row row={row} staticRowIndex={staticRowIndex} isSkeletonRow={isSkeletonRow} {...rest} />;
}
