import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { AvatarFallback, AvatarImage, AvatarRoot } from 'lib/Avatar';

export const DEFAULT_SIZE = 32;

const SKIP_PROPS = {
  shouldForwardProp: (propName: string) =>
    !['size', 'borderRadius', 'borderWidth', 'borderColor', 'severity', 'title'].includes(propName),
};

export interface RootProps {
  size?: number;
  borderRadius?: number;
  borderWidth?: number;
  borderColor?: string;
}

export const Root = styled(AvatarRoot, SKIP_PROPS)<RootProps>`
  display: flex;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'inherit')};
  ${({ size = DEFAULT_SIZE, borderRadius }) => css`
    border-radius: ${borderRadius ? `${borderRadius}px` : '50%'};
    height: ${size}px;
    min-width: ${size}px;
    max-width: ${size}px;
  `};
  ${({ borderWidth = 1.5, borderColor, theme }) => css`
    outline: ${borderWidth}px solid ${borderColor ?? theme.palette.dina.avatarBorder};
    outline-offset: ${-borderWidth}px;
  `};
`;

export const Image = styled(AvatarImage)`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: inherit;
`;

export const Fallback = styled(AvatarFallback)`
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: inherit;
  background-color: ${({ theme }) => theme.palette.dina.avatarBackground};
`;
