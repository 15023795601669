/* eslint-disable sort-imports */
import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';

import { CreateMemberInput, MemberType } from 'types/graphqlTypes';
import useLogger from 'utils/useLogger';

import { getErrorMessage, STANDARD_ORDER_FORM_KEYS } from '../commonKeys';

import { writeOrderFormToCache } from './handleOrderFormCache';
import { normalizeOrderForm } from './useGetOrderForms';

const CREATE_MDF = gql`
  mutation CreateOrderForm($input: CreateMemberInput) {
    createMember(input: $input) {
      ${STANDARD_ORDER_FORM_KEYS}
    }
  }
`;

interface CreateOrderForm {
  createMember: MemberType;
}

export const useCreateOrderForm = () => {
  const [createMutation] = useMutation<CreateOrderForm>(CREATE_MDF);
  const logger = useLogger('CreateOrderForm');
  const createOrderForm = useCallback(
    async (input: CreateMemberInput) => {
      try {
        const result = await createMutation({
          variables: {
            input,
          },
          update: (proxy, mutationResult) => {
            const newForm = mutationResult.data?.createMember;
            if (newForm) {
              writeOrderFormToCache(proxy, newForm);
            }
          },
        });
        if (result?.data?.createMember) return normalizeOrderForm(result?.data?.createMember);
      } catch (err) {
        logger.log(getErrorMessage(err, input));
      }
    },
    [createMutation],
  );
  return { createOrderForm };
};
