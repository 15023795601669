import { useCallback } from 'react';
import { ApolloCache, gql, useMutation } from '@apollo/client';

import GET_STUDIOS from 'operations/queries/getStudios';
import { MemberType } from 'types/graphqlTypes';
import useLogger from 'utils/useLogger';

const DELETE_STUDIO = gql`
  mutation DeleteStudio($input: DeleteSingleMemberInput) {
    deleteSingleMember(input: $input) {
      mId
      mRefId
    }
  }
`;

interface GetStudiosType {
  getMemberFromId: MemberType[];
}
export const updateStudiosCache = (
  mId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cache: ApolloCache<any>,
  removedStudio: MemberType,
  log: (message: unknown) => void,
) => {
  try {
    const getStudiosVariables = {
      input: {
        mId,
      },
    };
    const { getMemberFromId: currentStudios = [] } =
      cache.readQuery<GetStudiosType>({
        query: GET_STUDIOS,
        variables: getStudiosVariables,
      }) || {};

    if (
      currentStudios.length < 1 &&
      !currentStudios.find((studio) => studio.mRefId === removedStudio.mRefId)
    )
      return;

    cache.writeQuery<GetStudiosType>({
      query: GET_STUDIOS,
      variables: getStudiosVariables,
      data: {
        getMemberFromId: currentStudios.filter((studio) => studio.mRefId !== removedStudio.mRefId),
      },
    });
  } catch (err: unknown) {
    log(err);
  }
};

interface DeleteStudioType {
  deleteSingleMember: MemberType;
}

const useDeleteStudio = () => {
  const logger = useLogger('useDeleteStudio');
  const [deleteStudioMutation] = useMutation<DeleteStudioType>(DELETE_STUDIO);

  const deleteStudio = useCallback(async (mId: string, mRefId: string) => {
    const variables = {
      input: {
        mId,
        mRefId,
      },
    };
    return deleteStudioMutation({
      variables,
      update: (cache, result) => {
        const { deleteSingleMember: removedStudio } = result?.data || {};
        if (!removedStudio) return;
        updateStudiosCache(mId, cache, removedStudio, logger.log);
      },
    });
  }, []);

  return [deleteStudio] as const;
};

export default useDeleteStudio;
