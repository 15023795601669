import { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import AddMemberDropdown from 'components/addMember';
import { normalizeFilter } from 'components/mdfEditor/fields/utils';
import { MdfField } from 'types/graphqlTypes';
import { AssignedMember } from 'types/members';

interface Props {
  selectCallback: (m: AssignedMember) => void;
}

type WithFilter = Props & Pick<MdfField, 'filter'>;

const StyledDropdown = styled(AddMemberDropdown)`
  .MuiAutocomplete-root {
    height: 32px !important;
  }
`;

const Wrapper = styled('div')`
  min-width: 250px;
`;

function SelectAssignee({ selectCallback, filter }: WithFilter) {
  const [selectedMembers, setSelectedMembers] = useState<AssignedMember[]>([]);

  const normalizedFilter = normalizeFilter(filter);
  const isContact = normalizedFilter?.includes('contact');

  useEffect(() => {
    selectCallback(selectedMembers[0]);
  }, [selectCallback, selectedMembers]);

  return (
    <Wrapper>
      <StyledDropdown
        variants={normalizedFilter}
        variant="all"
        singleChoice
        autoFocus
        showChips
        selectOnFocus
        disableClearable={false}
        selectedMembers={selectedMembers}
        setSelectedMembers={setSelectedMembers}
        placeholderText="Find assignee"
        noOptionsText="Could not find assignee"
        injectedMembers={undefined}
        doCallApi={isContact}
      />
    </Wrapper>
  );
}

export default SelectAssignee;
