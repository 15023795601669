import { Editor } from 'slate';

import getMark, { MarkType } from './getMark';

/**
 * Determines if specified mark type is active or not
 *
 * @param editor SlateJS editor instance
 * @param type Type for the mark to check for
 * @returns true/false, whether the mark is active or not
 */

const isMarkActive = (editor: Editor, type: string) => Boolean(getMark(editor, type as MarkType));

export default isMarkActive;
