import { css } from '@emotion/react';
import styled from '@emotion/styled';
import transientOptions from 'theme/helpers';

export const RenderItem = styled('div')`
  list-style: none;
  transform: ${({ $transform }) => $transform};
  transition: ${({ $transition }) => $transition};
  flex: 1 0 0;
`;

export const MemberRenderItem = styled('div')`
  padding: 4px 8px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  :hover {
    background: ${({ theme }) => theme.palette.dina.hoverOverlay};
  }
`;

export const ItemDragWrapper = styled('div')`
  background: ${({ theme }) => theme.palette.dina.onFocus};
`;

export const LaneWrapper = styled('div', transientOptions)`
  border-radius: 4px;
  overflow: hidden;
  ${({ $dense }) =>
    !$dense &&
    css`
      margin: 4px;
    `};
  cursor: pointer;
`;
