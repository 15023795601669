import styled from '@emotion/styled';

export const IframeWrapper = styled('div')`
  width: 100%;
  height: 100%;
  border-color: ${({ theme }) => theme.palette.dina.surfaceCard};
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const Iframe = styled('iframe')`
  border: none;
  height: 100%;
  width: 100%;
  padding-bottom: 0;
  padding-top: 0;
`;
