import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import useHover from 'hooks/useHover';
import stopAllPropagation from 'components/editor/utils/stopAllPropagation';

import {
  StyledTextArea,
  StyledDragHandle,
  StyledFunctionButtonsWrapper,
  StyledAddIcon,
  StyledColumnWrapper,
  StyledRemoveIcon,
  StyledHideIcon,
  StyledShowIcon,
} from './styled';

function RundownColumn({
  field,
  fields,
  onColumnChange,
  onRemoveColumn,
  setSelectedColumn,
  onOpenColumnsMenu,
}) {
  const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef } =
    useSortable({
      id: field.id,
    });
  const [input, setInput] = useState(field.label);
  const [hoverRef, isHovered] = useHover();
  const [columnWidth, setColumnWidth] = useState(Number(field.width));
  const style = {
    transform: CSS.Transform.toString({ ...transform, y: 0, scaleX: 1, scaleY: 1 }),
    transition,
  };
  const onInputChange = (e) => {
    const newText = e.target.value.replace(/\n/g, '');
    setInput(newText);
  };

  const normalizeWidth = (width) => Math.round(width / 5) * 5;

  const handleMouseUp = (e) => {
    const width = normalizeWidth(e.target.clientWidth);
    if (width === columnWidth) return;
    setColumnWidth(width);
    onColumnChange(field, input, width.toString());
  };

  const handleShowColumn = (show) => {
    const width = show ? 45 : 0;
    setColumnWidth(width);
    onColumnChange(field, input, width.toString());
  };

  const onKeyUp = (e) => {
    stopAllPropagation(e);
    if (e.key === 'Enter') {
      e.target.blur();
      const newText = e.target.value.replace(/\n/g, '');
      onColumnChange(field, newText, field.width);
    }
  };

  const onBlur = (e) => {
    stopAllPropagation(e);
    setSelectedColumn(null);
    if (field.label !== e.target.value) {
      onColumnChange(field, e.target.value, field.width);
    }
  };

  const getFieldDef = () => {
    const { id } = field;
    const fieldDef = fields.find((f) => f.id === id);
    const ret = { ...fieldDef };
    delete ret.__typename;
    delete ret.parameters;
    delete ret.options;

    return ret;
  };

  const getTitle = () => {
    let ret = '';
    const fieldDef = getFieldDef();
    if (columnWidth === 0) ret += 'HIDDEN COLUMN\n';
    ret += `Column Label: ${input?.toUpperCase()}\n`;
    Object.keys(fieldDef).forEach((key) => {
      const value = fieldDef[key] ? fieldDef[key] : 'N/A';
      ret += `${key[0].toUpperCase()}${key.slice(1).toLowerCase()}: ${value}\n`;
    });
    ret += `Width: ${columnWidth}`;
    return ret;
  };

  return (
    <div ref={hoverRef}>
      <StyledColumnWrapper
        key={field.id}
        ref={setNodeRef}
        style={style}
        {...attributes}
        onSelectCapture={() => setSelectedColumn(field)}
      >
        {isHovered && (
          <StyledFunctionButtonsWrapper>
            <StyledDragHandle {...listeners} ref={setActivatorNodeRef} />
            <StyledAddIcon title="Add column" onClick={(e) => onOpenColumnsMenu(e, field)} />
            <StyledRemoveIcon title="Remove column" onClick={(e) => onRemoveColumn(field)} />
            {columnWidth === 0 ? (
              <StyledShowIcon title="Show column" onClick={(e) => handleShowColumn(true)} />
            ) : (
              <StyledHideIcon title="Hide column" onClick={(e) => handleShowColumn(false)} />
            )}
          </StyledFunctionButtonsWrapper>
        )}
        <StyledTextArea
          title={getTitle()}
          data-title={getTitle()}
          value={input?.toUpperCase()}
          onChange={(e) => onInputChange(e)}
          onKeyUp={(e) => onKeyUp(e)}
          onBlur={(e) => onBlur(e)}
          InputProps={{ disableUnderline: true, multiline: true, rows: 2 }}
          onMouseUp={(e) => handleMouseUp(e)}
          style={{ width: columnWidth }}
          $isHidden={columnWidth === 0}
          ref={hoverRef}
        />
      </StyledColumnWrapper>
    </div>
  );
}

RundownColumn.propTypes = {
  field: PropTypes.shape({
    type: PropTypes.oneOf(['boolean', 'select', 'text']),
    id: PropTypes.string,
    description: PropTypes.string,
    options: PropTypes.array,
  }),
};

RundownColumn.defaultProps = {
  field: null,
};

export default RundownColumn;
