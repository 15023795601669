import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ReactComponent as CloseSmall } from 'assets/icons/systemicons/close_small.svg';

export const ChipListWrapper = styled('div')`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  margin-bottom: 16px;
`;

export const ChipWrapper = styled('div')`
  ${({ theme }) => css`
    ${theme.typography.dina.captionMedium};
    border: 1px solid ${theme.palette.dina.borderHover};
    background-color: ${theme.palette.dina.chipBackground};
  `}
  display: flex;
  align-items: center;
  padding: 4px 8px;
  margin: 4px;
  border-radius: 2px;
`;

export const CloseIcon = styled(CloseSmall)`
  margin-left: 8px;
  :hover {
    path {
      fill-opacity: 1;
    }
  }
`;
