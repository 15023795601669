import { React, useCallback } from 'react';

import useGetFeedItem from 'api/feeds/useGetFeedItem';
import FeedViewerModal from 'features/feedViewer/components/modal';
import { usePortal } from 'features/portal';

function useFeedViewer() {
  const [, setPortal] = usePortal();
  const getFeedItem = useGetFeedItem();

  const closePortal = useCallback(() => {
    setPortal(null);
  }, [setPortal]);

  /** Use this when you have the complete feed item */
  const openModal = useCallback(
    (item) => {
      setPortal(<FeedViewerModal open handleClose={closePortal} feedItem={item} />);
    },
    [closePortal, setPortal],
  );

  /** Use this this when you only have the provider and mId */
  const fetchAndOpen = useCallback(
    async (provider, mId) => {
      const item = await getFeedItem(provider, mId);
      setPortal(<FeedViewerModal open handleClose={closePortal} feedItem={item} />);
    },
    [closePortal, setPortal, getFeedItem],
  );

  return [openModal, fetchAndOpen];
}

export default useFeedViewer;
