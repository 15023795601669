/* eslint-disable no-param-reassign */
import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import useFileUpload from 'hooks/useFileUpload';
import { ReactComponent as UploadIcon } from 'assets/icons/systemicons/upload.svg';
import ActionButton from 'components/editor/components/addMedia/components/button';
import {
  ChipWrapper,
  CheckIcon,
  DeleteIcon,
  ChipLabel,
  RootWrapper,
  ButtonWrapper,
  TitleWrapper,
  Title,
  Subtitle,
} from './styled';

const SubtitleChip = ({ label, onRemoveClick }) => (
  <ChipWrapper onClick={() => {}} role="presentation">
    <CheckIcon />
    <ChipLabel>{`Captions uploaded: ${label}`}</ChipLabel>
    <DeleteIcon onClick={onRemoveClick} />
  </ChipWrapper>
);

const mimeTypes = ['.srt'];

const UploadCaptions = ({ subtitles = [], uploadSubtitle, updateSubtitles }) => {
  const uploadFile = useFileUpload(mimeTypes, uploadSubtitle);

  const onRemoveClick = useCallback(
    (event, index) => {
      event.preventDefault();
      const updatedSubtitles = subtitles.filter((_, pos) => pos !== index);

      updateSubtitles(updatedSubtitles);
    },
    [subtitles, updateSubtitles],
  );

  return (
    <RootWrapper>
      <ButtonWrapper>
        <TitleWrapper>
          <Title>Add Captions</Title>
          <Subtitle>Use Subrip (.srt) files</Subtitle>
        </TitleWrapper>
        <ActionButton icon={<UploadIcon />} label="Upload Captions-file" onClick={uploadFile} />
      </ButtonWrapper>
      <div>
        {subtitles.map((sub, index) => (
          <SubtitleChip
            key={sub.mRefId}
            label={sub.title}
            onRemoveClick={(event) => onRemoveClick(event, index)}
          />
        ))}
      </div>
    </RootWrapper>
  );
};

UploadCaptions.propTypes = {
  /** list of subtitle */
  subtitles: PropTypes.arrayOf(PropTypes.object),
  /** callback func to store subtitles */
  uploadSubtitle: PropTypes.func,
  /** updateSubtitles callback */
  updateSubtitles: PropTypes.func,
};

UploadCaptions.defaultProps = {
  subtitles: [],
  uploadSubtitle: () => {},
  updateSubtitles: () => {},
};

export default memo(UploadCaptions);
