import React, { useState } from 'react';
import { Collapse } from '@material-ui/core';
import PropTypes from 'prop-types';

import { ReactComponent as ArrowDown } from 'assets/icons/systemicons/arrows/disclosurearrow_down.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/systemicons/arrows/disclosurearrow_up.svg';

import {
  CollapseButton,
  CollapseButtonLabelWrapper,
  CollapseHeaderCount,
  Header,
  ListBody,
  ListWrapper,
} from './styled';

function List({ children }) {
  return <ListWrapper>{children}</ListWrapper>;
}

List.Body = function Body({ children, horizontal = false }) {
  return <ListBody horizontal={horizontal}>{children}</ListBody>;
};

List.CollapseHeader = function CollapseHeader({ children, hide, title, count }) {
  const [isOpen, setIsOpen] = useState(true);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  if (hide) return <>{children}</>;

  return (
    <Header>
      <CollapseButton onClick={toggleCollapse}>
        <CollapseButtonLabelWrapper>
          {isOpen ? <ArrowUp /> : <ArrowDown />}
          {title}
          {count > 0 && <CollapseHeaderCount>{count}</CollapseHeaderCount>}
        </CollapseButtonLabelWrapper>
      </CollapseButton>
      <Collapse in={isOpen} timeout="auto" mountOnEnter unmountOnExit>
        {children}
      </Collapse>
    </Header>
  );
};

List.propTypes = {
  /** Children */
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
};

List.defaultProps = {};

export default List;
