import { memo } from 'react';

import { elementTypes } from 'components/editor/constants';

import BlockButton from '../blockButton';
import { ButtonGroup } from '../ButtonGroup';
import IconButton from '../iconButton';

const { GIF, IMAGE, VIDEO } = elementTypes;

interface TwitterToolbarProps {
  extraBlocks: string[] | undefined;
}

const TwitterToolbar = ({ extraBlocks }: TwitterToolbarProps) => {
  return (
    <ButtonGroup>
      <IconButton type={VIDEO} />
      <IconButton type={IMAGE} />
      <IconButton type={GIF} />
      {extraBlocks?.map((block) => (
        <BlockButton key={block} type={block} tooltipType={block} />
      ))}
    </ButtonGroup>
  );
};

export default memo(TwitterToolbar);
