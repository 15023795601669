import gql from 'graphql-tag';

export default gql`
  mutation updateForm($input: UpdateFormInput) {
    updateForm(input: $input) {
      mTitle
      mId
      mRefId
      mRefTitle
      mType
      mProperties {
        __typename
        ... on MetadataFormType {
          types
          defaultView
          defaultViews {
            defaultView
            type
            variant
          }
          fields {
            id
            name
            mostag
            description
            value
            type
            label
            function
            defaultValue
            parameters {
              id
            }
            options {
              id
              title
              value
              icon
            }
            readonly
          }
          views {
            id
            name
            type
            columndefs {
              variant
              columns {
                id
                width
                style
                readonly
                label
                name
              }
            }
          }
        }
      }
    }
  }
`;
