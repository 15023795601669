import { useState } from 'react';
import styled from '@emotion/styled';

import { ReactComponent as ArrowDown } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_down.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_right.svg';
import Text from 'components/text/Text';
import { Box, HStack } from 'layouts/box/Box';
import { Conversation } from 'types/messageHub';

import ConversationListItem, { MenuProps } from './ListItem';

const GroupHeader = styled(HStack)`
  user-select: none;
  cursor: pointer;
  height: 32px;
  width: 100%;
  padding: 0 8px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  justify-content: 'flex-start';
  gap: 4px;
  background: ${({ theme }) => theme.palette.dina.headerFooterOverlay};
  :not(:first-of-type) {
    border-top: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  }
`;

interface GroupProps extends MenuProps {
  title: string;
  conversations: Conversation[];
}

function Group({ title, conversations, menuItems, onMenuSelect }: Readonly<GroupProps>) {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <>
      <GroupHeader onClick={() => setIsCollapsed(!isCollapsed)} key={title}>
        {isCollapsed ? <ArrowRight /> : <ArrowDown />}
        <Box flex="1">
          <Text variant="listItemLabel" color="highEmphasis">
            {title}
          </Text>
        </Box>
      </GroupHeader>
      {!isCollapsed &&
        conversations.map((conversation) => (
          <ConversationListItem
            key={conversation.mId}
            conversation={conversation}
            menuItems={menuItems}
            onMenuSelect={onMenuSelect}
          />
        ))}
    </>
  );
}

export default Group;
