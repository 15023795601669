import { Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import { ActionTypesEnum } from 'components/editor/constants/types/actionTypes';
import { isCreateAsset, Update } from 'components/editor/types';
import normalizeIntoBlockData from 'components/editor/utils/normalizeIntoBlockData';
import notifyChange from 'components/editor/utils/notifyChange';
import { CustomEditor, CustomElement } from 'types';
import findAndReplace from 'utils/findAndReplace';
import normalizeAssetData, { DroppedAsset } from 'utils/normalizeAssetData';

/**
 * Adds clip media to given element
 *
 * @param editor SlateJS editor instance
 * @param element SlateJS element to be updated
 * @param payload Data payload of dropped item
 * @param update Callback to be invoked on update
 * @returns SlateJS editor instance
 */

const addMedia = async (
  editor: CustomEditor,
  element: CustomElement,
  payload: DroppedAsset,
  update: Update,
  errorToast: (err: unknown) => void = () => {},
) => {
  const { data } = element;
  const { assets: previousAssets = [], ...rest } = data ?? {};
  const assetPayload = normalizeAssetData(payload);

  try {
    if (payload.itemType === 'video' || payload.itemType === 'image') {
      const result = await update({
        type: ActionTypesEnum.CREATE_ASSET,
        payload: { document: editor.children, asset: assetPayload },
      });

      const asset = isCreateAsset(result) ? result?.data?.createAssets?.[0] : null;

      if (asset) {
        const blockData = normalizeIntoBlockData({ ...payload, ...asset });
        const updatedData = {
          ...rest,
          assets: findAndReplace(previousAssets, blockData, 'assetType'),
        };

        const path = ReactEditor.findPath(editor, element);

        Transforms.setNodes(editor, { data: updatedData }, { at: path });
        notifyChange(editor, update);
      }
      ReactEditor.focus(editor);
    }
  } catch (error) {
    errorToast(error);
  }
};

export default addMedia;
