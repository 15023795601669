/**
 * Metadata keys for metadata used in Dina as system fields.
 * I.e. should always be part of the forms fields.
 */
const SystemFields = {
  CLIP_DURATION: '05-clip-duration',
  HOST_READ_SPEED: 'hostReadSpeed',
  IS_FLOAT: '10-isFloat',
  ORDER: '11-order',
  OVERUNDER_DURATION: '09-overunder-duration',
  PLANNED_DURATION: '06-planned-duration',
  SPEAK_DURATION: '08-speak-duration',
  TOTAL_DURATION: '07-total-duration',
  WORD_COUNT: '12-word-count',
};

/**
 * Custom MetaData fields that are not part of the forms fields.
 */
const CustomFields = {
  STORY_TITLE: 'story_title',
};

/**
 * Array of metadata keys to ignore when restoring an instance from a template
 */
const IgnoredTemplateKeys = [
  SystemFields.HOST_READ_SPEED,
  SystemFields.ORDER,
  SystemFields.SPEAK_DURATION,
  SystemFields.TOTAL_DURATION,
  CustomFields.STORY_TITLE,
];

export { SystemFields, IgnoredTemplateKeys, CustomFields };
