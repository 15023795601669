import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const HtmlContent = styled('div')`
  ${({ theme }) => css`
    background-color: ${theme.palette.dina.highEmphasis};
    color: ${theme.palette.dina.blackHighEmphasis};
  `}
  white-space: normal;
  user-select: text !important;
  padding: 12px;
  border-radius: 8px;
  width: 576px;
  height: 324px;
  transform: scale(0.5);
  transform-origin: 0 0;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-color: ${({ theme }) => theme.palette.dina.dividerDark};
  }
`;

export const PreviewWrapper = styled('div')`
  ${({ theme }) => css`
    ${theme.typography.dina.captionMedium};
    border: 1px dashed ${theme.palette.dina.iconInactive};
    background-color: ${theme.palette.dina.blackHoverOverlay};
  `}
  width: 288px;
  height: 162px;
  border-radius: 8px;
  box-sizing: border-box;
  backdrop-filter: blur(15px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
`;
