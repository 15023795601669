/* eslint-disable import/no-extraneous-dependencies */
import { useMutation, useApolloClient } from '@apollo/client';
import differenceBy from 'lodash/differenceBy';

import memberTypes from 'operations/memberTypes';
import differenceByProp from 'utils/differenceByProp';
import useLogger from 'utils/useLogger';
import SHARE_STORY_WITH from 'operations/mutations/shareStoryWith';
import GET_STORY from 'operations/queries/getStory';
import useCheckUserRight from 'hooks/useCheckUserRight';

const storyMemberMap = {
  usr_str: memberTypes.USER,
  tea_str: memberTypes.TEAM,
  dep_str: memberTypes.DEPARTMENT,
  mar_str: memberTypes.MARKET,
  str_con: memberTypes.CONTACT,
  user: memberTypes.USER,
  team: memberTypes.TEAM,
  department: memberTypes.DEPARTMENT,
};

const useShareStory = () => {
  const logger = useLogger('use share story');

  const [shareStoryMutation] = useMutation(SHARE_STORY_WITH);
  const client = useApolloClient();

  const [checkUserRight] = useCheckUserRight();
  const canUpdateStory = checkUserRight('story', 'update');
  const canUpdatePitch = checkUserRight('pitch', 'update');

  const getStory = async (storyId) => {
    const result = await client.query({
      query: GET_STORY,
      variables: {
        input: {
          mId: storyId,
        },
      },
    });

    const { data } = result;
    return data && data.getStory;
  };

  const shareStory = (
    _storyId,
    _type,
    addemembersInput,
    removedMembersInput,
    updateAssigneesInput,
    _removedIdsList = [],
  ) => {
    const newMembers = {
      members: addemembersInput,
    };

    const removedMembers = {
      members: removedMembersInput,
    };

    const variables = {
      newMembers,
      removedMembers,
      updateAssigneesInput,
    };

    shareStoryMutation({
      variables,
    }).catch((err) => {
      logger.error(err);
    });
  };

  const canUpdate = (story) => {
    const { mType: storyType } = story;

    const isPitch = storyType === memberTypes.PITCH || storyType === memberTypes.RESTRICTED_PITCH;
    return isPitch ? canUpdatePitch : canUpdateStory;
  };

  const shareStoryWith = async (
    storyId,
    members,
    existingMembers,
    mType,
    messageAssign,
    isShareStory = false,
  ) => {
    if (!storyId) return;

    const story = await getStory(storyId);
    if (!story || !canUpdate(story)) return;

    const { mAssignedMembers } = story;

    let addMembersInput = [];
    let removedMembersInput = [];
    let removedIdsList = [];

    const mTypeMap = {
      [memberTypes.USER]: memberTypes.USER_STORY,
      [memberTypes.TEAM]: memberTypes.TEAM_STORY,
      [memberTypes.DEPARTMENT]: memberTypes.DEPARTMENT_STORY,
    };

    if (isShareStory && mType === null) {
      const removedMembers = differenceBy(existingMembers, members, 'mId');
      const addedMembers = differenceBy(members, existingMembers, 'mId');
      const [removedIds] = differenceByProp(existingMembers, members, 'mId');
      removedIdsList = removedIds;
      addMembersInput = addedMembers.map((member) => ({
        mId: member.mId,
        mRefId: storyId,
        mType: mTypeMap[member.mType],
        messageAssign,
      }));

      removedMembersInput = removedMembers.map((member) => ({
        mId: member.mId,
        mRefId: storyId,
        mType: mTypeMap[member.mType],
      }));
    } else if (mType === memberTypes.STORY_CONTACT) {
      const [addedIds, removedIds] = differenceByProp(existingMembers, members, 'mRefId');

      addMembersInput = addedIds.map((mRefId) => ({
        mId: storyId,
        mRefId,
        mType,
        messageAssign,
      }));

      removedMembersInput = removedIds.map((mRefId) => ({
        mId: storyId,
        mRefId,
        mType,
      }));
    } else {
      const [addedIds, removedIds] = differenceByProp(existingMembers, members, 'mId');
      removedIdsList = removedIds;
      addMembersInput = addedIds.map((mId) => ({
        mId,
        mRefId: storyId,
        mType,
        messageAssign,
      }));

      removedMembersInput = removedIds.map((mId) => ({
        mId,
        mRefId: storyId,
        mType,
      }));
    }

    const assignedMembers = [];

    if (isShareStory && mType === null) {
      members.forEach((member) => {
        assignedMembers.push({
          mId: member.mId,
          mType: member.mType,
        });
      });
    } else {
      const memberType = storyMemberMap[mType];
      // mapping members with specific mType to assigned members
      members.forEach((member) => {
        assignedMembers.push({
          mId: member.mId,
          mType: memberType,
        });
      });
      // m.mType members already exists in mAssignedMembers this filtering them out
      // and mapping rest of the members to assignedMembers
      (mAssignedMembers || [])
        .filter((m) => m.mType !== memberType)
        .forEach((m) => {
          assignedMembers.push({
            mId: m.mId,
            mType: m.mType,
          });
        });
    }

    const updateAssigneesInput = {
      mId: storyId,
      mRefId: storyId,
      mAssignedMembers: assignedMembers,
    };

    shareStory(
      storyId,
      mType,
      addMembersInput,
      removedMembersInput,
      updateAssigneesInput,
      removedIdsList,
    );
  };

  const assignMemberToStory = async (storyId, memberId, memberType) => {
    console.log('storyId', storyId, memberId, memberType);
    if (!storyId) return;

    const story = await getStory(storyId);
    if (!story || !canUpdate(story)) return;

    const { mAssignedMembers } = story;

    let memberInput = {
      mId: memberId,
      mRefId: storyId,
      mType: memberTypes.USER_STORY,
    };

    switch (memberType) {
      case memberTypes.TEAM:
        memberInput.mType = memberTypes.TEAM_STORY;
        break;
      case memberTypes.DEPARTMENT:
        memberInput.mType = memberTypes.DEPARTMENT_STORY;
        break;
      case memberTypes.CONTACT:
      case memberTypes.STORY_CONTACT:
        memberInput = {
          mId: storyId,
          mRefId: memberId,
          mType: memberTypes.STORY_CONTACT,
        };
        break;
      default:
        break;
    }

    const assignedMembers = [];

    (mAssignedMembers || []).forEach((m) => {
      assignedMembers.push({
        mId: m.mId,
        mType: m.mType,
      });
    });

    assignedMembers.push({
      mId: memberId,
      mType: storyMemberMap[memberInput.mType],
    });

    const updateAssigneesInput = {
      mId: storyId,
      mRefId: storyId,
      mAssignedMembers: assignedMembers,
    };

    shareStory(storyId, memberInput.mType, [memberInput], [], updateAssigneesInput);
  };

  return [assignMemberToStory, shareStoryWith];
};

export default useShareStory;
