import styled from '@emotion/styled';
import transientOptions from 'theme/helpers';
import { css } from '@emotion/react';
import { FormControlLabel as FormControlLabelComponent } from '@material-ui/core';

export const ContentWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 9px 16px 16px 16px;
`;

export const LabelWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

export const Label = styled('label')`
  ${({ theme }) => theme.typography.dina.overline};
`;

export const InputWrapper = styled('div', transientOptions)`
  width: ${({ $width }) => ($width ? `${$width}%` : '100%')};
  padding-top: 9.5px;
`;

export const BetaLabel = styled('label')`
  padding-left: 4px;
  position: relative;
  bottom: 0.5px;
  ${({ theme }) => css`
    ${theme.typography.dina.overline};
    color: ${theme.palette.dina.statusWarning};
  `}
`;

export const FormControlLabel = styled(FormControlLabelComponent)`
  .MuiFormControlLabel-label {
    ${({ theme }) => theme.typography.dina.listItemLabel};
  }
`;

export const SelectWrapper = styled('div')`
  display: flex;
  gap: 8px;
  width: 100%;
`;
