import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  imageDiv: {
    width: 800,
    height: 500,
    borderRadius: '4px',
    background: 'linear-gradient(180deg, rgba(1,0,5,0.7) 0%, rgba(1,0,5,0.7) 0%)',
    position: 'relative',
  },
  video: {
    borderRadius: '4px',
    padding: '5px',
    outline: 'none',
    background: 'linear-gradient(180deg, rgba(1,0,5,0.7) 0%, rgba(1,0,5,0.7) 0%)',
  },
  audio: {
    outline: 'none',
  },
  button: {
    padding: 0,
    position: 'absolute',
    top: 0,
    right: 0,
  },
  icon: {
    '&:hover': {
      '& path': {
        fillOpacity: 1,
      },
    },
  },
}));

export default useStyles;
