import React from 'react';
import PropTypes from 'prop-types';
import Select from '../select';
import GeneralLabel from '../generalLabel';
import SeverityIndicator from '../severityIndicator';
import ContainerBox from '../containerBox';

const DropdownView = ({ severity, label, usage, disableEdit, ...rest }) => (
  <ContainerBox
    Indicator={<SeverityIndicator severity={severity} />}
    Label={<GeneralLabel severity={severity} label={label} usage={usage} />}
    Field={
      <Select
        severity={severity}
        itemHeight={40}
        disable={disableEdit}
        disableEdit={disableEdit}
        {...rest}
      />
    }
    usage={usage}
  />
);

DropdownView.propTypes = {
  /** label for the composite field */
  label: PropTypes.string,
  /** color for label, indicator and input underline */
  severity: PropTypes.string,
  /** input label  */
};

DropdownView.defaultProps = {
  label: '',
  severity: 'regular',
};
export default DropdownView;
