import { useState } from 'react';

import { Button } from 'components/buttons';
import Dialog from 'components/dialogs/DialogBuilder';
import ChoiceField from 'components/mdfEditor/fields/choice/ChoiceField';
import { StyledTextField } from 'components/mdfEditor/fields/text/styled';
import Text from 'components/text/Text';
import Tooltip from 'components/tooltip';
import { HStack, VStack } from 'layouts/box/Box';
import { Alternative, AuthHeader, AuthType, FieldTypeEnum } from 'types/graphqlTypes';

import { OptionWrapper } from './styled';

interface Props {
  open: boolean;
  setOpen: (val: boolean) => void;
  alternatives: Alternative[];
  savedOptionList: string;
  doUpdate: (val: {}) => void;
}

export function EditAuthDialogue({
  open,
  setOpen,
  alternatives,
  savedOptionList,
  doUpdate,
}: Readonly<Props>) {
  const [apiKey, setApiKey] = useState<string>('');
  const [resetKey, setResetKey] = useState<boolean>(false);
  const [selectedListId, setSelectedListId] = useState<string | null>(savedOptionList);

  const saveApiKey = (key: string) => {
    setApiKey(key);
  };

  const resetApiKey = () => {
    setResetKey(true);
  };

  const confirmChanges = () => {
    const authParam: { auth: { type: AuthType; headers?: AuthHeader } } = {
      auth: {
        type: selectedListId as AuthType,
      },
    };

    if (selectedListId === 'APIKEY') {
      authParam.auth.headers = {
        apikey: btoa(apiKey),
      };
    }

    doUpdate(authParam);
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      style={{ minWidth: '600px', maxWidth: '800px' }}
    >
      <Dialog.Header>Configure Auth</Dialog.Header>
      <Dialog.Body>
        <HStack alignItems="end" gap="10px" justifyContent="space-between" margin="0 0 10px 0">
          <ChoiceField
            editorId="AuthDialog"
            fieldModel={{
              fieldId: 'optionLists',
              type: FieldTypeEnum.choice,
              defaultValue: { value: null },
              alternatives: alternatives,
            }}
            fieldSettings={null}
            defaultFieldSettings={{
              fieldId: 'optionLists',
              label: 'Choose Auth Type',
              visible: true,
              hint: '',
            }}
            value={selectedListId}
            setValue={(val) => {
              setSelectedListId(val as string | null);
            }}
            errorMessage={undefined}
            style={{ width: '240px' }}
            view="default"
          />
          {savedOptionList === 'APIKEY' && selectedListId !== 'NONE' && (
            <Button
              width={120}
              height={32}
              variant="contained"
              usage={resetKey ? 'outlined' : 'warning'}
              disabled={resetKey}
              onClick={resetApiKey}
              title={resetKey ? '' : 'Reset APIKey'}
            >
              Reset APIKEY
            </Button>
          )}
        </HStack>
        {selectedListId === 'APIKEY' && savedOptionList !== 'APIKEY' && (
          <OptionWrapper>
            <VStack>
              <Text variant="caption"> x-api-key : </Text>
            </VStack>

            <VStack>
              <Tooltip
                title={!apiKey && savedOptionList !== 'APIKEY' ? 'This field is required.' : ''}
              >
                <StyledTextField
                  name="value"
                  variant="filled"
                  value={apiKey}
                  type="password"
                  onChange={(ev) => saveApiKey(ev.target.value)}
                  required
                  error={!apiKey && savedOptionList !== 'APIKEY'}
                />
              </Tooltip>
            </VStack>
          </OptionWrapper>
        )}

        {resetKey && selectedListId !== 'NONE' && (
          <OptionWrapper>
            <VStack>
              <Text variant="caption"> x-api-key : </Text>
            </VStack>
            <VStack>
              <Tooltip title={!apiKey ? 'This field is required.' : ''}>
                <StyledTextField
                  name="value"
                  variant="filled"
                  value={apiKey}
                  type="password"
                  onChange={(ev) => saveApiKey(ev.target.value)}
                  required
                  error={!apiKey}
                />
              </Tooltip>
            </VStack>
          </OptionWrapper>
        )}
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton />
        <Dialog.ConfirmButton
          label="Confirm"
          onConfirm={confirmChanges}
          disabled={selectedListId === 'APIKEY' && !apiKey}
        />
      </Dialog.Footer>
    </Dialog>
  );
}
