import { actionTypes } from 'components/editor/constants/types';
import { Transforms } from 'slate';
import normalizeAssetData from 'utils/normalizeAssetData';
import normalizeIntoBlockData from 'components/editor/utils/normalizeIntoBlockData';
import { ReactEditor } from 'slate-react';
import notifyChange from 'components/editor/utils/notifyChange';

const findBlockType = (asset) => {
  const { mediaType, itemType } = asset;
  if (mediaType.includes('image/gif')) return 'gif';
  return itemType;
};
/**
 * Adds clip media to given element
 *
 * @param {Object} editor SlateJS editor instance
 * @param {Object} element SlateJS element to be updated
 * @param {Object} payload Data payload of dropped item
 * @param {Function} update Callback to be invoked on update
 * @returns {Object} SlateJS editor instance
 */

const addMedia = async (editor, element, payload, update) => {
  const { data } = element;
  const assetPayload = normalizeAssetData(payload);

  try {
    const result = await update({
      type: actionTypes.CREATE_ASSET,
      payload: { document: editor.children, asset: assetPayload },
    });

    const asset = result.data.createAssets[0];
    const isAssetPlaceholder = asset.mediaType.includes('placeholder');
    const type = isAssetPlaceholder ? 'placeholder' : findBlockType(asset);

    if (asset) {
      const blockData = normalizeIntoBlockData({ ...payload, ...asset });

      const updatedData = { ...data, ...blockData };

      const path = ReactEditor.findPath(editor, element);

      Transforms.setNodes(editor, { type, data: updatedData }, { at: path });
      ReactEditor.focus(editor);
      notifyChange(editor, update);
    }
  } catch (error) {
    // console.log(error)
  }
};

export default addMedia;
