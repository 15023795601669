import styled from '@emotion/styled';
import ListItem from 'components/ellipsisDropdown/listItem-view';

export const RootWrapper = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const GroupList = styled(ListItem)`
  padding-left: 8px;
`;

export const BlankDiv = styled('div')`
  ${(props) => ({ marginLeft: props.margin })}
`;

export const ArrowWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

export const CountText = styled('div')`
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
`;
