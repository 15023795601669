import { memo } from 'react';

import { Version } from 'types';

import Content from './content';
import LeftMenu from './leftMenu';

import { ContentWrapper, Divider, LeftMenuWrapper, Wrapper } from './styled';

interface DialogBodyProps {
  isMenuLoading: boolean;
  versions: Version[];
}

function DialogBody({ versions, isMenuLoading }: Readonly<DialogBodyProps>) {
  return (
    <Wrapper>
      <LeftMenuWrapper>
        <LeftMenu versions={versions} loading={isMenuLoading} />
      </LeftMenuWrapper>
      <Divider />
      <ContentWrapper>
        <Content />
      </ContentWrapper>
    </Wrapper>
  );
}

export default memo(DialogBody);
