import { DropdownList } from './components/DropdownList';
import { DropdownListContent } from './components/DropdownListContent';
import { useMultiSelect } from './hooks/useMultiSelect';
import { type MultiSelectListProps } from './types';

/** Multi-select list component. */
export const MultiSelectList = ({
  maxSelected = Number.MAX_SAFE_INTEGER,
  onMaxSelected,
}: Readonly<MultiSelectListProps>) => {
  const {
    creatable,
    debouncedSearchTerm,
    inputRef,
    inputValue,
    isLoading,
    mouseOn,
    onChange,
    onSearch,
    open,
    options,
    selected,
    setInputValue,
    setSelected,
  } = useMultiSelect();

  return (
    <DropdownList
      {...{
        inputRef,
        isLoading,
        mouseOn,
        open,
      }}
    >
      <DropdownListContent
        {...{
          creatable,
          debouncedSearchTerm,
          inputValue,
          isLoading,
          maxSelected,
          onChange,
          onMaxSelected,
          onSearch,
          options,
          selected,
          setInputValue,
          setSelected,
        }}
      />
    </DropdownList>
  );
};
