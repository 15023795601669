import getSettingsFromSchema from 'utils/settings/getSettingsFromSchema';

const DEFAULT_SCHEMA = 'main-settings';

const transform = (metaData) => {
  const result = {};
  Array.isArray(metaData) &&
    metaData.forEach(({ key, value }) => {
      result[key] = value;
    });
  return result;
};

export const defaultSettings = getSettingsFromSchema(DEFAULT_SCHEMA);

const mergeSettings = (generalSettingsData, userSettingsData) => {
  const generalSettings = transform(generalSettingsData);
  const userSettings = transform(userSettingsData);
  const mergedSettings = { ...defaultSettings, ...generalSettings, ...userSettings };
  return mergedSettings;
};

export default mergeSettings;
