import PropTypes from 'prop-types';

import { useSelectedCategory, useSelectedMenuSystemSettings } from '../../../../atoms';
import SETTING_TYPES, { MENU_TYPES } from '../../../../utils/settingTypes';
import General from './general';
import MetadataField from './metadataField';
import Instances from './instances';
import Links from './link';
import HtmlPanel from './htmlpanel';
import Editor from './editor/EditorDetails';
import RundownViews from './rundownviews/rundownViews';
import { RootWrapper } from './details-styled';
import Studios from './studios';
import { AiConfig } from '../menu/aiConfig/AiConfig';
import RundownPermissions from './rundownPermissions/RundownPermissions';

const SettingDetails = ({ systemSettings, categories, fields, webhooks, rundowns }) => {
  const [category] = useSelectedCategory();
  const [selectedMenu] = useSelectedMenuSystemSettings();

  switch (category) {
    case SETTING_TYPES.LINKS:
      return <Links />;
    case SETTING_TYPES.INSTANCES:
      return <Instances fields={fields} />;
    case SETTING_TYPES.METADATA:
      return <MetadataField fields={fields} />;
    case SETTING_TYPES.RUNDOWN:
      if (selectedMenu === MENU_TYPES.RUNDOWN_STUDIOS) return <Studios />;

      if (selectedMenu === MENU_TYPES.RUNDOWN_PERMISSIONS)
        return <RundownPermissions rundowns={rundowns} />;

      return <General systemSettings={systemSettings} categories={categories} />;
    case SETTING_TYPES.RUNDOWNVIEWS:
      return <RundownViews fields={fields} />;
    case SETTING_TYPES.GENERAL:
      return <General systemSettings={systemSettings} categories={categories} />;
    case SETTING_TYPES.AI_CONFIG:
      return <AiConfig />;
    case SETTING_TYPES.HTMLPANELS:
      return <HtmlPanel />;
    case SETTING_TYPES.EDITOR:
      return <Editor />;
    default:
      return null;
  }
};

const Details = ({ systemSettings, categories, fields, webhooks, rundowns }) => (
  <RootWrapper>
    <SettingDetails
      fields={fields}
      systemSettings={systemSettings}
      categories={categories}
      webhooks={webhooks}
      rundowns={rundowns}
    />
  </RootWrapper>
);

const propTypes = {
  systemSettings: PropTypes.object,
  categories: PropTypes.array,
  fields: PropTypes.arrayOf(PropTypes.object),
  webhooks: PropTypes.arrayOf(PropTypes.object),
  rundowns: PropTypes.arrayOf(PropTypes.object),
};

const defaultProps = {
  systemSettings: {},
  categories: [],
  fields: [],
  webhooks: [],
  rundowns: [],
};

Details.propTypes = propTypes;
SettingDetails.propTypes = propTypes;

Details.defaultProps = defaultProps;
SettingDetails.defaultProps = defaultProps;

export default Details;
