/** search with fuse, for frontend filtering of object arrays,
 * fuse.js documentation can be found on https://fusejs.io/
 */
import Fuse from 'fuse.js';

type SearchResult<T> = Fuse.FuseResult<T>;

const useFuseSearch = <TBase = unknown, B extends boolean = true>(
  options: Omit<Fuse.IFuseOptions<TBase>, 'keys'> = {},
  formatResult: B = true as B,
) => {
  const search = <T extends TBase>(
    list: T[],
    keys: (keyof T)[],
    expressions: string,
  ): B extends true ? T[] : SearchResult<T>[] => {
    const fuseOptions: Fuse.IFuseOptions<T> = {
      useExtendedSearch: true,
      shouldSort: false,
      threshold: 0.2,
      distance: 8000,
      ...options,
      keys: keys as Fuse.FuseOptionKey<T>[],
    };
    const fuse = new Fuse(list, fuseOptions);
    if (expressions?.length) {
      const results = fuse.search(expressions);
      return (formatResult ? results.map((res) => res.item) : results) as B extends true
        ? T[]
        : SearchResult<T>[];
    }
    // Return consistent type even when there are no search expressions
    return (formatResult ? list : list.map((item) => ({ item, refIndex: -1 }))) as B extends true
      ? T[]
      : SearchResult<T>[];
  };

  return search;
};

export default useFuseSearch;
