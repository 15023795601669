import getDurationKey from './getDurationKey';

/**
 * Finds duration by key & returns its value
 *
 * @param {Object[]} items Array of duration objects that will be searched
 * @param {string} key Property of object that should be used to find duration
 * @param {string} defaultValue Optional default value, default = undefined
 * @returns {string} Duration value
 */

const getDuration = (collection, key, defaultValue = undefined) => {
  const duration = (collection || []).find((item) => getDurationKey(item) === key);
  return duration ? duration.value : defaultValue;
};

export default getDuration;
