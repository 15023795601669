import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { endOfDay, startOfDay } from 'date-fns';
import { capitalize } from 'lodash';

import UserContext from 'contexts/UserContext';
import useSettingsValue from 'hooks/useSettingsValue';
import GET_PLATFORMS from 'operations/queries/getPlatforms';
import GET_RUNDOWNS_BY_PUBLISHING_DATE from 'operations/queries/getRundownByPublishingDate';
import { Destination, DestinationAccount, Instance, Platform, PlatformAccount } from 'types';
import { GetMembersByPublishingDateInput, MemberTypeEnum, Rundown } from 'types/graphqlTypes';
import { getPlatform } from 'utils/instance/platform';
import { getInstanceDefaultTemplateKey } from 'utils/instance/templates/getDefaultTemplateKey';
import {
  convertToPlatform,
  mergePlatforms,
  PlatformInfo,
  PlatformVariant,
} from 'utils/mergePlatforms';
import { isRundownEditable } from 'utils/rundown/isRundownEditable';

type GetPlatformsQueryReturn = {
  getPlatforms: Platform[];
};

type GetRundownsByPublishingDateInput = {
  input: GetMembersByPublishingDateInput;
};

type GetRundownsByPublishingDateQueryReturn = {
  getRundownsByPublishingDate: Rundown[];
};

const LinearPlatformId = 'linear';

export const getLinearVariantId = (platformKind?: string) => {
  return platformKind ? `${LinearPlatformId}-${platformKind}` : LinearPlatformId;
};

const getAccountFromRundown = (rundown: Rundown): DestinationAccount => ({
  accountId: rundown.mId,
  accountTitle: rundown.mTitle as string,
  accountUrl: rundown.mTitle as string,
  accountLogo: 'url@tolog',
  recurrence: rundown.recurrence,
  publishingAt: rundown.mPublishingAt,
  platformKind: rundown.platformKind,
  rundownTemplateId: rundown.mRundownTemplateId,
  variant: getLinearVariantId(rundown.platformKind),
});

const UnassignedAccount: PlatformAccount = {
  accountId: null,
  accountTitle: 'Unassigned',
  accountUrl: 'Unassigned',
  accountLogo: 'url@tolog',
  isUnassigned: true,
  recurrence: {},
};

const getPlatformVariantsById = (platformData: PlatformInfo[]): Record<string, PlatformVariant> => {
  return platformData.reduce((acc, pdata) => {
    const { variants = {} } = pdata;
    for (const variant of Object.keys(variants)) {
      acc[variant] = variants[variant];
    }
    return acc;
  }, {} as Record<string, PlatformVariant>);
};

const getDefaultLinearPlatformInfo = (platformKind?: string): PlatformInfo => {
  const variantId = getLinearVariantId(platformKind);

  const kind = platformKind ?? LinearPlatformId;
  const label = capitalize(kind);

  const unassignedAccount = { ...UnassignedAccount, variant: variantId };

  const linear: PlatformInfo = {
    platform: LinearPlatformId,
    platformKind,
    variants: {
      [variantId]: {
        id: variantId,
        platform: LinearPlatformId,
        platformKind,
        label,
        title: label,
        icon: kind,
        defaultTemplateKey: getInstanceDefaultTemplateKey(LinearPlatformId, platformKind),
      },
    },
    accounts: [unassignedAccount],
  };
  return linear;
};

const getLinearDestinations = (
  platform: Platform | undefined,
  rundowns: Rundown[],
  platformKind?: string,
) => {
  const targets =
    platform?.mProperties?.accounts?.map(({ accountId, accountTitle, recurrence }) => {
      const rundown = rundowns.find((r) => r.mId === accountId);
      const mPublishingAt = rundown?.mPublishingAt;
      const unassignedAccount = { ...UnassignedAccount, variant: getLinearVariantId(platformKind) };

      return {
        id: accountId,
        value: accountId ?? accountTitle,
        title: accountTitle,
        publishingTime: mPublishingAt ?? null,
        startTime: recurrence?.startTime ?? undefined,
        timeZone: recurrence?.timeZone ?? undefined,
        account: rundown ? getAccountFromRundown(rundown) : unassignedAccount,
        rundownTemplateId: rundown?.mRundownTemplateId,
      } as Destination;
    }) || [];
  return targets;
};

const getSoMeDestinations = (platform: Platform | undefined) => {
  const targets =
    platform?.mProperties?.accounts?.map(({ accountId, accountTitle }) => {
      return {
        id: accountId,
        value: accountTitle,
        title: accountTitle,
      } as Destination;
    }) || [];
  return targets;
};

const setRundownAccounts = (linearPlatforms: PlatformInfo[], rundowns: Rundown[]) => {
  if (!rundowns.length) return;
  const linearPlatformMap = new Map<string, PlatformInfo>(
    linearPlatforms.map((platform) => [platform.platformKind ?? 'linear', platform]),
  );
  for (const rundown of rundowns) {
    const account = getAccountFromRundown(rundown);
    const platformKind = account.platformKind ?? 'linear';
    const linearPlatform = linearPlatformMap.get(platformKind);
    linearPlatform?.accounts?.push(account);
  }
};

const useGetPlatforms = (
  date?: string | Date | number | null,
  instanceType?: string,
  platformKind?: string,
  skip = false,
) => {
  const [destinations, setDestinations] = useState<Destination[]>([]);
  const [platformInfo, setPlatformInfo] = useState<PlatformInfo[]>([]);
  const [platforms, setPlatforms] = useState<Platform[]>([]);
  const [getSettingsValue] = useSettingsValue();
  const { groups } = useContext(UserContext);

  // Retrieve all SoMe platforms as defined in the database: mId=platform
  const { data, error, loading } = useQuery<GetPlatformsQueryReturn>(GET_PLATFORMS, {
    skip,
  });

  // Retrieve all rundowns using the given date.
  const refDate = date ? new Date(date) : new Date();
  const startDate = startOfDay(refDate).toISOString();
  const endDate = endOfDay(refDate).toISOString();

  const {
    data: rundownsByDateData,
    error: errorRundownsByDate,
    loading: loadingRundownsByDate,
  } = useQuery<GetRundownsByPublishingDateQueryReturn, GetRundownsByPublishingDateInput>(
    GET_RUNDOWNS_BY_PUBLISHING_DATE,
    {
      variables: {
        input: {
          mType: 'rundown' as MemberTypeEnum,
          startDate,
          endDate,
        },
      },
      skip: !data || !date || skip,
    },
  );

  const getDefaultLinearPlatforms = useCallback(() => {
    const defaultLinearPlatforms = [getDefaultLinearPlatformInfo()];
    const isAudioEnabled = getSettingsValue('rundown.audioEnabled') === 'true';
    if (isAudioEnabled) defaultLinearPlatforms.push(getDefaultLinearPlatformInfo('audio'));
    return defaultLinearPlatforms;
  }, [getSettingsValue]);

  useEffect(() => {
    if (loading || loadingRundownsByDate) return;

    const SoMePlatformsInfo = mergePlatforms(data);

    const linearPlatformsInfo = getDefaultLinearPlatforms();
    const rundowns = (rundownsByDateData?.getRundownsByPublishingDate ?? []).filter((rundown) =>
      isRundownEditable({ permissions: rundown.permissions, groups }),
    );
    setRundownAccounts(linearPlatformsInfo, rundowns);

    const allPlatformsInfo = [...SoMePlatformsInfo, ...linearPlatformsInfo];
    const allPlatforms = allPlatformsInfo.map(convertToPlatform);

    if (instanceType) {
      const platformsByType = getPlatform(allPlatforms, instanceType, platformKind);
      const targets =
        instanceType === 'linear'
          ? getLinearDestinations(platformsByType, rundowns, platformKind)
          : getSoMeDestinations(platformsByType);
      setDestinations(targets);
    }

    setPlatformInfo(allPlatformsInfo);
    setPlatforms(allPlatforms);
  }, [
    data,
    rundownsByDateData,
    loading,
    loadingRundownsByDate,
    getDefaultLinearPlatforms,
    instanceType,
    groups,
    platformKind,
  ]);

  const platformVariants = useMemo(() => {
    const variants = getPlatformVariantsById(platformInfo);
    // Store platform variant default template from settings
    for (const variant of Object.values(variants)) {
      if (!variant.defaultTemplateKey) continue;
      variant.defaultTemplate = getSettingsValue(variant.defaultTemplateKey) as string;
    }
    return variants;
  }, [platformInfo, getSettingsValue]);

  const getPlatformVariant = useCallback(
    (instance: Instance): PlatformVariant | undefined => {
      const {
        mProperties: { platform: pform, platformKind: pkind, account: { accountId } = {} } = {},
      } = instance;

      const iplatforms = platformInfo.filter(
        (p) =>
          p.platform === pform &&
          (pkind
            ? p.platformKind === pkind || p.variants?.[pkind]
            : p.accounts?.some((a) => a.accountId === accountId)),
      );

      if (!iplatforms.length) return;
      const iplatform =
        pform !== 'linear'
          ? iplatforms[0]
          : iplatforms.find((p) => p.platformKind === pkind) ?? iplatforms[0];

      const paccount = iplatform.accounts?.find((a) => a.accountId === accountId);
      const pvariant = paccount?.variant;

      return pvariant ? iplatform.variants?.[pvariant] : undefined;
    },
    [platformInfo],
  );

  return {
    platforms,
    error: error || errorRundownsByDate,
    loading: loading || loadingRundownsByDate,
    destinations,
    platformVariants,
    getPlatformVariant,
  };
};

export default useGetPlatforms;
