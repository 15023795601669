import React from 'react';
import RadioButton from 'components/buttons/radioButton';
import { RadioButtonWrapper, RadioLabel, Subtitle, Wrapper } from './styled';
import scheduleOptions from '../../utils/scheduleOptions';

const { UNPUBLISH, UPDATE, REPUBLISH, UNSCHEDULE, SCHEDULE } = scheduleOptions;

function RadioButtonWithLabel({ onClick, selected, label, subtitle }) {
  return (
    <RadioButtonWrapper role="presentation" onClick={onClick}>
      <RadioButton selected={selected} size={22} />
      <RadioLabel>{label}</RadioLabel>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </RadioButtonWrapper>
  );
}

function RepublishRadioButtons({ selectedOption, onSelectOption }) {
  return (
    <Wrapper $showBorderBottom>
      <RadioButtonWithLabel
        selected={selectedOption === UPDATE}
        label="Update"
        onClick={() => onSelectOption(UPDATE)}
      />
      <RadioButtonWithLabel
        selected={selectedOption === REPUBLISH}
        label="Re-publish"
        onClick={() => onSelectOption(REPUBLISH)}
        subtitle="Publish to front page"
      />
      <RadioButtonWithLabel
        selected={selectedOption === UNPUBLISH}
        label="Unpublish"
        onClick={() => onSelectOption(UNPUBLISH)}
      />
    </Wrapper>
  );
}

function ScheduleRadioButtons({ selectedOption, onSelectOption, setConfirmPublish }) {
  return (
    <Wrapper>
      <RadioButtonWithLabel
        selected={selectedOption === UNSCHEDULE}
        label="Unschedule"
        onClick={() => {
          onSelectOption(UNSCHEDULE);
          setConfirmPublish(false);
        }}
      />
      <RadioButtonWithLabel
        selected={selectedOption === SCHEDULE}
        label="Schedule or publish"
        onClick={() => onSelectOption(SCHEDULE)}
      />
    </Wrapper>
  );
}

function RadioButtons({ isCmsRepublish, selectedOption, onSelectOption, setConfirmPublish }) {
  return isCmsRepublish ? (
    <RepublishRadioButtons selectedOption={selectedOption} onSelectOption={onSelectOption} />
  ) : (
    <ScheduleRadioButtons
      selectedOption={selectedOption}
      onSelectOption={onSelectOption}
      setConfirmPublish={setConfirmPublish}
    />
  );
}

export default RadioButtons;
