import { distanceInWordsToNow } from 'date-fns';
import capitalize from 'lodash/capitalize';

import { AssignedMember } from 'types/members';
import { Conversation } from 'types/messageHub';
import conversationTypes from 'utils/constants/conversationTypes';

import AvatarCell from '../AvatarCell';

const assembleTeamData = (conversations: Conversation[] = [], skipAvatarComponent = false) =>
  conversations.map((conversation) => {
    const { mId, mRefId, mTitle, mUpdatedAt, mAvatarKey } = conversation;
    return {
      ...conversation,
      conversationMId: mId,
      conversationMRefId: mRefId,
      rowId: mId,
      type: 'Team',
      name: skipAvatarComponent ? (
        mTitle
      ) : (
        <AvatarCell mTitle={mTitle} mAvatarKey={mAvatarKey} avatarVariant="team" />
      ),
      chatHistory: mUpdatedAt
        ? capitalize(distanceInWordsToNow(mUpdatedAt, { addSuffix: true }))
        : '',
      convoType: conversationTypes.TEAM,
      mAssignedMembers: [{ mId, mRefId, mTitle, mAvatarKey }] as AssignedMember[],
    } as Conversation;
  });
export default assembleTeamData;
