import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Input } from '@material-ui/core';
import transientOptions from 'theme/helpers';
import searchbarPositions from 'components/searchTable/constants/searchbarPositions';

const settingsStyle = ({ theme }) => css`
  border: 1px solid ${theme.palette.dina.inputBorderResting};
  border-radius: 6px;
`;

const defaultStyle = ({ theme }) => css`
  border-bottom: 1px solid ${theme.palette.dina.dividerLight};
  border-radius: 1px;
`;

const borderBottom = ({ theme }) => css`
  border-bottom: 1px solid ${theme.palette.dina.dividerLight};
`;
const borderTop = ({ theme }) => css`
  border-top: 1px solid ${theme.palette.dina.dividerLight};
`;

export const Search = styled('div', transientOptions)`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  padding: 8px;
  background: ${({ theme }) => theme.palette.dina.blackHoverOverlay};
  ${({ $searchbarPosition }) => {
    if ($searchbarPosition === searchbarPositions.TOP) return borderBottom;
    return borderTop;
  }}
`;

export const InputSearch = styled(Input, transientOptions)`
  ${({ theme }) => css`
    ${theme.typography.dina.body2};
    background: ${theme.palette.dina.blackHoverOverlay};
  `}
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px 8px 0px 8px;
  ${({ $usageType }) => {
    if ($usageType === 'settings') return settingsStyle;
    return defaultStyle;
  }}
  :hover {
    border-color: ${({ theme }) => theme.palette.dina.inputBorderFocused};
  }
  :focus {
    ${({ theme }) => css`
      background-color: ${theme.palette.dina.backgroundSelected};
      border-color: ${theme.palette.dina.inputBorderFocused};
    `}
  }
`;

export const ClearButton = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  :hover {
    path {
      fill-opacity: 1;
    }
  }
`;
