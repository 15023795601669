import styled from '@emotion/styled';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import transientOptions from 'theme/helpers';
import Divider from 'components/divider';

export const Container = styled('div')`
  height: 100vh;
  display: flex;
`;

// Left Side Styles
export const LeftContainer = styled('div')`
  flex: 1;
  display: flex;
`;

export const LeftContentWrapper = styled('div')`
  flex: 1;
  padding: 32px 56px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const IconWrapper = styled('div')`
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const StyledCaption = styled(Typography)`
  ${({ theme }) => theme.typography.dina.caption}
`;

export const FormWrapper = styled('div')`
  flex: 1 1 0;
  display: flex;
  justify-content: center;
`;

export const LinksWrapper = styled('div')`
  flex: 1 1 0;
  display: flex;
  align-items: flex-end;
`;

// Right Side Styles
export const RightContainer = styled('div', transientOptions)`
  flex: 1;
  display: flex;
  background-size: cover;
  background-position: center;
  background-image: ${({ $imageUrl }) => `url(${$imageUrl})`};

  @media (max-width: 768px) {
    display: none;
  }
`;

export const RightContentWrapper = styled('div')`
  flex: 1;
  padding: 24px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  background: ${({ theme }) =>
    theme.palette.mode === 'light'
      ? 'linear-gradient(360deg, rgba(255, 255, 255, 0.2) 5%, rgba(255, 255, 255, 0.05) 100%)'
      : 'linear-gradient(360deg, rgba(31, 61, 119, 0.5) 5%, rgba(31, 61, 119, 0.05) 100%)'};
`;

export const StyledLink = styled(Link)`
  ${({ theme }) => theme.typography.dina.caption}
  text-align: end;
`;

export const StyledDivider = styled(Divider)`
  height: 10px;
  width: 1px;
  margin: 1px 12px;
  background-color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
`;
