import React, { useState, useCallback, useMemo, memo, useEffect } from 'react';
import { Transforms } from 'slate';
import { useSlate } from 'slate-react';
import { elementTypes } from 'components/editor/constants/types';
import Avatar from 'components/avatar/Avatar';
import { useEditorContext } from 'components/editor/EditorContext';
import Portal from '../portal';
import { ListWrapper, List, MenuItem, Title } from './styled';
import { beforeTextRegex as beforeExp, afterTextRegex as afterExp } from '../../utils/matcher';
import useCombobox from 'components/editor/hooks/useCombobox';

const { select, insertNodes, move } = Transforms;

const Suggestions = () => {
  const editor = useSlate();
  const { users } = useEditorContext();
  const [search, setSearch] = useState('');

  const filteredUsers = useMemo(
    () =>
      users
        .filter(({ mTitle }) => mTitle?.toLowerCase().includes(search?.toLowerCase()))
        .sort((a, b) => a?.mTitle.localeCompare(b.mTitle)),
    [search, users],
  );

  const insertMention = useCallback(
    (user, targetNode) => {
      const mention = {
        type: elementTypes.MENTION,
        data: user,
        children: [{ text: '' }],
      };

      select(editor, targetNode);
      insertNodes(editor, mention, { select: true });
      move(editor, { unit: 'character', distance: 1 });
    },
    [editor],
  );

  const { position, target, cursor } = useCombobox(filteredUsers, insertMention, setSearch, {
    beforeExp,
    afterExp,
  });

  useEffect(() => {
    const target = document.getElementById(filteredUsers[cursor]?.mId);
    target?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }, [cursor]);

  const showSuggestions = target && filteredUsers.length > 0;

  return showSuggestions ? (
    <Portal>
      <ListWrapper position={position} elevation={12}>
        <List>
          {filteredUsers.map(({ mTitle, mId, mAvatarKey }, index) => (
            <MenuItem
              dense
              id={mId}
              key={mId}
              selected={index === cursor}
              onClick={() => insertMention({ mTitle, mId }, target)}
            >
              <Avatar imageKey={mAvatarKey} size={24} title={mTitle} />
              <Title>{mTitle}</Title>
            </MenuItem>
          ))}
        </List>
      </ListWrapper>
    </Portal>
  ) : null;
};

export default memo(Suggestions);
