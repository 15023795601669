import { useCallback } from 'react';

import useDinaNavigate from 'hooks/useDinaNavigate';
import useGetInstanceDetails from 'hooks/useGetInstanceDetails';
import { ResourceType } from 'types/forms/forms';
import { MemberTypeEnum } from 'types/graphqlTypes';

export const isNavigatable = (
  mResourceType: unknown,
): mResourceType is 'story' | 'space' | 'instance' | 'rundown' => {
  if (typeof mResourceType !== 'string') return false;
  return ['story', 'space', 'instance', 'rundown', 'pitch'].includes(mResourceType);
};

const useGoToOrderResource = () => {
  const { getInstanceURLParams } = useGetInstanceDetails();
  const { navigateTo } = useDinaNavigate();

  const goToResource = useCallback(
    async (resourceId: string, resourceType: ResourceType) => {
      return new Promise((resolve, reject) => {
        if (resourceType === MemberTypeEnum.Instance) {
          getInstanceURLParams(resourceId)
            .then((details) => {
              if (details) {
                if (details.path === 'story') {
                  navigateTo(details.path, details.id, {
                    tab: 'instances',
                    entityId: resourceId,
                  });
                } else {
                  navigateTo(details.path as 'rundown' | 'rundowntemplate', details.id);
                }
              }
              resolve(true);
            })
            .catch(() => reject(new Error('Expected instance details returned')));
        } else if (isNavigatable(resourceType)) {
          if (resourceId === 'home') navigateTo('home');
          else navigateTo(resourceType, resourceId);
          resolve(true);
        }
        resolve(true);
      });
    },
    [getInstanceURLParams, navigateTo],
  );

  return { goToResource };
};

export default useGoToOrderResource;
