import { memo } from 'react';

import { elementTypes, markTypes } from 'components/editor/constants';

import { ToolbarContainer } from '../../styled';
import { ButtonGroup } from '../ButtonGroup';
import CheckListButton from '../checkListButton/CheckListButton';
import ColorButton from '../colorButton';
import ElementButton from '../elementButton';
import HorizontalRuleButton from '../horizontalRuleButton/HorizontalRule';
import ImageButton from '../imageButton';
import LinkButton from '../linkButton';
import ListButton from '../listButton';
import MarkButton from '../markButton';
import PlaceholderButton from '../placeholderButton';
import QuoteButton from '../quoteButton/QuoteButton';
import UppercaseButton from '../uppercaseButton/UppercaseButton';
import VideoButton from '../videoButton';

const { BOLD, ITALIC, UNDERLINE, STRIKE_THROUGH } = markTypes;

const { UNORDERED_LIST, HEADING_FOUR, HEADING_ONE, HEADING_THREE, HEADING_TWO, ORDERED_LIST } =
  elementTypes;

interface GeneralToolbarProps {
  hideRightButtons?: boolean;
  isAllowed?: boolean;
  extras?: React.ReactElement;
}

function GeneralToolbar({ hideRightButtons, isAllowed, extras }: Readonly<GeneralToolbarProps>) {
  return (
    <ToolbarContainer>
      <ButtonGroup>
        <ElementButton type={HEADING_ONE} />
        <ElementButton type={HEADING_TWO} />
        <ElementButton type={HEADING_THREE} />
        <ElementButton type={HEADING_FOUR} />
      </ButtonGroup>
      <ButtonGroup>
        <MarkButton type={BOLD} />
        <MarkButton type={ITALIC} />
        <MarkButton type={UNDERLINE} />
        <MarkButton type={STRIKE_THROUGH} />
        <UppercaseButton />
      </ButtonGroup>
      <ButtonGroup>
        <ListButton type={UNORDERED_LIST} />
        <ListButton type={ORDERED_LIST} />
      </ButtonGroup>
      <ButtonGroup>
        <CheckListButton />
        <QuoteButton />
        <LinkButton />
        <HorizontalRuleButton />
      </ButtonGroup>
      <ButtonGroup>
        <ColorButton />
      </ButtonGroup>
      {!hideRightButtons && (
        <ButtonGroup>
          {!isAllowed && <PlaceholderButton />}
          <VideoButton />
          <ImageButton />
          <ImageButton isGif />
        </ButtonGroup>
      )}
      {extras}
    </ToolbarContainer>
  );
}

export default memo(GeneralToolbar);
