import { ApolloCache } from '@apollo/client';

import {
  BoardType,
  BoardUpdatedType,
  ColumnType,
  MemberType,
  UpdateBoardActionEnum,
} from 'types/graphqlTypes';

import { GET_BOARD } from './graphql';
import { QueryType } from './types';

/** Read board from cache */
const readBoardFromCache = (cache: ApolloCache<unknown>, mId: string) => {
  const cachedQuery = cache.readQuery<QueryType>({
    query: GET_BOARD,
    variables: { input: { mId } },
    returnPartialData: true,
  });

  return cachedQuery?.getBoard;
};

/** Write updated board to cache */
const writeCache = (cache: ApolloCache<unknown>, boardId: string, updatedBoard: BoardType) => {
  cache.writeQuery({
    query: GET_BOARD,
    variables: { input: { mId: boardId } },
    data: {
      getBoard: { ...updatedBoard },
    },
  });
};

/** Add a new member to a column */
export const addMemberToCache = (cache: ApolloCache<unknown>, updates: BoardUpdatedType) => {
  // result data from the mutation

  const { member, columnId, boardId } = updates;
  if (!member?.mId) return;

  // Fetch board from cache
  const cachedBoard = readBoardFromCache(cache, boardId);
  if (!cachedBoard) return;

  // Update column with new member
  const updatedColumns = (cachedBoard?.columns ?? []).map((c) => {
    if (c.id === columnId) {
      // Check if member already exists in column
      const existingMember = c.members.find((m) => m.mId === member.mId);
      if (existingMember) return c;

      // Add member to column
      const columnMembers = [...c.members, { ...member }];
      return { ...c, members: columnMembers };
    }
    return c;
  });

  const updatedBoard = {
    ...cachedBoard,
    columns: updatedColumns,
  };

  writeCache(cache, boardId, updatedBoard);
};

/** Remove a member from a column */
export const removeMemberFromCache = (cache: ApolloCache<unknown>, updates: BoardUpdatedType) => {
  // result data from the mutation
  const { memberId, columnId, boardId } = updates;

  // Fetch board from cache
  const cachedBoard = readBoardFromCache(cache, boardId);
  if (!cachedBoard) return;

  // Remove member from column
  const updatedColumns = (cachedBoard?.columns ?? []).map((c) => {
    if (c.id === columnId) {
      const filteredMembers = c.members.filter((m) => m.mId !== memberId);
      return { ...c, members: filteredMembers };
    }
    return c;
  });

  const updatedBoard = {
    ...cachedBoard,
    columns: updatedColumns,
  };

  writeCache(cache, boardId, updatedBoard);
};

/** Reorder members in a column */
export const reorderMembersInCache = (cache: ApolloCache<unknown>, updates: BoardUpdatedType) => {
  // Result data from the mutation
  const { columnId, memberIds, boardId } = updates;
  const sortOrder = memberIds ?? [];

  // Fetch board from cache
  const cachedBoard = readBoardFromCache(cache, boardId);
  if (!cachedBoard) return;

  // Update column with new member order
  const updatedColumns: ColumnType[] = (cachedBoard?.columns ?? []).map((c) => {
    if (c.id === columnId) {
      // remove members from c.members that dont have a mId
      const updatedMembers: MemberType[] = [];

      sortOrder.forEach((id) => {
        if (!id) return;
        const member = c.members.find((m) => m.mId === id);
        if (member) updatedMembers.push(member);
      });
      return { ...c, members: updatedMembers };
    }
    return c;
  });

  const updatedBoard = {
    ...cachedBoard,
    columns: updatedColumns,
  };

  writeCache(cache, boardId, updatedBoard);
};

export const updateBoardWithSubscriptionData = (
  client: ApolloCache<unknown>,
  subscriptionUpdates: BoardUpdatedType,
) => {
  const { crudAction } = subscriptionUpdates;
  switch (crudAction) {
    case UpdateBoardActionEnum.AddMember:
      addMemberToCache(client, subscriptionUpdates);
      break;
    case UpdateBoardActionEnum.RemoveMember:
      removeMemberFromCache(client, subscriptionUpdates);
      break;
    case UpdateBoardActionEnum.ReorderMembers:
      reorderMembersInCache(client, subscriptionUpdates);
      break;
    default:
      break;
  }
};
