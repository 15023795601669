import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import configCtx from 'contexts/configContext';
import { useSelectedMenuSystemSettings } from '../../../../../atoms';
import { RootWrapper, TableHead, InstanceMetadataLabel } from './instance-styled';
import InstanceFields from './InstanceFields';

const Instances = (props) => {
  const { fields } = props;
  const { metadataForms } = useContext(configCtx);
  const [selectedMenu] = useSelectedMenuSystemSettings();
  const { views } = metadataForms[0];
  const defaultView = views.find((view) => view.id === 'instance-metadata-1');
  const selectedView = views.find((view) => view.id === `${selectedMenu}-instance-metadata-1`);

  return (
    <RootWrapper>
      <TableHead>
        <InstanceMetadataLabel>Add or remove fields from instance</InstanceMetadataLabel>
      </TableHead>
      <InstanceFields fields={fields} selectedView={selectedView || defaultView} />
    </RootWrapper>
  );
};

Instances.propTypes = {
  fields: PropTypes.array,
};

Instances.defaultProps = {
  fields: {},
};

export default Instances;
