/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Typography } from '@material-ui/core';
import useStyles from './toggleButtons-styles';

const ToggleButtons = ({ selectedValue, onChange, list, label }) => {
  const classes = useStyles();
  const isListEmpty = list.length < 1;

  const initialValue = selectedValue || (!isListEmpty && list[0].value);

  const [selected, setSelected] = useState(initialValue);

  useEffect(() => {
    if (selectedValue) setSelected(selectedValue);
  }, [selectedValue]);

  const handleClick = (event, newValue) => {
    event.preventDefault();
    event.stopPropagation();
    if (newValue !== selected) {
      setSelected(newValue);
      onChange(newValue);
    }
  };

  return (
    !isListEmpty && (
      <div>
        {label && (
          <div className={classes.label}>
            <Typography classes={{ root: classes.labelText }}>{label}</Typography>
          </div>
        )}
        <ButtonGroup
          fullWidth
          classes={{
            root: classes.groupRoot,
            grouped: classes.grouped,
          }}
          aria-label="toggle-buttons"
        >
          {list.map((button, index) => (
            <Button
              fullWidth
              key={`${button.value}-${index}`}
              style={{ fontSize: button.fontSize || 14 }}
              disabled={button.disabled}
              classes={{
                root:
                  button.value === selected
                    ? classes.toggleButtonSelected
                    : classes.toggleButtonRoot,
                disabled: classes.disabled,
              }}
              onClick={(event) => handleClick(event, button.value)}
            >
              {button.icon && button.icon}
              {button.label && button.label}
            </Button>
          ))}
        </ButtonGroup>
      </div>
    )
  );
};

ToggleButtons.propTypes = {
  /**
   * current selected value from list of buttons
   */
  selectedValue: PropTypes.string,
  /**
   * callback function that returns the newly selected value
   */
  onChange: PropTypes.func,
  /**
   * buttonList to render inside the toggle button group
   */
  list: PropTypes.arrayOf(PropTypes.object),
  /**
   * label to show above the toggle buttons
   */
  label: PropTypes.string,
};

ToggleButtons.defaultProps = {
  selectedValue: '',
  onChange: () => {},
  list: [],
  label: '',
};

export default ToggleButtons;
