import gql from 'graphql-tag';

export default gql`
  query GetStudios($input: GetMemberInput) {
    getMemberFromId(input: $input) {
      mId
      mRefId
      mType
      mTitle
      mUpdatedAt
      mMetaData {
        key
        value
      }
    }
  }
`;
