import styled from '@emotion/styled/macro';

export const Wrapper = styled('div')`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const LeftMenuWrapper = styled('div')`
  position: absolute;
  width: 256px;
  left: 0px;
  top: 0px;
  bottom: 0px;
`;

export const Divider = styled('div')`
  position: absolute;
  width: 1px;
  left: 256px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background: ${({ theme }) => theme.palette.dina.dividerLight};
`;

export const ContentWrapper = styled('div')`
  position: absolute;
  left: 257px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  height: 93%;
`;
