import gql from 'graphql-tag';

export default gql`
  query BatchGetInstances($input: BatchGetMembersInput) {
    batchGetMembers(input: $input) {
      mId
      mRefId
      mTitle
      mThumbnailKey
      mType
      mStoryId
      mCreatedAt
      mUpdatedAt
      mUpdatedById
      mPublishingAt
      mContentKey
      mDefaultContentKey
      locked
      mState
      mTemplateId
      isTemplateInstance
      mProperties {
        __typename
        ... on PlatformType {
          id
          platform
          platformKind
          account {
            id
            accountUrl
            accountLogo
            accountTitle
            accountId
            accountRefId
            orderType
          }
        }
      }
      items {
        itemId
        title
        templateType
        templateVariant
        iconUrl
        subItems {
          itemId
          title
          templateType
          templateVariant
          iconUrl
        }
      }
      mMetaData {
        key
        value
        manual
        autoValue
      }
      mAssignedMembers {
        mId
        mType
      }
      mRelatedMembers
      assets {
        mId
        assetRefId
        itemId
        itemType
        state
      }
    }
  }
`;
