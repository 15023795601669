import { Editor, Element, Node, Operation, Range } from 'slate';

import { isMdfBlock, isOrderBlock } from './ElementKeyDownUtils';

/**
 * Determines whether a node should be protected from deletion.
 *
 * @param node - The node to check.
 * @returns True if the node should be protected, false otherwise.
 */
const shouldPreventDeletion = (node: Node): boolean => {
  return Element.isElement(node) && (isOrderBlock(node.type) || isMdfBlock(node.type));
};

/**
 * Checks if the current operation is replacing a node with a single character.
 *
 * @param editor - The Slate editor.
 * @returns True if a node is being replaced with a single character, false otherwise.
 */
const isReplacingWithCharacter = (editor: Editor): boolean => {
  const { operations } = editor;
  return operations.some((op) => op.type === 'insert_text' && op.text.length === 1);
};

/**
 * A higher-order function that enhances a Slate editor to prevent deletion
 * of certain nodes when they're being replaced by a single character keypress.
 *
 * @param editor - The Slate editor to enhance.
 * @returns The enhanced Slate editor.
 */
const withPreventDeletion = (editor: Editor) => {
  const { apply, insertText } = editor;

  editor.apply = (operation: Operation) => {
    if (operation.type === 'remove_node') {
      const [node] = Editor.node(editor, operation.path);
      if (shouldPreventDeletion(node) && isReplacingWithCharacter(editor)) return;
    }
    apply(operation);
  };

  editor.insertText = (text: string) => {
    const { selection } = editor;
    if (selection && !Range.isCollapsed(selection)) {
      const [start, end] = Range.edges(selection);
      const range = Editor.range(editor, start, end);

      const nodesInRange = Array.from(Editor.nodes(editor, { at: range }));
      const hasRestrictedNode = nodesInRange.some(([node]) => shouldPreventDeletion(node));

      if (hasRestrictedNode) return;
    }
    insertText(text);
  };

  return editor;
};

export default withPreventDeletion;
