import getMosAsset from 'components/editor/utils/getMosAsset';
import { actionTypes } from 'components/editor/constants/types';
import { Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import findAndReplace from 'utils/findAndReplace';
import notifyChange from 'components/editor/utils/notifyChange';
import { mediaTypes } from 'utils/rundownItemTypes';
/**
 * Adds graphics media to given element
 *
 * @param {Object} editor SlateJS editor instance
 * @param {Object} element SlateJS element to be updated
 * @param {Object} payload Data payload of dropped item
 * @param {Function} update Callback to be invoked on update
 * @returns {Object} SlateJS editor instance
 */

const addGraphics = async (editor, element, payload, update) => {
  const mosAsset = getMosAsset(payload, mediaTypes.GRAPHICS);
  if (!mosAsset) return;

  try {
    const result = await update({
      type: actionTypes.CREATE_ASSET,
      payload: { document: editor.children, asset: mosAsset },
    });

    const asset = result.data.createAssets[0];
    if (!asset) return;

    const { data } = element;
    const { assets: previousAssets = [], ...rest } = data;
    const newAsset = {
      ...mosAsset,
      mId: asset.mId,
      mRefId: asset.mRefId,
      mAssetId: asset.mAssetId,
      mosobj: payload,
    };

    const updatedData = {
      ...rest,
      assets: findAndReplace(previousAssets, newAsset, 'assetType'),
    };

    const path = ReactEditor.findPath(editor, element);
    Transforms.setNodes(editor, { data: updatedData }, { at: path });

    update({
      type: actionTypes.AUTOMATION_UPDATE,
      payload: { document: editor.children, updatedData },
    });

    notifyChange(editor, update);
    ReactEditor.focus(editor);
  } catch (error) {
    // console.log(error);
  }
};

export default addGraphics;
