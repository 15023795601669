import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useContextMenu } from 'react-contexify';
import { FetchPolicy } from '@apollo/client';
import { ScopeProvider } from 'jotai-molecules';

import { ReactComponent as AddFolder } from 'assets/icons/systemicons/addfolder.svg';
import { ReactComponent as KanbanIcon } from 'assets/icons/systemicons/kanban.svg';
import { ReactComponent as GridIcon } from 'assets/icons/systemicons/list.svg';
import { MemberContextMenu } from 'components/contextMenu/MemberContextMenu';
import Deck from 'components/deck';
import Kanban from 'features/kanban';
import useGetKanban, { SubscriptionType } from 'features/kanban/api/useGetKanban';
import useStoreKanbanUpdates from 'features/kanban/api/useUpdateKanban';
import { RemoveMemberItem } from 'features/kanban/components/contextMenu/RemoveItem';
import { kanbanScope, useKanbanMolecule } from 'features/kanban/store/kanban';
import WidgetWrapper, { FilterComponentType } from 'features/widget/components/WidgetWrapper';
import { WIDGETS } from 'features/widget/constants';
import { MemberType } from 'types/graphqlTypes';

import { FilterComponent } from './KanbanFilters';
import { WidgetProps } from './types';
import { getPropFilters } from './utils';

interface Props {
  mId: string;
  isHorizontal?: boolean;
}

interface GetKanbanProps {
  getKanban: ({ mId, fetchPolicy }: { mId: string; fetchPolicy: FetchPolicy }) => void;
  loading: boolean;
  startSubscription: (mId: string) => [SubscriptionType, SubscriptionType];
}
const domainContextItems = [<RemoveMemberItem key="remove" />];

const KanbanWidget = memo(({ mId, isHorizontal = true }: Readonly<Props>) => {
  const { show } = useContextMenu({ id: `kanbanItemMenu-${mId}` });

  // API
  const { getKanban, loading, startSubscription }: GetKanbanProps = useGetKanban();
  const { updateKanbanBoard } = useStoreKanbanUpdates();

  // Store
  const { kanbanBoardUpdatedListener } = useKanbanMolecule();
  const [useUpdateListener] = kanbanBoardUpdatedListener();

  useUpdateListener(
    useCallback(
      async (newVal, prevVal) => {
        if (JSON.stringify(newVal) !== JSON.stringify(prevVal)) {
          await updateKanbanBoard({
            kanbanBoard: newVal,
          });
        }
      },
      [mId, updateKanbanBoard],
    ),
  );

  useEffect(() => {
    if (getKanban) {
      getKanban({ mId, fetchPolicy: 'network-only' });
      const [subscription, rundownSubscription] = startSubscription(mId);

      return () => {
        if (subscription) subscription?.unsubscribe();
        if (rundownSubscription) rundownSubscription?.unsubscribe();
      };
    }
  }, [mId, getKanban, startSubscription]);

  const onContextMenu = useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      member: MemberType,
      parentLaneId: string,
    ) => {
      show({
        event,
        props: {
          member,
          parentLaneId,
        },
      });
    },
    [show],
  );

  return (
    <>
      <Kanban
        isHorizontal={isHorizontal}
        loading={loading}
        customRenderItem={undefined}
        // @ts-expect-error Will be resolved once Kanban.jsx is typed
        onContextMenu={onContextMenu}
      />
      <MemberContextMenu menuId={`kanbanItemMenu-${mId}`} domainItems={domainContextItems} />
    </>
  );
});

KanbanWidget.displayName = 'KanbanWidget';

type KanbanMenuProps = {
  mId: string;
  toggleOrientation: () => void;
  isHorizontal: boolean;
};

const KanbanMenu = ({ mId, toggleOrientation, isHorizontal }: KanbanMenuProps) => {
  const { useCreateKanbanLane } = useKanbanMolecule();
  const createKanbanLane = useCreateKanbanLane();

  const handleCreateLane = useCallback(() => {
    createKanbanLane({ mId, direction: 'ltr' });
  }, [mId, createKanbanLane]);

  const buttons = [
    {
      onClick: toggleOrientation,
      title: `${isHorizontal ? 'Vertical' : 'Horizontal'}`,
      icon: isHorizontal ? <GridIcon /> : <KanbanIcon />,
    },
    { onClick: handleCreateLane, title: 'Create group', icon: <AddFolder /> },
  ];

  return buttons.map((button) => (
    <Deck.Button onClick={button.onClick} title={button.title} key={button.title}>
      {button.icon}
    </Deck.Button>
  ));
};

function KanbanWidgetContainer({
  mId,
  mRefId,
  title,
  filters,
  writeAccess,
  layout,
}: Readonly<WidgetProps>) {
  const [isHorizontal, setIsHorizontal] = useState(true);

  const memoizedFilters = useMemo(() => {
    return getPropFilters({ filters });
  }, [filters]);

  const customWidth = memoizedFilters?.customWidth as number | undefined;

  return (
    <ScopeProvider scope={kanbanScope} value={mId}>
      <WidgetWrapper
        mRefId={mRefId}
        layout={layout}
        type={WIDGETS.KANBAN}
        customWidth={customWidth}
        writeAccess={writeAccess}
        title={title}
        mId={mId}
        filters={memoizedFilters}
        filterComponent={FilterComponent as FilterComponentType}
        headerProps={
          <KanbanMenu
            isHorizontal={isHorizontal}
            mId={mId}
            toggleOrientation={() => setIsHorizontal((prev) => !prev)}
          />
        }
      >
        <KanbanWidget mId={mId} isHorizontal={isHorizontal} />
      </WidgetWrapper>
    </ScopeProvider>
  );
}

KanbanWidgetContainer.displayName = 'KanbanWidgetContainer';

export default memo(KanbanWidgetContainer);
