import { atom, useAtom } from 'jotai';
import { createScope, molecule, useMolecule } from 'jotai-molecules';

export interface DialogDataModel {
  [key: string]: string | number | boolean | null | undefined | string[];
}

// The scope prevents data model collisions between different dialogs.
export const DialogScope = createScope();
const DialogMolecule = molecule((_, getScope) => {
  getScope(DialogScope);
  const dataModelAtom = atom<DialogDataModel | null>(null);
  const useScopedDataModelAtom = () => useAtom(dataModelAtom);
  return { useScopedDataModelAtom };
});

export const useDataModel = () => {
  const { useScopedDataModelAtom } = useMolecule(DialogMolecule);
  return useScopedDataModelAtom();
};
