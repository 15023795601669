import gql from 'graphql-tag';

export default gql`
  mutation CreateContact($input: CreateContactInput) {
    createContact(input: $input) {
      mId
      mRefId
      mTitle
      mDescription
      mType
      mAvatarKey
      mMetaData {
        key
        value
      }
      mdfId
      metadata
    }
  }
`;
