import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { ReactComponent as Delete } from 'assets/icons/systemicons/delete.svg';
import { ReactComponent as Open } from 'assets/icons/systemicons/open.svg';
import { ReactComponent as AddPlaceholder } from 'assets/icons/systemicons/placeholder_add.svg';
import { ReactComponent as Remove } from 'assets/icons/systemicons/placeholder_delete.svg';
import { ReactComponent as SidebarActive } from 'assets/icons/systemicons/sidebar_active.svg';
import { ReactComponent as SidebarInactive } from 'assets/icons/systemicons/sidebar_inactive.svg';
import Text from 'components/text';
import { Box } from 'layouts/box/Box';
import transientOptions from 'theme/helpers';
import { svgHoverStyles } from 'theme/utils/commonStyles';

export const ImageBlock = styled('div')`
  padding: 8px;
  :hover {
    .actions {
      width: 32px;
    }
    .left-side-wrapper *,
    .mdf-wrapper {
      border-color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
    }
  }
`;

export const PlaceholderZone = styled('div', transientOptions)<{ $isOver: boolean }>`
  height: 240px;
  flex-shrink: 0;
  width: 430px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border: 1px dashed ${({ theme }) => theme.palette.dina.blockBorderHover};
  display: flex;
  ${({ $isOver, theme }) =>
    $isOver &&
    css`
      background-color: ${theme.palette.dina.whiteHoverOverlay};
      border-color: ${theme.palette.dina.highEmphasis};
      color: ${theme.palette.dina.highEmphasis};
    `}
`;

export const StyledText = styled(Text)`
  margin: auto;
`;

export const StyledParagraph = styled('p', transientOptions)<{ $locked: boolean }>`
  z-index: 100;
  margin: 0px;
  padding: 8px;
`;

export const StyledImg = styled('div', transientOptions)<{ $src?: string; $collapsed?: boolean }>`
  z-index: 99;
  height: 240px;
  width: 430px;
  cursor: pointer;
  background-color: black;
  background-image: url(${({ $src }) => $src});
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  border: 1px solid ${({ theme }) => theme.palette.dina.blockBorderHover};
  border-right: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  ${({ $collapsed }) =>
    $collapsed &&
    css`
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    `}
`;

export const MdfWrapper = styled('div')`
  height: 240px;
  overflow: auto;
  width: 100%;
  max-width: 400px;
  padding: 2px 10px 0 10px;
  border: 1px solid ${({ theme }) => theme.palette.dina.blockBorderHover};
  border-left: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`;

export const DropZone = styled('div', transientOptions)<{ $isOver: boolean }>`
  border: 1px solid transparent;
  border-radius: 4px;
  ${({ $isOver, theme }) =>
    $isOver &&
    css`
      border: 1px dashed ${theme.palette.dina.mediumEmphasis};
    `}
`;

export const Actions = styled(Box, transientOptions)<{ $collapsed?: boolean }>`
  z-index: 100;
  padding: 4px 0;
  width: 0px;
  height: calc(100% - 2px);
  position: absolute;
  right: 0;
  top: 1px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  ${({ $collapsed }) =>
    $collapsed &&
    css`
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    `}
  background: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundMain};
  -webkit-transition: width 0.11s ease-in-out;
  -moz-transition: width 0.11s ease-in-out;
  -o-transition: width 0.11s ease-in-out;
  transition: width 0.11s ease-in-out;
`;

export const StyledOpen = styled(Open)`
  ${svgHoverStyles}
  width: 32px;
`;

export const StyledDelete = styled(Delete)`
  ${svgHoverStyles}
  width: 32px;
`;

export const StyledSidePanelActive = styled(SidebarActive)`
  ${svgHoverStyles}
  width: 32px;
`;

export const StyledRemove = styled(Remove)`
  ${svgHoverStyles}
  width: 32px;
`;

export const StyledSidePanelInactive = styled(SidebarInactive)`
  ${svgHoverStyles}
  width: 32px;
`;

export const StyledPlaceholderAdd = styled(AddPlaceholder)`
  ${svgHoverStyles}
  width: 32px;
`;

export const LeftSideWrapper = styled('div')`
  position: relative;
`;
