import { Box } from 'layouts/box/Box';
import { Collapsible, CollapsibleContent } from 'lib/collapsible';

import {
  Button,
  CollapsibleTrigger,
  DownIcon,
  ListWrapper,
  MdfWrapper,
  RightIcon,
  SelectionIndicator,
} from './styled';

export interface ToggleItemProps {
  trigger: React.ReactNode;
  content: React.ReactNode;
  selected: boolean;
  onSelect: (event: React.MouseEvent<HTMLButtonElement>) => void;
  id?: string;
  color?: string;
  open?: boolean;
  onContextMenu?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export function ToggleItem({
  id,
  trigger,
  content,
  color,
  selected,
  onSelect,
  onContextMenu,
  open,
}: Readonly<ToggleItemProps>) {
  return (
    <SelectionIndicator id={id} $selected={selected} $color={color}>
      <Collapsible open={open} onContextMenu={onContextMenu}>
        <CollapsibleTrigger tabIndex={0} onClick={onSelect} asChild>
          <Button>
            <DownIcon className="skipOverride down" />
            <RightIcon className="skipOverride right" />
            {trigger}
          </Button>
        </CollapsibleTrigger>
        <CollapsibleContent>{content && <MdfWrapper>{content}</MdfWrapper>}</CollapsibleContent>
      </Collapsible>
    </SelectionIndicator>
  );
}

interface ToggleListProps {
  header: React.ReactNode;
  list: React.ReactNode;
}

export function ToggleList({ header, list }: Readonly<ToggleListProps>) {
  return (
    <Box container flexDirection="column" height="100%" width="100%">
      {header}
      <ListWrapper>{list}</ListWrapper>
    </Box>
  );
}
