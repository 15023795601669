import { Editor, Transforms } from 'slate';
import { elementTypes } from 'components/editor/constants';
import isLink from 'components/editor/utils/isLink';

const { removeNodes, insertNodes, move, unwrapNodes } = Transforms;
const { LINK } = elementTypes;

/**
 * Wraps given text in link node
 *
 * @param {Object} editor SlateJS editor instance
 * @param {String} href URL of the link
 * @param {String} linkText Text for the link
 * @returns {Object} SlateJS editor instance
 */
const wrapLink = (editor, href, linkText = '') => {
  const [match] = Editor.nodes(editor, { match: isLink });
  const isActive = Boolean(match);
  const activeLinkText = isActive && match[0].children[0].text;

  if (!href) return unwrapNodes(editor, { match: isLink });

  const element = {
    type: LINK,
    data: { href },
    children: [{ text: linkText || activeLinkText || href }],
  };

  removeNodes(editor, { match: isLink });
  insertNodes(editor, element);
  /**
   * This line fixes the issue mentioned here: https://github.com/ianstormtaylor/slate/issues/4704
   * We are following the comment here: https://github.com/ianstormtaylor/slate/issues/4704#issuecomment-1006822445
   */
  move(editor, { unit: 'offset' });

  return editor;
};

export default wrapLink;
