import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import Tooltip from 'components/tooltip';
import { Autocomplete } from '@material-ui/lab';
import { FieldHeader } from '../../styled';
import {
  StyledTextField,
  StyledOptionWrapper,
  StyledOption,
  StyledPopper,
  SelectWrapper,
} from './styled';

function SelectField({ fieldModel, value, setValue, disableClearable, noOptionsText, style }) {
  const [selectedValue, setSelectedValue] = useState('');
  const [tooltipPlacement, setTooltipPlacement] = useState('bottom');
  const { label, id } = fieldModel;

  const options = useMemo(() => fieldModel.options ?? [], [fieldModel]);

  useEffect(() => {
    if (value !== selectedValue) {
      const option = options.find((op) => op.value === value);
      if (option) {
        setSelectedValue(option);
      } else {
        setSelectedValue('');
      }
    }
  }, [value, options, selectedValue]);

  const onChange = (_event, newValue, _reason) => {
    setValue(newValue?.value ?? '');
  };

  const onFocus = () => {
    setTooltipPlacement('top');
  };

  const onBlur = () => {
    setTooltipPlacement('bottom');
  };

  const renderInput = (params) => (
    <StyledTextField {...params} variant='filled' placeholder='Select' />
  );

  const renderOption = (member) => (
    <StyledOptionWrapper>
      <StyledOption>{member.title ?? member.value ?? 'Invalid option!'}</StyledOption>
    </StyledOptionWrapper>
  );

  return (
    <Tooltip title={fieldModel.description ?? ''} placement={tooltipPlacement}>
      <SelectWrapper key={id} style={style}>
        <FieldHeader>{label}</FieldHeader>
        <Autocomplete
          disableClearable={disableClearable}
          onFocus={onFocus}
          onBlur={onBlur}
          openOnFocus
          fullWidth
          blurOnSelect
          selectOnFocus={false}
          singleChoice
          noOptionsText={noOptionsText}
          options={options}
          value={selectedValue}
          getOptionSelected={(option, v) => {
            if (!v) return '';
            return option.id === v.id;
          }}
          onChange={onChange}
          renderInput={renderInput}
          renderOption={renderOption}
          PopperComponent={StyledPopper}
          getOptionLabel={(option) => option?.title ?? option?.value}
        />
      </SelectWrapper>
    </Tooltip>
  );
}

SelectField.propTypes = {
  fieldModel: PropTypes.shape({
    type: PropTypes.oneOf(['boolean', 'select', 'text']),
    id: PropTypes.string,
    description: PropTypes.string,
    options: PropTypes.array,
  }),
  value: PropTypes.string,
  setValue: PropTypes.func,
  disableClearable: PropTypes.bool,
  noOptionsText: PropTypes.string,
};

SelectField.defaultProps = {
  fieldModel: null,
  value: null,
  disableClearable: false,
  noOptionsText: 'No options available',
  setValue: () => {},
};

export default SelectField;
