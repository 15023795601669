import styled from '@emotion/styled';
import { Typography, IconButton } from '@material-ui/core';
import { dialogBoxShadow } from 'theme/utils/commonStyles';

export const CloseButton = styled(IconButton)`
  margin-right: 4px;
  :hover {
    svg {
      path {
        fill-opacity: 1;
      }
    }
  }
`;

export const Wrapper = styled('div')`
  ${dialogBoxShadow};
  width: 506px;
  background-color: ${({ theme }) => theme.palette.dina.surfaceCard};
`;

export const Header = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  height: 62px;
`;

export const HeaderLabel = styled(Typography)`
  ${({ theme }) => theme.typography.dina.h5};
  line-height: 28px;
  margin-left: 16px;
`;

export const ContentWrapper = styled('div')`
  margin: 16px;
`;

export const Footer = styled('div')`
  height: 86px;
  border-top: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

export const ButtonsWrapper = styled('div')`
  display: flex;
  flex: 1 1 auto;
  gap: 5px;
  justify-content: flex-end;
`;
