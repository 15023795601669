import styled from '@emotion/styled/macro';
import {
  TimelineConnector as MaterialTimeLineConnector,
  TimelineContent as MaterialTimeLineContent,
  TimelineDot as MaterialTimeLineDot,
  TimelineItem as MaterialTimeLineItem,
  TimelineSeparator as MaterialTimeLineSeparator,
} from '@material-ui/lab';

import transientOptions from 'theme/helpers';

interface TimelineProps {
  $selected: boolean;
  $lastElement?: boolean;
}

export const MarginTop = styled('span')`
  margin-top: 12px;
`;

export const TimelineContent = styled(MaterialTimeLineContent)`
  padding: 0px;
  position: relative;
  left: 0%;
  right: 0%;
  top: 2.56%;
  bottom: 76.92%;
`;

export const TimelineConnector = styled(MaterialTimeLineConnector, transientOptions)<TimelineProps>`
  background: ${({ theme, $selected }) =>
    $selected ? theme.palette.dina.iconInactive : theme.palette.dina.onSelected};
  height: 95%;
  position: relative;
  width: 1px;
  visibility: ${({ $lastElement }) => ($lastElement ? 'hidden' : 'visible')};
`;

export const TimelineConnectorGap = styled(
  MaterialTimeLineConnector,
  transientOptions,
)<TimelineProps>`
  background: ${({ theme, $selected }) =>
    $selected ? theme.palette.dina.iconInactive : theme.palette.dina.onSelected};
  position: relative;
  width: 1px;
  height: 20px;
`;

export const TimelineDot = styled(MaterialTimeLineDot, transientOptions)<TimelineProps>`
  background: ${({ theme, $selected }) =>
    $selected ? theme.palette.dina.iconInactive : theme.palette.dina.onSelected};
  border-width: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export const TimelineItem = styled(MaterialTimeLineItem, transientOptions)<TimelineProps>`
  position: relative;
  left: 0px;
  min-height: 48px;
  cursor: ${({ $selected }) => ($selected ? 'grab' : 'pointer')};
  width: 256px;
  background: ${({ theme, $selected }) => ($selected ? theme.palette.dina.onSelected : '')};
`;

export const TimelineSeparator = styled(MaterialTimeLineSeparator)`
  flex: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: -18px;
`;
