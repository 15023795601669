import { useState } from 'react';

import useFuseSearch from 'hooks/useFuseSearch';
import { useTeams } from 'store/members';

import MemberList from './memberList/MemberList';
import Search from './Search';

function Teams() {
  const search = useFuseSearch();
  const [teams] = useTeams();

  const [searchString, setSearchString] = useState('');
  const searchedTeams = search(teams, ['mTitle'], searchString);
  return (
    <MemberList
      members={searchedTeams}
      title="Teams"
      search={<Search searchString={searchString} setSearchString={setSearchString} />}
    />
  );
}

export default Teams;
