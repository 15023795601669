import React from 'react';
import PropTypes from 'prop-types';

const docTypes = ['.xlsx', '.xls', '.doc', '.docx', '.ppt', '.pptx', '.txt', '.pdf', '.csv'];

const UploadMediaInput = ({ coverPhoto, onClick, onInput, inputRef, disabled }) => (
  <input
    id="upload"
    accept={coverPhoto ? ['image/*'] : ['audio/*', 'video/*', 'image/*', ...docTypes]}
    type="file"
    style={{ display: 'none' }}
    onInput={onInput}
    onClick={onClick}
    ref={inputRef}
    disabled={disabled}
  />
);

UploadMediaInput.propTypes = {
  coverPhoto: PropTypes.bool,
  disabled: PropTypes.bool,
};

UploadMediaInput.defaultProps = {
  coverPhoto: false,
  disabled: false,
};

export default UploadMediaInput;
