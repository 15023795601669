import { MemberTypeEnum } from 'types/graphqlTypes';

import type { PreviewProps } from './utils/childMap';
import { childMap } from './utils/childMap';

/** Factory that renders a child based on the member type. */
export default function ChildFactory({ member, onClose }: Readonly<PreviewProps>) {
  const ChildComponent = childMap[member?.mType as MemberTypeEnum];
  if (!ChildComponent) return null;

  return <ChildComponent member={member} onClose={onClose} />;
}
