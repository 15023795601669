import { useCallback, useRef } from 'react';
import isEqual from 'lodash/isEqual';

/**
 * Custom hook that stabilizes a reference to a value, updating it only when the value changes
 * according to a provided comparator function.
 *
 * `Use cases`
 * Preventing unnecessary effect triggers,
 * Stabilizing complex object references,
 * Memoizing computational results
 *
 * `Performance Considerations`:
 * Use sparingly for large object,
 * Prefer simple primitive comparisons when possible,
 * Consider custom comparison logic for performance-critical paths
 *
 * @template T - The type of the value to be stabilized.
 *
 * @param {T} value - The value to be stabilized.
 * @param {(prev: T, next: T) => boolean} [comparator] - Optional comparator function to determine
 * if the value has changed. If not provided, lodash's `isEqual` is used by default.
 *
 * @returns {T} - The stabilized value.
 *
 * @example
 * const stableValue = useReferenceStabilizer(myValue, (prev, next) => prev.id === next.id);
 */
function useReferenceStabilizer<T>(value: T, comparator?: (prev: T, next: T) => boolean): T {
  const ref = useRef<T>(value);

  // Default comparator uses lodash isEqual
  const defaultComparator = useCallback((prev: T, next: T) => isEqual(prev, next), []);

  const compareValues = comparator || defaultComparator;

  // Only update if values are different
  if (!compareValues(ref.current, value)) {
    ref.current = value;
  }

  // Stable getter to prevent new references
  const getStableValue = useCallback(() => {
    return ref.current;
  }, []);

  return getStableValue();
}

export default useReferenceStabilizer;
