import { elementTypes } from 'components/editor/constants';
import { Transforms } from 'slate';

const { insertNodes } = Transforms;

/**
 * Inserts image element
 *
 * @param {Object} editor SlateJS editor instance
 * @param {Function} update Callback to be invoked on update
 * @returns {Object} SlateJS editor instance
 */

const insertImageBlock = async (editor, update) => {
  const element = {
    type: elementTypes.IMAGE,
    data: {},
    children: [{ text: '' }],
  };

  insertNodes(editor, element);

  update({
    type: 'image-insert',
    payload: {
      document: editor.children,
      insertedElement: element,
    },
  });

  return editor;
};

export default insertImageBlock;
