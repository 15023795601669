import { useMemo } from 'react';

import { TableRowWrapperProps, typedMemo } from '../types';

import { MemoTableRow } from './TableRow';

function TableRowWrapper<T>({
  rows,
  staticRowIndex,
  isSkeletonRow,
  ...rest
}: Readonly<TableRowWrapperProps<T>>) {
  const row = useMemo(
    () => (!isSkeletonRow ? rows[staticRowIndex] : rows[rows.length - 1]),
    [isSkeletonRow, rows, staticRowIndex],
  );

  return (
    <MemoTableRow
      staticRowIndex={staticRowIndex}
      isSkeletonRow={isSkeletonRow}
      row={row}
      {...rest}
    />
  );
}

export const MemoTableRowWrapper = typedMemo(TableRowWrapper);
