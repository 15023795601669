/**
 * Converts datetime to UTC date time string according to source time zone
 *
 * @param {DateTime} dateTime source datetime to be converted to UTC datetime string
 * @param {string} sourceTimeZone IANA timezone string. e.g: Europe/Berlin, Europe/Oslo, Asia/Dhaka.
 * @returns {String} UTC datetime string
 */
const getUTCDateTimeFromSourceTime = (dateTime, sourceTimeZone) => {
  const sourceDateTime = new Date(
    dateTime.toLocaleString('en-US', {
      timeZone: sourceTimeZone,
    }),
  );

  const diff = dateTime.getTime() - sourceDateTime.getTime();

  // Actual local date time for given source date time.
  const localDateTime = new Date(dateTime.getTime() + diff);
  return localDateTime.toISOString();
};

/**
 * returns publishing time for rundown.
 * @param {DateTime} date publishing date of rundown
 * @param {String} startTime start time of rundown in hh:mm:ss format
 * @param {string} sourceTimeZone IANA timezone string. e.g: Europe/Berlin, Europe/Oslo, Asia/Dhaka.
 * @returns {String} publishing time in ISO format.
 */
const getRundownPublishingTime = (date, startTime, timeZone) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  const timeParts = startTime.split(':');

  const publishingDateTime = new Date(
    year,
    month,
    day,
    timeParts[0] || 0,
    timeParts[1] || 0,
    timeParts[2] || 0,
  );
  return getUTCDateTimeFromSourceTime(publishingDateTime, timeZone);
};

export default getRundownPublishingTime;
