import React, { memo, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import InputField from 'components/inputField';
import TextAreaField from 'components/textArea';
import useInputEvents from 'hooks/useInputEvents';
import CheckBox from 'components/checkbox';

import { CheckBoxWrapper, ClickableTextWrapper } from './styled';

const Input = memo(InputField);
const TextArea = memo(TextAreaField);

const Header = ({
  onUpdateBlockMeta,
  initialTitle,
  initialDescription,
  firstItemLeadImage,
  readOnly,
  initialTransitionType,
  direction,
  allowVideoInPhotogallery,
  ...rest
}) => {
  const [title, setTitle] = useState(initialTitle);
  const [description, setDescription] = useState(initialDescription);

  const onUpdateTitle = useCallback(
    (newValue) => {
      if (newValue === initialTitle) return;
      onUpdateBlockMeta('title', title);
    },
    [initialTitle, onUpdateBlockMeta, title],
  );

  const [titleInputRef, handleKeydownOnTitle, handleBlurOnTitle] = useInputEvents(
    onUpdateTitle,
    title,
    initialTitle,
  );

  const onUpdateDescription = useCallback(
    (newValue) => {
      if (newValue === initialDescription) return;
      onUpdateBlockMeta('description', description);
    },
    [initialDescription, onUpdateBlockMeta, description],
  );

  const [descriptionInputRef, handleKeydownOnDescription, handleBlurOnDescription] = useInputEvents(
    onUpdateDescription,
    description,
    initialDescription,
  );

  const preventDrag = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const toggleSetLeadImage = useCallback(() => {
    onUpdateBlockMeta('isCoverphoto', !firstItemLeadImage);
  }, [firstItemLeadImage, onUpdateBlockMeta]);

  useEffect(() => {
    setTitle(initialTitle);
  }, [initialTitle]);

  useEffect(() => {
    setDescription(initialDescription);
  }, [initialDescription]);

  return (
    <Grid container direction="column" draggable onDragStart={preventDrag}>
      <Grid item>
        <Input
          inputRef={titleInputRef}
          type="text"
          usage="editor"
          severity="regular"
          label={`Add a Title to the ${allowVideoInPhotogallery ? '' : 'Photo '}Gallery`}
          value={title}
          onBlur={handleBlurOnTitle}
          onKeyDown={handleKeydownOnTitle}
          onChange={setTitle}
          disabled={readOnly}
          direction={direction}
          {...rest}
        />
      </Grid>
      <Grid item>
        <TextArea
          type="editor"
          severity="regular"
          label={`Add a Description to the ${allowVideoInPhotogallery ? '' : 'Photo '}Gallery`}
          inputRef={descriptionInputRef}
          onChange={setDescription}
          onKeyDown={handleKeydownOnDescription}
          onBlur={handleBlurOnDescription}
          value={description}
          rows={3}
          direction={direction}
        />
      </Grid>
      {allowVideoInPhotogallery ? null : (
        <Grid item>
          <CheckBoxWrapper>
            <CheckBox onClick={toggleSetLeadImage} selected={firstItemLeadImage} size={24} />
            <ClickableTextWrapper onClick={toggleSetLeadImage}>
              Set first image as lead image
            </ClickableTextWrapper>
          </CheckBoxWrapper>
        </Grid>
      )}
    </Grid>
  );
};

Header.propTypes = {
  /** Callable to be called when we update the firstItemLeadImage/transitionType
   * and store it in the backend.
   * */
  onUpdateBlockMeta: PropTypes.func.isRequired,
  /** Initial title of the block */
  initialTitle: PropTypes.string,
  /** Initial Description of the block */
  initialDescription: PropTypes.string,
  /** Boolean to check if the first image should be the lead image or not */
  firstItemLeadImage: PropTypes.bool,
  /** Boolean to check if the editor is in readOnly or not */
  readOnly: PropTypes.bool,
  /** Callable to be called when we update the title/description and store it in the backend. */
  onUpdate: PropTypes.func.isRequired,
  /** String for selected transition type */
  initialTransitionType: PropTypes.string,
};

Header.defaultProps = {
  initialTitle: '',
  initialDescription: '',
  firstItemLeadImage: false,
  readOnly: false,
  initialTransitionType: '',
};

export default memo(Header);
