import { memo } from 'react';

import { elementTypes } from 'components/editor/constants';

import BlockButton from '../blockButton';
import { ButtonGroup } from '../ButtonGroup';

const { GIF, IMAGE, VIDEO } = elementTypes;

interface InstagramToolbarProps {
  extraBlocks: string[] | undefined;
}

const InstagramToolbar = ({ extraBlocks }: InstagramToolbarProps) => {
  return (
    <ButtonGroup>
      <BlockButton type={VIDEO} data={{ showThumbnail: true }} />
      <BlockButton type={IMAGE} />
      <BlockButton type={GIF} />
      {extraBlocks?.map((block) => (
        <BlockButton key={block} type={block} tooltipType={block} />
      ))}
    </ButtonGroup>
  );
};

export default memo(InstagramToolbar);
