import styled from '@emotion/styled';
import Input from '@material-ui/core/Input';

import { ReactComponent as SearchIconSvg } from 'assets/icons/systemicons/search.svg';
import Divider from 'components/divider';
import { LoadingButtonIndicator } from 'components/loadingIndicator';
import { HStack, VStack } from 'layouts/box/Box';

const MUIInput = styled(Input)`
  ${({ theme }) => theme.typography.dina.listItemLabel}
`;

interface SearchProps {
  searchString: string;
  setSearchString: (val: string) => void;
  loading?: boolean;
}

function Search({ searchString, setSearchString, loading }: Readonly<SearchProps>) {
  return (
    <VStack width="100%">
      <HStack width="100%" height="36px" gap="8px" padding="0 8px">
        {loading ? <LoadingButtonIndicator inline size={16} /> : <SearchIconSvg />}
        <MUIInput
          fullWidth
          disableUnderline
          value={searchString}
          placeholder="Type to find"
          onChange={(event) => setSearchString(event.target.value)}
        />
      </HStack>
      <Divider orientation="horizontal" />
    </VStack>
  );
}

export default Search;
