import React from 'react';
import { Editor, NodeEntry, Transforms } from 'slate';

import { elementTypes } from 'components/editor/constants';
import {
  defaultSelection,
  getNodesFromSelection,
  hasRestrictedBlock,
} from 'components/editor/utils/ElementKeyDownUtils';
import { CustomElement } from 'types';
import preventDefaultEvent from 'utils/preventDefaultEvent';

import isCheckList from '../../checkList/isCheckList';
import isSection from '../../sectionDivider/utils/isSection';

const { isEmpty, previous } = Editor;

/**
 * Removes empty element and moves the cursor to the previous position
 */
const removeEmptyElementAndMoveCursor = (
  editor: Editor,
  event: React.KeyboardEvent<HTMLDivElement>,
): void => {
  preventDefaultEvent(event);
  const nextNodeEntry = Editor.next(editor);
  Transforms.removeNodes(editor);
  if (nextNodeEntry) Transforms.move(editor, { reverse: true });
};

/**
 * Handles the behavior of the backspace key when the previous element is a void element.
 */
const preserveVoidOnBackspace = (
  event: React.KeyboardEvent<HTMLDivElement>,
  editor: Editor,
  element: CustomElement,
  isAtStart: boolean,
) => {
  const selectedNodes = getNodesFromSelection(editor);
  if (selectedNodes.length && hasRestrictedBlock(selectedNodes)) {
    preventDefaultEvent(event);
    return;
  }
  if (isEmpty(editor, element)) {
    removeEmptyElementAndMoveCursor(editor, event);
    return;
  }
  if (isAtStart) {
    preventDefaultEvent(event);
    Transforms.move(editor, { reverse: true });
  }
};

/**
 * Handles the behavior of the backspace key when the previous element is a checklist item.
 */
const preserveCheckListOnBackspace = (
  event: React.KeyboardEvent<HTMLDivElement>,
  editor: Editor,
  element: CustomElement,
  isAtStart: boolean,
) => {
  if (isEmpty(editor, element)) {
    removeEmptyElementAndMoveCursor(editor, event);
    return;
  }
  if (isAtStart) {
    preventDefaultEvent(event);
    Transforms.mergeNodes(editor);
    Transforms.setNodes(editor, { type: elementTypes.CHECKLIST });
  }
};

/**
 * Handles the behavior of the backspace key based on the previous element's type.
 *
 * @param editor The `Editor` instance to perform the necessary transformations.
 * @param match The `NodeEntry<CustomElement>` containing the current element and its path.
 * @param shouldPrevent Whether default backspace behavior should be prevented.
 * @param event Backspace key event.
 */

const onBackspace = (
  editor: Editor,
  match: NodeEntry<CustomElement>,
  shouldPrevent: boolean,
  event: React.KeyboardEvent<HTMLDivElement>,
) => {
  const [element, path] = match;
  const previousMatch = previous<CustomElement>(editor, { at: path });
  const [previousElement] = previousMatch ?? [];

  /* If backspace behavior should be prevented or there is no previous element, return */
  if (shouldPrevent || !previousElement) return;

  const selection = editor.selection ?? defaultSelection;
  const isAtStart = Editor.isStart(editor, selection.anchor, path);

  if (Editor.isVoid(editor, previousElement))
    return preserveVoidOnBackspace(event, editor, element, isAtStart);

  if (isCheckList(previousElement))
    return preserveCheckListOnBackspace(event, editor, element, isAtStart);

  if (isSection(previousElement) && isAtStart) {
    preventDefaultEvent(event);
    Transforms.move(editor, { reverse: true });
  }
};

export default onBackspace;
