import styled from '@emotion/styled';

export const StyledTable = styled('table')`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  border-radius: 8px;
  border-spacing: 0;
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  th,
  td {
    border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
    padding-inline: 8px;
  }
`;

export const StyledThead = styled('thead')`
  ${({ theme }) => theme.typography.dina.overline};
  height: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  text-align: left;
  text-transform: uppercase;
  user-select: none;
  background-color: ${({ theme }) => theme.palette.dina.blocksBackgroundResting};
  padding-inline: 8px;
`;

export const StyledTbody = styled('tbody')`
  padding-inline: 8px;
  tr {
    ${({ theme }) => theme.typography.dina.listItemLabel};
    height: 40px;
    color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  }
  tr:last-of-type {
    td {
      border-bottom: none;
    }
  }
`;
