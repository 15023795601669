import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Popover from 'components/popover/Popover';
import EllipsisButton from 'components/buttons/ellipsisButton';

const ItemsWrapper = styled('div')`
  width: ${(props) => `${props.width}px`};
  border: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  border-radius: 6px;
  padding-block: 8px;
  margin-block: -8px;
`;

const EllipsisDropdown = (props) => {
  const { listItems: items, anchorEl, setAnchorEl, IconComponent, width, padding, ...rest } = props;

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <EllipsisButton
        onClick={handleClick}
        IconComponent={IconComponent}
        padding={padding}
        {...rest}
      />
      <Popover anchorEl={anchorEl} onClose={handleClose} position="bottom">
        <ItemsWrapper width={width}>{items}</ItemsWrapper>
      </Popover>
    </>
  );
};

EllipsisDropdown.propTypes = {
  className: PropTypes.string,
  /** Items that should be displayed */
  listItems: PropTypes.arrayOf(PropTypes.element),
  setAnchorEl: PropTypes.func,
  anchorEl: PropTypes.objectOf(PropTypes.object),
  IconComponent: PropTypes.node,
  width: PropTypes.number,
  padding: PropTypes.string,
};

EllipsisDropdown.defaultProps = {
  listItems: [<div key="1" />],
  setAnchorEl: () => {},
  anchorEl: null,
  IconComponent: null,
  width: 360,
  padding: null,
};

export default EllipsisDropdown;
