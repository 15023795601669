import { RundownPermissionsType } from 'types/graphqlTypes';

export const isRundownEditable = ({
  permissions,
  groups,
}: {
  groups?: string[];
  permissions?: RundownPermissionsType | null;
}) => {
  if (!permissions) return true;

  if (!permissions?.write?.length) return false;

  return !!groups?.some((g) => permissions.write.includes(g));
};

export default isRundownEditable;
