import type { CSSProperties } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { TableRow } from 'components/dataTable/Table';
import { MemoTableCell } from 'features/grids/common/components/TableCell';
import { TableRowProps } from 'features/grids/common/types';
import { ParsedMemberType } from 'types';

export function Row({
  row,
  staticRowIndex,
  measureElement,
  start,
  isSkeletonRow,
  rowMeta,
  table,
}: Readonly<TableRowProps<ParsedMemberType>>) {
  // ─── Row Drag And Drop ───────────────────────────────────────────────
  const { attributes, listeners, transform, transition, setNodeRef, isDragging } = useSortable({
    id: row.original.mId,
  });

  const styles: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
    background: isDragging ? '#0A73EB' : 'revert-layer',
    zIndex: isDragging ? 1 : 0,
    position: 'relative',
    outline: row.getIsSelected() ? '2px solid #0A73EB' : 'none',
    boxShadow: isDragging ? '0 5px 10px 0px rgba(0, 0, 0, 0.3)' : 'none',
  };

  return (
    <div ref={setNodeRef} className="dnd-table-row">
      <TableRow
        style={{
          transform: `translateY(${start}px)`,
          left: 0,
          top: 0,
          ...styles,
        }}
        {...attributes}
        {...listeners}
        data-index={staticRowIndex}
        ref={measureElement}
        onDoubleClick={() => rowMeta?.onDoubleClick?.(row.original)}
        onContextMenu={(event) => rowMeta?.onContextMenu?.(event, row.original)}
      >
        {row.getVisibleCells().map((cell, index) => (
          <MemoTableCell
            key={cell.id}
            cell={cell}
            isSkeletonRow={isSkeletonRow}
            row={row}
            table={table}
            staticColumnIndex={index}
          />
        ))}
      </TableRow>
    </div>
  );
}
