/* eslint-disable sort-imports */
import { DragOverlay as DNDDragOverlay } from '@dnd-kit/core';

interface Props {
  activeId: string;
  containers: string[];
  renderContainerDragOverlay: (activeId: string) => React.ReactNode;
  renderSortableItemDragOverlay: (activeId: string) => React.ReactNode;
}

export default function DragOverlay({
  activeId,
  containers,
  renderContainerDragOverlay,
  renderSortableItemDragOverlay,
}: Props) {
  if (!activeId) {
    return null;
  }

  return (
    <DNDDragOverlay>
      {containers?.includes(activeId)
        ? renderContainerDragOverlay(activeId)
        : renderSortableItemDragOverlay(activeId)}
    </DNDDragOverlay>
  );
}
