import styled from '@emotion/styled/macro';
import { ReactComponent as LocationIconComponent } from 'assets/icons/systemicons/location_off.svg';
import { ReactComponent as MapIconComponent } from 'assets/icons/systemicons/location.svg';

export const LocationIcon = styled(LocationIconComponent)`
  width: 24px;
  height: 24px;
  margin: 8px;
`;

export const ChildrenWrapper = styled('div')`
  width: 100%;
  margin-left: 8px;
`;

export const MapIcon = styled(MapIconComponent)`
  width: 88px;
  height: 88px;
`;

export const ContentWrapper = styled('div')`
  display: flex;
`;
