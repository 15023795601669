import { useEffect, useMemo, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import { useGetOrderForms } from 'api/order_forms/useGetOrderForms';
import { StyledPopper } from 'components/addMember/styled';
import { StyledTextField } from 'components/mdfEditor/fields/text/styled';
import Text from 'components/text/Text';
import { MemberTypeEnum } from 'types/graphqlTypes';

import { MdfDropdownWrapper, OptionWrapper, SelectWrapper } from './styled';

export type ConfigType = {
  id: string;
  label: string;
  type: MemberTypeEnum;
  resourceId: string;
};

export interface EditorProps {
  value: string | null;
  onSelect?: (selected: string | null) => void;
  onSelectConfig?: (selected: ConfigType | null) => void;
  style?: React.CSSProperties;
  error?: boolean;
  disableClearable?: boolean;
  hideLabel?: boolean;
  placeholder?: string;
  disabled?: boolean;
}

function ConfigDropdown({
  value,
  onSelect,
  onSelectConfig,
  style,
  error,
  disableClearable,
  placeholder,
  hideLabel,
  disabled,
}: Readonly<EditorProps>) {
  const [selectedValue, setSelectedValue] = useState<ConfigType | null>(null);
  const { mdfs } = useGetMdfs({ all: true });
  const { orderForms } = useGetOrderForms();

  const options: ConfigType[] = useMemo(() => {
    const mappedMdfs = mdfs.map(({ id, label }) => ({
      id,
      label,
      type: MemberTypeEnum.Mdf,
      resourceId: id,
    }));
    const mappedOrderForms = orderForms
      .filter(
        ({ configs }) =>
          !!configs?.find((config) => config.key === 'types')?.values?.includes('story'),
      )
      .map(({ mRefId, mTitle, mDescription, mSecId }) => ({
        id: mRefId,
        label: `${mTitle} > ${mDescription}`,
        type: MemberTypeEnum.OrderForm,
        resourceId: mSecId ?? '',
      }));

    return [...mappedOrderForms, ...mappedMdfs];
  }, [mdfs, orderForms]);

  useEffect(() => {
    if (value !== selectedValue?.id) {
      const option = options.find((m) => m.id === value);
      if (option) {
        setSelectedValue(option);
      } else {
        setSelectedValue(null);
      }
    }
  }, [value, options, selectedValue]);

  const renderInput = (params: object) => (
    <StyledTextField
      {...params}
      variant="filled"
      error={error}
      placeholder={placeholder ?? 'Select configuration'}
    />
  );

  const renderOption = (option: ConfigType) => (
    <OptionWrapper>
      <Text variant="listItemLabel" color="highEmphasis">
        {option.label}
      </Text>
    </OptionWrapper>
  );

  return (
    <MdfDropdownWrapper style={style}>
      {!hideLabel && (
        <Text variant="overline">
          {disabled ? 'Selected configuration' : 'Select a configuration'}
        </Text>
      )}
      <SelectWrapper>
        <Autocomplete
          openOnFocus
          fullWidth
          blurOnSelect
          disabled={disabled}
          disableClearable={disableClearable}
          noOptionsText="No configuration available"
          selectOnFocus={false}
          options={options}
          value={selectedValue}
          getOptionSelected={(u, v) => u.id === v.id}
          groupBy={(option: ConfigType) => {
            if (option.type === MemberTypeEnum.Mdf) return 'Schema';
            if (option.type === MemberTypeEnum.OrderForm) return 'Order form';
            return option.type;
          }}
          onChange={(_ev, changedValue) => {
            if (onSelect) {
              onSelect(changedValue?.id ?? null);
            }
            if (onSelectConfig) {
              onSelectConfig(changedValue);
            }
          }}
          renderInput={renderInput}
          popupIcon={disabled && null}
          PopperComponent={StyledPopper}
          renderOption={renderOption}
          getOptionLabel={(option) => option?.label}
        />
      </SelectWrapper>
    </MdfDropdownWrapper>
  );
}

export default ConfigDropdown;
