import { memo, useCallback } from 'react';
import Picker from '@emoji-mart/react';
import styled from '@emotion/styled';
import { ReactEditor, useSlate } from 'slate-react';

import Popover from 'components/dialogs/PopoverBuilder';
import { useDinaTheme, useEmojiData } from 'store';
import { EmojiType } from 'types/editor';

import { Arrow, EmojiButton, EmojiIcon } from './styled';

const PopoverContent = styled(Popover.Content)`
  padding: 0;
  min-width: 0;
  & > * {
    padding-inline: 0px;
  }
`;

function EmojiPicker() {
  const [emojiData] = useEmojiData();
  const [theme] = useDinaTheme();
  const editor = useSlate();

  const onEmojiSelect = useCallback(
    (emoji: EmojiType) => {
      if (emoji.native) editor.insertText(emoji.native);
      ReactEditor.focus(editor as ReactEditor);
    },
    [editor],
  );

  return (
    <Popover>
      <Popover.Trigger asChild>
        <EmojiButton role="presentation">
          <EmojiIcon className="skipOverride" />
          <Arrow className="skipOverride" />
        </EmojiButton>
      </Popover.Trigger>
      <PopoverContent side="top" sideOffset={12} align="center">
        <Picker
          autoFocus
          data={emojiData}
          theme={theme === 'system' ? 'auto' : theme}
          skinTonePosition="search"
          perLine={8}
          onEmojiSelect={onEmojiSelect}
          previewPosition="none"
        />
      </PopoverContent>
    </Popover>
  );
}

export default memo(EmojiPicker);
