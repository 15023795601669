import { Dispatch, SetStateAction, useState } from 'react';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';

import useGetUsers from 'api/userManagement/useGetUsers';
import useUpdateUser from 'api/userManagement/useUpdateUser';
import useUserStateChange from 'api/userManagement/useUserStateChange';
import { ReactComponent as SSOUser } from 'assets/icons/systemicons/aws_singleSignOn.svg';
import { DeleteDialog, WarningDialog } from 'components/dialogs/CommonDialogs';
import LoadingIndicator from 'components/loadingIndicator';
import SearchbarPositions from 'components/searchTable/constants/searchbarPositions';
import SearchTableView from 'components/searchTable/searchTable-view';
import useToast from 'components/toast/useToast';
import { MemberType } from 'types/graphqlTypes';
import { isAtLeastOneYearBefore, isoToLocaleShort } from 'utils/datetimeHelpers';
import { getParseMetadata } from 'utils/getParsedMetadata';

import Actions from './components/actions';
import Groups from './components/group/Groups';
import columns from './constants/columns';

import { BlankDiv, EmailWrapper, ErrorWrapper, RootWrapper } from './styled';

interface QueryUsersList {
  getUsers: MemberType[];
}

interface RowType {
  action: EmotionJSX.Element;
  rowId: string;
  searchByEmail: string;
  searchByGroup: string;
  email: EmotionJSX.Element;
  status: string;
  groups: EmotionJSX.Element;
  lastLogin: string | Date;
  sortByDate?: string;
}

interface UserValues {
  id: string;
  mRefId: string;
  username: string;
  email: string;
  notListed: boolean;
}

interface UserListProps {
  groupPolicies: MemberType[];
}

const getRows = (
  data: QueryUsersList,
  groupsArray: Record<string, string>[],
  setOpen: Dispatch<SetStateAction<boolean>>,
  setOpenUserHideDialog: Dispatch<SetStateAction<boolean>>,
  setValues: Dispatch<SetStateAction<UserValues | undefined>>,
) => {
  const Users = data?.getUsers;
  const rows: Array<RowType> = [];
  Users?.forEach((item) => {
    const { mId, user, mRefId, mMetaData, mLastLogin, metadata } = item;
    if (!user || !mId || !mRefId || !mMetaData) return rows;
    const groups = mMetaData.find((attr) => attr.key === 'groups')?.value ?? '';
    const { status } = user;
    const parsedMetadata = getParseMetadata(metadata);
    const { username, notListed, email } = parsedMetadata ?? {};
    if (typeof email !== 'string' || typeof username !== 'string') return rows;
    const isHidden = Boolean(notListed);
    rows.push({
      rowId: mId,
      searchByEmail: email,
      searchByGroup: groups,
      email: (
        <EmailWrapper>
          {status === 'EXTERNAL_PROVIDER' ? <SSOUser /> : <BlankDiv />}
          {email}
        </EmailWrapper>
      ),
      status: status ?? 'NOT_SPECIFIED',
      sortByDate: mLastLogin,
      lastLogin: mLastLogin
        ? isoToLocaleShort(mLastLogin, isAtLeastOneYearBefore(mLastLogin))
        : 'Not logged in yet',
      groups: (
        <Groups
          userAssignedGroups={groups}
          groupsArray={groupsArray}
          username={username}
          mId={mId}
          mRefId={mRefId}
        />
      ),
      action: (
        <Actions
          setOpen={setOpen}
          setValues={setValues}
          setOpenUserHideDialog={setOpenUserHideDialog}
          isHidden={isHidden}
          mId={mId}
          mRefId={mRefId}
          username={username}
          email={email}
        />
      ),
    });
  });
  return rows;
};

const UsersList = ({ groupPolicies }: UserListProps) => {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState<UserValues | undefined>(undefined);

  const [openUserHideDialog, setOpenUserHideDialog] = useState(false);

  const [updateUserStatus, loadingStateChange] = useUserStateChange();
  const [updateUser, loadingUpdate] = useUpdateUser();
  const { data, error, loading: fetching } = useGetUsers('user');
  const { errorToast } = useToast();

  const onConfirmDisable = async () => {
    const { id, username, mRefId } = values ?? {};
    if (id && username && mRefId)
      await updateUserStatus('disable', id, mRefId, username, setOpen, errorToast);
  };

  const onConfirmHide = async () => {
    const { id, mRefId, notListed } = values ?? {};
    if (id && mRefId && typeof notListed === 'boolean') {
      const params = {
        mId: id,
        mRefId,
        notListed: !notListed,
        setOpenDialog: setOpenUserHideDialog,
        errorToast,
      };
      await updateUser(params);
    }
  };
  const groupsArray = groupPolicies.map((group) => ({
    value: group.mRefId ?? '',
    title: group.mTitle ?? '',
  }));

  const generateConfirmationMessage = () => {
    const action = values?.notListed ? 'expose' : 'hide';
    const preposition = values?.notListed ? 'to' : 'from';
    // eslint-disable-next-line max-len
    return `Are you sure you want to ${action} the user "${values?.email}" ${preposition} user list?`;
  };

  const disableText = `Are you sure you want to disable the user "${values?.email}"? 
  User can not use Dina after disable`;

  if (data) {
    const rows = getRows(data, groupsArray, setOpen, setOpenUserHideDialog, setValues);
    return (
      <RootWrapper>
        <SearchTableView
          usageType="settings"
          columns={columns}
          rows={rows}
          defaultSelect={false}
          showTitle={false}
          showSearchIcon
          searchbarPosition={SearchbarPositions.TOP}
          selectable={false}
          tabs={undefined}
          setSelectedItems={undefined}
          timelineItems={undefined}
          storyInformationForTimeline={undefined}
          resourceTimelineDropdownItems={undefined}
          selectedType={undefined}
          onChangeType={undefined}
          showAvailableResourceOnly={undefined}
          setShowAvailableResourceOnly={undefined}
          shouldDisableTypeFilter={undefined}
        />
        <DeleteDialog
          open={open}
          onClose={() => setOpen(false)}
          onClick={onConfirmDisable}
          title="Disable User"
          confirmLabel="Disable"
          message={disableText}
          loading={loadingStateChange}
        />

        <WarningDialog
          open={openUserHideDialog}
          onClose={() => setOpenUserHideDialog(false)}
          onClick={onConfirmHide}
          title={values?.notListed ? 'Expose User' : 'Hide User'}
          message={generateConfirmationMessage()}
          confirmLabel="Yes"
          loading={loadingUpdate}
        />
      </RootWrapper>
    );
  }

  if (fetching) return <LoadingIndicator />;
  if (error || !data) return <ErrorWrapper>Error loading active users</ErrorWrapper>;
  return null;
};

export default UsersList;
