import styled from '@emotion/styled/macro';
import { ReactComponent as GifIconComponent } from 'assets/icons/systemicons/editor/gif_off.svg';

export const GifIcon = styled(GifIconComponent)`
  width: 24px;
  height: 24px;
  margin: 8px;
  path {
    fill: ${({ theme }) => theme.palette.dina.blackMediumEmphasis};
    fill-opacity: 1;
  }
`;

export const ContentWrapper = styled('div')`
  width: 100%;
  margin-bottom: 10px;
`;
export const GifWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

export const Image = styled('img')`
  height: 80px;
  width: 80px;
  border-radius: 2px;
  margin-right: 84px;
`;

export const ProgressBarWrapper = styled('div')`
  margin-top: 10px;
`;
