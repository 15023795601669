import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSlate } from 'slate-react';
import styled from '@emotion/styled';
import { useEditorContext } from 'components/editor/EditorContext';
import { ReactComponent as QuoteIconComponent } from 'assets/icons/systemicons/editor/blocks_plain_quote_off.svg';
import { removeBlock, updateBlock } from 'components/editor/utils';
import MetadataEditor from 'components/metadataEditor';
import { elementTypes } from 'components/editor/constants/types';
import useChangeCollapse from 'components/editor/hooks/useChangeCollapse';
import InputGroup from '../inputGroup';
import Box from '../box';
import DragAndDrop from '../dragAndDrop';

export const QuoteIcon = styled(QuoteIconComponent)`
  margin: 8px;
`;

export const ContentWrapper = styled('div')`
  margin-bottom: 16px;
`;

const QuoteBox = ({ attributes, children, element, readOnly, direction }) => {
  const { data, type } = element;
  const editor = useSlate();
  const { update, formsForThisPlatform, platformId } = useEditorContext();

  const [onChangeCollapse] = useChangeCollapse(element);

  const { quote, byline, metadata, collapsed = false } = data || {};

  const handleQuoteValueChange = useCallback(
    (newQuoteValue) => {
      const updatedData = {
        ...data,
        quote: newQuoteValue,
      };
      updateBlock(editor, element, updatedData, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  );

  const updateMetadata = useCallback(
    (newValue, metaPropName) => {
      const updatedMetadata = metadata;
      const updatedData = {
        ...data,
        metadata: {
          ...updatedMetadata,
          [metaPropName]: newValue,
        },
      };

      updateBlock(editor, element, updatedData, update);
    },
    [data, editor, element, metadata, update],
  );

  const handleBylineValueChange = useCallback(
    (newBylineValue) => {
      const updatedData = {
        ...data,
        byline: newBylineValue,
      };
      updateBlock(editor, element, updatedData, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  );

  const onMenuSelect = useCallback(
    ({ action }) => {
      if (action === 'delete-block') removeBlock(editor, element, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const blockForm = formsForThisPlatform?.[type];
  const renderQuote = useMemo(() => {
    const quoteInputProps = {
      disableLabel: true,
      onChange: handleQuoteValueChange,
      value: quote || '',
      placeholder: 'Type Quote text here',
      direction,
    };

    const bylineInputProps = {
      disableLabel: true,
      onChange: handleBylineValueChange,
      value: byline || '',
      placeholder: 'Type Byline here',
      direction,
    };

    const inputProps = [quoteInputProps, bylineInputProps];

    return (
      <Box
        iconComponent={<QuoteIcon className="skipOverride" />}
        title="Quote"
        boxType={type}
        platformId={platformId}
        readOnly={readOnly}
        collapsed={collapsed}
        collapsedContent={quote}
        updateCollapsed={onChangeCollapse}
        hideEllipsisButton
        onMenuSelect={onMenuSelect}
      >
        <ContentWrapper>
          <InputGroup readOnly={readOnly} inputProps={inputProps} />
          {blockForm && (
            <MetadataEditor
              model={blockForm}
              setPayload={(u) => updateMetadata(u.value, u.fieldId)}
              payload={metadata}
            />
          )}
        </ContentWrapper>
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    byline,
    collapsed,
    blockForm,
    handleBylineValueChange,
    handleQuoteValueChange,
    onMenuSelect,
    quote,
    readOnly,
    onChangeCollapse,
  ]);

  return (
    <div {...attributes}>
      <DragAndDrop element={element}>
        {children}
        {renderQuote}
      </DragAndDrop>
    </div>
  );
};

QuoteBox.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

QuoteBox.defaultProps = {
  attributes: {},
  children: null,
  element: {
    children: [],
    data: {},
    type: elementTypes.QUOTE_BOX,
  },
};

export default memo(QuoteBox);
