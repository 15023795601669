import { SyncProviderType } from 'types/graphqlTypes';

const mimirOpenFolder = {
  action: 'open_folder',
  payload: 'folder-id',
  destination: 'MIMIR',
};

const mimirWorkfolder = {
  action: 'set_work_folder',
  payload: 'folder-id',
  destination: 'MIMIR',
};

const syncSyncProviders = (syncProviders: SyncProviderType[] | null | undefined) => {
  if (!syncProviders) return;
  const mimirProvider = syncProviders.find((sp) => sp.provider === 'Mimir');
  if (!mimirProvider || !mimirProvider.mMetaData) return;

  const { mMetaData } = mimirProvider;
  const folderId = mMetaData.find((keyValue) => keyValue.key === 'folderId');
  if (!folderId) return;

  const messageStringOpenFolder = {
    ...mimirOpenFolder,
    payload: folderId.value,
  };

  const messageStringSetWorkFolder = {
    ...mimirWorkfolder,
    payload: folderId.value,
  };

  const mimirIFrame = document.getElementById('mimir') as HTMLIFrameElement;
  if (mimirIFrame?.contentWindow) {
    mimirIFrame.contentWindow.postMessage(messageStringOpenFolder, '*');
  }
  if (mimirIFrame?.contentWindow) {
    mimirIFrame.contentWindow.postMessage(messageStringSetWorkFolder, '*');
  }
};

export default syncSyncProviders;
