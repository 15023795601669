import { FocusEventHandler } from 'react';
import styled from '@emotion/styled';

import transientOptions from 'theme/helpers';

interface WrapperProps {
  $color?: string;
  $defaultColor?: string;
}
// Color circle
export const ColorButtonWrapper = styled('span', transientOptions)<WrapperProps>`
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  cursor: pointer;
  background-color: ${({ $color, theme, $defaultColor }) => {
    if ($color) return $color;
    if ($defaultColor) return $defaultColor;
    return theme.palette.dina.borderHover;
  }};
  border: 1px solid ${({ theme }) => theme.palette.dina.buttonBorderOutlined};
  outline: none;
  border-radius: 50%;
  :focus {
    outline: 2px solid ${({ theme }) => theme.palette.dina.statusOnFocused};
  }
  :focus-visible {
    outline: 2px solid ${({ theme }) => theme.palette.dina.statusOnFocused};
  }
`;

// Brings up the color input
export const Input = styled('input')`
  width: 100%;
  height: 100%;
  opacity: 0;
  position: relative;
  top: -6px;
  cursor: pointer;
`;

interface ColorInputProps {
  color?: string;
  onChange?: (value: string) => void;
  className?: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  defaultColor?: string;
}

function ColorInput({
  color,
  defaultColor,
  onChange,
  onBlur,
  className,
}: Readonly<ColorInputProps>) {
  return (
    <ColorButtonWrapper
      $color={color}
      $defaultColor={defaultColor}
      tabIndex={0}
      className={className}
    >
      {onChange ? (
        <Input
          type="color"
          value={color ?? defaultColor}
          onChange={(e) => onChange(e.currentTarget.value)}
          onBlur={onBlur}
        />
      ) : null}
    </ColorButtonWrapper>
  );
}

export default ColorInput;
