import gql from 'graphql-tag';

export default gql`
  query GetSummary($input: GetSummaryType) {
    getSummary(input: $input) {
      mContent
      mSummarizeLines
      mSummarize
    }
  }
`;
