import { useMemo } from 'react';
import type { Table } from '@tanstack/react-table';

import { ReactComponent as SearchIcon } from 'assets/icons/systemicons/search.svg';
import { InputField } from 'components/input/InputField';
import { DataTableFilterField } from 'features/grids/common/types';
import { Flex, HStack } from 'layouts/box/Box';

import { ClearButton } from './ClearButton';
import { DataTableColumnFilter } from './ColumnFilter';
import { DataTableViewOptions } from './ViewOptions';

import { Toolbar } from './styled';

interface DataTableToolbarProps<TData> extends React.HTMLAttributes<HTMLDivElement> {
  table: Table<TData>;
  filterFields?: DataTableFilterField<TData>[];
}

export function DataTableToolbar<TData>({
  children,
  table,
  filterFields = [],
}: Readonly<DataTableToolbarProps<TData>>) {
  const isFiltered = table.getState().columnFilters.length > 0;

  const { searchableColumns, filterableColumns } = useMemo(() => {
    return {
      searchableColumns: filterFields.filter((field) => !field.options),
      filterableColumns: filterFields.filter((field) => field.options),
    };
  }, [filterFields]);

  return (
    <Toolbar>
      <HStack flex="1" gap="4px">
        {searchableColumns.length > 0 &&
          searchableColumns.map(
            (column) =>
              table.getColumn(column.value ? String(column.value) : '') && (
                <Flex key={String(column.value)} maxWidth="560px" width="560px">
                  <InputField
                    height={32}
                    startAdornment={<SearchIcon className="skipOverride" />}
                    placeholder={column.placeholder}
                    value={
                      (table.getColumn(String(column.value))?.getFilterValue() as string) ?? ''
                    }
                    onChange={(event) =>
                      table.getColumn(String(column.value))?.setFilterValue(event.target.value)
                    }
                  />
                </Flex>
              ),
          )}
        {filterableColumns.length > 0 &&
          filterableColumns.map(
            (column) =>
              table.getColumn(column.value ? String(column.value) : '') && (
                <DataTableColumnFilter
                  key={String(column.value)}
                  column={table.getColumn(column.value ? String(column.value) : '')}
                  title={column.label}
                  options={column.options ?? []}
                />
              ),
          )}

        {isFiltered && <ClearButton table={table} />}
      </HStack>
      <Flex gap="4px" overflow="visible">
        {children}
        <DataTableViewOptions table={table} />
      </Flex>
    </Toolbar>
  );
}
