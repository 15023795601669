import { useDrop } from 'react-dnd';

import RenderWidget from 'features/widget/components/RenderWidget';
import { ICreateWidgetMenu } from 'features/widget/CreateWidgetMenu';
import { useMoveWidget } from 'screens/space/store/widgets';
import { WidgetType } from 'screens/space/types/space';

import { LayoutProps, SpaceViewLayout } from './layouts';
import PlaceholderLayout from './PlaceholderLayout';

import { StyledWrapper } from './styled';

type PanelContentProps = Omit<LayoutProps, 'scrollRef' | 'widgets'> & {
  index: number;
  layout: SpaceViewLayout;
  widget: WidgetType | undefined;
  setCreateWidget: (val: ICreateWidgetMenu) => void;
};

export const PanelContent = ({
  index,
  layout,
  widget,
  getWidgetProperties,
  setCreateWidget,
  federatedSearchString,
  filterByDate,
  selectedDate,
}: PanelContentProps) => {
  const moveWidget = useMoveWidget();

  const [{ isOver }, drop] = useDrop({
    accept: ['WIDGET_DRAG'],
    drop(p: { id: string }) {
      if (p.id !== widget?.mId) {
        moveWidget({ id: p.id, targetIdOrIndex: widget?.mRefId ?? index });
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  if (!widget) {
    return (
      <PlaceholderLayout
        ref={(ref) => drop(ref)}
        isOver={isOver}
        onClick={(ev) => setCreateWidget({ show: true, x: ev.pageX, y: ev.pageY, index })}
      />
    );
  }

  return (
    <StyledWrapper ref={(ref) => drop(ref)} $isOver={isOver}>
      <RenderWidget
        key={widget.mId}
        type={widget.type}
        config={widget.config}
        properties={getWidgetProperties({
          layout,
          type: widget.type,
          selectedDate: filterByDate ? selectedDate : undefined,
          federatedSearchString:
            federatedSearchString && federatedSearchString.length > 0
              ? federatedSearchString
              : undefined,
          widget: widget,
        })}
      />
    </StyledWrapper>
  );
};
