import gql from 'graphql-tag';
import { getSignedUrl } from 'utils/s3Utils';

const GET_RUNDOWN = gql`
  query GetRundown($input: GetRundownInput) {
    getRundown(input: $input) {
      mId
      mType
      mRundownTemplateId
    }
  }
`;

const getRundownThumbnailKey = (rundown) => {
  const { mRundownTemplateId } = rundown;
  return `${mRundownTemplateId}/media/thumb_${mRundownTemplateId}.jpg`;
};

/**
 * For linear instance rundown thumbnail is default in story view.
 * If rundown thumbnail is not set then display the story thumbnail.
 */
const getInstanceThumbnailKey = async (instance, linearVariant, client) => {
  const { mStoryId, mTemplateId, mProperties } = instance;
  const { platform, account } = mProperties;

  const storyThumbnailKey =
    mStoryId && !mTemplateId ? `${mStoryId}/media/thumb_${mStoryId}.jpg` : null;

  if (platform !== 'linear' || linearVariant !== 'rundown') {
    return storyThumbnailKey;
  }

  const { accountId } = account;

  if (!accountId) return storyThumbnailKey;

  try {
    const result = await client.query({
      query: GET_RUNDOWN,
      variables: { input: { mId: accountId, mRefId: accountId } },
    });

    const { data } = result;
    const { getRundown: rundown } = data;
    const key = getRundownThumbnailKey(rundown);

    // instance belongs to rundown.
    if (accountId === mStoryId) return key;

    // check if the rundown thumbnail is valid. If not send
    // the parent story thumbnail
    const url = await getSignedUrl(key, true);
    if (!url) return storyThumbnailKey;

    return key;
  } catch (e) {
    return storyThumbnailKey;
  }
};

/**
 * Returns the S3 bucket key for the given entity
 * @param {*} entity [story,instance]
 */
const getThumbnailKey = async (entity, linearVariant, client) => {
  switch (entity.mType) {
    case 'rundown':
      return getRundownThumbnailKey(entity);
    case 'instance':
      return getInstanceThumbnailKey(entity, linearVariant, client);
    default:
      return `${entity.mId}/media/thumb_${entity.mId}.jpg`;
  }
};

const getThumbnailUrl = async (parent, args, { client }) => {
  try {
    const { mThumbnailUrl, mType } = parent;
    if (mThumbnailUrl || mType === 'instance') {
      return mThumbnailUrl;
    }

    const { linearVariant } = args || {};
    const key = await getThumbnailKey(parent, linearVariant, client);

    if (!key) return null;
    const url = await getSignedUrl(key);

    return url;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }

  return null;
};

// resolves cover photo / avatar url from s3
const getCoverPhotoUrl = async (parent, args, { client }) => {
  try {
    const { mType, mId, mAvatarUrl, mRundownTemplateId, mCoverPhotoUrl } = parent;
    if (
      ![
        'story',
        'pitch',
        'user',
        'contact',
        'team',
        'department',
        'rundown',
        'rundowntemplate',
      ].includes(mType)
    )
      return null;

    if (mAvatarUrl || mCoverPhotoUrl) return mAvatarUrl || mCoverPhotoUrl;

    const id = mType === 'rundown' ? mRundownTemplateId : mId;
    const key = `${id}/media/${id}.jpg`;
    return await getSignedUrl(key);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }

  return null;
};

export { getThumbnailUrl, getCoverPhotoUrl };
