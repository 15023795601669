import { useMutation } from '@apollo/client';
import CREATE_PLACEHOLDER from 'operations/mutations/createPlaceholder';
import { useCallback } from 'react';

const useCreatePlaceholder = () => {
  const [createPlaceholder] = useMutation(CREATE_PLACEHOLDER);
  const createStoryPlaceholder = useCallback(async (storyId, data) => {
    const { account } = data.mProperties;
    const mProperties = {
      __typename: 'PlatformType',
      platform: data.mProperties.platform,
      account: {
        accountUrl: account.accountUrl,
        accountLogo: account.accountLogo,
        accountTitle: account.accountTitle,
        accountId: account.accountId,
      },
    };
    const mMetaData = data.exportFormat
      ? [
          {
            key: 'exportFormat',
            value: data.exportFormat,
          },
        ]
      : [];
    const input = {
      mId: storyId,
      mTitle: data.mTitle,
      itemType: data.itemType ?? 'video', // default should be video for linear instance
      mMetaData,
      mProperties,
    };
    const reposnse = await createPlaceholder({
      variables: { input },
    });
    return reposnse.data.createPlaceholder;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [createStoryPlaceholder];
};

export default useCreatePlaceholder;
