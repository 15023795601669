import { memo } from 'react';
import PropTypes from 'prop-types';
import { v1 as uuidv1 } from 'uuid';

import { publishingPoints as publishingPointIcons } from 'assets/icons/publishingPoints';
import { ReactComponent as ArrowRightSmall } from 'assets/icons/systemicons/arrows/disclosurearrow_discreet_right.svg';
import fallbackImage from 'assets/images/default/defaultThumbnail.png';
import RundownIcon, { iconUsage } from 'components/rundownIcons';
import { Item } from 'types/graphqlTypes';
import { onImageLoadingError } from 'utils/image/imageUtils';

import {
  Avatar,
  CenterContent,
  Divider,
  Icons,
  PublishingPointImage,
  ThumbnailImage,
  TimingDiv,
  Title,
  Wrapper,
} from './styled';

interface InstanceItemProps {
  title: string;
  thumbnail?: string | null;
  showFocus: boolean;
  items?: Item[];
  timingInfo: string;
  showDivider: boolean;
  selected: boolean;
  isFloat: boolean;
  platformKind?: string;
}

function InstanceItem({
  title,
  thumbnail,
  showFocus,
  items,
  timingInfo,
  showDivider,
  selected,
  isFloat,
  platformKind,
}: Readonly<InstanceItemProps>) {
  return (
    <Wrapper $showFocus={showFocus} $selected={selected} $isFloat={isFloat}>
      <Avatar>
        <ThumbnailImage
          src={thumbnail ?? fallbackImage}
          onError={onImageLoadingError}
          alt="rnd_thmb"
        />
        <PublishingPointImage
          src={platformKind ? publishingPointIcons[platformKind] : publishingPointIcons.linear}
          alt="publishing-point"
        />
      </Avatar>
      <CenterContent>
        <Title>{title}</Title>
        <Icons>
          {(items?.length ?? 0) > 0 &&
            items?.map((item) => (
              <RundownIcon
                key={uuidv1()}
                variant={item.title!}
                width={16}
                height={16}
                usage={iconUsage.META}
                iconUrl={undefined}
              />
            ))}
        </Icons>
      </CenterContent>
      <TimingDiv>
        {timingInfo}
        <ArrowRightSmall />
      </TimingDiv>
      {showDivider && <Divider />}
    </Wrapper>
  );
}

InstanceItem.propTypes = {
  /** Instance title */
  title: PropTypes.string,
  /** Url for thumbnail */
  thumbnail: PropTypes.string,
  /** automation items for that instance */
  items: PropTypes.arrayOf(PropTypes.object),
  /** last updated time */
  timingInfo: PropTypes.string,
  /** float status of the instance */
  isFloat: PropTypes.bool,
};

InstanceItem.defaultProps = {
  title: '',
  thumbnail: fallbackImage,
  items: [],
  timingInfo: '',
  isFloat: false,
};

export default memo(InstanceItem);
