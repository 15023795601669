import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ReactComponent as VideoIconComponent } from 'assets/icons/systemicons/editor/video_off.svg';
import { ReactComponent as ImageIconComponent } from 'assets/icons/systemicons/editor/photo_off.svg';

export const ContentWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  > * {
    margin: 0px 8px 8px 0px;
  }
  margin-bottom: 8px;
`;

export const ThumbnailWrapper = styled('div')`
  border-radius: 4px;
  overflow: hidden;
`;

export const IconWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: 140px;
`;

const iconStyle = ({ theme }) => css`
  margin: 8px;
  path {
    fill: ${theme.palette.dina.blackMediumEmphasis};
    fill-opacity: 1;
  }
`;

export const VideoIcon = styled(VideoIconComponent)`
  ${iconStyle}
`;
export const ImageIcon = styled(ImageIconComponent)`
  ${iconStyle}
`;
