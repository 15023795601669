import { createContext } from 'react';

import memberTypes from 'operations/memberTypes';
import { User, UserMProperties } from 'types';
import { MemberTypeEnum } from 'types/graphqlTypes';

interface UserContextProps {
  mId: string;
  mRefId: string;
  mType: MemberTypeEnum;
  avatarUrl: string;
  mTitle: string;
  bookmarks: string[];
  mProperties: UserMProperties;
  groups: string[];
  attributes?: User;
}

const UserContext = createContext<UserContextProps>({
  mId: '',
  mRefId: '',
  mType: memberTypes.USER as MemberTypeEnum,
  avatarUrl: '',
  mTitle: 'user',
  groups: [],
  bookmarks: [],
  mProperties: { firstName: '', surname: '', email: '' },
});
export default UserContext;
