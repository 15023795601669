import { memo } from 'react';
import styled from '@emotion/styled';
import { RenderLeafProps } from 'slate-react';

interface LeafProps {
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  strikeThrough?: boolean;
  uppercase?: boolean;
}
const LeafWrapper = styled('span')<LeafProps>`
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
  text-decoration: ${({ underline, strikeThrough }) => {
    if (underline) return 'underline';
    if (strikeThrough) return 'line-through';
    return 'none';
  }};
  text-shadow: ${({ color }) => {
    if (color?.includes('ffffff')) return '1px 1px #000';
    return 'none';
  }};
  color: ${({ color }) => color ?? 'inherit'};
`;

function Leaf({ attributes, children = null, leaf }: Readonly<RenderLeafProps>) {
  return (
    <LeafWrapper {...leaf} {...attributes}>
      {children}
    </LeafWrapper>
  );
}

export default memo(Leaf);
