import { useState } from 'react';

import { ReactComponent as AddIcon } from 'assets/icons/systemicons/add_small.svg';
import Popover from 'components/popover/Popover';
import Tooltip from 'components/tooltip';
import { useStoryMolecule } from 'screens/storyV2/store/story';

import CreateStoryItemsMenu from './Menu';

import { StyledCreateButton } from './styled';

const CreateStoryItems = ({ paneIndex }: { paneIndex?: number }) => {
  const { useCanUpdateStoryValue } = useStoryMolecule();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const canUpdateStory = useCanUpdateStoryValue();

  const openMenu: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title={'Create new'}>
        <StyledCreateButton
          aria-haspopup="true"
          aria-owns={anchorEl ? 'create-menu' : undefined}
          onClick={openMenu}
          $notAllowed={false}
          $size={25}
          disabled={!canUpdateStory}
        >
          <AddIcon />
        </StyledCreateButton>
      </Tooltip>
      <Popover
        onClose={closeMenu}
        anchorEl={anchorEl}
        position="top"
        type="surfaceCardDark"
        noMargin
        style={{ zIndex: 1301 }}
      >
        <CreateStoryItemsMenu closeMenu={closeMenu} paneIndex={paneIndex} />
      </Popover>
    </>
  );
};

export default CreateStoryItems;
