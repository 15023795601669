import styled from '@emotion/styled';
import { Typography, Grid } from '@material-ui/core';

export const ClickableTextWrapper = styled(Typography)`
  cursor: pointer;
`;

export const CheckBoxWrapper = styled(Grid)`
  margin-left: -3px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  margin-top: 8px;
  margin-bottom: 16px;
`;
