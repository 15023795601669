import gql from 'graphql-tag';

/** permissions are derived from master */
export default gql`
  query GetRundownByPublishingDate($input: GetMembersByPublishingDateInput) {
    getRundownsByPublishingDate(input: $input) {
      mId
      mRefId
      mTitle
      mPublishingAt
      mPlannedDuration
      mThumbnailKey
      mRundownTemplateId
      mType
      mState
      version
      platformKind
      metadata
      mdfId
      recurrence {
        duration
        startTime
        timeZone
        dailyExclusive
      }
      permissions {
        read
        write
      }
    }
  }
`;
