import styled from '@emotion/styled';
import { ReactComponent as DragHandle } from 'assets/icons/systemicons/unionDragHandle.svg';
import { ReactComponent as MinusSmallCirle } from 'assets/icons/systemicons/minus_ultrasmall_cirkle.svg';
import { ReactComponent as PlusSmallCircle } from 'assets/icons/systemicons/plus_ultraSmall_circle.svg';
import { ReactComponent as HideIcon } from 'assets/icons/systemicons/visibility_off.svg';
import { ReactComponent as ShowIcon } from 'assets/icons/systemicons/visibility_on.svg';
import { MenuItem as MaterialMenuItem } from '@material-ui/core';

export const MainRootWrapper = styled('div')`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  height: 102px;
  background-color: ${({ theme }) => theme.palette.dina.backgroundResting};
  margin-right: 102px;
`;

export const RootWrapper = styled('div')`
  width: 100%;
  height: 28px;
  display: inline-flex;
  margin-top: 24px;
  z-index: 1;
  cursor: pointer;
  border-top: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  background-color: ${({ theme }) => theme.palette.dina.backgroundResting};
`;

export const StyledHeaderWrapper = styled('div')`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  width: auto;
  height: 24px;
  margin-left: 4px;
`;

export const StyledColumnWrapper = styled('div')`
  margin-left: 4px;
  align-items: left;
  width: 100%;
  height: 100%;
`;
export const StyledFunctionButtonsWrapper = styled('div')`
  margin-top: -24px;
  display: flex;
  position: absolute;
  align-items: center;
  z-index: 2;
  left: auto;
  width: 100px;
  height: 24px;
  background-color: ${({ theme }) => theme.palette.dina.outlineButtonOnHover};
`;

export const StyledDragHandle = styled(DragHandle)`
  height: 24px;
  width: 24px;
  cursor: grab;
  path {
  }
  :active {
    cursor: grabbing;
    path {
      fill-opacity: 1;
    }
  }
  :hover {
    path {
      fill-opacity: 1;
    }
  }
`;
export const StyledAddIcon = styled(PlusSmallCircle)`
  opacity: 0.5;
  :hover {
    opacity: 1;
  }
`;

export const StyledRemoveIcon = styled(MinusSmallCirle)`
  opacity: 0.5;
  :hover {
    path {
      fill: ${({ theme }) => theme.palette.dina.warningBorder};
    }
    opacity: 1;
  }
`;

export const StyledHideIcon = styled(HideIcon)`
  margin-left: 4px;
  opacity: 0.5;
  width: 24px;
  :hover {
    path {
      fill: ${({ theme }) => theme.palette.dina.warningBorder};
    }
    opacity: 1;
  }
`;

export const StyledShowIcon = styled(ShowIcon)`
  margin-left: 4px;
  opacity: 0.5;
  width: 24px;
  :hover {
    path {
      fill: ${({ theme }) => theme.palette.dina.warningBorder};
    }
    opacity: 1;
  }
`;

export const StyledTextArea = styled('textarea')`
  ${({ theme }) => theme.typography.dina.overline};
  background-color: transparent;
  height: 28px;
  border: none;
  resize: none;
  overflow: hidden;
  min-width: 45px;
  ${({ $isHidden, theme }) => `${$isHidden ? 'font-style: italic;opacity:0.5;' : ''}`};
  &:hover {
    background-color: ${({ theme }) => theme.palette.dina.outlineButtonOnHover};
    resize: horizontal;
  }
  &:focus {
    font-style: italic;
  }
`;
export const StyledInput = styled('input')`
  ${({ theme }) => theme.typography.dina.overline};
  background-color: transparent;
  height: 24px;
  display: inline-flex;
  width: 240px;
  border: none;
  resize: none;
  overflow: hidden;
  font-size: 12px;
  margin-left: 4px;
  &:hover {
    background-color: ${({ theme }) => theme.palette.dina.outlineButtonOnHover};
  }
  &:focus {
    font-style: italic;
  }
`;
export const MenuItem = styled(MaterialMenuItem)`
  font-size: 12px;
  color: #ffffffcf;
  &:hover {
    background-color: #ffffff30;
    color: white;
    padding-right: 12px;
  }
`;
