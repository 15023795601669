/* eslint-disable sort-imports */
import { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { TableRow } from '@material-ui/core';

import { ReactComponent as ColorIcon } from 'assets/icons/systemicons/colors.svg';
import Tooltip from 'components/tooltip';
import LWCheckbox from 'features/orderForm/components/LWCheckbox';
import LWSelect from 'features/orderForm/components/LWSelect';
import {
  Alternative,
  FieldTypeEnum,
  LayoutSettings,
  MdfField,
  ViewTypes,
} from 'types/graphqlTypes';

import { hasAlternatives } from '../utils';

import { Input, RowCell } from './FieldModelRow';

const StyledColorIcon = styled(ColorIcon)`
  width: 20px;
  cursor: pointer;
  &:hover path {
    fill-opacity: 1;
  }
`;

interface RowProps {
  field: MdfField;
  defaultFieldSettings: LayoutSettings | undefined;
  fieldSettings: LayoutSettings | undefined;
  onUpdateSettings: (settings: LayoutSettings, view: ViewTypes) => void;
  selectedView: ViewTypes;
  onDoColorConfig: () => void;
}

const createOptions = () => {
  const alts: Alternative[] = [];
  for (let i = 2; i < 21; i++) {
    alts.push({ id: `${i}`, label: `${i}`, value: `${i}` });
  }
  return alts;
};

const options: Alternative[] = createOptions();

export function FieldSettingsRow({
  field,
  defaultFieldSettings,
  fieldSettings,
  onUpdateSettings,
  onDoColorConfig,
  selectedView,
}: Readonly<RowProps>) {
  const settings = fieldSettings ?? defaultFieldSettings;

  if (!settings) {
    throw new Error('NO SETTINGS FOUND');
  }

  const [multiline, setMultiline] = useState(settings?.multiline ?? false);
  const [label, setLabel] = useState(settings?.label ?? '');
  const [hint, setHint] = useState(settings?.hint ?? '');
  const [visible, setVisible] = useState(settings?.visible ?? true);
  const [fieldHeight, setFieldHeight] = useState(settings?.fieldHeight ?? 3);
  const updateVisibility = useCallback(
    (value: boolean) => {
      setVisible(value);
      if (settings) {
        onUpdateSettings({ ...settings, visible: value }, selectedView);
      }
    },
    [settings, selectedView, onUpdateSettings],
  );

  const updateHint = useCallback(
    (value: string) => {
      setHint(value);
      if (settings) {
        onUpdateSettings({ ...settings, hint: value }, selectedView);
      }
    },
    [settings, selectedView, onUpdateSettings],
  );

  const updateLabel = useCallback(
    (value: string) => {
      setLabel(value);
      if (settings) {
        onUpdateSettings({ ...settings, label: value }, selectedView);
      }
    },
    [settings, selectedView, onUpdateSettings],
  );

  const updateMultiline = useCallback(
    (value: boolean) => {
      setMultiline(value);
      if (settings) {
        onUpdateSettings({ ...settings, multiline: value }, selectedView);
      }
    },
    [settings, selectedView, onUpdateSettings],
  );

  const updateFieldHeight = useCallback(
    (value: number) => {
      setFieldHeight(value);
      if (settings) {
        onUpdateSettings({ ...settings, fieldHeight: value }, selectedView);
      }
    },
    [settings, selectedView, onUpdateSettings],
  );

  useEffect(() => {
    setLabel(settings?.label ?? '');
    setHint(settings?.hint ?? '');
    setVisible(settings?.visible ?? true);
    setMultiline(settings?.multiline ?? false);
    setFieldHeight(settings?.fieldHeight ?? 3);
  }, [settings]);

  if (!settings) {
    return null;
  }

  return (
    <TableRow tabIndex={-1}>
      <RowCell>{field.fieldId}</RowCell>
      <RowCell>
        <LWCheckbox
          style={{ position: 'relative', left: '10px' }}
          selected={visible}
          setValue={() => updateVisibility(!visible)}
        />
      </RowCell>
      <RowCell>
        <Input
          value={label}
          onChange={(ev) => setLabel(ev.target.value)}
          onBlur={() => updateLabel(label)}
        />
      </RowCell>
      <RowCell>
        <Input
          value={hint}
          onChange={(ev) => setHint(ev.target.value)}
          onBlur={() => updateHint(hint)}
        />
      </RowCell>
      <RowCell>
        {field.type === FieldTypeEnum.text && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Tooltip title="Allow more than one line of text">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <LWCheckbox selected={multiline} setValue={() => updateMultiline(!multiline)} />
                <span
                  style={{ margin: '4px 0 0 4px', cursor: 'pointer' }}
                  onClick={() => updateMultiline(!multiline)}
                >
                  Multiline
                </span>
              </div>
            </Tooltip>
            {multiline && (
              <Tooltip title="Set default amount of rows the field should show">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <LWSelect
                    style={{ width: '32px' }}
                    value={`${fieldHeight}`}
                    setValue={(val: string) => updateFieldHeight(Number(val))}
                    options={options}
                  />
                  <span style={{ margin: '4px 4px 4px 4px' }}>Rows</span>
                </div>
              </Tooltip>
            )}
          </div>
        )}
        {hasAlternatives(field) && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Tooltip title="Configure colors">
              <StyledColorIcon onClick={onDoColorConfig} />
            </Tooltip>
          </div>
        )}
      </RowCell>
    </TableRow>
  );
}
