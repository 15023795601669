import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Scrollbar from 'components/scrollbar';

import { RootWrapper, Years, ButtonWrapper } from './styled';

const YearSelector = ({ year, onYearSelect }) => {
  const currentYear = new Date().getFullYear();
  const range = (start, stop, step) =>
    Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
  const years = range(1990, Math.max(currentYear + 5, year), 1);
  useEffect(() => {
    const el = document.getElementById(`ys-${year}`);
    el?.scrollIntoView({ block: 'center' });
  }, [year]);
  return (
    <RootWrapper>
      <Scrollbar top={5} bottom={5}>
        <Years>
          {years.map((y) => (
            <ButtonWrapper
              id={`ys-${y}`}
              key={y}
              $selected={year === y}
              onClick={() => {
                onYearSelect(y);
              }}
            >
              {y}
            </ButtonWrapper>
          ))}
        </Years>
      </Scrollbar>
    </RootWrapper>
  );
};

YearSelector.propTypes = {
  year: PropTypes.number,
  onYearSelect: PropTypes.func,
};
YearSelector.defaultProps = {
  year: new Date().getFullYear(),
  onYearSelect: () => {},
};

export default YearSelector;
