/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';

import { Button } from './styled';

export function RectangleButton({
  disabled,
  children,
  onClick,
  selected,
  className,
  width,
  height,
}) {
  const clickHandler = (e) => {
    if (!disabled) onClick(e);
  };

  return (
    <Button
      className={className}
      disabled={disabled}
      onClick={clickHandler}
      selected={selected}
      width={width}
      height={height}
    >
      {children}
    </Button>
  );
}

RectangleButton.propTypes = {
  /** Used for custom css */
  className: PropTypes.string,
  /**  Children */
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string]).isRequired,
  /** Can't be pressed when disabled */
  disabled: PropTypes.bool,
  /** height */
  height: PropTypes.number,
  /** onClick callback when button is clicked */
  onClick: PropTypes.func,
  /** Will be blue when selected */
  selected: PropTypes.bool,
  /** width */
  width: PropTypes.number,
};

RectangleButton.defaultProps = {
  className: null,
  disabled: false,
  height: null,
  onClick: () => {},
  selected: false,
  width: null,
};
