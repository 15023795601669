import React from 'react';

import type { FieldValue } from 'types/forms/forms';
import { FieldTypeEnum, LayoutSettings, Mdf, MdfField, ViewTypes } from 'types/graphqlTypes';
import { EditorCommandConfigType } from 'types/memberTypes/editorCommands';
import { OrderFormMemberType } from 'types/memberTypes/order_form';

import CheckboxCell from './checkbox/CheckboxCell';
import { CheckboxField } from './checkbox/CheckboxField';
import ChoiceCell from './choice/ChoiceCell';
import ChoiceField from './choice/ChoiceField';
import DateCell from './date/DateCell';
import { DateField } from './date/DateField';
import LinkCell from './link/LinkCell';
import { LinkField } from './link/LinkField';
import MultipleChoiceCell from './multiplechoice/MultipleChoiceCell';
import { MultipleChoiceField } from './multiplechoice/MultipleChoiceField';
import RelationCell from './relation/RelationCell';
import { RelationField } from './relation/RelationField';
import SubTypeCell from './subtype/SubTypeCell';
import TextCell from './text/TextCell';
import { TextField } from './text/TextField';
import TreeChoiceCell from './treechoice/TreeChoiceCell';
import { TreeChoiceField } from './treechoice/TreeChoiceField';
import UserCell from './user/UserCell';
import { UserField } from './user/UserField';

export interface FieldProps {
  fieldModel: MdfField;
  fieldSettings: LayoutSettings | null;
  defaultFieldSettings: LayoutSettings;

  value: FieldValue;
  setValue: (val: FieldValue) => void;
  style?: React.CSSProperties;
  errorMessage?: string;
  view: ViewTypes;
  autoFocus?: boolean;

  // Every unique instance of the MdfEditor will have this unique id
  editorId?: string;

  // Will enforce date field to select a range. Note
  // that this will change the returned value to type DateRange
  // instead of string.
  forceDateRange?: boolean;

  // By default the editor will send new values when the user blurs
  // text fields, setting this to true will fire field changes on every
  // keystroke.
  fireOnChange?: boolean;
  // Indicates there is more vertical space to work with
  moreVerticalSpace?: boolean;
  // Override default placeholder text
  placeholder?: string;

  // If true disable editing/clearing value
  disableEdit?: boolean;

  hideLabel?: boolean;

  // Config maps passed down so we don't fetch a copy per field.
  // These are needed for the Relation field, to display all Members correctly.
  commands?: Record<string, EditorCommandConfigType | undefined>;
  forms?: Record<string, OrderFormMemberType | undefined>;
}

export interface CellProps {
  mdf: Mdf;
  fieldModel: MdfField;
  fieldSettings: LayoutSettings | null;
  value: FieldValue;
  disableEdit: boolean;
  errorValue?: string;
  setValue: (val: FieldValue) => void;
  onClick?: () => void;
}

export const UserFriendlyLabel: Record<FieldTypeEnum, string> = {
  choice: 'Choice',
  multiplechoice: 'Multiple choice',
  checkbox: 'Checkbox',
  number: 'Number',
  link: 'Link',
  user: 'User/Team/Dept/Contact',
  text: 'Text',
  date: 'Date',
  treechoice: 'Tree choice',
  subtype: 'Sub type',
  relation: 'Relation',
};

export const FieldMap: Record<FieldTypeEnum, React.FC<FieldProps>> = {
  choice: ChoiceField,
  multiplechoice: MultipleChoiceField,
  treechoice: TreeChoiceField,
  number: TextField,
  link: LinkField,
  user: UserField,
  checkbox: CheckboxField,
  date: DateField,
  text: TextField,
  subtype: ChoiceField,
  relation: RelationField,
};

export const CellMap: Record<FieldTypeEnum, React.FC<CellProps>> = {
  choice: ChoiceCell,
  multiplechoice: MultipleChoiceCell,
  treechoice: TreeChoiceCell,
  number: TextCell,
  link: LinkCell,
  user: UserCell,
  checkbox: CheckboxCell,
  date: DateCell,
  text: TextCell,
  subtype: SubTypeCell,
  relation: RelationCell,
};
