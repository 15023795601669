import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';

import useCheckUserRight from 'hooks/useCheckUserRight';
import RESTRICT_MEMBER from 'operations/mutations/restrictMember';

const RES_PREFIX = 'res_';

const useAccessControl = ({ mType }: { mType: string }) => {
  const [restrictMember, { loading }] = useMutation(RESTRICT_MEMBER);
  const [checkUserRight] = useCheckUserRight();
  const canChangeRestriction = checkUserRight('story', 'access-restriction');

  const [isRestricted, setIsRestricted] = useState(false);

  useEffect(() => setIsRestricted(mType?.startsWith(RES_PREFIX)), [mType]);

  const toggleRestriction = async (mId: string) => {
    await restrictMember({
      variables: {
        input: {
          mId,
          isRestricted: !isRestricted,
        },
      },
      onCompleted: () => setIsRestricted((pre) => !pre),
      notifyOnNetworkStatusChange: true,
    });
  };

  return { toggleRestriction, loading, canChangeRestriction, isRestricted };
};

export default useAccessControl;
