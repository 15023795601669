import { useQuery } from '@apollo/client';

import GET_FOLDERS from 'operations/queries/getFolders';
import { FolderType } from 'types/graphqlTypes';

const useGetContentTemplateFolders = (platformType: string, platformKind: string) => {
  const { data: foldersData } = useQuery<{ getFolders: FolderType[] }>(GET_FOLDERS);

  const folders = (foldersData?.getFolders || []).filter((folder) => {
    const { platform: folderPlatform, platformKind: folderPlatformKind } = folder?.mProperties || {
      mProperties: {},
    };
    const isSamePlatformType = folderPlatform === platformType;

    if (platformType === 'linear') {
      if (platformKind) {
        return isSamePlatformType && platformKind === folderPlatformKind;
      }

      // mProperties does not exist for older linear template folders
      if (!folderPlatform) return true;

      if (isSamePlatformType && folderPlatformKind) return false;
    }

    return isSamePlatformType;
  });

  return folders;
};

export default useGetContentTemplateFolders;
