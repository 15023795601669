/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react';
import { ApolloCache, ApolloError, FetchResult, useMutation } from '@apollo/client';

import memberTypes from 'operations/memberTypes';
import CREATE_ASSET from 'operations/mutations/createAssets';
import GET_ASSETS from 'operations/queries/getAssets';
import { getMembersQuery } from 'operations/queryVariables';
import { Asset } from 'types';
import { CreateAssetInput } from 'types/graphqlTypes';
import { uploadToS3 } from 'utils/s3Utils';
import useLogger from 'utils/useLogger';

import useCreateAndUploadThumbnail from './useCreateAndUploadThumbnail';

export type AssetInput = CreateAssetInput & {
  data?: {
    [k: string]: any;
  };
  file?: File;
};

const useCreateAsset: () => readonly [
  (
    storyId: string,
    assetInput: AssetInput,
    updateCache: boolean,
    updateProgressCallback?: () => void,
    memberType?: string,
  ) => Promise<FetchResult<any>>,
  boolean,
  ApolloError | undefined,
] = () => {
  const [createAssets, { loading, error }] = useMutation(CREATE_ASSET);
  const logger = useLogger('use create assets');

  const { createAndUploadThumbnail } = useCreateAndUploadThumbnail();
  const updateAssetList = useCallback(
    (
      proxy: ApolloCache<any>,
      mutationResult: Omit<FetchResult<any>, 'context'>,
      memberType: string,
      file?: File,
    ) => {
      const { createAssets: newAssets } = mutationResult.data;
      if (!newAssets || !newAssets[0]) return;

      // Read asset list from the cache
      try {
        const asset = { ...newAssets[0] };

        const assetsList: {
          getAssets: Asset[];
        } | null = proxy.readQuery({
          query: GET_ASSETS,
          variables: getMembersQuery(asset.mId, memberType),
        });

        const list = assetsList?.getAssets.filter(
          (tAsset: Asset) => tAsset !== null && tAsset.mRefId === asset.mRefId,
        );

        if (file) {
          const url = window.URL || window.webkitURL;
          const fileUrl = url.createObjectURL(file);
          asset.mContentUrl = fileUrl;
          asset.mThumbnailUrl = fileUrl;
        }

        const assets = assetsList ? [...assetsList.getAssets] : [];
        if (list?.length === 0) {
          assets.push(asset);
        }

        proxy.writeQuery({
          query: GET_ASSETS,
          variables: getMembersQuery(asset.mId, memberType),
          data: {
            getAssets: assets,
          },
        });
      } catch (updateAssetError) {
        logger.log(
          `'updateAssetList: Failed to update cache', ${JSON.stringify(updateAssetError)}`,
        );
      }
    },
    [],
  );

  const createAsset = useCallback(
    async (
      storyId: string,
      assetInput: AssetInput,
      updateCache: boolean,
      updateProgressCallback: (() => void) | undefined,
      memberType = memberTypes.STORY,
    ) => {
      const { mTitle, mRefId, mediaType, renditions, data, extension, file } = assetInput;

      const asset: CreateAssetInput = {
        mId: storyId,
        mMetaData: [],
        mediaType,
        mTitle,
        ...(mRefId && { mRefId }),
        ...(mediaType?.includes('application/srt') && { itemType: 'file' }),
        ...(renditions && { renditions }),
      };

      Object.keys(data!).forEach((key: string) => {
        const value = data![key];
        if (!value) return;

        if (key === 'id') {
          if (!data?.url) asset.mAssetId = value;
        } else if (key === 'url') asset.mAssetId = value;
        else if (key === 'title') asset.mTitle = value;
        else asset?.mMetaData?.push({ key, value });
      });

      if (file) {
        asset?.mMetaData?.push({ key: 'extension', value: extension });
        asset?.mMetaData?.push({ key: 'size', value: file.size });
        asset?.mMetaData?.push({ key: 'provider', value: 'Dina' });
      }

      const result = await createAssets({
        variables: {
          input: {
            assets: [asset],
            copyVideoRenditions: true,
          },
        },
        update: (proxy, mutationResult) => {
          if (updateCache) updateAssetList(proxy, mutationResult, memberType, file);
        },
      });

      if (!file || (renditions && renditions.length > 0)) return result;

      const [assetData] = result?.data?.createAssets || [];
      if (assetData) {
        const { mContentKey, mThumbnailKey, itemType } = assetData;
        const assetPromise = uploadToS3(mContentKey, file, updateProgressCallback);
        const thumbnailPromise =
          itemType === 'image' ? createAndUploadThumbnail(mThumbnailKey, file) : null;
        await Promise.allSettled([assetPromise, thumbnailPromise]);
      }

      return result;
    },
    [],
  );

  return [createAsset, loading, error] as const;
};

export default useCreateAsset;
