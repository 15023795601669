import { Editor } from 'slate';
import isLink from 'components/editor/utils/isLink';

const getHrefFromSelection = (editor) => {
  const [match] = Editor.nodes(editor, { match: isLink });
  const isSelected = Boolean(match);
  return isSelected && match[0]?.data?.href ? match[0]?.data?.href : '';
};

export default getHrefFromSelection;
