import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(({ palette, typography }) => ({
  alert: {
    backgroundColor: palette.dina.tooltip,
    color: palette.dina.mediumEmphasis,
  },
}));

export default useStyles;
