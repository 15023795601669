import { useRightHidden, useRightSelection } from 'store/sidebar';
import { postMosMessage } from 'utils/postMessage';
import { useExternalUrls } from 'store/externalUrls';
import { getPanelId } from './useOpenSearchResult';

/**
 * Hook for opening MOS asset item in PILOT EDGE in right sidebar
 * @returns {function(): void} - returns a function that opens the Asset
 */
const useOpenAssetInPilotEdge = () => {
  const [, setRightHidden] = useRightHidden();
  const [, setRightSelection] = useRightSelection();
  const [externalUrls] = useExternalUrls();

  /**
   *
   * @param {Object} mosObj object to open asset
   * @returns
   */
  const openInPilotEdge = (mosObj, element) => {
    if (!mosObj) return;

    const provider = element?.data?.provider;

    const externalUrl = provider
      ? externalUrls.find((_) => provider === _.mTitle || provider === _.mosId)
      : null;

    const externalId =
      externalUrl?.type === 'pilotedge' ? 'pilotedge' : externalUrl?.id ?? 'pilotedge';

    postMosMessage(mosObj, externalId, element);
    setRightHidden(false);

    setRightSelection(externalUrl ? getPanelId(externalUrl) : 'graphicsbox');
  };

  return openInPilotEdge;
};

export default useOpenAssetInPilotEdge;
