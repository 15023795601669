/**
 *  Hook for sending a feed item to a story.
 *  It takes the mId of the story, and mId and provider from the feed item.
 */
import { useMutation } from '@apollo/client';

import { useLocalNotifications } from 'store/notifications';
import ADD_FEED_TO_STORY from 'operations/mutations/addFeedToStory';
import memberTypes from 'operations/memberTypes';

const useAddFeedToStory = () => {
  const [addFeedToStory] = useMutation(ADD_FEED_TO_STORY);
  const [, setLocalNotifications] = useLocalNotifications();

  const add = async ({ storyId, feedId, provider, storyType }) => {
    const variables = {
      input: {
        storyId,
        feedId,
        provider,
        storyType,
      },
    };

    await addFeedToStory({
      variables,
      update: (_, mutationResult) => {
        const addedToStoryId = mutationResult.data?.addFeedToStory?.mId;
        setLocalNotifications((prev) => [
          ...prev,
          {
            mId: feedId,
            mStoryId: addedToStoryId,
            time: new Date(),
            mType: addedToStoryId ? memberTypes.FEED : 'error',
            storyType,
          },
        ]);
      },
    });
  };

  return [add];
};

export default useAddFeedToStory;
