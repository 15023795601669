import styled from '@emotion/styled/macro';
import transientOptions from 'theme/helpers';
import { Typography, Divider } from '@material-ui/core';

const sixth = 100 / 6;
const eighth = 100 / 8;

export const RootWrapper = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const CalendarHeader = styled('div')`
  display: flex;
  width: 100%;
  height: 32px;
  align-items: center;
`;

export const CalendarBody = styled('div')`
  flex-grow: 1;
`;

export const CalendarRow = styled('div')`
  width: 100%;
  height: ${sixth}%;
  display: flex;
  align-items: center;
  position: relative;
`;

export const Row = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const Cell = styled('div')`
  width: ${eighth}%;
  display: flex;
  justify-content: center;
`;

export const TypographyWrapper = styled(Typography, transientOptions)`
  ${({ $typographyType, theme }) => {
    switch ($typographyType) {
      case 'weekText':
        return theme.typography.dina.captionSmall;
      default:
        return theme.typography.dina.listItemGroupHeadersLabel;
    }
  }}

  color: ${({ $typographyType, theme }) => {
    switch ($typographyType) {
      case 'cellTextToday':
        return theme.palette.dina.onSelected;
      case 'cellTextHoliday':
        return theme.palette.dina.datePickerHoliday;
      case 'weekText':
        return theme.palette.dina.disabled;
      case 'cellText':
        return theme.palette.dina.mediumEmphasis;
      default:
        return theme.palette.dina.mediumEmphasis;
    }
  }};

  letter-spacing: 0.25px;
  font-weight: normal;
`;

export const DateDivider = styled(Divider)`
  background-color: ${({ theme }) => theme.palette.dina.dividerLight};
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: calc(100% - ${eighth}%);
  margin-left: ${eighth}%;
`;
