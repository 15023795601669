import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { TextareaAutosize } from 'lib/textarea';

export const Input = styled(TextareaAutosize)<{ $locked: boolean }>`
  ${({ theme }) => theme.typography.dina.h1};
  font-size: 34px !important;
  line-height: 43px;
  outline: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  margin-left: 6px;
  resize: none;
  width: 100%;
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
  ${({ $locked, theme }) =>
    !$locked &&
    css`
      :focus {
        border: 1px solid ${theme.palette.dina.onFocus};
        background-color: ${theme.palette.dina.blackHoverOverlay};
      }
      :hover {
        border: 1px solid ${theme.palette.dina.inputBorderResting};
        background-color: ${theme.palette.dina.whiteHoverOverlay};
      }
    `}
  ::placeholder {
    color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  }
`;
