import React, { useState, memo, useCallback, useMemo, useEffect } from 'react';
import { useSlate } from 'slate-react';
import PropTypes from 'prop-types';
import uuidv1 from 'uuid/v1';

import { elementTypes } from 'components/editor/constants/types';
import { menuOptions } from 'components/editor/constants';
import { ReactComponent as CloseIcon } from 'assets/icons/systemicons/fullscreen.svg';
import { ReactComponent as CodeIcon } from 'assets/icons/systemicons/editor/code_off.svg';
import { removeBlock, updateBlock, refreshSelection } from 'components/editor/utils';
import { Button } from 'components/buttons';
import TextArea from 'components/textArea';
import useChangeCollapse from 'components/editor/hooks/useChangeCollapse';
import { useEditorContext } from 'components/editor/EditorContext';

import { decodeHTMLEntities, encodeHTMLEntities } from './utils/encoding';
import Box from '../box';
import DragAndDrop from '../dragAndDrop';
import FullscreenHtmlViewer from '../htmlViewer';
import HtmlThumbnail from './components/htmlThumbnail';

import { BoxIcon, InputWrapper, HtmlThumbWrapper, PreviewWrapper } from './styled';

const PREVIEW_BUTTON_LABEL = 'Large Preview';

const Html = ({ attributes, children, element }) => {
  const editor = useSlate();
  const { update } = useEditorContext();
  const [onChangeCollapse] = useChangeCollapse(element);

  const { data } = element;
  const { html: initialHtml, showHtml = true, collapsed = false } = data || {};

  const [htmlContent, setHtmlContent] = useState(decodeHTMLEntities(initialHtml || ''));
  const [previewFullScreen, setPreviewFullScreen] = useState(false);

  const updateHtml = useCallback(
    () => {
      const updatedData = {
        html: encodeHTMLEntities(htmlContent),
        itemId: uuidv1(),
        showHtml,
      };
      updateBlock(editor, element, updatedData, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element, htmlContent],
  );

  const updateShowHtml = useCallback(
    (val) => {
      const updatedData = {
        html: encodeHTMLEntities(htmlContent),
        itemId: uuidv1(),
        showHtml: val,
      };
      updateBlock(editor, element, updatedData, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  const handleChange = useCallback((newValue) => {
    setHtmlContent(newValue);
  }, []);

  const onBlur = useCallback(
    (event) => {
      event.preventDefault();
      if (event.relatedTarget) updateHtml();
    },
    [updateHtml],
  );

  const toggleShowHtml = useCallback(() => {
    updateShowHtml(!showHtml);
  }, [showHtml, updateShowHtml]);

  const toggleFullScreen = useCallback(() => {
    setPreviewFullScreen(!previewFullScreen);
  }, [previewFullScreen, setPreviewFullScreen]);

  const onMenuSelect = useCallback(
    ({ action }) => {
      if (action === 'delete-block') removeBlock(editor, element, update);
      if (action === 'show-html') toggleShowHtml();
    },
    [editor, element, update, toggleShowHtml],
  );

  const menuItems = useMemo(() => {
    const extendedOptions = [
      {
        title: `${showHtml ? 'Hide' : 'Show'} Html`,
        action: 'show-html',
        icon: <CodeIcon />,
        divider: true,
      },
    ];
    return [...extendedOptions, ...menuOptions];
  }, [showHtml]);

  useEffect(() => {
    setHtmlContent(decodeHTMLEntities(initialHtml || ''));
  }, [initialHtml]);

  const renderHtmlBlock = useMemo(
    () => (
      <Box
        iconComponent={<BoxIcon className="skipOverride" />}
        menuItems={menuItems}
        title="HTML"
        updateCollapsed={onChangeCollapse}
        collapsed={collapsed}
        collapsedContent={htmlContent}
        onMenuSelect={onMenuSelect}
      >
        <FullscreenHtmlViewer
          handleClose={toggleFullScreen}
          open={previewFullScreen}
          html={htmlContent}
        />
        <PreviewWrapper>
          <HtmlThumbWrapper>
            <HtmlThumbnail html={htmlContent} />
          </HtmlThumbWrapper>
          <Button
            align="left"
            disabled={!htmlContent}
            onClick={toggleFullScreen}
            startIcon={<CloseIcon />}
            usage="outlined"
            variant="outlined"
            width={220}
          >
            {PREVIEW_BUTTON_LABEL}
          </Button>
        </PreviewWrapper>
        {showHtml && (
          <InputWrapper>
            <TextArea
              disableLabel
              type="SoMe"
              rows={4}
              placeholder="<p>Html content</p>"
              value={htmlContent}
              onChange={handleChange}
              description="Paste or edit html here"
              onBlur={onBlur}
            />
          </InputWrapper>
        )}
      </Box>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [collapsed, handleChange, onBlur, onMenuSelect, onChangeCollapse, showHtml, previewFullScreen],
  );

  const onBlurComponent = useCallback(() => {
    refreshSelection(editor, element);
  }, [editor, element]);

  return (
    <div {...attributes} onBlur={onBlurComponent}>
      <DragAndDrop element={element}>
        {children}
        {renderHtmlBlock}
      </DragAndDrop>
    </div>
  );
};

Html.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

Html.defaultProps = {
  attributes: {},
  children: null,
  element: {
    children: [],
    data: {},
    type: elementTypes.HTML,
  },
};

export default memo(Html);
