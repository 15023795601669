import { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { atom, useAtom } from 'jotai';
import { keyBy } from 'lodash';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import Dialog from 'components/dialogs/DialogBuilder';
import DraggableDialog from 'components/dialogs/Draggable';
import Tooltip from 'components/tooltip';
import useMetadata from 'hooks/useMetadata';
import { Metadata } from 'types/forms/forms';
import { Mdf, ViewTypes } from 'types/graphqlTypes';

import { MdfEditor } from './MdfEditor';

const HeaderWrapper = styled('div')`
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 380px;
`;

export interface EditMetadata {
  metadata: Metadata;
  mdfId: string | null;
  viewType: ViewTypes;
  headerText: string;
  confirmLabel?: string;
  onConfirm: (updatedMetadata: Metadata, mdfId: string | null) => void;
  onCancel?: () => void;
}

const editMetadataDialog = atom<EditMetadata | null>(null);

export const useEditMetadataDialog = () => useAtom(editMetadataDialog);

export function EditMetadataDialog() {
  const [dimension, setDimension] = useState({
    width: `${window.innerWidth < 300 ? window.innerWidth : '350'}`,
    height: `${window.innerHeight < 600 ? window.innerHeight : '600'}`,
  });
  const { mdfs } = useGetMdfs({ all: true });
  const [state, setState] = useEditMetadataDialog();

  const mdfMap = useMemo(() => {
    return keyBy(mdfs, (mdf) => mdf.id);
  }, [mdfs]);

  const mdf: Mdf | undefined = mdfMap[state?.mdfId ?? ''];
  const { metadata, updateFieldValues, errorMap, errorTooltip } = useMetadata(
    mdf,
    state?.metadata,
    state?.viewType,
  );

  const onCancel = () => {
    if (state?.onCancel) {
      state.onCancel();
    }
    setState(null);
  };

  const onConfirm = () => {
    if (state?.onConfirm) {
      state.onConfirm(metadata, state.mdfId);
    }
    onCancel();
  };

  return (
    <DraggableDialog
      open={state !== null}
      onClose={() => setState(null)}
      initialPosition={{
        x: 400,
        y: 100,
      }}
      minWidth={300}
      minHeight={300}
      dimension={dimension}
      setDimension={setDimension}
    >
      <Dialog.Header className="dragHandler">
        <Tooltip title={state?.headerText ?? ''}>
          <HeaderWrapper>{state?.headerText}</HeaderWrapper>
        </Tooltip>
      </Dialog.Header>
      <Dialog.Body bodyHeight="calc(100% - 84px)" overflow="auto">
        {state && mdf && (
          <MdfEditor
            view={state.viewType}
            fields={mdf.fields}
            autoFocus={true}
            defaultLayoutSettings={mdf.views.default}
            layoutSettings={mdf.views[state.viewType]}
            permissions={mdf.permissions}
            metadata={metadata}
            updateFieldValue={updateFieldValues}
            errorMap={errorMap}
            fieldEditMode={false}
          />
        )}
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton onCancel={onCancel} />
        <Tooltip title={errorTooltip ?? ''}>
          <span>
            <Dialog.ConfirmButton
              label={state?.confirmLabel ?? 'Confirm'}
              onClick={onConfirm}
              disabled={Boolean(errorTooltip)}
            />
          </span>
        </Tooltip>
      </Dialog.Footer>
    </DraggableDialog>
  );
}
