import styled from '@emotion/styled';

// ─── Toolbar Styles ─────────────────────────────────────────────────────────────
export const Toolbar = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-block: 4px;
  padding-inline: 8px;
  position: sticky;
  top: 0;
  left: 0;
  gap: 8px;
`;

// ─── Column Filter Styles ────────────────────────────────────────────────────
export const CheckIconWrapper = styled('div')<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  border: 1px solid
    ${({ isSelected, theme }) =>
      isSelected ? theme.palette.dina.onFocus : theme.palette.dina.inputBorderResting};
  background-color: transparent;
  opacity: ${({ isSelected }) => (isSelected ? 1 : 0.5)};
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.palette.dina.onFocus : 'transparent'};
  svg {
    display: ${({ isSelected }) => (isSelected ? 'block' : 'none')};
    path {
      fill-opacity: 1;
    }
  }
`;

export const OptionCount = styled('span')`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto Mono', monospace;
`;
