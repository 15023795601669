/* eslint-disable max-len */

import styled from '@emotion/styled';

import RenderWidget from 'features/widget/components/RenderWidget';

import { LayoutProps } from '../layouts';

export const WidgetsWrapper = styled('div')`
  display: flex;
  gap: 8px;
  height: 100%;
  width: 100%;
  overflow-x: auto;
  padding-inline: 8px;

  :only-child {
    padding-top: 6px;
  }

  // Remove focus outline on all children
  > * {
    & :focus-visible {
      outline: none;
    }
  }
`;

export default function Horizontal({
  widgets,
  scrollRef,
  getWidgetProperties,
  filterByDate,
  federatedSearchString,
  selectedDate,
}: Readonly<LayoutProps>) {
  return (
    <WidgetsWrapper ref={scrollRef}>
      {widgets.map((widget) => {
        if (widget) {
          return (
            <RenderWidget
              key={widget.mRefId}
              type={widget.type}
              config={widget.config}
              properties={getWidgetProperties({
                layout: 'horizontal',
                type: widget.type,
                selectedDate: filterByDate ? selectedDate : undefined,
                federatedSearchString:
                  federatedSearchString && federatedSearchString.length > 0
                    ? federatedSearchString
                    : undefined,
                widget,
              })}
            />
          );
        }
      })}
    </WidgetsWrapper>
  );
}
