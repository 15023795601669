import { useMemo } from 'react';
import { keyBy } from 'lodash';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import useMetadata from 'hooks/useMetadata';
import { Metadata } from 'types/forms/forms';

import { useInstanceMolecule } from '../store/instance';

const getParsedMD = (val?: string | null) => {
  try {
    return JSON.parse(val ?? '{}') as Metadata | null | undefined;
  } catch (err) {
    return null;
  }
};

export const useInstanceMdf = () => {
  const { mdfsSeparated } = useGetMdfs({ all: true });
  const { useInstanceValue } = useInstanceMolecule();

  const instance = useInstanceValue();
  const instanceMdfs = useMemo(() => {
    return keyBy(mdfsSeparated.instances, (mdf) => mdf.id);
  }, [mdfsSeparated.instances]);

  const mdf = useMemo(() => {
    const iProps = instance?.mProperties;
    if (!iProps) return undefined;
    if (iProps.platformKind === 'audio') return instanceMdfs['linear-audio'];
    return (
      instanceMdfs[iProps?.platformKind ?? ''] ??
      instanceMdfs[iProps?.account?.accountId ?? ''] ??
      instanceMdfs[iProps.platform]
    );
  }, [instance?.mProperties, instanceMdfs]);

  const parsedMetadata = useMemo(() => {
    return getParsedMD(instance?.metadata ?? '') ?? {};
  }, [instance?.metadata]);

  const { metadata, updateFieldValues, errorMap } = useMetadata(
    mdf,
    parsedMetadata,
    'default',
    instance ?? undefined,
  );

  return {
    mdf,
    metadata,
    parsedMetadata,
    errorMap,
    updateFieldValues,
    subMdfs: mdfsSeparated.subTypes,
  };
};

export default useInstanceMdf;
