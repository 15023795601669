/**
 * Encode HTML entities and handle unwanted <br> tags
 */
export const encodeHTMLEntities = (text) => {
  const textArea = document.createElement('textarea');
  textArea.innerText = text;

  const innerHtml = textArea.innerHTML;
  const splitter = innerHtml.split('<br>');
  const encodedOutput = splitter.join('\n');

  return encodedOutput;
};

/**
 * Decode HTML entities
 */
export const decodeHTMLEntities = (text) => {
  const textArea = document.createElement('textarea');
  textArea.innerHTML = text;

  return textArea.value;
};
