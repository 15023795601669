import { useState } from 'react';
import { useMutation } from '@apollo/client';

import { ReactComponent as AddIcon } from 'assets/icons/systemicons/add.svg';
import { IconButton } from 'components/buttons';
import Dialog from 'components/dialogs/DialogBuilder';
import Text from 'components/text/Text';
import { VStack } from 'layouts/box/Box';
import CREATE_TEAM from 'operations/mutations/createTeam';
import { useTeams } from 'store/members';
import { MemberTypeEnum } from 'types/graphqlTypes';
import { User } from 'types/members';
import useLogger from 'utils/useLogger';

import { StyledTextField } from './styled';

function CreateTeam() {
  const logger = useLogger('RightSidebar-createTeam');
  const [teams, setTeams] = useTeams();
  const [createTeam, { loading }] = useMutation<{ createMember: User }>(CREATE_TEAM, {
    update: (_proxy, mutationResult) => {
      const { createMember } = mutationResult.data ?? {};

      const exists = teams.find((team) => team.mId === createMember?.mId);

      if (!exists && createMember) {
        setTeams([...teams, createMember]);
      }
    },
  });

  const [open, setOpen] = useState(false);
  const [teamName, setTeamName] = useState('');
  const [description, setDescription] = useState('');

  const onClose = () => {
    setTeamName('');
    setDescription('');
    setOpen(false);
  };

  const createNewTeam = async () => {
    try {
      await createTeam({
        variables: {
          input: {
            mType: MemberTypeEnum.Team,
            mTitle: teamName,
            mDescription: description.length ? description : undefined,
          },
        },
      });
    } catch (error) {
      logger.log(error);
    }
    onClose();
  };

  return (
    <>
      <IconButton
        usage="text"
        size={24}
        iconSize={18}
        title="Add Team"
        onClick={() => setOpen(true)}
      >
        <AddIcon />
      </IconButton>
      <Dialog open={open} onClose={onClose}>
        <Dialog.Header>Create Team</Dialog.Header>
        <Dialog.Body>
          <VStack height="100%" width="100%" gap="12px">
            <VStack width="100%" gap="4px">
              <Text variant="overline">Title</Text>
              <StyledTextField
                fullWidth
                type="text"
                variant="filled"
                placeholder="Enter title here"
                onChange={(e) => setTeamName(e.target.value)}
                value={teamName}
                error={teamName?.length === 0}
                helperText={teamName?.length === 0 ? 'Team title is required' : undefined}
              />
            </VStack>

            <VStack width="100%" gap="4px">
              <Text variant="overline">Description</Text>
              <StyledTextField
                fullWidth
                variant="filled"
                type="text"
                placeholder="Enter description here"
                onChange={(e) => setDescription(e.target.value)}
                value={description}
                multiline={true}
                minRows={4}
                maxRows={10}
              />
            </VStack>
          </VStack>
        </Dialog.Body>
        <Dialog.Footer>
          <Dialog.CancelButton onCancel={onClose} />
          <Dialog.ConfirmButton
            disabled={!teamName}
            title="Create Team"
            label="Create"
            onConfirm={createNewTeam}
            loading={loading}
          />
        </Dialog.Footer>
      </Dialog>
    </>
  );
}

export default CreateTeam;
