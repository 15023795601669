import Resizer from 'react-image-file-resizer';

interface ResizeFileProps {
  file: File;
  width: number;
  height: number;
  quality: number;
}

const resizeFile = ({
  file,
  width,
  height,
  quality,
}: ResizeFileProps): Promise<string | File | Blob | ProgressEvent<FileReader>> => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      width,
      height,
      'JPEG',
      quality,
      0,
      (uri) => {
        resolve(uri);
      },
      'file',
      128,
      128,
    );
  });
};

interface ResizeImageProps extends ResizeFileProps {
  fileName: string;
}

const resizeImage = async ({
  file,
  width,
  height,
  quality,
  fileName,
}: ResizeImageProps): Promise<[File, string]> => {
  const url = window.URL || window.webkitURL;
  try {
    const resized = await resizeFile({ file, width, height, quality });
    const [defaultFileName] = file.name.split('.');
    const imageFile = new File([resized as BlobPart], `${fileName || defaultFileName}.jpg`, {
      type: 'image/jpeg',
    });
    const imageUrl = url.createObjectURL(imageFile);
    return [imageFile, imageUrl];
  } catch (err) {
    const fileUrl = url.createObjectURL(file);
    return [file, fileUrl];
  }
};

export default resizeImage;
