/* eslint-disable react/no-array-index-key */
import React, { memo, useCallback } from 'react';
import { useSlate } from 'slate-react';
import PropTypes from 'prop-types';

import imageTypes from 'utils/constants/imageTypes';
import useImageUpload from 'hooks/useImageUpload';
import useUploadBySignedUrl from 'hooks/useUploadBySignedUrl';
import { ReactComponent as AddIcon } from 'assets/icons/systemicons/add.svg';
import { RectangleButton } from 'components/buttons';
import { actionTypes } from 'components/editor/constants/types';
import { generateName } from 'components/editor/utils';
import { useEditorContext } from 'components/editor/EditorContext';

import Thumbnail from './component/thumbnail';

import { ThumbnailWrapper } from './styled';

const AddThumbnails = ({ thumbnails, readOnly, addThumbnails, removeThumbnail, maxThumbnails }) => {
  const editor = useSlate();
  const [uploadToProvider] = useUploadBySignedUrl();
  const { update, withSignedUrl: useUploadApi } = useEditorContext();

  const onImageLoad = useCallback(
    async (files) => {
      const updatedLocalThumbnails = [...thumbnails];
      const noOfAllowedThumbnails = maxThumbnails - thumbnails.length;
      Promise.all(
        // TODO: Need to have a look for consistent return
        // eslint-disable-next-line consistent-return
        await files.slice(0, noOfAllowedThumbnails).map(async (fileObject) => {
          const { file, filePath } = await fileObject;
          if (file && filePath) {
            const fileName = generateName(file.type);
            const response = await update({
              type: actionTypes.ASSET_INSERT,
              payload: { document: editor.children, file, fileName },
            });

            const result = useUploadApi && response?.signedUrl ? response.result : response;

            if (response?.signedUrl) await uploadToProvider(response.signedUrl, file);

            if (result) updatedLocalThumbnails.push({ ...result, filePath });
            return result;
          }
        }),
      ).then(async (results) => {
        if (results) {
          await addThumbnails(results, updatedLocalThumbnails);
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addThumbnails, maxThumbnails, thumbnails, useUploadApi],
  );

  const removeFilePathFromData = useCallback(
    (thumbnailArray) => thumbnailArray.map(({ filePath, ...rest }) => ({ ...rest })),
    [],
  );

  const onThumbnailRemoveClick = useCallback(
    (arrayIndex) => {
      const updatedThumbnails = [
        ...thumbnails.slice(0, arrayIndex),
        ...thumbnails.slice(arrayIndex + 1),
      ];
      const updatedThumbs = removeFilePathFromData(updatedThumbnails);
      removeThumbnail(updatedThumbs, updatedThumbnails);
    },
    [removeFilePathFromData, removeThumbnail, thumbnails],
  );

  const uploadImage = useImageUpload({
    disableResize: true,
    imageTypes,
    onImageLoad,
    multiple: true,
  });

  const onAddThumbnailClick = useCallback(
    (event) => {
      event.preventDefault();
      uploadImage();
    },
    [uploadImage],
  );

  return (
    <ThumbnailWrapper>
      {thumbnails.map((thumbnail, index) => (
        <Thumbnail {...{ onThumbnailRemoveClick, thumbnail }} key={index} index={index} />
      ))}
      {thumbnails.length < maxThumbnails && (
        <RectangleButton height={58} width={58} onClick={onAddThumbnailClick} disabled={readOnly}>
          <AddIcon />
        </RectangleButton>
      )}
    </ThumbnailWrapper>
  );
};

AddThumbnails.propTypes = {
  /** Boolean that indicates that component is readOnly */
  readOnly: PropTypes.bool,
  /** List of thumbnails */
  thumbnails: PropTypes.arrayOf(
    PropTypes.shape({
      /** id of image as asset */
      mId: PropTypes.string,
      /** Unique id of image as asset */
      mRefId: PropTypes.string,
      /** mContentKey of thumbnail */
      src: PropTypes.string,
    }),
  ),
  /** callback to add one or multiple thumbnail */
  addThumbnails: PropTypes.func,
  /** maximum number of allowed thumbnail */
  maxThumbnails: PropTypes.number,
  /** callback to remove thumbnail */
  removeThumbnail: PropTypes.func,
};

AddThumbnails.defaultProps = {
  readOnly: false,
  thumbnails: [],
  addThumbnails: () => {},
  maxThumbnails: 4,
  removeThumbnail: () => {},
};

export default memo(AddThumbnails);
