import memberTypes from 'operations/memberTypes';

const getIsAddAction = (story, leftSelection) => {
  if (leftSelection === memberTypes.STORY || leftSelection === memberTypes.PITCH) return true;

  const { crudAction } = story.message;
  if (crudAction === 'INSERT') return true;
  if (crudAction === 'REMOVE') return false;
  return false;
};

const shareStoryWithUser = (story, updateCache, leftSelection) => {
  const { user, loggedInUserId, mType } = story.message;
  if (loggedInUserId === user.mId) {
    updateCache(story, mType, getIsAddAction(story, leftSelection));
  }
};

export default shareStoryWithUser;
