import { Svg, Path, Rect } from '@react-pdf/renderer';

const AudioIcon = () => {
  return (
    <Svg style={{ width: 32, height: 32, marginRight: 8 }} viewBox='0 0 32 32'>
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16 7.5C16 7.22386 16.2239 7 16.5 7C16.7761 7 17 7.22386 17 7.5V24.5C17 24.7761 16.7761 25 16.5 25C16.2239 25 16 24.7761 16 24.5V7.5ZM20 10.5C20 10.2239 20.2239 10 20.5 10C20.7761 10 21 10.2239 21 10.5V21.5C21 21.7761 20.7761 22 20.5 22C20.2239 22 20 21.7761 20 21.5V10.5ZM12.5 10C12.2239 10 12 10.2239 12 10.5V21.5C12 21.7761 12.2239 22 12.5 22C12.7761 22 13 21.7761 13 21.5V10.5C13 10.2239 12.7761 10 12.5 10ZM18 12.5C18 12.2239 18.2239 12 18.5 12C18.7761 12 19 12.2239 19 12.5V19.5C19 19.7761 18.7761 20 18.5 20C18.2239 20 18 19.7761 18 19.5V12.5ZM14.5 12C14.2239 12 14 12.2239 14 12.5V19.5C14 19.7761 14.2239 20 14.5 20C14.7761 20 15 19.7761 15 19.5V12.5C15 12.2239 14.7761 12 14.5 12ZM22 14.5C22 14.2239 22.2239 14 22.5 14C22.7761 14 23 14.2239 23 14.5V17.5C23 17.7761 22.7761 18 22.5 18C22.2239 18 22 17.7761 22 17.5V14.5ZM10.5 14C10.2239 14 10 14.2239 10 14.5V17.5C10 17.7761 10.2239 18 10.5 18C10.7761 18 11 17.7761 11 17.5V14.5C11 14.2239 10.7761 14 10.5 14ZM24 15.75C24 15.4739 24.2239 15.25 24.5 15.25C24.7761 15.25 25 15.4739 25 15.75V16.25C25 16.5261 24.7761 16.75 24.5 16.75C24.2239 16.75 24 16.5261 24 16.25V15.75ZM8.5 15.25C8.22386 15.25 8 15.4739 8 15.75V16.25C8 16.5261 8.22386 16.75 8.5 16.75C8.77614 16.75 9 16.5261 9 16.25V15.75C9 15.4739 8.77614 15.25 8.5 15.25Z'
        fill='#0F141A'
        fillOpacity='0.87'
      />
      <Rect
        x='0.5'
        y='0.5'
        width='31'
        height='31'
        rx='5.5'
        ry='5.5'
        stroke='#0F141A'
        strokeOpacity='0.87'
      />
    </Svg>
  );
};

export default AudioIcon;
