import { memo, useCallback } from 'react';
import { Range } from 'slate';
import { useFocused, useSlate } from 'slate-react';

import { ReactComponent as HorizontalRuleIcon } from 'assets/icons/systemicons/editor/divider_off.svg';
import { elementTypes } from 'components/editor/constants';
import { isElementActive, toggleElement } from 'components/editor/utils';

import ButtonBase from '../buttonBase';

const { edges, isCollapsed } = Range;

const isZero = (value: number) => value === 0;

const { HORIZONTAL_RULE } = elementTypes;

function HorizontalRuleButton() {
  const editor = useSlate();
  const isFocused = useFocused();
  const isActive = isElementActive(editor, HORIZONTAL_RULE);

  const onMouseDown = useCallback(
    (event: React.MouseEvent<SVGSVGElement>) => {
      event.preventDefault();
      if (!editor.selection) return;
      if (isFocused && isCollapsed(editor.selection)) {
        const [start] = edges(editor.selection);
        if (isZero(start.offset)) toggleElement(editor, HORIZONTAL_RULE, isActive);
      }
    },
    [isFocused, editor, isActive],
  );

  return (
    <ButtonBase
      isActive={isActive}
      type={elementTypes.HORIZONTAL_RULE}
      IconComponent={HorizontalRuleIcon}
      onMouseDown={onMouseDown}
    />
  );
}

export default memo(HorizontalRuleButton);
