export const getLockToken = (scope: string) => window.sessionStorage.getItem(scope);

export const setLockToken = (scope: string, userId: string, lockTime?: string) => {
  const timeStamp = lockTime ?? new Date().toISOString();
  const token = `${userId}||${timeStamp}||${scope}`;
  window.sessionStorage.setItem(scope, token);

  return token;
};

export const resetLockToken = (scope: string) => {
  window.sessionStorage.removeItem(scope);
};

export const getScopeFromLockedId = (lockedId: string | null) => {
  if (!lockedId) return null;
  return lockedId.split('||')[2];
};

export const getUserIdFromLockedId = (lockedId: string | null) => {
  if (!lockedId) return null;
  return lockedId.split('||')[0];
};

export const getTimestampFromLockedId = (lockedId: string | null) => {
  if (!lockedId) return null;
  return lockedId.split('||')[1];
};
