import { gql, useQuery } from '@apollo/client';

import { MemberType } from 'types/graphqlTypes';

import { BLOCK_PROPS, memberToBlock } from './utils';

const GET_BLOCK = gql`
  query GetBlock($input: GetMemberInput) {
    getMember(input: $input) {
      ${BLOCK_PROPS}
    }
  }
`;

interface GetBlock {
  getMember: MemberType;
}

export const useGetBlock = (mId?: string, mRefId?: string) => {
  const { data, error, loading, refetch } = useQuery<GetBlock>(GET_BLOCK, {
    variables: {
      input: {
        mId,
        mRefId,
      },
    },
    fetchPolicy: 'cache-and-network',
    skip: !mId || !mRefId,
  });
  return {
    block: data?.getMember ? memberToBlock(data?.getMember) : null,
    error,
    loading,
    refetch,
  };
};
