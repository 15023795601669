import Checkbox from 'components/checkbox';
import Text from 'components/text/Text';
import Tooltip from 'components/tooltip';
import { FieldValue } from 'types/forms/forms';

import { FieldProps } from '../fields';

import { BooleanWrapper } from './styled';

export const getCheckboxValue = (value: FieldValue): boolean => {
  // Should never happen
  if (Array.isArray(value)) {
    return true;
  }

  if (typeof value === 'boolean') {
    return value;
  }

  return false;
};

export function CheckboxField({
  fieldModel,
  fieldSettings,
  defaultFieldSettings,
  editorId,
  value,
  setValue,
  style,
  disableEdit,
}: Readonly<FieldProps>) {
  const { fieldId } = fieldModel;
  const { label, hint } = fieldSettings ?? defaultFieldSettings;
  return (
    <Tooltip title={hint || ''}>
      <BooleanWrapper style={style}>
        <Checkbox
          size={20}
          selected={getCheckboxValue(value)}
          onClick={() => setValue(!getCheckboxValue(value))}
          id={`${editorId}-${fieldId}`}
          disabled={disableEdit}
        />
        <Text
          variant="listItemLabel"
          color="highEmphasis"
          as="label"
          htmlFor={`${editorId}-${fieldId}`}
          style={{ cursor: 'pointer', userSelect: 'none' }}
        >
          {label}
        </Text>
      </BooleanWrapper>
    </Tooltip>
  );
}
