import { gql, useQuery } from '@apollo/client';

import {
  ProviderConfig,
  ProviderConfigInput,
  ProviderInput,
  ProviderName,
  ProviderOutput,
} from './provider-types';

const GET_PROVIDER_CONFIG = gql`
  query GetProviderConfig($input: GetProviderConfigInput) {
    getProviderConfig(input: $input) {
      mId
    }
  }
`;

export const GET_PROVIDER = gql`
  query GetProvider($input: GetProviderInput) {
    getProvider(input: $input) {
      member {
        mId
        mType
      }
      providerDetails {
        id
        resourceUrl
        resourcePermit
      }
      notificationPreferences {
        title
        key
        value
        subPreferences {
          title
          key
          value
          subPreferences {
            title
            key
            value
          }
        }
      }
    }
  }
`;

interface GetProvider {
  getProvider: ProviderOutput;
}

interface GetProviderInput {
  input: ProviderInput;
}

interface GetProviderConfig {
  getProviderConfig: ProviderConfig;
}

interface GetProviderConfigInput {
  input: ProviderConfigInput;
}

/**
 * For now we assume Slack is enabled if the configuration for it exists at all.
 */
export const useGetProviderConfig = (providerName: ProviderName = 'slack') => {
  const { data, error, loading } = useQuery<GetProviderConfig, GetProviderConfigInput>(
    GET_PROVIDER_CONFIG,
    {
      variables: {
        input: {
          providerName,
        },
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  return { providerConfig: data?.getProviderConfig ?? null, error, loading };
};

export const useGetProvider = (
  memberId: string | undefined | null,
  providerName: ProviderName = 'slack',
) => {
  const { data, error, loading, refetch, startPolling, stopPolling } = useQuery<
    GetProvider,
    GetProviderInput
  >(GET_PROVIDER, {
    variables: {
      input: {
        memberId: memberId ?? '',
        providerName,
      },
    },
    fetchPolicy: 'cache-and-network',
    skip: !memberId,
  });

  return { provider: data?.getProvider, error, loading, refetch, startPolling, stopPolling };
};
