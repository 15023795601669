import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '48px',
    height: '28px',
    borderRadius: '6px',
    backgroundColor: theme.palette.dina.statusApproved,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& *': {
      fill: `${theme.palette.dina.whiteHighEmphasis} !important`,
      fillOpacity: 0.54,
    },
    '&:hover': {
      '-webkit-filter': theme.palette.dina.onHover,
      '& *': {
        fill: `${theme.palette.dina.whiteHighEmphasis} !important`,
        fillOpacity: 1,
      },
    },
  },
}));

export default useStyles;
