import { useCallback, useMemo } from 'react';
import differenceBy from 'lodash/differenceBy';

import useAssignInstance from 'hooks/useAssignInstance';
import useGetAssignedMembers from 'hooks/useGetAssignedMembers';
import useReferenceStabilizer from 'hooks/useReferenceStabilizer';
import { AssignedMember } from 'types';

import { useInstanceMolecule } from '../store/instance';

const useInstanceAssignees = () => {
  const { useInstanceValue } = useInstanceMolecule();
  const [addMembersToInstance] = useAssignInstance();

  const instance = useInstanceValue();

  const [getAssignedMembers] = useGetAssignedMembers(
    (instance?.mAssignedMembers ?? []) as AssignedMember[],
  );

  const assignedMembers = useMemo(() => {
    const [assignedUsers, assignedTeams, assignedDepartments] = getAssignedMembers();
    return [...assignedUsers, ...assignedTeams, ...assignedDepartments];
  }, [getAssignedMembers]);

  const stableAssignedMembers = useReferenceStabilizer(assignedMembers);

  const assignMembers = useCallback(
    async (members: AssignedMember[], messageAssignees?: string) => {
      const existingIds = stableAssignedMembers;
      const updatedIds = members.map(({ mId: id, mType }) => ({ mId: id, mType }));
      const removedIds = differenceBy(existingIds, updatedIds, 'mId');
      const addedIds = differenceBy(updatedIds, existingIds, 'mId');

      await addMembersToInstance(instance?.mId, addedIds, removedIds, updatedIds, messageAssignees);
    },
    [addMembersToInstance, stableAssignedMembers, instance?.mId],
  );

  const assignMemberToInstance = useCallback(
    async (members: { mId: string; mType: string }[]) => {
      const assignee = stableAssignedMembers;
      const addedIds = members.map(({ mId: id, mType }) => ({ mId: id, mType }));
      const updatedAssignees = [...assignee, ...addedIds];

      await addMembersToInstance(instance?.mId, addedIds, [], updatedAssignees);
    },
    [addMembersToInstance, stableAssignedMembers, instance?.mId],
  );

  return { assignedMembers: stableAssignedMembers, assignMembers, assignMemberToInstance };
};

export default useInstanceAssignees;
