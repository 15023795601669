import { Editor } from 'slate';

import { Update } from 'components/editor/types';
import { PlatformSectionConfig } from 'types/graphqlTypes';

import { elementTypes } from '../../../constants';
import { getMetaDataValueFromConfig } from '../../../utils/getMetaDataValueFromConfig';

import unwrapSection from './unwrapSection';
import wrapSection from './wrapSection';

/**
 *
 * @param editor SlateJS editor instance
 * @param update callback for updating states
 * @param config configuration data of the element from platform
 * @param unwrap Should unwrap the selection
 * @returns void
 */
const onWrapSection = (
  editor: Editor,
  update: Update,
  config: PlatformSectionConfig[] | undefined,
  unwrap: boolean,
) => {
  if (unwrap) {
    unwrapSection(editor, { split: true });
    return;
  }

  wrapSection({
    editor,
    update,
    data: {
      mTitle: getMetaDataValueFromConfig(config, elementTypes.SECTION_DIVIDER, 'defaultTitle'),
    },
  });
};

export default onWrapSection;
