import { memo } from 'react';
import { Tooltip } from '@material-ui/core';

import { StyledNativeButton } from 'features/reusableStyled';

import { CellProps } from '../fields';

function SubTypeCell({ value, onClick }: Readonly<CellProps>) {
  return (
    <Tooltip title="View fields in this group">
      <StyledNativeButton onClick={onClick}>
        {typeof value === 'string' ? value : ''}
      </StyledNativeButton>
    </Tooltip>
  );
}

export default memo(SubTypeCell);
