import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'components/switch';
import GeneralLabel from '../generalLabel';
import SeverityIndicator from '../severityIndicator';
import ContainerBox from '../containerBox';

const SwitchView = ({ severity, label, selected, onClick, usage, disableEdit }) => (
  <ContainerBox
    Indicator={<SeverityIndicator {...{ severity }} />}
    Label={<GeneralLabel {...{ severity, label, usage }} style={{ height: '38px' }} />}
    Field={<Switch {...{ selected, onClick }} disabled={disableEdit} />}
    usage={usage}
  />
);

SwitchView.propTypes = {
  /** label for the composite field */
  label: PropTypes.string,
  /** color for label, indicator and input underline */
  severity: PropTypes.string,
  /** whether the switch is selected or not */
  selected: PropTypes.bool,
  /** onClick callback function */
  onClick: PropTypes.func,
  /** Boolean that stops an user from editing */
  disableEdit: PropTypes.bool,
};

SwitchView.defaultProps = {
  label: 'Label',
  severity: 'regular',
  selected: false,
  onClick: () => {},
  disableEdit: false,
};
export default SwitchView;
