import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Popover } from '@material-ui/core';

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';

import {
  useChangedRundownGridViewColumns,
  useSelectedMenuSystemSettings,
} from '../../../../../../atoms';
import RundownColumn from './RundownColumn';
import {
  RootWrapper,
  MenuItem,
  StyledHeaderWrapper,
  StyledInput,
  MainRootWrapper,
  StyledAddIcon,
  StyledRemoveIcon,
} from './styled';

function ColumnsMenu({ fields, anchorEl, onClose, onColumnClicked }) {
  return (
    <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onClose}>
      {fields.map((field) => (
        <MenuItem id={field.id} key={field.id} onClick={() => onColumnClicked(field)}>
          {field.label ? field.label : 'N/A'}
        </MenuItem>
      ))}
    </Popover>
  );
}

function RundownColumnsViews(props) {
  const { selectedView, fields, onViewChanged, onViewDelete } = props;
  const [selectedMenu] = useSelectedMenuSystemSettings();
  const [changedRundownGridViewColumns, setChangedRundownGridViewColumns] =
    useChangedRundownGridViewColumns();
  const [gridColumns, setGridColumns] = useState([]);
  const [columnsMenu, setColumnsMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState(null);

  const { name = 'N/A' } = selectedView;
  const [viewName, setViewName] = useState(name);

  const findField = (id) => fields?.find((field) => field.id === id);
  const setChangedGridView = (gridViewFields) => {
    const newSelectedView = JSON.parse(JSON.stringify(selectedView));
    const view = newSelectedView.columndefs.find((column) => column.variant === 'normal');
    view.columns = gridViewFields;
    if (
      changedRundownGridViewColumns.length > 0 &&
      changedRundownGridViewColumns.findIndex(
        (changedRundownView) => changedRundownView.id === selectedView.id,
      ) > -1
    ) {
      const indexOFexistingViews = changedRundownGridViewColumns.findIndex(
        (changedRundownView) => changedRundownView.id === selectedView.id,
      );
      changedRundownGridViewColumns[indexOFexistingViews] = newSelectedView;
      setChangedRundownGridViewColumns(changedRundownGridViewColumns);
      return;
    }

    const changedViews = [...changedRundownGridViewColumns, newSelectedView];
    setChangedRundownGridViewColumns(changedViews);
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const getGridColumns = () => {
    const columns = [];
    const normalColumns = selectedView.columndefs.find((column) => column.variant === 'normal');
    normalColumns?.columns.forEach((column) => {
      const nColumn = { ...column };
      if (!nColumn.label) nColumn.label = findField(nColumn.id)?.label;
      columns.push(nColumn);
    });
    return columns;
  };

  useEffect(() => {
    // setTableRows(getRows());
    setGridColumns(getGridColumns());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMenu]);

  const handleDragEnd = (event) => {
    const { active, over } = event;

    const oldIndex = gridColumns.findIndex((item) => item.id === active.id);
    const newIndex = gridColumns.findIndex((item) => item.id === over.id);
    const updatedOrder = arrayMove(gridColumns, oldIndex, newIndex);
    setGridColumns(updatedOrder);
    setChangedGridView(updatedOrder);
  };
  const handleColumnChange = (column, label, width) => {
    const replaceColumn = gridColumns.find((item) => item.id === column.id);
    const index = gridColumns.indexOf(replaceColumn);
    const nColumn = { ...column };
    nColumn.label = label;
    nColumn.width = width;
    gridColumns[index] = nColumn;
    setChangedGridView(gridColumns);
  };

  const handleOpenColumnsMenu = (e, column) => {
    setAnchorEl(e.currentTarget);
    setColumnsMenu(true);
    setSelectedColumn(column);
  };
  const getSortedFields = () => {
    const sortedFields = [];
    fields.forEach((field) => {
      const exist = gridColumns.find((column) => column.id === field.id);
      if (!exist) sortedFields.push(field);
    });
    sortedFields.sort((a, b) => {
      if (a.label?.toLowerCase() < b.label?.toLowerCase()) {
        return -1;
      }
      if (a.label?.toLowerCase() > b.label?.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    return sortedFields;
  };
  const getColumnWidth = (column) => {
    const { width, type } = column;
    if (type === 'datetime') return '70';
    if (!width) return '45';
    return width;
  };
  const handleAddColumn = (field) => {
    const { id, label, style = '', readonly, name: uName, group = '', severity, columnId } = field;

    const newColumn = {
      id,
      label,
      width: getColumnWidth(field),
      style,
      group,
      severity,
      readonly,
      name: uName,
      columnId,
    };

    const newColumns = [...gridColumns];
    newColumns.splice(gridColumns.indexOf(selectedColumn), 0, newColumn);

    // const newColumns = [newColumn, ...gridColumns];
    setGridColumns(newColumns);
    setChangedGridView(newColumns);
    setAnchorEl(null);
  };

  const handleRemoveColumn = (column) => {
    const newColumns = gridColumns.filter((item) => item.id !== column?.id);
    setGridColumns(newColumns);
    setChangedGridView(newColumns);
  };

  const handleViewNameKeyup = (e) => {
    if (e.key === 'Enter' && viewName !== selectedView?.name) {
      const newSelectedView = JSON.parse(JSON.stringify(selectedView));
      newSelectedView.name = viewName;
      onViewChanged(newSelectedView);
      e.target.blur();
    }
  };
  const onBlur = (e) => {
    if (viewName !== selectedView?.name) {
      const newSelectedView = JSON.parse(JSON.stringify(selectedView));
      newSelectedView.name = viewName;
      onViewChanged(newSelectedView);
    }
  };

  return (
    <MainRootWrapper>
      <StyledHeaderWrapper>
        {selectedView.id !== 'rundown-grid-1' && (
          <StyledRemoveIcon
            title="Remove view"
            onClick={() => onViewDelete(selectedView.id, viewName)}
          />
        )}
        <StyledInput
          value={viewName}
          onChange={(e) => setViewName(e.target.value)}
          onKeyUp={(e) => handleViewNameKeyup(e)}
          onBlur={(e) => onBlur(e)}
        />
      </StyledHeaderWrapper>

      <RootWrapper>
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext items={gridColumns} strategy={horizontalListSortingStrategy}>
            {gridColumns.map((column) => (
              <RundownColumn
                key={column.id}
                field={column}
                fields={fields}
                onColumnChange={handleColumnChange}
                selectedColumn={selectedColumn}
                setSelectedColumn={setSelectedColumn}
                onRemoveColumn={handleRemoveColumn}
                onOpenColumnsMenu={handleOpenColumnsMenu}
              />
            ))}
            {gridColumns.length === 0 && (
              <StyledAddIcon title="Add column" onClick={handleOpenColumnsMenu} />
            )}
          </SortableContext>
        </DndContext>
      </RootWrapper>
      {columnsMenu && (
        <ColumnsMenu
          fields={getSortedFields()}
          anchorEl={anchorEl}
          onClose={() => setColumnsMenu(false)}
          onColumnClicked={handleAddColumn}
        />
      )}
    </MainRootWrapper>
  );
}

RundownColumnsViews.propTypes = {
  fields: PropTypes.array,

  selectedView: PropTypes.object,
};

RundownColumnsViews.defaultProps = {
  fields: {},

  selectedView: '',
};

export default RundownColumnsViews;
