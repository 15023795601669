import styled from '@emotion/styled/macro';

import transientOptions from 'theme/helpers';

interface RootProps {
  $showRightMargin: boolean;
  $rightMarginWidth: number;
}

// eslint-disable-next-line import/prefer-default-export
export const Root = styled('div', transientOptions)<RootProps>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  > * {
    cursor: pointer;
  }
  > *:not(:last-child) {
    margin-right: ${({ $showRightMargin, $rightMarginWidth }) => {
      if (!$showRightMargin) return 'none';
      return `${$rightMarginWidth}px`;
    }};
  }
  @container toolbar (max-width: 305px) {
    &.list-button {
      display: none;
    }
  }
`;
