import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.dina.buttonBackgroundHighEmphasis,
    borderRadius: '4px',
    height: ({ height }) => height,
    width: ({ width }) => width,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      '-webkit-filter': theme.palette.dina.onHover,
    },
  },
  disabled: {
    userSelect: 'none',
    pointerEvents: 'none',
    opacity: '0.5',
  },
  icon: {
    marginLeft: '8px',
    marginRight: '4px',
  },
  label: {
    ...theme.typography.dina.button,
    fontWeight: 'normal',
  },
}));

export default useStyles;
