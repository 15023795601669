import styled from '@emotion/styled';
import { ReactComponent as UserIconComponent } from 'assets/icons/systemicons/user.svg';

export const AutoCompleteWrapper = styled('div')`
  width: 100%;
`;

export const ChipListWrapper = styled('div')`
  list-style: none;
  margin-bottom: 16px;
  li {
    margin-top: 4px;
  }
`;

export const UserIcon = styled(UserIconComponent)`
  width: 24px;
  height: 24px;
  margin: 6px;
`;
