/**
 * Determines given text is mos or not
 *
 * @param {String} text Text to be tested for mos
 * @returns {Boolean} True if the text is mos, False otherwise
 */

const isMos = (data) => {
  if (typeof data !== 'string') return false;
  return data.substring(0, 5) === '<mos>';
};

export default isMos;
