import styled from '@emotion/styled/macro';
import { Typography } from '@material-ui/core';

export const ContentWrapper = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const TitleWrapper = styled('div')`
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  padding-inline: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

export const Title = styled(Typography)`
  ${({ theme }) => theme.typography.dina.h6};
`;

export const OptionsWrapper = styled('div')`
  flex: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Subtitle = styled(Typography)`
  ${({ theme }) => theme.typography.dina.overline};
`;

export const Options = styled('div')`
  flex: 1;
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
`;

export const OptionImage = styled('img')`
  width: 256px;
  height: 144px;
  border: 3px solid transparent;
  filter: ${({ theme }) =>
    theme.palette.mode === 'light'
      ? `drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.15)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15))`
      : `drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25)) 
      drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))`};
  border-radius: 8px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

export const Option = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  cursor: pointer;
  &:hover ${OptionImage} {
    border: 3px solid ${({ theme }) => theme.palette.dina.onSelected};
  }
`;

export const RadioButtonWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const RadioTitle = styled(Typography)`
  ${({ theme }) => theme.typography.dina.listitemLabel};
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
`;
