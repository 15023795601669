import { Editor } from 'slate';

import { CustomElement } from 'types';

import isParagraph from '../components/paragraph/utils/isParagraph';

export const isEmptyEditor = (editor: Editor) => {
  if (editor.children.length !== 1) return false;

  const isPara = isParagraph(editor.children[0] as CustomElement);

  if (!isPara) return false;

  const { isEmpty } = editor;
  const isEmptyPara = isEmpty(editor.children[0] as CustomElement);

  if (!isEmptyPara) return false;

  return true;
};
export default isEmptyEditor;
