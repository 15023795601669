/* eslint-disable no-param-reassign */
import { format as fnsFormat } from 'date-fns';

const ONE_DAY = 86400;

const stringToInt = (timeString) => {
  if (typeof timeString !== 'string') return timeString;

  if (!timeString || timeString === '') return null;

  if (timeString === 'now') return inputToInt(new Date());

  timeString = timeString.toLowerCase().replace(/[\s.]/g, '');

  // if the last character is an "a" or "p", add the "m"
  if (timeString.slice(-1) === 'a' || timeString.slice(-1) === 'p') timeString += 'm';

  const pattern = timeString.match(/\W/)
    ? /^(([^0-9]*))?([0-9]?[0-9])(\W+([0-5][0-9]?))?(\W+([0-5][0-9]))?(([^0-9]*))$/
    : /^(([^0-9]*))?([0-9]?[0-9])(([0-5][0-9]))?(([0-5][0-9]))?(([^0-9]*))$/;

  const time = timeString.match(pattern);

  if (!time) return null;

  const hour = parseInt(time[3] * 1, 10);
  let meridiem = time[2] || time[9];
  let hours = hour;
  const minutes = time[5] * 1 || 0;
  const seconds = time[7] * 1 || 0;

  if (!meridiem && time[3].length === 2 && time[3][0] === '0') {
    // preceding '0' implies AM
    meridiem = 'am';
  }

  if (hour <= 12 && meridiem) {
    meridiem = meridiem.trim();
    const isPm = meridiem === 'pm' || meridiem === 'PM';
    if (hour === 12) hours = isPm ? 12 : 0;
    else hours = hour + (isPm ? 12 : 0);
  } else {
    const t = hour * 3600 + minutes * 60 + seconds;
    if (t >= ONE_DAY) hours = hour % 24;
  }

  let timeInt = hours * 3600 + minutes * 60 + seconds;

  if (hour < 12 && !meridiem && timeInt < 0 && timeInt >= ONE_DAY / -2) {
    timeInt = (timeInt + ONE_DAY / 2) % ONE_DAY;
  }

  return timeInt;
};

const inputToInt = (input) => {
  if (typeof input === 'number') {
    return input;
  }
  if (typeof input === 'string') {
    return stringToInt(input);
  }
  if (typeof input === 'object' && input instanceof Date) {
    return input.getHours() * 3600 + input.getMinutes() * 60 + input.getSeconds();
  }
  return null;
};

const round = (seconds, interval) => {
  if (seconds === null) {
    return null;
  }
  if (typeof interval !== 'number') {
    return seconds;
  }
  const offset = seconds % (interval * 60); // step is in minutes

  if (offset >= interval * 30) {
    // if offset is larger than a half step, round up
    seconds += interval * 60 - offset;
  } else {
    // round down
    seconds -= offset;
  }
  return seconds % ONE_DAY;
};

const intToTime = (timeInt, format) => {
  if (typeof timeInt !== 'number') return null;

  const seconds = parseInt(timeInt % 60, 10);
  const minutes = parseInt((timeInt / 60) % 60, 10);
  const hours = parseInt((timeInt / (60 * 60)) % 24, 10);

  const time = new Date(1971, 0, 2, hours, minutes, seconds, 0);

  if (isNaN(time.getTime())) return null;
  if (format === 12) {
    return fnsFormat(time, 'hh:mma');
  }
  return fnsFormat(time, 'HH:mm');
};

const stringToTime = (input, interval = 15, format = 24) =>
  intToTime(round(inputToInt(input), interval), format);

export default stringToTime;
