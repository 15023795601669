import {
  closestCenter,
  DndContext,
  type DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove } from '@dnd-kit/sortable';

import { useUpdateBoardColumns } from 'api/gridAndKanban/useUpdateBoardColumns';

interface DndWrapperProps {
  children: React.ReactNode;
  itemIds?: string[];
  tableId: string;
  itemGroupId?: string;
}

/** The distance the item must be dragged before it is activated */
const activationConstraint = {
  distance: 30,
};

const dndModifiers = [restrictToVerticalAxis];

/** Reorder wrapper for rows */
export function ReorderRows({
  children,
  itemIds = [],
  tableId,
  itemGroupId,
}: Readonly<DndWrapperProps>) {
  const { reorderMembers } = useUpdateBoardColumns(tableId);

  // Update the order of the items
  const updateOrder = async (sourceId: string, targetId: string) => {
    if (!itemGroupId) return;
    const sourceIndex = itemIds.indexOf(sourceId);
    const targetIndex = itemIds.indexOf(targetId);
    const mOrder = arrayMove(itemIds, sourceIndex, targetIndex);
    await reorderMembers(mOrder, itemGroupId);
  };

  // Drag end handler
  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      void updateOrder(active.id as string, over.id as string);
    }
  };

  // Configure sensors that trigger the drag events
  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint }),
    useSensor(TouchSensor, { activationConstraint }),
    useSensor(KeyboardSensor, {}),
  );

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={dndModifiers}
      onDragEnd={onDragEnd}
      sensors={sensors}
    >
      {children}
    </DndContext>
  );
}
