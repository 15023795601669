import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { IconButton as MaterialIconButton } from '@material-ui/core';
import tag from 'assets/icons/systemicons/editor/user_tag.svg';
import useStyles from './imageComponents-styles';

const IconButton = memo(MaterialIconButton);

const ImageComponents = ({ showTagButton, onTag }) => {
  const classes = useStyles();
  if (!showTagButton) return null;
  return (
    <IconButton className={classes.tagButton} onClick={onTag}>
      <img src={tag} alt="close" className={classes.tagImage} />
    </IconButton>
  );
};

ImageComponents.propTypes = {
  /** Boolean to show tag button for image component */
  showTagButton: PropTypes.bool,
  /** Callback for tag button click */
  onTag: PropTypes.func,
};

ImageComponents.defaultProps = {
  showTagButton: false,
  onTag: () => {},
};

export default memo(ImageComponents);
