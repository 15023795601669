import styled from '@emotion/styled/macro';
import { ReactComponent as FactBoxIconComponent } from 'assets/icons/systemicons/editor/blocks_FactBox_off.svg';

export const FactBoxIcon = styled(FactBoxIconComponent)`
  width: 24px;
  height: 24px;
  margin: 8px;
`;

export const InputGroupWrapper = styled('div')`
  margin-bottom: 16px;
`;
