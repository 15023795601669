import { memo, useMemo } from 'react';
import { ScopeProvider } from 'jotai-molecules';

import FilterComponent from 'features/feedDeck/components/filters';
import { kanbanScope } from 'features/kanban/store/kanban';
import FeedDeck from 'features/spaceDeck';
import WidgetWrapper, { FilterComponentType } from 'features/widget/components/WidgetWrapper';
import { WIDGETS } from 'features/widget/constants';
import { useFeedSources } from 'store';
import { useSetPreview } from 'store/preview';

import { FeedWidgetProps } from './types';
import { getPropFilters } from './utils';

function FeedWidget({
  layout,
  mId,
  mRefId,
  title,
  filters,
  providers,
  federatedSearchString,
  writeAccess,
}: Readonly<FeedWidgetProps>) {
  const setPreview = useSetPreview();

  const memoizedFilters = useMemo(() => {
    const propFilters = getPropFilters({ filters });
    return propFilters;
  }, [filters]);

  const [allProviders] = useFeedSources();

  const activeFilters = useMemo(() => {
    return memoizedFilters && Object.keys(memoizedFilters).length > 0;
  }, [memoizedFilters]);

  // Currently missing count and ticker func that is available in feeds view

  return (
    <ScopeProvider scope={kanbanScope} value={mId}>
      <WidgetWrapper
        mRefId={mRefId}
        layout={layout}
        mId={mId}
        title={title}
        writeAccess={writeAccess}
        filterComponent={FilterComponent as FilterComponentType}
        filters={memoizedFilters}
        providers={providers}
        allProviders={allProviders}
        activeFilters={activeFilters}
        type={WIDGETS.FEED}
      >
        <FeedDeck
          providers={providers}
          filters={memoizedFilters}
          freeText={federatedSearchString}
          allProviders={allProviders}
          openInModal={setPreview}
        />
      </WidgetWrapper>
    </ScopeProvider>
  );
}

export default memo(FeedWidget);
