import React, { useEffect, useState } from 'react';

import Text from 'components/text/Text';
import Tooltip from 'components/tooltip';
import { FieldValue } from 'types/forms/forms';

import { FieldHeader } from '../../styled';
import { FieldProps } from '../fields';
import { StyledTextField, TextWrapper } from '../text/styled';

import { Link } from './LinkDefault';

export const getTextValue = (value: FieldValue): string => {
  // Should not happen - lets normalize.
  if (Array.isArray(value)) {
    return '';
  }

  if (typeof value === 'string') {
    return value;
  }

  return '';
};

export function LinkField({
  fieldModel,
  fieldSettings,
  defaultFieldSettings,
  value,
  setValue,
  style,
  errorMessage,
  autoFocus,
}: FieldProps) {
  const [liveValue, setLiveValue] = useState('');
  const [editing, setEditing] = useState(false);
  const { fieldId } = fieldModel;
  const { hint, label, multiline = false, fieldHeight = 3 } = fieldSettings ?? defaultFieldSettings;

  useEffect(() => {
    if (autoFocus) {
      setEditing(true);
    }
  }, [autoFocus]);

  useEffect(() => {
    setLiveValue(getTextValue(value));
  }, [value]);

  const doSave = () => {
    setValue(liveValue);
    setEditing(false);
  };

  const onKeyUp = (ev: React.KeyboardEvent) => {
    if (ev.key === 'Enter') {
      doSave();
    }
  };

  return (
    <Tooltip title={hint ?? ''}>
      <TextWrapper key={fieldId} style={style}>
        <FieldHeader>{label}</FieldHeader>
        {editing ? (
          <StyledTextField
            variant="filled"
            placeholder="Enter URL here"
            onChange={(ev) => setLiveValue(ev.target.value)}
            value={liveValue}
            multiline={multiline}
            minRows={fieldHeight}
            autoFocus={true}
            onFocus={(ev) => ev.target.select()}
            onKeyUp={onKeyUp}
            maxRows={20}
            error={Boolean(errorMessage)}
            helperText={errorMessage?.length ? errorMessage : undefined}
            inputProps={{
              onBlur: () => {
                doSave();
              },
            }}
          />
        ) : (
          <div>
            <Link url={liveValue} hint={hint} onEditClick={() => setEditing(true)} />
            {errorMessage && (
              <Text variant="caption" color="statusWarning">
                {errorMessage}
              </Text>
            )}
          </div>
        )}
      </TextWrapper>
    </Tooltip>
  );
}
