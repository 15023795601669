import styled from '@emotion/styled';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Divider from 'components/divider';

export const StyledMenu = styled(Menu)`
  .MuiMenu-list {
    border-radius: 4px;
    padding: 8px 1px;
    background: ${({ theme }) => theme.palette.dina.surfaceCardDark};
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  min-height: 32px;
  width: 100%;
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
  padding: 0 1rem;

  &.Mui-disabled {
    opacity: 0.19;
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.dina.outlineButtonOnHover};
  }

  &.Mui-selected {
    background-color: ${({ theme }) => theme.palette.dina.onSelected};

    &:hover {
      background-color: ${({ theme }) => theme.palette.dina.onSelected};
    }
  }
`;

export const StyledMenuItemText = styled(ListItemText)`
  span {
    ${({ theme }) => theme.typography.dina.listItemLabel};
    width: 100% !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StyledDivider = styled(Divider)`
  width: 100%;
  margin: 8px 0 8px;
`;
