import Skeleton from '@material-ui/lab/Skeleton';

import { HStack, VStack } from 'layouts/box/Box';

function LeftColumnSkeleton() {
  return (
    <VStack height="100%" width="100%" padding="12px 12px" justifyContent="flex-start" gap="12px">
      {[...Array(15).keys()].map((i) => (
        <HStack width="100%" gap="8px" key={i}>
          <Skeleton animation="wave" variant="rect" height={20} width={24} />
          <Skeleton animation="wave" width="100%" />
        </HStack>
      ))}
    </VStack>
  );
}

export default LeftColumnSkeleton;
