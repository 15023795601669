import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Video } from 'assets/icons/systemicons/editor/video.svg';
import tooltipEnum from 'components/editor/constants/tooltipEnum';
import { useSlateStatic } from 'slate-react';
import useFileUpload from 'hooks/useFileUpload';
import { useEditorContext } from 'components/editor/EditorContext';
import insertVideoBlock from 'components/editor/components/audioVideo/utils/insertVideoBlock';
import insertVideo from 'components/editor/components/audioVideo/utils/insertVideo';
import normalizeOnListFocus from 'components/editor/utils/normalizeOnListFocus';

const mimeTypes = ['video/mp4'];

const VideoButton = () => {
  const editor = useSlateStatic();
  const { update, isAllowed } = useEditorContext();

  const onFileUpload = useCallback(
    (file, fileUrl) => insertVideo(editor, update, file, fileUrl),
    [editor, update],
  );

  const uploadFile = useFileUpload(mimeTypes, onFileUpload, false, 'videoButton');

  const onMouseDown = useCallback(
    (event) => {
      event.preventDefault();
      normalizeOnListFocus(editor);
      if (isAllowed) {
        insertVideoBlock(editor, update);
      } else uploadFile();
    },
    [editor, isAllowed, update, uploadFile],
  );

  return <Video {...{ onMouseDown }} title={tooltipEnum.VIDEO} />;
};

VideoButton.propTypes = {
  /** Callback to invoked when text editor's value updates,
   * with the update type and relevant payload passed in
   */
  update: PropTypes.func,
};

VideoButton.defaultProps = {
  update: ({ type, payload }) => {},
};

export default memo(VideoButton);
