import { useMemo } from 'react';

import useCheckUserRight from 'hooks/useCheckUserRight';

import { useInstanceMolecule } from '../store/instance';

import useCheckPlatformPermission from './useCheckPlatformPermission';

const useInstancePermissions = () => {
  const [checkUserRight] = useCheckUserRight();
  const { useInstanceValue } = useInstanceMolecule();

  const { checkPlatformPermission } = useCheckPlatformPermission();

  const instance = useInstanceValue();

  const hasPermissionForPlatformAccount = useMemo(
    () => checkPlatformPermission(instance),
    [checkPlatformPermission, instance, instance?.mProperties],
  );

  return useMemo(() => {
    const canUpdateInstance =
      checkUserRight('instance', 'update') && hasPermissionForPlatformAccount;
    const canCreateNewTemplate =
      checkUserRight('instance', 'create-template') && hasPermissionForPlatformAccount;
    const canDeleteTemplate =
      checkUserRight('instance', 'delete-template') && hasPermissionForPlatformAccount;
    const canSetDefaultTemplate =
      checkUserRight('instance', 'set-default-template') && hasPermissionForPlatformAccount;
    const canReorderTemplates =
      checkUserRight('instance', 'allow-reordering-templates') && hasPermissionForPlatformAccount;
    const canDeleteTemplateFolder =
      checkUserRight('instance', 'delete-template-folder') && hasPermissionForPlatformAccount;
    const canPinTemplateFolder =
      checkUserRight('instance', 'allow-pinning-template-folder') &&
      hasPermissionForPlatformAccount;
    const canCreateInstance =
      checkUserRight('instance', 'create') && hasPermissionForPlatformAccount;
    const canScheduleInstance =
      checkUserRight('instance', 'schedule') && hasPermissionForPlatformAccount;
    const canShowCmsIframe =
      checkUserRight('feature', 'cms-iframe-design') && hasPermissionForPlatformAccount;
    const canUploadMediaBySignedURL =
      checkUserRight('feature', 'upload-media') && hasPermissionForPlatformAccount;
    const canEditReadyInstance = checkUserRight('rundown', 'edit-ready-instances');
    const canShowNewDesign = checkUserRight('feature', 'new-some-design');
    const canPreviewInstance = checkUserRight('feature', 'preview-instance');
    const canDisableSchedule = checkUserRight('feature', 'disable-schedule-on-edit');
    const canRepublish = checkUserRight('feature', 'republish-workflow');
    const canDeleteInstance =
      checkUserRight('instance', 'delete') && hasPermissionForPlatformAccount;

    return {
      canUpdateInstance,
      canCreateInstance,
      canEditReadyInstance,
      canUploadMediaBySignedURL,
      canCreateNewTemplate,
      canReorderTemplates,
      canSetDefaultTemplate,
      canDeleteTemplate,
      canDeleteTemplateFolder,
      canPinTemplateFolder,
      canShowNewDesign,
      canShowCmsIframe,
      canScheduleInstance,
      canPreviewInstance,
      canDisableSchedule,
      canRepublish,
      canDeleteInstance,
    };
  }, [checkUserRight, hasPermissionForPlatformAccount]);
};

export default useInstancePermissions;
