import { useState } from 'react';

import { ReactComponent as Copy } from 'assets/icons/systemicons/copy.svg';
import { ReactComponent as Edit } from 'assets/icons/systemicons/edit.svg';
import { IconButton } from 'components/buttons';
import Text from 'components/text/Text';
import Tooltip from 'components/tooltip';

import { IconWrapper, LinkWrapper, StyledA } from './styled';

interface LinkProps {
  url: string | undefined;
  hint: string | undefined;
  onEditClick: () => void;
}

export function Link({ url, hint, onEditClick }: Readonly<LinkProps>) {
  const [copyTooltip, setCopyTooltip] = useState('Copy value');
  const doCopy = (v: string | undefined) => {
    void navigator.clipboard.writeText(v ?? '');
    setCopyTooltip('Copied!');
    setTimeout(() => setCopyTooltip('Copy value'), 2000);
  };

  return (
    <Tooltip title={url && url.length > 0 ? 'Click to open in a new tab' : 'Edit link'}>
      <LinkWrapper $url={!!url}>
        {url && url.length > 0 ? (
          <>
            <StyledA href={url} target="_blank" rel="noreferrer" title={hint ?? url}>
              {url}
            </StyledA>
            <IconWrapper className="edit-icn">
              <IconButton title="Edit" usage="text" size={24} iconSize={16} onClick={onEditClick}>
                <Edit />
              </IconButton>
              <IconButton
                title={copyTooltip}
                usage="text"
                size={24}
                iconSize={16}
                onClick={() => doCopy(url)}
              >
                <Copy />
              </IconButton>
            </IconWrapper>
          </>
        ) : (
          <Text variant="listItemLabel" color="mediumEmphasis" onClick={onEditClick}>
            Set a link
          </Text>
        )}
      </LinkWrapper>
    </Tooltip>
  );
}
