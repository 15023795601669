import { Editor } from 'slate';

/**
 * Toggles the specified mark
 *
 * @param editor SlateJS editor instance
 * @param type Type for the mark to toggle
 * @param isActive Specifies if the given mark type is active or not
 * @param value Value for the mark
 * @returns SlateJS editor instance
 */

const toggleMark = (
  editor: Editor,
  type: string,
  isActive = false,
  value: string | boolean = true,
) => {
  if (isActive) Editor.removeMark(editor, type);
  else Editor.addMark(editor, type, value);

  return editor;
};

export default toggleMark;
