import { memo } from 'react';

import { elementTypes, markTypes } from 'components/editor/constants';
import { ToolbarWrapper } from 'components/messageInput/messageInput-styled';

import DoneButton from '../doneButton';
import EmojiPicker from '../emojiPicker';
import LinkButton from '../linkButton';
import ListButton from '../listButton';
import MarkButton from '../markButton';
import VerticalDivider from '../verticalDivider';
import Wrapper from '../wrapper';

const { BOLD, ITALIC, UNDERLINE, STRIKE_THROUGH } = markTypes;

const { UNORDERED_LIST, ORDERED_LIST } = elementTypes;

const MessageToolbar = ({ showDoneButton }: { showDoneButton?: boolean }) => (
  <>
    <ToolbarWrapper>
      <Wrapper showRightMargin rightMarginWidth={8}>
        <MarkButton type={BOLD} />
        <MarkButton type={ITALIC} />
        <MarkButton type={UNDERLINE} />
        <MarkButton type={STRIKE_THROUGH} />
      </Wrapper>
      <VerticalDivider />
      <LinkButton />
      <Wrapper className="list-button" showRightMargin rightMarginWidth={8}>
        <VerticalDivider />
        <ListButton type={ORDERED_LIST} />
        <ListButton type={UNORDERED_LIST} />
      </Wrapper>
      <Wrapper>
        <VerticalDivider />
        <EmojiPicker />
      </Wrapper>
    </ToolbarWrapper>
    {showDoneButton && (
      <Wrapper>
        <DoneButton title="Send Message" />
      </Wrapper>
    )}
  </>
);

export default memo(MessageToolbar);
