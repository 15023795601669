import styled from '@emotion/styled';

export const RootWrapper = styled('div')`
  height: 50px;
  align-items: center;
  display: flex;
  user-select: none;
  background: ${({ theme }) => theme.palette.dina.backgroundResting};
`;

export const GroupHeaderWrapper = styled('div')`
  ${({ theme }) => theme.typography.dina.h6}
  display: flex;
  cursor: pointer;
  padding-left: 12px;
  :hover {
    path {
      fill: ${({ theme }) => theme.palette.dina.highEmphasis};
      fill-opacity: 1;
    }
  }
`;
