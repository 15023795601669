import { useEffect } from 'react';

interface CookieCleanupOptions {
  cookiePrefix: string;
  excludedPath: string;
}

const useCookieCleanup = ({ cookiePrefix, excludedPath }: CookieCleanupOptions) => {
  const removeCookie = (name: string, path: string) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path}`;
  };

  const handleBeforeUnload = () => {
    const existingCookies = document.cookie.split('; ');

    existingCookies.forEach((cookie) => {
      const [cookieName, cookieValue] = cookie.split('=');

      /* Check if the cookie starts with specified prefix and has path other than excluded path */
      if (cookieName.startsWith(cookiePrefix) && cookieValue && !cookie.includes(excludedPath)) {
        removeCookie(cookieName, '/');
      }
    });
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [cookiePrefix, excludedPath]);
};

export default useCookieCleanup;
