import { memo, useCallback, useMemo } from 'react';
import { capitalize } from 'lodash';

import Tooltip from 'components/tooltip';
import { useEditFieldValueDialog } from 'features/mdf/mdf-utils';
import { EmptyValueWrapper } from 'features/orderForm/styled';
import { LayoutSettings, MdfField } from 'types/graphqlTypes';

import { CellProps } from '../fields';

import { isMiniMemberArray } from './relation-utils';

const getDefaultLayoutSettings = (field: MdfField): LayoutSettings => {
  return {
    fieldId: field.fieldId,
    label: capitalize(field.fieldId),
    hint: '',
    visible: true,
  };
};

const getTypedValue = (value: unknown) => {
  return isMiniMemberArray(value) ? value : [];
};

function RelationCell({
  fieldModel,
  disableEdit,
  fieldSettings,
  value: rawValue,
  mdf,
  setValue,
}: Readonly<CellProps>) {
  const [, showEditFieldDialog] = useEditFieldValueDialog();
  const { hint } = fieldSettings ?? getDefaultLayoutSettings(fieldModel);
  const value = useMemo(() => {
    return getTypedValue(rawValue);
  }, [rawValue]);

  const showFieldEditor = useCallback(() => {
    if (!disableEdit) {
      showEditFieldDialog({
        startValue: value,
        fieldId: fieldModel.fieldId,
        headerText: `Edit ${fieldSettings?.label ?? fieldModel.fieldId}`,
        viewType: 'order_grid',
        mdf,
        onConfirm: (v) => setValue(v),
      });
    }
  }, [showEditFieldDialog, setValue, disableEdit, mdf, fieldModel, fieldSettings, value]);
  const text = value.length > 1 ? '(s)' : '';
  return (
    <Tooltip title={disableEdit ? 'You are not allowed to edit this field' : hint}>
      <EmptyValueWrapper onClick={() => showFieldEditor()} $disabled={disableEdit}>
        {value.length ? `${value.length} related item${text}` : 'Add relations'}
      </EmptyValueWrapper>
    </Tooltip>
  );
}

export default memo(RelationCell);
