import { useContext, useState } from 'react';
import uuidv4 from 'uuid/v4';
import PropTypes from 'prop-types';

import configCtx from 'contexts/configContext';
import Scrollbar from 'components/scrollbar/scrollbar';
import { DeleteDialog } from 'components/dialogs/CommonDialogs';
import { useChangedRundownGridViews } from '../../../../../atoms';
import {
  MainRootWrapper,
  RootWrapper,
  TableHead,
  HeaderLabel,
  StyledAddIcon,
  ColumnsViewWrapper,
} from './styled';
import RundownColumns from './rundownColumns';

function Rundownviews(props) {
  const { fields } = props;
  const { metadataForms } = useContext(configCtx);
  const [, setChangedRundownGridViews] = useChangedRundownGridViews();
  const { views } = metadataForms[0];
  const defaultView = views.find((view) => view.type === 'grid');
  const [selectedViews, setSelectedViews] = useState(views.filter((view) => view.type === 'grid'));
  const [viewToDelete, setViewToDelete] = useState(null);

  const handleAddGridViews = () => {
    const newGridViewId = uuidv4();
    const newView = { ...defaultView, id: newGridViewId, name: 'New grid view' };
    selectedViews.push(newView);
    setSelectedViews([...selectedViews]);
    setChangedRundownGridViews([...selectedViews]);
  };

  const openConfirmation = (viewId, viewName) => {
    setViewToDelete(viewId);
  };

  const closeConfirmation = () => {
    setViewToDelete(null);
  };

  const handleRemoveRundownGridView = () => {
    const newViews = selectedViews.filter((item) => item.id !== viewToDelete);
    setSelectedViews([...newViews]);
    setChangedRundownGridViews([...newViews]);
    closeConfirmation();
  };

  const handleUpdateRundownGridView = (updatedView) => {
    const newViews = selectedViews.map((view) => {
      if (view.id === updatedView.id) {
        return updatedView;
      }
      return view;
    });
    setSelectedViews([...newViews]);
    setChangedRundownGridViews([...newViews]);
  };

  const getViewName = () => {
    const view = views.find((v) => v.id === viewToDelete);
    return view ? view.name : '';
  };

  return (
    <RootWrapper>
      <TableHead>
        <HeaderLabel>Rundown grid views</HeaderLabel>
      </TableHead>
      <Scrollbar showHorizontal>
        <MainRootWrapper>
          {selectedViews.map((view) => (
            <ColumnsViewWrapper key={view.id}>
              <RundownColumns
                fields={fields}
                selectedView={view || defaultView}
                onViewChanged={handleUpdateRundownGridView}
                onViewDelete={openConfirmation}
              />
            </ColumnsViewWrapper>
          ))}
          <StyledAddIcon title="Add grid view" onClick={handleAddGridViews} />
        </MainRootWrapper>
        <DeleteDialog
          open={Boolean(viewToDelete)}
          onClose={closeConfirmation}
          onClick={handleRemoveRundownGridView}
          title="Delete grid view"
          message={`Are you sure you want to delete the ${getViewName()} grid view?`}
        />
      </Scrollbar>
    </RootWrapper>
  );
}

Rundownviews.propTypes = {
  fields: PropTypes.array,
};

Rundownviews.defaultProps = {
  fields: {},
};

export default Rundownviews;
