import styled from '@emotion/styled';

export const IconWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
`;

export const ActionWrapper = styled('div')`
  display: flex;
  flex-direction: row;
`;
