import styled from '@emotion/styled';

import { ReactComponent as DinaIcon } from 'assets/images/DinaIcon.svg';
import LoadingIndicator from 'components/loadingIndicator';

export const Wrapper = styled('div')`
  position: absolute;
  inset: 0;
  margin: auto;
  padding: 32px;
  width: 512px;
  height: 360px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.dina.surfaceCard};
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%),
    0px 9px 46px 8px rgb(0 0 0 / 12%);
`;

export const DinaLogo = styled(DinaIcon)`
  width: 58px;
  height: 32px;
  margin-block: 24px;
`;

export const Loading = styled(LoadingIndicator)`
  position: static;
  height: 64px;
  width: 64px;
  margin-block: 16px;
`;

export const Footer = styled('div')`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;
