import { memo, useCallback, useMemo } from 'react';

import MimirDeck from 'features/mimirDeck';
import WidgetWrapper, { FilterComponentType } from 'features/widget/components/WidgetWrapper';
import { WIDGETS } from 'features/widget/constants';
import { useUpdateWidgetFilters } from 'screens/space/store/widgets';

import { FilterComponent } from './AssetsFilters';
import type { WidgetProps } from './types';
import { getPropFilters } from './utils';

function AssetsWidget({
  layout,
  filters,
  federatedSearchString,
  mId,
  mRefId,
  title,
  writeAccess,
}: Readonly<WidgetProps>) {
  const updateWidgetFilters = useUpdateWidgetFilters();

  const memoizedFilters = useMemo(() => {
    const propFilters = getPropFilters({ filters });
    return propFilters;
  }, [filters]);

  const handleUpdateSearchString = useCallback(
    (val: string) => {
      updateWidgetFilters({ id: mId, filters: { ...(memoizedFilters ?? {}), searchString: val } });
    },
    [memoizedFilters],
  );

  const activeFilters = useMemo(() => {
    const activeSearch = memoizedFilters?.searchString as string;
    return activeSearch?.length > 0;
  }, [memoizedFilters]);

  return (
    <WidgetWrapper
      layout={layout}
      mId={mId}
      mRefId={mRefId}
      title={title}
      filterComponent={FilterComponent as FilterComponentType}
      writeAccess={writeAccess}
      filters={memoizedFilters}
      activeFilters={activeFilters}
      type={WIDGETS.ASSETS}
    >
      <MimirDeck
        filters={memoizedFilters}
        federatedSearchString={federatedSearchString}
        onSearchStringChange={(val: string) => handleUpdateSearchString(val)}
      />
    </WidgetWrapper>
  );
}

export default memo(AssetsWidget);
