const getUpdatedRundownGridViews = (oldViews, newViews) => {
  newViews.forEach((newView) => {
    const { id } = newView;
    const oldView = oldViews.find((view) => view.id === id);
    if (oldView && oldView.name !== newView.name) {
      oldView.name = newView.name;
    }
  });
};

const addRemoveColumnViews = (oldViews, changedRundownGridViews) => {
  if (changedRundownGridViews.length === 0) return oldViews;
  const oldViewsCopy = JSON.parse(JSON.stringify(oldViews));
  const oldViewsIds = oldViews.filter((view) => view.type === 'grid').map((view) => view.id);
  const newViewsIds = changedRundownGridViews.map((view) => view.id);
  const removedViews = oldViewsIds.filter((id) => !newViewsIds.includes(id));
  const addedViews = newViewsIds.filter((id) => !oldViewsIds.includes(id));
  removedViews.forEach((id) => {
    const index = oldViews.findIndex((view) => view.id === id);
    oldViewsCopy.splice(index, 1);
  });
  addedViews.forEach((id) => {
    const index = changedRundownGridViews.findIndex((view) => view.id === id);
    oldViewsCopy.push(changedRundownGridViews[index]);
  });
  getUpdatedRundownGridViews(oldViewsCopy, changedRundownGridViews);
  return oldViewsCopy;
};

const getUpdatedRundownGridViewsInForm = (
  form,
  changedColumnViews,
  changedRundownGridViews = [],
) => {
  const updatedViews = [];
  const oldViews = form?.mProperties?.views;

  const newViews = addRemoveColumnViews(oldViews, changedRundownGridViews);

  newViews.forEach((oldView) => {
    const { id } = oldView;
    const newView = changedColumnViews.find((view) => view.id === id);
    if (newView) {
      updatedViews.push(newView);
    } else {
      updatedViews.push(oldView);
    }
  });
  const { defaultViews } = form?.mProperties;

  return { views: updatedViews, defaultViews };
};

export default getUpdatedRundownGridViewsInForm;
