import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { ReactComponent as PlusSmall } from 'assets/icons/systemicons/plus_small.svg';

import transientOptions from 'theme/helpers';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const Header = styled('div', transientOptions)`
  height: 48px;
  background-color: ${({ $selected, theme }) =>
    $selected ? theme.palette.dina.selectedNotActive : 'transparent'};
  display: flex;
  user-select: none;
`;

export const HeaderButton = styled('button', transientOptions)`
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  background-color: ${({ $selected, theme }) =>
    $selected ? theme.palette.dina.selectedNotActive : 'transparent'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  :hover {
    background-color: ${({ $selected, theme }) =>
      $selected ? theme.palette.dina.selectedNotActive : theme.palette.dina.outlineButtonOnHover};
  }
  :focus {
    background-color: ${({ theme }) => theme.palette.dina.blackHoverOverlay};
  }
`;

export const HeaderText = styled('p')`
  ${({ theme }) => theme.typography.dina.listItemLabel};
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const HeaderTextWrapper = styled('div')`
  display: flex;
  overflow: hidden;

  padding-right: 26px;
`;

export const CountText = styled('div')`
  position: absolute;
  right: ${({ hovered }) => (hovered ? '40px' : '16px')};
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  transition: 'all 0.25s';
`;

export const ArrowWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

export const PlusButton = styled(PlusSmall, transientOptions)`
  padding: 3px;
  border-radius: 50%;
  align-self: center;
  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.35;
    `}
  :hover {
    background-color: ${({ theme }) => theme.palette.dina.whiteHoverOverlay};
  }
`;

export const List = styled('ul')`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const ListItem = styled('li', transientOptions)`
  height: 48px;
  display: flex;
  align-items: center;
  background-color: ${({ $selected, theme }) =>
    $selected ? theme.palette.dina.onSelected : 'transparent'};
  user-select: none;
  cursor: pointer;
  :hover {
    background-color: ${({ $selected, theme }) =>
      $selected ? theme.palette.dina.onSelected : theme.palette.dina.outlineButtonOnHover};
  }
  :focus {
    background-color: ${({ theme }) => theme.palette.dina.blackHoverOverlay};
  }
  :before {
  }
`;

export const ListItemText = styled('p', transientOptions)`
  ${({ $italic, theme }) =>
    $italic ? theme.typography.dina.listItemLabelItalic : theme.typography.dina.listItemLabel};
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
  padding-left: 42px;
  padding-right: 8px;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  text-align: left;
  white-space: nowrap;
`;
