import * as ResizablePrimitive from 'react-resizable-panels';

import { ReactComponent as Grip } from 'assets/icons/systemicons/DraggableVertical.svg';
import { Flex } from 'layouts/box/Box';

const ResizablePanelGroup = ({
  ...props
}: React.ComponentProps<typeof ResizablePrimitive.PanelGroup>) => (
  <ResizablePrimitive.PanelGroup {...props} style={{ overflowX: 'auto' }} />
);

const ResizablePanel = ({ ...props }) => {
  return <ResizablePrimitive.Panel {...props} />;
};

const ResizableHandle = ({
  withHandle,
  ...props
}: React.ComponentProps<typeof ResizablePrimitive.PanelResizeHandle> & {
  withHandle?: boolean;
}) => (
  <ResizablePrimitive.PanelResizeHandle {...props}>
    {withHandle && (
      <Flex width="12px" height="100%">
        <Grip />
      </Flex>
    )}
  </ResizablePrimitive.PanelResizeHandle>
);

export { ResizablePanelGroup, ResizablePanel, ResizableHandle };
