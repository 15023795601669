/* eslint-disable sort-imports */
import { memo, useCallback, useState } from 'react';
import { useDrop } from 'react-dnd';
import {
  AnimateLayoutChanges,
  defaultAnimateLayoutChanges,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import Collapsible from 'components/collapsible/Collapsible';
import useToast from 'components/toast/useToast';
import { useKanbanMolecule } from 'features/kanban/store/kanban';
import { DNDKitUseSortableProps } from 'types/dndkit/sortable';
import { MemberType } from 'types/graphqlTypes';

import LaneHeader from './lane/LaneHeader';
import SortableItem from './SortableItem';

import { Wrapper } from './styled';

interface GroupMembers {
  [key: string]: MemberType;
}

interface RenderItemProps extends DNDKitUseSortableProps {
  member?: MemberType;
  parentLaneId: string;
}

interface Props {
  activeId: string | null;
  color: string;
  groupMembers: GroupMembers;
  id: string;
  isSortingContainer?: boolean;
  items?: string[];
  label: string;
  parentId: string;
  type?: string;
  isDropOver?: boolean;
  isHorizontal?: boolean;
  renderItem: (props: RenderItemProps) => JSX.Element;
}

interface DroppableLaneProps extends Props {
  open?: boolean;
  setOpen?: (isOpen: boolean) => void;
}

const animateLayoutChanges: AnimateLayoutChanges = (args) =>
  defaultAnimateLayoutChanges({ ...args, wasDragging: true });

const DroppableLane = ({
  groupMembers,
  id,
  isSortingContainer,
  items = [],
  label,
  parentId,
  color,
  renderItem,
  isDropOver,
  open,
  setOpen,
  isHorizontal,
}: Readonly<DroppableLaneProps>): JSX.Element => {
  const { attributes, isDragging, listeners, setNodeRef, transition, transform } = useSortable({
    id,
    data: {
      type: 'container',
      children: items,
    },
    animateLayoutChanges,
  });

  const count = items?.length ?? 0;
  const handleProps = { ...attributes, ...listeners };

  const collapseOpen = !isHorizontal ? open : true;
  const setCollapseOpen = (isOpen: boolean) => {
    if (!isHorizontal) setOpen?.(isOpen);
  };

  return (
    <Wrapper
      ref={setNodeRef}
      style={{
        border: isDropOver ? '1px solid orange' : '1px solid transparent',
        opacity: isDragging ? 0.5 : undefined,
        backgroundColor: isDragging ? '#0A73EB' : 'rgba(0, 0, 0, 0.03)',
        transform: CSS.Translate.toString(transform),
        transition,
      }}
      tabIndex={-1}
    >
      <Collapsible
        open={collapseOpen}
        setOpen={setCollapseOpen}
        trigger={
          <LaneHeader
            {...handleProps}
            isHorizontal={isHorizontal}
            label={label}
            count={count}
            mId={parentId}
            mRefId={id}
            color={color}
          />
        }
        content={
          <SortableContext items={items} strategy={verticalListSortingStrategy}>
            {items?.map((memberId) => {
              const member = groupMembers[memberId];
              if (!member) return null;

              return (
                <SortableItem
                  disabled={isSortingContainer}
                  id={memberId}
                  key={memberId}
                  member={member}
                  renderItem={renderItem}
                  parentLaneId={id}
                />
              );
            })}
          </SortableContext>
        }
      />
    </Wrapper>
  );
};

DroppableLane.displayName = 'DroppableLane';

const LaneWithDropzone = (props: Readonly<Props>) => {
  const [open, setOpen] = useState(true);

  const { toast } = useToast();
  const { useAddMember, useKanbanMembers } = useKanbanMolecule();
  const addMember = useAddMember();
  const [, setKanbanMembers] = useKanbanMembers();

  const onAddItem = useCallback(
    (item: MemberType) => {
      let alreadyExists = false;
      setKanbanMembers((prevState) => {
        // Check if member already exists on the board
        const member = prevState[item.mId as string];

        if (member) {
          alreadyExists = true;
          toast({
            title: 'Already added',
            description: 'The item is already on the board',
            type: 'info',
          });
          return prevState;
        }

        const newMembers = prevState;
        newMembers[item.mId as string] = item;
        return newMembers;
      });

      if (alreadyExists) return;

      setOpen(true);
      addMember({ laneId: props.id, memberId: item.mId as string });
    },
    [setKanbanMembers, addMember],
  );

  const [{ isOver }, drop] = useDrop({
    accept: ['STORY_DRAG'],
    drop(member: MemberType) {
      onAddItem(member);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div ref={(ref) => drop(ref)}>
      <DroppableLane setOpen={setOpen} open={open} isDropOver={isOver} {...props} />
    </div>
  );
};

function Lane({ type, ...rest }: Readonly<Props>) {
  if (type === 'rundown') return <DroppableLane {...rest} />;
  return <LaneWithDropzone {...rest} />;
}

const areEqual = (prevProps: Props, nextProps: Props) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
};

const MemoizedKanbanLane = memo(Lane, areEqual);

export default MemoizedKanbanLane;
