import { searchbarFields } from 'utils/constants/searchbar';

const getTooltipTitleFromModel = (model) => {
  if (!model) return '';
  const tooltipTextObjects = [];
  Object.keys(model).forEach((key) => {
    switch (key) {
      case searchbarFields.FREE_TEXT: {
        if (model.freeText.length > 0) {
          tooltipTextObjects.push(model.freeText);
        }
        break;
      }
      case searchbarFields.USER: {
        if (model.user.length > 0) {
          const userText = `user:"${model.user.map((people) => people.mTitle).join(', ')}"`;
          tooltipTextObjects.push(userText);
        }
        break;
      }
      case searchbarFields.TEAM: {
        if (model.team.length > 0) {
          const teamText = `team:"${model.team.map((team) => team.mTitle).join(', ')}"`;
          tooltipTextObjects.push(teamText);
        }
        break;
      }
      case searchbarFields.DEPARTMENT: {
        if (model.department.length > 0) {
          const departmentText = `department:"${model.department
            .map((department) => department.mTitle)
            .join(', ')}"`;
          tooltipTextObjects.push(departmentText);
        }
        break;
      }
      case searchbarFields.PLATFORM: {
        if (model.platform.length > 0) {
          const platformText = `platform:"${model.platform
            .map((platform) => platform.platform)
            .join(', ')}"`;
          tooltipTextObjects.push(platformText);
        }
        break;
      }
      default:
    }
  });

  return tooltipTextObjects.join(', ');
};

export default getTooltipTitleFromModel;
