import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import MUILinearProgress from '@material-ui/core/LinearProgress';

const StyledMUILinearProgress = styled(MUILinearProgress)`
  height: ${({ height }) => height}px;
  z-index: 1100;
  position: absolute;
  left: ${({ left }) => left}px;
  right: ${({ right }) => right}px;
  &.top {
    top: 0;
  }
  &.bottom {
    bottom: 0;
  }
  &.MuiLinearProgress-colorPrimary {
    background-color: ${({ theme }) => theme.palette.dina.dividerLight};
  }
  & > * {
    &.MuiLinearProgress-barColorPrimary {
      background-color: ${({ theme }) => theme.palette.dina.onSelected};
    }
  }
`;

function LinearProgress({ height, left, right, position }) {
  return <StyledMUILinearProgress height={height} left={left} right={right} className={position} />;
}

LinearProgress.propTypes = {
  height: PropTypes.number,
  left: PropTypes.number,
  right: PropTypes.number,
  position: PropTypes.string,
};

LinearProgress.defaultProps = {
  height: 2,
  left: 0,
  right: 0,
  position: 'top',
};

export default LinearProgress;
