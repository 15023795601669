/* eslint-disable import/no-extraneous-dependencies */
import convertIntoMosAsset from './convertIntoMosAsset';
import parseXml from './parseXml';

/**
 * Parses given text and converts to mos asset object
 *
 * @param xmlString Text to be parsed
 * @param mediaType rundown item type
 * @returns Parsed xml object
 */

const getMosAsset = (xmlString: string, mediaType: string) => {
  const xml = parseXml(xmlString);
  return convertIntoMosAsset(xml, mediaType);
};

export default getMosAsset;
