import { useMutation } from '@apollo/client';

import UPDATE_MEMBER from 'operations/mutations/updateMember';

const usePublishDateUpdate = () => {
  const [updateStoryPublishingTime] = useMutation(UPDATE_MEMBER);

  const update = async (
    mId: string,
    mPublishingAt?: string | null,
    mPublishingEnd?: string | null,
  ) => {
    const input = {
      mId,
      mPublishingAt: mPublishingAt || null,
      mPublishingEnd: mPublishingEnd || null,
    };

    await updateStoryPublishingTime({
      variables: {
        input,
      },
    });
  };
  return [update];
};

export default usePublishDateUpdate;
