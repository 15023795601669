import { Theme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { FormControlLabel as MuiFormControlLabel } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { Timeline } from '@material-ui/lab';
import { withStyles } from '@material-ui/styles';

export const TimeLineWrapper = styled(Timeline)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 6px 0px;
`;

export const FormControlLabel = styled(MuiFormControlLabel)`
  margin: 0;
  & .MuiFormControlLabel-label {
    ${({ theme }) => theme.typography.dina.listItemLabel};
  }
`;

export const NoAuditsText = styled('div')`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  padding: 10px;
`;

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  position: static;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  flex: none;
  order: 0;
  align-self: flex-start;
  background: ${({ theme }) => theme.palette.dina.blackHoverOverlay};
`;

export const Header = styled('div')`
  position: relative;
  width: 100%;
  left: 0px;
  top: 0px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

export const MenuContainer = styled('div')`
  height: 100%;
  width: 100%;
`;

export const StylesSwitch = withStyles((theme: Theme) => ({
  switchBase: {
    color: 'white',
    '&$checked': {
      color: 'white',
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.dina.onSelected,
    },
  },
  checked: {},
  track: {},
}))(Switch);
