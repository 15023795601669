import { useLoadingIndicator } from 'store';
import { useMutation } from '@apollo/client';
import useLogger from 'utils/useLogger';
import COPY_INSTANCE from 'operations/mutations/moveInstanceToRundown';
import UPDATE_RUNDOWN_SYNC from 'operations/mutations/updateRundownSync';
import updateStoryInstanceCache from 'utils/instance/updateStoryInstanceCache';
import useSettingsValue from 'hooks/useSettingsValue';
import { useStoryMolecule } from 'screens/storyV2/store/story';
import { useStoryPaneMolecule } from 'screens/storyV2/store/storyPane';
import useStoryPanes from 'screens/storyV2/useStoryPanes';
import order from 'components/editor/components/order';

const findIndexInArray = (item) => (array) => array ? array.indexOf(item) : -1;

const returnOrderType = (instanceId, mOrder, mPreorder, isSameRundown, scheduleToReadyRundown) => {
  const returnIndex = findIndexInArray(instanceId);

  const isInReadyList = mOrder?.length > 0 && returnIndex(mOrder) >= 0;
  const isInPreparingList = mPreorder?.length > 0 && returnIndex(mPreorder) >= 0;

  let index = isInReadyList ? returnIndex(mOrder) : returnIndex(mPreorder);

  if (index === -1) {
    index = 2000;
  }

  let orderType = scheduleToReadyRundown ? 'ready' : 'preparing';

  if (isInReadyList) {
    orderType = 'ready';
  }
  if (isInPreparingList) {
    orderType = 'preparing';
  }
  return { orderType, index: index + 1 };
};

const useDuplicateInstance = () => {
  const [, setLoadingIndicator] = useLoadingIndicator();
  const [duplicateInstance] = useMutation(COPY_INSTANCE);
  const [updateRundownSync] = useMutation(UPDATE_RUNDOWN_SYNC);
  const { isWithinStoryScope, scopeId } = useStoryMolecule();
  const { useCreatingInstance, useNewlyAddedInstance, usePaneIndexValue } = useStoryPaneMolecule();
  const { updateStoryPane } = useStoryPanes();
  const paneIndex = usePaneIndexValue();
  const [, setCreatingInstance] = useCreatingInstance();
  const [, setNewlyAddedInstance] = useNewlyAddedInstance();
  const logger = useLogger('duplicate instance');
  const [getSettingsValue] = useSettingsValue();

  const createDuplicate = async (instanceId, rundownId, rundownData) => {
    const scheduleToReadyRundown =
      getSettingsValue('rundown.instance.scheduleToReadyRundown') === 'true';
    try {
      const { mOrder, mPreorder, mId } = rundownData;

      const input = {
        mIds: [instanceId],
        copy: true,
        audit: { source: 'instance:menu' },
      };

      if (rundownId) {
        const { orderType, index } = returnOrderType(
          instanceId,
          mOrder,
          mPreorder,
          mId === rundownId,
          scheduleToReadyRundown,
        );

        input.orderType = orderType;
        input.index = index;
        input.targetRundown = {
          mId: rundownId,
          mRefId: rundownId,
        };
      }

      setLoadingIndicator(input.orderType || 'create');
      if (isWithinStoryScope) {
        setCreatingInstance(true);
      }

      await duplicateInstance({
        variables: {
          input,
        },
        fetchPolicy: 'no-cache',
        update: (cache, mutationResult) => {
          const [newInstance] = mutationResult?.data?.moveInstanceToRundown;

          if (isWithinStoryScope && scopeId && newInstance) {
            setNewlyAddedInstance(newInstance);
            updateStoryInstanceCache(cache, scopeId, newInstance);
            updateStoryPane(paneIndex ?? 0, 'instances', newInstance.mRefId);
          }

          if (!rundownId) return;
          const variables = {
            input: { mId: rundownId, mRefId: rundownId },
          };
          const order = input.orderType === 'ready' ? 'mOrder' : 'mPreorder';
          const payloadInput = {
            crudAction: 'NONE',
            value: {
              mId: newInstance.mId,
              index: input.index,
              destination: order,
            },
          };
          const payloads = [payloadInput];
          variables.input.mPayload = payloads;
          updateRundownSync({
            variables,
            optimisticResponse: false,
          });
        },
      });
    } catch (error) {
      logger.log(error);
    } finally {
      setLoadingIndicator(null);
    }
  };

  return [createDuplicate];
};

export default useDuplicateInstance;
