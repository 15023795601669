import { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import { KanbanBoardType } from 'screens/space/types/space';

import { UPDATE_BOARD } from './graphql';

export type UpdateBoardInputType = {
  kanbanBoard: KanbanBoardType;
};

const useUpdateBoard = () => {
  const [updateBoard] = useMutation(UPDATE_BOARD);

  const updateKanbanBoard = useCallback(
    async ({ kanbanBoard }: UpdateBoardInputType) => {
      if (!kanbanBoard) return;

      const { mId, mRefId, lanes, mOrder } = kanbanBoard;
      const metadata = JSON.stringify({ lanes });

      try {
        await updateBoard({
          variables: {
            input: { mId, mRefId, metadata, mOrder },
          },
          fetchPolicy: 'no-cache',
        });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    },
    [updateBoard],
  );

  return { updateKanbanBoard };
};

export default useUpdateBoard;
