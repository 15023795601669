import { isBefore, startOfDay } from 'date-fns';

/**
 * Finds if the given date is before today considering only date not time
 *
 */

const isBeforeToday = (date: string | number | Date) =>
  isBefore(startOfDay(date), startOfDay(new Date()));

export default isBeforeToday;
