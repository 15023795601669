import { memo, useCallback } from 'react';
import { useSlate } from 'slate-react';

import { ReactComponent as Uppercase } from 'assets/icons/systemicons/editor/uppercase.svg';
import { markTypes } from 'components/editor/constants';
import { isMarkActive, toggleMark } from 'components/editor/utils';

import ButtonBase from '../buttonBase';

import toggleCase from './utils/toggleCase';

function UppercaseButton() {
  const editor = useSlate();
  const isActive = isMarkActive(editor, markTypes.UPPERCASE);

  const onMouseDown = useCallback(
    (event: React.MouseEvent<SVGSVGElement>) => {
      event.preventDefault();
      toggleMark(editor, markTypes.UPPERCASE, isActive);
      toggleCase(editor, isActive);
    },
    [editor, isActive],
  );

  return (
    <ButtonBase
      type={markTypes.UPPERCASE}
      isActive={isActive}
      IconComponent={Uppercase}
      onMouseDown={onMouseDown}
    />
  );
}

export default memo(UppercaseButton);
