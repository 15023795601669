interface TimeFormatConversionOptions {
  fps?: number;
}
/**
 * Converts hh:mm:ss / hh:mm:ss;ff (ff=frames) formatted time string to milliseconds
 */
const getMilliseconds = (time = '', options?: TimeFormatConversionOptions) => {
  const { fps = 25 } = options || {};
  if (!time) return 0;
  const [parsedTimeString, frameString] = time.toString().split(';');
  if (frameString && isNaN(Number(frameString))) return 0;

  const parsedFrames = Number(frameString) || 0;

  const parsedTime = parsedTimeString.toString().split(':').map(Number);

  if (parsedTime.some(Number.isNaN)) return 0;

  const [firstItem] = parsedTime;
  const sign = firstItem === 0 ? 1 : Math.sign(firstItem);

  const seconds = parsedTime.reduce(
    (accumulator, currentValue) => 60 * accumulator + Math.abs(currentValue),
    0,
  );

  return sign * (seconds * 1000 + Math.ceil(parsedFrames * (1000 / fps)));
};

export default getMilliseconds;
