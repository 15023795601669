import { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import useLogger from 'utils/useLogger';

import { UPDATE_DRAFT } from './graphql';

interface UpdateDraftProps {
  mId?: string;
  mRefId?: string;
  mTitle?: string;
  mDescription?: string;
}

const useUpdateDraft = () => {
  const [updateDraft] = useMutation(UPDATE_DRAFT);
  const logger = useLogger('useUpdateDraft hook');

  const update = useCallback(
    async ({ mId, mRefId, mTitle, mDescription }: UpdateDraftProps) => {
      if (!mId || !mRefId) return;
      try {
        await updateDraft({
          variables: {
            input: {
              mId,
              mRefId,
              mTitle,
              mDescription,
            },
          },
        });
      } catch (err) {
        logger.log(err);
      }
    },
    [updateDraft],
  );

  return [update];
};

export default useUpdateDraft;
