import elementTypes from 'components/editor/constants/types/elementTypes';
import getTwitterMetaKey from 'utils/metadata/getTwitterMetaKey';

/**
 * Counts number of tweet thread
 * It first searches in metadata
 * If not found (in old tweet instances) then it counts from editor value
 *
 * @param  {Array} metaData
 * @returns {number} number of tweet threads or undefined
 */

const countTwitterThread = (metaData = [], editorValue, blankMetaData = []) => {
  const tweetMetaKey = getTwitterMetaKey(blankMetaData);

  const twitterMetaData = metaData.find(item => item.key === tweetMetaKey);

  if (twitterMetaData) return twitterMetaData.value;

  if (editorValue && editorValue.document) {
    const twitterThreads = editorValue.document.filter(
      meta => meta.type === elementTypes.TWEET_THREAD,
    );
    return twitterThreads.length;
  }
  return undefined;
};

export default countTwitterThread;
