import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const Wrapper = styled('div')`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.palette.dina.errorText};
`;

function FallbackComponent({ fallbackText }) {
  return <Wrapper>{fallbackText}</Wrapper>;
}

FallbackComponent.propTypes = {
  fallbackText: PropTypes.string,
};

FallbackComponent.defaultProps = {
  fallbackText: 'Something went wrong',
};

export default FallbackComponent;
