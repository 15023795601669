import { postMessage } from 'utils/postMessage';

const openAssetInMimirLeftSidePanel = (element) => {
  const assetItem = getMimirAssetItem(element);

  if (!assetItem) return;
  const { mAssetId, mProvider } = assetItem;
  const destination = mProvider === 'ampp' ? 'FLX' : 'Mimir';
  const message = {
    action: 'open_item',
    payload: mAssetId,
    destination,
  };

  postMessage(message, 'mimir');
};

export const isMimirAssetItem = (element) => !!getMimirAssetItem(element);

export const openPreviewInProvider = (provider) => provider === 'ampp';

export const allowedProviderSet = new Set(['Mimir', 'ampp']);

const mediaItemSets = new Set(['video', 'image', 'audio']);

const getMimirAssetItem = (element) => {
  if (!element?.data) return null;
  const { data } = element;
  // fix for element structure is not consistent.
  if (allowedProviderSet.has(data.mProvider)) return data;
  const { assets = [] } = data ?? {};
  const asset = assets.find(
    ({ itemType, assetType }) => mediaItemSets.has(itemType) || mediaItemSets.has(assetType),
  );

  return allowedProviderSet.has(asset?.mProvider) ? asset : null;
};

export default openAssetInMimirLeftSidePanel;
