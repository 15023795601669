/* eslint-disable array-callback-return */
import { useMutation } from '@apollo/client';
import UPDATE_RUNDOWN_SYNC from 'operations/mutations/updateRundownSync';

const useUpdateFloatedItems = () => {
  const [updateRundownSync] = useMutation(UPDATE_RUNDOWN_SYNC);

  const updateRundown = async (mId, instanceIds, isFloat) => {
    const crudAction = isFloat === 'true' ? 'FLOAT' : 'UNFLOAT';

    const mPayload = instanceIds.map((id) => ({
      crudAction,
      value: {
        mId: id,
      },
    }));

    const input = {
      mId,
      mRefId: mId,
      mPayload,
    };

    updateRundownSync({
      variables: {
        input,
      },
    });
  };

  const updateFloatedItems = async (rundownId, instanceIds, value) => {
    await updateRundown(rundownId, instanceIds, value);
  };

  return [updateFloatedItems];
};

export default useUpdateFloatedItems;
