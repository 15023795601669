import { markTypes } from './types';

const { BOLD, ITALIC, UNDERLINE } = markTypes;

const hotkeys: Record<string, string> = {
  'mod+b': BOLD,
  'mod+i': ITALIC,
  'mod+u': UNDERLINE,
};

export default hotkeys;
