import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Scrollbar from 'components/scrollbar/scrollbar';
import SearchTableView from 'components/searchTable';
import searchbarPositions from 'components/searchTable/constants/searchbarPositions';
import Checkbox from 'components/checkbox';
import InputField from './InputField/inputField-view';
import RootWrapper from './instanceFields-styled';
import { useChangedInstanceFields, useSelectedMenuSystemSettings } from '../../../../../../atoms';
import columnsFields from '../constants/columns';

const CheckboxWrapper = styled('div')`
  align-items: center;
  padding: 2px;
  margin-right: -10px;
  margin-left: -10px;
`;

const CheckBoxField = (isChecked) => (
  <CheckboxWrapper>
    <Checkbox selected={isChecked} size={24} />
  </CheckboxWrapper>
);

const DisplayInputField = (rowId, id, viewId, value, disabled) => (
  <InputField rowId={rowId} fieldId={id} viewId={viewId} value={value} disabled={disabled} />
);

const InstanceFields = (props) => {
  const { onClose, selectedView, fields } = props;
  const [selectedMenu] = useSelectedMenuSystemSettings();
  const [changedInstanceFields, setChangedInstanceFields] = useChangedInstanceFields();
  const [tableRows, setTableRows] = useState([]);

  const setChangedRows = (rows) => {
    setChangedInstanceFields(rows);
  };

  const selectedViewId = selectedView?.id;

  const fieldsInSelectedView = selectedView?.columndefs[0]?.columns;

  const getCheckboxValueOfRow = (rowId, fieldExistsInView) => {
    const result = changedInstanceFields.find(
      (changed) => changed.rowId === rowId && changed.viewId === selectedViewId,
    );
    return result ? result.checked : !!fieldExistsInView;
  };

  const findFieldInView = (id) => fieldsInSelectedView?.find((field) => field.id === id);

  const getRows = () => {
    const rows = [];
    fields.forEach((field) => {
      if (field) {
        const { id, name, label } = field;
        const rowId = `${selectedMenu}-${id}`;
        const viewId = `${selectedMenu}-instance-metadata-1`;
        // Find if field exists in selected instance view
        const fieldExistsInView = findFieldInView(id);
        const columnName = fieldExistsInView?.name || '';
        const isChecked = getCheckboxValueOfRow(rowId, fieldExistsInView);
        rows.push({
          rowId,
          fieldId: id,
          name,
          label,
          viewId,
          checkbox: CheckBoxField(isChecked),
          checked: isChecked,
          columnName,
          displayName: DisplayInputField(rowId, id, viewId, columnName, !isChecked),
        });
      }
    });
    return rows;
  };

  useEffect(() => {
    setTableRows(getRows());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMenu]);

  const updateTableRows = (row) => {
    const { rowId, fieldId, viewId, columnName } = row;
    const newCheckedValue = !row.checked;
    const updatedRows = tableRows.map((tableRow) => {
      if (tableRow.rowId === row.rowId) {
        return {
          ...tableRow,
          checked: newCheckedValue,
          checkbox: CheckBoxField(newCheckedValue),
          displayName: DisplayInputField(rowId, fieldId, viewId, columnName, !newCheckedValue),
        };
      }
      return tableRow;
    });
    setTableRows(updatedRows);
  };

  const handleItemSelect = (row) => {
    const { rowId, fieldId, viewId, checked } = row;
    updateTableRows(row);
    const result = changedInstanceFields.find(
      (changed) => changed.rowId === rowId && changed.viewId === selectedViewId,
    );
    if (!result) {
      setChangedRows([
        ...changedInstanceFields,
        {
          rowId,
          fieldId,
          checked: !checked,
          viewId: `${selectedMenu}-instance-metadata-1`,
        },
      ]);
      return;
    }

    if (!('displayValue' in result)) {
      setChangedRows(changedInstanceFields.filter((changedRow) => changedRow.rowId !== rowId));
      return;
    }

    const updatedInstanceFields = changedInstanceFields.map((changedRow) => {
      if (changedRow.rowId !== rowId) return changedRow;
      if ('checked' in result)
        // If checkbox value is changed to original value
        return { rowId, fieldId, viewId, displayValue: result.displayValue };
      return { ...result, checked: !checked };
    });
    setChangedInstanceFields([...updatedInstanceFields]);
  };

  return (
    <RootWrapper>
      <Scrollbar>
        <SearchTableView
          onItemSelect={handleItemSelect}
          columns={columnsFields}
          rows={tableRows}
          defaultSelect={false}
          showSearchIcon
          searchbarPosition={searchbarPositions.TOP}
          selectable
          onClose={onClose}
          closeOnSelect={false}
          showTitle={false}
        />
      </Scrollbar>
    </RootWrapper>
  );
};

InstanceFields.propTypes = {
  fields: PropTypes.array,
  onClose: PropTypes.func,
  selectedView: PropTypes.object,
};

InstanceFields.defaultProps = {
  fields: {},
  onClose: () => {},
  selectedView: '',
};

export default InstanceFields;
