import gql from 'graphql-tag';

export default gql`
  mutation DeleteSingleMember($input: DeleteSingleMemberInput) {
    deleteSingleMember(input: $input) {
      mId
      mRefId
    }
  }
`;
