import styled from '@emotion/styled';

export const DailyHeader = styled('header')`
  height: 80px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0px;
`;

export const Row = styled('span')`
  min-height: 40px;
  padding: 0 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
