import { useState } from 'react';

import Divider from 'components/divider';
import Tabs from 'components/tabs/contained';
import { HStack } from 'layouts/box/Box';
import { useSelectedLeftMenu } from 'screens/main/components/header/navbar/settings/atomsTs';
import { MemberType } from 'types/graphqlTypes';
import { GroupPropType } from 'types/groupPermissions/permissions';

import GroupHeader from './groupHeader/groupHeader-view';
import GroupMembers from './groupMembers/groupMembers-view';
import GroupPermissions from './groupPermissions/GroupPermissions';

import { RootWrapper } from './styled';

const tabs = ['Group permissions', 'Group members'];

export default function Group({
  groupPolicies,
  instanceTypes,
  kanbanStates,
}: Readonly<GroupPropType>) {
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const [selectedLeftMenu] = useSelectedLeftMenu();

  const groupPolicy = groupPolicies.find(
    (policy) => policy.mRefId === selectedLeftMenu,
  ) as MemberType;

  const viewHelper = () => {
    switch (selectedTab) {
      case tabs[0]:
        return (
          <GroupPermissions
            groupPolicy={groupPolicy}
            instanceTypes={instanceTypes}
            kanbanStates={kanbanStates}
          />
        );
      case tabs[1]:
        return <GroupMembers groupPolicy={groupPolicy} />;
      default:
        return <div />;
    }
  };

  return (
    <RootWrapper>
      <GroupHeader groupPolicy={groupPolicy} />
      <HStack width="100%" padding="6px">
        <Tabs
          tabs={tabs}
          activeTab={selectedTab}
          setActiveTab={setSelectedTab}
          styleProps={{
            fullWidth: true,
          }}
        />
      </HStack>
      <Divider />
      {viewHelper()}
    </RootWrapper>
  );
}
