import { ReactComponent as PadlockOff } from 'assets/icons/systemicons/padlock_off.svg';
import { ReactComponent as PadlockOn } from 'assets/icons/systemicons/padlock_on.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/systemicons/warning.svg';
import Dialog from 'components/dialogs/DialogBuilder';
import Text from 'components/text/Text';

const notRestrictedValues = {
  headerLabel: 'Restrict Who Can Access',
  sub1: 'By restricting access, only selected People, Teams or Departments can find or access.',
  sub2: 'Already added members will still be able to access.',
};

const restrictedValues = {
  headerLabel: 'Remove Access Restrictions',
  sub1: 'By removing access restrictions, everyone can find and access.',
  sub2: 'Please make sure no sensitive information is present before removing access restrictions.',
};

interface Props {
  isRestricted: boolean;
  onClose: () => void;
  onControlAccess: () => void;
  open: boolean;
}

function AccessDialog({ isRestricted, onClose, onControlAccess, open }: Readonly<Props>) {
  const { headerLabel, sub1, sub2 } = isRestricted ? restrictedValues : notRestrictedValues;

  return (
    <Dialog open={open} onClose={onClose}>
      <Dialog.Header>{headerLabel}</Dialog.Header>
      <Dialog.Body>
        <Text>
          {sub1}
          <br /> <br />
          {sub2}
        </Text>
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton />
        {!isRestricted ? (
          <Dialog.ConfirmButton
            icon={<PadlockOn />}
            label="Restrict Access"
            onConfirm={onControlAccess}
            infoOnly
          />
        ) : (
          <Dialog.TwoStepButton
            label1="Remove Restrictions"
            icon1={<PadlockOff />}
            label2="Click again to remove restrictions"
            icon2={<WarningIcon />}
            onConfirm={onControlAccess}
          />
        )}
      </Dialog.Footer>
    </Dialog>
  );
}

export default AccessDialog;
