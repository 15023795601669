/* eslint-disable react/prop-types */
import { ItemParams } from 'react-contexify';

import ContextMenu from 'features/contextMenu';
import { OrderButtonProps } from 'features/orderForm/components/OrderButton';
import { useGetOrderMenu } from 'hooks/useGetOrderMenu';
import { useOrderFormDialog } from 'store';

function OrderFormsMenu() {
  const { menuItems } = useGetOrderMenu();
  const [, setOrderForm] = useOrderFormDialog();

  const handleClickEvent = ({ id, props }: ItemParams<OrderButtonProps>) => {
    if (id && props) {
      const ids = id.split('#');
      setOrderForm({
        open: true,
        mFormId: ids[0],
        mdfId: ids[1],
        resourceId: props.resourceId,
        resourceType: props.resourceType,
      });
    }
    if (props?.onClose) {
      props.onClose();
    }
  };

  if (!menuItems.length) {
    return (
      <ContextMenu
        id="orderForms"
        menuItems={[{ id: 'none', label: 'No options available' }]}
        onClick={() => {}}
      />
    );
  }

  return <ContextMenu id="orderForms" menuItems={menuItems} onClick={handleClickEvent} />;
}

export default OrderFormsMenu;
