import { useCallback } from 'react';

import { scrollItemIntoView } from 'features/sidepanel/ComponentUtils';
import { SidepanelTabValue } from 'features/sidepanel/Sidepanel';
import { useSplitViewMolecule } from 'features/splitView/store';
import { useStoryPaneMolecule } from 'screens/storyV2/store/storyPane';

const useToggleSidePanel = () => {
  const { useShowRightPanel: useShowSidePanelForStory } = useStoryPaneMolecule();
  const { useShowSidePanel, useSidepanelTab, useActiveView, useForceOpenId } =
    useSplitViewMolecule();

  const [, setShowSidepanel] = useShowSidePanel();
  const [, setShowSidepanelForStory] = useShowSidePanelForStory();

  const activeView = useActiveView();
  const [, setSidepanelTab] = useSidepanelTab();
  const [, setForceOpenId] = useForceOpenId();

  const toggleSidePanel = useCallback(
    (id: string, tabType: SidepanelTabValue) => {
      if (activeView === 'story') setShowSidepanelForStory(true);
      else setShowSidepanel(true);

      setSidepanelTab(tabType);
      setForceOpenId(id);
      setTimeout(() => scrollItemIntoView(id), 500);
    },
    [activeView],
  );

  return { toggleSidePanel };
};

export default useToggleSidePanel;
