import { memo } from 'react';

import Tabs from './tabs';
import UserContent from './userContent';
import CreateNew from './create';

import {
  StyledAppBar as AppBar,
  StyledToolbar as Toolbar,
  StyledTabDivider as Divider,
} from './navbar-styled';

const NavbarView = () => (
  <AppBar elevation={0} position="static">
    <Toolbar>
      <CreateNew />
      <Tabs />
      <Divider orientation="vertical" flexItem />
      <UserContent />
    </Toolbar>
  </AppBar>
);

export default memo(NavbarView);
