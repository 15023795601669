import styled from '@emotion/styled';

import { SelectContent, SelectGroup, SelectItem, SelectTrigger } from 'lib/select';
import { dialogBoxShadow } from 'theme/utils/commonStyles';

export const Trigger = styled(SelectTrigger)`
  display: flex;
  width: auto;
  height: 32px;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid transparent;
  background-color: transparent;
  padding-inline: 8px;
  outline: none;
  outline-color: transparent;
  outline-style: solid;
  outline-width: 1px;
  ${({ theme }) => theme.typography.dina.listItemLabel};
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  &:hover {
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
  &:focus {
    outline-width: 1px;
    outline-style: solid;
    border-color: ${({ theme }) => theme.palette.dina.statusOnHover};
    background-color: ${({ theme }) => theme.palette.dina.onFocusedBackground};
    outline-color: ${({ theme }) => theme.palette.dina.onFocus};
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
`;

export const Content = styled(SelectContent)`
  position: relative;
  z-index: 2000;
  min-width: 120px;
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.palette.dina.buttonBorderOutlined};
  background-color: ${({ theme }) => theme.palette.dina.surfaceCard};
  ${dialogBoxShadow};
`;

export const Item = styled(SelectItem)`
  position: relative;
  display: flex;
  width: auto;
  cursor: default;
  user-select: none;
  align-items: center;
  justify-content: flex-start;
  border-radius: 6px;
  padding-block: 6px;
  padding-inline: 8px;
  ${({ theme }) => theme.typography.dina.listItemLabel};
  outline: none;
  outline-color: transparent;
  outline-style: solid;
  outline-width: 1px;
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  &:hover {
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
  &:focus {
    background-color: ${({ theme }) => theme.palette.dina.onFocusedBackground};
    outline-color: ${({ theme }) => theme.palette.dina.onFocus};
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
`;

export const Group = styled(SelectGroup)`
  padding-inline: 8px;
  padding-block: 6px;
`;
