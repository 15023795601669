import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import { Button } from '@material-ui/core';

const buttonStyle = ({ theme }) => css`
  ${theme.typography.dina.button};
  width: 100%;
  border-radius: 4px;
  height: 40px;
  text-transform: none;
  :focus {
    box-shadow: 0 0 0 2px ${theme.palette.dina.onFocus};
  }
`;

export const DateTimeWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const DatePickerWrapper = styled('div')`
  width: 202px;
  margin: 0px 4px 0px 8px;
`;

export const TimePickerWrapper = styled('div')`
  width: 134px;
  margin: 0px 8px 0px 4px;
`;

export const ButtonWrapper = styled('div')`
  display: flex;
`;

export const CancelButton = styled(Button)`
  ${buttonStyle}
  margin:8px 4px 0px 8px;
  :hover {
    ${({ theme }) => css`
      -webkit-filter: ${theme.palette.dina.onHover};
      filter: ${theme.palette.dina.onHover};
    `}
  }
`;

export const OkButton = styled(Button)`
  ${buttonStyle}
  margin:8px 8px 0px 4px;
  background-color: ${({ theme }) => theme.palette.dina.paletteAccentPurple};
  :hover {
    ${({ theme }) => css`
      -webkit-filter: ${theme.palette.dina.onHover};
      filter: ${theme.palette.dina.onHover};
      background-color: ${theme.palette.dina.paletteAccentPurple};
    `}
  }
`;
