import { gql } from 'graphql-tag';

const draftCommonFields = `
  mId
  mRefId
  mTitle
  mDescription
  mType
  mUpdatedAt
  mCreatedAt
  mPublishingAt
  mPublishingEnd
`;

export const CREATE_DRAFT = gql`
  mutation CreateDraft($input: CreateMemberInput) {
    createMember(input: $input) {
      ${draftCommonFields}
    }
  }
`;

export const UPDATE_DRAFT = gql`
  mutation UpdateDraft($input: UpdateMemberInput) {
    updateMember(input: $input) {
      ${draftCommonFields}
    }
  }
`;
