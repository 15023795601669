import styled from '@emotion/styled';

// eslint-disable-next-line import/prefer-default-export
export const IconWrapper = styled('div')`
  width: 24px;
  height: 24px;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  overflow: hidden;
`;
