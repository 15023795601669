import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    width: '100%',
  },
  head: {
    height: '32px',
  },
  stickyHeader: {},
  headRow: {},
  headCell: {
    ...theme.typography.dina.overline,
    padding: '0 0 0 8px',
    height: '32px',
    background: theme.palette.dina.surfaceAppBackgroundNavLevel2,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    '&:first-of-type': {
      padding: '0 0 0 16px',
    },
  },
  headRoot: {
    color: `${theme.palette.dina.mediumEmphasis} !important`,
    '& path': {
      fill: theme.palette.dina.iconActive,
      'fill-opacity': 0.25,
    },
    '&:hover $headIcon': {
      opacity: 1,
      '& path': {
        'fill-opacity': 0.54,
      },
    },
  },
  headActive: {
    '& path': {
      fill: theme.palette.dina.iconSelected,
      'fill-opacity': 0.75,
    },
    '&:hover $headIcon': {
      opacity: 1,
      '& path': {
        'fill-opacity': 1,
      },
    },
  },
  headIcon: {
    opacity: 1,
  },
  tableRow: {
    height: '40px',
    '&:hover': {
      background: theme.palette.dina.whiteHoverOverlay,
    },
  },
  bodyCell: {
    height: '40px',
    padding: '0 4px 0 7px',
    border: 'none',
    whiteSpace: 'nowrap',
    background: theme.palette.dina.outlineButtonOnHover,
    borderTop: `1px solid transparent`,
    borderBottom: `1px solid transparent`,
    '&:first-of-type': {
      padding: '0 0 0 16px',
      borderLeft: `1px solid transparent`,
    },
    '&:last-child': {
      borderRight: `1px solid transparent`,
    },
  },
  alter: {
    background: theme.palette.dina.tableAlternatingRowOverlay,
    borderTop: `1px solid transparent`,
    borderBottom: `1px solid transparent`,
    '&:first-of-type': {
      padding: '0 0 0 16px',
      borderLeft: `1px solid transparent`,
    },
    '&:last-child': {
      borderRight: `1px solid transparent`,
    },
  },
  selected: {
    backgroundColor: theme.palette.dina.inputBackgroundFocused,
    borderTop: `1px solid ${theme.palette.dina.inputBorderFocused}`,
    borderBottom: `1px solid ${theme.palette.dina.inputBorderFocused}`,
    '&:first-of-type': {
      padding: '0 0 0 16px',
      borderLeft: `1px solid ${theme.palette.dina.inputBorderFocused}`,
    },
    '&:last-child': {
      borderRight: `1px solid ${theme.palette.dina.inputBorderFocused}`,
    },
  },
  bodyText: {
    ...theme.typography.dina.listItemLabel,
    color: theme.palette.dina.highEmphasis,
  },
  selectedRow: {
    backgroundColor: `${theme.palette.dina.inputBackgroundFocused} !important`,
  },

  emptyRow: {
    height: '56px',
  },

  loading: {
    background: theme.palette.dina.outlineButtonOnHover,
  },
  loadingAlter: {
    background: theme.palette.dina.tableAlternatingRowOverlay,
  },

  paginationContainer: {
    position: 'absolute',
    left: '0px',
    right: '0px',
    bottom: '7px',
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paginationWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '40px',
    width: '420px',
    padding: '0px 8px',
    background: theme.palette.dina.boxShadowDark,
    backdropFilter: 'blur(5px)',
    borderRadius: '8px',
  },
  rowsPerPage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '170px',
    height: '32px',
    marginRight: '8px',
  },
  rowsCount: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '225px',
    height: '32px',
    background: theme.palette.dina.blackHoverOverlay,
    border: `0.5px solid ${theme.palette.dina.buttonBorderOutlined}`,
    borderRadius: '6px',
  },
  arrow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40px',
    '&:hover:not($disabled)': {
      cursor: 'pointer',
      '& path': {
        'fill-opacity': 1,
      },
    },
  },
  disabled: {
    '& path': {
      'fill-opacity': 0.25,
    },
  },
  rowsCountText: {
    ...theme.typography.dina.listItemLabel,
    color: theme.palette.dina.mediumEmphasis,
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-khtml-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    'user-select': 'none',
  },
}));

export default useStyles;
