import gql from 'graphql-tag';

export default gql`
  mutation UploadAsset($input: UploadAssetInput) {
    uploadAsset(input: $input) {
      mId
      mRefId
      mAssetId
      mType
      mState
      itemType
      mediaType
      mTitle
      mDescription
      mMetaData {
        key
        value
      }
    }
  }
`;
