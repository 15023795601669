import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';
import ListItem from 'components/ellipsisDropdown/listItem-view';
import Checkbox from 'components/checkbox';
import Divider from 'components/divider';
import { getPlatformLabel } from 'utils/instance/platform';
import Footer from './footer';
import { Wrapper, Header, FormControl } from './platforms-styled';

const Platforms = ({ filters, updateFilters, onClose, platforms }) => {
  const [selectedFilters, setSelectedFilters] = useState([]);

  useEffect(() => {
    setSelectedFilters(filters);
  }, [filters]);

  const togglePlatform = (platform) => {
    const { platform: platformName, platformKind } = platform;
    const filter = selectedFilters.find(
      (selectedFilter) =>
        selectedFilter.platform === platformName &&
        (selectedFilter.platformKind === platformKind ||
          (!selectedFilter.platformKind && !platformKind)),
    );

    if (filter) {
      const newSelectedFilters = filter.platformKind
        ? selectedFilters.filter(
            (selectedFilter) =>
              !(
                selectedFilter.platform === platformName &&
                selectedFilter.platformKind === platformKind
              ),
          )
        : selectedFilters.filter(
            (selectedFilter) =>
              !(selectedFilter.platform === platformName && !selectedFilter.platformKind),
          );

      setSelectedFilters(newSelectedFilters);
    } else {
      setSelectedFilters([...selectedFilters, platform]);
    }
  };

  const clearSelectedFilters = () => {
    setSelectedFilters([]);
  };

  const handleUpdateFilters = () => {
    updateFilters(selectedFilters);
    onClose();
  };

  const checkIsSelected = (platform, platformKind) =>
    Boolean(
      selectedFilters.find(
        (selectedFilter) =>
          selectedFilter.platform === platform &&
          ((!selectedFilter.platformKind && !platformKind) ||
            selectedFilter.platformKind === platformKind),
      ),
    );

  return (
    <Wrapper>
      <Header>Choose Platforms to show</Header>
      <Divider />
      {platforms.map((platform) => {
        const label = getPlatformLabel(
          platform.mProperties.platform,
          platform.mProperties.platformKind,
        );
        const selected = checkIsSelected(
          platform.mProperties.platform,
          platform.mProperties.platformKind,
        );
        const onClick = () => togglePlatform(platform.mProperties);
        return (
          <FormControl
            key={label}
            control={<Checkbox selected={selected} onClick={onClick} size={24} />}
            label={capitalize(label)}
          />
        );
      })}
      <Divider />
      <ListItem
        excludeDivider
        maxHeight={40}
        text="Clear selected items"
        onClick={clearSelectedFilters}
        disabled={selectedFilters.length === 0}
      />
      <Divider />
      <Footer
        onCancel={onClose}
        onOk={handleUpdateFilters}
        isOkDisabled={
          filters.length === selectedFilters.length &&
          filters.every((filter) =>
            selectedFilters.find(
              (selectedFilter) =>
                selectedFilter.platform === filter.platform &&
                ((!selectedFilter.platformKind && !filter.platformKind) ||
                  selectedFilter.platformKind === filter.platformKind),
            ),
          )
        }
      />
    </Wrapper>
  );
};

Platforms.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      platform: PropTypes.string,
      accounts: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  ),
  updateFilters: PropTypes.func,
  onClose: PropTypes.func,
  platforms: PropTypes.arrayOf(PropTypes.shape({})),
};

Platforms.defaultProps = {
  filters: [],
  updateFilters: () => {},
  onClose: () => {},
  platforms: [],
};

export default Platforms;
