import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  list: {
    '& > *': {
      marginBottom: '8px',
    },
    position: 'absolute',
    right: '0px',
  },
}));

export default useStyles;
