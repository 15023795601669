import { memo, useEffect, useLayoutEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import TourPopup from 'components/tourPopup';
import useApolloSubscription from 'hooks/useApolloSubscriptionV2';
import useBoundingClientRect from 'hooks/useBoundingClientRect';
import useContentTabMessage from 'hooks/useContentTabMessage';
import useTabs from 'hooks/useTabs';
import CONTENT_CHAT from 'operations/subscriptions/updateContentChat';
import { tours } from 'store';
import { useContentTabs, useIconTabs, useMaxVisibleTabs } from 'store/tabs';

import ContentTab from './components/contentTab';
import TabsDropdown from './components/dropdown';
import IconTab from './components/iconTab';

import { StyledTabs } from './styled';

const minContentTabWidth = 146;
const maxContentTabWidth = 240;
const iconTabWidth = 48;

const TabsContainer = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const iconTabs = useIconTabs();
  const [contentTabs] = useContentTabs();
  const { resetTabNotification } = useTabs();
  const [maxVisibleTabs, setMaxVisibleTabs] = useMaxVisibleTabs();
  const [processContentTabMessageData] = useContentTabMessage();

  const [rect, ref] = useBoundingClientRect<HTMLDivElement>();
  const { width } = rect || {};

  const [, type] = pathname.split('/');
  const selectedTab =
    (id ? contentTabs.find((tab) => tab.id === id) : iconTabs.find((tab) => tab.type === type)) ??
    iconTabs[0];

  const contentIds = useMemo(() => {
    if (contentTabs?.length === 0) return [];
    const visibleContentTabIds = contentTabs.slice(0, maxVisibleTabs).reduce((ids, tab) => {
      return (tab.type === 'pitch' || tab.type === 'story') && tab.restricted
        ? ids
        : [...ids, tab.id];
    }, [] as string[]);

    return id && !visibleContentTabIds?.includes(id)
      ? [...visibleContentTabIds, id]
      : visibleContentTabIds;
  }, [contentTabs, id, maxVisibleTabs]);

  const subscriptionFilter = JSON.stringify(contentIds);

  const maxContentTabs = useMemo(() => {
    if (!width) return 0;
    return Math.floor((width - iconTabs.length * iconTabWidth) / minContentTabWidth);
  }, [iconTabs.length, width]);

  const contentTabWidth = useMemo(() => {
    let contentWidth = 0;
    if (!width) return contentWidth;
    const cTWidth = Math.floor(
      (width -
        iconTabs.length * iconTabWidth -
        ((width - iconTabs.length * iconTabWidth) % minContentTabWidth)) /
        contentTabs.length,
    );
    if (cTWidth < minContentTabWidth) {
      contentWidth = minContentTabWidth;
    }
    if (cTWidth > maxContentTabWidth) {
      contentWidth = maxContentTabWidth;
    }
    if (cTWidth >= minContentTabWidth && cTWidth <= maxContentTabWidth) {
      contentWidth = cTWidth;
    }
    return contentWidth;
  }, [contentTabs.length, iconTabs.length, width]);

  useLayoutEffect(() => {
    if (width) setMaxVisibleTabs(maxContentTabs);
  }, [maxContentTabs, setMaxVisibleTabs, width]);

  useApolloSubscription(CONTENT_CHAT, {
    variables: { ids: subscriptionFilter },
    onSubscriptionData: processContentTabMessageData,
    skip: !contentIds || contentIds.length === 0,
    source: 'tabs',
  });

  useEffect(() => {
    if (id) {
      const toReset = contentTabs.find((tab) => tab.id === id);
      if (toReset) resetTabNotification(toReset);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <TourPopup
        position="bottom"
        anchor={ref}
        tourStep={tours.MainTour.steps.Home}
        tourId={tours.MainTour.id}
      />
      <StyledTabs ref={ref} $tab={selectedTab}>
        {iconTabs.map((tab) => (
          <IconTab key={tab.type} tab={tab} isSelected={tab.type === selectedTab.type} />
        ))}
        {(contentTabs.length > maxVisibleTabs
          ? contentTabs.slice(0, maxVisibleTabs)
          : contentTabs
        ).map((tab, index) => (
          <ContentTab
            key={`${tab.type}_${tab.id}`}
            index={index}
            width={contentTabWidth}
            tab={tab}
            isSelected={tab.id === id}
            notificationCount={tab.notificationCount}
          />
        ))}
      </StyledTabs>
      {/* Dropdown ↓ */}
      <TabsDropdown />
    </>
  );
};

export default memo(TabsContainer);
