import { gql } from 'graphql-tag';

export default gql`
  mutation UpdateUserState($input: UserStateChangeInput) {
    updateUserState(input: $input) {
      mId
      mRefId
      mLastLogin
      mTitle
      mType
      mMetaData {
        value
        key
      }
      user {
        email
        provider
        status
      }
      metadata
    }
  }
`;
