/* eslint-disable sort-imports */
import { memo, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { BaseRange } from 'slate';
import { useSlate } from 'slate-react';

import { useEditorContext } from 'components/editor/EditorContext';
import { useEditorMolecule } from 'components/editor/store';
import Text from 'components/text/Text';
import { getSupportedResourceType } from 'features/sidepanel/ComponentUtils';
import useFuseSearch from 'hooks/useFuseSearch';
import { Box } from 'layouts/box/Box';
import { useEditorCommands } from 'store';
import { MemberTypeEnum } from 'types/graphqlTypes';
import { EditorCommandConfigType } from 'types/memberTypes/editorCommands';
import preventDefaultAndPropagation from 'utils/preventDefaultAndStopPropagation';

import useCombobox, { Position } from '../../hook/useCustomCombobox';
import useInsertBlock from '../../hook/useInsertBlock';

import { Info, List, ListWrapper, MenuItem, TaskIcon } from './styled';

const beforeRegex = /(?:^\/)|(?:\/$)/;
const afterRegex = /^(\s|$)/;

function Portal({ children }: { children: ReactNode }) {
  return createPortal(children, document.body);
}

function UserCommands({ readOnly }: { readOnly: boolean }) {
  const editor = useSlate();
  const [search, setSearch] = useState('');
  const { useCommandTarget } = useEditorMolecule();
  const [target, setTarget] = useCommandTarget();
  const [allEditorCommands] = useEditorCommands();
  const { resourceDetails } = useEditorContext();
  const fuseSearch = useFuseSearch({
    shouldSort: true,
  });
  const { insertMdfBlock, insertOrderBlock } = useInsertBlock(search, resourceDetails);

  const { resource, orderFormMap } = resourceDetails ?? {};

  const editorCommands = useMemo(() => {
    if (!resource) return [];

    const formTypes = getSupportedResourceType(resource);

    const filteredCommands = allEditorCommands.filter((editorCmd) => {
      if (editorCmd.mResourceType === MemberTypeEnum.Mdf) return editorCmd.mActive;

      const orderForm = orderFormMap?.[editorCmd.mTertId];
      if (orderForm?.configs) {
        const allowedTypes =
          orderForm.configs.find((config) => config.key === 'types')?.values ?? [];
        return editorCmd.mActive && allowedTypes.some((type) => formTypes.includes(type));
      }
      return false;
    });

    return filteredCommands;
  }, [allEditorCommands, orderFormMap, resource]);

  const matchFunction = useCallback(
    (list: EditorCommandConfigType[]) =>
      fuseSearch(list, ['mTitle', 'slashCommand'], search?.substring(1) || ''),
    [fuseSearch, search],
  );

  const filteredCommands = useMemo(
    () => matchFunction(editorCommands).sort((a, b) => a.mTitle.localeCompare(b.mTitle)),
    [matchFunction, editorCommands],
  );

  const insertCommand = useCallback(
    (command: EditorCommandConfigType, targetNode: BaseRange | null) => {
      if (!targetNode) return;

      switch (command.mResourceType) {
        case MemberTypeEnum.OrderForm:
          return insertOrderBlock(command, targetNode);
        case MemberTypeEnum.Mdf:
          return insertMdfBlock(command, targetNode);
        default:
          return;
      }
    },
    [editor, search, insertOrderBlock],
  );

  const { position, cursor } = useCombobox(
    filteredCommands,
    insertCommand,
    setSearch,
    {
      beforeExp: beforeRegex,
      afterExp: afterRegex,
    },
    target,
    setTarget,
    false,
  );

  useEffect(() => {
    const targetElement = document.getElementById(filteredCommands[cursor]?.mRefId);
    targetElement?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }, [cursor, filteredCommands]);

  const showSuggestion = !readOnly && target && filteredCommands.length > 0;

  const onClickInsert = useCallback(
    (event: React.MouseEvent<HTMLLIElement>, command: EditorCommandConfigType) => {
      preventDefaultAndPropagation(event);
      insertCommand(command, target);
      setTarget(null);
    },
    [target],
  );

  return showSuggestion ? (
    <Portal>
      <ListWrapper position={position as Position<string>} elevation={12}>
        <List disablePadding>
          <Box container justifyContent="flex-start" height="32px" padding="0 8px 0">
            <Text variant="caption" color="inactive">
              Commands
            </Text>
          </Box>
          {filteredCommands.map((command, index) => {
            const { mRefId, mTitle, slashCommand } = command;
            return (
              <MenuItem
                dense
                disableRipple
                selected={index === cursor}
                key={mRefId}
                id={mRefId}
                onClick={(event) => onClickInsert(event, command)}
                disableGutters
              >
                {mTitle && (
                  <Text variant="listItemLabel" color="highEmphasis">
                    {mTitle}
                  </Text>
                )}
                {command.mResourceType === MemberTypeEnum.OrderForm && (
                  <TaskIcon height={12} width={12} className="skipOverride" />
                )}
                <Info>{`/${slashCommand}`}</Info>
              </MenuItem>
            );
          })}
        </List>
      </ListWrapper>
    </Portal>
  ) : null;
}

export default memo(UserCommands);
