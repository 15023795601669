import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { InputWrapper, OutlinedInput, DivWrapper } from './inputField-styled';
import { useChangedInstanceFields } from '../../../../../../../atoms';

const InputField = (props) => {
  const { rowId, fieldId, viewId, value, disabled } = props;
  const [changedFields, setChangedFields] = useChangedInstanceFields();
  const [inputValue, setInputValue] = useState(value);
  const inputRef = useRef(null);

  const handleOnClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };


  const onKeyDown = (event) => {
    if (event?.key === 'ArrowUp' || event?.key === 'ArrowDown') {
      inputRef.current.blur();
    }
    if (event?.key === 'Enter') {
      inputRef.current.blur();
      event.stopPropagation();
      event.preventDefault();
    }
  };

  const handleInputChange = (event) => {
    const displayValue = event.target.value;
    setInputValue(displayValue);
    const hasFieldChanges = changedFields.find((changedField) => changedField.rowId === rowId);
    if (!hasFieldChanges) {
      setChangedFields([...changedFields, { rowId, fieldId, viewId, displayValue }]);
      return;
    }

    if (value !== displayValue) {
      const updatedFields = changedFields.map((changedField) => {
        if (changedField.rowId === rowId) {
          return { ...changedField, displayValue };
        }
        return changedField;
      });
      setChangedFields([...updatedFields]);
      return;
    }
    // If Changed input value is same as original value
    const updatedChangedFields = changedFields.filter((changedRow) => changedRow.rowId !== rowId);
    if ('checked' in hasFieldChanges) {
      updatedChangedFields.push({ rowId, fieldId, viewId, checked: hasFieldChanges.checked });
    }
    setChangedFields([...updatedChangedFields]);
  };

  if (disabled) return <DivWrapper>{inputValue}</DivWrapper>;

  return (
    <InputWrapper onClick={handleOnClick} onDoubleClick={handleOnClick}>
      <OutlinedInput
        value={inputValue}
        fullWidth
        onChange={handleInputChange}
        inputRef={inputRef}
        onKeyDown={onKeyDown}
      />
    </InputWrapper>
  );
};

InputField.propTypes = {
  rowId: PropTypes.string,
  fieldId: PropTypes.string,
  viewId: PropTypes.string,
  value: PropTypes.string,
};

InputField.defaultProps = {
  rowId: '',
  fieldId: '',
  viewId: '',
  value: '',
};

export default InputField;
