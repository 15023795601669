import ReactSplitPane from 'react-split-pane';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const CollapseLine = styled('div')`
  ${({ theme }) => css`
    cursor: pointer;
    display: inline-flex;
    width: 16px;
    height: 100%;
    border-right: 1px solid ${theme.palette.dina.dividerLight};
    background: ${theme.palette.dina.surfaceAppBackgroundNavLevel1};
    &:hover {
      background: ${theme.palette.dina.hoveringTooltip};
    }
  `}
`;

export const SplitPane = styled(ReactSplitPane)`
  height: 100%;
  .Resizer {
    box-sizing: border-box;
    background-clip: padding-box;
    z-index: 100;
    cursor: col-resize;
    transition: all 150ms ease-in-out;
    :before {
      content: '';
      width: 2px;
      height: 100%;
      display: block;
      text-align: center;
      background-color: ${({ theme }) => theme.palette.dina.dividerLight};
    }
    :hover,
    :focus {
      ${({ theme }) => css`
        background-color: ${theme.palette.dina.onFocus};
        box-shadow: 0px 0px 0px 1px ${theme.palette.dina.onFocus};
      `}
    }
  }
`;
