/**
 * Function invoked when a timeout occurs
 * @callback timeoutCallback
 */

/**
 * Function invoked to get a duration in milliseconds
 * @callback durationCallback
 * @returns {number} duration in milliseconds
 */

/**
 * Sets a timer which executes a function for two events, onTimeout and onExpired.
 * @param {Object} params - Object with named properties
 * @param {number} params.expirationTime - Time in milliseconds when the timer expires
 * @param {number} params.maxIdleTime - Time in milliseconds for maximum idle time
 * @param {durationCallback} params.getIdleTime - Function invoked to get the idle duration in msec
 * @param {timeoutCallback} params.onTimeout - Timeout invoked when idle exceeds maxIdleTime
 * @param {timeoutCallback} params.onExpired - Timeout invoked when timer expires
 *
 * @returns {Object} - Timer object used to clear active timers using clearIdleTimer
 */
const setIdleTimer = ({ expirationTime, maxIdleTime, getIdleTime, onTimeout, onExpired }) => {
  const timeouts = { idleTimer: null, expirationTimer: null };
  const getTimeouts = () => timeouts;
  const resetTimeouts = () => {
    timeouts.expirationTimer = null;
    timeouts.idleTimer = null;
  };
  const timer = { getTimeouts, resetTimeouts };

  const idleTimeSampler = () => {
    const idleTime = getIdleTime?.();
    if (idleTime >= maxIdleTime) {
      clearIdleTimer(timer);
      onTimeout?.();
      return;
    }
    timeouts.idleTimer = setTimeout(() => {
      idleTimeSampler();
    }, maxIdleTime - idleTime);
  };

  if (getIdleTime) {
    timeouts.idleTimer = setTimeout(() => {
      idleTimeSampler();
    }, maxIdleTime);
  }

  timeouts.expirationTimer = setTimeout(() => {
    clearIdleTimer(timer);
    onExpired?.();
  }, expirationTime);

  return timer;
};

/**
 * Cancels a timer previously established by calling setIdleTimer().
 * @param {Object} timer - Object created by setIdleTimer to cancel
 */
const clearIdleTimer = (timer) => {
  const { getTimeouts = () => {}, resetTimeouts = () => {} } = timer || {};
  const { idleTimer, expirationTimer } = getTimeouts();
  if (idleTimer) {
    clearTimeout(idleTimer);
  }
  if (expirationTimer) {
    clearTimeout(expirationTimer);
  }
  resetTimeouts();
};

export { setIdleTimer, clearIdleTimer };
