import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const GroupPermissionTableRow = styled('div')`
  ${({ theme }) => css`
    ${theme.typography.dina.listItemLabel}
  `}
  width: 100%;
  display: flex;
  height: 40px;
  cursor: pointer;
  user-select: none;
  align-items: center;
  justify-content: center;
`;

export const CheckboxCell = styled('div')`
  margin-left: -5px;
`;

export const PermissionCell = styled('div')`
  flex: 1;
  margin-left: 8px;
`;

export const ResourceTypesCell = styled('div')`
  width: 160px;
  flex-shrink: 0;
`;
