import React from 'react';

import Text from 'components/text';
import { Box } from 'layouts/box/Box';

import { IconDivider, IconWrapper, ListItemWrapper } from './styled';

interface Props {
  text: string;
  info?: string;
  icon?: React.ReactElement;
  topDivider?: boolean;
  bottomDivider?: boolean;
  onClick: () => void;
  disabled?: boolean;
}

const ListItemView = ({
  text,
  info,
  icon,
  topDivider,
  bottomDivider,
  onClick,
  disabled,
}: Readonly<Props>) => {
  return (
    <Box
      container
      flexDirection="column"
      alignItems="start"
      justifyContent="space-between"
      width="100%"
    >
      {topDivider && <IconDivider $icon={!!icon} />}
      <ListItemWrapper disabled={disabled} button onClick={onClick}>
        {icon && <IconWrapper>{icon}</IconWrapper>}
        <Box container flexDirection="row" justifyContent="space-between" width="100%">
          <Text variant="listItemLabel">{text}</Text>
          {info && (
            <Text variant="caption" color="whiteInactive">
              {info}
            </Text>
          )}
        </Box>
      </ListItemWrapper>
      {bottomDivider && <IconDivider $icon={!!icon} />}
    </Box>
  );
};

export default ListItemView;
