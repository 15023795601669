import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Divider from 'components/divider';
import Popover from 'components/popover';
import CreateNew from 'components/createNew';
import Tooltip from 'components/tooltip';
import useHover from 'hooks/useHover';
import { ReactComponent as AddIcon } from 'assets/icons/systemicons/plus_small.svg';
import { ReactComponent as PinOnIcon } from 'assets/icons/systemicons/pin_on.svg';
import { ReactComponent as PinOffIcon } from 'assets/icons/systemicons/pin_off.svg';
import { currentSearchTypes } from 'utils/constants/searchbar';
import { BookmarkIcon } from '../icons';
import getTooltipTitleFromModel from '../../utils/getTooltipTitleFromModel';
import {
  Wrapper,
  ScrollWrapper,
  Header,
  CheckedIcon,
  LeadingIconWrapper,
  PinIconWrapper,
  DeleteIcon,
  ListItem,
  HoverVisibilityWrapper,
  PinOffIconHovered,
} from './savedSearches-styled';

const Icons = ({ isPinned = false, onDelete = () => {}, onTogglePin = () => {} }) => {
  const [hoverRef, isHovered] = useHover();

  const UnPinnedIcon = isHovered ? PinOnIcon : PinOffIcon;
  const PinnedIcon = isHovered ? PinOffIconHovered : PinOnIcon;

  const handleDelete = (event) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    onDelete();
  };

  const handleTogglePin = (event) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    onTogglePin();
  };

  return (
    <>
      <Tooltip title="Remove saved search">
        <HoverVisibilityWrapper>
          <DeleteIcon onClick={handleDelete} />
        </HoverVisibilityWrapper>
      </Tooltip>
      <Tooltip title={isPinned ? 'Unpin filter' : 'Pin filter'}>
        <PinIconWrapper ref={hoverRef} onClick={handleTogglePin}>
          {isPinned ? (
            <PinnedIcon />
          ) : (
            <HoverVisibilityWrapper>
              <UnPinnedIcon />
            </HoverVisibilityWrapper>
          )}
        </PinIconWrapper>
      </Tooltip>
    </>
  );
};

const FilterListItem = ({ filter, onDelete, onTogglePin, selected, onClick, isPinned }) => {
  const deleteFilter = () => onDelete(filter);
  const toggleFilter = () => onTogglePin(filter);
  const selectFilter = () => onClick(filter);
  return (
    <ListItem
      excludeDivider
      maxHeight={40}
      key={filter?.label}
      text={filter?.label}
      tooltipTitle={getTooltipTitleFromModel(filter?.model)}
      firstChild={
        <LeadingIconWrapper>
          <BookmarkIcon />
        </LeadingIconWrapper>
      }
      iconChild={<Icons onDelete={deleteFilter} onTogglePin={toggleFilter} isPinned={isPinned} />}
      onClick={selectFilter}
      whiteSpace="nowrap"
      selected={selected}
    />
  );
};

const SavedSearches = ({
  history,
  savedFilters,
  currentSearch,
  updateCurrentSearch,
  onSelectSearch,
  onCreateFilter,
  onUpdateFilters,
  isEmptyModel,
  hideSavedFilters,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const closePopover = () => {
    setAnchorEl(null);
  };

  const pinnedFilters = [];
  const unpinnedFilters = [];

  savedFilters.forEach((savedFilter) => {
    if (savedFilter.isPinned) pinnedFilters.push(savedFilter);
    else unpinnedFilters.push(savedFilter);
  });

  const deleteFilter = (filter) => {
    onUpdateFilters(savedFilters.filter((savedFilter) => savedFilter !== filter));
  };

  const handleCreateNewFilter = (label) => {
    onCreateFilter(label);
    setAnchorEl(null);
  };
  const handleTogglePin = (filter) => {
    const newFilter = {
      id: filter?.id,
      label: filter?.label,
      model: filter?.model,
      isPinned: !filter?.isPinned,
    };
    const newSavedFilters = [
      ...savedFilters.filter((savedFilter) => savedFilter !== filter),
      newFilter,
    ];
    onUpdateFilters(newSavedFilters);
  };

  const isFilterSelected = (filter) =>
    currentSearch?.type === currentSearchTypes.SAVED_FILTERS &&
    currentSearch?.identification === filter?.label;

  const onFilterSelect = (filter) => {
    updateCurrentSearch(currentSearchTypes.SAVED_FILTERS, filter?.label, filter?.model);
    onSelectSearch(filter?.model);
  };

  return (
    <Wrapper>
      <ScrollWrapper>
        <Header>RECENT SEARCHES</Header>
        <Divider />
        {history.length > 0 ? (
          history.map((search, index) => {
            const isSelected =
              currentSearch?.type === currentSearchTypes.HISTORY &&
              currentSearch?.identification === index;
            return (
              <ListItem
                excludeDivider
                maxHeight={40}
                key={search?.id || `search_history_${index}`}
                text={getTooltipTitleFromModel(search)}
                iconChild={isSelected ? <CheckedIcon /> : undefined}
                selected={isSelected}
                tooltipTitle={getTooltipTitleFromModel(search)}
                onClick={() => {
                  updateCurrentSearch(currentSearchTypes.HISTORY, index, search);
                  onSelectSearch(search);
                }}
                whiteSpace="nowrap"
              />
            );
          })
        ) : (
          <ListItem text="No recent searches" disabled excludeDivider maxHeight={40} />
        )}
        {!hideSavedFilters && (
          <>
            <Divider />
            {pinnedFilters.length > 0 && (
              <>
                <Header>PINNED FILTER SHORTCUTS</Header>
                {pinnedFilters.map((filter, index) => (
                  <FilterListItem
                    key={filter?.id || `pinned_filter_${index}`}
                    filter={filter}
                    onDelete={deleteFilter}
                    onTogglePin={handleTogglePin}
                    selected={isFilterSelected(filter)}
                    onClick={onFilterSelect}
                    isPinned
                  />
                ))}
              </>
            )}
            <Header>FILTER SHORTCUTS</Header>
            {unpinnedFilters.length > 0 ? (
              unpinnedFilters.map((filter, index) => (
                <FilterListItem
                  key={filter?.id || `unpinned_filter_${index}`}
                  filter={filter}
                  onDelete={deleteFilter}
                  onTogglePin={handleTogglePin}
                  selected={isFilterSelected(filter)}
                  onClick={onFilterSelect}
                />
              ))
            ) : (
              <ListItem text="No saved filters" disabled excludeDivider maxHeight={40} />
            )}
          </>
        )}
      </ScrollWrapper>
      {!hideSavedFilters && (
        <>
          <Divider />
          <Header>Save as new Search Bookmark</Header>
          <ListItem
            excludeDivider
            maxHeight={40}
            text="Save current search as Bookmark"
            firstChild={
              <LeadingIconWrapper>
                <AddIcon />
              </LeadingIconWrapper>
            }
            onClick={(event) => setAnchorEl(event.currentTarget)}
            disabled={isEmptyModel}
          />
        </>
      )}
      <Popover onClose={closePopover} anchorEl={anchorEl}>
        <CreateNew onCancel={closePopover} onCreate={handleCreateNewFilter} variant="feedFilter" />
      </Popover>
    </Wrapper>
  );
};

SavedSearches.propTypes = {
  history: PropTypes.arrayOf(PropTypes.shape()),
  savedFilters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
    }),
  ),
  currentSearch: PropTypes.shape({
    type: PropTypes.string,
    identification: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    model: PropTypes.PropTypes.shape(),
  }),
  onSelectSearch: PropTypes.func,
  onCreateFilter: PropTypes.func,
  onUpdateFilters: PropTypes.func,
  isEmptyModel: PropTypes.bool,
  updateCurrentSearch: PropTypes.func,
  hideSavedFilters: PropTypes.bool,
};

SavedSearches.defaultProps = {
  history: [],
  savedFilters: [],
  currentSearch: undefined,
  onSelectSearch: () => {},
  onCreateFilter: () => {},
  onUpdateFilters: () => {},
  isEmptyModel: false,
  updateCurrentSearch: () => {},
  hideSavedFilters: false,
};

export default SavedSearches;
