import styled from '@emotion/styled';

const RootWrapper = styled('div')`
  width: 100%;
  height: calc(100% - 40px);
  display: flex-column;
  cursor: pointer;
`;

export default RootWrapper;
