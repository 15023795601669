import styled from '@emotion/styled';
import { FormControlLabel } from '@material-ui/core';

export const Wrapper = styled('div')`
  width: 320px;
  display: flex;
  flex-direction: column;
`;

export const Header = styled('div')`
  ${({ theme }) => theme.typography.dina.h7};
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
`;

export const FormControl = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin-left: 16px;
    height: 40px;
  }
  .MuiFormControlLabel-label {
    ${({ theme }) => theme.typography.dina.listItemLabel};
    margin-left: 6px;
  }
`;
