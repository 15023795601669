import { Asset } from 'types';
import { DroppedAsset } from 'utils/normalizeAssetData';

const normalizeIntoBlockData = (data: DroppedAsset & Asset) => ({
  mId: data.mId,
  mRefId: data.mRefId,
  mAssetId: data.mAssetId || data.mRefId,
  mTitle: data.title,
  mProvider: data.provider,
  itemDuration: data.itemDuration,
  itemType: data.itemType,
  assetType: data.itemType,
  mediaType: data.mediaType || data.itemType,
  proxy: data.proxy,
});

export default normalizeIntoBlockData;
