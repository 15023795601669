import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import Fab from '@material-ui/core/Fab';

import Divider from 'components/divider';
import MenuItem from 'lib/Menu/MenuItem';
import transientOptions from 'theme/helpers';

export const StyledCreateButton = styled(Fab, transientOptions)<{
  $notAllowed: boolean;
  $size?: number;
  $margin?: string;
}>`
  background: linear-gradient(45deg, #27d171 0%, #0385d1 100%);
  color: ${({ theme }) => theme.palette.dina.iconActive};
  height: ${({ $size }) => $size ?? 32}px;
  width: ${({ $size }) => $size ?? 32}px;
  min-height: ${({ $size }) => $size ?? 32}px;
  min-width: ${({ $size }) => $size ?? 32}px;
  margin: ${({ $margin }) => $margin ?? '0.5rem'};
  filter: ${({ $notAllowed }) => ($notAllowed ? 'saturate(50%)' : 'saturate(1)')};

  svg {
    path {
      fill: ${({ theme }) => theme.palette.dina.whiteHighEmphasis};
      fill-opacity: ${({ theme }) => (theme.palette.mode === 'light' ? 0.65 : 0.54)};
    }
  }

  cursor: ${({ $notAllowed }) => ($notAllowed ? 'not-allowed' : 'pointer')};

  :hover {
    svg {
      transform: ${({ $notAllowed }) => ($notAllowed ? 'none' : 'scale(1.1)')};
      path {
        fill-opacity: ${({ $notAllowed, theme }) =>
          $notAllowed ? (theme.palette.mode === 'light' ? 0.65 : 0.54) : 1};
      }
    }
  }
`;

export const MenuContainer = styled('div')`
  width: 300px;
`;

export const SvgHoverWrapper = styled('div')`
  position: relative;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledMenuItem = styled(MenuItem, transientOptions)<{
  $isUnassigned?: boolean;
  $type?: string;
}>`
  ${({ theme }) => theme.typography.dina.listItemLabel};
  text-transform: capitalize;

  ${({ $type = 'platform' }) =>
    $type === 'account'
      ? css`
          :first-of-type {
            border-top-right-radius: 0.5rem;
            border-top-left-radius: 0.5rem;
          }
          :last-of-type {
            border-bottom-right-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
        `
      : ''};

  :hover {
    ${SvgHoverWrapper} {
      svg {
        path {
          fill-opacity: 1;
        }
      }
    }
  }

  &.MuiMenuItem-root {
    width: 298px;
    height: 32px;
    overflow: hidden;

    ${({ $isUnassigned }) =>
      $isUnassigned && {
        borderBottom: '1px solid rgba(172, 184, 198, 0.25)',
      }}
  }
`;

export const StyledCreateMenuWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  min-width: 280px;
  padding-block: 0.5rem;
`;
export const StyledDivider = styled(Divider)`
  height: 1px;
  margin-block: 8px;
`;

export const CreateMenuLabel = styled('div')`
  ${({ theme }) => theme.typography.dina.overline}
  padding: 0.5rem 1rem;
`;
