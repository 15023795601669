import { css } from '@emotion/react';

/**
 * An attempt at reusable hover styles for our SVG icons.
 */
export const svgHoverStyles = ({ theme }) => css`
  cursor: pointer;
  :hover path {
    fill: white;
    fill-opacity: 1 !important;
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const dialogBoxShadow = ({ theme }) => css`
  box-shadow: 0 0 2px ${theme.palette.dina.boxShadowDark},
    0 0 4px ${theme.palette.dina.boxShadowDark}, 0 12px 24px ${theme.palette.dina.boxShadowDark};
`;
