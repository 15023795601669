import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import ListItemComponent from 'components/ellipsisDropdown/listItem-view';
import { ReactComponent as Checked } from 'assets/icons/systemicons/statusIndicators/isSelected.svg';
import { ReactComponent as Delete } from 'assets/icons/systemicons/delete.svg';
import { ReactComponent as PinOff } from 'assets/icons/systemicons/pin_off.svg';

const commonIconStyle = ({ theme }) => css`
  min-width: 24px;
  min-height: 24px;
  path {
    fill: ${theme.palette.dina.highEmphasis};
    fill-opacity: 0.54;
  }
  :hover {
    path {
      fill: ${theme.palette.dina.highEmphasis};
      fill-opacity: 1;
    }
  }
`;

export const Wrapper = styled('div')`
  width: 320px;
`;

export const ScrollWrapper = styled('div')`
  min-height: 0px;
  max-height: 700px;
  overflow-x: hidden;
`;

export const Header = styled('div')`
  ${({ theme }) => theme.typography.dina.overline};
  height: 32px;
  margin-left: 16px;
  display: flex;
  align-items: center;
`;

export const CheckedIcon = styled(Checked)`
  ${commonIconStyle};
`;

export const PinOffIconHovered = styled(PinOff)`
  ${commonIconStyle};
`;

export const LeadingIconWrapper = styled('div')`
  margin-right: 8px;
`;

export const PinIconWrapper = styled('div')`
  width: 24px;
  height: 24px;
`;

export const DeleteIcon = styled(Delete)`
  margin-right: 8px;
  :hover {
    path {
      fill: ${({ theme }) => theme.palette.dina.highEmphasis};
      fill-opacity: 1;
    }
  }
`;
export const HoverVisibilityWrapper = styled('span')`
  opacity: 0;
  display: flex;
  align-items: center;
  transition: opacity 0.3s ease-in-out;
`;

export const ListItem = styled(ListItemComponent)`
  :hover {
    ${HoverVisibilityWrapper} {
      opacity: 1;
    }
  }
`;
