import { Menu as CMenu } from 'react-contexify';
import styled from '@emotion/styled';

export const Menu = styled(CMenu)`
  background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel2};
  min-width: 250px !important;
  pointer-events: auto;
  border: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  padding: 4px 0;
  .contexify {
    background: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel2};
    border: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
    min-width: 250px !important;
    padding: 4px 0;
  }
  .contexify_itemContent {
    ${({ theme }) => theme.typography.dina.listItemLabel};
    color: ${({ theme }) => theme.palette.dina.highEmphasis} !important;
    height: 32px;
    border-radius: 0 !important;
    :hover {
      background-color: ${({ theme }) => theme.palette.dina.hoverOverlay} !important;
    }
    :after {
      border-bottom: 3px solid orange !important;
    }
  }
  .contexify_separator {
    background: ${({ theme }) => theme.palette.dina.dividerLight} !important;
  }
`;
