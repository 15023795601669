import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { BoxProps } from './Box';

export const StyledBox = styled('div')<BoxProps>`
  // Override default
  display: ${({ container }) => (container ? 'flex' : 'block')};
  justify-content: ${({ justifyContent }) => justifyContent ?? 'center'};
  align-items: ${({ alignItems }) => alignItems ?? 'center'};
  align-self: ${({ alignSelf }) => alignSelf ?? 'auto'};
  overflow: hidden;

  // Flex
  ${({ flex }) =>
    flex &&
    css`
      flex: ${flex};
    `};
  ${({ flexDirection }) =>
    flexDirection &&
    css`
      flex-direction: ${flexDirection};
    `};
  ${({ flexWrap }) =>
    flexWrap &&
    css`
      flex-wrap: ${flexWrap};
    `};
  ${({ flexBasis }) =>
    flexBasis &&
    css`
      flex-basis: ${flexBasis};
    `};
  ${({ flexGrow }) =>
    typeof flexGrow === 'number' &&
    css`
      flex-grow: ${flexGrow};
    `};
  ${({ flexShrink }) =>
    typeof flexShrink === 'number' &&
    css`
      flex-shrink: ${flexShrink};
    `};
  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap};
    `};

  // Size
  ${({ height }) =>
    height &&
    css`
      height: ${height};
    `};
  ${({ maxHeight }) =>
    maxHeight &&
    css`
      max-height: ${maxHeight};
    `};
  ${({ minHeight }) =>
    minHeight &&
    css`
      min-height: ${minHeight};
    `};
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `};
  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `};
  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${minWidth};
    `};

  // Spacing
  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `};
  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `};

  // Other
  ${({ cursor }) =>
    cursor &&
    css`
      cursor: ${cursor};
    `};

  ${({ overflow }) =>
    overflow &&
    css`
      overflow: ${overflow};
    `};

  ${({ borderRadius }) =>
    borderRadius &&
    css`
      border-radius: ${borderRadius};
    `};
  ${({ position }) =>
    position &&
    css`
      position: ${position};
    `};
  ${({ opacity }) =>
    opacity &&
    css`
      opacity: ${opacity};
    `}
  ${({ border }) =>
    border &&
    css`
      border: ${border};
    `};
  ${({ background }) =>
    background &&
    css`
      background: ${background};
    `};
`;
