import { useCallback, useEffect, useRef } from 'react';

type CallbackFunction<T> = (...args: T[]) => Promise<void> | void;

const useDebouncedCallback = <T>(
  callBack: CallbackFunction<T>,
  delay: number = 1000,
): [CallbackFunction<T>, () => void] => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isSubscribedRef = useRef<boolean>(true);

  const cancelCallback = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }, []);

  const debouncedCallback: CallbackFunction<T> = useCallback(
    (...args: T[]) => {
      cancelCallback();

      timeoutRef.current = setTimeout(() => {
        if (isSubscribedRef.current) {
          void callBack(...args);
          timeoutRef.current = null;
        }
      }, delay);
    },
    [callBack, delay],
  );

  useEffect(() => {
    return () => {
      isSubscribedRef.current = false;
      cancelCallback();
    };
  }, [cancelCallback]);

  return [debouncedCallback, cancelCallback];
};

export default useDebouncedCallback;
