import gql from 'graphql-tag';

export default gql`
  mutation ArchiveMember($input: ArchiveStoryInput) {
    archiveMember(input: $input) {
      mId
      mRefId
      mUpdatedById
      rundownId
    }
  }
`;
