import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const SkipTour = styled('span')`
  ${({ theme }) => css`
    ${theme.typography.dina.button}
    color: ${theme.palette.dina.mediumEmphasis};
  `}
  margin: auto;
  cursor: pointer;
  letter-spacing: 0px;
  text-align: center;
`;

export const CountText = styled('div')`
  ${({ theme }) => css`
    ${theme.typography.dina.durationLabel}
    color: ${theme.palette.dina.mediumEmphasis};
  `}
  width: 130px;
  padding: 14px 5px 0 5px;
  letter-spacing: 0.25px;
  text-align: center;
`;

export const TourButtonWrapper = styled('div')`
  display: flex;
  width: 220px;
  margin-left: auto;
`;

export const Bottom = styled('div')`
  border-top: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  padding: 8px;
  box-sizing: border-box;
  height: 58px;
  display: flex;
  flex-direction: row;
`;
