import gql from 'graphql-tag';

export default gql`
  query GetSession($input: GetMemberInput) {
    getMember(input: $input) {
      mId
      mRefId
      mTitle
      mType
      mState
      mProperties {
        __typename
        ... on SessionType {
          sessionExpirationTime
          refreshTokenExpirationTime
          idlePeriod1ExpirationTime
          idlePeriod2ExpirationTime
        }
      }
    }
  }
`;
