import styled from '@emotion/styled';
export const RootWrapper = styled('div')`
  width: 100%;
  height: 100%;
  display: flex-column;
`;

export const Warning = styled('div')`
  ${({ theme }) => theme.typography.dina.listItemLabel};
  padding: 10px;
  width: 100%;
  height: 50px;
  background-color: ${({ theme }) => theme.palette.dina.statusWarning};
  color: white;
`;
