import { useState, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tweet as ReactTweet } from 'react-tweet';
import { PreviewWrapper } from './styled';
import LoadingIndicator from 'components/loadingIndicator';

const Preview = <PreviewWrapper>Preview</PreviewWrapper>;

const Tweet = ({ tweetId }) => {
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    setShowPreview(false);
  }, [tweetId]);

  if (!tweetId || showPreview) return Preview;

  return <ReactTweet fallback={<LoadingIndicator />} id={tweetId} />;
};

Tweet.propTypes = {
  /** id of the tweet */
  tweetId: PropTypes.string,
};
Tweet.defaultProps = {
  tweetId: null,
};

export default memo(Tweet);
