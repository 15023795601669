import styled from '@emotion/styled';

const ROW_HEIGHT = '36px';

export const StyledTable = styled('table')`
  display: grid;
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};

  thead {
    background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
    ${({ theme }) => theme.typography.dina.overline};
    display: grid;
    position: sticky;
    top: 0px;
    z-index: 1;
    tr {
      display: flex;
      position: sticky;
      top: 0;
      border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
      th {
        border-right: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
        padding-inline: 8px;
        user-select: none;
        height: ${ROW_HEIGHT};
        display: flex;
        align-items: center;
        position: relative;
        overflow: hidden;
        :focus-within {
          outline: none;
          background: ${({ theme }) => theme.palette.dina.hoverOverlay};
          outline: 1px dashed ${({ theme }) => theme.palette.dina.highEmphasis};
        }
      }
    }
  }

  tbody {
    display: grid;
    position: relative;
    tr {
      box-sizing: border-box;
      display: flex;
      position: absolute;
      width: 100%;
      border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
      :nth-of-type(even) {
        background-color: ${({ theme }) => theme.palette.dina.tableAlternatingRowOverlay};
      }
      td {
        display: flex;
        overflow: hidden;
        justify-content: flex-start;
        align-items: center;
        height: ${ROW_HEIGHT};
        border-right: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
        padding-inline: 8px;
        :hover {
          background-color: ${({ theme }) => theme.palette.dina.hoverOverlay};
        }
        :empty {
          cursor: not-allowed;
        }
        :focus-within {
          outline-offset: -1px;
          outline: 1px dashed ${({ theme }) => theme.palette.dina.highEmphasis};
        }
        :has(:focus-within) {
          outline: 2px solid ${({ theme }) => theme.palette.dina.statusOnFocused};
          background: ${({ theme }) => theme.palette.dina.statusOnFocusedDiscreetFill};
        }
    }
    .dnd-table-row {
      :nth-of-type(even) {
        background-color: ${({ theme }) => theme.palette.dina.tableAlternatingRowOverlay};
      }
    }
  }
`;

export const SortHandle = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  gap: 4px;
  max-height: 14px;
  overflow: hidden;
  white-space: nowrap;
`;

export const Resizer = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  right: -1.5px;
  width: 5px;
  border-radius: 2px;
  cursor: col-resize;
  user-select: none;
  touch-action: none;
  :hover {
    background: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
  &.isResizing {
    background: ${({ theme }) => theme.palette.dina.onFocus};
    opacity: 1;
  }
`;
