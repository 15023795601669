import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';

import { BATCH_GET_MEMBERS } from './graphql';

const useBatchGetMembers = () => {
  const client = useApolloClient();

  const loadItems = useCallback(
    async (ids = []) => {
      const result = await client.query({
        query: BATCH_GET_MEMBERS,
        variables: {
          input: { mIds: ids },
        },
        fetchPolicy: 'network-only',
      });

      return result?.data?.batchGetMembers;
    },
    [client],
  );

  return [loadItems];
};

export default useBatchGetMembers;
