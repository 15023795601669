import { atom } from 'jotai';
import { planningStateTypes, planningViews, timeVariants, sortVariants } from 'utils/planningViews';
import { useReducerAtom } from 'jotai/utils';

const PLANS_STORAGE_KEY = 'plans';

const DEFAULT_STATE = {
  [planningStateTypes.VIEW]: planningViews.STATUS,
  [planningStateTypes.TIME]: new Date().toISOString(),
  [planningStateTypes.TAB_INDEX]: 0,
  [planningStateTypes.TIME_VARIANT]: timeVariants.DAY,
  [planningStateTypes.SAVED_FILTERS]: [],
  [planningStateTypes.STATE_COLLAPSED]: {},
  [planningStateTypes.SHOW_SCHEDULED]: true,
  [planningStateTypes.STATES_VIEW]: 'status-normal',
  [planningStateTypes.CURRENT_FILTER]: {},
  [planningStateTypes.SHOW_STORY]: true,
  [planningStateTypes.SHOW_PITCH]: true,
  [planningStateTypes.SORT_CRITERIA]: sortVariants.PUBLISHING_DATE,
};

const planningReducer = (state, action) => {
  switch (action.type) {
    case planningStateTypes.VIEW:
      return { ...state, [planningStateTypes.VIEW]: action.value };
    case planningStateTypes.TIME:
      return { ...state, [planningStateTypes.TIME]: action.value };
    case planningStateTypes.TAB_INDEX:
      return { ...state, [planningStateTypes.TAB_INDEX]: action.value };
    case planningStateTypes.TIME_VARIANT:
      return { ...state, [planningStateTypes.TIME_VARIANT]: action.value };
    case planningStateTypes.SAVED_FILTERS:
      return { ...state, [planningStateTypes.SAVED_FILTERS]: action.value };
    case planningStateTypes.STATE_COLLAPSED:
      return { ...state, [planningStateTypes.STATE_COLLAPSED]: action.value };
    case planningStateTypes.SHOW_SCHEDULED:
      return { ...state, [planningStateTypes.SHOW_SCHEDULED]: action.value };
    case planningStateTypes.STATES_VIEW:
      return { ...state, [planningStateTypes.STATES_VIEW]: action.value };
    case planningStateTypes.CURRENT_FILTER:
      return { ...state, [planningStateTypes.CURRENT_FILTER]: action.value };
    case planningStateTypes.SHOW_STORY:
      return { ...state, [planningStateTypes.SHOW_STORY]: action.value };
    case planningStateTypes.SHOW_PITCH:
      return { ...state, [planningStateTypes.SHOW_PITCH]: action.value };
    case planningStateTypes.SORT_CRITERIA:
      return { ...state, [planningStateTypes.SORT_CRITERIA]: action.value };
    default:
      return state;
  }
};

const SAVED_STATE = JSON.parse(window.localStorage.getItem(PLANS_STORAGE_KEY)) ?? {};

const planningStorageAtom = atom({ ...DEFAULT_STATE, ...SAVED_STATE });

const planningAtom = atom(
  (get) => get(planningStorageAtom),
  (get, set, updateFunc) => {
    const currentState = get(planningAtom);
    const updatedState = updateFunc(currentState);
    set(planningStorageAtom, updatedState);
    localStorage.setItem(PLANS_STORAGE_KEY, JSON.stringify(updatedState));
  },
);

// eslint-disable-next-line import/prefer-default-export
export const usePlanning = () => useReducerAtom(planningAtom, planningReducer);
