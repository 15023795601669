/** key pressed hook */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const useMetaKeyPress = (targetMetaKey) => {
  const [metaKey, setMetaKey] = useState(false);

  const downHandler = (e) => {
    if (!targetMetaKey) return;
    if (e[`${targetMetaKey}Key`] && !metaKey) {
      setMetaKey(true);
    }
  };

  const upHandler = (e) => {
    if (!e[`${targetMetaKey}Key`] && metaKey) {
      setMetaKey(false);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  });

  return metaKey;
};

useMetaKeyPress.propTypes = {
  /** whether can create story */
  targetMetaKey: PropTypes.oneOf(['ctrl', 'alt', 'shift']),
};

useMetaKeyPress.defaultProps = {
  targetMetaKey: undefined,
};

export default useMetaKeyPress;
