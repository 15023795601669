import { useMemo } from 'react';

import { CommandGroup } from 'lib/command';
import { Mdf } from 'types/graphqlTypes';

import { CommandItem } from '../CommandItem';

interface MetadataGroupProps {
  mdfs: Mdf[];
  goToMdf: (val: Mdf) => void;
  inputValue: string;
}

const MetadataGroup = ({ mdfs, goToMdf, inputValue }: MetadataGroupProps) => {
  const MdfItems = useMemo(() => {
    return mdfs.map((mdf) => (
      <CommandItem
        onSelect={() => goToMdf(mdf)}
        key={mdf.id}
        type="metadata"
        forceMount={`${mdf.label.toLowerCase()} metadata`.includes(inputValue)}
      >
        {mdf.label}
      </CommandItem>
    ));
  }, [mdfs, goToMdf, inputValue]);
  return <CommandGroup heading="Metadata">{MdfItems}</CommandGroup>;
};

export default MetadataGroup;
