import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';
import { Divider as MaterialDivider, Typography } from '@material-ui/core';

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  position: static;
  margin: 0px;
  padding: 0px;
  top: 1px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  flex: none;
  order: 0;
  background: ${({ theme }) => theme.palette.dina.surfaceCardDark};
`;

export const Header = styled('div')`
  position: absolute;
  top: 0px;
  left: 0px;
  height: 40px;
  width: 100%;
  flex: none;
  order: 0;
  align-self: flex-start;
  user-select: none;
`;

export const DateLabel = styled(Typography)`
  ${({ theme }) => css`
    ${theme.typography.dina.subtitle1};
  `}
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
  position: absolute;
  left: 16px;
  right: 8px;
  top: calc(50% - 18px / 2);
  bottom: 0px;
`;

export const Divider = styled(MaterialDivider)`
  position: absolute;
  top: 38px;
  left: 0px;
  right: 0px;
  height: 1px;
  background: ${({ theme }) => theme.palette.dina.dividerLight};
`;

export const ContentWrapper = styled('div')`
  position: absolute;
  top: 41px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  height: 95%;
`;

export const EditorContent = styled('div')`
  height: 100%;
  width: 100%;
`;

export const Fallback = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
`;
