import variants from 'utils/instance/variants';

/**
 * Checks whether drag is disabled for block
 *
 * @param {String} variant of the instance
 * @returns {Bool} whether drag is disabled or not
 */

const validVariants = [variants.CMS];

const checkIfDragDisabled = (variant) => !validVariants.includes(variant);

export default checkIfDragDisabled;
