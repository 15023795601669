import styled from '@emotion/styled/macro';

type HeaderProps = {
  isStory?: boolean;
  isPitch?: boolean;
};

export const Header = styled('header')<HeaderProps>`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  padding-inline: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.outlineButtonOnHover};
  background-color: ${({ theme, isStory, isPitch }) => {
    if (isPitch) return theme.palette.dina.statusPitch;
    if (isStory) return theme.palette.dina.statusStory;
    return theme.palette.dina.surfaceAppBackgroundNavLevel1;
  }};
`;

type IconProps = {
  type?: string;
};

export const Icon = styled('div')<IconProps>`
  min-width: 24px;
  min-height: 24px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, type }) => {
    if (type === 'pitch') return theme.palette.dina.statusPitch;
    return theme.palette.dina.watermark;
  }};
  svg {
    width: 16px;
    height: 16px;
    path {
      fill: ${({ theme }) => theme.palette.dina.whiteHighEmphasis};
      fill-opacity: 1;
    }
  }
`;

export const TitleInput = styled('input')`
  ${({ theme }) => theme.typography.dina.h6};
  background: transparent;
  border: none;
  outline: none;
  border: 1px solid transparent;
  border-radius: 6px;
  height: 36px;
  width: 100%;
  :hover {
    filter: brightness(1.1);
    background-color: ${({ theme }) => theme.palette.dina.outlineButtonOnHover};
    border-color: ${({ theme }) => theme.palette.dina.outlineButtonOnHover};
  }
  :focus-within {
    border: 1px solid ${({ theme }) => theme.palette.dina.statusOnFocused};
    background: ${({ theme }) => theme.palette.dina.statusOnFocusedDiscreetFill};
  }
`;
