/* eslint-disable import/prefer-default-export */
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import MuiModal from '@material-ui/core/Modal';

export const Modal = styled(MuiModal)`
  display: flex;
  align-items: center;
  justify-content: center;
  :focus {
    outline: none;
  }
`;

export const ModalContent = styled('div')`
  :focus {
    outline: none;
  }
`;

export const Content = styled('div')`
  ${({ theme }) => css`
    background: ${theme.palette.dina.highEmphasis};
    border-bottom: 1px solid ${theme.palette.dina.dividerLight};
  `}
  width: 600px;
  height: 70vh;
  border-radius: 8px;
`;
