import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import styled from '@emotion/styled';
import mosIcon from 'assets/icons/systemicons/rundown/mos.svg';
import { RundownIconsSmall, RundownIcons } from './icon-directory';
import useStyles from './rundownIcon-styles';

const Image = styled('img')`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: ${({ borderRadius }) => borderRadius};
  ::before {
    width: 100px;
  }
  ::after {
    width: 100px;
  }
`;

export const iconUsage = {
  DEFAULT: 'default',
  META: 'meta',
  EDITOR: 'editor',
};

const RundownIcon = ({
  variant,
  height,
  width,
  borderRadius,
  label,
  state,
  usage,
  isNormalSize,
  noBackground,
  iconUrl,
  type,
}) => {
  const classes = useStyles(width, height, borderRadius)();
  if (iconUrl)
    return (
      <Image
        src={iconUrl}
        alt=""
        width={width}
        height={height}
        borderRadius={borderRadius}
        title={variant}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = mosIcon;
        }}
      />
    );
  const svgClass = classes[usage];
  const isAltIcon = (variant === 'live' || variant === 'dve') && type === 'grid';

  const RenderedIcon = !isNormalSize
    ? RundownIconsSmall[isAltIcon ? 'liveAlt' : variant]
    : RundownIcons[variant];

  const iconContainer = noBackground ? classes.noBackground : classes[variant];

  return (
    <div className={state === 'error' ? classes.errorDiv : classes.mainDiv}>
      {state === 'error' && <div className={classes.errorStateDiv} />}
      <div
        className={iconContainer}
        style={{
          width,
          height,
          opacity: state === 'error' ? 0.8 : 1,
        }}
      />
      {type === 'grid' && (variant === 'live' || variant === 'camera' || variant === 'dve') ? (
        <div className={classes.gridContainer}>
          <Typography classes={{ root: classes.labelRoot }}>
            {label === null ? '' : label.substring(0, 4)}
          </Typography>
          <RenderedIcon
            className={`${isAltIcon ? classes.altSvgClass : classes.gridSvgClass} skipOverride`}
          />
        </div>
      ) : (
        <RenderedIcon className={`${svgClass} skipOverride`} />
      )}
    </div>
  );
};

RundownIcon.propTypes = {
  /** icon variant according to item types */
  variant: PropTypes.string.isRequired,
  /** height of the icon background */
  height: PropTypes.number,
  /** width of the icon background */
  width: PropTypes.number,
  /** whether icon will have border radius or not */
  borderRadius: PropTypes.string,
  /** defines the usage of svg icon  */
  usage: PropTypes.oneOf(Object.values(iconUsage)),
  /** state of the icon: error or normal */
  state: PropTypes.string,
  /** label of the icon if exist */
  label: PropTypes.string,
  /** defines whether the icons are normal size or not */
  isNormalSize: PropTypes.bool,
  /** type of the icon: default or grid */
  type: PropTypes.string,
  /** defines whether the icon will have background or not */
  noBackground: PropTypes.bool,
};

RundownIcon.defaultProps = {
  height: 24,
  width: 24,
  borderRadius: '4px',
  label: 'CAM',
  state: 'normal',
  usage: iconUsage.DEFAULT,
  type: 'default',
  isNormalSize: false,
  noBackground: false,
};
export default RundownIcon;
