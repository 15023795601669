const variants = {
  LINEAR: 'linear',
  TWITTER: 'twitter',
  YOUTUBE: 'youtube',
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
  CMS: 'cms',
  GENERAL: 'general',
  NOTES: 'notes',
  TIKTOK: 'tiktok',
  LINKEDIN: 'linkedin',
} as const;

export default variants;
