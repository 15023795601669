import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import debounce from 'lodash/debounce';

import updateMember from 'operations/mutations/updateMember';
import { UpdateMemberInput } from 'types/graphqlTypes';

type UpdateMemberInputType = {
  input: UpdateMemberInput;
};

const useStoryDescription = (
  storyId: string,
  mDescription: string | null = '',
  canUpdate: boolean = false,
) => {
  const [description, setDescription] = useState<string | null>(mDescription);

  const [updateStory] = useMutation<UpdateMemberInputType>(updateMember);

  const debouncedDescriptionChange = debounce(async (newDescription: string) => {
    if (!canUpdate) return;

    const variables = {
      input: {
        mId: storyId,
        mDescription: newDescription,
      },
    };

    await updateStory({
      variables,
    });
  }, 2000);

  useEffect(() => {
    setDescription(mDescription);
  }, [mDescription]);

  return { description, debouncedDescriptionChange };
};

export default useStoryDescription;
