import styled from '@emotion/styled';

export const Wrapper = styled('div')`
  background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel1};
  height: 100%;
`;

export const ButtonWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

export const ErrorMessage = styled('span')`
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
`;

export const LoadingSpinner = styled('img')`
  height: 20px;
  width: 20px;
  margin-right: 8px;
`;
