import { User } from 'types';
import { Conversation } from 'types/messageHub';
import conversationTypes from 'utils/constants/conversationTypes';

import AvatarCell from '../AvatarCell';

import getUsersConversationData from './getUsersConversationData';

const assemblePeopleData = (users: User[], userId: string, conversations: Conversation[] = []) =>
  users
    .filter(({ mId }) => userId !== mId)
    .map(({ mId, mTitle, mAvatarKey }) => {
      const userData = {
        rowId: mId,
        mId,
        name: AvatarCell({ mTitle, mAvatarKey }),
        mTitle,
        mAvatarKey,
        convoType: conversationTypes.DIRECT,
        type: 'Person',
      };
      const conversationData = getUsersConversationData(conversations, mId);
      return { ...userData, ...conversationData };
    });
// .sort((a, b) => a.mTitle.toLowerCase() - b.mTitle.toLowerCase());

export default assemblePeopleData;
