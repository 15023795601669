import { useMutation } from '@apollo/client';
import CREATE_CONTENT_TEMPLATE from 'operations/mutations/createContentTemplate';
import GET_FOLDERS from 'operations/queries/getFolders';
import useLogger from 'utils/useLogger';

const useCreateTemplate = () => {
  const logger = useLogger('use create template');
  const [createTemplateMutation] = useMutation(CREATE_CONTENT_TEMPLATE);

  const create = async (mId, mTitle, mDescription, mProperties) => {
    const input = {
      mId,
      mTitle,
      mDescription,
    };
    if (mProperties) input.mProperties = mProperties;

    const result = await createTemplateMutation({
      variables: {
        input,
      },
      update: (proxy, mutationResult) => {
        const { createContentTemplate: createdTemplate } = mutationResult.data;
        try {
          const folderList = proxy.readQuery({
            query: GET_FOLDERS,
          });

          const newFolderList = folderList.getFolders.map((f) => {
            const folder = {
              ...f,
              items: f.items ? [...f.items] : [],
              mOrder: f.mOrder ? [...f.mOrder] : [],
            };
            if (folder.mRefId !== mId) return folder;

            if (!folder.items.find((item) => item.mRefId === createdTemplate.mRefId))
              folder.items.push(createdTemplate);
            if (!folder.mOrder.find((item) => item === createdTemplate.mRefId))
              folder.mOrder.push(createdTemplate.mRefId);

            return folder;
          });

          proxy.writeQuery({
            query: GET_FOLDERS,
            data: { getFolders: newFolderList },
          });
        } catch (err) {
          logger.error(err);
        }
      },
    });
    return result;
  };

  return [create];
};

export default useCreateTemplate;
