const convertToTitleCase = (lowerCaseTitle) =>
  `${lowerCaseTitle.charAt(0).toUpperCase()}${lowerCaseTitle.slice(1)}`;

const getSoMeBrandName = (mTitle) => {
  if (typeof mTitle !== 'string') return mTitle;
  const lowerCaseTitle = mTitle.toLowerCase();
  switch (lowerCaseTitle) {
    case 'cms':
      return 'CMS';
    case 'tiktok':
      return 'TikTok';
    case 'youtube':
      return 'YouTube';
    case 'linkedin':
      return 'LinkedIn';
    case 'instagram':
    case 'facebook':
    case 'twitter':
    default:
      return convertToTitleCase(lowerCaseTitle);
  }
};

export default getSoMeBrandName;
