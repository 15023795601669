import gql from 'graphql-tag';

export default gql`
  mutation UpdateMessage($input: CreateMessageInput) {
    updateMessage(input: $input) {
      mId
      mRefId
      mType
      mTitle
      mContent
      mCreatedById
      mCreatedAt
      mUpdatedAt
      mUpdatedById
      convoType
      crudAction
      contentIds
    }
  }
`;
