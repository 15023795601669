import { elementTypes } from 'components/editor/constants';
import { ReactComponent as VideoIcon } from 'assets/icons/systemicons/editor/video_small.svg';
import { ReactComponent as ImageIcon } from 'assets/icons/systemicons/editor/photo_small.svg';
import { ReactComponent as GifIcon } from 'assets/icons/systemicons/editor/gif_small.svg';
import { ReactComponent as PhotoGallery } from 'assets/icons/systemicons/editor/blocks_PhotoCarousel_on.svg';
import { ReactComponent as QuoteIcon } from 'assets/icons/systemicons/editor/blocks_plain_quote_off.svg';
import { ReactComponent as FactBoxIcon } from 'assets/icons/systemicons/editor/blocks_FactBox_off.svg';
import { ReactComponent as H1Icon } from 'assets/icons/systemicons/editor/h1_off.svg';
import { ReactComponent as SectionIcon } from 'assets/icons/systemicons/editor/section.svg';
import { ReactComponent as ContentIcon } from 'assets/icons/systemicons/editor/contents.svg';
import { ReactComponent as DescriptionIcon } from 'assets/icons/systemicons/editor/description.svg';
import { ReactComponent as AudioIcon } from 'assets/icons/systemicons/editor/audio_on.svg';
import { ReactComponent as OverlineIcon } from 'assets/icons/systemicons/editor/trumpet.svg';
import { ReactComponent as EmbeddedTweetIcon } from 'assets/icons/systemicons/editor/embeddedTweet.svg';
import { ReactComponent as CodeIcon } from 'assets/icons/systemicons/editor/code_off.svg';
import { ReactComponent as EmbeddedVideoIcon } from 'assets/icons/systemicons/editor/blocks_EmbeddedVideo_off.svg';
import { ReactComponent as TagIcon } from 'assets/icons/systemicons/editor/hashtags.svg';
import { ReactComponent as ProgramsIcon } from 'assets/icons/systemicons/editor/programs.svg';
import { ReactComponent as AuthorIcon } from 'assets/icons/systemicons/user.svg';
import { ReactComponent as SourceIcon } from 'assets/icons/systemicons/FeedSource.svg';
import { ReactComponent as LiveStreamIcon } from 'assets/icons/systemicons/editor/live_stream.svg';
import { ReactComponent as NotificationIcon } from 'assets/icons/systemicons/editor/PushMsgGeneric.svg';
import { ReactComponent as LanguageIcon } from 'assets/icons/systemicons/editor/translate.svg';
import { ReactComponent as UrlIcon } from 'assets/icons/systemicons/link_off.svg';
import { ReactComponent as SectionDivider } from 'assets/icons/systemicons/is_grouped_true.svg';

const {
  SECTION,
  TITLE,
  QUOTE_BOX,
  VIDEO,
  IMAGE,
  GIF,
  FACT,
  EMBEDDED_TWEET,
  HTML,
  EMBEDDED_VIDEO,
  CONTENT,
  ABSTRACT,
  OVERLINE,
  AUDIO,
  TAGS,
  AUTHOR,
  SOURCE,
  LIVE_STREAM,
  NOTIFICATION,
  PHOTO_GALLERY,
  LANGUAGE,
  URL,
  SECTION_DIVIDER,
  PROGRAMS,
} = elementTypes;

const DEFAULT = 'default';

const iconComponents = {
  [IMAGE]: ImageIcon,
  [VIDEO]: VideoIcon,
  [GIF]: GifIcon,
  [PHOTO_GALLERY]: PhotoGallery,
  [TITLE]: H1Icon,
  [OVERLINE]: OverlineIcon,
  [AUDIO]: AudioIcon,
  [ABSTRACT]: DescriptionIcon,
  [CONTENT]: ContentIcon,
  [SECTION]: SectionIcon,
  [QUOTE_BOX]: QuoteIcon,
  [FACT]: FactBoxIcon,
  [EMBEDDED_TWEET]: EmbeddedTweetIcon,
  [EMBEDDED_VIDEO]: EmbeddedVideoIcon,
  [HTML]: CodeIcon,
  [TAGS]: TagIcon,
  [AUTHOR]: AuthorIcon,
  [SOURCE]: SourceIcon,
  [LIVE_STREAM]: LiveStreamIcon,
  [NOTIFICATION]: NotificationIcon,
  [LANGUAGE]: LanguageIcon,
  [URL]: UrlIcon,
  [SECTION_DIVIDER]: SectionDivider,
  [PROGRAMS]: ProgramsIcon,
  [DEFAULT]: H1Icon,
};

export default iconComponents;
