/* eslint-disable react/no-array-index-key */
import React, { memo, useState, useMemo, useCallback, useEffect } from 'react';
import { useSlate, useReadOnly } from 'slate-react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { elementTypes } from 'components/editor/constants/types';
import { checkIfDragDisabled } from 'components/editor/utils';
import Select from 'components/select';
import { useEditorContext } from 'components/editor/EditorContext';
import { ReactComponent as LanguageIconComponent } from 'assets/icons/systemicons/editor/translate.svg';
import updateBlock from 'components/editor/utils/updateBlock';
import useChangeCollapse from 'components/editor/hooks/useChangeCollapse';
import Box from '../box';
import DragAndDrop from '../dragAndDrop';

const initialLanguageBlockValue = { value: '', title: '' };

const MemoizedSelect = memo(Select);

export const LanguageIcon = styled(LanguageIconComponent)`
  margin: 8px;
`;

export const ContentWrapper = styled('div')`
  margin-bottom: 16px;
`;

const LanguageBlock = ({ attributes, children, element }) => {
  const { data } = element;
  const editor = useSlate();
  const readOnly = useReadOnly();
  const { update, variant } = useEditorContext();

  const { content, collapsed = false } = data;

  const [onChangeCollapse] = useChangeCollapse(element);

  const languageOptions = [
    { value: 'en', title: 'English' },
    { value: 'ar', title: 'Arabic' },
  ];

  const [language, setLanguage] = useState(content || initialLanguageBlockValue);

  const updateLanguage = useCallback(
    (updatedData) => {
      updateBlock(editor, element, updatedData, update, false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element, update],
  );

  const handleChange = useCallback(
    (newValue) => {
      const selectedItem =
        languageOptions.find((item) => item.value === newValue) || initialLanguageBlockValue;

      const updatedData = {
        ...data,
        content: selectedItem,
      };
      updateLanguage(updatedData);
      setLanguage(selectedItem);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, updateLanguage],
  );

  const memoizedLanguageBlock = useMemo(
    () => {
      const collapsedContent = content.title;

      return (
        <Box
          iconComponent={<LanguageIcon className="skipOverride" />}
          title="Language"
          readOnly={readOnly}
          hideEllipsisButton
          collapsed={collapsed}
          collapsedContent={collapsedContent}
          updateCollapsed={onChangeCollapse}
        >
          <ContentWrapper>
            <MemoizedSelect
              items={languageOptions}
              onChange={handleChange}
              selectedValue={language.value}
              hideLabel
            />
          </ContentWrapper>
        </Box>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [readOnly, collapsed, onChangeCollapse, language, content, handleChange, languageOptions],
  );

  useEffect(() => {
    setLanguage(content || initialLanguageBlockValue);
  }, [data, content, initialLanguageBlockValue]);

  return (
    <div {...attributes}>
      <DragAndDrop element={element} isDragDisabled={checkIfDragDisabled(variant)}>
        {children}
        {memoizedLanguageBlock}
      </DragAndDrop>
    </div>
  );
};

LanguageBlock.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

LanguageBlock.defaultProps = {
  attributes: {},
  children: null,
  element: {
    children: [],
    data: { content: initialLanguageBlockValue },
    type: elementTypes.LANGUAGE,
  },
};

export default memo(LanguageBlock);
