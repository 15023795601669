import * as CollapsiblePrimitive from '@radix-ui/react-collapsible';

/**
 * An interactive component which expands/collapses a panel.
 *
 * Can be controlled or uncontrolled.
 * Supports keyboard navigation.
 * @see Docs {@link https://radix-ui.com/primitives/docs/components/collapsible}
 *
 */
const Collapsible = CollapsiblePrimitive.Root;
const CollapsibleTrigger = CollapsiblePrimitive.CollapsibleTrigger;
const CollapsibleContent = CollapsiblePrimitive.CollapsibleContent;

export { Collapsible, CollapsibleTrigger, CollapsibleContent };
