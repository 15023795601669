import React, { useState, useRef, useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ArrowDoubleUnboxedIcon } from 'assets/icons/systemicons/arrows/arrow_double_unboxed.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/systemicons/check.svg';
import Input from 'components/editor/components/inputBase';
import stopAllPropagation from 'components/editor/utils/stopAllPropagation';
import {
  RootWrapper,
  ArrowIconWrapper,
  SelectWrapper,
  Select,
  InputWrapper,
  MenuItemIcon,
  MenuItem,
  useStyles,
} from './styled';

const renderSelectIcon = () => null;

const TimingSelect = ({
  items,
  showInput,
  selectValue,
  inputValue,
  handleInputChange,
  handleTypeChange,
}) => {
  const containerRef = useRef(null);
  const iconRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();

  const openSelectMenu = useCallback(() => setIsOpen(true), []);
  const closeSelectMenu = useCallback(() => setIsOpen(false), []);

  const onKeyDown = useCallback(
    (event) => {
      stopAllPropagation(event);

      const { key } = event;

      if ((key === 'ArrowUp' || key === 'ArrowDown') && !isOpen) openSelectMenu();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const selectOptions = useMemo(
    () =>
      items.map((item) => (
        <MenuItem value={item.type} key={item.type}>
          <MenuItemIcon>
            <CheckIcon />
          </MenuItemIcon>
          {item.title}
        </MenuItem>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onOpen = useCallback(() => iconRef.current.click(), []);

  return (
    <RootWrapper onClick={stopAllPropagation} ref={containerRef} role="presentation">
      <ArrowIconWrapper role="presentation" onClick={openSelectMenu} ref={iconRef}>
        <ArrowDoubleUnboxedIcon />
      </ArrowIconWrapper>

      <SelectWrapper role="presentation" onKeyDown={onKeyDown}>
        {showInput && (
          <InputWrapper>
            <Input type="input" value={inputValue} onUpdate={handleInputChange} />
          </InputWrapper>
        )}

        <Select
          variant="standard"
          disableUnderline
          fullWidth
          open={isOpen}
          onClose={closeSelectMenu}
          IconComponent={renderSelectIcon}
          value={selectValue}
          showInput={showInput}
          onChange={handleTypeChange}
          MenuProps={{
            classes: {
              paper: classes.menu,
              list: classes.menuList,
            },
            anchorEl: containerRef.current,
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'center',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'center',
              horizontal: 'left',
            },
            PaperProps: {
              onMouseLeave: closeSelectMenu,
            },
          }}
          onOpen={onOpen}
        >
          {selectOptions}
        </Select>
      </SelectWrapper>
    </RootWrapper>
  );
};

TimingSelect.propTypes = {
  /** list of option for timing select */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  /** whether to show input or not */
  showInput: PropTypes.bool,
  /** value for the select component */
  selectValue: PropTypes.string,
  /** value for the input component */
  inputValue: PropTypes.string,
  /** callback for input change */
  handleInputChange: PropTypes.func,
  /** callback for type change */
  handleTypeChange: PropTypes.func,
};

TimingSelect.defaultProps = {
  items: [],
  showInput: false,
  selectValue: '',
  inputValue: '',
  handleInputChange: () => {},
  handleTypeChange: () => {},
};

export default memo(TimingSelect);
