import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const RootWrapper = styled('div')`
  border-radius: 8px;
  display: flex;
  height: 100%;
  width: 460px;
  flex-direction: column;
  user-select: none;
`;

export const ContentWrapper = styled('div')`
  ${({ theme }) => css`
    ${theme.typography.dina.body2};
    border-bottom: 1px solid ${theme.palette.dina.dividerLight};
  `}
  height: 101px;
  padding: 16px;
`;

export const FooterWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  gap: 8px;
  & :first-of-type {
    margin-right: 28px;
  }
`;
