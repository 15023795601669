/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback, forwardRef, useState, useContext } from 'react';
import { useQuery, NetworkStatus } from '@apollo/client';
import List from '@material-ui/core/List';
import throttle from 'lodash/throttle';
import UserCtx from 'contexts/UserContext';
import INSTANCE_SEARCH_API from 'operations/queries/searchInstances';
import useApolloSubscription from 'hooks/useApolloSubscriptionV2';
import NOTIFY_MEMBER_UPDATE_SUBSCRIPTION from 'operations/subscriptions/notifyMemberUpdate';
import useUpdateLeftSidebarCache from 'hooks/useUpdateLeftSidebarCache';
import loadingSpinnerSrc from 'assets/images/loadingSpinner/dina-loader-anpng-60frames.png';
import { Button } from 'components/buttons';
import LoadingIndicator from 'components/loadingIndicator';

import LinearProgress from 'components/linearProgress';

import { InstanceItemVariant } from './listItem/listItem-view';
import getIsUnscheduled from './utils/getIsUnscheduled';
import InstanceItem from './listItem';

import { Wrapper, ButtonWrapper, ErrorMessage, LoadingSpinner } from './list-styled';

const LIMIT = 25;
const SKIP = false;
const FETCH_POLICY = 'cache-and-network';

const InstanceContainer = (
  {
    selectedDates,
    searchString,
    assignedMembers,
    platformsFilter,
    scheduleType,
    showMyItemsOnly,
    onSelectInstance,
    selectedIndices = new Set(),
    linkedInstances = [],
    parentInstance,
    variant = InstanceItemVariant.INSTANCE_LIST_ITEM,
    isArchivedTabSelected,
  },
  ref,
) => {
  const [openPreviewIndex, setOpenPreviewIndex] = useState(null);
  const [currentFocusedIndex, setCurrentFocusedIndex] = useState(null);
  const [, updateOnSubscription, setCurrentFilter] = useUpdateLeftSidebarCache();

  const isUnscheduled = getIsUnscheduled(scheduleType);
  const user = useContext(UserCtx);
  const { mId: userId } = user;

  useApolloSubscription(NOTIFY_MEMBER_UPDATE_SUBSCRIPTION, {
    variables: {
      mIdSubscribed: 'instances',
    },
    onSubscriptionData: ({ client, data: subscriptionData }) => {
      const item = subscriptionData.data.notifyMemberUpdateSubscription;
      updateOnSubscription(item, userId);
    },
    source: 'instances',
  });

  const filter = {
    mTypes: [`${isArchivedTabSelected ? 'archived_' : ''}instance`],
    searchString,
    isUnscheduled,
    assignedMembers,
    platformsFilter,
    userItemsOnly: showMyItemsOnly,
  };

  if (isUnscheduled !== true && selectedDates) {
    const { endDate, startDate, ignoreStartDate } = selectedDates;
    filter.searchBefore = endDate ? endDate.toISOString() : undefined;
    if (!ignoreStartDate && startDate) {
      filter.searchAfter = startDate ? startDate.toISOString() : undefined;
    }
  }

  const handleKeyPress = useCallback((key, currentIndex, listLength, keyType) => {
    if (keyType === 'released') {
      setOpenPreviewIndex(currentIndex);
      return;
    }
    if (key === 'ArrowUp') {
      if (currentIndex !== null && currentIndex > 0) {
        setCurrentFocusedIndex(currentIndex - 1);
      }
    }
    if (key === 'ArrowDown') {
      if (currentIndex !== null && currentIndex < listLength - 1) {
        setCurrentFocusedIndex(currentIndex + 1);
      }
    }
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledHandleKeyPress = useCallback(
    throttle(
      (eventData) => {
        handleKeyPress(eventData.key, eventData.current, eventData.listLength, eventData.keyType);
      },
      500,
      { leading: true },
    ),
    [],
  );

  const handleKeyUp = (event) => {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
      event.stopPropagation();
      throttledHandleKeyPress({
        key: event.key,
        current: currentFocusedIndex,
        listLength: members.length,
        keyType: 'released',
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
      event.stopPropagation();
      throttledHandleKeyPress({
        key: event.key,
        current: currentFocusedIndex,
        listLength: members.length,
        keyType: 'pressed',
      });
    }
  };

  let members = [];

  setCurrentFilter(filter);

  const { data, error, loading, fetchMore, networkStatus } = useQuery(INSTANCE_SEARCH_API, {
    variables: {
      filter,
      limit: LIMIT,
    },
    skip: SKIP,
    fetchPolicy: FETCH_POLICY,
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    const errorMessage = `Error! ${error.message}`;
    return <ErrorMessage>{errorMessage}</ErrorMessage>;
  }

  if (data) {
    members = data.searchApi?.items || [];

    const hasMore = data.searchApi.nextToken;

    const fetchMoreInstances = async () => {
      await fetchMore({
        variables: {
          filter,
          limit: LIMIT,
          nextToken: hasMore,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => ({
          // Add the new  items to the end of the old  items.
          searchApi: {
            items: [...previousResult.searchApi.items, ...fetchMoreResult.searchApi.items],
            nextToken: fetchMoreResult.searchApi.nextToken,
            __typename: 'MemberType',
          },
        }),
      });
    };

    const instanceItems = [...data.searchApi.items].map((item, index) => (
      <InstanceItem
        key={item.mId}
        instance={item}
        index={index}
        isInstanceItemSelected={
          (openPreviewIndex !== null && openPreviewIndex === index) || selectedIndices.has(index)
        }
        ref={ref}
        variant={variant}
        disabled={linkedInstances.includes(item.mId) || item.mId === parentInstance?.mId}
        onSelectInstance={(event, idx, instanceId) => {
          onSelectInstance(event, idx, instanceId, data.searchApi.items);
        }}
        setOpenPreviewIndex={setOpenPreviewIndex}
        setCurrentFocusedIndex={setCurrentFocusedIndex}
      />
    ));

    return (
      <Wrapper>
        {loading &&
          (networkStatus === NetworkStatus.loading ||
            networkStatus === NetworkStatus.setVariables) && <LinearProgress />}
        <List disablePadding onKeyDown={handleKeyDown} onKeyUp={handleKeyUp}>
          {instanceItems}
          {hasMore && (
            <ButtonWrapper>
              <Button
                onClick={fetchMoreInstances}
                variant="discreet"
                usage="text"
                title="Load more elements"
              >
                {loading && networkStatus === NetworkStatus.fetchMore && (
                  <LoadingSpinner src={loadingSpinnerSrc} alt="loading" />
                )}
                Load more
              </Button>
            </ButtonWrapper>
          )}
        </List>
      </Wrapper>
    );
  }

  if (
    loading &&
    (networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.setVariables)
  ) {
    return (
      <Wrapper>
        <LoadingIndicator />
      </Wrapper>
    );
  }

  return null;
};

const forwardedInstanceContainer = forwardRef(InstanceContainer);

export default forwardedInstanceContainer;
