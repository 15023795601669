import React, { memo } from 'react';
import { LazyPlayer } from 'components/reactPlayer';
import useGetAsset from 'hooks/useGetAsset';
import useStyles from 'screens/storybox/components/assets/mediaViewer/mediaViewer-styles';
import { Wrapper } from 'screens/storybox/components/assets/mediaViewer/mediaViewer-view';
import Dialog from 'components/dialog';
import LoadingIndicator from 'components/loadingIndicator';
import useLogger from 'utils/useLogger';

const MemoizedDialog = memo(Dialog);

const getContentUrl = (contentUrl) => {
  if (contentUrl === 'missingproxy') return null;
  return contentUrl;
};

const getItemType = (asset) => {
  const { itemType, mediaType } = asset;
  if (mediaType === 'application/mxf') return 'video';
  return itemType || mediaType?.split('/')[0];
};

const MediaViewer = ({ asset, onClose }) => {
  const logger = useLogger('MediaViewer');
  const classes = useStyles();
  const { mId, mRefId, mContentUrl, thumbnailUrl, proxy, mThumbnailUrl } = asset;

  const hasContentUrl = Boolean(mContentUrl) && Boolean(mThumbnailUrl);
  // mThumbnailUrl for distinguishing placeholder from Linear & other instances
  // placeholder in Other Instances doesn't have mThumbnailUrl.
  // mThumbnailUrl Only exists in PrimaryAutomation if the placeholder is 'opened in mimir'
  // first by far now!

  const [assetData, , loading] = useGetAsset(mId, mRefId, hasContentUrl);
  // if hasContentUrl is true it will skip the Query

  if (loading) return <LoadingIndicator />;

  const url = assetData?.mContentUrl;

  const imageSrc = url || getContentUrl(mContentUrl) || thumbnailUrl;
  const mediaSrc = url || getContentUrl(mContentUrl) || proxy;

  const itemType = getItemType(asset);
  switch (itemType) {
    case 'image':
      return (
        <Wrapper onClose={onClose}>
          <img src={imageSrc} className={classes.image} alt="imageUrl" />
        </Wrapper>
      );
    case 'video':
      return (
        <LazyPlayer
          controls
          url={mediaSrc}
          width="800px"
          height="500px"
          className={classes.video}
          allowFullScreen
          onError={onClose}
        />
      );
    case 'audio':
      return (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <audio controls className={classes.audio}>
          <source src={mediaSrc} type="audio/mpeg" />
        </audio>
      );
    default:
      logger.log(`MediaViewer: Unrecognized itemType=${itemType}, mediaType=${asset?.mediaType}`);
      return <div />;
  }
};

const MediaDialog = ({ asset, open, onClose }) => (
  <MemoizedDialog
    disableRestoreFocus
    PaperProps={{
      style: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
    }}
    open={open}
    onClose={onClose}
  >
    <MediaViewer asset={asset} onClose={onClose} />
  </MemoizedDialog>
);

export default memo(MediaDialog);
