import LockedIndicator from 'components/lockedIndicator';
import Tooltip from 'components/tooltip';
import OrderButton from 'features/orderForm/components/OrderButton';
import { useCanSeeOrderManagement } from 'hooks/useCheckUserRight';
import { HStack } from 'layouts/box/Box';

import { InstanceChangeObject } from '../hooks/useInstanceCore';
import useInstanceMetadata from '../hooks/useInstanceMetadata';
import useInstancePermissions from '../hooks/useInstancePermissions';
import useInstanceViewUtils from '../hooks/useInstanceViewUtils';
import { useInstanceMolecule } from '../store/instance';
import { FooterTop, FooterWrapper, StyledFooterText } from '../styled';

import InfoSummary from './infoSummary/InfoSummary';
import InstanceLink from './instanceLink';
import StatusSelector from './statusSelector';

interface FooterProps {
  onDone: (version?: string) => Promise<void>;
  handleCancel: () => Promise<void>;
  onForceUnlock: () => Promise<void>;
  handleClipDurationChange: (newClipDuration: string, manual?: boolean) => Promise<void>;
  handleScriptDurationChange: (newScriptDuration: string, manual?: boolean) => Promise<void>;
  onInstanceChanged: (instanceChangeObject: InstanceChangeObject) => Promise<unknown>;
  twitterThreadCount: number;
  wordCount: number;
}
const Footer = ({
  wordCount,
  twitterThreadCount,
  handleClipDurationChange,
  handleScriptDurationChange,
  onInstanceChanged,
  onForceUnlock,
  handleCancel,
  onDone,
}: FooterProps) => {
  const {
    useDisableEdit,
    useInstanceValue,
    usePublishingPointValue,
    useReadLock,
    useWriteLock,
    useLockedBy,
    useIsCancelled,
    useIsSavingInstance,
    useDurations,
    useLockedByCurrentUser,
  } = useInstanceMolecule();
  const {
    isLinearInstance,
    isTwitterInstance,
    footerMessage,
    backgroundColor,
    opacity,
    variant,
    accountIdentifier,
    isCMSInstance,
  } = useInstanceViewUtils();
  const {
    clipAutoDurationField,
    scriptDurationField,
    autoClipDurationSettingsValue,
    canUpdateScriptDurationSettingsValue,
  } = useInstanceMetadata();
  const { canShowNewDesign, canUpdateInstance } = useInstancePermissions();
  const { canAccessOrderManagement } = useCanSeeOrderManagement();

  const [disableEdit] = useDisableEdit();
  const instance = useInstanceValue();
  const publishingPoint = usePublishingPointValue();
  const [readLock] = useReadLock();
  const [writeLock] = useWriteLock();
  const [lockedBy] = useLockedBy();
  const [lockedByCurrentUser] = useLockedByCurrentUser();
  const [isSaving] = useIsSavingInstance();
  const [isCancelled] = useIsCancelled();
  const [durations] = useDurations();

  return (
    <FooterWrapper $backgroundColor={backgroundColor} $opacity={opacity} alignItems="flex-start">
      <FooterTop
        justifyContent="space-between"
        alignItems="center"
        flexGrow={1}
        $backgroundColor={backgroundColor}
        gap="0.5rem"
      >
        <HStack gap="0.5rem" overflow="visible">
          {isLinearInstance && (
            <>
              <InfoSummary
                disabled={disableEdit}
                duration={durations.clip}
                manualDurationField={clipAutoDurationField}
                title="Dur"
                onUpdate={handleClipDurationChange}
                manualDurationSettingsValue={autoClipDurationSettingsValue}
              />

              <InfoSummary
                disabled={!canUpdateScriptDurationSettingsValue || disableEdit}
                duration={durations.script}
                manualDurationField={scriptDurationField}
                title="Script"
                onUpdate={handleScriptDurationChange}
                manualDurationSettingsValue={canUpdateScriptDurationSettingsValue}
              />

              <InfoSummary disabled duration={durations.total} title="Total" />
            </>
          )}
          {canShowNewDesign && isTwitterInstance && (
            <InfoSummary disabled duration={String(twitterThreadCount)} title="Threads" />
          )}
          {isCMSInstance && <InfoSummary disabled duration={wordCount.toString()} title="Words" />}
          <Tooltip title={footerMessage}>
            <StyledFooterText variant="captionMedium">{footerMessage}</StyledFooterText>
          </Tooltip>
        </HStack>
        <HStack gap="0.2rem" minWidth="min-content">
          {canAccessOrderManagement && instance && (
            <OrderButton
              resourceId={instance?.mId}
              resourceTitle={instance?.mTitle}
              resourceType="instance"
              formTypes={['instance', publishingPoint]}
            />
          )}
          <Tooltip title="Link instance">
            <InstanceLink
              linkedInstances={instance?.mRelatedMembers || []}
              instance={instance}
              isLinkingDisabled={!canUpdateInstance}
            />
          </Tooltip>
          <StatusSelector
            variant={variant}
            margin="0px"
            statusId={instance?.mState}
            publishingPoint={publishingPoint}
            accountIdentifier={accountIdentifier}
            disableEdit={disableEdit}
            onChange={(status: string) => onInstanceChanged({ changeType: 'status', status })}
          />
        </HStack>
      </FooterTop>
      {(readLock || writeLock) && (
        <LockedIndicator
          readLock={readLock}
          writeLock={writeLock}
          lockedBy={lockedBy}
          onDone={onDone}
          onCancel={handleCancel}
          isSaving={isSaving}
          isCancelled={isCancelled}
          lockedId={instance?.locked}
          isCurrentUser={lockedByCurrentUser}
          onForceUnlock={onForceUnlock}
        />
      )}
    </FooterWrapper>
  );
};

export default Footer;
