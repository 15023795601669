const saveFeedOnContext = (feedResult, config) => {
  // eslint-disable-next-line no-param-reassign
  config.feeds = [
    ...feedResult.data.getMemberFromId.map(({ mTitle, mRefId, accessToken, mMetaData }) => ({
      mTitle,
      mRefId,
      accessToken,
      mMetaData,
    })),
  ];
};

export default saveFeedOnContext;
