import { useEffect, useMemo, useState } from 'react';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import styled from '@emotion/styled';
import { Checkbox } from '@material-ui/core';

import { ReactComponent as Add } from 'assets/icons/systemicons/plus_small.svg';
import { Button } from 'components/buttons';
import { PopoverColorConfig } from 'components/editMdfDialog/components/PopoverColorConfig';
import { SortableItemWrapper } from 'components/editMdfDialog/components/SortableItemWrapper';
import { StyledTextField } from 'components/mdfEditor/fields/text/styled';
import Tooltip from 'components/tooltip';
import { createAlternative } from 'features/mdf/mdf-utils';
import { getStatusColors } from 'features/sidepanel/utils/utils';
import { Box, HStack } from 'layouts/box/Box';
import { useDisableSaveError } from 'screens/main/components/header/navbar/settings/atomsTs';
import { Alternative } from 'types/graphqlTypes';
import { OrderFormMemberType } from 'types/memberTypes/order_form';

import { OptionWrapper, Remove } from './styled';

export const StyledCheckbox = styled(Checkbox)`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

interface Props {
  alternatives: Alternative[];
  onColorsChange: (colors: Record<string, string>) => void;
  onChange: (alts: Alternative[]) => void;
  form: OrderFormMemberType;
}

interface SpecialAlternative extends Alternative {
  markAsComplete: boolean;
}

const produceStatusOptions = (alts: SpecialAlternative[]): Alternative[] => {
  return alts.map((specialAlt) => ({
    id: specialAlt.id,
    label: specialAlt.label,
    value: `${specialAlt.markAsComplete ? 'inactive' : 'active'}#${specialAlt.value}`,
  }));
};

const nonEditableOptions = ['created', 'closed'];

export function EditStatusAlternatives({
  form,
  alternatives,
  onChange,
  onColorsChange,
}: Readonly<Props>) {
  const [options, setOptions] = useState<SpecialAlternative[]>([]);
  const [colors, setColors] = useState<Record<string, string>>({});
  const [, setDisableSaveError] = useDisableSaveError();
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  useEffect(() => {
    setOptions(
      alternatives.map((alt) => {
        return {
          ...alt,
          value: alt.value.split('#')[1],
          markAsComplete: alt.value.startsWith('inactive#'),
        };
      }),
    );
    setColors(getStatusColors(form));
  }, [form.mRefId, alternatives]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    const oldIndex = options.findIndex((item) => item.id === active.id);
    const newIndex = options.findIndex((item) => item.id === over?.id);
    const updatedOrder = arrayMove(options, oldIndex, newIndex);
    setOptions(updatedOrder);
    onChange(produceStatusOptions(updatedOrder));
  };

  const removeAlternative = (alternative: SpecialAlternative) => {
    if (!nonEditableOptions.includes(alternative.value)) {
      setOptions([...options.filter((alt) => alt.id !== alternative.id)]);
    }
  };

  const addOption = () => {
    const newOption = createAlternative('value');
    const newOptions = [...options, { ...newOption, markAsComplete: false }];
    setOptions(newOptions);
    onChange(produceStatusOptions(newOptions));
  };

  const handleOptionChange = (
    newValue: string | boolean,
    prop: 'label' | 'value' | 'markAsComplete',
    alternative: SpecialAlternative,
    index: number,
  ) => {
    const copy = [...options];
    const newAlt: SpecialAlternative = {
      ...alternative,
      [prop]: newValue,
    };
    copy.splice(index, 1, newAlt);
    const updated = [...copy];
    setOptions(updated);
    onChange(produceStatusOptions(updated));
  };

  const duplicateValues = useMemo(() => {
    const fSet = new Set<string>();
    const duplicates: string[] = [];
    options.forEach((opt) => {
      if (fSet.has(opt.value)) {
        duplicates.push(opt.value);
      } else {
        fSet.add(opt.value);
      }
    });
    return duplicates;
  }, [options]);

  useEffect(() => {
    if (duplicateValues.length) {
      setDisableSaveError((prev) => {
        return {
          ...prev,
          [`${form.mRefId}-statuses`]: `Duplicate status in task '${form.mTitle}'`,
        };
      });
    } else {
      setDisableSaveError((prev) => {
        const copy = { ...prev };
        delete copy[`${form.mRefId}-statuses`];
        return copy;
      });
    }
  }, [duplicateValues, setDisableSaveError]);

  const handleColorChange = (alt: Alternative, val: string) => {
    if (colors[alt.value] === val) return;
    const copy = {
      ...colors,
      [alt.value]: val,
    };
    setColors(copy);
    onColorsChange(copy);
  };

  const clearColor = (alt: Alternative) => {
    const copy = { ...colors };
    delete copy[alt.value];
    setColors(copy);
    onColorsChange(copy);
  };

  return (
    <HStack width="100%" alignItems="start">
      <Box width="100%" maxWidth="450px" flexGrow={0}>
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext items={options} strategy={verticalListSortingStrategy}>
            {options.map((alt, i) => (
              <SortableItemWrapper key={alt.id} item={alt}>
                <OptionWrapper>
                  <PopoverColorConfig
                    hideLabel
                    onColorChoice={(c: string) => handleColorChange(alt, c)}
                    onClearColor={() => clearColor(alt)}
                    selectedColor={colors[alt.value]}
                  />
                  <StyledTextField
                    variant="filled"
                    value={alt.label}
                    onChange={(ev) => handleOptionChange(ev.target.value, 'label', alt, i)}
                  />
                  <Tooltip
                    title={duplicateValues.includes(alt?.value) ? 'Duplicate value' : ''}
                    enterDelay={300}
                  >
                    <StyledTextField
                      name="value"
                      variant="filled"
                      value={alt.value}
                      disabled={nonEditableOptions.includes(alt.value)}
                      onChange={(ev) => handleOptionChange(ev.target.value, 'value', alt, i)}
                      error={Boolean(duplicateValues.includes(alt?.value))}
                    />
                  </Tooltip>
                  <Tooltip
                    title={
                      nonEditableOptions.includes(alt.value)
                        ? 'This is a system status and cannot be changed.'
                        : 'Mark task as completed when this status is set'
                    }
                  >
                    <span>
                      <StyledCheckbox
                        name="checkbox"
                        checked={alt.markAsComplete}
                        onChange={() =>
                          handleOptionChange(!alt.markAsComplete, 'markAsComplete', alt, i)
                        }
                        disabled={nonEditableOptions.includes(alt.value)}
                        color="primary"
                      />
                    </span>
                  </Tooltip>
                  <Tooltip
                    title={
                      nonEditableOptions.includes(alt.value)
                        ? 'This is a system status and cannot be removed.'
                        : ''
                    }
                  >
                    <Remove
                      className="remove"
                      onClick={() => removeAlternative(alt)}
                      $disabled={nonEditableOptions.includes(alt.value)}
                    />
                  </Tooltip>
                </OptionWrapper>
              </SortableItemWrapper>
            ))}
          </SortableContext>
        </DndContext>
      </Box>
      <Box margin="5px 0 0 0">
        <Button
          width={120}
          height={32}
          variant="outlined"
          usage="outlined"
          onClick={addOption}
          title="Add option"
        >
          <Add className="skipOverride" />
          Add option
        </Button>
      </Box>
    </HStack>
  );
}
