import { useCallback } from 'react';

import useCreateAsset from 'hooks/useCreateAsset';
import useUpdateAsset from 'hooks/useUpdateAsset';
import useUploadAsset from 'hooks/useUploadAsset';
import { Asset } from 'types';
import { getAssetData, getFileAssetData } from 'utils/assetData';

import { useInstanceMolecule } from '../store/instance';

import useInstancePermissions from './useInstancePermissions';

const useInstanceAsset = () => {
  const { useInstanceValue } = useInstanceMolecule();
  const { canUploadMediaBySignedURL } = useInstancePermissions();

  const [createStoryAsset] = useCreateAsset();
  const [getUploadUrl] = useUploadAsset();
  const [updateStoryAsset] = useUpdateAsset();

  const instance = useInstanceValue();

  const createAssetWithoutUrl = useCallback(
    async (file: File, uploadProgressCallback: () => void) => {
      if (!instance?.mStoryId) return;

      const assetData = getFileAssetData(instance?.mStoryId, file);
      const sourceData = {
        mId: assetData.mId,
        mRefId: assetData.mRefId,
        src: '',
      };

      try {
        const result = await createStoryAsset(
          instance?.mStoryId,
          assetData,
          true,
          uploadProgressCallback,
        );
        const { createAssets: assets } = result.data as { createAssets: Asset[] };
        if (assets && assets[0]) {
          sourceData.src = assets[0].mContentKey;
        }
      } catch (e) {
        // logger.log(e)
      }
      return sourceData;
    },
    [createStoryAsset, instance?.mStoryId],
  );

  const getAssetUploadUrl = useCallback(
    async (file: File, data?: { mTitle?: string; mRefId?: string }) => {
      const { name, type } = file;
      const { mTitle: payloadAssetTitle, mRefId: assetId } = data || {};

      return getUploadUrl({
        mId: instance?.mStoryId,
        mRefId: assetId || undefined,
        mTitle: payloadAssetTitle || name,
        itemType: type === 'application/mxf' ? 'video' : type.split('/')[0],
        filename: name,
        fileType: type,
      });
    },
    [getUploadUrl, instance?.mStoryId],
  );

  const onAssetInsert = useCallback(
    async (
      file: File,
      data: { mTitle?: string; mRefId?: string },
      bypassUploadApi: boolean,
      uploadProgressCallback: () => void,
    ) => {
      if (!bypassUploadApi && canUploadMediaBySignedURL) return getAssetUploadUrl(file, data);
      return createAssetWithoutUrl(file, uploadProgressCallback);
    },
    [canUploadMediaBySignedURL, getAssetUploadUrl, createAssetWithoutUrl],
  );

  const createAsset = useCallback(
    async (storyId: string, assetData: Asset) => {
      const asset = getAssetData(storyId, assetData);
      const result = await createStoryAsset(storyId, asset, true, undefined);
      return result;
    },
    [createStoryAsset],
  );

  const updateAsset = useCallback(
    async (storyId: string, assetData: Asset) => {
      const result = await updateStoryAsset(storyId, assetData);
      return result;
    },
    [updateStoryAsset],
  );

  return { createAssetWithoutUrl, getAssetUploadUrl, onAssetInsert, createAsset, updateAsset };
};

export default useInstanceAsset;
