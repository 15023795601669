import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Modal as MuiModal, IconButton as MuiIconButton } from '@material-ui/core';
import { LazyPlayer as LazyPlayerComponent } from 'components/reactPlayer';
import { ReactComponent as CloseIconComponent } from 'assets/icons/systemicons/close.svg';

export const Modal = styled(MuiModal)`
  display: flex;
  align-items: center;
  justify-content: center;
  :focus {
    outline: none;
  }
`;

export const AnimatedModalContent = styled('div')`
  :focus {
    outline: none;
  }
  transition: transform 0.2s;
  opacity: ${({ open }) => (open ? 1 : 0)};
`;

export const ContentWrapper = styled('div')`
  width: 800px;
  background-color: ${({ theme }) => theme.palette.dina.surfaceCardDark};
  border-radius: 8px;
  ${({ theme }) =>
    theme.palette.mode === 'light'
      ? css`
          box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15), 0px 0px 6px rgba(0, 0, 0, 0.15),
            0px 12px 24px rgba(0, 0, 0, 0.15);
        `
      : css`
          box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25), 0px 0px 6px rgba(0, 0, 0, 0.25),
            0px 12px 24px rgba(0, 0, 0, 0.25);
        `}
  :focus {
    outline: none;
  }
`;

export const HeaderWrapper = styled('div')`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.palette.dina.dividerLight};
    background: ${theme.palette.dina.backgroundResting};
  `}
  height: 63px;
  display: flex;
  justify-content: space-between;
`;

export const HeaderContent = styled('span')`
  display: flex;
  flex-direction: column;
  padding: 15.5px 59px 0 16px;
`;

export const Title = styled('p')`
  ${({ theme }) => theme.typography.dina.overline};
  margin: 0;
  margin-bottom: 2px;
`;

export const Filename = styled('p')`
  ${({ theme }) => theme.typography.dina.subtitle1};
  margin: 0;
  overflow: hidden;
  width: 670px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
`;

export const IconButton = styled(MuiIconButton)`
  margin-right: 16px;
  :hover {
    background-color: transparent;
  }
`;
export const CloseIcon = styled(CloseIconComponent)`
  :hover {
    path {
      fill: ${({ theme }) => theme.palette.dina.highEmphasis};
      fill-opacity: 1;
    }
  }
`;

export const MainContentWrapper = styled('div')`
  width: 800px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  display: flex;
  flex-direction: row-reverse;
`;

export const LazyPlayer = styled(LazyPlayerComponent)`
  & > video {
    :focus {
      outline: none;
    }
  }
`;

export const BgImageWrapper = styled('span')`
  width: 800px;
  height: 450px;
  background: ${(props) => `url(${props.image})`};
  background-size: cover;
`;

export const BgImage = styled('img')`
  width: 800px;
  height: 450px;
  backdrop-filter: blur(30px);
  object-fit: contain;
`;

export const InfoWrapper = styled('div')`
  position: absolute;
  display: flex;
`;

export const AnimatedOverlay = styled('div')`
  background: ${({ theme }) => theme.palette.dina.dialogBackdropOverlay};
  backdrop-filter: blur(30px);
  padding-left: 16px;
  height: 450px;
  transition: transform 0.2s;
  width: ${({ open }) => (open ? '400px' : 0)};
`;

export const InfoIconWrapper = styled('span')`
  height: 40px;
  width: 40px;
  ${({ isInfoOpen, theme }) =>
    isInfoOpen
      ? {
          position: 'absolute',
          right: 0,
        }
      : {
          background: theme.palette.dina.dialogBackdropOverlay,
          backdropFilter: 'blur(30px)',
          borderRadius: '0 0 0 6px',
        }}
`;

export const AnimatedInfoIcon = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  transition: transform 0.2s;
  :hover {
    transform: scale(1.02);
  }
`;

export const FooterWrapper = styled('div')`
  background: ${({ theme }) => theme.palette.dina.backgroundResting};
  height: 57px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 8px;
`;
