import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled('div')<{
  $isDragging: boolean;
  $isOver: boolean;
  $drawLeftBorder: boolean;
}>`
  ${({ theme }) => css`
    background-color: ${theme.palette.dina.surfaceAppBackgroundNavLevel1};
  `}
  border-radius: 6px;
  height: 100%;
  width: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  user-select: none;
  padding-bottom: 8px;
  opacity: ${({ $isDragging }) => ($isDragging ? '0.6' : '1')};
  position: relative;
  &:after {
    content: '';
    position: absolute;
    right: 0px;
    width: 4px;
    top: 0;
    height: 100%;
    ${({ $drawLeftBorder }) => ($drawLeftBorder ? 'left: -6px' : 'right: -6px')};
    background: ${({ $isOver, theme }) => ($isOver ? theme.palette.dina.onFocus : 'transparent')};
  }
`;

export const Top = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
`;

export const Title = styled('p')`
  ${({ theme }) => theme.typography.dina.h7};
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
  writing-mode: vertical-lr;
`;

export const Metadata = styled('p')`
  ${({ theme }) => theme.typography.dina.caption};
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  writing-mode: vertical-lr;
  padding-bottom: 8px;
  padding-top: 8px;
  border-top: 1px solid ${({ theme }) => theme.palette.dina.mediumEmphasis};
  :first-of-type {
    border-top: none;
  }
`;
