import { Svg, Rect, Path } from '@react-pdf/renderer';

const JingleIcon = () => {
  return (
    <Svg style={{ width: 32, height: 32, marginRight: 8 }} viewBox="0 0 32 32">
      <Rect x="0.5" y="0.5" width="31" height="31" rx="5.5" ry="5.5" stroke="black" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 8C15 7.44772 15.4477 7 16 7C16.5523 7 17 7.44772 17 8V12H21C21.5523 12 22 12.4477 22 13C22 13.5523 21.5523 14 21 14H17V18C17 18.5523 16.5523 19 16 19C15.4477 19 15 18.5523 15 18V14H11C10.4477 14 10 13.5523 10 13C10 12.4477 10.4477 12 11 12H15V8ZM12 23.5C12 24.3284 11.3284 25 10.5 25C9.67157 25 9 24.3284 9 23.5C9 22.6716 9.67157 22 10.5 22C11.3284 22 12 22.6716 12 23.5ZM17.5 23.5C17.5 24.3284 16.8284 25 16 25C15.1716 25 14.5 24.3284 14.5 23.5C14.5 22.6716 15.1716 22 16 22C16.8284 22 17.5 22.6716 17.5 23.5ZM21.5 25C22.3284 25 23 24.3284 23 23.5C23 22.6716 22.3284 22 21.5 22C20.6716 22 20 22.6716 20 23.5C20 24.3284 20.6716 25 21.5 25Z"
        fill="#0F141A"
        fillOpacity="0.87"
      />
    </Svg>
  );
};

export default JingleIcon;
