import conversationTypes from 'utils/constants/conversationTypes';

const { DIRECT, GROUP, ALL, TEAM, DEPARTMENT, STORY, PITCH, RUNDOWN, SPACE, MENTION } =
  conversationTypes;
const regularConvoTypes = [
  DIRECT,
  GROUP,
  ALL,
  TEAM,
  DEPARTMENT,
  STORY,
  PITCH,
  RUNDOWN,
  SPACE,
  MENTION,
];

const checkIsNotificationConversation = (convoType) =>
  !regularConvoTypes.some((type) => type === convoType);

export default checkIsNotificationConversation;
