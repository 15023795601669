import { isPast, isThisMinute } from 'date-fns';

/**
 * Returns true if the date indicates to publish now.
 * This is true for timestamps in the past or within the current minute
 * @param {string} tstr - time as ISO string
 */
const scheduleNow = (tstr) => {
  const date = new Date(tstr);
  return isPast(date) || isThisMinute(date);
};

/**
 * Sets the time component of given timestamp
 * @param {string} tiso - timestamp (ISO string)
 * @param {Date} tvalue - date with time information
 * @returns updated timestamp (ISO string)
 */
const setHoursIsoString = (tiso, tvalue) => {
  const ttime = new Date(tiso);
  const dValue = new Date(tvalue);
  ttime.setHours(
    dValue.getHours(),
    dValue.getMinutes(),
    dValue.getSeconds(),
    dValue.getMilliseconds(),
  );
  const ntime = isPast(ttime) ? new Date() : ttime;
  return ntime.toISOString();
};

/**
 * Sets the date component of given timestamp
 * @param {string} tiso - timestamp (ISO string)
 * @param {Date} tvalue - date with date information
 * @returns updated timestamp (ISO string)
 */
const setDateIsoString = (tiso, tvalue) => {
  const ttime = new Date(tiso);
  const dValue = new Date(tvalue);
  ttime.setFullYear(dValue.getFullYear());
  ttime.setMonth(dValue.getMonth());
  ttime.setDate(dValue.getDate());
  return ttime.toISOString();
};

export { scheduleNow, setHoursIsoString, setDateIsoString };
