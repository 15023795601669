import SearchbarPositions from 'components/searchTable/constants/searchbarPositions';
import SearchTableView from 'components/searchTable/searchTable-view';
import { useSelectedLeftMenu } from 'screens/main/components/header/navbar/settings/atomsTs';
import { PermissionPropType, PermissionType } from 'types/groupPermissions/permissions';

import GroupPermissionsRow from './row';
import getGroupPermissions from './utils';

import { RootWrapper } from './styled';

interface Rows {
  rowId: string;
  permissions: JSX.Element;
  resource: string;
  permission: string;
}

const columns = [
  {
    field: 'permissions',
    headerName: 'Permissions',
    searchField: 'permission',
    sortField: 'permission',
    sortable: true,
  },
  {
    field: 'resource',
    headerName: 'Resource',
    sortable: true,
  },
];

const getRows = (permissionRowsFromConfig: PermissionType[], selectedLeftMenu: string | null) => {
  const rows = [] as Rows[];
  if (selectedLeftMenu)
    permissionRowsFromConfig.forEach((row) =>
      rows.push({
        rowId: row.id,
        permissions: (
          <GroupPermissionsRow key={row.id} row={row} selectedGroupRefId={selectedLeftMenu} />
        ),
        resource: row.resourceName,
        permission: row.permission,
      }),
    );
  return rows;
};

const GroupPermissions = (props: PermissionPropType) => {
  const { groupPolicy, instanceTypes, kanbanStates } = props;
  const [selectedLeftMenu] = useSelectedLeftMenu();
  const permissionRowsFromConfig = getGroupPermissions(
    groupPolicy,
    selectedLeftMenu as string,
    instanceTypes,
    kanbanStates,
  );
  const rows = getRows(permissionRowsFromConfig, selectedLeftMenu);
  return (
    <RootWrapper>
      <SearchTableView
        usageType="settings"
        columns={columns}
        rows={rows}
        defaultSelect={false}
        showTitle={false}
        showSearchIcon
        searchbarPosition={SearchbarPositions.TOP}
        selectable={false}
        tabs={undefined}
        setSelectedItems={undefined}
        timelineItems={undefined}
        storyInformationForTimeline={undefined}
        resourceTimelineDropdownItems={undefined}
        selectedType={undefined}
        onChangeType={undefined}
        showAvailableResourceOnly={undefined}
        setShowAvailableResourceOnly={undefined}
        shouldDisableTypeFilter={undefined}
      />
    </RootWrapper>
  );
};

export default GroupPermissions;
