import { ReactComponent as RemoveIcon } from 'assets/icons/systemicons/cancel.svg';
import Badge, { BadgeVariants } from 'components/badge';
import { Button } from 'components/buttons';

import { type MultiSelectContextProps, type Option } from '../types';

interface SelectedBadgeProps extends Pick<MultiSelectContextProps, 'handleUnselect'> {
  option: Option;
}

/** Selected badge component */
export const SelectedBadge = ({ option, handleUnselect }: Readonly<SelectedBadgeProps>) => {
  // Unselect option on enter key press.
  const onKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === 'Enter') handleUnselect(option);
  };

  // Prevent default behavior on mouse down.
  const onMouseDown = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const label = option.label.charAt(0).toUpperCase() + option.label.slice(1);

  return (
    <Badge key={option.value} variant={BadgeVariants.INPUT}>
      {label}
      <Button
        variant="discreet"
        usage="text"
        height={16}
        width={16}
        style={{ borderRadius: '50%', padding: '0' }}
        onKeyDown={onKeyDown}
        onMouseDown={onMouseDown}
        onClick={() => handleUnselect(option)}
      >
        <RemoveIcon />
      </Button>
    </Badge>
  );
};
