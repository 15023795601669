import { useCallback } from 'react';

import { TriggerActionInput, useTriggerAction } from 'api/config/useTriggerAction';
import useToast from 'components/toast/useToast';
import { IntegrationUserType } from 'types/graphqlTypes';

const useActionTrigger = () => {
  const { triggerAction } = useTriggerAction();
  const { toast, errorToast } = useToast();

  const trigger = useCallback(
    (action: IntegrationUserType, input: TriggerActionInput, description: string) => {
      const messageToUser = `'${action.mTitle}' on '${description}'`;
      triggerAction(input)
        .then(() => {
          toast({
            title: 'Action success',
            description: `${messageToUser} succeeded.`,
            type: 'success',
          });
        })
        .catch((err) => errorToast(err, `${messageToUser} failed.`));
    },
    [triggerAction, toast],
  );

  return { trigger };
};

export default useActionTrigger;
