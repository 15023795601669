import { useEffect } from 'react';
import { atom, useAtom, useSetAtom } from 'jotai';
import { createScope, molecule, useMolecule } from 'jotai-molecules';

import { MemberType } from 'types/graphqlTypes';
import { FilterValueType } from 'types/widget';

export interface GridBoardType {
  /** Grid Board mId - same as the parent widget it is mounted to  */
  mId?: string;
  mRefId?: string;
  /** Ordered lane ids */
  mOrder?: string[];
  mType?: string;
  metadata?: FilterValueType;
  mUpdatedById?: string;
}
type CallbackType = (newVal: GridBoardType, prevVal: GridBoardType) => Promise<void> | void;
export type UpdateBoardOrderType = { mOrder: string[] };
export type AddMemberType = { memberId: string; idx: number; member: MemberType };
export type RemoveMemberType = Pick<AddMemberType, 'memberId'>;
type UpdateLaneItemsInput = Record<string, string[]>;
export type UpdateLaneOrderType = { items?: UpdateLaneItemsInput };

/* -------------------------------------------------------------------------- */
/*                              Grid board atom                               */
/* -------------------------------------------------------------------------- */

export const gridScope = createScope(undefined);

const gridMolecule = molecule((_, getScope) => {
  getScope(gridScope);

  const gridBoardAtom = atom<GridBoardType>({
    mOrder: [],
  });

  // Callback hook, used for storing changes when grid board is updated
  const listenersAtom = atom<CallbackType[]>([]);

  const updateGridBoardAtom = atom(
    (get) => get(gridBoardAtom),
    (get, set, val: GridBoardType) => {
      const prevVal = get(gridBoardAtom);
      set(gridBoardAtom, val);
      const newVal = get(gridBoardAtom);
      get(listenersAtom).forEach((callback: CallbackType) => {
        void callback(newVal, prevVal);
      });
    },
  );

  const updateBoardOrderAtom = atom(null, (get, set, { mOrder = [] }: UpdateBoardOrderType) => {
    const gridBoard = { ...get(gridBoardAtom) };
    if (!gridBoard) return;
    const updatedGridBoard = { ...gridBoard, mOrder };

    set(updateGridBoardAtom, updatedGridBoard);
  });

  const gridBoardUpdatedListener = () => {
    const useListener = (callback: CallbackType) => {
      const setListeners = useSetAtom(listenersAtom);
      useEffect(() => {
        setListeners((prev) => [...prev, callback]);
        return () =>
          setListeners((prev) => {
            const index = prev.indexOf(callback);
            return [...prev.slice(0, index), ...prev.slice(index + 1)];
          });
      }, [setListeners, callback]);
    };
    return [useListener];
  };

  type GridMembersType = Record<string, MemberType>;
  const gridMembersAtom = atom<GridMembersType>({});

  const addMemberAtom = atom(null, (get, set, { memberId, member, idx }: AddMemberType) => {
    if (!memberId) return;

    // Add to grid members
    const gridMembers = { ...get(gridMembersAtom) };
    const newGridMembers = { ...gridMembers };

    // If member already exists, return
    if (newGridMembers[memberId]) return;

    newGridMembers[memberId] = member;
    set(gridMembersAtom, newGridMembers);

    // Update board order
    const board = { ...get(gridBoardAtom) };
    const newOrder = [...(board.mOrder ?? [])];
    newOrder.splice(idx, 0, memberId);
    const newBoard = { ...board, mOrder: newOrder };

    set(updateGridBoardAtom, newBoard);
  });

  const removeMemberAtom = atom(null, (get, set, { memberId }: RemoveMemberType) => {
    if (!memberId) return;

    // Update board order
    const board = { ...get(gridBoardAtom) };
    const mOrder = board.mOrder ?? [];
    const newBoard = { ...board, mOrder: [...mOrder.filter((id) => id !== memberId)] };

    set(updateGridBoardAtom, newBoard);

    // Remove from grid members
    const gridMembers = { ...get(gridMembersAtom) };
    const newGridMembers = { ...gridMembers };
    delete newGridMembers[memberId];

    set(gridMembersAtom, newGridMembers);
  });

  return {
    gridBoardUpdatedListener,
    useRemoveMember: () => useSetAtom(removeMemberAtom),
    useAddMember: () => useSetAtom(addMemberAtom),
    useGridBoard: () => useAtom(gridBoardAtom),
    useUpdateBoardOrder: () => useSetAtom(updateBoardOrderAtom),
    useGridMembers: () => useAtom(gridMembersAtom),
  };
});

export const useGridMolecule = () => useMolecule(gridMolecule);
