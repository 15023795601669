import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  input: {
    ...theme.typography.dina.listItemLabel,
    fontWeight: 'normal',
    paddingLeft: '4px',
    height: '31px',
    width: '100%',
    background: 'transparent',
    border: `1px solid transparent`,
    outline: 'none',
    '&:hover': {
      transition: '.2s ease-in',
      border: `1px solid ${theme.palette.dina.inputBorderResting}`,
      backgroundColor: theme.palette.dina.whiteHoverOverlay,
    },
    '&:focus': {
      transition: '.2s ease-out',
      border: `1px solid ${theme.palette.dina.onFocus}`,
      backgroundColor: theme.palette.dina.blackHoverOverlay,
    },
  },
}));

export default useStyles;
