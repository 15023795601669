import { actionTypes } from 'components/editor/constants/types';
import { Transforms } from 'slate';
import normalizeAssetData from 'utils/normalizeAssetData';
import { ReactEditor } from 'slate-react';
import notifyChange from 'components/editor/utils/notifyChange';
import normalizeIntoGalleryItemData from './normalizeIntoGalleryItemData';

/**
 * Adds clip media to given element
 *
 * @param {Object} editor SlateJS editor instance
 * @param {Object} element SlateJS element to be updated
 * @param {Object} currentAssets Array of objects that contains current gallery items
 * @param {Object} payload Data payload of dropped item
 * @param {Function} update Callback to be invoked on update
 * @returns {Object} SlateJS editor instance
 */

const initialPhotoGalleryItem = {
  caption: '',
  source: '',
};

const addMedia = async (editor, element, currentAssets, payload, update, index) => {
  const { data } = element;
  const assetPayload = normalizeAssetData(payload);

  try {
    const result = await update({
      type: actionTypes.CREATE_ASSET,
      payload: { document: editor.children, asset: assetPayload },
    });

    const asset = result.data.createAssets[0];

    if (asset) {
      const blockData = normalizeIntoGalleryItemData({ ...payload, ...asset });

      const assets = currentAssets?.length
        ? currentAssets.map((item, idx) => {
            if (idx === index)
              return { ...item, ...blockData, source: item.source, caption: item.caption, index };
            return item;
          })
        : [
            { index: 0, ...initialPhotoGalleryItem },
            { index: 1, ...initialPhotoGalleryItem },
          ].map((item, idx) => {
            if (idx === index) return { ...blockData, index };
            return item;
          });

      const updatedData = { ...data, assets: [...assets] };
      const path = ReactEditor.findPath(editor, element);

      Transforms.setNodes(editor, { data: updatedData }, { at: path });
      notifyChange(editor, update);
    }
  } catch (error) {
    // console.log(error);
  }
};

export default addMedia;
