import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  boxContainer: {
    ...theme.typography.dina.body2,
    background: theme.palette.dina.inputBackground,
    paddingLeft: '12px',
    lineHeight: '24px',
    fontWeight: 'normal',
    width: '100%',
    borderRadius: '2px',
    display: 'flex',
    alignItems: 'center',
  },
  helperText: {
    ...theme.typography.dina.caption,
    lineHeight: '14px',
    height: 20,
    width: '100%',
    letterSpacing: '0.5px',
    fontWeight: 'normal',
    paddingLeft: '12px',
    display: 'flex',
    alignItems: 'center',
  },
}));

export default useStyles;
