import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled/macro';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as More } from 'assets/icons/systemicons/more_vertical.svg';
import { ReactComponent as Padlock } from 'assets/icons/systemicons/padlock_on.svg';
import { ReactComponent as PinOff } from 'assets/icons/systemicons/pin_off.svg';
import { ReactComponent as PinOn } from 'assets/icons/systemicons/pin_on.svg';
import Divider from 'components/divider';
import Text from 'components/text/Text';
import { Flex } from 'layouts/box/Box';
import transientOptions from 'theme/helpers';

export const getLockColor = (readLock: boolean, writeLock: boolean, theme: Theme) => {
  if (readLock) return theme.palette.dina.statusWarning;
  if (writeLock) return theme.palette.dina.statusApproved;
  return 'transparent';
};

/** editor wrapper */
export const EditorWrapper = styled(Flex)<{
  $readLock: boolean;
  $writeLock: boolean;
}>`
  border: ${({ $readLock, $writeLock, theme }) =>
    `1px solid ${getLockColor($readLock, $writeLock, theme)}`};
`;

/** list item */
export const MoreIcon = styled(More, transientOptions)<{ $selected: boolean }>`
  transition: 0.2s;
  width: 0;
  align-self: center;

  path {
    transition: 0.2s;
    fill: ${({ $selected, theme }) =>
      $selected ? theme.palette.dina.whiteHighEmphasis : theme.palette.dina.highEmphasis};
    fill-opacity: 0.54;
  }

  :hover {
    path {
      fill-opacity: 1;
    }
  }
`;

export const PinOnIcon = styled(PinOn)<{ $selected: boolean }>`
  align-self: center;
  width: 24px;
  opacity: 1;
  transition: opacity 0.3s;

  path {
    transition: 0.2s;
    fill: ${({ $selected, theme }) =>
      $selected ? theme.palette.dina.whiteHighEmphasis : theme.palette.dina.highEmphasis};
    fill-opacity: 0.54;
  }
`;

export const PinOffIcon = styled(PinOff)<{ $selected: boolean }>`
  align-self: center;
  width: 0;
  opacity: 0;
  transition: opacity 0.3s;

  path {
    transition: 0.2s;
    fill: ${({ $selected, theme }) =>
      $selected ? theme.palette.dina.whiteHighEmphasis : theme.palette.dina.highEmphasis};
    fill-opacity: 0.54;
  }
`;

export const PinButtonsWrapper = styled('div')<{ $isPinned: boolean }>`
  height: 24px;
  align-self: center;

  ${PinOnIcon} {
    width: ${({ $isPinned }) => ($isPinned ? '24px' : 0)};
    opacity: ${({ $isPinned }) => ($isPinned ? 1 : 0)};
  }

  :hover {
    ${PinOnIcon} {
      width: ${({ $isPinned }) => ($isPinned ? 0 : '24px')};
      opacity: ${({ $isPinned }) => ($isPinned ? 0 : 1)};
    }
    ${PinOffIcon} {
      width: ${({ $isPinned }) => ($isPinned ? '24px' : 0)};
      opacity: ${({ $isPinned }) => ($isPinned ? 1 : 0)};
    }

    path {
      fill-opacity: 1;
    }
  }
`;

interface ListItemProps {
  $selected?: boolean;
  $disabled?: boolean;
}

export const StyledListItem = styled('div', transientOptions)<ListItemProps>`
  width: 100%;
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  row-gap: 0.5rem;
  column-gap: 0.5rem;
  min-width: 222px;
  min-height: 76px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.inputBorderResting};
  cursor: pointer;
  outline: none;

  background-color: ${({ theme, $selected }) =>
    $selected ? theme.palette.dina.onFocus : 'transparent'};

  :hover {
    background-color: ${({ theme, $selected }) =>
      $selected ? theme.palette.dina.onFocus : theme.palette.dina.outlineButtonOnHover};
    ${MoreIcon} {
      width: 24px;
    }
  }
  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.45;
    `}
`;

export const StyledPadlock = styled(Padlock)`
  height: 18px;
  width: 18px;

  path {
    fill: ${({ theme }) => theme.palette.dina.statusWarning};
  }
`;

export const StyledHeaderText = styled(Text)`
  flex: 1;
`;

export const StyledDescriptionText = styled(Text)`
  flex: 1;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const StyledTimeIndicator = styled(Text)`
  white-space: pre-line;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  text-align: center;
`;

export const Label = styled('div')<{ $label: string }>`
  ${({ $label }) => ({ backgroundColor: $label || 'transparent' })}
  width: 3px;
  height: auto;
  border-radius: 1.5px;
`;

export const FatDivider = styled('div')`
  width: 100%;
  height: 4px;
  background-color: ${({ theme }) => theme.palette.dina.dividerLight};
`;

/** menu */

export const MenuWrapper = styled('div')`
  width: 360px;
`;

export const MUIListItem = styled(ListItem)`
  align-items: center;
  padding: 0 16px;
  :hover {
    ${({ disabled, theme }) => ({
      backgroundColor: disabled ? 'inherit' : theme.palette.dina.outlineButtonOnHover,
      '& path': {
        'fill-opacity': disabled ? 0.54 : 1,
      },
    })}
  }
`;

export const Icon = styled('div')`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled('div')`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 8px 16px 8px 0;
`;

export const ItemContainer = styled('div')`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ItemText = styled(Typography)`
  ${({ theme }) => theme.typography.dina.listItemLabel};
  white-space: pre-line;
`;

export const InfoText = styled(Typography)`
  ${({ theme }) => theme.typography.dina.caption};
  white-space: pre-line;
  text-align: right;
`;

export const MenuLabelContainer = styled('div')`
  width: 100%;
`;

export const MenuLabel = styled(Typography)`
  ${({ theme }) => theme.typography.dina.overline};
  height: 32px;
  margin: 0 17px;
  display: flex;
  align-items: center;
`;

export const MenuItemContainer = styled('div')<{ $selected: boolean }>`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  cursor: pointer;
  ${({ $selected, theme }) => ({
    backgroundColor: $selected ? theme.palette.dina.onSelected : 'inherit',
    '&:hover': {
      backgroundColor: $selected
        ? theme.palette.dina.onSelected
        : theme.palette.dina.outlineButtonOnHover,
    },
  })}
`;

export const MenuItemWrapper = styled('div')`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Circle = styled('div')`
  width: 14px;
  height: 14px;
  border-radius: 7px;
  border: 0.5px solid ${({ theme }) => theme.palette.dina.blackMediumEmphasis};
  box-sizing: border-box;
  margin: 5px;
`;

export const MenuItemLabel = styled(Typography)`
  ${({ theme }) => theme.typography.dina.listItemLabel};
  text-transform: capitalize;
`;

export const Check = styled('div')`
  width: 24px;
  height: 24px;
  path {
    fill: ${({ theme }) => theme.palette.dina.highEmphasis};
    fill-opacity: 1;
  }
`;

export const HDivider = styled(Divider)`
  width: 100%;
  height: 1px;
  margin: 4px 0;
  background-color: ${({ theme }) => theme.palette.dina.dividerLight};
`;
