import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => {
  const buttonStyle = {
    ...theme.typography.dina.button,
    height: '40px',
    borderRadius: '4px',
    textTransform: 'none',
    fontWeight: 500,
    width: '151.5px',
  };
  return {
    instancesContainer: {
      backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel1,
      maxHeight: '768px',
      height: `calc(768px - 58px)`,
      minHeight: '75px',
      width: '327px',
      overflowY: 'hidden',
      display: 'flex',
      flexDirection: 'column',
    },
    headerContainer: {
      backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel2,
      borderBottom: `1px solid ${theme.palette.dina.dividerLight}`,
    },
    instanceListContainer: {
      flexGrow: 100,
      width: '100%',
    },
    emptyList: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      ...theme.typography.dina.listItemLabel,
      color: theme.palette.dina.mediumEmphasis,
    },
    addNewWrapper: {
      whiteSpace: 'nowrap',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    addNew: {
      ...theme.typography.dina.listItemLabel,
      'text-decoration': 'underline',
      fontWeight: 700,
      cursor: 'pointer',
    },
    instanceList: {
      width: '327px',
      minHeight: '75px',
      height: '100% !important',
    },
    loadMoreButtonContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      borderBottom: `1px solid ${theme.palette.dina.dividerLight}`,
    },
    headerText: {
      ...theme.typography.dina.h5,
      lineHeight: '28px',
      padding: '15px 15px 15px 15px',
      width: '100%',
      color: theme.palette.dina.highEmphasis,
      backgroundColor: theme.palette.dina.surfaceAppBackgroundNavLevel2,
    },
    buttonContainer: {
      padding: '8px',
      background: theme.palette.dina.surfaceCard,
    },
    cancelButton: {
      ...buttonStyle,
      border: `0.5px solid ${theme.palette.dina.buttonBorderOutlined}`,
    },
    createButton: {
      ...buttonStyle,
      backgroundColor: theme.palette.dina.buttonBackgroundHighEmphasis,
      marginLeft: '8px',
      '&:hover': {
        backgroundColor: theme.palette.dina.buttonBackgroundHighEmphasis,
      },
    },
    disabled: {
      opacity: '0.35',
      color: `${theme.palette.dina.highEmphasis} !important`,
    },
    buttonLabel: {
      color: theme.palette.dina.highEmphasis,
    },
  };
});

export default useStyles;
