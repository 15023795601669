const getKeyString = (event: React.KeyboardEvent<HTMLInputElement>) => {
  const { code, ctrlKey, shiftKey, altKey, metaKey } = event;
  const ctrl = ctrlKey ? 'ctrl+' : '';
  const shift = shiftKey ? 'shift+' : '';
  const alt = altKey ? 'alt+' : '';
  const meta = metaKey ? 'meta+' : '';
  const keyCode = code?.toLowerCase()?.replace(/key|digit/gi, '');
  const eventKey = `${ctrl}${shift}${alt}${meta}${keyCode}`;
  return eventKey;
};

const validateShortCut = (event: React.KeyboardEvent<HTMLInputElement>, shortCut: string) => {
  if (getKeyString(event) === shortCut) return true;
  return false;
};

export { validateShortCut, getKeyString };
