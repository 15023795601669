import { memo, useCallback } from 'react';

import {
  MultiSelect,
  MultiSelectInput,
  MultiSelectList,
  type Option,
} from 'components/combobox/MultiSelect';

import { LANGUAGE_OPTIONS as OPTIONS } from './languages';
import { FilterCommonTypes } from './types';

interface MediaTopicsFilterProps extends FilterCommonTypes {
  languages?: string[];
}

// Get value string array from option array
const getSelectedValues = (selected: Option[]) => selected.map((option) => option.value);

// Get selected options from string array
const getSelectedOptions = (languageKeys: string[]) => {
  const selectedLanguages = [];

  for (const key of languageKeys) {
    const language = OPTIONS.find((t) => t.value === key);
    if (language) selectedLanguages.push(language);
  }

  return selectedLanguages;
};

/** Filter on language */
function LanguagesFilter({ languages = [], onChange }: Readonly<MediaTopicsFilterProps>) {
  const handleChange = useCallback(
    (val: Option[]) => {
      onChange({
        name: 'languages',
        value: getSelectedValues(val),
      });
    },
    [onChange],
  );

  const placeholder =
    languages.length > 0
      ? `${languages.length} language${languages.length > 1 ? 's' : ''} selected`
      : 'Showing all languages';

  return (
    <MultiSelect value={getSelectedOptions(languages)} onChange={handleChange} options={OPTIONS}>
      <MultiSelectInput placeholder={placeholder} />
      <MultiSelectList />
    </MultiSelect>
  );
}

export default memo(LanguagesFilter);
