import { gql } from '@apollo/client';

const COMMON_KEYS = `
  id
  type
  order
`;

export const UPDATED_ORDERING = gql`
  mutation UpdateOrdering($input: UpdateOrderingInput) {
    updateOrdering(input: $input) {
      order
    }
  }
`;

export const GET_ORDERING = gql`
  query GetOrdering($input: GetOrderingInput) {
    getOrdering(input: $input) {
      ${COMMON_KEYS}
    }
  }
`;
