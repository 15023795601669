import styled from '@emotion/styled';

import Text from 'components/text/Text';
import preventDefaultEvent from 'utils/preventDefaultEvent';

import Input from './Input';

interface Props {
  isEditing: boolean;
  onBlur?: () => void;
  onUpdate: (value: string) => void;
  placeholder?: string;
  value?: string;
}

export const StyledText = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
`;

export default function HeaderInput({
  isEditing,
  onBlur,
  onUpdate,
  placeholder = 'Title..',
  value = '',
}: Readonly<Props>) {
  return (
    <>
      {isEditing ? (
        <Input
          placeholder={placeholder}
          initialValue={value}
          updateValue={onUpdate}
          onClick={preventDefaultEvent}
          onBlur={onBlur}
        />
      ) : (
        <Text variant="listItemLabel" color="highEmphasis">
          {value}
        </Text>
      )}
    </>
  );
}
