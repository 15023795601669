import { ApolloCache } from '@apollo/client';

import { MdfType } from 'types/graphqlTypes';

import { GET_MDFS, GetMdfs } from './useGetMdfs';

export const removeFromMdfCache = (client: ApolloCache<unknown>, id: string) => {
  const cachedMdfs = client.readQuery<GetMdfs>({
    query: GET_MDFS,
    variables: {
      input: { all: true },
    },
  });
  if (cachedMdfs?.getMdfs) {
    const updated = [...cachedMdfs.getMdfs.filter((f) => f.id !== id)];
    client.writeQuery({
      query: GET_MDFS,
      variables: {
        input: { all: true },
      },
      data: {
        getMdfs: updated,
      },
    });
  }
};

export const writeMdfToCache = (client: ApolloCache<unknown>, newMdf: MdfType) => {
  const cachedMdfs = client.readQuery<GetMdfs>({
    query: GET_MDFS,
    variables: {
      input: { all: true },
    },
  });
  const allMdfs = [newMdf, ...(cachedMdfs?.getMdfs ?? [])];
  client.writeQuery({
    query: GET_MDFS,
    variables: {
      input: { all: true },
    },
    data: {
      getMdfs: allMdfs,
    },
  });
};
