/* eslint-disable max-len */
import { useEffect } from 'react';
import { atom, useAtom } from 'jotai';
import { atomWithHash } from 'jotai-location';
import useDinaNavigate from 'hooks/useDinaNavigate';

import feedsTickerImage from 'assets/images/tours/mainTour/Ticker.jpg';
import createButtonImage from 'assets/images/tours/mainTour/CreateStoryOrPitch.jpg';
import dinaMobileImage from 'assets/images/tours/mainTour/DInaMobileSetup.jpg';
import navBarImage from 'assets/images/tours/mainTour/Modes.jpg';
import leftPanelImage from 'assets/images/tours/mainTour/LeftSidePanel.jpg';
import rightPanelImage from 'assets/images/tours/mainTour/RightSidePanel.jpg';

export const tours = {
  MainTour: {
    id: 'MainTour',
    title: 'First time use tour',
    description:
      'The quick guide for new users, highlighting the important areas of Dina to quickly get started',
    steps: {
      CreateButton: {
        id: 'CreateButton',
        title: 'Click on the + icon to get started',
        text: 'Create a Story or a Pitch to start producing content',
        imageUrl: createButtonImage,
      },
      Home: {
        id: 'Home',
        title: 'The Navigation bar',
        text: 'Check your news feeds, view the StoryHub for scheduling or see Stories by location in the maps view. Here you will also find your opened Stories, pitches and rundowns',
        imageUrl: navBarImage,
      },
      FeedsTicker: {
        id: 'FeedsTicker',
        title: 'Feeds Ticker',
        text: 'Activate the Feeds ticker to receive all the latest news updates regardless of where you are in Dina.',
        imageUrl: feedsTickerImage,
      },
      Mobile: {
        id: 'Mobile',
        title: 'Set up Dina Mobile',
        text: 'Click the Profile picture to access User Settings and configure the Dina Mobile App',
        imageUrl: dinaMobileImage,
      },
      RightPanel: {
        id: 'RightPanel',
        title: 'Right side panel: All assets',
        text: 'The right side menu is where you can find all your assets such as media and graphics. This is also where you can manage all contacts, teams and departments.',
        imageUrl: rightPanelImage,
      },
      LeftPanel: {
        id: 'LeftPanel',
        title: 'Left side panel: All content',
        text: 'The left side menu is for finding your content such as Stories, Pitches, Instances and Rundowns.',
        imageUrl: leftPanelImage,
      },
    },
  },
  StoryHubTour: {
    id: 'StoryHubTour',
    title: 'The StoryHub tour',
    description: 'Explore StoryHub and its features for planning your stories',
    steps: {
      ScheduleButton: {
        id: 'ScheduleButton',
        title: 'Toggle view',
        text: 'View unscheduled stories, or all scheduled stories for the chosen planning view',
      },
      NavigationTabs: {
        id: 'NavigationTabs',
        title: 'Various planning views',
        text: 'Check out the board and various calendar views to plan your stories',
      },
      MyItemsOnly: {
        id: 'MyItemsOnly',
        title: 'A personalized overview',
        text: 'Show only items assigned or created by you',
      },
      FilterBar: {
        id: 'FilterBar',
        title: 'Filter options',
        text: 'Find various ways to filter the planning view to your needs',
      },
    },
  },
  LeftTabTour: {
    id: 'LeftTabTour',
    title: 'The left side panel tour',
    description:
      'Explore how to use the left side panel, the place where you can quickly find all your relevant content',
    steps: {
      Bookmarks: {
        id: 'Bookmarks',
        title: 'Your bookmarks',
        text: 'Create and find your bookmarks here, allowing for customized quick access to any content',
      },
      'My Stories': {
        id: 'My stories',
        title: 'Your stories',
        text: 'Find all your assigned and created stories here',
      },
      'My Teams': {
        id: 'My Teams',
        title: 'Your teams',
        text: 'Find all the teams you are part of here',
      },
      'My Departments': {
        id: 'My Departments',
        title: 'Your departments',
        text: 'Find all the departments you are part of here',
      },
      'All Stories': {
        id: 'All Stories',
        title: 'All stories',
        text: 'Browse all stories within your organisation here',
      },
      'All Pitches': {
        id: 'All Pitches',
        title: 'All pitches',
        text: 'Browse all pitches within your organisation here',
      },
      'All Instances': {
        id: 'All Instances',
        title: 'All instances',
        text: 'Browse all instances within your organisation here. Instances are editions of stories, and one story may contain many instances',
      },
      Rundowns: {
        id: 'Rundowns',
        title: 'Rundowns',
        text: 'Browse all rundowns within your organisation here',
      },
    },
  },
  RightTabTour: {
    id: 'RightTabTour',
    title: 'The right side panel tour',
    description:
      'Explore how to use the right side panel, the place where you can quickly find assets to include in stories, pitches, instances and rundowns',
    steps: {
      Graphics: {
        id: 'Graphics',
        title: 'Find Graphics',
        text: 'Find all your graphic content here',
      },
      Assets: {
        id: 'Assets',
        title: 'Find media content',
        text: 'Find all your media content for your stories here',
      },
      Contacts: {
        id: 'Contacts',
        title: 'All your contacts',
        text: 'Browse or create/modify contacts within your organization',
      },
      Teams: {
        id: 'Teams',
        title: 'All teams',
        text: 'Browse or create/modify existing teams within your organization',
      },
      Departments: {
        id: 'Departments',
        title: 'All Departments',
        text: 'Browse or create/modify existing departments within your organization',
      },
    },
  },
};

/**
 * If navigation / prep is needed for a tour, handle that here.
 *
 * Ideally we'd hook into the atomWithHash `activeTour` but unfortunately it
 * does not seem to support listening to `set()` combined with hash.
 *
 * TODO: Once Router implementation is in, replace this mechanism with a useNavigation() strategy instead.
 */
export const useSideEffectTour = () => {
  const { navigateTo } = useDinaNavigate();
  const [active] = useSetActiveTour();
  useEffect(() => {
    if (active === tours.StoryHubTour.id) {
      navigateTo('plans');
    }
  }, [active, navigateTo]);
};

/**
 * Used to keep track of which tour is active, and where the user is
 * within the active tour
 */
const activeTourCurrent = atom(1);
const activeTour = atomWithHash('tour', '');
const useActiveTourSetCurrent = atom(
  (get) => get(activeTourCurrent),
  (get, set, val) => {
    if (val === 'NEXT') {
      set(activeTourCurrent, get(activeTourCurrent) + 1);
    } else if (val === 'PREVIOUS') {
      set(activeTourCurrent, get(activeTourCurrent) - 1);
    } else if (val === 'RESET') {
      set(activeTourCurrent, 1);
    }
  },
);

export const useSetActiveTour = () => useAtom(activeTour);
export const useActiveTour = () => useAtom(useActiveTourSetCurrent);
