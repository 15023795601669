import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
// eslint-disable-next-line import/no-extraneous-dependencies
import isEqual from 'lodash/isEqual';

import { useSeenHelp, useUserConfig } from 'store';
import GET_USER_DATA, { USR_DATA_KEYS } from 'operations/queries/getUserData';

/**
 *  Hook for accessing user data and passing it to the seen hook.
 *  It receives the mId of the logged in user.
 */
const useUserData = (mId) => {
  const [, setSeenHelp] = useSeenHelp();
  const [config, setUserConfig] = useUserConfig();

  const { data, error } = useQuery(GET_USER_DATA, {
    variables: {
      input: {
        mId,
        ...USR_DATA_KEYS,
      },
    },
  });

  useEffect(() => {
    if (data) {
      const userSeenData = {
        mId,
        mSeen: data.getMember?.mSeen ?? [],
        ...USR_DATA_KEYS,
      };
      setSeenHelp(userSeenData);

      const { mId: configMid, mMetaData: configMMetaData, ...restOfUserConfig } = config || {};

      const newConfigData = {
        mId,
        ...USR_DATA_KEYS,
        mMetaData: data.getMember?.mMetaData ?? [],
        ...restOfUserConfig,
      };

      if (!isEqual(config, newConfigData)) {
        setUserConfig(newConfigData);
      }
    }
  }, [data, setSeenHelp, mId, config, setUserConfig]);

  return [error];
};

export default useUserData;
