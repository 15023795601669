import { useCallback, useState } from 'react';
import { FetchPolicy, useApolloClient, useMutation } from '@apollo/client';

import useToast from 'components/toast/useToast';
import memberTypes from 'operations/memberTypes';
import { useUserConfig } from 'store';
import { GetMemberInput, MemberType, MemberTypeEnum } from 'types/graphqlTypes';

import { GridBoardType, useGridMolecule } from '../store';

import { CREATE_GRID, GET_GRID, SUBSCRIBE_TO_GRID } from './graphql';
import useBatchGetItems from './useBatchGetItems';

type CreateGridInputType = {
  input: {
    mId: string;
    mType: MemberTypeEnum.Group;
  };
};

type CreateGridReturnType = {
  createMember: MemberType;
};

type GetMemberReturnType = {
  getMembersOf: GridBoardType[];
};

type GetMemberInputType = {
  input: GetMemberInput;
};

type BoardSubscriptionType = {
  data: {
    notifyMemberUpdateSubscription: GridBoardType | MemberType;
  };
};

interface Props {
  mId: string;
  fetchPolicy?: FetchPolicy;
}

export interface GridType {
  [key: string]: MemberType;
}

export interface SubscriptionType {
  unsubscribe: () => void;
}

/** Get grid and members
 * @returns getGrid function, start subscription function and loading boolean
 */
const useGetGrid = () => {
  const [loading, setLoading] = useState<boolean>(true);

  const [user] = useUserConfig();
  const client = useApolloClient();
  const { errorToast } = useToast();

  // API Calls
  const [getMembers] = useBatchGetItems();
  const [createGrid] = useMutation<CreateGridReturnType, CreateGridInputType>(CREATE_GRID);

  // Grid store
  const { useGridBoard, useGridMembers } = useGridMolecule();
  const [, setGridBoard] = useGridBoard();
  const [, setGridMembers] = useGridMembers();

  // ─── Load Members ────────────────────────────────────────────────────
  const loadGridMembers = useCallback(
    async (board: GridBoardType) => {
      const boardMembers = board?.mOrder ?? [];

      if (boardMembers.length === 0) return;
      const loadedMembers = await getMembers({ mIds: boardMembers });

      // Put the members into the store
      const members: GridType = {};
      loadedMembers.forEach((member) => {
        if (!member?.mRefId) return;
        members[member.mRefId] = member;
      });

      setGridMembers(members);
    },
    [getMembers],
  );

  // ─── Update Board With Subscribed Data ───────────────────────────────
  const updateBoardWithSubscribedData = useCallback(
    (newBoard: GridBoardType) => {
      if (!newBoard) return;

      setGridBoard(newBoard);
      loadGridMembers(newBoard).catch((error: unknown) => errorToast(error));
    },
    [client, setGridBoard, loadGridMembers],
  );

  // ─── Start Subscription ──────────────────────────────────────────────
  const startSubscription = useCallback(
    (mId: string): [SubscriptionType] => {
      const observer = client.subscribe({
        query: SUBSCRIBE_TO_GRID,
        variables: {
          mIdSubscribed: mId,
        },
      });

      const subscription = observer.subscribe({
        next: (data: BoardSubscriptionType) => {
          const updatedMember = data?.data?.notifyMemberUpdateSubscription;
          if (!updatedMember) return;

          if (updatedMember.mType === memberTypes.SPACE_GROUP) {
            // prevent update if the user is the one who updated the board
            if (updatedMember?.mUpdatedById === user?.mId) return;
            updateBoardWithSubscribedData(updatedMember as GridBoardType);
          } else {
            setGridMembers((prevState) => {
              const newMembers = prevState ?? {};
              newMembers[updatedMember.mId as string] = updatedMember as MemberType;
              return { ...newMembers };
            });
          }
        },
      });

      return [subscription];
    },
    [client, updateBoardWithSubscribedData],
  );

  // ─── Create a Grid Board ─────────────────────────────────────────────
  const createGridBoard = useCallback(
    async (mId: string) => {
      const result = await createGrid({
        variables: {
          input: {
            mId,
            mType: MemberTypeEnum.Group,
          },
        },
        fetchPolicy: 'network-only',
      });

      return result?.data?.createMember as GridBoardType;
    },
    [createGrid],
  );

  // ─── Load The Board ──────────────────────────────────────────────────
  const loadGrid = useCallback(
    async (mId: string, fetchPolicy?: FetchPolicy) => {
      const result = await client.query<GetMemberReturnType, GetMemberInputType>({
        query: GET_GRID,
        variables: {
          input: {
            mId,
            membersOfType: MemberTypeEnum.Group,
          },
        },
        fetchPolicy,
      });

      const board = result?.data?.getMembersOf;
      // Return the board if it exists
      if (board?.length > 0) return board[0];

      // If not, create one
      const newBoard = await createGridBoard(mId);
      return newBoard;
    },
    [client],
  );

  // ─── Get Grid Board and its Members ──────────────────────────────────
  const getGrid = useCallback(
    async ({ mId, fetchPolicy }: Props) => {
      if (!mId) return;
      setLoading(true);

      // Load board
      const board: GridBoardType = await loadGrid(mId, fetchPolicy);
      setGridBoard(board);

      // Load members
      await loadGridMembers(board).finally(() => setLoading(false));
    },
    [loadGridMembers, loadGrid],
  );

  return { getGrid, loading, startSubscription };
};

export default useGetGrid;
