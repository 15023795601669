import { mediaTypes } from './rundownItemTypes';

const DndTypes = {
  INSTANCE: 'instance',
  MEMBER: 'member',
  STORY: 'story',
  CONTACT: 'contact',
  PITCH: 'pitch',
  GROUP: 'group',
  STORY_INSTANCE: 'story-instance',
  STORY_NOTE: 'story-note',
  GRID_INSTANCE: 'grid-instance',
  ASSET: 'asset',
  INSTANCE_LIST_ITEM: 'instance_list_item',
  EDITOR_BLOCK: 'editor-block',
  PHOTO_GALLERY_ITEM: 'photo_gallery_item',
  CONTENT_TAB: 'content_tab',
  FEED_ITEM: 'feed_item',
  ...mediaTypes,
};

export default DndTypes;
