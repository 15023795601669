import { css } from '@emotion/react';
import styled from '@emotion/styled';
import transientOptions from 'theme/helpers';

export const Image = styled('img')`
  border: 1px solid ${({ theme }) => theme.palette.dina.buttonBorderOutlined};
  border-radius: 4px;
  min-height: 66px;
  min-width: 66px;
  object-fit: cover;
  position: relative;
  flex-shrink: 0;
`;

export const PlayIconWrapper = styled('div', transientOptions)`
  position: absolute;
  width: 66px;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ $isClickable, theme }) =>
    $isClickable
      ? css`
          cursor: pointer;
          :hover {
            filter: ${theme.palette.dina.onHover};
            background-color: ${theme.palette.dina.whiteHoverOverlay};
          }
        `
      : css`
          pointer-events: none;
        `}
`;
