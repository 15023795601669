import { useCallback, useState } from 'react';

import useUpdateUser from 'api/userManagement/useUpdateUser';
import Checkbox from 'components/checkbox/Checkbox';
import Dialog from 'components/dialogs/DialogBuilder';
import Divider from 'components/divider';
import Text from 'components/text/Text';
import useToast from 'components/toast/useToast';
import Tooltip from 'components/tooltip';
import { HStack } from 'layouts/box/Box';

import { BodyWrapper, CheckBoxLabel, GroupsTitle, GroupsWrapper } from './styled';

interface GroupProps {
  userAssignedGroups: string;
  groupsArray: Record<string, string>[];
  mId: string;
  mRefId: string;
  username: string;
}

const Groups = ({ userAssignedGroups, groupsArray, mId, mRefId, username }: GroupProps) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [assignedGroups, setAssignedGroups] = useState(
    userAssignedGroups !== '' ? userAssignedGroups.split(',') : [],
  );
  const [newGroups, setNewGroups] = useState<string[]>([]);
  const [removedGroups, setRemovedGroups] = useState<string[]>([]);
  const { errorToast } = useToast();
  const [updateUser, loading] = useUpdateUser();

  const handleOnClick = (item: string) => {
    setAssignedGroups((prevAssignedGroups) => {
      const updatedAssignedGroups = [...prevAssignedGroups];
      const isCurrentlySelected = updatedAssignedGroups.includes(item);

      if (isCurrentlySelected) {
        setRemovedGroups((prevRemovedGroups) => [...prevRemovedGroups, item]);
        updatedAssignedGroups.splice(updatedAssignedGroups.indexOf(item), 1);
      } else {
        setNewGroups((prevNewGroups) => [...prevNewGroups, item]);
        updatedAssignedGroups.push(item);
      }
      const allGroupsSelected = updatedAssignedGroups.length === groupsArray.length;
      setSelectAll(allGroupsSelected);
      return updatedAssignedGroups;
    });
  };

  const handleSelectAll = () => {
    setSelectAll((prevSelectAll) => !prevSelectAll);
    if (!selectAll) {
      const newlyAddedGroups = groupsArray.filter((group) => !assignedGroups.includes(group.value));
      setAssignedGroups(groupsArray.map((group) => group.value));
      setNewGroups(newlyAddedGroups.map((group) => group.value));
      setRemovedGroups([]);
    } else {
      setAssignedGroups([]);
      setNewGroups([]);
      setRemovedGroups(assignedGroups);
    }
  };

  const handleSave = useCallback(async () => {
    const params = {
      mId,
      mRefId,
      username,
      groups: assignedGroups,
      newGroups: newGroups,
      removedGroups: removedGroups,
      errorToast,
      setOpenDialog,
    };
    await updateUser(params);
  }, [newGroups, removedGroups, assignedGroups]);

  const handleCancel = useCallback(() => {
    setOpenDialog(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpenDialog(true);
    setNewGroups([]);
    setRemovedGroups([]);
    const initialAssignedGroups = userAssignedGroups !== '' ? userAssignedGroups.split(',') : [];
    setAssignedGroups(initialAssignedGroups);
    const allGroupsSelected = initialAssignedGroups.length === groupsArray.length;
    setSelectAll(allGroupsSelected);
  }, [userAssignedGroups, groupsArray]);

  const getTitle = (value: string, groups: Record<string, string>[]): string => {
    const group = groups.find((item) => item.value === value);
    return group ? group.title : value;
  };

  const mappedValues = useCallback(
    (values: string[]): string => {
      return values.map((value) => getTitle(value, groupsArray)).join(', ');
    },
    [userAssignedGroups, groupsArray],
  );

  const isDisable = (!newGroups.length && !removedGroups.length) || !assignedGroups.length;

  return (
    <>
      <Tooltip title={mappedValues(userAssignedGroups.split(','))}>
        <GroupsWrapper onClick={handleOpen}>
          {userAssignedGroups !== '' ? (
            <GroupsTitle>{mappedValues(userAssignedGroups.split(','))}</GroupsTitle>
          ) : (
            <Text variant="listItemLabel" color="statusWarning">
              Unassigned
            </Text>
          )}
        </GroupsWrapper>
      </Tooltip>

      <Dialog open={openDialog} onClose={handleCancel}>
        <Dialog.Header>Assign to groups</Dialog.Header>
        <Dialog.Body>
          <HStack>
            <Checkbox value="all" selected={selectAll} onClick={handleSelectAll} />
            <CheckBoxLabel variant="listItemLabel" color="highEmphasis" onClick={handleSelectAll}>
              Select All
            </CheckBoxLabel>
          </HStack>
          <Divider />
          <BodyWrapper>
            {groupsArray.map((item) => {
              return (
                <HStack key={item.value}>
                  <Checkbox
                    value={item.value}
                    selected={assignedGroups.includes(item.value)}
                    onClick={() => handleOnClick(item.value)}
                  />
                  <CheckBoxLabel
                    onClick={() => handleOnClick(item.value)}
                    variant="listItemLabel"
                    color="highEmphasis"
                  >
                    {item.title}
                  </CheckBoxLabel>
                </HStack>
              );
            })}
          </BodyWrapper>
        </Dialog.Body>
        <Dialog.Footer>
          <Dialog.CancelButton onCancel={handleCancel} />
          <Dialog.ConfirmButton onConfirm={handleSave} disabled={isDisable} loading={loading} />
        </Dialog.Footer>
      </Dialog>
    </>
  );
};

export default Groups;
