import { ApolloCache } from '@apollo/client';
// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag';

import { getStoryInstancesQuery } from 'operations/queryVariables';
import { Instance } from 'types';

const GET_INSTANCES = gql`
  query getStoryInstances($input: GetMembersOfTypeBySecIdInput) {
    getMembersOfTypeBySecId(input: $input) {
      mId
      mRefId
    }
  }
`;

const updateStoryInstanceCache = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  proxy: ApolloCache<any>,
  storyId: string,
  newInstance: Instance,
) => {
  const mutationResult: {
    getMembersOfTypeBySecId: Instance[];
  } | null = proxy.readQuery({
    query: GET_INSTANCES,
    variables: getStoryInstancesQuery(storyId),
  });

  // Check if story is already in the cache
  const instancesList = mutationResult?.getMembersOfTypeBySecId;
  const list = instancesList
    ? instancesList.filter(
        (storyInstance) => storyInstance !== null && storyInstance.mId === newInstance.mId,
      )
    : [];
  // Add new story to the storylist if it is not the cache
  if (list.length === 0) {
    proxy.writeQuery({
      query: GET_INSTANCES,
      variables: getStoryInstancesQuery(storyId),
      data: {
        getMembersOfTypeBySecId: instancesList ? [...instancesList, newInstance] : [newInstance],
      },
    });
  }
};

export default updateStoryInstanceCache;
