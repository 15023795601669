import { useState } from 'react';

import RundownDetails from './details';
import RundownList from './list';

import useStyles from './rundown-styles';

export default function RundownSidebar() {
  const [previewRundown, setPreviewRundown] = useState(null);
  const [showMasterRundown, setShowMasterRundown] = useState(false);

  const classes = useStyles();

  return (
    <div className={`${classes.root} ${previewRundown ? classes.details : ''}`}>
      {previewRundown === null ? (
        <RundownList
          setPreviewRundown={setPreviewRundown}
          showMasterRundown={showMasterRundown}
          setShowMasterRundown={setShowMasterRundown}
        />
      ) : (
        <RundownDetails previewRundown={previewRundown} setPreviewRundown={setPreviewRundown} />
      )}
    </div>
  );
}
