import styled from '@emotion/styled';

export const StyledWrapper = styled('div')<{ $isOver: boolean }>`
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid;
  border-color: ${({ $isOver }) => ($isOver ? 'orange' : 'transparent')};
  border-radius: 4px;
`;
