import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.dina.surfaceCard,
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    height: '56px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 16px',
    flexShrink: 0,
    cursor: 'move',
  },
  titleWrapper: {
    width: 'calc(100% - 62px)',
  },
  mode: {
    ...theme.typography.dina.overline,
    marginBottom: '5px',
  },
  title: {
    ...theme.typography.dina.h6,
    lineHeight: '24px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  icon: {
    '&:hover': {
      '& path': {
        fill: theme.palette.dina.highEmphasis,
        fillOpacity: 1,
        transition: '0.3s ease',
      },
    },
  },
  footer: {
    width: '100%',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '8px',
    flexShrink: 0,
  },
  button: {
    ...theme.typography.dina.button,
    color: theme.palette.dina.highEmphasis,
    backgroundColor: theme.palette.dina.buttonBackgroundHighEmphasis,
    width: '192px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': {
      '-webkit-filter': theme.palette.dina.onHover,
    },
  },
  buttonWarning: {
    ...theme.typography.dina.button,
    color: theme.palette.dina.blackHighEmphasis,
    backgroundColor: theme.palette.dina.statusWarning,
    width: '192px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': {
      '-webkit-filter': theme.palette.dina.onHover,
    },
  },
  warningDialog: {
    minWidth: '500px',
    fontSize: '24px',
    color: 'white',
  },
}));

export default useStyles;
