import { useContext, useEffect, useMemo, useRef } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';

import UserContext from 'contexts/UserContext';
import { useNotesMolecule } from 'features/notes/store';
import useSyncedRef from 'hooks/useSyncedRef';
import { EditorValue, Note } from 'types';
import { getScopeFromLockedId, getUserIdFromLockedId } from 'utils/lock/lockTokenV2';

import { useNoteMolecule } from '../store';

export interface BeforeUnloadProps {
  lastNote: Note | null;
  lastContent: EditorValue | null;
  isCurrentlyEditing: boolean;
  wasLoading: boolean;
  unlockFn: (args_0: {
    content: EditorValue | null;
    cancelled: boolean;
    source: string;
  }) => Promise<string | null | undefined>;
}

/**
 * Custom hook that handles the logic for unlocking a note before the view unloads.
 *
 * This hook sets up an event listener for the `beforeunload` event to ensure that
 * the note's content is saved and the note is unlocked if it was being edited by the current user.
 *
 * The hook uses several atoms and context values to manage
 * the state of the note and its editing status.
 *
 * @returns {void}
 *
 * @example
 * ```typescript
 * const NoteComponent = () => {
 *   useNoteBeforeUnload();
 *   return <div>Note Component</div>;
 * };
 * ```
 */
const useNoteBeforeUnload = (): void => {
  const { useScopeViewRefValue, currentEditingScope } = useNotesMolecule();
  const { baseAtom, unlockNoteAtom, editorValueRef, saveTimeoutRef } = useNoteMolecule();
  const { mId: currentUserId } = useContext(UserContext);
  const scopeViewRef = useScopeViewRefValue();

  const { note, isLoading, lockedBy } = useAtomValue(baseAtom);
  const unlockNote = useSetAtom(unlockNoteAtom);

  const { isSameScope, lockedUserId } = useMemo(
    () => ({
      isSameScope: currentEditingScope === getScopeFromLockedId(lockedBy),
      lockedUserId: getUserIdFromLockedId(lockedBy),
    }),
    [lockedBy, currentEditingScope],
  );

  const unlockNoteRef = useSyncedRef(unlockNote);
  const currentNoteRef = useSyncedRef(note);
  const isLoadingRef = useSyncedRef(isLoading);
  const isSameScopeRef = useSyncedRef(isSameScope);
  const lockedUserIdRef = useSyncedRef(lockedUserId);

  /** before unload fn ref */
  const beforeUnloadFnRef = useRef<((props: BeforeUnloadProps) => Promise<void>) | null>(null);

  /** saves content when content view unmounts */
  beforeUnloadFnRef.current = async (props: BeforeUnloadProps) => {
    const { lastNote, lastContent, isCurrentlyEditing, unlockFn, wasLoading } = props;

    const { locked } = lastNote ?? {};
    const lockedByMe = getUserIdFromLockedId(locked ?? null) === currentUserId;

    if (locked && lockedByMe && !wasLoading && isCurrentlyEditing) {
      await unlockFn({
        content: lastContent,
        cancelled: false,
        source: 'useNoteBeforeUnload:beforeUnloadFn',
      });
      /** clear debounce */
      if (saveTimeoutRef.current) {
        clearTimeout(saveTimeoutRef.current);
        saveTimeoutRef.current = null;
      }
    }
  };

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      beforeUnloadFnRef
        .current?.({
          lastNote: currentNoteRef.current,
          lastContent: editorValueRef.current,
          isCurrentlyEditing: isSameScopeRef.current && lockedUserIdRef.current === currentUserId,
          unlockFn: unlockNoteRef.current,
          wasLoading: isLoadingRef.current,
        })
        ?.catch(() => {});
    });

    return () => {
      beforeUnloadFnRef
        .current?.({
          lastNote: currentNoteRef.current,
          lastContent: editorValueRef.current,
          isCurrentlyEditing: isSameScopeRef.current && lockedUserIdRef.current === currentUserId,
          unlockFn: unlockNoteRef.current,
          wasLoading: isLoadingRef.current,
        })
        ?.catch(() => {});

      window.removeEventListener('beforeunload', () => {
        beforeUnloadFnRef
          .current?.({
            // eslint-disable-next-line react-hooks/exhaustive-deps
            lastNote: currentNoteRef.current,
            // eslint-disable-next-line react-hooks/exhaustive-deps
            lastContent: editorValueRef.current,
            // eslint-disable-next-line react-hooks/exhaustive-deps
            isCurrentlyEditing: isSameScopeRef.current && lockedUserIdRef.current === currentUserId,
            // eslint-disable-next-line react-hooks/exhaustive-deps
            unlockFn: unlockNoteRef.current,
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wasLoading: isLoadingRef.current,
          })
          ?.catch(() => {});
      });
    };
  }, [
    currentNoteRef,
    currentUserId,
    editorValueRef,
    isLoadingRef,
    isSameScopeRef,
    lockedUserIdRef,
    note?.mRefId,
    scopeViewRef,
    unlockNoteRef,
  ]);
};

export default useNoteBeforeUnload;
