const columnsFields = [
  {
    field: 'checkbox',
    headerName: '',
    sortable: false,
  },
  {
    field: 'name',
    headerName: 'Default Name',
    sortable: true,
    searchField: 'name',
  },
  {
    field: 'label',
    headerName: 'label',
    sortable: true,
  },
  {
    field: 'displayName',
    headerName: 'Display Name',
    sortable: true,
  },
];

export default columnsFields;
