export const isProductionMode = import.meta.env.MODE === 'production';

export const emptySignedCookiePromise = new Promise((resolve) => {
  resolve({
    loading: false,
    data: {
      getSignedCookie: {
        cookies: [],
      },
    },
  });
});
