import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Scrollbar from 'components/scrollbar/scrollbar';

import SettingsContent from '../settingsTabs/systemSettings/details/settingsContent/settingsContent-view';
import RootWrapper from './styled';

function SettingsContentForm({
  categories,
  selectedMenu,
  onChangeSettings,
  changedSettings,
  getValue,
}) {
  const category = categories?.find(({ title }) => title === selectedMenu);
  const { t } = useTranslation();

  const getChildComponent = (property, parentValue) => {
    const {
      label,
      type,
      placeholder,
      description,
      options,
      isBetaLabel,
      parentKey,
      defaultValue,
      fallbackProperty,
    } = category?.properties?.[property];
    const changedSetting = changedSettings.find((x) => x.key === parentKey);
    const showChildDiv = changedSetting ? changedSetting.value : parentValue;
    return showChildDiv ? (
      <SettingsContent
        key={property}
        label={label}
        type={type}
        placeholder={placeholder}
        description={description}
        options={options}
        contentKey={property}
        value={getValue(property, type, fallbackProperty, defaultValue)}
        onChange={onChangeSettings}
        changedSettings={changedSettings}
        isBetaLabel={isBetaLabel}
      />
    ) : null;
  };

  return (
    <RootWrapper>
      <Scrollbar>
        {category?.properties &&
          Object.keys(category?.properties).map((property) => {
            const {
              label,
              type,
              placeholder,
              description,
              options,
              visible,
              isBetaLabel,
              childKeys,
              defaultValue,
              fallbackProperty,
            } = category?.properties?.[property];
            return visible ? (
              <div key={property}>
                <SettingsContent
                  label={t(`settingsMenu.${property}`, label)}
                  type={type}
                  placeholder={placeholder}
                  description={description}
                  options={options}
                  contentKey={property}
                  value={getValue(property, type, fallbackProperty, defaultValue)}
                  onChange={onChangeSettings}
                  changedSettings={changedSettings}
                  isBetaLabel={isBetaLabel}
                  childKeys={childKeys}
                />
                {childKeys?.map((childKey) =>
                  childKey
                    ? getChildComponent(childKey, (property, type, fallbackProperty, defaultValue))
                    : null,
                )}
              </div>
            ) : null;
          })}
      </Scrollbar>
    </RootWrapper>
  );
}

SettingsContentForm.propTypes = {
  categories: PropTypes.array,
  selectedMenu: PropTypes.string,
  changedSettings: PropTypes.array,
  getValue: PropTypes.func,
  onChangeSettings: PropTypes.func,
};

SettingsContentForm.defaultProps = {
  categories: [],
  selectedMenu: '',
  changedSettings: [],
  getValue: () => {},
  onChangeSettings: [],
};

export default SettingsContentForm;
