import { useCallback, useRef } from 'react';
import { BaseRange, BaseSelection, Transforms } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';

import { createBlockInput } from 'api/mdfBlocks/types';
import { useCreateBlock } from 'api/mdfBlocks/useCreateMdfBlock';
import { elementTypes } from 'components/editor/constants';
import useToast from 'components/toast/useToast';
import { ResourceDetails, supportedResourceMTypes } from 'hooks/useResourceDetails';
import { useOrderFormDialog } from 'store';
import { ResourceType } from 'types/forms/forms';
import { EditorCommandConfigType } from 'types/memberTypes/editorCommands';

const useInsertBlock = (search: string, resourceDetails: ResourceDetails | undefined) => {
  const editor = useSlate();
  const selectionRef = useRef<BaseSelection | null>(null);
  const [, setOrderForm] = useOrderFormDialog();
  const { createBlock } = useCreateBlock();
  const { errorToast } = useToast();

  const resetEditorSelection = useCallback(() => {
    if (selectionRef.current) {
      ReactEditor.focus(editor as ReactEditor);
      Transforms.select(editor, selectionRef.current);
      selectionRef.current = null;
    }
  }, [editor]);

  const selectWithSearchText = useCallback(
    (targetNode: BaseRange) => {
      const { anchor, focus } = targetNode;
      Transforms.select(editor, {
        anchor: { ...anchor, offset: focus.offset - search.length },
        focus,
      });
    },
    [editor, search],
  );

  const insertOrderBlock = useCallback(
    (command: EditorCommandConfigType, targetNode: BaseRange) => {
      if (resourceDetails?.resource) {
        selectionRef.current = editor.selection;
        ReactEditor.deselect(editor as ReactEditor);
        ReactEditor.blur(editor as ReactEditor);

        const { resource, orderFormMap } = resourceDetails;
        if (!supportedResourceMTypes.includes(resource.mType)) return;

        setOrderForm({
          open: true,
          mFormId: command.mTertId,
          mdfId: command.mSecId,
          resourceId: resource.mId,
          resourceType: resource.mType as ResourceType,
          onConfirm: (order) => {
            resetEditorSelection();
            const orderBlock = {
              type: elementTypes.ORDER_BLOCK,
              data: {
                mId: order.mId,
                mResourceId: order.mResourceId,
                mFormId: order.mFormId,
                mStatus: order.mStatus,
                mCreatedAt: order.mCreatedAt,
                taskType: orderFormMap?.[command.mTertId]?.mDescription,
              },
              children: [{ text: '' }],
            };
            selectWithSearchText(targetNode);
            Transforms.insertNodes(editor, orderBlock);
          },
          onCancel: () => {
            resetEditorSelection();
          },
        });
      }
    },
    [editor, resourceDetails, resetEditorSelection, selectWithSearchText],
  );

  const insertMdfBlock = useCallback(
    (command: EditorCommandConfigType, targetNode: BaseRange) => {
      if (resourceDetails?.resource) {
        const { resource } = resourceDetails;
        const input = createBlockInput(resource, command);
        createBlock(input)
          .then((block) => {
            const mdfBlock = {
              type: elementTypes.MDF_BLOCK,
              data: {
                mId: block?.mRefId,
                mResourceId: block?.mId,
                mFormId: command.mRefId,
                mdfId: block?.mdfId,
                mCreatedAt: block?.mCreatedAt,
              },
              children: [{ text: '' }],
            };
            selectWithSearchText(targetNode);
            Transforms.insertNodes(editor, mdfBlock);
            ReactEditor.focus(editor);
          })
          .catch(errorToast);
      }
    },
    [editor, resourceDetails, createBlock, selectWithSearchText],
  );

  return { insertMdfBlock, insertOrderBlock };
};

export default useInsertBlock;
