import gql from 'graphql-tag';

export default gql`
  query GetUnreadConversations($input: ConversationsOfUserInput) {
    getUnreadConversations(input: $input) {
      mTitle
      mId
      mRefId
      mType
      convoType
      mCreatedAt
      mUpdatedAt
      mUpdatedById
      mCreatedById
      mAssignedMembers {
        mId
        mType
      }
      mIdSubscribed
      message
    }
  }
`;
