import { type GroupOption, type Option } from '../types';

export const transToGroupOption = (options: Option[], groupBy?: string) => {
  if (options.length === 0) return {};
  if (!groupBy) return { '': options };

  const groupOption: GroupOption = {};
  options.forEach((option) => {
    const key = (option[groupBy] as string) || '';
    if (!groupOption[key]) {
      groupOption[key] = [];
    }
    groupOption[key].push(option);
  });

  return groupOption;
};

export const isOptionsExist = (groupOption: GroupOption, targetOption: Option[]) => {
  for (const [, value] of Object.entries(groupOption)) {
    if (value.some((option) => targetOption.find((p) => p.value === option.value))) {
      return true;
    }
  }

  return false;
};
