import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ReactComponent as DragHandle } from 'assets/icons/systemicons/unionDragHandle.svg';

export const StyledFieldWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
`;

export const FieldHeader = styled('div')`
  ${({ theme }) => theme.typography.dina.overline}
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 1px;
  margin: 0;
  user-select: none;
`;

export const StyledDragHandle = styled(DragHandle)`
  cursor: grab;
  :active {
    cursor: grabbing;
    path {
      fill-opacity: 1;
    }
  }
  :hover {
    path {
      fill-opacity: 1;
    }
  }
  position: relative;
  top: 8px;
`;

export const Wrapper = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
  .MuiFormControl-root {
    width: 100%;
  }
  ${({ direction }) =>
    direction === 'horizontal' &&
    css`
      align-items: end;
      gap: 10px;
      flex-direction: row;
    `}
`;

export const Field = styled('span')``;
