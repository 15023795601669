import { capitalize } from 'lodash';

import { UnsavedMdfField } from 'features/mdf/mdf-utils';
import { FieldValue, Metadata } from 'types/forms/forms';
import { FieldTypeEnum, LayoutSettings, Mdf, MdfField } from 'types/graphqlTypes';

export const getDefaultPayload = (mdf: Mdf) => {
  const payload = mdf.fields.reduce((acc: Metadata, curr: MdfField) => {
    const value = curr.defaultValue.value;
    if (value && (!Array.isArray(value) || value.length)) {
      acc[curr.fieldId] = curr.defaultValue.value as FieldValue;
    }
    return acc;
  }, {});
  return payload;
};

export const createNewField = (fieldId: string): UnsavedMdfField => ({
  fieldId,
  type: FieldTypeEnum.text,
  isUnsaved: true,
  existsElseWhere: false,
  defaultValue: {
    value: null,
  },
});

export const getDefaultLayoutSettings = (field: MdfField): LayoutSettings => {
  return {
    fieldId: field.fieldId,
    label: capitalize(field.fieldId),
    hint: '',
    visible: true,
  };
};

export const hasAlternatives = (field: MdfField) =>
  [FieldTypeEnum.multiplechoice, FieldTypeEnum.choice].includes(field.type);

export const normalizeField = (field: MdfField): MdfField => {
  const updatedField = { ...field };
  if (field.type === FieldTypeEnum.choice && !field?.alternatives) {
    updatedField.alternatives = [];
    return updatedField;
  }
  return field;
};

// Tree choice utils
export interface TreeNode {
  value: string;
  isCollapsed: boolean;
  children: TreeNode[];
}

export function createTree(data: string[][]): TreeNode[] {
  const tree: TreeNode[] = [];
  for (const item of data) {
    let currentLevel: TreeNode[] = tree;
    for (const nodeValue of item) {
      let found = false;
      for (const child of currentLevel) {
        if (child.value === nodeValue) {
          currentLevel = child.children;
          found = true;
          break;
        }
      }
      if (!found) {
        const newNode: TreeNode = {
          value: nodeValue,
          isCollapsed: true,
          children: [],
        };
        currentLevel.push(newNode);
        currentLevel = newNode.children;
      }
    }
  }
  return tree;
}

export function sortTree(tree: TreeNode[]): TreeNode[] {
  return tree
    .toSorted((a, b) => a.value.localeCompare(b.value))
    .map((node) => {
      if (node.children.length > 1) {
        node.children.sort((a, b) => a.value.localeCompare(b.value));
        node.children = sortTree(node.children);
      } else if (node.children.length > 0) {
        node.children = sortTree(node.children);
      }
      return node;
    });
}

export function revertTree(tree: TreeNode[]): string[][] {
  const result: string[][] = [];

  function reverseNode(node: TreeNode, parentPath: string[] = []): void {
    const fullPath = [...parentPath, node.value];
    result.push(fullPath);

    for (const child of node.children) {
      reverseNode(child, fullPath);
    }
  }

  for (const node of tree) {
    reverseNode(node);
  }

  return result;
}

export const generateNodeName = (currentLevel: TreeNode[]): string => {
  let counter = currentLevel.length + 1;
  let value = `Value ${counter}`;
  // eslint-disable-next-line @typescript-eslint/no-loop-func
  while (currentLevel.some((node) => node?.value === value)) {
    counter++;
    value = `Value ${counter}`;
  }
  return value;
};

export function addNode(tree: TreeNode[], path: string[]): TreeNode[] {
  let currentNode = tree;
  for (const nodeName of path) {
    const matchingNode = currentNode.find((node) => node.value === nodeName);
    if (matchingNode) {
      matchingNode.isCollapsed = false;
      currentNode = matchingNode.children;
    }
  }

  const newNote = generateNodeName(currentNode);
  currentNode.push({
    value: newNote,
    isCollapsed: true,
    children: [],
  });

  return [...tree];
}

export function removeNode(tree: TreeNode[], path: string[]): TreeNode[] {
  let currentNode = tree;
  const deletePath = path;
  const deleteNode = deletePath.pop();

  for (const nodePath of deletePath) {
    const matchingNode = currentNode.find((node) => node.value === nodePath);
    if (matchingNode) {
      currentNode = matchingNode.children;
    }
  }

  const index = currentNode.findIndex((node) => node.value === deleteNode);
  currentNode.splice(index, 1);
  return [...tree];
}

export function renameNode(tree: TreeNode[], path: string[], newName: string): TreeNode[] {
  let currentNode = tree;
  for (let i = 0; i < path.length; i++) {
    const matchingNode = currentNode.find((node) => node.value === path[i]);
    if (matchingNode) {
      if (i === path.length - 1) {
        matchingNode.value = newName;
      } else {
        currentNode = matchingNode.children;
      }
    }
  }

  return [...tree];
}
export function toggleNodeCollapse(tree: TreeNode[], path: string[]): TreeNode[] {
  let currentNode = tree;
  for (let i = 0; i < path.length; i++) {
    const matchingNode = currentNode.find((node) => node.value === path[i]);
    if (matchingNode) {
      if (i === path.length - 1) {
        matchingNode.isCollapsed = !matchingNode.isCollapsed;
      } else {
        currentNode = matchingNode.children;
      }
    }
  }

  return [...tree];
}
