import React from 'react';

class DebounceSCU extends React.Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.shouldComponentUpdate;
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

export default DebounceSCU;
