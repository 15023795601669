import { MouseEventHandler, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { ReactComponent as CalendarOff } from 'assets/icons/systemicons/calendar_small_off.svg';
import { ReactComponent as VisibilityIcon } from 'assets/icons/systemicons/editor/visibility.svg';
import ResizableDialog from 'components/resizableDialog';
import Text from 'components/text/Text';
import UserContext from 'contexts/UserContext';
import useInstanceCardHeader from 'features/instance/hooks/useInstanceCardHeader';
import { InstanceChangeObject } from 'features/instance/hooks/useInstanceCore';
import useInstancePermissions from 'features/instance/hooks/useInstancePermissions';
import useInstanceViewUtils from 'features/instance/hooks/useInstanceViewUtils';
import { useInstanceMolecule } from 'features/instance/store/instance';
import { getVariant } from 'features/instance/utils';
import useGetRundown from 'hooks/useGetRundown';
import { Box } from 'layouts/box/Box';
import { Rundown } from 'types/graphqlTypes';
import variants from 'utils/instance/variants';
import isRundownEditable from 'utils/rundown/isRundownEditable';

import EditButton from '../cmsEditing/components/editButton';
import DatetimeIndicator from '../datetimeIndicator';
import Preview from '../preview/Preview';

import { ScheduleButton, ScheduleButtonDateTime } from './styled';

interface SchedulingButtonsProps {
  readLock: boolean;
  writeLock: boolean;
  onInstanceChanged: (instanceChangeObject: InstanceChangeObject) => Promise<unknown>;
}

const SchedulingButtons = ({ readLock, writeLock, onInstanceChanged }: SchedulingButtonsProps) => {
  const { useInstanceValue } = useInstanceMolecule();
  const { isCMS, openPublishingSettings, embeddedEndpoint, previewEndpoint, destination } =
    useInstanceCardHeader(onInstanceChanged);

  const { isFailedState, isCmsBlock, isLinearInstance, onCmsEditingClick } = useInstanceViewUtils();
  const { canShowCmsIframe, canShowNewDesign, canDisableSchedule, canScheduleInstance } =
    useInstancePermissions();
  const [getRundown] = useGetRundown('cache-first');
  const userContext = useContext(UserContext);

  const instance = useInstanceValue();
  const variant = getVariant(instance?.mProperties?.platform ?? '');

  const showPreviewButtons = (canShowNewDesign || canShowCmsIframe) && isCMS && !isCmsBlock;

  const [openPreview, setOpenPreview] = useState(false);
  const [relatedRundown, setRelatedRundown] = useState<Rundown | null>(null);

  const openPreviewView = () => setOpenPreview(true);
  const closePreviewView = () => setOpenPreview(false);

  const canDisplayPreview = !!previewEndpoint && isCMS;
  const displayEdit = !!embeddedEndpoint && showPreviewButtons;

  const isSchedulingDisabled = useMemo(() => {
    if (canDisableSchedule && variant !== variants.LINEAR) {
      return readLock || writeLock;
    }

    if (!canScheduleInstance) return true;

    return false;
  }, [canDisableSchedule, canScheduleInstance, readLock, variant, writeLock]);

  const hasPermissionForRundown = useMemo(() => {
    if (!isLinearInstance || !destination?.id || !relatedRundown) return true;

    /** doesn't have read access to the rundown */
    if ('__typename' in relatedRundown && relatedRundown?.__typename === 'RestrictedErrorType') {
      return false;
    }

    return isRundownEditable({
      permissions: relatedRundown?.permissions,
      groups: userContext?.groups,
    });
  }, [isLinearInstance, destination?.id, relatedRundown, userContext?.groups]);

  const getAndSetRundown = useCallback(async () => {
    if (destination?.id) {
      const data = await getRundown(destination.id, destination.id);
      setRelatedRundown(data);
    }
  }, [destination?.id]);

  const handleOpenPublishingSettings: MouseEventHandler<HTMLDivElement> = (event) => {
    if (!isSchedulingDisabled && hasPermissionForRundown) {
      openPublishingSettings(event);
    }
  };

  useEffect(() => {
    if (isLinearInstance) {
      void getAndSetRundown();
    }
  }, [isLinearInstance, getAndSetRundown]);

  return (
    <>
      <Box flexDirection="row">
        {displayEdit && <EditButton onClick={onCmsEditingClick} />}
        {canDisplayPreview && (
          <ScheduleButton role="presentation" onClick={openPreviewView}>
            <VisibilityIcon />
            <Text variant="button">Preview</Text>
          </ScheduleButton>
        )}
        {canDisplayPreview && (
          <ResizableDialog open={openPreview}>
            <Preview id={instance?.mId} title={instance?.mTitle} closeDialog={closePreviewView} />
          </ResizableDialog>
        )}
      </Box>
      {instance?.mPublishingAt ? (
        <DatetimeIndicator
          datetime={instance?.mPublishingAt}
          isFailedState={isFailedState}
          isDisabled={isSchedulingDisabled || !hasPermissionForRundown}
          format="MMM D / HH:mm"
          onClick={handleOpenPublishingSettings}
        />
      ) : (
        <ScheduleButtonDateTime
          role="presentation"
          $isDisabled={isSchedulingDisabled}
          onClick={handleOpenPublishingSettings}
        >
          <CalendarOff />
          <Text variant="button">Schedule...</Text>
        </ScheduleButtonDateTime>
      )}
    </>
  );
};

export default SchedulingButtons;
