import { useCallback, useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import uniqBy from 'lodash/uniqBy';

import SEARCH_MEMBER from 'operations/queries/searchMembers';
import { PaginatedItemType, SearchItemInput } from 'types/graphqlTypes';

export interface SearchResult {
  searchItem: PaginatedItemType;
}

export interface GetInput {
  input: SearchItemInput;
}

export interface SearchProps {
  skip: boolean;
  searchString: string;
  inputs: SearchItemInput;
  perPagelimit?: number;
  doPoll?: boolean;
  pollInterval?: number;
}

export const getInput = (
  searchString: string,
  inputs: SearchItemInput,
  perPagelimit: number,
): SearchItemInput => {
  const { mTypes } = inputs;

  return {
    perPagelimit,
    mTypes,
    searchString: searchString.length ? searchString : undefined,
  };
};

export const useSearchMembers = ({
  skip,
  searchString,
  perPagelimit = 50,
  inputs,
  doPoll,
  pollInterval = 30000,
}: SearchProps) => {
  const input = useMemo(
    () => getInput(searchString, inputs, perPagelimit),
    [searchString, inputs, perPagelimit],
  );

  const searchResult = useQuery<SearchResult, GetInput>(SEARCH_MEMBER, {
    variables: {
      input,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-first',
    skip,
  });

  const { data, error, loading, fetchMore, startPolling, stopPolling, refetch } = searchResult;

  useEffect(() => {
    if (doPoll) startPolling(pollInterval);

    return () => {
      if (doPoll) stopPolling();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doPoll, pollInterval]);

  const loadMore = useCallback(async () => {
    if (data?.searchItem.nextToken) {
      await fetchMore({
        variables: {
          input: {
            ...input,
            searchAfter: data?.searchItem.nextToken,
          },
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          return {
            searchItem: {
              items: uniqBy(
                [...previousResult.searchItem.items, ...fetchMoreResult.searchItem.items],
                'mId',
              ),
              nextToken: fetchMoreResult?.searchItem.nextToken,
              total: fetchMoreResult?.searchItem.total,
            },
          };
        },
      });
    }
  }, [data?.searchItem.nextToken, fetchMore, input]);

  return {
    items: data?.searchItem.items ?? [],
    error,
    loading,
    total: data?.searchItem?.total ?? 0,
    loadMore,
    refetch,
  };
};
