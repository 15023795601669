import { useCallback, useEffect, useMemo, useState } from 'react';
import { ApolloClient, useApolloClient, useQuery } from '@apollo/client';

import { getInputFromValue, MemberKeys } from 'components/mdfEditor/fields/relation/relation-utils';
import { FieldValue, MiniMember } from 'types/forms/forms';
import { BatchGetMembersInput, MemberType } from 'types/graphqlTypes';
import useLogger from 'utils/useLogger';

import { BATCH_GET_MEMBERS } from './graphql';

type ReturnType = {
  batchGetMembers: MemberType[];
};

type InputType = {
  input: BatchGetMembersInput;
};

export const batchGetMembersFromFieldValue = (
  client: ApolloClient<object>,
  miniMembers: MiniMember[],
) => {
  const input = getInputFromValue(miniMembers);

  return client.query<ReturnType, InputType>({
    query: BATCH_GET_MEMBERS,
    variables: {
      input: {
        keys: input,
      },
    },
    fetchPolicy: 'network-only',
  });
};

export const useBatchGetMembersHook = (keys: MemberKeys[]) => {
  const [members, setMembers] = useState<MemberType[]>([]);
  const { data, error, loading } = useQuery<ReturnType, InputType>(BATCH_GET_MEMBERS, {
    variables: {
      input: {
        keys,
      },
    },
    fetchPolicy: 'cache-first',
    skip: !keys?.length,
  });

  useEffect(() => {
    if (!loading) {
      setMembers(data?.batchGetMembers ?? []);
    }
  }, [data, loading]);

  return { members, loading, error };
};

export const useGetMembersFromFieldValue = (miniMembers: FieldValue) => {
  const { members, loading, error } = useBatchGetMembersHook(
    useMemo(() => getInputFromValue(miniMembers), [miniMembers]) ?? [],
  );
  return { members, loading, error };
};

const useBatchGetMembers = () => {
  const client = useApolloClient();
  const logger = useLogger();

  const loadItems = useCallback(
    async (input: BatchGetMembersInput) => {
      const result = await client.query<ReturnType, InputType>({
        query: BATCH_GET_MEMBERS,
        variables: {
          input,
        },
        fetchPolicy: 'network-only',
      });

      if (result?.errors) {
        logger.log(`Error loading items: ${result.errors.toString()}`);
        return [];
      }

      if (!result?.data?.batchGetMembers) return [];

      return result?.data?.batchGetMembers;
    },
    [client, logger],
  );

  return [loadItems];
};

export default useBatchGetMembers;
