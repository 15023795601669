import { css } from '@emotion/react';
import styled from '@emotion/styled';
import List from '@material-ui/core/List';

import Divider from 'components/divider';

export const StyledDropDownWrapper = styled('div')<{ active: boolean }>`
  ${({ active, theme }) => css`
    ${theme.typography.dina.caption};
    color: ${theme.palette.dina.statusWarning};
    svg {
      path {
        fill: ${active
          ? theme.palette.dina.whiteHighEmphasis
          : theme.palette.dina.whiteMediumEmphasis};
        fill-opacity: ${theme.palette.mode === 'light' ? 0.65 : 0.54};
      }
    }
  `}
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 1px;
  cursor: pointer;
  &:hover {
    svg {
      path {
        fill-opacity: 1;
      }
    }
  }
`;

export const StyledWrapper = styled('div')`
  width: 368px;
  max-height: 508px;
  background: ${({ theme }) => theme.palette.dina.surfaceCardDark};
`;

export const StyledDropDownContentList = styled(List)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
`;

export const StyledDropdownTabAction = styled('div')`
  ${({ theme }) => theme.typography.dina.caption};
  text-align: right;
  white-space: pre-line;
`;

export const StyledTabsOverline = styled('div')`
  ${({ theme }) => theme.typography.dina.overline};
  padding: 0 0 0 8px;
  margin: 8px 0;
`;

export const StyledDivider = styled(Divider)`
  width: 100%;
  margin: 8px 0;
`;
