// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag';

import { ORDER_PROPS } from 'screens/space/api/useGetOrdersAndForms';

export const UPDATED_ORDERS = gql`
  subscription notifyOrderUpdate($mIdSubscribed: String!) {
    notifyOrderUpdateSubscription(mIdSubscribed: $mIdSubscribed) {
      ${ORDER_PROPS}
    }
  }
`;
