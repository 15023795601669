import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import transientOptions from 'theme/helpers';

import InputBase from '../inputBase';

export const HeaderWrapper = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 1px;
  border-radius: 4px 4px 0 0;
  height: 32px;
  overflow: hidden;
  align-self: flex-start;
  ${({ theme }) =>
    css`
      background-color: ${theme.palette.dina.section};
    `}
`;

export const NormalChildWrapper = styled('div')`
  ${({ theme }) =>
    css`
      border: 4px solid ${theme.palette.dina.section};
    `}
  border-radius: 0 1px 1px;
`;

export const HiddenChildrenWrapper = styled('div')`
  ${({ theme }) =>
    css`
      border: 4px solid ${theme.palette.dina.section};
      background-color: ${theme.palette.dina.section};
    `}
  height: 0px;
  overflow: hidden;
  border-radius: 0 1px 1px;
`;

type WrapperType = {
  $isDragging?: boolean;
};

export const Wrapper = styled('div', transientOptions)<WrapperType>`
  display: flex;
  flex-direction: column;
  margin: 8px 0;
  opacity: ${(props) => (props.$isDragging ? 0.4 : 1)};
`;

export const Input = styled(InputBase)`
  ${({ theme }) =>
    css`
      ${theme.typography.dina.listItemLabel}
      color: ${theme.palette.dina.whiteHighEmphasis};
      ::placeholder {
        color: ${theme.palette.dina.whiteHighEmphasis};
      }
    `}
`;

export const ArrowWrapper = styled('div')`
  width: 24px;
  height: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
