import React, { useMemo, memo } from 'react';
import useGetAutomationTemplates from 'hooks/useGetAutomationTemplates';
import RundownItemTypes from 'utils/rundownItemTypes';
import Details from './details-view';
import SortAfterFilter from './utils/SortAfterFilter';

const removeDisabledTemplates = (templates) => templates.filter(({ disabled }) => !disabled);

const DetailsContainer = ({
  onClose,
  onOk,
  transitionData,
  metaData,
  initialTransition,
  initialVariant,
  templateType,
  type,
}) => {
  const { templates, resources, selectTemplateSet, templateSets, templateSetIndex } =
    useGetAutomationTemplates();

  const sortedSpec = useMemo(
    () =>
      SortAfterFilter(
        removeDisabledTemplates(templates),
        { type: RundownItemTypes[templateType] || type },
        'variant',
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [templates],
  );

  return (
    <Details
      items={templateSets}
      spec={sortedSpec}
      resources={resources}
      selectTemplateSet={selectTemplateSet}
      templateSetIndex={templateSetIndex}
      onClose={onClose}
      onOk={onOk}
      transitionData={transitionData}
      metaData={metaData}
      initialTransition={initialTransition}
      initialVariant={initialVariant}
    />
  );
};

export default memo(DetailsContainer);
