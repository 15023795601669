/* eslint-disable import/no-extraneous-dependencies */
import { useState, useEffect, useCallback, useMemo } from 'react';
import Divider from 'components/divider';
import { Button } from 'components/buttons';
import Scrollbar from 'components/scrollbar/scrollbar';
import debounce from 'lodash/debounce';
import fieldTypes from 'screens/main/components/header/navbar/settings/utils/fieldTypes';
import SettingsContent from 'screens/main/components/header/navbar/settings/components/settingsTabs/systemSettings/details/settingsContent';
import FieldOptions from './FieldOptions';
import { createOption } from '../utils';
import { RootWrapper, SettingsContentWrapper, FieldTopWrapper } from '../styled';

const supportedFieldTypes = {
  SELECT: 'select',
  BOOLEAN: 'boolean',
  TEXT: 'text',
};

const fieldTypesOptions = [];
for (const [key, value] of Object.entries(supportedFieldTypes)) {
  fieldTypesOptions.push({ id: key, title: value, value });
}

const metadataFieldProperties = [
  {
    key: 'id',
    label: 'Programmatic identifier',
    type: fieldTypes.TEXT,
    propName: 'id',
    placeholder: 'Unique identifier',
    disabled: true,
  },
  {
    key: 'label',
    label: 'User visible label',
    type: fieldTypes.TEXT,
    propName: 'label',
    placeholder: 'Type the field label',
  },
  {
    key: 'description',
    label: 'Optional tooltip',
    type: fieldTypes.TEXT,
    propName: 'description',
    placeholder: 'Type a tooltip',
  },
  {
    key: 'type',
    label: 'Type',
    type: fieldTypes.SELECT,
    propName: 'type',
    options: { fieldTypesOptions },
  },
];

const MetadataField = ({
  field,
  onChange,
  updateField,
  setSelectedField,
  setHasDuplicateFieldValue,
}) => {
  const fieldOptions = useMemo(() => field?.options ?? [], [field]);
  const [showOptions, setShowOptions] = useState(false);
  const updateInput = debounce((newVal) => onChange(newVal), [500]);

  // TODO - improve in a bigger refactor.
  const isDefaultStatusField = field.id === 'DEFAULT#status';

  const updateOption = useCallback(
    (ev) => {
      const { prop, value, option } = ev;
      const copy = {
        ...option,
        [prop]: value,
      };
      const index = fieldOptions.findIndex((op) => op.id === copy.id);
      const updatedOptions = [...fieldOptions.filter((op) => op.id !== copy.id)];
      updatedOptions.splice(index, 0, copy);
      const copyField = {
        ...field,
        options: updatedOptions,
      };
      updateField(copyField);
      setSelectedField(copyField);
    },
    [field, fieldOptions, updateField, setSelectedField],
  );

  const addOption = useCallback(() => {
    const newOption = createOption(isDefaultStatusField ? 'active#value' : 'value');
    const copy = {
      ...field,
      options: [...(field?.options ?? []), newOption],
    };
    updateField(copy);
    setSelectedField(copy);
  }, [field, updateField, setSelectedField]);

  const removeOption = useCallback(
    (option) => {
      const newOptions = fieldOptions.filter((op) => op.id !== option.id);
      const copy = {
        ...field,
        options: newOptions,
      };
      updateField(copy);
      setSelectedField(copy);
    },
    [field, fieldOptions, updateField, setSelectedField],
  );

  useEffect(() => {
    setShowOptions(field?.type === fieldTypes.SELECT || field?.type === fieldTypes.MENU);
  }, [field]);

  const onChangeType = (val) => {
    if (val === fieldTypes.SELECT || val === fieldTypes.MENU) setShowOptions(true);
    else {
      setShowOptions(false);
    }
  };

  const onChangeMetadataFieldSettings = (val, contentKey, originalValue) => {
    const [, attribute] = contentKey.split(':');
    updateInput({ attribute, val });
    if (attribute === 'type') {
      onChangeType(val);
    }
  };

  const getValue = (type, value) => {
    if (type === fieldTypes.BOOLEAN) {
      return value?.toString() === 'true';
    }
    if (type === fieldTypes.NUMBER) {
      return value?.toString();
    }
    return value ?? '';
  };

  const viewSettingsContent = (property, isLast) => (
    <SettingsContentWrapper key={`${field.id}:${property.key}`}>
      <SettingsContent
        contentKey={`${field.id}:${property.key}`}
        label={property.label}
        disabled={property.disabled}
        type={property.type}
        value={getValue(property.type, field[property?.propName])}
        description={property.description}
        placeholder={property.placeholder}
        onChange={onChangeMetadataFieldSettings}
        options={fieldTypesOptions}
        width={`${property.type === fieldTypes.SELECT ? '60' : '100'}`}
      >
        {showOptions && property.type === fieldTypes.SELECT && (
          <div style={{ width: '100px' }}>
            <Button onClick={addOption}>Add option</Button>
          </div>
        )}
      </SettingsContent>
      {showOptions && property.type === fieldTypes.SELECT && (
        <FieldOptions
          fieldOptions={fieldOptions}
          updateOption={updateOption}
          removeOption={removeOption}
          updateFieldOptions={updateField}
          setHasDuplicateOption={setHasDuplicateFieldValue}
          field={field}
          setSelectedField={setSelectedField}
        />
      )}
      {!isLast && <Divider />}
    </SettingsContentWrapper>
  );

  return (
    <RootWrapper>
      <Scrollbar>
        {field && (
          <>
            <FieldTopWrapper>
              {metadataFieldProperties
                ?.slice(0, 2)
                .map((property) => viewSettingsContent(property))}
            </FieldTopWrapper>
            {metadataFieldProperties
              ?.slice(2, metadataFieldProperties.length)
              .map((property, index) =>
                viewSettingsContent(property, metadataFieldProperties.length - 3 === index),
              )}
          </>
        )}
      </Scrollbar>
    </RootWrapper>
  );
};

export default MetadataField;
