import gql from 'graphql-tag';

export default gql`
  mutation restrictMember($input: RestrictMemberInput) {
    restrictMember(input: $input) {
      mId
      mRefId
      mType
      isRestricted
      mAssignedMembers {
        mId
        mType
      }
    }
  }
`;
