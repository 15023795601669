import { useMutation } from '@apollo/client';

import GET_MEMBER from 'operations/queries/getMember';
import { DailyNote } from 'types';
import { DailyNoteInput } from 'types/graphqlTypes';

import { UPDATE_DAILY_NOTE } from './graphql';

const useUpdateDailyNote = () => {
  const [mutation, { loading, data, error }] = useMutation<
    { updateDailyNote: DailyNote },
    { input: DailyNoteInput }
  >(UPDATE_DAILY_NOTE);

  const updateDailyNote = async (input: DailyNoteInput) => {
    const result = await mutation({
      variables: {
        input,
      },
      update: (proxy, mutaionResult) => {
        const updatedData = mutaionResult.data?.updateDailyNote;
        proxy.writeQuery({
          query: GET_MEMBER,
          variables: { mId: input.mId, mRefId: input.mRefId },
          data: {
            getMember: updatedData,
          },
        });
      },
    });

    return result?.data?.updateDailyNote ?? null;
  };

  return {
    updateDailyNote,
    loading,
    data,
    error,
  };
};

export default useUpdateDailyNote;
