import { useCallback } from 'react';

import { useAllMembers } from 'store';
import { AssignedMember } from 'types';
import { MemberTypeEnum } from 'types/graphqlTypes';
import { getParseMetadata } from 'utils/getParsedMetadata';

import MemberAutoComplete from './MemberAutoComplete';

type SetMembers = React.Dispatch<React.SetStateAction<AssignedMember[]>>;
type AddNewMember = (newAssignee: AssignedMember[]) => void;

interface AddMemberProps {
  selectedMembers: AssignedMember[];
  injectedMembers?: AssignedMember[];
  showChips?: boolean;
  setSelectedMembers: SetMembers | AddNewMember;
  onRemove?: () => void;
  onClose?: () => void;
  autoFocus?: boolean;
  disableClearable?: boolean;
  singleChoice?: boolean;
  selectOnFocus?: boolean;
  placeholderText?: string;
  noOptionsText?: string;
  variant?: 'user' | 'team' | 'department' | 'unit' | 'contact' | 'custom' | 'all';
  variants?: string[];
  doCallApi?: boolean;
}

function AddMember({
  selectedMembers = [],
  injectedMembers,
  showChips = true,
  setSelectedMembers = () => {},
  onRemove = () => {},
  onClose = () => {},
  autoFocus = false,
  disableClearable = true,
  singleChoice = false,
  selectOnFocus = false,
  placeholderText = 'Type to search, or press down to browse',
  noOptionsText = 'None found, or all available resources already added',
  variant = 'all',
  doCallApi = false,
  variants,
}: Readonly<AddMemberProps>) {
  const [members] = useAllMembers();
  const filteredMembers = members?.filter((m) => {
    if (Array.isArray(variants)) {
      return variants.includes(m.mType);
    }
    if (variant === 'custom') return false;
    if (variant === 'all') {
      return m.mType !== ('contact' as MemberTypeEnum);
    }
    if (variant === 'unit') {
      return m.mType === ('team' as MemberTypeEnum) || m.mType === ('department' as MemberTypeEnum);
    }
    return m.mType === variant;
  });

  const filterMembersByMetadata = useCallback(
    (member: AssignedMember) => {
      return !(getParseMetadata(member?.metadata) ?? member?.mProperties)?.notListed;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getParseMetadata],
  );

  const filteredMembersByMetadata = (injectedMembers ?? filteredMembers).filter(
    filterMembersByMetadata,
  );

  return (
    <MemberAutoComplete
      showChips={showChips}
      selectedMembers={selectedMembers}
      setSelectedMembers={setSelectedMembers}
      singleChoice={singleChoice}
      placeholderText={placeholderText}
      noOptionsText={noOptionsText}
      autoFocus={autoFocus}
      selectOnFocus={selectOnFocus}
      disableClearable={disableClearable}
      allMembers={filteredMembersByMetadata}
      onRemove={onRemove}
      onClose={onClose}
      doCallApi={doCallApi}
    />
  );
}

export default AddMember;
