import { useCallback } from 'react';

import useCreatePlaceholder from 'hooks/useCreatePlaceholder';
import useRemovePlaceholder from 'hooks/useRemovePlaceholder';

import { useInstanceMolecule } from '../store/instance';

const useInstancePlaceholder = () => {
  const { useInstanceValue } = useInstanceMolecule();
  const [createPlaceholder] = useCreatePlaceholder();
  const [removePlaceholder] = useRemovePlaceholder();

  const instance = useInstanceValue();

  const handleCreatePlaceholder = useCallback(
    async (title: string, itemType: string) => {
      if (!instance?.mStoryId) return;

      const { mId, mRefId } = (await createPlaceholder(instance?.mStoryId, {
        mTitle: title,
        itemType,
        mProperties: instance?.mProperties,
      })) as {
        mId: string;
        mRefId: string;
      };

      return { title, mId, mRefId };
    },
    [createPlaceholder, instance?.mProperties, instance?.mStoryId],
  );

  return { handleCreatePlaceholder, removePlaceholder };
};

export default useInstancePlaceholder;
