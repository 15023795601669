import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  rnd: {
    position: 'relative',
    '&:focus': {
      outline: 'none',
    },
  },
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
    background: theme.palette.dina.surfaceCardDark,
    boxShadow: `0px 0px 1px ${theme.palette.dina.boxShadowDark},
     0px 12px 24px ${theme.palette.dina.boxShadowDark}`,
    borderRadius: '8px',
    overflow: 'hidden',
  },

  // Header Styles
  headerContainer: {
    position: 'relative',
    left: '0px',
    right: '0px',
    top: '0px',
    cursor: 'move',
  },
  headerWrapper: {
    height: '64px',
    background: theme.palette.dina.backgroundResting,
    display: 'flex',
    alignItems: 'center',
    padding: '0px 16px',
    left: '0px',
  },
  titleContainer: {
    position: 'absolute',
    width: `calc(100% - 60px)`,
    left: '16px',
  },
  title: {
    ...theme.typography.dina.marketplaceHeader,
    width: '100%',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  subtitle: {
    ...theme.typography.dina.h5,
    marginBottom: '2px',
    fontSize: '10px',
    fontWeight: 500,
    lineHeight: '12px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    color: theme.palette.dina.mediumEmphasis,
    cursor: 'default',
  },
  idContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  copyButton: {
    cursor: 'pointer',
    height: '14px',
    '&:hover': {
      background: 'none',
      border: 'none',
      '& path': {
        fillOpacity: 1,
      },
    },
    '&:focus': {
      background: 'none',
      border: 'none',
      outline: 'none',
      '& path': {
        fillOpacity: 1,
      },
    },
  },
  closeButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '25px',
    height: '25px',
    right: '15px',
    cursor: 'pointer',
    background: 'none',
    border: 'none',
    '&:hover': {
      background: 'none',
      border: 'none',
      '& path': {
        fillOpacity: 1,
      },
    },
    '&:focus': {
      background: 'none',
      border: 'none',
      outline: 'none',
      '& path': {
        fillOpacity: 1,
      },
    },
  },

  // Content Container Styles
  contentContainer: {
    position: 'absolute',
    left: '0px',
    right: '0px',
    top: '64px',
    bottom: '57px',
  },

  // Footer Styles
  footerContainer: {
    position: 'absolute',
    right: '0px',
    left: '0px',
    bottom: '0px',
  },
  footerWrapper: {
    height: '57px',
    padding: '0 8px',
    background: theme.palette.dina.backgroundResting,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '8px',
  },
}));

export default useStyles;
