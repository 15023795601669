import { useMutation } from '@apollo/client';
import DELETE_FOLDER from 'operations/mutations/deleteMember';
import GET_FOLDERS from 'operations/queries/getFolders';

const useDeleteFolder = () => {
  const [deleteFolderMutation] = useMutation(DELETE_FOLDER);

  const deleteFunction = async (mId, mRefId) => {
    const input = {
      mId,
      mRefId,
    };
    await deleteFolderMutation({
      variables: { input },
      update: (proxy, mutationResult) => {
        const { deleteSingleMember } = mutationResult.data;
        try {
          const folderList = proxy.readQuery({
            query: GET_FOLDERS,
          });

          const newFolderList = folderList.getFolders.filter(
            (folder) => folder.mRefId !== deleteSingleMember.mRefId,
          );

          proxy.writeQuery({
            query: GET_FOLDERS,
            data: { getFolders: newFolderList },
          });
        } catch (err) {
          //   logger.error(err);
        }
      },
    });
  };

  return [deleteFunction];
};

export default useDeleteFolder;
