import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { omit } from 'lodash';

import { OptionList, OptionListRaw, UpdateOptionListInput } from 'types/graphqlTypes';

import { STANDARD_OPTION_LIST_KEYS } from '../commonKeys';

import { generateIdForAlternatives } from './useGetOptionLists';

const UPDATE_LIST = gql`
  mutation UpdateOptionList($input: UpdateOptionListInput) {
    updateOptionList(input: $input) {
      ${STANDARD_OPTION_LIST_KEYS}
    }
  }
`;

interface UpdateList {
  updateOptionList: OptionListRaw;
}

interface Input {
  input: UpdateOptionListInput;
}

const toRawInput = (list: OptionList): UpdateOptionListInput => {
  return {
    ...omit(list, 'optionListType'),
    alternatives: list.alternatives.map((alt) => ({ ...omit(alt, 'id') })),
  };
};

export const useUpdateOptionList = () => {
  const [updateMutation] = useMutation<UpdateList, Input>(UPDATE_LIST);
  const updateOptionList = useCallback(
    async (input: OptionList) => {
      const result = await updateMutation({
        variables: {
          input: toRawInput(input),
        },
      });
      if (result?.data) return generateIdForAlternatives(result?.data?.updateOptionList);
    },
    [updateMutation],
  );
  return { updateOptionList };
};
