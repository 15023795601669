import { endOfDay, startOfDay } from 'date-fns';

type DateTimeType = Date | string | null;

export function sanitizeDates(
  startDate?: DateTimeType,
  endDate?: DateTimeType,
): { from: string; to: string } {
  const now = new Date();

  const parseDate = (date?: DateTimeType): Date => {
    return typeof date === 'string' ? new Date(date) : date ?? now;
  };

  const startTime = parseDate(startDate || endDate);
  const endTime = parseDate(endDate || startDate);

  const sanitizedStartDate = startOfDay(startTime).toISOString();
  const sanitizedEndDate = endOfDay(endTime).toISOString();

  return { from: sanitizedStartDate, to: sanitizedEndDate };
}
