import { memo } from 'react';

import Input from 'components/input/Input';
import Text from 'components/text/Text';

import { FilterComponentType, FilterProps } from './types';

function AssetsFilters({ filters, updateFilters }: Readonly<FilterProps>) {
  const folderId = filters?.folderId as string;

  const setFolderId = (value: string) => {
    const newFilters = { folderId: value };
    updateFilters(newFilters);
  };

  return (
    <>
      <Text variant="listItemLabel">Search in specific asset folder</Text>
      <Input
        placeholder="Configure asset folder identifier"
        initialValue={folderId}
        updateValue={(val) => setFolderId(val)}
      />
    </>
  );
}

export const FilterComponent: FilterComponentType = memo(AssetsFilters) as FilterComponentType;
