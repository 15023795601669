import { ItemParams } from 'react-contexify';

import { ReactComponent as Delete } from 'assets/icons/systemicons/delete.svg';
import { ContextItem, MemberMenuProps } from 'components/contextMenu/memberItems/Base';

import { useGridMolecule } from '../store';

interface KanbanMenuProps extends MemberMenuProps {
  parentLaneId?: string;
}

export function RemoveMemberItem({ ...contextProps }) {
  const { useRemoveMember } = useGridMolecule();
  const removeMember = useRemoveMember();

  const handleClickEvent = (data: ItemParams<KanbanMenuProps>) => {
    const { member } = data.props ?? {};
    if (member?.mId) {
      removeMember({ memberId: member?.mId });
    }
  };

  return (
    <ContextItem
      id="remove"
      label="Remove from grid"
      icon={<Delete />}
      onClick={(data: ItemParams<MemberMenuProps>) => handleClickEvent(data)}
      {...contextProps}
    />
  );
}
