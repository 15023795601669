const TWITTER_URL_PREFIX = 'https://twitter.com/';
const X_URL_PREFIX = 'https://x.com/';

/**
 * According https://twitter.com/scorpionoxvoid/status/1202199959128309760
 * Twitter embedds query parameters based on the platform the link was copied from
 * and Dina is unable to render preview with the suffix.
 * This function removes those added URL suffix only if it contains '?s='
 * Other suffixes will be returned as it is to not break any existing workflow
 *
 * @param {String} tweetId which may or may not include suffix
 * @returns {String} processed tweet id
 */

const idWithoutShareSuffix = (tweetId) => {
  if (tweetId === '') return null;
  const idParts = tweetId.split('?');
  return idParts?.length > 0 ? idParts[0] : tweetId;
};

const urlStartWithCorrectPrefix = (url) => {
  if (typeof url !== 'string') return false;

  return url.startsWith(TWITTER_URL_PREFIX) || url.startsWith(X_URL_PREFIX);
};

/**
 * Extracts tweet id from the last part of the embedded tweet url separated by '/'
 *
 * @param {String} url Embedded tweet url
 * @returns {String} extracted tweet id
 */
const getTweetIdFromURL = (url) => {
  if (!url) return null;
  const correctPrefix = urlStartWithCorrectPrefix(url);

  if (correctPrefix) {
    const tokens = url.split('/');
    const id = tokens.slice(-1);
    return idWithoutShareSuffix(id[0]);
  }
  return null;
};

export default getTweetIdFromURL;
