import { useMemo } from 'react';

import { useGetBoard } from 'api/gridAndKanban/useGetBoard';

/** Get grid items, their ids and itemGroupId */
export default function useGetData(gridId: string) {
  const { board, error, loading } = useGetBoard(gridId);

  const { members, id } = board?.columns[0] ?? {};
  const itemIds = useMemo(() => members?.map((member) => member.mId) ?? [], [members]);

  return { items: members ?? [], itemIds, error, loading, itemGroupId: id };
}
