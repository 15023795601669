import { useMemo, useState } from 'react';

import { useGetMdfColumns } from 'features/mdf/useGetMdfColumns';
import { Mdf } from 'types/graphqlTypes';
import { ParsedMemberType } from 'types/members';

import { actionColumn } from './actionColumn';
import { defaultColumns } from './defaultColumns';

export function useGetWidgetColumns(items: ParsedMemberType[], mdfs: Mdf[]) {
  const [itemIds, setItemIds] = useState<string[]>([]);
  if (items.length !== itemIds.length) {
    setItemIds(items.map((item) => item.mId));
  }
  const mdfDefinitions = useGetMdfColumns(items, mdfs);

  const columns = useMemo(() => {
    return [...defaultColumns, ...mdfDefinitions, actionColumn];
  }, [itemIds]);
  return columns;
}
