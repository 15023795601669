import gql from 'graphql-tag';

export default gql`
  query GetScheduleResources($input: GetScheduleResourcesInput) {
    getScheduleResources(input: $input) {
      mId
      mRefId
      mTitle
      mType
      items {
        itemId
        title
      }
      mMetaData {
        key
        value
      }
      mPublishingAt
      mPublishingEnd
    }
  }
`;
