import { useCallback, useState } from 'react';
import { OnDataOptions } from '@apollo/client';

import { Block } from 'api/mdfBlocks/types';
import useApolloSubscription from 'hooks/useApolloSubscriptionV2';
import useBatchGetMembers from 'hooks/useBatchGetRundownInstances';
import NOTIFY_MEMBER_UPDATE_SUBSCRIPTION from 'operations/subscriptions/notifyMemberUpdate';
import updateCacheWithTransaction from 'operations/utils/cache/notification/transaction/updateCacheWithTransaction';
import { Instance } from 'types';

interface SubscriptionData {
  notifyMemberUpdateSubscription: Instance | Block;
}

interface LoadInstancesProps {
  rundownId: string;
}

export default function useLoadInstances({ rundownId }: LoadInstancesProps) {
  const [loadItems] = useBatchGetMembers();
  const [isLoading, setLoading] = useState(true);

  // Subscribe
  useApolloSubscription(NOTIFY_MEMBER_UPDATE_SUBSCRIPTION, {
    variables: {
      mIdSubscribed: rundownId,
    },
    skip: !rundownId,
    source: 'useLoadInstances',
    onSubscriptionData: ({ client, data: subscriptionData }: OnDataOptions<SubscriptionData>) => {
      const item = subscriptionData.data?.notifyMemberUpdateSubscription;
      if (item) updateCacheWithTransaction(client, item);
    },
  });

  // Load instances by id. Populates the cache.
  const loadInstances = useCallback(
    async (instanceIds: string[]) => {
      setLoading(true);
      await loadItems(instanceIds);
      setLoading(false);
    },
    [loadItems],
  );

  return { loadInstances, isLoading };
}
