import { useCallback } from 'react';

import useScheduleLinearInstance from 'hooks/useScheduleLinearInstance';
import { isBeforeToday } from 'screens/rundown/components/editor/utils';
import { Destination } from 'types';
import variants from 'utils/instance/variants';

import { useInstanceMolecule } from '../store/instance';

import { InstanceChangeObject } from './useInstanceCore';

export type PublishSettingsInputType = {
  selectedDestination: { id: string; value: string; title: string };
  publishingTime: string;
  expiryTime: string;
};

const useInstancePublishing = (
  onInstanceChanged: (instanceChangeObject: InstanceChangeObject) => Promise<unknown>,
) => {
  const { useCurrentDestination, useInstanceValue } = useInstanceMolecule();

  const [currentDestination, setCurrentDestination] = useCurrentDestination();
  const instance = useInstanceValue();

  const [scheduleLinearInstance] = useScheduleLinearInstance(() => {}, setCurrentDestination);

  const onMoveRundownInstance = useCallback(
    async (
      sourceRundown: {
        mId?: string | null;
        mRefId?: string | null;
      },
      targetRundown: {
        mId?: string | null;
        mRefId?: string | null;
      },
    ) => {
      await scheduleLinearInstance(instance, sourceRundown, targetRundown, currentDestination);
    },
    [currentDestination, instance, scheduleLinearInstance],
  );

  const onPublishSettingsChange = useCallback(
    async ({
      selectedDestination,
      publishingTime,
      expiryTime,
    }: {
      selectedDestination: Destination;
      publishingTime: string;
      expiryTime: string;
    }) => {
      const destinationChanged = currentDestination?.value !== selectedDestination.value;
      const publishingTimeChanged = publishingTime !== instance?.mPublishingAt;
      const expiryTimeChanged = instance?.mPublishingEnd !== expiryTime;

      setCurrentDestination(selectedDestination);

      let changeObject: InstanceChangeObject | null = null;

      if (destinationChanged && publishingTimeChanged && expiryTimeChanged) {
        changeObject = {
          changeType: 'schedule-and-destination-and-expiry',
          publishingSettings: {
            publishTime: publishingTime,
            accountUrl: selectedDestination.value,
            expiryTime,
          },
        };
      } else if (destinationChanged && publishingTimeChanged) {
        changeObject = {
          changeType: 'schedule-and-destination',
          publishingSettings: {
            publishTime: publishingTime,
            accountUrl: selectedDestination.value,
          },
        };
      } else if (destinationChanged && expiryTimeChanged) {
        changeObject = {
          changeType: 'expiry-and-destination',
          publishingSettings: {
            expiryTime,
            accountUrl: selectedDestination.value,
          },
        };
      } else if (publishingTimeChanged && expiryTimeChanged) {
        changeObject = {
          changeType: 'schedule-and-expiry',
          schedule: {
            publishTime: publishingTime,
            expiryTime,
          },
        };
      } else if (publishingTimeChanged) {
        changeObject = {
          changeType: 'publishingSchedule',
          schedule: {
            publishTime: publishingTime,
          },
        };
      } else if (expiryTimeChanged) {
        changeObject = {
          changeType: 'expirySchedule',
          schedule: {
            expiryTime,
          },
        };
      } else if (destinationChanged) {
        changeObject = {
          changeType: 'destination',
          accountUrl: selectedDestination.value,
        };
      }

      if (!changeObject) return;

      await onInstanceChanged(changeObject);
    },
    [
      currentDestination?.value,
      instance?.mPublishingAt,
      instance?.mPublishingEnd,
      onInstanceChanged,
      setCurrentDestination,
    ],
  );

  const handleInstanceRundownUpdate = useCallback(
    async ({
      selectedDestination,
      publishingTime,
    }: {
      selectedDestination: Destination;
      publishingTime: string;
    }) => {
      const newCurrentDestination = {
        id: selectedDestination.id,
        value: selectedDestination.value,
        title: selectedDestination.title,
        publishingTime,
        startTime: selectedDestination?.startTime,
        timeZone: selectedDestination?.timeZone,
      };

      setCurrentDestination(newCurrentDestination);

      const targetRundown = {
        mId: selectedDestination.id,
        mRefId: selectedDestination.id,
        mTitle: selectedDestination.title,
        mPublishingAt: selectedDestination.publishingTime,
      };

      const sourceRundown = {
        mId: instance?.mProperties?.account?.accountId,
        mRefId: instance?.mProperties?.account?.accountId,
      };

      if (instance?.mId !== '-' && (!publishingTime || !isBeforeToday(publishingTime))) {
        await onMoveRundownInstance(sourceRundown, targetRundown);
      }
    },
    [
      setCurrentDestination,
      instance?.mProperties?.account?.accountId,
      instance?.mId,
      onMoveRundownInstance,
    ],
  );

  const handlePublishSettingChange = useCallback(
    async (publishSetting: PublishSettingsInputType) => {
      if (instance?.mProperties?.platform === variants.LINEAR)
        await handleInstanceRundownUpdate(publishSetting);
      else {
        await onPublishSettingsChange(publishSetting);
      }
    },
    [instance?.mProperties?.platform, handleInstanceRundownUpdate, onPublishSettingsChange],
  );

  return {
    handlePublishSettingChange,
  };
};

export default useInstancePublishing;
