import { Editor, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import { CustomElement } from 'types';

import { elementTypes } from '../constants';
import { ActionTypesEnum } from '../constants/types/actionTypes';
import { Update } from '../types';

const { setNodes } = Transforms;

/**
 * Removes block (video, image, gif, section etc. ) element
 *
 * @param editor SlateJS editor instance
 * @param element element block object
 * @param update Callback to be invoked on update
 * @returns SlateJS editor instance
 */

const removeBlock = (editor: Editor, element: CustomElement, update: Update) => {
  const path = ReactEditor.findPath(editor, element);
  setNodes(editor, { type: elementTypes.PARAGRAPH }, { at: path });

  update({
    type: ActionTypesEnum.BLOCK_REMOVE,
    payload: {
      document: editor.children as CustomElement[],
      removedElement: element,
    },
  })?.then(
    () => {},
    () => {},
  );

  return editor;
};

export default removeBlock;
