import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { OutlinedInput as MaterialOutlinedInput } from '@material-ui/core';

export const InputWrapper = styled('div')`
  height: 38px;
  min-width: 285px;
`;

export const DivWrapper = styled('div')`
  height: 38px;
  display: flex;
  align-items: center;
  min-width: 285px;
  user-select: none;
`;

export const OutlinedInput = styled(MaterialOutlinedInput)`
  &.MuiOutlinedInput-root {
    height: 38px;
    border-radius: 0px;
    :hover {
      ${({ theme }) => css`
        border-bottom: 0.5px solid ${theme.palette.dina.buttonBorderOutlined};
        background-color: ${theme.palette.dina.whiteHoverOverlay};
        filter: ${theme.palette.dina.onHover};
      `}
    }
  }

  .MuiOutlinedInput-input {
    ${({ theme }) => theme.typography.dina.listItemLabel};
    padding-left: 0px;
  }

  &.Mui-focused {
    border: 1px solid ${({ theme }) => theme.palette.dina.onFocus};
    :hover {
      border: 1px solid ${({ theme }) => theme.palette.dina.onFocus};
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;
