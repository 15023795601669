export const CATEGORY_TYPES = {
  GENERAL: 'general',
  METADATA: 'metadata',
  LINKS: 'links',
  INSTANCES: 'instances',
  RUNDOWN: 'rundown',
  RUNDOWNVIEWS: 'rundown-views',
  AI_CONFIG: 'ai-config',
  WEBHOOKS: 'webhooks',
  HTMLPANELS: 'html-panel-views',
  EDITOR: 'editor',
};

export const MENU_TYPES = {
  RUNDOWN_SETTINGS: 'Rundown',
  RUNDOWN_VIEWS: 'rundown-views',
  RUNDOWN_STUDIOS: 'rundown-studios',
  RUNDOWN_PERMISSIONS: 'rundown-permissions',
};

export default CATEGORY_TYPES;
