import React from 'react';
import PropTypes from 'prop-types';
import chipData from './chipData';
import { MaterialChip, CountText, ArrowIcon } from './chip-styled';

const Chip = ({ type, membersCount, openPopover, fullWidth }) => {
  const isPopulated = membersCount > 0;

  const { title, SelectedIcon, UnselectedIcon } = chipData[type];

  const handleChipClick = (event) => {
    openPopover(event.currentTarget, type);
  };

  return (
    <MaterialChip
      label={title}
      variant="outlined"
      clickable
      onClick={handleChipClick}
      onDelete={handleChipClick}
      icon={isPopulated ? <SelectedIcon className="selectedIcon" /> : <UnselectedIcon />}
      $isPopulated={isPopulated}
      $fullWidth={fullWidth}
      deleteIcon={
        <>
          <CountText $isPopulated={isPopulated}>{membersCount || 'All'}</CountText>
          <ArrowIcon />
        </>
      }
    />
  );
};

Chip.propTypes = {
  type: PropTypes.string,
  membersCount: PropTypes.number,
  openPopover: PropTypes.func,
  fullWidth: PropTypes.bool,
};

Chip.defaultProps = {
  type: '',
  membersCount: 0,
  openPopover: () => {},
  fullWidth: false,
};

export default Chip;
