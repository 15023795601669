import PropTypes from 'prop-types';

import useSettingsValue from 'hooks/useSettingsValue';
import { useTranslation } from 'react-i18next';

import fieldTypes from 'utils/rundown/fieldTypes';
import UserSettingsView from 'utils/settings/schemas/userSettingsView';

import SettingsContentForm from '../../../../settingsContentForm/SettingsContentForm';
import filterVisibleCategories from '../../../../../utils/filterVisibleCategories';
import { useSelectedUserSettingAtom } from '../../../../../atoms';
import { useChangedUserSettings } from '../../../../../atomsTs';

function Notification({ systemSettings }) {
  const categories = filterVisibleCategories(UserSettingsView);
  const mMetaData = systemSettings?.userSettings?.mMetaData || [];
  const [getSettingsValue] = useSettingsValue();
  const [selectedMenu] = useSelectedUserSettingAtom();
  const [changedUserSettings, setChangedUserSettings] = useChangedUserSettings();
  const { i18n } = useTranslation();

  const getValue = (key, type, fallbackProperty, defaultValue) => {
    const systemMetaData = mMetaData?.find((metaData) => metaData.key === key);
    const value =
      systemMetaData?.value || getSettingsValue(key, fallbackProperty, defaultValue) || '';
    if (type === fieldTypes.BOOLEAN) {
      return typeof value === 'boolean' ? value : value?.toLowerCase() === 'true';
    }
    if (type === fieldTypes.NUMBER) {
      return value?.toString();
    }
    return value;
  };

  const onChangeSettings = (val, contentKey, originalValue) => {
    if (contentKey === 'user.displayLanguage') i18n.changeLanguage(val);
    const changedSetting = changedUserSettings.find((x) => x.key === contentKey);
    if (changedSetting) {
      if (changedSetting?.originalValue === val) {
        setChangedUserSettings(changedUserSettings.filter((x) => x.key !== changedSetting.key));
      } else {
        changedSetting.value = val;
      }
    } else {
      setChangedUserSettings([
        ...changedUserSettings,
        {
          key: contentKey,
          value: val,
          originalValue,
        },
      ]);
    }
  };

  return (
    <SettingsContentForm
      systemSettings={systemSettings}
      categories={categories}
      onChangeSettings={onChangeSettings}
      changedSettings={changedUserSettings}
      getValue={getValue}
      selectedMenu={selectedMenu === 'notification-general' ? 'notification' : selectedMenu}
    />
  );
}

Notification.propTypes = {
  systemSettings: PropTypes.object,
};

Notification.defaultProps = {
  systemSettings: {},
};

export default Notification;
