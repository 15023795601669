import React, { useState } from 'react';
import styled from '@emotion/styled';

import Collapse from 'components/collapse';
import { MemberMdfEditor } from 'components/mdfEditor/MemberMdfEditor';
import ResizableBox from 'components/resizableBox';
import Scrollbar from 'components/scrollbar';
import { Story } from 'types';
import { BOUNDS, ENABLE_RESIZING, INITIAL_SIZE, MIN_HEIGHT } from 'utils/constants/resizableBox';

const Wrapper = styled('div')`
  padding: 8px 20px 8px 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

interface Props {
  story: Story;
  useCollapse: boolean;
}

export const StoryMetadata: React.FC<Props> = ({ story, useCollapse }): JSX.Element => {
  const [open, setOpen] = useState(false);

  return useCollapse ? (
    <Collapse title="Metadata" open={open} setOpen={() => setOpen(!open)}>
      <ResizableBox
        initialSize={INITIAL_SIZE}
        enableResizing={ENABLE_RESIZING}
        minHeight={MIN_HEIGHT}
        bounds={BOUNDS}
        onResize={() => {}}
      >
        <Scrollbar>
          <Wrapper>
            <MemberMdfEditor view="story_view" member={story} />
          </Wrapper>
        </Scrollbar>
      </ResizableBox>
    </Collapse>
  ) : (
    <Scrollbar style={{ maxWidth: '650px' }}>
      <Wrapper>
        <MemberMdfEditor view="story_view" member={story} />
      </Wrapper>
    </Scrollbar>
  );
};
