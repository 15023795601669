import { createContext, useContext } from 'react';

import { type MultiSelectContextProps } from '../types';

const MultiSelectContext = createContext<MultiSelectContextProps | null>(null);

/** Hook to consume MultiSelectContext. */
const useMultiSelect = () => {
  const context = useContext(MultiSelectContext);
  if (!context) {
    throw new Error('useMultiSelect must be used within MultiSelectProvider');
  }
  return context;
};

export { MultiSelectContext, useMultiSelect };
