import React, { useMemo, memo } from 'react';
import RundownItemTypes from 'utils/rundownItemTypes';
import TitleBox from './view';
import SortAfterFilter from '../details/utils/SortAfterFilter';

const removeDisabledTemplates = (templates) => templates.filter(({ disabled }) => !disabled);

const TitleBoxContainer = ({ templates = [], ...props }) => {
  const { templateType, type } = props;

  const sortedSpec = useMemo(
    () =>
      SortAfterFilter(
        removeDisabledTemplates(templates),
        { type: RundownItemTypes[templateType] || type },
        'variant',
      ),
    [templateType, type, templates],
  );

  return <TitleBox spec={sortedSpec} {...props} />;
};

export default memo(TitleBoxContainer);
