import preventDefaultEvent from 'utils/preventDefaultEvent';

/**
 * Handles onContentKeyDown event for content block
 *
 * @param {Object} event React synthetic event
 * @param {Boolean} isContentElement whether the block is a content block or not
 * @param {Boolean} isEdge whether the cursor in start or end of element
 * @returns {Object} SlateJS editor instance
 */

const onContentKeyDown = (event, isContentElement, isEdge) => {
  if (isContentElement) {
    if (isEdge) preventDefaultEvent(event);
  } else preventDefaultEvent(event);
};

export default onContentKeyDown;
