/* eslint-disable default-case */
import { memo } from 'react';
import { useSortable } from '@dnd-kit/sortable';

import { DNDKitUseSortableProps } from 'types/dndkit/sortable';
import { MemberType } from 'types/graphqlTypes';

import Item from './Item';

interface RenderItemProps extends DNDKitUseSortableProps {
  member?: MemberType;
  parentLaneId: string;
}

interface Props {
  disabled?: boolean;
  id: string;
  renderItem?: (props: RenderItemProps) => JSX.Element;
  member?: MemberType;
  parentLaneId: string;
}

function SortableItem({ disabled, id, renderItem, member, parentLaneId }: Props) {
  const { setNodeRef, listeners, transform, transition }: DNDKitUseSortableProps = useSortable({
    id,
  });

  return (
    <Item
      listeners={listeners}
      ref={disabled ? undefined : setNodeRef}
      renderItem={renderItem}
      transform={transform}
      transition={transition}
      member={member}
      parentLaneId={parentLaneId}
    />
  );
}

export default memo(SortableItem);
