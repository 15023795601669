import { Editor, Range, Transforms } from 'slate';
import { v1 as uuidv1 } from 'uuid';

import { elementTypes } from 'components/editor/constants/types';
import { Update } from 'components/editor/types';
import { getSelectedElement } from 'components/editor/utils';
import { CustomData } from 'types';

import isList from '../../list/utils/isList';
import { insertParagraph } from '../../paragraph/utils';
import insertBlock from '../../toolbar/components/iconButton/utils/insertBlock';

import isSelectionInsideSectionDivider from './isSelectionInsideSectionDivider';

interface WrapSection {
  editor: Editor;
  update: Update;
  data: CustomData | {};
}
/**
 * @param editor SlateJs editor instance
 * @param update callback for state update
 * @param data additional data while inserting a section-divider element
 * @returns void
 */
const wrapSection = ({ editor, update, data = {} }: WrapSection) => {
  const block = {
    type: elementTypes.SECTION_DIVIDER,
    children: [{ type: elementTypes.PARAGRAPH, children: [{ text: '' }] }],
    data: { ...data, itemId: uuidv1() },
  };

  if (!editor?.selection) {
    insertBlock({
      ...block,
      editor,
      update,
      options: { select: true },
    });
    return;
  }

  if (isSelectionInsideSectionDivider(editor)) return;

  const selectedElement = getSelectedElement(editor);
  if (!selectedElement) return;

  if (
    Range.isCollapsed(editor.selection) &&
    !editor.isVoid(selectedElement) &&
    !isList(selectedElement)
  )
    insertParagraph(editor, { split: true });

  if (isList(selectedElement))
    insertParagraph(editor, { split: true, select: true, mode: 'highest' });

  Transforms.wrapNodes(editor, block, {
    at: Editor.unhangRange(editor, editor?.selection, { voids: true }),
    split: true,
    mode: 'all',
  });
};

export default wrapSection;
