import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import { IconButton as MuiIconButton } from '@material-ui/core';

export const ThumbnailWrapper = styled('div')`
  border-radius: 4px;
  overflow: hidden;
  width: 156px;
  height: 88px;
  margin-right: 12px;
  position: relative;
`;

export const IconButton = styled(MuiIconButton)`
  backdrop-filter: blur(15px);
  background-color: ${({ theme }) => theme.palette.dina.blackHoverOverlay};
  width: 32px;
  height: 32px;
  padding: 0px;
  :hover {
    background-color: ${({ theme }) => theme.palette.dina.blackHoverOverlay};
  }
`;

export const PlayImage = styled('img')`
  width: 32px;
  height: 32px;
`;

export const DurationIndicator = styled('div')`
  ${({ theme }) => css`
    ${theme.typography.dina.captionRegular};
    background-color: ${theme.palette.dina.blackInactive};
    color: ${theme.palette.dina.highEmphasis};
  `}
  width: 44px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  bottom: 0;
  border-radius: 0 4px;
  backdrop-filter: blur(15px);
`;

export const EmptyThumbnail = styled('div')`
  ${({ theme }) => css`
    border: 1px dashed ${theme.palette.dina.iconInactive};
    background-color: ${theme.palette.dina.blackHoverOverlay};
  `}
  width: 156px;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  border-radius: 2px;
`;
