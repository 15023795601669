import { useEffect, useRef, useState } from 'react';
import { SetStateAction } from 'jotai';
import { BaseSelection, Editor, Transforms } from 'slate';

import { useCreateInstance } from 'api/instance/useCreateInstance';
import { AddInstance } from 'components/addInstanceDialog/AddInstanceDialog';
import Button from 'components/buttons/button';
import {
  CreateInstanceButton,
  StyledHStack,
} from 'components/editor/components/hoveringTooltip/CreateInstanceButton';
import stopAllPropagation from 'components/editor/utils/stopAllPropagation';
import useToast from 'components/toast/useToast';
import Tooltip from 'components/tooltip';
import { CopyIcon } from 'features/orderForm/styled';
import useChatGPTSearch from 'hooks/useChatGPTSearch';
import useGetPlatforms from 'hooks/useGetPlatforms';
import { HStack } from 'layouts/box/Box';
import { getPlatform, PlatformData } from 'utils/instance/platform';

import insertParagraph from '../paragraph/utils/insertParagraph';

import {
  Body,
  ButtonsWrapper,
  Label,
  MUIPopover,
  ReplayWrapper,
  ResultContainer,
  TextArea,
} from './styled';

interface AskAIProps {
  question: string;
  isAsking: boolean;
  editor: Editor;
  selection: BaseSelection;
  selectedText: string;
  canReplace: boolean;
  onDone: () => void;
  setAddInstance: (val: SetStateAction<AddInstance>) => void;
}

const { insertText, select } = Transforms;

function AskAI({
  question,
  isAsking,
  editor,
  selection,
  selectedText,
  onDone,
  canReplace,
}: AskAIProps) {
  const { errorToast } = useToast();
  const { platforms } = useGetPlatforms(new Date());
  const { createInstance } = useCreateInstance();
  const [copying, setCopying] = useState(false);
  const [currentText, setCurrentText] = useState(selectedText);
  const [currentSelection, setCurrentSelection] = useState(selection);
  const searchChatGPT = useChatGPTSearch();
  const [result, setResult] = useState('');
  const [loading, setLoading] = useState(false);
  const [instanceLoading, setInstanceLoading] = useState(false);
  const popupRef = useRef<HTMLDivElement | null>(null);
  const onCopy = (ev: React.MouseEvent<HTMLElement, MouseEvent>) => {
    stopAllPropagation(ev);
    setCopying(true);
    void navigator.clipboard.writeText(result);
    setTimeout(() => setCopying(false), 2000);
  };

  const askAI = async () => {
    setLoading(true);
    const message = await searchChatGPT(question ? `${currentText} \n ${question}` : currentText);
    setResult(message);
    setLoading(false);
  };

  const replaceText = () => {
    insertText(editor, result);

    if (currentSelection) {
      const newAnchor = {
        ...(currentSelection?.anchor.path[0] < currentSelection?.focus.path[0]
          ? currentSelection?.anchor
          : currentSelection?.focus),
      };

      if (currentSelection?.anchor.path[0] === currentSelection?.focus.path[0]) {
        newAnchor.offset = Math.min(
          currentSelection?.anchor.offset,
          currentSelection?.focus.offset,
        );
      }
      setTimeout(() => {
        select(editor, {
          anchor: newAnchor,
          focus: { ...newAnchor, offset: result.length + newAnchor.offset },
        });
      }, 0);
    }

    onDone();
  };

  const appendText = () => {
    if (currentSelection) {
      const path0 = Math.max(currentSelection?.focus.path[0], currentSelection?.anchor.path[0]) + 1;

      insertParagraph(
        editor,
        {
          select: true,
          at: [path0],
        },
        result,
      );

      setTimeout(() => {
        select(editor, {
          anchor: { path: [path0, 0], offset: 0 },
          focus: { path: [path0, 0], offset: result.length },
        });
      }, 0);
    }

    onDone();
  };

  const onPlatformSelect = (val: PlatformData) => {
    setInstanceLoading(true);
    const platform = getPlatform(platforms, val?.platform ?? '', val?.platformKind);
    createInstance(val, result, platform)
      .then(() => {
        onDone();
      })
      .catch(errorToast)
      .finally(() => setInstanceLoading(false));
  };

  useEffect(() => {
    if (question && question !== '') {
      void askAI();
    }
  }, [question, isAsking]);

  useEffect(() => {
    setCurrentText(selectedText);
  }, [selectedText]);

  useEffect(() => {
    setCurrentSelection(selection);
  }, [selection]);

  return (
    <MUIPopover
      open={isAsking}
      elevation={0}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      onClose={onDone}
      onMouseDown={stopAllPropagation}
      onClick={stopAllPropagation}
    >
      <ResultContainer>
        <ReplayWrapper>
          <Body>
            <Label>Question</Label>
            <TextArea
              rows={5}
              value={currentText}
              onChange={(e) => {
                setCurrentText(e.target.value);
                if (result !== '') {
                  setResult('');
                }
              }}
              disabled={loading}
            />
          </Body>
          {(result || loading) && (
            <Body>
              <Label>Reply</Label>
              <TextArea
                rows={5}
                value={loading ? 'loading...' : result}
                disabled={loading}
                onChange={(e) => setResult(e.target.value)}
              />
            </Body>
          )}
        </ReplayWrapper>
        <ButtonsWrapper>
          <Button variant="outlined" usage="outlined" width={104} onClick={onDone}>
            Close
          </Button>
          {result ? (
            <HStack alignItems="center" ref={popupRef}>
              <Tooltip title="Create instance from answer">
                <CreateInstanceButton
                  onPlatformSelect={onPlatformSelect}
                  popupRef={popupRef}
                  loading={instanceLoading}
                />
              </Tooltip>
              <Tooltip title={copying ? 'Copied!' : 'Copy to clipboard'}>
                <StyledHStack onClick={onCopy} gap="4px">
                  <CopyIcon />
                  Copy text
                </StyledHStack>
              </Tooltip>
              {canReplace && (
                <>
                  <Button
                    variant="contained"
                    usage="warning"
                    autoWidth
                    onClick={replaceText}
                    disabled={result === ''}
                  >
                    Replace
                  </Button>
                  <Button
                    variant="contained"
                    usage="significant"
                    autoWidth
                    onClick={appendText}
                    disabled={result === ''}
                  >
                    Append
                  </Button>
                </>
              )}
            </HStack>
          ) : (
            <Button
              variant="contained"
              usage="cta"
              autoWidth
              onClick={() => void askAI()}
              disabled={loading}
            >
              {loading ? 'Asking...' : 'Ask AI'}
            </Button>
          )}
        </ButtonsWrapper>
      </ResultContainer>
    </MUIPopover>
  );
}

export default AskAI;
