import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { ReactComponent as InProgress } from 'assets/icons/systemicons/status/inProgress.svg';
import { ReactComponent as InReview } from 'assets/icons/systemicons/status/inReview.svg';
import { ReactComponent as Published } from 'assets/icons/systemicons/status/published.svg';
import { ReactComponent as Ready } from 'assets/icons/systemicons/status/ready.svg';
import { ReactComponent as ToDo } from 'assets/icons/systemicons/status/toDo.svg';
import { ReactComponent as Error } from 'assets/icons/systemicons/status/error.svg';
import { ReactComponent as Pending } from 'assets/icons/systemicons/status/pending.svg';
import { ReactComponent as Expired } from 'assets/icons/systemicons/status/expired.svg';
import { ReactComponent as Approved } from 'assets/icons/systemicons/status/approved.svg';
import { ReactComponent as Archived } from 'assets/icons/systemicons/status/archived.svg';

const ToDoStyled = styled(ToDo)`
  path {
    fill: ${({ theme }) => theme.palette.dina.highEmphasis};
    fill-opacity: 0.54;
  }
  rect {
    fill: ${({ theme }) => theme.palette.dina.highEmphasis};
    fill-opacity: 0.54;
  }
`;

const icons = {
  inProgress: <InProgress className="skipOverride" />,
  inReview: <InReview className="skipOverride" />,
  published: <Published className="skipOverride" />,
  ready: <Ready className="skipOverride" />,
  todo: <ToDoStyled className="skipOverride" />,
  error: <Error />,
  pending: <Pending />,
  expired: <Expired className="skipOverride" />,
  approved: <Approved className="skipOverride" />,
  archived: <Archived className="skipOverride" />,
};

const IconView = ({ status }) => icons[status];

IconView.propTypes = {
  /** Status for the icon */
  status: PropTypes.string,
};

IconView.defaultProps = {
  status: 'todo',
};

export default IconView;
