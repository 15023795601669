/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState, useCallback, useMemo } from 'react';
import { blockFormSplit } from 'operations/global-functions/utils/saveMetaFormOnContext';
import { useUpdateBlockForm } from 'screens/main/components/header/navbar/settings/hooks/useUpdateBlockForm';
import { useEditFormDialog, useEditFormOpenDialog, useBlockForms } from 'store';
import { Button } from 'components/buttons';
import TextArea from 'components/textArea';
import Scrollbar from 'components/scrollbar';
import { EditableMetadataEditor } from 'components/metadataEditor';
import MetadataField from './components/MetadataField';
import FormFields from './components/FormFields';
import Header from './components/Header';
import { normalizeField, createNewForm } from './utils';
import {
  StyledDialogTitle,
  StyledDialog,
  StyledConfirmButtonWrapper,
  StyledCancelButtonWrapper,
  StyledDialogActions,
  StyledDialogContent,
  ContentWrapper,
  EditingWrapper,
  Split,
  NoFieldsMessage,
  CenteredText,
  Input,
  PayloadWrapper,
  Overline,
  ViewWrapper,
} from './styled';

function EditFormDialog() {
  const [open, setOpen] = useEditFormOpenDialog();
  const [blockForms] = useBlockForms();
  const [isNew, setIsNew] = useState(false);
  const [mTitle, setMTitle] = useState('');
  const [hasDuplicateFieldValue, setHasDuplicateFieldValue] = useState(false);
  const [payload, setFormPayload] = useState({});
  const [editingForm, setEditingForm] = useState({});
  const [selectedField, setSelectedField] = useState(null);
  const [saveBlockForm, loading] = useUpdateBlockForm();
  const [state] = useEditFormDialog();

  const hasFields = useMemo(
    () => (editingForm?.mProperties?.fields ?? []).length > 0,
    [editingForm],
  );

  const setFields = useCallback(
    (newFields) => {
      setEditingForm((prevForm) => ({
        ...prevForm,
        mProperties: {
          ...prevForm.mProperties,
          fields: [...newFields],
        },
      }));
    },
    [setEditingForm],
  );

  const setPayload = useCallback(
    (p) => {
      setFormPayload((prevState) => ({
        ...prevState,
        [p.fieldId]: p.value,
      }));
    },
    [setFormPayload],
  );

  const removeFromPayload = useCallback(
    (fieldId) => {
      const prevState = {
        ...payload,
      };
      delete prevState[fieldId];
      setFormPayload(prevState);
    },
    [setFormPayload, payload],
  );

  const onConfirm = async (isCancel) => {
    if (!isCancel) {
      const formToSave = { ...editingForm };
      formToSave.mTitle = mTitle;

      // TODO - move the other save functions to the outside of this component.
      if (state.onOk) {
        state.onOk(formToSave);
      } else if (state.form) {
        await saveSpaceForm(formToSave);
      } else {
        await saveBlockForm(formToSave);
      }
    } else {
      if (state.onCancel) {
        state.onCancel();
      }
      // Set form state to initial state
      setEditingForm({});
      setMTitle('');
      setFormPayload({});
      setSelectedField({});
      setHasDuplicateFieldValue(false);
    }
    setOpen(false);
  };

  const selectField = useCallback((f) => {
    setSelectedField(f);
  }, []);

  const updateField = useCallback(
    (field) => {
      const index = editingForm.mProperties.fields.findIndex((f) => f.id === field.id);
      const otherFields = editingForm.mProperties.fields.filter((f) => f.id !== field.id);
      const updatedFields = [...otherFields];
      updatedFields.splice(index, 0, field);
      setEditingForm((prevForm) => ({
        ...prevForm,
        mProperties: {
          ...prevForm.mProperties,
          fields: updatedFields,
        },
      }));
    },
    [editingForm, setEditingForm],
  );

  const updateFieldProps = useCallback(
    ({ attribute, val }) => {
      if (editingForm && selectedField) {
        const updatedField = {
          ...selectedField,
          [attribute]: val,
        };
        const normalized = normalizeField(updatedField);
        setSelectedField(normalized);
        updateField(normalized);
      }
    },
    [selectedField, editingForm, updateField, setSelectedField],
  );

  const addNewField = useCallback(
    (field) => {
      if (editingForm) {
        const normalized = normalizeField(field);
        updateField(normalized);
        selectField(normalized);
      }
    },
    [editingForm, updateField, selectField],
  );

  const onDeleteField = useCallback(
    (fieldId) => {
      const fields = editingForm?.mProperties?.fields ?? [];
      const updatedFields = fields.filter((f) => f.id !== fieldId);
      setEditingForm((prevForm) => ({
        ...prevForm,
        mProperties: {
          ...prevForm.mProperties,
          fields: updatedFields,
        },
      }));
      removeFromPayload(fieldId);
      if (selectedField?.id === fieldId) {
        setSelectedField(null);
      }
    },
    [editingForm, removeFromPayload, setEditingForm, setSelectedField, selectedField],
  );

  useEffect(() => {
    const fields = editingForm?.mProperties?.fields ?? [];
    const exists = fields.find((f) => f.id === selectedField?.id);
    if (!selectedField && fields.length > 0) {
      setSelectedField(fields[0]);
    } else if (!exists && fields.length > 0) {
      setSelectedField(fields[0]);
    } else if (!exists) {
      setSelectedField(null);
    }
  }, [editingForm?.mProperties?.fields, selectedField]);

  useEffect(() => {
    if (selectedField?.type) {
      setPayload({ fieldId: selectedField.id, value: '' });
    }
  }, [selectedField?.type, selectedField?.id, setPayload]);

  useEffect(() => {
    if (state.form) {
      setEditingForm({ ...state.form });
      setMTitle(state.form.mTitle);
    } else if (state.formRefId) {
      // TODO - abstract away block form saving etc to outside of this component
      const { platform, blockType } = blockFormSplit(state.formRefId);
      const formToEdit = blockForms?.[platform]?.[blockType];
      if (formToEdit) {
        setEditingForm({ ...formToEdit });
        setMTitle(formToEdit.mTitle);
      } else {
        setIsNew(true);
        setMTitle('New form');
        setEditingForm(createNewForm(platform, blockType));
      }
    }
  }, [state.formRefId, state.form, blockForms, setEditingForm, setIsNew, setMTitle]);

  useEffect(() => {
    if (!open) {
      setFormPayload({});
      setSelectedField(null);
    }
  }, [open]);

  const EditingPart = useMemo(
    () => (
      <Split
        style={{
          position: 'relative',
        }}
        split="vertical"
        minSize={48}
        maxSize={-56}
        resizerStyle={{
          content: '""',
          width: '3px',
          height: '100%',
          display: 'block',
          textAlign: 'center',
          cursor: 'col-resize',
          backgroundColor: 'rgba(147, 157, 168, 0.2)',
        }}
        pane1Style={{
          minWidth: '20%',
          maxWidth: '80%',
        }}
        pane2Style={{
          minWidth: '20%',
          maxWidth: '80%',
          marginBottom: '12px',
        }}
        defaultSize={10}
        primary="second"
      >
        <Scrollbar>
          <ViewWrapper>
            {selectedField ? (
              <MetadataField
                setSelectedField={setSelectedField}
                field={selectedField}
                onChange={(p) => updateFieldProps(p)}
                updateField={updateField}
                setHasDuplicateFieldValue={setHasDuplicateFieldValue}
              />
            ) : (
              <NoFieldsMessage>
                <CenteredText>
                  {hasFields
                    ? 'Select a field on the left to edit'
                    : 'Create a field on the left to get started'}
                </CenteredText>
              </NoFieldsMessage>
            )}
          </ViewWrapper>
        </Scrollbar>
        {editingForm && (
          <Scrollbar>
            <ViewWrapper>
              <EditableMetadataEditor
                style={{ padding: '12px' }}
                model={editingForm}
                setFields={setFields}
                setPayload={setPayload}
                payload={payload}
              />
              <PayloadWrapper>
                <Overline>Payload JSON</Overline>
                <TextArea
                  severity="regular"
                  type="SoMe"
                  rows={8}
                  value={JSON.stringify(payload, null, 2)}
                />
              </PayloadWrapper>
            </ViewWrapper>
          </Scrollbar>
        )}
      </Split>
    ),
    [
      payload,
      setPayload,
      setFields,
      selectedField,
      editingForm,
      updateField,
      updateFieldProps,
      hasDuplicateFieldValue,
    ],
  );
  return (
    <StyledDialog
      open={open}
      onClose={() => onConfirm(true)}
      fullScreen
      fullWidth
      maxWidth="lg"
      scroll="body"
    >
      <StyledDialogTitle>
        {isNew ? 'Creating new' : 'Editing'} form:
        <Input name="header-input" value={mTitle} onChange={(e) => setMTitle(e.target.value)} />
      </StyledDialogTitle>
      <StyledDialogContent>
        <ContentWrapper>
          <FormFields
            form={editingForm}
            selectedField={selectedField}
            setSelectedField={setSelectedField}
            onDeleteField={onDeleteField}
          >
            <Header
              form={editingForm}
              setForm={setEditingForm}
              selectField={selectField}
              addNewField={addNewField}
            />
          </FormFields>
          <EditingWrapper>{EditingPart}</EditingWrapper>
        </ContentWrapper>
      </StyledDialogContent>
      <StyledDialogActions>
        <StyledCancelButtonWrapper>
          <Button onClick={() => onConfirm(true)} variant="outlined" usage="outlined">
            Cancel
          </Button>
        </StyledCancelButtonWrapper>
        <StyledConfirmButtonWrapper>
          <Button disabled={loading || hasDuplicateFieldValue} onClick={() => onConfirm(false)}>
            Save
          </Button>
        </StyledConfirmButtonWrapper>
      </StyledDialogActions>
    </StyledDialog>
  );
}

export default EditFormDialog;
