import useSettingsValue from 'hooks/useSettingsValue';

const useCheckPublishingPermission = () => {
  const [getSettingsValue] = useSettingsValue();

  const isPublishingRestrictedForPlatform = (platformName) => {
    if (!platformName) return false;
    const publishingRestrictedForPlatform =
      getSettingsValue(`platforms.${platformName}.requiresApproval`) === 'true';
    return publishingRestrictedForPlatform;
  };
  return [isPublishingRestrictedForPlatform];
};

export default useCheckPublishingPermission;
