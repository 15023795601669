/* eslint-disable sort-imports */
import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { omit } from 'lodash';

import { isUnsavedMdfField, UnsavedMdfField } from 'features/mdf/mdf-utils';
import {
  LayoutSettings,
  LayoutSettingsEntity,
  Mdf,
  MdfField,
  MdfFieldEntity,
  MdfType,
  UpdateMdfInput,
  Views,
  ViewsEntity,
  ViewTypes,
} from 'types/graphqlTypes';

import { STANDARD_MDF_KEYS } from '../commonKeys';

const CREATE_MDF = gql`
  mutation UpdateMdf($input: UpdateMdfInput) {
    updateMdf(input: $input) {
      ${STANDARD_MDF_KEYS}
    }
  }
`;

interface UpdateMdf {
  updateMdf: Mdf;
}

interface Input {
  input: UpdateMdfInput;
}

const toMdfFieldEntity = (field: UnsavedMdfField | MdfField): MdfFieldEntity => {
  const copy: MdfField = isUnsavedMdfField(field)
    ? { ...omit(field, ['isUnsaved', 'existsElseWhere']) }
    : { ...field };
  return {
    ...copy,
    defaultValue: JSON.stringify(field.defaultValue),
    constraint: field.constraint ? JSON.stringify(field.constraint) : undefined,
    filters: field.filters ? JSON.stringify(field.filters) : undefined,
  };
};

const toLayoutSettingsEntity = (setting: LayoutSettings): LayoutSettingsEntity => {
  return {
    ...setting,
    colors: JSON.stringify(setting.colors ?? '{}'),
  };
};

const toMdfViewsEntity = (views: Views): ViewsEntity => {
  const keys = Object.keys(views) as ViewTypes[];
  const emptyCopy: Record<string, LayoutSettingsEntity[]> = {};
  for (const key of keys) {
    emptyCopy[key] = [...(views[key] ?? []).map(toLayoutSettingsEntity)];
  }
  return emptyCopy as ViewsEntity;
};

const toMdfEntity = (mdf: Mdf): MdfType => {
  const copy = { ...mdf };
  delete copy.isSubtype;
  return {
    ...copy,
    views: toMdfViewsEntity(mdf.views),
    fields: mdf.fields.map(toMdfFieldEntity),
    permissions: {
      read: JSON.stringify(mdf.permissions.read),
      write: JSON.stringify(mdf.permissions.write),
    },
  };
};

export const useUpdateMdf = () => {
  const [updateMutation] = useMutation<UpdateMdf, Input>(CREATE_MDF);
  const updateMdf = useCallback(
    async (mdfToSave: Mdf) => {
      const result = await updateMutation({
        variables: {
          input: toMdfEntity(mdfToSave),
        },
      });
      return result?.data?.updateMdf;
    },
    [updateMutation],
  );
  return { updateMdf };
};
