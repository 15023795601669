import { atom, useAtom } from 'jotai';
import { keyBy } from 'lodash';

import { EditorCommandConfigType } from 'types/memberTypes/editorCommands';

const editorFocusedAtom = atom(false);
export const useEditorFocus = () => useAtom(editorFocusedAtom);

const editorCommandsAtom = atom<EditorCommandConfigType[]>([]);
const editorCommandsKeyed = atom((get) => {
  const commands = get(editorCommandsAtom);
  return keyBy(commands, (c) => c.mRefId);
});

export const useEditorCommandsKeyed = () => useAtom(editorCommandsKeyed);
export const useEditorCommands = () => useAtom(editorCommandsAtom);
