import { useContext } from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

import UserContext from 'contexts/UserContext';
import memberTypes from 'operations/memberTypes';
import CREATE_MESSAGE from 'operations/mutations/createMessage';
import DELETE_MESSAGE from 'operations/mutations/deleteMessage';
import UPDATE_MESSAGE from 'operations/mutations/updateMessage';
import { ConversationTypeEnum } from 'types/graphqlTypes';
import { Conversation } from 'types/messageHub';
import useLogger from 'utils/useLogger';

const GET_MESSAGES_OF_CONVERSATION = gql`
  query getMessagesOfConversation(
    $input: GetMessagesOfConversationInput
    $limit: Int
    $nextToken: String
  ) {
    getMessagesOfConversation(input: $input, limit: $limit, nextToken: $nextToken) {
      items {
        mId
        mRefId
      }
      nextToken
    }
  }
`;

type QueryResult = {
  getMessagesOfConversation: { items: Conversation[] };
};

const useCreateMessage = () => {
  const logger = useLogger('use create message');
  const [createMessage] = useMutation(CREATE_MESSAGE);
  const [deleteMessage] = useMutation(DELETE_MESSAGE);
  const [updateMessage] = useMutation(UPDATE_MESSAGE);

  const user = useContext(UserContext);
  const now = new Date().toISOString();

  const create = async (
    mId: string,
    mContent: string,
    convoType?: ConversationTypeEnum,
    mRefId?: string,
    crudAction?: 'UPDATE' | 'DELETE',
  ) => {
    if (crudAction === 'UPDATE') {
      try {
        await updateMessage({
          variables: {
            input: {
              mId,
              mRefId,
              mContent,
              crudAction,
              convoType,
            },
          },
          update: (cache) => {
            const { getMessagesOfConversation } =
              cache.readQuery<QueryResult>({
                query: GET_MESSAGES_OF_CONVERSATION,
                variables: {
                  input: {
                    mId,
                  },
                  limit: 25,
                },
              }) ?? {};
            const items = getMessagesOfConversation?.items || [];
            const newItems: Conversation[] = [];
            items.forEach((item) => {
              newItems.push({
                ...item,
                mContent: item.mRefId === mRefId ? mContent : item.mContent,
              });
            });
            cache.writeQuery({
              query: GET_MESSAGES_OF_CONVERSATION,
              variables: {
                input: {
                  mId,
                },
                limit: 25,
              },
              data: {
                getMessagesOfConversation: {
                  ...getMessagesOfConversation,
                  items: newItems,
                },
              },
            });
          },
        });
      } catch (e) {
        logger.error(e as string);
      }
    } else if (crudAction === 'DELETE') {
      try {
        await deleteMessage({
          variables: {
            input: {
              mId,
              mRefId,
              convoType,
              crudAction,
            },
          },
          update: (cache) => {
            const { getMessagesOfConversation } =
              cache.readQuery<QueryResult>({
                query: GET_MESSAGES_OF_CONVERSATION,
                variables: {
                  input: {
                    mId,
                  },
                  limit: 25,
                },
              }) ?? {};

            const items = getMessagesOfConversation?.items || [];
            const newItems = items.filter((message) => message.mRefId !== mRefId);

            cache.writeQuery({
              query: GET_MESSAGES_OF_CONVERSATION,
              variables: {
                input: {
                  mId,
                },
                limit: 25,
              },
              data: {
                getMessagesOfConversation: {
                  ...getMessagesOfConversation,
                  items: newItems,
                },
              },
            });
          },
        });
      } catch (e) {
        logger.error(e as string);
      }
    } else {
      try {
        await createMessage({
          variables: {
            input: {
              mId,
              mContent,
              convoType,
            },
          },
          update: (cache, mutationResult) => {
            const { createMessage: createMessageResult } = (mutationResult?.data ?? {}) as {
              createMessage: Conversation;
            };
            const { getMessagesOfConversation } =
              cache.readQuery<QueryResult>({
                query: GET_MESSAGES_OF_CONVERSATION,
                variables: {
                  input: {
                    mId,
                  },
                  limit: 25,
                },
              }) ?? {};
            const items = getMessagesOfConversation?.items || [];
            const isFound = items.find((message) => message.mRefId === createMessageResult.mRefId);
            const newMessageList = isFound ? items : [createMessageResult, ...items];
            cache.writeQuery({
              query: GET_MESSAGES_OF_CONVERSATION,
              variables: {
                input: {
                  mId,
                },
                limit: 25,
              },
              data: {
                getMessagesOfConversation: { ...getMessagesOfConversation, items: newMessageList },
              },
            });
          },
          optimisticResponse: {
            __typename: 'Mutation',
            createMessage: {
              mId,
              mRefId: now,
              mTitle: '',
              mCreatedAt: now,
              mUpdatedAt: now,
              mContent,
              convoType,
              mType: memberTypes.MESSAGE,
              mCreatedById: user.mId,
              mUpdatedById: user.mId,
              contentIds: [mId],
              __typename: 'MessageType',
            },
          },
        });
      } catch (e) {
        logger.error(e as string);
      }
    }
  };

  return [create];
};

export default useCreateMessage;
