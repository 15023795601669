import { actionTypes, elementTypes } from 'components/editor/constants/types';
import { Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import normalizeAssetData from 'utils/normalizeAssetData';
import normalizeIntoBlockData from 'components/editor/utils/normalizeIntoBlockData';
import notifyChange from 'components/editor/utils/notifyChange';

const { select, collapse, insertNodes } = Transforms;

const findElementType = ({ mediaType, itemType, itemDuration }) => {
  if (!mediaType.includes('placeholder')) {
    if (itemType === 'video' && itemDuration !== 0) return elementTypes.VIDEO;
    if (itemType === 'image') return elementTypes.IMAGE;
  }
  return elementTypes.PLACEHOLDER;
};

const onAssetDrop = async ({ payload }, editor, element, update) => {
  const assetData = normalizeAssetData(payload);

  try {
    const result = await update({
      type: actionTypes.CREATE_ASSET,
      payload: { document: editor.children, asset: assetData },
    });

    const asset = result.data.createAssets[0];

    if (asset) {
      const data = normalizeIntoBlockData({ ...payload, ...asset });

      const placeholderElement = {
        data,
        type: findElementType(data),
        children: [{ text: '' }],
      };

      const path = ReactEditor.findPath(editor, element);

      select(editor, path);
      collapse(editor, { edge: 'end' });
      insertNodes(editor, placeholderElement);
      notifyChange(editor, update);
    }
  } catch (error) {
    // logger.log(error);
  }
};

export default onAssetDrop;
