import styled from '@emotion/styled/macro';

export const Wrapper = styled('div')`
  height: 55px;
  background: ${({ theme }) => theme.palette.dina.surfaceCard};
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;
