import styled from '@emotion/styled';

export const RootWrapper = styled('div')`
  height: 50px;
  align-items: center;
  display: flex;
  user-select: none;
  background: ${({ theme }) => theme.palette.dina.backgroundResting};
`;

export const TextButtonWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px 0px 10px;
  width: 100%;
`;

export const Text = styled('div')`
  font-size: 18px;
`;
