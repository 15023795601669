import { atom, useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export const THEMES = ['dark', 'light', 'system'] as const;
export type ThemeMode = (typeof THEMES)[number];

const themeAtom = atomWithStorage<ThemeMode>('theme', 'dark');
export const useDinaTheme = () => useAtom(themeAtom);

// Used to change the system settings
const changedThemeAtom = atom<ThemeMode | undefined>(undefined);
export const useChangedTheme = () => useAtom(changedThemeAtom);
