import { Editor } from 'slate';
import isLink from './isLink';

const getSelectedLinkElement = (editor) => {
  try {
    const [match] = Editor.nodes(editor, { match: isLink });
    return match?.length ? match[0] : null;
  } catch (err) {
    return null;
  }
};

export default getSelectedLinkElement;
