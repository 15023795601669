import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '16px',
  },
  qrTitle: {
    ...theme.typography.dina.h5,
    color: theme.palette.dina.highEmphasis,
    marginBottom: '8px',
  },
  qrDescription: {
    ...theme.typography.dina.h7,
    color: theme.palette.dina.mediumEmphasis,
    marginBottom: '24px',
  },
}));

export default useStyles;
