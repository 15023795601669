import { atom, useAtom } from 'jotai';

import { Form, Order, RawOrder, ResourceType } from 'types/forms/forms';

// Temporary block type. Will be removed once we move everything to MDF
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type BlockForms = Record<string, any>;

const blockForms = atom<BlockForms>({});
export const useBlockForms = () => useAtom(blockForms);

const editFormDefaults: EditForm = {
  formRefId: null,
  form: null, // null if blocks, otherwise provide the form to edit
  open: false,
};

interface EditForm {
  formRefId: string | null;
  form: Form | null;
  open: boolean;
  onOk?: (updatedForm: Form) => void;
  onCancel?: () => void;
}

const editFormDialog = atom<EditForm>({
  ...editFormDefaults,
});
const editForm = atom<Form | null>(null);

const editFormOpen = atom(false);
const editFormDialogAtom = atom<EditForm, [EditForm], void>(
  (get) => get(editFormDialog),
  (get, set, val) => {
    set(editFormDialog, val);
    const { open } = val;
    if (open) {
      set(editFormOpen, true);
    }
  },
);

const openDialogAtom = atom<boolean, [boolean], void>(
  (get) => get(editFormOpen),
  (get, set, val) => {
    set(editFormOpen, val);
    if (!val) {
      // Clear after 500 ms to avoid state hopping around while animating out
      setTimeout(() => {
        set(editFormDialog, { ...editFormDefaults });
      }, 500);
    }
  },
);

export const useEditingForm = () => useAtom(editForm);
export const useEditFormDialog = () => useAtom(editFormDialogAtom);
export const useEditFormOpenDialog = () => useAtom(openDialogAtom);

/**
 * Everything related to bringing up an order form below
 */

const orderFormDialogDefaults: EditOrderForm = {
  resourceId: '',
  mFormId: '',
  mdfId: '',
  resourceType: 'instance',
  open: false,
};

interface EditOrderForm {
  mFormId: string;
  mdfId: string;
  resourceId: string;
  resourceType: ResourceType;
  open: boolean;
  onConfirm?: (result: RawOrder) => void;
  onCancel?: () => void;
  orderToEdit?: Order;
}

const orderFormDialog = atom<EditOrderForm>({
  ...orderFormDialogDefaults,
});

const orderFormOpen = atom(false);
const orderFormDialogAtom = atom<EditOrderForm, [EditOrderForm], void>(
  (get) => get(orderFormDialog),
  (get, set, val) => {
    set(orderFormDialog, val);
    const { open } = val;
    if (open) {
      set(orderFormOpen, true);
    }
  },
);

const openOrderFormDialogAtom = atom<boolean, [boolean], void>(
  (get) => get(orderFormOpen),
  (get, set, val) => {
    set(orderFormOpen, val);
    if (!val) {
      // Clear after 500 ms to avoid state hopping around while animating out
      setTimeout(() => {
        set(editFormDialog, { ...editFormDefaults });
      }, 500);
    }
  },
);

export const useOrderFormDialog = () => useAtom(orderFormDialogAtom);
export const useOrderFormOpenDialog = () => useAtom(openOrderFormDialogAtom);

/**
 * Everything related to bringing up orders below
 */

const orderDetails: OrderDialog = {
  resourceId: '',
  resourceTitle: '',
  resourceType: 'story',
  formTypes: [],
  open: false,
  type: 'resource',
};

const myOrderDetails: MyOrderDialog = {
  resourceId: '',
  open: false,
  type: 'user',
};

interface OrderDialog {
  resourceId: string;
  resourceTitle: string;
  resourceType: ResourceType;
  formTypes: string[]; // which resource types can a resource be ordered for
  type: 'resource';
  open: boolean;
}

interface MyOrderDialog {
  resourceId: string;
  type: 'user';
  open: boolean;
}

interface FormFilter {
  types: string[] | null;
}

const orderFormFilter = atom<FormFilter>({ types: null });

const orderDetailsDialog = atom<OrderDialog>({
  ...orderDetails,
});

const myOrderDetailsDialog = atom<MyOrderDialog>({
  ...myOrderDetails,
});

const orderDialogOpen = atom(false);
const myOrderDialogOpen = atom(false);
const orderDialogAtom = atom<OrderDialog, [OrderDialog], void>(
  (get) => get(orderDetailsDialog),
  (get, set, val) => {
    set(orderDetailsDialog, val);
    const { open } = val;
    if (open) {
      set(orderDialogOpen, true);
    }
  },
);
const myOrderDialogAtom = atom<MyOrderDialog, [MyOrderDialog], void>(
  (get) => get(myOrderDetailsDialog),
  (get, set, val) => {
    set(myOrderDetailsDialog, val);
    const { open } = val;
    if (open) {
      set(myOrderDialogOpen, true);
    }
  },
);

const openOrderDialogAtom = atom<boolean, [boolean], void>(
  (get) => get(orderDialogOpen),
  (get, set, val) => {
    set(orderDialogOpen, val);
    if (!val) {
      // Clear after 500 ms to avoid state hopping around while animating out
      setTimeout(() => {
        // set(orderDetailsDialog, { ...orderDetails });
      }, 500);
    }
  },
);
const myOpenOrderDialogAtom = atom<boolean, [boolean], void>(
  (get) => get(myOrderDialogOpen),
  (get, set, val) => {
    set(myOrderDialogOpen, val);
    if (!val) {
      // Clear after 500 ms to avoid state hopping around while animating out
      setTimeout(() => {
        // set(orderDetailsDialog, { ...orderDetails });
      }, 500);
    }
  },
);
export const useOrdersDialog = () => useAtom(orderDialogAtom);
export const useOrdersOpenDialog = () => useAtom(openOrderDialogAtom);
export const useOrderFormFilter = () => useAtom(orderFormFilter);

export const useMyOrdersDialog = () => useAtom(myOrderDialogAtom);
export const useMyOrdersOpenDialog = () => useAtom(myOpenOrderDialogAtom);

export default useBlockForms;
