import SortAfterFilter from 'components/editor/components/details/utils/SortAfterFilter';

/**
 * Gets defalult template for a given automation type
 *
 * @param {String} type Automation type
 * @param {Object[]} templates List of templates
 * @returns {Object} mos asset object
 */

const getDefaultTemplate = (type, templates) => {
  const sortedSpec = SortAfterFilter(templates, { type }, ['defaultVariant', 'variant']);

  if (sortedSpec.length > 0) return sortedSpec[0];

  return { variant: 'VARIANT', name: '', description: '' };
};

export default getDefaultTemplate;
