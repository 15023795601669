// eslint-disable-next-line import/no-extraneous-dependencies
import { PDFViewer } from '@react-pdf/renderer';

const PdfViewer = ({ children }) => {
  return (
    <div
      style={{
        width: '90vw',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <PDFViewer height="100%" width="100%">
        {children}
      </PDFViewer>
    </div>
  );
};

export default PdfViewer;
