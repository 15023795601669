import { Editor } from 'slate';

import { CustomText } from 'types';

export type MarkType = keyof Omit<CustomText, 'text'>;

/**
 * Gets mark of specified type
 *
 * @param editor SlateJS editor instance
 * @param type Type for the mark
 * @returns null or
 */

const getMark = (editor: Editor, type: MarkType) => {
  const marks = Editor.marks(editor);

  return marks?.[type];
};

export default getMark;
