import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { ReactComponent as Task } from 'assets/icons/search/task.svg';
import { ReactComponent as Instance } from 'assets/icons/systemicons/ContainerLeft_Navbar/instances_off.svg';
import { ReactComponent as Story } from 'assets/icons/systemicons/ContainerLeft_Navbar/Left_AllStories_off.svg';
import { ReactComponent as Rundown } from 'assets/icons/systemicons/ContainerLeft_Navbar/rundowns_off.svg';
import { ReactComponent as Pitch } from 'assets/icons/systemicons/pitch_green.svg';
import { ReactComponent as Space } from 'assets/icons/systemicons/spaces_off.svg';

const IconStyles = css`
  cursor: pointer;
  width: 20px;
  height: 20px;
  &:hover path {
    fill-opacity: 1;
  }
`;

export const InstanceIcon = styled(Instance)`
  ${IconStyles}
`;

export const RundownIcon = styled(Rundown)`
  ${IconStyles}
`;

export const StoryIcon = styled(Story)`
  ${IconStyles}
`;

export const SpacesIcon = styled(Space)`
  ${IconStyles}
`;

export const TaskIcon = styled(Task)`
  ${IconStyles}
`;

export const PitchIcon = styled(Pitch)`
  ${IconStyles}
`;
