import styled from '@emotion/styled/macro';

const DashedWrapper = styled('tr')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  width: '100%';
  min-height: 100%;
`;

interface Props {
  children?: React.ReactNode;
}

/** A component to show when there is no data to display. */
export function EmptyState({ children = 'Nothing to show' }: Readonly<Props>) {
  return <DashedWrapper>{children}</DashedWrapper>;
}
