import { atom, useAtom } from 'jotai';

import { DailyNote } from 'types';

const selectedDateAtom = atom(new Date());

selectedDateAtom.onMount = (set) => {
  set(new Date());
};

export const useSelectedDailyNoteDate = () => useAtom(selectedDateAtom);

const hasUpdatedSubscriptionAtom = atom(false);
export const useHasUpdatedSubscription = () => useAtom(hasUpdatedSubscriptionAtom);

const currentDailyNoteAtom = atom<DailyNote | null | undefined>(null);
export const useCurrentDailyNote = () => useAtom(currentDailyNoteAtom);
