import { MMetaDataField } from 'types/graphqlTypes';

const getHostReadSpeed = (metadata: MMetaDataField[], defaultReadSpeed = 150) => {
  if (!Array.isArray(metadata)) return defaultReadSpeed;
  const readSpeedField = metadata.find((val) => val.key === 'hostReadSpeed');
  const rr = Number(readSpeedField?.value);
  return !isNaN(rr) && rr > 0 ? rr : defaultReadSpeed;
};

export default getHostReadSpeed;
