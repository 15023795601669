import { useQuery } from '@apollo/client';

import GET_POLICY from 'operations/queries/getPolicy';
import { MemberType } from 'types/graphqlTypes';

interface FetchGroupPolicy {
  getMemberFromId: MemberType[];
}

export default function useGetGroupPolicy() {
  const { data, error, loading } = useQuery<FetchGroupPolicy>(GET_POLICY, {
    variables: {
      input: {
        mId: 'groupPolicy',
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  return { groupPolicies: data?.getMemberFromId, error, loading };
}
