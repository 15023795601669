/**
 * return true/false whether the platform passed as argument matches with platform
 * on mProperties of the instance
 *
 * @param instance
 * @param platform
 * @returns {boolean}
 */
const checkInstancePlatform = (instance, platform) => {
  const { mProperties } = instance || {};
  if (!mProperties) return false;
  return mProperties.platform === platform;
};

/**
 * Returns true if the instance is a linear instance.
 * @param {*} instance
 */
const isLinearInstance = (instance) => checkInstancePlatform(instance, 'linear');

/**
 * Returns true if the instance is a cms instance.
 * @param {*} instance
 */
const isCmsInstance = (instance) => checkInstancePlatform(instance, 'cms');

export { isCmsInstance, isLinearInstance, checkInstancePlatform };
