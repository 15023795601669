const isEmptyModel = (model) =>
  !(
    model?.freeText ||
    (model?.user && model.user.length > 0) ||
    (model?.platform && model.platform.length > 0) ||
    (model?.team && model.team.length > 0) ||
    (model?.department && model.department.length > 0)
  );

export default isEmptyModel;
