import GET_MEMBERS_OF from 'operations/queries/getMembersOf';
import { getMembersOfQuery } from 'operations/queryVariables';

const getUpdatedList = (list, item) => {
  const { crudAction } = item.message;
  if (crudAction === 'INSERT') {
    const memberExist = list.filter((t) => t.mId === item.mId);
    if (memberExist.length === 0) {
      list.push(item);
    }
    return list;
  }
  return list.filter((t) => t.mId !== item.mId);
};

const updateMemberListOnLeftSide = (proxy, item) => {
  try {
    const { user, mType } = item.message;
    const variables = getMembersOfQuery(user.mId, mType);
    let hasQueryInCache = true;
    let list;
    try {
      list = proxy.readQuery({
        query: GET_MEMBERS_OF,
        variables,
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      hasQueryInCache = false;
    }
    if (hasQueryInCache) {
      const updatedList = getUpdatedList([...list.getMembersOf], item);
      // Write updated member list in the cache.
      proxy.writeQuery({
        query: GET_MEMBERS_OF,
        variables,
        data: {
          getMembersOf: updatedList,
        },
      });
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

const updateMember = (proxy, item) => {
  try {
    const { loggedInUserId, user } = item.message;
    const isCurrentMemberUpdated = user.mId === loggedInUserId;
    if (isCurrentMemberUpdated) {
      updateMemberListOnLeftSide(proxy, item);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};

export default updateMember;
