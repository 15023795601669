import { useMutation } from '@apollo/client';
import GET_RUNDOWN from 'operations/queries/getRundown';
import MOVE_INSTANCE_TO_RUNDOWN from 'operations/mutations/moveInstanceToRundown';
import useLogger from 'utils/useLogger';

const useMoveInstanceToRundown = (onInstanceMoved) => {
  const logger = useLogger('use move instance');
  const [moveInstanceToRundownMutation] = useMutation(MOVE_INSTANCE_TO_RUNDOWN);

  const updateCacheForSourceRundown = (proxy, sourceRundown, mId) => {
    try {
      const originRundown = proxy.readQuery({
        query: GET_RUNDOWN,
        variables: { input: sourceRundown },
      });

      const { mOrder, mPreorder } = originRundown.getRundown;

      proxy.writeQuery({
        query: GET_RUNDOWN,
        variables: { input: sourceRundown },
        data: {
          getRundown: {
            ...originRundown.getRundown,
            mOrder: mOrder.filter((order) => order !== mId),
            mPreorder: mPreorder.filter((preOrder) => preOrder !== mId),
          },
        },
      });
    } catch (err) {
      logger.log(err);
    }
  };

  const moveInstanceToRundown = async (mId, sourceRundown, destinationRundown, copy) => {
    if (!destinationRundown.mId) return;
    const tRundown = {
      mId: destinationRundown.mId,
      mRefId: destinationRundown.mRefId,
    };

    const input = { mIds: [mId], targetRundown: tRundown, copy };

    if (tRundown.mId === sourceRundown?.mId) return;

    if (sourceRundown) {
      const sRundown = { mId: sourceRundown.mId, mRefId: sourceRundown.mRefId };
      input.sourceRundown = sRundown;
    }

    await moveInstanceToRundownMutation({
      variables: { input },
      update: (proxy, result) => {
        const item = Array.isArray(data?.moveInstanceToRundown) && data?.moveInstanceToRundown[0];

        const instanceMoved =
          !copy && sourceRundown && item.mProperties?.account.accountId === sourceRundown.mId;

        if (instanceMoved) {
          updateCacheForSourceRundown(proxy, sourceRundown, mId);
          if (onInstanceMoved) onInstanceMoved();
        }
      },
    });
  };

  return [moveInstanceToRundown];
};

export default useMoveInstanceToRundown;
