import styled from '@emotion/styled/macro';
import transientOptions from 'theme/helpers';
import { css } from '@emotion/react';
import { Typography } from '@material-ui/core';

const commonContainerStyle = ({ theme }) => css`
  width: 100%;
  background-color: ${theme.palette.dina.onSelected};
`;

const container = css`
  width: 40px;
  height: 20px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const rightContainer = () => css`
  ${commonContainerStyle};
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
`;

const commonStyle = ({ theme }) => css`
  height: 24px;
  width: 59px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    & * {
      color: ${theme.palette.dina.highEmphasis};
    }
  }
`;

const rootToday = ({ theme }) => css`
  ${commonStyle({ theme })};
  background-color: ${theme.palette.dina.blackHoverOverlay};
  border: 1px solid ${theme.palette.dina.onSelected};
  border-radius: 12px;
  box-sizing: border-box;
`;

const rootSelected = ({ theme }) => css`
  ${commonStyle({ theme })};
  background-color: ${theme.palette.dina.onSelected};
  border-radius: 12px;
`;

const rootSelectedLeft = ({ theme }) => css`
  ${commonStyle({ theme })};
  background-color: ${theme.palette.dina.onSelected};
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
`;

const rootSelectedMiddle = ({ theme }) => css`
  ${commonStyle({ theme })};
  background-color: ${theme.palette.dina.onSelected};
`;
const rootSelectedRight = ({ theme }) => css`
  ${commonStyle({ theme })};
  background-color: ${theme.palette.dina.onSelected};
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
`;

const rootTodaySelected = ({ theme }) => css`
  ${commonStyle({ theme })};
  width: 26px;
  height: 26px;
  background-color: ${theme.palette.dina.blackHoverOverlay};
  border: 1px solid ${theme.palette.dina.onSelected};
  border-radius: 2px;
  transform: rotate(-45deg);
`;

const commonTextStyle = ({ theme }) => css`
  ${theme.typography.dina.listItemGroupHeadersLabel};
  color: ${theme.palette.dina.mediumEmphasis};
  letter-spacing: 0.25px;
  font-weight: normal;
`;
const textDimmed = ({ theme }) => css`
  ${commonTextStyle({ theme })};
  color: ${theme.palette.dina.disabled};
`;
const textWeekend = ({ theme }) => css`
  ${commonTextStyle({ theme })};
  color: ${theme.palette.dina.datePickerHoliday};
`;
const textToday = ({ theme }) => css`
  ${commonTextStyle({ theme })};
  color: ${theme.palette.dina.onSelected};
`;
const textTodaySelectedDiamond = ({ theme }) => css`
  ${commonTextStyle({ theme })};
  color: ${theme.palette.dina.highEmphasis};
  transform: rotate(45deg);
`;
const textSelected = ({ theme }) => css`
  ${commonTextStyle({ theme })};
  color: ${theme.palette.dina.highEmphasis};
`;

export const ContainerWrapper = styled('div', transientOptions)`
  ${({ $containerType, theme }) => {
    switch ($containerType) {
      case 'container':
        return container;
      case 'middleContainer':
        return commonContainerStyle({ theme });
      case 'rightContainer':
        return rightContainer();
      default:
        return '';
    }
  }}
`;

export const RootWrapper = styled('div', transientOptions)`
  ${({ $rootType }) => {
    switch ($rootType) {
      case 'root':
        return commonStyle;
      case 'rootToday':
        return rootToday;
      case 'rootSelected':
        return rootSelected;
      case 'rootSelectedLeft':
        return rootSelectedLeft;
      case 'rootSelectedMiddle':
        return rootSelectedMiddle;
      case 'rootSelectedRight':
        return rootSelectedRight;
      case 'rootTodaySelected':
        return rootTodaySelected;
      default:
        return '';
    }
  }}

  cursor:  ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ $disabled }) => ($disabled ? 0.15 : 1)};

  &:hover {
    ${({ $selectingRange }) => ($selectingRange ? rootSelected : '')}
  }
`;

export const TypographyWrapper = styled(Typography, transientOptions)`
  ${({ $typographyType }) => {
    switch ($typographyType) {
      case 'text':
        return commonTextStyle;
      case 'textDimmed':
        return textDimmed;
      case 'textWeekend':
        return textWeekend;
      case 'textToday':
        return textToday;
      case 'textTodaySelectedDiamond':
        return textTodaySelectedDiamond;
      case 'textSelected':
        return textSelected;
      default:
        return commonStyle;
    }
  }}
`;
