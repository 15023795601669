import styled from '@emotion/styled';

export const StyledImg = styled('img')`
  width: 24px;
  height: 24px;
`;

export const ListItem = styled('li')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.palette.dina.hoverOverlay};
  }

  svg {
    width: 20px;
  }
`;
