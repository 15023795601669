import { Metadata } from 'types/forms/forms';
import { BoardType, ColumnType, MemberType } from 'types/graphqlTypes';
import { ParsedMemberType } from 'types/members';

import { ParsedBoardType } from './types';

// --- Parse the member metadata ---
export const parseMember = (member: MemberType): ParsedMemberType => {
  return {
    ...member,
    mId: member.mId as string,
    metadata: JSON.parse(member.metadata ?? '{}') as Metadata,
  };
};

// --- Parse the board data ---
export const parseBoard = (boardData: BoardType): ParsedBoardType => {
  const columns = boardData.columns ?? [];
  return {
    ...boardData,
    columns: columns.map((column: ColumnType) => ({
      ...column,
      members: column.members.map(parseMember),
    })),
  };
};
