import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  tagButton: {
    position: 'absolute',
    width: '24px',
    height: '24px',
    backdropFilter: 'blur(15px)',
    zIndex: 1,
    backgroundColor: theme.palette.dina.blackHoverOverlay,
    '&:hover': {
      backgroundColor: theme.palette.dina.blackHoverOverlay,
    },
    bottom: '4px',
    left: '4px',
  },
  tagImage: {
    width: '20px',
    height: '20px',
  },
}));

export default useStyles;
