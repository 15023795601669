/* eslint-disable import/no-extraneous-dependencies */
import curry from 'lodash/fp/curry';
import { Editor, Path, Transforms } from 'slate';

/**
 * Sets the indent level on paragraph element data
 *
 * @param editor SlateJS editor instance
 * @param path SlateJS location path
 * @returns SlateJS editor instance
 */
const setIndentLevel = (editor: Editor, path: Path, indentLevel: number) => {
  Transforms.setNodes(
    editor,
    {
      data: { indentLevel },
    },
    { at: path },
  );

  return editor;
};

export default curry(setIndentLevel);
