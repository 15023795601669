import { atom, useAtom } from 'jotai';
import { createScope, molecule, useMolecule } from 'jotai-molecules';

import { Version } from 'types';
import { EditorValue } from 'types/editor';

type ReformedVersion = Version & { count: number };

export const VersionHistoryScope = createScope(undefined);

const versionHistoryMolecule = molecule((_, getScope) => {
  getScope(VersionHistoryScope);

  const selectedContentAtom = atom<EditorValue | null>(null);
  const showFullTimelineAtom = atom<boolean>(false);

  const auditAtom = atom<ReformedVersion | null>(null);
  const currentAuditAtom = atom(
    (get) => get(auditAtom),
    (_get, set, payload: ReformedVersion) => {
      set(auditAtom, payload);
    },
  );

  return {
    useSelectedContent: () => useAtom(selectedContentAtom),
    useShowFullTimeline: () => useAtom(showFullTimelineAtom),
    useCurrentAudit: () => useAtom(currentAuditAtom),
  };
});

export const useVersionHistoryMolecule = () => useMolecule(versionHistoryMolecule);
