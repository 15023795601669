/* eslint-disable max-len */
import { ReactComponent as CameraSmall } from 'assets/icons/systemicons/rundown/primary_videocamera_small.svg';
import { ReactComponent as Camera } from 'assets/icons/systemicons/rundown/primary_videocamera.svg';
import { ReactComponent as PackageSmall } from 'assets/icons/systemicons/rundown/primary_videoclip_small.svg';
import { ReactComponent as Package } from 'assets/icons/systemicons/rundown/primary_videoclip.svg';
import { ReactComponent as LiveSmall } from 'assets/icons/systemicons/rundown/primary_live_small.svg';
import { ReactComponent as Live } from 'assets/icons/systemicons/rundown/primary_live.svg';
import { ReactComponent as FullScreenGraphicsSmall } from 'assets/icons/systemicons/rundown/primary_fullscreengraphic_small.svg';
import { ReactComponent as FullScreenGraphics } from 'assets/icons/systemicons/rundown/primary_fullscreengraphic.svg';
import { ReactComponent as BreakSmall } from 'assets/icons/systemicons/rundown/primary_break_small.svg';
import { ReactComponent as Break } from 'assets/icons/systemicons/rundown/primary_break.svg';

import { ReactComponent as TelephoneSmall } from 'assets/icons/systemicons/rundown/primary_phone_small.svg';
import { ReactComponent as Telephone } from 'assets/icons/systemicons/rundown/primary_phone.svg';

import { ReactComponent as LiveAlt } from 'assets/icons/systemicons/rundown/primary_live_alt_small.svg';

import { ReactComponent as JingleSmall } from 'assets/icons/systemicons/rundown/primary_jingle_small.svg';
import { ReactComponent as Jingle } from 'assets/icons/systemicons/rundown/primary_jingle.svg';

import { ReactComponent as FloatSmall } from 'assets/icons/systemicons/rundown/primary_float_small.svg';
import { ReactComponent as Float } from 'assets/icons/systemicons/rundown/primary_float.svg';
import { ReactComponent as Adlib } from 'assets/icons/systemicons/rundown/primary_adlib.svg';
import { ReactComponent as overlayGraphicsSmall } from 'assets/icons/systemicons/rundown/secondary_graphic.svg';
import { ReactComponent as AudioSmall } from 'assets/icons/systemicons/rundown/secondary_audio.svg';
import { ReactComponent as TextTelePrompterSmall } from 'assets/icons/systemicons/rundown/secondary_prompter.svg';
import { ReactComponent as AccessoriesSmall } from 'assets/icons/systemicons/rundown/secondary_code.svg';
import { ReactComponent as MosSmall } from 'assets/icons/systemicons/rundown/mos.svg';

const RundownIconsSmall = {
  camera: CameraSmall,
  package: PackageSmall,
  voiceOver: PackageSmall,
  live: LiveSmall,
  fullscreenGraphics: FullScreenGraphicsSmall,
  dve: LiveSmall,
  break: BreakSmall,
  telephone: TelephoneSmall,
  jingle: JingleSmall,
  float: FloatSmall,
  adlib: Adlib,
  overlayGraphics: overlayGraphicsSmall,
  audio: AudioSmall,
  textTelePrompter: TextTelePrompterSmall,
  liveAlt: LiveAlt,
  accessory: AccessoriesSmall,
  mos: MosSmall,
};

const RundownIcons = {
  camera: Camera,
  package: Package,
  live: Live,
  voiceOver: Package,
  fullscreenGraphics: FullScreenGraphics,
  dve: Live,
  jingle: Jingle,
  break: Break,
  float: Float,
  telephone: Telephone,
  adlib: Adlib,
  overlayGraphics: overlayGraphicsSmall,
  audio: AudioSmall,
  textTelePrompter: TextTelePrompterSmall,
  accessory: AccessoriesSmall,
  mos: MosSmall,
};

export { RundownIconsSmall, RundownIcons };
