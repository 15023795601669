import React from 'react';
import { ReactComponent as Delete } from 'assets/icons/systemicons/delete.svg';

const menuOptions = [
  {
    title: 'Delete this Block item',
    action: 'delete-block',
    icon: <Delete />,
  },
];

export default menuOptions;
