import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';

import { useCreateMdf } from 'api/mdf/useCreateMdf';
import { useDeleteMdf } from 'api/mdf/useDeleteMdf';
import { useGetMdfs } from 'api/mdf/useGetMdfs';
import { useCreateOptionList } from 'api/optionLists/useCreateOptionList';
import { useDeleteOptionList } from 'api/optionLists/useDeleteOptionList';
import { useGetOptionLists } from 'api/optionLists/useGetOptionLists';
import { useUpdateOptionList } from 'api/optionLists/useUpdateOptionList';
import { DeleteDialog } from 'components/dialogs/CommonDialogs';
import { EditMdf } from 'components/editMdfDialog/EditMdf';
import { useEditStringDialog } from 'components/editStringDialog/EditStringDialog';
import LoadingIndicator from 'components/loadingIndicator';
import Scrollbar from 'components/scrollbar';
import SplitBar from 'components/split';
import useToast from 'components/toast/useToast';
import { useGetMdfIcon } from 'features/mdf/useGetMdfIcon';
import useCheckUserRight from 'hooks/useCheckUserRight';
import { Mdf, OptionList } from 'types/graphqlTypes';

import { useChangedMdfs, useSelectMdfSchema } from '../../atomsTs';
import SettingsListItem from '../layout/SettingsListItem';
import { SettingsListSection } from '../layout/SettingsListSection';
import CreateOptionListDialog from '../optionList/CreateOptionListDialog';
import { OptionListComponent } from '../optionList/OptionList';

import CreateSchemaDialog, { SchemaType } from './CreateSchemaDialog';

import { FormsWrapper, Wrapper } from './styled';

interface MdfListItemProps {
  mdf: Mdf;
  isDefault: boolean;
  selectedForm: Mdf | null;
  changedMdfs: Record<string, Mdf>;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  setSelectedSchemaId: React.Dispatch<React.SetStateAction<string | null>>;
  setSelectedListId: React.Dispatch<React.SetStateAction<string | null>>;
  openEditLabelDialog: (updatedMdf: Mdf | null, originalMdf: Mdf) => void;
  doDelete: () => void;
  getMdfIcon?: (mdf: Mdf) => React.FC<React.SVGProps<SVGSVGElement>> | undefined;
}

interface OptionListItemProps {
  list: OptionList;
  selectedList: OptionList | null;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  onDoEditLabel: () => void;
  setSelectedSchemaId: React.Dispatch<React.SetStateAction<string | null>>;
  setSelectedListId: React.Dispatch<React.SetStateAction<string | null>>;
  doDelete: () => void;
}

function MdfListItem({
  changedMdfs,
  mdf,
  setSelectedSchemaId,
  setSelectedListId,
  selectedForm,
  isDefault,
  openEditLabelDialog,
  doDelete,
  getMdfIcon,
}: Readonly<MdfListItemProps>) {
  const updatedMdf = changedMdfs[mdf.id];
  const Icon = getMdfIcon ? getMdfIcon(mdf) : null;
  return (
    <SettingsListItem
      Icon={Icon ?? undefined}
      selected={mdf.id === selectedForm?.id}
      tooltip={updatedMdf && `${updatedMdf?.label ?? mdf.label} has unsaved changes`}
      onClick={() => {
        setSelectedSchemaId(mdf.id);
        setSelectedListId(null);
      }}
      onDeleteClick={doDelete}
      onEditClick={() => openEditLabelDialog(changedMdfs[mdf.id] ?? null, mdf)}
      label={updatedMdf?.label ?? mdf.label}
      hasChange={!!updatedMdf}
      hideActions={isDefault}
    />
  );
}

function OptionListItem({
  list,
  setSelectedSchemaId,
  setSelectedListId,
  selectedList,
  doDelete,
  onDoEditLabel,
}: Readonly<OptionListItemProps>) {
  return (
    <SettingsListItem
      selected={list.id === selectedList?.id}
      tooltip=""
      onClick={() => {
        setSelectedSchemaId(null);
        setSelectedListId(list.id);
      }}
      onDeleteClick={doDelete}
      onEditClick={onDoEditLabel}
      label={list.label}
      hasChange={false}
    />
  );
}

export function MdfSchemas() {
  const { errorToast } = useToast();
  const { mdfsSeparated, mdfs, loading, error } = useGetMdfs({ all: true });
  const { optionLists } = useGetOptionLists();
  const { createMdf } = useCreateMdf();
  const { deleteMdf } = useDeleteMdf();
  const { createOptionList } = useCreateOptionList();
  const { deleteOptionList } = useDeleteOptionList();
  const { updateOptionList } = useUpdateOptionList();
  const [, showEditStringDialog] = useEditStringDialog();
  const [schemaType, setSchemaType] = useState<SchemaType>('custom');
  const [mdfToDelete, setMdfToDelete] = useState<Mdf | null>(null);
  const [listToDelete, setListToDelete] = useState<OptionList | null>(null);
  const [checkUserRight] = useCheckUserRight();
  const canSeeNewCmsWorkflow = checkUserRight('feature', 'cms-blocks');

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [createListModalOpen, setCreateListModalOpen] = useState(false);
  const [selectOnCreate, setSelectOnCreate] = useSelectMdfSchema();
  const [selectListOnCreate, setSelectListOnCreate] = useState<string | null>(null);
  const [creatingMdf, setCreatingMdf] = useState(false);
  const [creatingList, setCreatingList] = useState(false);
  const [selectedSchemaId, setSelectedSchemaId] = useState<string | null>(null);
  const [selectedListId, setSelectedListId] = useState<string | null>(null);
  const [changedMdfs, setChangedMdfs] = useChangedMdfs();
  const getMdfIcon = useGetMdfIcon();

  const selectedForm = useMemo(() => {
    if (!selectedSchemaId) return null;
    return changedMdfs[selectedSchemaId] ?? mdfs.find((mdf) => mdf.id === selectedSchemaId) ?? null;
  }, [mdfsSeparated, changedMdfs, selectedSchemaId]);

  const selectedList = useMemo(() => {
    return optionLists.find((l) => l.id === selectedListId) ?? null;
  }, [selectedListId, optionLists]);

  useLayoutEffect(() => {
    if (mdfs?.length > 0 && !selectedForm) {
      setSelectedSchemaId(mdfs[0].id);
    }
  }, [mdfs]);

  const createNewMdf = useCallback(
    async (formTitle: string) => {
      const newMdf = await createMdf({ label: formTitle });
      if (newMdf) {
        setSelectedSchemaId(newMdf.id);
      }
    },
    [setSelectedSchemaId, createMdf],
  );

  const doDelete = useCallback(async () => {
    if (mdfToDelete) {
      await deleteMdf(mdfToDelete.id);
      if (mdfToDelete.id === selectedForm?.id) {
        setSelectedSchemaId(mdfs.length ? mdfs[0].id : null);
      }
      setMdfToDelete(null);
    } else if (listToDelete) {
      await deleteOptionList(listToDelete.id);
      if (listToDelete.id === selectedListId) {
        setSelectedListId(optionLists.length ? optionLists[0].id : null);
      }
      setListToDelete(null);
    }
  }, [mdfToDelete, listToDelete]);

  const onMdfChange = useCallback(
    (mdf: Mdf | null, originalMdf: Mdf | null) => {
      if (mdf === null || originalMdf === null) return;
      if (!changedMdfs[mdf.id] && !isEqual(mdf, originalMdf)) {
        setChangedMdfs({
          ...changedMdfs,
          [mdf.id]: mdf,
        });
      } else if (changedMdfs[mdf.id]) {
        if (isEqual(mdf, originalMdf)) {
          const copy = { ...changedMdfs };
          delete changedMdfs[mdf.id];
          setChangedMdfs(copy);
        } else {
          setChangedMdfs((prevState) => {
            return {
              ...prevState,
              [mdf.id]: mdf,
            };
          });
        }
      }
      setSelectedSchemaId(mdf.id);
    },
    [changedMdfs, setSelectedSchemaId, setChangedMdfs],
  );

  const openEditLabelDialog = useCallback(
    (updatedMdf: Mdf | null, originalMdf: Mdf) => {
      showEditStringDialog({
        headerText: 'Edit form label',
        required: true,
        startValue: updatedMdf?.label ?? originalMdf.label,
        onConfirm: (val) => {
          const copy = { ...(updatedMdf ?? originalMdf) };
          copy.label = val;
          onMdfChange(copy, originalMdf);
        },
      });
    },
    [showEditStringDialog, createNewMdf, changedMdfs],
  );

  const onConfirm = useCallback(
    (createParams: { label: string; schemaType: SchemaType; instanceId?: string }) => {
      const { label, schemaType: newType, instanceId } = createParams;
      setCreatingMdf(true);
      createMdf({ label, isSubtype: newType === 'subtype', id: instanceId })
        .then((val) => {
          if (val) setSelectOnCreate(val.id);
        })
        .catch(errorToast)
        .finally(() => {
          setCreatingMdf(false);
          setCreateModalOpen(false);
        });
    },
    [setCreatingMdf, setSelectOnCreate],
  );

  const doEditLabelOptionList = useCallback(
    (list: OptionList) => {
      showEditStringDialog({
        headerText: 'Edit list label',
        required: true,
        startValue: list.label,
        onConfirm: (val) => {
          const copy = { ...list };
          copy.label = val;
          updateOptionList(copy).catch(errorToast);
        },
      });
    },
    [updateOptionList, showEditStringDialog],
  );

  const doOpenOptionList = useCallback(
    (id: string) => {
      setSelectedSchemaId(null);
      setSelectedListId(id);
    },
    [setSelectedSchemaId, setSelectedListId],
  );

  const onConfirmList = useCallback(
    (createParams: { label: string; id: string }) => {
      setCreatingList(true);
      createOptionList({ ...createParams, optionListType: 'choice' })
        .then((val) => {
          if (val) setSelectOnCreate(val.id);
        })
        .catch(errorToast)
        .finally(() => {
          setCreatingList(false);
          setCreateListModalOpen(false);
        });
    },
    [setCreatingList, setCreateListModalOpen, setSelectOnCreate],
  );

  useEffect(() => {
    if (selectOnCreate) {
      const createdMdf = mdfs.find((mdf) => mdf.id === selectOnCreate);
      if (createdMdf) {
        setSelectedSchemaId(createdMdf.id);
        setSelectOnCreate(null);
      }
    }
  }, [selectOnCreate, mdfs, setSelectOnCreate, setSelectedSchemaId]);

  useEffect(() => {
    if (selectListOnCreate) {
      const createdList = optionLists.find((list) => list.id === selectListOnCreate);
      if (createdList) {
        setSelectedListId(createdList.id);
        setSelectListOnCreate(null);
      }
    }
  }, [selectListOnCreate, optionLists, setSelectListOnCreate, setSelectedSchemaId]);

  const SideBar = useMemo(() => {
    return (
      <FormsWrapper>
        {loading || (error && <LoadingIndicator className={undefined} height={32} width={32} />)}
        <Scrollbar>
          <SettingsListSection label="System defaults">
            {mdfsSeparated.defaults.map((mdf) => (
              <MdfListItem
                key={mdf.id}
                mdf={mdf}
                isDefault={true}
                changedMdfs={changedMdfs}
                selectedForm={selectedForm}
                setSelectedListId={setSelectedListId}
                setSelectedSchemaId={setSelectedSchemaId}
                openEditLabelDialog={openEditLabelDialog}
                doDelete={() => setMdfToDelete(mdf)}
                getMdfIcon={getMdfIcon}
              />
            ))}
          </SettingsListSection>
          <SettingsListSection
            label="Instance schemas"
            onAddClick={() => {
              setCreateModalOpen(true);
              setSchemaType('instance');
            }}
            disableAddClick={!!(loading || error)}
            tooltip="Create schema"
          >
            {mdfsSeparated.instances.map((mdf) => (
              <MdfListItem
                key={mdf.id}
                mdf={mdf}
                isDefault={false}
                changedMdfs={changedMdfs}
                selectedForm={selectedForm}
                setSelectedSchemaId={setSelectedSchemaId}
                setSelectedListId={setSelectedListId}
                openEditLabelDialog={openEditLabelDialog}
                doDelete={() => setMdfToDelete(mdf)}
                getMdfIcon={getMdfIcon}
              />
            ))}
          </SettingsListSection>
          {canSeeNewCmsWorkflow && (
            <SettingsListSection label="Instance blocks" tooltip="Create schema">
              <div>
                {mdfsSeparated.blocks.map((mdf) => (
                  <MdfListItem
                    key={mdf.id}
                    mdf={mdf}
                    isDefault={false}
                    changedMdfs={changedMdfs}
                    selectedForm={selectedForm}
                    setSelectedSchemaId={setSelectedSchemaId}
                    setSelectedListId={setSelectedListId}
                    openEditLabelDialog={openEditLabelDialog}
                    doDelete={() => setMdfToDelete(mdf)}
                    getMdfIcon={getMdfIcon}
                  />
                ))}
              </div>
            </SettingsListSection>
          )}
          <SettingsListSection
            label="Custom schemas"
            tooltip="Create schema"
            onAddClick={() => {
              setCreateModalOpen(true);
              setSchemaType('custom');
            }}
          >
            {mdfsSeparated.custom.map((mdf) => (
              <MdfListItem
                key={mdf.id}
                mdf={mdf}
                isDefault={false}
                changedMdfs={changedMdfs}
                selectedForm={selectedForm}
                setSelectedListId={setSelectedListId}
                setSelectedSchemaId={setSelectedSchemaId}
                openEditLabelDialog={openEditLabelDialog}
                doDelete={() => setMdfToDelete(mdf)}
                getMdfIcon={getMdfIcon}
              />
            ))}
          </SettingsListSection>
          <SettingsListSection
            label="Sub types"
            tooltip="Create new subtype schema"
            onAddClick={() => {
              setCreateModalOpen(true);
              setSchemaType('subtype');
            }}
            disableAddClick={!!(loading || error)}
          >
            {mdfsSeparated.subTypes.map((mdf) => (
              <MdfListItem
                key={mdf.id}
                mdf={mdf}
                isDefault={false}
                changedMdfs={changedMdfs}
                selectedForm={selectedForm}
                setSelectedListId={setSelectedListId}
                setSelectedSchemaId={setSelectedSchemaId}
                openEditLabelDialog={openEditLabelDialog}
                doDelete={() => setMdfToDelete(mdf)}
                getMdfIcon={getMdfIcon}
              />
            ))}
          </SettingsListSection>
          <SettingsListSection
            label="Option lists"
            tooltip="Create new list"
            onAddClick={() => {
              setCreateListModalOpen(true);
            }}
          >
            {optionLists.map((list) => (
              <OptionListItem
                key={list.id}
                list={list}
                selectedList={selectedList}
                setSelectedListId={setSelectedListId}
                setSelectedSchemaId={setSelectedSchemaId}
                doDelete={() => setListToDelete(list)}
                onDoEditLabel={() => doEditLabelOptionList(list)}
              />
            ))}
          </SettingsListSection>
        </Scrollbar>
      </FormsWrapper>
    );
  }, [
    loading,
    error,
    mdfsSeparated,
    changedMdfs,
    selectedForm,
    selectedList,
    optionLists,
    setListToDelete,
    setSelectedListId,
    setSelectedSchemaId,
    setCreateModalOpen,
    openEditLabelDialog,
    doDelete,
  ]);

  return (
    <Wrapper>
      <SplitBar
        split={undefined}
        style={{
          height: '100%',
        }}
        primary="first"
        pane1Style={{
          minWidth: '180px',
          maxWidth: '300px',
        }}
        pane2Style={{
          minWidth: '200px',
          height: '100%',
        }}
      >
        {SideBar}
        {selectedForm && (
          <EditMdf mdf={selectedForm} onChange={onMdfChange} doOpenOptionList={doOpenOptionList} />
        )}
        {selectedList && <OptionListComponent list={selectedList} />}
      </SplitBar>
      <CreateSchemaDialog
        existingInstanceMdfs={mdfsSeparated.instances}
        schemaType={schemaType}
        setSchemaType={setSchemaType}
        open={createModalOpen}
        setOpen={setCreateModalOpen}
        creatingMdf={creatingMdf}
        onCreate={onConfirm}
      />
      <CreateOptionListDialog
        existingOptionLists={optionLists}
        open={createListModalOpen}
        setOpen={setCreateListModalOpen}
        creating={creatingList}
        onCreate={onConfirmList}
      />
      <DeleteDialog
        open={Boolean(mdfToDelete) || Boolean(listToDelete)}
        onClose={() => {
          setMdfToDelete(null);
          setListToDelete(null);
        }}
        onClick={doDelete}
        title={`Delete ${mdfToDelete ? 'schema' : 'list'}? `}
        message={`Are you sure you want to delete ${mdfToDelete?.label ?? listToDelete?.label}?
        This may have unintended side effects places this is in use, and cannot be undone.`}
      />
    </Wrapper>
  );
}
