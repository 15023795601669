import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Photo } from 'assets/icons/systemicons/editor/photo.svg';
import { ReactComponent as Gif } from 'assets/icons/systemicons/editor/gif.svg';
import tooltipEnum from 'components/editor/constants/tooltipEnum';
import useImageUpload from 'hooks/useImageUpload';
import { useSlate } from 'slate-react';
import { useEditorContext } from 'components/editor/EditorContext';
import insertImageBlock from 'components/editor/components/image/utils/insertImageBlock';
import insertImage from 'components/editor/components/image/utils/insertImage';
import insertGifBlock from 'components/editor/components/gif/utils/insertGifBlock';
import defaultImageTypes from 'utils/constants/imageTypes';
import normalizeOnListFocus from 'components/editor/utils/normalizeOnListFocus';

const ImageButton = ({ isGif }) => {
  const editor = useSlate();
  const { update, isAllowed } = useEditorContext();

  const onImageLoad = useCallback(
    (file, fileUrl) => insertImage(editor, update, file, fileUrl),
    [editor, update],
  );

  const imageTypes = isGif ? ['gif'] : defaultImageTypes;

  const uploadImage = useImageUpload({
    disableResize: true,
    imageTypes,
    onImageLoad,
  });

  const onMouseDown = useCallback(
    (event) => {
      event.preventDefault();
      normalizeOnListFocus(editor);
      if (isAllowed) {
        if (isGif) insertGifBlock(editor, update);
        else insertImageBlock(editor, update);
      } else uploadImage();
    },
    [editor, isAllowed, isGif, update, uploadImage],
  );

  const IconComponent = isGif ? Gif : Photo;
  const title = isGif ? tooltipEnum.GIF : tooltipEnum.IMAGE;

  return <IconComponent title={title} {...{ onMouseDown }} />;
};

ImageButton.propTypes = {
  /** Specifies if the image is GIF of not */
  isGif: PropTypes.bool,
};

ImageButton.defaultProps = {
  isGif: false,
};

export default memo(ImageButton);
