const fieldTypes = {
  SELECT: 'select',
  MENU: 'menu',
  BOOLEAN: 'boolean',
  FUNCTION: 'function',
  TEXT: 'text',
  NUMBER: 'number',
  DATETIME: 'datetime',
  STRING: 'string',
};

export default fieldTypes;
