import React from 'react';
import PropTypes from 'prop-types';

import { ButtonWrapper, QuickSelectButton } from './styled';

const QuickSelect = ({ onQuickSelect, isExpanded }) => (
  <ButtonWrapper>
    <QuickSelectButton onClick={() => onQuickSelect(-7)}>-7 Days</QuickSelectButton>
    <QuickSelectButton onClick={() => onQuickSelect(-30)}>-30 Days</QuickSelectButton>
    <QuickSelectButton onClick={() => onQuickSelect(7)}>+7 Days</QuickSelectButton>
    <QuickSelectButton onClick={() => onQuickSelect(30)}>+30 Days</QuickSelectButton>
  </ButtonWrapper>
);

QuickSelect.propTypes = {
  onQuickSelect: PropTypes.func,
};

QuickSelect.defaultProps = {
  onQuickSelect: () => {},
};

export default QuickSelect;
