import { useMemo, useRef } from 'react';

import { Table } from 'components/dataTable/Table';
import { DataTableHeader } from 'features/grids/common/components/TableHeader';
import { TableInstance } from 'features/grids/common/types';
import { parseCellId } from 'features/grids/common/utils/cellUtils';

import { Body } from './components/TableBody';

/** Sets up a general data table */
export function DataTable<TData>({
  table,
  ColumnFilters,
  children,
}: Readonly<TableInstance<TData>>) {
  const virtualizationContainer = useRef<HTMLDivElement>(null);

  const { columnSizing, columnSizingInfo, columnVisibility } = table.getState();
  const columns = table.options.columns;

  const columnSizeVars = useMemo(() => {
    const headers = table.getFlatHeaders();
    const colSizes: { [key: string]: number } = {};
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i];
      const colSize = header.getSize();
      colSizes[`--header-${parseCellId(header.id)}-size`] = colSize;
      colSizes[`--col-${parseCellId(header.column.id)}-size`] = colSize;
    }
    return colSizes;
  }, [columns, columnSizing, columnSizingInfo, columnVisibility]);

  return (
    <div
      ref={virtualizationContainer}
      style={{
        overflow: 'auto',
        position: 'relative',
        height: '100%',
        width: '100%',
      }}
    >
      {children}
      <Table style={{ ...columnSizeVars }}>
        <DataTableHeader table={table} ColumnFilters={ColumnFilters} />
        <Body table={table} containerRef={virtualizationContainer} />
      </Table>
    </div>
  );
}
