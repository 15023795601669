import { Theme, ThemeProvider } from '@emotion/react';

import { ThemeMode } from 'store';
import DarkColors from 'theme/colors-dark';
import LightColors from 'theme/colors-light';

const adjustedThemes = {
  dark: (ancestorTheme: Partial<Theme>) => ({
    ...ancestorTheme,
    palette: { ...ancestorTheme.palette, dina: DarkColors },
  }),
  light: (ancestorTheme: Partial<Theme>) => ({
    ...ancestorTheme,
    palette: { ...ancestorTheme.palette, dina: LightColors },
  }),
};

export interface Props {
  children: React.ReactNode;
  mode?: Exclude<ThemeMode, 'system'>;
}

export default function PersistentTheme({ children, mode = 'dark' }: Readonly<Props>) {
  return <ThemeProvider theme={adjustedThemes[mode] as Partial<Theme>}>{children}</ThemeProvider>;
}
