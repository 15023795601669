import { differenceInCalendarYears, format, isToday, isTomorrow, isYesterday } from 'date-fns';

const getFormattedPublishedDate = (
  publishedDate: string | Date | number,
  delimiter: string = ' / ',
) => {
  if (differenceInCalendarYears(new Date(), publishedDate) > 0)
    return `${format(publishedDate, 'ddd D MMM YY')}`;

  if (isToday(new Date(publishedDate))) return `Today${delimiter}${format(publishedDate, 'HH:mm')}`;

  if (isTomorrow(new Date(publishedDate)))
    return `Tomorrow${delimiter}${format(publishedDate, 'HH:mm')}`;

  if (isYesterday(new Date(publishedDate)))
    return `Yesterday${delimiter}${format(publishedDate, 'HH:mm')}`;

  const formatString = `D MMM[${delimiter}]HH:mm`;
  return `${format(publishedDate, formatString)}`;
};

export default getFormattedPublishedDate;
