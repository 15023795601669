import { sortBy } from 'lodash';

import { MemberType } from 'types/graphqlTypes';

export type ButtonStatusType = (typeof buttonStatus)[keyof typeof buttonStatus];

export const buttonStatus = {
  CONFIRM: 'confirm',
  DEFAULT: 'default',
} as const;

export const columns = [
  { key: 'mTitle', name: 'STUDIO NAME' },
  { key: 'mRefId', name: 'NCS-ID' },
  { key: 'status', name: 'STATUS' },
  { key: 'version', name: 'MOSGW VER.' },
  { key: 'mUpdatedAt', name: 'LAST UPDATED' },
  { key: 'action', name: 'ACTIONS' },
];

export const createRows = (rows: MemberType[]) =>
  sortBy(rows, 'mTitle').map((row) => ({
    ...row,
    version: row?.mMetaData?.find((md) => md.key === 'mosgw.version')?.value,
    status: row?.mMetaData?.find((md) => md.key === 'mosgw.status')?.value,
  }));
