import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { IconButton } from '@material-ui/core';
import transientOptions from 'theme/helpers';

export const Button = styled(IconButton, transientOptions)`
  ${({ theme }) => css`
    background: ${theme.palette.dina.blackHoverOverlay};
    border: 0.5px solid ${theme.palette.dina.buttonBorderOutlined};
  `}
  height: 32px;
  border-radius: 6px;
  padding: 0;

  svg {
    path {
      fill: ${({ theme }) => theme.palette.dina.iconActive};
      fill-opacity: 0.54;
    }
  }
  :disabled {
    opacity: 0.35;
  }
  :hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.dina.whiteHoverOverlay};
      filter: ${theme.palette.dina.onHover};
    `}
  }
  width: ${(props) => `${props.$width ?? 0}px`};
`;

export const StyledSearchRoot = styled('div', transientOptions)`
  ${({ theme, $noBackground }) =>
    !$noBackground &&
    css`
      background-color: ${theme.palette.dina.surfaceCard};
      border-top: 1px solid ${theme.palette.dina.dividerLight};
      border-bottom: 1px solid ${theme.palette.dina.dividerLight};
    `}
  backdrop-filter: blur(60px);
  display: flex;
  padding: 0 4px 0 8px;
  flex-wrap: wrap;
  align-items: center;
  & > * {
    margin-right: 4px;
  }
`;

export const StyledChip = styled('div')`
  display: flex;
  flex-grow: 1;
  min-height: 48px;
  margin-right: 4px;
  align-items: center;
`;

export const StyledButtonContainer = styled('div', transientOptions)`
  display: flex;
  flex: ${(props) => (!props.$showFreeTextInput ? 'auto' : '100 1 auto')};
  align-items: center;
  min-height: 48px;
  & > *:not(:last-child) {
    margin-right: 4px;
  }
`;
