import capitalize from 'lodash/capitalize';

import PlatformIcons from 'components/menu/createInstanceMenu/PlatformIcons';
import { LinearPlatform, Platform } from 'types';
import { AccountType } from 'types/graphqlTypes';

export const defaultLinearPlatformKind = undefined;

export const linearPlatformKinds = [defaultLinearPlatformKind, 'audio'];

export interface PlatformData {
  platform: string;
  platformKind?: string;
  account: AccountType & {
    isUnassigned?: boolean;
    rundownTemplateId?: string;
    variant?: string;
  };
}

export const getPlatform = (
  platforms: (Platform | LinearPlatform)[],
  platformName: string,
  platformKind?: string,
) => {
  const platformsByType = platforms.filter((p) => p?.mProperties?.platform === platformName);
  if (platformsByType.length === 1) return platformsByType[0];
  return platformsByType.find(
    (p) => p?.mProperties?.platformKind === (platformKind || defaultLinearPlatformKind),
  );
};

export const getPlatformLabel = (platform: string, platformKind?: string): string => {
  const normalizedPlatform = platform.toLowerCase();

  const platformLabels: { [key: string]: string } = {
    linear: platformKind ?? platform,
    cms: 'CMS',
    twitter: 'X',
    linkedin: 'LinkedIn',
    tiktok: 'TikTok',
    youtube: 'YouTube',
  };

  return platformLabels[normalizedPlatform] ?? capitalize(platform);
};

export const getPlatformIcon = (platform?: string, platformKind?: string) => {
  const icon = platform === 'linear' && platformKind ? platformKind : platform;
  return icon ? PlatformIcons[icon] ?? PlatformIcons.defaultIcon : PlatformIcons.defaultIcon;
};
