import { useEffect } from 'react';
import { v1 as uuidv1 } from 'uuid';

import Tooltip from 'components/tooltip';
import { MemberTypeEnum } from 'types/graphqlTypes';
import { PanelType } from 'types/members';

import { useSelectedCategory, useSelectedMenuSystemSettings } from '../../../../../atoms';
import { useChangedPanels, useNewPanelId, usePanels } from '../../../../../atomsTs';
import SETTING_TYPES from '../../../../../utils/settingTypes';
import Collapse from '../../../../collapseList';

import { StyledClose, StyledSpan, StyledSpanWrapper, StyledWrapper } from './styled';

interface PanelProps {
  panels: PanelType[];
}

const HtmlPanelsMenu = ({ panels }: PanelProps) => {
  const [selectedSetting, setSelectedSetting] = useSelectedMenuSystemSettings() as [
    string,
    (value: string) => void,
  ];

  const [category, setCategory] = useSelectedCategory();
  const [panelFields, setPanelFields] = usePanels();
  const [changedPanels, setChangedPanels] = useChangedPanels();
  const [newPanelId, setNewPanelId] = useNewPanelId();

  useEffect(() => {
    if (panels !== panelFields) {
      setPanelFields(panels);
    }
  }, [panels, setPanelFields]);

  const onAddNewPanel = () => {
    const mRefId = uuidv1();
    const mTitle = 'Untitled Panel';
    setNewPanelId(mRefId);
    const newPanel = {
      mId: 'externalurl',
      mRefId,
      mTitle,
      mType: MemberTypeEnum.Config,
      configs: [
        {
          key: 'url',
          name: mRefId,
          type: 'generic',
          value: 'https://...',
        },
      ],
    };
    setChangedPanels([newPanel, ...changedPanels]);
    setPanelFields([newPanel, ...panelFields]);
    setSelectedSetting(mRefId);
    setCategory(SETTING_TYPES.HTMLPANELS);
  };

  const onPanelClick = (id: string | undefined) => {
    setSelectedSetting(id || '');
    setCategory(SETTING_TYPES.HTMLPANELS);
  };

  const onRemovePanel = (e: React.SyntheticEvent, id: string | undefined) => {
    e.stopPropagation();

    const toBeRemoved = panelFields.find((f) => f.mRefId === id);
    if (!toBeRemoved) return;
    const index = panelFields.indexOf(toBeRemoved);
    const filteredPanels = panelFields.filter((f) => f.mRefId !== id);
    setPanelFields(filteredPanels);
    setChangedPanels([toBeRemoved, ...changedPanels]);
    setSelectedSetting(filteredPanels[index]?.mRefId || filteredPanels[0]?.mRefId || '  ');
  };

  return (
    <Collapse>
      <Collapse.Header
        onCreateClick={onAddNewPanel}
        itemCount={panelFields?.length}
        createButtonTooltip="Add Panel"
        selected={category === SETTING_TYPES.HTMLPANELS}
        createButtonDisabled={false}
        hideCreateButton={false}
      >
        HTML Panels
      </Collapse.Header>
      <Collapse.Body>
        {panelFields?.map((field) => (
          <StyledSpanWrapper key={field.mRefId}>
            <Collapse.Item
              className="link-collapse-item"
              selected={selectedSetting === field.mRefId}
              onClick={() => onPanelClick(field.mRefId)}
              italic={newPanelId === field?.mRefId}
              id={field?.mRefId}
              itemCount=""
            >
              <StyledWrapper>
                <StyledSpan>{field.mTitle}</StyledSpan>
                <Tooltip title="Remove panel">
                  <StyledClose
                    className="link-remove"
                    onClick={(e) => onRemovePanel(e, field.mRefId)}
                  />
                </Tooltip>
              </StyledWrapper>
            </Collapse.Item>
          </StyledSpanWrapper>
        ))}
      </Collapse.Body>
    </Collapse>
  );
};

export default HtmlPanelsMenu;
