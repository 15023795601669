import { useMemo } from 'react';

import { useGetMdfs } from 'api/mdf/useGetMdfs';
import { BlockWithLabel } from 'api/mdfBlocks/types';
import { useGetBlocks } from 'api/mdfBlocks/useGetMdfBlocks';
import { useGetOrdersForEntities } from 'screens/space/api/useGetOrdersAndForms';
import { useEditorCommandsKeyed } from 'store';
import { GetOrderEnum, TaskStatusEnum } from 'types/graphqlTypes';

import useGetBlocksWithMdf from './useGetBlocksWithMdf';
import useGetOrdersWithMdf from './useGetOrdersWithMdf';

const useGetBlocksAndOrders = (memberId: string) => {
  const [editorCommandsKeyed] = useEditorCommandsKeyed();
  const { mdfs, mdfsSeparated, loading: mdfsLoading, error: mdfsError } = useGetMdfs({ all: true });

  const { blocks, loading: blocksLoading, error: blocksError } = useGetBlocks(memberId);

  const mIds = useMemo(() => blocks.map((block) => block.mRefId), [blocks]);

  const {
    orders,
    loading: ordersLoading,
    error: ordersError,
  } = useGetOrdersForEntities(mIds, GetOrderEnum.Resource, TaskStatusEnum.all);

  const blocksWithType: BlockWithLabel[] = useMemo(() => {
    return blocks.map((block) => {
      const cmd = editorCommandsKeyed[block.mSecId];
      return {
        ...block,
        commandLabel: cmd?.mTitle ?? '',
        color: cmd?.mColor,
      };
    });
  }, [blocks, editorCommandsKeyed]);

  const { blocksWithMdf } = useGetBlocksWithMdf(blocksWithType, mdfs, mdfsSeparated);
  const { ordersWithMdf } = useGetOrdersWithMdf(orders, mdfs, mdfsSeparated);

  return {
    mdfsSeparated,
    blocksWithMdf,
    ordersWithMdf,
    mdfsLoading,
    blocksLoading,
    ordersLoading,
    mdfsError,
    blocksError,
    ordersError,
  };
};

export default useGetBlocksAndOrders;
