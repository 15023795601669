/* eslint-disable import/no-extraneous-dependencies */
import cloneDeep from 'lodash/cloneDeep';

const merge = (policy1, policy2) => {
  const mergedPolicy = cloneDeep(policy2);

  policy1.forEach((resource) => {
    const mergedResourceObj = mergedPolicy.find(
      (policy) => policy.resourceName === resource.resourceName,
    );
    if (!mergedResourceObj) {
      getMergedResourceObj(resource, mergedPolicy);
    } else {
      getPermissions(resource, mergedResourceObj);
    }
  });
  return mergedPolicy;
};

const getMergedResourceObj = (resource, mergedPolicy) => {
  if (resource.resourceName === 'feature') {
    mergedPolicy.push(resource);
  } else {
    const permissions = resource.permissions.map((permission) => ({
      ...permission,
      access: 'allow',
    }));
    mergedPolicy.push({ ...resource, permissions });
  }
};

const getMergedPermission = (permission, mergedResourceObj) =>
  mergedResourceObj.permissions.find((permit) => permit.action === permission.action);

const getPermissions = (resource, mergedResourceObj) => {
  const { permissions, resourceName } = resource;
  permissions.forEach((permission) => {
    const mergedPermission = getMergedPermission(permission, mergedResourceObj);
    if (!mergedPermission) {
      if (resourceName === 'feature') mergedResourceObj.permissions.push(permission);
      else
        mergedResourceObj.permissions.push({
          ...permission,
          access: permission.defaultAccess || 'allow',
        });
    } else if (mergedPermission.access === 'allow' || permission.access === 'allow')
      mergedPermission.access = 'allow';
    else mergedPermission.access = 'deny';
  });
};

export default merge;
