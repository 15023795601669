export const STORAGE_KEY = 'storyLayout';

export const FIXED_LAYOUT_IDENTIFIER = {
  EDITOR: 'editor',
  NOTE: 'note',
  STORYBOX_PREVIEW: 'storybox_preview',
};

const setStoryLayout = (currentLayout) => {
  window.localStorage.setItem(STORAGE_KEY, JSON.stringify(currentLayout));
};

const getStoryLayout = () => JSON.parse(window.localStorage.getItem(STORAGE_KEY)) || {};

/**
 *
 * @param {string} storyId
 * @param {'editor' | 'note' | instanceId} identifier
 */
export const updateStoryLayout = (storyId, identifier, isCollapsed) => {
  const allStoryLayout = getStoryLayout();
  if (allStoryLayout[storyId]) {
    const currentStoryLayout = allStoryLayout[storyId];
    currentStoryLayout[identifier] = isCollapsed;
    allStoryLayout[storyId] = currentStoryLayout;
  } else {
    allStoryLayout[storyId] = { [identifier]: isCollapsed };
  }
  setStoryLayout(allStoryLayout);
};

export const checkIfCollapsed = (storyId, identifier) => {
  let isCollapsed = true;
  const allStoryLayout = getStoryLayout();
  if (allStoryLayout[storyId] && allStoryLayout[storyId][identifier] !== undefined) {
    isCollapsed = allStoryLayout[storyId][identifier];
  }

  return isCollapsed;
};

/**
 * Removes the story layout config from localStorage by storyId
 * @param {string} storyId
 */
export const removeStoryLayoutByStoryId = (storyId) => {
  const allStoryLayout = getStoryLayout();
  const updatedStoryLayout = Object.keys(allStoryLayout).reduce((prev, curr) => {
    if (curr !== storyId) {
      /* eslint-disable no-param-reassign */
      prev[curr] = allStoryLayout[curr];
    }
    return prev;
  }, {});
  setStoryLayout(updatedStoryLayout);
};
