import gql from 'graphql-tag';

export default gql`
  mutation updateRundownImage($input: UpdateRundownInput) {
    updateRundownSync(input: $input) {
      mId
      mRefId
      mCoverPhotoKey
      mCoverPhotoUrl
      mThumbnailKey
      mThumbnailUrl
    }
  }
`;
