import { memo, useEffect, useState } from 'react';

import { getDefaultLayoutSettings } from 'components/editMdfDialog/utils';
import LWCheckbox from 'features/orderForm/components/LWCheckbox';

import { CellProps } from '../fields';

import { getCheckboxValue } from './CheckboxField';

function CheckboxCell({
  fieldSettings,
  fieldModel,
  setValue,
  value,
  disableEdit,
}: Readonly<CellProps>) {
  const [localValue, setLocalValue] = useState(value);
  const { hint } = fieldSettings ?? getDefaultLayoutSettings(fieldModel);

  useEffect(() => {
    if (localValue !== value) {
      setLocalValue(value);
    }
  }, [value]);

  return (
    <LWCheckbox
      title={hint}
      setValue={() => {
        setLocalValue(!localValue);
        setValue(!localValue);
      }}
      selected={getCheckboxValue(localValue)}
      disabled={disableEdit}
    />
  );
}

export default memo(CheckboxCell);
