import gql from 'graphql-tag';

// This is for fetching a single feed item
export const GET_FEED_ITEM = gql`
  query GetFeedItem($input: GetFeedItemInput) {
    getFeedItem(input: $input) {
      __typename
      provider
      mId
      version
      mPublishedAt
      section
      priority
      pubstatus
      ednotes
      newscodes
      mTitle
      byline
      mContent
      mDescription
      mediatopics
      location
      embargoed
      href
      copyrightnotice
      usageterms
      infosource
      storyList
      language
      firstcreated
      versioncreated
      revision
      slugline
      assets {
        duration
        headline
        byline
        type
        renditions {
          href
          uri
          mimetype
          duration
          videoaspectratio
          format
          height
          width
          filename
          duration
          rendition
          sizeinbytes
          fileextension
          orientation
          previewUri
          thumbnailUri
          videoPreviewUri
        }
      }
    }
  }
`;
