import GET_MEMBERS_OF from 'operations/queries/getMembersOf';
import { getMembersOfQuery } from 'operations/queryVariables';
import memberTypes from 'operations/memberTypes';

const getUpdatedList = (list, item) => {
  const departmentUpdated = item.department;
  const updatedList = [...list];
  if (item.crudAction === 'INSERT') {
    departmentUpdated.__typename = 'MemberType';
    departmentUpdated.mDescription = item.department.mDescription || null;
    departmentUpdated.mAvatarUrl = item.department.mAvatarUrl || null;
    const departmentExist = updatedList.filter((d) => d.mId === item.department.mId);
    if (departmentExist.length === 0) {
      updatedList.push(departmentUpdated);
    }
    return updatedList;
  }
  if (item.crudAction === 'REMOVE') {
    return updatedList.filter((d) => d.mId !== item.department.mId);
  }
  return list;
};

const updateCahe = (proxy, item, query, variables) => {
  try {
    const list = proxy.readQuery({
      query,
      variables,
    });
    list.getMembersOf = getUpdatedList(list.getMembersOf, item);
    // Write updated member list in the cache.
    proxy.writeQuery({
      query,
      variables,
      data: list,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

const updateDepartmentInTeam = (proxy, item) => {
  try {
    const variables = getMembersOfQuery(item.team.mId, memberTypes.DEPARTMENT_TEAM);
    updateCahe(proxy, item, GET_MEMBERS_OF, variables);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};

export default updateDepartmentInTeam;
