import React, { useCallback, memo, useMemo, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Grid, Divider } from '@material-ui/core';
import { useSlate } from 'slate-react';
import {
  removeBlock,
  updateBlock,
  checkIfDragDisabled,
  refreshSelection,
} from 'components/editor/utils';
import MetadataEditor from 'components/metadataEditor';
import { menuOptions } from 'components/editor/constants';
import { useEditorContext } from 'components/editor/EditorContext';
import useChangeCollapse from 'components/editor/hooks/useChangeCollapse';
import { ReactComponent as PhotoGalleryIcon } from 'assets/icons/systemicons/editor/blocks_PhotoCarousel_on.svg';

import v1 from 'uuid/v1';
import MediaDropZone from './components/mediaDropZone';
import DragAndDrop from '../dragAndDrop';
import Box from '../box';
import Header from './components/header';
import Footer from './components/footer';
import GalleryItem from './components/galleryItem';

const initialPhotoGalleryItem = {
  caption: '',
  source: [],
};

const BlockWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

const PhotoGalleryIconWrapper = styled(PhotoGalleryIcon)`
  width: 22px;
  height: 22px;
  margin: 9px;

  & > path {
    fill: ${({ theme }) => theme.palette.dina.blackMediumEmphasis};
    fill-opacity: 1;
  }
`;

const MAX_GALLERY_ITEM = 50;
const MIN_GALLERY_ITEM = 2;

const defaultGalleryItems = [
  { index: 0, mId: v1(), ...initialPhotoGalleryItem },
  { index: 1, mId: v1(), ...initialPhotoGalleryItem },
];

const PhotoGallery = ({ attributes, children, element, readOnly, direction }) => {
  const editor = useSlate();
  const { update, variant, isAllowed, formsForThisPlatform, platformId, allowVideoInPhotogallery } =
    useEditorContext();
  const { data, type } = element;
  const { assets, collapsed = false, metadata } = data;

  const { title, description, transitionType, isCoverphoto } = metadata || {};

  const currentAssets = useMemo(() => assets || defaultGalleryItems, [assets]);

  const [boxType, setBoxType] = useState('media');
  const [onChangeCollapse] = useChangeCollapse(element);

  useEffect(() => {
    if (isCoverphoto) setBoxType('iscoverphoto');
    else setBoxType('media');
  }, [isCoverphoto]);

  /** Delete items from PhotoGallery when click on the delete button under the item */
  const deletePhotoGalleryItem = useCallback(
    (index) => {
      if (index >= 0 && index < assets.length) {
        const newGalleryItems = assets
          .filter((item) => item.index !== index)
          .map((item, idx) => ({ ...item, index: idx }));

        const updatedData = {
          ...element.data,
          assets: newGalleryItems,
        };
        updateBlock(editor, element, updatedData, update);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [assets],
  );

  const onUpdateBlockMeta = useCallback(
    (key, value) => {
      const updatedData = {
        ...data,
        [key]: value || false, // For backward compatibility only
        metadata: {
          ...metadata,
          [key]: value,
        },
      };

      const allAttributes =
        key === 'isCoverphoto' && value === true ? { key: 'isCoverphoto', value: false } : null;
      updateBlock(editor, element, updatedData, update, undefined, allAttributes);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, metadata, element],
  );

  /** For adding new gallery item inside the block */
  const onClickAddPhotos = useCallback(() => {
    const updatedData = {
      ...element.data,
      assets: [
        ...currentAssets,
        { index: currentAssets.length, mId: v1(), ...initialPhotoGalleryItem },
      ],
    };
    updateBlock(editor, element, updatedData, update);
  }, [element, currentAssets, editor, update]);

  /** Callback for delete block from editor */
  const onMenuSelect = useCallback(
    ({ action }) => {
      if (action === 'delete-block') removeBlock(editor, element, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /**
   * This method updates the editor content for each gallery item's caption and source
   * and store them in the backend.
   */
  const onUpdateAssetItemMeta = useCallback(
    (index, key, value) => {
      const updatedData = {
        ...element.data,
        assets: currentAssets.map((item, idx) => {
          if (item.index === index) {
            return {
              ...item,
              [key]: value,
            };
          }

          return item;
        }),
      };

      updateBlock(editor, element, updatedData, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentAssets, metadata],
  );

  /** Update gallery item order by clicking on move up or move down button
   * For moving up offset will be -1.
   * For moving down offset will be +1.
   */
  const moveGalleryItemWithOffset = useCallback(
    (currentIndex, offset) => {
      const oldAssets = [...currentAssets];
      const destinationIndex = currentIndex + offset;

      if (destinationIndex >= 0 && destinationIndex < oldAssets.length) {
        const tempAsset = { ...oldAssets[currentIndex] };
        oldAssets[currentIndex] = { ...oldAssets[destinationIndex] };
        oldAssets[destinationIndex] = tempAsset;

        const updatedData = {
          ...data,
          assets: oldAssets.map((item, index) => ({ ...item, index })),
        };

        updateBlock(editor, element, updatedData, update);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentAssets, data],
  );

  const moveGalleryItem = useCallback(
    (source, destination) => {
      const oldGalleryItem = [...currentAssets];
      oldGalleryItem.splice(source.index, 1);
      oldGalleryItem.splice(destination.index, 0, source);
      const updatedData = {
        ...data,
        assets: oldGalleryItem.map((item, idx) => ({ ...item, index: idx })),
      };

      updateBlock(editor, element, updatedData, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentAssets, data],
  );

  const memoizedHeader = useMemo(
    () => (
      <Header
        onUpdateBlockMeta={onUpdateBlockMeta}
        firstItemLeadImage={isCoverphoto || false}
        initialTitle={title || ''}
        initialDescription={description || ''}
        initialTransitionType={transitionType}
        readOnly={readOnly}
        direction={direction}
        allowVideoInPhotogallery={allowVideoInPhotogallery}
      />
    ),
    [
      onUpdateBlockMeta,
      isCoverphoto,
      title,
      description,
      transitionType,
      readOnly,
      direction,
      allowVideoInPhotogallery,
    ],
  );

  const memoizedFooter = useMemo(
    () => (
      <Footer
        onClickHandler={onClickAddPhotos}
        shouldDisable={currentAssets.length === MAX_GALLERY_ITEM}
      />
    ),
    [currentAssets.length, onClickAddPhotos],
  );

  const blockForm = formsForThisPlatform?.[type];

  const memoizedGalleryItems = useMemo(
    () =>
      currentAssets.map((item, idx) => (
        <MediaDropZone
          index={idx}
          element={element}
          galleryItems={currentAssets}
          key={`galleryItemDropZone-${item.index}-${item.mId}`}
        >
          <GalleryItem
            galleryItem={item}
            onUpdateAssetItemMeta={onUpdateAssetItemMeta}
            disableDeleteButton={currentAssets.length <= MIN_GALLERY_ITEM}
            deleteItem={deletePhotoGalleryItem}
            moveGalleryItemWithOffset={moveGalleryItemWithOffset}
            hideMoveUp={item.index === 0}
            hideMoveDown={item.index === currentAssets.length - 1}
            moveItem={moveGalleryItem}
            isAllowed={isAllowed}
            direction={direction}
          />
        </MediaDropZone>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentAssets,
      onUpdateAssetItemMeta,
      deletePhotoGalleryItem,
      moveGalleryItemWithOffset,
      moveGalleryItem,
    ],
  );

  const updateMetadata = useCallback(
    (newValue, metaPropName) => {
      const updatedMetadata = metadata;
      const updatedData = {
        ...data,
        metadata: {
          ...updatedMetadata,
          [metaPropName]: newValue,
        },
      };

      updateBlock(editor, element, updatedData, update);
    },
    [data, editor, element, metadata, update],
  );

  const onBlur = useCallback(() => {
    refreshSelection(editor, element);
  }, [editor, element]);

  const memoizedRenderedContent = useMemo(
    () => (
      <div {...attributes} onBlur={onBlur}>
        {children}
        <DragAndDrop element={element} isDragDisabled={checkIfDragDisabled(variant)}>
          <Box
            iconComponent={<PhotoGalleryIconWrapper className="skipOverride" />}
            title={`${allowVideoInPhotogallery ? '' : 'Photo '}Gallery`}
            readOnly={readOnly}
            platformId={platformId}
            boxType={type}
            collapsedContent={`${allowVideoInPhotogallery ? '' : 'Photo '}Gallery`}
            collapsed={collapsed}
            updateCollapsed={onChangeCollapse}
            menuItems={menuOptions}
            onMenuSelect={onMenuSelect}
            type={boxType}
            boxTopRightContent={`${currentAssets.length} ${
              allowVideoInPhotogallery ? 'items' : 'photos'
            } added`}
            allowInternalDragDrop
          >
            <BlockWrapper>
              {memoizedHeader}
              <Divider />
              {memoizedGalleryItems}
              {memoizedFooter}
              {blockForm && (
                <MetadataEditor
                  model={blockForm}
                  setPayload={(u) => updateMetadata(u.value, u.fieldId)}
                  payload={metadata}
                />
              )}
            </BlockWrapper>
          </Box>
        </DragAndDrop>
      </div>
    ),
    [
      attributes,
      children,
      element,
      variant,
      readOnly,
      collapsed,
      blockForm,
      metadata,
      platformId,
      type,
      updateMetadata,
      onChangeCollapse,
      onMenuSelect,
      currentAssets.length,
      memoizedHeader,
      memoizedGalleryItems,
      memoizedFooter,
      boxType,
      onBlur,
      allowVideoInPhotogallery,
    ],
  );

  const renderedContentWithoutBlock = useMemo(
    () => (
      <div {...attributes}>
        {children}
        <BlockWrapper>
          {memoizedHeader}
          <Divider />
          {memoizedGalleryItems}
          {memoizedFooter}
        </BlockWrapper>
      </div>
    ),
    [children, attributes, memoizedHeader, memoizedGalleryItems, memoizedFooter],
  );

  if (!isAllowed) return renderedContentWithoutBlock;

  return memoizedRenderedContent;
};

export default memo(PhotoGallery);
