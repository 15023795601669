import React, { memo } from 'react';
import styled from '@emotion/styled/macro';

const Text = styled('span')`
  letter-spacing: 0.2px;
  font-size: 13px;
  font-weight: bold;
`;
const Track = styled('div')`
  width: auto;
  height: 3px;
  background-color: rgba(115, 134, 120, 0.5);
`;
const Thumb = styled('div')`
  width: ${(props) => props.percentage}%;
  height: 100%;
  background-color: green;
  transition: width 1.85s ease-in-out;
`;
const ProgressWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const ProgressBar = ({ percentage }) => {
  const clamp = (min, value, max) => Math.min(Math.max(min, value), max);

  const progressStatus = () => {
    const truncatedPercentage = Math.trunc(percentage);
    const status = {
      start: 'Initialising Upload ... ',
      continue: `Uploading ... ${truncatedPercentage}%`,
      end: 'Uploaded Successfully!',
    };
    if (truncatedPercentage < 10) return status.start;
    if (truncatedPercentage >= 10 && truncatedPercentage < 100) return status.continue;
    return status.end;
  };

  return (
    <ProgressWrapper>
      <Text>{progressStatus()}</Text>
      <Track>
        <Thumb percentage={clamp(0, percentage, 100)} />
      </Track>
    </ProgressWrapper>
  );
};

export default memo(ProgressBar);
