import transitionTypes from './transitionTypes';

const { MIX, EFFECT } = transitionTypes;

const options = [
  { type: MIX, value: '' },
  { type: EFFECT, value: '' },
];

export default options;
