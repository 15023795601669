import { useAtomValue } from 'jotai';

import { policiesAtom } from 'store';
import checkUserRight from 'utils/checkUserRight';

export function GetStoryPolicies() {
  const policies = useAtomValue(policiesAtom);
  const canUpdateStory = checkUserRight(policies, 'story', 'update');
  const canUpdatePitch = checkUserRight(policies, 'pitch', 'update');
  const canCreateStory = checkUserRight(policies, 'story', 'create');

  return { canUpdateStory, canUpdatePitch, canCreateStory };
}
