import React, { memo, useCallback, useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Transforms } from 'slate';
import { useSlate, ReactEditor } from 'slate-react';

import { ReactComponent as AddIcon } from 'assets/icons/systemicons/add.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/systemicons/close.svg';
import { RectangleButton } from 'components/buttons';
import generateName from 'components/editor/utils/generateName';
import {
  checkIfDragDisabled,
  removeBlock,
  updateBlock,
  refreshSelection,
} from 'components/editor/utils';
import { menuOptions } from 'components/editor/constants';
import actionTypes from 'components/editor/constants/types/actionTypes';
import useImageUpload from 'hooks/useImageUpload';
import { useEditorContext } from 'components/editor/EditorContext';
import useChangeCollapse from 'components/editor/hooks/useChangeCollapse';
import MediaDropZone from 'components/editor/components/image/mediaDropZone';
import { getThumbnailKey } from 'utils/mediaKey';
import useGetSignedUrl from 'hooks/useGetSignedUrl';
import fallbackImageSrc from 'assets/images/default/defaultThumbnail.png';
import UploadProgress from '../uploadProgress';

import ActionButton from '../addMedia/components/button';
import Box from '../box';
import DragAndDrop from '../dragAndDrop';

import { GifIcon, ContentWrapper, GifWrapper, Image, ProgressBarWrapper } from './styled';

const imageTypes = ['gif'];

const { setNodes } = Transforms;

const Gif = ({ attributes, children, element, readOnly }) => {
  const fileRef = useRef();
  const cacheRef = useRef(null);
  const [buttonType, setButtonType] = useState('default');

  const editor = useSlate();
  const { update, variant, withSignedUrl } = useEditorContext();
  const { data } = element;
  const { src, collapsed = false, title, mId, mRefId } = data;

  const thumbnailKey = src || getThumbnailKey(mId, mRefId);
  const { url: thumbSrc } = useGetSignedUrl(thumbnailKey, cacheRef.current);
  const gifSrc = cacheRef.current || thumbSrc || src;

  const [onChangeCollapse] = useChangeCollapse(element);

  const onImageLoad = useCallback(
    async (file, fileUrl) => {
      const fileName = generateName(file.type);
      cacheRef.current = fileUrl;

      let assetData = {
        ...element.data,
        title: fileName,
      };

      if (withSignedUrl) fileRef.current = file;

      if (!withSignedUrl) {
        const result = await update({
          type: actionTypes.ASSET_INSERT,
          payload: { document: editor.children, file, fileName, bypassUploadApi: true },
        });

        assetData = {
          ...assetData,
          ...result,
        };
      }

      const path = ReactEditor.findPath(editor, element);
      setNodes(editor, { data: assetData }, { at: path });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [withSignedUrl, element],
  );

  const updateEditorOnUpload = useCallback(
    (result) => {
      const updatedData = {
        ...element.data,
        ...result,
      };
      const path = ReactEditor.findPath(editor, element);
      setNodes(editor, { data: updatedData }, { at: path });
    },
    [element, editor],
  );

  const uploadGif = useImageUpload({
    disableResize: true,
    imageTypes,
    onImageLoad,
  });

  const onAddGIFClick = useCallback((event) => {
    event.preventDefault();
    uploadGif();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addImageFallback = useCallback((event) => {
    // eslint-disable-next-line no-param-reassign
    event.currentTarget.src = fallbackImageSrc;
  }, []);

  const onMenuSelect = useCallback(
    ({ action }) => {
      if (action === 'delete-block') removeBlock(editor, element, update);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const removeGif = useCallback(
    (event) => {
      event.preventDefault();
      updateBlock(editor, element, {}, update);
      cacheRef.current = null;
      setButtonType('default');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  const handleAction = useCallback(
    (event) => {
      if (buttonType === 'default') setButtonType('confirm');
      else removeGif(event);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [buttonType],
  );

  const handleClickAway = useCallback(
    (event) => {
      if (buttonType === 'confirm') setButtonType('default');
    },
    [buttonType],
  );

  const renderContent = useMemo(
    () => (
      <MediaDropZone element={element}>
        {children}
        <Box
          iconComponent={<GifIcon className="skipOverride" />}
          title="Gif"
          readOnly={readOnly}
          menuItems={menuOptions}
          onMenuSelect={onMenuSelect}
          type="media"
          updateCollapsed={onChangeCollapse}
          collapsed={collapsed}
          collapsedContent={title}
        >
          <ContentWrapper>
            {gifSrc ? (
              <GifWrapper>
                <Image src={gifSrc} alt="gif" onError={addImageFallback} />
                <ActionButton
                  onClickAway={handleClickAway}
                  type={buttonType}
                  icon={<CloseIcon />}
                  label="Remove Gif"
                  onClick={handleAction}
                />
              </GifWrapper>
            ) : (
              <RectangleButton onClick={onAddGIFClick} disabled={readOnly} width={80} height={80}>
                <AddIcon />
              </RectangleButton>
            )}
            <ProgressBarWrapper>
              {withSignedUrl && fileRef.current && cacheRef.current !== null && (
                <UploadProgress fileRef={fileRef} updateEditorOnUpload={updateEditorOnUpload} />
              )}
            </ProgressBarWrapper>
          </ContentWrapper>
        </Box>
      </MediaDropZone>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      buttonType,
      collapsed,
      gifSrc,
      handleAction,
      handleClickAway,
      onAddGIFClick,
      onMenuSelect,
      readOnly,
      title,
      onChangeCollapse,
      children,
    ],
  );

  const onBlur = useCallback(() => {
    refreshSelection(editor, element);
  }, [editor, element]);

  return (
    <div {...attributes} onBlur={onBlur}>
      <DragAndDrop element={element} isDragDisabled={checkIfDragDisabled(variant)}>
        {children}
        {renderContent}
      </DragAndDrop>
    </div>
  );
};

Gif.propTypes = {
  /** Boolean that stops image upload */
  readOnly: PropTypes.bool,
};

Gif.defaultProps = {
  readOnly: false,
};

export default memo(Gif);
