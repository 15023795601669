import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ContentIcon } from 'assets/icons/systemicons/editor/contents.svg';
import { elementTypes } from 'components/editor/constants/types';
import { useReadOnly, useSelected } from 'slate-react';
import { getCharacterCount } from 'components/editor/utils';
import useStyles from './styles';

const Content = ({ attributes, children, element }) => {
  const isReadOnly = useReadOnly();
  const isSelected = useSelected(element);
  const showHighlight = !isReadOnly && isSelected;

  const classes = useStyles({ showHighlight });

  const characterCount = getCharacterCount(element.children);

  return (
    <div {...attributes} className={classes.contentContainer}>
      <div className={classes.iconWrapper} contentEditable={false}>
        <ContentIcon className={classes.icon} />
      </div>
      <div className={classes.contentWrapper}>
        <div contentEditable={false} className={classes.withEllipsis}>
          <div className={classes.titleWrapper}>
            <p className={classes.title}>Contents</p>
          </div>
        </div>
        <div className={classes.childrenWrapper}>
          <div className={classes.children}>{children}</div>
        </div>
        <div className={classes.assistiveText} contentEditable={false}>{`${characterCount} ${
          characterCount <= 1 ? 'character' : 'characters'
        }`}</div>
      </div>
    </div>
  );
};

Content.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

Content.defaultProps = {
  attributes: {},
  children: null,
  element: {
    children: [],
    data: {},
    type: elementTypes.CONTENT,
  },
};

export default memo(Content);
