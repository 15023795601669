export const getHeaderFontSize = (type: string) => {
  switch (type) {
    case 'heading-one':
      return 34;
    case 'heading-two':
      return 30;
    case 'heading-three':
      return 24;
    case 'heading-four':
      return 20;
    case 'heading-five':
      return 18;
    case 'heading-six':
      return 16;
    default:
      return 16;
  }
};
