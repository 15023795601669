import { memo, useCallback } from 'react';

import { isOlderSlateValue, migrateValue } from 'components/editor';
import useInstanceCardHeader from 'features/instance/hooks/useInstanceCardHeader';
import {
  InstanceChangeObject,
  UpdateInputParameters,
} from 'features/instance/hooks/useInstanceCore';
import useInstancePermissions from 'features/instance/hooks/useInstancePermissions';
import useInstanceTemplate from 'features/instance/hooks/useInstanceTemplate';
import { useInstanceMolecule } from 'features/instance/store/instance';
import { EditorValue } from 'types';
import { getLockToken } from 'utils/lock/lockTokenV2';

import { publishingPoints } from '../../utils';
import GeneralEllipsisMenu from '../generalEllipsisMenu';
import LinearEllipsisMenu from '../linearEllipsisMenu';

interface MenuProps {
  onDeleteInstance: () => Promise<void>;
  lockedByUser: string;
  lockedByCurrentUser: boolean;
  editorValue: EditorValue;
  onForceUnlock: () => Promise<void>;
  onCreateDuplicate: (rundownId: string) => Promise<void>;
  onInstanceChanged: (instanceChangeObject: InstanceChangeObject) => Promise<unknown>;
  saveAll: (params: UpdateInputParameters) => Promise<void>;
  cancelDebounce: () => void;
  resetEditorValue: (newValue: EditorValue) => void;
  handleLockInstance: () => Promise<string | undefined>;
  updateLock: (lockedId?: string) => void;
}

const Menu = ({
  onDeleteInstance,
  updateLock,
  lockedByUser,
  editorValue,
  lockedByCurrentUser,
  onForceUnlock,
  onCreateDuplicate,
  onInstanceChanged,
  saveAll,
  cancelDebounce,
  resetEditorValue,
  handleLockInstance,
}: MenuProps) => {
  const {
    useInstanceValue,
    useIsSavingInstance,
    useHideTemplateOptions,
    usePublishingPointValue,
    useDisableEdit,
    useReadLock,
    usePrintDialog,
    currentEditingScope,
  } = useInstanceMolecule();
  const {
    canUpdateInstance,
    canCreateNewTemplate,
    canDeleteTemplate,
    canDeleteTemplateFolder,
    canPinTemplateFolder,
    canReorderTemplates,
    canCreateInstance,
    canScheduleInstance,
    canSetDefaultTemplate,
    canDeleteInstance,
  } = useInstancePermissions();

  const { hideAutomationTemplates, isDeleteEnabled, handleDownload, canOpenStory } =
    useInstanceCardHeader(onInstanceChanged);
  const {
    folders,
    onSelectTemplate,
    onSaveTemplate,
    onDeleteFolder,
    onCreateFolder,
    onSetDefaultTemplate,
    defaultTemplateRefId,
    onDeleteTemplate,
  } = useInstanceTemplate(saveAll, cancelDebounce, resetEditorValue, handleLockInstance);

  const instance = useInstanceValue();
  const publishingPoint = usePublishingPointValue();
  const [isSavingContent] = useIsSavingInstance();
  const [hideTemplateOptions] = useHideTemplateOptions();
  const [disableEdit] = useDisableEdit();
  const [readLock] = useReadLock();
  const [, setShowPrintDialog] = usePrintDialog();

  const checkVersionRestorability = useCallback(async () => {
    if (lockedByCurrentUser) return true;
    const lockedId = await handleLockInstance();
    return lockedId === getLockToken(currentEditingScope);
  }, [currentEditingScope, handleLockInstance, lockedByCurrentUser]);

  const onRestoreVersion = useCallback(
    async (rescont: EditorValue) => {
      if (!instance?.mId) return;

      const content = isOlderSlateValue(rescont)
        ? (migrateValue(rescont, {}) as EditorValue)
        : rescont;

      resetEditorValue(content);

      const params = {
        content,
        instance,
        unlock: true,
        version: 'restored',
        audit: { source: 'useInstanceVersion:onRestoreVersion' },
      };
      await saveAll(params);
      updateLock();
    },
    [resetEditorValue, instance, saveAll, updateLock],
  );

  if (!canUpdateInstance || disableEdit) return null;

  return publishingPoints.LINEAR === publishingPoint ? (
    <LinearEllipsisMenu
      disableEdit={!canUpdateInstance}
      folders={folders}
      onSelectTemplate={onSelectTemplate}
      onSaveTemplate={onSaveTemplate}
      onDeleteTemplate={onDeleteTemplate}
      onCreateFolder={onCreateFolder}
      onDeleteFolder={onDeleteFolder}
      onDownload={handleDownload}
      onCreateDuplicate={onCreateDuplicate}
      onForceUnlock={onForceUnlock}
      onClientPrint={() => {
        setShowPrintDialog(true);
      }}
      lockedByUser={lockedByUser}
      canCreateNewTemplate={canCreateNewTemplate}
      canDeleteTemplate={canDeleteTemplate}
      canDeleteTemplateFolder={canDeleteTemplateFolder}
      canPinTemplateFolder={canPinTemplateFolder}
      canReorderTemplates={canReorderTemplates}
      onDeleteInstance={onDeleteInstance}
      isDeleteEnabled={isDeleteEnabled}
      title={instance?.mTitle}
      instanceId={instance?.mId}
      onRestoreVersion={onRestoreVersion}
      checkVersionRestorability={checkVersionRestorability}
      isSavingContent={isSavingContent}
      canCreateInstance={canCreateInstance}
      canScheduleInstance={canScheduleInstance}
      canDeleteInstance={canDeleteInstance}
      canUpdateInstance={canUpdateInstance}
      hideTemplateOptions={hideTemplateOptions}
      schedule={instance?.mPublishingAt}
      hideAutomationTemplates={hideAutomationTemplates}
      platformKind={instance?.mProperties?.platformKind}
      canSetDefaultTemplate={canSetDefaultTemplate}
      defaultTemplateRefId={defaultTemplateRefId}
      onSetDefaultTemplate={onSetDefaultTemplate}
      isContentLoaded={!!editorValue}
      isLockedByAnotherUser={readLock}
      canOpenStory={canOpenStory}
    />
  ) : (
    <GeneralEllipsisMenu
      disableEdit={!canUpdateInstance}
      isDeleteEnabled={isDeleteEnabled}
      onDeleteInstance={onDeleteInstance}
      onForceUnlock={onForceUnlock}
      isLockedByAnotherUser={readLock}
      lockedByUser={lockedByUser}
      title={instance?.mTitle}
      instanceId={instance?.mId}
      onRestoreVersion={onRestoreVersion}
      checkVersionRestorability={checkVersionRestorability}
      isSavingContent={isSavingContent}
      canDeleteInstance={canDeleteInstance}
      canUpdateInstance={canUpdateInstance}
      folders={folders}
      canCreateNewTemplate={canCreateNewTemplate}
      canDeleteTemplateFolder={canDeleteTemplateFolder}
      canPinTemplateFolder={canPinTemplateFolder}
      canReorderTemplates={canReorderTemplates}
      canDeleteTemplate={canDeleteTemplate}
      onDeleteFolder={onDeleteFolder}
      onSaveTemplate={onSaveTemplate}
      onSelectTemplate={onSelectTemplate}
      onDeleteTemplate={onDeleteTemplate}
      onCreateFolder={onCreateFolder}
      hideTemplateOptions={hideTemplateOptions}
      canSetDefaultTemplate={canSetDefaultTemplate}
      defaultTemplateRefId={defaultTemplateRefId}
      onSetDefaultTemplate={onSetDefaultTemplate}
      isContentLoaded={!!editorValue}
    />
  );
};

export default memo(Menu);
