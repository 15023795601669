const shouldIgnoreSetNotification = (payload, channelsToIgnore = []) => {
  if (!payload) return true;
  const { convoType } = payload;
  if (channelsToIgnore.includes(convoType)) return true;
  // In following scenario, notification was set during story/instance or team/department assignment
  // and the payload is related, which is only for updating list of messages in 'Dina Notifications'
  if (convoType === 'assignment') return true;
  if (payload.mType === 'message' && payload.message?.crudAction !== 'INSERT') return true;
  return false;
};

export default shouldIgnoreSetNotification;
