import React, { memo } from 'react';
import PropTypes from 'prop-types';
import useStyles from './thumbnail-styles';
import VideoComponents from './components/videoComponents';
import ImageComponents from './components/imageComponents';
import CommonComponents from './components/commonComponents';

const Thumbnail = ({
  id,
  type,
  size,
  width,
  height,
  src,
  onClose,
  onEdit,
  onTag,
  hidePlayButton,
  hideCloseButton,
  showEditButton,
  showTagButton,
  duration,
  durationDataType,
  canPlayVideo,
  videoThumbnailImage,
  usage,
  showPlayer,
  hideDuration,
  mId,
  mRefId,
  element,
  onImageClick,
  withSignedUrl,
}) => {
  const isImage = type === 'image' || type === 'mos';
  const isVideo = type === 'video';
  const isAudio = type === 'audio';

  const thumbnailImage = () => {
    if (isImage) return src;
    if ((isVideo || isAudio) && videoThumbnailImage) return videoThumbnailImage;
    return null;
  };

  const classes = useStyles({
    width: size || width,
    height: size || height,
    src: thumbnailImage(),
  });

  const handleThumbnailClick = (e) => {
    if (!isImage) return;
    onImageClick(e);
  };

  return (
    <div className={classes.root} onClick={handleThumbnailClick} role="presentation">
      {(isVideo || isAudio) && (
        <VideoComponents
          {...{
            videoThumbnailImage,
            size,
            width,
            height,
            src,
            hidePlayButton,
            durationDataType,
            duration,
            canPlayVideo,
            usage,
            showPlayer,
            hideDuration,
            mId,
            mRefId,
            element,
            withSignedUrl,
          }}
        />
      )}
      {isImage && <ImageComponents {...{ showTagButton, onTag }} />}
      <CommonComponents {...{ hideCloseButton, id, onClose, showEditButton, onEdit }} />
    </div>
  );
};

Thumbnail.propTypes = {
  /** id of the asset */
  id: PropTypes.string,
  /** size of the thumbnail, overwrites both width & height props */
  size: PropTypes.number,
  /** Width of the component */
  width: PropTypes.number,
  /** Height of the component */
  height: PropTypes.number,
  /** source of the media */
  src: PropTypes.string,
  /** Component type */
  type: PropTypes.oneOf(['image', 'video', 'mos', 'audio']),
  /** Boolean to hide close button */
  hideCloseButton: PropTypes.bool,
  /** Boolean to show edit button for image component */
  showEditButton: PropTypes.bool,
  /** Boolean to show tag button for image component */
  showTagButton: PropTypes.bool,
  /** Callback for clicking close button */
  onClose: PropTypes.func,
  /** Callback for edit button click */
  onEdit: PropTypes.func,
  /** Callback for tag button click */
  onTag: PropTypes.func,
  /** Duration of video for video component */
  duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Type of duration data */
  durationDataType: PropTypes.oneOf(['milliSeconds', 'string']),
  /** Boolean to play video of component */
  canPlayVideo: PropTypes.bool,
  /** Boolean to hide play button of video thumbnail */
  hidePlayButton: PropTypes.bool,
  /** Thumbnail image for video */
  videoThumbnailImage: PropTypes.string,
  /** Type of usage of thumbnail component */
  usage: PropTypes.string,
  /** Hides duration information */
  hideDuration: PropTypes.bool,
  /** handle thumbnail clicks for images */
  onImageClick: PropTypes.func,
};

Thumbnail.defaultProps = {
  id: '',
  size: 0,
  width: 96,
  height: 64,
  src: '',
  type: 'image',
  hideCloseButton: false,
  showEditButton: false,
  showTagButton: false,
  onClose: () => {},
  onEdit: () => {},
  onTag: () => {},
  duration: 0,
  durationDataType: 'milliSeconds',
  canPlayVideo: true,
  hidePlayButton: false,
  videoThumbnailImage: '',
  usage: 'default',
  hideDuration: false,
  onImageClick: () => {},
};

export default memo(Thumbnail);
