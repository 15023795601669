import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useReadOnly, useSelected, useSlate } from 'slate-react';
import { useRightHidden, useRightSelection } from 'store/sidebar';
import { elementTypes } from 'components/editor/constants/types';
import SelectedElement from 'components/editor/components/selectedElement';
import { postMosMessage } from 'utils/postMessage';
import Menu from 'components/editor/components/menu';
import { refreshSelection } from 'components/editor/utils';
import { onImageLoadingError } from 'utils/image/imageUtils';
import mosIcon from 'assets/icons/systemicons/editor/mos.svg';
import DragAndDrop from '../dragAndDrop';
import MosDropZone from './components/mosDropZone';
import AutomationIcon from '../primaryAutomation/components/automationIcon';
import { BoxWrapper, ContentWrapper, RootWrapper, TitleWrapper, Icon, IconWrapper } from './styled';

const menuItems = [
  {
    title: 'Open Asset',
    action: 'open-in-mos',
    divider: false,
  },
];

const MosObject = ({ attributes, children, element }) => {
  const readOnly = useReadOnly();
  const { data, type } = element;
  const isSelected = useSelected(element);
  const editor = useSlate();
  const { title, mosobj, iconUrl } = data;
  const [, setRightHidden] = useRightHidden();
  const [, setRightSelection] = useRightSelection();

  const handleOpenMosObject = () => {
    postMosMessage(mosobj, 'mos', element);
    setRightHidden(false);
    setRightSelection('mos');
  };

  const onMenuSelect = ({ action }) => {
    if (action === 'open-in-mos') handleOpenMosObject();
  };

  const onBlur = useCallback(() => {
    refreshSelection(editor, element);
  }, [editor, element]);

  return (
    <div {...attributes} onBlur={onBlur}>
      <DragAndDrop element={element} hideHighlight>
        <SelectedElement element={element}>
          <MosDropZone element={element}>
            {children}
            <RootWrapper contentEditable={false} readOnly={readOnly}>
              <BoxWrapper isSelected={isSelected}>
                {(!iconUrl && <AutomationIcon type={type} />) || (
                  <IconWrapper>
                    <Icon
                      src={iconUrl}
                      alt="asset"
                      onError={(e) => onImageLoadingError(e, mosIcon)}
                    />
                  </IconWrapper>
                )}
                <ContentWrapper>
                  <TitleWrapper className="primaryAutomationTitleWrapper">{title}</TitleWrapper>
                  <Menu
                    className="primaryAutomationMenu"
                    items={menuItems}
                    onSelect={onMenuSelect}
                  />
                </ContentWrapper>
              </BoxWrapper>
            </RootWrapper>
          </MosDropZone>
        </SelectedElement>
      </DragAndDrop>
    </div>
  );
};

MosObject.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

MosObject.defaultProps = {
  attributes: {},
  children: null,
  element: {
    type: elementTypes.CAMERA,
    children: [],
  },
};

export default memo(MosObject);
