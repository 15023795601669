import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Button from '@material-ui/core/Button';

const buttonStyles = ({ theme }) => css`
  ${theme.typography.dina.button};
  width: 100%;
  border-radius: 4px;
  height: 40px;
  text-transform: none;
`;

export const Wrapper = styled('div')`
  display: flex;
  padding: 4px;
  gap: 4px;
`;

export const OkButton = styled(Button)`
  ${buttonStyles};
  ${({ theme }) => css`
    background-color: ${theme.palette.dina.buttonBackgroundHighEmphasis};
    color: ${theme.palette.dina.highEmphasis};
  `}
  :hover {
    ${({ theme }) => css`
      filter: ${theme.palette.dina.onHover};
      background-color: ${theme.palette.dina.buttonBackgroundHighEmphasis};
    `}
  }
  :focus {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.palette.dina.onFocus};
  }
  :disabled {
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
    opacity: 0.35;
  }
`;

export const CancelButton = styled(Button)`
  ${buttonStyles};
  ${({ theme }) => css`
    color: ${theme.palette.dina.mediumEmphasis};
    border: 0.5px solid ${theme.palette.dina.buttonBorderOutlined};
    background-color: ${theme.palette.dina.backgroundResting};
  `}
  :hover {
    ${({ theme }) => css`
      filter: ${theme.palette.dina.onHover};
      color: ${theme.palette.dina.highEmphasis};
    `}
  }
  :focus {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.palette.dina.onFocus};
  }
`;
