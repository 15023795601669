import { ChangeEvent, KeyboardEventHandler, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import FilledInput from '@material-ui/core/FilledInput';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';

import { ReactComponent as CloseIcon } from 'assets/icons/systemicons/close_small.svg';
import { ReactComponent as EnterIcon } from 'assets/icons/systemicons/enter.svg';
import Text from 'components/text/Text';
import { InstanceChangeObject } from 'features/instance/hooks/useInstanceCore';
import useInstancePermissions from 'features/instance/hooks/useInstancePermissions';
import useInputEvents from 'hooks/useInputEvents';
import transientOptions from 'theme/helpers';

import useInstanceViewUtils from '../hooks/useInstanceViewUtils';
import { useInstanceMolecule } from '../store/instance';

export const TitleWrapper = styled(Text, transientOptions)<{ $canUpdate: boolean }>`
  cursor: ${({ $canUpdate }) => ($canUpdate ? 'pointer' : 'select')};
  flex: 1;
  padding: 0.5rem;

  :hover {
    text-decoration: ${({ $canUpdate }) => ($canUpdate ? 'underline' : 'none')};
  }
`;

export const TitleInput = styled(FilledInput)`
  &.MuiFilledInput-root {
    width: 100%;
    border-radius: 0;
    padding-right: 0.5rem;
  }
  .MuiFilledInput-input {
    padding: 0 0 0 0.5rem;
    height: 32px;
    ${({ theme }) => theme.typography.dina.listItemLabel}
  }

  &.MuiFilledInput-underline {
    &::before,
    &::after {
      border-bottom: 1px solid ${({ theme }) => theme.palette.dina.inputBorderResting};
    }
  }

  .MuiInputAdornment-positionEnd {
    display: flex;
    align-items: center;
    gap: 0.25rem;

    svg {
      &:hover {
        cursor: pointer;

        path {
          fill-opacity: 1;
        }
      }
    }
  }
`;

interface TitleBoxProps {
  onInstanceChanged: (instanceChangeObject: InstanceChangeObject) => Promise<unknown>;
}

const TitleBox = ({ onInstanceChanged }: TitleBoxProps) => {
  const { title } = useInstanceViewUtils();
  const { useDisableEdit } = useInstanceMolecule();
  const { canUpdateInstance } = useInstancePermissions();

  const [disableEdit] = useDisableEdit();

  const [showInput, setShowInput] = useState(false);
  const [localTitle, setLocalTitle] = useState(title);

  const onUpdateInput = (newValue: string) => {
    const value = newValue?.trim();
    if (!value) setLocalTitle(title);
    else if (value === title) setLocalTitle(value);
    else {
      setLocalTitle(value);
      void onInstanceChanged({ changeType: 'title', title: value });
    }
    setShowInput(false);
  };

  const [inputRef, handleKeyDown] = useInputEvents(onUpdateInput, localTitle!, title!, true);

  const onChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setLocalTitle(event.target.value);

  const handleEnterClick = () => {
    onUpdateInput(localTitle!);
  };

  const handleCloseClick = () => {
    setLocalTitle(title);
    setShowInput(false);
  };

  useEffect(() => {
    if (localTitle !== title) {
      setLocalTitle(title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  if (showInput)
    return (
      <ClickAwayListener onClickAway={() => setShowInput(false)}>
        <TitleInput
          autoFocus
          type="text"
          inputRef={inputRef}
          value={localTitle}
          onKeyDown={
            handleKeyDown as unknown as KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>
          }
          onChange={onChange}
          disabled={!canUpdateInstance || disableEdit}
          endAdornment={
            <InputAdornment position="end">
              <CloseIcon onClick={handleCloseClick} />
              <EnterIcon className="skipOverride" onClick={handleEnterClick} />
            </InputAdornment>
          }
        />
      </ClickAwayListener>
    );

  return (
    <TitleWrapper
      title={localTitle}
      onClick={() => {
        if (canUpdateInstance || !disableEdit) {
          setShowInput(true);
        }
      }}
      $canUpdate={canUpdateInstance || !disableEdit}
      variant="listItemLabel"
      color="highEmphasis"
      as="h1"
      truncate
    >
      {localTitle}
    </TitleWrapper>
  );
};

export default TitleBox;
