import React from 'react';
import { Backdrop } from '@material-ui/core';
import PropTypes from 'prop-types';

import { useTickerFeedSources } from 'store';
import FeedViewer from 'features/feedViewer';

import { Modal, ModalContent, Content } from './styled';

function MediaViewer({ handleClose, open, feedItem }) {
  const [sources] = useTickerFeedSources();

  const source = sources.find((p) => p.mRefId === feedItem?.provider);
  const accessToken = source?.accessToken ?? '';
  const useProxy = source?.useProxy;

  return (
    <Modal
      open={open}
      disableEnforceFocus
      onClose={() => handleClose()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 300,
      }}
    >
      <ModalContent>
        {open ? (
          <Content>
            <FeedViewer
              feedItem={{ ...feedItem, accessToken, useProxy }}
              closeTickerDialog={handleClose}
            />
          </Content>
        ) : null}
      </ModalContent>
    </Modal>
  );
}

MediaViewer.propTypes = {
  /** Close modal callback */
  handleClose: PropTypes.func,
  /** Is the modal open */
  open: PropTypes.bool,
  /** Feed item object */
  feedItem: PropTypes.shape({
    assets: PropTypes.arrayOf(PropTypes.object),
    mId: PropTypes.string,
    provider: PropTypes.string,
    mDescription: PropTypes.string,
    href: PropTypes.string,
    byline: PropTypes.string,
    section: PropTypes.string,
    mPublishedAt: PropTypes.string,
    mContent: PropTypes.string,
    mTitle: PropTypes.string,
  }),
};

MediaViewer.defaultProps = {
  handleClose: () => {},
  open: false,
  feedItem: null,
};

export default MediaViewer;
