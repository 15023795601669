import { elementTypes } from 'components/editor/constants/types';

const props = {
  input: {
    image: {
      placeholder: 'Add Photographer...',
      description: 'Who is the photographer?',
      propName: 'photographer',
    },
    video: {
      placeholder: 'Add Editor...',
      description: 'Who is the editor?',
      propName: 'editor',
    },
  },
  textArea: {
    image: {
      placeholder: 'Add Caption...',
      description: 'Describe the photo',
      propName: 'caption',
    },
    video: {
      placeholder: 'Add Caption...',
      description: 'Describe the video',
      propName: 'description',
    },
  },
  title: {
    image: {
      placeholder: 'Add Title',
      description: `Add a Title to the photo`,
      propName: 'title',
    },
    video: {
      placeholder: 'Add Title',
      description: `Add a Title to the video`,
      propName: 'title',
    },
  },
};

const getInputProps = (type, photographer = '', editors = '') => {
  const getValue = () => {
    if (type === 'image') return photographer;
    if (type === 'video') return editors;
    return '';
  };

  return { value: getValue(), ...props.input[type] };
};

const getTextAreaProps = (type, caption = '', description = '') => {
  const getValue = () => {
    if (type === 'image') return caption;
    if (type === 'video') return description;
    return '';
  };

  return { value: getValue(), ...props.textArea[type] };
};

const getTitleProps = (type, title = '') => ({ value: title, ...props.title[type] });

/* Update slate data */

const elementType = {
  video: elementTypes.VIDEO,
  audio: elementTypes.AUDIO,
};

const findElementType = (payload) => {
  if (
    ['video', 'audio'].includes(payload.itemType) &&
    !payload.mediaType.includes('placeholder') &&
    payload.mContentUrl
  )
    return elementType[payload.itemType];
  return elementTypes.PLACEHOLDER;
};

const updateSlateData = (previousData, newData) => {
  const duration =
    newData?.mMetaData?.reduce((acc, i) => {
      if (i.key.includes('mediaDuration') || i.key.includes('itemDuration')) return i.value;
      return acc;
    }, 0) ?? 0;

  return {
    data: {
      ...previousData,
      mediaType: newData.mediaType ?? previousData.mediaType,
      mTitle: newData.mTitle || previousData.mTitle,
      itemDuration: duration ?? previousData.itemDuration,
    },
    type: findElementType(newData),
  };
};

export { getInputProps, getTextAreaProps, getTitleProps, updateSlateData };
