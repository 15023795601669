import { ColumnDef } from '@tanstack/react-table';

import { ParsedMemberType } from 'types/members';

import { type ExtendedTableMeta, RowActions } from '../components/actions/RowActions';

export const actionColumn: ColumnDef<ParsedMemberType> = {
  cell: ({ row, table }) => (
    <RowActions row={row} tableMeta={table.options.meta as ExtendedTableMeta} />
  ),
  id: 'actions',
  enableHiding: false,
  enableResizing: false,
  enableSorting: false,
  maxSize: 36,
};
