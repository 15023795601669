import React from 'react';
import PropTypes from 'prop-types';
import fieldTypes from 'utils/rundown/fieldTypes';
import Dropdown from '../dropdown';
import InputField from '../inputField';
import useStyles from './metadataField-styles';
import Switch from '../switch';
import Combobox from '../comboBox';
import Grid from '../grid';

export const DivWrapper = ({ children, className }) => {
  const classes = useStyles();
  return <div className={`${classes.divWrapper} ${className}`}>{children}</div>;
};

const FlexibleField = ({
  type,
  name,
  updateField,
  renderedValue,
  options,
  parameters,
  parameterFields,
  severity,
  usage,
  description,
  disableEdit,
}) => {
  const switchSelected = renderedValue === 'true';
  const classes = useStyles();
  const className = classes[usage];

  switch (type) {
    case fieldTypes.SELECT:
    case fieldTypes.MENU:
      return (
        <DivWrapper {...{ className }}>
          <Dropdown
            label={name}
            onChange={updateField}
            selectedValue={renderedValue}
            {...{ options, severity, usage, disableEdit }}
          />
        </DivWrapper>
      );
    case fieldTypes.COMBOBOX:
      return (
        <DivWrapper {...{ className }}>
          <Combobox
            label={name}
            onChange={updateField}
            renderedValue={renderedValue}
            {...{ options, severity, usage, disableEdit }}
          />
        </DivWrapper>
      );
    case fieldTypes.BOOLEAN:
      return (
        <DivWrapper {...{ className }}>
          <Switch
            label={name}
            selected={switchSelected}
            onClick={() => updateField((!switchSelected).toString())}
            {...{ severity, usage, disableEdit }}
          />
        </DivWrapper>
      );
    case fieldTypes.GRID:
      return (
        <DivWrapper>
          <Grid
            label={name}
            parameters={parameters || []}
            {...{ parameterFields, renderedValue, updateField, disableEdit }}
          />
        </DivWrapper>
      );
    default:
      return (
        <DivWrapper {...{ className }}>
          <InputField
            label={name}
            value={renderedValue.toString()}
            onUpdateInput={updateField}
            inputDescription={description}
            disabled={disableEdit}
            {...{ severity, usage }}
          />
        </DivWrapper>
      );
  }
};

FlexibleField.propTypes = {
  /** Type of metadata field: select, combo, menu, default input field */
  type: PropTypes.string,
  /** label of the Field to be used */
  name: PropTypes.string,
  /** onUpdateValue callback function */
  updateField: PropTypes.func,
  /**  renderedValue on the field */
  renderedValue: PropTypes.string,
  /** options to be rendered on the select/menu/combobox */
  options: PropTypes.arrayOf(PropTypes.object),
  /** severity of the flexible field */
  severity: PropTypes.string,
  /** description of the field */
  description: PropTypes.string,
  /** Boolean that stops an user from editing */
  disableEdit: PropTypes.bool,
};

FlexibleField.defaultProps = {
  type: '',
  updateField: () => {},
  description: '',
  name: '',
  renderedValue: '',
  options: [],
  severity: 'regular',
  disableEdit: false,
};
export default FlexibleField;
