import styled from '@emotion/styled';

export const DateLabel = styled('div')`
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  &:hover {
    span {
      color: ${({ theme }) => theme.palette.dina.highEmphasis} !important;
    }
  }
`;

export const DateWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;
