import { format, isToday, isTomorrow, isYesterday } from 'date-fns';

/**
 * Adds relative context to the date (today, tomorrow, or yesterday) for the user.
 * If the date is none of these shows the date in a user given format
 */
const getRelativeDate = (date: Date | string, dateFormat = 'dddd, MMM D YYYY') => {
  if (isToday(date)) return 'today';
  if (isTomorrow(date)) return 'tomorrow';
  if (isYesterday(date)) return 'yesterday';
  return format(date, dateFormat);
};

export default getRelativeDate;
