import styled from '@emotion/styled';
import { css } from '@emotion/react';
import DividerComponent from 'components/divider';
import transientOptions from 'theme/helpers';
import { KANBAN_CARD_SIZE, KANBAN_CARD_WITHOUT_STORY_TITLE_SIZE } from 'utils/constants/kanbanCard';

export const KanbanCardWrapper = styled('div', transientOptions)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
  height: ${({ $autoHeight, $storyTitle }) => {
    if ($autoHeight) return 'auto';
    if ($storyTitle) return `${KANBAN_CARD_SIZE}px`;
    return `${KANBAN_CARD_WITHOUT_STORY_TITLE_SIZE}px`;
  }};
  gap: 4px;
`;

export const TopRowWrapper = styled('div', transientOptions)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  gap: 4px;
  cursor: default;
`;

export const TitleWrapper = styled('div')`
  ${({ theme }) => theme.typography.dina.caption};
  display: flex;
  align-items: center;
  line-height: 14px;
  flex: 1;
  min-width: 0;
  gap: 4px;
  svg {
    min-width: 20px;
    height: 20px;
  }
`;

export const DestinationWrapper = styled('span')`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  ${({ $canOpenDestination, theme }) =>
    $canOpenDestination &&
    css`
      color: ${theme.palette.dina.highEmphasis};
      cursor: pointer;
      :hover {
        transform: scale(1.03);
        text-decoration: underline;
      }
    `}
`;

export const DetailsWrapper = styled('div')`
  flex: 0;
  display: flex;
  align-items: center;
  height: 16px;
  .relationNumber {
    ${({ theme }) => theme.typography.dina.captionSmall};
    margin-right: 2px;
  }
`;

export const BottomRowWrapper = styled('div')`
  ${({ theme }) => theme.typography.dina.caption};
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: space-between;
  div {
    display: flex;
    align-items: center;
    & > :last-child {
      white-space: nowrap;
    }
  }
  .publishedText {
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
`;

export const PublishingInfoWrapper = styled('div', transientOptions)`
  border-radius: 4px;
  height: 20px;
  padding-right: 4px;
  margin-right: 8px;
  & > :last-child {
    margin-left: 4px;
    white-space: nowrap;
  }
`;

export const TitleRowWrapper = styled('div')`
  width: 100%;
`;

export const StoryTitle = styled('div')`
  ${({ theme }) => theme.typography.dina.overline};
  width: 100%;
  margin-bottom: 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Title = styled('div')`
  ${({ $autoHeight, theme }) => {
    if (!$autoHeight)
      return css`
        ${theme.typography.dina.listItemLabelMedium};
        white-space: nowrap;
      `;
    return css`
      ${theme.typography.dina.listItemLabelMedium};
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    `;
  }}
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ItemsWrapper = styled('div')`
  display: flex;
  gap: 2px;
  margin-right: 8px;
`;

export const Divider = styled(DividerComponent)`
  margin: 0 4px;
`;
