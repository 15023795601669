import styled from '@emotion/styled';
import { Typography, Link } from '@material-ui/core';

/** LinkItem */
export const Wrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.borderResting};
  cursor: pointer;
  user-select: none;
  transition: transform 0.2s;
  :hover {
    background-color: ${({ theme }) => theme.palette.dina.whiteHoverOverlay};
  }
`;

export const ThumbAndText = styled(Link)`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const Thumbnail = styled('img')`
  width: 32px;
  height: 32px;
`;

export const Title = styled(Typography)`
  ${({ theme }) => theme.typography.dina.listItemLabel};
  margin-left: 12px;
  display: flex;
  align-items: center;
`;
export const Description = styled(Typography)`
  ${({ theme }) => theme.typography.dina.caption};
  margin-left: 12px;
  display: flex;
  align-items: center;
`;

export const NewTabText = styled(Typography)`
  ${({ theme }) => theme.typography.dina.caption};
  text-align: right;
  margin-left: 12px;
  display: flex;
  align-items: center;
`;
