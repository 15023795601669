import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { createScope, molecule, useMolecule } from 'jotai-molecules';
import { groupBy, mapValues } from 'lodash';

import useUpdateConvoReadAt from 'hooks/useUpdateConvoReadAt';
import { isMessageHubOpenAtom, useSelectedConversationId } from 'store/messageHub';
import { MemberTypeEnum } from 'types/graphqlTypes';
import { Conversation } from 'types/messageHub';

export const ConversationScope = createScope<string | undefined>(undefined);

const convoMolecule = molecule((_getMol, getScope) => {
  getScope(ConversationScope);

  const [updateConvoReadAt] = useUpdateConvoReadAt();
  const [, setSelectedConversationId] = useSelectedConversationId();

  const notifiedCountAtom = atom<number>(0);

  const notificationsAtom = atom<Conversation[]>([]);
  const updateNotificationsAtom = atom(null, (get, set, convoId: string, storyId?: string) => {
    const notifications = get(notificationsAtom);
    const filteredNotifications = storyId
      ? notifications.filter(({ mStoryId }) => mStoryId !== storyId)
      : notifications.filter(({ mId }) => mId !== convoId);
    set(notificationsAtom, filteredNotifications);
  });
  const notificationsCountAtom = atom<Record<string, number>>((get) => {
    const notifications = get(notificationsAtom);
    return mapValues(groupBy(notifications, 'mId'), (group) => group.length);
  });
  const toggleMessageHubAtom = atom(null, (get, set) => {
    const isMessageHubOpen = get(isMessageHubOpenAtom);
    set(isMessageHubOpenAtom, !isMessageHubOpen);

    const notifications = get(notificationsAtom);
    const filteredNotifications = notifications.filter(
      ({ mType }) => mType !== MemberTypeEnum.Convo,
    );
    set(notificationsAtom, filteredNotifications);
  });

  const currentConvoAtom = atom<Conversation | undefined>(undefined);
  const updateCurrentConvoAtom = atom(null, (get, set, newConvo?: Conversation) => {
    const currentConvo = get(currentConvoAtom);
    if (currentConvo?.mId) {
      void updateConvoReadAt(currentConvo.mId);
    }
    if (!newConvo?.mId) {
      setSelectedConversationId(undefined);
      set(currentConvoAtom, undefined);
    } else if (currentConvo?.mId !== newConvo?.mId) {
      setSelectedConversationId(newConvo?.mId);
      set(currentConvoAtom, newConvo);
    }
  });

  const isCreateNewChatAtom = atom(false);
  const toggleIsCreateNewChatAtom = atom(null, (get, set) => {
    const isCreateNewChat = get(isCreateNewChatAtom);
    set(isCreateNewChatAtom, !isCreateNewChat);
  });

  return {
    useNotifiedCount: () => useAtom(notifiedCountAtom),
    useNotifications: () => useAtom(notificationsAtom),
    useSetNotifications: () => useSetAtom(updateNotificationsAtom),
    useNotificationsCount: () => useAtomValue(notificationsCountAtom),
    useToggleMessageHub: () => useSetAtom(toggleMessageHubAtom),
    useCurrentConversation: () => useAtomValue(currentConvoAtom),
    useSetCurrentConversation: () => useSetAtom(updateCurrentConvoAtom),
    useIsCreateNewChat: () => useAtomValue(isCreateNewChatAtom),
    useToggleIsCreateNewChat: () => useSetAtom(toggleIsCreateNewChatAtom),
  };
});

export const useConversationMolecule = () => useMolecule(convoMolecule);
