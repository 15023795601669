import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Popover from 'components/popover';
import NotificationPopup, { notificationIdentifiers } from 'components/notificationPopup';

import MenuItem from '../../../menuItem';
import TemplateSubMenu from '../templatesSubMenu';
import { List } from './foldersSubMenu-styled';

const SubMenu = (props) => {
  const {
    folders,
    anchorEl,
    onDeleteFolder,
    disabled,
    disableChildren,
    canCreateNewTemplate,
    canDeleteTemplate,
    canDeleteTemplateFolder,
    canPinTemplateFolder,
    canReorderTemplates,
    canSetDefaultTemplate,
    togglePin,
    updateMOrder,
    defaultTemplateRefId,
    onSetDefaultTemplate,
    isContentLoaded,
    ...rest
  } = props;
  const [anchor, setAnchor] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState({});
  const notificationRef = useRef(null);

  const handleClosePopover = () => {
    setAnchor(null);
    setSelectedFolder({});
  };

  const HandleUpdateMOrder = (newMOrder) => {
    updateMOrder(selectedFolder.mId, selectedFolder.mRefId, newMOrder);
  };

  return (
    <>
      <List $disableChildren={disableChildren}>
        {!selectedFolder?.mId && canPinTemplateFolder && folders.length > 0 && (
          <NotificationPopup
            useHighlight
            position="left"
            anchor={notificationRef}
            id={notificationIdentifiers.PinTemplateFolder}
            title="Did you know?"
            text="You can now pin template folders in the menu."
          />
        )}
        {folders.map((folder, index) => {
          const { mId, mRefId, mTitle, items, mProperties } = folder;
          return (
            <MenuItem
              ref={index === 0 ? notificationRef : undefined}
              key={mRefId}
              label={mTitle}
              data={items}
              showDeleteButton={canDeleteTemplateFolder}
              anchorEl={anchorEl}
              disabled={disabled}
              showPin={canPinTemplateFolder}
              onClick={() => {
                setAnchor(anchorEl);
                setSelectedFolder(folder);
              }}
              onDeleteButtonClick={() => onDeleteFolder(mId, mRefId)}
              pinned={mProperties?.pinned}
              togglePin={(newPinnedValue) => togglePin(mId, mRefId, mProperties, newPinnedValue)}
            />
          );
        })}
      </List>

      <Popover
        anchorEl={selectedFolder?.items?.length > 0 || canCreateNewTemplate ? anchor : null}
        onClose={handleClosePopover}
        noMargin
      >
        <TemplateSubMenu
          folderId={selectedFolder?.mRefId}
          templates={selectedFolder?.items}
          anchorEl={anchor}
          disabled={disableChildren}
          canCreateNewTemplate={canCreateNewTemplate}
          canDeleteTemplate={canDeleteTemplate}
          mOrder={selectedFolder?.mOrder}
          canReorderTemplates={canReorderTemplates}
          canSetDefaultTemplate={canSetDefaultTemplate}
          updateMOrder={HandleUpdateMOrder}
          defaultTemplateRefId={defaultTemplateRefId}
          onSetDefaultTemplate={onSetDefaultTemplate}
          isContentLoaded={isContentLoaded}
          {...rest}
        />
      </Popover>
    </>
  );
};

SubMenu.propTypes = {
  /** List of folders to be shown */
  folders: PropTypes.arrayOf(PropTypes.any),
  /** Node for the popover to attach to */
  anchorEl: PropTypes.instanceOf(Element),
  /** Callback to be invoked while deleting a folder */
  onDeleteFolder: PropTypes.func,
  /** boolean to disable the item */
  disabled: PropTypes.bool,
  /** boolean to disable the children */
  disableChildren: PropTypes.bool,
  /** boolean that hides create new template from menu */
  canCreateNewTemplate: PropTypes.bool,
  /** boolean that hides delete template from menu */
  canDeleteTemplate: PropTypes.bool,
  /** boolean that hides delete template folder from menu */
  canDeleteTemplateFolder: PropTypes.bool,
  canPinTemplateFolder: PropTypes.bool,
  canReorderTemplates: PropTypes.bool,
  canSetDefaultTemplate: PropTypes.bool,
  togglePin: PropTypes.func,
  updateMOrder: PropTypes.func,
  defaultTemplateRefId: PropTypes.string,
  onSetDefaultTemplate: PropTypes.func,
};

SubMenu.defaultProps = {
  folders: [],
  anchorEl: null,
  onDeleteFolder: () => {},
  disabled: false,
  disableChildren: false,
  canCreateNewTemplate: false,
  canDeleteTemplate: false,
  canDeleteTemplateFolder: false,
  canReorderTemplates: false,
  canSetDefaultTemplate: false,
  togglePin: () => {},
  updateMOrder: () => {},
  defaultTemplateRefId: '',
  onSetDefaultTemplate: () => {},
};

export default SubMenu;
