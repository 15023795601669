import { Text, View } from '@react-pdf/renderer';
import keyBy from 'lodash/keyBy';

import { getSubMdf, hasPermission, shouldFilterField } from 'features/mdf/mdf-utils';
import useGetMembersInfo from 'hooks/useGetMembersInfo';
import useSettingsValue from 'hooks/useSettingsValue';
import { FieldTypeEnum } from 'types/graphqlTypes';

import { getMdfFieldComponent } from '../docs/utils';
import { styles } from '../styles';
import { BlockProps } from '../types';

import { Order } from './Order';

export const Block = ({
  fields,
  layoutSettings,
  metadata,
  permissions,
  subMdfs,
  blockTitle,
  orders,
  subOrders,
  color,
  relationMembers,
  contacts,
  mdfsSeparated,
  groups,
  optionLists,
}: BlockProps) => {
  const { getMemberTitle, getMember } = useGetMembersInfo();

  const [getSettingsValue] = useSettingsValue();
  const contactFieldsToPrint =
    getSettingsValue('app.contactFieldsToPrint', 'app.contactFieldsToPrint', 'all')?.toString() ??
    'all';

  const subTypes = keyBy(subMdfs, (mdf) => mdf.label);

  const settingsMap = keyBy(layoutSettings, (setting) => setting.fieldId);

  const visibleFields = fields?.filter((f) =>
    shouldFilterField(
      f,
      settingsMap,
      settingsMap,
      false,
      hasPermission(permissions?.read[f.fieldId] as string[], groups),
    ),
  );

  return (
    <View style={styles.blockWrapper}>
      <View style={color ? { ...styles.colorLabel, backgroundColor: color } : styles.colorLabel} />
      <View style={{ flex: 1, height: '100%', flexDirection: 'column' }}>
        <View style={[styles.header, { padding: '4px 8px 0', marginBottom: 0 }]}>
          <Text style={[styles.title, { fontSize: 14 }]}>{blockTitle}</Text>
        </View>
        <View style={styles.horizontalLine} />

        {visibleFields ? (
          visibleFields?.map((field, index) => {
            if (field.type === FieldTypeEnum.subtype) {
              const subMdf = getSubMdf(field, metadata, subTypes);

              return (
                <View key={field.fieldId} style={{ margin: '8px' }}>
                  <Block
                    fields={subMdf?.fields}
                    layoutSettings={subMdf?.views.default}
                    permissions={subMdf?.permissions}
                    metadata={metadata}
                    blockTitle={
                      metadata[field.fieldId]?.toString() ?? settingsMap[field.fieldId].label
                    }
                    relationMembers={relationMembers}
                    contacts={contacts}
                    mdfsSeparated={mdfsSeparated}
                    groups={groups}
                    optionLists={optionLists}
                  />
                </View>
              );
            }

            return getMdfFieldComponent({
              field,
              settingsMap,
              metadata,
              showBottomDivider: index !== visibleFields.length - 1,
              getMemberTitle,
              getMember,
              relationMembers,
              contacts,
              contactFieldsToPrint,
              subMdfs,
              groups,
              mdfsSeparated,
              optionLists,
            });
          })
        ) : (
          <View style={styles.emptyBlock}>
            <Text>No options selected</Text>
          </View>
        )}

        {orders?.length && (
          <View style={{ padding: 8 }}>
            <View style={{ border: '1px solid #000', borderRadius: 4 }}>
              <View style={[styles.header, { padding: '4px 8px 0', marginBottom: 0 }]}>
                <Text style={[styles.title, { fontSize: 14 }]}>Tasks</Text>
              </View>
              <View style={styles.horizontalLine} />

              {orders.map((order, index) => {
                return (
                  <Order
                    key={order.mId}
                    order={order}
                    getMemberTitle={getMemberTitle}
                    groups={groups}
                    index={index}
                    subOrders={subOrders?.filter((subOrder) => subOrder.mResourceId === order.mId)}
                    getMember={getMember}
                    subMdfs={subMdfs}
                    relationMembers={relationMembers}
                    contacts={contacts}
                    mdfsSeparated={mdfsSeparated}
                    optionLists={optionLists}
                  />
                );
              })}
            </View>
          </View>
        )}
      </View>
    </View>
  );
};
