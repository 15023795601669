import styled from '@emotion/styled';
import { css } from '@emotion/react';

const transientOptions = {
  shouldForwardProp: (propName) => !propName.startsWith('$'),
};

export const Table = styled('table')`
  width: 100%;
  border-collapse: collapse;
  border-style: hidden;
  box-shadow: 0 0 0 1px ${({ theme }) => theme.palette.dina.dividerLight};
  border-radius: 2px;
`;

export const TableHeader = styled('th', transientOptions)`
  ${({ $width, theme }) => css`
    ${theme.typography.dina.overline};
    width: ${$width}px;
    border: 1px solid ${theme.palette.dina.dividerLight};
    background-color: ${theme.palette.dina.blackHoverOverlay};
  `}
  height: 24px;
  text-align: left;
  padding-left: 8px;
`;

export const TableData = styled('td', transientOptions)`
  ${({ $width, theme }) => css`
    ${theme.typography.dina.listItemLabel};
    width: ${$width}px;
    border: 1px solid ${theme.palette.dina.dividerLight};
  `}
  font-weight: normal;
  height: 40px;
  padding-left: 4px;
  padding-right: 4px;
`;
