import { gql } from '@apollo/client';

export default gql`
  subscription notifyMemberUpdate($mIdSubscribed: String!) {
    notifyMemberUpdateSubscription(mIdSubscribed: $mIdSubscribed) {
      mId
      mRefId
      mCreatedAt
      mTitle
      mType
      mUpdatedAt
      crudAction
      mMetaData {
        key
        value
        manual
        autoValue
      }
    }
  }
`;
