import styled from '@emotion/styled';
import { css } from '@emotion/react';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import { InputBase, Paper as MuiPaper } from '@material-ui/core';

export const Autocomplete = styled(MuiAutocomplete)`
  .MuiAutocomplete-inputFocused {
    padding: 0 0 0 4px;
  }
`;

export const Paper = styled(MuiPaper)`
  background-color: ${({ theme }) => theme.palette.dina.surfaceDialogs};
  .MuiAutocomplete-option {
    ${({ theme }) => theme.typography.dina.instanceHeaderTitle};
    font-weight: normal;
    &[aria-selected='true'] {
      background-color: ${({ theme }) => theme.palette.dina.onSelected};
    }
    &[data-focus='true']:not([aria-selected='true']) {
      background-color: ${({ theme }) => theme.palette.dina.statusOnHover};
    }
  }
  .MuiAutocomplete-noOptions {
    ${({ theme }) => theme.typography.dina.listItemLabel};
    font-weight: normal;
  }
`;

export const Input = styled(InputBase)`
  ${({ theme }) => css`
    ${theme.typography.dina.listItemLabel};
    border: 1px solid ${theme.palette.dina.inputBorderResting};
  `}
  font-weight: normal;
  height: 31px;
  background: transparent;
  outline: none;
  :hover {
    transition: 0.2s ease-in;
    ${({ theme }) => css`
      border: 1px solid ${theme.palette.dina.inputBorderResting};
      background-color: ${theme.palette.dina.whiteHoverOverlay};
    `}
  }
`;

export const EndAdornment = styled('div')`
  display: flex;
`;
