import React from 'react';

import { StyledBaseInput } from './styled';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(({ type, ...props }, ref) => {
  return <StyledBaseInput type={type} ref={ref} {...props} />;
});
Input.displayName = 'Input';
