/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable max-len */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { keyBy } from 'lodash';

import Dialog from 'components/dialogs/DialogBuilder';
import { StyledTextField } from 'components/mdfEditor/fields/text/styled';
import { Box } from 'layouts/box/Box';
import { OptionList } from 'types/graphqlTypes';

import { programmaticIdRegex } from '../integrations/EditActions';

interface Props {
  open: boolean;
  creating: boolean;
  existingOptionLists: OptionList[];
  setOpen: (val: boolean) => void;
  onCreate: (val: { label: string; id: string }) => void;
}

export default function CreateOptionListDialog({
  existingOptionLists,
  creating,
  open,
  setOpen,
  onCreate,
}: Readonly<Props>) {
  const [label, setLabel] = useState('');
  const [id, setId] = useState('');

  const byId = useMemo(() => {
    return keyBy(existingOptionLists, (e) => e.id);
  }, [existingOptionLists]);

  const onConfirm = useCallback(() => {
    if (label.length > 0 && id.length > 0) {
      onCreate({ label, id });
    }
  }, [label, id]);

  useEffect(() => {
    if (!open) {
      setLabel('');
      setId('');
    }
  }, [open]);

  const labelErrorMessage = useMemo(() => {
    return id.length === 0 ? 'Required value' : '';
  }, [label]);

  const idErrorMessage = useMemo(() => {
    if (id.length === 0) return 'Required value';
    if (byId[id]) return `A list with identifier ${id} already exists`;
    if (!programmaticIdRegex.test(id)) return 'Only alphanumeric characters are allowed';
    return '';
  }, [id]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <Dialog.Header>Create new option list</Dialog.Header>
      <Dialog.Body>
        <Box margin="10px 0">
          <StyledTextField
            error={idErrorMessage.length > 0}
            helperText={idErrorMessage}
            autoFocus
            variant="filled"
            fullWidth
            placeholder="Programmatic identifier"
            value={id}
            onChange={(event) => setId(event.target.value)}
          />
        </Box>
        <Box margin="10px 0">
          <StyledTextField
            error={labelErrorMessage.length > 0}
            helperText={labelErrorMessage}
            variant="filled"
            fullWidth
            placeholder="User visible label"
            value={label}
            onChange={(event) => setLabel(event.target.value)}
          />
        </Box>
      </Dialog.Body>
      <Dialog.Footer>
        <Dialog.CancelButton />
        <Dialog.ConfirmButton
          onConfirm={onConfirm}
          label="Confirm"
          disabled={idErrorMessage.length > 0 || labelErrorMessage.length > 0 || creating}
          loading={creating}
        />
      </Dialog.Footer>
    </Dialog>
  );
}
