/** Hook for getting a single feed item. Use provider and mId as input */
import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';

import { GET_FEED_ITEM } from 'api/feeds/graphql';
import { FeedItem, QueryGetFeedItemArgs } from 'types/graphqlTypes';

interface QueryReturnType {
  getFeedItem: FeedItem;
}

const useGetFeedItem = () => {
  const [queryItem] = useLazyQuery<QueryReturnType, QueryGetFeedItemArgs>(GET_FEED_ITEM, {
    fetchPolicy: 'cache-and-network',
  });

  const getFeedItem = useCallback(
    async (provider: string, mId: string) => {
      const result = await queryItem({
        variables: {
          input: {
            provider,
            mId,
          },
        },
      });

      return result?.data?.getFeedItem;
    },
    [queryItem],
  );

  return getFeedItem;
};

export default useGetFeedItem;
