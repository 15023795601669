import getMosAsset from 'components/editor/utils/getMosAsset';
import { elementTypes, actionTypes } from 'components/editor/constants/types';
import { Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { mediaTypes } from 'utils/rundownItemTypes';

const { MOS } = elementTypes;

/**
 * Adds graphics media to given element
 *
 * @param {Object} editor SlateJS editor instance
 * @param {Object} element SlateJS element to be updated
 * @param {Object} payload Data payload (MosObject) of dropped item
 * @param {Function} update Callback to be invoked on update
 * @returns {Object} SlateJS editor instance
 */

const addMos = (editor, element, payload, iconUrl, update) => {
  const asset = getMosAsset(payload, mediaTypes.MOS);
  const { type: elementType } = element;

  const path = ReactEditor.findPath(editor, element);

  if (asset) {
    if (elementType === MOS) {
      const { data } = element;
      const { assets: previousAssets, ...rest } = data;
      const newAsset = { ...asset, mosobj: payload };

      const updatedData = {
        ...rest,
        title: newAsset.title,
        description: newAsset.description,
        mosobj: payload,
        id: newAsset.id,
        iconUrl,
      };

      Transforms.setNodes(editor, { data: updatedData }, { at: path });

      update({
        type: actionTypes.AUTOMATION_UPDATE,
        payload: { document: editor.children, updatedData },
      });
    }

    ReactEditor.focus(editor);
  }
};

export default addMos;
