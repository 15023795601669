import { distanceInWordsToNow } from 'date-fns';
import capitalize from 'lodash/capitalize';

import memberTypes from 'operations/memberTypes';
import { Conversation } from 'types/messageHub';

const getUsersConversationData = (conversations: Conversation[], userId: string) => {
  const foundConversation = conversations.find((conversation) =>
    conversation?.mAssignedMembers?.find(({ mId }) => mId === userId),
  );
  if (foundConversation)
    return {
      conversationMId: foundConversation.mId,
      conversationMRefId: foundConversation.mRefId,
      chatHistory: capitalize(
        distanceInWordsToNow(foundConversation?.mUpdatedAt ?? '', { addSuffix: true }),
      ),
      mUpdatedAt: foundConversation.mUpdatedAt,
      mAssignedMembers: foundConversation.mAssignedMembers,
      convoType: foundConversation.convoType,
    };
  const tenDaysFromNow = new Date(new Date().getTime() + 10 * 86400000).toISOString();
  return {
    chatHistory: 'Never',
    mAssignedMembers: [{ mId: userId, mType: memberTypes.USER }],
    mUpdatedAt: tenDaysFromNow,
  };
};

export default getUsersConversationData;
