import { useReducer, useState, useCallback, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import getSearchFilters from 'utils/search/getSearchFilters';
import { searchbarActions, currentSearchTypes } from 'utils/constants/searchbar';

const defaultFilterModel = {};

const reducerFunction = (state, action) => {
  switch (action.type) {
    case searchbarActions.CLEAR:
      return {};
    case searchbarActions.UPDATE_FREE_TEXT: {
      return { ...state, freeText: action.value };
    }
    case searchbarActions.UPDATE_USER: {
      return { ...state, user: action.value };
    }
    case searchbarActions.UPDATE_TEAM: {
      return { ...state, team: action.value };
    }
    case searchbarActions.UPDATE_DEPARTMENT: {
      return { ...state, department: action.value };
    }
    case searchbarActions.UPDATE_PLATFORM: {
      return { ...state, platform: action.value };
    }
    case searchbarActions.SELECT: {
      return { ...action.value };
    }
    case searchbarActions.UPDATE_UNIT: {
      return {
        ...state,
        team: action.value.team,
        department: action.value.department,
        filterByAndLogic: action.value.filterByAndLogic,
      };
    }
    default:
      return { ...state };
  }
};

const useSearchBar = ({ historyLimit = 5 }) => {
  const [history, setHistory] = useState([]);
  const [currentSearch, setCurrentSearch] = useState(defaultFilterModel);
  const [assignedMembers, setAssignedMembers] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [platformsFilter, setPlatformsFilter] = useState([]);
  const [instanceAssignedMembers, setInstanceAssignedMembers] = useState([]);
  const [filterByAndLogic, setFilterByAndLogic] = useState(false);

  const [filterModel, updateFilterModel] = useReducer(reducerFunction, defaultFilterModel);
  useEffect(() => {
    const { assignedMembers: assignedMembers_, searchString: searchString_ , filterByAndLogic: filterByAndLogic_ } =
      getSearchFilters(filterModel);
    setAssignedMembers(assignedMembers_);
    setSearchString(searchString_);
    setFilterByAndLogic(filterByAndLogic_);
    const { assignedMembers: instanceAssignedMembers_, platformsFilter: platformsFilter_ } =
      getSearchFilters(filterModel, true);
    setInstanceAssignedMembers(instanceAssignedMembers_);
    setPlatformsFilter(platformsFilter_);
  }, [filterModel]);

  const updateHistory = useCallback(
    (newHistory) => {
      const newHistoryInput = { ...newHistory, id: uuid() };
      if (history.length > historyLimit - 1) setHistory([newHistoryInput, ...history.slice(0, 4)]);
      else setHistory([newHistoryInput, ...history]);
    },
    [history, historyLimit],
  );

  const updateCurrentSearch = (type, identification, model) => {
    switch (type) {
      case currentSearchTypes.HISTORY:
      case currentSearchTypes.SAVED_FILTERS: {
        setCurrentSearch({ type, identification, model });
        break;
      }
      default: {
        setCurrentSearch(null);
      }
    }
  };

  return {
    filterModel,
    updateFilterModel,
    history,
    updateHistory,
    currentSearch,
    updateCurrentSearch,
    assignedMembers,
    searchString,
    platformsFilter,
    instanceAssignedMembers,
    filterByAndLogic,
  };
};

export default useSearchBar;
