import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Popper } from '@material-ui/core';
import { Rnd } from 'react-rnd';
import useStyles from './resizableDialog-styles';

const getSize = (parentSize, childSize) =>
  Math.floor((100 * parentSize - parentSize * parseInt(childSize, 10)) / 200);

function ResizableWrapper({ children, asPopper, open, anchorEl, onClose, disableEscapeKeyDown }) {
  return asPopper ? (
    <Popper open={open} anchorEl={anchorEl} style={{ zIndex: 1301 }}>
      {children}
    </Popper>
  ) : (
    <Modal open={open} onClose={onClose} disableEscapeKeyDown={disableEscapeKeyDown}>
      {children}
    </Modal>
  );
}

const ResizableDialog = ({
  open,
  children,
  initialWidth,
  initialHeight,
  minWidth,
  minHeight,
  onClose,
  initialPosition,
  disableEscapeKeyDown,
  asPopper,
  anchorEl,
}) => {
  const [currentDimension, setCurrentDimension] = useState({
    width: initialWidth,
    height: initialHeight,
  });
  const { x: initialX, y: initialY } = initialPosition || { x: 0, y: 0 };
  const [currentPosition, setCurrentPosition] = useState({ x: initialX, y: initialY });

  const classes = useStyles();
  const { width, height } = currentDimension;
  const { x, y } = currentPosition;

  useLayoutEffect(() => {
    const bodyWidth = document.body.clientWidth;
    const bodyHeight = document.body.clientHeight;

    if (!initialPosition)
      setCurrentPosition({
        x: getSize(bodyWidth, initialWidth),
        y: getSize(bodyHeight, initialHeight),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ResizableWrapper
      asPopper={asPopper}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      disableEscapeKeyDown={disableEscapeKeyDown}
    >
      <Rnd
        className={classes.rndRoot}
        size={{ width, height }}
        position={{ x, y }}
        onResize={(_1, _2, ref, _3, position) => {
          setCurrentDimension({
            width: ref.style.width,
            height: ref.style.height,
          });
          setCurrentPosition({
            x: position.x,
            y: position.y,
          });
        }}
        onDrag={(_1, position) => {
          setCurrentPosition({
            x: position.x,
            y: position.y,
          });
        }}
        minWidth={minWidth}
        minHeight={minHeight}
        dragHandleClassName="dragHandler"
        bounds="window"
      >
        <div className={classes.childrenContainer}>{children}</div>
      </Rnd>
    </ResizableWrapper>
  );
};

ResizableDialog.propTypes = {
  /** whether the dialog is open or not */
  open: PropTypes.bool,
  /** children to render inside */
  children: PropTypes.element,
  /** initial width of the dialog, i.e. '50%' or 950 */
  initialWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** initial height of the dialog, i.e. '60%' or 800 */
  initialHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Minimum width of the resizable dialog */
  minWidth: PropTypes.number,
  /** Minimum height of the resizable dialog */
  minHeight: PropTypes.number,
  /** callback to close the modal */
  onClose: PropTypes.func,
  /** initial position of the dialog, initialWidth & initialHeight must be a number in this case */
  initialPosition: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  /** if true, hitting escape will not fire onClose */
  disableEscapeKeyDown: PropTypes.bool,
  /** should it be a modal or popper */
  asPopper: PropTypes.bool,
  /** anchorEl if it's a popper */
  anchorEl: PropTypes.element,
};

ResizableDialog.defaultProps = {
  open: false,
  children: null,
  initialHeight: '100%',
  initialWidth: '100%',
  minHeight: 640,
  minWidth: 840,
  onClose: () => {},
  initialPosition: undefined,
  disableEscapeKeyDown: false,
  asPopper: false,
  anchorEl: undefined,
};

export default ResizableDialog;
