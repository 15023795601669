/* eslint-disable max-len */
import React from 'react';
import { SvgIcon } from '@material-ui/core';

const CheckboxSelected = (props) => {
  const { className, mousedown } = props;
  const { classIcon, classWrapper } = className;
  return (
    <div className={classWrapper}>
      <SvgIcon className={`${classIcon} skipOverride`}>
        <path
          d="M19 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.11 21 
          21 20.1 21 19V5C21 3.9 20.11 3 19 3Z"
          fill="#0F75DF"
        />
        {!mousedown && (
          <path
            d="M10.1887 14.9387L7.59187 12.3419C7.29882 12.0488 6.82368 
            12.0488 6.53063 12.3419C6.23757 12.6349 6.23757 13.1101 6.53063 13.4031L10.1887 
            17.0612L18.6581 8.59187C18.9512 8.29882 18.9512 7.82368 18.6581 7.53062C18.3651 
            7.23757 17.8899 7.23757 17.5969 7.53063L10.1887 14.9387Z"
            fill="#FFFFFF"
          />
        )}
      </SvgIcon>
    </div>
  );
};

const CheckboxUnselected = ({ className }) => {
  const { classIcon, classWrapper } = className;
  return (
    <div className={classWrapper}>
      <SvgIcon className={classIcon}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 3C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H5ZM5 4C4.44772 4 4 4.44772 4 5V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19V5C20 4.44772 19.5523 4 19 4H5Z"
          fill="white"
          fillOpacity="0.54"
        />
      </SvgIcon>
    </div>
  );
};

const CheckboxIndeterminate = ({ className }) => {
  const { classIcon, classWrapper } = className;
  return (
    <div className={classWrapper}>
      <SvgIcon className={classIcon}>
        <rect
          xmlns="http://www.w3.org/2000/svg"
          x="2.5"
          y="2.5"
          width="19"
          height="19"
          rx="1.5"
          fill="#197EEB"
          fillOpacity="0.1"
          stroke="#0A73EB"
        />
        <rect
          xmlns="http://www.w3.org/2000/svg"
          x="6"
          y="11"
          width="12"
          height="2"
          rx="1"
          fill="white"
        />
      </SvgIcon>
    </div>
  );
};

export { CheckboxSelected, CheckboxUnselected, CheckboxIndeterminate };
