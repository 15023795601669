import { memo } from 'react';

import Tooltip from 'components/tooltip';
import { Box } from 'layouts/box/Box';
import { CustomElement } from 'types';

import { Actions, StyledDelete, StyledSidePanelActive, StyledSidePanelInactive } from './styled';

interface Props {
  element: CustomElement | undefined;
  showHtml: boolean;
  setShowHtml: (val: boolean) => void;
  onRemoveBlockClick: () => void;
}

const HtmlActions = ({ element, showHtml, setShowHtml, onRemoveBlockClick }: Readonly<Props>) => {
  if (!element) return null;

  return (
    <Actions
      $collapsed={!showHtml}
      className="actions"
      container
      flexDirection="column"
      justifyContent="space-between"
      gap="4px"
      height="100%"
    >
      <Box container flexDirection="column" gap="6px" justifyContent="start">
        <Tooltip title="Show HTML">
          {showHtml ? (
            <StyledSidePanelActive onClick={() => setShowHtml(!showHtml)} />
          ) : (
            <StyledSidePanelInactive onClick={() => setShowHtml(!showHtml)} />
          )}
        </Tooltip>
      </Box>
      <Tooltip title="Remove entire block">
        <StyledDelete onClick={onRemoveBlockClick} />
      </Tooltip>
    </Actions>
  );
};

export default memo(HtmlActions);
