import gql from 'graphql-tag';

export default gql`
  mutation logout($input: UpdateMemberInput) {
    logout(input: $input) {
      mId
      mRefId
      mLastLogout
    }
  }
`;
