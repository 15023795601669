import { MouseEvent, MouseEventHandler, useCallback } from 'react';

import usePromiseApi from './usePromiseApi';

interface CancellablePromise<T = unknown> {
  promise: Promise<T>;
  cancel: () => void;
}

class CancellationError extends Error {
  constructor(public value?: unknown) {
    super('Promise was cancelled');
    this.name = 'CancellationError';
  }
}

function cancellablePromise<T>(promise: Promise<T>): CancellablePromise<T> {
  let isCanceled = false;

  const wrappedPromise = new Promise<T>((resolve, reject) => {
    promise.then(
      (value) => {
        if (isCanceled) {
          reject(new CancellationError(value));
        } else {
          resolve(value);
        }
      },
      (error: Error) => {
        if (isCanceled) {
          reject(new CancellationError());
        } else {
          reject(error);
        }
      },
    );
  });

  return {
    promise: wrappedPromise,
    cancel: () => {
      isCanceled = true;
    },
  };
}

const delay = (n: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, n));

const useMouseClickEvents = <T extends Element>(
  onClick: MouseEventHandler<T>,
  onDoubleClick: MouseEventHandler<T>,
): [MouseEventHandler<T>, MouseEventHandler<T>] => {
  const api = usePromiseApi();

  const handleClick = useCallback(
    (event: MouseEvent<T>) => {
      api.clearPendingPromises();
      const waitForClick = cancellablePromise(delay(300));
      api.appendPendingPromise(waitForClick);

      event.persist();

      void waitForClick.promise
        .then(() => {
          api.removePendingPromise(waitForClick);
          onClick(event);
        })
        .catch((error: Error) => {
          api.removePendingPromise(waitForClick);
          if (error instanceof CancellationError) {
            // Promise was cancelled, which is expected behavior
            return;
          }
          // eslint-disable-next-line no-console
          console.error('An unexpected error occurred:', error);
        });
    },
    [onClick, api],
  );

  const handleDoubleClick = useCallback(
    (event: MouseEvent<T>) => {
      api.clearPendingPromises();
      onDoubleClick(event);
    },
    [onDoubleClick, api],
  );

  return [handleClick, handleDoubleClick];
};

export default useMouseClickEvents;
