/**
 * Stops event propagation
 *
 * @param {Object} event React synthetic event
 * @returns {Null}
 */

const stopEventPropagation = event => event.stopPropagation();

export default stopEventPropagation;
