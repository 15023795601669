import { Editor, Element } from 'slate';

import { elementTypes } from 'components/editor/constants/types';
import { CustomElement } from 'types';

import insertParagraph from '../components/paragraph/utils/insertParagraph';
import { ActionTypesEnum } from '../constants/types/actionTypes';
import { Update } from '../types';

const { VIDEO, IMAGE, GIF } = elementTypes;
const assetTypes = [VIDEO, IMAGE, GIF];

/**
 * Handles onKeyDown event on video elements
 *
 * @param editor SlateJS editor instance
 * @param event React synthetic event
 * @returns SlateJS editor instance
 */

const onAssetElementKeyDown = (
  editor: Editor,
  event: React.KeyboardEvent<HTMLDivElement>,
  update?: Update,
) => {
  const [match] = Editor.nodes<CustomElement>(editor, {
    match: (node) => Element.isElement(node) && assetTypes.includes(node.type),
  });

  if (match) {
    const { key } = event;
    const isEnter = key === 'Enter';
    const isBackspace = key === 'Backspace';

    if (isEnter) {
      event.preventDefault();
      insertParagraph(editor);
    }

    if (isBackspace && update) {
      const [element] = match;
      const { src } = element.data ?? {};

      update({
        type: ActionTypesEnum.ASSET_REMOVE,
        payload: { document: editor.children as CustomElement[], src },
      })?.then(
        () => {},
        () => {},
      );
    }
  }

  return editor;
};

export default onAssetElementKeyDown;
