import styled from '@emotion/styled/macro';
import transientOptions from 'theme/helpers';
import { Button } from '@material-ui/core';

export const ContainerWrapper = styled('div')`
  max-width: 738px;
  max-height: 520px;
  display: flex;
  background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel2};
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  overflow: hidden;
  position: relative;
`;

export const RootWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const BodyWrapper = styled('div')`
  display: flex;
  background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel2};
  border-bottom: 0.5px solid ${({ theme }) => theme.palette.dina.buttonBorderOutlined};
`;
export const DateSelectorWrapper = styled('div')`
  width: 470px;
  height: 272px;
`;
export const Drawer = styled('div')`
  position: absolute;
  display: flex;
  left: 384px;
  min-width: 24px;
  background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel2};
  border-radius: 0 8px 8px 0;
`;

export const SelectorsWrapper = styled('div', transientOptions)`
  display: flex;
  width: ${({ $openDrawer }) => ($openDrawer ? '244px' : '0')};
  height: 272px;
  overflow: hidden;
  transition: width 200ms ease-in-out;
  transform: translateZ(0);
`;

export const MonthSelectorWrapper = styled('div')`
  width: 148px;
  max-height: 272px;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const YearSelectorWrapper = styled('div')`
  width: 96px;
  max-height: 272px;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const OpenButton = styled(Button)`
  max-width: 24px;
  min-width: 24px;
  height: 272px;
  padding: 0;
  border-radius: 0 8px 8px 0;
  border-left: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel2};
  hover: {
    background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel2};
  }
`;
