import React, { useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { useSlate, ReactEditor } from 'slate-react';
import { Transforms } from 'slate';
import { useEditorContext } from 'components/editor/EditorContext';
import getTimeString from 'utils/getTimeString';
import useSettingsValue from 'hooks/useSettingsValue';
import getMilliseconds from 'utils/getMilliseconds';
import { inTimingTypes, actionTypes } from 'components/editor/constants/types';
import selectElement from 'components/editor/utils/selectElement';
import TimingSelect from '../timingSelect';

const { MANUAL_IN, AUTO_IN } = inTimingTypes;

const items = [
  { type: MANUAL_IN, title: 'Manual In' },
  { type: AUTO_IN, title: 'Set Start Time' },
];

const InTimingSelect = ({ element }) => {
  const editor = useSlate();
  const { update } = useEditorContext();
  const { inTiming, startTime } = element.data;
  const showInput = inTiming === AUTO_IN;

  const [getSettingsValue] = useSettingsValue();
  const includeFramesInOutTimingCalculation =
    getSettingsValue('rundown.includeFramesInOutTimingCalculation') === 'true';

  const fps = Number(getSettingsValue('rundown.videoFrameRate', undefined, 25)) || 25;

  const updateData = useCallback(
    (updatedProperty) => {
      const updatedData = { ...element.data, ...updatedProperty };

      selectElement(editor, element);
      Transforms.setNodes(editor, { data: updatedData });
      ReactEditor.focus(editor);

      update({
        type: actionTypes.AUTOMATION_UPDATE,
        payload: { document: editor.children, updatedData },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element],
  );

  const handleTypeChange = useCallback(
    (event) => {
      updateData({ inTiming: event.target.value });
      ReactEditor.focus(editor);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateData],
  );

  const handleInputChange = useCallback(
    (newValue) => {
      updateData({
        inTiming: AUTO_IN,
        startTime: getMilliseconds(newValue),
      });
    },
    [updateData],
  );

  const inputValue = useMemo(
    () =>
      getTimeString(startTime, {
        preserveSign: false,
        includeFrames: includeFramesInOutTimingCalculation,
        fps,
      }),
    [startTime, includeFramesInOutTimingCalculation, fps],
  );

  return (
    <TimingSelect
      items={items}
      showInput={showInput}
      selectValue={inTiming}
      inputValue={inputValue}
      handleInputChange={handleInputChange}
      handleTypeChange={handleTypeChange}
    />
  );
};

InTimingSelect.propTypes = {
  /** SlateJS element */
  element: PropTypes.shape({}),
};

InTimingSelect.defaultProps = {
  element: {},
};

export default memo(InTimingSelect);
