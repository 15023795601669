import { Svg, Rect, Path } from '@react-pdf/renderer';

const GraphicsIcon = () => {
  return (
    <Svg style={{ width: 32, height: 32, marginRight: 8 }} viewBox='0 0 32 32'>
      <Rect
        x='0.5'
        y='0.5'
        width='31'
        height='31'
        rx='5.5'
        ry='5.5'
        fill='#FFBE55'
        stroke='black'
      />
      <Path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.75 6L7 11V21L15.75 26L24.5 21V11L15.75 6ZM23.25 20.38L15.75 24.76L8.25 20.38V11.62L10.75 13.08V18.91L15.75 21.83L20.75 18.91V13.08L23.25 11.62V20.38ZM19.5 18.19V13.81L15.75 11.62L12 13.81V18.19L15.75 16L19.5 18.19Z'
        fill='#0F141A'
        fillOpacity='0.87'
      />
    </Svg>
  );
};

export default GraphicsIcon;
