import { useCallback } from 'react';

import { useMembersDialog } from 'store';
import { AssignedMember } from 'types';

import { ChangedTeamsOrDeptsTypes } from '../../../../../atomsTs';

const useUpdateProfile = () => {
  const [, setGetMembers] = useMembersDialog();

  const addTeamOrDept = useCallback(
    (
      variant: 'team' | 'department',
      name: string,
      members: AssignedMember[],
      onUpdate: (value: ChangedTeamsOrDeptsTypes) => void,
      updatedMembers?: AssignedMember[],
    ) => {
      setGetMembers({
        variant,
        open: true,
        startWith: updatedMembers ?? members,
        dialogTitle: `Add ${name} to ${variant}s`,
        removeTooltipText: `Remove ${name} from ${variant}`,
        subHeader: `${name} is part of ${variant}s:`,
        onOk: (newMembers: AssignedMember[]) => {
          onUpdate({ old: members, new: newMembers });
        },
      });
    },
    [setGetMembers],
  );

  return { addTeamOrDept };
};

export default useUpdateProfile;
