import { Dispatch, SetStateAction, useCallback } from 'react';
import { useMutation } from '@apollo/client';

import memberTypes from 'operations/memberTypes';
import UPDATE_USER_STATE from 'operations/mutations/updateUserState';
import GET_USERS from 'operations/queries/getAllDbUsers';
import { getMembersOfTypeQuery } from 'operations/queryVariables';
import { useMembers } from 'store';
import { User } from 'types';
import useLogger from 'utils/useLogger';

interface InputUser {
  mId: string;
  mRefId: string;
  username: string;
}

interface UserStateChangeInput {
  users?: InputUser[];
  action: string;
}

interface QueryUsersList {
  getUsers: User[];
}

interface Members {
  updateUserState: User[];
}

type UpdateUserStatusFunction = (
  action: string,
  mId: string,
  mRefId: string,
  username: string,
  setOpen: Dispatch<SetStateAction<boolean>>,
  errorToast: (err: unknown, description?: string) => void,
) => Promise<void>;

type UseUserStateChangeHook = () => [UpdateUserStatusFunction, boolean];

const useUserStateChange: UseUserStateChangeHook = () => {
  const logger = useLogger('use user state change');
  const [updateUserState, { loading }] = useMutation<Members>(UPDATE_USER_STATE);
  const [allMembers, setAllMembers] = useMembers();

  const updateUserStatus = useCallback(
    async (
      action: string,
      mId: string,
      mRefId: string,
      username: string,
      setOpen: Dispatch<SetStateAction<boolean>>,
      errorToast: (err: unknown, description?: string) => void,
    ) => {
      const input: UserStateChangeInput = {
        action: action,
      };
      const users: InputUser[] = [];
      const user: InputUser = { mId, mRefId, username: username };
      users.push(user);
      input.users = users;

      try {
        await updateUserState({
          variables: { input },
          onError: (error) => {
            logger.log(error);
            errorToast(error, 'Could not restore user. Please try again');
          },
          update: (proxy, result) => {
            const updatedUser = result?.data?.updateUserState;

            const members = proxy.readQuery<QueryUsersList>({
              query: GET_USERS,
              variables: getMembersOfTypeQuery(
                action === 'disable' ? memberTypes.USER : memberTypes.DISABLED_USER,
              ),
            });

            if (!members?.getUsers || !updatedUser) return null;

            const updatedMembers = members?.getUsers?.filter((member) => member.mId !== mId);
            setAllMembers({
              ...allMembers,
              user:
                action === 'disable'
                  ? allMembers.user?.filter((member) => member.mId !== mId)
                  : [...allMembers.user, ...updatedUser],
            });
            proxy.writeQuery({
              query: GET_USERS,
              variables: getMembersOfTypeQuery(
                action === 'disable' ? memberTypes.USER : memberTypes.DISABLED_USER,
              ),
              data: {
                getUsers: updatedMembers,
              },
            });
            setOpen(false);
          },
        });
      } catch (e) {
        logger.log(e);
      }
    },
    [updateUserState, logger, setAllMembers, allMembers],
  );
  return [updateUserStatus, loading];
};

export default useUserStateChange;
