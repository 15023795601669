import { Editor, Node, Transforms } from 'slate';
import { NodeInsertNodesOptions } from 'slate/dist/interfaces/transforms/node';
import { v1 as uuidv1 } from 'uuid';

import { ActionTypesEnum } from 'components/editor/constants/types/actionTypes';
import { Update } from 'components/editor/types';
import normalizeOnListFocus from 'components/editor/utils/normalizeOnListFocus';
import { CustomData, CustomElement, CustomText } from 'types';

const { insertNodes } = Transforms;

interface InsertBlock {
  editor: Editor;
  update: Update;
  type: string;
  data: CustomData | {};
  children?: (CustomElement | CustomText)[];
  options?: NodeInsertNodesOptions<Node>;
}

/**
 * Inserts block element
 *
 * @returns SlateJS editor instance
 */
const insertBlock = ({
  editor,
  update,
  type,
  data = {},
  children = [{ text: '' }],
  options = {},
}: InsertBlock) => {
  const blockElement = {
    type,
    data: { ...data, itemId: uuidv1() },
    children,
  };
  normalizeOnListFocus(editor);

  insertNodes(editor, blockElement, options);
  update({
    type: ActionTypesEnum.BLOCK_INSERT,
    payload: {
      document: editor.children as CustomElement[],
      insertedElement: blockElement,
    },
  })?.then(
    () => {},
    () => {},
  );

  return editor;
};

export default insertBlock;
