import gql from 'graphql-tag';

export default gql`
  mutation UpdateUserData($input: UpdateMemberInput) {
    updateMember(input: $input) {
      mId
      mRefId
      mType
      mSeen
      mMetaData {
        key
        value
      }
    }
  }
`;
