import { ReactComponent as Checked } from 'assets/icons/systemicons/statusIndicators/isSelected.svg';
import { CommandItem } from 'lib/command';

import { type MultiSelectContextProps, type MultiSelectListProps, type Option } from '../types';

type ContextProps = Pick<
  MultiSelectContextProps,
  'selected' | 'setSelected' | 'setInputValue' | 'onChange'
>;

interface DropdownListItemProps extends ContextProps, MultiSelectListProps {
  maxSelected: number;
  option: Option;
}

/** Dropdown list item */
export const DropdownListItem = ({
  maxSelected,
  onChange,
  onMaxSelected,
  option,
  selected,
  setInputValue,
  setSelected,
}: Readonly<DropdownListItemProps>) => {
  const onMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const isSelected = selected.some((s) => s.value === option.value);

  const onSelect = () => {
    // Deselect the option if it is already selected.
    if (isSelected) {
      const newOptions = selected.filter((s) => s.value !== option.value);
      setSelected(newOptions);
      onChange?.(newOptions);
      return;
    }

    // Check if the max selected limit is reached.
    if (selected.length >= maxSelected) {
      onMaxSelected?.(selected.length);
      return;
    }

    setInputValue('');
    const newOptions = [...selected, option];
    setSelected(newOptions);
    onChange?.(newOptions);
  };

  return (
    <CommandItem
      key={option.value}
      className={isSelected ? 'selected' : ''}
      value={option.label}
      disabled={option.disable}
      onMouseDown={onMouseDown}
      onSelect={onSelect}
    >
      {option.label}
      {isSelected && <Checked />}
    </CommandItem>
  );
};
