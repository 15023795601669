import styled from '@emotion/styled';

export const RootWrapper = styled('div')`
  width: 100%;
  height: 100%;
`;

export const SettingsContentWrapper = styled('div')`
  width: 100%;
`;

export const FieldTopWrapper = styled('div')`
  display: flex;
`;

export const OptionsWrapper = styled('div')`
  margin-left: 55px;
  margin-top: -32px;
`;
