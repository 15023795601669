import React, { useState, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSlate } from 'slate-react';
import Thumbnail from 'components/thumbnail';
import useFileUpload from 'hooks/useFileUpload';
import { ReactComponent as AddIcon } from 'assets/icons/systemicons/add.svg';
import { generateName, refreshSelection } from 'components/editor/utils';
import { elementTypes } from 'components/editor/constants/types';
import { RectangleButton } from 'components/buttons';

import Box from '../box';

import { ContentWrapper, ThumbnailWrapper, IconWrapper, VideoIcon, ImageIcon } from './styled';

const Button = memo(RectangleButton);
const menuItems = [];
const mediaTypes = ['video/mp4', 'image/gif', 'image/jpeg', 'image/png'];

const Icons = () => (
  <IconWrapper>
    <VideoIcon />
    <ImageIcon />
  </IconWrapper>
);

const Media = ({ attributes, children, element }) => {
  const readOnly = false;
  const [assets, setAssets] = useState([]);
  const editor = useSlate();
  const { type } = element;

  // this function needs to be updated
  const addAssets = useCallback(
    (uploadedFiles) => {
      const updatedAssets = [...assets, ...uploadedFiles];
      setAssets(updatedAssets);
    },
    [assets],
  );

  // this function needs to be updated
  const removeAsset = useCallback(
    (id) => {
      const updatedAssets = assets.filter((asset) => asset.name !== id);
      setAssets(updatedAssets);
    },
    [assets],
  );

  const onFileUpload = useCallback(
    async (files) => {
      const uploadedFiles = [];
      await files.forEach(({ file, filePath }) => {
        const fileName = generateName(file.type);
        const [fileType] = file.type.split('/');
        const newAsset = { name: fileName, type: fileType, src: filePath };
        uploadedFiles.push(newAsset);
      });
      addAssets(uploadedFiles);
    },
    [addAssets],
  );

  const uploadMedia = useFileUpload(mediaTypes, onFileUpload, true);

  const handleMediaSelect = useCallback(() => uploadMedia(), [uploadMedia]);

  const onBlur = useCallback(() => {
    refreshSelection(editor, element);
  }, [editor, element]);

  return (
    <div {...attributes} onBlur={onBlur}>
      {children}
      <Box
        iconComponent={<Icons className="skipOverride" />}
        title="Add Photos and/or Videos"
        readOnly={readOnly}
        menuItems={menuItems}
        boxType={type}
        type="media"
      >
        <ContentWrapper>
          {assets.map((asset, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <ThumbnailWrapper key={index}>
              <Thumbnail
                src={asset.src}
                size={88}
                type={asset.type}
                id={asset.name}
                onClose={removeAsset}
              />
            </ThumbnailWrapper>
          ))}
          <Button onClick={handleMediaSelect} disabled={readOnly} width={88} height={88}>
            <AddIcon />
          </Button>
        </ContentWrapper>
      </Box>
    </div>
  );
};

Media.propTypes = {
  /** Attributes of SlateJS children */
  attributes: PropTypes.shape({}),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
};

Media.defaultProps = {
  attributes: {},
  children: null,
  element: {
    children: [],
    data: { src: '' },
    type: elementTypes.MEDIA,
  },
};

export default memo(Media);
