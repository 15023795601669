import React from 'react';

import Scrollbar from 'components/scrollbar/scrollbar';

import SettingsContent from '../settingsContent/settingsContent-view';
import fieldTypes from '../../../../../utils/fieldTypes';
import {
  useSelectedMenuSystemSettings,
  useChangedLinkFields,
  useLinkFields,
} from '../../../../../atoms';

import { RootWrapper } from './styled';

const fieldTypesOptions = [];

for (const [key, value] of Object.entries(fieldTypes)) {
  fieldTypesOptions.push({ id: key, title: value, value });
}

const fieldProperties = [
  {
    key: 'name',
    label: 'Name',
    type: fieldTypes.TEXT,
    propName: 'name',
    placeholder: 'Site name',
  },
  {
    key: 'description',
    label: 'Description',
    type: fieldTypes.TEXT,
    propName: 'description',
    placeholder: 'Site description',
  },
  {
    key: 'value',
    label: 'Url',
    type: fieldTypes.TEXT,
    propName: 'value',
    placeholder: 'https://...',
  },
  {
    key: 'readonly',
    label: 'iframe?',
    type: fieldTypes.BOOLEAN,
    propName: 'readonly',
    description: 'Open in an iframe',
  },
];

const getValue = (type, value) => {
  if (type === fieldTypes.BOOLEAN) {
    return value?.toString() === 'true';
  }
  if (type === fieldTypes.NUMBER) {
    return value?.toString();
  }
  return value || '';
};

function LinkDetails() {
  const [selectedSettings] = useSelectedMenuSystemSettings();
  const [changedLinkFields, setChangedLinkFields] = useChangedLinkFields();
  const [linkFields] = useLinkFields();

  const link = linkFields?.find(({ id }) => id === selectedSettings);

  const onChangeLinkFields = (val, contentKey, originalValue) => {
    const changedSetting = changedLinkFields.find((x) => x.key === contentKey);
    if (changedSetting) {
      if (changedSetting?.originalValue === val) {
        setChangedLinkFields(changedLinkFields.filter((x) => x.key !== changedSetting.key));
      } else {
        changedSetting.value = val;
      }
    } else {
      setChangedLinkFields([
        ...changedLinkFields,
        {
          key: contentKey,
          value: val,
          originalValue,
        },
      ]);
    }
  };

  return (
    <RootWrapper>
      <Scrollbar>
        {link && (
          <>
            {fieldProperties.map((property) => (
              <SettingsContent
                key={`${link.id}:${property.key}`}
                contentKey={`${link.id}:${property.key}`}
                label={property.label}
                type={property.type}
                value={() => getValue(property.type, link[property.propName])}
                description={property.description}
                placeholder={property.placeholder}
                onChange={onChangeLinkFields}
                changedSettings={changedLinkFields}
                options={fieldTypesOptions}
              />
            ))}
          </>
        )}
      </Scrollbar>
    </RootWrapper>
  );
}

export default LinkDetails;
