import { Dispatch, SetStateAction, useCallback } from 'react';
import { useMutation } from '@apollo/client';

import memberTypes from 'operations/memberTypes';
import DELETE_USER from 'operations/mutations/deleteUser';
import GET_ALL_USERS from 'operations/queries/getAllDbUsers';
import { getMembersOfTypeQuery } from 'operations/queryVariables';
import { useMembers } from 'store';
import { MemberType } from 'types/graphqlTypes';
import useLogger from 'utils/useLogger';

interface QueryUsersList {
  getUsers: MemberType[];
}

interface DeleteUserType {
  deleteUser: MemberType;
}

type DeleteUserStatusFunction = (
  mId: string,
  mRefId: string,
  username: string,
  setOpen: Dispatch<SetStateAction<boolean>>,
  errorToast: (err: unknown, description?: string) => void,
) => Promise<void>;

type UseDeleteUserHook = () => [DeleteUserStatusFunction, boolean];

const useDeleteUser: UseDeleteUserHook = () => {
  const logger = useLogger('DeleteUser');
  const [deleteUser, { loading }] = useMutation<DeleteUserType>(DELETE_USER);
  const [members, setMembers] = useMembers();

  const handleDelete: DeleteUserStatusFunction = useCallback(
    async (mId, mRefId, username, setOpen, errorToast) => {
      const variables = {
        input: {
          mId,
          mRefId,
          username,
        },
      };

      await deleteUser({
        variables,
        onError: (error) => {
          logger.log(error);
          errorToast(error, 'Could not delete user. Please try again');
        },
        update: (client) => {
          try {
            setMembers({
              ...members,
              user: members.user?.filter((member) => member.mId !== mId) ?? [],
            });
            const usersList = client.readQuery<QueryUsersList>({
              query: GET_ALL_USERS,
              variables: getMembersOfTypeQuery(memberTypes.DISABLED_USER),
            });
            if (!Array.isArray(usersList?.getUsers)) return;
            const filteredUsers = usersList?.getUsers?.filter((user) => {
              return user.mId !== mId;
            });
            client.writeQuery({
              query: GET_ALL_USERS,
              variables: getMembersOfTypeQuery(memberTypes.DISABLED_USER),
              data: {
                getUsers: filteredUsers,
              },
            });
            setOpen(false);
          } catch (err) {
            logger.log(err);
          }
        },
      });
    },
    [deleteUser, logger, members, setMembers],
  );
  return [handleDelete, loading];
};

export default useDeleteUser;
