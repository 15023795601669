import React from 'react';
import { ReactComponent as EffectIcon } from 'assets/icons/systemicons/effect.svg';
import { ReactComponent as MixIcon } from 'assets/icons/systemicons/mix.svg';

const transitionIcons = {
  EFFECT: <EffectIcon />,
  MIX: <MixIcon />,
};

export default transitionIcons;
