import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  closestCenter,
  // eslint-disable-next-line sort-imports
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  // eslint-disable-next-line sort-imports
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import sortBy from 'lodash/sortBy';

import { useGetOptionList } from 'api/optionLists/useGetOptionList';
import { useUpdateOptionList } from 'api/optionLists/useUpdateOptionList';
import { ReactComponent as Add } from 'assets/icons/systemicons/plus_small.svg';
import { Button } from 'components/buttons';
import { SortableItemWrapper } from 'components/editMdfDialog/components/SortableItemWrapper';
import LoadingIndicator from 'components/loadingIndicator/LoadingIndicator';
import { StyledTextField } from 'components/mdfEditor/fields/text/styled';
import Scrollbar from 'components/scrollbar';
import useToast from 'components/toast/useToast';
import Tooltip from 'components/tooltip';
import { createAlternative } from 'features/mdf/mdf-utils';
import { Box, HStack } from 'layouts/box/Box';
import { Alternative, OptionList } from 'types/graphqlTypes';

import { ListWrapper, Remove, RowWrapper } from './styled';

interface OptionListProps {
  list: OptionList;
}

export function OptionListComponent({ list }: Readonly<OptionListProps>) {
  const { errorToast, toast } = useToast();
  const { optionList, loading, error } = useGetOptionList(list.id, true);
  const { updateOptionList } = useUpdateOptionList();
  const [options, setOptions] = useState<Alternative[]>([]);
  const [saving, setSaving] = useState(false);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  useEffect(() => {
    setOptions(optionList?.alternatives ?? []);
  }, [optionList]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    const oldIndex = options.findIndex((item) => item.id === active.id);
    const newIndex = options.findIndex((item) => item.id === over?.id);
    const updatedOrder = arrayMove(options, oldIndex, newIndex);
    setOptions(updatedOrder);
  };

  const removeAlternative = (alternative: Alternative) => {
    setOptions([...options.filter((alt) => alt.id !== alternative.id)]);
  };

  const addOption = () => {
    const newOption = createAlternative('value', options.length + 1);
    setOptions([...options, newOption]);
  };

  const handleOptionChange = (
    newValue: string,
    prop: 'label' | 'value',
    alternative: Alternative,
    index: number,
  ) => {
    const copy = [...options];
    const newAlt: Alternative =
      prop === 'label'
        ? { id: alternative.id, label: newValue, value: alternative.value }
        : { id: alternative.id, label: alternative.label, value: newValue };
    copy.splice(index, 1, newAlt);
    setOptions([...copy]);
  };

  const doSave = useCallback(() => {
    if (optionList) {
      setSaving(true);
      updateOptionList({
        ...optionList,
        alternatives: options,
      })
        .then(() =>
          toast({
            title: 'List saved',
            type: 'success',
          }),
        )
        .catch(errorToast)
        .finally(() => setSaving(false));
    }
  }, [updateOptionList, setSaving, options, optionList]);

  const duplicateValues = useMemo(() => {
    const fSet = new Set<string>();
    const duplicates: string[] = [];
    options.forEach((opt) => {
      if (fSet.has(opt.value)) {
        duplicates.push(opt.value);
      } else {
        fSet.add(opt.value);
      }
    });
    return duplicates;
  }, [options]);

  const doSortAlphabetically = useCallback(
    (opt: Alternative[]) => {
      setOptions(sortBy(opt, ['label']));
    },
    [setOptions],
  );

  if (loading) {
    return <LoadingIndicator />;
  }
  if (error) {
    return <div>Something went wrong: {error.message}</div>;
  }
  return (
    <Box maxWidth="600px" height="100%">
      <HStack justifyContent="space-between" padding="8px">
        <Button
          width={120}
          height={32}
          title={duplicateValues.length > 0 ? 'Duplicate values detected' : 'Save'}
          onClick={doSave}
          disabled={saving || duplicateValues.length > 0}
        >
          {saving ? 'Saving' : 'Save changes'}
        </Button>
        <HStack gap="8px">
          <Button
            width={60}
            height={32}
            variant="outlined"
            usage="outlined"
            title="Sort alphabetically"
            onClick={() => doSortAlphabetically(options)}
          >
            Sort
          </Button>
          <Button
            width={120}
            height={32}
            variant="outlined"
            usage="outlined"
            title="Add option"
            onClick={addOption}
          >
            <Add className="skipOverride" />
            Add option
          </Button>
        </HStack>
      </HStack>
      <ListWrapper>
        <Scrollbar>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext items={options} strategy={verticalListSortingStrategy}>
              {options.map((alt, i) => {
                const isDuplicate = Boolean(duplicateValues.includes(alt?.value));
                return (
                  <SortableItemWrapper key={alt.id} item={alt}>
                    <RowWrapper>
                      <StyledTextField
                        variant="filled"
                        value={alt.label}
                        onChange={(ev) => handleOptionChange(ev.target.value, 'label', alt, i)}
                        style={{ width: '250px' }}
                      />
                      <Tooltip title={isDuplicate ? 'Duplicate value' : ''}>
                        <StyledTextField
                          name="value"
                          variant="filled"
                          value={alt.value}
                          onChange={(ev) => handleOptionChange(ev.target.value, 'value', alt, i)}
                          error={isDuplicate}
                          style={{ width: '250px' }}
                        />
                      </Tooltip>
                      <Remove className="remove" onClick={() => removeAlternative(alt)} />
                    </RowWrapper>
                  </SortableItemWrapper>
                );
              })}
            </SortableContext>
          </DndContext>
        </Scrollbar>
      </ListWrapper>
    </Box>
  );
}
