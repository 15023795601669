import React from 'react';
import PropTypes from 'prop-types';
import { useDrag } from 'react-dnd';
import { Typography } from '@material-ui/core';
import Thumbnail from 'components/thumbnail';
import { mediaTypes } from 'utils/rundownItemTypes';
import { ReactComponent as Drag } from 'assets/icons/systemicons/drag.svg';
import { ReactComponent as MosIcon } from 'assets/icons/systemicons/rundown/mos.svg';
import useStyles from './thumbMos-styles';

const ThumbMos = (props) => {
  const { data, title, iconUrl } = props;

  const classes = useStyles();
  const [, dragRef] = useDrag({
    type: mediaTypes.MOS,
    item: {
      type: mediaTypes.MOS,
      payload: data,
      iconUrl,
    },
  });
  return (
    <div className={classes.container} ref={dragRef}>
      <Typography className={classes.title} classes={{ root: classes.titleText }}>
        {title}
      </Typography>
      {iconUrl ? (
        <Thumbnail
          hidePlayButton
          src={iconUrl}
          width={96}
          height={64}
          type="mos"
          usage="scratchPad"
          hideCloseButton
        />
      ) : (
        <MosIcon className={classes.mosIcon} />
      )}
      <Drag className={classes.dragButton} />
    </div>
  );
};

ThumbMos.propTypes = {
  /** Title of the graphic */
  title: PropTypes.string,
};

ThumbMos.defaultProps = {
  title: '',
};

export default ThumbMos;
