/* eslint-disable no-console */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useContextMenu } from 'react-contexify';
import useIntersectionObserver from '@react-hook/intersection-observer';
import { useInfiniteQuery } from '@tanstack/react-query';
import axios from 'axios';
import { debounce, isEmpty } from 'lodash';

import { useGetIntegrationsForAdmin } from 'api/config/useGetIntegrations';
import { ReactComponent as SearchIconSvg } from 'assets/icons/systemicons/search.svg';
import { Input } from 'components/input/styled';
import { LoadingButtonIndicator } from 'components/loadingIndicator/LoadingIndicator';
import { DateRange } from 'components/mdfEditor/fields/date/DatePicker';
import useToast from 'components/toast/useToast';
import useOpenSearchResult from 'hooks/useOpenSearchResult';
import { Box } from 'layouts/box/Box';
import { IntegrationEnum, IntegrationType } from 'types/graphqlTypes';
import { FilterValueType } from 'types/widget';

import SearchDeckItem from './SearchPluginDeckItem';
import { SearchPayload, SearchResultItem } from './types';

import { HeaderWrapper, IntersectionObserver, SearchResultList, Wrapper } from './styled';
interface Props {
  config: IntegrationType;
  deckId: string;
  onSearchStringChange: (val: string) => void;
  filters?: FilterValueType;
  selectedDate?: DateRange;
  federatedSearchString?: string;
}

const getSearchResult = async (
  plugin: IntegrationType | null,
  searchString: string,
  pageParam: number,
) => {
  if (!plugin?.endpoint) return null;
  const finalPayload: SearchPayload = {
    searchString,
    from: pageParam,
    pageSize: 25,
    pluginId: plugin.externalId,
  };
  try {
    const { data } = await axios.post<SearchResultItem[]>(plugin.endpoint, finalPayload);
    return data;
  } catch (err: unknown) {
    console.error(err);
    return [];
  }
};

export default function SearchPluginDeck({
  config,
  deckId,
  filters,
  onSearchStringChange,
  federatedSearchString,
}: Readonly<Props>) {
  const { errorToast } = useToast();
  const { show } = useContextMenu({ id: 'pluginMenu' });
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const [searchInput, setSearchInput] = useState((filters?.searchString as string) ?? '');
  const { integrations: plugins } = useGetIntegrationsForAdmin(IntegrationEnum.SearchPlugin);
  const { isIntersecting } = useIntersectionObserver(ref, { pollInterval: 2000 });
  const openSearchResult = useOpenSearchResult();
  const searchString = useMemo(() => {
    const savedString = (filters?.searchString as string | undefined) ?? '';
    if (typeof federatedSearchString === 'string') {
      if (isEmpty(savedString)) return federatedSearchString;
      return `${savedString} ${federatedSearchString}`;
    }
    return savedString;
  }, [filters, federatedSearchString]);

  const pageSize = 25;
  const localConfig = useMemo(() => {
    return plugins.find((p) => p.mRefId === config.mRefId);
  }, [plugins]);

  const debounced = useRef(
    debounce((newValue: string, oldValue: string) => {
      if (newValue !== oldValue) {
        onSearchStringChange(newValue);
      }
    }, 300),
  );

  const { data, isLoading, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteQuery({
      queryKey: [`plugin-search-${localConfig?.externalId}-${deckId}`, searchString],
      queryFn: ({ pageParam = 0 }) => getSearchResult(localConfig ?? null, searchString, pageParam),
      initialPageParam: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPageData, allPages) => {
        if (Array.isArray(lastPageData)) {
          if (lastPageData.length < pageSize) return undefined;
          return (allPages.length - 1) * pageSize + pageSize;
        }
      },
    });

  const items = useMemo(() => {
    if (Array.isArray(data?.pages)) {
      return (data?.pages ?? []).flat(1).filter((item) => item !== null) as SearchResultItem[];
    } else {
      return [];
    }
  }, [data]);

  useEffect(() => {
    debounced.current(searchInput, searchString);
  }, [searchInput]);

  useEffect(() => {
    if (isIntersecting && !isFetchingNextPage) {
      fetchNextPage().catch(errorToast);
    }
  }, [isIntersecting, isFetchingNextPage]);

  const onContextClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>, item: SearchResultItem) => {
      show({
        event,
        props: {
          item,
          pluginId: localConfig?.mRefId,
        },
      });
    },
    [show],
  );

  const onDoubleClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>, item: SearchResultItem) => {
      openSearchResult(item);
    },
    [],
  );

  return (
    <Wrapper>
      <HeaderWrapper>
        {isLoading || isFetching || isFetchingNextPage ? (
          <LoadingButtonIndicator inline size={16} style={{ marginRight: '5px' }} />
        ) : (
          <SearchIconSvg />
        )}
        <Input
          value={searchInput}
          onChange={(val) => setSearchInput(val.currentTarget.value)}
          placeholder="Search here..."
        />
      </HeaderWrapper>
      <SearchResultList ref={wrapperRef}>
        {typeof data === 'string' && <Box padding="12px">{data}</Box>}
        {items.map((item) => (
          <SearchDeckItem
            item={item}
            key={item.id}
            onDoubleClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
              onDoubleClick(event, item)
            }
            onContextClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
              onContextClick(event, item)
            }
          />
        ))}
        {hasNextPage && <IntersectionObserver ref={setRef} />}
      </SearchResultList>
    </Wrapper>
  );
}
