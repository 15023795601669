import { useRef } from 'react';
import styled from '@emotion/styled';

import { useCreateMemberDialog } from 'components/createNewV3/CreateNewDialog';
import DividerComponent from 'components/divider';
import TourPopup from 'components/tourPopup';
import useCheckUserRight from 'hooks/useCheckUserRight';
import { tours } from 'store';

import Create from './create-view';

export const CreateWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 48px;
`;

const Divider = styled(DividerComponent)`
  margin: 12px 0px;
`;

const CreateTab = () => {
  const [, showCreateMemberDialog] = useCreateMemberDialog();
  const mainTourRef = useRef<React.ElementRef<'div'> | null>(null);
  const [checkUserRight] = useCheckUserRight();
  const canCreateStory = checkUserRight('story', 'create');
  const canCreatePitch = checkUserRight('pitch', 'create');

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    showCreateMemberDialog({
      anchorEl: event.currentTarget,
    });
  };

  if (!(canCreateStory || canCreatePitch)) return <div />;
  return (
    <>
      <TourPopup
        position="bottom"
        anchor={mainTourRef}
        tourStep={tours.MainTour.steps.CreateButton}
        tourId={tours.MainTour.id}
      />
      <CreateWrapper>
        <div ref={mainTourRef}>
          <Create onClick={handleClick} />
        </div>
      </CreateWrapper>

      <Divider orientation="vertical" flexItem />
    </>
  );
};

export default CreateTab;
