/**
 * Parses given text with DOMParser
 *
 * @param {String} text Text to be pasred
 * @returns {Object} Parsed xml object
 */

const parseMos = (text) => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(text, 'text/xml');

  return xmlDoc;
};

export default parseMos;
