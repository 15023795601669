import React from 'react';
import PropTypes from 'prop-types';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { StyledDragHandle, StyledFieldWrapper } from '../styled';

function FieldWrapper({ field, children }) {
  const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef } =
    useSortable({
      id: field.id,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div key={field.id} ref={setNodeRef} style={style} {...attributes}>
      <StyledFieldWrapper>
        {children}
        <div>
          <StyledDragHandle {...listeners} ref={setActivatorNodeRef} />
        </div>
      </StyledFieldWrapper>
    </div>
  );
}

FieldWrapper.propTypes = {
  field: PropTypes.shape({
    type: PropTypes.oneOf(['boolean', 'select', 'text']),
    id: PropTypes.string,
    description: PropTypes.string,
    options: PropTypes.array,
  }),
};

FieldWrapper.defaultProps = {
  field: null,
};

export default FieldWrapper;
