import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSlate, useReadOnly } from 'slate-react';
import { useDrop } from 'react-dnd';
import selectElement from '../../utils/selectElement';

const DropZone = ({ accept, children, element, onDrop }) => {
  const editor = useSlate();
  const readOnly = useReadOnly();

  const [{ didDrop }, dropRef] = useDrop({
    accept,
    canDrop: () => !readOnly,
    drop: (item) => {
      if (!didDrop) onDrop(item);
    },
    collect: (monitor) => {
      const isActive = monitor.canDrop() && monitor.isOver();

      if (isActive) {
        selectElement(editor, element);
      }

      return { didDrop: monitor.didDrop() };
    },
  });

  return <div ref={dropRef}>{children}</div>;
};

DropZone.propTypes = {
  /** Types for React-DnD */
  accept: PropTypes.arrayOf(PropTypes.string),
  /** SlateJS children */
  children: PropTypes.node,
  /** SlateJS element */
  element: PropTypes.shape({}),
  /** Callback to be invoked on item drop */
  onDrop: PropTypes.func,
};

DropZone.defaultProps = {
  accept: [],
  children: null,
  element: {
    children: [],
  },
  onDrop: (droppedItem) => {},
};

export default memo(DropZone);
