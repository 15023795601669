/* eslint-disable import/prefer-default-export */

/** Map from value stored in dynamodb to values used in the views */
const mapping = (storedVal) => ({
  id: storedVal.id,
  title: storedVal.name,
  description: storedVal.description,
  url: storedVal.value,
  iframe: storedVal.readonly,
});

/** Return the links as an array. Each link will consist of:
 * id, title, description, url and iframe */
export const mapLinks = (props) => {
  if (!props) return [];

  const { fields } = props;

  return fields?.reduce((accumulator, value) => {
    if (value.value) accumulator.push(mapping(value));
    return accumulator;
  }, []);
};
