import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Text from 'components/text/Text';
import { HStack, VStack } from 'layouts/box/Box';
import { CommandItem } from 'lib/command';

import { ToolbarIcons } from '../../command-constants';

export const ItemInstances = styled(HStack)`
  padding: 2px 0;

  svg {
    margin-right: 2px;
  }
`;

export const ItemDate = styled(Text)`
  padding: 2px 0;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ItemFooter = styled(HStack)`
  ${({ theme }) => theme.typography.dina.caption};
  align-content: center;
  width: 100%;
  justify-content: start;
  gap: 8px;
  margin-top: 4px;
  svg path {
    fill-opacity: 0.7;
  }
`;

export const ItemContentWrapper = styled(VStack)`
  width: 100%;
`;

export const ItemTitleWrapper = styled(HStack)`
  width: 100%;
  gap: 4px;
`;

export const ItemTitle = styled('div')<{ $titleOnly?: boolean }>`
  ${({ theme, $titleOnly }) =>
    $titleOnly ? theme.typography.dina.listItemLabel : theme.typography.dina.listItemLabelBold};
  width: 100%;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ItemDescription = styled('div')`
  ${({ theme }) => theme.typography.dina.caption};
  margin-top: 4px;
  position: relative;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const statusToColor: Record<'ready' | 'active' | 'notReady', string> = {
  active: '#FF9800',
  ready: '#FF6666',
  notReady: 'transparent',
};

export const RundownStatus = styled(Text)<{ status: 'ready' | 'active' | 'notReady' }>`
  text-transform: uppercase;
  ${({ status }) => css`
    color: ${statusToColor[status]};
  `}
`;

export const IntersectionObserver = styled('div')`
  height: 20px;
  width: 100%;
  position: absolute;
  bottom: 0;
  pointer-events: none;
`;

export const IconWrapper = styled('div')<{ $color?: string }>`
  width: 20px;
  height: 20px;
  background-color: ${({ theme, $color }) => $color ?? theme.palette.dina.borderHover};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 16px;
    height: 16px;
    path: {
      fill-opacity: 1;
    }
  }
`;

export const SearchItemWrapper = styled(CommandItem)`
  display: flex;
  justify-content: space-between;
  padding: 4px;
  width: 100%;
  overflow: hidden;
  em {
    font-style: normal;
    color: ${({ theme }) => theme.palette.dina.statusSearchMatchBG};
  }
  svg {
    flex-grow: 0;
    flex-shrink: 0;
    path {
      fill-opacity: 1;
    }
  }
  max-height: 84px;
  .search-icon {
    align-self: start;
    margin-top: 4px;
  }
  .dimmed path {
    fill-opacity: 0.6;
  }
`;

export const RestrictedIcon = styled(ToolbarIcons.Restricted)`
  width: 16px;
  height: 16px;
  flex: 0 0 auto;
`;
