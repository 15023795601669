import { useMutation } from '@apollo/client';
import POST_EVENT from 'operations/mutations/postEvent';

const usePostEvent = () => {
  const [postEventMutation] = useMutation(POST_EVENT);
  const postEvent = async (mId, name, subtopic, mType) => {
    await postEventMutation({
      variables: {
        input: {
          name,
          subtopic,
          mId,
          mType,
        },
      },
    });
  };
  return [postEvent];
};

export default usePostEvent;
