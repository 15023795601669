/* eslint-disable max-len */
import React, { ForwardedRef, forwardRef, useEffect, useRef } from 'react';
import styled from '@emotion/styled';

import Text from 'components/text/Text';
import { Alternative } from 'types/graphqlTypes';

interface Props {
  setValue: (val: string) => void;
  value: string;
  options: Alternative[];
  disabled?: boolean;
  style?: React.CSSProperties;
  allowNoValue?: boolean;
}

/**
 * Most light weight way of doing this is encoding svg directly into svg as a background on the select item itself.
 * Since this component may render many times in a large grid, we need to find the most resource saving way of styling a native select.
 */
const svgWhite = encodeURIComponent(
  `<svg width="8" height="24" viewBox="0 0 8 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.145157 9.79515C-0.0483857 9.98869 -0.0483857 10.3025 0.145157 10.496L4 14.3509L7.85484 10.496C8.04839 10.3025 8.04839 9.98869 7.85484 9.79515C7.6613 9.60161 7.34751 9.60161 7.15396 9.79515L4 12.9491L0.846038 9.79515C0.652495 9.60161 0.3387 9.60161 0.145157 9.79515Z" fill="white" fill-opacity="0.54"/>
</svg>`,
);

const svgBlack = encodeURIComponent(
  `<svg width="8" height="24" viewBox="0 0 8 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.145157 9.79515C-0.0483857 9.98869 -0.0483857 10.3025 0.145157 10.496L4 14.3509L7.85484 10.496C8.04839 10.3025 8.04839 9.98869 7.85484 9.79515C7.6613 9.60161 7.34751 9.60161 7.15396 9.79515L4 12.9491L0.846038 9.79515C0.652495 9.60161 0.3387 9.60161 0.145157 9.79515Z" fill="black" fill-opacity="0.54"/>
</svg>`,
);

const StyledSelect = styled('select')`
  // A reset of styles, including removing the default dropdown arrow
  appearance: none;
  // Additional resets for further consistency
  background-color: transparent;
  border: none;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  color: ${({ theme }) => theme.palette.dina.highEmphasis};
  cursor: pointer;
  outline: none;
  background: ${({ theme }) =>
    `transparent url('data:image/svg+xml;utf8,${
      theme.palette.mode === 'dark' ? svgWhite : svgBlack
    }') no-repeat`};
  background-position: right 0px top 50%;

  :disabled {
    background: none;
    color: rgba(255, 255, 255, 0.45);
    cursor: default;
  }
`;

/**
 * On windows / chrome, the color from StyledSelect applies
 * to options too making the text invisible, so we explicitly
 * set it to black.
 */
const StyledOption = styled('option')`
  color: black;
`;

function LWSelect(
  { setValue, value: alternativeValue, options, disabled, style, allowNoValue = false }: Props,
  ref?: ForwardedRef<HTMLSelectElement> | null,
) {
  const selectRef = useRef<HTMLSelectElement>(null);
  useEffect(() => {
    if (selectRef.current) {
      selectRef.current.focus();
    }
  }, []);

  if (!options?.length) {
    return (
      <Text variant="captionItalic" color="mediumEmphasis">
        No options
      </Text>
    );
  }

  return (
    <StyledSelect
      style={style}
      value={alternativeValue}
      onChange={(ev) => setValue(ev.target.value)}
      onClick={(ev) => ev.stopPropagation()}
      ref={ref}
      disabled={disabled}
    >
      {allowNoValue && (
        <StyledOption selected value="">
          {!alternativeValue ? 'Select an option' : 'No value'}
        </StyledOption>
      )}
      {options.map((o) => (
        <StyledOption value={o.value} key={o.value}>
          {o.label}
        </StyledOption>
      ))}
    </StyledSelect>
  );
}

export default forwardRef(LWSelect);
