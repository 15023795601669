/* eslint-disable consistent-return */
import { useEffect, useRef } from 'react';

const useEventListener = (eventName, handler, element) => {
  // Create a ref that stores handler
  const savedHandler = useRef();

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      // Make sure element supports addEventListener
      // On
      const isSupported = element && element.current && element.current.addEventListener;

      if (!isSupported) return;

      // Create event listener that calls handler function stored in ref
      const eventListener = (event) => savedHandler.current(event);

      /**
       * Capturing the mutable element ref inside the useEffect as
       * suggested https://reactjs.org/blog/2020/08/10/react-v17-rc.html#potential-issues
       */
      const elementRef = element;

      // Add event listener
      elementRef.current.addEventListener(eventName, eventListener);

      // Remove event listener on cleanup
      return () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        if (elementRef && elementRef.current)
          elementRef.current.removeEventListener(eventName, eventListener);
      };
    },

    [eventName, element], // Re-run if eventName or element changes
  );
};

export default useEventListener;
