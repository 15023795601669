/* eslint-disable no-console */
import { useCallback } from 'react';
import { ApolloCache, gql, useMutation } from '@apollo/client';

import { MemberType, MemberTypeEnum } from 'types/graphqlTypes';
import useLogger from 'utils/useLogger';

import { CreateBlock } from './types';
import { GET_BLOCKS, GetBlocks } from './useGetMdfBlocks';
import { BLOCK_PROPS, memberToBlock } from './utils';

const CREATE_BLOCK = gql`
  mutation CreateBlock($input: CreateMemberInput) {
    createMember(input: $input) {
      ${BLOCK_PROPS}
    }
  }
`;

interface CreateBlockResult {
  createMember: MemberType;
}

const getErrorMessage = <E, I>(err: E, input: I): string =>
  `Could not create block: ${err instanceof Error ? err.message : ''} - input: ${JSON.stringify(
    input,
  )}`;

export const getCachedBlocks = (proxy: ApolloCache<unknown>, newBlock: MemberType) => {
  const cachedBlocksFromQuery = proxy.readQuery<GetBlocks>({
    query: GET_BLOCKS,
    variables: {
      input: {
        mId: newBlock.mId,
        mType: MemberTypeEnum.Block,
      },
    },
  });

  return cachedBlocksFromQuery?.getMembers ?? [];
};

export const writeBlocksToCache = (
  proxy: ApolloCache<unknown>,
  currentBlock: MemberType,
  updatedBlocks: MemberType[],
) => {
  proxy.writeQuery<GetBlocks>({
    query: GET_BLOCKS,
    variables: {
      input: {
        mId: currentBlock.mId,
        mType: MemberTypeEnum.Block,
      },
    },
    data: {
      getMembers: updatedBlocks,
    },
  });
};

export const insertToCache = (proxy: ApolloCache<unknown>, newBlock: MemberType) => {
  const cachedBlocks = getCachedBlocks(proxy, newBlock);

  const alreadyExists = cachedBlocks?.find((block) => block.mRefId === newBlock.mRefId);
  if (alreadyExists) return;
  const updatedBlocks = [newBlock, ...cachedBlocks];

  writeBlocksToCache(proxy, newBlock, updatedBlocks);
};

export const useCreateBlock = () => {
  const [createMutation] = useMutation<CreateBlockResult>(CREATE_BLOCK);
  const logger = useLogger('CreateBlock');
  const createBlock = useCallback(
    async (input: CreateBlock) => {
      try {
        const result = await createMutation({
          variables: {
            input: {
              ...input,
              mType: MemberTypeEnum.Block,
            },
          },
          update: (proxy, mutationResult) => {
            const newBlock = mutationResult.data?.createMember;
            if (newBlock) insertToCache(proxy, newBlock);
          },
        });
        return memberToBlock(result?.data?.createMember as MemberType);
      } catch (err) {
        logger.log(getErrorMessage(err, input));
      }
    },
    [createMutation],
  );
  return { createBlock };
};
