/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import inRange from 'lodash/inRange';
import slice from 'lodash/slice';
import sortBy from 'lodash/sortBy';
import sortedUniqBy from 'lodash/sortedUniqBy';

const getHighlighted = (searchedData, searchKey, classes) => {
  const sortUniqueFn = (item) => item[0] + item[1];

  /*
   * This function takes two arguments where first one is two
   * dimensional array and the child array contains two value,
   * the second argument is an array of two values.
   */
  const reducerFn = (acc, curr) => {
    const [currX, currY] = curr;
    if (currY - currX === 0) return [...acc];
    if (acc.length === 0) return [curr];

    const [accX, accY] = acc[acc.length - 1];
    if (inRange(currX, accX, accY + 1) || inRange(accX, currX, currY + 1)) {
      return [...slice(acc, 0, acc.length - 1), [Math.min(accX, currX), Math.max(accY, currY)]];
    }
    return [...acc, curr];
  };
  const highlight = (text) => `<span class='${classes.highlightMatch}'>${text}</span>`;

  return searchedData.map((searched) => {
    const item = { ...searched.item };
    searched.matches.forEach((match) => {
      let { value } = match;
      const sortedIndices = sortBy(match.indices, sortUniqueFn);
      const indices =
        searchKey.length > 1
          ? sortedUniqBy(sortedIndices, sortUniqueFn).reduce(reducerFn, [])
          : sortedUniqBy(sortedIndices, sortUniqueFn);
      indices.forEach((reducedIndex, index) => {
        const indexIncrement = index * highlight('').length;
        const x = reducedIndex[0] + indexIncrement;
        const y = reducedIndex[1] + indexIncrement + 1;
        const head = slice(value, 0, x).join('');
        const replace = slice(value, x, y).join('');
        const tail = slice(value, y).join('');
        value = `${head}${highlight(replace)}${tail}`;
      });
      // eslint-disable-next-line react/no-danger
      item[match.key] = <div dangerouslySetInnerHTML={{ __html: value }} />;
    });
    return item;
  });
};

export default getHighlighted;
