/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {
  TableBody as MaterialTableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Input from 'components/shared/input';
import { actionTypes } from '../../../utils';

const useStyles = makeStyles(theme => ({
  tableData: {
    ...theme.typography.dina.listItemLabel,
    fontWeight: 'normal',
    border: `1px solid ${theme.palette.dina.dividerLight}`,
    height: '40px',
    paddingLeft: '4px',
    paddingRight: '4px',
    '&:last-child': {
      paddingRight: 4,
    },
  },
}));

const TableBody = ({ rows, columns, onUpdate }) => {
  const classes = useStyles();
  const handleKeyDown = (event, cIndex, rIndex) => {
    if (
      event.key === 'Tab' &&
      cIndex === rows.length - 1 &&
      rIndex === columns.length - 1
    ) {
      onUpdate({
        type: actionTypes.INSERT_ROW,
        payload: columns,
      });
    }
  };
  return (
    <MaterialTableBody>
      {rows.map((row, cIndex) => (
        <TableRow key={`${cIndex}`}>
          {row.map((item, rIndex) => (
            <TableCell
              key={`${item.key}-${rIndex}`}
              classes={{ root: classes.tableData }}
              component="th"
              scope="row"
            >
              <Input
                value={item.value}
                onUpdate={newValue => {
                  if (newValue !== item.value)
                    onUpdate({
                      type: actionTypes.UPDATE_ROW,
                      payload: { rIndex, cIndex, value: newValue },
                    });
                }}
                onKeyDown={event => handleKeyDown(event, cIndex, rIndex)}
              />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </MaterialTableBody>
  );
};

TableBody.propTypes = {
  /** callback when any field is updated  */
  onUpdate: PropTypes.func,
  /** Fields to be mapped on metadata on instance view */
  rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
};

TableBody.defaultProps = {
  onUpdate: () => {},
  rows: [],
};

export default TableBody;
