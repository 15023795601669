/**
 * Gets duration key substring
 *
 * @param {Object} item Duration object
 * @returns {string} Duration key
 */

const getDurationKey = ({ key }) => {
  const hasInitialNumbers = !isNaN(key.substring(0, 2));

  return hasInitialNumbers ? key.substring(3) : key;
};

export default getDurationKey;
