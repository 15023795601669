import styled from '@emotion/styled';

import iconComponents from '../toolbar/components/blockButton/constants/iconComponents';

export const IconComponent = (type: string) => styled(iconComponents[type])`
  margin: 6px;
`;
export const AutoCompleteWrapper = styled('div')`
  width: 100%;
`;

export const ChipListWrapper = styled('div')`
  list-style: none;
  margin-bottom: 16px;
  display: flex;
  flex-wrap: wrap;
  li {
    margin-left: 4px;
    margin-top: 2px;
  }
`;
