import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Popper, TextField } from '@material-ui/core';

export const SelectWrapper = styled('div')`
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  margin-bottom: 5px;
  width: 100%;
`;

export const StyledOptionWrapper = styled('div')`
  ${({ theme }) => theme.typography.dina.listItemLabel};
  width: 100%;
  height: 36px;
`;

export const StyledOption = styled('div')`
  width: 100%;
  line-height: 34px;
`;

export const StyledTextField = styled(TextField)`
  svg path {
    fill: ${({ theme }) => theme.palette.dina.highEmphasis};
  }
  .MuiFormHelperText-contained {
    ${({ theme }) => theme.typography.dina.chipLabel};
    white-space: prewrap;
  }
  .MuiAutocomplete-inputRoot {
    margin-top: 4px;
    padding: 2px;
    width: 100%;
    padding: 4px;
    display: inline-flex;
    flex-flow: row wrap;
    min-height: 24px;
    border: 1px solid transparent;
    border-radius: 4px;
    &:hover {
      background-color: ${({ theme }) => theme.palette.dina.outlineButtonOnHover};
    }
    &.Mui-focused {
      ${({ theme }) => css`
        border-color: ${theme.palette.dina.statusOnFocused};
        background: ${theme.palette.dina.statusOnFocusedDiscreetFill};
      `}
    }
  }
  input {
    width: 256px;
    font-size: 14px;
    padding-left: 6px;
    color: ${({ theme }) => theme.palette.dina.highEmphasis};
    padding: 4px 6px !important;
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder {
      color: ${({ theme }) => theme.palette.dina.disabled};
    }
  }
  .MuiFilledInput-underline {
    &:hover:not($disabled):not($focused):not($error):before {
      border-bottom: none;
    }
    &:after {
      border-color: transparent;
    }
    &:before {
      border-color: transparent;
    }
  }
`;

export const StyledPopper = styled(Popper)`
  .MuiAutocomplete-listbox {
    padding: 0;
  }
  .MuiAutocomplete-option {
    background: ${({ theme }) => theme.palette.dina.surfaceCard};
    &:hover,
    &[data-focus='true'] {
      background: ${({ theme }) => theme.palette.dina.storyCardHover};
      .styled-option {
        border: none;
      }
    }
  }

  .MuiAutocomplete-option:last-child .styled-option {
    border: none;
  }
  .MuiAutocomplete-noOptions {
    ${({ theme }) => theme.typography.dina.captionMedium};
  }
`;

export const Field = styled('span')``;
