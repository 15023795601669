import gql from 'graphql-tag';

/*  Query to search for stories in the indexed storysearchtable
    $Searchfilter variables
    ## the search string can contains spaces for AND search and | for OR search 
    searchString:String
    ## the mUpdated at is where the search shall start e.g 2020-08-02
    mUpdatedAt:String
    ## assignedMembers is an array of user ids, team ids department ids.
    assignedMembers:[string]
*/
export default gql`
  query SearchApi($filter: SearchFilter, $nextToken: String, $limit: Int) {
    searchApi(filter: $filter, nextToken: $nextToken, limit: $limit) {
      nextToken
      items {
        mId
        mRefId
        mTitle
        mContentKey
        mThumbnailKey
        mCreatedAt
        mUpdatedAt
        mState
        mPublishingAt
        mType
        mStoryId
        mTemplateId
        isRestricted
        isTemplateInstance
        locked
        items {
          itemId
          title
          templateType
          templateVariant
          iconUrl
          subItems {
            itemId
            title
            templateType
            templateVariant
            iconUrl
          }
        }
        mProperties {
          __typename
          ... on PlatformType {
            id
            platform
            platformKind
            provider {
              id
              requestId
              state
              message
              embeddedEndpoint
              previewEndpoint
              updateEndpoint
            }
            account {
              id
              accountUrl
              accountLogo
              accountTitle
              accountId
              accountRefId
              orderType
            }
          }
        }
        mMetaData {
          key
          value
          autoValue
          manual
        }
        mAssignedMembers {
          mId
          mType
        }
        mRelatedMembers
      }
    }
  }
`;
