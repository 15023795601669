export const STANDARD_MDF_KEYS = `
  id
  label
  isSubtype
  views {
    default {
      fieldId
      hint
      visible
      multiline
      fieldHeight
      label
      colors
    }
    order_grid {
      fieldId
      hint
      visible
      multiline
      fieldHeight
      label
      colors
    }
    order_form {
      fieldId
      hint
      visible
      multiline
      fieldHeight
      label
      colors
    }
    story_view {
      fieldId
      hint
      visible
      label
      colors
    }
    story_create {
      fieldId
      hint
      visible
      label
      colors
    }
    search_view {
      fieldId
      hint
      visible
      label
      colors
    }
  }
  fields {
    fieldId
    required
    constraint
    systemDefault
    defaultValue
    freeform
    type
    filter
    filters
    alternatives {
      id
      label
      value
    }
    treeAlternatives
    optionListId
  }
  permissions {
    read
    write
  }
`;

export const STANDARD_ORDER_FORM_KEYS = `
  mId
  mRefId
  mTitle
  mDescription
  mSecId
  mColor
  configs {
    key
    name
    values
    alternatives {
      id
      label
      value
    }
  }
`;

export const STANDARD_AI_PROMPT_KEYS = `
  mId
  mRefId
  configs {
    key
    alternatives {
      id
      label
      value
    }
  }
`;

export const STANDARD_ACTION_ADMIN_KEYS = `
  mId 
  mRefId
  mTitle
  mDescription
  mProvider
  mUpdatedAt
  mTypes
  mAllowedEntities {
    mType
  }
  mInvokeUrl
  mActive
  externalId
  endpoint
  iconUrl
  connectedIds
  auth {
    type
  }
`;

export const STANDARD_ACTION_KEYS = `
  mRefId
  mTitle
  mDescription
  mTypes
  connectedIds
  iconUrl
`;

export const STANDARD_OPTION_LIST_KEYS = `
  id
  label
  optionListType
  alternatives {
    label
    value
  }
`;

export const getErrorMessage = <E, I>(err: E, input: I, type?: string): string =>
  `${type ?? ''} ${err instanceof Error ? err.message : ''} - input: ${JSON.stringify(input)}`;
