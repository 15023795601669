import * as React from 'react';

import { TextAreaStyled } from 'components/input/TextAreaStyled';

export interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ ...props }, ref) => {
    return <TextAreaStyled ref={ref} {...props} />;
  },
);

TextArea.displayName = 'TextArea';
