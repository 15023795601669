import { gql } from 'graphql-tag';

export default gql`
  mutation UpdateUser($input: UpdateUserInput) {
    updateUser(input: $input) {
      mId
      mRefId
      mLastLogin
      mTitle
      mMetaData {
        value
        key
      }
      user {
        email
        provider
        status
      }
      metadata
    }
  }
`;
