import { memo } from 'react';
import styled from '@emotion/styled';

import { ReactComponent as ArrowUpDown } from 'assets/icons/search/arrow_up_down.svg';
import { ReactComponent as Enter } from 'assets/icons/search/enter.svg';
import { ReactComponent as Help } from 'assets/icons/search/help.svg';
import Text from 'components/text/Text';
import { HStack } from 'layouts/box/Box';

const Wrapper = styled('div')`
  height: 32px;
  width: 100%;
  padding: 0 8px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  flex-shrink: 0;
`;

const StyledText = styled(Text)`
  margin-left: 4px;
  display: flex;
  align-items: center;
`;

const items = [
  {
    Icon: ArrowUpDown,
    text: 'Select item',
  },
  {
    Icon: Enter,
    text: 'Open',
  },
];

function CommandToolbar() {
  return (
    <Wrapper>
      <HStack alignItems="center" gap="16px">
        {items.map(({ text, Icon }) => (
          <HStack key={text} alignItems="center">
            <Icon />
            <StyledText variant="caption">{text}</StyledText>
          </HStack>
        ))}
      </HStack>
      <HStack alignItems="center">
        <StyledText variant="caption">
          Type <Help style={{ margin: '0 4px' }} /> for help
        </StyledText>
      </HStack>
    </Wrapper>
  );
}

export default memo(CommandToolbar);
