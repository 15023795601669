import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  groupRoot: {
    ...theme.typography.dina.subtitle1,
    lineHeight: '18px',
    color: theme.palette.dina.highEmphasis,
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  contentContainer: {
    width: '100%',
    borderTop: `1px solid ${theme.palette.dina.dividerLight}`,
  },
  labelContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  icon: {
    marginRight: '16px',
  },
  listItem: {
    padding: '0px 0px 0px 16px',
    '&:hover': {
      background: theme.palette.dina.whiteHoverOverlay,
    },
  },
}));

export default useStyles;
