import React from 'react';
import isHotkey from 'is-hotkey';
import { BaseRange, Editor, Element, NodeEntry, Range, Transforms } from 'slate';

import onContentKeyDown from 'components/editor/components/content/utils/onContentKeyDown';
import { elementTypes } from 'components/editor/constants/types';
import { CustomElement } from 'types';
import preventDefaultEvent from 'utils/preventDefaultEvent';

import { insertParagraph } from '../components/paragraph/utils';
import isSection from '../components/sectionDivider/utils/isSection';
import { EditorVariant } from '../types';

import {
  anchorInPath,
  backspaceOnSectionDivider,
  defaultSelection,
  deleteNodes,
  getNodesFromSelection,
  hasRestrictedBlock,
  isMdfBlock,
  isOrderBlock,
  removeNodesWhenCollapsed,
  removeNodesWhenExpanded,
} from './ElementKeyDownUtils';
import { conditionalBlocksPlatformVariants, DesiredVariants } from './getConditionalToolbarBlocks';
import matchRestriction from './matchRestriction';

const { nodes, isEmpty, previous } = Editor;
const { setNodes, insertText } = Transforms;
const elementTypeValues = Object.values(elementTypes);
const { isExpanded, isCollapsed } = Range;

type SectionWrap = (unwrap: boolean) => void;
type Options = {
  isAllowed: boolean;
  isCmsBlock: boolean;
  isMessageVariant: boolean;
};

const onMdfOrOrderBlock = (type: string, event: React.KeyboardEvent<HTMLDivElement>) => {
  if (isMdfBlock(type) || isOrderBlock(type)) {
    preventDefaultEvent(event);
  }
};

const handleDeleteKeyPress = (
  editor: Editor,
  match: NodeEntry<CustomElement>,
  shouldPrevent: boolean,
  event: React.KeyboardEvent<HTMLDivElement>,
) => {
  const selection = editor.selection ?? defaultSelection;
  if (!selection) return;

  const [element, path] = match;
  const cursorAt = anchorInPath(editor, selection.anchor, path);

  /* Handle delete key press on mdf or order block */
  onMdfOrOrderBlock(element.type, event);

  /* on content element */
  const isEnd = cursorAt(Editor.isEnd);
  const isContentElement = element.type === elementTypes.CONTENT;
  if (shouldPrevent) onContentKeyDown(event, isContentElement, isEnd);

  /* on section divider */
  const isStart = cursorAt(Editor.isStart);
  const isSectionDivider = isSection(element);
  if (isCollapsed(selection) && isEnd)
    removeNodesWhenCollapsed(editor, match, shouldPrevent, isSectionDivider, isStart, event);

  /* when selection is expanded meaning multiple nodes are selected */
  if (isExpanded(selection))
    removeNodesWhenExpanded(editor, match, isContentElement, isSectionDivider, event);
};

const isCollapsedSectionDivider = (
  selection: BaseRange,
  isStart: boolean,
  isSectionDivider: boolean,
) => isCollapsed(selection) && isStart && isSectionDivider;

const handleBackspace = (
  editor: Editor,
  match: NodeEntry<CustomElement>,
  shouldPrevent: boolean,
  event: React.KeyboardEvent<HTMLDivElement>,
) => {
  const selection = editor.selection ?? defaultSelection;
  const [element, path] = match;

  /* backspace on mdf or order block */
  onMdfOrOrderBlock(element.type, event);

  /* backspace on section divider */
  const isSectionDivider = isSection(element);
  const isStart = anchorInPath(editor, selection.anchor, path)(Editor.isStart);
  if (isCollapsedSectionDivider(selection, isStart, isSectionDivider))
    backspaceOnSectionDivider(editor, match, event);

  /* backspace on content element */
  const isContentElement = element.type === elementTypes.CONTENT;
  if (shouldPrevent) onContentKeyDown(event, isContentElement, isStart);

  /* backspace when editor selection goes to top and previous node doesn't exist */
  const previousNode = previous(editor, { at: path });
  if (!isContentElement && isEmpty(editor, element) && !previousNode) {
    if (!hasRestrictedBlock(getNodesFromSelection(editor))) {
      setNodes(editor, { type: elementTypes.PARAGRAPH });
    }
  }

  /* backspace when there is multiple nodes selected  */
  if (selection && isExpanded(selection)) {
    deleteNodes(editor, match, isSectionDivider, event);
  }
};

const handleContentElement = (
  editor: Editor,
  match: NodeEntry<CustomElement>,
  event: React.KeyboardEvent<HTMLDivElement>,
) => {
  const [element] = match;
  if (element.type !== elementTypes.CONTENT) return;

  const { key } = event;
  const isShiftEnter = isHotkey('shift+enter')(event);
  const isEnter = key === 'Enter';
  /* on enter / shift+enter add a newline on content block */
  if (isShiftEnter || isEnter) {
    preventDefaultEvent(event);
    insertText(editor, '\n');
  }
};

const handleSectionWrap = (
  editor: Editor,
  match: NodeEntry<CustomElement>,
  onSectionWrap: SectionWrap,
  event: React.KeyboardEvent<HTMLDivElement>,
) => {
  const [element, path] = match;
  const { key, altKey, ctrlKey, metaKey, shiftKey, code } = event;
  const isEnter = key === 'Enter';

  const isSectionWrapperKey = altKey && (ctrlKey || metaKey) && code === 'KeyG';
  const isAddParagraphAfterSectionKey = altKey && (ctrlKey || metaKey) && isEnter;
  const isSectionDivider = isSection(element);

  if (isSectionWrapperKey) onSectionWrap(shiftKey);
  if (isSectionDivider && isAddParagraphAfterSectionKey) {
    preventDefaultEvent(event);
    insertParagraph(editor, {
      at: [path[0] + 1],
      select: true,
    });
  }
};

/**
 * Handles onKeyDown event on all elements
 *
 * @returns SlateJS editor instance
 */
const onElementKeyDown = (
  editor: Editor,
  variant: EditorVariant,
  onSectionWrap: SectionWrap,
  event: React.KeyboardEvent<HTMLDivElement>,
  { isAllowed, isCmsBlock, isMessageVariant }: Options,
  onDone?: () => void,
) => {
  const [match] = nodes<CustomElement>(editor, {
    match: (node) => Element.isElement(node) && elementTypeValues.includes(node.type),
  });

  if (!match) return editor;

  const { key } = event;
  const isShiftEnter = isHotkey('shift+enter')(event);
  const isEnter = key === 'Enter';
  const isBackspace = key === 'Backspace';
  const isDelete = key === 'Delete';

  /* If it is message (chat) editor and enter is pressed (not shift+enter) fire onDone callback */
  if (isMessageVariant && isEnter && !isShiftEnter && onDone) onDone();

  /* If it is CMS block instance or one of twitter, facebook, instagram or linkedin instance
  enable section wrapping */
  if (isCmsBlock || conditionalBlocksPlatformVariants.includes(variant as DesiredVariants))
    handleSectionWrap(editor, match, onSectionWrap, event);

  const shouldPrevent = !isCmsBlock && isAllowed && matchRestriction(variant);

  if (shouldPrevent) handleContentElement(editor, match, event);

  if (isBackspace) handleBackspace(editor, match, shouldPrevent, event);

  if (isDelete) handleDeleteKeyPress(editor, match, shouldPrevent, event);

  return editor;
};

export default onElementKeyDown;
