import gql from 'graphql-tag';

export default gql`
  query GetMember($input: GetMemberInput) {
    getMember(input: $input) {
      mId
      mRefId
      mTitle
      mDescription
      mAvatarKey
      mCreatedAt
      mOrder
      mUpdatedAt
      mUpdatedById
      locked
      mType
      mContentKey
      metadata
    }
  }
`;
