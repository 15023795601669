import { useContext } from 'react';
import capitalize from 'lodash/capitalize';

import Avatar, { AvatarVariant } from 'components/avatar/Avatar';
import HoverCard from 'components/hoverCard/HoverCard';
import Text from 'components/text/Text';
import Tooltip from 'components/tooltip';
import UserContext from 'contexts/UserContext';
import useCreateConvo from 'hooks/useCreateConvo';
import { useIsMessageHubOpen, useMembersOpenDialog, useSelectedConversationId } from 'store';
import { AssignedMember } from 'types';
import { MemberTypeEnum } from 'types/graphqlTypes';

import Details from './Details';

import {
  Chat,
  Delete,
  Email,
  SelectedMembersWrapper,
  SelectMember,
  SelectMemberAvatar,
  SelectMemberHeader,
  SelectMemberTitle,
  SelectMemberTitleWrapper,
} from './styled';

interface Assignee extends AssignedMember {
  isOwner?: boolean;
  isCreator?: boolean;
}

interface SelectedMembersProps {
  selectedMembers: Assignee[];
  existingMembers: Assignee[];
  removeTooltipText?: string;
  onRemove: (member: Assignee) => void;
  subHeader?: string;
  disableRemoveOnlyAssignee?: boolean;
  disabledRemoveTooltipText?: string;
  hideLabel?: boolean;
}

interface DetailsTooltipProps {
  children: React.ReactElement;
  member: Assignee;
  isCurrentUser: boolean;
}

function DetailsTooltip({ children, member, isCurrentUser }: Readonly<DetailsTooltipProps>) {
  return (
    <HoverCard content={<Details userDetails={member} isCurrentUser={isCurrentUser} />}>
      {children}
    </HoverCard>
  );
}

function SelectedMembers({
  selectedMembers = [],
  existingMembers,
  removeTooltipText = 'Remove',
  onRemove = () => {},
  subHeader = 'Selected: ',
  disableRemoveOnlyAssignee,
  disabledRemoveTooltipText = '',
  hideLabel,
}: Readonly<SelectedMembersProps>) {
  const user = useContext(UserContext);
  const { createConvo } = useCreateConvo();
  const [, setIsMessageHubOpen] = useIsMessageHubOpen();
  const [, setSelectedConvoId] = useSelectedConversationId();
  const [, setOpen] = useMembersOpenDialog();

  const handleChat = async (member: Assignee) => {
    setOpen(false);
    await createConvo(member, (convo) => {
      setSelectedConvoId(convo?.mId);
      setIsMessageHubOpen(true);
    });
  };

  return (
    <SelectedMembersWrapper>
      {selectedMembers.length > 0 && !hideLabel ? (
        <SelectMemberHeader>{subHeader}</SelectMemberHeader>
      ) : null}
      {selectedMembers.map((member) => {
        const isCurrentUser = member.mId === user.mId;
        const isNew = !existingMembers.includes(member);
        const isDisabled = disableRemoveOnlyAssignee && selectedMembers.length === 1;
        return (
          <SelectMember key={member.mId}>
            <DetailsTooltip member={member} isCurrentUser={isCurrentUser}>
              <SelectMemberAvatar>
                <Avatar
                  variant={member.mType as AvatarVariant}
                  size={24}
                  imageKey={member.mAvatarKey}
                  title={member.mTitle}
                />
              </SelectMemberAvatar>
            </DetailsTooltip>
            <SelectMemberTitleWrapper>
              <DetailsTooltip member={member} isCurrentUser={isCurrentUser}>
                <SelectMemberTitle
                  variant={isNew ? 'listItemLabelItalic' : 'listItemLabel'}
                  color="highEmphasis"
                >
                  {member.mTitle}
                </SelectMemberTitle>
              </DetailsTooltip>
              {member.mType !== ('user' as MemberTypeEnum) && (
                <Text variant="captionItalic" color="disabled">
                  ({capitalize(member?.mType)})
                </Text>
              )}
            </SelectMemberTitleWrapper>
            {isCurrentUser && (
              <Text variant="caption" color="mediumEmphasis">
                You
              </Text>
            )}
            {!member.isOwner &&
              (member.isCreator ? (
                <Text variant="caption" color="mediumEmphasis">
                  Creator
                </Text>
              ) : (
                isNew && (
                  <Text variant="caption" color="statusWarning">
                    New
                  </Text>
                )
              ))}
            {!isCurrentUser && (
              <>
                {member?.mProperties?.email && (
                  <Tooltip title="email">
                    <Email
                      type="a"
                      onClick={() =>
                        window.location.assign(`mailto: ${member?.mProperties?.email ?? ''}`)
                      }
                    />
                  </Tooltip>
                )}
                <Tooltip title="chat">
                  <Chat onClick={() => void handleChat(member)} />
                </Tooltip>
              </>
            )}
            {!member.isCreator && !member.isOwner && (
              <Tooltip title={!isDisabled ? removeTooltipText : disabledRemoveTooltipText}>
                <Delete
                  onClick={() => {
                    if (!isDisabled) onRemove(member);
                  }}
                  disabled={isDisabled}
                />
              </Tooltip>
            )}
          </SelectMember>
        );
      })}
    </SelectedMembersWrapper>
  );
}

export default SelectedMembers;
