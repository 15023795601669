import { Collapsible, CollapsibleContent, CollapsibleTrigger } from 'lib/collapsible';

interface Props {
  /** Click to show/hide content */
  trigger: React.ReactNode;
  /** Content to be shown when trigger is clicked */
  content: React.ReactNode;
  /** Whether the content is visible */
  open?: boolean;
  /** Callback to set the open state */
  setOpen?: (isOpen: boolean) => void;
}

export default function Collapse({ trigger, content, setOpen, ...rest }: Props) {
  return (
    <Collapsible defaultOpen {...rest} onOpenChange={setOpen}>
      <CollapsibleTrigger asChild>{trigger}</CollapsibleTrigger>
      <CollapsibleContent>{content}</CollapsibleContent>
    </Collapsible>
  );
}
