import * as React from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';

import { ReactComponent as CloseIcon } from 'assets/icons/systemicons/close.svg';

import { Close, Content, Footer, Header, Overlay, Title } from './styled';

const Dialog = DialogPrimitive.Root;

const DialogTrigger = DialogPrimitive.Trigger;

const DialogPortal = DialogPrimitive.Portal;

const DialogClose = DialogPrimitive.Close;

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ ...props }, ref) => <Overlay ref={ref} {...props} />);
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

type ContentPrimitiveProps = React.ComponentProps<typeof DialogPrimitive.Content>;
type ContentProps = {
  onPointerDownOutside?: ContentPrimitiveProps['onPointerDownOutside'];
  onInteractOutside?: ContentPrimitiveProps['onInteractOutside'];
  dialogWidth?: number;
  onDialogFocus?: () => void;
  modal?: boolean;
  overlayStyle?: React.CSSProperties;
};
export type ContentActionProps = Pick<ContentProps, 'onPointerDownOutside' | 'onInteractOutside'>;

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content> & {
    dialogWidth?: number;
    modal?: boolean;
  },
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & ContentProps
>(({ children, onDialogFocus, modal = true, overlayStyle, ...props }, ref) => {
  const overlayRef = React.useRef<HTMLDivElement | null>(null);

  const OverlayComponent = modal ? DialogOverlay : React.Fragment;
  const overlayStyles = modal ? { style: overlayStyle } : {};

  return (
    <DialogPortal>
      <OverlayComponent ref={modal ? overlayRef : null} {...overlayStyles}>
        <Content
          ref={ref}
          {...props}
          onOpenAutoFocus={(ev) => {
            ev.preventDefault();
            if (onDialogFocus) onDialogFocus();
          }}
          onPointerDownOutside={(ev) => {
            if (ev.currentTarget !== overlayRef.current) {
              ev.preventDefault();
            }
          }}
        >
          {children}
        </Content>
      </OverlayComponent>
    </DialogPortal>
  );
});
DialogContent.displayName = DialogPrimitive.Content.displayName;

const DialogEmpty = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>(({ children, ...props }, ref) => (
  <Content ref={ref} {...props}>
    {children}
  </Content>
));
DialogEmpty.displayName = DialogPrimitive.Content.displayName;

const DialogHeader = ({
  children,
  showCloseIcon = true,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & { showCloseIcon?: boolean }) => (
  <Header {...rest}>
    <>
      {children}
      {showCloseIcon && (
        <Close>
          <CloseIcon />
          <span className="sr-only">Close</span>
        </Close>
      )}
    </>
  </Header>
);
DialogHeader.displayName = 'DialogHeader';

const DialogFooter = ({ ...props }: React.HTMLAttributes<HTMLDivElement>) => <Footer {...props} />;
DialogFooter.displayName = 'DialogFooter';

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ ...props }, ref) => <Title ref={ref} {...props} />);
DialogTitle.displayName = DialogPrimitive.Title.displayName;

export {
  Dialog,
  DialogPortal,
  DialogOverlay,
  DialogClose,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogEmpty,
};

export type DialogProps = DialogPrimitive.DialogProps;
