import { useContext, useState, useEffect, useCallback } from 'react';
import configCtx from 'contexts/configContext';

const useGetAutomationTemplates = () => {
  const { automationTemplateConfigs, automationTemplateConfigsValues } = useContext(configCtx);
  const { automationTemplateConfigIndex, templateSetIndex } = automationTemplateConfigsValues;
  const studio = automationTemplateConfigs[automationTemplateConfigIndex] || {};
  const resources = studio.resources || {};

  const getTemplates = useCallback(
    (index) => {
      const templateSets_ = studio.templateSets || [];
      const templateSet_ = templateSets_[index] || {};
      const templates_ = templateSet_.templates || [];
      return templates_;
    },
    [studio.templateSets],
  );

  const getTemplateSets = useCallback(() => {
    const templateSets_ = studio.templateSets || [];
    return templateSets_.map(({ name }, index) => ({ title: name, value: index }));
  }, [studio.templateSets]);

  const [templates, setTemplates] = useState(getTemplates(templateSetIndex));
  // eslint-disable-next-line no-unused-vars
  const [templateSets, setTemplateSets] = useState(getTemplateSets());

  useEffect(() => {
    const newTemplateSet = getTemplates(templateSetIndex);
    setTemplates(newTemplateSet);
  }, [getTemplates, templateSetIndex]);

  const selectTemplateSet = useCallback(
    (newTemplateSetIndex) => {
      automationTemplateConfigsValues.templateSetIndex = newTemplateSetIndex;
      const newTemplateSet = getTemplates(newTemplateSetIndex);
      setTemplates(newTemplateSet);
      window.localStorage.setItem('automation', JSON.stringify(automationTemplateConfigsValues));
    },
    [automationTemplateConfigsValues, getTemplates],
  );

  return { templates, resources, selectTemplateSet, templateSetIndex, templateSets };
};

export default useGetAutomationTemplates;
