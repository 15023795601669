import { isKeyHotkey } from 'is-hotkey';
import { BasePoint, Editor, Element, Range, Transforms } from 'slate';

import { elementTypes } from 'components/editor/constants';
import { defaultSelection } from 'components/editor/utils/ElementKeyDownUtils';
import { CustomElement, CustomText } from 'types';

const isAtStart = (point?: BasePoint) => point?.offset === 0;

/**
 * Handles onKeyDown event on link element
 *
 * @param editor SlateJS editor instance
 * @param event React synthetic event
 * @returns SlateJS editor instance
 */

const onLinkKeyDown = (editor: Editor, event: React.KeyboardEvent<HTMLDivElement>) => {
  const { selection } = editor;
  const [match] = Editor.nodes<CustomElement>(editor, {
    match: (node) => Element.isElement(node) && node.type === elementTypes.LINK,
  });

  if (match && event.key === 'Backspace') {
    const [element, path] = match;

    const previousPoint = Editor.before(editor, path);
    const currentPoint = Editor.end(editor, selection ?? defaultSelection);
    const [nodeAbove] = Editor.previous<CustomElement>(editor, { at: previousPoint }) ?? [];

    const isPointAfterVoid =
      isAtStart(previousPoint) &&
      isAtStart(currentPoint) &&
      nodeAbove &&
      Editor.isVoid(editor, nodeAbove);

    if (isPointAfterVoid) {
      event.preventDefault();
      Transforms.move(editor, { reverse: true });
      return;
    }

    const { children } = element;
    const [{ text = '' }] = children as CustomText[];

    if (text.length === 1) Transforms.removeNodes(editor, { at: path });
  }

  // Default left/right behavior is unit:'character'.
  // This fails to distinguish between two cursor positions, such as
  // <inline>foo<cursor/></inline> vs <inline>foo</inline><cursor/>.
  // Here we modify the behavior to unit:'offset'.
  // This lets the user step into and out of the inline without stepping over characters.
  // We can customize this further to only use unit:'offset' in specific cases(if needed).
  if (selection && match && Range.isCollapsed(selection)) {
    const { nativeEvent } = event;
    if (isKeyHotkey('left', nativeEvent)) {
      event.preventDefault();
      Transforms.move(editor, { unit: 'offset', reverse: true });
      return editor;
    }
    if (isKeyHotkey('right', nativeEvent)) {
      event.preventDefault();
      Transforms.move(editor, { unit: 'offset' });
      return editor;
    }
  }

  return editor;
};

export default onLinkKeyDown;
