import { Row, TableMeta } from '@tanstack/react-table';
import { capitalize } from 'lodash';

import Tooltip from 'components/tooltip';
import { Center } from 'layouts/box/Box';
import { ParsedMemberType } from 'types/members';

import { OpenIcon, RemoveIcon } from './styled';

export interface ExtendedTableMeta extends TableMeta<ParsedMemberType> {
  onOpen: (member: ParsedMemberType) => void;
  onRemove: (member: ParsedMemberType) => void;
}

interface Props {
  row: Row<ParsedMemberType>;
  tableMeta: ExtendedTableMeta;
}

/** Row actions component */
export function RowActions({ row, tableMeta }: Readonly<Props>) {
  const { onOpen, onRemove } = tableMeta;

  return (
    <Center gap="6px" height="100%" width="100%" padding="0 4px 0 0">
      <Tooltip title={`Go to ${capitalize(row.original.mType)}`}>
        <OpenIcon onClick={() => onOpen(row.original)} />
      </Tooltip>
      <Tooltip title="Remove">
        <RemoveIcon onClick={() => onRemove(row.original)} />
      </Tooltip>
    </Center>
  );
}
