/* eslint-disable max-len */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { atom, useAtom } from 'jotai';
import { capitalize } from 'lodash';

import useCreateSpace from 'api/useCreateSpace';
import { Button } from 'components/buttons';
import useOpenMember from 'components/contextMenu/useOpenMember';
import DialogBuilder from 'components/dialogs/DialogBuilder';
import DraggableDialog from 'components/dialogs/Draggable';
import { LoadingButtonIndicator } from 'components/loadingIndicator';
import { StyledTextField } from 'components/mdfEditor/fields/text/styled';
import Text from 'components/text/Text';
import useToast from 'components/toast/useToast';
import Tooltip from 'components/tooltip';
import { MemberType } from 'types/graphqlTypes';

import { Wrapper } from './styled';

export interface ISpaceDialog {
  show: boolean;
  type: 'create' | 'update';
  spaceToUpdate?: Pick<MemberType, 'mId' | 'mTitle' | 'mDescription'>;
  teamOrDeptSpace?: {
    spaceType: 'team' | 'department';
    spaceId: string;
    title: string;
    description?: string;
  };
  onComplete?: () => void;
}

const defaults: ISpaceDialog = {
  show: false,
  type: 'create',
};

const spaceDialog = atom<ISpaceDialog>({ ...defaults });

export const useSpaceDialog = () => useAtom(spaceDialog);

const SpaceDialog = () => {
  const { errorToast } = useToast();
  const { openItem } = useOpenMember();
  const { createSpace, updateSpace } = useCreateSpace();
  const [state, setState] = useSpaceDialog();
  const [dimension, setDimension] = useState({ width: 'fit-content', height: 'fit-content' });
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const titleErrorTooltip = useMemo(() => (title.length === 0 ? 'Title is required' : ''), [title]);

  useEffect(() => {
    if (state.show) {
      setTitle(state.spaceToUpdate?.mTitle ?? state.teamOrDeptSpace?.title ?? '');
      setDescription(state.spaceToUpdate?.mDescription ?? state.teamOrDeptSpace?.description ?? '');
    }
  }, [state.show]);

  const header = useMemo(() => {
    if (state.type === 'create') {
      if (state.teamOrDeptSpace) {
        return `Create new space for ${state.teamOrDeptSpace.title}`;
      }
      return 'Create new Space';
    }
    return 'Edit Space';
  }, [state.type, state.teamOrDeptSpace]);

  const buttonLabel = useMemo(() => {
    if (state.type === 'create') return 'Create';
    return 'Save';
  }, [state.type]);

  const handleClose = () => {
    setState((prevState) => {
      return {
        ...prevState,
        show: false,
      };
    });
    setTimeout(() => {
      setState({
        ...defaults,
      });
    }, 1000);
  };

  const doCreateSpace = useCallback(async () => {
    setSubmitting(true);
    const result = await createSpace({
      title,
      description,
      spaceId: state.teamOrDeptSpace?.spaceId,
    }).finally(() => setSubmitting(false));

    if (result) {
      if (state.onComplete) {
        state.onComplete();
      } else {
        openItem(result);
      }
    }
    setState({ ...defaults });
  }, [openItem, createSpace, title, description, state.teamOrDeptSpace?.spaceId]);

  const doUpdateSpace = useCallback(async () => {
    if (state.spaceToUpdate?.mId) {
      setSubmitting(true);
      await updateSpace(
        state.spaceToUpdate.mId,
        state.spaceToUpdate.mId,
        title,
        description,
      ).finally(() => {
        if (state.onComplete) {
          state.onComplete();
        }
        setSubmitting(false);
        setState({ ...defaults });
      });
    }
  }, [updateSpace, setState, title, description, state]);

  const doAction = () => {
    if (state.type === 'create') {
      doCreateSpace().catch(errorToast);
    } else {
      doUpdateSpace().catch(errorToast);
    }
  };

  const onKeyDown = (ev: React.KeyboardEvent) => {
    if (!titleErrorTooltip && ev.key === 'Enter') {
      doAction();
    }
  };

  return (
    <DraggableDialog
      open={state.show}
      onClose={handleClose}
      minWidth={400}
      minHeight={140}
      dimension={dimension}
      setDimension={setDimension}
    >
      <Wrapper>
        <DialogBuilder.Header className={'dragHandler'}>{header}</DialogBuilder.Header>
        <DialogBuilder.Body>
          <Text variant="overline">
            {state.teamOrDeptSpace
              ? `${capitalize(state.teamOrDeptSpace.spaceType)} title`
              : 'Title'}
          </Text>
          <StyledTextField
            fullWidth
            autoFocus
            disabled={typeof state.teamOrDeptSpace?.spaceId === 'string'}
            error={Boolean(titleErrorTooltip)}
            variant="filled"
            helperText={titleErrorTooltip}
            onKeyDown={onKeyDown}
            placeholder="Type title here..."
            value={title}
            style={{ marginTop: '2px' }}
            onChange={(ev) => setTitle(ev.target.value)}
            onFocus={(ev) => ev.currentTarget.select()}
          />
          <Text variant="overline">Description</Text>
          <StyledTextField
            fullWidth
            variant="filled"
            onKeyDown={onKeyDown}
            placeholder={
              state.teamOrDeptSpace
                ? `Enter keywords to make your ${state.teamOrDeptSpace.spaceType} space easy to find`
                : 'Enter keywords to make your space easy to find'
            }
            value={description}
            style={{ marginTop: '2px' }}
            onChange={(ev) => setDescription(ev.target.value)}
            onFocus={(ev) => ev.currentTarget.select()}
          />
        </DialogBuilder.Body>

        <DialogBuilder.Footer>
          <Tooltip title={titleErrorTooltip}>
            <span>
              <Button
                ariaLabel={state.type === 'create' ? 'Create space' : 'Update space'}
                disabled={Boolean(titleErrorTooltip) || submitting}
                onClick={doAction}
                width={100}
                height={28}
                variant="contained"
              >
                {submitting ? <LoadingButtonIndicator /> : buttonLabel}
              </Button>
            </span>
          </Tooltip>
        </DialogBuilder.Footer>
      </Wrapper>
    </DraggableDialog>
  );
};

export default SpaceDialog;
