import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Text from 'components/text/Text';
import transientOptions from 'theme/helpers';

interface ChipWrapperProps {
  $width?: string | number;
  $height?: string | number;
  disabled?: boolean;
}

export const ChipWrapper = styled('div')<ChipWrapperProps>`
  ${({ theme, $width, $height }) => css`
    height: ${$height}px;
    width: ${$width === 'auto' ? 'auto' : `${$width}px`};
    border: 1px solid ${theme.palette.dina.buttonBorderOutlined};
    background-color: ${theme.palette.dina.blackHoverOverlay};
  `}
  padding-left: 4px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  :hover,
  :focus {
    background-color: ${({ theme, disabled }) =>
      disabled ? theme.palette.dina.blackHoverOverlay : theme.palette.dina.whiteHoverOverlay};
  }
`;

export const ChipLabel = styled(Text, transientOptions)`
  :not(:first-child) {
    margin-left: 4px;
  }
  :last-child {
    margin-right: 4px;
  }
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
