import { memo } from 'react';
import { RenderPlaceholderProps } from 'slate-react';

import useDebouncedLoading from 'hooks/useDebouncedLoading';

function CustomPlaceholder({
  attributes: { style, ...rest },
  children = null,
}: Readonly<RenderPlaceholderProps>) {
  const showLoading = useDebouncedLoading();

  return showLoading ? (
    <span {...rest} style={{ ...style, top: 'auto' }}>
      {children}
    </span>
  ) : null;
}

export default memo(CustomPlaceholder);
