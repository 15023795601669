import styled from '@emotion/styled';

import Text from 'components/text/Text';

const Wrapper = styled('div')`
  z-index: 1201;
  height: 24px;
  background: ${({ theme }) => theme.palette.dina.buttonBackgroundHighEmphasis};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export interface AppTitleProps {
  appTitle: string;
}

const AppTitle = ({ appTitle }: AppTitleProps) => {
  if (!appTitle) return null;

  return (
    <Wrapper>
      <Text variant="listItemLabelMedium" color="whiteHighEmphasis">
        {appTitle}
      </Text>
    </Wrapper>
  );
};

export default AppTitle;
