import {
  ChangeEvent,
  KeyboardEvent,
  KeyboardEventHandler,
  MouseEvent,
  useEffect,
  useState,
} from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import useInputEvents from 'hooks/useInputEvents';
import transientOptions from 'theme/helpers';
import preventDefaultAndPropagation from 'utils/preventDefaultAndStopPropagation';

import Text, { TextProps } from './Text';

import { Input } from './styled';

type TextAlign = 'left' | 'center' | 'right';

interface EditableTextProps extends TextProps {
  value: string;
  onUpdate: (value: string) => void | Promise<void>;
  onInputClick?: (e: MouseEvent | KeyboardEvent) => void;
  disabled?: boolean;
  borderColor?: TextProps['color'];
  textAlign?: TextAlign;
  multiline?: number;
  placeholder?: string;
}

export const StyledText = styled(Text, transientOptions)<{
  $canUpdate: boolean;
  $textAlign: TextAlign;
  $multiline?: number;
  $placeholder?: boolean;
}>`
  cursor: ${({ $canUpdate }) => ($canUpdate ? 'pointer' : 'select')};
  text-align: ${({ $textAlign = 'left' }) => $textAlign} !important;
  ${({ $multiline }) =>
    $multiline &&
    css`
      display: -webkit-box !important;
      -webkit-box-orient: vertical !important;
      -webkit-line-clamp: ${$multiline} !important;
      white-space: normal !important;
    `}
  ${({ $placeholder, theme }) =>
    $placeholder &&
    css`
      font-style: italic !important;
      color: ${theme.palette.dina.mediumEmphasis};
    `}
  :hover {
    text-decoration: ${({ $canUpdate }) => ($canUpdate ? 'underline' : 'none')};
  }
`;

function EditableText({
  value,
  onUpdate,
  onInputClick,
  disabled,
  variant = 'listItemLabel',
  color = 'highEmphasis',
  borderColor = 'inputBorderResting',
  textAlign = 'left',
  multiline,
  placeholder,
  ...rest
}: EditableTextProps) {
  const [showInput, setShowInput] = useState(false);
  const [localText, setLocalText] = useState(value);

  const onUpdateInput = async (newValue: string) => {
    const update = newValue?.trim();
    if (!update) setLocalText(value);
    else if (update === value) setLocalText(value);
    else {
      setLocalText(update);
      await onUpdate(update);
    }
    setShowInput(false);
  };

  const [inputRef, handleKeyDown] = useInputEvents(onUpdateInput, localText, value, true);

  const onChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setLocalText(event.target.value);

  const handleInputOnClick = (e: MouseEvent | KeyboardEvent) => {
    preventDefaultAndPropagation(e);
    if (onInputClick) {
      onInputClick(e);
    }
  };

  useEffect(() => {
    if (localText !== value) {
      setLocalText(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return showInput ? (
    <Input
      autoFocus
      type="text"
      $variant={variant}
      $color={color}
      $borderColor={borderColor}
      $textAlign={textAlign}
      inputRef={inputRef}
      value={localText}
      multiline={!!multiline}
      rows={multiline ?? 1}
      onKeyDown={
        handleKeyDown as unknown as KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>
      }
      onBlur={() => {
        setLocalText(value);
        setShowInput(false);
      }}
      onClick={handleInputOnClick}
      onChange={onChange}
      disabled={disabled}
    />
  ) : (
    <StyledText
      title={`${localText}\n\nClick to edit`}
      onClick={(e: MouseEvent | KeyboardEvent) => {
        if (!disabled) {
          preventDefaultAndPropagation(e);
          setShowInput(true);
        }
      }}
      $canUpdate={!disabled}
      $textAlign={textAlign}
      $multiline={multiline ?? 1}
      $placeholder={!localText.length && !!placeholder}
      variant={variant}
      color={color}
      truncate
      {...rest}
    >
      {localText.length ? localText : placeholder}
    </StyledText>
  );
}

export default EditableText;
